import React from 'react';
import Button from '../../../../../components/Button';

const Sidebar = ({
  input,
  prev,
  handleSelectNew,
  renderLabelsList,
  existing,
  onDelete,
  onSubmit,
  loadingMsg,
  isValid,
}) => {
  return (
    <div className="sidebar w-50">
      <div>
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex justify-content-between">
            <button className="sidebar__back" onClick={prev}>
              <i className="fas fa-arrow-left"></i>
              Setup
            </button>
          </div>
        </div>
        <h2 className="sidebar__title" style={{ marginTop: '2rem' }}>
          {input.name}
        </h2>

        {renderLabelsList()}
        <Button onClick={handleSelectNew} appearance="subtleBordered" fullWidth>
          New label
        </Button>
      </div>
      <div className="w-100">
        {/* {tempMsg && <p className="sidebar__msg">{tempMsg}</p>}
        {loadingMsg && <p className="sidebar__msg">{loadingMsg}</p>} */}
        {loadingMsg && <p className="sidebar__msg">{loadingMsg}</p>}
        <div className="d-flex mb-2">
          {existing && (
            <Button
              fullWidth
              appearance="red"
              onClick={onDelete}
              className="mr-2"
            >
              Delete
            </Button>
          )}
          <Button
            // appearance="secondary"
            disabled={!isValid}
            fullWidth
            onClick={onSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
