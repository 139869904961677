import React, { useEffect, useMemo, useRef, useState } from 'react';
import _isEqual from 'lodash/isEqual';
import { LabelsResultsData, SummaryDataItem } from '../../types/types';
import { useSelector } from 'react-redux';
import { useAnalyticsUrlProps } from '../../../CreativeAnalytics/PivotReport/hooks';
import { useSearchParams } from '../../../../components/Hooks/search-params';
import { DetailData } from './DetailData/DetailData';
import { SummaryTableWrapper } from './SummaryTable';

export const Results = ({
  summary_data = [],
  detail_data = [],
  visual_id_data = [],
  dateRange,
  debug_data = [],
  renderAnalyticsToggle = () => {},
  darwinIqApi,
  selectedBlockIds,
  allBlocks,
}: LabelsResultsData) => {
  const isROAS = useSelector((state) => state.ViewSettings.isROAS);
  const { selectedRowId, mode, summary_columns } = useAnalyticsUrlProps();
  const { setParams } = useSearchParams();

  const setSelectedRowId = (id, rest) =>
    setParams({ selectedRowId: id, ...rest });

  const selectedRow: SummaryDataItem = useMemo(() => {
    return summary_data.find((item) => item.id === selectedRowId);
  }, [summary_data, selectedRowId]);

  const handleFetchOnRowClick = (id, refresh_insights = false) => {
    const row = summary_data.find((item) => item.id === id);

    const allowDataMode =
      summary_data.length === 1 ||
      (!!row?.ad_name_group && row?.ad_name_group !== 'No Grouping');

    if (!allowDataMode) return darwinIqApi.clearData();

    const selected_segment = allBlocks?.find((block) => {
      return block.name === row?.ad_name_group;
    });

    const isDataLoaded = !!summary_data?.length;
    const isNoFiltering = summary_data?.length === 1;
    const isSegmentSelected = !!row && row.ad_name_group !== 'No Grouping';
    const isValidSegment =
      !!row?.ad_name_group && (!row?.label_name || row?.label_name === 'None');

    if (isDataLoaded && (isNoFiltering || isValidSegment)) {
      darwinIqApi.fetchDarwinIqData(
        dateRange.start,
        dateRange.end,
        selected_segment,
        refresh_insights
      );
    }
  };

  useEffect(() => {
    if (summary_data.length > 0) {
      const sortedBySpend = summary_data.sort(
        (a, b) => b.total_spend - a.total_spend
      );
      let defaultRow = sortedBySpend[0].id;

      if (summary_data.length > 1) {
        defaultRow =
          sortedBySpend.find((item) => item.ad_name_group !== 'No Grouping')
            ?.id || sortedBySpend[0].id;
      }

      const dataHasLabelName = summary_data.some(
        (group) => !!group.label_name && group.label_name !== 'None'
      );

      let updated_cols = [...summary_columns];

      if (dataHasLabelName) {
        if (!summary_columns.includes('label_name'))
          updated_cols.push('label_name');

        setParams({ summary_columns: updated_cols });
      }

      if (!selectedRow) {
        setSelectedRowId(defaultRow, { summary_columns: updated_cols });
      }
      handleFetchOnRowClick(defaultRow ?? selectedRowId);
    }
  }, [summary_data]);

  const handleRowSelect = (id) => {
    setSelectedRowId(id);
    handleFetchOnRowClick(id);
  };

  return (
    <div className="lp-results">
      <SummaryTableWrapper
        data={summary_data}
        exportFileName="summary_data"
        onRowSelect={handleRowSelect}
        selectedRowId={selectedRowId}
        dateRange={dateRange}
      />

      <DetailData
        exportFileName="detail_data"
        renderAnalyticsToggle={renderAnalyticsToggle}
        darwinIqData={darwinIqApi.data}
        fetchAnalyticsData={(refresh_insights) =>
          handleFetchOnRowClick(selectedRowId, refresh_insights)
        }
        allowDataMode={
          summary_data.length === 1 ||
          (!!selectedRow?.ad_name_group &&
            selectedRow?.ad_name_group !== 'No Grouping')
        }
        {...{
          detail_data,
          selectedRow,
          dateRange,
          isROAS,
          darwinIqApi,
          mode,
          visual_id_data,
        }}
      />
    </div>
  );
};
