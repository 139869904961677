import React from 'react';
import flatten from 'lodash/flatten';
import { useViewSettings } from '../../../../selectors';
import { toCurrency, toFixed_2, toInt } from '../../../../utils/numbers';

export const useCustomIntegratedMetricsColumns = ({ isNoom }) => {
  const { customIntegratedMetrics } = useViewSettings();

  if (!customIntegratedMetrics) return [];

  if (isNoom) {
    return [
      {
        Header: `Signups`,
        accessor: 'Signups',
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          const value = original['Signups'];
          if (!value) return <p>N/A</p>;
          return <p>{toInt(value)}</p>;
        },
      },
      {
        Header: `LTV`,
        accessor: 'LTV',
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          const value = original['LTV'];
          if (!value) return <p>N/A</p>;
          return <p>{toCurrency(value)}</p>;
        },
      },
      {
        Header: `Cost per Signup`,
        accessor: 'cp_Signups',
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          const value = original['cp_Signups'];
          if (!value || !isFinite(value)) return <p>N/A</p>;
          return <p>{toCurrency(value)}</p>;
        },
      },
      {
        Header: `Average LTV`,
        accessor: 'Average LTV',
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          const value = original['Average LTV'];
          if (!value || !isFinite(value)) return <p>N/A</p>;
          return <p>{toCurrency(value)}</p>;
        },
      },
      {
        Header: `LTV to CAC Ratio`,
        accessor: 'LTV to CAC Ratio',
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          const value = original['LTV to CAC Ratio'];
          if (!value || !isFinite(value)) return <p>N/A</p>;
          return <p>{toFixed_2(value)}</p>;
        },
      },
    ];
  }

  const customIntegratedMetricsColumns = customIntegratedMetrics.map(
    (metric) => {
      return [
        {
          Header: `Total ${metric.label}`,
          accessor: metric.value,
          disableFilters: true,
          Cell: ({ row: { original } }) => {
            const value = original[metric.value];
            if (!value) return <p>N/A</p>;
            return <p>{value.toFixed(2)}</p>;
          },
        },
        {
          Header: `Cost Per ${metric.label}`,
          accessor: `cp_${metric.value}`,
          disableFilters: true,
          minWidth: 200,
          Cell: ({ row: { original } }) => {
            const value = original[`cp_${metric.value}`];
            if (!value) return <p>N/A</p>;
            return <p>{toCurrency(value)}</p>;
          },
        },
        {
          Header: `Median Cost Per ${metric.label}`,
          accessor: `median_cp_${metric.value}`,
          disableFilters: true,
          minWidth: 200,
          Cell: ({ row: { original } }) => {
            const value = original[`median_cp_${metric.value}`];
            if (!value) return <p>N/A</p>;
            return <p>{toCurrency(value)}</p>;
          },
        },
      ];
    }
  );

  return flatten(customIntegratedMetricsColumns);
};
