const RoleCache = new Map();

if (process.env !== 'production') {
  window.RoleCache = RoleCache;
}

function parseCookie(cookie) {
  const parsed = decodeURIComponent(cookie)
    .split('; ')
    .map((c) => c.split('='));

  return new Map(parsed);
}

let current = document.cookie;
let Cookies = parseCookie(current);

function valueEq(key, predicate) {
  if (current !== document.cookie) {
    current = document.cookie;
    Cookies = parseCookie(current);
  }

  if (!Cookies.has(key)) {
    return false;
  }
  return predicate(Cookies.get(key));
}

const isAdmin = (val) => val.includes('internal') || val.includes('admin_user');

export const isAdminRole = () => valueEq('roles', isAdmin);

const isContractor = (val) => val.includes('contractor');

export const isContractorRole = () => valueEq('roles', isContractor);

/**
 * @description Returns true if user's cookie contains the given roles.
 * Returns true by default if the user is an admin user, which can be ignored
 * by passing useAdmin: false.
 * @param {string[]} roles
 * @param {{ useAdmin: boolean}=} options
 */
export const hasRoles = (roles = [], options = { useAdmin: true }) => {
  const userRoles = Cookies.get('roles') || '';

  if (userRoles.includes('internal') || userRoles.includes('admin_user')) {
    if (options.useAdmin) {
      return true; // Otherwise continue to check roles.
    }
  }
  const key = roles.sort((a, b) => a.localeCompare(b)).join(',');

  if (!RoleCache.has(key)) {
    RoleCache.set(
      key,
      roles.some((role) => userRoles.includes(role))
    );
  }

  return RoleCache.get(key);
};
