export const MOCK = [
  {
    account_id: '123',
    report_group_id:
      '17976d87ac427f65dc1615325708460961bc2590826d4c16dc22fbe1a0aeab5f',
    report_group_name: 'group1',
    report_group_tabs: [
      {
        report_url:
          'http://ec2-54-209-10-56.compute-1.amazonaws.com/static-report/bf8337caff26a7db89278487b4f7bf82f3ad1c5514440c07ca26047971f92548',
        tab_name: 'Wisdom Panel DNA',
        tab_order: 1,
      },
      {
        report_url:
          'http://ec2-54-209-10-56.compute-1.amazonaws.com/static-report/42309fc56c4039718944b4acc05311401948aeaea391f34fd11d85ba7168fda8',
        tab_name: 'Wisdom Panel PFR',
        tab_order: 2,
      },
    ],
  },
];
