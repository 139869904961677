import { useEffect } from 'react';
import { usePostRequest } from '../../../../components/Hooks/usePostRequest';
import { useSearchTermsApi } from '../../BasicLabelsSetup/api';
import { useCreativeAnalyticsGet } from '../../contexts';
import { useSelector } from 'react-redux';

export const useRerunLabelsApi = () => {
  const { request, error, loading, data } = usePostRequest(
    ['api', 'creative-analytics', 'get-keywords-by-thresholds'].join('/')
  );

  return {
    request,
    error,
    loading,
    data,
  };
};

export const useRerunForAccount = () => {
  const searchTermsApi = useSearchTermsApi(true);
  const rerunLabelsForAccountApi = usePostRequest(
    ['api', 'creative-analytics', 'rerun-labels-for-account'].join('/')
  );
  const {
    searchTermsData,
    account_id,
  } = useCreativeAnalyticsGet();
  const ids = useSelector(({ CreativeAnalytics: { visuals } }) => visuals.ids);
  const { brand_search_terms, promotion_search_terms, keywords } =
    searchTermsData ?? {};

  useEffect(() => {
    // update global keywords settings, then rerun labels
    if (searchTermsApi.post.data) {
      rerunLabelsForAccountApi.request({
        account_id,
        jobs_to_rerun: ['aws_text'],
        darwin_visual_ids: ids || [],
      });
    }
  }, [searchTermsApi.post.data]);

  useEffect(() => {
    console.log({ rerunLabelsForAccountApi });
  }, [rerunLabelsForAccountApi]);

  const updateThresholdSettings = (minTextSize, confidenceThreshold) => {
    const payload = {
      platform_account_id: account_id,
      brand_search_terms: brand_search_terms ?? [],
      promotion_search_terms: promotion_search_terms ?? [],
      keywords: keywords ?? [],
      confidence_threshold: confidenceThreshold,
      text_threshold: minTextSize,
    };

    searchTermsApi.post.request(payload);
  };

  return {
    handleRerunForAccount: (minTextSize, confidenceThreshold) => {
      updateThresholdSettings(minTextSize, confidenceThreshold);
    },
    searchTermsApi,
  };
};
