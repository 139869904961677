import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Toast } from 'bootstrap';

const ToastNotification = ({ status, title, message, since, delay }) => {
  var [toast, setToast] = useState(true);
  const toastRef = useRef();

  useEffect(() => {
      var myToast = toastRef.current
      var bsToast = Toast.getInstance(myToast)
      
      if (!bsToast) {
          // initialize Toast
          bsToast = new Toast(myToast, {autohide: false})
          // hide after init
          bsToast.hide()
          setToast(false)
      }
      else {
          // toggle
          toast ? bsToast.show() : bsToast.hide()
      }
  })

  return (
    <div ref={toastRef} className="toast ml-auto" data-delay={delay}>
      <div className={`toast-header text-light bg-${status}`}>
        <strong className="mr-auto">{title}</strong>
        <small>{since}</small>
        <button
          type="button"
          className="ml-2 mb-1 close"
          data-dismiss="toast"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="toast-body">{message}</div>
    </div>
  );
}

ToastNotification.defaultProps = {
  since: "just now"
};

ToastNotification.propTypes = {
  status: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  since: PropTypes.string.isRequired,
  delay: PropTypes.number.isRequired
};

export default ToastNotification;
