import React from 'react';
import './FavoriteIcon.scss';

export const FavoriteIcon = ({
  onClick,
  animateSave,
  animateUnsave,
  isSaved,
}) => (
  <span className={`favorite-icon ${isSaved ? 'saved' : ''}`} onClick={onClick}>
    {animateSave && (
      <>
        <span className="sparkle sparkle1"></span>
        <span className="sparkle sparkle2"></span>
        <span className="sparkle sparkle3"></span>
        <span className="sparkle sparkle4"></span>
        <span className="sparkle sparkle5"></span>
        <span className="sparkle sparkle6"></span>
      </>
    )}
    <svg
      className={
        animateSave ? 'just-saved' : animateUnsave ? 'just-unsaved' : ''
      }
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6284 9.52956L9.75337 16.3386L2.87837 9.52956M2.87837 9.52956C2.4249 9.08829 2.06771 8.55791 1.82929 7.97181C1.59087 7.38571 1.47638 6.7566 1.49304 6.12408C1.50971 5.49157 1.65715 4.86935 1.9261 4.29662C2.19504 3.72389 2.57966 3.21304 3.05573 2.79626C3.5318 2.37947 4.08902 2.06578 4.69228 1.87492C5.29554 1.68406 5.93179 1.62018 6.56096 1.6873C7.19012 1.75441 7.79858 1.95107 8.348 2.26489C8.89743 2.57871 9.37593 3.00289 9.75337 3.51073C10.1324 3.00658 10.6115 2.58611 11.1605 2.27562C11.7096 1.96513 12.3168 1.77131 12.9442 1.70629C13.5716 1.64127 14.2057 1.70645 14.8067 1.89775C15.4078 2.08906 15.9629 2.40236 16.4373 2.81806C16.9117 3.23376 17.2952 3.74291 17.5638 4.31363C17.8323 4.88435 17.9802 5.50437 17.9982 6.13488C18.0161 6.76538 17.9037 7.3928 17.668 7.97787C17.4323 8.56294 17.0784 9.09307 16.6284 9.53506"
        stroke="#1E2B4E"
        stroke-width="1.83333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
);

export default FavoriteIcon;
