import { useSelector } from "react-redux";

export const useDarwinClientId = () => {
  return useSelector(({ ViewSettings: { darwin_client_id } }) => darwin_client_id);
};

export const usePlatform = () => {
  return useSelector(({ ViewSettings: { platforms } }) => platforms ? platforms[0] : '');
};

export const useViewId = () => {
  return useSelector(({ ViewSettings: { viewId } }) => viewId);
};

export const useDates = () => {
  return useSelector(({ ViewSettings: { start_date, end_date } }) => ([start_date, end_date]));
};

export const useUserId = () => {
  return useSelector(({ ViewSettings: { user_id } }) => user_id || '');
};
