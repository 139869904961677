import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { getMonth, getYear } from 'date-fns';
import DatePicker from 'react-datepicker';
import Button from '../Button';

// datepicker css file loaded at root index.js

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const CustomDatePicker = ({
  startDate,
  endDate,
  handleChange,
  btnStyle = {},
}) => (
  <>
    <DatePicker
      customInput={<CustomLink btnStyle={btnStyle} />}
      selected={startDate}
      startDate={startDate}
      endDate={endDate}
      onChange={(e) => handleChange([e, endDate])}
      renderCustomHeader={CustomHeader}
    />
    -
    <DatePicker
      customInput={<CustomLink btnStyle={btnStyle} />}
      selected={endDate}
      selectsEnd
      startDate={startDate}
      endDate={endDate}
      onChange={(e) => handleChange([startDate, e])}
      renderCustomHeader={CustomHeader}
    />
  </>
);

CustomDatePicker.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default CustomDatePicker;

function CustomHeader({ decreaseMonth, increaseMonth, date }) {
  return (
    <div className="d-flex flex-row align-items-baseline justify-content-center">
      <button
        type="button"
        className="btn btn-link"
        onClick={(e) => (
          e.stopPropagation(), e.preventDefault(), decreaseMonth(e)
        )}
      >
        {'<'}
      </button>
      {MONTHS[getMonth(date)]} {getYear(date)}
      <button
        type="button"
        className="btn btn-link"
        onClick={(e) => (
          e.stopPropagation(), e.preventDefault(), increaseMonth(e)
        )}
      >
        {'>'}
      </button>
    </div>
  );
}

class CustomLink extends React.Component {
  render() {
    return (
      <button
        type="button"
        className="btn btn-link"
        style={this.props.btnStyle}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          this.props.onClick(e);
        }}
      >
        {this.props.value}
      </button>
    );
  }
}

CustomLink.defaultProps = {
  btnStyle: {
    color: 'inherit',
  },
};
