import React from 'react';
import './index.scss';
import { useCreativeAnalyticsStore } from '../../../selectors';
import { useDispatch } from 'react-redux';
import { setBannerDisplay } from '../../../store/CreativeAnalytics/creativeAnalyticsSlice';

export const Banner = () => {
  const { bannerDisplay } = useCreativeAnalyticsStore();
  const dispatch = useDispatch();

  const handleClose = () => dispatch(setBannerDisplay(null));

  if (!bannerDisplay) return null;

  return (
    <div className="banner">
      {bannerDisplay}
      <button className="btn-reset" onClick={handleClose}>
        <i className="fa fa-xmark" />
      </button>
    </div>
  );
};
