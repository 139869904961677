import React from "react";
import { toPercent, toPercentRounded } from "../../../utils/numbers";
import { darwinDateToDate } from "../../../utils/darwin-dates";
import { isLastMonth } from "./utils";

const ReportDescription = ({ defaultCategories, selectedReport, combinedPercentage }) => {
  let prep = "";
  let dem = "these";
  let range = "Last month";
  const singular = defaultCategories.length === 1;

  if (!isLastMonth(selectedReport.start_date, selectedReport.end_date)) {
    prep = "From";
    range = `${darwinDateToDate(
      selectedReport.start_date
    ).toLocaleDateString()} - ${darwinDateToDate(
      selectedReport.end_date
    ).toLocaleDateString()}`;
  }

  if (singular) dem = "this";
  
  if (selectedReport.error) return null;

  return (
    <p className="impact-report__description">
      {prep} {range}, {dem} {defaultCategories.length} categor
      {`${singular ? "y" : "ies"}`} explained{" "}
      <span>
        {combinedPercentage} of your creative
        performance.
      </span>
    </p>
  );
};

export default ReportDescription;
