import React, { useContext, createContext, useState } from 'react';
import PropTypes from 'prop-types';

const GetCtx = createContext(false);
const SetCtx = createContext(null);

export function ImpersonateProvider({ children }) {
  const [impersonate, setImpersonate] = useState(false);

  const toggle = () => setImpersonate((prev) => !prev);

  return (
    <GetCtx.Provider value={impersonate}>
      <SetCtx.Provider value={toggle}>
        {children}
      </SetCtx.Provider>
    </GetCtx.Provider>
  );
}

ImpersonateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useGetImpersonate() {
  return useContext(GetCtx);
}

export function useSetImpersonate() {
  return useContext(SetCtx);
}
