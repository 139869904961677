import { useEffect } from 'react';
import { useSearchParams } from '../../../components/Hooks/search-params';
import { useSelector } from 'react-redux';

export const setDefaultPrimaryMetric = (
  default_primary_metric: string,
  isROAS: boolean
) => {
  if (!!default_primary_metric) return default_primary_metric;

  return isROAS ? 'roas' : 'cpa';
};

export const useUrlParams = () => {
  const { default_primary_metric, isROAS, isOrganicView } = useSelector(
    ({ ViewSettings: { default_primary_metric, isROAS, isOrganicView } }) => ({
      default_primary_metric,
      isROAS,
      isOrganicView,
    })
  );
  const { getParams } = useSearchParams();
  const params = getParams(
    'spendThreshold',
    'creativeCount',
    'depth',
    'fixed',
    'sort',
    'min',
    'primary_metric',
    'category',
    'display'
  );
  const { spendThreshold } = params;
  const creativeCount = params.creativeCount || '1';
  const recDepth = params.depth || '1';
  const fixedLabels = params.fixed || [];
  const sort = params.sort || 'spend';
  const min = params.min || '0';
  const primary_metric =
    params.primary_metric ||
    setDefaultPrimaryMetric(default_primary_metric, isROAS);
  isOrganicView ? 'All Visuals' : '';

  return {
    spendThreshold,
    creativeCount,
    recDepth,
    fixedLabels,
    sort,
    min,
    primary_metric,
    category: params.category || (isOrganicView ? 'All Visuals' : ''),
    display:
      params.display || (isOrganicView ? 'performance_frequency' : 'dna_table'),
  };
};
