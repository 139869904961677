import { getCategories } from '../SimpleImpact';
import { CategoryOptionType } from './types';

export const getCategoryOptions = (
  dnaReportData,
  categoriesLookup,
  simpleImpactData = []
): CategoryOptionType[] => {
  let categories = [];

  if (dnaReportData) {
    categories = getCategories(dnaReportData);
  } else if (categoriesLookup) {
    categories = Object.keys(categoriesLookup);
  }

  return categories
    .map((category) => {
      const maybe = simpleImpactData?.find(
        (c) => c['Category Name'] === category
      );
      return {
        category_name: category,
        importance: maybe?.['Aggregated Importance'] ?? 0,
      };
    })
    .sort((a, b) => b.importance - a.importance);
};
