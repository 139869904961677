import React from 'react';
import './Table.scss';
import '../PerformanceFrequency/FrequencyTable.scss';

const CopyTable = ({ tableInstance, ColumnPicker, ShareButton, title }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { filters, selectedRowIds },
  } = tableInstance;

  return (
    <div className="copy-report-table">
      <div className="d-flex align-items-center">
        {ColumnPicker}
        {ShareButton ?? null}
      </div>
      <div className="report">
        {title && (
          <div className="report-header">
            <div className="report__title">{title}</div>
          </div>
        )}
        <table {...getTableProps()} className={`frequency-table`}>
          <thead className="frequency-table__header">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      title={column.Header}
                      className={
                        column.isSorted
                          ? column.isSortedDesc
                            ? 'sorted-desc'
                            : 'sorted-asc'
                          : ''
                      }
                    >
                      <div className="table-header__text">
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' 🔽'
                              : ' 🔼'
                            : ''}
                        </span>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          {rows.length ? (
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);

                return (
                  <tr className="frequency-table__row" {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          className={`frequency-table__cell`}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <div className="d-flex flex-column align-items-center justify-content-center mx-auto w-100 mt-5">
              <div className="no-data-face">
                <span></span>
                <span></span>
              </div>
              <h5 className="my-0 ml-3 mt-3">No data to display.</h5>
            </div>
          )}
        </table>
      </div>
    </div>
  );
};

export default CopyTable;
