import React from 'react';
import Tooltip from '../../../components/Tooltip';

const DeleteButton = ({ onClick }) => {
  return (
    <Tooltip content="Remove this category from dashboard">
      <button onClick={onClick} className="delete-btn">
        <i className="fa fa-xmark" style={{ color: '#fff' }}></i>
      </button>
    </Tooltip>
  );
};

export default DeleteButton;