import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import CreativeAnalytics from './CreativeAnalytics';
import { useMetadataApi } from '../DNA/api';
import { useFetchPresetsApi } from './Presets/api';
import AnalyticsApiProvider from './contexts';
import { useUrlFeature } from './LeftDisplay/components/Menu/hooks';
import { PivotReport } from './PivotReport';

export const Wrapper = (props) => {
  useMetadataApi();
  const fetchPresetsData: () => void = useFetchPresetsApi();
  const { page } = useUrlFeature();
  const { isSettingsOpen } = props;

  useEffect(() => {
    console.log('wrapper');
  }, []);

  return (
    <AnalyticsApiProvider fetchPresetsData={fetchPresetsData}>
      {!page && !isSettingsOpen ? (
        <PivotReport />
      ) : (
        <CreativeAnalytics {...props} display={page || ''} />
      )}
    </AnalyticsApiProvider>
  );
};
