import { DARWIN_BLUE, DARWIN_GRAY } from '../../constants';

export const formatMetricType = (type) => {
  switch (type) {
    case 'share_of_voice':
      return 'Share of Voice';

    default:
      return type.toUpperCase();
  }
};

export const formatMetricTypeAbbrv = (type) => {
  switch (type) {
    case 'share_of_voice':
      return 'SOV';

    default:
      return type.toUpperCase();
  }
};

export const fontColor = (active) => (active ? '#eeeeee' : '#666666');

export const activeBG = (active) =>
  active ? { backgroundColor: '#F1F5FA' } : { backgroundColor: '#fff' };

export const activeBGTesting = (active) =>
  active
    ? { backgroundColor: DARWIN_BLUE, color: '#eeeeee' }
    : { backgroundColor: '#f5f5f5', color: DARWIN_GRAY };
