import React from "react";
import { DARWIN_NAVY } from "../../../../constants";

export const BetaIcon = () => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7429 11.9419C11.8024 12.0397 11.8471 12.1396 11.8768 12.2416C11.9066 12.3436 11.9214 12.452 11.9214 12.5668C11.9214 12.7369 11.8896 12.8963 11.8258 13.0451C11.762 13.1939 11.6749 13.3235 11.5643 13.4341C11.4538 13.5446 11.3242 13.6317 11.1754 13.6955C11.0266 13.7593 10.8671 13.7912 10.6971 13.7912H2.1458C1.97575 13.7912 1.81633 13.7593 1.66753 13.6955C1.51874 13.6317 1.38908 13.5446 1.27855 13.4341C1.16802 13.3235 1.08087 13.1939 1.0171 13.0451C0.953332 12.8963 0.921448 12.7369 0.921448 12.5668C0.921448 12.452 0.934201 12.3436 0.959709 12.2416C0.985216 12.1396 1.02985 12.0397 1.09362 11.9419L4.72203 5.61608C4.76454 5.55657 4.7858 5.4843 4.7858 5.39927V1.54768H3.96956V0.731445H8.86696V1.54768H8.05072V5.39927C8.05072 5.48004 8.07198 5.55444 8.11449 5.62246L11.7429 11.9419ZM5.60203 5.39927C5.60203 5.62459 5.54464 5.83502 5.42985 6.03058L3.31913 9.71H9.51739L7.40667 6.03058C7.29188 5.83502 7.23449 5.62459 7.23449 5.39927V1.54768H5.60203V5.39927Z"
        fill={DARWIN_NAVY}
      />
    </svg>
  );
};
