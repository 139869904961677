import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ children, render, loggedIn, ...rest }) => {
  const toRender = !children
    ? render
    : ({ location }) =>
        loggedIn ? (
          children
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        );

  return <Route {...rest} render={toRender} />;
};

PrivateRoute.propTypes = {
  loggedIn: PropTypes.bool,
  component: PropTypes.object,
};

export default PrivateRoute;
