import React, { useRef, useState } from 'react';
import './styles.scss';
import { useClickOutside } from '../../hooks/useClickOutside';

type Option = {
  title: string;
  description?: string;
  value: string;
};

type SelectMenuProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  options: Option[];
  onSelect: (value: string) => void;
  menuStyle: React.CSSProperties;
};

export const SelectMenu = ({
  isOpen,
  setIsOpen,
  options,
  onSelect,
  menuStyle = {},
}: SelectMenuProps) => {
  if (!isOpen) return null;

  const ref = useRef(null);
  
  useClickOutside(ref, () => {
    setIsOpen(false);
  });

  const handleClick = (value) => {
    onSelect(value);
    setIsOpen(false);
  };

  return (
    <ul className="select-menu" style={menuStyle} ref={ref}>
      {options.map((opt) => {
        return (
          <li onClick={() => handleClick(opt.value)}>
            <h3 className={`${!!opt.description ? 'mb-2' : 'mb-0'}`}>
              {opt.title}
            </h3>
            {opt.description && <p>{opt.description}</p>}
          </li>
        );
      })}
    </ul>
  );
};

export const SelectMenuWrapper = ({ children }) => {
  return (
    <span style={{ position: 'relative', cursor: 'pointer' }}>{children}</span>
  );
};
