import { useState } from 'react';

export function useAsyncCustomCategoriesApi() {
  const [processes, setProcesses] = useState([]);
  const [ongoingBatched, setOngoingBatched] = useState(false);

  const createAsyncProcess = (newProcess) => {
    if ((!processes.some((process) => process.category === newProcess.category))) {
      setProcesses((prev) => [...prev, newProcess]);
    }
  };

  const removeAsyncProcess = (category) => {
    if (category) {
      setProcesses((prev) => prev.filter((process) => process.category !== category));
    } else {
      setProcesses([]);
    }
  }
  
  return {
    processes,
    createAsyncProcess,
    removeAsyncProcess,
    ongoingBatched,
    setOngoingBatched,
  }
}