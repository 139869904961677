import React from 'react';
import { useCreativeAnalyticsGet } from '../contexts';
import { useViewSettings } from '../../../selectors';

export const useCopyColumnOptions = () => {
  const { isROAS } = useCreativeAnalyticsGet();
  const { isOrganicView } = useViewSettings();

  return [
    {
      label: 'Name',
      value: 'breakdown_value',
    },
    {
      label: isROAS ? 'ROAS' : 'CPA',
      value: isROAS ? 'roas' : 'cpa',
    },
    ...(!isOrganicView
      ? [
          {
            label: 'Spend',
            value: 'total_spend',
          },
          {
            label: 'Impressions',
            value: 'total_impressions',
          },
        ]
      : []),
    {
      label: 'CPM',
      value: 'cpm',
    },
    {
      label: 'CPC',
      value: 'cpc',
    },
    {
      label: 'CTR',
      value: 'ctr',
    },
    {
      label: 'Total Link Clicks',
      value: 'total_link_clicks',
    },
    {
      label: 'Results',
      value: 'total_results',
    },
    {
      label: '# Unique Ad Names',
      value: 'ad_name',
    },
    {
      label: '# Unique Ad Ids',
      value: 'ad_id',
    }
  ];
};
