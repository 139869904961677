/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */

import React from 'react';
import PropTypes from 'prop-types';

export default function SimpleTree({ data, render }) {
  return (
    <>
      {data.map((props, i) => (
        <TreeItem key={`${props.value}-${i}`} {...props} render={render} />
      ))}
    </>
  );
}

SimpleTree.defaultProps = {
  data: [],
};

SimpleTree.propTypes = {
  render: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any,
      rest: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.string,
      })),
    }),
  ),
};

function TreeItem({
  label, value, rest, render, ...extraProps
}) {
  const toRender = render({
    value, label, rest, ...extraProps,
  });

  if (!rest) {
    return toRender;
  }
  const children = [];

  rest.forEach((child, i) => {
    children.push(
      <div className="simple-tree-child">
        <TreeItem key={`${child.value}-${i}`} {...child} render={render} />
      </div>,
    );
  });

  return (
    <details className="simple-tree-root">
      <summary className="simple-tree-summary">{toRender}</summary>
      <div className="simple-tree-children">{children}</div>
    </details>
  );
}

TreeItem.defaultProps = {
  rest: null,
};

TreeItem.propTypes = {
  rest: PropTypes.array,
  render: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
};
