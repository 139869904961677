import { getTotal } from './getTotal';
import { getAggregate } from './getAggregate';
import { getAverage } from './getAverage';
import { findMedian, getMedian } from './getMedian';
import { getTotalImpressions } from '../../../utils/getTotalImpressions';

const calculateAggregateValues = (
  visuals: VisualObject[],
  customEvents,
  numOfAllVisuals,
  totalImpressions
) => {
  const results = {};

  customEvents.forEach((event) => {
    const numeratorKey = event.numerator;
    const denominatorKey = event.denominator;

    let totalNumerator = 0;
    let totalDenominator = 0;

    const impressions = getTotalImpressions(visuals);
    const share_of_impressions = impressions / totalImpressions;
    const share_of_assets = visuals.length / numOfAllVisuals;

    if (event.calculation === 'share_of_impressions') {
      results[`${event.name}`] = isFinite(share_of_impressions)
        ? share_of_impressions
        : 0;
    } else if (event.calculation === 'share_of_assets') {
      results[`${event.name}`] = isFinite(share_of_assets)
        ? share_of_assets
        : 0;
    } else {
      visuals.forEach((visual) => {
        const numeratorValue = visual[numeratorKey];
        const denominatorValue = visual[denominatorKey];

        if (
          typeof numeratorValue === 'number' &&
          typeof denominatorValue === 'number'
        ) {
          totalNumerator += numeratorValue;
          totalDenominator += denominatorValue;
        }
      });

      if (totalDenominator === 0) {
        results[`${event.name}`] = 0;
      } else {
        results[`${event.name}`] = totalNumerator / totalDenominator;
      }
    }

    results[`Median ${event.name}`] = findMedian(
      visuals.map((visual) => visual[event.name] || 0)
    );
  });

  return results;
};

export const getCustomEventsValues = ({
  customEvents,
  visuals,
  numOfAllVisuals,
  totalImpressions,
}): {} => {
  let customEventsValues = {};

  try {
    if (customEvents?.length) {
      customEventsValues = calculateAggregateValues(
        visuals,
        customEvents,
        numOfAllVisuals,
        totalImpressions
      );
    }
  } catch (e) {
    console.error(e);
    console.log(e);
  }

  return customEventsValues;
};
