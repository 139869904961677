
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  AD_NAME,
  ADSET_NAME,
  CREATIVE_MATRIX,
  AD_STUDY,
  COLOR,
  AD_LABELS,
  LABEL_MATRIX,
  PERFORMANCE_FREQUENCY,
  RECOMMENDATIONS,
} from '../../store/CreativeReports/constants';
import { SpendThresholdPicker } from '../../components/Pickers/SpendThreshold';
import { VisualTypePicker } from '../../components/Pickers/VisualType';
import { StudyTypePicker } from '../../components/Pickers/AdStudyType';
import { AdLabelTypePicker } from '../../components/Pickers/AdLabelType';
import { CreativeCountPicker } from '../../components/Pickers/CreativeCount';
import { BasicLoading } from '../../components/Helpers/Loading';
import FooterWrapper from '../../components/ReportChartFooter';

import { AdStudyButton } from './StudyModal';
import Dashboards from './Dashboards';
import Chart from './Charts';
import Displays from './Right-Displays';
import { useDimensions } from '../../components/Hooks/dom-utils';
import { useUrlProps } from './report-hooks';
import { HISTORY, REPORTS_INDEX } from './constants';

import { LayoutContainer } from './Helpers';
import { useSelector } from 'react-redux';
import Tooltip from '../../components/Tooltip';

const HEIGHT = { height: '70vh' };

function useInsightsView() {
  return useSelector(({ CreativeReports: { insights_view } }) => {
    return {
      insightsView: insights_view
    }
  });
}

function useShowRequest() {
  return useSelector(({ CreativeReports: { show_request } }) => {
    return {
      showRequest: show_request
    }
  });
}

const Layout = ({
  handleSelect,
  selectedId,
  selected,
  percent_difference,
  data,
  ad_ids,
  chartType,
  ChartPicker,
  showPreviews,
  isDirectMail,
  isPF,
  asset,
}) => {
  const [visuals, { height }, $visuals] = useDimensions({ liveMeasure: false });
  const hasData = data.length > 0;

  const { report_type, display } = useUrlProps();
  const { showRequest } = useShowRequest();

  let mainDisplayClass = 'col-12 col-md-7 ci-chart creative-reports__layout-display bg-white';
  let rightDisplayClass = 'col-12 col-md-4 pl-0 pr-0 creative-reports__layout-display';

  const ChartDisplay = !!$visuals && hasData ? (
    <div
      style={{
        height: '100%',
        paddingBottom: '24px',
        position: 'relative',
      }}
      id="report-chart-container"
    >
      <Chart
        dimensions={{ height: height * (1 - (0.075 + 0.15)) - 24 }}
        id="creative-report-visual"
        chartType={chartType}
        data={data}
        reportType={!isPF ? report_type : display}
        handleSeriesClick={handleSelect}
        selectedId={selectedId}
      />
    </div>
  ) : report_type === AD_STUDY ? null : (
    <p>No data available</p>
  );

  return (
    <React.StrictMode>
      <div className={`row creative-reports__layout${showRequest ? ' show-request' : ''}`}>
        <div
          ref={visuals}
          className={mainDisplayClass}
          style={
            // eslint-disable-next-line no-nested-ternary
            chartType === LABEL_MATRIX
              ? {
                height: '43rem',
                padding: '0',
              } : chartType === 'label_settings' || report_type === AD_LABELS
                ? {
                  ...HEIGHT,
                  padding: '0',
                } : {
                  ...HEIGHT,
                  padding: '0',
                }
          }
        >
          {height === 0 ? null : (
            <>
              <div
                style={
                  report_type === CREATIVE_MATRIX || chartType === 'label_matrix' || chartType === 'label_settings' || chartType === 'scatter'
                    ? {}
                    : { height: `${height * 0.15}px` }
                }
              >
                <Title selectedId={selectedId} reportType={!isPF ? report_type : display} />
              </div>

              {ChartDisplay}

              {!isPF && (
                <FooterWrapper height={height}>
                  <div className={`d-flex flex-row align-items-center h-100 ${chartType === 'label_settings' ? 'justify-content-end' : 'justify-content-between'}`}>
                    {chartType !== 'label_settings' && (
                      <div className="d-flex flex-row">
                        <SpendThresholdPicker chartType={chartType} />

                        {report_type === AD_NAME ? (
                          <VisualTypePicker />
                        ) : report_type === AD_STUDY ? (
                          <StudyTypePicker />
                        ) : null}

                        {report_type === AD_LABELS && (
                          <>
                            {chartType === LABEL_MATRIX && <AdLabelTypePicker />}
                            <CreativeCountPicker chartType={chartType} />
                          </>
                        )}
                      </div>
                    )}
                    {/* {report_type !== CREATIVE_MATRIX && ChartPicker} */}
                  </div>
                </FooterWrapper>
              )}
            </>
          )}
        </div>

        <div
          className={rightDisplayClass}
          style={
            chartType === 'label_matrix'
              ? {
                height: '43rem',
                backgroundColor: '#fff',
              } : {
                ...HEIGHT,
                backgroundColor: '#fff',
              }
          }
        >
          <Displays
            valueId={selectedId}
            percent_difference={percent_difference}
            values={selected}
            ad_ids={ad_ids}
            report_type={report_type}
            asset={asset}
          />
        </div>
      </div>
      {selectedId !== '' && showPreviews && <Dashboards />}
    </React.StrictMode>
  );
};

Layout.defaultProps = {
  data: [],
  selected: [],
  percent_difference: '',
};

Layout.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  selectedId: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.array,
  ad_ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  percent_difference: PropTypes.string,
  chartType: PropTypes.string.isRequired,
  ChartPicker: PropTypes.node.isRequired,
};

export default Layout;

const subTitle = (report) => {
  switch (report) {
    case 'keyword':
      return 'Keywords are extracted from above the fold copy';
    case 'trigger':
      return 'Triggers are groups of words extracted from above the fold copy';
    case 'ad_labels':
      return 'Supervised machine learning from your ad images and videos';

    default:
      return null;
  }
};

const Title = ({ selectedId, reportType }) => {
  if (
    (selectedId === '' && reportType !== AD_STUDY)
    || reportType === HISTORY
  ) {
    return null;
  }
  switch (reportType) {
    case AD_LABELS:
      return (
        <>
        </>
      );
    case AD_NAME:
    case ADSET_NAME:
      return (
        <>
          <Tooltip
            content={selectedId}
            delay={[500,0]}
          >
            <h3 className="creative-reports__chart-title">{REPORTS_INDEX[reportType]?.title}<span>{selectedId}</span></h3>
          </Tooltip>
        </>
      );

    case AD_STUDY:
      return (
        <div className="d-flex flex-row justify-content-between">
          {selectedId === '' ? (
            <p className="align-self-center m-0">
              There are no current ad studies. Click "Create Study" to begin.
            </p>
          ) : (
            <h4 title={selectedId}>
              {selectedId.length > 30
                ? `${selectedId.slice(0, 27)}...`
                : selectedId}
            </h4>
          )}

          <AdStudyButton />
        </div>
      );

    case COLOR:
      const displayAs = selectedId.toUpperCase();

      return (
        <div className="d-flex flex-row">
          <h3 title={displayAs}>
            {displayAs}
            {' '}
          </h3>
          <div
            className="ml-2"
            style={{
              backgroundColor: selectedId,
              border: '1px solid #666666',
              borderRadius: '3px',
              height: '2rem',
              width: '2rem',
              marginTop: '0.15rem',
            }}
          />
        </div>
      );

    default:
      const maybeSub = subTitle(reportType);

      return (
        <>
          <h3 title={selectedId}>
            {selectedId != undefined && selectedId.length > 30
              ? `${selectedId.slice(0, 27)}...`
              : selectedId}
          </h3>
          {!!maybeSub && <div className="font-weight-light">{maybeSub}</div>}
        </>
      );
  }
};

Title.propTypes = {
  selectedId: PropTypes.string.isRequired,
  reportType: PropTypes.string.isRequired,
};

export const LayoutPlaceholder = () => (
  <div className="row mt-2 mx-0 mx-md-5 mb-2">
    <div
      className="col-7 mr-2"
      style={{
        ...HEIGHT,
        padding: '1.25rem',
        backgroundColor: '#ffffff',
        boxShadow: '0 1px 3px 1px #dddddd',
      }}
    >
      <BasicLoading />
    </div>

    <div
      className="col-4 ml-2"
      style={{
        ...HEIGHT,
        padding: '1.25rem',
        backgroundColor: '#ffffff',
        boxShadow: '0 1px 3px 1px #dddddd',
      }}
    >
      <BasicLoading />
    </div>
  </div>
);
