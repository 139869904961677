import React, { useEffect, useState } from "react";
import Button from "../../../components/Button";
import { useClustersContext } from "./contexts/ClustersContext";
import { RetryButton } from "../Recommendations/components/RetryButton";

type displayTypes = "select" | "loading" | "error" | "success";

const ChangeClusters = () => {
  const {
    changeClustersLoading,
    changeClustersError,
    changeClustersData,
    handleChangeClusters,
    generalLoading,
    numberOfClusters,
    setNumberOfClusters,
    setShouldRefresh,
    preprocessSuccess,
    changeClustersSuccess,
    clustersSuccess,
  } = useClustersContext();

  const [display, setDisplay] = useState<displayTypes>("select");
  const MIN = 4;
  const MAX = 32;
  const invalid =
    !numberOfClusters ||
    parseInt(numberOfClusters) < MIN ||
    parseInt(numberOfClusters) > MAX;

  const disabled =
    changeClustersLoading || invalid;

  useEffect(() => {
    if (changeClustersError) {
      setDisplay("error");
    }
    if (changeClustersLoading) {
      setDisplay("loading");
    }
  }, [changeClustersError, changeClustersLoading]);

  useEffect(() => {
    if (changeClustersData) {
      if (changeClustersSuccess) {
        setDisplay("success");

        if (clustersSuccess) {
          setShouldRefresh(true);
        }
      } else {
        console.log(changeClustersData.body);
        setDisplay("select");
      }
    }
  }, [changeClustersData]);

  const updateSelection = (e) => {
    setNumberOfClusters(e.target.value);
  };

  const Select = () => {
    return (
      <span>
        Retrain the dataset to divide the creative results amongst
        <input
          type="number"
          value={numberOfClusters}
          onChange={(e) => updateSelection(e)}
          className="number-input"
          min={MIN}
          max={MAX}
          onBlur={() => {
            if (invalid) {
              setNumberOfClusters("4");
            }
          }}
        />
        new visual clusters.
      </span>
    );
  };

  const RetryButton = () => {
    return (
      <Button
        className="ml-2"
        appearance="subtle"
        onClick={() => setDisplay("select")}
      >
        <i className="fa fa-edit" />
      </Button>
    );
  };

  let Display = <Select />;

  if (display === "loading") {
    Display = (
      <span>Updating the number of clusters. Check back in 5 minutes.</span>
    );
  }

  if (display === "error") {
    <span>
      There was an error.
      <RetryButton />
    </span>;
  }

  if (display === "success") {
    Display = (
      <div className="d-flex align-items-center">
        <i className="fa-solid fa-check mr-2"></i>
        <span>
          Number of clusters successfully updated to {numberOfClusters}.
        </span>
        <RetryButton />
      </div>
    );
  }

  return (
    <div className="clusters__section change-clusters">
      <h2>Change Number of Clusters</h2>
      <div className="d-flex align-items-center">
        <Button
          onClick={() => handleChangeClusters(numberOfClusters)}
          loading={changeClustersLoading}
          disabled={disabled}
        >
          Go
        </Button>
        <p>{Display}</p>
      </div>
    </div>
  );
};

export default ChangeClusters;
