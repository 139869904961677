import React, { useEffect, useRef } from 'react';
import { downloadAsset } from '../../../CreativeStudio/AssetSelect/utils';

const IterationCard = ({ data }) => {
  const {
    reason_chosen,
    iteration_data,
    iteration_asset_url: asset_url,
    asset_type,
  } = data;
  const vidRef = useRef();

  const handleClick = () => {
    window.open(asset_url, '_blank').focus();
  };

  useEffect(() => {
    vidRef.current?.load();
  }, [asset_url]);

  let preview = <div></div>;

  if (asset_type === 'image') {
    preview = <img onClick={handleClick} src={asset_url} alt="" />;
  } else if (asset_type === 'video') {
    preview = (
      <video
        onClick={handleClick}
        key={asset_url}
        autoPlay
        loop
        muted
        plays-inline="true"
        ref={vidRef}
      >
        <source src={asset_url} type="video/mp4" />
      </video>
    );
  }

  return (
    <div className="iteration-card">
      <div className="iteration-card__section">
        <div className="iteration-card__section-title">Why this asset was chosen</div>
        <p>{reason_chosen}</p>
      </div>
      <div className="iteration-card__section">
        <div className="iteration-card__section-title">Data driven reasons</div>
        <p>{iteration_data}</p>
      </div>
      <div className="iteration-card__section">
        <div className="iteration-card__section-title">Asset to test</div>
        <div className="iteration-card__preview">{preview}</div>
      </div>
    </div>
  );
};

export default IterationCard;
