import React, { useState, useEffect } from 'react';
import { BrowserIcon } from '../../../Explorer/icons/ToolbarIcons';
import { useVariationContext } from '../../contexts/variationContext';
import { FavoriteIcon } from '../../../../../components/Icons';
import { TOGGLE_FAVORITE } from '../../hooks/useVariationState';
import Visual from './Visual';
import './Card.scss';
import { formatMetricValue } from '../../../../DNA/utils';
import { capitalizeFirst } from '../../../ReportCard/utils';

type favoriteStatusTypes = 'just-saved' | 'just-unsaved' | 'normal';

const Card = ({
  type,
  data,
  title,
  subtitle,
  handleOpenDrawer,
  loading,
  isROAS,
  rowIndex,
  variantIndex,
  isSharedReport,
  rowMetric,
  primaryMetricLabel,
  disableDrawer,
}) => {
  const { variationDispatch } = useVariationContext();
  const isBaseVisual = type === 'base';
  const [favoriteStatus, setFavoriteStatus] =
    useState<favoriteStatusTypes>('normal');

  const handleToggleFavorite = () => {
    if (data.favorite) {
      setFavoriteStatus('just-unsaved');
    } else {
      setFavoriteStatus('just-saved');
    }

    variationDispatch({
      type: TOGGLE_FAVORITE,
      rowIndex,
      variantIndex,
      favorite: data.favorite ? false : true,
    });

    setTimeout(() => {
      setFavoriteStatus('normal');
    }, 2000);
  };

  return (
    <div
      className={`variation__card variation__card--${type} ${
        loading || !data ? 'loading' : ''
      }`}
      onClick={() => {
        if (data || !isBaseVisual || loading) return;
        handleOpenDrawer(rowIndex);
      }}
    >
      {!!data && !isSharedReport && (
        <button
          className={data.favorite ? 'active' : ''}
          style={{ position: 'absolute', top: 16, right: 16 }}
          onClick={() => {
            if (isBaseVisual) {
              handleOpenDrawer(rowIndex);
            } else {
              handleToggleFavorite();
            }
          }}
          disabled={isBaseVisual && disableDrawer}
        >
          {isBaseVisual ? (
            <BrowserIcon height={20} width={20} />
          ) : (
            <FavoriteIcon
              onClick={null}
              animateSave={favoriteStatus === 'just-saved'}
              animateUnsave={favoriteStatus === 'just-unsaved'}
              isSaved={data.favorite}
            />
          )}
        </button>
      )}
      <div className="card-title">{title}</div>
      <Visual
        {...{ data, loading, isBaseVisual, handleOpenDrawer, disableDrawer }}
      />
      {!!data && (
        <div
          className="card-subtitle"
          style={{ opacity: !!data && (!loading || isBaseVisual) ? 1 : 0 }}
        >
          {/* <div className="performance">
            {`${!isBaseVisual ? 'Predicted' : ''} ${primaryMetricLabel}: `}
            <span>
              {!!data
                ? formatMetricValue(
                    rowMetric,
                    data.predicted_performance ?? data.performance,
                    isROAS
                  )
                : ''}
            </span>
          </div> */}
          {subtitle && (
            <span>
              Confidence:{' '}
              <span className="confidence">{capitalizeFirst(subtitle)}</span>
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default Card;
