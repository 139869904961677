import { useMemo, useState } from 'react';
const _ = require('lodash');
import { useCreativeAnalyticsGet } from '../contexts';
import { useAdditionalMetricsColumns } from '../PerformanceFrequency/hooks/useAdditionalMetricsColumns';
import { useCustomEvents, useGaEnabled } from '../selectors';
import { useViewSettings } from '../../../selectors';
import {
  useColumns,
  useCustomColumnHeaders,
  useCustomEventsHeaders,
  dropROASColumns,
  dropCPAColumns,
  dropROASColumnsP1,
  dropROASColumnsP2,
  dropCPAColumnsP1,
  dropCPAColumnsP2,
  dropNonOrganicColumns,
  dropNonEmailColumns,
} from '../PerformanceFrequency/Columns';
import { useCustomIntegratedMetricsColumns } from '../PerformanceFrequency/hooks/useCustomIntegratedMetricsColumns';
import { CUSTOM_SCORING_METRICS } from '../PerformanceFrequency/constants';
import { useCustomScoringColumns } from '../PerformanceFrequency/hooks/useCustomScoringColumns';

const getColumns = ({
  isROAS,
  period2,
  isCompare,
  columnDropdownOptions,
  customColumnHeaders,
  customEventsHeaders,
  additionalMetricsColumns,
  customIntegratedMetricsColumns,
  customEvents,
  columnData,
  gaEnabled,
  isOrganicView,
  isEmailView,
  selectedColumns,
  customScoringData,
}) => {
  let result = [];

  const filterCols = (fn) => {
    const gaCols = ['ga_aggregate_ctr', 'ga_average_ctr', 'ga_median_ctr'];

    let customEventsCols = customEvents
      ? customEvents.map((event) => {
          return [
            'aggregate_' + event.calculation,
            'average_' + event.calculation,
            'median_' + event.calculation,
          ];
        })
      : [];
    customEventsCols = _.flatten(customEventsCols);

    if (!gaEnabled) {
      return columnData
        .filter(fn)
        .filter(({ accessor }) => !gaCols.includes(accessor));
    } else {
      return columnData.filter(fn);
    }
  };

  if (isROAS) {
    if (period2) {
      if (isCompare) {
        result = filterCols(dropCPAColumnsP2);
      } else if (!isCompare) {
        result = filterCols(dropCPAColumnsP1);
      }
    } else {
      result = filterCols(dropCPAColumns);
    }
  } else {
    if (period2) {
      if (isCompare) {
        result = filterCols(dropROASColumnsP2);
      } else if (!isCompare) {
        result = filterCols(dropROASColumnsP1);
      }
    } else {
      result = filterCols(dropROASColumns);
    }
  }

  let columnOrder = columnDropdownOptions
    .map((x) => x.value)
    .filter((x) => x !== 'visuals' && x !== 'planning');

  columnOrder = ['name', ...columnOrder, 'visuals', 'planning', 'options'];

  const customScoringColumns =
    customScoringData && customScoringData.length
      ? useCustomScoringColumns()
      : [];

  const combined = [
    ...result,
    ...customScoringColumns,
    ...customColumnHeaders,
    ...customEventsHeaders,
    ...additionalMetricsColumns,
    ...customIntegratedMetricsColumns,
  ].filter(
    (v, i, a) =>
      // remove duplicates
      a.findIndex(
        (v2) => v2.accessor.toLowerCase() === v.accessor.toLowerCase()
      ) === i
  );

  if (isOrganicView) {
    result = combined.filter(dropNonOrganicColumns);
  } else if (isEmailView) {
    result = combined.filter(dropNonEmailColumns);
  } else {
    result = combined;
  }

  const sorted = result.sort(
    (a, b) => columnOrder.indexOf(a.accessor) - columnOrder.indexOf(b.accessor)
  );

  return sorted.filter(
    (col) => col.accessor === 'name' || selectedColumns.includes(col.accessor)
  );
};

export const useFilteredColumns = (isCompare = false, selectedColumns) => {
  const {
    columnDropdownOptions,
    isDirectMail,
    period2,
    isROAS,
    isOrganicView,
    isEmailView,
    customScoringData,
    isNoom,
  } = useCreativeAnalyticsGet();
  const columnData = useColumns();
  const customColumnHeaders = useCustomColumnHeaders(isDirectMail);
  const gaEnabled = useGaEnabled();
  const customEvents = useCustomEvents();
  const customEventsHeaders = useCustomEventsHeaders(customEvents);
  const additionalMetricsColumns = useAdditionalMetricsColumns();
  const isUBeauty = additionalMetricsColumns.some(
    (col) => col.accessor === 'value_Rockerbox_ROAS'
  );
  const customIntegratedMetricsColumns = !isUBeauty
    ? useCustomIntegratedMetricsColumns({ isNoom })
    : [];

  const columns = useMemo(() => {
    return getColumns({
      isROAS,
      period2,
      isCompare,
      columnDropdownOptions,
      customColumnHeaders,
      customEventsHeaders,
      additionalMetricsColumns,
      customIntegratedMetricsColumns,
      customEvents,
      columnData,
      gaEnabled,
      isOrganicView,
      isEmailView,
      selectedColumns,
      customScoringData,
    });
  }, [
    isROAS,
    period2,
    isCompare,
    columnDropdownOptions,
    customColumnHeaders,
    customEventsHeaders,
    additionalMetricsColumns,
    customIntegratedMetricsColumns,
    customEvents,
    columnData,
    gaEnabled,
    isOrganicView,
    isEmailView,
    selectedColumns,
    customScoringData,
  ]);

  return columns;
};
