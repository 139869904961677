export const getReach = (visuals) => {
  if (!visuals.length) {
    return 0;
  }

  const spend = visuals.reduce((result, visual) => {
    result += visual.spend ?? 0;
    return result;
  }, 0);

  const reach = visuals.reduce((result, visual) => {
    result += visual.reach ?? 0;
    return result;
  }, 0);

  return reach > 0 ? (spend / reach) * 1000 : 0;
};
