import React, { useState } from 'react';
import { useViewId } from '../../../../components/Hooks/view-id';

import './RequestRevision.scss';
import RevisionRequestForm from './RevisionRequestForm';
import { makeRevisionPayload } from './utils';
import { useRevisionRequestApi } from '../api';

const RequestRevision = ({ suggestions, close, api, options }) => {
  const view_id = useViewId();
  const [revision_type, setRevisionType] = useState('darwin_talent');
  const [emailInput, setEmailInput] = useState('');
  const [additional_message, setAdditionalMessage] = useState('');

  const handleSubmit = () => {
    const payload = makeRevisionPayload(
      view_id,
      revision_type,
      emailInput,
      additional_message,
      suggestions,
      options
    );
    api.request(payload);
    close();
  };

  return (
    <div className="request-revision-modal">
      <RevisionRequestForm
        {...{
          revision_type,
          setRevisionType,
          emailInput,
          setEmailInput,
          additional_message,
          setAdditionalMessage,
          handleSubmit,
        }}
      />
    </div>
  );
};

export default RequestRevision;
