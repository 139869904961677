import {
  DARWIN_NAVY,
  DARWIN_NAVY_3,
  DARWIN_NAVY_4,
  DARWIN_NAVY_5,
  DARWIN_NAVY_6,
} from '../../constants';

export const DROPDOWN_STYLES = {
  control: (styles) => ({
    ...styles,
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    // color: '#566577',
    cursor: 'pointer',
    width: '100%',
    maxHeight: 'none',
  }),
  valueContainer: (styles) => ({
    ...styles,
    // overflow: 'visible',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    // color: '#dedede',
    borderLeft: 0,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  container: (provided) => ({
    ...provided,
    borderRadius: '2px',
    minWidth: '150px',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '10px',
    width: 'fit-content',
    minWidth: '200px',
    padding: '0.5rem',
    zIndex: 9999
  }),
  option: (provided) => ({
    ...provided,
    cursor: 'pointer',
    color: '#667394',
    backgroundColor: 'white',
    ':hover': {
      backgroundColor: '#F2F8FF',
    },
    borderRadius: '5px',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#07063e',
    overflow: 'visible',
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: DARWIN_NAVY_6,
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: DARWIN_NAVY_3,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: DARWIN_NAVY_3,
    ':hover': {
      backgroundColor: DARWIN_NAVY_5,
    },
  }),
};
