import React from "react";

export const AddIcon = () => {
  return (
    <svg
      className="add-icon"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5064 6.58862H6.75641V10.3386C6.75641 10.5375 6.67739 10.7283 6.53674 10.869C6.39609 11.0096 6.20532 11.0886 6.00641 11.0886C5.8075 11.0886 5.61673 11.0096 5.47608 10.869C5.33543 10.7283 5.25641 10.5375 5.25641 10.3386V6.58862H1.50641C1.3075 6.58862 1.11673 6.50961 0.976079 6.36895C0.835426 6.2283 0.756409 6.03754 0.756409 5.83862C0.756409 5.63971 0.835426 5.44895 0.976079 5.30829C1.11673 5.16764 1.3075 5.08862 1.50641 5.08862H5.25641V1.33862C5.25641 1.13971 5.33543 0.948945 5.47608 0.808293C5.61673 0.66764 5.8075 0.588623 6.00641 0.588623C6.20532 0.588623 6.39609 0.66764 6.53674 0.808293C6.67739 0.948945 6.75641 1.13971 6.75641 1.33862V5.08862H10.5064C10.7053 5.08862 10.8961 5.16764 11.0367 5.30829C11.1774 5.44895 11.2564 5.63971 11.2564 5.83862C11.2564 6.03754 11.1774 6.2283 11.0367 6.36895C10.8961 6.50961 10.7053 6.58862 10.5064 6.58862Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};
