import React from 'react';
import { DARWIN_NAVY_5 } from '../../../constants';

const CarouselIcon = () => (
  <svg
    width="20"
    height="15"
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1324 1.17004C11.8163 1.17004 12.6574 1.17004 13.1806 1.69326C13.7038 2.21647 13.7038 3.05754 13.7038 4.74147V10.0986C13.7038 11.7825 13.7038 12.6236 13.1806 13.1468C12.6574 13.67 11.8163 13.67 10.1324 13.67H9.23952C7.55559 13.67 6.71452 13.67 6.19131 13.1468C5.66809 12.6236 5.66809 11.7825 5.66809 10.0986V4.74147C5.66809 3.05754 5.66809 2.21647 6.19131 1.69326C6.71452 1.17004 7.55559 1.17004 9.23952 1.17004H10.1324Z"
      stroke={DARWIN_NAVY_5}
      stroke-width="1.125"
    />
    <path
      d="M18.6146 13.67H18.1682C17.5762 13.67 17.0084 13.4349 16.5898 13.0163C16.1712 12.5977 15.936 12.0299 15.936 11.4379V3.40219C15.936 2.81019 16.1712 2.24243 16.5898 1.82382C17.0084 1.40522 17.5762 1.17004 18.1682 1.17004H18.6146M0.757446 13.67H1.20387C1.79588 13.67 2.36363 13.4349 2.78224 13.0163C3.20085 12.5977 3.43602 12.0299 3.43602 11.4379V3.40219C3.43602 2.81019 3.20085 2.24243 2.78224 1.82382C2.36363 1.40522 1.79588 1.17004 1.20387 1.17004H0.757446"
      stroke={DARWIN_NAVY_5}
      stroke-width="1.125"
      stroke-linecap="round"
    />
  </svg>
);

export default CarouselIcon;
