import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { trackEvent } from "../../../store/UserActivity/actions";
import { LIBRARY } from "../../../store/CreativeReports/constants";
import { LOADING, LoadingEvents } from "../../../store/UserActivity/constants";

export const useEventTracking = (libraryView, mode) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (mode === 'library') {
      if (libraryView === LIBRARY) {
        dispatch(trackEvent(LOADING, LoadingEvents.CREATIVE_LIBRARY));
      } else if (libraryView === 'insights') {
        dispatch(trackEvent(LOADING, LoadingEvents.PERFORMANCE_DASHBOARD));
      }
    } else {
      dispatch(trackEvent(LOADING, LoadingEvents.CREATIVE_ANALYTICS));
    }
  }, [libraryView]);
}