import React from "react";

export const SuggestionIcon = () => {
  return (
    <span className="suggestion-icon">
      <svg
        width="12"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.44765 10.3452H8.56694M7.00763 1V1.66751M11.2557 2.75957L10.7838 3.2315M13.0153 7.00763H12.3477M1.66751 7.00763H1M3.2315 3.2315L2.75957 2.75957M4.6473 9.36796C4.18062 8.90116 3.86283 8.30646 3.73412 7.65906C3.60541 7.01165 3.67156 6.34063 3.9242 5.73082C4.17684 5.121 4.60462 4.5998 5.15347 4.2331C5.70231 3.8664 6.34756 3.67068 7.00763 3.67068C7.6677 3.67068 8.31295 3.8664 8.86179 4.2331C9.41064 4.5998 9.83842 5.121 10.0911 5.73082C10.3437 6.34063 10.4098 7.01165 10.2811 7.65906C10.1524 8.30646 9.83464 8.90116 9.36796 9.36796L9.00216 9.73309C8.79304 9.94226 8.62716 10.1906 8.514 10.4638C8.40084 10.7371 8.34262 11.03 8.34266 11.3258V11.6802C8.34266 12.0343 8.202 12.3739 7.95164 12.6242C7.70127 12.8746 7.3617 13.0153 7.00763 13.0153C6.65356 13.0153 6.31399 12.8746 6.06362 12.6242C5.81326 12.3739 5.6726 12.0343 5.6726 11.6802V11.3258C5.6726 10.7284 5.43497 10.155 5.0131 9.73309L4.6473 9.36796Z"
          stroke="#3590e5"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  );
}