import React from 'react';
import DraftCreativeRow from '../CreativeAnalytics/DraftCreative/components/Row/Row';

const StaticDraftCreative = ({ report_name, report_description, data }) => {
  const { rows } = data;
  return (
    <div className="draft-creative">
      <div className="draft-creative__rows">
        {rows?.length
          ? rows?.map((row, index) => {
              return (
                <DraftCreativeRow
                  creativeData={row}
                  rowIndex={index}
                  activeRow={null}
                  setActiveRow={null}
                  isSharedReport={true}
                  sharedRows={rows}
                />
              );
            })
          : null}
      </div>
    </div>
  );
};

export default StaticDraftCreative;
