import React, { useMemo } from 'react';
import { MenuItem } from '../../../../../components/LeftMenu/MenuItem';
import { ReportsNav } from './ReportsNav';
import {
  useCreativeAnalyticsGet,
  useCreativeAnalyticsSet,
} from '../../../contexts';
import { DashboardIcon } from '../../icons/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import { trackEvent } from '../../../../../store/UserActivity/actions';
import {
  CREATIVE_ANALYTICS,
  CREATIVE_ANALYTICS,
  TOGGLE_ADSET_REPORT,
  TOGGLE_BLOCK_REPORT,
  TOGGLE_COPILOT,
  TOGGLE_COPY,
  TOGGLE_DASHBOARD,
  TOGGLE_VISUAL_MODE,
} from '../../../../../store/UserActivity/constants';
import {
  COPILOT,
  COPY,
  DASHBOARD,
  PERFORMANCE_FREQUENCY,
  RECOMMENDATIONS,
  REPORT_BUILDER,
} from '../../../../../store/CreativeReports/constants';
import { TrendingIcon } from '../../icons/trending';
import { LegacyReports } from './LegacyReports';
import { BetaReports } from './BetaReports';
import { usePermissions } from '../../../../../components/Permissions/usePermissions';
import {
  LEGACY_REPORTS,
  BETA_REPORTS,
  TRENDING,
  COPY_REPORT,
  ANNOTATE_VISUALS,
  SETTINGS,
  DRAFT_CREATIVE,
  BLOCK_REPORT,
  ROADMAP,
  ANALYTICS,
  STUDIO,
  PFR,
} from '../../../../../components/Permissions/constants';
import { useSearchParams } from '../../../../../components/Hooks/search-params';
import { useSetShowQaEditor, useUrlFeature } from './hooks';
import RoleAccess from '../../../../../components/RoleAccess';
import { CopyIcon } from '../../icons/copy';
import { hasRoles, isAdminRole } from '../../../../../cookies';
import { DraftCreativeIcon } from '../../icons/draftCreative';
import Tooltip from '../../../../../components/Tooltip';
import {
  AnalyticsIcon,
  RoadmapIcon,
  StudioIcon,
} from '../../../../../components/Nav/assets';
import { useIsDirectMail } from '../../../selectors';
import { BlockIcon } from '../../icons/block';
import { useHistory } from 'react-router-dom';
import {
  setIsSettingsOpen,
  setShowQaEditor,
} from '../../../../../store/CreativeAnalytics/creativeAnalyticsSlice';
import { EasyAiIcon } from '../../../../EasyAiManager/Sidebar';

const TableModeMenu = ({ collapsed, setCollapsed, loading = false }) => {
  const dispatch = useDispatch();
  // const { display, isOrganicView, viewName } = useCreativeAnalyticsGet();
  // const { setParams } = useCreativeAnalyticsSet();
  const { getParams, setParams: setSearchParams } = useSearchParams();
  const { display } = getParams('display');
  const hasPermission = usePermissions();
  const setShowQaEditor = useSetShowQaEditor();
  const isUltraLight = useSelector((state) => state.ViewSettings.isUltraLight);
  const history = useHistory();
  const { feature, page } = useUrlFeature();
  const isAnalytics = feature === 'analytics';

  const allowRoadmap = hasPermission(ROADMAP);
  const allowAnalytics = hasPermission(ANALYTICS);
  const allowStudio = hasPermission(STUDIO);
  const allowPFR = hasPermission(PFR);

  const {
    darwin_light,
    allowedAdmin,
    isTikTokView,
    isDirectMail,
    viewName,
    isOrganicView,
    viewId,
  } = useSelector(
    ({
      ViewSettings: {
        darwin_light,
        allowedAdmin,
        isTikTokView,
        isDirectMail,
        viewName,
        isOrganicView,
        viewId,
      },
    }) => ({
      darwin_light,
      allowedAdmin,
      isTikTokView,
      isDirectMail,
      viewName,
      isOrganicView,
      viewId,
    })
  );

  const prefixView = (rest) => `/${viewId}/${rest}`;

  const isCompetitorView =
    isDirectMail && viewName.toLowerCase().includes('competitor');

  const itemClass = collapsed ? 'icon-only' : '';

  const handleSelection = (key, subkey = null) => {
    const modes = ['analytics', 'library'];

    const withParams = !!subkey ? `${key}/${subkey}` : key;

    history.push(prefixView(withParams));
  };

  return (
    <>
      <MenuItem
        title="Creative Analytics"
        icon={
          <AnalyticsIcon
            isSelected={feature === 'analytics' && !page}
            // className="menu-item__icon"
          />
        }
        onMenuItemClick={() => {
          // setSearchParams({ display: 'analytics', category: '' });
          setCollapsed(false);
          handleSelection('analytics');
        }}
        isSelected={feature === 'analytics' && !page}
        className={itemClass}
        isPrimaryItem
        // exclude={(!isNonPaidMediaType && !isUltraLight) || isOrganicView}
        subMenu={
          <>
            <MenuItem
              title={'BETA Reports'}
              // onMenuItemClick={handleSelect}
              // isSelected={isSelectedCategory}
              className="detailed-report-item"
              subMenu={
                <>
                  <MenuItem
                    title="Copy Report"
                    // icon={<CopyIcon />}
                    onMenuItemClick={() => {
                      dispatch(trackEvent(CREATIVE_ANALYTICS, TOGGLE_COPY));

                      handleSelection('analytics', 'copy');
                      // setSearchParams({ display: COPY, category: '' });
                    }}
                    isSelected={isAnalytics && page === 'copy'}
                    exclude={
                      isTikTokView ||
                      isOrganicView ||
                      isCompetitorView ||
                      isDirectMail ||
                      !hasPermission(COPY_REPORT)
                    }
                    className={itemClass}
                    {...{ collapsed, setCollapsed }}
                  />

                  <MenuItem
                    title="Report Builder"
                    // icon={<i className="fa-solid fa-screwdriver-wrench"></i>}
                    onMenuItemClick={() => {
                      // setSearchParams({
                      //   display: REPORT_BUILDER,
                      //   category: '',
                      // });
                      handleSelection('analytics', REPORT_BUILDER);
                    }}
                    isSelected={isAnalytics && page === REPORT_BUILDER}
                    className={itemClass}
                    {...{ collapsed }}
                  />
                </>
              }
            />

            <MenuItem
              title={'Legacy Reports'}
              // onMenuItemClick={handleSelect}
              // isSelected={isSelectedCategory}
              className="detailed-report-item"
              subMenu={
                <>
                  <MenuItem
                    title="Creative Performance"
                    // icon={<i className="fa-regular fa-images"></i>}
                    onMenuItemClick={() => {
                      handleSelection('analytics', 'dna_table');
                    }}
                    isSelected={isAnalytics && page === 'dna_table'}
                    className={itemClass}
                    {...{ collapsed, setCollapsed }}
                  />

                  <Tooltip
                    disabled={!collapsed}
                    content={'Block Report'}
                    placement={'right'}
                    follow={false}
                  >
                    <div>
                      <MenuItem
                        title="Block Report"
                        // icon={<BlockIcon />}
                        id="block-report"
                        onMenuItemClick={() => {
                          dispatch(
                            trackEvent(CREATIVE_ANALYTICS, TOGGLE_BLOCK_REPORT)
                          );
                          handleSelection('analytics', 'block_report');
                        }}
                        isSelected={isAnalytics && page === 'block_report'}
                        exclude={
                          !hasPermission(BLOCK_REPORT) ||
                          isCompetitorView ||
                          ((darwin_light || isDirectMail) && !allowedAdmin) ||
                          isOrganicView
                        }
                        className={itemClass}
                      />
                    </div>
                  </Tooltip>

                  <ReportsNav
                    loading={loading}
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    handleSelection={handleSelection}
                    feature={feature}
                    page={page}
                  />
                  {/* <MenuItem
                    title="Performance Frequency"
                    // icon={<BlockIcon />}
                    id="performance_frequency"
                    onMenuItemClick={() => {
                      handleSelection('analytics', 'performance_frequency');
                    }}
                    isSelected={isAnalytics && page === 'performance_frequency'}
                    className={itemClass}
                  /> */}
                </>
              }
            />

            {/* <MenuItem
              title="Category Builder"
              // icon={<RoadmapIcon className="menu-item__icon" />}
              onMenuItemClick={() => {
                setSearchParams({ display: 'dna_table', category: '' });
              }}
              // isSelected={display === 'dna_table'}
              className={itemClass}
              {...{ collapsed, setCollapsed }}
            /> */}
          </>
        }
        {...{ collapsed, setCollapsed }}
      />
      {/* <div className={`divider ${collapsed ? 'collapsed' : ''}`}></div> */}

      <MenuItem
        title="Creative Studio"
        icon={
          <StudioIcon
            isSelected={feature === 'studio'}
            // className="menu-item__icon"
          />
        }
        onMenuItemClick={() => {
          // setSearchParams({ display: 'dna_table', category: '' });
          setCollapsed(true);
          handleSelection('studio');
        }}
        isSelected={feature === 'studio'}
        className={itemClass}
        isPrimaryItem
        exclude={!allowStudio}
        {...{ collapsed, setCollapsed }}
      />

      <MenuItem
        title="Creative Roadmap"
        icon={<i class="fa-solid fa-route"></i>}
        onMenuItemClick={() => {
          handleSelection('roadmap');
          setCollapsed(true);
        }}
        isSelected={feature === 'roadmap'}
        className={itemClass}
        isPrimaryItem
        {...{ collapsed, setCollapsed }}
      />

      <MenuItem
        title="Easy AI Editor"
        icon={<EasyAiIcon />}
        onMenuItemClick={() => {
          handleSelection('easy-ai-manager');
          setCollapsed(true);
        }}
        isSelected={feature === 'easy-ai-manager'}
        className={itemClass}
        isPrimaryItem
        {...{ collapsed, setCollapsed }}
        exclude={!allowPFR || !hasRoles(['internal'])}
      />
      {/* <LegacyReports
        {...{
          loading,
          isDirectMail,
          // isNonPaidMediaType,
          darwin_light,
          allowedAdmin,
          isCompetitorView,
          itemClass,
          collapsed,
        }}
        exclude={
          !hasPermission(LEGACY_REPORTS) ||
          isUltraLight ||
          viewName.includes('Email')
        }
      /> */}
      {/* <BetaReports
        {...{
          loading,
          isDirectMail,
          // isNonPaidMediaType,
          darwin_light,
          allowedAdmin,
          isCompetitorView,
          itemClass,
          collapsed,
        }}
        exclude={
          !hasPermission(BETA_REPORTS) ||
          isUltraLight ||
          isOrganicView ||
          viewName.includes('Email')
        }
      /> */}
      {/* <div className={`divider ${collapsed ? 'collapsed' : ''}`}></div> */}
      {/* <MenuItem
        title="Annotate visuals"
        icon={<i className="fa-regular fa-pen-to-square"></i>}
        onMenuItemClick={() => setShowQaEditor(true)}
        exclude={!hasPermission(ANNOTATE_VISUALS) && !isAdminRole()}
        className={itemClass}
        {...{ collapsed }}
      /> */}
      <MenuItem
        title={'Internal Tools'}
        icon={<i class="fa-solid fa-wrench"></i>}
        // onMenuItemClick={handleSelect}
        // isSelected={isSelectedCategory}
        exclude={!isAnalytics || collapsed}
        className="detailed-report-item"
        isPrimaryItem
        {...{ collapsed, setCollapsed }}
        subMenu={
          <>
            <MenuItem
              title="Annotate visuals"
              // icon={<i className="fa-regular fa-pen-to-square"></i>}
              onMenuItemClick={() => setShowQaEditor(true)}
              exclude={
                !isAnalytics ||
                !page ||
                (!hasPermission(ANNOTATE_VISUALS) && !isAdminRole())
              }
              className={itemClass}
              {...{ collapsed }}
            />
            <MenuItem
              title="Draft Creative"
              // icon={<DraftCreativeIcon />}
              isSelected={isAnalytics && page === DRAFT_CREATIVE}
              onMenuItemClick={
                () => handleSelection('analytics', DRAFT_CREATIVE)
                // setSearchParams({ display: DRAFT_CREATIVE, category: '' })
              }
              exclude={!hasPermission(DRAFT_CREATIVE)}
              className={itemClass}
              {...{ collapsed }}
            />
          </>
        }
      />

      <MenuItem
        title="Settings"
        icon={<i className="fa fa-gear"></i>}
        onMenuItemClick={() => dispatch(setIsSettingsOpen(true))}
        exclude={
          !isAnalytics || !page || (!hasPermission(SETTINGS) && !isAdminRole())
        }
        className={itemClass}
        isPrimaryItem
        {...{ collapsed }}
      />
    </>
  );
};

export default TableModeMenu;
