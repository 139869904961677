import React from 'react';
import PropTypes from 'prop-types';

const { logger } = window;

export const BasicError = ({ fallback = null }) => (
  <div className="row">
    <div className="col-md-12">
      { fallback || <h3>Something went wrong.</h3>}
    </div>
  </div>
);

/**
 * @param {{ hasError: Boolean, children?: React.Component }} props
 */
export const WhenError = ({ hasError, children }) => {
  if (hasError) {
    return (
      <div className="row">
        <div className="col-md-6">
          <h3>Something went wrong.</h3>
        </div>
      </div>
    );
  }
  return children;
};

export class ErrorBoundary extends React.Component {
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
    };

    this.Fallback = this.Fallback.bind(this);
  }

  componentDidCatch(error, errorInfo) {
    logger.error(error, errorInfo);
  }

  componentWillUnmount() {
    this.Fallback = null;
  }

  Fallback() {
    const { fallback, message } = this.props;

    if (fallback) {
      return fallback;
    }
    const { error } = this.state;

    return (
      <div className="row">
        <div className="col-md-6">
          <h3>{message}</h3>
          {!!error && <p>{error.toString()}</p>}
        </div>
      </div>
    );
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      const { Fallback } = this;

      return <Fallback />;
    }
    return children;
  }
}

ErrorBoundary.defaultProps = {
  fallback: null,
  message: 'Something went wrong.',
};

ErrorBoundary.propTypes = {
  message: PropTypes.string,
  fallback: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};
