import { EMPTY_SETTING } from "./constants";

export const OPEN_SETTINGS = "open-settings";
export const CLOSE_SETTINGS = "close-settings";
export const SET_LAST_SAVED = "set-last-saved";
export const SET_CURRENT = "set-current";
export const UPDATE_NAME = "update-name";
export const UPDATE_DESCRIPTION = "update-description";
export const ADD_EXAMPLE = "add-example";
export const UPDATE_EXAMPLE = "update-example";
export const REMOVE_EXAMPLE = "remove-example";
export const ADD_SETTING = "add-setting";
export const DELETE_SETTING = "delete-setting";
export const THROW_ERROR = "throw-error";
export const CLEAR_ERROR = "clear-error";
export const CLEAN_UP = "clean-up";

export function phraseThemeSettingsReducer(state, action) {
  const { payload } = action || {};

  const {
    index,
    phrase_theme,
    description,
    lastSaved,
    current,
    exampleIndex,
    exampleText,
  } = {
    ...payload,
    index: payload.index || payload.index === 0 ? payload.index : null,
    phrase_theme: payload.phrase_theme || "",
    description: payload.description || "",
    lastSaved: payload.lastSaved || [],
    current: payload.current || [],
    exampleIndex:
      payload.exampleIndex || payload.exampleIndex === 0
        ? payload.exampleIndex
        : null,
    exampleText: payload.exampleText || "",
  };

  switch (action.type) {
    case OPEN_SETTINGS:
      return {
        ...state,
        show: true,
      };

    case CLOSE_SETTINGS:
      return {
        ...state,
        show: false,
      };

    case SET_LAST_SAVED:
      return {
        ...state,
        lastSaved,
      };

    case SET_CURRENT:
      return {
        ...state,
        current,
      };

    case UPDATE_NAME:
      return {
        ...state,
        current: state.current.map((setting, i) =>
          i === index
            ? {
                ...setting,
                phrase_theme,
              }
            : setting
        ),
      };

    case UPDATE_DESCRIPTION:
      return {
        ...state,
        current: state.current.map((setting, i) =>
          i === index
            ? {
                ...setting,
                description,
              }
            : setting
        ),
      };

    case ADD_EXAMPLE:
      return {
        ...state,
        current: state.current.map((setting, i) =>
          i === index
            ? {
                ...setting,
                examples: [...setting.examples, ""],
              }
            : setting
        ),
      };

    case UPDATE_EXAMPLE:
      return {
        ...state,
        current: state.current.map((setting, i) =>
          i === index
            ? {
                ...setting,
                examples: setting.examples.map((ex, ix) =>
                  ix === exampleIndex ? exampleText : ex
                ),
              }
            : setting
        ),
      };

    case REMOVE_EXAMPLE:
      return {
        ...state,
        current: state.current.map((setting, i) =>
          i === index
            ? {
                ...setting,
                examples: setting.examples.filter((_, i) => i !== exampleIndex),
              }
            : setting
        ),
      };

    case ADD_SETTING:
      return {
        ...state,
        current: [...state.current, EMPTY_SETTING],
      };

    case DELETE_SETTING:
      return {
        ...state,
        current: state.current.filter((_, i) => i !== index),
      };

    case THROW_ERROR:
      return {
        ...state,
        error: true,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: false,
      };

    case CLEAN_UP:
      return {
        ...state,
        current: state.current
          .map((setting) => {
            return {
              phrase_theme: setting.phrase_theme.trim(),
              description: setting.description.trim(),
              examples: setting.examples
                .map((ex) => ex.trim())
                .filter((ex) => ex.length),
            };
          })
          .filter((setting) => !_.isEqual(setting, EMPTY_SETTING)),
      };
  }
}
