import { apiRequest, API_FAILURE } from '../Api/actions';
import { addNotification } from '../Notifications/actions';
import { getViewId } from '../utils';

export const DASHBOARD_CONFIG = 'DashboardConfig';
export const DASHBOARD_CONFIG_FETCH = `[${DASHBOARD_CONFIG}] FETCH`;

export const fetchDashboardConfig = (dashboardConfig) => ({
  type: DASHBOARD_CONFIG_FETCH,
  data: dashboardConfig,
});

export const dashboardConfigMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case DASHBOARD_CONFIG_FETCH: {
        const viewId = getViewId();
        const { data } = action;

        dispatch(
          apiRequest({
            body: { dashboard_configuration: data },
            url: ['api', viewId, '/save-dashboard-config'].join('/'),
            entity: DASHBOARD_CONFIG,
            method: 'POST',
          })
        );

        break;
      }

      case `[${DASHBOARD_CONFIG}] ${API_FAILURE}`: {
        const { error } = action;

        dispatch(
          addNotification(
            {
              id: new Date().getMilliseconds(),
              status: 'danger',
              title: 'Dashboard configuration',
              message: error.body || error,
            },
            DASHBOARD_CONFIG
          )
        );

        break;
      }

      default:
        return result;
    }

    return result;
  };
