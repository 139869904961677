export const OPTIONS = 'Marketplace.options';

export const Options = {
  SET: `[${OPTIONS}] SET`,
};

export function setMarketplaceOptions({ data }) {
  return {
    type: Options.SET,
    data,
    meta: { entity: OPTIONS },
  };
}
