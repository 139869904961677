import React from 'react';
import PropTypes from 'prop-types';

export const LayoutContainer = ({ children }) => (
  <div className="row mt-2 mx-0 mx-md-5 mb-2">{children}</div>
);

LayoutContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export const YellowRule = () => (
  <hr
    className="mb-2 mt-2 pr-2 pl-2"
    style={{ borderTop: '4px solid #ffcb36', width: '100%' }}
  />
);
