import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

const PrivateAdminLeverRoute = ({
  children,
  loggedIn,
  isLeverView,
  allowedAdmin,
  viewId,
  ...rest
}) => (
  <Route
    {...rest}
    render={({ location }) =>
      loggedIn ? (
        isLeverView && allowedAdmin ? (
          children
        ) : (
          <Redirect
            to={{ pathname: 'bulk-actions', state: { from: location } }}
          />
        )
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: location } }} />
      )
    }
  />
);

PrivateAdminLeverRoute.propTypes = {
  loggedIn: PropTypes.bool,
  isLeverView: PropTypes.bool,
  allowedAdmin: PropTypes.bool,
  viewId: PropTypes.string,
  component: PropTypes.object,
};

export default PrivateAdminLeverRoute;
