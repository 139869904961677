import { subDays } from 'date-fns';
import { darwinDateToDate, toDarwinDate } from '../../../utils/darwin-dates';
import { toPercent_2 } from '../../../utils/numbers';
import { findPerformanceScore } from '../../CreativeAnalytics/utils/findPerformanceScore';

export const getPreviousDates = (start_date: Date, end_date: Date) => {
  const days =
    (end_date.valueOf() - start_date.valueOf()) / (1000 * 60 * 60 * 24);

  const previousEndDate = subDays(start_date, 1);
  const previousStartDate = subDays(previousEndDate, days);

  return {
    previous_start_date: previousStartDate,
    previous_end_date: previousEndDate,
  };
};

export const generateMockPreviousPeriodData = (visuals) => {
  return visuals.map((visual) => ({
    cpa: 46.61,
    ctr: 0.61,
    cpm: 46.61,
    cvc: 46.61,
    roas: 4.23,
    _id: visual._id,
    results: 854,
    spend: 819.22,
    impressions: 80251,
    clicks: 7942,
  }));
};

export const addMetrics =
  ({
    clientMetrics,
    additional_metrics,
    customIntegratedMetrics,
    customEvents,
    custom_performance_scores,
  }) =>
  (visual) => {
    const cpa = visual.spend / visual.results ?? 0;
    const cpm =
      visual.impressions === 0
        ? null
        : (visual.spend / visual.impressions) * 1000;
    const cpc = visual.clicks === 0 ? null : visual.spend / visual.clicks;
    const ctr =
      visual.impressions === 0 ? null : visual.clicks / visual.impressions;
    const roas = cpa === 0 ? 0 : 1 / cpa;

    const custom = {};
    const cpClientMetrics = {};

    if (clientMetrics) {
      [...clientMetrics, ...additional_metrics].forEach((metric) => {
        if (visual.hasOwnProperty(metric)) {
          const metricValue = visual[metric];
          const costPerMetric =
            metricValue !== 0 ? visual.spend / metricValue : null;
          cpClientMetrics[`cp_${metric}`] = costPerMetric;
        }
      });
    }

    if (customIntegratedMetrics) {
      [...customIntegratedMetrics].forEach(({ label, value }) => {
        if (visual.hasOwnProperty(value)) {
          const metricValue = visual[value];
          const costPerMetric =
            metricValue !== 0 ? visual.spend / metricValue : null;
          cpClientMetrics[`cp_${value}`] = costPerMetric;
        }
      });
    }

    if (customEvents) {
      [...customEvents].forEach(
        ({ name, numerator, denominator }) => {
          try {
            const metricValue = visual[numerator] / visual[denominator];

            if (!metricValue || typeof metricValue !== 'number') {
              console.error(`Error adding custom event data.`);
            } else {
              custom[name] = toPercent_2(metricValue);
            }
          } catch (e) {
            console.error(`Error adding custom event data. ${e}`);
          }
        }
      );
    }

    const update = {
      ...visual,
      cpm,
      cpc,
      ctr,
      cpa: isFinite(cpa) ? cpa : 0,
      roas: isFinite(roas) ? roas : 0,
      ...custom,
      ...cpClientMetrics,
    };

    return {
      ...update,
      custom_performance_score: findPerformanceScore(
        update,
        custom_performance_scores
      ),
    };
  };

export const getTooltipText = (trendData) => {
  const { status } = trendData;

  switch (status) {
    case 'idle':
      return 'Click to fetch previous period data.';
    case 'loading':
      return 'Fetching previous period data...';
    case 'error':
      return 'There was an error fetching previous period data.';
    case 'success':
      return 'Previous period data is available to be viewed in the metric data display.'
    default:
      return;
  }
};
