import React from 'react';
import { DARWIN_RED, DARWIN_SEAFOAM, DARWIN_YELLOW } from '../../../../../constants';

const GREEN = DARWIN_SEAFOAM;
const YELLOW = DARWIN_YELLOW;
const RED = DARWIN_RED;

function useStatus([item]) {
  if (!item) {
    return ['No status', YELLOW];
  }
  const { category } = item;

  switch (category.toLowerCase()) {
    case 'red':
      return [category, RED];

    case 'yellow':
      return [category, YELLOW];

    case 'green':
      return [category, GREEN];

    default:
      return ['No status', YELLOW];
  }
}

export default function Status({ resultSet }) {
  const [status, color] = useStatus(resultSet.chartPivot());
  const style = {
    color,
  };

  return (
    <div>
      <h3 style={style}>
        <strong>{status}</strong>
      </h3>
    </div>
  );
}
