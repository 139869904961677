import React from 'react';
import Button from '../../../components/Button';
import Tooltip from '../../../components/Tooltip';
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from '../contexts';
import { darwinDateToFormat } from '../../../utils/darwin-dates';
import { useFetchPFRData } from '../hooks/useFetchPFRData';
import { useDispatch } from 'react-redux';
import { setPfrLoading } from '../../../store/CreativeAnalytics/creativeAnalyticsSlice';

const Refresh = ({
  retrievedDate,
  filteredData,
  refresh,
}) => {
  const dispatch = useDispatch();
  const { setParams } = useCreativeAnalyticsSet();
  const { fetchPerformanceData } = useCreativeAnalyticsGet();
  return (
    <div className="mr-2">
      <Tooltip
        content={`Click to run a refresh of the data.${
          filteredData.length > 0
            ? ` The current snapshot is ${
                retrievedDate
                  ? darwinDateToFormat(retrievedDate, 'MM/dd/yyyy')
                  : ''
              }.`
            : ''
        }`}
      >
        <div className="hard-refresh-button">
          <Button
            onClick={() => {
              setParams({ refresh: true });
              if (refresh) {
                fetchPerformanceData();
                dispatch(setPfrLoading(true))
              }
            }}
            appearance="raised"
          >
            <i className="fa-solid fa-arrow-rotate-right" />
          </Button>
        </div>
      </Tooltip>
    </div>
  );
};

export default Refresh;
