export const formatData = (data, metric) => {
  return data.map((variant) => {
    const { preview_url, visual_type, confidence } = variant;
    const predictedMetric = variant[`predicted_${metric}`];

    return {
      data: {
        asset_url: preview_url,
        visual_type: visual_type.toLowerCase(),
        predicted_performance: predictedMetric,
        confidence,
        favorite: false,
      },
    };
  });
};
