import { useEffect, useRef } from 'react';

import { toDarwinDate } from '../../../../utils/darwin-dates';

function fetchHistory(id, body, signal) {
  return fetch(['api', 'blocks', id, 'history'].join('/'), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
    signal,
  });
}

// 1. get all ids and bind to common payload
// 2. if { id, ...body } exists in cache return cached value
//    else fetch {id, ...body }
// 3. if common payload has changed, clear cache

class HistoryCache {
  constructor() {
    this.cache = new Map();
    this.body = {};
  }

  async get(id, controller) {
    const key = JSON.stringify({ block_id: id, ...this.body });

    if (this.cache.has(key)) {
      return this.cache.get(key);
    }

    const value = fetchHistory(id, this.body, controller.signal)
      .then((r) => r.json())
      .then(({ data }) => data);

    this.cache.set(key, value);

    return value;
  }

  setBody(body) {
    this.body = body;
    this.cache = new Map();

    return this;
  }

  bodiesEqual(otherBody) {
    return JSON.stringify(this.body) === JSON.stringify(otherBody);
  }
}

function prepBody({ interval, dateRange }) {
  const [start, end] = dateRange;

  return {
    interval,
    start_date: toDarwinDate(start),
    end_date: toDarwinDate(end),
  };
}

export function useUnmountController() {
  const controller = useRef(new AbortController());

  useEffect(
    () => () => {
      controller.current.abort();
    },
    []
  );

  return controller.current;
}

let Cache = new HistoryCache();

/**
 * @typedef {{ interval: string, dateRange: [Date, Date], type: string }} Options
 * @typedef {{ status: "fulfilled" | "rejected", value: any }} AllSettledRes
 *
 * @returns {(ids: string[], body: Options, controller: AbortController) => Promise<AllSettledRes[]>}
 */
export function useFetchHistories() {
  const fetchBlocks = (ids, body, controller) => {
    const prepped = prepBody(body);

    if (!Cache.bodiesEqual(prepped)) {
      Cache.setBody(prepped);
    }

    const thunk = (id) => Cache.get(id, controller);

    return Promise.allSettled(ids.map(thunk));
  };

  useEffect(
    () => () => {
      Cache = new HistoryCache();
    },
    []
  );

  return fetchBlocks;
}
