/**
 * @param {(accumulator: any, row: Object, i: number) => any} reducer same signature as Array.reduce callback
 * @param {[Object]} table
 * @param {[string]} keys
 * @param {() => any} accCreator
 *
 * @return {[Object]}
 */
export function groupBy(reducer, table, keys, accCreator) {
  const grouped = groupedRows(table, keys);

  return reduceGrouped(reducer, grouped, accCreator);
}

function reduceGrouped(proc, groupings, accCreator) {
  const results = [];

  for (const group of groupings) {
    results.push(group.reduce(proc, accCreator()));
  }

  return results;
}

/**
 * @param {[Object]} table
 * @param {[String]} keys
 *
 * @return {[[Object]]}
 */
function groupedRows(table, keys) {
  const tree = new IndexTree();
  let i = 0;

  while (table[i]) {
    const row = table[i];

    const path = keys.map((k) => row[k]);

    tree.add(path, i);

    i++;
  }

  const results = [];

  for (const indexes of tree.toIndexes()) {
    results.push(indexes.map((index) => table[index]));
  }

  return results;
}

/**
 * @description A nested index, allowing for grouping
 * on multiple keys instead of one.
 *
 * _tree is shape of:
 * `{
 *    ...primaryKey: {
 *        ...n-aryKey: [...Number|String<document-id>]
 *    }
 * }`
 */
class IndexTree {
  constructor() {
    this._tree = Object.create(null);
  }

  exists(path) {
    let cursor = this._tree;

    for (const key of path) {
      if (cursor[key]) {
        cursor = cursor[key];
      } else {
        return false;
      }
    }

    return true;
  }

  /**
   * @description Assumes given path exists on tree.
   * @param {[String]} path
   */
  get(path) {
    let cursor = this._tree;

    for (const key of path) {
      cursor = cursor[key];
    }

    return cursor;
  }

  /**
   * @description Adds a document id to the tree
   * at the given path.
   * @param {[String]} path
   * @param {Number | String} id
   */
  add(path, id) {
    let cursor = this._tree;
    const L = path.length;
    const last = L - 1;

    for (let i = 0; i < L; i++) {
      const key = path[i];

      if (cursor[key] !== undefined && i === last) {
        cursor[key].push(id);
      } else if (i === last) {
        cursor[key] = [id];
      } else if (cursor[key] === undefined) {
        cursor[key] = Object.create(null);
      }

      cursor = cursor[key];
    }

    return this;
  }

  /**
   * @description Yields each id grouping in the tree.
   *
   * @return {Generator<null, null, [Number | String]>}
   */
  toIndexes() {
    const loop = function* (nested) {
      const outerKeys = Object.keys(nested);

      for (const key of outerKeys) {
        const val = nested[key];

        if (isObject(val)) {
          yield* loop(val);
        } else {
          yield val;
        }
      }
    };

    return loop(this._tree);
  }
}

function isObject(val) {
  return (
    val !== undefined &&
    val !== null &&
    typeof val === 'object' &&
    !Array.isArray(val)
  );
}

/**
 * @param { {string: any}[] } table
 * @param {string} key
 * @param {{ preserveEmpty?: boolean }} conf
 *
 * @return { {string: any}[] }
 */
export function unwind(table, key, conf = { preserveEmpty: false }) {
  const results = [];

  const setWithNested = (obj, nested) => {
    for (const item of nested) {
      results.push({
        ...obj,
        [key]: item,
      });
    }
  };

  const handleNested = conf.preserveEmpty
    ? (item, nested) =>
        !nested ? results.push(item) : setWithNested(item, nested)
    : (item, nested) => !!nested && setWithNested(item, nested);

  for (const item of table) {
    handleNested(item, item[key]);
  }

  return results;
}

/**
 *
 * @param {Object<string, any>[]} docs
 * @param {Object<string, any> | Function} projection
 */
export function project(docs, projection) {
  if (typeof projection === 'function') {
    return docs.map(projection);
  }

  const keys = Object.keys(projection);

  const results = [];

  docs.forEach((doc) => {
    const newDoc = {};

    keys.forEach((k) => {
      const projectTo = projection[k];
      const projectionType = typeof projectTo;

      if (projectTo === 1) {
        newDoc[k] = doc[k];
      } else if (projectionType === 'function') {
        newDoc[k] = projectTo(doc);
      } else if (projectionType === 'string' && projectTo[0] === '$') {
        newDoc[k] = doc[projectTo.replace('$', '')];
      } else {
        newDoc[k] = projectTo;
      }
    });

    results.push(newDoc);
  });

  return results;
}
