import {
  ADD_NOTIFICATION,
  removeNotification,
  showNotification,
} from './actions';

const middleware = () => (next) => (action) => {
  const result = next(action);

  if (action.type.includes(ADD_NOTIFICATION)) {
    const {
      delay,
      message,
      status,
      id,
      meta: { entity },
    } = action;

    next(
      showNotification(
        {
          id,
          message,
          status,
        },
        entity
      )
    );

    const timeoutId = setTimeout(
      () => next(removeNotification(id, entity)),
      delay
    );

    return timeoutId;
  }
  return result;
};

export default middleware;
