export const SELECT_LABEL = 'select-label';
export const ADD_LABEL = 'add-label';
export const EDIT_LABEL = 'edit-label';
export const DELETE_LABEL = 'delete-label';

function updateLabel(labels, index, updatedLabel) {
  return labels.map((lbl, i) => {
    if (index === i) {
      return { ...lbl, ...updatedLabel };
    }
    return lbl;
  });
}

function deleteLabel(labels, label) {
  return labels.filter((lbl) => lbl.name !== label.name);
}

export function reducer(state, action) {
  switch (action.type) {
    case SELECT_LABEL:
      return {
        index: action.index,
        labels: state.labels,
      };

    case DELETE_LABEL:
      return {
        index: -1,
        labels: deleteLabel(state.labels, { name: action.name }),
      };

    case ADD_LABEL: {
      return {
        index: -1,
        labels: [...state.labels, { ...action.label }],
      };
    }

    case EDIT_LABEL: {
      return {
        index: -1,
        labels: updateLabel(state.labels, state.index, { ...action.label }),
      };
    }

    default:
      return state;
  }
}