import React, { useEffect } from 'react';

import CardOptions from './CardOptions';
import { ArrowLeft, ArrowRight } from './assets';
import { SET_SECOND_VALUE, SET_SECOND_VISUAL_TYPE } from '../DraftCreative/hooks/useDraftCreativeState';

const Carousel = ({
  previews,
  carouselIndex,
  prev,
  next,
  showOptions,
  setShowOptions,
  name,
  value,
  isTopPerformers,
  isTryThese,
  label,
  copy,
  justSaved,
  showOverlay,
  fromDraftCreative,
  handleBlockValue,
  handleBlockVisualType,
}) => {
  if (!previews[carouselIndex]) {
    return <p>There was an error loading the asset.</p>;
  }

  let display = name || label.label;

  if (isTopPerformers) {
    display = previews[carouselIndex].ad_names[0];
  }

  if (isTryThese) {
    display = label.label;
  }

  const PreviewOverlay = (
    <div className="recommendation-card__preview-overlay">
      <svg
        className="checkmark"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle
          className="checkmark__circle"
          cx="26"
          cy="26"
          r="25"
          fill="none"
        />
        <path
          className="checkmark__check"
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </svg>
      <span className="overlay-text">
        {label.label === justSaved
          ? 'Added to saved concepts'
          : 'Removed from saved concepts'}
      </span>
    </div>
  );

  const handleClick = (value, type) => {
    if (fromDraftCreative) {
      handleBlockValue(SET_SECOND_VALUE, value);
      handleBlockVisualType(SET_SECOND_VISUAL_TYPE, type);
    }
  };

  return (
    <>
      <div
        className={`recommendation-card__preview${
          showOverlay ? ' no-click' : ''
        }`}
        onMouseEnter={() => {
          if (!showOverlay && !fromDraftCreative) setShowOptions(true);
        }}
        onMouseLeave={() => {
          if (!showOverlay) setShowOptions(false);
        }}
      >
        {showOverlay && PreviewOverlay}
        {showOptions && previews.length > 1 && <ArrowLeft {...{ prev }} />}
        {showOptions && previews.length > 1 && <ArrowRight {...{ next }} />}
        {showOptions && (
          <CardOptions
            visual_type={previews[carouselIndex].visual_type}
            url={previews[carouselIndex].asset_url}
            {...{ display, value, isTopPerformers, isTryThese, label }}
          />
        )}

        <Preview
          asset={previews[carouselIndex]}
          showOptions={showOptions}
          copy={copy}
          handleClick={handleClick}
        />
      </div>
    </>
  );
};

export const Preview = ({ asset, showOptions, handleClick }) => {
  const { asset_url, visual_type } = asset;
  const imgClass = `recommendation-card__img${
    showOptions ? ' show-options' : ''
  }`;

  let visual = <p className="text-white">There was an error.</p>;

  if (visual_type === 'image') {
    visual = (
      <img
        onClick={() => handleClick(asset_url, visual_type)}
        className={imgClass}
        src={asset_url}
        alt=""
      />
    );
  } else if (visual_type === 'video') {
    visual = (
      <video
        onClick={() => handleClick(asset_url, visual_type)}
        key={asset_url}
        className={imgClass}
        autoPlay
        loop
        muted
        plays-inline="true"
        // onMouseOver={(e) => e.target.play()}
        // onMouseOut={(e) => e.target.pause()}
      >
        <source src={asset_url} type="video/mp4" />
      </video>
    );
  }

  return visual;
};

export default Carousel;
