import { getColumnDropdownOptions } from './Columns';

export const getHiddenColumns = (columns, isROAS, isDirectMail, clientMetrics, customEvents) => {
  return getColumnDropdownOptions(isROAS, isDirectMail, clientMetrics, customEvents).reduce((acc, { value }) => {
    if (!columns.includes(value)) {
      acc.push(value);
    }
    
    return acc;
  }, [])};

export const checkIsEmpty = (row) => {
  const {
    aggregate_cpa,
    aggregate_roas,
    median_cpa,
    median_roas,
    aggregate_cpm,
    aggregate_cpc,
    aggregate_ctr,
    spend,
    percent_budget,
    number_unique_creatives,
  } = row;

  const numericValues = [
    aggregate_cpa,
    aggregate_roas,
    median_roas,
    aggregate_cpm,
    aggregate_cpc,
    aggregate_ctr,
    spend,
    parseInt(percent_budget),
    number_unique_creatives,
  ];

  return numericValues.every((v) => v === 0) && !median_cpa;
};