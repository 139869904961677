import { STANDARD_CATEGORIES_LOOKUP } from '../categories';
import { useCreativeAnalyticsGet } from '../contexts';
import { useActionName, useCategoriesLookup } from '../selectors';

export const useBoxPlotData = (activeBoxPlotCategory) => {
  const categoriesLookup = useCategoriesLookup();
  const actionName = useActionName();
  const isROAS = actionName.includes('value_');

  const current =
    categoriesLookup[
      STANDARD_CATEGORIES_LOOKUP[activeBoxPlotCategory] || activeBoxPlotCategory
    ];

  if (!current) {
    return { boxPlotData: null, chartLabels: null };
  }

  let result = [];

  current.labels?.forEach((label) => {
    const { visuals } = label;
    const cpas = visuals.map(({ cpa }) => {
      if (cpa === 0) return 0;
      const val = isROAS ? 1 / cpa : cpa;
      return val;
    });

    result.push({
      low: Math.min(...cpas),
      q1: getPercentile(cpas, 25),
      median: getPercentile(cpas, 50),
      q3: getPercentile(cpas, 75),
      high: Math.max(...cpas),
      name: label.name,
      count: visuals.length,
    });
  });

  const sorted = result.sort((a, b) => {
    return b.median - a.median;
  });

  return {
    boxPlotData: sorted,
    chartLabels: sorted.map((label) => label.name),
  };
};

function numSort(a, b) {
  return a - b;
}

function getPercentile(data, percentile) {
  data.sort(numSort);
  var index = (percentile / 100) * data.length;
  var result;
  if (Math.floor(index) == index) {
    result = ((data[index - 1] + data[index]) / 2).toFixed(2);
  } else {
    result = data[Math.floor(index)].toFixed(2);
  }
  return result;
}
