import React from 'react';

export const BrowserIcon = ({ width, height }) => (
  <svg
    width={width ?? "21"}
    height={height ?? "21"}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.05794 3.43933H4.05794C3.83693 3.43933 3.62497 3.52713 3.46869 3.68341C3.31241 3.83969 3.22461 4.05165 3.22461 4.27266V9.27266C3.22461 9.49368 3.31241 9.70564 3.46869 9.86192C3.62497 10.0182 3.83693 10.106 4.05794 10.106H9.05794C9.27896 10.106 9.49092 10.0182 9.6472 9.86192C9.80348 9.70564 9.89128 9.49368 9.89128 9.27266V4.27266C9.89128 4.05165 9.80348 3.83969 9.6472 3.68341C9.49092 3.52713 9.27896 3.43933 9.05794 3.43933ZM8.22461 8.43933H4.89128V5.106H8.22461V8.43933ZM12.3913 10.106H17.3913C17.6123 10.106 17.8243 10.0182 17.9805 9.86192C18.1368 9.70564 18.2246 9.49368 18.2246 9.27266V4.27266C18.2246 4.05165 18.1368 3.83969 17.9805 3.68341C17.8243 3.52713 17.6123 3.43933 17.3913 3.43933H12.3913C12.1703 3.43933 11.9583 3.52713 11.802 3.68341C11.6457 3.83969 11.5579 4.05165 11.5579 4.27266V9.27266C11.5579 9.49368 11.6457 9.70564 11.802 9.86192C11.9583 10.0182 12.1703 10.106 12.3913 10.106ZM13.2246 5.106H16.5579V8.43933H13.2246V5.106ZM3.22461 17.606C3.22461 17.827 3.31241 18.039 3.46869 18.1953C3.62497 18.3515 3.83693 18.4393 4.05794 18.4393H9.05794C9.27896 18.4393 9.49092 18.3515 9.6472 18.1953C9.80348 18.039 9.89128 17.827 9.89128 17.606V12.606C9.89128 12.385 9.80348 12.173 9.6472 12.0167C9.49092 11.8605 9.27896 11.7727 9.05794 11.7727H4.05794C3.83693 11.7727 3.62497 11.8605 3.46869 12.0167C3.31241 12.173 3.22461 12.385 3.22461 12.606V17.606ZM4.89128 13.4393H8.22461V16.7727H4.89128V13.4393ZM11.5579 17.606C11.5579 17.827 11.6457 18.039 11.802 18.1953C11.9583 18.3515 12.1703 18.4393 12.3913 18.4393H17.3913C17.6123 18.4393 17.8243 18.3515 17.9805 18.1953C18.1368 18.039 18.2246 17.827 18.2246 17.606V12.606C18.2246 12.385 18.1368 12.173 17.9805 12.0167C17.8243 11.8605 17.6123 11.7727 17.3913 11.7727H12.3913C12.1703 11.7727 11.9583 11.8605 11.802 12.0167C11.6457 12.173 11.5579 12.385 11.5579 12.606V17.606ZM13.2246 13.4393H16.5579V16.7727H13.2246V13.4393Z"
      fill="white"
    />
  </svg>
);
