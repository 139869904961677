//@ts-check
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useAd, useFetchStatus, usePayload } from "./Container";
import BoostModal from "../../features/CreativeReports/BoostModal";
import Layout from "./Layout";
import AdName from "../../features/CreativeReports/Dashboards/AdName";

import withStrikezoneMethods from "./withStrikezoneMethods";
import { TestingStrikezone } from "../Strikezone";
import TestingPerformancePanel from "./PerformancePanel";
import { Ad } from "./AdDisplay";
import { ActionPanel } from "./ActionPanel";

const PERF_WRAPPER = { marginTop: "10px" };

const TestingAd = ({
  handleSelection,
  toggleTooltip,
  handleBoostModal,
  selection,
  selectedType,
}) => {
  const { fetchPreview } = usePayload();
  const { loadingPreview, previewFetchError } = useFetchStatus();
  const [showEditor, setEditVisibility] = useState(false);

  const toggleModify = (e) => {
    if (!showEditor) {
      setEditVisibility(true);
    } else {
      setEditVisibility(false);

      fetchPreview();
    }
  };

  useEffect(() => {
    fetchPreview();
  }, []);

  if (previewFetchError) {
    return (
      <Layout
        AdName={<AdName adName="Best Available Ad" />}
        AdPreview={<PreviewError error={previewFetchError} />}
        ActionPanel={<button className="btn btn-success">Restart?</button>}
      />
    );
  } else if (loadingPreview) {
    return (
      <Layout
        AdName={<AdName adName="Best Available Ad" />}
        AdPreview={<PreviewLoader />}
      />
    );
  } else {
    return (
      <Layout
        AdName={<AdName adName="Best Available Ad" />}
        AdPreview={<Ad showEditor={showEditor} />}
        ActionPanel={
          <ActionPanel
            showEditor={showEditor}
            handleBoostModal={handleBoostModal}
            toggleEditor={toggleModify}
          />
        }
        StrikeZone={
          <StrikeZoneContainer
            handleSelection={handleSelection}
            toggleTooltip={toggleTooltip}
          />
        }
        PerformancePanel={
          selectedType === "" ? null : (
            <TestingPerformancePanel
              selection={selection}
              label={selectedType}
            />
          )
        }
      />
    );
  }
};

TestingAd.propTypes = {
  handleSelection: PropTypes.func.isRequired,
  handleBoostModal: PropTypes.func,
  toggleTooltip: PropTypes.func,
  fetchPreview: PropTypes.func,
  fetchAdToCreate: PropTypes.func,
  selectedType: PropTypes.string,
  selection: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const Wrapped_ = withStrikezoneMethods(TestingAd);

export default Wrapped_;

export const TestingAdContainer = () => {
  const {
    ad: { testing_information },
  } = useAd();

  let [show, setShow] = useState(false);
  let [adName, setAdName] = useState("");

  const handleModal = (name) => {
    setAdName(name);
    setShow(!show);
  };

  return (
    <>
      <Wrapped_
        testing_information={testing_information}
        handleBoostModal={handleModal}
      />
      <BoostModal
        id="wtt-boost-modal"
        show={show}
        adName={adName}
        handleHide={(e) => handleModal("")}
      />
    </>
  );
};

const StrikeZoneContainer = ({ handleSelection, toggleTooltip }) => {
  const {
    ad: { atf, btf, cta, visual },
  } = useAd();

  const props = {
    toggleTooltip,
    handleSelection,
    ...(atf && { atf, btf, cta, visual }),
  };

  return (
    <div style={PERF_WRAPPER}>
      <TestingStrikezone {...props} />
    </div>
  );
};

StrikeZoneContainer.propTypes = {
  handleSelection: PropTypes.func.isRequired,
  toggleTooltip: PropTypes.func,
};

const PreviewLoader = () => {
  const {
    ad: {
      visual: { image_url },
    },
  } = useAd();

  return (
    <div
      className="p-3 bg-white darwin-box-shadow d-flex flex-col justify-content-center align-items-center"
      style={{ position: "relative", width: "510px", cursor: "wait" }}
    >
      <h3>Fetching ad preview...</h3>
      <img
        src={image_url}
        alt="Image of ad currently downloading"
        style={{ width: "250px" }}
      />
    </div>
  );
};

const PreviewError = ({ error }) => (
  <div
    className="p-3 bg-white darwin-box-shadow d-flex flex-col"
    style={{ position: "relative", width: "510px" }}
  >
    <h3>An error occurred</h3>
    <p>{JSON.stringify(error)}</p>
  </div>
);
