import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import { arrayEquals, getIndexFromRight } from './utils';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import Card from './Card';

import './InsightsDashboard.scss';
import './InsightsDashboard.EditMode.scss';
import { AudienceBreakdown } from './AudienceBreakdown/AudienceBreakdown';
import { useCreativeAnalyticsGet } from '../CreativeAnalytics/contexts';
import clsx from 'clsx';
import { AddCategories } from './AddCategories/AddCategories';
import usePortal from 'react-cool-portal';
import { ErrorBoundary } from '../../components/Helpers/Error';
import { Grid, Item, SortableItem } from './Grid';
import AddCardIcon from './assets/AddCardIcon';
import { ExitEditMode } from './ExitEditMode';

export const Wrapper = ({
  cards,
  isROAS,
  libraryAnalyticsState,
  editMode,
  setEditMode,
  config,
  setConfig,
  messages,
}) => {
  const { Portal: ToolbarPortal } = usePortal({
    containerId: 'dashboard-toolbar-root',
    defaultShow: true,
    clickOutsideToHide: false,
  });
  const mounted = useRef(false);
  const [showAddCategoriesModal, setShowAddCategoriesModal] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState([]);
  const { requestOpen } = useCreativeAnalyticsGet();
  const {
    openLibraryAnalytics,
    setOpenLibraryAnalytics,
    setSelectedCategory,
    setSelectedFromDashboard,
  } = libraryAnalyticsState;

  useEffect(() => {
    if (editMode) setExpandedCategories([]);
  }, [editMode])

  const [activeId, setActiveId] = useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(MouseSensor),
    useSensor(TouchSensor)
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id);
  }, []);

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setConfig((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        let newState = arrayMove(items, oldIndex, newIndex);
        return newState;
      });
    }

    setActiveId(null);
  }, []);

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    }

    return () => (mounted.current = false);
  }, []);

  const cardsLookup = useMemo(() => {
    return cards.reduce((acc, cardData) => {
      let Render = null;

      const openAnalyticsTable = () => {
        setOpenLibraryAnalytics(true);
        setSelectedCategory(cardData.category || null);
        setSelectedFromDashboard(true);
      };

      const remove = () => {
        setConfig((prev) => {
          let newState = prev.filter((category) => {
            const maybe = category.id === cardData.id;

            return !maybe;
          });

          return newState;
        });
      };

      const openCategoryInNewTab = () => {
        const currentUrl = window.location.href;

        const fieldsToUpdate = [
          { param: 'category', value: cardData.category },
          { param: 'display', value: 'performance_frequency' },
        ];
    
        const url = new URL(currentUrl);

        for (const field of fieldsToUpdate) {
          url.searchParams.set(field.param, field.value);
        }
    
        window.open(url.toString(), '_blank');
      };

      if (cardData.isAudienceBreakdown) {
        Render = (
          <AudienceBreakdown
            category={cardData.category || ''}
            {...{
              cardData,
              openAnalyticsTable,
              isROAS,
              remove,
              editMode,
              openCategoryInNewTab,
            }}
            amountOfCards={cards.length}
          />
        );
      } else {
        Render = (
          <Card
            {...{
              cards,
              cardData,
              isROAS,
              openAnalyticsTable,
              expandedCategories,
              setExpandedCategories,
              editMode,
              remove,
              activeId,
              openCategoryInNewTab,
            }}
            amountOfCards={cards.length}
          />
        );
      }

      acc[cardData.id] = Render;

      return acc;
    }, Object.create(null));
  }, [cards, editMode]);

  const cardIds = Object.keys(cardsLookup).map((id) => id);

  return (
    <ErrorBoundary fallback={<h5>An error occurred :(</h5>}>
      <div
        className={clsx('insights', {
          'insights--condensed': openLibraryAnalytics || requestOpen,
          'edit-mode': editMode,
        })}
      >
        {editMode && (
          <ToolbarPortal>
            <ul className="toolbar__messages">
              {messages.map(({ msg, ellipses }) => (
                <li className={`${ellipses ? 'loading-text' : ''}`}>{msg}</li>
              ))}
            </ul>
            {!showAddCategoriesModal && <ExitEditMode {...{ setEditMode }} />}
          </ToolbarPortal>
        )}
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          <SortableContext items={cardIds} strategy={rectSortingStrategy}>
            <Grid>
              {cardIds.length < 1 && (
                <div>
                  <p className="">No data available.</p>
                </div>
              )}
              {Object.entries(cardsLookup).map(([id, Render]) => {
                const indexFromRight = getIndexFromRight(
                  id,
                  expandedCategories
                );
                return (
                  <SortableItem
                    key={id}
                    id={id}
                    editMode={editMode}
                    cardWrapperProps={{
                      isExpanded: expandedCategories.includes(id),
                      amountOfCards: cards.length,
                      indexFromRight,
                    }}
                  >
                    {Render}
                  </SortableItem>
                );
              })}
              {editMode && (
                <div
                  onClick={() => setShowAddCategoriesModal(true)}
                  className="insights-card add-new-card"
                >
                  <AddCardIcon />
                  <p>Add New Category</p>
                </div>
              )}
            </Grid>
          </SortableContext>
          <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
            {activeId ? (
              <Item id={activeId} isDragging>
                {cardsLookup[activeId]}
              </Item>
            ) : null}
          </DragOverlay>
        </DndContext>

        {showAddCategoriesModal && (
          <AddCategories
            {...{
              cards,
              showAddCategoriesModal,
              setShowAddCategoriesModal,
              setConfig,
            }}
          />
        )}
      </div>
    </ErrorBoundary>
  );
};
