// @ts-nocheck
import React from 'react';
import { collapseToast, toast } from 'react-toastify';
import { useNotifications } from '../../../../../components/Notification/useNotifications';

export function makeEmptyLabel() {
  return {
    name: '',
    filters: [
      {
        name: 'ad',
        include: '',
        exclude: '',
      },
    ],
  };
}
const formatLabels = (labels) => {
  return labels.map((label) => {
    let ad = {};

    label.filters.forEach(({ name, include, exclude }) => {
      if (name === 'ad') {
        ad.include = include;
        ad.exclude = exclude;
      }
    });

    return {
      name: label.name,
      parameters: {
        ad,
      },
    };
  });
};

export const makeCreatePayload = (labels, account_id) => {
  return {
    platform_account_id: account_id,
    ad_name_labels: formatLabels(labels),
  };
};

const NAME = 'name';
const LABELS = 'labels';

export const makeUpdatePayload = (
  input,
  labels,
  updatedFields,
  account_id,
  existing
) => {
  let updates = [];

  [NAME].forEach((field) => {
    if (updatedFields[field]) {
      const f = field === NAME ? 'category_name' : field;
      updates.push({
        field_name: f,
        value: input[field],
        type: 'category',
      });
    }
  });

  if (updatedFields[LABELS]) {
    updates.push({
      field_name: 'ad_name_labels',
      value: formatLabels(labels),
      type: 'label',
    });
  }

  return {
    platform_account_id: account_id,
    updates,
  };
};

export const formatExistingLabels = (labels) => {
  let result = [];
  if (!labels) return result;

  labels.forEach((label) => {
    result.push({
      name: label.name,
      filters: Object.entries(label.parameters).map(([key, val]) => {
        return {
          name: key,
          ...val,
        };
      }),
    });
  });

  return result;
};

export const notifySaving = (name) => {
  const notifications = useNotifications();

  notifications.add({
    id: `ad-ccc-loading`,
    title: name,
    message: 'Saving category',
    showEllipses: true,
  });
};

export const notifyError = (name, error) => {
  const notifications = useNotifications();

  notifications.add({
    title: name,
    message: error,
    toastProps: {
      className: 'error-toast',
    },
  });
};

export const notifyCreateSuccess = (refresh) => {
  const notifications = useNotifications();

  const message = (
    <>
      Ad name labels have been successfully saved.{' '}
      <span
        style={{
          color: '#e1e3ff',
          cursor: 'pointer',
        }}
        onClick={refresh}
      >
        Click here
      </span>{' '}
      to refresh your page to view the changes.
    </>
  );

  notifications.add({
    title: name,
    message,
  });
};
