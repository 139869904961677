import React from 'react';
import PropTypes from 'prop-types';

import Overlay from '../Overlay';

const MODAL_STYLE = {
  borderRadius: '15px',
};

const YELLOW_BAR = {
  borderBottom: '4px solid #ffcb36',
};

const Modal = ({
  show,
  id,
  Body,
  Footer,
  modalClass,
  footerStyle,
  Title,
  Header,
  labelledby,
  allowClose,
  handleHide,
}) => {
  const renderHeader = Header ? (
    Header
  ) : Title ? (
    <div className="modal-header" style={YELLOW_BAR}>
      <div className="modal-title" id={labelledby}>
        {Title}
      </div>
      {allowClose && (
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={handleHide}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      )}
    </div>
  ) : null;

  return (
    <Overlay {...{ show, id, labelledby }}>
      <div
        className="modal"
        id={id}
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby={labelledby}
        aria-hidden="true"
        style={{ display: 'block' }}
      >
        <div
          className={`modal-dialog modal-dialog-centered ${modalClass}`}
          role="document"
        >
          <div className="modal-content" style={MODAL_STYLE}>
            {renderHeader}
            {Body && <div className="modal-body">{Body}</div>}
            {Footer && (
              <div className="modal-footer" style={footerStyle}>
                {Footer}
              </div>
            )}
          </div>
        </div>
      </div>
    </Overlay>
  );
};

Modal.defaultProps = {
  modalClass: 'modal-lg',
  Footer: null,
  Title: null,
  Header: null,
  Body: null,
  labelledby: '',
  allowClose: true,
  footerStyle: {},
};

Modal.propTypes = {
  Title: PropTypes.node,
  Header: PropTypes.node,
  Body: PropTypes.node,
  Footer: PropTypes.node,
  labelledby: PropTypes.string,
  id: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  handleHide: PropTypes.func.isRequired,
  modalClass: PropTypes.string,
  allowClose: PropTypes.bool,
  footerStyle: PropTypes.shape({ color: PropTypes.string }),
};

export default Modal;
