import React, { useMemo } from 'react';
import {
  useCreativeAnalyticsGet,
  useCreativeAnalyticsSet,
} from '../../contexts';
import { LabelObject } from '../../types';

import './CategoryDetails.scss';
import { CategoriesLookupItem, VisualsLookupItem } from '../types';
import { useReportCardContext } from '../contexts';
import { useViewName } from '../../selectors';
import { getColumnHeaders } from './columns';
import { DetailsTable } from './DetailsTable';
import { DarwinIndexTable } from './DarwinIndexTable';
import { formatMetric, formatTypeMetric } from '../utils';
import { STANDARD_CATEGORIES_LOOKUP } from '../../categories';
import { BackIcon, EditIcon } from '../icons';
import Tooltip from '../../../../components/Tooltip';
import { formatMetricLabel } from '../../utils/formatMetricLabel';
import { useViewSettings } from '../../../../selectors';

type CategoryDetailsProps = {
  current: VisualsLookupItem;
  showCategoryDetails: string;
  setShowCategoryDetails: (value: string) => void;
  fromCreativeStudio: boolean;
  isRoas: boolean;
};

const CategoryDetails = ({
  current,
  showCategoryDetails,
  setShowCategoryDetails,
  fromCreativeStudio,
  isRoas,
}: CategoryDetailsProps) => {
  if (!current || !showCategoryDetails) return <div></div>;

  const { isROAS, isDirectMail, allVisuals, shouldShowBoxPlot, isGoHealth } =
    useCreativeAnalyticsGet();
  const { setEditCategoryVisual, setEditCategory } = useCreativeAnalyticsSet();
  const isROASClient = fromCreativeStudio ? isRoas : isROAS;
  const viewName = useViewName();
  const isCompetitorView =
    isDirectMail && viewName.toLowerCase().includes('competitor');
  const isOrganicView =
    isDirectMail && viewName.toLowerCase().includes('organic');
  const isDuff = isCompetitorView || isOrganicView;
  const { data_type, primary_metric } = useReportCardContext();
  const category: CategoriesLookupItem = useMemo(
    () =>
      current.categories.find(
        (c: CategoriesLookupItem) => c.category === showCategoryDetails
      ),
    [current, showCategoryDetails]
  );

  let selectedMetric = formatMetric(
    primary_metric,
    isROASClient,
    isGoHealth,
    data_type
  );
  let typeMetric = formatTypeMetric(
    data_type,
    primary_metric,
    isDuff,
    isGoHealth
  );
  let dataTypeLabel = data_type && data_type === 'aggregate' ? '' : 'Median';
  let dataMetricLabel = primary_metric
    ? primary_metric.includes('OppAttempted')
      ? 'Cost per Opp Attempted'
      : selectedMetric
    : 'CPA';
  if (dataMetricLabel.includes('cp'))
    dataMetricLabel = formatMetricLabel(dataMetricLabel);

  const isStudio =
    window.location.pathname.includes('studio') ||
    window.location.pathname.includes('marketplace');
  const isDerivative = category.labels.some((l) => l.value.startsWith('FX_'));
  const isSynonym = category.labels.some((l) => l.synonym);
  const isStandard = !!STANDARD_CATEGORIES_LOOKUP[category.category];
  const categoryIsEditable =
    !category.isUdc && !isStandard && !isStudio && category.ccc && !isSynonym;

  if (isCompetitorView || isOrganicView) {
    dataTypeLabel = '';
  }

  if (isOrganicView) {
    dataMetricLabel = `${dataMetricLabel} per post`;
  }

  const hasIndexData = category.labels.some((label) => {
    return label.average_cpa_quintile || label.average_percent_spend;
  });

  const handleEditCategory = () => {
    if (isDerivative) {
      setEditCategory({
        category: category.category,
        category_id: category.category_id,
        ccc_type: 'derived_labels',
        shouldShowBoxPlot,
      });
    } else {
      setEditCategoryVisual({
        category: isStandard
          ? STANDARD_CATEGORIES_LOOKUP[category.category]
          : category.category,
        category_id: category.category_id,
        visual_hash: current.data.id,
        ccc_type: 'ai_driven',
        type: isStandard ? 'standard' : 'custom',
        show: true,
        shouldShowBoxPlot: false,
      });
    }
  };

  const columnHeaders = getColumnHeaders(
    dataTypeLabel,
    dataMetricLabel,
    isOrganicView,
    isCompetitorView
  );

  return (
    <>
      <div className="category-details__back">
        <button onClick={() => setShowCategoryDetails(null)}>
          <BackIcon />
        </button>
      </div>
      <div>
        <div className="subheader subheader--sm">
          {category.category}
          {categoryIsEditable && (
            <Tooltip content="Edit category" delay={[500, 0]}>
              <span className="edit-icon" onClick={handleEditCategory}>
                <EditIcon />
              </span>
            </Tooltip>
          )}
        </div>
        <DetailsTable
          {...{
            category,
            columnHeaders,
            fromCreativeStudio,
            current,
            isCompetitorView,
            isOrganicView,
            typeMetric,
            selectedMetric,
            isDuff,
          }}
        />
        {!isDirectMail && hasIndexData && (
          <DarwinIndexTable
            isCPATable={true}
            tableMetric={isROASClient ? 'roas' : 'cpa'}
            {...{
              category,
              fromCreativeStudio,
              current,
              isCompetitorView,
              isOrganicView,
              isROASClient,
              allVisuals,
              typeMetric,
            }}
          />
        )}
        {!isDirectMail && hasIndexData && (
          <DarwinIndexTable
            tableMetric="percent_spend"
            {...{
              category,
              fromCreativeStudio,
              current,
              isCompetitorView,
              isOrganicView,
              isROASClient,
              allVisuals,
            }}
          />
        )}
      </div>
    </>
  );
};

export default CategoryDetails;
