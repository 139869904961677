export const getAggregate = (
  ads,
  metric,
  clientMetrics = [],
  isDirectMail = false
) => {
  const shouldAddClientMetric = clientMetrics.length;
  const customMetrics = {};

  const calculateMetricValue = (numerator, denominator) => {
    return denominator > 0 ? numerator / denominator : 0;
  };

  const calculateTotals = (requiredMetrics) => {
    const totals = {
      spend: 0,
      results: 0,
      impressions: 0,
      clicks: 0,
      sessions: 0,
      leads: 0,
      recallers: 0,
    };

    ads.forEach((ad) => {
      if (!ad) return;
      if (requiredMetrics.includes('spend')) totals.spend += ad.spend;
      if (requiredMetrics.includes('results')) totals.results += ad.results;
      if (requiredMetrics.includes('impressions'))
        totals.impressions += ad.impressions;
      if (requiredMetrics.includes('clicks')) totals.clicks += ad.clicks;
      if (requiredMetrics.includes('sessions'))
        totals.sessions += ad.ga_landing_page_views;
      if (requiredMetrics.includes('leads')) totals.leads += ad.leads;
      if (requiredMetrics.includes('recallers'))
        totals.recallers += ad.estimated_ad_recallers;
      if (shouldAddClientMetric) {
        clientMetrics.forEach((metric) => {
          customMetrics[`total_${metric}`] += ad[metric];
        });
      }
    });

    return totals;
  };

  const requiredMetricsMap = {
    cpa: ['spend', 'results'],
    roas: ['spend', 'results'],
    cpm: ['spend', 'impressions'],
    cpc: ['spend', 'clicks'],
    ctr: ['clicks', 'impressions'],
    ga_ctr: ['sessions', 'impressions'],
    cpik: ['leads', 'impressions'],
    cp_recallers: ['spend', 'recallers'],
    conversion_rate: ['results', 'clicks'],
    default: clientMetrics,
  };

  const requiredMetrics =
    requiredMetricsMap[metric] || requiredMetricsMap.default;

  const totals = calculateTotals(requiredMetrics);

  switch (metric) {
    case 'cpa':
      return calculateMetricValue(totals.spend, totals.results);
    case 'roas':
      return calculateMetricValue(totals.results, totals.spend);
    case 'cpm':
      return calculateMetricValue(totals.spend, totals.impressions / 1000);
    case 'cpc':
      return calculateMetricValue(totals.spend, totals.clicks);
    case 'ctr':
      return calculateMetricValue(totals.clicks, totals.impressions);
    case 'ga_ctr':
      return calculateMetricValue(totals.sessions, totals.impressions);
    case 'cpik':
      return calculateMetricValue(totals.leads * 1000000, totals.impressions);
    case 'cp_recallers':
      return calculateMetricValue(totals.spend, totals.recallers);
    case 'conversion_rate':
      const conversionRate = calculateMetricValue(
        totals.results,
        totals.clicks
      );
      return (conversionRate * 100).toFixed(2) + '%';
    default:
      const total = ads.reduce((total, ad) => (total += ad[metric] ?? 0), 0);
      if (metric.startsWith('cp_')) {
        return calculateMetricValue(total, totals.spend);
      } else {
        return total;
      }
  }
};
