import React, { useState } from 'react';
import { useCopilotPayload } from '../hooks/useCopilotPayload';
import { useCategories } from '../hooks';
import Button from '../../../components/Button';
import { RATIO, FORMAT } from './constants';
import { SelectIcon } from '../../CreativeStudio/assets';
import { useCreativeAnalyticsGet } from '../contexts';

const Wizard = ({
  currentRequest,
  open,
  copilotRequestSuccess,
  copilotLoading,
  copilotRequestError,
  copilotRequest,
  showWizard,
  setShowWizard,
  wizard,
  currentIndex,
  handleInstructions,
  handleFeedback,
  requestWidth,
}) => {
  const { columns, handleColumns, display, isROAS } = useCreativeAnalyticsGet();
  const { additionalInstructions, previousOutput, userFeedback } =
    wizard[currentIndex];

  const dataPoints = currentRequest.data_points.map(
    ({ labelSnapshot }, i) =>
      `${i + 1}. Label: ${labelSnapshot.name}, ${
        isROAS ? 'ROAS' : 'CPA'
      }: ${labelSnapshot.aggregate_cpa.toFixed(2)}, Spend: ${
        labelSnapshot.percent_budget
      }, Wins/All: ${labelSnapshot.numerator}/${labelSnapshot.denominator} `
  );

  const ratios = Array.isArray(currentRequest[RATIO])
    ? currentRequest[RATIO]
    : Object.entries(currentRequest[RATIO])
        .filter(([k, v]) => v > 0)
        .map(([k, v]) => k);

  const allVisualsAggregate = isROAS
    ? currentRequest.data_points[0]?.allVisualsSnapshot.aggregate_roas
    : currentRequest.data_points[0]?.allVisualsSnapshot.aggregate_cpa;

  const copilotPayload = useCopilotPayload({
    indexData: false,
    isRevision: previousOutput.length ? true : false,
    userInstructions: `Here are some instructions on what the client definitely wants included in the creative request. Ratio: ${ratios}; Format: ${
      currentRequest[FORMAT]
    }; ${
      currentRequest.data_points?.length
        ? `Data Points: ${dataPoints}. The overall ${
            isROAS ? 'ROAS' : 'CPA'
          } for all visuals is ${
            isROAS ? '' : '$'
          }${allVisualsAggregate?.toFixed(2) || 'N/A'}`
        : ''
    }; Additional instructions: ${additionalInstructions || 'None'}`,
    previousOutput: previousOutput || 'None',
    userFeedback: userFeedback || 'None',
    requestType: 'creative request',
    visualExample: null,
    includeDna: false,
  });

  const fetchCopilot = () => {
    copilotRequest(copilotPayload);
  };

  const handleAddPlanningCol = () => {
    if (columns.includes('planning')) return;

    handleColumns({
      column: 'planning',
      checked: !columns.includes('planning'),
    });
  };

  return (
    <div
      className={`wizard`}
      style={{
        right: showWizard ? requestWidth : '0',
        width: showWizard ? '360px' : '0',
        maxWidth: showWizard ? '360px' : '0',
        overflow: 'hidden',
        padding: showWizard ? '1.5rem' : '0',
      }}
    >
      <div className="d-flex align-items-center justify-content-between">
        <span>Write a creative request using the Wizard.</span>
        <i
          className="fa fa-close"
          style={{ cursor: 'pointer' }}
          onClick={() => setShowWizard(false)}
        />
      </div>
      <div className="instructions">
        <div
          className="section-label"
          style={{
            color:
              copilotRequestSuccess && previousOutput.length
                ? '#616161'
                : '#1E2B4E',
          }}
        >
          Additional instructions:
        </div>
        <textarea
          className="text-input"
          value={additionalInstructions}
          onChange={(e) => handleInstructions(e.target.value)}
          disabled={copilotRequestSuccess || copilotLoading}
          style={{
            borderColor:
              copilotRequestSuccess && previousOutput.length
                ? '#ecf1f9'
                : '#9E9E9E',
          }}
        />
      </div>
      {previousOutput.length ? (
        <div className="feedback">
          <div className="section-label">Feedback:</div>
          <textarea
            className="text-input"
            value={userFeedback}
            onChange={(e) => handleFeedback(e.target.value)}
            disabled={copilotLoading}
          />
        </div>
      ) : null}
      <div>
        <Button
          onClick={fetchCopilot}
          loading={copilotLoading}
        >
          Create request {` >`}
        </Button>
        {copilotRequestError && <span>There was an error.</span>}
      </div>
      <div style={{ fontSize: '12px' }}>
        Note: This will replace text that is currently in the request
        description.
      </div>
    </div>
  );
};

export default Wizard;
