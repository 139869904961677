import { useSelector } from 'react-redux';

import { useUrlProps } from '../../features/CreativeReports/report-hooks';
import { useViewId } from '../Hooks/view-id';
import { useFetch } from '../Hooks/fetch';

/**
 * @param {String} entity
 * @param {String} lookback
 */
export function useApi(entity, lookback) {
  const viewId = useViewId();
  const { start_date, end_date, selectedAudience, spendThreshold } =
    useUrlProps();
  const { account_id, campaigns } = useSelector(
    ({ ViewSettings: { account_id, campaigns } }) => ({
      account_id,
      campaigns,
    })
  );

  const { error, loading, data } = useFetch(
    `/api/${viewId}/animated-scatter`,
    {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        entity,
        lookback,
        start_date,
        end_date,
        campaigns,
        audience: [selectedAudience].filter((s) => s !== ''),
        spendThreshold,
        account_id,
      }),
    },
    [entity, lookback]
  );

  return { error, loading, scatterData: data ? data.data : data };
}
