import React from 'react';
import { DARWIN_NAVY } from '../../../constants';

const RobotIcon = () => (
  <svg
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 15.251C18 16.4619 17.0291 17.4328 15.8182 17.4328C14.6073 17.4328 13.6364 16.4619 13.6364 15.251C13.6364 14.0401 14.6182 13.0692 15.8182 13.0692C17.0182 13.0692 18 14.051 18 15.251ZM8.18182 13.0692C6.98182 13.0692 6 14.051 6 15.251C6 16.451 6.98182 17.4328 8.18182 17.4328C9.38182 17.4328 10.3636 16.4619 10.3636 15.251C10.3636 14.0401 9.39273 13.0692 8.18182 13.0692ZM24 14.7056V17.9783C24 18.5783 23.5091 19.0692 22.9091 19.0692H21.8182V20.1601C21.8182 21.371 20.8473 22.3419 19.6364 22.3419H4.36364C3.78498 22.3419 3.23003 22.1121 2.82086 21.7029C2.41169 21.2937 2.18182 20.7388 2.18182 20.1601V19.0692H1.09091C0.490909 19.0692 0 18.5783 0 17.9783V14.7056C0 14.1056 0.490909 13.6147 1.09091 13.6147H2.18182C2.18182 9.39283 5.59636 5.97829 9.81818 5.97829H10.9091V4.59283C10.2545 4.22192 9.81818 3.51283 9.81818 2.70556C9.81818 1.50556 10.8 0.523743 12 0.523743C13.2 0.523743 14.1818 1.50556 14.1818 2.70556C14.1818 3.51283 13.7455 4.22192 13.0909 4.59283V5.97829H14.1818C18.4036 5.97829 21.8182 9.39283 21.8182 13.6147H22.9091C23.5091 13.6147 24 14.1056 24 14.7056ZM21.8182 15.7965H19.6364V13.6147C19.6364 10.6037 17.1927 8.16011 14.1818 8.16011H9.81818C6.80727 8.16011 4.36364 10.6037 4.36364 13.6147V15.7965H2.18182V16.8874H4.36364V20.1601H19.6364V16.8874H21.8182V15.7965Z"
      fill={DARWIN_NAVY}
    />
  </svg>
);

export default RobotIcon;
