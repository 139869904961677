import React, { useMemo } from "react";
import "../Tables.scss";
import { useTable, useSortBy, useFlexLayout } from "react-table";
import Tippy from "@tippyjs/react";
import { CombinedIcon } from "../../Helpers";
import { useImpactReportColumns } from "../Columns";
import Tooltip from "../../../../../components/Tooltip";

const ImpactReportTable = ({
  sortedCategories,
  handleSelectCategory,
  selectedCategory,
  showAll,
}) => {
  const memoed = useMemo(
    () => ({ cols: useImpactReportColumns(), rows: sortedCategories }),
    [sortedCategories]
  );

  const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } =
    useTable(
      {
        columns: memoed.cols,
        data: memoed.rows,
      },
      useSortBy,
      useFlexLayout
    );

  return (
    <table {...getTableProps()} className="impact-report__table">
      <thead className="frequency-table__header">
        {headerGroups.map((headerGroup) => (
          <>
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return <HeaderRow {...{ column }} />;
              })}
            </tr>
          </>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);

          return (
            <Row
              handleClick={handleSelectCategory}
              active={selectedCategory === row.original.name}
              {...{ row, i, showAll }}
            />
          );
        })}
      </tbody>
    </table>
  );
};

export default ImpactReportTable;

export const HeaderRow = ({ column }) => {
  let Header = <div>{column.Header}</div>;

  if (column.Header === "Individual Impact % (r2)") {
    Header = (
      <div>
        Individual Impact % (r<span className="superscript">2</span>){" "}
        <span className="asterisk">*</span>
      </div>
    );
  }

  return (
    <th
      {...column.getHeaderProps(column.getSortByToggleProps())}
      className={`frequency-table__header table-header ${
        !column.Header ? "included" : ""
      } table-header--${
        column.Header === "Category" ? "category" : column.id.split("_")[0]
      }`}
    >
      <div className="table-header__text">
        <Tooltip
          disabled={column.id !== "score"}
          content="This is the % of performance that can be explained by this single category."
          className="impact-report__tooltip"
          // delay={[500, 0]}
          follow={false}
        >
          <div>{Header}</div>
        </Tooltip>
      </div>
    </th>
  );
};

export const Row = ({ handleClick, active, row, i, showAll }) => {
  const isCategory = row.original.label_breakdown;

  return (
    <tr
      className={`frequency-table__row table-row ${active ? "active" : ""} ${
        isCategory && !showAll && !row.original.included_in_combined
          ? "hidden"
          : ""
      }`}
      onClick={() => handleClick(row.original.name)}
    >
      {row.cells.map((cell) => (
        <Cell cell={cell} i={i} />
      ))}
    </tr>
  );
};

export const Cell = ({ cell, i }) => {
  const {
    column: { id: columnId },
    row: {
      original: { included_in_combined: isIncluded },
    },
  } = cell;

  let Cell = (
    <td {...cell.getCellProps()} className={`table-cell table-cell--${columnId}`}>
      {/* <span
        className={`${
          ["score", "budget_percentage", "cpa", "roas"].includes(columnId)
            ? " right-align"
            : ""
        }`}
      > */}
        {cell.render("Cell")}
      {/* </span> */}
    </td>
  );

  if (columnId === "included_in_combined") {
    Cell = (
      <td {...cell.getCellProps()} className="table-cell included">
        <Tooltip
          content="Included in combined impact %"
          disabled={!isIncluded}
          className="impact-report__tooltip"
          // delay={[500, 0]}
          follow={false}
        >
          <div
            className={`table-cell-inner table-cell-inner--included ${!isIncluded ? "hide" : ""}`}
            aria-label="Included in combined impact"
          >
            <CombinedIcon />
          </div>
        </Tooltip>
      </td>
    );
  }

  return <>{Cell}</>;
};
