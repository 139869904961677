import React, { useRef, useState, useEffect } from 'react';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { cloneDeep } from 'lodash';
import Button from '../../../components/Button';

import Overlay from '../../../components/Overlay';
import VisualsList from './VisualsList';

import { LabelObject, EditVisualObject } from '../types';
import { useVisuals, useStandardCategories } from './hooks';
import { useCreativeAnalyticsSet } from '../contexts';
import { PEOPLE_TRAITS } from '../categories';
import { MULTIPLE_LABELS, MUTUALLY_EXCLUSIVE } from './constants';

import { CloseIcon } from '../../../components/Icons';
import { makeUpdateOnePayload } from './payloads';
import { useDarwinClientId, usePlatform, useViewId } from './selectors';
import { useCustomCategoriesApi } from './api';
import { useUpdateOneHashApi } from '../api/updateOneHash';
import { STANDARD_CATEGORIES_LOOKUP } from '../categories.js';
import { useNotifications } from '../../../components/Notification/useNotifications';

type StandardCategoriesProps = {
  data: LabelObject[];
  category: string;
  singleVisual: string;
};

const getDefaultSelections = (visuals: EditVisualObject[]) => {
  let result = {};
  Object.entries(visuals)
    .sort((a, b) => (a.spend > b.spend ? 1 : -1))
    .forEach(([visual_hash, val]) => {
      result[visual_hash] = {
        asset: {
          asset_url: val.asset.asset_url,
          type: val.asset.asset_url,
          thumbbnail_url: val.asset.thumbnail_url || null,
        },
        labels: val.labels,
      };
    });
  return result;
};

// no sidebar with label count
// save button in bottom right
// back button, title at top

const Standard = ({
  data,
  category,
  singleVisual,
}: StandardCategoriesProps) => {
  const { setEditCategoryVisual } = useCreativeAnalyticsSet();
  const view_id = useViewId();
  const categoryType =
    category === PEOPLE_TRAITS ? MULTIPLE_LABELS : MUTUALLY_EXCLUSIVE;
  const [visuals, labelOptions] = useStandardCategories(
    data,
    category,
    categoryType,
    singleVisual
  );
  const [loadingMsg, setLoadingMsg] = useState(null);
  const [annotatedVisuals, setAnnotatedVisuals] = useState(
    getDefaultSelections(visuals)
  );
  const [singleUpdated, setSingleUpdated] = useState({});
  const input = {
    labels: labelOptions,
    type: categoryType,
  };
  const darwin_client_id = useDarwinClientId();
  const platform = usePlatform();
  const api = useCustomCategoriesApi();
  const updateOne = useUpdateOneHashApi();
  const notifications = useNotifications();

  const getLabels = () => {
    const key = Object.keys(STANDARD_CATEGORIES_LOOKUP).find(
      (k) => STANDARD_CATEGORIES_LOOKUP[k] === category
    );
    if (Object.values(STANDARD_CATEGORIES_LOOKUP).includes(category)) {
      return data.filter((x) => x.category === key);
    } else {
      return data.filter((x) => x.category === category);
    }
  };

  const labels = getLabels();
  const labelNames = labels.map(({ name }) => name);

  const handleSaveOne = () => {
    const payload = makeUpdateOnePayload({
      input,
      visual: singleVisual,
      category,
      custom_category: false,
      darwin_client_id,
      platform,
      view_id,
      labelOptions,
      annotatedVisuals,
    });
    updateOne.request(payload);
  };

  const handleSelect = (categoryType, label, visual_hash, val) => {
    const url = val.asset.url;
    const thumbnail_url = val.asset.thumbnail_url || null;
    const type = val.asset.type;

    if (
      annotatedVisuals[visual_hash] &&
      label === annotatedVisuals[visual_hash].labels
    ) {
      setAnnotatedVisuals((prev) => {
        setSingleUpdated({
          [label]: 'no',
        });

        let update = cloneDeep(prev);

        delete update[visual_hash];

        return update;
      });
    } else {
      setSingleUpdated({
        [label]: 'yes',
      });

      setAnnotatedVisuals((prev) => ({
        ...prev,
        [visual_hash]: {
          asset: {
            url,
            type,
            thumbnail_url,
          },
          labels: label,
        },
      }));
    }
  };

  const handleMultipleSelect = (
    categoryType,
    label,
    selection,
    visual_hash,
    val
  ) => {
    const url = val.asset.url;
    const type = val.asset.type;

    setAnnotatedVisuals((prev) => {
      const labels = prev[visual_hash] ? prev[visual_hash].labels : {};

      return {
        ...prev,
        [visual_hash]: {
          asset: {
            url,
            type,
          },
          labels: {
            ...labels,
            [label]: selection,
          },
        },
      };
    });

    setSingleUpdated((prev) => ({
      ...prev,
      [label]: selection,
    }));
  };

  useEffect(() => {
    if (updateOne.loading) {
      setLoadingMsg('Saving category...');
    }
  }, [updateOne.loading]);

  useEffect(() => {
    if (updateOne.data) {
      notifications.add({
        id: `save-success`,
        title: category,
        message:
          'Changes have been made to the labels. If you refresh your browser you can view the changes.',
      });
      setEditCategoryVisual(null);
    }
  }, [updateOne.data]);

  return (
    <div className="category-manager standard-categories">
      <Header category={category} />
      <VisualsList
        visuals={visuals}
        labelOptions={labelOptions}
        categoryType={
          category === PEOPLE_TRAITS ? MULTIPLE_LABELS : MUTUALLY_EXCLUSIVE
        }
        annotatedVisuals={annotatedVisuals}
        handleSelect={handleSelect}
        handleMultipleSelect={handleMultipleSelect}
      />
      <div className="standard-categories__footer">
        {loadingMsg && <p className="sidebar__msg">{loadingMsg}</p>}
        <Button className="standard-categories__save" onClick={handleSaveOne}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default Standard;

export const Header = ({ category }: { category: string }) => {
  const { setEditCategory, setEditCategoryVisual } = useCreativeAnalyticsSet();

  const handleClose = () => {
    setEditCategory(null);
    setEditCategoryVisual(null);
  };

  return (
    <div className="standard-categories__header pl-0">
      <div className="d-flex justify-content-between w-100">
        {/* <button className="standard-categories__back">
          <i className="fa fa-left-long"></i>
          Back!
        </button> */}
        {/* <h2>{category}</h2> */}
        <CloseIcon
          style={{
            transform: 'translateY(-10px) translateX(20px)',
            cursor: 'pointer',
          }}
          onClick={handleClose}
        />
      </div>
    </div>
  );
};

const Footer = ({ category }: { category: string }) => {
  const { setEditCategory, setEditCategoryVisual } = useCreativeAnalyticsSet();

  return (
    <div className="standard-categories__header">
      <div className="d-flex justify-content-between w-100">
        {/* <button className="standard-categories__back">
          <i className="fa fa-left-long"></i>
          Back!
        </button> */}
        <h2>{category}</h2>
        <CloseIcon
          style={{
            transform: 'translateY(-10px) translateX(20px)',
            cursor: 'pointer',
          }}
          onClick={handleClose}
        />
      </div>
    </div>
  );
};
