import React from 'react';

const TikTokIcon = ({ className = '', boring = false }) => {
  if (boring) {
    return (
      <svg
        width="24"
        height="27"
        viewBox="0 0 24 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M23.0839 11.1051C20.827 11.1105 18.6255 10.4066 16.7903 9.09301V18.2547C16.7897 19.9516 16.2711 21.6078 15.3037 23.0019C14.3364 24.396 12.9665 25.4616 11.3773 26.0562C9.78799 26.6507 8.05508 26.7459 6.41024 26.329C4.76541 25.9121 3.28705 25.003 2.17285 23.7232C1.05865 22.4434 0.361715 20.854 0.175236 19.1674C-0.0112441 17.4808 0.32162 15.7775 1.12932 14.2852C1.93702 12.793 3.18106 11.5828 4.69508 10.8167C6.20911 10.0505 7.92096 9.7648 9.60173 9.99779V14.6058C8.83261 14.3639 8.0067 14.3712 7.24197 14.6266C6.47723 14.8821 5.81277 15.3727 5.34347 16.0283C4.87417 16.6839 4.62404 17.4711 4.62879 18.2773C4.63353 19.0836 4.89292 19.8677 5.36991 20.5178C5.8469 21.1678 6.5171 21.6505 7.28479 21.897C8.05248 22.1434 8.87841 22.141 9.64464 21.89C10.4109 21.639 11.0782 21.1524 11.5513 20.4995C12.0245 19.8466 12.2792 19.061 12.2792 18.2547V0.344482H16.7903C16.7872 0.725437 16.8191 1.10587 16.8857 1.48098C17.0424 2.31835 17.3684 3.11494 17.8435 3.82202C18.3187 4.5291 18.9332 5.13181 19.6493 5.59329C20.668 6.26693 21.8625 6.62598 23.0839 6.62572V11.1051Z"
          fill="black"
        />
      </svg>
    );
  }

  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clip-path="url(#clip0_5082_227)">
        <path
          d="M10.3753 5.76119C11.3968 6.49746 12.6481 6.93068 13.9996 6.93068V4.30833C13.7438 4.30838 13.4887 4.28146 13.2385 4.228V6.29217C11.8871 6.29217 10.6359 5.85901 9.61421 5.12279V10.4743C9.61421 13.1514 7.46193 15.3215 4.80718 15.3215C3.81663 15.3215 2.89591 15.0195 2.1311 14.5016C3.00403 15.4016 4.22137 15.9599 5.5681 15.9599C8.22307 15.9599 10.3754 13.7899 10.3754 11.1126V5.76119H10.3753V5.76119ZM11.3143 3.11556C10.7922 2.5405 10.4495 1.79732 10.3753 0.975697V0.638428H9.65403C9.83559 1.68268 10.4549 2.57481 11.3142 3.11556H11.3143ZM3.81023 12.4473C3.51857 12.0617 3.36092 11.59 3.36163 11.105C3.36163 9.88058 4.34606 8.88781 5.56061 8.88781C5.78692 8.88769 6.0119 8.92273 6.22763 8.9917V6.3107C5.97553 6.27589 5.72112 6.26105 5.46682 6.26651V8.35324C5.25099 8.28422 5.02589 8.2492 4.79947 8.24941C3.58497 8.24941 2.6006 9.24207 2.6006 10.4666C2.6006 11.3325 3.09262 12.0821 3.81023 12.4473Z"
          fill="#FF004F"
        />
        <path
          d="M9.61425 5.12273C10.636 5.85895 11.8871 6.29211 13.2385 6.29211V4.22794C12.4841 4.0659 11.8164 3.66844 11.3143 3.11556C10.4549 2.57476 9.83562 1.68262 9.65406 0.638428H7.75952V11.1125C7.7552 12.3336 6.77247 13.3223 5.56054 13.3223C4.84643 13.3223 4.21194 12.9791 3.81016 12.4473C3.09266 12.0821 2.60058 11.3324 2.60058 10.4667C2.60058 9.24223 3.58495 8.24946 4.79945 8.24946C5.03215 8.24946 5.25642 8.28599 5.4668 8.3533V6.26657C2.85865 6.32091 0.761108 8.46976 0.761108 11.1126C0.761108 12.4319 1.28343 13.6278 2.13119 14.5017C2.896 15.0195 3.81666 15.3216 4.80727 15.3216C7.46208 15.3216 9.6143 13.1514 9.6143 10.4743V5.12279H9.61425V5.12273Z"
          fill="black"
        />
        <path
          d="M13.2385 4.22786V3.66985C12.5582 3.67089 11.8914 3.4788 11.3142 3.11553C11.8251 3.67951 12.4979 4.06843 13.2385 4.22797V4.22786ZM9.65398 0.638345C9.63668 0.538571 9.6234 0.43813 9.61417 0.337269V0H6.99825V10.4742C6.99409 11.6952 6.01136 12.6839 4.79937 12.6839C4.45579 12.6844 4.1169 12.6034 3.81008 12.4473C4.21187 12.979 4.84635 13.3222 5.56046 13.3222C6.77234 13.3222 7.75518 12.3336 7.75944 11.1126V0.6384H9.65398V0.638345ZM5.46689 6.26648V5.67233C5.2483 5.64219 5.02793 5.62711 4.8073 5.6272C2.15223 5.6272 0 7.79735 0 10.4742C0 12.1525 0.845852 13.6316 2.13123 14.5016C1.28346 13.6278 0.761141 12.4317 0.761141 11.1125C0.761141 8.46974 2.85862 6.32088 5.46689 6.26654V6.26648Z"
          fill="#00F2EA"
        />
      </g>
      <defs>
        <clipPath id="clip0_5082_227">
          <rect width="14" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TikTokIcon;
