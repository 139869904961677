import { combineReducers } from 'redux';

import { Marketplace } from './actions';
import projects from './Project.reducer';
import { assets } from './Assets/reducer';
import { versions } from './Versions/reducer';
import { options } from './Options/reducer';
import { editorOptions } from './EditorOptions/reducer';
import { assetChats } from './Chats/reducer';
import { previews } from './Previews/reducer';

export const MARKETPLACE_STATE = {
  projects: {
    ids: [],
    lookup: {},
  },
  assets: {
    ids: [],
    lookup: {},
    cachedView: '',
  },

  assetChats: {
    author: '',
    ids: [],
    lookup: {},
  },

  versions: {
    ids: [],
    lookup: {},
  },

  options: null,

  bulkOptions: null,
};

export const marketplaceReducer = combineReducers({
  options,
  projects,
  assets,
  versions,
  previews,
  bulkOptions,
  editorOptions,
  assetChats,
});

function bulkOptions(state = null, action) {
  switch (action.type) {
    case Marketplace.RESET_ASSETS:
      return null;

    case Marketplace.SET_BULK_OPTIONS:
      return setBulkOptions(action.data);

    case Marketplace.SET_BULK_OPTIONS:
      return setBulkOptions(action.data);

    default:
      return state;
  }
}

function setBulkOptions({ adsets, campaigns, lead_forms }) {
  return {
    campaigns:
      campaigns && campaigns.length > 0
        ? campaigns.map(({ id, name }) => ({ value: id, label: name }))
        : [],
    adsets:
      adsets && adsets.length > 0
        ? adsets
            .map(({ adset_id, adset_name, ...rest }) => ({
              value: adset_id,
              label: adset_name,
              ...rest,
            }))
            .sort((a, b) =>
              a.label === 'ASPID' ? -1 : b.label === 'ASPID' ? 1 : 0
            )
        : [],
    lead_forms,
  };
}
