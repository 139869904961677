import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { SELECT_STYLES } from '../../../constants';
import Button from '../../../../../components/Button';

export const OverridesSelect = ({
  visual_id,
  current,
  options,
  handleSaveOverride,
  loading,
}) => {
  const [selected, setSelected] = useState(current);

  useEffect(() => {
    setSelected(current);
  }, [visual_id, current]);

  const handleSelect = (selection: SelectOption[]) => {
    setSelected(selection);
  };


  return (
    <div className="d-flex">
      <Select
        isClearable
        options={options}
        styles={SELECT_STYLES}
        placeholder="Select label"
        value={selected || null}
        isSearchable
        onChange={handleSelect}
        // onCreateOption={handleCreate}
      />
      <Button
        onClick={() => {
          handleSaveOverride({
            label: selected?.value,
            visual: visual_id,
          });
        }}
        disabled={loading}
      >
        Save
      </Button>
    </div>
  );
};
