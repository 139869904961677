import { batch } from 'react-redux';

import { setLoader } from '../../Loaders/actions';
import { setError } from '../../Errors/actions';

import { AD_PREVIEW_TYPES } from './constants';

export const EDITOR_OPTIONS = 'Marketplace.editorOptions';

export const EditorOptions = {
  FETCH: `[${EDITOR_OPTIONS}] FETCH`,
  SET: `[${EDITOR_OPTIONS}] SET`,
};

export function editorOptionsFetch({ project_id }) {
  return { type: EditorOptions.FETCH, data: { project_id } };
}

export function editorOptionsSet({
  landing_page,
  utm_parameters,
  creative_options,
  stories_creative_options,
  carousel_options,
  carousel_choices,
  atf,
  btf,
  call_to_action,
  default_preview_type,
  ad_type,
}) {
  return {
    type: EditorOptions.SET,
    data: {
      landing_page,
      utm_parameters,
      creative_options,
      stories_creative_options,
      carousel_options,
      carousel_choices,
      atf,
      btf,
      call_to_action,
      default_preview_type,
      ad_type,
    },
  };
}

export function fetchEditorOptions(project_id, filename, version) {
  return async (dispatch) => {
    batch(() => {
      dispatch(setError(EDITOR_OPTIONS, null));
      dispatch(setLoader(EDITOR_OPTIONS, true));
    });

    try {
      const res = await fetch(
        [
          'api',
          'marketplace',
          'assets',
          project_id,
          'editor-options-asset',
        ].join('/'),
        {
          body: JSON.stringify({
            project_id,
            filename,
            version,
          }),
          method: 'POST',
          headers: { 'content-type': 'application/json' },
        }
      );
      const json = await res.json();

      if (res.status !== 200) {
        return batch(() => {
          dispatch(setError(EDITOR_OPTIONS, new Error(json.error)));
          dispatch(setLoader(EDITOR_OPTIONS, false));
        });
      }
      return batch(() => {
        dispatch(
          editorOptionsSet({
            ...json.data,
            default_preview_type: AD_PREVIEW_TYPES,
          })
        );
        dispatch(setLoader(EDITOR_OPTIONS, false));
      });
    } catch (e) {
      return batch(() => {
        dispatch(setError(EDITOR_OPTIONS, e));
        dispatch(setLoader(EDITOR_OPTIONS, false));
      });
    }
  };
}
