import React, { useEffect } from 'react';
import { OVERVIEW, DETAILS, CATEGORIES, ITERATIONS } from './constants';
import { REPORT_CARD_TAB } from '../../../components/Permissions/constants';
import { usePermissions } from '../../../components/Permissions/usePermissions';
import { isStaticReportCard } from './utils';

const Nav = ({
  activePage,
  setActivePage,
  fromCreativeStudio,
  isOrganicView,
  fetchCopilot,
  copilotData,
  copilotRequestError,
  copilotLoading,
  assetIterations,
}) => {
  const hasPermission = usePermissions();

  const allowReportCardTab =
    hasPermission(REPORT_CARD_TAB) || isStaticReportCard();

  let pages = [OVERVIEW];

  if (allowReportCardTab) {
    if (
      fromCreativeStudio ||
      isOrganicView ||
      (isStaticReportCard() && !copilotData)
    ) {
      pages = [CATEGORIES];
    } else {
      pages = [CATEGORIES, OVERVIEW];
    }
  }

  if (assetIterations?.length) pages = [...pages, ITERATIONS];

  useEffect(() => {
    if (!allowReportCardTab) fetchCopilot();
  }, []);

  return (
    <div className="report-card__nav">
      <ul className="nav-group">
        {pages.map((page) => {
          return (
            <NavItem
              {...{
                page,
                activePage,
                setActivePage,
                fetchCopilot,
                copilotData,
                copilotRequestError,
                copilotLoading,
              }}
            >
              {page}
              {page === OVERVIEW && <span className='beta'>BETA</span>}
            </NavItem>
          );
        })}
      </ul>
    </div>
  );
};

export default Nav;

const NavItem = ({
  page,
  activePage,
  setActivePage,
  fetchCopilot,
  copilotData,
  copilotRequestError,
  copilotLoading,
  children,
}) => {
  return (
    <li
      className={`nav-item ${page === activePage ? 'active' : 'inactive'}`}
      onClick={() => {
        if (!!activePage) setActivePage(page);
        if (
          page === OVERVIEW &&
          !copilotLoading &&
          (!copilotData || copilotRequestError)
        )
          fetchCopilot();
      }}
    >
      {children}
    </li>
  );
};
