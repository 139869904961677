
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useLazyLoader } from '../../../components/LazyLoader/useLazyLoader';

import { SkeletonType } from './feature-prop-types';
import { usePerformanceApi, useBlockSectionsApi, useGetSections } from './api';
import { LEFT_BORDER } from './constants';
import { useBlockParamsGet, useBlockParamsSet } from './Contexts';
import { useSelector } from 'react-redux';
import { useDarwinClientId } from './selectors';

import { ViewSelector } from '../../../components/Dropdowns';
import { GripIcon, ArrowsIcon } from '../../../components/Icons';
import { DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

export function BlockRow({
  Name,
  Options,
  id,
  numMetrics,
  numDmMetrics,
  numColumns,
  createRow,
  selected,
  platforms,
  fromRoadmap,
}) {
  const { breakdown, period1, period2, include_ga_data } = useBlockParamsGet();
  const {
    error, loading, data, request,
  } = usePerformanceApi();
  const blockSectionsApi = useBlockSectionsApi();
  const [domRef, visible, pause] = useLazyLoader();
  const darwin_client_id = useDarwinClientId();

  const colSpan = platforms !== 'direct mail' ? numColumns * numMetrics : numColumns * numDmMetrics;

  let renderTo = null;

  if (error) {
    console.log({ error })
    renderTo = (
      <td colSpan={colSpan} className="table-danger" style={{ border: 'none' }}>
        {error.error}
      </td>
    );
  } else if (loading) {
    renderTo = (
      <td colSpan={colSpan}>
        <i className="fa fa-hourglass-half" />
      </td>
    );
  } else {
    renderTo = createRow(data.column_data).map((val, i) => (
      <td key={`${val}-${i}`} style={i % numMetrics === 0 ? LEFT_BORDER : null}>
        {val}
      </td>
    ));
  }

  useEffect(() => {
    if (visible && breakdown 
      && (!fromRoadmap || (fromRoadmap && !data))
      ) {
      request({
        block_id: id,
        breakdown,
        dateRange1: period1.split(','),
        dateRange2: period2 ? period2.split(',') : [],
        include_ga_data,
        platform: platforms,
        darwin_client_id: darwin_client_id,
      });
    }
  }, [breakdown, visible, period1, period2, include_ga_data, platforms, darwin_client_id]);

  useEffect(() => {
    if (visible) {
      pause();
    }
  }, [visible]);

  useEffect(() => {
    if (blockSectionsApi.post.data) {
      // refresh when data changes
      blockSectionsApi.post.request(account_id);
    }
  }, [blockSectionsApi.post.data]);

  return (
    <tr ref={domRef} className={selected ? 'bg-primary text-white' : ''}>
      <td className="d-flex justify-content-between" style={{border: 'none'}}>
        {Name}
        {Options && Options}
      </td>
      {renderTo}
    </tr>
  );
}

BlockRow.defaultProps = {
  selected: false,
};

BlockRow.propTypes = {
  id: PropTypes.string.isRequired,
  Name: PropTypes.node.isRequired,
  numColumns: PropTypes.number.isRequired,
  numMetrics: PropTypes.number.isRequired,
  createRow: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  //platforms: PropTypes.list.isRequired,
};

function isPerformance({ category }) {
  return category.toLowerCase() === 'performance';
}

function getDefaultOrder(skeleton, sectionList) {
  if (sectionList.length === 0) {
    if (skeleton.length && isPerformance(skeleton[0])) {
      return skeleton;
    }
    return skeleton.sort((a, b) => {
      if (isPerformance(a)) {
        return -1;
      } if (isPerformance(b)) {
        return 1;
      }
      return 0;
    });
  }
  return skeleton.sort((a, b) => {
    return sectionList.indexOf(a.category) - sectionList.indexOf(b.category);
  });
}

export function BlockTable({
  Headers, MetricHeaders, skeleton, renderRow, onCreateBlock, creativeTest, onClose }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [sectionList, setSectionList] = useState([]);

  const setParams = useBlockParamsSet();
  const getSections = useGetSections();
  const saveSections = useBlockSectionsApi();

  useEffect(() => {
    getSections.get.request();
  }, []);

  useEffect(() => {
    if (getSections.get.data) {
      let { data } = getSections.get;
      data = JSON.parse(data);
      setSectionList(data.block_order);
    }
  }, [getSections.get.data]);

  const rows = getDefaultOrder(skeleton, sectionList);
  const { include_ga_data } = useBlockParamsGet();

  const handleCreateBlock = onCreateBlock;

  const { ga_enabled } = useSelector(({ ViewSettings: { ga_enabled } }) => ({
    ga_enabled,
  }));

  const handleIncludeGaData = () => {
    setParams({ include_ga_data: true });
  }

  const handleToggleSections = () => {
    setMenuOpen(!menuOpen);
  };

  const handleSubmit = (e) => {
    saveSections.post.request({
      sectionList
    });
    setMenuOpen((prev) => !prev);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result, e) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      sectionList,
      result.source.index,
      result.destination.index
    );

    setSectionList(items);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: 12,
    margin: `0 0 8px 0`,

    // change if dragging
    background: isDragging ? "#D9FFE3" : "#fff",
    color: isDragging ? "#000" : "#333",

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
    background: "#333",
    padding: 8,
    width: 250
  });

  const bodyClick = (e) => {
    if (document.getElementById('order-sections') && !document.getElementById('dropdown').contains(e.target) && (!document.getElementById('order-sections').contains(e.target))) {
      document.getElementById('dropdown').classList.remove('section-dragdrop-overlay--open');
      setMenuOpen(false);
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', bodyClick);

    return () => {
      document.body.removeEventListener('click', bodyClick);
    };
  }, []);

  const Dropdown = (
    <div id="dropdown" className={menuOpen ? 'section-dragdrop-overlay section-dragdrop-overlay--open' : 'section-dragdrop-overlay'} onClick={e => e.stopPropagation()}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              className="draggable"
            >
              {sectionList.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <GripIcon className="grip-icon" />
                      {item}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="d-flex justify-content-center mt-4">
        {/*<button type="button" className="btn btn-danger btn-danger-outline" onClick={handleToggleSections}>
          Close
        </button>*/}
        <button type="button" className="btn btn-primary btn-primary-outline ml-3" onClick={handleSubmit}>
          Save
        </button>
      </div>
    </div>
  )

  const OrderSections = (
    <div id="order-sections">
      <button className="btn btn-link section-dropdown" onClick={handleToggleSections}>
        <span className="fa-stack fa-1x">
          <i className="fa fa-circle fa-stack-1x" />
          <i className="fa fa-caret-down fa-stack-1x fa-inverse" />
        </span>
        Order Sections
      </button>
      {Dropdown}
    </div>
  )

  return (
    <table className="table table-bordered">
      <thead className="thead-dark">
        <tr>
          <td>
            {!creativeTest && (
              <>
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-link"
                    style={{ padding: 0 }}
                    onClick={handleCreateBlock}
                  >
                    <i className="fa fa-plus-circle" />
                    {' '}
                    Add
                  </button>
                  {OrderSections}
                </div>
              </>
            )}

            {ga_enabled && include_ga_data === false && (
              <button
                type="button"
                className="btn btn-link d-block"
                style={{ padding: 0 }}
                onClick={handleIncludeGaData}
              >
                Fetch GA Data
              </button>
            )}
          </td>
          {Headers}
        </tr>
      </thead>
      {rows.map(({ category, blocks }) => (
        <tbody key={category}>
          <tr className="thead-light">
            <th style={creativeTest ? {minWidth: "58%"} : null}>
              {!creativeTest ? category : ''}
            </th>
            {MetricHeaders}
          </tr>
          {blocks.map(({ block_id, block_name, block_platforms }) => (
            <React.Fragment key={block_id}>
              {renderRow({ id: block_id, name: block_name, platform: block_platforms || '' })}
            </React.Fragment>
          ))}
        </tbody>
      ))}
    </table>
  );
}

BlockTable.propTypes = {
  renderRow: PropTypes.func.isRequired,
  skeleton: SkeletonType.isRequired,
  Headers: PropTypes.arrayOf(PropTypes.node).isRequired,
  MetricHeaders: PropTypes.arrayOf(PropTypes.node).isRequired
};
