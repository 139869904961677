import React from 'react';
import { toCurrency } from '../../../../utils/numbers';
import {
  formatCell,
  formatMetric,
} from '../../../DNA/CreativePerformance/utils';

export const HeatmapGrid = ({
  data,
  minPerformance,
  maxNumOfCreatives,
  settings,
  xAxisLabels,
  yAxisLabels,
  primary_metric,
  maxPerformance,
  maxSpend,
}) => {
  const { metricsToDisplay } = settings;

  return (
    <div
      className="heatmap"
      style={{
        gridTemplateColumns: `repeat(${xAxisLabels?.length + 1 ?? 0}, auto)`,
      }}
    >
      <div className="heatmap__cell heatmap__label"></div>

      {xAxisLabels.map((label) => (
        <div className="heatmap__cell heatmap__label">
          <p title={label.label}>{label.label}</p>
        </div>
      ))}
      {data?.map((row, rowIndex) => (
        <>
          <div className="heatmap__cell heatmap__label align-items-end">
            <p title={yAxisLabels[rowIndex].label}>
              {yAxisLabels[rowIndex].label}
            </p>
          </div>
          {row.map((cell, cellIndex) => (
            <Cell
              {...{
                cell,
                cellIndex,
                metricsToDisplay,
                primary_metric,
                minPerformance,
                maxNumOfCreatives,
                settings,
                maxPerformance,
                maxSpend,
              }}
            />
          ))}
        </>
      ))}
    </div>
  );
};

const Cell = ({
  cell,
  cellIndex,
  metricsToDisplay,
  primary_metric,
  minPerformance,
  maxNumOfCreatives,
  settings,
  maxPerformance,
  maxSpend,
}) => {
  const { organizeAndColor } = settings;
  const { lightness, hue } = getColorValues(
    cell,
    maxNumOfCreatives,
    maxPerformance,
    maxSpend,
    organizeAndColor,
    primary_metric,
    minPerformance
  );

  return (
    <div
      className="heatmap__cell"
      key={cellIndex}
      style={{
        backgroundColor: `hsl(${hue}, 74%, ${lightness}%)`,
        border: cell ? '1px solid #ECF0F9' : 'none',
      }}
    >
      {metricsToDisplay.spend && (
        <p>
          <MetricLabel>Spend: </MetricLabel>
          {toCurrency(cell.spend)}
        </p>
      )}
      {metricsToDisplay.primaryMetric && (
        <p>
          <MetricLabel>{formatMetric(primary_metric)}: </MetricLabel>
          {formatCell(primary_metric, cell.performance)}
        </p>
      )}
      {metricsToDisplay.numOfCreatives && (
        <p>
          <MetricLabel>n=</MetricLabel>
          {cell.num}
        </p>
      )}
    </div>
  );
};

const MetricLabel = ({ children }) => (
  <span className="heatmap__cell__label">{children}</span>
);

const getColorValues = (
  cell,
  maxNumOfCreatives,
  maxPerformance,
  maxSpend,
  organizeAndColor,
  primary_metric,
  minPerformance
) => {
  const percentage = getPercentage(
    cell,
    maxNumOfCreatives,
    maxPerformance,
    maxSpend,
    organizeAndColor,
    primary_metric,
    minPerformance
  );

  return calculateHueAndLightness(percentage);
};

const getPercentage = (
  cell,
  maxNumOfCreatives,
  maxPerformance,
  maxSpend,
  organizeAndColor,
  primary_metric,
  minPerformance
) => {
  switch (organizeAndColor) {
    case 'numOfCreatives':
      const x = cell.num / maxNumOfCreatives;
      return 0.5 * (1 + x);
    case 'primaryMetric':
    case 'spendAndPerformance':
      const preferLowerValue = primary_metric?.slice(0, 2) === 'cp';
      const p =
        (cell.performance - minPerformance) / (maxPerformance - minPerformance);

      if (!cell.performance) {
        return 0.5;
      }

      return preferLowerValue ? 1 - p : p;
    default:
      const s = cell.spend / maxSpend;
      return 0.5 * (1 + s);
  }
};

const calculateHueAndLightness = (percentage) => {
  let hue, lightness;

  lightness = 76 + (1 - Math.abs(percentage - 0.5) * 2) * (100 - 76);

  if (percentage >= 0.5) {
    hue = (1 - (percentage - 0.5) * 2) * (144 - 100) + 100;
  } else {
    hue = percentage * 2 * (18 - 0) + 0;
  }

  return { hue, lightness };
};
