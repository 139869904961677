export const validate = (tags: string[] = []) => {
  let errors = [];

  if (tags.some((tag) => !tag.length)) {
    errors.push('One or more tags is empty.');
  }

  if (tags.length !== new Set(tags).size) {
    errors.push('Duplicate tags not allowed.');
  }

  if (tags.some((tag) => validateInput(tag))) {
    errors.push('Uppercase letters or spaces are not allowed.')
  }

  return errors;
};

export const validateInput = (tag): boolean => {
  const invalidCharactersRegex = /[A-Z\s]/g;
  return invalidCharactersRegex.test(tag);
};
