import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const useSelectedLabels = (
  labelsParam,
  udc_labels = [],
  data = [],
  selectedAudience
) => {
  const labels = useMemo(() => {
    const l = labelsParam.length ? labelsParam.split(',') : [];

    if (selectedAudience && !l.includes(selectedAudience)) {
      return [...l, selectedAudience];
    }

    return l;
  }, [labelsParam]);

  const selectedLabels = useMemo(() => {
    return labels.reduce((acc, label) => {
      const c = label.split('->')[0];
      const l = label.split('->')[1];
      let match = data.find(
        (d) => (d.category === c && d.name === l) || d.name === l
      );

      if (match) {
        const {
          value,
          udc,
          winners,
          losers,
          name: originalName,
          category: originalCategory,
        } = match;

        let name = originalName;
        let category = originalCategory;

        const categoryMapping = {
          CST_PROMO: ['Promo / No Promo', 'Promo'],
          CST_NO_PROMO: ['Promo / No Promo', 'No Promo'],
          CST_BRAND: ['Brand / No Brand', 'Brand'],
          CST_NO_BRAND: ['Brand / No Brand', 'No Brand'],
        };

        if (name.startsWith('CST_')) {
          [category, name] = categoryMapping[value] || [
            category,
            name.split('_')[1],
          ];
        }

        const visuals = [...winners, ...losers];

        acc.push({
          label: name,
          value,
          category,
          isUdc: udc,
          visuals,
        });
      } else {
        acc.push({
          label: l,
          value: l,
          visuals: [],
        });
      }

      return acc.filter((lbl) => !!lbl.label && !!lbl.value);
    }, []);
  }, [labels, data]);

  const visualIdsFromSelectedLabels = useMemo(() => {
    if (selectedLabels.length === 0) {
      return new Set<string>();
    }

    let commonIds = new Set<string>(selectedLabels[0].visuals.map((v) => v.id));

    selectedLabels.forEach((label) => {
      const currentIds = new Set<string>(label.visuals.map((v) => v.id));
      commonIds = new Set<string>(
        [...commonIds].filter((id) => currentIds.has(id))
      );
    });

    return commonIds;
  }, [selectedLabels]);

  return [selectedLabels, visualIdsFromSelectedLabels];
};
