import React, { useState, useEffect } from "react";
import "./ReportEditor.scss";
import { useImpactReportContext } from "../contexts";
import { useFormValidation, useReportEditor } from "./hooks";
import {
  ADD_EXCLUDED_LABEL,
  REMOVE_EXCLUDED_LABEL,
  SET_AUDIENCE,
  SET_CURRENT,
  SET_ERRORS,
  SET_NAME,
  SET_STATUS,
} from "./reducer";
import { EMPTY_REPORT } from "./constants";
import EditMode from "./EditMode";
import Header from "./Header";
import { toDarwinDate } from "../../../../utils/darwin-dates";
import dayjs from "dayjs";
import ConfirmationModal from "./ConfirmationModal";
import { SET_INCLUDED_CATEGORIES } from "./reducer";
import { useImpactReportApi, useGetImpactReportsApi } from "../api";
import ReportList from "./ReportList";

const ReportEditor = () => {
  const {
    reports,
    audienceOptions,
    labelOptions,
    showReportEditor,
    setShowReportEditor,
    view_id,
    refreshData,
    defaultReport,
  } = useImpactReportContext();
  const { current, errors, dispatchReportEditor: dispatch } = useReportEditor();
  const [editing, setEditing] = useState<boolean>(false);
  const [reportsToDelete, setReportsToDelete] = useState<string[]>([]);
  const [reportDetails, setReportDetails] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const [attempted, setAttempted] = useState<string>("");
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [statusMessage, setStatusMessage] = useState<string>("");
  const impactReportApi = useImpactReportApi();
  const getImpactReportsApi = useGetImpactReportsApi();

  const activeReports = reports.filter(
    (r) => r.status === "on"
  ).length;

  const changesMade = current && current.id
    ? !_.isEqual(
        reports.find((r) => r.id === current.id),
        current
      )
    : !_.isEqual(EMPTY_REPORT, current);

  useEffect(() => {
    if (impactReportApi.post.loading) {
      setSaveInProgress(true);
    } else {
      setTimeout(() => setSaveInProgress(false), 1000);
    }
  }, [impactReportApi.post.loading]);

  useEffect(() => {
    if (impactReportApi.post.data) {
      refreshData();
    }
  }, [impactReportApi.post.data]);

  useEffect(() => {
    if (impactReportApi.post.error) {
      console.log(impactReportApi.post.error);
    }
  }, [impactReportApi.post.error]);

  useEffect(() => {
    if (impactReportApi.delete.data) {
      refreshData();
    }
  }, [impactReportApi.delete.data]);

  const doingStuff =
    impactReportApi.post.loading ||
    impactReportApi.delete.loading ||
    getImpactReportsApi.get.loading;

  useEffect(() => {
    if (impactReportApi.post.loading) {
      setStatusMessage("Saving...");
    } else if (impactReportApi.delete.loading) {
      setStatusMessage("Deleting in progress...");
    } else if (impactReportApi.post.data) {
      setStatusMessage("Saved.");
    } else if (
      impactReportApi.post.error ||
      impactReportApi.delete.error ||
      getImpactReportsApi.get.error
    ) {
      const postError = impactReportApi.post?.error;
      const deleteError = impactReportApi.delete?.error;
      const fetchError = impactReportApi.get?.error;
      setStatusMessage("An error occurred.");
      console.log(postError, deleteError, fetchError);
    } else {
      setStatusMessage("");
    }
  }, [impactReportApi.post, impactReportApi.delete, getImpactReportsApi.get]);

  useEffect(() => {
    if (reports.length) {
      if (current.id) {
        dispatch({
          type: SET_CURRENT,
          payload: reports.find((report) => report.id === current.id),
        });
      } else {
        const existing = reports.find((report) => report.name === current.name);
        dispatch({
          type: SET_CURRENT,
          payload: existing ? existing : EMPTY_REPORT,
        });
      }
    }
  }, [reports]);

  useEffect(() => {
    clearErrors();
  }, [current.id])

  const clearErrors = () => {
    dispatch({
      type: SET_ERRORS,
      payload: [],
    });
  };

  const StatusMessage = () => {
    return (
      <div className="status">
        <div className={`message`}>
          {doingStuff && (
            <i className="fa-solid fa-floppy-disk fa-fade mr-2"></i>
          )}
          {statusMessage}
        </div>
      </div>
    );
  };

  const setCurrent = (id) => {
    dispatch({
      type: SET_CURRENT,
      payload: reports.find((report) => report.id === id),
    });
  };

  const handleSelectCurrent = (id) => {
    setEditing(true);
    setShowChangesModal(false);
    if (id !== current.id) {
      setCurrent(id);
      setAttempted("");
    }
  };

  const handleSelectDate = (date, type) => {
    if (!!date && dayjs(date).isValid()) {
      dispatch({
        type: type,
        payload: toDarwinDate(date),
      });
    }
  };

  const handleSetStatus = (id, status) => {
    const existing = reports.find((r) => r.id === id);
    if (existing && current.id !== id) {
      handleSave({ id, status });
    }

    if (current.id === id) {
      dispatch({
        type: SET_STATUS,
        payload: status,
      });
    }
  };

  const handleSelectAudience = (selected) => {
    dispatch({
      type: SET_AUDIENCE,
      payload: selected.value,
    });
  };

  const handleAddLabel = (label) => {
    dispatch({
      type: ADD_EXCLUDED_LABEL,
      payload: label,
    });
  };

  const handleRemoveLabel = (label) => {
    dispatch({
      type: REMOVE_EXCLUDED_LABEL,
      payload: label,
    });
  };

  const updateReportsToDelete = (reportId) => {
    let updated = [...reportsToDelete];

    if (updated.includes(reportId)) {
      updated = updated.filter((id) => id !== reportId);
    } else {
      updated = [...updated, reportId];
    }

    setReportsToDelete(updated);
  };

  const handleReturn = () => {
    if (editing) {
      setEditing(false);
    } else {
      setShowReportEditor(false);
    }
  };

  const setToEmpty = () => {
    dispatch({
      type: SET_CURRENT,
      payload: EMPTY_REPORT,
    });
  };

  const handleCreateNew = () => {
    setEditing(true);
    setShowChangesModal(false);

    if (changesMade) {
      setShowChangesModal(true);
    } else {
      setToEmpty();
    }
  };

  const handleSetName = (value) => {
    dispatch({
      type: SET_NAME,
      payload: value,
    });
  };

  const handleSave = (payload) => {
    let changes = {};

    if (current.id) {
      changes = _.fromPairs(
        _.differenceWith(
          _.toPairs(current),
          _.toPairs(reports.find((r) => r.id === current.id)),
          _.isEqual
        )
      );
    } else {
      changes = { ...current };
    }

    if (payload) {
      impactReportApi.post.request({ view_id, ...payload });
    } else {
      impactReportApi.post.request({ view_id, id: current.id, ...changes });
    }
  };

  const handleRefresh = (reportId) => {
    const {
      id,
      name,
      status,
      start_date,
      end_date,
      audience,
      excluded_labels,
    } = reports.find((r) => r.id === reportId);

    const payload = {
      view_id,
      id,
      name,
      status,
      start_date,
      end_date,
      audience,
      excluded_labels,
    };

    impactReportApi.post.request(payload);
  };

  const handleUndo = (id) => {
    if (id) {
      setCurrent(id);
    } else {
      setToEmpty();
    }

    clearErrors();
  };

  const handleDelete = () => {
    impactReportApi.delete.request({ ids: reportsToDelete });

    if (reportsToDelete.includes(current.id)) {
      setToEmpty();
    }

    setReportsToDelete([]);
    setEditing(false);
  };

  const handleDeleteClickAway = (e) => {
    if (["bulk-delete", "single-delete"].includes(e.target.id)) return;
    setShowModal(false);
  };

  const handleChangesModal = (id) => {
    setAttempted(id);
    setShowChangesModal(true);
  };

  const handleChangesClickAway = (e) => {
    if (!e.target.id.includes(attempted) && showChangesModal)
      setShowChangesModal(false);
  };

  const handleIncludedCategories = (category) => {
    let update = [...current.combined_category_names];

    if (update.includes(category)) {
      update = update.filter((c) => c !== category);
    } else {
      update = [...update, category];
    }

    dispatch({
      type: SET_INCLUDED_CATEGORIES,
      payload: update,
    });
  };

  const handleSetDefault = (id, isDefault) => {
    impactReportApi.post.request({ view_id, id, default: !isDefault })
  };
  
  const handleValidation = () => {
    const errors = useFormValidation(current, reports);
    
    dispatch({
      type: SET_ERRORS,
      payload: errors,
    })

    if (errors.length) {
      return;
    }

    handleSave(null);
  };

  return (
    <div
      className={`report-editor__container ${
        !showReportEditor ? "slide-right" : ""
      }`}
    >
      <div className="report-editor d-flex flex-column">
        <div className="report-editor__display-container">
          <div className="report-editor__nav">
            {current && (
              <span className="go-back-icon" onClick={handleReturn}>
                <i className="fa fa-arrow-left"></i>
              </span>
            )}
          </div>
          <div
            className={`report-editor__main-view report-editor__main-view--${
              !editing ? "show" : ""
            }`}
          >
            <div className="view-inner">
              <Header
                {...{
                  reports,
                  current,
                  reportsToDelete,
                  activeReports,
                  handleCreateNew,
                  reportDetails,
                  setReportDetails,
                  handleDelete,
                  showModal,
                  setShowModal,
                  handleDeleteClickAway,
                  showChangesModal,
                  setShowChangesModal,
                  changesMade,
                  handleChangesModal,
                  setEditing,
                  setToEmpty,
                  saveInProgress,
                }}
              />
              <StatusMessage />
              <ReportList
                {...{
                  reports,
                  current,
                  reportsToDelete,
                  updateReportsToDelete,
                  activeReports,
                  handleSetStatus,
                  handleSelectCurrent,
                  reportDetails,
                  changesMade,
                  handleChangesModal,
                  handleChangesClickAway,
                  handleRefresh,
                  saveInProgress,
                  handleSetDefault,
                  setToEmpty,
                  handleValidation,
                  errors,
                }}
              />
              <ConfirmationModal
                handleClickAway={(e) => handleChangesClickAway(e)}
                show={showChangesModal}
                handleCancel={() => setShowChangesModal(false)}
                handleConfirm={() => {
                  if (attempted === "new") {
                    setToEmpty();
                    handleCreateNew();
                  } else {
                    handleSelectCurrent(attempted);
                  }
                }}
                message={`${
                  current.name ? `${current.name} has` : "You have"
                } unsaved changes.
                Discard changes?`}
                className={"change-report"}
              ></ConfirmationModal>
            </div>
          </div>
          <div
            className={`report-editor__edit-view report-editor__edit-view--${
              editing ? "show" : ""
            }`}
          >
            <div className="view-inner">
              <EditMode
                {...{
                  current,
                  reports,
                  audienceOptions,
                  labelOptions,
                  handleSelectDate,
                  handleRemoveLabel,
                  handleAddLabel,
                  handleSelectAudience,
                  handleSetStatus,
                  activeReports,
                  handleSetName,
                  handleSave,
                  handleUndo,
                  handleDelete,
                  changesMade,
                  showModal,
                  setShowModal,
                  handleDeleteClickAway,
                  handleIncludedCategories,
                  updateReportsToDelete,
                  saveInProgress,
                  handleValidation,
                  errors,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportEditor;
