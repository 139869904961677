import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useInterval } from '../../hooks/useInterval';
import { useNotifications } from '../Notification/useNotifications';
import { assetsAddOne } from '../../store/Marketplace/Assets/actions';
import { versionsAddOne } from '../../store/Marketplace/Versions/actions';
import { abortableFetch } from '../../utils';
import { bindFetchToRef, useApiState } from '../Hooks/useApiState';

function checkPredictionStatus(view_id) {
  return abortableFetch(
    [
      'api',
      'marketplace',
      'assets',
      'handle-check-asset-prediction-status',
      view_id,
    ].join('/'),
    {
      method: 'GET',
      credentials: 'include',
    }
  );
}

export const useAssetPredictionStatusApi = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [polling, setPolling] = useState(false);
  const notifications = useNotifications();
  const [updateStudioParams, setUpdateStudioParams] = useState(null);
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [predictionData, setPredictionData] = useState(null);
  const viewId = useSelector(({ ViewSettings: { viewId } }) => viewId);
  const [getState, getDispatch] = useApiState(
    (view_id) =>
      withRef(checkPredictionStatus(view_id)).then((data) => {
        return data;
      }),
    {
      loading: false,
      error: null,
      data: null,
      count: 0,
    }
  );
  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  useEffect(() => {
    if (getState.data) {
      setPredictionData(getState.data);
    }
  }, [getState.data])

  useInterval(
    async () => {
      if (polling) {
        console.log('checking prediction status 🤪');
        getDispatch(viewId);
      }
    },
    polling ? 15000 : null
  );

  const fetch = () => {
    setUpdateStudioParams(null);
    setPredictionData(null);
    setPolling(true);
  };

  const showReportCard = (data) => {
    const isStudio = window.location.pathname.includes('studio');
    const assetParam = encodeURIComponent(data.filename);
    console.log(data);

    if (isStudio) {
      setUpdateStudioParams({ display: 'report-card', asset_id: assetParam, project_id: '', version: 1, s3_filename: data.s3_filename })
    } else {
      history.push(
        `/${viewId}/studio?asset_id=${assetParam}&display=report-card&version=1&s3_filename=${data.s3_filename}`
      );
    }
  };

  const notifyPredictionsSuccess = (data) => {
    const message = (
      <>
        Asset has been labelled.{' '}
        <span
          style={{
            color: '#e1e3ff',
            cursor: 'pointer',
          }}
          onClick={() => showReportCard(data)}
        >
          Click here
        </span>{' '}
        to view report card.
      </>
    );

    notifications.add({
      title: 'Asset labelled successfully.',
      message,
    });
  };

  const notifyError = (name, error) => {
    notifications.add({
      title: name,
      message: error,
      toastProps: {
        className: 'error-toast',
      },
    });
  };

  useEffect(() => {
    if (getState.error) {
      console.log(getState.error);
      setPolling(false);
      notifications.remove('asset-predictions-running');

      try {
        notifyError(
          'There was an error.',
          getState.error.error.message.toString()
        );
      } catch (error) {
        notifyError(
          'There was an error.',
          getState.error.toString()
        );
        console.error(error);
      }
    }
  }, [getState.error]);

  useEffect(() => {
    if (predictionData) {
      const { data } = predictionData;
      if (data.Message && data.Message === 'Asset Predictions still running.') {
        console.log('Predictions 🍬 still ⭐ running ');
      } else {
        setPolling(false);
        notifications.remove('asset-predictions-running');
        notifyPredictionsSuccess(data);

        console.log(data);

        batch(() => {
          dispatch(
            assetsAddOne(data.filename, {
              project_id: '',
              filename: data.filename,
              s3_filename: data.s3_filename,
              carousel: true,
              isStudioUpload: true,
              card_number: 1,
              versionIds: [data.version],
              labels: data.prediction_data.total_labels,
            })
          );
          dispatch(
            versionsAddOne(data.version, {
              src: data.src,
              version: data.version,
              created: data.created_ms,
              type: data.content_type,
              approved: false,
              client_approved: false,
              isStudioUpload: true,
              tags: [],
              labels: data.prediction_data.total_labels,
            })
          );
        });
      }
    }
  }, [predictionData]);

  return {
    fetch,
    ...getState,
    updateStudioParams,
  };
};
