import React from "react";
import PropTypes from "prop-types";

import Zone from "./Testing.Zone";

const TestingStrikezone = ({
  handleSelection,
  toggleTooltip,
  atf,
  btf,
  cta,
  visual,
  activeKey
}) => (
  <div className="flex flex-col">
    <Zone
      {...atf}
      type="atf"
      label={"Primary Copy"}
      handleSelection={handleSelection}
      activeKey={activeKey}
      toggleTooltip={toggleTooltip}
    />
    <Zone
      {...visual}
      type="visual"
      label={"Visual"}
      handleSelection={handleSelection}
      activeKey={activeKey}
      toggleTooltip={toggleTooltip}
    />

    <Zone
      {...btf}
      type="btf"
      label={"Headline"}
      handleSelection={handleSelection}
      activeKey={activeKey}
      toggleTooltip={toggleTooltip}
    />
    <Zone
      {...cta}
      label={"Button"}
      type="cta"
      handleSelection={handleSelection}
      activeKey={activeKey}
      toggleTooltip={toggleTooltip}
    />
  </div>
);

TestingStrikezone.propTypes = {
  activeKey: PropTypes.string.isRequired,
  handleSelection: PropTypes.func.isRequired,
  toggleTooltip: PropTypes.func,
  atf: PropTypes.object,
  btf: PropTypes.object,
  cta: PropTypes.object,
  visual: PropTypes.object
};

export default TestingStrikezone;
