import { useRef, useEffect } from 'react';

import { abortableFetch } from '../../../utils';
import {
  useApiState,
  bindFetchToRef,
} from '../../../components/Hooks/useApiState';

function postEfficiencyReport(payload) {
  return abortableFetch(
    ['api', 'creative-reports', 'efficiency-report', payload.view_id].join('/'),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(payload),
    }
  );
}

export function useEfficiencyReportApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) =>
      withRef(postEfficiencyReport(payload)).then(({ data }) => data),
    {
      loading: false,
      error: null,
      data: null,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: postDispatch,
    },
  };
}
