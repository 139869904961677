import { LABEL_TEMPLATE } from './constants';
import { updateRuleOrder } from './utils';
import uuid from 'uuid';
export const SET_CATEGORY_NAME = 'set-category-name';
export const SET_DESCRIPTION = 'set-description';
export const ADD_NEW_RULE = 'add-new-rule';
export const REMOVE_RULE = 'remove-rule';
export const SELECT_RULE = 'select-rule';
export const EDIT_RULE = 'edit-rule';
export const ADD_LABEL_TO_RULE = 'add-label-to-rule';
export const REMOVE_LABEL_FROM_RULE = 'remove-label-from-rule';
export const UPDATE_LABEL_NAME = 'update-label-name';
export const DELETE_RULE = 'delete-rule';
export const SET_DATA = 'set-data';

export const makeEmptyRule = () => {
  return {
    rule_id: `${uuid.v4()}`,
    label_name: '',
    labels_to_include: [],
    labels_to_exclude: [],
    rule_num: 1,
  };
};

const addLabelToRule = (state, action) => {
  const { field, new_label } = action;
  const current = state.rules.find((rule) => rule.rule_id === state.selected_rule);
  if (!current) return {...state}
  const updatedLabels = [...current[field], new_label];
  const updatedRule = { ...current, [field]: updatedLabels };
  const updatedList = state.rules.map((rule, i) => {
    if (rule.rule_id === state.selected_rule) {
      return updatedRule;
    }
    return rule;
  });

  return {
    ...state,
    rules: updatedList,
  };
};

const removeLabelFromRule = (state, action) => {
  const { field, label } = action;
  const current = state.rules.find((rule) => rule.rule_id === state.selected_rule);
  if (!current) return {...state}
  const updatedLabels = [...current[field]].filter(
    (x) => x.value !== label.value
  );
  const updatedRule = { ...current, [field]: updatedLabels };
  const updatedList = state.rules.map((rule, i) => {
    if (rule.rule_id === state.selected_rule) {
      return updatedRule;
    }
    return rule;
  });

  return {
    ...state,
    rules: updatedList,
  };
};

const updateLabelName = (state, action) => {
  const current = state.rules.find((rule) => rule.rule_id === state.selected_rule);
  if (!current) return {...state}

  const updatedRule = { ...current, label_name: action.label_name };

  const updatedList = state.rules.map((rule, i) => {
    if (rule.rule_id === state.selected_rule) {
      return updatedRule;
    }
    return rule;
  });

  return {
    ...state,
    rules: updateRuleOrder(updatedList),
  };
};

export function derivedLabelsReducer(state, action) {
  let update = [];

  switch (action.type) {
    case SET_CATEGORY_NAME:
      return {
        ...state,
        category_name: action.category_name,
      };

    case SET_DESCRIPTION:
      return {
        ...state,
        description: action.description,
      };

    case ADD_NEW_RULE:
      update = [...state.rules, makeEmptyRule(state.rules.length)];

      return {
        ...state,
        rules: updateRuleOrder(update),
        selected_rule: update[update.length - 1].rule_id,
      };

    case SELECT_RULE:
      return {
        ...state,
        selected_rule: action.rule_id,
      };

    case SET_DATA:
      return {
        ...state,
        ...action.data,
      };

    case ADD_LABEL_TO_RULE:
      return addLabelToRule(state, action);

    case REMOVE_LABEL_FROM_RULE:
      return removeLabelFromRule(state, action);

    case UPDATE_LABEL_NAME:
      return updateLabelName(state, action);

    case DELETE_RULE:
      const index = state.rules.findIndex((rule) => rule.rule_id === action.selected_rule)

      if (action.selected_rule) {
        update = [...state.rules].filter((x) => x.rule_id !== action.selected_rule);
      }

      let newRuleId = update[index]?.rule_id || update[index - 1]?.rule_id || null;

      return {
        ...state,
        rules: updateRuleOrder(update),
        selected_rule: newRuleId,
      };

    default:
      return state;
  }
}
