import { useDispatch, useSelector, batch } from 'react-redux';

import {
  updateElement,
  appendCarouselElement,
} from '../../store/CreateAd/actions';

/**
 * @return {[{ data: Object, len: number}, Function]}
 */
export function useElementPayload(type) {
  const dispatch = useDispatch();
  const { data, len } = useSelector(({ CreateAd: { payload, carousel } }) => ({
    data: payload[type],
    len: carousel[type].length,
  }));

  /**
   * @param {{ value: string, index?: number }} update
   */
  const setElement = (update) => {
    if (update.value === '') {
      dispatch(updateElement({ type, value: data }));
    } else if (update.value !== data.value) {
      const anUpdate = { type, value: update };

      batch(() => {
        dispatch(updateElement(anUpdate));
        dispatch(appendCarouselElement(anUpdate));
      });
    }
  };

  return [{ data, len }, setElement];
}
