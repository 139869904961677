import React from 'react';

export const BirdIcon = () => {
  return (
    <svg
      className="bird-icon"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6663 4.66663H10.673M2.26634 12H7.99967C9.41416 12 10.7707 11.4381 11.7709 10.4379C12.7711 9.43767 13.333 8.08112 13.333 6.66663V4.66663C13.3345 4.09857 13.1546 3.54487 12.8194 3.08622C12.4842 2.62757 12.0114 2.28794 11.4697 2.11681C10.928 1.94567 10.3459 1.95199 9.80803 2.13483C9.2702 2.31767 8.80481 2.66748 8.47967 3.1333L1.33301 13.3333"
        stroke="black"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3327 4.66675L14.666 5.00008L13.3327 5.33341M6.66602 12.0001V14.0001M9.33268 11.8334V14.0001M4.66602 12.0001C5.48809 12 6.2902 11.7467 6.96317 11.2746C7.63614 10.8024 8.14729 10.1344 8.42706 9.36136C8.70683 8.58835 8.74163 7.74791 8.52672 6.95442C8.31182 6.16093 7.85765 5.45292 7.22602 4.92675"
        stroke="black"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
