import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useReportCardPrefsApi } from '../api';
import {
  BRAND,
  FORMAT,
  KEYWORD,
  PHRASES,
  PROMO,
  PROMOTION_TYPE,
} from '../categories';
import { DISABLED_CATEGORIES } from './CategoryEditor/constants';
import { DEFAULT_metric_order } from './constants';
import { reportCardSettingsReducer, SET_CATEGORIES, SET_DATA } from './reducer';
import { useSelector } from 'react-redux';

const ALWAYS_INCLUDE = [PHRASES, KEYWORD, PROMOTION_TYPE, PROMO, BRAND, FORMAT];

export const useCurrentReportCardVisual = (visual_hash) => {
  const result = useSelector(
    ({ CreativeAnalytics: { visuals, labels, categoriesLookup } }) => {
      const current = visuals.lookup.get(visual_hash);
      if (!current) return {};

      const getVisualById = (id) => visuals.lookup.get(id);
      const getLabelById = (id) => labels.lookup.get(id);

      return {
        ...current,
        categories: current.category_ids
          .map((id) => {
            const category = categoriesLookup[id];
            if (!category) return null;

            const enrichedLabels = category.labels
              .map((label) => {
                const labelDetails = getLabelById(label.id) || label || {};
                const associatedVisuals = (label.visual_ids || [])
                  .map(getVisualById)
                  .filter(Boolean);

                return {
                  ...labelDetails,
                  visuals: associatedVisuals,
                };
              })
              .filter(Boolean);

            return { ...category, labels: enrichedLabels };
          })
          .filter(Boolean),
        labels: current.label_ids
          .map((id) => {
            const label = getLabelById(id);
            if (!label) return null;

            const associatedVisuals = (label.visual_ids || [])
              .map(getVisualById)
              .filter(Boolean);

            return { ...label, visuals: associatedVisuals };
          })
          .filter(Boolean),
      };
    }
  );

  return result; // No need for useMemo wrapping `result`
};

export const useReportCardCategories = (current, categorySettings) => {
  const categories = useMemo(() => {
    if (!current || !current.categories) return [];
    if (!categorySettings || !categorySettings.length)
      return current.categories;

    return categorySettings.reduce((acc, category) => {
      const match = current.categories.find(
        (c) => c.category === category.category_name
      );

      if (match && category.enabled.report_card) {
        acc.push(match);
      }

      return acc;
    }, []);
  }, [categorySettings, current]);

  return categories;
};

export const appendMissingCategories = (
  categorySettings,
  categoriesLookup,
  shouldAlwaysUpdate = false
) => {
  const categories = Object.keys(categoriesLookup);

  if (!categories?.length || !categorySettings?.length) return categorySettings;

  let updateNeeded = shouldAlwaysUpdate;
  const update = [...categorySettings];

  categories.forEach((category) => {
    const isDisabled = DISABLED_CATEGORIES.includes(category);
    const isMissing = !categorySettings.some(
      (x) => x.category_name === category
    );

    if (isMissing && !isDisabled) {
      updateNeeded = true;
      const shouldBeEnabled = category !== 'Top 10 Spending Visuals';
      update.push({
        category_name: category,
        enabled: { report_card: shouldBeEnabled, recommendations: false },
      });
    }
  });

  return updateNeeded ? update : [];
};

export const useReportCardSettings = (view_id, isROAS, reportCardPrefsApi) => {
  let defaultMetric = isROAS ? 'aggregate_roas' : 'aggregate_cpa';
  const { filteredData, categoriesLookup } = useSelector(
    ({ CreativeAnalytics: { filteredData, categoriesLookup } }) => ({
      filteredData,
      categoriesLookup,
    })
  );

  const [
    {
      categorySettings,
      settings_changed,
      data_type,
      metric_options,
      selected_metric,
      metric_order,
      custom_metrics,
    },
    dispatchReportCardSettings,
  ] = useReducer(reportCardSettingsReducer, {
    categorySettings: [],
    settings_changed: false,
    data_type: 'average',
    metric_options: [],
    selected_metric: defaultMetric,
    custom_metrics: [],
    metric_order: DEFAULT_metric_order,
  });

  useEffect(() => {
    if (reportCardPrefsApi.get.data) {
      const { data } = reportCardPrefsApi.get;
      let categorySettingsData = data.categories.filter(
        (category) => !DISABLED_CATEGORIES.includes(category.category_name)
      );

      let categorySettings = [...categorySettingsData];

      ALWAYS_INCLUDE.forEach((category) => {
        if (!categorySettings.some((x) => x.category_name === category)) {
          categorySettings.unshift({
            category_name: category,
            enabled: { report_card: true, recommendations: true },
          });
        }
      });

      dispatchReportCardSettings({
        type: SET_DATA,
        categorySettings: appendMissingCategories(
          categorySettings,
          categoriesLookup,
          true
        ),
        data_type: isROAS && data.type === 'cpa' ? 'roas' : data.type,
        metric_options: data.metric_options,
        custom_metrics: data.custom_metrics || [],
        selected_metric:
          isROAS && data.selected_down_funnel_metric === 'cpa'
            ? 'roas'
            : data.selected_down_funnel_metric,
        ...(data.metric_order && { metric_order: data.metric_order }),
      });
    }
  }, [reportCardPrefsApi.get.data]);

  useEffect(() => {
    if (Object.keys(categoriesLookup).length > 0) {
      const updatedCategorySettings = appendMissingCategories(
        categorySettings,
        categoriesLookup
      );

      if (updatedCategorySettings?.length) {
        dispatchReportCardSettings({
          type: SET_DATA,
          categorySettings: updatedCategorySettings,
        });
      }
    }
  }, [categoriesLookup]);

  useEffect(() => {
    if (settings_changed) {
      reportCardPrefsApi.post.request({
        view_id,
        categories: categorySettings,
        type: data_type,
        selected_down_funnel_metric: selected_metric,
        metric_order,
        custom_metrics: [],
      });
    }
  }, [
    settings_changed,
    data_type,
    categorySettings,
    selected_metric,
    metric_order,
    custom_metrics,
  ]);

  const deleteCategoryFromSettings = useCallback(
    (category_name) => {
      const found = categorySettings.some(
        (x) => x.category_name === category_name
      );

      if (found) {
        const update = categorySettings.filter(
          (x) => x.category_name !== category_name
        );

        dispatchReportCardSettings({
          type: SET_CATEGORIES,
          categorySettings: update,
        });
      }
    },
    [categorySettings]
  );

  const rcSettings = useMemo(() => {
    return {
      categorySettings: categorySettings ?? [],
      settings_changed,
      data_type,
      metric_options,
      selected_metric,
      metric_order,
      deleteCategoryFromSettings,
    };
  }, [
    categorySettings,
    settings_changed,
    data_type,
    metric_options,
    selected_metric,
    metric_order,
    deleteCategoryFromSettings,
  ]);

  return [rcSettings, dispatchReportCardSettings];
};
