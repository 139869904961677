import React from 'react';
import { DARWIN_BLUE } from '../../../constants';

const VisualsIcon = ({ active }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.64764 11.8329L3.46425 12.0738H3.76698H12.248H12.548L12.368 11.8338L9.74885 8.34092L9.63198 8.18506L9.51117 8.33789L7.20343 11.2572L5.70031 9.44058L5.57986 9.295L5.4654 9.44534L3.64764 11.8329ZM0.847188 14.8445C1.16238 15.1597 1.55708 15.3164 2.02056 15.3164H13.8518C14.3153 15.3164 14.7103 15.1593 15.0261 14.8435C15.3412 14.5284 15.498 14.1337 15.498 13.6702V1.83898C15.498 1.37539 15.3409 0.980663 15.025 0.665493C14.7099 0.349797 14.3152 0.192712 13.8518 0.192712H2.02056C1.55703 0.192712 1.16234 0.349833 0.847187 0.665608C0.531412 0.980759 0.374292 1.37545 0.374292 1.83898V13.6702C0.374292 14.1338 0.531444 14.5287 0.847188 14.8445ZM13.8518 14.09H2.02056C1.92542 14.09 1.83126 14.0519 1.73471 13.956C1.63878 13.8594 1.60078 13.7653 1.60078 13.6702V1.83898C1.60078 1.74385 1.63879 1.64969 1.73473 1.55315C1.83127 1.45721 1.92543 1.4192 2.02056 1.4192H13.8518C13.9469 1.4192 14.041 1.4572 14.1376 1.55313C14.2335 1.64968 14.2715 1.74384 14.2715 1.83898V13.6702C14.2715 13.7653 14.2335 13.8595 14.1376 13.956C14.041 14.0519 13.9469 14.09 13.8518 14.09Z"
      fill={active ? DARWIN_BLUE : '#616161'}
      stroke={active ? DARWIN_BLUE : '#616161'}
      stroke-width="0.3"
    />
  </svg>
);

export default VisualsIcon;
