export const REPORT_OPTIONS = [
  {
    label: 'Trending',
    value: 'trending',
  },

  {
    label: 'Age',
    value: 'age',
  },

  {
    label: 'Placement',
    value: 'placement',
  },

  {
    label: 'Gender',
    value: 'gender',
  },

  {
    label: 'Device',
    value: 'device',
  },

  {
    label: 'Platform',
    value: 'platform',
  },
];

export const METRIC_OPTIONS = [
  { value: 'spend', label: 'Spend' },
  { value: 'impressions', label: 'Impressions' },
  { value: 'reach', label: 'Reach' },
  { value: 'post', label: 'Shares' },
  { value: 'thruplays', label: 'Thruplays' },
  { value: 'cp_post', label: 'Cost per Share' },
  { value: 'link_clicks', label: 'Link Clicks' },
  { value: 'cpm', label: 'CPM' },
  { value: 'ctr', label: 'CTR' },
  { value: 'cpc', label: 'CPC' },
  { value: 'results', label: 'Results' },
  { value: 'cpa', label: 'CPA' },
  { value: 'roas', label: 'ROAS' },
  { value: 'a_s', label: 'A/S' },
  { value: 'cp_thruplay', label: 'Cost per Thruplay'},
];

export const GA_METRIC_OPTIONS = [
  { value: 'ga_fb_lp', label: 'GA FB Landing Page View' },
  { value: 'ga_fb_add_to_cart', label: 'GA FB Add to Cart' },
  { value: 'ga_fb_initiate_checkout', label: 'GA FB Initiate Checkouts' },
  { value: 'ga_fb_conversions', label: 'GA FB Conversions' },
  { value: 'ga_fb_conversion_value', label: 'GA FB Conversion Value' },
  { value: 'cp_ga_fb_lp', label: 'Cost Per GA FB Landing Page View' },
  { value: 'cp_ga_fb_add_to_cart', label: 'Cost Per GA FB Add to Cart' },
  {
    value: 'cp_ga_fb_initiate_checkout',
    label: 'Cost Per GA FB Initiate Checkout',
  },
  { value: 'cp_ga_fb_conversions', label: 'GA FB CPA' },
  { value: 'cp_ga_fb_conversion_value', label: 'GA FB ROAS' },
];

export const INTERVAL_OPTIONS = [
  {
    value: 'daily',
    label: 'Day',
  },
  {
    value: 'weekly',
    label: 'Week',
  },
  {
    value: 'monthly',
    label: 'Month',
  },
];
