import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const LoadingSkeleton = () => {
  return (
    <div className="insights-grid" style={{ margin: '25px' }}>
      {[...Array(8)].map((e, i) => (
        <div className="insights-card-wrapper" key={i}>
          <Skeleton
            baseColor="#EBF3FA"
            highlightColor="#F6FCFF"
            inline={true}
            count={1}
            height={360}
          />
        </div>
      ))}
    </div>
  );
};
