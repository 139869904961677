import React, { useState, useRef } from 'react';
import usePortal from 'react-cool-portal';
import { useClickOutside } from '../../hooks/useClickOutside';
import './Dropdown.scss';
import { useEffect } from 'react';
import BeagleToggle from '../BeagleToggle';

export default function Dropdown({ style, children }) {
  return (
    <div className="dw-dropdown" style={style}>
      <ul>{children}</ul>
    </div>
  );
}

const Title = ({ title }) => (
  <li>
    <p className="dw-dropdown__title">{title}</p>
  </li>
);

const Item = ({ onClick, option }) => {
  return <li onClick={onClick} className="hover-bg">{option}</li>;
};

const Toggle = ({ label, onChange, checked, id = 'dropdown-toggle', disabled }) => {
  const handleToggle = (e) => {
    onChange(e);
  }

  return (
    <li class="d-flex justify-content-between">
      <label className="mr-3">{label}</label>
      <BeagleToggle
        checked={checked}
        onChange={handleToggle}
        id={id}
        disabled={disabled}
      />
    </li>
  );
};

const Divider = () => <hr style={{ borderTop: '2px solid #2D3A5B'}} />

Dropdown.Title = Title;
Dropdown.Item = Item;
Dropdown.Toggle = Toggle;
Dropdown.Divider = Divider;