import clsx from 'clsx';
import React from 'react';
import { BrowserType, DrawerAction } from '../../types';
import { CLOSE, SET_VIEW } from '../../hooks/useExplorerState';
import { VisualsBrowser } from './VisualsBrowser';
import { BrowserTypeSelect } from './BrowserTypeSelect';
import { CategoriesBrowser } from './CategoriesBrowser';

type BrowserWrapperProps = {
  browserType: BrowserType;
  dispatch: React.Dispatch<DrawerAction>;
  handleClose: () => void;
  keepState: boolean;
};

export const BrowserWrapper = ({
  browserType,
  dispatch,
  handleClose,
  keepState,
}: BrowserWrapperProps) => {
  const handleSelectVisual = (visual_id) => {
    dispatch({
      type: SET_VIEW,
      currentView: {
        type: 'visual_viewer',
        id: visual_id,
      },
    });

    if (keepState) {
      dispatch({ type: CLOSE, keepState });
    }
  };

  const handleSetBrowserType = (type) => {
    dispatch({
      type: SET_VIEW,
      currentView: {
        type: 'browser',
        browserType: type,
      },
    });
  };

  const handleSelectCategory = (category) => {
    dispatch({
      type: SET_VIEW,
      currentView: {
        type: 'category_viewer',
        id: category,
      },
      clearSelectedVisual: true,
    });
  };

  let BrowserContent = <p>There is nothing here.</p>;

  switch (browserType) {
    case 'visuals':
      BrowserContent = (
        <VisualsBrowser
          handleSelectVisual={handleSelectVisual}
        />
      );
      break;
    case 'categories':
      BrowserContent = (
        <CategoriesBrowser handleSelectCategory={handleSelectCategory} />
      );
      break;
    default:
      BrowserContent = <p>There is nothing here.</p>;
  }

  return (
    <div className="browser">
      <BrowserHeader {...{ browserType, handleClose, handleSetBrowserType }} />
      {BrowserContent}
    </div>
  );
};

const BrowserHeader = ({ browserType, handleClose, handleSetBrowserType }) => {
  let title = 'Browser';

  if (browserType === 'visuals') title = 'Visuals';
  if (browserType === 'categories') title = 'Categories';

  return (
    <div className="browser-header">
      <BrowserTypeSelect onSelect={handleSetBrowserType} title={title} />
      <i
        className="browser-header__close fa fa-xmark"
        onClick={handleClose}
      ></i>
    </div>
  );

  return;
};
