import { API_REQUEST, apiSuccess, apiFailure } from './actions';

const { logger } = window;

const responseHasError = (json) =>
  json.status === 'FAILURE' ||
  json.Status === 'FAILURE' ||
  json.error ||
  json.errors ||
  (typeof json === 'string' && json.includes('Error'));

const middleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    next(action);

    if (action.type.includes(API_REQUEST)) {
      const {
        body,
        meta: { entity, method, url, headers },
      } = action;

      const reqOptions = {
        mode: 'cors',
        method,
        headers,
      };

      try {
        const res = await window.fetch(url, {
          ...reqOptions,
          body: JSON.stringify(body),
        });

        const json = await res.json();

        if (res.status >= 400 || responseHasError(json)) {
          const err = new Error(json.error || json);

          logger.error(err, {
            request: reqOptions,
            response: { status: res.status },
          });

          return dispatch(apiFailure(new Error(json.error || json), entity));
        }
        return dispatch(apiSuccess(json, entity));
      } catch (err) {
        logger.error(err);

        return dispatch(apiFailure(err, entity));
      }
    }
  };

export default middleware;
