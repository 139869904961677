import React from 'react';
import { CaretRightIcon } from './CaretRightIcon';
import { CopilotIcon } from './CopilotIcon';

const CopilotButton = ({
  handleClick,
  disableButton,
  loading,
  text,
  className,
}) => {
  return (
    <button
      className={`copilot-button ${className ?? ''}`}
      onClick={handleClick}
      disabled={disableButton}
    >
      <div className="d-flex align-items-center">
        <CopilotIcon loading={loading} button={true} />
        {text && (
          <div className="position-relative">
            {!disableButton && <div className="fading-effect"></div>}
            <span className="ml-1">{text}</span>
          </div>
        )}
      </div>
      {text && (
        <div className="icon">
          <CaretRightIcon />
        </div>
      )}
    </button>
  );
};

export default CopilotButton;
