import React, { useEffect, useState } from 'react';
import Overlay from '../../../components/Overlay';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';

import './styles.scss';
import { useTagsApi } from './api';
import { validate, validateInput } from './validate';
import { useViewSettings } from '../../../selectors';
import { useNotifications } from '../../../components/Notification/useNotifications';
import Tooltip from '../../../components/Tooltip';
import { useCreativeAnalyticsGet } from '../../CreativeAnalytics/contexts';

const newTag = () => {
  return 'New Tag';
};

export const ManualTags = ({ current, close, existing = [] }) => {
  const { viewId, account_id } = useViewSettings();
  const { fetchPerformanceData } = useCreativeAnalyticsGet();
  const { data, loading, error, request } = useTagsApi();
  const notifications = useNotifications();
  if (!current) return null;
  const { id, asset_url, visual_type } = current.data ?? current;

  const [tags, setTags] = useState<string[]>(existing);
  const errors: string[] = validate(tags);

  useEffect(() => {
    if (data) {
      const message = (
        <>
          Tags saved successfully.{' '}
          <span
            style={{
              color: '#e1e3ff',
              cursor: 'pointer',
            }}
            onClick={() => fetchPerformanceData()}
          >
            Click here
          </span>{' '}
          to refresh.
        </>
      );

      notifications.add({
        title: 'Success!',
        message,
      });
    }
  }, [data]);

  const parseError = (err) =>
    err?.error?.toString() ??
    err?.toString() ??
    'There was an error saving the tags.';

  useEffect(() => {
    if (error) {
      notifications.add({
        title: 'Error.',
        message: parseError(error),
        toastProps: {
          className: 'error-toast',
        },
      });
    }
  }, [error]);

  const save = () => {
    request({
      account_id,
      visual_id: id,
      manual_tags: tags.map((tag) => `MT_${tag}`),
    });
  };

  const addNew = () => {
    setTags((prev) => [...prev, newTag()]);
  };

  const deleteTag = (i) => {
    setTags((prev) => {
      return prev.filter((t, index) => index !== i);
    });
  };

  const handleInput = (i, value) => {
    setTags((prev) =>
      prev.map((curr, index) => {
        if (index === i) {
          return value;
        } else {
          return curr;
        }
      })
    );
  };

  let visual = null;

  if (visual_type === 'image') {
    visual = <img src={asset_url} />;
  } else if (visual_type === 'video') {
    visual = (
      <video
        key={asset_url}
        loop
        muted
        plays-inline="true"
        className=""
        controls
      >
        <source src={asset_url} type="video/mp4" />
      </video>
    );
  }

  const emptyState = <p>Click below to add a tag.</p>;

  return (
    <Overlay close={close} show>
      <Modal className="manual-tags-editor" close={close}>
        <div className="manual-tags-editor__left">{visual}</div>
        <div className="manual-tags-editor__right">
          <div style={{ padding: '1rem' }}>
            <div className="manual-tags-editor__top">
              <h2 className="manual-tags-editor__heading">Edit Manual Tags</h2>
            </div>
            <ul className="manual-tags-editor__list">
              {tags.length < 1 && emptyState}

              {tags.map((tag, i) => {
                const isInvalid = validateInput(tag);
                return (
                  <Tooltip
                    shouldShow={isInvalid}
                    content="Uppercase letters or spaces are not allowed."
                  >
                    <li>
                      <input
                        className={`manual-tags__input ${
                          isInvalid ? 'manual-tags__input--invalid' : ''
                        }`}
                        value={tag}
                        onChange={(e) => handleInput(i, e.target.value)}
                      ></input>
                      <i
                        className="fa-solid fa-xmark"
                        onClick={() => deleteTag(i)}
                      ></i>
                    </li>
                  </Tooltip>
                );
              })}
            </ul>
          </div>

          <div className="manual-tags-editor__bottom">
            <Button
              fullWidth
              className="mb-2"
              appearance="secondary"
              onClick={addNew}
            >
              Add new tag
            </Button>
            <Tooltip
              shouldShow={!!errors.length}
              content={
                <ul className="p-0 mb-0" style={{ listStyle: 'none' }}>
                  {errors.map((err) => (
                    <li>- {err}</li>
                  ))}
                </ul>
              }
            >
              <div>
                <Button
                  fullWidth
                  disabled={loading || !!errors.length}
                  onClick={save}
                >
                  Save
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
      </Modal>
    </Overlay>
  );
};
