import React, { useCallback, useMemo, useState } from 'react';
import Select from 'react-select';
import { Tag, TagsList } from '../../../../../components/Tags';
import { useTable, useSortBy, usePagination } from 'react-table';
import Button from '../../../../../components/Button';
import { RANK_BASED_COLUMNS, SPEND_BASED_COLUMNS } from '../../../columns';
import '../../../../CreativeAnalytics/PerformanceFrequency/Table/index.scss';
import '../../../../CreativeAnalytics/PerformanceFrequency/FrequencyTable.scss';
import { BasicLoading } from '../../../../../components/Helpers/Loading';
import { useAnalyticsUrlProps } from '../../../../CreativeAnalytics/PivotReport/hooks';
import { useSearchParams } from '../../../../../components/Hooks/search-params';
import { useSelector } from 'react-redux';
import { EmptyDisplay } from '../../../../CreativeAnalytics/PivotReport/Insights';
import { Chart } from '../../../../BirdsEye/Chart';
import { DataItem } from '../../../../BirdsEye/types';
import { CategoriesModal } from './CategoriesModal';
import { VisualsByLabel } from './VisualsByLabel';
import { SELECT_STYLES } from '../../../constants';
import { OverridesSelect } from './Overrides';
import { useSaveOverridesApi } from '../../../../CreativeAnalytics/Explorer/api';

export const DATASET_MAPPING = {
  spend_based: {
    field: 'category_performance_data',
    columns: SPEND_BASED_COLUMNS,
    defaultSort: { id: 'total_spend', desc: true },
  },
  rank_based: {
    field: 'darwin_iq_data',
    columns: RANK_BASED_COLUMNS,
    defaultSort: { id: 'success_rate', desc: true },
  },
};

export const getDataModeColumns = (
  dataset,
  selectedColumnsParam,
  isROAS = false
) => {
  let result = ['label_name'];

  if (selectedColumnsParam?.length) {
    return [...result, ...selectedColumnsParam];
  } else {
    if (dataset === 'rank_based') {
      return [...DATASET_MAPPING.rank_based.columns.map((col) => col.accessor)];
    }
    return [
      'label_name',
      'total_spend',
      isROAS ? 'roas' : 'cpa',
      ...(isROAS ? ['total_revenue'] : []),
    ];
  }
};

type DataModeProps = {
  darwinIqData?: {};
  loading: boolean;
  allowDataMode: boolean;
  selectedColumns?: string[];
  visibleColumns?: string[];
};

export const DataMode: React.FC<DataModeProps> = ({
  darwinIqData = {
    category_performance_data: [],
    darwin_iq_data: [],
  },
  loading,
  allowDataMode,
  selectedColumns = [],
  visibleColumns = [],
  sort,
  selectedRow,
  selectedLabel,
  setSelectedLabel,
  dataByCategory,
}) => {
  const uniqueCategoryNames = useMemo(
    () => [
      ...new Set(
        darwinIqData?.category_performance_data.map(
          (item) => item.category_name
        ) ?? []
      ),
    ],
    [darwinIqData?.category_performance_data]
  );

  const { setParams } = useSearchParams();
  const {
    dataset,
    categories: selectedCategories,
  }: {
    dataset: 'spend_based' | 'rank_based';
    categories: string[];
  } = useAnalyticsUrlProps();

  const setSelectedCategories = useCallback(
    (callback: (prev: string[]) => string[]) => {
      const update = callback(selectedCategories);
      setParams({
        categories: encodeURIComponent(update?.join(',') || ''),
      });
    },
    [selectedCategories, setParams]
  );

  const datasetConfig = DATASET_MAPPING[dataset] || {};

  const data = darwinIqData?.[datasetConfig.field] || [];
  const defaultSort = datasetConfig.defaultSort || null;

  const chartData: DataItem[] = useMemo(() => {
    return (
      darwinIqData?.darwin_iq_data?.map((item) => {
        return {
          experiment_type: 'Combined',
          category_name: item.category_name,
          label_name: item.label_name,
          full_label_name: item.label_name,
          secondary_label: null,
          experiments_with_comparison: item.number_of_valid_eperiments,
          experiments_with_favorable_outcome: item.number_of_positive_outcomes,
          win_percentage: item.success_rate * 100,
          combined_p_value: item.p_value,
        };
      }) ?? []
    );
  }, [darwinIqData]);

  const handleSelectLabel = useCallback(
    (category_name = null, label_name = null) => {
      if (!category_name || !label_name) return setSelectedLabel(null);
      setSelectedLabel(`${category_name}->${label_name}`);
    },
    []
  );

  if (!allowDataMode) {
    return (
      <EmptyDisplay message="No Grouping is currently not supported as a segment in Data View." />
    );
  }

  if (loading) {
    return <BasicLoading />;
  }

  if (!darwinIqData) {
    return <EmptyDisplay message="No data available." />;
  }

  return (
    <div className="data-mode">
      {' '}
      <Header
        Left={<div className="d-flex align-items-center"></div>}
        Right={
          <div className="d-flex align-items-center">
            <CategoryWidget
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              uniqueCategoryNames={uniqueCategoryNames}
            />
          </div>
        }
      />
      {loading ? (
        <BasicLoading />
      ) : (
        <div>
          {dataset === 'rank_based' && (
            <Chart data={chartData} location="data_view" />
          )}
          <div className="d-flex">
            <div style={{ flex: 1, minWidth: '50%' }}>
              <Tables
                columns={visibleColumns}
                allowLabelSelect={dataset === 'spend_based'}
                {...{
                  dataByCategory,
                  defaultSort,
                  selectedLabel,
                  handleSelectLabel,
                }}
              />
            </div>

            {/* {!!selectedLabel && dataset === 'spend_based' && (
              <div style={{ flex: 1 }}>
                <VisualsByLabel
                  selectedLabel={selectedLabel}
                  visualsData={visualsData}
                  sort={sort}
                  handleOverride={handleOverride}
                  overridesSelect={(visual) => {
                    return (
                      <div>
                        <label>Override label to:</label>
                        <OverridesSelect
                          visual_id={visual.darwin_visual_id}
                          current={{
                            label: convertFormat1ToFormat2(selectedLabel),
                            value: convertFormat1ToFormat2(selectedLabel),
                          }}
                          options={selectedCategoryData?.category_labels?.map(
                            (label) => ({
                              label,
                              value: label,
                            })
                          )}
                          handleSaveOverride={handleOverride}
                          loading={saveOverridesApi.loading}
                        />
                        {/* <Select
                          isClearable
                          options={selectedCategoryData?.category_labels?.map(
                            (label) => ({
                              label,
                              value: label,
                            })
                          )}
                          styles={SELECT_STYLES}
                          placeholder="Select label or type to create new..."
                          value={selected || null}
                          isSearchable
                          onChange={handleSelect}
                          onCreateOption={handleCreate}
                        /> */}
            {/* </div>
                    );
                  }} */}
            {/* />
              </div>
            )} */}
          </div>
        </div>
      )}
    </div>
  );
};

const Header = ({ Left, Right }) => {
  return (
    <div className="d-flex justify-content-start w-100 mb-5">
      {Left}
      {Right}
    </div>
  );
};
export const CategoryWidget = ({
  selectedCategories,
  setSelectedCategories,
  uniqueCategoryNames,
}) => {
  const [show, setShow] = useState(false);
  const [showList, setShowList] = useState(false);

  return (
    <div className="d-flex align-items-center">
      <div className="mr-3 mb-0">
        <div className="category-widget">
          <Button
            appearance="raised"
            className="category-widget-toggle"
            active={show}
            onClick={() => setShow(true)}
          >
            Toggle Categories <span>{selectedCategories.length}</span>
          </Button>
          {show && (
            <CategoriesModal
              {...{
                selectedCategories,
                setSelectedCategories,
                uniqueCategoryNames,
                setShow,
                showList,
                setShowList,
              }}
            />
          )}
        </div>
      </div>

      {showList && (
        <TagsList className="data-mode-tags-list">
          {selectedCategories.map((name) => {
            return (
              <Tag
                deletable
                onDelete={() => {
                  setSelectedCategories((prev) =>
                    prev.filter((selectedCategory) => name !== selectedCategory)
                  );
                }}
              >
                {name}
              </Tag>
            );
          })}
        </TagsList>
      )}
    </div>
  );
};

const Tables = ({
  dataByCategory,
  columns,
  defaultSort,
  selectedLabel,
  handleSelectLabel,
  allowLabelSelect,
}) => {
  if (!dataByCategory.size) {
    return <p>No data.</p>;
  }

  return Array.from(dataByCategory.entries()).map(([key, value]) => {
    return (
      <div className="data-table-wrapper">
        <h2 className="data-table-header">
          Performance by <span>{key}</span>
        </h2>
        <DataTable
          category_name={key}
          columns={columns}
          data={value}
          defaultSort={defaultSort}
          handleSelectLabel={handleSelectLabel}
          selectedLabel={selectedLabel}
          allowLabelSelect={allowLabelSelect}
        />
      </div>
    );
  });
};

const DataTable = ({
  category_name,
  columns,
  data,
  defaultSort,
  selectedLabel,
  handleSelectLabel,
  allowLabelSelect,
}) => {
  const defaultColumn = useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 200,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state: { pageIndex, pageSize },
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { sortBy: [defaultSort], pageIndex: 0, pageSize: 10 },
      defaultColumn,
    },
    useSortBy,
    usePagination
  );

  return (
    <div
      className="table-responsive border rounded"
      style={{
        width: data.length > 9 ? '1000px' : 'fit-content',
        maxWidth: '90vw',
      }}
    >
      <table
        {...getTableProps()}
        style={{ tableLayout: 'auto' }}
        className="frequency-table w-100"
      >
        <thead className="frequency-table__header">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{ minWidth: '150px', whiteSpace: 'nowrap' }}
                  className="p-2 text-left"
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const isSelected =
              allowLabelSelect && isRowSelected(selectedLabel, row);
            return (
              <tr
                {...row.getRowProps()}
                className={`frequency-table__row border ${
                  isSelected ? 'is-selected' : ''
                }`}
                style={{ cursor: allowLabelSelect ? 'pointer' : 'auto' }}
                onClick={() => {
                  if (!allowLabelSelect) return;

                  if (isSelected) {
                    handleSelectLabel(null);
                  } else {
                    handleSelectLabel(
                      row.original.category_name,
                      row.original.label_name
                    );
                  }
                }}
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    style={{ minWidth: '150px', whiteSpace: 'nowrap' }}
                    className="frequency-table__cell p-2"
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.length > 9 ? (
        <div className="d-flex justify-content-between p-2">
          <Button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            appearance={'subtleBordered'}
          >
            Previous
          </Button>
          <span>
            Page{' '}
            <input
              type="number"
              value={pageIndex + 1}
              min={1}
              max={pageOptions.length}
              onChange={(e) => gotoPage(Number(e.target.value) - 1)}
              style={{ width: '50px' }}
            />{' '}
            of {pageOptions.length}
          </span>
          <Button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            appearance={'subtleBordered'}
          >
            Next
          </Button>
        </div>
      ) : null}
    </div>
  );
};

const isRowSelected = (selectedLabel, row) => {
  if (selectedLabel) {
    return (
      selectedLabel ===
      `${row.original.category_name}->${row.original.label_name}`
    );
  }

  return false;
};
