export const DATE_RX = /\d{4}-\d{2}-\d{2}/i;

export function isString(x) {
  return typeof x === 'string';
}

export function isNumber(x) {
  return typeof x === 'number' && !isNaN(x);
}

export function maybe(predicate) {
  return (x) => !x || predicate(x);
}

export function isNonEmptyString(x) {
  return isString(x) && x.length > 0;
}

export function isNonEmptyList(x) {
  return Array.isArray(x) && x.length > 0;
}

export function regexpMatch(pattern) {
  return (x) => pattern.test(x);
}

export function isOneOfValues(...values) {
  return (x) => values.some((y) => y === x);
}

export function where(predicate) {
  return (x) => predicate(x);
}

export function capitalize(str) {
  if (str === undefined) {
    return '';
  }
  if (str.length < 2) {
    return str;
  }
  return `${str[0].toUpperCase()}${str.slice(1)}`;
}

export function shorten(str, limit = 20) {
  if (str.length < limit) {
    return str;
  }
  return `${str.slice(0, limit - 3)}...`;
}
