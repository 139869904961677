import React, { useMemo, useState } from 'react';
import { useTable, useColumnOrder } from 'react-table';
import '../CopyReport/index.scss';
import '../CopyReport/Table.scss';
import '../PerformanceFrequency/FrequencyTable.scss';
import CustomDropdown from '../../../components/CustomDropdown';
import Button from '../../../components/Button';
import { toCurrency } from '../../../utils/numbers';

const DataTable = ({ data }) => {
  const allColumns = useMemo(() => {
    if (!data || data.length === 0) return [];
    return Object.keys(data[0]).map((key) => {
      if (key === 'Total Spend' || key === 'CPA') {
        return {
          Header: key,
          accessor: key,
          Cell: ({ value }) => (value ? toCurrency(value) : '-'),
        };
      }
      return {
        Header: key,
        accessor: key,
      };
    });
  }, [data]);

  const defaultColumnIds = useMemo(() => ['CPA', 'Total Spend', 'Ad Name'], []);
  const [visibleColumnIds, setVisibleColumnIds] = useState(defaultColumnIds);

  const columns = useMemo(
    () =>
      visibleColumnIds
        .map((id) => allColumns.find((col) => col.accessor === id))
        .filter((x) => !!x),
    [allColumns, visibleColumnIds]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useColumnOrder);

  const sortedRows = [...rows].sort(
    (a, b) => b.original['Total Spend'] - a.original['Total Spend']
  );
  console.log({ allColumns, visibleColumnIds });
  return (
    <div className="col-12 col-xl-8 p-3">
      <div
        style={{ position: 'relative' }}
        className="d-flex justify-content-between align-items-center mb-4"
      >
        <h3 className="m-0">Meta Performance by Ad Name</h3>

        <div
          style={{
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 100,
          }}
        >
          <ColumnPicker
            metrics={allColumns.map((col) => col.accessor)}
            onChange={setVisibleColumnIds}
            visibleColumnIds={visibleColumnIds}
          />
        </div>
      </div>
      <div className="report p-0">
        <table {...getTableProps()} className={`frequency-table`}>
          <thead className="frequency-table__header">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {sortedRows.map((row) => {
              prepareRow(row);
              return (
                <tr className="frequency-table__row" {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className={`d-table-cell frequency-table__cell`}
                      // style={{ padding: '5px', borderBottom: '1px solid gray' }}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DataTable;

const makeOpts = (fields) => {
  return fields.map((field) => ({ label: field, value: field }));
};

const ColumnPicker = ({ metrics, onChange, visibleColumnIds }) => {
  const options = makeOpts(metrics);

  const [show, setShow] = useState(false);

  const handleVisibility = (isOpen, meta) => {
    if (isOpen) {
      setShow(true);
    } else if (meta.source !== 'select') {
      setShow(false);
    }
  };

  const handleColumns = (value) => {
    const isCurrentlyVisible = visibleColumnIds.includes(value);
    if (isCurrentlyVisible) {
      onChange(visibleColumnIds.filter((id) => id !== value));
    } else {
      onChange([...visibleColumnIds, value]);
    }
  };

  console.log({ options });

  const handleSelect = (index) => {
    const metric = options[index];

    return handleColumns(metric.value);
  };

  return (
    <CustomDropdown
      id="report-builder-metric-select"
      onToggle={handleVisibility}
      onSelect={handleSelect}
      menuStyle={{ overflow: 'scroll', height: '650px' }}
      show={show}
      className="dw-dropdown-toggle dw-dropdown-toggle--sm"
      label={
        <Button
          appearance="raised"
          style={{ height: '30px', padding: '0 6px' }}
        >
          <i
            style={{ color: '#07063E', fontSize: '1rem' }}
            className="fa-solid fa-columns mr-3"
          />
          Edit columns
        </Button>
      }
    >
      <CustomDropdown.Header>Columns</CustomDropdown.Header>
      {options.map(({ value, label }, i) => (
        <CustomDropdown.Item key={value} eventKey={i}>
          <div className="form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="form-check-input"
                name={value}
                checked={visibleColumnIds.includes(value)}
              />
              {label}
            </label>
          </div>
        </CustomDropdown.Item>
      ))}
    </CustomDropdown>
  );
};
