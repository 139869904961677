import { connectSingle, connectMulti, connectGenericMulti } from './Container';
import Preview from './Preview';
import withApiSingle from './withApi-Single';
import withApiMulti from './withApi-Multi';
import withGenericApi from './withApi-Generic';

/**
 * @param {String} adName
 */
export const SingleAdPreview = connectSingle(withApiSingle(Preview));

/**
 * @param {[String]} adNames
 */
export const MultiAdPreview = connectMulti(withApiMulti(Preview));

export const withMultiAdPreviews = (Comp) => connectMulti(withApiMulti(Comp));

export const AdPreviewsList = connectGenericMulti(withGenericApi(Preview));
