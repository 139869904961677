import React from "react";

export const LabelIcon = () => {
  return (
    <svg
      className="label-icon"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0281 4.67358C11.2838 4.67358 11.5291 4.57199 11.7099 4.39115C11.8908 4.21031 11.9924 3.96504 11.9924 3.7093C11.9924 3.45355 11.8908 3.20828 11.7099 3.02745C11.5291 2.84661 11.2838 2.74501 11.0281 2.74501C10.7723 2.74501 10.5271 2.84661 10.3462 3.02745C10.1654 3.20828 10.0638 3.45355 10.0638 3.7093C10.0638 3.96504 10.1654 4.21031 10.3462 4.39115C10.5271 4.57199 10.7723 4.67358 11.0281 4.67358ZM8.31843 0.173584C7.76433 0.173617 7.23293 0.393759 6.84114 0.785584L0.894712 6.73201C0.502933 7.12382 0.282837 7.65522 0.282837 8.2093C0.282837 8.76338 0.502933 9.29477 0.894712 9.68658L5.05014 13.8427C5.24416 14.0367 5.47451 14.1907 5.72804 14.2957C5.98158 14.4008 6.25332 14.4549 6.52775 14.4549C6.80218 14.4549 7.07392 14.4008 7.32745 14.2957C7.58098 14.1907 7.81134 14.0367 8.00535 13.8427L13.9518 7.89623C14.1459 7.70215 14.2998 7.47174 14.4048 7.21815C14.5098 6.96456 14.5639 6.69277 14.5638 6.4183V1.94144C14.5638 1.47258 14.3775 1.02291 14.046 0.691377C13.7145 0.35984 13.2648 0.173584 12.7959 0.173584H8.31843ZM7.52321 1.46766C7.73408 1.25664 8.02011 1.13802 8.31843 1.13787H12.7959C13.2395 1.13787 13.5995 1.49787 13.5995 1.94144V6.4183C13.5995 6.71723 13.4806 7.0033 13.2697 7.21416L7.32328 13.1606C7.21882 13.2651 7.09479 13.3479 6.95829 13.4045C6.82179 13.461 6.67549 13.4901 6.52775 13.4901C6.38 13.4901 6.2337 13.461 6.0972 13.4045C5.9607 13.3479 5.83668 13.2651 5.73221 13.1606L1.57678 9.00451C1.36598 8.79356 1.24756 8.50753 1.24756 8.2093C1.24756 7.91107 1.36598 7.62504 1.57678 7.41408L7.52321 1.46766Z"
        fill="#9E9E9E"
      />
    </svg>
  );
};
