import { Marketplace } from './actions';

function removeKey(obj, key) {
  const result = {};

  Object.keys(obj).forEach((k) => {
    if (k !== key) {
      result[k] = obj[k];
    }
  });

  return result;
}

function updateProjectsState({ lookup, ids }, project) {
  const pid = project.project_id;
  const current = lookup[pid];

  if (!current) {
    return {
      ids: [...ids, pid],
      lookup: { ...lookup, [pid]: project },
    };
  }
  if (Object.keys(project).length === 1) {
    return {
      lookup: removeKey(lookup, project.project_id),
      ids: ids.filter((_id) => _id !== project.project_id),
    };
  }
  return {
    ids,
    lookup: { ...lookup, [pid]: { ...current, ...project } },
  };
}

export default function projectReducer(
  state = { lookup: {}, ids: [] },
  action
) {
  switch (action.type) {
    case Marketplace.SET_PROJECTS:
      return {
        lookup: action.data,
        ids: action.byIds,
      };

    case Marketplace.UPDATE_PROJECTS:
      return updateProjectsState(state, action.data);

    default:
      return state;
  }
}
