import { batch } from 'react-redux';
import { API_SUCCESS, apiRequest, API_FAILURE } from '../Api/actions';
import { addNotification } from '../Notifications/actions';

export const AD_CREATION = 'AdCreation';

export const AD_CREATION_FETCH = `[${AD_CREATION}] FETCH`;

export const fetchAdToCreate = ({
  ad_name,
  atf,
  btf,
  cta,
  visual,
  type,
  image_url,
}) => ({
  type: AD_CREATION_FETCH,
  data: {
    ad_name,
    atf,
    btf,
    cta,
    visual,
    type,
    image_url,
  },
});

export const adCreationMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case AD_CREATION_FETCH: {
        const {
          ViewSettings: { viewId, campaigns, account_id },
        } = getState();
        const {
          data: { ad_name, atf, btf, cta, visual, type, image_url },
        } = action;

        batch(() => {
          dispatch(
            apiRequest({
              body: {
                ad_name,
                atf,
                btf,
                cta,
                visual,
                type,
                image_url,
                campaigns,
                account_id,
              },
              url: ['api', viewId, 'create-ad'].join('/'),
              entity: AD_CREATION,
              method: 'POST',
            })
          );

          dispatch(
            addNotification(
              {
                id: new Date().getMilliseconds(),
                status: 'info',
                message: 'Submitting Ad to Facebook',
                title: 'Ad Creation',
              },
              AD_CREATION
            )
          );
        });

        break;
      }

      case `[${AD_CREATION}] ${API_SUCCESS}`: {
        const { response } = action;
        const successes = response.reduce(
          (acc, { Status }) => acc + (Status === 'SUCCESS' ? 1 : 0),
          0
        );

        dispatch(
          addNotification(
            {
              id: new Date().getMilliseconds(),
              status: 'success',
              title: 'Ad Creation',
              message: `${successes} ads were created`,
            },
            AD_CREATION
          )
        );

        break;
      }

      case `[${AD_CREATION}] ${API_FAILURE}`: {
        const { error } = action;

        dispatch(
          addNotification(
            {
              id: new Date().getMilliseconds(),
              status: 'danger',
              title: 'Ad Creation',
              message: error.body || error,
            },
            AD_CREATION
          )
        );

        break;
      }

      default:
        return result;
    }

    return result;
  };
