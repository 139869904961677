import {
  RUNNING_ADS_BY_NAME_SET,
  RUNNING_ADS_BY_NAME_SET_NAMES,
} from './actions';
import INITIAL_STATE from '../initial-state';

const reducer = (state = INITIAL_STATE.RunningAdsByName, action) => {
  switch (action.type) {
    case RUNNING_ADS_BY_NAME_SET_NAMES:
      return { ...state, selectedNames: action.selected };

    case RUNNING_ADS_BY_NAME_SET:
      return {
        ...state,
        ads: { ...state.ads, ...action.data },
      };

    default:
      return state;
  }
};

export default reducer;
