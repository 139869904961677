import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
  useEffect,
} from 'react';
import './index.scss';
import { ErrorBoundary } from '../../../components/Helpers/Error';

const DEFAULT_STYLE: React.CSSProperties = {
  borderLeft: '1px solid #ECF1F9',
  color: '#1E2B4E',
};

export const defaultDrawerWidth = 500;
const minDrawerWidth = 200;
const maxDrawerWidth = 1000;

const RightDrawerContext = createContext(null);

export const useRightDrawerContext = () => {
  return useContext(RightDrawerContext);
};

export const RightDrawerWrapper = ({ style = null, children, opts = {} }) => {
  const drawerRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(defaultDrawerWidth);

  const startResizing = useCallback((mouseDownEvent) => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent) => {
      const newWidth =
        drawerRef.current.getBoundingClientRect().right -
        mouseMoveEvent.clientX;
      if (isResizing && newWidth >= minDrawerWidth) {
        setDrawerWidth(newWidth);
      }
    },
    [isResizing]
  );

  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  return (
    <ErrorBoundary message="There was an error.">
      <RightDrawerContext.Provider value={{ drawerWidth, setDrawerWidth }}>
        <div
          ref={drawerRef}
          className="right-drawer"
          style={{
            ...(style || DEFAULT_STYLE),
            width: drawerWidth,
          }}
          onMouseDown={(e) => e.preventDefault()}
        >
          <div
            onMouseDown={startResizing}
            className="right-drawer__handle"
          ></div>
          {children}
        </div>
      </RightDrawerContext.Provider>
    </ErrorBoundary>
  );
};
