import React, { useRef } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/bundle';

import Card from '../Card/Card';
import './Carousel.scss';

const VariantCarousel = ({
  visualsData,
  loading,
  isROAS,
  rowIndex,
  prevRef,
  nextRef,
  isSharedReport,
  rowMetric,
  primaryMetricLabel,
  width,
}) => {
  const ref = useRef();

  return (
    <div ref={ref} className="variant-carousel" style={{ width: '100%' }}>
      <Swiper
        modules={[Navigation]}
        spaceBetween={50}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
        }}
        slidesPerView={width < 576 ? 1 : width < 992 ? 2 : 3}
        slidesPerGroup={width < 576 ? 1 : width < 992 ? 2 : 3}
        observeParents={true}
        observer={true}
        allowTouchMove={true}
        noSwiping
      >
        {visualsData
          .sort((a, b) => {
            if (
              ['ctr', 'impressions', 'results', 'clicks'].includes(rowMetric)
            ) {
              return (
                b.data?.predicted_performance - a.data?.predicted_performance
              );
            }
            return a.data?.predicted_performance - b.data?.predicted_performance;
          })
          .map((visual, i) => {
            return (
              <SwiperSlide>
                <Card
                  type="variant"
                  data={visual.data}
                  title={`AI Variant ${i + 1}`}
                  subtitle={visual.data?.confidence ?? ''}
                  handleOpenDrawer={null}
                  variantIndex={i}
                  {...{
                    loading,
                    isROAS,
                    rowIndex,
                    isSharedReport,
                    rowMetric,
                    primaryMetricLabel,
                  }}
                />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default VariantCarousel;
