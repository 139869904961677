import React, { useState } from 'react';
import Menu from './components/Menu/Menu';
import ToggleButton from './components/ToggleButton/ToggleButton';

import './LeftMenu.scss';
import { useCreativeAnalyticsGet } from '../contexts';
import DisplaySelect from './components/DisplaySelect/DisplaySelect';
import ElementPerformance from './ElementPerformance';
import { ChevronDoubleRightIcon } from '../CreativeRequest/icons';
import { ChevronDoubleDownIcon } from '../ReportCard/icons';
import { MenuIcon } from './icons/menu';
import Tooltip from '../../../components/Tooltip';
import { useViewGroupContext } from '../../ViewGroup/context';

const LeftMenu: React.FC = ({
  setIsSettingsOpen,
  display,
  loading,
  isDirectMail,
  isNonPaidMediaType,
}) => {
  const { insights_by_asset, selected } = useCreativeAnalyticsGet();
  const { mainView } = useViewGroupContext();
  const [mode, setMode] = useState<'table' | 'visuals'>('table');
  const isTableMode = display === 'performance_frequency';
  const [minimized, setMinimized] = useState(false);

  const handleToggle = () => {
    setMode((prevMode) => (prevMode === 'table' ? 'visuals' : 'table'));
  };

  const handleMenuItemClick = (menuItem: string) => {
    // Handle the menu item click logic here
    console.log(`Clicked on menu item: ${menuItem}`);
  };

  return (
    <div
      className={`left-menu__container ${minimized ? 'minimized' : ''}`}
      style={{ marginTop: !!mainView ? '-64px' : '0' }}
    >
      <Menu
        loading={loading}
        mode={mode}
        onMenuItemClick={handleMenuItemClick}
        setIsSettingsOpen={setIsSettingsOpen}
        isDirectMail={isDirectMail}
        isNonPaidMediaType={isNonPaidMediaType}
      />
      {isTableMode && <ElementPerformance data={selected.element} />}
    </div>
  );
};

export default LeftMenu;
