/**
 * @typedef Preview
 * @type {Object}
 * @property {string} preview_id
 * @property {string} DESKTOP_FEED
 */

/**
 * @typedef AssetVersion
 * @type {Object}
 * @property {File} file
 * @property {string} src
 * @property {number} created
 * @property {number} size
 * @property {string} type
 * @property {boolean=} selected
 * @property {string=} version
 * @property {string[]=} previewIds
 */

/**
 * @typedef Asset
 * @type {Object}
 * @property {string} filename
 * @property {string} contractor
 * @property {string} s3_filename
 * @property {AssetVersion[]} versions
 */

export function fileForDisplay(file) {
  return {
    fileObject: file,
    src: window.URL.createObjectURL(file),
  };
}

/**
 * @param {File} file
 * @param {"create"=} mode
 *
 * @return {AssetVersion}
 */
export function makeVersionFromUpload(file, versionId, mode = 'create') {
  const { fileObject, src } = fileForDisplay(file);
  const now = Date.now();

  return {
    file: fileObject,
    src,
    created: now,
    size: fileObject.size,
    type: fileObject.type,
    version: versionId || `TEMP-${now}`,
    selected: mode === 'create',
  };
}

/**
 * @param {AssetVersion} version
 * @param {string} contractor
 *
 * @return {Asset}
 */
export function createAsset(version, contractor) {
  return {
    contractor,
    s3_filename: '',
    filename: version.file.name,
    versions: [version],
  };
}

/**
 * @param {Asset} asset
 * @param {AssetVersion} version
 *
 * @return {Asset}
 */
export function updateAsset(asset, version) {
  const { versions } = asset;

  return {
    ...asset,
    versions: [...versions, version],
  };
}

/**
 * @param {{ version: AssetVersion, asset: Asset, versionId: string }} payload
 *
 * @return {FormData}
 */
export function makeAssetFormData({ asset, version }) {
  const data = new FormData();
  const { filename, s3_filename, contractor } = asset;
  const { file, created } = version;

  data.append('filename', filename);
  data.append('s3_filename', s3_filename);
  data.append('contractor', contractor);
  data.append('created', created);
  // "file" must always be last.
  data.append('file', file);

  return data;
}
