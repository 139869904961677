import { SET_ERROR } from './actions';

const STATE = {};

const reducer = (state = STATE, action) => {
  switch (action.type) {
    case SET_ERROR:
      const {
        hasError,
        meta: { entity },
      } = action;

      return { ...state, [entity]: hasError };

    default:
      return state;
  }
};

export default reducer;
