import { useRef, useEffect } from 'react';
import joi from 'joi';

import { abortableFetch } from '../../../utils';
import {
  useApiState,
  bindFetchToRef,
} from '../../../components/Hooks/useApiState';

const SaveBlockSchema = joi.object({
  // account_id validated through URL params.
  id: joi.string().allow('').optional(),
  name: joi.string().min(1).required(),
  darwin_client_id: joi.string().min(1).required(),
  platforms: joi.array().items(joi.string().min(1)).required(),
  category: joi.string().allow('').optional(),
  campaign_include: joi.string().allow('').optional(),
  campaign_exclude: joi.string().allow('').optional(),
  adset_include: joi.string().allow('').optional(),
  adset_exclude: joi.string().allow('').optional(),
  ad_include: joi.string().allow('').optional(),
  ad_exclude: joi.string().allow('').optional(),
});

function fetchBlocks(account_id, darwin_client_id, include_testing_blocks) {
  let payload = { account_id, darwin_client_id };
  if (include_testing_blocks)
    payload.include_testing_blocks = include_testing_blocks;
  return abortableFetch(['api', 'blocks', account_id].join('/'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    body: JSON.stringify(payload),
  });
}

function postBlock(account_id, block) {
  return fetch(['api', 'blocks', account_id, 'save'].join('/'), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(block),
  });
}

function deleteBlock(account_id, block_id) {
  return fetch(['api', 'blocks', account_id].join('/'), {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ block_id }),
  });
}

export function useGetApi(
  account_id,
  darwin_client_id,
  include_testing_blocks
) {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const fetcher = () =>
    withRef(
      fetchBlocks(account_id, darwin_client_id, include_testing_blocks)
    ).then(({ data }) => data);
  const [getState, dispatchGet] = useApiState(fetcher, {
    loading: true,
    error: null,
    data: null,
    count: 0,
  });

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return {
    ...getState,
    request: dispatchGet,
  };
}

function setFiltersForPost(filters) {
  return filters.reduce((acc, { name, include, exclude }) => {
    if (include) {
      acc[`${name}_include`] = include;
    }

    if (exclude) {
      acc[`${name}_exclude`] = exclude;
    }

    return acc;
  }, {});
}

export function usePostApi(accountId, darwin_client_id) {
  return {
    request_P: (block) => {
      if (!accountId || !darwin_client_id) {
        return Promise.reject(
          new Error('No account id or darwin client id passed.')
        );
      }

      const { filters, id, ...rest } = block;
      const payload = { ...rest, ...setFiltersForPost(filters) };

      if (!id.includes('IGNORE')) {
        payload.id = id;
      }

      payload.darwin_client_id = darwin_client_id;

      const { error } = SaveBlockSchema.validate(payload);

      if (error) {
        return Promise.reject(error);
      }
      return postBlock(accountId, payload);
    },
  };
}

export function useDeleteApi(accountId) {
  return {
    request_P: (id) => {
      if (!id) {
        return Promise.reject(new Error('No block id passed.'));
      }
      return deleteBlock(accountId, id);
    },
  };
}
