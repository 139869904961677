import React from 'react';
import { toFixed_3 } from '../../../../utils/numbers';

const useCategoryImportancesColumns = () => {
  return [
    {
      Header: 'Category',
      accessor: 'Category',
      Cell: ({
        row: {
          original: { Category },
        },
      }) => {
        return <div>{Category}</div>;
      },
    },
    {
      Header: 'Label',
      accessor: 'Label',
      Cell: ({
        row: {
          original: { Label },
        },
      }) => {
        return <div>{Label.replace(/\[|\]/g, '')}</div>;
      },
    },
    {
      Header: 'Importance Fitness',
      accessor: 'Importance_Fitness',
      Cell: ({
        row: {
          original: { Importance_Fitness },
        },
      }) => {
        return <div>{toFixed_3(Importance_Fitness)}</div>;
      },
    },
    {
      Header: 'Direction Fitness',
      accessor: 'Direction_Fitness',
      Cell: ({
        row: {
          original: { Direction_Fitness },
        },
      }) => {
        return <div>{Direction_Fitness}</div>;
      },
    },
  ];
};

export default useCategoryImportancesColumns;
