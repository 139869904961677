export const updateMetric = (metric: string): string => {
  if (metric === 'roas') return 'total_roas';
  if (metric === 'cpa') return 'total_cpa';
  if (metric === 'cpc') return 'total_cpc';
  if (metric === 'spend') return 'total_spend';
  return metric || null;
};

export const getColumnOptions = (isROAS: boolean) => {
  if (isROAS) {
    return [
      {
        label: 'Spend',
        value: 'total_spend',
      },
      {
        label: 'ROAS',
        value: 'total_roas',
      },
      {
        label: 'Total Revenue',
        value: 'total_revenue',
      },
      {
        label: 'CPC',
        value: 'total_cpc',
      },
    ];
  } else {
    return [
      {
        label: 'Spend',
        value: 'total_spend',
      },
      {
        label: 'CPA',
        value: 'total_cpa',
      },
      {
        label: 'Results',
        value: 'total_results',
      },
      {
        label: 'CPC',
        value: 'total_cpc',
      },
    ];
  }
}; 