import React from 'react';
import { DARWIN_NAVY_5 } from '../../../constants';
import { useVisualReportSet } from './contexts';

const LabelFilters = ({ labelFilters, setLabelFilters, labelOptions, handleCancel, loading, error }) => {
  const handleAddLabel = (label) => {
    if (!labelFilters.includes(label)) {
      let updatedLabels = labelFilters;
      updatedLabels.push(label);
      setLabelFilters([...updatedLabels]);
    }
  };

  const handleRemoveTag = (index) => {
    const updatedLabels = labelFilters.filter((label, i) => i !== index);
    setLabelFilters(updatedLabels);
  };

  return (
    <div className="filter-tags darwin-box-shadow">
      <div className="filter-tags__header">
        <p className="filter-tags__heading">
          Filter by Labels
        </p>
        <div className="filter-tags__select">
          <select
            className="filter-tags__select"
            style={{
              width: '100%',
              borderColor: DARWIN_NAVY_5,
            }}
            value=""
            onChange={(e) => handleAddLabel(e.target.value)}
          >
            <option className="filter-tags__option" selected value=""> -- select a label -- </option>
            {labelOptions.filter((label) => !labelFilters.includes(label)).map((opt) => {
              return <option className="filter-tags__option" value={opt}>{opt}</option>
            })}
          </select>
        </div>
      </div>
      <div className="filter-tags__body">
        <ul className="filter-tags__list">
          {labelFilters.length > 0 ? (
            labelFilters.map((label, index) => (
              <li className="filter-tags__item" onClick={() => handleRemoveTag(index)}>
                <p className="filter-tags__tag-name">{label}</p>
              </li>
            ))
          ) : (
            <p className="noselect-all">
              Search in the dropdown to add a label.
            </p>
          )}
        </ul>
      </div>
    </div>
  );
}

export default LabelFilters;