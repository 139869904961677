import React, { useRef, useState } from 'react';
import Tooltip from '../../../../../components/Tooltip';
import BeagleToggle from '../../../../../components/BeagleToggle';

import { EASY_AI, MULTIPLE_LABELS, MUTUALLY_EXCLUSIVE } from '../../constants';

import { Tag, TagsList } from '../../../../../components/Tags';

import Button from '../../../../../components/Button';
import { useSetupValidation } from './hooks';
import { createVertexApiCategory } from '../../../../../store/CreativeAnalytics/thunks';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

const validate = (input) => {
  if (input.compliance) {
    if (input.name.length && input.description.length) {
      return true;
    }
  } else if (input.name.length && input.type.length) {
    if (input.type === MUTUALLY_EXCLUSIVE && input.labels.length > 1) {
      return true;
    } else if (
      input.type === MULTIPLE_LABELS &&
      input.labels.length > 0 &&
      input.labels.length < 5
    ) {
      return true;
    }
  }

  return false;
};

type CategorySetup = {
  dropdownOptions: any;
  next: () => void;
  input: any;
  handleInput: () => void;
  removeLabel: () => void;
  existing: any;
};

const CategorySetup = ({
  next,
  prev,
  input,
  handleInput,
  removeLabel,
  existing,
  existingNames,
  isNew,
  createVertexCategory,
  editVertexCategory,
  deleteVertexCategory,
  isLoading,
  easy_ai,
}) => {
  const dispatch = useDispatch();
  const [labelInput, setLabelInput] = useState('');
  const { errors, labelInputErrors, categoryInputErrors } = useSetupValidation(
    input,
    existing,
    existingNames,
    labelInput
  );

  const addLabel = () => {
    handleInput('labels', labelInput);
    setLabelInput('');
  };

  const onKeyDown = (e) => {
    if (labelInputErrors.length < 1) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        addLabel();
      }
    }
  };

  const changeType = (type) => {
    if (!existing) {
      handleInput('type', type);
    }
  };

  return (
    <div className="category-setup">
      <h2>A.I. Driven Category</h2>
      {isNew && (
        <button
          style={{ position: 'absolute', left: '10px', top: '15px' }}
          className="sidebar__back"
          onClick={prev}
        >
          <i className="fas fa-arrow-left"></i>
          Back
        </button>
      )}

      <form action="">
        <Field>
          <div className="d-flex justify-content-between">
            <CategoryTypeOption
              isSelected={input.type === EASY_AI}
              isDisabled={!easy_ai && existing}
              onClick={() => changeType(EASY_AI)}
            >
              Easy AI
            </CategoryTypeOption>
            <CategoryTypeOption
              tooltip="Choose ‘Mutually Exclusive’ as the category type if all visuals can only have one label. Examples would be ‘background color’, ‘theme’, ‘promo type’, etc."
              isSelected={input.type === MUTUALLY_EXCLUSIVE}
              isDisabled={easy_ai && existing}
              onClick={() => changeType(MUTUALLY_EXCLUSIVE)}
            >
              Mutually Exclusive
            </CategoryTypeOption>

            <CategoryTypeOption
              tooltip="Choose ‘Multiple Labels’ as a category type if a visual can have more than one label. Examples might be ‘actor name’ (in the case of multiple actors), ‘ice cream flavors’ (if there is more than one ice cream flavor in a visual, etc.)"
              isSelected={input.type === MULTIPLE_LABELS}
              isDisabled={easy_ai && existing}
              onClick={() => changeType(MULTIPLE_LABELS)}
            >
              Multiple Labels
            </CategoryTypeOption>
          </div>
        </Field>

        <Field label="Category Name">
          <input
            value={input.name}
            type="text"
            onChange={(e) => handleInput('name', e.target.value)}
            className={`${categoryInputErrors.length ? 'input-error' : ''}`}
          />
          {categoryInputErrors.map((error) => (
            <p className="category-setup__error mt-2">{error}</p>
          ))}
        </Field>

        <Field label="Description">
          <input
            value={input.description}
            type="text"
            onChange={(e) => handleInput('description', e.target.value)}
          />
        </Field>

        <Field label="Labels" disable={input.type === EASY_AI}>
          <div className="d-inline">
            <div className="d-flex">
              <input
                type="text"
                value={labelInput}
                onChange={(e) => setLabelInput(e.target.value)}
                onKeyDown={onKeyDown}
                className={`${labelInputErrors.length ? 'input-error' : ''}`}
              />
              <Button
                className="ml-3"
                appearance="secondary"
                onClick={addLabel}
                disabled={labelInputErrors.length}
              >
                Add
              </Button>
            </div>
          </div>
          {labelInputErrors.map((error) => (
            <p className="category-setup__error mt-2">{error}</p>
          ))}
          <div className={input.labels.length ? 'mt-4' : ''}>
            <TagsList>
              {input.labels &&
                input.labels.map((label) => (
                  <Tag onClick={() => removeLabel(label)}>{label}</Tag>
                ))}
            </TagsList>
          </div>
        </Field>
      </form>
      <div className="category-setup__btn">
        {errors.length ? (
          <Tooltip
            content={
              <ul style={{ listStyle: 'none', padding: 0, marginBottom: 0 }}>
                {errors.map((err) => (
                  <li>{err}</li>
                ))}
              </ul>
            }
          >
            <i
              className="fa fa-circle-exclamation mr-3"
              style={{ color: '#E08797' }}
            ></i>
          </Tooltip>
        ) : null}
        {!!existing && input.type === EASY_AI && (
          <Button
            onClick={deleteVertexCategory}
            appearance="subtle"
            className="mr-3"
          >
            Delete
          </Button>
        )}
        <Button
          disabled={errors.length}
          loading={isLoading}
          onClick={() => {
            if (input.type === EASY_AI) {
              console.log('create');
              console.log(existing);
              if (!!existing) {
                editVertexCategory();
              } else {
                createVertexCategory();
              }
            } else {
              next();
            }
          }}
        >
          {getButtonText(input, existing)}
        </Button>
      </div>
    </div>
  );
};

export default CategorySetup;

const getButtonText = (input, existing) => {
  if (input.type === EASY_AI) {
    if (existing) return 'Save';
    return 'Create';
  }
  return 'Next';
};

const Field = ({ label, disable = false, children }) => {
  if (disable) return null;

  return (
    <div className="category-setup__group">
      {label && <label htmlFor="">{label}</label>}
      {children}
    </div>
  );
};

const CategoryTypeOption = ({
  tooltip = '',
  isSelected = false,
  isDisabled = false,
  children,
  onClick,
}) => {
  return (
    <Tooltip shouldShow={!!tooltip?.length} content={tooltip}>
      <div
        className={clsx('category-setup__type', {
          'category-setup__type--selected': isSelected,
          'category-setup__type--disabled': isDisabled,
        })}
        onClick={isDisabled ? () => null : onClick}
      >
        {children}
      </div>
    </Tooltip>
  );
};
