import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import SplitsModal from '../SplitsModal';
import { format$ } from './utils';
import CollapsibleItem from '../../../components/CollapsibleItem/CollapsibleItem';

function DemoTable({
  title,
  rows,
  valueId,
  placeholder,
  colors: { tertiary },
}) {
  const { isROAS } = useSelector((state) => state.ViewSettings);
  const [show, setShow] = useState(false);
  const [segments, setSegments] = useState({});

  const handleCheck = (index, checked) => {
    let update = { ...segments };

    if (!checked) {
      delete update[index];
    } else {
      update[index] = true;
    }

    setSegments(update);
  };

  const Header = (
    <div className="display-table__header">
      {/* <p className="display-checkbox">&emsp;</p> */}
      <p className="display-table__type">Type</p>
      <p className="display-table__num">{isROAS ? 'ROAS' : 'CPA'}</p>
      <p className="display-table__num">Spend</p>
    </div>
  );

  const Rows = rows.map(({ type, spend, cpa, roas }, i) => {
    const checked = !!segments[i];
    const performanceMetric = isROAS ? roas : cpa;

    return (
      <div
        className="display-table__values d-flex"
        key={`${type}-${performanceMetric}-${i}`}
      >
        {/* <div className="display-checkbox">
          <input
            type="checkbox"
            checked={checked}
            onChange={(e) => handleCheck(i, !checked)}
          />
        </div> */}
        <p className="display-table__type">{type}</p>
        <p className="display-table__num">{format$(performanceMetric)}</p>
        <p className="display-table__num">{format$(spend)}</p>
      </div>
    );
  });

  return (
    <>
      <div className="right-display__card">
        <CollapsibleItem
          AnchorChild={
            <p className="display-table__heading">
              <i class="fa-solid fa-play"></i>
              {title}
            </p>
          }
          ExpandableChild={
            <>
              {Header}
              {placeholder || <div>{Rows}</div>}
            </>
          }
        />
      </div>
      {/* <SplitsModal
        id="cr-splits-modal"
        show={show}
        handleHide={(e) => setShow(false)}
        segments={rows.filter((_, i) => !!segments[i]).reduce(toSegments, {})}
        adsetName={valueId}
      /> */}
    </>
  );
}

DemoTable.propTypes = {
  valueId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      spend: PropTypes.string,
      cpa: PropTypes.string,
    })
  ).isRequired,
  loading: PropTypes.bool,
  colors: PropTypes.shape({
    tertiary: PropTypes.string.isRequired,
  }).isRequired,
};

export default DemoTable;

function toSegments(acc, { type }) {
  const [s1, s2] = type.split(' ');

  acc[s1] = true;
  s2 && (acc[s2] = true);

  return acc;
}
