import React, { useState, useEffect, useRef } from 'react';
import { BrowserIcon } from '../../../Explorer/icons/ToolbarIcons';
import { useDraftCreativeContext } from '../../contexts/draftCreativeContext';
import Visual from './Visual';
import './Card.scss';
import {
  DESCRIPTION,
  S3_IMAGE_URL,
  S3_VIDEO_URL,
  S3_VISUAL,
} from '../../constants';
import { SET_ADDITIONAL_NOTE } from '../../hooks/useDraftCreativeState';

const Card = ({
  value,
  type,
  visualType,
  onClick,
  valueAction,
  isFinal,
  cardProps,
}) => {
  const [focused, setFocused] = useState(false);
  const ref = useRef();

  const {
    draftCreativeDispatch,
    rowIndex,
    loading,
    handleOpenDrawer,
    disableDrawer,
    isSharedReport,
  } = cardProps;

  const handleBlockValue = (value) => {
    draftCreativeDispatch({
      type: valueAction,
      value,
      rowIndex,
    });
  };

  return (
    <div
      className={`draft-creative__card draft-creative__card--${
        loading ? 'loading' : ''
      }`}
      onClick={() => {
        if (loading) return;
        if (onClick && !value) onClick();
      }}
      style={{ cursor: type !== DESCRIPTION && !!type ? 'pointer' : 'default' }}
    >
      {type === S3_VISUAL || visualType ? (
        <>
          <Visual
            asset_url={value}
            visual_type={visualType}
            {...{ loading, handleOpenDrawer, disableDrawer }}
          />
          {!isSharedReport &&
            ![S3_IMAGE_URL, S3_VIDEO_URL].includes(type) &&
            !isFinal && (
              <div
                className="edit-icon"
                onClick={() => {
                  if (loading) return;
                  onClick && onClick();
                }}
              >
                <i className="fa-solid fa-pencil" />
              </div>
            )}
        </>
      ) : (
        <div className="description">
          <textarea
            value={value || ''}
            onChange={(e) => handleBlockValue(e.target.value)}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            ref={ref}
          />
          {!value && !focused && (
            <div
              className="description__placeholder"
              onClick={() => {
                setFocused(true);
                if (ref.current) ref.current.focus();
              }}
            >
              <i
                className="fa-solid fa-i-cursor"
                style={{
                  color:
                    valueAction === SET_ADDITIONAL_NOTE ? '#ebcf33' : '#ccc',
                }}
              />
            </div>
          )}
          {!focused && !isSharedReport && (
            <div
              className="edit-icon"
              onClick={() => {
                setFocused(true);
                if (ref.current) ref.current.focus();
              }}
            >
              <i className="fa-solid fa-pencil" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Card;
