/* eslint-disable arrow-body-style */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { ErrorBoundary } from '../../components/Helpers/Error';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  ADSET_NAME,
  AD_NAME,
  CLUSTERS,
  COPILOT,
  COPY,
  DASHBOARD,
  HURDLE,
  LIBRARY,
  PERFORMANCE_FREQUENCY,
  PHRASE_THEME,
  RECOMMENDATIONS,
  REPORT_BUILDER,
  TRENDING,
  VARIATION,
} from '../../store/CreativeReports/constants';
import PerformanceFrequency from './PerformanceFrequency';
import TryThese from './TryThese';
import { useCreativeAnalyticsApis } from './api';
import {
  useAdNameLabelsApi,
  useGetAdNameLabelsApi,
} from './CategoryBuilder/Custom/AdNameLabels/api';
import {
  useAnalyticsParams,
  useStandardLabels,
  useElementSelect,
  useDatePeriods,
} from './hooks';
import { setReports } from '../../store/CreativeReports/actions';
import { useGetDnaTableApi } from './api';
import { darwinDateToDate } from '../../utils/darwin-dates';
import './CreativeAnalytics.scss';
import AsyncCCCProcess from './CategoryBuilder/AsyncCCCProcess';
import TopPerformers from './TopPerformers';
import Header from './Header/Header';
import CategoryBuilder from './CategoryBuilder';
import ReportCard from './ReportCard';
import {
  CREATIVE_ANALYTICS,
  OPEN_CREATIVE_REQUEST_DRAWER,
} from '../../store/UserActivity/constants';
import CreativeRequest from './CreativeRequest';
import { CreativeRequestProvider } from './CreativeRequest/contexts';
import { CreativeAnalyticsProvider } from './contexts';
import LeftDisplay from './LeftDisplay';
import { useColumns } from './PerformanceFrequency/hooks';
import { makeSearchLookup } from './utils';
import useCategoryFilters from './hooks/useCategoryFilters';
import { trackEvent } from '../../store/UserActivity/actions';
import { useUrlParams } from './hooks/useUrlParams';
import { LabelObject, MissingDataType, RightDrawerOptions } from './types';
import VisualsLibrary from './VisualLibrary/VisualsLibrary';
import { useUDCApi } from './CategoryBuilder/Custom/UDC/api';
import { usePortals } from './hooks/usePortals';
import Tooltip from '../../components/Tooltip';
import { getColumnDropdownOptions } from './PerformanceFrequency/Columns';
import {
  useCustomEvents,
  useCustomMetrics,
  useLabelsLookup,
} from './selectors';
import InsightsDashboard from '../InsightsDashboard/InsightsDashboard';
import LabelsSetup from './BasicLabelsSetup/BasicLabelsSetup';
import { useSearchTermsApi } from './BasicLabelsSetup/api';
import { useLibraryAnalyticsState } from './LibraryAnalytics/hooks';
import { useSearchParams } from '../../components/Hooks/search-params';
import {
  setEditCategory as setEditCategoryAction,
  setIsSettingsOpen,
  setNavCategories,
  setRightDrawerDisplay,
  setTableData,
} from '../../store/CreativeAnalytics/creativeAnalyticsSlice';
import { PHRASE_BUILDER, REPORT_CARD } from './ReportCard/constants';
import { useDerivedLabelsApi } from './CategoryBuilder/Custom/DerivedLabels/api';
import ActionButtons from './ActionButtons/ActionButtons';
import { CreativeRequestToggle } from './CreativeRequest/Toggle';
import { DashboardEditModeToggle } from '../InsightsDashboard/DashboardEditModeToggle';
import CategoryEditor from './ReportCard/CategoryEditor';
import { useClickOutside } from '../../hooks/useClickOutside';
import { ClickAwayListener } from '@mui/material';
import './ActionButtons/ActionButtons.scss';
import BoxPlot from './BoxPlot';
import Explorer from './Explorer';
import ExplorerContextProvider from './Explorer/contexts/explorerContext';
import Overlay from '../../components/Overlay';
import Modal from '../../components/Modal';
import { useReportCardPrefs } from './hooks/useReportCardPrefs';
import { useEventTracking } from './hooks/useEventTracking';
import { useKeywords } from './hooks/useKeywords';
import { usePerformanceData } from './hooks/usePerformanceData';
import { useInit } from './hooks/useInit';
import { useDnaTable } from './hooks/useDnaTable';
import { useReportRefresh } from './hooks/useReportRefresh';
import { useNewAds } from './hooks/useNewAds';
import { useMissingSpend } from './hooks/useMissingSpend';
import { useLabelSetup } from './hooks/useLabelSetup';
import { useDerivedLabelsDelete } from './hooks/useDerivedLabelsDelete';
import { getCpasList } from './utils/getCpasList';
import { getPrimaryPhraseLabels } from './utils/getPrimaryPhraseLabels';
import Settings from './Settings';
import IconButton from '../../components/IconButton';
import { MoreSettings } from './Settings/MoreSettings';
import Button from '../../components/Button';
import { RefreshReport } from './Settings/RefreshReport';
import {
  useCreativeAnalyticsStore,
  useLoading,
  useViewSettings,
} from '../../selectors';
import Recommendations from './Recommendations';
import {
  useRecommendationsApi,
  useRecommendationsStatus,
} from './Recommendations/api';
import DNATable from '../DNA';
import Dashboard from '../InsightsDashboard';
import CreativeReports from '../CreativeReports';
import Scatter from './Scatter';
import { RightDrawerWrapper } from './RightDrawer';
import MissingData from './MissingData';
import { Banner } from './Banner';
import PhraseTheme from './PhraseTheme';
import ActiveIcon from '../../components/Icons/ActiveIcon';
import BlockReport from '../CreativeReports/BlockReport';
import ImpactReport from './ImpactReport';
import _isEqual from 'lodash/isEqual';
import { lastMonthEnd, lastMonthStart } from './ImpactReport/constants';
import { GlobalKeywordSettings } from './Settings/GlobalKeywordSettings';
import Toggle from '../../components/BeagleToggle';
import Clusters from './Clusters';
import SuccessConditions from './SuccessConditions';
import { useCustomScoringApi, useMetadataApi } from '../DNA/api';
import Dna from '../DNA';
import { useLabelSelection } from './hooks/useLabelSelection';
import { usePhraseThemeData } from './hooks/usePhraseThemeData';
import { deriveUdcLabels } from './utils/deriveUdcLabels';
import { useFilteredData } from './hooks/useFilteredData/useFilteredData';
import Variation from './Variation';
import Hurdle from './Hurdle';
import Copilot from './CreativeCopilot';
import { checkIfVisualIsSelected } from './hooks/useFilteredData/utils/filterVisuals';
import { addMetrics } from '../DNA/PeriodComparison/utils';
import { getRows } from '../DNA/utils';
import Copy from './CopyReport';
import { useShareStaticReport } from './hooks/useShareStaticReport';
import { ShareReport } from '../DNA/ShareReport';
import { QA, useShowQaEditor } from './CategoryBuilder/QA';
import { useSetShowQaEditor } from './LeftDisplay/components/Menu/hooks';
import { HideLabels } from './Settings/HideLabels';
import {
  PlanningFillIcon,
  PlanningNoFillIcon,
} from './PerformanceFrequency/icons';
import { TrendingIcon } from './LeftDisplay/icons/trending';
import { Bar } from './LeftDisplay/icons/bar';
import { usePermissions } from '../../components/Permissions/usePermissions';
import useTrackDeps from '../../hooks/useTrackDeps';
import {
  DRAFT_CREATIVE,
  GROUP_BY_AD_NAME,
} from '../../components/Permissions/constants';
import { buildRequestShape, formatRecs } from './Recommendations/utils';
import DraftCreativeProjects from './DraftCreative';
import { useSavedConceptsApi } from './TryThese/api';
import { notInData, sortTryThese } from './TryThese/utils';
import {
  SET_SECOND_VALUE,
  SET_SECOND_VISUAL_TYPE,
  useDraftCreativeState,
} from './DraftCreative/hooks/useDraftCreativeState';
import { TOP_PERFORMERS } from './TopPerformers/constants';
import RightRail from './DraftCreative/RightRail';
import { useSearchLookup } from './hooks/useSearchLookup';
import { useExplorerState } from './Explorer/hooks/useExplorerState';
import RulesManager from './RulesManager';
import { ReportBuilder } from './ReportBuilder';
import { PivotReport } from './PivotReport';
import { processCategories } from './utils/processCategories';
import { CUSTOM_SCORING_METRICS } from './PerformanceFrequency/constants';
import { useSelectFeature } from './hooks/useSelectFeature';

type CreativeAnalyticsProps = {
  view_id: string;
  viewName: string;
  account_id: string;
  start_date: string;
  end_date: string;
  selectedAudience: string;
  only_new_ads: boolean;
  insights_by_asset: boolean;
  show_request: boolean;
  isDirectMail: boolean;
  actionName: string;
  mode: 'library' | 'analytics';
  gaEnabled: boolean;
  isNonPaidMediaType: boolean;
  isUltraLight: boolean;
  rightDrawerDisplay: RightDrawerOptions;
  missingData: MissingDataType;
  customIntegratedMetrics: [];
};

const CreativeAnalytics = ({
  view_id,
  viewName,
  account_id,
  only_new_ads,
  insights_by_asset,
  show_request,
  isDirectMail,
  actionName,
  mode = 'analytics',
  gaEnabled,
  isNonPaidMediaType,
  isUltraLight,
  rightDrawerDisplay,
  customIntegratedMetrics,
  additional_metrics,
  selectedAudience: storedAudience,
  isOrganicView: organicView,
  shouldGroupByAdName,
  selectedTab,
  isNoom,
  isSkyZone,
  isWisdomPanel,
  display,
  isSettingsOpen,
  hiddenLabels,
  editCategory,
  setPfrLoading,
}: CreativeAnalyticsProps) => {
  const isOrganicView =
    organicView || selectedTab?.tab_name.includes('Organic');
  const dispatch = useDispatch();
  const [{ period1, period2, rc, refresh }, setParams] = useAnalyticsParams();
  const { setParams: setSearchParams, getParams } = useSearchParams();
  const isROAS = actionName && actionName.value?.includes('value_');
  const isCompetitorView =
    isDirectMail && viewName.toLowerCase().includes('competitor');
  const setEditCategory = (payload) => dispatch(setEditCategoryAction(payload));
  const [editCategoryVisual, setEditCategoryVisual] = useState(null);
  const {
    spendThreshold,
    creativeCount,
    recDepth,
    fixedLabels,
    sort,
    min,
    primary_metric: primaryMetricParam,
    category,
  } = useUrlParams(isROAS, isOrganicView);
  const {
    newAdsApi,
    asyncApi,
    postRefreshApi,
    refreshStatusApi,
    missingSpendApi,
    getUdcApi,
    revisionRequestApi,
    keywordsApi,
    reportCardPrefsApi,
    getDerivedLabelsApi,
    presetsApi,
    clustersApi,
    iterationsApi,
    recoRulesApi,
  } = useCreativeAnalyticsApis();
  const libraryAnalyticsState = useLibraryAnalyticsState();
  const [fullData, setFullData] = useState<LabelObject[] | null>(null);
  const { categoryFilters, dispatchCategoryFilter } = useCategoryFilters(mode);
  const [
    standardLabels,
    trending,
    executionStyles,
    inspiration,
    categoryLabelOptions,
  ] = useStandardLabels(account_id);
  const [recsTab, setRecsTab] = useState('test_more');
  const [TTNTSections, setTTNTSections] = useState(null);
  const {
    ActionButtonsPortal,
    CreativeRequestPortal,
    ReportCardPortal,
    LibraryAnalyticsPortal,
  } = usePortals();
  const [period1Start, period1End, period2Start, period2End] = useDatePeriods(
    period1,
    period2
  );

  const [showActiveOnly, setShowActiveOnly] = useState(false);
  const fromCreativeStudio = false;
  const dnaTableApi = useGetDnaTableApi();
  const {
    reportCardDataType,
    setReportCardDataType,
    reportCardMetric,
    setReportCardMetric,
    reportCardSettings,
    fetchCustomSettings,
    dispatchReportCardSettings,
    loadingRcSettings,
  } = useReportCardPrefs(view_id, isROAS, reportCardPrefsApi);
  const { newAds } = useNewAds(newAdsApi);
  const [requestOpen, setRequestOpen] = useState(false);
  const customEvents = useCustomEvents();
  const { darwin_client_id, pull_live_metrics, label_queue, isEmailView } =
    useViewSettings();
  const [libraryView, setLibraryView] = useState('library');
  const searchTermsApi = useSearchTermsApi();
  const [reportCardMode, setReportCardMode] = useState(REPORT_CARD);
  const [openedMenu, setOpenedMenu] = useState(null);
  const [activeBoxPlotCategory, setActiveBoxPlotCategory] = useState(null);
  const ref = useRef();
  const getAdNameLabelsApi = useGetAdNameLabelsApi();
  const recommendationsStatus = useRecommendationsStatus();
  const params = getParams(
    'audience',
    'min',
    'labels',
    'columns',
    'dna_columns',
    'udc_labels'
  );
  const selectedAudience = storedAudience || params.audience || '';
  const resultsThreshold = params.min || 0;
  const labelsParam = params.labels || '';
  const columnsParam = params.columns || '';
  const dnaColumnsParam = params.dna_columns || '';
  const udcLabelsParam = params.udc_labels || '';
  const rcColumnsParam = params.rc_columns || '';
  const { audienceOptions, metadata, custom_performance_scores } = useSelector(
    ({
      ViewSettings: { audience, custom_performance_scores },
      CreativeAnalytics: { dnaReportData },
    }) => {
      return {
        audienceOptions: audience || [],
        metadata: dnaReportData,
        custom_performance_scores,
      };
    }
  );
  const explorerState = useExplorerState();
  const [defaultPfrColumns, setDefaultPfrColumns] = useState(null);
  const [defaultDnaColumns, setDefaultDnaColumns] = useState(null);
  const DEFAULT_START = viewName.toLowerCase().split(' ').includes('demo')
    ? new Date('January 1, 2010')
    : lastMonthStart;
  const defaultDates = [DEFAULT_START, lastMonthEnd];
  const [clustersData, setClustersData] = useState([]);
  const [impactCategories, setImpactCategories] = useState([]);
  const [showQaEditor, setShowQaEditor] = useShowQaEditor();
  const [planningMode, setPlanningMode] = useState(false);
  const [coreVisuals, setCoreVisuals] = useState({
    0: { urls: [], types: [], html: '' },
    1: { urls: [], types: [], html: '' },
    2: { urls: [], types: [], html: '' },
    3: { urls: [], types: [], html: '' },
  });
  const defaultRequestWidth = 580;
  const [requestWidth, setRequestWidth] = useState(defaultRequestWidth);
  const [customScoringData, setCustomScoringData] = useState([]);
  const [iterationsData, setIterationsData] = useState([]);
  const [presetsOpen, setPresetsOpen] = useState(false);
  const [savedConcepts, setSavedConcepts] = useState([]);
  const savedConceptsApi = useSavedConceptsApi();

  const [selectedDraftProject, setSelectedDraftProject] = useState(null);
  const draftCreativeState = useDraftCreativeState();

  const isFacebookView = selectedTab?.tab_name.includes('Facebook');
  const isInstagramView = selectedTab?.tab_name.includes('Instagram');
  const isLinkedInView = selectedTab?.tab_name.includes('LinkedIn');

  const [activeTTSection, setActiveTTSection] = useState(TOP_PERFORMERS);
  const [showRightRail, setShowRightRail] = useState(false);
  const railRef = useRef();
  useClickOutside(railRef, () => setShowRightRail(false));
  const [showRulesManager, setShowRulesManager] = useState(false);
  const selectFeature = useSelectFeature();

  const {
    data: getRecoRulesData,
    error: getRecoRulesError,
    loading: getRecoRulesLoading,
    request: getRecoRulesRequest,
  } = recoRulesApi.get;
  const {
    data: deleteRecoRulesData,
    loading: deleteRecoRulesLoading,
    request: deleteRecoRulesRequest,
  } = recoRulesApi.delete;
  const { data: saveRecoRulesData, request: saveRecoRulesRequest } =
    recoRulesApi.post;

  useEffect(() => {
    getRecoRulesRequest(view_id);
  }, []);

  const {
    draftCreativeState: { currentRowIndex },
    draftCreativeDispatch,
  } = draftCreativeState;

  const handleBlockValue = (action, value) => {
    draftCreativeDispatch({
      type: action,
      value: value,
      rowIndex: currentRowIndex,
    });

    setShowRightRail(false);
  };

  const handleBlockVisualType = (action, type) => {
    draftCreativeDispatch({
      type: action,
      value: type,
      rowIndex: currentRowIndex,
    });
  };

  useEffect(() => {
    if (!requestOpen && planningMode) setRequestOpen(true);
    if (requestOpen && !planningMode) setRequestOpen(false);
  }, [planningMode]);

  useEffect(() => {
    if (!requestOpen) {
      setRequestWidth(0);
    } else {
      setRequestWidth(defaultRequestWidth);
    }
  }, [requestOpen]);

  const udc_labels = useMemo(() => {
    return deriveUdcLabels(udcLabelsParam);
  }, [udcLabelsParam]);

  const [columns, handleColumns] = useColumns({
    isROAS,
    primary_metric: primaryMetricParam,
    display,
    isOrganicView,
    columnsParam,
    dnaColumnsParam,
    setDefaultPfrColumns,
    setSearchParams,
    planningMode,
    rcColumnsParam,
    rc,
    isFacebookView,
    isInstagramView,
    isLinkedInView,
  });

  const primary_metric = primaryMetricParam.replace('median_', '');

  const [groupByAdName, setGroupByAdName] = useState(shouldGroupByAdName);

  const performanceDataProps = {
    view_id,
    account_id,
    period1Start,
    period1End,
    period2Start,
    period2End,
    getUdcApi,
    keywordsApi,
    getDerivedLabelsApi,
    getAdNameLabelsApi,
    fetchCustomSettings,
    showActiveOnly,
    spendThreshold,
    only_new_ads,
    newAds,
    minResults: min,
    groupByAdName,
    setGroupByAdName,
    customIntegratedMetrics,
    // clientMetrics,
    customScoringData,
    additional_metrics,
    primary_metric,
    labelsParam,
    udc_labels,
    selectedAudience,
    refresh,
    loadingRcSettings,
  };

  const {
    data,
    metricsData,
    originalMetricsData,
    error,
    loading,
    loadingCompare,
    fetchPerformanceData,
    selectedLabels,
    visualIdsFromSelectedLabels,
    showLoading,
    retrievedDate,
    clientMetrics,
  } = usePerformanceData({
    performanceDataProps,
    setParams,
  });
  useInit({
    view_id,
    insights_by_asset,
    fetchPerformanceData,
    period1Start,
    period1End,
    period1,
    period2,
    period2Start,
    period2End,
    newAdsApi,
  });

  useClickOutside(ref, () => {
    if (openedMenu && ref.current) {
      setOpenedMenu(null);
    }
  });

  const isUBeauty = account_id === 'act_478482719415309';
  useEffect(() => {
    if (isUBeauty) {
      setSearchParams({
        spendThreshold: spendThreshold ?? 500,
        primary_metric: 'cp_Rockerbox_Conversions',
      });
    }
  }, [isUBeauty]);

  useEffect(() => {
    if (isOrganicView) {
      const primary = actionName.name || clientMetrics[0]; // in case actionName is empty
      setSearchParams({ spendThreshold: 1, primary_metric: primary });
    }
  }, [isOrganicView, clientMetrics]);

  useEffect(() => {
    if (getAdNameLabelsApi.data) {
      try {
        if (!Object.keys(getAdNameLabelsApi.data.ad_name_labels).length) {
          reportCardSettings.deleteCategoryFromSettings('Ad Name Labels');
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [getAdNameLabelsApi.data]);

  const [dashboardEditMode, setDashboardEditMode] = useState(false);

  useEffect(() => {
    if (dashboardEditMode) {
      setRequestOpen(false);
      libraryAnalyticsState.setOpenLibraryAnalytics(false);
    }
  }, [dashboardEditMode]);

  useEffect(() => {
    dispatch(setReports({ show_request: requestOpen }));
  }, [requestOpen]);

  const columnDropdownOptions = useMemo(
    () =>
      getColumnDropdownOptions({
        isROAS,
        isDirectMail,
        clientMetrics,
        gaEnabled,
        customEvents,
        additional_metrics,
        customIntegratedMetrics,
        isOrganicView,
        account_id,
        isEmailView,
        customScoringData,
        isWisdomPanel,
        selectedTab,
      }),
    [
      isROAS,
      isDirectMail,
      clientMetrics,
      gaEnabled,
      customEvents,
      additional_metrics,
      customIntegratedMetrics,
      isOrganicView,
      account_id,
      isEmailView,
      customScoringData,
      isWisdomPanel,
      selectedTab,
    ]
  );

  const udcApi = useUDCApi(
    setEditCategory,
    fetchPerformanceData,
    reportCardSettings
  );

  const derivedLabelsApi = useDerivedLabelsApi(fetchPerformanceData);

  useEventTracking(libraryView, mode);

  useEffect(() => {
    if (editCategory) setEditCategoryVisual(null);
  }, [editCategory]);

  const [
    {
      filteredData,
      filteredAllVisuals: filteredVisuals,
      totalBudget,
      categoriesLookup,
    },
  ] = useFilteredData({
    data,
    metricsData,
    standardLabels,
    newAds,
    spendThreshold,
    creativeCount,
    categoryFilters,
    visualIdsFromSelectedLabels,
    isDirectMail,
    isROAS,
    min,
    isOrganicView,
    isCompetitorView,
    primary_metric,
    viewName,
    clustersData,
    customScoringData,
    isNoom,
    isSkyZone,
    reportCardDataType,
    reportCardSettings,
    isWisdomPanel,
    selectedTab,
  });

  const labelsLookup = useLabelsLookup();
  useEffect(() => {
    const { navCategories, tableData } = processCategories({
      reportCardSettings,
      categoryFilters,
      hiddenLabels,
      labelsLookup,
      categoriesLookup,
    });

    batch(() => {
      dispatch(setNavCategories(navCategories));
      dispatch(setTableData(tableData));
    });
  }, [reportCardSettings, categoryFilters, hiddenLabels, labelsLookup]);

  const tryTheseTrending = () =>
    trending.filter((trendingLabel) => notInData(trendingLabel, filteredData));

  const tryTheseInspiration = () => {
    const inspirationLabels = inspiration;
    const executionStylesLabels = executionStyles.filter(
      (label) => label.status === 'active'
    );

    const merged = [...inspirationLabels, ...executionStylesLabels];

    return merged.filter((standardLabel) =>
      notInData(standardLabel, filteredData)
    );
  };

  const trendingRecs = tryTheseTrending();
  const inspirationRecs = tryTheseInspiration();

  const { custom_events } = useCustomMetrics();

  const { existingKeywords, allKeywords } = useKeywords({
    filteredData,
    fullData,
    keywordsApi,
  });
  const { handleSelectLabel, handleFilter } = useLabelSelection({
    selectedLabels,
    dispatchCategoryFilter,
    mode,
    fetchPerformanceData,
    selectedAudience,
    labelsParam,
    data,
    filteredData,
    audienceOptions,
    setSearchParams,
    setParams,
    params,
  });

  const { ExportButton } = useDnaTable({
    view_id,
    account_id,
    period1Start,
    period1End,
    dnaTableApi,
  });

  const cpasList = useMemo(
    () => getCpasList(filteredVisuals, isROAS),
    [filteredVisuals]
  );

  const [selected, handleSelectElement] = useElementSelect(
    period1,
    period2,
    filteredData,
    metricsData,
    isROAS,
    totalBudget,
    spendThreshold,
    isOrganicView,
    clientMetrics,
    primary_metric
  );

  const metricOptions = reportCardSettings.metric_options;

  const { categorySettings } = reportCardSettings;

  const adNameLabelsApi = useAdNameLabelsApi(
    fetchPerformanceData,
    getAdNameLabelsApi,
    account_id
  );

  useDerivedLabelsDelete(fetchPerformanceData, reportCardSettings);

  const filteredSearchLookup = useMemo(() => {
    return makeSearchLookup(filteredData, categorySettings);
  }, [filteredData, categorySettings]);

  const primaryPhraseLabels = useMemo(
    () => getPrimaryPhraseLabels(data),
    [data]
  );

  const { handleRefreshReport } = useReportRefresh(
    account_id,
    postRefreshApi,
    refreshStatusApi
  );
  const analyticsComp = () => {
    switch (display) {
      case RECOMMENDATIONS:
        return TopPerformers;
      case PHRASE_THEME:
        return PhraseTheme;
      case 'dna_table':
        return null;
      case ADSET_NAME:
      case AD_NAME:
        return CreativeReports;
      case DASHBOARD:
        return Dashboard;
      case 'block_report':
        return BlockReport;
      case LIBRARY:
        return VisualsLibrary;
      case 'impact_report':
        return ImpactReport;
      case CLUSTERS:
        return Clusters;
      case VARIATION:
        return Variation;
      case REPORT_BUILDER:
        return ReportBuilder;
      case HURDLE:
        return Hurdle;
      case COPILOT:
        return Copilot;
      case COPY:
        return Copy;
      case DRAFT_CREATIVE:
        return DraftCreativeProjects;
      case PERFORMANCE_FREQUENCY:
        return PerformanceFrequency;
      default:
        return PivotReport;
    }
  };

  let Comp = null;
  const isDashboard = mode === LIBRARY && libraryView === 'insights';

  const bypassLoading = [REPORT_BUILDER, 'block_report', 'dna_table'].includes(
    display
  );
  const bypassNoData = [
    REPORT_BUILDER,
    RECOMMENDATIONS,
    'block_report',
    DRAFT_CREATIVE,
  ].includes(display);

  switch (mode) {
    case 'analytics':
      Comp = analyticsComp();
      break;
    case LIBRARY:
      Comp = isDashboard ? InsightsDashboard : VisualsLibrary;
      break;
    default:
      Comp = PerformanceFrequency;
  }

  if (!insights_by_asset) {
    Comp = PerformanceFrequency;
  }

  const handleClickAway = () => {
    setOpenedMenu(false);
  };

  const enableCreativeRequest =
    insights_by_asset && reportCardMode !== PHRASE_BUILDER;
  const isPF =
    Comp === PerformanceFrequency || Comp === DNATable || Comp === PhraseTheme;

  useMissingSpend({
    data,
    view_id,
    isPF,
    missingSpendApi,
    start_date: period1Start,
    end_date: period1End,
    pull_live_metrics,
    label_queue,
  });

  const {
    sharedReportId,
    handleSharePfr,
    shouldShowShareModal,
    closeShareModal,
    submitShareStaticPfr,
    loadingSharePfr,
    error: shareError,
    status: shareStatus,
  } = useShareStaticReport({
    columnOptions: columnDropdownOptions,
    filteredData,
    filteredVisuals,
    reportCardSettings,
    columns,
    selectedLabels,
    period1Start,
    period1End,
    primary_metric,
    clientMetrics,
    category,
    customScoringData,
  });

  const sharePayloadError =
    shareStatus === 'error' &&
    shareError &&
    (shareError.includes('SyntaxError') ||
      shareError.includes('BSON document too large'));

  const {
    searchTermsData,
    searchTermsLoading,
    searchTermsError,
    LabelSetupIcon,
    editBasicLabels,
    setEditBasicLabels,
  } = useLabelSetup({
    account_id,
    filteredData,
    fullData,
    setFullData,
    searchTermsApi,
  });

  let RightDrawer = null;

  switch (rightDrawerDisplay) {
    case 'recommendations':
      RightDrawer = <Recommendations />;
      break;
    case 'scatter':
      RightDrawer = <Scatter />;
      break;
    case 'missing_data':
      RightDrawer = <MissingData />;
      break;
    default:
      RightDrawer = null;
      break;
  }

  const { selectedVisuals } = useCreativeAnalyticsStore();
  const previousPayloadRef = useRef(null);
  const { data: recsData, request } = useRecommendationsApi();
  const [idsInLastRequest, setIdsInLastRequest] = useState([]);
  const [recs, setRecs] = useState([]);

  useEffect(() => {
    if (recsData) {
      setRecs(formatRecs(recsData));
    }
  }, [recsData]);

  useEffect(() => {
    if (filteredData?.length > 0) {
      fetchRecs();
    }
  }, [filteredData]);

  const fetchRecs = (shouldRefetch = false) => {
    if (
      !filteredVisuals ||
      (display === 'dna_table' && selectedVisuals.length < 2)
    )
      return;

    const payload = {
      view_id,
      client_id: darwin_client_id,
      ...buildRequestShape(filteredVisuals, primary_metric),
    };

    if (shouldRefetch || !_isEqual(previousPayloadRef.current, payload)) {
      request(payload);
      setIdsInLastRequest(filteredVisuals.map(({ id }) => id));
    }

    previousPayloadRef.current = payload;
  };

  // console.log(filteredVisuals.map((visual) => visual.id));
  // console.log({ clientMetrics})
  const hasPermission = usePermissions();
  const hideTrending =
    !hasPermission(TRENDING) ||
    isUltraLight ||
    isOrganicView ||
    viewName.includes('Email');

  const isGoHealth = account_id === 'act_637485863314808';
  if (error) {
    return <h3>There was an error. {error.toString()}</h3>;
  }
  return (
    <ErrorBoundary>
      <CreativeAnalyticsProvider
        account_id={account_id}
        data={data}
        filteredData={filteredData}
        asyncApi={asyncApi}
        display={display}
        category={category}
        spendThreshold={spendThreshold}
        creativeCount={creativeCount}
        period1={period1}
        period2={period2}
        start_date={darwinDateToDate(period1Start)}
        end_date={darwinDateToDate(period1End)}
        newAds={newAds}
        primaryPhraseLabels={primaryPhraseLabels}
        categoryLabelOptions={categoryLabelOptions}
        selected={selected}
        handleSelectElement={handleSelectElement}
        editCategory={editCategory}
        editCategoryVisual={editCategoryVisual}
        isROAS={isROAS}
        setEditCategory={setEditCategory}
        setEditCategoryVisual={setEditCategoryVisual}
        categoryFilters={categoryFilters}
        selectedLabels={selectedLabels}
        setParams={setParams}
        clientMetrics={clientMetrics}
        getUdcApi={getUdcApi}
        derivedLabelsApi={derivedLabelsApi}
        getDerivedLabelsApi={getDerivedLabelsApi}
        cpasList={cpasList}
        reportCardDataType={reportCardDataType}
        reportCardMetric={reportCardMetric}
        metricOptions={metricOptions}
        reportCardSettings={reportCardSettings}
        columnDropdownOptions={columnDropdownOptions}
        customEvents={customEvents}
        editBasicLabels={editBasicLabels}
        setEditBasicLabels={setEditBasicLabels}
        requestOpen={requestOpen}
        setRequestOpen={setRequestOpen}
        activeBoxPlotCategory={activeBoxPlotCategory}
        setActiveBoxPlotCategory={setActiveBoxPlotCategory}
        resultsThreshold={resultsThreshold}
        isNonPaidMediaType={isNonPaidMediaType}
        isOrganicView={isOrganicView}
        fetchPerformanceData={fetchPerformanceData}
        libraryView={libraryView}
        selectedAudience={selectedAudience}
        primary_metric={primary_metric}
        mode={mode}
        viewName={viewName}
        presetsApi={presetsApi}
        groupByAdName={groupByAdName}
        defaultPfrColumns={defaultPfrColumns}
        defaultDnaColumns={defaultDnaColumns}
        defaultDates={defaultDates}
        searchTermsData={searchTermsData}
        isEmailView={isEmailView}
        setClustersData={setClustersData}
        clustersApi={clustersApi}
        min={min}
        loading={loading}
        dnaColumnsParam={dnaColumnsParam}
        impactCategories={impactCategories}
        setImpactCategories={setImpactCategories}
        columns={columns}
        handleColumns={handleColumns}
        planningMode={planningMode}
        setPlanningMode={setPlanningMode}
        coreVisuals={coreVisuals}
        setCoreVisuals={setCoreVisuals}
        requestWidth={requestWidth}
        setRequestWidth={setRequestWidth}
        isGoHealth={isGoHealth}
        rightDrawerDisplay={rightDrawerDisplay}
        fetchRecs={fetchRecs}
        recsData={recsData}
        idsInLastRequest={idsInLastRequest}
        iterationsData={iterationsData}
        setIterationsData={setIterationsData}
        presetsOpen={presetsOpen}
        setPresetsOpen={setPresetsOpen}
        trendingRecs={trendingRecs}
        inspirationRecs={inspirationRecs}
        customScoringData={customScoringData}
        setCustomScoringData={setCustomScoringData}
        savedConcepts={savedConcepts}
        setSavedConcepts={setSavedConcepts}
        selectedDraftProject={selectedDraftProject}
        setSelectedDraftProject={setSelectedDraftProject}
        draftCreativeState={draftCreativeState}
        isFacebookView={isFacebookView}
        isInstagramView={isInstagramView}
        isLinkedInView={isLinkedInView}
        selectedTab={selectedTab}
        showRulesManager={showRulesManager}
        setShowRulesManager={setShowRulesManager}
        getRecoRulesData={getRecoRulesData}
        getRecoRulesError={getRecoRulesError}
        getRecoRulesLoading={getRecoRulesLoading}
        getRecoRulesRequest={getRecoRulesRequest}
        deleteRecoRulesData={deleteRecoRulesData}
        deleteRecoRulesLoading={deleteRecoRulesLoading}
        deleteRecoRulesRequest={deleteRecoRulesRequest}
        saveRecoRulesData={saveRecoRulesData}
        saveRecoRulesRequest={saveRecoRulesRequest}
        udcApi={udcApi}
        allKeywords={allKeywords}
        getAdNameLabelsApi={getAdNameLabelsApi}
        adNameLabelsApi={adNameLabelsApi}
        originalMetricsData={originalMetricsData}
      >
        <ExplorerContextProvider explorerState={explorerState}>
          <CreativeRequestProvider>
            <div className="analytics">
              {!!shouldShowShareModal && (
                <ShareReport
                  location="pfr"
                  close={closeShareModal}
                  submit={({
                    report_name,
                    report_description,
                    selectedCategories,
                  }) =>
                    submitShareStaticPfr({
                      report_name,
                      report_description,
                      selectedCategories,
                    })
                  }
                  sharedReportId={sharedReportId}
                  loading={loadingSharePfr}
                  categorySettings={categorySettings}
                  sharePayloadError={sharePayloadError}
                />
              )}
              {/* {mode === 'analytics' && !planningMode && (
                <LeftDisplay
                  {...{
                    display,
                    setIsSettingsOpen,
                    loading,
                    isDirectMail,
                    isNonPaidMediaType,
                  }}
                />
              )} */}
              <div
                style={{
                  marginLeft: explorerState.drawerState.isOpen ? '100px' : '0',
                }}
                className={`analytics__content ${
                  show_request ? 'request-open' : ''
                } ${
                  [
                    'dna_table',
                    'variation',
                    'hurdle',
                    'copilot',
                    'draft-creative',
                  ].includes(display)
                    ? 'gray-bg'
                    : ''
                } ${planningMode ? 'planning-mode' : ''}`}
              >
                {openedMenu && (
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <div className="clickaway-div">
                      <CategoryEditor
                        analyticsView={true}
                        {...{
                          categorySettings,
                          dispatchReportCardSettings,
                          reportCardSettings,
                        }}
                      />
                    </div>
                  </ClickAwayListener>
                )}
                <Header
                  RecsToggle={
                    <Tooltip
                      content={`Recommendations${
                        !!recommendationsStatus
                          ? recommendationsStatus === 'success'
                            ? ' ready to view'
                            : ` (${recommendationsStatus})`
                          : ''
                      }`}
                      follow={false}
                      placement={'left'}
                    >
                      <div>
                        <IconButton
                          style={{ height: '30px', padding: '0 6px' }}
                          appearance="raised"
                          active={rightDrawerDisplay === 'recommendations'}
                          status={recommendationsStatus}
                          onClick={() => {
                            dispatch(
                              setRightDrawerDisplay(
                                rightDrawerDisplay === 'recommendations'
                                  ? null
                                  : 'recommendations'
                              )
                            );
                          }}
                        >
                          <i class="fa-solid fa-wand-magic-sparkles"></i>
                        </IconButton>
                      </div>
                    </Tooltip>
                  }
                  MissingDataToggle={
                    <Tooltip
                      content={`Missing data`}
                      follow={false}
                      placement={'left'}
                    >
                      <div>
                        <IconButton
                          style={{ height: '30px', padding: '0 6px' }}
                          appearance="raised"
                          active={rightDrawerDisplay === 'missing_data'}
                          onClick={() => {
                            dispatch(
                              setRightDrawerDisplay(
                                rightDrawerDisplay === 'missing_data'
                                  ? null
                                  : 'missing_data'
                              )
                            );
                          }}
                        >
                          <i
                            class="fa-solid fa-border-none"
                            style={{ fontSize: '15px' }}
                          ></i>
                        </IconButton>
                      </div>
                    </Tooltip>
                  }
                  RefreshIcon={
                    <Tooltip
                      content={'Refresh performance data'}
                      follow={false}
                      placement={'left'}
                    >
                      <IconButton onClick={fetchPerformanceData}>
                        <i className="fa fa-rotate-right"></i>
                      </IconButton>
                    </Tooltip>
                  }
                  isPF={isPF}
                  mode={mode}
                  libraryView={libraryView}
                  setLibraryView={setLibraryView}
                  display={display}
                  columns={columns}
                  handleColumns={handleColumns}
                  recsTab={recsTab}
                  filteredData={filteredData}
                  selectedLabels={selectedLabels}
                  handleRefreshReport={handleRefreshReport}
                  isDirectMail={isDirectMail}
                  clientMetrics={clientMetrics}
                  fetchPerformanceData={fetchPerformanceData}
                  useMissingSpend={useMissingSpend}
                  viewName={viewName}
                  setParams={setParams}
                  dashboardEditMode={dashboardEditMode}
                  {...{
                    selectedLabels,
                    handleFilter,
                    showActiveOnly,
                    setShowActiveOnly,
                    filteredSearchLookup,
                    handleSelectLabel,
                    handleSharePfr,
                  }}
                  GroupByRatioToggle={
                    <Tooltip
                      content={
                        'This is a BETA feature and will group creatives together based on the shared occurrence of ad names. If you customize your placements then this feature will potentially be misleading.'
                      }
                      follow={false}
                      placement={'left'}
                    >
                      <div>
                        <Toggle
                          checked={groupByAdName}
                          onChange={() => setGroupByAdName((prev) => !prev)}
                          id="group-by-labels-toggle"
                        />
                      </div>
                    </Tooltip>
                  }
                  ActiveVisualsToggle={
                    <Tooltip
                      content={'Only show visuals that are currently running'}
                      follow={false}
                      placement={'left'}
                    >
                      <div>
                        <Button
                          style={{ height: '30px', padding: '0 6px' }}
                          appearance="raised"
                          active={showActiveOnly}
                          onClick={() => setShowActiveOnly((prev) => !prev)}
                        >
                          <ActiveIcon className="mr-2" /> Active visuals
                        </Button>
                      </div>
                    </Tooltip>
                  }
                  retrievedDate={retrievedDate}
                  refresh={refresh}
                />

                {([PERFORMANCE_FREQUENCY, RECOMMENDATIONS].includes(display) ||
                  (!filteredData.length && !display.includes('copilot'))) &&
                  !loading &&
                  !presetsOpen && (
                    <>
                      <div className="planning-mode__toggle-border">
                        <Tooltip
                          content="Planning mode"
                          disabled={planningMode}
                          placement={'right'}
                        >
                          <div
                            onClick={() => {
                              setPlanningMode((prev) => !prev);
                              if (planningMode) {
                                if (!filteredData.length) {
                                  selectFeature('analytics', 'dna_table');
                                } else {
                                  setSearchParams({
                                    display: PERFORMANCE_FREQUENCY,
                                  });

                                  if (!!rightDrawerDisplay) {
                                    dispatch(setRightDrawerDisplay(null));
                                  }
                                }
                              } else {
                                selectFeature(
                                  'analytics',
                                  PERFORMANCE_FREQUENCY
                                );
                                setRequestOpen(true);
                              }
                            }}
                            className="planning-mode__toggle"
                          >
                            {!planningMode && <PlanningNoFillIcon />}
                            {planningMode && <i className="fa fa-close" />}
                          </div>
                        </Tooltip>
                      </div>
                    </>
                  )}

                {showRulesManager && <RulesManager />}

                {planningMode && (
                  <div
                    className="planning-mode__header"
                    style={{
                      marginRight:
                        requestOpen || !!rightDrawerDisplay ? '0' : '2rem',
                    }}
                  >
                    <div className="d-flex align-items-end">
                      {!hideTrending && (
                        <div
                          className={`tab ${
                            display === RECOMMENDATIONS ? 'active' : ''
                          }`}
                          onClick={() =>
                            selectFeature('analytics', RECOMMENDATIONS)
                          }
                        >
                          <TrendingIcon />
                          <span>Trending</span>
                        </div>
                      )}
                      <div
                        className={`tab ${
                          display === PERFORMANCE_FREQUENCY ? 'active' : ''
                        }`}
                        onClick={() =>
                          selectFeature('analytics', PERFORMANCE_FREQUENCY)
                        }
                      >
                        <Bar />
                        <span>Detailed Reports</span>
                      </div>
                    </div>
                    <Tooltip content="Impact report">
                      <Button
                        appearance="raised"
                        className="simple-impact-toggle"
                        onClick={() => {
                          if (requestOpen) {
                            setRequestOpen(false);
                          }

                          dispatch(
                            setRightDrawerDisplay(
                              rightDrawerDisplay === 'recommendations'
                                ? null
                                : 'recommendations'
                            )
                          );
                        }}
                      >
                        <i className="fa-solid fa-wand-magic-sparkles"></i>
                      </Button>
                    </Tooltip>
                  </div>
                )}

                {isPF && <Banner />}

                {(loading || loadingRcSettings) && !bypassLoading ? (
                  <h3
                    className="performance-frequency__loading mx-3"
                    style={{ marginTop: '1rem' }}
                  >
                    Fetching performance data
                  </h3>
                ) : (
                  <div
                    className={`analytics__main ${
                      ['impact_report', 'copy'].includes(display)
                        ? 'no-header'
                        : ''
                    }`}
                  >
                    {!!Comp && (filteredData.length > 0 || bypassNoData) ? (
                      <Comp
                        {...{
                          data,
                          filteredData,
                          creativeCount,
                          newAds,
                          insights_by_asset,
                          only_new_ads,
                          trending,
                          executionStyles,
                          inspiration,
                          show_request,
                          account_id,
                          columns,
                          handleColumns,
                          recsTab,
                          period1,
                          period2,
                          loading,
                          loadingCompare,
                          showLoading,
                          setTTNTSections,
                          TryThese,
                          isROAS,
                          sort,
                          isDirectMail,
                          gaEnabled,
                          libraryAnalyticsState,
                          requestOpen,
                          dashboardEditMode,
                          setDashboardEditMode,
                          view_id,
                          darwin_client_id,
                          getParams,
                          filteredSearchLookup,
                          dispatchReportCardSettings,
                          handleSharePfr,
                          activeTTSection,
                          setActiveTTSection,
                          handleBlockValue,
                          handleBlockVisualType,
                          showRightRail,
                          setShowRightRail,
                        }}
                        LabelSetupIcon={LabelSetupIcon}
                        LibraryExport={ExportButton}
                        TryThese={
                          <TryThese
                            {...{
                              data,
                              filteredData,
                              only_new_ads,
                              trending,
                              executionStyles,
                              inspiration,
                              show_request,
                              account_id,
                              view_id,
                              activeTTSection,
                              setActiveTTSection,
                              handleBlockValue,
                              handleBlockVisualType,
                            }}
                            recsTab={recsTab}
                            fromDraftCreative={
                              display === DRAFT_CREATIVE ? true : false
                            }
                          />
                        }
                      />
                    ) : !!Comp && !loading && !bypassLoading ? (
                      <p>No data found for this view.</p>
                    ) : null}

                    {showRightRail && display === DRAFT_CREATIVE && (
                      <RightRail
                        TryThese={
                          <TryThese
                            {...{
                              data,
                              filteredData,
                              only_new_ads,
                              trending,
                              executionStyles,
                              inspiration,
                              show_request,
                              account_id,
                              view_id,
                              activeTTSection,
                              setActiveTTSection,
                              handleBlockValue,
                              handleBlockVisualType,
                            }}
                            recsTab={recsTab}
                            fromDraftCreative={
                              display === DRAFT_CREATIVE ? true : false
                            }
                          />
                        }
                        {...{
                          isROAS,
                          activeTTSection,
                          setActiveTTSection,
                          showRightRail,
                          setShowRightRail,
                          handleBlockValue,
                          handleBlockVisualType,
                          railRef,
                        }}
                      />
                    )}
                    {display === 'dna_table' && (
                      <Dna
                        pfrLoading={loading}
                        {...{
                          columns,
                          handleColumns,
                          refresh,
                          dnaTableApi,
                        }}
                      />
                    )}
                  </div>
                )}

                {enableCreativeRequest && (
                  <CreativeRequest
                    filteredData={filteredData}
                    CreativeRequestPortal={CreativeRequestPortal}
                    requestOpen={requestOpen}
                    setRequestOpen={setRequestOpen}
                  />
                )}

                {/* {enableLibraryAnalytics && (
                    <LibraryAnalytics
                      filteredData={filteredData}
                      LibraryAnalyticsPortal={LibraryAnalyticsPortal}
                      {...{
                        libraryAnalyticsState,
                        defaultAnalyticsTableCategory,
                        reportCardPrefsApi,
                      }}
                    />
                  )} */}

                <ActionButtons
                  ActionButtonsPortal={ActionButtonsPortal}
                  show={!dashboardEditMode && !loading}
                >
                  <CreativeRequestToggle
                    label="Creative Request"
                    show={enableCreativeRequest}
                    className="request-toggle"
                    onClick={() => {
                      setRequestOpen((prev) => {
                        if (!prev)
                          dispatch(
                            trackEvent(
                              CREATIVE_ANALYTICS,
                              OPEN_CREATIVE_REQUEST_DRAWER
                            )
                          );
                        return !prev;
                      });

                      if (!!rightDrawerDisplay) {
                        dispatch(setRightDrawerDisplay(null));
                      }
                    }}
                  />

                  <DashboardEditModeToggle
                    label="Edit dashboard"
                    show={display === DASHBOARD || isDashboard}
                    onClick={() => setDashboardEditMode((prev) => !prev)}
                  />
                </ActionButtons>

                {(editCategory ||
                  (editCategoryVisual && editCategoryVisual.show)) && (
                  <Overlay
                    show={true}
                    close={() => {
                      setEditCategory(null);
                      setEditCategoryVisual(null);
                    }}
                  >
                    <Modal styles={{ overflowY: 'initial' }}>
                      <CategoryBuilder
                        category={editCategory}
                        editCategoryVisual={editCategoryVisual}
                        {...{
                          allKeywords,
                          getAdNameLabelsApi,
                          adNameLabelsApi,
                          setEditCategory,
                          setEditCategoryVisual,
                          udcApi,
                          originalMetricsData,
                        }}
                      />
                    </Modal>
                  </Overlay>
                )}

                {showQaEditor && <QA {...{ loading, setShowQaEditor }} />}

                {isSettingsOpen && (
                  <Overlay
                    show={true}
                    close={() => {
                      dispatch(setIsSettingsOpen(false));
                    }}
                  >
                    <Modal styles={{ overflowY: 'initial' }}>
                      <Settings
                        onClose={() => dispatch(setIsSettingsOpen(false))}
                        CategoryEditor={
                          <CategoryEditor
                            analyticsView={true}
                            style={{}}
                            {...{
                              categorySettings,
                              dispatchReportCardSettings,
                              reportCardSettings,
                            }}
                          />
                        }
                        LabelSetup={
                          <LabelsSetup
                            data={searchTermsData}
                            loading={searchTermsLoading}
                            error={searchTermsError}
                            fetchSearchTerms={searchTermsApi.get.request}
                            {...{
                              account_id,
                              existingKeywords,
                            }}
                          />
                        }
                        MoreSettings={
                          <MoreSettings
                            dispatchCategoryFilter={dispatchCategoryFilter}
                          />
                        }
                        GlobalKeywordSettings={
                          <GlobalKeywordSettings
                            searchTermsData={searchTermsData}
                          />
                        }
                        RefreshReport={
                          <RefreshReport
                            handleRefreshReport={handleRefreshReport}
                          />
                        }
                        SuccessConditions={<SuccessConditions />}
                        HideLabels={<HideLabels />}
                      />
                    </Modal>
                  </Overlay>
                )}

                <BoxPlot
                  activeBoxPlotCategory={activeBoxPlotCategory}
                  setActiveBoxPlotCategory={setActiveBoxPlotCategory}
                />

                {rc.length && rc !== '0' ? (
                  <ReportCard
                    visual_hash={rc}
                    depth={recDepth}
                    fixed={fixedLabels}
                    setParams={setParams}
                    start_date={darwinDateToDate(period1Start)}
                    end_date={darwinDateToDate(period1End)}
                    insights_by_asset={insights_by_asset}
                    filteredData={filteredData}
                    isROAS={isROAS}
                    fromCreativeStudio={fromCreativeStudio}
                    reportCardPrefsApi={reportCardPrefsApi}
                    {...{
                      spendThreshold,
                      selectedAudience,
                      ReportCardPortal,
                      revisionRequestApi,
                      setReportCardDataType,
                      isDirectMail,
                      viewName,
                      setReportCardMetric,
                      gaEnabled,
                      reportCardMode,
                      setReportCardMode,
                      period1,
                      primary_metric,
                      columns,
                      handleColumns,
                      dispatchReportCardSettings,
                    }}
                  />
                ) : null}

                {asyncApi.processes.length
                  ? asyncApi.processes.map((process, index) => {
                      return (
                        <AsyncCCCProcess
                          processIndex={index}
                          key={process.category_id}
                          process={process}
                          processes={asyncApi.processes}
                          remove={asyncApi.removeAsyncProcess}
                          setEditCategory={setEditCategory}
                          fetchPerformanceData={fetchPerformanceData}
                          ongoingBatched={asyncApi.ongoingBatched}
                          isFirstItem={index === 0}
                          isLastItem={index === asyncApi.processes.length - 1}
                        />
                      );
                    })
                  : null}

                {(!loading && Comp === PerformanceFrequency) ||
                Comp === PhraseTheme ||
                Comp === Variation ||
                Comp === Copilot ||
                Comp === DraftCreativeProjects ? (
                  <Explorer {...{ filteredData }} />
                ) : null}
              </div>

              {!!RightDrawer && (
                <RightDrawerWrapper>{RightDrawer}</RightDrawerWrapper>
              )}
            </div>
          </CreativeRequestProvider>
        </ExplorerContextProvider>
      </CreativeAnalyticsProvider>
    </ErrorBoundary>
  );
};

export default CreativeAnalytics;
