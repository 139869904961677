import { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';

import { asAsync } from '../../utils';

const isProd = process.env.NODE_ENV === 'production';
const Noop = () => null;

/**
 * "tag" will not work when multiple instances are called near
 * simultaneously, even with a delay.
 */
const tag = asAsync((...args) => window.gtag(...args));

/**
 * @description (For sanity) A valid page view is on an the initial app load
 * (current === undefined), or when the history action is PUSH
 * and the next pathname is the same as the window pathname and
 * not equal to the current pathname.
 * @param {"PUSH" | "REPLACE" | "POP"} action
 * @param {String} [current] current pathname
 * @param {String} next next pathname
 * @param {String} winPath current window.location.pathname
 */
function validPageView(action, current, next, winPath) {
  return (
    !current || (winPath === next && action === 'PUSH' && current !== next)
  );
}

function usePageView({ location, history }) {
  const path = useRef();

  useEffect(() => {
    const nextPath = location.pathname;

    if (
      validPageView(
        history.action,
        path.current,
        nextPath,
        window.location.pathname
      )
    ) {
      window.gtag('config', window.GA_ID, {
        page_location: window.location.href,
        page_path: nextPath,
        page_title: window.USER_ID,
      });

      path.current = nextPath;
    }
  }, [location.pathname]);

  return null;
}

export default withRouter(isProd ? usePageView : Noop);

function useReportChange({ location }) {
  // Saves the current report to prevent it re-tagging
  // when audiences/dates are selected.
  const saved = useRef();
  const report = new URLSearchParams(location.search).get('report');

  useEffect(() => {
    if (!!report && saved.current !== report) {
      tag('event', report, {
        event_category: 'set_creative_report',
        event_label: window.USER_ID,
      });
    }

    saved.current = report;
  }, [report]);

  return null;
}

export const ReportAnalytics = withRouter(isProd ? useReportChange : Noop);
