import { useSelector } from 'react-redux';
import { useAbortableFetch } from '../../../hooks/useAbortableFetch';
import {
  useAccountId,
  useActionName,
  useViewId,
} from '../../CreativeAnalytics/selectors';
import { useUrlParams } from '../../CreativeAnalytics/hooks/useUrlParams';
import { RunQueryParams } from '../types/types';
import { useMemo } from 'react';

const RUN_QUERY_ENDPOINT = [
  'api',
  'creative-analytics',
  'report-builder',
  'run-label-report-query',
].join('/');

export const getMetric = (metric: string, action_name: string) => {
  switch (metric) {
    case 'cpa':
    case 'roas':
      return action_name ?? 'results';
    case 'cpc':
      return 'link_click';
    case 'cpm':
    case 'ctr':
      return 'impressions';
    default:
      if (metric?.includes('cp_')) return metric.replace('cp_', '');
      if (metric?.includes('Cost Per')) return metric.replace('Cost Per', '');
      return metric;
  }
};

export const useRunQueryApi = (dateRange) => {
  const { data, loading, error, request, clearData } = useAbortableFetch();
  const account_id = useAccountId();
  const view_id = useViewId();
  const { isROAS } = useSelector(({ ViewSettings: { isROAS } }) => ({
    isROAS,
  }));
  const { primary_metric } = useUrlParams();
  const action_name = useActionName();

  const runQuery = ({
    hard_refresh,
    chosen_label,
    comparison_label,
    array_of_ad_name_strings,
    array_of_segments,
    include_missing_permissions,
  }: RunQueryParams) => {
    try {
      request(RUN_QUERY_ENDPOINT, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
          fb_account_id: account_id,
          view_id,
          optimization_event:
            getMetric(primary_metric, action_name) || primary_metric,
          is_roas: isROAS,
          hard_refresh,
          chosen_label,
          comparison_label,
          array_of_ad_name_strings: null,
          array_of_segments,
          include_missing_permissions,
          start_date: dateRange.start,
          end_date: dateRange.end,
        }),
      });
    } catch (e) {
      console.error('Error running query.');
    }
  };

  const parsed = useMemo(() => parseData(data), [data]);

  return { data: parsed, loading, error, runQuery, clearData };
};

const addIds = (arr = []) => {
  return arr.map((item) => {
    const { ad_name_group, label_name } = item;
    const id = `${ad_name_group}->${label_name}`;

    return {
      ...item,
      id,
    };
  });
};

const parseData = (data) => {
  if (data?.data) {
    try {
      return {
        debug_data: JSON.parse(data.data.debug_data ?? ''),
        detail_data: JSON.parse(data.data.detail_data ?? ''),
        summary_data: addIds(JSON.parse(data.data.summary_data ?? '')),
        visual_id_data: JSON.parse(data.data.visual_id_data ?? ''),
      };
    } catch (e) {
      console.error('Could not parse data.');
    }
  }

  return data;
};
