import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';

import { ErrorBoundary } from '../../../../components/Helpers/Error';

import { DATES } from '../dates';

import BlockHistoryChart from './Chart';
import { Picker, DatePicker } from './Pickers';
import { useFetchHistories, useUnmountController } from './service';
import { METRIC_OPTIONS, GA_METRIC_OPTIONS, INTERVAL_OPTIONS, REPORT_OPTIONS } from './constants';

import './style.css';

function handleAllSettled(responses) {
  const data = [];
  let errors = 0;

  responses.forEach(({ status, value }) => {
    if (status === 'fulfilled') {
      data.push(value);
    } else {
      errors += 1;
    }
  });

  return { data, errors };
}

function ResponsiveWrapper({ style, children }) {
  const isSmall = useMediaQuery({ maxWidth: '1224px' });

  if (isSmall) {
    return (
      <div
        className="col-12 darwin-box-shadow bg-white mt-2"
        style={{ padding: '1.25rem', ...style }}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      className="darwin-box-shadow bg-white col-12 col-md-4"
      style={{
        padding: '1.25rem',
        height: '300px',
        ...style,
      }}
    >
      {children}
    </div>
  );
}

ResponsiveWrapper.defaultProps = {
  style: {},
};

ResponsiveWrapper.propTypes = {
  style: PropTypes.shape({ cursor: PropTypes.string }),
  children: PropTypes.node.isRequired,
};

function StatusIcon({ status }) {
  switch (status) {
    case 'loading':
      return <i className="fa fa-hourglass-half" title="Save in progress" />;

    case 'error':
      return (
        <i
          className="fa fa-exclamation-circle text-danger"
          title="An error occurred"
        />
      );

    default:
      return null;
  }
}

StatusIcon.propTypes = {
  status: PropTypes.oneOf(['error', 'loading', '']).isRequired,
};

function BlockHistory({ ids, onClose }) {
  const fetchHistories = useFetchHistories();
  const controller = useUnmountController();
  // Default to an empty chart with the selected date range.
  const [blocks, setBlocks] = useState([]);
  const [metric, setMetric] = useState(METRIC_OPTIONS[0]);
  const [type, setType] = useState(REPORT_OPTIONS[0]);
  const [periodInterval, setPeriodInterval] = useState(
    INTERVAL_OPTIONS.find(({ value }) => value === 'weekly'),
  );
  // dateRange is [Date, Date]
  const [dateRange, setDateRange] = useState(
    DATES.find(({ label }) => label.includes('28')),
  );
  const [status, setStatus] = useState('');

  let { ga_enabled } = useSelector(({ ViewSettings: { ga_enabled } }) => ({
    ga_enabled,
  }));

  const chartOptions = ga_enabled ? METRIC_OPTIONS.concat(GA_METRIC_OPTIONS) : METRIC_OPTIONS;

  const fetchFlow = async () => {
    const res = await fetchHistories(
      ids,
      {
        interval: periodInterval.value,
        type: type.value,
        dateRange: dateRange.value,
      },
      controller,
    );

    if (controller.signal.aborted) {
      return null;
    }

    const handled = handleAllSettled(res);

    setBlocks(handled.data);
    setStatus(handled.errors > 0 ? 'error' : '');

    return null;
  };

  useEffect(() => {
    setStatus('loading');
    fetchFlow();
  }, [ids.length, type.value, periodInterval.value, dateRange.label]);

  // If nothing is selected, hide the component
  // but maintain current state.
  if (!ids.length) {
    return null;
  }

  return (
    <ResponsiveWrapper
      style={{ cursor: status === 'loading' ? 'progress' : '' }}
    >
      <div className="d-flex flex-row flex-wrap align-items-center justify-content-between">
        <div className="d-flex flex-row flex-wrap history-picker-row">
          <Picker
            style={{ fontSize: '16px', fontWeight: 'bold', color: 'inherit' }}
            disabled={status === 'loading'}
            value={metric.label}
            onChange={setMetric}
            options={chartOptions.map((opt) => ({
              ...opt,
              active: metric.value === opt.value,
            }))}
            id="metric-options"
          />
          <div className="dropdown">
            <button
              type="button"
              className="btn btn-link"
              onClick={() => null}
              style={{ fontSize: '16px', fontWeight: 'bold', color: 'inherit' }}
            >
              Trending
            </button>
          </div>
          {/* <Picker
            style={{ fontSize: '16px', fontWeight: 'bold', color: 'inherit' }}
            disabled={status === 'loading'}
            value={type.label}
            onChange={setType}
            options={
              REPORT_OPTIONS
                .filter(({ value }) => value === 'trending')
                .map((opt) => ({ ...opt, active: type.value === opt.value }))
            }
            id="report-options"
          /> */}
          <Picker
            style={{ fontSize: '16px', fontWeight: 'bold', color: 'inherit' }}
            disabled={status === 'loading'}
            value={`by ${periodInterval.label}`}
            onChange={setPeriodInterval}
            options={INTERVAL_OPTIONS.map((opt) => ({
              ...opt,
              active: periodInterval.value === opt.value,
            }))}
            id="period-options"
          />
        </div>

        <div className="d-flex flex-row align-items-center">
          <StatusIcon status={status} />
          {/* &emsp;
          <button type="button" className="btn btn-link" style={{ color: 'inherit' }} onClick={onClose}>
            <i className="fa fa-times" />
          </button> */}
        </div>
      </div>

      <BlockHistoryChart
        loading={status === 'loading'}
        blocks={blocks}
        metric={metric.value}
        interval={periodInterval.value}
      />

      <div
        className="pl-3 d-flex flex-row flex-wrap align-items-center text-muted"
        style={{ fontSize: '0.8rem' }}
      >
        Dates are between
        {' '}
        <DatePicker options={DATES} value={dateRange} onChange={setDateRange} />
      </div>
    </ResponsiveWrapper>
  );
}

BlockHistory.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
};

BlockHistory.Fallback = ({ onClose }) => (
  <div
    className="ml-2 col-12 col-md-4 darwin-box-shadow bg-white d-flex align-items-baseline justify-content-between"
    style={{ height: '2.5rem' }}
  >
    <p>An error occurred.</p>
    <button
      type="button"
      className="btn btn-link"
      style={{ color: 'inherit' }}
      onClick={onClose}
    >
      <i className="fa fa-times" />
    </button>
  </div>
);

BlockHistory.Fallback.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default function BlockHistoryBoundary({ ids, onClose }) {
  return (
    <ErrorBoundary fallback={<BlockHistory.Fallback onClose={onClose} />}>
      <BlockHistory ids={ids} onClose={onClose} />
    </ErrorBoundary>
  );
}

BlockHistoryBoundary.propTypes = BlockHistory.propTypes;
