import { useMemo } from 'react';
import { DetailDataItem, SummaryDataItem } from '../../../../types/types';
import { updateMetric } from '../utils/metricUtils';

type VisualsSortState = {
  sortBy: string;
  desc: boolean;
};

export const filterVisualsBySelectedRow =
  (selectedRow: SummaryDataItem) => (ad: DetailDataItem) => {
    try {
      if (!ad || !selectedRow) return false;

      const labelNameIsNone =
        !selectedRow.label_name || selectedRow.label_name === 'None';

      if (!labelNameIsNone) {
        return (
          ad.ad_name_group === selectedRow.ad_name_group &&
          ad.all_labels.includes(selectedRow.label_name)
        );
      } else {
        return ad.ad_name_group === selectedRow.ad_name_group;
      }
    } catch (e) {
      console.error('Error filtering visuals.');
      return false;
    }
  };

/**
 * Get filtered + sorted visuals for CreativePerformance grid
 */
export const useVisualsData = (
  detail_data: DetailDataItem[],
  selectedRow: SummaryDataItem,
  sort: VisualsSortState
) => {
  const processedVisuals = useMemo(() => {
    return detail_data
      .filter(filterVisualsBySelectedRow(selectedRow))
      .sort((a, b) => {
        const metric = updateMetric(sort?.sortBy) ?? 'total_spend';
        const preferHigher = metric === 'total_roas';

        const aValue = a[metric] ?? null;
        const bValue = b[metric] ?? null;

        const aInvalid = isNaN(aValue) || aValue === 0;
        const bInvalid = isNaN(bValue) || bValue === 0;

        if (aInvalid && !bInvalid) return 1;
        if (bInvalid && !aInvalid) return -1;

        if (preferHigher) {
          return sort?.desc ? aValue - bValue : bValue - aValue;
        }

        return sort?.desc ? bValue - aValue : aValue - bValue;
      })
      .map((ad, index) => ({
        ...ad,
        rank: index + 1,
        isTopSpending: index === 0,
      }));
  }, [selectedRow, detail_data, sort]);

  return processedVisuals;
};

/**
 * Get visuals to be shown for the selected label
 */
export const useLabelVisualsData = (
  darwinIqData: any,
  selectedLabel: string | null,
  dataByCategory: Map<string, any[]>
) => {
  const visualsData = useMemo(() => {
    if (!darwinIqData || !selectedLabel) return [];

    const [selectedCategory = null, selectedLabelName = null] =
      selectedLabel?.split('->');
    const categoryData = dataByCategory.get(selectedCategory);

    if (!categoryData) return [];

    const labelData = categoryData.find(
      (label) => label.label_name === selectedLabelName
    );

    if (!labelData) return [];

    const ad_name_array = labelData.ad_name_array || [];
    return ad_name_array
      .map(
        (ad_name) =>
          darwinIqData?.ad_name_performance_data?.find(
            (y) => ad_name === y.ad_name
          )
      )
      .filter(Boolean);
  }, [darwinIqData, selectedLabel, dataByCategory]);

  return visualsData;
};
