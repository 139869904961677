//@ts-check
import React, { useContext, createContext, useRef, useEffect } from "react";

import { abortableFetch, simpleHash } from "../../utils.js";
import { formToApi } from "./utils.js";
import { useApiState } from "./useApiState";

let Responses = new Map();

export const ApiContext = createContext({
  flowchart: {},
  testDates: {},
});

export function ApiProvider({
  children,
  view_id,
  account_id,
  campaigns,
  start_date,
  end_date,
}) {
  const flowchart = flowchartApi({ campaigns, view_id, account_id });
  const testDates = testDatesApi({
    view_id,
    account_id,
    campaigns,
    start_date,
    end_date,
  });

  return (
    <ApiContext.Provider
      value={{
        flowchart,
        testDates,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
}

export function useApi() {
  return useContext(ApiContext);
}

function flowchartApi({ campaigns, account_id, view_id }) {
  let mounted = useRef(false);
  const handleReq = handleMountedRequest(mounted);

  const [getState, dispatchGet] = useApiState(
    (args) => handleReq(getFlowchart(args)),
    {
      loading: true,
      error: null,
      data: null,
    }
  );
  const [putState, dispatchPut] = useApiState((args) =>
    handleReq(saveFlowchart(args))
  );
  const [deleteState, dispatchDelete] = useApiState(deleteStudies);

  const boundView = (study) => formToApi(study, view_id);

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    get: {
      ...getState,
      request: () =>
        dispatchGet({
          view_id,
          campaigns,
          account_id,
        }),
    },
    put: {
      ...putState,
      request: (studies) =>
        studies.length > 0 &&
        dispatchPut({
          view_id,
          studies: studies.map(boundView),
        }),
    },
    delete: {
      ...deleteState,
      request: (studies) =>
        studies.length > 0 && dispatchDelete({ view_id, studies }),
    },
  };
}

function getFlowchart({ view_id, campaigns, account_id }) {
  return abortableFetch(["api", view_id, "flowchart"].join("/"), {
    mode: "cors",
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      campaigns,
      account_id,
    }),
  });
}

function saveFlowchart({ view_id, studies }) {
  return abortableFetch(["api", view_id, "flowchart", "save"].join("/"), {
    mode: "cors",
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      studies,
    }),
  });
}

function testDatesApi({
  campaigns,
  account_id,
  view_id,
  start_date,
  end_date,
}) {
  let mounted = useRef(false);
  const handleReq = handleMountedRequest(mounted);
  const cacheableReq = handleCacheableRequest(Responses, (args) =>
    handleReq(getDetailData(args))
  );
  const [state, request] = useApiState(cacheableReq, {
    error: null,
    loading: true,
    data: null,
  });

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
      Responses = new Map();
    };
  }, []);

  return {
    ...state,
    /**
     * @param {string} study_name
     * @param {boolean=} useDates
     */
    request: (study_name, useDates) =>
      request({
        view_id,
        campaigns,
        account_id,
        start_date,
        end_date,
        study_name,
        useDates,
      }),
  };
}

export function getDetailData({
  view_id,
  campaigns,
  account_id,
  study_name,
  start_date,
  end_date,
  useDates = false,
}) {
  if (useDates) {
    return abortableFetch(
      ["api", view_id, "flowchart", "test-dates"].join("/"),
      {
        mode: "cors",
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          view_id,
          campaigns,
          account_id,
          start_date,
          end_date,
          study_name,
        }),
      }
    );
  } else {
    return abortableFetch(
      ["api", view_id, "flowchart", "test-performance"].join("/"),
      {
        mode: "cors",
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          view_id,
          campaigns,
          account_id,
          study_name,
        }),
      }
    );
  }
}

export function getStudyPerformance({
  view_id,
  campaigns,
  account_id,
  study_name,
}) {
  return abortableFetch(
    ["api", view_id, "flowchart", "test-performance"].join("/"),
    {
      mode: "cors",
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        view_id,
        campaigns,
        account_id,
        study_name,
      }),
    }
  );
}

async function deleteStudies({ view_id, studies }) {
  const responses = await Promise.all(
    studies.map(({ study_name }) =>
      deleteStudy({ view_id, study_name }).then((r) => r.json())
    )
  );

  return responses;
}

function deleteStudy({ view_id, study_name }) {
  return fetch(["api", view_id, "ad-study"].join("/"), {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ study_name }),
  });
}

function handleMountedRequest(mountedRef) {
  return async ({ ready, abort }) => {
    const res = await ready;
    const json = await res.json();

    if (!mountedRef.current) {
      abort();
    } else if (res.status !== 200) {
      return Promise.reject(json);
    } else {
      return json.data || "Success";
    }
  };
}

/**
 * @param {Map | WeakMap} cache
 * @param {(args: any) => Promise<any>} proc
 */
function handleCacheableRequest(cache, proc) {
  return async (args) => {
    const hashed = simpleHash(JSON.stringify(args));

    if (cache.has(hashed)) {
      return cache.get(hashed);
    } else {
      const res = await proc(args);

      cache.set(hashed, res);

      return res;
    }
  };
}

export function useApiPotentialOptions(type) {
  const {
    flowchart: {
      get: { loading, error, data },
    },
  } = useContext(ApiContext);

  if (error || loading) {
    return [];
  } else {
    let field = "";

    switch (type) {
      case "campaign":
        field = "potential_campaigns";
        break;

      case "audience":
        field = "potential_audiences";
        break;

      case "creative":
        field = "potential_creatives";
        break;

      case "manual_campaigns":
        field = "manual_potential_campaigns";
        break;

      default:
        throw new Error(`Invalid potential type of "${type}."`);
    }

    return data[field].map(toOption);
  }
}

function toOption({ name, id, ...rest }) {
  return {
    value: id,
    label: name,
    ...rest,
  };
}

export function useIdLookup() {
  const {
    flowchart: {
      get: { loading, error, data },
    },
  } = useContext(ApiContext);

  if (error || loading) {
    return {};
  } else {
    let lookup = {};

    for (const key of [
      "manual_potential_campaigns",
      "potential_creatives",
      "potential_audiences",
    ]) {
      const arr = data[key];

      for (const { name, id } of arr) {
        lookup[id] = name;
      }
    }

    return (id) => lookup[id] || "";
  }
}
