import React from 'react';

export const StatusIcon = ({ status }) => {
  let background = '#9FAAD2';

  switch (status) {
    case 'success':
      background = '#45BB9F';
      break;
    case 'loading':
      background = '#FEB46F';
      break;
    case 'error':
      background = '#AD5660';
      break;
    default:
      break;
  }

  const style = {
    position: 'absolute',
    top: '-3px',
    right: '-3px',
    background,
    height: '6px',
    width: '6px',
    zIndex: 5,
    borderRadius: '25px',
  };

  return <div {...{ style }} />;
};
