import PropTypes from 'prop-types';

/**
 * @typedef {{ label: string, value: number[] }} PeriodOption
 */
export const PeriodOptionType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
});

/**
 * @typedef {{ series: Function, decompose: Function }} ResultSet
 */
export const ResultSetType = PropTypes.shape({
  series: PropTypes.func.isRequired,
  decompose: PropTypes.func.isRequired,
});

export const MeasureType = PropTypes.arrayOf(PropTypes.string);

export const FilterType = PropTypes.arrayOf(PropTypes.any);

export const CompareDateRangeType = PropTypes.arrayOf(
  PropTypes.oneOfType([PeriodOptionType, PropTypes.string, PropTypes.number])
);

/**
 * @typedef {Object} Query
 * @property {string[]} Query.measures
 * @property {any[]} Query.filters
 * @property {any[]} Query.dimensions
 * @property {[{ compareDateRange: [PeriodOption, PeriodOption] }]} Query.timeDimensions
 */
export const QueryType = PropTypes.shape({
  measures: MeasureType,
  filters: FilterType,
  dimensions: FilterType,
  timeDimensions: PropTypes.arrayOf(
    PropTypes.shape({
      compareDateRange: CompareDateRangeType,
    })
  ),
});

/**
 * @typedef {{ chartType: string, query: Query }} VizState
 */
export const VizStateType = PropTypes.shape({
  chartType: PropTypes.string,
  query: QueryType,
});

/**
 * @typedef {{ h: number, w: number, x: number, y: number }} Layout
 */
export const LayoutType = PropTypes.shape({
  h: PropTypes.number,
  w: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
});
