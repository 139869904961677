import React from "react";
import "./AssetDetails.scss";
import { useReportCardContext } from "../contexts";
import { useCreativeAnalyticsGet } from "../../contexts";
import { SettingsIcon } from "../Header/icons";
import { DETAILS } from "../constants";

const AssetDetails = ({ metricsData, isBestPerformingView, activePage }) => {
  const { filteredMetrics } = useReportCardContext();
  const { selected, isOrganicView } = useCreativeAnalyticsGet();

  if (!selected || !selected.element) return <div></div>;

  const displayData = !isBestPerformingView
    ? selected.element.display.find((obj) => obj.performance === "cpa").data
    : metricsData;

  let isPos = false,
    adjective = "",
    diffAbs = "",
    diffFloat = 0.0,
    fillWidth = "",
    percent_difference = "";

  if (!isBestPerformingView) {
    percent_difference = selected.element.percent_difference;
    isPos = !percent_difference.startsWith("-");
    adjective = isPos ? "better" : "worse";
    diffAbs = percent_difference.replace("-", "");
    diffFloat = parseFloat(diffAbs) / 100.0;
    fillWidth = diffFloat > 1 ? "100%" : diffAbs;
  }

  return (
    <div className={`asset-details card ${activePage === DETAILS ? "show" : ""}`}>
      {!isBestPerformingView && (
        <div className="performance">
          <div className="performance__percent">
            This element is performing
            <span>{` ${diffAbs} ${adjective} `}</span>
            than other elements.
          </div>
          <div className="performance__bar">
            <div className="negative">
              {!isPos && (
                <>
                  <div className="fill" style={{ width: `${diffAbs}` }}></div>
                  <div
                    className="marker"
                    style={{ right: `calc(${fillWidth} - 4px)` }}
                  ></div>
                </>
              )}
            </div>
            <div className="positive">
              {isPos && (
                <>
                  <div className="fill" style={{ width: `${diffAbs}` }}></div>
                  <div
                    className="marker"
                    style={{ left: `calc(${fillWidth} - 4px)` }}
                  ></div>
                </>
              )}
            </div>
            <div className="middle"></div>
          </div>
        </div>
      )}
      <div className="performance__stats">
        {filteredMetrics.length ? (
          <>
            {!isBestPerformingView && (
              <div className="headers">
                <div className="header">This Visual</div>
                <div className="header">Other Visuals</div>
              </div>
            )}
            {filteredMetrics.map((metric) => {
              return (
                <DetailsRow
                  {...{ metric, displayData, isBestPerformingView, isOrganicView }}
                />
              );
            })}
          </>
        ) : (
          <div className="no-metrics">
            No metrics to display. You can edit this in the <SettingsIcon />{" "}
            Preferences.
          </div>
        )}
      </div>
    </div>
  );
};

export default AssetDetails;

const DetailsRow = ({ metric, displayData, isBestPerformingView, isOrganicView }) => {
  const others = displayData.find((obj) => obj.type === "Others");
  let othersValue = null;
  if (!isBestPerformingView) othersValue = others[metric.metric.toLowerCase().replace(" ", "_")];
  if (isOrganicView) othersValue = others[metric.metric];

  return (
    <div className="stats-row d-flex">
      <div className="column current">
        <div className="subheader">{metric.metric}</div>
        <div className="value">{metric.value}</div>
      </div>
      {!isBestPerformingView && (
        <div className="column others">
          {othersValue ? (
            <>
              <div className="subheader">{metric.metric}</div>
              <div className="value">
                {othersValue}
              </div>
            </>
          ) : <div></div>}
        </div>
      )}
    </div>
  );
};
