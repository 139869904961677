import React, { useRef, useState } from 'react';
import Tooltip from '../../../../../components/Tooltip';
import BeagleToggle from '../../../../../components/BeagleToggle';

import { MULTIPLE_LABELS, MUTUALLY_EXCLUSIVE } from '../../constants';

import { Tag, TagsList } from '../../../../../components/Tags';

import Button from '../../../../../components/Button';

const validate = (input) => {
  if (input.compliance) {
    if (input.name.length && input.description.length) {
      return true;
    }
  } else if (input.name.length && input.type.length) {
    if (input.type === MUTUALLY_EXCLUSIVE && input.labels.length > 1) {
      return true;
    } else if (
      input.type === MULTIPLE_LABELS &&
      input.labels.length > 0 &&
      input.labels.length < 5
    ) {
      return true;
    }
  }

  return false;
};

type CategorySetup = {
  dropdownOptions: any;
  next: () => void;
  input: any;
  handleInput: () => void;
  removeLabel: () => void;
  existing: any;
};

const CategorySetup = ({ next, prev, input, handleInput, setVisualModeChanged }) => {
  // const isValid = validate(input);

  const updateVisualMode = (checked) => {
    handleInput('visual_mode', checked);
    setVisualModeChanged(true);
  };

  return (
    <div className="category-setup">
      {/* only show if new category */}
      <button className="sidebar__back" style={{ position: 'absolute', left: '10px', top: '15px' }} onClick={prev}>
        <i className="fas fa-arrow-left"></i>
        Back
      </button>
      <h2>User Defined Category</h2>
      <form action="">
        <div className="category-setup__group">
          <label htmlFor="">Category Name</label>
          <input
            value={input.name}
            type="text"
            onChange={(e) => handleInput('name', e.target.value)}
          />
        </div>

        <div className="category-setup__group">
          <label htmlFor="">Description</label>
          <input
            value={input.description}
            type="text"
            onChange={(e) => handleInput('description', e.target.value)}
          />
        </div>

        <div className="category-setup__group d-flex justify-content-between align-items-center">
          <label htmlFor="">Include in Report Card</label>
          <input
            name="visual-mode-toggle"
            type="checkbox"
            checked={input.visual_mode}
            onChange={(e) => updateVisualMode(e.target.checked)}
            className="visual-mode-toggle"
          />
        </div>
      </form>
      <Button
        // disabled={!isValid}
        onClick={next}
        className="category-setup__btn"
      >
        Next
      </Button>
    </div>
  );
};

export default CategorySetup;
