import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  useCreativeAnalyticsGet,
  useCreativeAnalyticsSet,
} from '../../contexts';
import { Chart } from './Chart';
import { CategoriesToggle } from './CategoriesToggle';
import { useRecommendationsContext } from '../context';
import { useSearchParams } from '../../../../components/Hooks/search-params';

const fetchData = (payload) => {
  return fetch(
    ['api', 'creative-analytics', 'get-category-performance'].join('/'),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(payload),
    }
  );
};

export const useSimpleImpactApi = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (payload) => {
    setError(false);
    setLoading(true);

    try {
      const res = await fetchData(payload);
      const json = await res.json();
      if (json?.data?.status > 200) {
        setError(`Error: ${data.message}`);
      }
      if (json?.data?.message) {
        if (!Array.isArray(json?.data?.message)) {
          setData([]);
        } else {
          setData(json?.data?.message);
        }
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading,
    error,
    request,
  };
};

export const getCategories = (dnaReportData) => {
  if (dnaReportData) {
    return dnaReportData?.categories?.map((c) => c.category_name) ?? [];
  } else {
    return [];
  }
};

export const SimpleImpactReport = ({ primary_metric }) => {
  const { dnaReportData, viewId } = useSelector(
    ({ CreativeAnalytics: { dnaReportData }, ViewSettings: { viewId } }) => {
      return {
        dnaReportData,
        viewId,
      };
    }
  );
  const { filteredAllVisualsLabel, period1, period2 } =
    useCreativeAnalyticsGet();
  const { setParams } = useSearchParams();
  const { simpleImpactApi, visuals } = useRecommendationsContext();
  const { request, data, loading, error } = simpleImpactApi;

  const all_visuals_array = useMemo(() => {
    return filteredAllVisualsLabel?.visuals ?? [];
  }, [filteredAllVisualsLabel]);

  const categorical_columns = getCategories(dnaReportData);

  const [activeCategories, setActiveCategories] = useState(categorical_columns);

  useEffect(() => {
    setActiveCategories(categorical_columns);
  }, [viewId, period1, period2, dnaReportData]);

  const fetch = (data_list) => {
    request({
      data_list,
      primary_metric,
      categorical_columns: activeCategories,
    });
  };

  const handleCategoryClick = (data, index) => {
    const category = data.payload['Category Name'];
    if (category === 'keywords') {
      setParams({ category: 'Keyword' });
    } else {
      setParams({ category });
    }
  };

  const toggleCategory = (category) => {
    setActiveCategories((prev) => {
      if (prev.includes(category)) {
        return prev.filter((c) => c !== category);
      } else {
        return [...prev, category];
      }
    });
  };

  useEffect(() => {
    fetch(visuals);
  }, [JSON.stringify(visuals), primary_metric, dnaReportData, activeCategories]);

  return (
    <div className="d-flex flex-column justify-content-center w-100">
      <p className="mt-3 text-center">
        Category Importances in Predicting{' '}
        <span style={{ fontWeight: 600 }}>{primary_metric}</span>
      </p>
      <Chart data={data ?? MOCK} handleCategoryClick={handleCategoryClick} />
      <CategoriesToggle
        activeCategories={activeCategories}
        categorical_columns={categorical_columns}
        toggleCategory={toggleCategory}
      />
    </div>
  );
};

const MOCK = [
  {
    'Category Name': 'Business Type',
    'Aggregated Importance': 0.5589778128981169,
  },
  {
    'Category Name': 'Photography Style',
    'Aggregated Importance': 0.439880199871976,
  },
  { 'Category Name': 'Format', 'Aggregated Importance': 0.001141987229907013 },
];
