export const COLORS = 'Colors';
export const PRIMARY_DIMENSIONS = 'Primary Dimensions';
export const FOCAL_POINT = 'Focal Point';
export const FORMAT = 'Format';
export const PEOPLE = 'People';
export const UNIQUE_CLIENT_ATTRIBUTES = 'Unique Client Attributes';
export const TRENDING = 'Trending';
export const ESSENTIALS = 'Essentials';
export const TEXT_TREATMENT = 'Text Treatment';
export const EXECUTION_STYLES = 'Execution Styles';
export const KEYWORD = 'Keyword';
export const PRIMARY_PHRASE = 'Primary Phrase';
export const PRIMARY_PHRASE_POSITION = 'Primary Phrase Position';
export const PRIMARY_PHRASE_FONT_SIZE = 'Primary Phrase Font Size';
export const SECONDARY_PHRASE = 'Secondary Phrase';
export const SECONDARY_PHRASE_POSITION = 'Secondary Phrase Position';
export const SECONDARY_PHRASE_FONT_SIZE = 'Secondary Phrase Font Size';
export const PERCENTAGE_OF_TEXT_IN_VISUAL = 'Percentage of Text in Visual';

export const LABEL_CATEGORIES = [
  FORMAT,
  PRIMARY_DIMENSIONS,
  PERCENTAGE_OF_TEXT_IN_VISUAL,
  PRIMARY_PHRASE_FONT_SIZE,
  PRIMARY_PHRASE_POSITION,
  SECONDARY_PHRASE_FONT_SIZE,
  SECONDARY_PHRASE_POSITION,
  FOCAL_POINT,
  TEXT_TREATMENT,
  COLORS,
  PEOPLE,
  PRIMARY_PHRASE,
  SECONDARY_PHRASE,
  KEYWORD,
  ESSENTIALS,
  UNIQUE_CLIENT_ATTRIBUTES,
  EXECUTION_STYLES,
  TRENDING,
];

export const STANDARD_AD_LABELS = [
  {
    value: 'VIDEO+',
    label: 'Video',
    description: 'An ad having moving elements.',
    example: {
      type: 'video',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/video.mp4',
    },
  },
  {
    value: 'IMAGE+',
    label: 'Image',
    description: 'An ad without moving elements, i.e. a static image.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/image.png',
    },
  },
  {
    value: 'VISUALTEXT+',
    label: 'Visual Text',
    description:
      'Any visual that contains text. Logos using text are not counted.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/visual-text.jpg',
    },
  },
  {
    value: 'CAPSCASE+',
    label: 'Capitalized ',
    description: 'Refers to text in the visual that is in all capital letters.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/price.png',
    },
  },
  {
    value: 'PROPERCASE+',
    label: 'Proper Case',
    description:
      'Refers to any text in the visual that is using proper case. This does not include logos.',
    example: {
      type: 'video',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/proper-case.mp4',
    },
  },
  {
    value: 'PEOPLE+',
    label: 'People ',
    description:
      'Any depiction of people in the visual. This can be one or more, does not necessarily have to show a group of people. The people can be real or non-real illustrations.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/people.png',
    },
  },
  {
    value: 'MAN+',
    label: 'Man ',
    description: 'Refers to any person in the visual that is male-presenting.',
    example: {
      type: 'video',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/male.mp4',
    },
  },
  {
    value: 'WOMAN+',
    label: 'Woman ',
    description: 'Refers to any person in the visual as female-presenting.',
    example: {
      type: 'video',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/female.mp4',
    },
  },
  {
    value: 'PHOTO+',
    label: 'Photo',
    description: 'A photo.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/photo.jpg',
    },
  },
  {
    value: 'NONREAL+',
    label: 'Non Real',
    description:
      'Generally speaking, “Non Real” is something that doesn’t exist in reality. For example, all elements might be fake (illustrations, etc.), or certain parts of the ad might be real (like people), but other elements might not be (background, etc.). This is the opposite of the “Photo” label which means that all elements on the ad are similar to a photograph you would take.',
    example: {
      type: 'video',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/nonreal.mp4',
    },
  },
  {
    value: 'PARTIALIMAGE+',
    label: 'Partial Image',
    description:
      'Any element that is partially covered by a block of color or is not fully shown in the visual.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/partialimage.png',
    },
  },
  {
    value: 'MINIMAL+',
    label: 'Minimal',
    description: 'Refers to a visual layout that is very simple.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/minimal.jpg',
    },
  },
  {
    value: 'PRICE+',
    label: 'Price ',
    description:
      'Refers to any element in the visual that is attached to a currency symbol. Promotion such as “$10 off” still fall under the “Price” label.',
    example: {
      type: 'video',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/price.mp4',
    },
  },
  {
    value: 'SYMBOL+',
    label: 'Symbol',
    description: 'Including a symbol (icon, arrow, doodle, etc.) ',
    example: {
      type: 'video',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/symbol.mp4',
    },
  },
  {
    value: 'BOLD+',
    label: 'Bold Text',
    description: 'Refers to any icon-style element in a visual.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/people.png',
    },
  },
  {
    value: 'LARGETEXT+',
    label: 'Large Text',
    description: 'Refers to text that takes up a lot of visual space.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/large-text.png',
    },
  },
  {
    value: 'CAROUSEL+',
    label: 'Carousel',
    description:
      'Using the ad carousel format, displaying three or more visuals in a group. “Carousel” can also apply to elements in a visual that are moving in a carousel (horizontal scroll) style.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/nonreal.png',
    },
  },
  {
    value: 'BUTTON+',
    label: 'Button ',
    description:
      'Refers to any shape in the image with a call to action. A “Button” is usually rectangular.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/button.png',
    },
  },
  {
    value: 'TESTIMONIAL+',
    label: 'Testimonial',
    description:
      'Refers to any testimonial text in quotations in the visual or any person/people giving a visual on or off camera in a video.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/testimonial.png',
    },
  },
  {
    value: 'LOGO+',
    label: 'Logo',
    description: 'Logo present in the visual.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/LOGO.jpg',
    },
  },
  {
    value: 'CENTEREDFONT+',
    label: 'Font Centered',
    description:
      'Refers to any section of text that is center-justified. It does not need to be centered in the visual overall.',
    example: {
      type: 'video',
      asset:
        'https://label-assets.s3.us-east-2.amazonaws.com/font-centered.mp4',
    },
  },
  {
    value: 'PROMO+',
    label: 'Promo',
    description:
      'Refers to any offer depicted in the visual. It can overlap with the “Price” label if the offer is a flat amount.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/promo.png',
    },
  },
  {
    value: 'LOUD+',
    label: 'Loud',
    description:
      'Generally speaking, “Loud” is something that is visually striking or likely to grab the users attention.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/image.png',
    },
  },
  {
    value: 'COLORBLOCK+',
    label: 'Color Block',
    description:
      'Refers to any solid or semi-transparent block of color in the visual. Color blocks can be any shape and are generally medium/large in size.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/colorblock.png',
    },
  },
  {
    value: 'THIN+',
    label: 'Thin Font',
    description:
      'Refers to any text that have a thin/light font style. Thin-leaning regular font styles are included.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/thin-font.png',
    },
  },
  {
    value: 'GROUP+',
    label: 'Group',
    description: 'Refers to any group of people shown in the visual.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/group.png',
    },
  },
  {
    value: '1.91:1+',
    label: '1.91:1 Ratio',
    description: 'Any creative that is horizontal.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/partialimage.png',
    },
  },
  {
    value: '9:16+',
    label: '9:16 Ratio',
    description: 'Any creative that is vertical, 1080x1920.',
    example: {
      type: 'video',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/osmo-vid.mp4',
    },
  },
  {
    value: '4:5+',
    label: '4:5 Ratio',
    description: 'Any creative that has dimensions of 4 by 5.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/4by5.png',
    },
  },
  {
    value: '1:1+',
    label: '1:1 Ratio',
    description: 'Any creative that is a square.',
    example: {
      type: 'video',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/1to1.mp4',
    },
  },
  {
    value: 'UGC+',
    label: 'UGC',
    description:
      'UGC: Refers to content that is "User generated" (generally looks less polished and more authentic).',
    example: {
      type: 'video',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/StatelyUGC.mp4',
    },
  },
  {
    value: 'EMOJI+',
    label: 'Emoji',
    description: 'Refers to a visual with one or more emoji element.',
    example: {
      type: 'video',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/emoji.mp4',
    },
  },
  {
    value: 'EYECONTACT+',
    label: 'Eye Contact',
    description:
      'Refers to visuals where a person is making direct eye contact with the viewer.',
    example: {
      type: 'video',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/eye-contact.mp4',
    },
  },
  {
    value: 'SMILING+',
    label: 'Smiling',
    description: 'Refers to an ad with a person clearly smiling.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/smiling.jpg',
    },
  },
  {
    value: 'EMOTION+',
    label: 'Emotion',
    description: '',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/emotion.jpg',
    },
  },
  {
    value: 'PRODUCTUSE+',
    label: 'Product Use',
    description: 'Refers to ads where the product is being used.',
    example: {
      type: 'video',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/productuse.mp4',
    },
  },
  {
    value: 'SCROLLINGPHONE+',
    label: 'Scrolling Phone Animation',
    description:
      'Visual containing a phone (real photo or non-real illustration) with text/objects scrolling up on the phone screen.',
    example: {
      type: 'video',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/osmo-vid.mp4',
    },
  },
  {
    value: 'TWOFRAMEANIMATION+',
    label: 'Two Frame Animation',
    description:
      'A simple gif-type animation that switches back and forth between two similar statics.',
    example: {
      type: 'video',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/twoframe.mp4',
    },
  },
  {
    value: 'TOPMIDDLETEXT+',
    label: 'Top Middle Text',
    description:
      'Simply refers to text positioning being in the upper half of the visual.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/top-mid-text.png',
    },
  },
  {
    value: 'TEXTONREALBACKGROUND+',
    label: 'Text on Background',
    description:
      'Refers to any text overlaid on a photo background. Generally speaking, the photo background is darkened to accommoate the text.',
    example: {
      type: 'image',
      asset:
        'https://label-assets.s3.us-east-2.amazonaws.com/text-over-real.png',
    },
  },
  {
    value: 'SOFTFRAME+',
    label: 'Soft Frame',
    description:
      'Generally speaking, this refers to visuals that have a line border (as opposed to a solid border frame). Other visual elements can be overlap with the frame.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/soft-frame.png',
    },
  },
  {
    value: 'STATICTEXT_ANIMATED OBJECT+',
    label: 'Static text + Animated Object',
    description:
      'The text is static and all other visual elements are animated.',
    example: {
      type: 'video',
      asset:
        'https://label-assets.s3.us-east-2.amazonaws.com/static-text-animated-object.mp4',
    },
  },
  {
    value: 'STATICAD_ANIMATEDTEXT+',
    label: 'Static Ad + Animated Text',
    description:
      'Generally speaking, these videos have just a static background with text entrance animated. This can refer to just the beginning of the video.',
    example: {
      type: 'video',
      asset:
        'https://label-assets.s3.us-east-2.amazonaws.com/ad-animated-text.mp4',
    },
  },
  {
    value: 'PRODUCT_USAGESPLIT+',
    label: 'Product + Product Use',
    description:
      'Generally, this refers to visuals with clear segments that have at least one segment showing the product and at least one segment showing product use. More than two segments can be present and segments can be text-only.',
    example: {
      type: 'image',
      asset:
        'https://label-assets.s3.us-east-2.amazonaws.com/product-usage-split.png',
    },
  },
  {
    value: 'POLAROID+',
    label: 'Polaroids',
    description:
      'Refers to any image or video asset overlaid on a flat colored background similar to a polaroid. Generally speaking, the visual is usually laid out as one polaroid, but multiple polaroid-style images/videos can appear.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/polaroid.png',
    },
  },
  {
    value: 'CELEBRITY+',
    label: 'Celebrity',
    description:
      'Using a celebrity or well known figure in the visual element.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/celebrity.jpg',
    },
  },
  {
    value: 'QUESTION+',
    label: 'Question',
    description:
      'Asking the viewer a question to try and engage them in your content.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/Question.png',
    },
  },
  {
    value: 'NOSTALGIA+',
    label: 'Nostalgia',
    description: 'Trying to appeal to a happy association with the past.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/Nostalgia.png',
    },
  },
  {
    value: 'MEME+',
    label: 'Meme',
    description:
      'Speaking informally to your audience and appealing to sense of humor.',
    example: {
      type: 'image',
      asset: 'https://label-assets.s3.us-east-2.amazonaws.com/Meme.png',
    },
  },
];
