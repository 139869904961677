import React, { useMemo, forwardRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useViewGroupContext } from '../../features/ViewGroup/context';
import { getViewFeatureUrl } from '../../root-routes';

import SimpleTree from '../SimpleTree';
import { groupViews, getFeatureUrl } from './utils';

import './styles.scss';
import IconButton from '../IconButton';

const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <div
    className="view-picker-wrapper"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <button type="button" className="view-picker" ref={ref} title={children}>
      {children}{' '}
    </button>
    <i className="fa fa-caret-down view-picker__arrow" />
  </div>
));

CustomToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const CustomMenu = forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledby }, ref) => (
    <div
      ref={ref}
      style={{
        ...style,
        maxHeight: '300px',
        overflow: 'auto',
        // zIndex: 250,
      }}
      className={`${className} px-3 py-1`}
      aria-labelledby={labeledby}
    >
      <ul className="list-unstyled">{children}</ul>
    </div>
  )
);

CustomMenu.defaultProps = {
  style: {},
  className: '',
  'aria-labeledby': '',
};

CustomMenu.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.shape({ color: PropTypes.string }),
  className: PropTypes.string,
  'aria-labeledby': PropTypes.string,
};

function ViewPicker({
  setView,
  viewName,
  views,
  report_group_name,
  setShouldReroute,
}) {
  const { mainView, setMainView } = useViewGroupContext();
  const pickerId = 'view-picker-button';
  const hist = useHistory();

  const handleView = (id, name, isLeverView) => {
    setView(id, name, isLeverView);

    hist.push(getViewFeatureUrl(id));

    if (mainView) {
      setMainView(null);
    }
  };

  const grouped = useMemo(() => groupViews(views), [views.length]);

  return (
    <Dropdown>
      <Dropdown.Toggle id={pickerId} as={CustomToggle}>
        {report_group_name || viewName}
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu}>
        <SimpleTree
          data={grouped}
          render={({ label, view_name, view_id, runs_lever }) => (
            <a
              // className="d-flex justify-content-between"
              title={view_name}
              href={getViewFeatureUrl(view_id)}
              onClick={(e) => {
                e.preventDefault();
                handleView(view_id, view_name, runs_lever);
                setShouldReroute(true);
              }}
            >
              {label}
              {/* <IconButton>
                <i className="fa-solid fa-layer-group"></i>
              </IconButton> */}
            </a>
          )}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}

ViewPicker.propTypes = {
  setView: PropTypes.func.isRequired,
  views: PropTypes.arrayOf(
    PropTypes.shape({
      view_id: PropTypes.string,
      view_name: PropTypes.string,
      runs_lever: PropTypes.bool,
    })
  ).isRequired,
  viewName: PropTypes.string.isRequired,
};

export default ViewPicker;
