import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'react-bootstrap';
import './styles.scss';

const CustomDropdown = ({
  toggleClass = 'nav-link darwin-custom-link',
  toggleStyle = { fontWeight: 500, fontSize: '0.95rem', height: '100%' },
  caretStyle,
  menuClass = '',
  menuStyle = { zIndex: 100 },
  onSelect,
  onToggle,
  show,
  id,
  alignRight,
  children,
  label,
}) => {
  const CustomToggle = UnboundCustomToggle(toggleStyle, toggleClass, caretStyle);
  const CustomMenu = UnboundCustomMenu(menuStyle, menuClass);

  return (
    <Dropdown onSelect={onSelect} onToggle={onToggle} show={show}>
      <Dropdown.Toggle as={CustomToggle} id={id}>
        {label}
      </Dropdown.Toggle>

      <Dropdown.Menu alignRight={alignRight} as={CustomMenu}>{children}</Dropdown.Menu>
    </Dropdown>
  );
};

CustomDropdown.defaultProps = {
  onToggle: undefined,
  onSelect: undefined,
  show: undefined,
};

CustomDropdown.propTypes = {
  id: PropTypes.string,
  toggleClass: PropTypes.string,
  toggleStyle: PropTypes.object,
  menuClass: PropTypes.string,
  menuStyle: PropTypes.object,
  label: PropTypes.string.isRequired,
  onToggle: PropTypes.func,
  onSelect: PropTypes.func,
  show: PropTypes.bool,
};

CustomDropdown.Item = Dropdown.Item;
CustomDropdown.Header = Dropdown.Header;

export default CustomDropdown;

/**
 * @param {Object=} style
 * @param {string=} className
 */
export const UnboundCustomToggle = (
  style = { fontWeight: 500, fontSize: '0.95rem', height: '100%' },
  className = 'nav-link darwin-custom-link',
  caretStyle = { color: '#BFC3D6'}
) => forwardRef(({ children, onClick }, ref) => (
  <a
    className={className}
    style={style}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    {' '}
  </a>
));

/**
 * @param {Object=} style
 * @param {string=} className
 */
const UnboundCustomMenu = (theStyle, aClassName) => forwardRef(
  ({
    children, style, className, 'aria-labelledby': labeledby,
  }, ref) => (
    <div
      ref={ref}
      style={{ ...style, ...theStyle }}
      className={`dw-dropdown-menu ${className} ${aClassName}`}
      aria-labelledby={labeledby}
    >
      <ul className="list-unstyled">{children}</ul>
    </div>
  ),
);
