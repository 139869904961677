import React from "react";
import PropTypes from "prop-types";

import Modal from "../../../components/BootstrapModal";
import { getViewId } from "../../../store/utils";
import { AGES, GENDERS } from "./constants";

const getAges = (segments) => getSegments(AGES, segments);
const getGenders = (segments) => getSegments(GENDERS, segments);

class SplitsModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.viewId = getViewId();

    this.state = {
      dailyBudget: 5.0,
      ages: getAges(AGES),
      genders: getGenders(GENDERS),
    };

    this.Body = this.Body.bind(this);
    this.Footer = this.Footer.bind(this);
    this.handleBudget = this.handleBudget.bind(this);
    this.handleSegment = this.handleSegment.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUnmount() {
    this.Body = null;
    this.Footer = null;
    this.handleBudget = null;
    this.handleSegment = null;
    this.handleSubmit = null;
  }

  componentDidUpdate(prevProps) {
    if (
      Object.keys(prevProps.segments).length !==
      Object.keys(this.props.segments).length
    ) {
      this.setState({
        ages: getAges(this.props.segments),
        genders: getGenders(this.props.segments),
      });
    }

    prevProps = null;
  }

  handleBudget(e) {
    const { name, value } = e.target;
    // Input type of "number" should never allow a non-numerical value
    // except for "".
    const n = value === "" ? 2 : parseFloat(value);

    this.setState({ [name]: n });
  }

  handleSegment(e) {
    const { name, checked, value } = e.target;
    const update = this.state[name].map(({ label, ...rest }) => ({
      ...rest,
      label,
      ...(label === value && { checked }),
    }));

    this.setState({ [name]: update });
  }

  handleSubmit(e) {
    const { adsetName, adsetId, fetchSplits, handleHide } = this.props;
    const { dailyBudget, ages, genders } = this.state;

    fetchSplits(
      {
        viewId: this.viewId,
        adsetName,
        adsetId,
        dailyBudget,
        ages: derivePostValues(ages),
        genders: derivePostValues(genders),
      },
      handleHide
    );
  }

  isFormValid() {
    const fields = [{ key: "dailyBudget", predicate: (n) => n >= 2 }];

    return fields.every(({ key, predicate }) => predicate(this.state[key]));
  }

  Body() {
    const { dailyBudget } = this.state;

    return (
      <form action="">
        <div className="row">
          <div className="col-sm-12">
            <h5>Step 1: Choose a daily budget</h5>
            <p>Darwin will crate a new adset with the budget specified</p>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">Daily Budget</label>
          <div className="col-sm-6">
            <input
              type="number"
              name="dailyBudget"
              min="0"
              step="0.01"
              className="form-control"
              value={dailyBudget}
              onChange={this.handleBudget}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h5>Step 2: Choose age and gender</h5>
            <p>Choose age and gender segments for the new adset</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3">
            <Checkboxes
              segment="ages"
              options={this.state.ages}
              handleCheck={this.handleSegment}
            />
          </div>
          <div className="col-sm-3">
            <Checkboxes
              segment="genders"
              options={this.state.genders}
              handleCheck={this.handleSegment}
            />
          </div>
        </div>
      </form>
    );
  }

  Footer() {
    const disabled = !this.isFormValid();

    return (
      <>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={this.props.handleHide}
        >
          Cancel
        </button>
        <button
          type="button"
          className={`btn btn-${disabled ? "secondary" : "primary"}`}
          disabled={disabled}
          onClick={this.handleSubmit}
        >
          Create
        </button>{" "}
      </>
    );
  }

  render() {
    const { id, show, handleHide } = this.props;
    const { Body, Footer } = this;

    return (
      <Modal
        id={id}
        handleHide={handleHide}
        labelledby="boost-modal-title"
        show={show}
        Title={
          <div className="d-flex flex-column">
            <h5 className="font-weight-bold">Audience Split</h5>
            <p className="mb-0">Split adsets into component audiences</p>
          </div>
        }
        Body={<Body />}
        Footer={<Footer />}
        modalClass="modal"
      />
    );
  }
}

SplitsModal.propTypes = {
  id: PropTypes.string.isRequired,
  adsetName: PropTypes.string.isRequired,
  adsetId: PropTypes.string.isRequired,
  fetchSplits: PropTypes.func.isRequired,
  show: PropTypes.bool,
  handleHide: PropTypes.func.isRequired,
  ages: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      checked: PropTypes.bool,
    })
  ),
  genders: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      checked: PropTypes.bool,
    })
  ),
};

export default SplitsModal;

function Checkboxes({ segment, options, handleCheck }) {
  const boxes = options.map(({ label, checked }) => (
    <div key={label}>
      <input
        type="checkbox"
        name={segment}
        value={label}
        checked={checked}
        onChange={handleCheck}
      />
      &emsp;
      <label>{label}</label>
    </div>
  ));

  return <>{boxes}</>;
}

/**
 * @param {[String]} targeted
 * @param {Object<string, 1>} segments
 */
function getSegments(targeted, segments) {
  let results = [];

  for (const target of targeted) {
    let option = {
      label: target,
      value: target,
      checked: !!segments[target],
    };

    results.push(option);
  }

  return results;
}

function derivePostValues(arr) {
  let results = [];

  for (const { checked, value } of arr) {
    if (checked) {
      results.push(value);
    }
  }

  return results;
}
