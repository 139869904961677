export const getClusterLabels = (
  allVisualsLabel,
  filteredAllVisuals,
  filteredWinnerIds,
  filteredLoserIds,
  clusterData
) => {
  let clusterLabels = [];

  if (clusterData.length) {
    const groups = _.groupBy(clusterData, 'cluster_number');

    const grouped = Object.entries(groups).map(([key, value]) => {
      const name = 'Cluster ' + (parseInt(key) + 1);
      const visual_ids = value.map(({ visual_id }) => visual_id);

      return {
        name,
        visual_ids,
      };
    });

    clusterLabels = grouped.map((cluster) => {
      const { winners, losers } = filteredAllVisuals.reduce(
        (result, visual) => {
          if (cluster.visual_ids?.includes(visual.id)) {
            result.clusterVisuals.push(visual);

            if (filteredWinnerIds.includes(visual.id)) {
              result.winners.push(visual);
            } else if (filteredLoserIds.includes(visual.id)) {
              result.losers.push(visual);
            }
          }

          return result;
        },
        { winners: [], losers: [] }
      );

      return {
        name: cluster.name,
        value: cluster.name,
        category: 'Darwin Clusters',
        winners,
        losers,
      };
    });
  }

  return clusterLabels;
};
