export const ALL_ADS = 'All Ads';
export const ALL_VISUALS = 'All Visuals';
export const COLORS = 'Colors';
export const PRIMARY_DIMENSIONS = 'Primary Dimensions';
export const FOCAL_POINT = 'Focal Point';
export const FORMAT = 'Format';
export const BRAND = 'Brand / No Brand';
export const PROMO = 'Promo / No Promo';
export const PROMOTION_TYPE = 'Promotion Type';
export const PEOPLE = 'People';
export const UNIQUE_CLIENT_ATTRIBUTES = 'Unique Client Attributes';
export const TRENDING = 'Trending';
export const ESSENTIALS = 'Essentials';
export const TEXT_TREATMENT = 'Text Treatment';
export const EXECUTION_STYLES = 'Execution Styles';
export const INSPIRATION = 'Inspiration';
export const KEYWORD = 'Keyword';
export const PRIMARY_PHRASE = 'Primary Phrase';
export const PRIMARY_PHRASE_POSITION = 'Primary Phrase Position';
export const PRIMARY_PHRASE_FONT_SIZE = 'Primary Phrase Font Size';
export const SECONDARY_PHRASE = 'Secondary Phrase';
export const SECONDARY_PHRASE_POSITION = 'Secondary Phrase Position';
export const SECONDARY_PHRASE_FONT_SIZE = 'Secondary Phrase Font Size';
export const PERCENTAGE_OF_TEXT_IN_VISUAL = 'Percentage of Text in Visual';
export const TOP_PIXEL_COLOR = 'Top Pixel Color';
export const DOMINANT_COLOR = 'Dominant Color';
export const WORD_COUNT = 'Word Count';
export const NUMBER_OF_PEOPLE = 'Number of People';
export const PEOPLE_TRAITS = 'People Traits';
export const PEOPLE_VS_NO_PEOPLE = 'People vs. No People';
export const PHRASES = 'Phrases';

// all categories that should show up in the data in order
export const LABEL_CATEGORIES = [
  ALL_ADS,
  ALL_VISUALS,
  FORMAT,
  BRAND,
  PROMO,
  PROMOTION_TYPE,
  KEYWORD,
  PRIMARY_DIMENSIONS,
  PERCENTAGE_OF_TEXT_IN_VISUAL,
  PRIMARY_PHRASE_FONT_SIZE,
  PRIMARY_PHRASE_POSITION,
  SECONDARY_PHRASE_FONT_SIZE,
  // SECONDARY_PHRASE_POSITION,
  TOP_PIXEL_COLOR,
  FOCAL_POINT,
  WORD_COUNT,
  COLORS,
  PEOPLE_VS_NO_PEOPLE,
  // NUMBER_OF_PEOPLE,
  PRIMARY_PHRASE,
  UNIQUE_CLIENT_ATTRIBUTES,
  // TEXT_TREATMENT,
  DOMINANT_COLOR,
  PEOPLE_TRAITS,
  PEOPLE,
  SECONDARY_PHRASE,
  // ESSENTIALS,
  EXECUTION_STYLES,
  TRENDING,
];

export const NAV_CATEGORIES = [
  {
    label: FORMAT,
    value: FORMAT,
  },
  {
    label: PRIMARY_DIMENSIONS,
    value: PRIMARY_DIMENSIONS,
  },
  {
    label: '% Text in Visual',
    value: PERCENTAGE_OF_TEXT_IN_VISUAL,
  },
  {
    label: 'Primary Phrase',
    value: PRIMARY_PHRASE,
  },
  {
    label: 'Primary Text Size',
    value: PRIMARY_PHRASE_FONT_SIZE,
  },
  {
    label: 'Primary Text Position',
    value: PRIMARY_PHRASE_POSITION,
  },
  {
    label: KEYWORD,
    value: KEYWORD,
  },
  {
    label: FOCAL_POINT,
    value: FOCAL_POINT,
  },
  // {
  //   label: TEXT_TREATMENT,
  //   value: TEXT_TREATMENT,
  // },
  {
    label: PEOPLE_VS_NO_PEOPLE,
    value: PEOPLE_VS_NO_PEOPLE,
  },
  {
    label: TOP_PIXEL_COLOR,
    value: TOP_PIXEL_COLOR,
  },
  {
    label: WORD_COUNT,
    value: WORD_COUNT,
  },
  // {
  //   label: NUMBER_OF_PEOPLE,
  //   value: NUMBER_OF_PEOPLE,
  // },
  // {
  //   label: PEOPLE_TRAITS,
  //   value: PEOPLE_TRAITS,
  // },
  {
    label: UNIQUE_CLIENT_ATTRIBUTES,
    value: UNIQUE_CLIENT_ATTRIBUTES,
  },
];

// key: category name that comes from server
// value: category name that is displayed
// used to check selected category
export const STANDARD_CATEGORIES_LOOKUP = {
  [FORMAT]: FORMAT,
  [PRIMARY_DIMENSIONS]: 'Primary Dimensions',
  [PERCENTAGE_OF_TEXT_IN_VISUAL]: '% Text in Visual',
  '% Text in Visual': PERCENTAGE_OF_TEXT_IN_VISUAL,
  [PRIMARY_PHRASE]: 'Primary Phrase',
  [PRIMARY_PHRASE_FONT_SIZE]: 'Primary Text Size',
  [PRIMARY_PHRASE_POSITION]: 'Primary Text Position',
  [SECONDARY_PHRASE_FONT_SIZE]: 'Secondary Text Size',
  [SECONDARY_PHRASE_POSITION]: 'Secondary Text Position',
  [KEYWORD]: KEYWORD,
  [FOCAL_POINT]: FOCAL_POINT,
  [TEXT_TREATMENT]: TEXT_TREATMENT,
  [PEOPLE_VS_NO_PEOPLE]: PEOPLE_VS_NO_PEOPLE,
  [WORD_COUNT]: WORD_COUNT,
  [NUMBER_OF_PEOPLE]: NUMBER_OF_PEOPLE,
  [PEOPLE_TRAITS]: PEOPLE_TRAITS,
  [TOP_PIXEL_COLOR]: TOP_PIXEL_COLOR,
  [UNIQUE_CLIENT_ATTRIBUTES]: UNIQUE_CLIENT_ATTRIBUTES,
};

export const TESTING_OPPORTUNITY_CATEGORIES = [
  FORMAT,
  PRIMARY_DIMENSIONS,
  PERCENTAGE_OF_TEXT_IN_VISUAL,
  PRIMARY_PHRASE_FONT_SIZE,
  PRIMARY_PHRASE_POSITION,
  // SECONDARY_PHRASE_POSITION,
  FOCAL_POINT,
];

// these categories should display labels in a certain order regardless of performance_percent
export const CATEGORY_ORDERS = {
  [PERCENTAGE_OF_TEXT_IN_VISUAL]: [
    '0-10_PERCENT_TEXT+',
    '10-20_PERCENT_TEXT+',
    '20-30_PERCENT_TEXT+',
    '30-40_PERCENT_TEXT+',
    '40-50_PERCENT_TEXT+',
    '50-60_PERCENT_TEXT+',
    '60-70_PERCENT_TEXT+',
    '70-80_PERCENT_TEXT+',
    '80-90_PERCENT_TEXT+',
    '90-100_PERCENT_TEXT+',
  ],
  [PRIMARY_PHRASE_FONT_SIZE]: [
    'XS_PRIMARY_TEXT+',
    'SM_PRIMARY_TEXT+',
    'MD_PRIMARY_TEXT+',
    'LG_PRIMARY_TEXT+',
    'XL_PRIMARY_TEXT+',
  ],
  [PRIMARY_PHRASE_POSITION]: [
    'TOP_PRIMARY_TEXT+',
    'VERTICAL_CENTER_PRIMARY_TEXT+',
    'BOTTOM_PRIMARY_TEXT+',
  ],
  [SECONDARY_PHRASE_FONT_SIZE]: [
    'XS_SECONDARY_TEXT+',
    'SM_SECONDARY_TEXT+',
    'MD_SECONDARY_TEXT+',
    'LG_SECONDARY_TEXT+',
    'XL_SECONDARY_TEXT+',
  ],
  // [SECONDARY_PHRASE_POSITION]: [
  //   'TOP_SECONDARY_TEXT+',
  //   'VERTICAL_CENTER_SECONDARY_TEXT+',
  //   'BOTTOM_SECONDARY_TEXT+',
  // ],
  [FOCAL_POINT]: [
    'FOCALPOINT_0_20+',
    'FOCALPOINT_20_40+',
    'FOCALPOINT_40_60+',
    'FOCALPOINT_60_80+',
    'HIGHMULTIFOCALPOINT+',
  ],
  // [TEXT_TREATMENT]: [
  //   'NOTEXT+',
  //   '10WORDSORLESS+',
  //   '11-20WORDS+',
  //   '20+WORDS+',
  //   'LOTSOFTEXT+',
  // ],
  [COLORS]: [
    'ONEMAINCOLOR+',
    'TWOMAINCOLORS+',
    'THREEMAINCOLORS+',
    'FOURMAINCOLORS+',
    'FIVEMAINCOLORS+',
  ],
};

export const EDITABLE_CATEGORIES = [
  FORMAT,
  STANDARD_CATEGORIES_LOOKUP[PRIMARY_DIMENSIONS],
  STANDARD_CATEGORIES_LOOKUP[PERCENTAGE_OF_TEXT_IN_VISUAL],
  STANDARD_CATEGORIES_LOOKUP[PRIMARY_PHRASE_FONT_SIZE],
  STANDARD_CATEGORIES_LOOKUP[PRIMARY_PHRASE_POSITION],
  // STANDARD_CATEGORIES_LOOKUP[SECONDARY_PHRASE_POSITION],
  WORD_COUNT,
  PEOPLE_VS_NO_PEOPLE,
  COLORS,
  PRIMARY_PHRASE,
  PEOPLE_TRAITS,
  ESSENTIALS,
  SECONDARY_PHRASE_POSITION,
  // NUMBER_OF_PEOPLE,
  // PEOPLE_TRAITS,
];

export const STANDARD_CATEGORIES = [
  ALL_VISUALS,
  FORMAT,
  STANDARD_CATEGORIES_LOOKUP[PRIMARY_DIMENSIONS],
  STANDARD_CATEGORIES_LOOKUP[PERCENTAGE_OF_TEXT_IN_VISUAL],
  STANDARD_CATEGORIES_LOOKUP[PRIMARY_PHRASE_FONT_SIZE],
  STANDARD_CATEGORIES_LOOKUP[PRIMARY_PHRASE_POSITION],
  STANDARD_CATEGORIES_LOOKUP[SECONDARY_PHRASE_POSITION],
  STANDARD_CATEGORIES_LOOKUP[SECONDARY_PHRASE_FONT_SIZE],
  SECONDARY_PHRASE_POSITION,
  SECONDARY_PHRASE_FONT_SIZE,
  TOP_PIXEL_COLOR,
  FOCAL_POINT,
  WORD_COUNT,
  COLORS,
  PEOPLE_VS_NO_PEOPLE,
  PRIMARY_PHRASE,
  KEYWORD,
  UNIQUE_CLIENT_ATTRIBUTES,
  DOMINANT_COLOR,
  PEOPLE_TRAITS,
  PEOPLE,
  SECONDARY_PHRASE,
  EXECUTION_STYLES,
  TRENDING,
  NUMBER_OF_PEOPLE,
  ESSENTIALS,
  TEXT_TREATMENT,
];

// scans thumbnail only
export const GOOGLE_VISION_CATEGORIES = [
  'Focal Point',
  'Top Pixel Color',
  'Colors',
  '% Text in Visual',
  'Percentage of Text in Visual',
  'Word Count',
];

// scans thumbnail + 3 seconds of content
export const REKOGNITION_CATEGORIES = [
  'Secondary Text Size',
  'Keyword',
];

export const KEY_CATEGORIES = [ALL_VISUALS, FORMAT, KEYWORD, PRIMARY_DIMENSIONS];

export const FORBIDDEN_TESTING_OPPORTUNITIES = [
  '80-90_PERCENT_TEXT+',
  '90-100_PERCENT_TEXT+',
];
