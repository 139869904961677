import React, { useCallback, useMemo } from 'react';
import {
  ADD_NEW_RULE,
  ADD_LABEL_TO_RULE,
  SELECT_RULE,
  REMOVE_LABEL_FROM_RULE,
  DELETE_RULE,
} from './reducer';
import { DerivedLabelsStateObject } from './types';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import './RuleEditor.scss';
import { SELECT_STYLES_GRAY } from '../../../../../constants';
import { useCreativeAnalyticsGet } from '../../../contexts';
import useEffect from 'react';
import { UPDATE_LABEL_NAME } from './reducer';
import { getFilteredOptions } from './utils';

const appendKeywords = (allKeywords, labels) => {
  let result = [...labels];

  allKeywords.forEach((keyword) => {
    if (
      result.some(
        (label) => label.category === 'Keyword' && label.name === keyword
      )
    ) {
      // dont add duplicate
    } else {
      result.push({
        name: keyword,
        category: 'Keyword',
        value: keyword,
      });
    }
  });

  return result;
};

export const RuleEditor = ({
  derivedLabelsState,
  dispatchDerivedLabels,
  deps,
  allKeywords,
}) => {
  const { filteredData } = useCreativeAnalyticsGet();
  const { rules, selected_rule, category_name }: DerivedLabelsStateObject =
    derivedLabelsState;

  const current = rules.find((rule) => rule.rule_id === selected_rule);
  if (!current) return null;

  const currentDeps = deps.get(current.label_name) || [];

  const labelsInUse = useMemo(() => {
    return rules.reduce((acc, current) => {
      if (acc.some((x) => x.value === current.label_name)) return acc;
      if (!current.label_name.trim().length) {
        return acc;
      }

      acc.push({
        label: current.label_name,
        value: current.label_name,
      });

      return acc;
    }, []);
  }, [rules]);

  const formatOptions = (labels) => {
    const uniq = [...new Map(labels.map(item => [item.value, item])).values()];
    
    return uniq
      .map(({ name, category, value }) => ({
        label: `${category}: ${name}`,
        name,
        category,
        value,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  };

  const labelIsDependent = (label) => {
    return currentDeps.some(
      (x) => x.label_value === label.value && label.category === x.category_name
    );
  };

  const isInCategory = (label) => label.category === derivedLabelsState.category_name;

  const isValid = (label) => {
    const match = labelIsDependent(label);
    const isAllVisuals =
      label.value === 'all_visuals' || label.value === 'new_visuals';
    return !label.udc && !match && !isAllVisuals && !isInCategory(label);
  };

  const getFilteredOptions = (existing) => {
    if (!existing || existing.length < 1)
      return formatOptions([...filteredData.filter((label) => isValid(label))]);

    const alreadySelected = (value) => existing.some((y) => y.value === value);

    const labels = [...filteredData].filter((label) => {
      return (
        !alreadySelected(label.value) && isValid(label) 
      );
    });

    return formatOptions(appendKeywords(allKeywords, labels));
  };

  const labelOptions = useMemo(
    () =>
      getFilteredOptions([
        ...current.labels_to_include,
        ...current.labels_to_exclude,
      ]),
    [current, filteredData]
  );

  const addLabelToRule = (label, field) => {
    dispatchDerivedLabels({
      type: ADD_LABEL_TO_RULE,
      new_label: label,
      field,
    });
  };

  const removeLabelFromRule = (label, field) => {
    dispatchDerivedLabels({
      type: REMOVE_LABEL_FROM_RULE,
      label,
      field,
    });
  };

  const handleUpdateLabelName = (e) => {
    dispatchDerivedLabels({
      type: UPDATE_LABEL_NAME,
      label_name: e ? e.label : '',
    });
  };

  const handleCreate = (e) => {
    handleUpdateLabelName({ label: e });
  };

  const handleDeleteRule = () => {
    dispatchDerivedLabels({
      type: DELETE_RULE,
      selected_rule,
    });
  };

  return (
    <div className="rule-editor">
      <div className="rule-editor__heading" style={{ fontWeight: 500 }}>
        <h2>
          <span style={{ marginRight: '1rem', fontWeight: 600 }}>
            Rule {rules.findIndex((rule) => rule.rule_id === selected_rule) + 1}
          </span>
          {category_name} - {current.label_name}
        </h2>
        <i
          className="fa fa-trash"
          style={{
            cursor: 'pointer',
            color: '#1E2B4E',
          }}
          onClick={handleDeleteRule}
        ></i>
      </div>

      <div className="rule-editor__content">
        <EditorBlock
          title="If it has all of these..."
          content={
            <div>
              <div className="editor-block__input d-inline">
                <Select
                  onChange={(label) =>
                    addLabelToRule(label, 'labels_to_include')
                  }
                  options={labelOptions}
                  styles={SELECT_STYLES_GRAY}
                  placeholder="Select labels..."
                  value={null}
                  menuPortalTarget={document.querySelector('body')}
                />
              </div>
              <ul className="editor-block__list">
                {current.labels_to_include.map((label) => {
                  return (
                    <li
                      onClick={() =>
                        removeLabelFromRule(label, 'labels_to_include')
                      }
                    >
                      {label.category}: {label.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          }
        />

        <EditorBlock
          title="But none of these..."
          content={
            <div>
              <div className="editor-block__input d-inline">
                <Select
                  onChange={(label) =>
                    addLabelToRule(label, 'labels_to_exclude')
                  }
                  options={labelOptions}
                  styles={SELECT_STYLES_GRAY}
                  placeholder="Select labels..."
                  value={null}
                  menuPortalTarget={document.querySelector('body')}
                />
              </div>
              <ul className="editor-block__list">
                {current.labels_to_exclude.map((label) => {
                  return (
                    <li
                      onClick={() =>
                        removeLabelFromRule(label, 'labels_to_exclude')
                      }
                    >
                      {label.category}: {label.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          }
        />

        <EditorBlock
          title="Label it a..."
          content={
            <div>
              <div className="editor-block__input d-inline">
                <CreatableSelect
                  isClearable
                  options={labelsInUse}
                  styles={SELECT_STYLES_GRAY}
                  placeholder="Select label or type to create new..."
                  value={
                    labelsInUse.find((x) => x.value === current.label_name) ||
                    null
                  }
                  isSearchable
                  menuPortalTarget={document.querySelector('body')}
                  onChange={handleUpdateLabelName}
                  onCreateOption={handleCreate}
                />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

const EditorBlock = ({ title = '', content }) => {
  return (
    <div className="editor-block">
      <h3 className="editor-block__title">{title}</h3>
      {content}
    </div>
  );
};
