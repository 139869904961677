import { batch } from 'react-redux';

import { setLoader } from '../../Loaders/actions';
import { setError } from '../../Errors/actions';

const { logger } = window;

export const ASSET_CHATS = 'Marketplace.assetChats';
export const ASSET_CHAT_MESSAGE = 'Marketplace.assetChats.message';

export const AssetChats = {
  FETCH: `[${ASSET_CHATS}] FETCH`,
  SET: `[${ASSET_CHATS}] SET`,
  SEND: `[${ASSET_CHATS}] SEND`,
  REMOVE: `[${ASSET_CHATS}] REMOVE`,
  EDIT: `[${ASSET_CHATS}] EDIT`,
  DELETE: `[${ASSET_CHATS}] DELETE`,
};

const HEADERS = { 'content-type': 'application/json' };

export function assetChatsFetch({ project_id }) {
  return { type: AssetChats.FETCH, data: { project_id } };
}

export function assetChatsSet({ message_author, message_list }) {
  return {
    type: AssetChats.SET,
    data: {
      message_author,
      message_list,
    },
  };
}

function assetChatsSendMessage({
  message_text,
  message_sent_time,
  conversation_thread_type,
  message_author,
  isSelf,
  asset_id,
}) {
  return {
    type: AssetChats.SEND,
    data: {
      message_text,
      message_sent_time,
      conversation_thread_type,
      message_author,
      isSelf,
    },
    meta: { asset_id },
  };
}

export function assetChatsEditMessage({
  asset_id,
  message: {
    message_author,
    message_text,
    new_message,
    author_type,
    message_sent_time,
    conversation_thread_type,
    isSelf,
  },
}) {
  return {
    type: AssetChats.EDIT,
    data: {
      asset_id,
      message_text,
      new_message,
      message_sent_time,
      conversation_thread_type,
      message_author,
      author_type,
      isSelf,
    },
  };
}

export function assetChatsDeleteMessage({
  asset_id,
  message: {
    message_author,
    message_text,
    author_type,
    message_sent_time,
    conversation_thread_type,
    isSelf,
  },
}) {
  return {
    type: AssetChats.DELETE,
    data: {
      asset_id,
      message_text,
      message_sent_time,
      conversation_thread_type,
      message_author,
      author_type,
      isSelf,
    },
  };
}

function assetChatsRemoveMessage({
  asset_id,
  conversation_thread_type,
  message_sent_time,
  error_message,
  message_author,
}) {
  return {
    type: AssetChats.REMOVE,
    data: {
      asset_id,
      conversation_thread_type,
      error_message,
      message_sent_time,
      message_author,
    },
  };
}

export function fetchAssetChats({ project_id }) {
  return async (dispatch) => {
    batch(() => {
      dispatch(setError(ASSET_CHATS, false));
      dispatch(setLoader(ASSET_CHATS, true));
    });

    try {
      const res = await fetch(
        `${['api', 'marketplace', 'chat', project_id].join('/')}?level=project`,
        {
          method: 'POST',
          headers: HEADERS,
          credentials: 'include',
        }
      );

      const json = await res.json();

      if (res.status !== 200) {
        const err = new Error(json.error);

        logger.error(err);

        return batch(() => {
          dispatch(setError(ASSET_CHATS, err));
          dispatch(setLoader(ASSET_CHATS, false));
        });
      }
      return batch(() => {
        dispatch(assetChatsSet(json.data));
        dispatch(setLoader(ASSET_CHATS, false));
      });
    } catch (e) {
      logger.error(e);

      return batch(() => {
        dispatch(setError(ASSET_CHATS, e));
        dispatch(setLoader(ASSET_CHATS, false));
      });
    }
  };
}

/*
message:
message_text,
      message_sent_time,
      conversation_thread_type: type,
      message_author: author,
      isSelf: true,
*/

export function sendAssetChatMessage({ project_id, asset_id, message, version }) {
  return async (dispatch) => {
    const {
      message_text,
      message_sent_time,
      conversation_thread_type,
      message_author,
      isSelf,
    } = message;

    batch(() => {
      dispatch(setError(ASSET_CHAT_MESSAGE, false));
      dispatch(setLoader(ASSET_CHAT_MESSAGE, true));
      dispatch(
        assetChatsSendMessage({
          asset_id,
          message_text,
          message_sent_time,
          conversation_thread_type,
          message_author,
          isSelf,
          version,
        })
      );
    });

    try {
      const res = await fetch(
        ['api', 'marketplace', 'chat', project_id, 'send'].join('/'),
        {
          method: 'POST',
          credentials: 'include',
          headers: HEADERS,
          body: JSON.stringify({
            asset_id,
            conversation_thread_type,
            message_text,
            version,
          }),
        }
      );

      if (res.status === 200) {
        return dispatch(setLoader(ASSET_CHAT_MESSAGE, false));
      }

      const err = new Error('Message failed to send.');

      logger.error(err);

      return batch(() => {
        dispatch(setError(ASSET_CHAT_MESSAGE, err));
        dispatch(
          assetChatsRemoveMessage({
            asset_id,
            conversation_thread_type,
            error_message: 'Message failed to send.',
            message_sent_time: new Date().toLocaleString(),
            message_author: 'System',
          })
        );
      });
    } catch (e) {
      logger.error(e);

      return batch(() => {
        dispatch(setError(ASSET_CHAT_MESSAGE, e));
        dispatch(
          assetChatsRemoveMessage({
            asset_id,
            conversation_thread_type,
            error_message: e.toString(),
            message_sent_time: new Date().toLocaleString(),
            message_author: 'System',
          })
        );
      });
    }
  };
}

export function fetchAssetChatsByView({ view_id }) {
  return async (dispatch) => {
    batch(() => {
      dispatch(setError(ASSET_CHATS, false));
      dispatch(setLoader(ASSET_CHATS, true));
    });

    try {
      const res = await fetch(
        `${['api', 'marketplace', 'chat', view_id].join('/')}?level=view`,
        {
          method: 'POST',
          headers: HEADERS,
          credentials: 'include',
        }
      );

      const json = await res.json();

      if (res.status !== 200) {
        const err = new Error(json.error);

        logger.error(err);

        return batch(() => {
          dispatch(setError(ASSET_CHATS, err));
          dispatch(setLoader(ASSET_CHATS, false));
        });
      }
      return batch(() => {
        dispatch(assetChatsSet(json.data));
        dispatch(setLoader(ASSET_CHATS, false));
      });
    } catch (e) {
      logger.error(e);

      return batch(() => {
        dispatch(setError(ASSET_CHATS, e));
        dispatch(setLoader(ASSET_CHATS, false));
      });
    }
  };
}

export function fetchAssetChatsByAsset(asset_id, project_id) {
  console.log(asset_id)
  return async (dispatch) => {
    batch(() => {
      dispatch(setError(ASSET_CHATS, false));
      dispatch(setLoader(ASSET_CHATS, true));
    });

    try {
      const res = await fetch(
        `${['api', 'marketplace', 'chat', project_id, 'asset-messages'].join('/')}`,
        {
          method: 'POST',
          headers: HEADERS,
          credentials: 'include',
          body: JSON.stringify({
            asset_id,
            project_id,
          }),
        }
      );

      const json = await res.json();

      if (res.status !== 200) {
        const err = new Error(json.error);

        logger.error(err);

        return batch(() => {
          dispatch(setError(ASSET_CHATS, err));
          dispatch(setLoader(ASSET_CHATS, false));
        });
      }
      return batch(() => {
        dispatch(assetChatsSet(json.data));
        dispatch(setLoader(ASSET_CHATS, false));
      });
    } catch (e) {
      logger.error(e);

      return batch(() => {
        dispatch(setError(ASSET_CHATS, e));
        dispatch(setLoader(ASSET_CHATS, false));
      });
    }
  };
}
