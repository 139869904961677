export const getAverage = (visuals = [], metric = null) => {
  if (!metric) {
    console.error('Missing metric');
    return 0;
  }

  if (!visuals.length) {
    return 0;
  }

  const sum = visuals.reduce((result, visual) => {
    if (visual) {
      result += visual[metric] ?? 0;
      return result;
    } else {
    }
  }, 0);

  return sum / visuals.length;
};
