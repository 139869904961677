/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import Tippy from '@tippyjs/react';
import { followCursor, animateFill } from 'tippy.js';
import PropTypes from 'prop-types';
import { classNames } from './constants';
import './styles.scss';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import 'tippy.js/animations/shift-away.css';
import 'tippy.js/animations/shift-away-subtle.css';

function Tooltip({
  className,
  shouldShow = true,
  style,
  content,
  distance,
  disabled,
  placement,
  interactive = false,
  follow,
  noPadding,
  showOnCreate,
  maxWidth,
  delay = [400, 0],
  onlyUseCustomClass = false,
  children,
  ...restProps
}) {
  if (!shouldShow) return children;

  const [mounted, setMounted] = useState(true);
  const baseClass = classNames.base;
  const noPaddingClass = classNames.noPadding;

  return (
    <Tippy
      content={mounted ? content : null}
      placement={placement || 'top'}
      animation="fade"
      theme="material"
      arrow={false}
      duration={1}
      distance={distance || 8}
      followCursor={follow}
      animateFill
      interactive={interactive}
      hideOnClick={false}
      maxWidth={maxWidth}
      boundary="viewport"
      className={
        onlyUseCustomClass
          ? className
          : [
              baseClass,
              className && className,
              noPadding && noPaddingClass,
            ].join(' ')
      }
      style={style}
      showOnCreate={showOnCreate}
      plugins={[followCursor, animateFill]}
      onShow={() => setMounted(true)}
      onHidden={() => setMounted(false)}
      disabled={disabled}
      delay={delay}
      popperOptions={{
        strategy: 'fixed',
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['bottom', 'left'],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              altAxis: true,
              tether: false,
            },
          },
          {
            name: 'computeStyles',
            options: {
              gpuAcceleration: false,
            },
          },
        ],
      }}
      {...restProps}
    >
      {children}
    </Tippy>
  );
}

export default Tooltip;

Tooltip.defaultProps = {
  className: null,
  placement: 'top',
  follow: true,
  children: null,
};

Tooltip.propTypes = {
  className: PropTypes.node,
  placement: PropTypes.string,
  follow: PropTypes.bool,
  children: PropTypes.node,
};

export const TooltipWrapper = ({
  shouldShow,
  content,
  tooltipProps,
  children,
}) => {
  if (shouldShow) {
    return (
      <Tooltip {...tooltipProps} content={content}>
        <div>{children}</div>
      </Tooltip>
    );
  }
};

export const TooltipIcon = ({ iconStyle, ...rest }) => {
  return (
    <Tooltip {...rest}>
      <i style={iconStyle} className="dw-tooltip-icon fa fa-circle-info"></i>
    </Tooltip>
  );
};
