import {
  DASHBOARD_SET,
  DASHBOARD_SET_AD,
  DASHBOARD_SET_ADS,
  DASHBOARD_SET_NAV,
  DASHBOARD_SET_SUPPLEMENTAL,
} from './actions';

import { APPROVALS } from './constants';
import INITIAL_STATE from '../initial-state';

const reducer = (state = INITIAL_STATE.CreativeTesting, action) => {
  switch (action.type) {
    case DASHBOARD_SET:
      return { ...state, ...action.data };

    case DASHBOARD_SET_NAV:
      return {
        ...state,
        navItems: action.data,
        navIds: action.byIds,
      };

    case DASHBOARD_SET_AD:
      const _id = action.data.ad_id;

      return {
        ...state,
        ads: {
          ...state.ads,
          [_id]: {
            ...state.ads[_id],
            ...action.data,
          },
        },
      };

    case DASHBOARD_SET_SUPPLEMENTAL:
      const { id } = action.data;

      if (state.ads[id]) {
        return {
          ...state,
          ads: {
            ...state.ads,
            [id]: setSupplemental(state.ads[id], action.data, action.stateType),
          },
        };
      }
      return state;

    case DASHBOARD_SET_ADS:
      return {
        ...state,
        ads: { ...state.ads, [action.data.ad_id]: action.data },
      };

    default:
      return state;
  }
};

export default reducer;

/**
 * @param {{ad_id: String, atf: Object, btf: Object, cta: Object, visual: Object}} ad
 * @param {{id: String, atf: Object, btf: Object, cta: Object, visual: Object}} supplemental
 * @param {String} stateType
 */
const setSupplemental = (ad, supplemental, stateType) => {
  switch (stateType) {
    case APPROVALS:
      return updateApproval(ad, supplemental);

    default:
      return ad;
  }
};

/**
 * @param {{ad_id: String, atf: Object, btf: Object, cta: Object, visual: Object}} ad
 * @param {{id: String, atf: Object, btf: Object, cta: Object, visual: Object}} response
 */
const updateApproval = (
  { ad_id, atf, btf, cta, visual, ...rest },
  response
) => ({
  ...rest,
  ad_id,
  atf: { ...atf, ...response.atf },
  btf: { ...btf, ...response.btf },
  cta: { ...cta, ...response.cta },
  visual: { ...visual, ...response.visual },
});

const updateRunning = ({ zones, ...rest }, response) => ({});
