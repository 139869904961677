import { endOfMonth, startOfMonth } from 'date-fns';
import { toDarwinDate, LAST_MONTH } from '../../../utils/darwin-dates';

export default function useDatePeriods(period1, period2) {
  const period1Start = period1 ? period1.split(',')[0] : toDarwinDate(startOfMonth(LAST_MONTH));
  const period1End = period1 ? period1.split(',')[1] : toDarwinDate(endOfMonth(LAST_MONTH));
  const period2Start = period2 ? period2.split(',')[0] : '';
  const period2End = period2 ? period2.split(',')[1] : '';

  return [period1Start, period1End, period2Start, period2End];
}
