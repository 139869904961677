export const memberToSelection = (m) => ({
  ...m,
  label: m.title,
  value: m.name,
});

export const dimensionToSelection = (m) => ({
  ...m,
  value: m.dimension.name,
  label: m.dimension.title,
});
