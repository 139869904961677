import React from 'react';
import { toPercentRounded } from '../../../../utils/numbers';
import Tooltip from '../../../../components/Tooltip';

const TooltipWrapper = ({
  children,
  isSelected,
  isPredicted,
  confidence,
}) => {
  let tooltip = (
    <div>
      <p style={{ color: '#fff', margin: 0 }}>User annotated</p>
    </div>
  );

  if (isPredicted) {
    tooltip = (
      <div>
        <p style={{ color: '#aef1a3', margin: 0 }}>A.I. Predicted</p>
        <p style={{ color: '#fff', margin: 0 }}>
          Confidence: {toPercentRounded(confidence)}
        </p>
      </div>
    );
  }

  if (!isSelected) return children;

  return <Tooltip content={tooltip}>{children}</Tooltip>;
};

export default TooltipWrapper;