import { toDarwinDate } from "../../../utils/darwin-dates";
import { lastMonthEnd, lastMonthStart } from "./constants";

export const isLastMonth = (start, end) => {
  if (
    start === toDarwinDate(lastMonthStart) &&
    end === toDarwinDate(lastMonthEnd)
  ) {
    return true;
  }
  return false;
};

export const sortReports = (a, b) => {
  return a.name === "default" ? -1 : 1;
}