import React from "react";
import Switch from "@mui/material/Switch";
import { defaultToggleStyle, toggleStyle } from "./constants";
import { darwinDateToFormat } from "../../../../utils/darwin-dates";
import { NoEditIcon } from "../Helpers";
import Tippy from "@tippyjs/react";

const ReportItem = ({
  reports,
  report,
  current,
  reportsToDelete,
  updateReportsToDelete,
  activeReports,
  handleSetStatus,
  handleSelectCurrent,
  reportDetails,
  changesMade,
  handleChangesModal,
  handleRefresh,
  saveInProgress,
  handleSetDefault,
  setToEmpty,
  handleValidation,
  errors,
}) => {
  const isBeingEdited = report.id === current.id;
  const isDefault = report.default;
  const disableAction = isBeingEdited || saveInProgress;

  const handleReportClick = () => {
    if (changesMade && current.id !== report.id) {
      handleChangesModal(report.id);
    } else {
      handleSelectCurrent(report.id);
    }
  };

  return (
    <li
      id={`${report.id}_${report.name}`}
      className={`row report-editor__list-item ${isDefault ? "default" : ""} ${
        isBeingEdited ? "editing" : ""
      }`}
    >
      <div className={`col-2 item-options`}>
        <div
          id="option-checkbox-select"
          className="checkbox-padding"
          onClick={() => updateReportsToDelete(report.id)}
        >
          <div className={`input-container ${disableAction ? "disabled" : ""}`}>
            <input
              type="checkbox"
              checked={reportsToDelete.includes(report.id)}
              id={report.id}
              disabled={disableAction}
            />
          </div>
        </div>
        <div id="option-toggle-active" className="status-toggle">
          <Switch
            aria-label="active"
            checked={report.status === "on"}
            onChange={() => {
              const newStatus = report.status === "on" ? "off" : "on";
              handleSetStatus(report.id, newStatus);
            }}
            disabled={
              (report.status === "off" && activeReports === 4) || disableAction
            }
            sx={isDefault ? defaultToggleStyle : toggleStyle}
          />
        </div>
      </div>
      <div
        className={`col-10 report-title`}
        onClick={() => handleReportClick()}
      >
        <div id={report.id} className="invisible-overlay"></div>
        <div className="report-title__details">
          <span className="report-name">
            {report.name === "default" ? "Last Month" : report.name}
          </span>
          {reportDetails && (
            <div className="report-details">
              <span>
                {`${darwinDateToFormat(
                  report.start_date,
                  "MM/dd/yyyy"
                )} - ${darwinDateToFormat(report.end_date, "MM/dd/yyyy")}`}
              </span>
              <span className="">
                {report.audience ? report.audience : "All Audiences"}
              </span>
            </div>
          )}
        </div>
      </div>
      <ReportOptions
        {...{
          report,
          isBeingEdited,
          handleSetDefault,
          saveInProgress,
          isDefault,
          handleRefresh,
          changesMade,
          setToEmpty,
          disableAction,
          handleValidation,
          handleReportClick,
          errors,
        }}
      />
    </li>
  );
};

export default ReportItem;

const ReportOptions = ({
  report,
  isBeingEdited,
  handleSetDefault,
  saveInProgress,
  isDefault,
  handleRefresh,
  changesMade,
  setToEmpty,
  disableAction,
  handleValidation,
  handleReportClick,
  errors,
}) => {
  return (
    <div className="report-options">
      {!isBeingEdited ? (
        <>
          <Tippy
            className="impact-report__tooltip"
            delay={[250, 0]}
            content={isDefault ? "Default report" : "Set as default"}
          >
            <div
              className={`report-options__option ${
                disableAction || report.status === "off" ? "disabled" : ""
              } ${isDefault ? "default" : ""}`}
              onClick={() => {
                if (disableAction || report.status === "off") return;
                handleSetDefault(report.id, isDefault);
              }}
            >
              {isDefault ? (
                <i className="fa-solid fa-star"></i>
              ) : (
                <i className="fa-regular fa-star"></i>
              )}
            </div>
          </Tippy>
          <Tippy
            className="impact-report__tooltip"
            delay={[250, 0]}
            content="Refresh"
          >
            <div
              className={`report-options__option ${
                disableAction ? "disabled" : ""
              }`}
              onClick={() => {
                if (disableAction) return;
                handleRefresh(report.id);
              }}
            >
              <i
                aria-label="refresh"
                className={`fa-solid fa-arrow-rotate-right refresh ${
                  disableAction ? "disabled" : ""
                }`}
              ></i>
            </div>
          </Tippy>
        </>
      ) : (
        <>
          <Tippy
            className="impact-report__tooltip"
            delay={[250, 0]}
            content={"Save"}
          >
            <div
              className={`report-options__option ${
                !changesMade || saveInProgress ? "disabled" : ""
              }`}
              onClick={() => {
                if (!changesMade || saveInProgress) return;
                handleValidation();
              }}
            >
              <i
                className={`fa-solid fa-floppy-disk ${
                  saveInProgress ? "fa-fade" : ""
                }`}
                aria-label="save"
              ></i>
            </div>
          </Tippy>
          <Tippy
            className="impact-report__tooltip"
            delay={[250, 0]}
            content={changesMade ? "Clear and discard changes" : "Clear"}
          >
            <div
              className={`report-options__option ${
                saveInProgress ? "disabled" : ""
              }`}
              onClick={() => {
                setToEmpty();
              }}
            >
              <i className="fa-solid fa-ban" aria-label="clear"></i>
            </div>
          </Tippy>
        </>
      )}
      <Tippy
        className="impact-report__tooltip"
        delay={[250, 0]}
        content="Details"
      >
        <div className="report-options__option" onClick={handleReportClick}>
          <span className="details-icon">
            {isBeingEdited ? (
              <>
                {errors.length ? (
                  <i
                    className="fa fa-circle-exclamation fa-fade error-icon"
                    aria-label="error"
                  ></i>
                ) : null}
                <i className="fa-solid fa-pen fa-fade"></i>
              </>
            ) : (
              <i className="fa fa-arrow-right"></i>
            )}
          </span>
        </div>
      </Tippy>
    </div>
  );
};
