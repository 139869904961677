import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import { useActionName } from '../selectors';
import { useBoxPlotData } from './useBoxPlotData';
import Overlay from '../../../components/Overlay';
import './BoxPlot.scss';

const BoxPlot = ({ activeBoxPlotCategory, setActiveBoxPlotCategory }) => {
  const { boxPlotData, chartLabels } = useBoxPlotData(activeBoxPlotCategory);
  const actionName = useActionName();
  const isROAS = actionName?.value?.includes('value_');

  return (
    <Overlay
      show={!!activeBoxPlotCategory}
      close={() => setActiveBoxPlotCategory(null)}
    >
      <div className="ccc-box-plot">
        <h2 className="ccc-box-plot__title">
          {activeBoxPlotCategory} | Box Plot
        </h2>
        <HighchartsReact
          containerProps={{
            style: { width: '90vw', maxWidth: '800px', paddingBottom: '20px' },
          }}
          highcharts={Highcharts}
          options={makeChartOptions(boxPlotData, chartLabels, isROAS)}
        />
      </div>
    </Overlay>
  );
};

export default BoxPlot;

HighchartsMore(Highcharts);

const HighchartsBoxPlotPlus = function (H) {
  H.wrap(H.seriesTypes.boxplot.prototype, 'translate', function (proceed) {
    proceed.apply(this, Array.prototype.slice.call(arguments, 1));

    this.points.forEach((p) => {
      if (p.current !== null) {
        p.currentPlot = this.yAxis.translate(p.current, 0, 1, 0, 1);
      }
    });
  });

  H.wrap(H.seriesTypes.boxplot.prototype, 'drawPoints', function (proceed) {
    proceed.apply(this, Array.prototype.slice.call(arguments, 1));

    const { renderer } = this.chart;
    this.points.forEach((point) => {
      const {
        plotX,
        currentPlot,
        indicatorShape,
        indicatorColor,
        shapeArgs: { width } = {},
      } = point;
      let shapePaths = null;
      // Use 50% of box width for indicator
      let indicatorWidth = Math.floor(width * 0.5);
      // Cap to 20 width so it doesn't look too big if bar widths are wide
      indicatorWidth = indicatorWidth > 20 ? 20 : indicatorWidth;
      const offset = indicatorWidth / 2;
      let polygonal = true;

      let shape;
      const attrs = {
        fill: indicatorColor,
        stroke: indicatorColor,
        'stroke-width': 0,
      };
      if (!point.indicator) {
        if (polygonal) {
          shape = renderer.path(shapePaths).attr(attrs);
        } else {
          shape = renderer.circle(plotX, currentPlot, offset).attr(attrs);
        }
        point.indicator = shape
          .addClass('highcharts-boxplot-current-plot')
          .add(point.graphic);
      } else {
        if (polygonal) {
          point.indicator.animate({
            d: shapePaths,
          });
        } else {
          point.indicator.animate({
            cx: plotX,
            cy: currentPlot,
            r: offset,
          });
        }
      }
    });
  });

  H.wrap(H.seriesTypes.boxplot.prototype, 'drawGraph', function (proceed) {
    // console.log("Test");
    // console.log(this);
  });
};

HighchartsBoxPlotPlus(Highcharts);

const makeChartOptions = (boxPlotData, chartLabels, isROAS) => {
  let max = chartLabels?.length > 9 ? 10 : null;

  return {
    chart: {
      inverted: true,
      width: 600,
    },
    xAxis: {
      categories: chartLabels,
      min: 0,
      max,
      scrollbar: {
        enabled: true,
      },
      labels: {
        step: 1,
      },
    },
    yAxis: {
      title: {
        text: isROAS ? 'ROAS' : 'CPA',
      },
    },
    series: [
      {
        name: 'Observations',
        type: 'boxplot',
        zIndex: 0,
        grouping: false,
        data: boxPlotData,
        whiskerLength: 0,
        showInLegend: false,
        tooltip: {
          pointFormatter: function () {
            let text = '<br>';
            text +=
              '<br><span style="color:#039be5;">\u25CF ' +
              '</span>' +
              '<span style="color:#333;font-weight:bold;">' +
              this.series.name +
              '</span><br>';
            text += '<br>Maximum: ' + this.high;
            text += '<br>Upper quartile: ' + this.q3;
            text += '<br>Median: ' + this.median;
            text += '<br>Lower quartile: ' + this.q1;
            text += '<br>Minimum: ' + this.low;
            text += '<br>Number observations: ' + this.count;

            return text;
          },
        },
      },
    ],
    plotOptions: {
      boxplot: {
        boxDashStyle: 'line',
        fillColor: '#039be5',
        lineWidth: 2,
        medianColor: '#434343',
        medianDashStyle: 'line',
        medianWidth: 2,
        stemColor: '#434343',
        stemDashStyle: 'dot',
        stemWidth: 1,
        whiskerColor: '#434343',
        whiskerLength: '10%',
        whiskerWidth: 2,
      },
      series: {
        cropThreshold: 100,
      }
    },
    title: false,
    credits: false,
  };
};
