export const debug = (updatedVisuals) => {
  updatedVisuals.forEach((value, key) => {
    const { added, original, updatedVisual } = value;
    let originalSum = original.spend;
    let addedSum = added.reduce((acc, curr) => {
      acc += curr.spend;
      return acc;
    }, 0);
    const newSpend = updatedVisual.spend;

    const diff = newSpend - addedSum;

    if (diff.toFixed() !== originalSum.toFixed()) {
      console.log('DISCREPANCY')
      console.log({ key, value, originalSum, addedSum, newSpend, diff })
    }
  })
}

export const checkForDuplicates = (allVisualLabel) => {
  if (!allVisualLabel) return;
  const visuals = [...allVisualLabel.winners, ...allVisualLabel.losers]
  let seen = [];

  visuals.forEach((visual) => {
    if (seen.includes(visual.id)) {
      console.log('DUPLICATE')
      console.log(visual)
    } else {
      seen.push(visual.id)
    }
  })

  console.log(seen)
  console.log(new Set(seen))
}
