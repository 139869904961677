import { useReducer, useEffect, useRef, useState, useCallback } from 'react';
import { abortableFetch } from '../../utils';
import {
  useApiState,
  bindFetchToRef,
} from '../../components/Hooks/useApiState';

const get = (url) => {
  return abortableFetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
  });
};

export const useGetRequest = (url, startLoading = false) => {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [data, getDispatch] = useApiState(
    () => withRef(get(url)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: startLoading,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);

  const request = useCallback(() => {
    getDispatch();
  }, [url]);

  return {
    ...data,
    request,
  };
};
