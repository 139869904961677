
import React, { useRef, useReducer } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';
import Select from 'react-select';

import CustomDropdown from '../../../components/CustomDropdown';

import { DARWIN_BLUE, DARWIN_RED, DARWIN_SEAFOAM } from '../../../constants';

// @ts-ignore
process.env.NODE_ENV === 'development' && (window.Highcharts_Maps = Highcharts);

const SELECT_STYLE = {
  container: (base, state) => ({
    ...base,
    width: '100%',
  }),
  menu: (base, state) => ({ ...base }),
};
const PRE_VALUES = ['Upper', 'Mid', 'Lower'];
const PRE_SELECTED = PRE_VALUES.map((a) => ({
  value: a,
  label: a,
}));

const ACTIONS = {
  CLEAR_SELECTIONS: 'CLEAR_SELECTIONS',
  SET_SELECTIONS: 'SET_SELECTIONS',
  SET_COLORS: 'SET_COLORS',
};

const chartReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.CLEAR_SELECTIONS:
      return {
        colors: state.colors,
        selections: [],
        chartState: action.chartState,
      };

    case ACTIONS.SET_SELECTIONS:
      return {
        colors: state.colors,
        selections: action.selections,
        chartState: action.chartState,
      };

    case ACTIONS.SET_COLORS:
      return {
        ...state,
        colors: action.colors,
      };

    default:
      return state;
  }
};

// Using a function component/pure component won't properly
// highlight a selected tile.
const HeatMatrix = ({
  data, dimensions, handleSeriesClick, selectedId,
}) => {
  const chart = useRef(null);
  const processed = preProcess(data);
  const cprThresholds = minMaxCpr(processed);
  const _setChartState = (selections) => setChartState(processed, selections);
  const options = audienceOptions(processed);
  const [{ selections, colors, chartState }, dispatch] = useReducer(
    chartReducer,
    {
      colors: '1',
      selections: PRE_SELECTED,
      chartState: _setChartState(PRE_SELECTED),
    },
  );

  const handleSelection = (s) => {
    dispatch(
      !s || s.length === 0
        ? {
          type: ACTIONS.CLEAR_SELECTIONS,
          chartState: _setChartState([]),
        }
        : {
          type: ACTIONS.SET_SELECTIONS,
          selections: s,
          chartState: _setChartState(s),
        },
    );
  };

  const handleColor = (key) => {
    key === colors ? null : dispatch({ type: ACTIONS.SET_COLORS, colors: key });
  };

  const state = {
    ...chartState,
    ...cprThresholds,
    onSeriesClick: handleSeriesClick,
    height: dimensions.height,
    colors: colors === '1',
  };

  return (
    <>
      <Select
        options={options}
        value={selections}
        onChange={handleSelection}
        styles={SELECT_STYLE}
        isMulti
        placeholder="Select audiences..."
      />
      <HighchartsReact
        highcharts={Highcharts}
        options={makeState(state)}
        callback={(chartInstance) => {
          chart.current = chartInstance;
          if (selectedId !== '') {
            const maybe = chartInstance.get(selectedId);

            return !!maybe && maybe.select(true, true);
          }
        }}
      />
      <div
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          height: '35.75px',
          zIndex: 1,
        }}
      >
        <div className="d-flex flex-row" style={{ height: '100%' }}>
          <CustomDropdown onSelect={handleColor} label="Show Colors">
            <CustomDropdown.Item eventKey="1">Yes</CustomDropdown.Item>
            <CustomDropdown.Item eventKey="0">No</CustomDropdown.Item>
          </CustomDropdown>
        </div>
      </div>
    </>
  );
};

HeatMatrix.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  handleSeriesClick: PropTypes.func.isRequired,
  dimensions: PropTypes.shape({
    height: PropTypes.number,
  }).isRequired,
};

export default HeatMatrix;

function makeState({
  onSeriesClick,
  height,
  audiences,
  themes,
  series,
  minBadCpr,
  maxGoodCpr,
  colors,
}) {
  const config = {
    chart: {
      type: 'heatmap',
      marginTop: 20,
      marginBottom: 80,
      height,
    },

    title: {
      text: undefined,
    },

    xAxis: {
      categories: audiences,
    },

    yAxis: {
      categories: themes,
      title: null,
      reversed: true,
    },

    // Disabling "colorAxis" entirely will not remove colors.
    colorAxis: {
      dataClasses: colors
        ? [
          {
            color: DARWIN_SEAFOAM,
            name: 'Good',
            from: 0,
            to: maxGoodCpr,
          },
          {
            color: '#ffffff',
            name: 'Average',
            from: maxGoodCpr,
            to: minBadCpr,
          },
          {
            color: DARWIN_RED,
            name: 'Poor',
            from: minBadCpr,
          },
        ]
        : [{
          color: '#ffffff', name: 'All', from: -Infinity, to: Infinity,
        }],
    },

    tooltip: {
      formatter() {
        return `
          Audience: <b>${this.series.xAxis.categories[this.point.x]}</b>
          <br />
          Theme: <b>${this.series.yAxis.categories[this.point.y]}</b>
          <br />
          Spend: <b>$${this.point._spend_}</b>
          <br />
          CPR: <b>$${this.point.value}</b>
        `;
      },
    },

    plotOptions: {
      heatmap: {
        allowPointSelect: true,
        states: {
          select: {
            textColor: '#ffffff',
            color: DARWIN_BLUE,
          },
        },
      },
    },

    series: [
      {
        borderWidth: 1,
        borderColor: '#ffffff',
        data: series,
        dataLabels: {
          enabled: true,
          color: '#333333',
          style: {
            textOutline: undefined,
          },
        },

        point: {
          events: {
            click(e) {
              return onSeriesClick({ category: this.id });
            },
          },
        },
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            yAxis: {
              labels: {
                formatter() {
                  return this.value.charAt(0);
                },
              },
            },
          },
        },
      ],
    },
  };

  return config;
}

function setChartState(data, selections) {
  let source = data;

  if (selections.length > 0) {
    const joined = selections.map(({ value }) => value).join(',');
    const filtered = data.filter(({ audience }) => joined.includes(audience));

    source = filtered;
  }

  return makeSeries(source);
}

function makeSeries(data) {
  const auds = new Set();
  const thms = new Set();
  const series = [];

  // Field names are surround by "_" to avoid
  // any potential collisions with Highchart's
  // names.
  for (const {
    cpr, value, spend, audience, theme,
  } of data) {
    if (cpr > 0) {
      series.push({
        value: cpr,
        audience,
        theme,
        id: value,
        _spend_: spend,
      });
      auds.add(audience);
      thms.add(theme);
    }
  }

  const audiences = [...auds];
  const themes = [...thms];

  const getCoords = (audience, theme) => ({
    _audience_: audience,
    _theme_: theme,
    x: audiences.indexOf(audience),
    y: themes.indexOf(theme),
  });

  const final = [];

  for (const { audience, theme, ...rest } of series) {
    final.push({
      ...rest,
      ...getCoords(audience, theme),
    });
  }

  return {
    series: final,
    audiences,
    themes,
  };
}

/**
 * @param {[Number]} nums
 *
 * @return {{ stddev: Number, mean: Number}}
 */
function streamingVariance(nums) {
  let n = 0;
  let mean = 0;
  let m2 = 0;

  for (const x of nums) {
    n++;

    const delta = x - mean;

    mean += delta / n;
    m2 += delta * (x - mean);
  }

  if (n < 2) {
    return { stddev: undefined, mean: undefined };
  }
  return { stddev: Math.sqrt(m2 / n), mean };
}

function preProcess(data) {
  return data.map(({ value, spend, cpr }) => {
    const [audience, theme] = value.split(':');

    return {
      cpr,
      spend,
      value,
      audience,
      theme,
    };
  });
}

function audienceOptions(data) {
  const uniq = new Set(data.map(({ audience }) => audience));

  return [...uniq].map((a) => ({ label: a, value: a }));
}

function minMaxCpr(data) {
  const cprs = data.filter(({ cpr }) => cpr > 0).map(({ cpr }) => cpr);

  const { stddev, mean } = streamingVariance(cprs);

  return {
    maxGoodCpr: mean - stddev,
    minBadCpr: mean + stddev,
  };
}
