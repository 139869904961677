import { useCallback, useEffect, useMemo } from 'react';
import { useCreativeAnalyticsGet } from '../../CreativeAnalytics/contexts';
import { addMetrics, getPreviousDates } from './utils';
import { toDarwinDate } from '../../../utils/darwin-dates';
import { useCustomMetrics } from '../../CreativeAnalytics/selectors';
import { formatPreviousData } from '../CreativePerformance/utils';
import { useFetchPFRData } from '../../CreativeAnalytics/hooks/useFetchPFRData';
import useTrackDeps from '../../../hooks/useTrackDeps';

const HEADERS = { 'content-type': 'application/json' };

export const usePreviousDateRangeData = () => {
  const { start_date, end_date, clientMetrics } = useCreativeAnalyticsGet();
  const { custom_events, additional_metrics, customIntegratedMetrics } =
    useCustomMetrics();
  const { previous_start_date, previous_end_date } = useMemo(() => {
    return getPreviousDates(start_date, end_date);
  }, [toDarwinDate(start_date), toDarwinDate(end_date)]);

  const { data, metricsData, loading, error, request } = useFetchPFRData({
    start_date: toDarwinDate(previous_start_date),
    end_date: toDarwinDate(previous_end_date),
    metrics_only: true,
    autoFetch: false,
    triggerStore: false,
  });

  const visuals = useMemo(() => {
    const visualsData = metricsData ?? data?.metric_data ?? data ?? [];
    return visualsData.map(
      addMetrics({
        clientMetrics,
        customEvents: custom_events,
        additional_metrics,
        customIntegratedMetrics,
      })
    );
  }, [
    data,
    metricsData,
    clientMetrics,
    custom_events,
    additional_metrics,
    customIntegratedMetrics,
  ]);
  let status = 'idle';

  if (error) status = 'error';
  else if (loading) status = 'loading';
  else if (visuals?.length) status = 'success';


  const formatted = useMemo(() => {
    return formatPreviousData(visuals);
  }, [visuals]);

  const trendData = useMemo(() => {
    return {
      data: formatted,
      status,
      previous_start_date,
      previous_end_date,
      request,
    };
  }, [
    formatted,
    status,
    toDarwinDate(previous_start_date),
    toDarwinDate(previous_end_date),
  ]);

  return trendData;
};
