import { format } from 'date-fns';
import React from 'react';
import { Virtuoso } from 'react-virtuoso';
import Tooltip from '../../components/Tooltip';
import { SORT_OPTIONS } from './constants';
import CustomDropdown from '../../components/CustomDropdown';
import LazyLoader from '../../components/LazyLoader';
import { toCurrency } from '../../utils/numbers';

export const Experiments = ({ experimentsData, debugExperiment }) => {
  return (
    <div className="experiments">
      <ul className="experiments-list">
        {experimentsData.map((experiment) => {
          return (
            <Experiment
              experiment={experiment}
              debugExperiment={debugExperiment}
            />
          );
        })}
      </ul>
    </div>
  );
};

export const Experiment = ({ experiment, debugExperiment }) => {
  return (
    <LazyLoader
      className="align-self-center"
      placeholder={
        <div style={{ height: '12rem', backgroundColor: '#f4f5f8' }} />
      }
    >
      <li className="experiments-item">
        {/* <button
          onClick={() =>
            debugExperiment(experiment.campaign_name, experiment.adset_name)
          }
        >
          debug
        </button> */}
        <h3 className="experiments-item__heading">
          Creative Launch{' '}
          {format(new Date(experiment.adset_created_time), 'MM/dd/yyyy')}
        </h3>
        <div className="experiments-item-content">
          <div className="experiments-item-metadata">
            <span className="experiments-item-metadata__item">
              System: Meta
            </span>
            <MetadataItem
              label={'Campaign'}
              content={experiment.campaign_name}
            />
            <MetadataItem label={'Adset'} content={experiment.adset_name} />
            <MetadataItem
              label={'Objective'}
              content={experiment.optimization_event}
            />

            {experiment.variation_score !== undefined && (
              <MetadataItem
                label={'Variation Score'}
                content={
                  'This is a score of 0.0 to 1.0 on how different the visuals are in your dataset according to the EasyAI labels you have stored. You want more variation in your launches to improve performance'
                }
                value={experiment.variation_score?.toFixed(2) ?? null}
                valueColor={valueColor(experiment.variation_score)}
              />
            )}
          </div>
          <div className="experiments-item-visuals">
            <VisualsList visuals={experiment.ads} />
          </div>
        </div>
      </li>
    </LazyLoader>
  );
};

const GREEN = '#239264';
const RED = '#b42e56';
const ORANGE = 'rgb(208 106 0)';

const valueColor = (value: number) => {
  if (value === null || value === undefined) return null;

  if (value <= 0.1) {
    return RED;
  } else if (value <= 0.2499) {
    return ORANGE;
  } else {
    return GREEN;
  }
};

export const MetadataItem = ({
  label,
  content,
  value = null,
  valueColor = 'inherit',
}) => {
  return (
    <span className="experiments-item-metadata__item">
      {label}
      <Tooltip
        delay={0}
        follow={false}
        interactive
        content={
          <p className="m-0">
            {label}: {content}
          </p>
        }
      >
        <i className="fa-regular fa-circle-question ml-1"></i>
      </Tooltip>
      {value && (
        <span
          title={value}
          style={{ color: valueColor, fontWeight: 500 }}
          className="ml-1"
        >
          {value}
        </span>
      )}
    </span>
  );
};

export const SortToggle = ({ sort, setSort }) => {
  const handleSelect = (eventKey, b) => {
    const [value, order] = eventKey.split('___');

    const matchingOpt = SORT_OPTIONS.find(
      (opt) => opt.value === value && opt.order === order
    );
    if (matchingOpt) {
      setSort(matchingOpt);
    }
  };

  return (
    <CustomDropdown
      id={'experiments-sort-toggle'}
      onSelect={handleSelect}
      label={`Ordered by ${sort?.label || ''}`}
    >
      {SORT_OPTIONS.map(({ value, label, order }) => (
        <CustomDropdown.Item
          key={label}
          eventKey={`${value}___${order}`}
          className="py-2"
        >
          {label}
        </CustomDropdown.Item>
      ))}
    </CustomDropdown>
  );
};

const VisualsList = ({ visuals = [] }) => {
  return (
    <ul className="experiments-ads-gallery">
      {visuals.map((ad) => {
        return (
          <LazyLoader>
            <li>
              <VisualDisplay ad={ad} />
            </li>
          </LazyLoader>
        );
      })}
    </ul>
  );
};

const VisualDisplay = ({ ad }) => {
  const {
    s3_url,
    visual_type,
    rank,
    adset_share_of_voice,
    ad_spend,
    line_item_cpa,
  } = ad;

  let display = null;

  if (visual_type === 'VIDEO') {
    display = (
      <video muted controls autoPlay={false}>
        <source src={s3_url}></source>
      </video>
    );
  } else if (visual_type === 'IMAGE' || visual_type === 'CAROUSEL') {
    display = <img src={s3_url} />;
  } else {
    return <p>There was an error.</p>;
  }

  return (
    <div className="experiments-visual-wrapper">
      <Tooltip
        content={
          <ul
            style={{
              listStyle: 'none',
              padding: 0,
              margin: 0,
            }}
          >
            <li>Share of Voice: {adset_share_of_voice || '-'}</li>
            <li>Spend: {ad_spend || '-'}</li>
            <li>Cost Per: {toCurrency(line_item_cpa ?? 0)}</li>
          </ul>
        }
      >
        <div className="experiments-visual">{display}</div>
      </Tooltip>
      <p className="mt-2">
        {!!rank && !isNaN(rank) ? `Rank ${rank}` : 'No Rank'}
      </p>
    </div>
  );
};
