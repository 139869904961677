//@ts-check
import React from "react";
import PropTypes from "prop-types";

import { formatMetricType } from "../Strikezone/utils";
import Details from "../HTML_Components/Details";

// too many null values to make required
const RequiredString = PropTypes.string;
const SUMMARY_STYLE = {
  fontSize: "17px",
  color: "#666666",
  fontWeight: "300"
};

const PerformancePanel = ({
  selectedType,
  testing_code,
  testing_information,
  ...props
}) => {
  if (!props.ad_name) {
    return <div>Loading...</div>;
  } else {
    if (selectedType === "") {
      return (
        <div>
          <h4>
            <strong>Why was this Ad tested?</strong>
          </h4>
          <p>{testing_information[0].explanation}</p>
        </div>
      );
    } else {
      //@ts-ignore
      return <RunningPerformancePanel {...mergeSelectedWithSpend(props)} />;
    }
  }
};

export default PerformancePanel;

/**
 * @description Merges spend data from the top level of the
 * displayed Ad's data with the selected zone data.
 */
const mergeSelectedWithSpend = ({
  ad_name,
  spend,
  spend_without,
  prospecting_spend,
  retargeting_spend,
  audience_splits,
  selection
}) => {
  const spendIndex = audience_splits.reduce(
    (acc, { audience, value }) => ({ ...acc, [audience]: value }),
    {}
  );

  const splits = selection.audience_splits.map(({ audience, value }) => ({
    audience,
    value: value === null ? "-" : value,
    spend: spendIndex[audience]
  }));

  return {
    ...selection,
    ad_name,
    spend,
    spend_without,
    prospecting_spend,
    retargeting_spend,

    audience_splits: splits
  };
};

const RunningPerformancePanel = props => {
  const formattedName = getFormattedName(props.type, props.name);
  const derivedProps = { ...props, name: formattedName };
  const headProps = deriveHeaderProps(derivedProps);
  const targetProps = deriveTargetProps(derivedProps);
  const audienceProps = deriveAudienceProps(derivedProps);

  return (
    <React.Fragment>
      <h4>
        <strong>{formattedName} Performance</strong>
      </h4>

      <MetricSection {...headProps} />

      <TargetingSection {...targetProps} />

      {audienceProps && <AudienceSection {...audienceProps} />}
    </React.Fragment>
  );
};

// this kinda sucks
const getFormattedName = (type, name) => {
  switch (type) {
    case "cpa":
    case "cpm":
      return type.toUpperCase();

    default:
      return name;
  }
};

const MetricSection = ({
  name,
  performance_difference,
  ad_name,
  spend,
  spend_without,
  value,
  value_without
}) => (
  <Details
    open
    className="running-details"
    summary="Overall Performance"
    summaryStyle={SUMMARY_STYLE}
  >
    <div>
      <p>
        This ad is doing {adjustDifference(performance_difference)} than average
      </p>

      <ComparisonTable
        name={name}
        with_name={ad_name}
        without_name="Others"
        spend={spend}
        spend_without={spend_without}
        value={value}
        value_without={value_without}
      />
    </div>
  </Details>
);

MetricSection.propTypes = {
  name: RequiredString,
  performance_difference: RequiredString,
  ad_name: RequiredString,
  spend: RequiredString,
  spend_without: RequiredString,
  value: RequiredString,
  value_without: RequiredString
};

const deriveHeaderProps = ({
  name,
  performance_difference,
  ad_name,
  spend,
  spend_without,
  value,
  value_without
}) => ({
  name,
  performance_difference,
  ad_name,
  spend,
  spend_without,
  value,
  value_without
});

const TargetingSection = ({
  name,
  prospecting_spend,
  prospecting_value,
  retargeting_spend,
  retargeting_value
}) => (
  <Details
    open
    className="running-details"
    summary="Performance by Targeting Type"
    summaryStyle={SUMMARY_STYLE}
  >
    <ComparisonTable
      name={name}
      with_name="Prospecting"
      without_name="Retargeting"
      spend={prospecting_spend}
      spend_without={retargeting_spend}
      value={prospecting_value}
      value_without={retargeting_value}
    />
  </Details>
);

TargetingSection.propTypes = {
  name: RequiredString,
  prospecting_spend: RequiredString,
  prospecting_value: RequiredString,
  retargeting_spend: RequiredString,
  retargeting_value: PropTypes.string
};

const deriveTargetProps = ({
  name,
  prospecting_spend,
  prospecting_value,
  retargeting_spend,
  retargeting_value
}) => ({
  name,
  prospecting_spend,
  prospecting_value,
  retargeting_spend,
  retargeting_value
});

const TABLE_STYLE = { height: "200px", overflow: "auto" };

const AudienceSection = ({ name, rows, willOverflow }) => (
  <Details
    open
    className="running-details"
    summary="Performance by Audience"
    summaryStyle={SUMMARY_STYLE}
  >
    <div style={willOverflow ? TABLE_STYLE : null}>
      <table className="table table-bordered table-hover table-striped">
        <thead>
          <tr>
            <th>Audience</th>
            <th>Spend</th>
            <th>{name}</th>
          </tr>
        </thead>

        <tbody>
          {rows.map(({ audience, spend, value }) => (
            <tr key={`${audience}-${value}`}>
              <td>{audience}</td>
              <td>{spend}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </Details>
);

AudienceSection.propTypes = {
  name: RequiredString,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      audience: PropTypes.string,
      spend: PropTypes.string,
      value: PropTypes.string
    })
  )
};

const deriveAudienceProps = ({ name, audience_splits }) => {
  if (!audience_splits || !audience_splits[0]) {
    return null;
  } else {
    return {
      name,
      rows: audience_splits,
      willOverflow: audience_splits.length > 3
    };
  }
};

const ComparisonTable = ({
  name,
  with_name,
  without_name,
  value,
  value_without,
  spend,
  spend_without
}) => (
  <table className="table table-bordered table-hover table-striped">
    <thead>
      <tr>
        <th>Type</th>
        <th>Spend</th>
        <th>{name}</th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td>{with_name}</td>
        <td>{spend}</td>
        <td>{value}</td>
      </tr>
      <tr>
        <td>{without_name}</td>
        <td>{spend_without}</td>
        <td>{value_without}</td>
      </tr>
    </tbody>
  </table>
);

ComparisonTable.propTypes = {
  name: RequiredString,
  with_name: RequiredString,
  without_name: RequiredString,
  value: RequiredString,
  value_without: PropTypes.string,
  spend: RequiredString,
  spend_without: RequiredString
};

const adjustDifference = diff => {
  return diff === null || diff === undefined
    ? "0% better"
    : diff[0] === "-"
    ? `${diff.slice(1)} worse`
    : `${diff} better`;
};

const deriveTrendProps = ({ ad_name, trendline, type }) => {
  const metric_type = formatMetricType(type);

  return {
    withData: trendlineData({
      trendline,
      name: ad_name,
      key: "value",
      metric_type
    }),
    withoutData: trendlineData({
      trendline,
      name: "Others",
      key: "value_without",
      metric_type
    }),
    ad_name,
    id: `trendline-${ad_name}-${type}`,
    className: "trendline"
  };
};

const trendlineData = ({ trendline, name, key, metric_type }) => {
  return {
    name,
    data: trendline.map((data, i) => ({
      x: i,
      y: parseFloat(data[key]),
      date: data.date,
      metric_type
    }))
  };
};
