import React, { useEffect, useState, useMemo } from 'react';
import '../Header/Header.scss';
import './Suggestions.scss';
import { toCurrency } from '../../../../utils/numbers';
import Tooltip from '../../../../components/Tooltip';
import Parser from 'html-react-parser';
import { getSuggestionTooltip } from '../utils';
import { getCategoryLabels } from '../CategoriesList/CategoriesList';
import { DEFAULT, FAVORITES } from './constants';
import FavoriteItem from './Recos/Favorite';
import { FavoriteOutlineIcon } from './icons';
import CollapsibleItem from '../../../../components/CollapsibleItem/CollapsibleItem';
import { useSuggestionsContext } from './contexts';
import SimpleSuggestion from './Recos/Simple';
import GeneralSuggestions from './Recos/GPT/General';
import ExecutionSuggestions from './Recos/GPT/ExecutionStyle';
import PhraseSuggestions from './Recos/GPT/Phrases';
import PerformanceSuggestions from './Recos/GPT/TopBottomPerforming';
import DesignersChoice from './Recos/DesignersChoice';
import EasySuggestions from './Recos/GPT/Easy';
import Copilot from './Recos/GPT/Copilot';
import PfrTable from '../../CreativeCopilot/Output/PfrTable';
import { useCategories } from '../../hooks';
import SettingsModal from './Recos/components/SettingsModal';
import { useCopilotApi } from '../../api';
import { useViewId } from '../../selectors';
import { useReportCardContext } from '../contexts';

const Suggestions = ({
  recsLoading,
  recDepth,
  recsError,
  fixedLabels,
  isAdvanced,
  disabled,
  predictions,
  categories,
  recsErrorDisplay,
  current,
  getNewPhrases,
  newPhrasesData,
  newPhrasesLoading,
  newPhrasesError,
  darwin_direct_enabled,
  isCompetitorView,
  isOrganicView,
  columns,
  handleColumns,
  selectedCategory,
  setSelectedCategory,
}) => {
  const {
    showMenu,
    activeTab,
    bestPerforming,
    isDirectMail,
    isROAS,
    fromCreativeStudio,
    suggestions,
    handleRevisionModal,
    handleQuickRevisionRequest,
    handleDeleteFavorite,
    favoriteSuggestions,
    copilotData,
    copilotLoading,
    showCopilotSettings,
    setShowCopilotSettings,
    fetchCopilot,
    getLabelsById,
  } = useSuggestionsContext();
  const isSimple = recDepth === 1;
  const { quintiles, score } = predictions;
  const [quintilesData, setQuintilesData] = useState(null);
  const [phraseLabels, setPhraseLabels] = useState<any[]>([]);
  const [requested, setRequested] = useState<string[]>([]);
  const [dataByPhrase, setDataByPhrase] = useState<any[]>([]);
  const [lastRequested, setLastRequested] = useState('');
  const requestSent =
    newPhrasesData.length || newPhrasesLoading || newPhrasesError;
  const showDesignersChoice =
    darwin_direct_enabled && !fromCreativeStudio && !bestPerforming;
  const phrasesCategory = categories.find(
    (category) => category.category === 'Phrases'
  );
  const { tableData } = useCategories();
  const categoryTableData = tableData?.find(
    (data) => data.category_name === selectedCategory
  );
  const { category_name, category_data, labels } = categoryTableData || {};
  const { clientPreferences, advertiserDetails, copilotApi } =
    useReportCardContext();
  const {
    data: saveSettingsData,
    error: saveSettingsError,
    loading: saveSettingsLoading,
    request: saveSettingsRequest,
  } = copilotApi.save;
  const viewId = useViewId();
  const [updatedPreferences, setUpdatedPreferences] = useState('None');
  const [updatedDetails, setUpdatedDetails] = useState('None');
  const [refreshCopilot, setRefreshCopilot] = useState(false);

  useEffect(() => {
    if (!refreshCopilot) {
      if (clientPreferences) setUpdatedPreferences(clientPreferences);
      if (advertiserDetails) setUpdatedDetails(advertiserDetails);
    }
  }, [clientPreferences, advertiserDetails]);

  const handleSaveSettings = (refresh) => {
    saveSettingsRequest({
      view_id: viewId,
      client_preferences: updatedPreferences || 'None',
      advertiser_details: updatedDetails || 'None',
    });

    if (refresh) setRefreshCopilot(true);
  };

  useEffect(() => {
    if (saveSettingsData && refreshCopilot) {
      fetchCopilot(updatedPreferences, updatedDetails);
      setRefreshCopilot(false);
      setShowCopilotSettings(false);
    }
  }, [saveSettingsData]);

  useEffect(() => {
    if (phrasesCategory && !requestSent) {
      setPhraseLabels(
        getCategoryLabels(
          current,
          phrasesCategory?.labels?.map((label) => label.id),
          fromCreativeStudio,
          bestPerforming
        )
      );
    }
  }, [phrasesCategory, requestSent]);

  useEffect(() => {
    const noPhrase = {
      phrase: 'none',
      data: null,
      error: false,
      loading: false,
    };

    let labelsArr = [];

    if (phraseLabels.length) {
      const labels = phraseLabels
        .map(({ categoryLabel: { name } }) => name)
        .sort((a, b) => b.split(' ').length - a.split(' ').length);

      labelsArr = labels.map((v) => ({
        phrase: v,
        data: null,
        error: false,
        loading: false,
      }));
    }

    labelsArr = [...labelsArr, noPhrase];
    setDataByPhrase(labelsArr);
  }, [phraseLabels]);

  useEffect(() => {
    if (newPhrasesData.length > 0) {
      const updated = dataByPhrase.map((phrase) => {
        if (phrase.phrase === lastRequested) {
          return {
            ...phrase,
            data: newPhrasesData,
            error: false,
            loading: false,
          };
        }
        return phrase;
      });
      setDataByPhrase(updated);
    } else if (newPhrasesError) {
      const updated = dataByPhrase.map((phrase) => {
        if (phrase.phrase === lastRequested) {
          return { ...phrase, data: null, error: true, loading: false };
        }
        return phrase;
      });
      setDataByPhrase(updated);
    } else if (newPhrasesLoading) {
      const updated = dataByPhrase.map((phrase) => {
        if (phrase.phrase === lastRequested) {
          return { ...phrase, data: null, error: false, loading: true };
        }
        return phrase;
      });
      setDataByPhrase(updated);
    }
  }, [newPhrasesData, newPhrasesError, newPhrasesLoading]);

  const handleGetNewPhrases = (phrase, alternatives) => {
    if (newPhrasesLoading) return;
    getNewPhrases(phrase);
    setLastRequested(phrase);

    if (!requested.includes(phrase)) {
      setRequested((prev) => [phrase, ...prev]);
    }
  };

  useEffect(() => {
    if (quintiles) {
      setQuintilesData(Object.entries(quintiles).map((item) => item[1]));
    }
  }, [quintiles]);

  const QuintilesData = () => {
    return (
      <>
        <p>The CPA ranges for the 5 scores are as follows: </p>
        <div className="d-flex flex-column-reverse">
          {quintilesData && quintilesData.length ? (
            quintilesData.map((q, i) => {
              let { low, high } = q;
              return (
                <p className="quintile">
                  {(i * -1 - 1) * -1}: <span>{toCurrency(low)}</span>{' '}
                  {!!high ? 'to' : 'and'}{' '}
                  <span>{!!high ? `${toCurrency(high)}` : 'higher'}</span>
                </p>
              );
            })
          ) : (
            <p>No data to display.</p>
          )}
        </div>
        {score && <p>{Parser(score)}</p>}
      </>
    );
  };

  const ExcludedLabels = () => {
    if (fixedLabels.length && !recsError && !recsLoading && isAdvanced) {
      return (
        <div className="fixed-labels-list">
          <span>Labels excluded: </span>
          {fixedLabels.map((label) => {
            return <span className="fixed-label">{label}</span>;
          })}
        </div>
      );
    } else {
      return null;
    }
  };

  const PredictedPerformance = () => {
    const text = (
      <div className="prediction-stats">
        <span>
          Predicted Performance
          <i className="fa fa-circle-question ml-2" />
        </span>
      </div>
    );

    if (!disabled) {
      return (
        <Tooltip
          content={<QuintilesData />}
          className="prediction-stats__tooltip"
          placement="left"
          follow
          distance={20}
        >
          {text}
        </Tooltip>
      );
    } else {
      return <>{text}</>;
    }
  };

  return (
    <div className={`suggestions-content ${!showMenu ? 'show' : ''}`}>
      {suggestions.length || darwin_direct_enabled ? (
        <>
          {recsLoading && (
            <div className="bounce-loading">
              <span>Creating suggestions</span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          )}
          <div className="" style={{ maxWidth: '100%' }}>
            {!recsLoading && !isSimple && !recsError ? (
              <>
                <PredictedPerformance />
                <ExcludedLabels />
              </>
            ) : null}
            {recsError && !isSimple && !recsLoading && (
              <div>
                <p>There was an error.</p>
                <p>{recsError.toString()}</p>
              </div>
            )}

            {!recsLoading && (
              <div className="" style={{ maxWidth: '100%' }}>
                <div
                  className={`suggestions-list ${
                    activeTab === DEFAULT ? 'show' : ''
                  }`}
                >
                  {!fromCreativeStudio && !bestPerforming && (
                    <Copilot
                      {...{
                        showCopilotSettings,
                        setShowCopilotSettings,
                        updatedPreferences,
                        setUpdatedPreferences,
                        updatedDetails,
                        setUpdatedDetails,
                        handleSaveSettings,
                        saveSettingsData,
                        saveSettingsError,
                        saveSettingsLoading,
                      }}
                    />
                  )}
                  {/* <ul className="d-flex flex-column">
                    {darwin_direct_enabled && (
                      <>
                        {suggestions && suggestions.length
                          ? sortedSuggestions.map((suggestion, i) => {
                              return (
                                <>
                                  <SimpleSuggestion
                                    {...{
                                      suggestion,
                                      i,
                                      isCompetitorView,
                                      isOrganicView,
                                      getSuggestionTooltip,
                                      recDepth,
                                      darwin_direct_enabled,
                                    }}
                                  />
                                </>
                              );
                            })
                          : null}
                      </>
                    )}
                    {showDesignersChoice && (
                      <>
                        <EasySuggestions />
                        <div className="additional-options">
                          <CollapsibleItem
                            AnchorChild={
                              <div className="additional-options__text">
                                {" "}
                                additional options
                              </div>
                            }
                            ExpandableChild={
                              <>
                                <PerformanceSuggestions {...{ dataByPhrase }} />
                                <PhraseSuggestions
                                  {...{
                                    dataByPhrase,
                                    handleGetNewPhrases,
                                    requested,
                                    requestSent,
                                    newPhrasesLoading,
                                    lastRequested,
                                  }}
                                />
                                <GeneralSuggestions {...{ dataByPhrase }} />
                                <ExecutionSuggestions {...{ dataByPhrase }} />
                                <DesignersChoice
                                  {...{
                                    phraseLabels,
                                    fromCreativeStudio,
                                  }}
                                />
                              </>
                            }
                          />
                        </div>
                      </>
                    )}
                  </ul> */}
                </div>
                {/* <div
                  className={`favorite-suggestions-list ${
                    activeTab === FAVORITES ? "show" : ""
                  }`}
                >
                  <ul className="d-flex flex-column">
                    {favoriteSuggestions.length ? (
                      favoriteSuggestions.map((favorite, i) => {
                        return (
                          <FavoriteItem
                            {...{
                              favorite,
                              i,
                              handleRevisionModal,
                              handleQuickRevisionRequest,
                              handleDeleteFavorite,
                            }}
                          />
                        );
                      })
                    ) : (
                      <div className="favorite-suggestions none">
                        You haven't saved any suggestions yet. Click the{" "}
                        <FavoriteOutlineIcon /> next to a suggestion to save it
                        here for later.
                      </div>
                    )}
                  </ul>
                </div> */}
                {!copilotLoading && activeTab === 'reasons' && (
                  <div
                    className={`copilot-reasons ${
                      activeTab === 'reasons' ? 'show' : ''
                    }`}
                  >
                    <div className={`output__reasons col-6 col-lg-7 col-xl-8`}>
                      <div className="reasons">
                        {copilotData?.recommendation_reasons_array.map(
                          ({
                            category_name,
                            label_name,
                            bold_reason,
                            description,
                          }) => {
                            return (
                              <div className="reason">
                                <div className="title">{bold_reason}</div>
                                <div className="description">{description}</div>
                                <div
                                  className="breakdown"
                                  onClick={() =>
                                    setSelectedCategory(category_name)
                                  }
                                >
                                  See full breakdown:{' '}
                                  <span>{category_name}</span>{' '}
                                  <i className="fa-solid fa-angle-right" />
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                      <div id="pfr-table">
                        {categoryTableData && (
                          <PfrTable
                            {...{
                              columns,
                              handleColumns,
                              setSelectedCategory,
                              category_name,
                              category_data,
                              labels,
                              selectedCategory,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="error-display error">{recsErrorDisplay}</div>
      )}
    </div>
  );
};

export default Suggestions;
