import React from 'react';
import PropTypes from 'prop-types';

import IntersectionVisible from './IntersectionVisible';

class LazyLoader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
  }

  componentWillUnmount() {
    this.handleShow = null;
    this.handleHide = null;
  }

  handleShow() {
    this.setState({
      show: true,
    });
  }

  handleHide() {
    this.setState({
      show: false,
    });
  }

  render() {
    const {
      className, style, children, placeholder, onContextMenu,
    } = this.props;
    const { show } = this.state;

    return (
      <IntersectionVisible
        onShow={this.handleShow}
        className={className}
        style={style}
        onContextMenu={onContextMenu}
      >
        {show ? children : placeholder}
      </IntersectionVisible>
    );
  }
}

LazyLoader.defaultProps = {
  className: '',
  style: {},
  id: null,
  children: null,
};

LazyLoader.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({ color: PropTypes.string }),
  placeholder: PropTypes.node.isRequired,
  children: PropTypes.node,
};

export default LazyLoader;
