import { Options } from './actions';

function strToOptions(s) {
  return { value: s, label: s };
}

function prepOptions({
  client_options,
  view_options,
  contractor_options,
  creative_type_to_contractor_type,
  creative_options,
  status_options,
  recommended_options,
  paid_options,
}) {
  return {
    client_options: client_options.map(({ _id, client_name }) => ({
      label: client_name,
      value: _id,
    })),
    view_options: view_options.map(({ _id, view_name, client_id }) => ({
      label: view_name,
      value: _id,
      client_id,
    })),
    contractor_options: contractor_options.map((option) => ({
      label: option.name,
      value: option,
    })),
    creative_type_to_contractor_type,
    creative_options: creative_options.map(strToOptions),
    status_options: status_options.map(strToOptions),
    recommended_options: recommended_options.map(strToOptions),
    paid_options: paid_options.map(strToOptions),
  };
}

export function options(state = null, action) {
  switch (action.type) {
    case Options.SET:
      return prepOptions(action.data);

    default:
      return state;
  }
}
