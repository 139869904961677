import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useWindowSize } from '../CreativeAnalytics/PerformanceFrequency/hooks';
import { getVisualsPerPage } from './CreativePerformance/utils';
import './CreativePerformance/VisualsWrapper.scss';

export const ModeToggle = ({ mode, setMode }) => {
  return (
    <div className="double-select">
      <button
        className={mode === 'visuals' ? 'is-selected' : ''}
        onClick={() => setMode('visuals')}
      >
        <i className="fa-regular fa-images"></i>
        Visuals
      </button>
      <button
        className={mode === 'table' ? 'is-selected' : ''}
        onClick={() => setMode('table')}
      >
        <i className="fa-solid fa-table-list"></i>
        Table
      </button>
    </div>
  );
};

export const CountSelect = ({ count, setCount }) => {
  const increase = () => {
    setCount(parseInt(count ?? 0) + 1);
  };

  const decrease = () => {
    setCount(parseInt(count ?? 0) - 1);
  };

  return (
    <div className="count-select">
      <button
        className="count-select__btn"
        onClick={decrease}
        disabled={count < 2}
      >
        <i className="fa-solid fa-minus"></i>
      </button>
      <span className="count-select__num">{count}</span>
      <button
        className="count-select__btn"
        onClick={increase}
        disabled={count > 9}
      >
        <i className="fa-solid fa-plus"></i>
      </button>
    </div>
  );
};

export const LoadingSkeleton = () => {
  const { width } = useWindowSize();
  const visualsPerPage = getVisualsPerPage(width);

  return (
    <div className="dna" style={{ padding: '2rem' }}>
      <div className="dna-grid dna-skeleton mt-0 mb-4">
        <Skeleton
          baseColor="#EBF3FA"
          highlightColor="#F6FCFF"
          inline={true}
          count={1}
          height={60}
        />

        <ul className="mb-0 mt-4">
          {[...Array(visualsPerPage)].map((e, i) => (
            <li key={i}>
              <div>
                <Skeleton
                  baseColor="#EBF3FA"
                  highlightColor="#F6FCFF"
                  inline={true}
                  count={1}
                  height={200}
                />
                <Skeleton
                  baseColor="#EBF3FA"
                  highlightColor="#F6FCFF"
                  inline={true}
                  count={1}
                  height={28}
                />
                <Skeleton
                  baseColor="#EBF3FA"
                  highlightColor="#F6FCFF"
                  inline={true}
                  count={1}
                  height={60}
                />
                <Skeleton
                  baseColor="#EBF3FA"
                  highlightColor="#F6FCFF"
                  inline={true}
                  count={1}
                  height={60}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>

      <Skeleton
        baseColor="#EBF3FA"
        highlightColor="#F6FCFF"
        inline={true}
        height={50}
        count={10}
      />
    </div>
  );
};
