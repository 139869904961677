import { batch } from 'react-redux';

import { setError } from '../../Errors/actions';
import { setLoader } from '../../Loaders/actions';

import { MARKETPLACE } from '../actions';
import { hasRoles } from '../../../cookies';

const { logger } = window;

export const ASSETS = `${MARKETPLACE}.assets`;

export const Assets = {
  SET: `[${ASSETS}] SET`,
  SET_CACHED_VIEW: `[${ASSETS}] SET_CACHED_VIEW`,
  ADD_ONE: `[${ASSETS}] ADD ONE`,
  UPDATE_ONE: `[${ASSETS}] UPDATE ONE`,
};

const filterVersions = (data = [], isInternalUser) => {
  if (isInternalUser) return data;

  return data.map((asset) => {
    return {
      ...asset,
      versions: asset.versions.filter((version) => version.approved),
    };
  });
};

export function setMarketplaceAssets({ data, view_id = '' }) {
  return {
    type: Assets.SET,
    data: filterVersions(data, hasRoles(['internal', 'contractor'])),
    meta: { entity: ASSETS, normalizeId: 'filename', view_id },
  };
}

export function setCachedView(view_id) {
  return {
    type: Assets.SET_CACHED_VIEW,
    data: { view_id },
  };
}

export function assetsAddOne(filename, asset) {
  return {
    type: Assets.ADD_ONE,
    data: { filename, asset },
  };
}

export function assetsUpdateOne(filename, update) {
  return {
    type: Assets.UPDATE_ONE,
    data: { filename, update },
  };
}

const isStudio = window.location.pathname.includes('studio');

export function fetchMarketplaceAssets({ project_id, view_id }) {
  return async (dispatch) => {
    batch(() => {
      dispatch(setLoader(ASSETS, true));
      dispatch(setError(ASSETS, false));
    });

    try {
      const res = await fetch(
        ['api', 'marketplace', 'assets', project_id, 'get'].join('/'),
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'Cache-control': 'no-cache',
          },
          body: JSON.stringify({
            view_id,
          }),
        }
      );
      const json = await res.json();

      if (res.status !== 200) {
        const err = new Error('Invalid response');
        logger.error(err);

        return batch(() => {
          dispatch(setError(ASSETS, err));
          dispatch(setLoader(ASSETS, false));
        });
      }
      return batch(() => {
        dispatch(setMarketplaceAssets(json));
        dispatch(setLoader(ASSETS, false));
      });
    } catch (e) {
      logger.error(e);

      return batch(() => {
        dispatch(setError(ASSETS, e));
        dispatch(setLoader(ASSETS, false));
      });
    }
  };
}

export function fetchMarketplaceAssetsByView({ view_id }) {
  return async (dispatch, getState) => {
    const {
      Marketplace: { assets },
    } = getState();
    const exists = assets.ids.length > 0;
    const viewHasChanged = view_id !== assets.cachedView;

    if (exists && isStudio && !viewHasChanged)
      return console.log('using cached assets');

    batch(() => {
      dispatch(setLoader(ASSETS, true));
      dispatch(setError(ASSETS, false));
    });

    try {
      const res = await fetch(
        ['api', 'marketplace', 'assets', 'view', view_id].join('/'),
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Cache-control': 'no-cache',
          },
        }
      );
      const json = await res.json();

      if (res.status !== 200) {
        const err = new Error('Invalid response');

        logger.error(err);

        return batch(() => {
          dispatch(setError(ASSETS, err));
          dispatch(setLoader(ASSETS, false));
        });
      }
      return batch(() => {
        dispatch(setMarketplaceAssets(json, view_id));
        dispatch(setCachedView(view_id));
        dispatch(setLoader(ASSETS, false));
      });
    } catch (e) {
      logger.error(e);

      return batch(() => {
        dispatch(setError(ASSETS, e));
        dispatch(setLoader(ASSETS, false));
      });
    }
  };
}

export function fetchClientAssets({ account_id }) {
  return async (dispatch) => {
    batch(() => {
      dispatch(setLoader(ASSETS, true));
      dispatch(setError(ASSETS, false));
    });

    try {
      const res = await fetch(
        ['api', 'marketplace', 'assets', 'client-assets', account_id].join('/'),
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Cache-control': 'no-cache',
          },
        }
      );
      const json = await res.json();

      if (res.status !== 200) {
        const err = new Error('Invalid response');
        logger.error(err);

        return batch(() => {
          dispatch(setError(ASSETS, err));
          dispatch(setLoader(ASSETS, false));
        });
      }
      return batch(() => {
        dispatch(setMarketplaceAssets(json));
        dispatch(setLoader(ASSETS, false));
      });
    } catch (e) {
      logger.error(e);

      return batch(() => {
        dispatch(setError(ASSETS, e));
        dispatch(setLoader(ASSETS, false));
      });
    }
  };
}
