import React from 'react';

import './FrameSelect.scss';
import { ErrorBoundary } from '../../../../components/Helpers/Error';

type FrameObject = {
  frame_num: string;
  labels: any;
  url: string;
};

const FrameSelect = ({
  framesList,
  selectedFrame,
  setSelectedFrame,
}: {
  framesList: FrameObject[];
  selectedFrame: FrameObject;
  setSelectedFrame: any;
}) => {
  return (
    <ErrorBoundary fallback={<p>Error rendering frame select.</p>}>
      <ul className="frame-select">
        <div className="frame-select__divider"></div>
        {framesList.map(({ frame_num, labels, url }, index) => {
          const isSelected = frame_num === selectedFrame.frame_num;

          const timestamp =
            frame_num === 'thumbnail' ? 'Thumb' : `${frame_num}s`;

          return (
            <li
              className={`frame-select__frame ${
                isSelected ? 'frame-select__frame--selected' : ''
              }`}
              onClick={() =>
                setSelectedFrame({ frame_num, labels, url, index })
              }
            >
              <p className="frame-select__timestamp">{timestamp}</p>
              <img src={url} alt="" />
            </li>
          );
        })}
      </ul>
    </ErrorBoundary>
  );
};

export default FrameSelect;
