import { batch } from 'react-redux';
import { ADSETS, ADSETS_FETCH, setAdsets } from './actions';
import { API_SUCCESS, apiRequest, API_FAILURE } from '../Api/actions';
import { setLoader } from '../Loaders/actions';
import { setError } from '../Errors/actions';
import { getAppSearchParams, getViewId } from '../utils';

const middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case ADSETS_FETCH:
        const {
          ViewSettings: { campaigns, account_id, isDirectMail },
        } = getState();

        const params = (new URL(document.location)).searchParams;
        const selectedPeriod = !!params.get('period1') ? "one" : null;
        const { start_date, end_date, selectedAudience, spendThreshold } =
          getAppSearchParams(isDirectMail, selectedPeriod);

        batch(() => {
          dispatch(setError(ADSETS, false));
          dispatch(setLoader(ADSETS, true));
          dispatch(
            apiRequest({
              body: {
                start_date,
                end_date,
                audience: selectedAudience,
                campaigns,
                account_id,
                spendThreshold,
              },
              url: ['api', getViewId(), 'adsets-scatter'].join('/'),
              entity: ADSETS,
              method: 'POST',
            })
          );
        });

        break;

      case `[${ADSETS}] ${API_SUCCESS}`:
        const { response } = action;

        batch(() => {
          dispatch(setAdsets({ adsets: response }));
          dispatch(setLoader(ADSETS, false));
        });

        break;

      case `[${ADSETS}] ${API_FAILURE}`:
        const { error } = action;

        batch(() => {
          dispatch(setError(ADSETS, error));
          dispatch(setLoader(ADSETS, false));
        });

        break;

      default:
        return result;
    }
  };

export default middleware;
