import React, { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';

import VisualsList from '../../VisualsList';
import { useCustomCategoriesEditor } from '../../hooks';

import {
  useDarwinClientId,
  usePlatform,
  useViewId,
  useUserId,
} from '../../selectors';
import {
  makePostPayload,
  makeDeletePayload,
  makeUpdateOnePayload,
} from '../../payloads';

import { useCustomCategoriesApi } from '../../api';

import Sidebar from './Sidebar';
import { COMPLIANCE_LABELS, MULTIPLE_LABELS } from '../../constants';
import {
  useCreativeAnalyticsGet,
  useCreativeAnalyticsSet,
} from '../../../contexts';
import {
  CREATIVE_ANALYTICS,
  EDIT_CCC,
  TRAIN_NEW_CCC,
} from '../../../../../store/UserActivity/constants';
import { useDispatch } from 'react-redux';
import { trackEvent } from '../../../../../store/UserActivity/actions';
import { MOCK_VISUALS } from '../mock-data';
import {
  getAssetObjWhenLockingInPredictions,
  removeConfidenceFromLabels,
} from './utils';

type CustomCategoryEditorProps = {
  prev: () => void;
  input: any;
  existing: any;
  fetchPerformanceData: any;
  visualsData: any;
  isSingle: boolean;
  visual: string;
  category: string;
  allVisuals: any;
  annotatedVisuals: any;
  setAnnotatedVisuals: any;
  predictedVisuals: any;
  setPredictedVisuals: any;
  initialSelected: any;
  labelsHaveChanged: boolean;
  visualModeChanged: boolean;
};

const CustomCategoryEditor = ({
  prev,
  input,
  existing,
  fetchPerformanceData,
  visualsData,
  isSingle,
  visual,
  category,
  annotatedVisuals,
  setAnnotatedVisuals,
  predictedVisuals,
  setPredictedVisuals,
  initialSelected,
  labelsHaveChanged,
  visualModeChanged,
  category_id,
  singleToFull,
}: CustomCategoryEditorProps) => {
  const view_id = useViewId();
  const { asyncApi } = useCreativeAnalyticsGet();
  const { setEditCategory, setEditCategoryVisual } = useCreativeAnalyticsSet();
  const [changed, setChanged] = useState(false);
  const [changedLabels, setChangedLabels] = useState(new Set());
  const labelOptions = input.compliance ? COMPLIANCE_LABELS : input.labels;
  const [visuals, labelCounter, isValid] = useCustomCategoriesEditor(
    input,
    annotatedVisuals,
    predictedVisuals,
    visualsData,
    labelOptions
  );
  const [selectedLabel, setSelectedLabel] = useState('');
  const { reportCardSettings } = useCreativeAnalyticsGet();
  const darwin_client_id = useDarwinClientId();
  const platform = usePlatform();
  const user_id = useUserId();
  const api = useCustomCategoriesApi();
  const [loadingMsg, setLoadingMsg] = useState(null);
  const [tempMsg, setTempMsg] = useState(null);
  const isLoading = api.delete.loading;
  const dispatch = useDispatch();

  const allowLockIn =
    Object.keys(predictedVisuals).length > 0 && input.type === MULTIPLE_LABELS;

  const trackSubmit = () => {
    let event = TRAIN_NEW_CCC;

    if (existing) {
      event = EDIT_CCC;
    }

    dispatch(trackEvent(CREATIVE_ANALYTICS, event));
  };

  const handleTrain = () => {
    const postPayload = makePostPayload({
      input,
      visuals,
      annotatedVisuals,
      darwin_client_id,
      platform,
      view_id,
      changedLabels,
      labelOptions,
      service_type: 'annotations',
      user_id,
      category_id,
    });

    asyncApi.createAsyncProcess({
      category: input.name,
      isSingle,
      postPayload,
      shouldTrain: true,
      changedLabels,
      visual_mode: input.visual_mode,
      category_id,
    });

    trackSubmit();
    setEditCategory(null);
  };

  const handleSave = () => {
    const postPayload = makePostPayload({
      input,
      visuals,
      annotatedVisuals,
      darwin_client_id,
      platform,
      view_id,
      changedLabels,
      labelOptions,
      service_type: 'annotations',
      user_id,
      category_id,
    });

    asyncApi.createAsyncProcess({
      category: input.name,
      isSingle,
      postPayload,
      shouldTrain: false,
      changedLabels,
      isValid,
      category_id,
    });

    trackSubmit();
    setEditCategory(null);
  };

  const handleSaveOne = () => {
    const postPayload = makeUpdateOnePayload({
      input,
      visual,
      category,
      custom_category: true,
      darwin_client_id,
      platform,
      view_id,
      labelOptions,
      annotatedVisuals,
      service_type: 'annotations',
      user_id: user_id,
      category_id,
    });

    asyncApi.createAsyncProcess({
      category: input.name,
      isSingle,
      postPayload,
      shouldTrain: false,
      changedLabels,
      visual_mode: input.visual_mode,
      category_id,
    });
    setEditCategoryVisual(null);
  };

  const handleTrainOne = () => {
    const postPayload = makeUpdateOnePayload({
      input,
      visual,
      category,
      custom_category: true,
      darwin_client_id,
      platform,
      view_id,
      labelOptions,
      annotatedVisuals,
      service_type: 'predictions',
      user_id: user_id,
      category_id,
    });

    asyncApi.createAsyncProcess({
      category: input.name,
      isSingle,
      postPayload,
      shouldTrain: true,
      changedLabels,
      visual_mode: input.visual_mode,
      category_id,
    });
    setEditCategoryVisual(null);
  };

  const handleDelete = () => {
    const deletePayload = makeDeletePayload(
      existing.category,
      darwin_client_id,
      platform,
      category_id
    );
    api.delete.request(deletePayload);
  };

  const handleSelect = (
    categoryType,
    label,
    visual_hash,
    visual,
    selectedFrame
  ) => {
    const frameIndex = selectedFrame.index || 0;

    setChangedLabels((prev) => new Set(prev).add(label));

    const annotated = annotatedVisuals[visual_hash];

    if (
      annotated &&
      annotated.asset[frameIndex] &&
      label === annotated.asset[frameIndex].labels
    ) {
      // unannotating
      setAnnotatedVisuals((prev) => {
        let update = cloneDeep(prev);

        delete update[visual_hash];

        return update;
      });
    } else {
      setAnnotatedVisuals((prev) => {
        const current = prev[visual_hash] || visual;

        const updatedVisual = {
          ...current,
          asset: current.asset.map((asset, i) =>
            frameIndex === i
              ? {
                  ...asset,
                  labels: label,
                }
              : asset
          ),
        };

        return {
          ...prev,
          [visual_hash]: updatedVisual,
        };
      });
    }
    setChanged(true);
  };

  const getLabels = (current, selectedFrame) => {
    let result = {};
    const labels = current?.asset?.[selectedFrame.index]?.labels;

    if (!labels) return result;

    Object.entries(labels).forEach(([key, l]) => {
      result[key] = l || {
        status: 'no',
        confidence: null,
      };
    });

    return result;
  };

  const applySelectionToLabels = (
    current,
    selectedFrame,
    selection,
    labelOptions
  ) => {
    let result = {};
    let labels = {};

    labelOptions.forEach((label) => {
      const maybe = current?.asset?.[selectedFrame.index]?.labels?.[label];

      labels[label] = maybe || {
        status: selection,
        confidence: null,
      };
    });

    if (!labels) return result;

    Object.entries(labels).forEach(([key, l]) => {
      result[key] = {
        status: selection,
        confidence: l.confidence || null,
      };
    });

    return result;
  };

  // for multiple labels category
  const handleMultipleSelect = (
    label,
    selection,
    visual_hash,
    visual,
    selectedFrame
  ) => {
    const frameIndex = selectedFrame.index || 0;

    setChangedLabels((prev) => new Set(prev).add(label));

    setAnnotatedVisuals((prev) => {
      const current = prev[visual_hash]?.asset ? prev[visual_hash] : visual;
      const labels = getLabels(current, selectedFrame);

      const updatedVisual = {
        ...current,
        asset: current.asset.map((asset, i) => {
          return frameIndex === i
            ? {
                ...asset,
                labels: {
                  ...labels,
                  [label]: {
                    status: selection,
                    confidence: null,
                  },
                },
              }
            : asset;
        }),
      };

      return {
        ...prev,
        [visual_hash]: updatedVisual,
      };
    });

    const predictedVisual = predictedVisuals[visual_hash];

    if (predictedVisual) {
      replacePredictionsWithAnnotations({
        visual_hash,
        predictedVisual: predictedVisuals[visual_hash],
        visual,
      });
    }

    setChanged(true);
  };

  const handleBulkSelect = (visual_hash, selection, visual, selectedFrame) => {
    const frameIndex = selectedFrame.index || 0;

    setChangedLabels(new Set(labelOptions));

    setAnnotatedVisuals((prev) => {
      const current = prev[visual_hash] || visual;
      const labels = applySelectionToLabels(
        current,
        selectedFrame,
        selection,
        labelOptions
      );

      const updatedVisual = {
        ...current,
        asset: current.asset.map((asset, i) => {
          return frameIndex === i
            ? {
                ...asset,
                labels,
              }
            : asset;
        }),
      };

      return {
        ...prev,
        [visual_hash]: updatedVisual,
      };
    });

    setChanged(true);
  };

  const replacePredictionsWithAnnotations = ({
    visual_hash,
    predictedVisual,
    visual,
  }) => {
    setPredictedVisuals((prev) => {
      let update = cloneDeep(prev);

      delete update[visual_hash];

      return update;
    });

    setChangedLabels(new Set(labelOptions));

    setAnnotatedVisuals((prev) => {
      const currentAnnotatedV = prev[visual_hash] ? prev[visual_hash] : visual;

      const asset = getAssetObjWhenLockingInPredictions(
        currentAnnotatedV,
        predictedVisual
      );

      if (!asset) return prev;

      const updatedVisual = {
        type: predictedVisual?.type ?? visual?.type,
        spend: visual?.spend ?? currentAnnotatedV?.spend ?? null,
        asset: removeConfidenceFromLabels(asset),
      };

      return {
        ...prev,
        [visual_hash]: updatedVisual,
      };
    });

    setChanged(true);
  };

  const lockInAll = () => {
    Object.entries(predictedVisuals).forEach(([k, v]) => {
      replacePredictionsWithAnnotations({
        visual_hash: k,
        predictedVisual: v,
        visual: visuals[k],
      });
    });
  };

  useEffect(() => {
    if (api.delete.data) {
      reportCardSettings.deleteCategoryFromSettings(api.delete.data);
      setLoadingMsg(null);
      setEditCategory(null);
      setEditCategoryVisual(null);
      fetchPerformanceData();
    }
  }, [api.delete.data]);

  useEffect(() => {
    if (loadingMsg) {
      setTempMsg(null);
    }
  }, [loadingMsg]);

  useEffect(() => {
    if (api.delete.loading) {
      setLoadingMsg('Deleting custom category...');
    }
  }, [api.delete.loading]);

  let Render = (
    <div className="d-flex h-100">
      <Sidebar
        prev={prev}
        input={input}
        labelCounter={labelCounter}
        handleSave={handleSave}
        handleTrain={handleTrain}
        handleTrainOne={handleTrainOne}
        handleSaveOne={handleSaveOne}
        handleDelete={handleDelete}
        tempMsg={tempMsg}
        loadingMsg={loadingMsg}
        isLoading={isLoading}
        isValid={isValid}
        existing={existing}
        changed={changed}
        isSingle={isSingle}
        visualModeChanged={visualModeChanged}
        selectedLabel={selectedLabel}
        setSelectedLabel={setSelectedLabel}
        singleToFull={singleToFull}
        lockInAll={lockInAll}
        predictedVisuals={predictedVisuals}
        allowLockIn={allowLockIn}
      />
      <VisualsList
        visuals={visuals}
        labelOptions={labelOptions}
        categoryType={input.type}
        annotatedVisuals={annotatedVisuals}
        predictedVisuals={predictedVisuals}
        handleSelect={handleSelect}
        handleMultipleSelect={handleMultipleSelect}
        selectedLabel={selectedLabel}
        handleBulkSelect={handleBulkSelect}
        replacePredictionsWithAnnotations={replacePredictionsWithAnnotations}
        allowLockIn={allowLockIn}
      />
    </div>
  );

  return Render;
};

export default CustomCategoryEditor;
