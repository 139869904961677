import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useReportBuilderGroupBy } from '../hooks/useGroupBy';

const Context = createContext(null);

export const GroupByProvider = ({ visual_id_data = [], children }) => {
  const [reportBuilderGroupBy, setReportBuilderGroupBy] =
    useReportBuilderGroupBy();

  return (
    <Context.Provider
      value={{
        visual_id_data,
        reportBuilderGroupBy,
        setReportBuilderGroupBy,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default GroupByProvider;

export function useGroupByContext() {
  return useContext(Context);
}
