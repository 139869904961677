import React, { useState, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import { toDarwinDate } from '../../../utils/darwin-dates';
import { useDispatch } from 'react-redux';
import {
  setMetricsData,
  setPerformanceData,
} from '../../../store/CreativeAnalytics/creativeAnalyticsSlice';

import { useFetchPFRData } from './useFetchPFRData';
import { useDeepCompare } from '../../../hooks/useDeepCompare';
import { useSelectedLabels } from './useSelectedLabels';
import { trackEvent } from '../../../store/UserActivity/actions';
import { HARD_REFRESH } from '../../../store/UserActivity/constants';
import { GENERAL_LOADING } from '../ImpactReport/constants';
import { groupByAdName } from '../utils/groupByAdName/groupByAdName';
import useTrackDeps from '../../../hooks/useTrackDeps';
import { CUSTOM_SCORING_METRICS } from '../PerformanceFrequency/constants';
import { useNotifications } from '../../../components/Notification/useNotifications';

type PerformanceDataProps = {
  view_id: string;
  account_id?: string;
  period1Start: string;
  period1End: string;
  selectedUdcLabels?: [];
};

export const usePerformanceData = ({
  performanceDataProps,
  setParams,
}: PerformanceDataProps) => {
  const {
    view_id,
    account_id,
    period1Start,
    period1End,
    period2Start,
    period2End,
    getUdcApi,
    keywordsApi,
    getDerivedLabelsApi,
    getAdNameLabelsApi,
    showActiveOnly,
    spendThreshold,
    groupByAdName: shouldGroupByAdName,
    setGroupByAdName,
    customIntegratedMetrics,
    additional_metrics,
    labelsParam,
    udc_labels,
    selectedAudience,
    refresh,
    minResults,
    primary_metric,
    customScoringData,
  } = performanceDataProps;
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const { add: addNotification } = useNotifications();

  const { data, metricsData, loading, error, request, retrievedDate } =
    useFetchPFRData({
      start_date: period1Start,
      end_date: period1End,
      autoFetch: false,
      active_only: showActiveOnly,
      selectedUdcLabels: udc_labels,
    });

  useEffect(() => {
    if (metricsData) {
      dispatch(setMetricsData(metricsData));
    }
  }, [metricsData]);

  const fetchPerformanceData = (manualRefresh = false) => {
    request(manualRefresh || refresh);
    getUdcApi.request({ account_id });
    keywordsApi.request({
      account_id,
      start_date: period1Start,
      end_date: period1End,
    });
    getAdNameLabelsApi.request(account_id);
    getDerivedLabelsApi.get.request(account_id);
  };

  const prevDeps = useRef(null);

  useEffect(() => {
    if (
      !_.isEqual(prevDeps.current, {
        view_id,
        selectedAudience,
        period1Start,
        period1End,
        udc_labels,
        refresh,
        showActiveOnly,
      })
    ) {
      fetchPerformanceData();
      prevDeps.current = {
        view_id,
        selectedAudience,
        period1Start,
        period1End,
        udc_labels,
        refresh,
        showActiveOnly,
      };
    }
  }, [
    view_id,
    selectedAudience,
    period1Start,
    period1End,
    udc_labels,
    refresh,
    showActiveOnly,
  ]);

  useEffect(() => {
    if (data?.length > 0) {
      dispatch(setPerformanceData(data));
    }
  }, [data]);

  useEffect(() => {
    if (loading) {
      setShowLoading(true);
    }

    if (!loading) {
      setShowLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    if (retrievedDate === toDarwinDate(new Date())) {
      dispatch(trackEvent(GENERAL_LOADING, HARD_REFRESH));
    }
  }, [retrievedDate]);

  const [selectedLabels, visualIdsFromSelectedLabels] = useSelectedLabels(
    labelsParam,
    udc_labels,
    data,
    selectedAudience
  );

  const clientMetrics = useMemo(() => {
    if (data?.length) {
      let metrics = data[0].client_down_funnel_events ?? [];

      if (customScoringData) {
        metrics = [...metrics, ...CUSTOM_SCORING_METRICS];
      }
      return metrics;
    }

    return [];
  }, [data]);

  const { updatedLabels: updatedData, updatedMetricsData } = useMemo(() => {
    try {
      return groupByAdName(
        data,
        shouldGroupByAdName,
        { customIntegratedMetrics, clientMetrics, additional_metrics },
        {
          spendThreshold,
          visualIdsFromSelectedLabels,
          metricsData,
          minResults,
          primary_metric,
          addNotification,
          turnOffGrouping: () => setGroupByAdName(false),
        }
      );
    } catch (e) {
      console.log(e);
      return {
        updatedLabels: [],
        updatedMetricsData: [],
      };
    }
  }, [
    data,
    shouldGroupByAdName,
    customIntegratedMetrics,
    clientMetrics,
    additional_metrics,
    spendThreshold,
    visualIdsFromSelectedLabels,
    metricsData,
  ]);

  const memoizedData = useDeepCompare(updatedData);
  const memoizedMetricsData = useDeepCompare(updatedMetricsData);

  return {
    data: memoizedData,
    metricsData: memoizedMetricsData,
    originalMetricsData: metricsData,
    loading,
    error,
    fetchPerformanceData,
    showLoading,
    selectedLabels,
    visualIdsFromSelectedLabels,
    retrievedDate,
    clientMetrics,
  };
};
