import { useReducer } from 'react';
import {
  getAssetObjWhenLockingInPredictions,
  removeConfidenceFromLabels,
  removeConfidenceFromLabelsME,
} from '../Custom/AIDriven/utils';
import { current } from '@reduxjs/toolkit';

export type CustomCategoryStateType = {
  annotatedVisuals: Map<string, {}>;
  predictedVisuals: Map<string, {}>;
};

type DisplayType = 'ccc' | 'phrase-builder';

export type QaStateType = {
  display: DisplayType;
  currentVisualIndex: number;
  categories: CustomCategoryStateType[];
  changedLabels: [];
  selectedFrameIndex: number;
  updates: { visual_hash: string; category_id: string }[];
};

export const INITIAL_QA_STATE = {
  display: 'ccc',
  currentVisualIndex: 0,
  categories: [],
  changedLabels: new Set([]),
  selectedFrameIndex: 0,
  changedCategoryIds: new Set([]),
  updates: [],
};
export const SET_DATA = 'SET_DATA';
export const SET_DISPLAY = 'SET_DISPLAY';
export const SELECT_FRAME = 'SELECT_FRAME';
export const SET_CURRENT_VISUAL_INDEX = 'SET_CURRENT_VISUAL_INDEX';
export const SET_ANNOTATED_VISUALS = 'SET_ANNOTATED_VISUALS';
export const SET_PREDICTED_VISUALS = 'SET_PREDICTED_VISUALS';
export const ANNOTATE_ME_LABEL = 'ANNOTATE_ME_LABEL'; //mutually exclusive category
export const ANNOTATE_ML_LABEL = 'ANNOTATE_ML_LABEL'; //multiple labels category
export const RESET = 'RESET';

export type QaAction =
  | { type: 'SET_DATA'; annotatedVisuals: {}; predictedVisuals: {} }
  | {
      type: 'ANNOTATE_ME_LABEL';
      label: string;
      visual_hash: string;
      visual: {};
      selectedFrame: {};
    };

const setCategoriesData = (data, prevState) => {
  return data.map((category) => {
    try {
      return {
        category_id: category.category_id,
        category_name: category.category_name,
        mutually_exclusive: category.mutually_exclusive,
        labels: Object.keys(category.output_label_info ?? {}),
        annotatedVisuals: new Map(
          Object.entries(category.annotated_visuals ?? {})
        ),
        predictedVisuals: new Map(
          Object.entries(category.predicted_visuals ?? {})
        ),
      };
    } catch (e) {
      console.log('There was an error setting the category data:');
      console.log(e);
      console.log(data);
    }
  });
};

export const qaReducer = (state, action) => {
  switch (action.type) {
    case SET_DISPLAY:
      return {
        ...state,
        display: action.display,
      };
    case SET_DATA:
      return {
        ...state,
        categories: setCategoriesData(action.data, state),
      };
    case SET_CURRENT_VISUAL_INDEX:
      return {
        ...state,
        currentVisualIndex: action.currentVisualIndex,
        selectedFrameIndex: 0,
      };
    case SELECT_FRAME:
      return {
        ...state,
        selectedFrameIndex: action.frameIndex,
      };
    case ANNOTATE_ME_LABEL:
      const { label, visual_hash, visual, selectedFrame, category_id } = action;

      const newObj = { visual_hash, category_id };

      const meUpdates = state.updates.some((obj) => {
        return (
          obj.visual_hash === visual_hash && obj.category_id === category_id
        );
      })
        ? state.updates
        : [...state.updates, { visual_hash, category_id }];

      return {
        ...state,
        changedLabels: new Set([...state.changedLabels, action.label]),
        changedCategoryIds: new Set([...state.changedCategoryIds, category_id]),
        categories: annotateMutuallyExclusiveLabel({
          existingCategories: state.categories,
          category_id,
          annotatedVisuals: state.annotatedVisuals,
          label,
          visual_hash,
          visual,
          selectedFrame,
        }),
        updates: meUpdates,
      };
    case ANNOTATE_ML_LABEL:
      const mlUpdates = state.updates.some((obj) => {
        return (
          obj.visual_hash === action.visual_hash &&
          obj.category_id === action.category_id
        );
      })
        ? state.updates
        : [
            ...state.updates,
            {
              visual_hash: action.visual_hash,
              category_id: action.category_id,
            },
          ];

      return {
        ...state,
        changedLabels: new Set([...state.changedLabels, action.label]),
        changedCategoryIds: new Set([
          ...state.changedCategoryIds,
          action.category_id,
        ]),
        categories: annotateMultipleLabels({
          existingCategories: state.categories,
          category_id: action.category_id,
          label: action.label,
          selection: action.selection,
          visual_hash: action.visual_hash,
          visual: action.visual,
          selectedFrame: action.selectedFrame,
        }),
        updates: mlUpdates,
      };
    case RESET:
      return {
        ...state,
        changedLabels: new Set([]),
        changedCategoryIds: new Set([]),
        updates: [],
      };
  }
};

export const useQaState = () => {
  const [state, dispatch] = useReducer(qaReducer, INITIAL_QA_STATE);

  return [state, dispatch];
};

const annotateMutuallyExclusiveLabel = ({
  existingCategories,
  category_id,
  categoryType,
  label,
  visual_hash,
  visual,
  selectedFrame,
}) => {
  const existingCategory = existingCategories.find(
    (x) => x.category_id === category_id
  );

  if (!existingCategory) {
    console.log('Error annotating.');
    console.log({
      existingCategories,
      category_id,
      categoryType,
      label,
      visual_hash,
      visual,
      selectedFrame,
    });
  }

  const { annotatedVisuals, predictedVisuals } = existingCategory;

  const frameIndex = selectedFrame?.index || 0;

  const update: Map<string, {}> = new Map(annotatedVisuals);
  const updatedPredictedVisuals: Map<string, {}> = new Map(predictedVisuals);

  const annotatedMatch = update.get(visual_hash);
  const predictedMatch = updatedPredictedVisuals.get(visual_hash);

  if (
    annotatedMatch?.asset?.[frameIndex] &&
    label === annotatedMatch.asset[frameIndex].labels
  ) {
    delete update[visual_hash];
  } else {
    const current = annotatedMatch || visual;

    const assetObj =
      !!predictedMatch && !!annotatedMatch
        ? getAssetObjWhenLockingInPredictions(annotatedMatch, predictedMatch)
        : current.asset;

    const updatedVisual = {
      ...current,
      asset: removeConfidenceFromLabelsME(
        assetObj.map((item, i) => {
          return frameIndex === i
            ? {
                ...item,
                labels: label,
              }
            : item;
        })
      ),
    };

    update.set(visual_hash, updatedVisual);
    updatedPredictedVisuals.delete(visual_hash);
  }

  return existingCategories.map((category) => {
    if (category.category_id === category_id) {
      return {
        ...category,
        annotatedVisuals: update,
        predictedVisuals: updatedPredictedVisuals,
        updatedPredictedVisuals,
      };
    }

    return category;
  });
};

const getLabels = (asset, selectedFrame) => {
  let result = {};
  const labels = asset?.[selectedFrame.index]?.labels;

  if (!labels) return result;

  Object.entries(labels).forEach(([key, l]) => {
    result[key] = l || {
      status: 'no',
      confidence: null,
    };
  });

  return result;
};

const annotateMultipleLabels = ({
  existingCategories,
  category_id,
  label,
  selection,
  visual_hash,
  visual,
  selectedFrame,
}) => {
  const existingCategory = existingCategories.find(
    (x) => x.category_id === category_id
  );

  if (!existingCategory) {
    console.log('Error annotating.');
    console.log({
      existingCategories,
      category_id,
      label,
      visual_hash,
      visual,
      selectedFrame,
    });
  }

  const { annotatedVisuals, predictedVisuals } = existingCategory;

  const frameIndex = selectedFrame?.index || 0;

  const update: Map<string, {}> = new Map(annotatedVisuals);
  const updatedPredictedVisuals: Map<string, {}> = new Map(predictedVisuals);
  const annotatedMatch = update.get(visual_hash);
  const predictedMatch = updatedPredictedVisuals.get(visual_hash);

  const current =
    Array.isArray(annotatedMatch?.asset) && annotatedMatch?.asset?.length
      ? annotatedMatch
      : visual;
  const asset = !!predictedMatch
    ? getAssetObjWhenLockingInPredictions(annotatedMatch, predictedMatch)
    : current.asset;
  const labels = getLabels(asset, selectedFrame);

  const updatedVisual = {
    ...current,
    asset: removeConfidenceFromLabels(
      asset.map((asset, i) => {
        return frameIndex === i
          ? {
              ...asset,
              labels: {
                ...labels,
                [label]: {
                  status: selection,
                  confidence: null,
                },
              },
            }
          : asset;
      })
    ),
  };

  update.set(visual_hash, updatedVisual);
  updatedPredictedVisuals.delete(visual_hash);

  return existingCategories.map((category) => {
    if (category.category_id === category_id) {
      return {
        ...category,
        annotatedVisuals: update,
        predictedVisuals: updatedPredictedVisuals,
      };
    }

    return category;
  });
};
