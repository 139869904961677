import { HeatmapSettings } from './types';

export const UPDATE_X_CATEGORY = 'UPDATE_X_CATEGORY';
export const UPDATE_Y_CATEGORY = 'UPDATE_Y_CATEGORY';
export const TOGGLE_METRIC_TO_DISPLAY = 'TOGGLE_METRIC_TO_DISPLAY';
export const UPDATE_ORGANIZE_AND_COLOR_BY = 'UPDATE_ORGANIZE_AND_COLOR_BY';

export const INITIAL_STATE: HeatmapSettings = {
  xCategory: null,
  yCategory: null,
  metricsToDisplay: {
    numOfCreatives: false,
    primaryMetric: true,
    spend: false,
  },
  organizeAndColor: 'primaryMetric',
};

export type HeatmapAction =
  | {
      type: 'UPDATE_X_CATEGORY';
      category: string;
    }
  | { type: 'UPDATE_Y_CATEGORY'; category: string }
  | {
      type: 'TOGGLE_METRIC_TO_DISPLAY';
      metric: 'numOfCreatives' | 'primaryMetric' | 'spend';
    }
  | {
      type: 'UPDATE_ORGANIZE_AND_COLOR_BY';
      metric: 'numOfCreatives' | 'primaryMetric' | 'spend';
    };

export const heatmapReducer = (
  state: HeatmapSettings,
  action: HeatmapAction
): HeatmapSettings => {
  switch (action.type) {
    case UPDATE_X_CATEGORY:
      return {
        ...state,
        xCategory: action.category,
      };

    case UPDATE_Y_CATEGORY:
      return {
        ...state,
        yCategory: action.category,
      };

    case TOGGLE_METRIC_TO_DISPLAY:
      const prev = !!state.metricsToDisplay[action.metric];
      return {
        ...state,
        metricsToDisplay: {
          ...state.metricsToDisplay,
          [action.metric]: !prev,
        },
      };

    case UPDATE_ORGANIZE_AND_COLOR_BY:
      return {
        ...state,
        organizeAndColor: action.metric,
      };

    default:
      return state;
  }
};
