import React, { ReactElement } from 'react';

type SectionProps = {
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  children: ReactElement;
  style?: React.CSSProperties;
};

export const SECTION_STYLE = {
  padding: '1rem',
  background: 'white',
  margin: '1rem',
  borderRadius: '5px',
  boxShadow: '3px 3px 2px #e4ebf4',
};

export const Section = ({
  title,
  description,
  style = { padding: '2rem' },
  children,
}: SectionProps) => {
  return (
    <div
      className="lp-form-section"
      style={style}
      // style={{ padding: '2rem', borderBottom: `1px solid ${DARWIN_NAVY_5}` }}
    >
      {typeof title === 'string' ? <h3>{title}</h3> : title}
      {description && typeof description === 'string' ? (
        <p>{description}</p>
      ) : (
        description
      )}
      {children}
    </div>
  );
};
