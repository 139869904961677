export const DARWIN_NAVY = '#1E2B4E';
export const DARWIN_NAVY_2 = '#3A4B77';
export const DARWIN_NAVY_3 = '#667394';
export const DARWIN_NAVY_4 = '#808BA6';
export const DARWIN_NAVY_5 = '#DDE6F3';
export const DARWIN_NAVY_6 = '#F1F5F8';
export const DARWIN_NAVY_7 = '#F8F9FB';

export const DARWIN_RED = '#F9405A';
export const DARWIN_RED_2 = '#FFA4A4';

export const DARWIN_GREEN = '#6bf886';

export const DARWIN_BLUE = '#039BE5';
export const DARWIN_BLUE_2 = '#B6E7FF';
export const DARWIN_BLUE_3 = '#DAF6FF';
export const DARWIN_BLUE_4 = '#F1FAFF';

export const DARWIN_YELLOW = '#FBCE4C';
export const DARWIN_YELLOW_2 = '#FBD271';

export const DARWIN_SEAFOAM = '#3fc4ae';

export const DARWIN_BLACK = '#434343';

export const DARWIN_GRAY = '#666666';

export const SELECT_STYLES = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    border: 'none',
    boxShadow: '0px 3px 3px -3px #C1CDDE',
    ':hover': {
      transform: 'translateY(-1px)',
      transition: '0.1s ease-in-out',
      boxShadow: '0px 5px 3px -3px #CFD8E7',
    },
    fontSize: '1.2rem',
    color: '#566577',
    cursor: 'pointer',
  }),
  valueContainer: (styles) => ({
    ...styles,
    overflow: 'visible',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: '#dedede',
    borderLeft: 0,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    color: '#dedede',
  }),
  container: (provided) => ({
    ...provided,
    borderRadius: '2px',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '2px',
    border: 'none',
    fontSize: '1.1rem',
    color: '#667394',
    width: 'fit-content',
    padding: '0.5rem',
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    cursor: 'pointer',
    fontSize: '1rem',
    color: DARWIN_NAVY_2,
    backgroundColor: isFocused ? '#F2F8FF' : 'white',
    ':hover': {
      backgroundColor: '#F2F8FF',
    },
    borderRadius: '2px',
  }),
  singleValue: (provided, { isDisabled }) => ({
    ...provided,
    color: isDisabled ? DARWIN_NAVY_4 : DARWIN_NAVY_2,
    fontSize: '1.1rem',
    fontWeight: '500',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: DARWIN_NAVY_2,
  }),
};

export const SELECT_STYLES_GRAY = {
  control: (styles) => ({
    ...styles,
    backgroundColor: '#E5EBF3',
    border: 'none',
    boxShadow: '0px 3px 3px -3px #C1CDDE',
    ':hover': {
      transform: 'translateY(-1px)',
      transition: '0.1s ease-in-out',
      boxShadow: '0px 5px 3px -3px #d7dee7',
    },
    fontSize: '1.2rem',
    color: '#566577',
    cursor: 'pointer',
  }),
  valueContainer: (styles) => ({
    ...styles,
    overflow: 'visible',
    padding: '0 8px',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: '#dedede',
    borderLeft: 0,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    color: '#dedede',
  }),
  container: (provided) => ({
    ...provided,
    borderRadius: '2px',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '2px',
    border: 'none',
    fontSize: '1.1rem',
    color: '#667394',
    width: 'fit-content',
    padding: '0.5rem',
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    cursor: 'pointer',
    fontSize: '1rem',
    color: DARWIN_NAVY_2,
    backgroundColor: isFocused ? '#F2F8FF' : 'white',
    ':hover': {
      backgroundColor: '#F2F8FF',
    },
    borderRadius: '2px',
  }),
  singleValue: (provided, { isDisabled }) => ({
    ...provided,
    color: isDisabled ? DARWIN_NAVY_4 : DARWIN_NAVY_2,
    fontSize: '1.1rem',
    fontWeight: '500',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: DARWIN_NAVY_2,
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      background: 'none',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {},
  }),
};
