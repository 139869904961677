import React from "react";
import ReportItem from "./ReportItem";

const ReportList = ({
  reports,
  current,
  reportsToDelete,
  updateReportsToDelete,
  activeReports,
  handleSetStatus,
  handleSelectCurrent,
  reportDetails,
  changesMade,
  handleChangesModal,
  handleChangesClickAway,
  handleRefresh,
  saveInProgress,
  handleSetDefault,
  setToEmpty,
  handleValidation,
  errors,
}) => {
  return (
    <>
      {reports.length ?
        <>
          <ul className="report-editor__list">
            {reports.map((report) => {
              return (
                <ReportItem
                  {...{
                    reports,
                    report,
                    current,
                    reportsToDelete,
                    updateReportsToDelete,
                    activeReports,
                    handleSetStatus,
                    handleSelectCurrent,
                    reportDetails,
                    changesMade,
                    handleChangesModal,
                    handleChangesClickAway,
                    handleRefresh,
                    saveInProgress,
                    handleSetDefault,
                    setToEmpty,
                    handleValidation,
                    errors,
                  }}
                />
              );
            })}
          </ul>
        </>
      : <div style={{ fontSize: "15px", fontWeight: "400" }}>No reports found.</div> }
    </>
  );
};

export default ReportList;
