import React from 'react';
import Carousel from './Carousel';
import { SECTION_HEADERS } from './constants';
import VisualCard from './VisualCard';
import LazyLoader from '../../../components/LazyLoader';

const METRIC_TEXT = {
  spend: 'Spend',
  cpa: 'Performance',
};

const Grid = ({ visualsData, show_request, viewName }) => {
  return (
    <ul
      className={`visuals-grid ${
        show_request ? 'visuals-grid--condensed' : ''
      }`}
    >
      {visualsData.map((visualObj) => (
        <LazyLoader>
          <VisualCard {...{ visualObj, viewName }} isHeader />
        </LazyLoader>
      ))}
    </ul>
  );
};

export default Grid;
