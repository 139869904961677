
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ChartRenderer from '../components/ChartRenderer';
import Dashboard from '../components/Dashboard';
import DashboardItem, {
  DashboardItemDropdown,
} from '../components/DashboardItem';

// imported from rest of app
import BootstrapModal from '../../../../components/BootstrapModal';
import { setViewSettings } from '../../../../store/ViewSettings/actions';
import { setDashboardConfigs, REMOVE } from '../graphql/dash-config';

// removed JSON.stringify & JSON.parse
const deserializeItem = (i) => ({
  ...i,
  layout: i.layout || {},
  vizState: i.vizState || {},
});

export const defaultLayout = (i) => ({
  x: i.layout.x || 0,
  y: i.layout.y || 0,
  w: i.layout.w || 4,
  h: i.layout.h || 8,
  minW: 1,
  minH: 1,
});

function onlyDelete({ chartType }) {
  return chartType === 'custom_periodComparison';
}

const DashboardPage = ({ dashboardConfig, setConfig }) => {
  const [showModal, setShowModal] = useState(false);
  // Footer is a component set by individual dashboard items
  const [Footer, setFooter] = useState(null);

  const handleRemove = (item) => setConfig(dashboardConfig, item);

  const dashboardItem = (item) => (
    <div key={item.id} data-grid={defaultLayout(item)}>
      <DashboardItem
        key={item.id}
        title={item.name}
        dropdown={(
          <DashboardItemDropdown
            itemId={item.id}
            handleHideModal={() => setShowModal(false)}
            handleRemove={() => handleRemove(item)}
            handleShowModalWithFooter={(differentFooter) => {
              setFooter(differentFooter);
              setShowModal(true);
            }}
            deleteOnly={onlyDelete(item.vizState)}
          />
        )}
      >
        <ChartRenderer vizState={item.vizState} />
      </DashboardItem>
    </div>
  );

  return (
    <>
      {dashboardConfig.length > 0 ? (
        <Dashboard dashboardItems={dashboardConfig}>
          {dashboardConfig.map(deserializeItem).map(dashboardItem)}
        </Dashboard>
      ) : (
        <Empty />
      )}

      <BootstrapModal
        id="delete-chart-modal"
        Title="Are you sure you want to delete this item?"
        Footer={Footer}
        Body={null}
        handleHide={() => setShowModal(false)}
        show={showModal}
      />
    </>
  );
};

DashboardPage.propTypes = {
  dashboardConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
  setConfig: PropTypes.func.isRequired,
};

const stateToProps = ({ ViewSettings: { dashboardConfig } }) => ({
  dashboardConfig,
});

const dispatchToProps = (dispatch) => ({
  setConfig: (dashboardConfig, item) => {
    dispatch(
      setViewSettings({
        dashboardConfig: setDashboardConfigs({
          existingConfigs: dashboardConfig,
          update: item,
          action: REMOVE,
        }),
        updateDashboardConfig: true,
      }),
    );
  },
});

export default connect(stateToProps, dispatchToProps)(DashboardPage);

function Empty() {
  return (
    <div className="row">
      <div className="col-md-12">
        <h2>There are no charts on this dashboard</h2>
        <Link
          to={`${window.location.pathname.replace(
            'dashboard',
            'create-new-report',
          )}`}
        >
          <button className="btn btn-primary" type="button">
            Add chart
          </button>
        </Link>
      </div>
    </div>
  );
}
