import React from 'react';
import { DARWIN_NAVY } from '../../../../constants';

export const CopyIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.40142 5.3858V1.77373H13.0014V5.3858H1.40142ZM0.201416 1.57373C0.201416 1.02144 0.649131 0.57373 1.20142 0.57373H13.2014C13.7537 0.57373 14.2014 1.02145 14.2014 1.57373V5.5858C14.2014 6.13808 13.7537 6.5858 13.2014 6.5858H1.20142C0.649132 6.5858 0.201416 6.13808 0.201416 5.5858V1.57373ZM10.3678 13.1642V10.2928H13.0013V13.1642H10.3678ZM9.16785 10.0928C9.16785 9.54049 9.61556 9.09277 10.1678 9.09277H13.2013C13.7536 9.09277 14.2013 9.54049 14.2013 10.0928V13.3642C14.2013 13.9164 13.7536 14.3642 13.2013 14.3642H10.1678C9.61556 14.3642 9.16785 13.9164 9.16785 13.3642V10.0928ZM0.201416 13.7647C0.201416 13.4334 0.470045 13.1647 0.801416 13.1647H6.25756C6.58893 13.1647 6.85756 13.4334 6.85756 13.7647C6.85756 14.0961 6.58893 14.3647 6.25756 14.3647L0.801416 14.3647C0.470045 14.3647 0.201416 14.0961 0.201416 13.7647ZM0.801416 9.34555C0.470045 9.34555 0.201416 9.61418 0.201416 9.94555C0.201416 10.2769 0.470045 10.5456 0.801416 10.5456L6.25756 10.5456C6.58893 10.5456 6.85756 10.2769 6.85756 9.94555C6.85756 9.61418 6.58893 9.34555 6.25756 9.34555H0.801416Z"
        fill={DARWIN_NAVY}
      />
    </svg>
  );
};
