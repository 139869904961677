//@ts-check
import React, { useState } from "react";
import PropTypes from "prop-types";

import { ErrorBoundary } from "../../../components/Helpers/Error";
import { useFetch } from "../../../components/Hooks/fetch";
import { useViewId } from "../../../components/Hooks/view-id";
import { useUrlProps } from "../report-hooks";

import { Trendline } from "./Chart";
import { AxisPicker } from "./AxisPicker";

const RenderWhen = ({ ad_ids, isDirectMail, ...props }) => {
  if (!ad_ids || ad_ids.length === 0 || isDirectMail) {
    return null;
  } else {
    return <TrendlineWrapper ad_ids={ad_ids} {...props} />;
  }
};

export default RenderWhen;

const TrendlineWrapper = ({
  ad_ids,
  width,
  backgroundColor,
  foregroundColor,
  tertiary,
}) => {
  let [selected, setSelections] = useState(["spend", "cpr"]);
  const { error, loading, data } = useTrends(ad_ids);

  if (!!error || !!loading) {
    return <div></div>;
  } else {
    return (
      <div className="right-display__card" style={{ backgroundColor }}>
        <ErrorBoundary fallback={<p>Something went wrong</p>}>
          <>
            <p className="display-table__heading">
              Performance Trending
            </p>
            <Trendline
              axis={selected}
              data={data.data}
              width={width}
              backgroundColor={backgroundColor}
              foregroundColor={foregroundColor}
            />

            <AxisPicker
              selected={selected}
              onSelect={setSelections}
              foregroundColor={foregroundColor}
            />
          </>
        </ErrorBoundary>
      </div>
    );
  }
};

TrendlineWrapper.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  foregroundColor: PropTypes.string.isRequired,
  tertiary: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  ad_ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const useTrends = (ad_ids) => {
  const viewId = useViewId();
  const { start_date, end_date } = useUrlProps();
  const { error, loading, data } = useFetch(
    ["api", viewId, "trendline"].join("/"),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ad_ids,
        start_date,
        end_date,
        includeAllMetrics: true,
      }),
    },
    [ad_ids],
    true
  );

  return { error, loading, data };
};
