import { ZENDESK } from './constants';

const { logger } = window;

function createScriptUrl(source) {
  const blob = new Blob([source], { type: 'application/javascript' });

  return URL.createObjectURL(blob);
}

export function loadScript({
  src = '',
  isAsync = true,
  id = null,
  isBlob = false,
}) {
  const script = document.createElement('script');

  script.src = isBlob ? createScriptUrl(src) : src;
  script.async = isAsync;

  if (id) {
    script.id = id;
  }

  document.head.appendChild(script);

  return document;
}

export const loadZendesk = () => {
  if (process.env.NODE_ENV === 'production') {
    loadScript({ src: ZENDESK.URL, id: ZENDESK.ID });
  } else {
    logger.info(
      `[ScriptInjections.loadZendesk] Not loading because of NODE_ENV "${process.env.NODE_ENV}."`
    );
  }
};
