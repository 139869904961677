import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const Ctx = createContext(null);

export function ImpactReportProvider({
  isROAS,
  reports,
  setReports,
  audienceOptions,
  labelOptions,
  showReportEditor,
  setShowReportEditor,
  view_id,
  refreshData,
  children,
}) {
  return (
    <Ctx.Provider
      value={{
        isROAS,
        reports,
        setReports,
        audienceOptions,
        labelOptions,
        showReportEditor,
        setShowReportEditor,
        view_id,
        refreshData,
      }}
    >
      {children}
    </Ctx.Provider>
  );
}

export function useImpactReportContext() {
  return useContext(Ctx);
}
