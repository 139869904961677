import { useReducer } from "react";
import { phraseThemeSettingsReducer } from "./reducer";

export const usePhraseThemeSettings = () => {
  const [
    {
      show,
      lastSaved,
      current,
      error,
    },
    dispatchPhraseThemeSettings,
  ] = useReducer(phraseThemeSettingsReducer, {
    show: false,
    lastSaved: [],
    current: [],
    error: false,
  });

  return {
    show,
    lastSaved,
    current,
    error,
    dispatchPhraseThemeSettings,
  }
};