export const createShareUrl = (rows, viewId, isROAS) => {
  const rowParams = rows
    .map((row, i) => {
      const {
        baseVisual: base,
        variantData,
        primaryMetric,
        primaryMetricLabel,
      } = row;
      return `row${i}=${primaryMetric},${primaryMetricLabel},
      ${base.asset_url},${base.visual_type},${base.performance},
      ${variantData.map((variant) => {
        const { data } = variant;
        return `${data.asset_url},${data.visual_type},${data.predicted_performance},${data.confidence}`;
      })}`;
    })
    .join('&');

  const prefix = window.location.href.split(`${viewId}/`)[0];

  return `${prefix}variation-report?${encodeURIComponent(
    rowParams + `&rowCount=${rows.length}` + `&roas=${isROAS}`
  )}`;
};
