import React, { useState, useEffect } from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import { BasicLoading } from '../Helpers/Loading';
import { useGetLabels, useSaveLabels } from './api';
import BeagleToggle from '../BeagleToggle';
import CreativeViewNav from '../../features/Marketplace/components/AssetUpload/CreativeViews/CreativeViewNav/CreativeViewNav';
import { InfoMessage } from '../../features/Marketplace/components/AssetUpload/Helpers';
import { AddButton, SuccessMessage, CloseIcon } from './Helpers';
import './styles.scss';
import '../../features/Marketplace/components/AssetUpload/styles.scss';

const marketplacePostPayload = (labelsData, project_id, filename, version) => {
  const data = {
    project_id,
    filename,
    version,
    custom_trained_labels: labelsData.custom_trained_labels,
    common_ad_labels: labelsData.common_ad_labels,
    visual_specific_properties: labelsData.visual_specific_properties,
  };
  return data;
};

const previewsPostPayload = (labelsData, ad_id, adIds) => {
  const data = {
    ad_id,
    adIds,
    custom_trained_labels: labelsData.custom_trained_labels,
    common_ad_labels: labelsData.common_ad_labels,
    visual_specific_properties: labelsData.visual_specific_properties,
  };
  return data;
};

export default function VisualAttributesWrapper({
  location,
  view,
  setView,
  getPayload,
  adIds,
}) {
  const [labelsData, setLabelsData] = useState({});
  const [labelInput, setLabelInput] = useState('');
  const [saved, setSaved] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const labelsApi = useGetLabels();
  const saveLabelsApi = useSaveLabels();
  const { project_id, filename, version } = getPayload;

  useEffect(() => {
    labelsApi.get.request(getPayload, location);
  }, []);

  useEffect(() => {
    if (labelsApi.get.data) {
      const data = labelsApi.get.data;
      setLabelsData(data);
    }
  }, [labelsApi.get.data]);

  const handleAddLabel = () => {
    let newData = labelsData.custom_trained_labels;
    newData.push(labelInput);

    setLabelsData({
      ...labelsData,
      custom_trained_labels: newData,
    });
    setSaved(false);
    setLabelInput('');
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      handleAddLabel();
    }
  };

  const handleSave = () => {
    const postPayload = (location === 'marketplace')
      ? marketplacePostPayload(labelsData, project_id, filename, version)
      : previewsPostPayload(labelsData, labelsData.ad_id, adIds)
    saveLabelsApi.post.request(postPayload, location);
    setSaved(true);
    setShowMessage(true);
  };

  if (labelsApi.get.error) {
    return <p>An error has occurred...</p>;
  }
  if (labelsApi.get.loading) {
    return <BasicLoading />;
  }
  if (labelsData === undefined || Object.keys(labelsData).length === 0) {
    return <p>No attribute data.</p>;
  }

  const LabelSettingsControls = () => (
    <div className={`labels-options-container ${location}`}>
      {showMessage && <SuccessMessage setShowMessage={setShowMessage} />}
      {!saved && (
        <button
        
          className="save-btn mr-3"
          onClick={handleSave}
          type="button"
          title="You have unsaved changes."
        >
          Save
        </button>
      )}
    </div>
  )

  return (
    <>
      {location === 'marketplace' && (
        <div className="creative-view col-12 col-xl-5">
          <div className="creative-view-header">
            <div className="d-flex align-items-center">
              <CreativeViewNav view={view} setView={setView} />
              <InfoMessage>
                <div className="creative-view-info"><p>Manage system and user generated visual attributes.</p></div>
              </InfoMessage>
            </div> 
            <LabelSettingsControls />
          </div>
          <VisualAttributes
            {...{
              labelsData,
              setLabelsData,
              onKeyDown,
              labelInput,
              setLabelInput,
              handleAddLabel,
              setSaved,
              getPayload,
              location
            }}
          />
        </div>
      )}

      {location === 'ad_preview' && (
        <>
          <LabelSettingsControls />
          <VisualAttributes
            {...{
              labelsData,
              setLabelsData,
              onKeyDown,
              labelInput,
              setLabelInput,
              handleAddLabel,
              setSaved,
              getPayload,
              location
            }}
          />
        </>
      )}
    </>
  );
}

VisualAttributesWrapper.propTypes = {
  getPayload: PropTypes.shape({
    project_id: PropTypes.string,
    filename: PropTypes.string,
    version: PropTypes.string,
    adName: PropTypes.string
  }).isRequired
};

function VisualAttributes({
  labelsData,
  setLabelsData,
  onKeyDown,
  labelInput,
  setLabelInput,
  handleAddLabel,
  setSaved,
  getPayload,
  location
}) {
  return (
    <div className={`attributes-card card darwin-box-shadow ${location}`}>
      <div className="attributes-card-section custom-trained">
        <div className="d-flex justify-content-between">
          <h3 className="attributes-card-section__heading m-0">
            Custom Trained Labels
          </h3>
          <div className="custom-trained-add">
            <input
              className="custom-trained-add__input"
              onChange={(e) => setLabelInput(e.target.value)}
              onKeyDown={onKeyDown}
              placeholder="Add new label"
              value={labelInput}
            />
            <AddButton onAddLabel={handleAddLabel} />
          </div>
        </div>
        <h4 className="attributes-card-section__subheading">
          Add custom labels on your own to train the AI to pick up on new
          concepts automatically for you over time.
        </h4>
        <CustomTrainedLabels
          labelsData={labelsData}
          setLabelsData={setLabelsData}
          setSaved={setSaved}
        />
      </div>
    </div>
  )
}

function CustomTrainedLabels({ labelsData, setLabelsData, setSaved }) {
  const handleRemoveLabel = (index) => {
    let newData = labelsData.custom_trained_labels;

    if (index > -1) {
      newData.splice(index, 1);
    }

    setLabelsData({
      ...labelsData,
      custom_trained_labels: newData,
    });

    setSaved(false);
  };

  return (
    <ul className="custom-trained-list">
      {labelsData.custom_trained_labels.length > 0 ? (
        labelsData.custom_trained_labels.map((label, index) => (
          <li className="custom-trained-label" key={`custom-trained-${label}`}>
            <p className="custom-trained-label__text">{label}</p>
            <CloseIcon onRemoveLabel={handleRemoveLabel} index={index} />
          </li>
        ))
      ) : (
        <p>No custom trained labels.</p>
      )}
    </ul>
  );
}

CustomTrainedLabels.propTypes = {
  labelsData: PropTypes.shape({
    custom_trained_labels: arrayOf(PropTypes.string).isRequired,
    common_ad_labels: arrayOf(PropTypes.object).isRequired,
    visual_specific_properties: arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  setLabelsData: PropTypes.func.isRequired,
  setSaved: PropTypes.func.isRequired,
};

function LabelsDisplay({
  type,
  getPayload,
  labelsData,
  setLabelsData,
  setSaved,
  location
}) {
  const [typeId, setTypeId] = useState('custom-trained');
  const [toggleId, setToggleId] = useState('');

  useEffect(() => {
    // SET LABEL ID BASED ON TYPE
    switch (type) {
      case 'common_ad_labels':
        setTypeId('common-ad');
        break;
      case 'visual_specific_properties':
        setTypeId('visual-specific');
        break;
      default:
        setTypeId('custom-trained');
    }
    // SET TOGGLE ID BASED ON LOCATION (marketplace vs. ad preview)
    switch (location) {
      case 'marketplace':
        setToggleId(`${getPayload.project_id}-${getPayload.filename}`);
        break;
      case 'ad_preview':
        setToggleId(getPayload.ad_name);
        break;
      default:
        setToggleId('');
    }
  });

  const handleToggle = (e, index) => {
    let newLabelsData = labelsData[type];

    if (newLabelsData[index].status === 'off') {
      newLabelsData[index].status = 'on';
    } else {
      newLabelsData[index].status = 'off';
    }

    setLabelsData({
      ...labelsData,
      [type]: newLabelsData,
    });
    setSaved(false);
  };

  return (
    <ul className="attributes-list">
      {labelsData[type].length > 0 ? (
        labelsData[type].sort((a, b) => ((a.name > b.name) ? 1 : -1)).map((label, index) => (
          <li className="attributes-list-item" key={`${typeId}-${label.name}`}>
            <BeagleToggle
              checked={label.status === 'on'}
              onChange={(e) => handleToggle(e, index)}
              id={`${typeId}-${index}-${toggleId}`}
              name={label.name}
            />
            <p className="attributes-list-item__name" title={label.name}>
              {label.name}
            </p>
          </li>
        ))
      ) : (
        <p>No labels found.</p>
      )}
    </ul>
  );
}
