import React from 'react';
import Tooltip from '../../../components/Tooltip';
import { FavoriteIcon } from '../../../components/Icons';

const Header = ({
  copied,
  rows,
  handleShareReport,
  filterFavorites,
  setFilterFavorites,
  hasFavorites,
}) => {
  return (
    <div className="variation__header">
      <h2>Variation Report</h2>
      <div className="report-options">
        {copied && <div className="copied">Link copied to clipboard</div>}
        <Tooltip content="Share report" disabled={copied}>
          <button
            onClick={() => handleShareReport()}
            disabled={
              !rows.filter((row) => hasFavorites(row.variantData)).length
            }
          >
            <i className="fa-solid fa-share"></i>
          </button>
        </Tooltip>
        <Tooltip content="Show only favorites">
          <button
            className={filterFavorites ? 'active' : ''}
            onClick={() => setFilterFavorites((prev) => !prev)}
            disabled={
              !rows[0].variantData.some((v) => !!v.data) ||
              !rows.filter((row) => hasFavorites(row.variantData)).length
            }
          >
            <FavoriteIcon onClick={null} isSaved={filterFavorites} />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default Header;
