import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { previewsFetchFlow } from '../../store/AdPreviews/actions';
import { ErrorBoundary } from '../../components/Helpers/Error';
import { useAdPreview, Status } from '../../components/AdPreview/Container';
import Preview from '../../components/AdPreview/Preview';
import { PlaceholderContainer } from '../../components/AdPreview/Helpers';

function setOptions(previews) {
  let results = [];

  previews.forEach(({ type, preview }) => {
    const option = { label: type, value: preview };

    if (type === 'MOBILE_FEED_STANDARD') {
      results = [option, ...results];
    } else {
      results.push(option);
    }
  });

  return results;
}

function Display({ previewKey }) {
  const ad = useAdPreview(previewKey);

  switch (ad.status) {
    case Status.error:
      return <PlaceholderContainer><p>Error</p></PlaceholderContainer>;

    case Status.loading || Status.init:
      return <PlaceholderContainer stlye={{ cursor: 'progress' }}>Loading...</PlaceholderContainer>;

    default: {
      const options = setOptions(ad.preview.preview_list);

      return (
        <ErrorBoundary>
          <Preview options={options} />
        </ErrorBoundary>
      );
    }
  }
}

Display.propTypes = {
  previewKey: PropTypes.string.isRequired,
};

export default function Container({ viewId, previewKey, previewAttrbute }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(previewsFetchFlow({ values: [previewKey], attribute: previewAttrbute, viewId }));
  }, []);

  return <Display previewKey={previewKey} />;
}

Container.propTypes = {
  previewKey: PropTypes.string.isRequired,
  previewAttrbute: PropTypes.oneOf(['ad_name', 'ad_id']).isRequired,
  viewId: PropTypes.string.isRequired,
};
