
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import { SingleAdPreview } from '../../../components/AdPreview';

import { strToNum } from '../../../utils/numbers';

import { useDetailsApi, ApiStatus } from './api';
import { useBlockParamsGet } from './Contexts';
import { sortSelectedMetrics } from './metric-columns';

function Layout({ children, className, style }) {
  return (
    <div className={`my-3 ${className}`} style={{ height: '80vh', ...style }}>
      {children}
    </div>
  );
}

Layout.defaultProps = {
  className: '',
  style: {},
};

Layout.propTypes = {
  style: PropTypes.shape({ width: PropTypes.string }),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

function DetailTabWrapper({ GetButton, data, creativeTest, adName, status, children }) {
  const loading = (creativeTest && !adName) || status.skeleton === ApiStatus.loading;

  if (loading) {
    return (
      <Layout>
        <p className="ct-block-table--loading">Loading</p>
      </Layout>
    );
  }

  if (!data) {
    return <Layout>{GetButton}</Layout>;
  }

  return children(data);
}

DetailTabWrapper.defaultProps = {
  data: null,
};

DetailTabWrapper.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({ spend: PropTypes.string, cpa: PropTypes.string })
  ),
  GetButton: PropTypes.node.isRequired,
  status: PropTypes.string.isRequired,
};

function DetailTab({ Headers, MetricHeaders, renderRow, data, label, sortAsc, metricKey, creativeTest }) {
  const rows = data.sort((a, b) => {
    const $a = a.metric !== "-" ? strToNum(a[metricKey]) : 0;
    const $b = b.metric !== "-" ? strToNum(b[metricKey]) : 0;

    if (sortAsc) {
      if ($a >= 0 && $b >= 0) {
        return $a - $b;
      }
      return 0;
    } else {      
      if ($a >= 0 && $b >= 0) {
        return $b - $a;
      }
      return 0;
    }
  });
  // 2021-01-21 - Details section is only for
  // "trending", "Period 1", & spend/cpa.
  // The table headers are truncated to support
  // this.
  return (
    <table className="table table-bordered mt-3">
      <thead className="thead-dark">
        <tr>
          <td />
          {Headers.slice(0, 1)}
        </tr>
      </thead>
      <tbody>
        <tr className="thead-light">
          <th>{label}</th>
          {MetricHeaders}          
        </tr>
        {rows.filter((row) => !!row.spend && strToNum(row.spend) > 0).map(renderRow)}
      </tbody>
    </table>
  );
}

DetailTab.propTypes = {
  label: PropTypes.string.isRequired,
  renderRow: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      spend: PropTypes.string,
      cpa: PropTypes.string,
    }),
  ).isRequired,
  Headers: PropTypes.arrayOf(PropTypes.node).isRequired,
  MetricHeaders: PropTypes.arrayOf(PropTypes.node).isRequired,
};

function PerformanceRow({ row, metrics, NameCell, status, active }) {
  const sortedMetrics = sortSelectedMetrics(metrics);
  let cols = null;

  const values = sortedMetrics.map((metric) => row[metric.value]);

  switch (status) {
    case ApiStatus.failure:
      cols = (
        <td>
          <i
            className="fa fa-exclamation-circle text-danger"
            title="Api failure!"
          />
        </td>
      );
      break;

    case ApiStatus.loading:
      cols = (
        <td>
          <i className="fa fa-hourglass-half" />
        </td>
      );
      break;

    case ApiStatus.success:
      cols = (
        <>
          {values.map((value) => (
            <td>{value}</td>
          ))}
        </>
      );
      break;

    default:
      break;
  }

  const className = active ? 'bg-primary text-white' : '';

  return (
    <tr className={className}>
      {NameCell}
      {cols}
    </tr>
  );
}

PerformanceRow.defaultProps = {
  spend: '-',
  cpa: '-',
  active: false,
};

PerformanceRow.propTypes = {
  spend: PropTypes.string,
  cpa: PropTypes.string,
  active: PropTypes.bool,
  NameCell: PropTypes.node.isRequired,
  status: PropTypes.string.isRequired,
};

function LinkButton({ onClick, label, active }) {
  const className = active
    ? 'btn btn-link p-0 text-left text-white'
    : 'btn btn-link p-0 text-left';

  return (
    <button type="button" onClick={onClick} className={className}>
      {label}
    </button>
  );
}

LinkButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};

export function BlockDetails({
  blockId,
  Headers,
  MetricHeaders,
  metrics,
  creativeTest,
  sortAsc,
  metricKey,
}) {
  const { period1 } = useBlockParamsGet();
  const [tab, setTab] = useState('creative');
  const [adName, setAdName] = useState('');
  const ad = useDetailsApi(blockId, 'ad', period1);
  const adset = useDetailsApi(blockId, 'adset', period1);

  const handleAdName = (name) => {
    if (adName === name) {
      return setAdName('');
    }
    return setAdName(name);
  };

  const selectTopSpendingPreview = (data) => {
    const topSpending = data.reduce((acc, curr) => {
      return strToNum(acc.spend) > strToNum(curr.spend) ? acc : curr;
    }, []);
    setAdName(topSpending.ad_name);
    setTab('ad-preview');
  };

  useEffect(() => {
    if (creativeTest) {
      setTab('creative');
      setAdName('');
      ad.request(blockId, 'ad');
    }
  }, [creativeTest, DetailTab, blockId]);

  useEffect(() => {
    if (creativeTest && ad.status.performance === ApiStatus.success) {
      selectTopSpendingPreview(ad.data);
    }
  }, [ad.status.performance]);

  return (
    <Tabs activeKey={tab} onSelect={setTab} className="my-2">
      <Tab eventKey="creative" title="Creative">
        <DetailTabWrapper
          status={ad.status}
          data={ad.data}
          creativeTest={creativeTest}
          adName={adName}
          GetButton={(
            <button
              type="button"
              className="btn btn-light"
              onClick={() => ad.request(blockId, 'ad')}
            >
              Get Data
            </button>
          )}
        >
          {(data) => (
            <DetailTab
              label="Ad Name"
              Headers={Headers}
              MetricHeaders={MetricHeaders}
              data={data}
              sortAsc={sortAsc}
              metricKey={metricKey}
              creativeTest={creativeTest}
              renderRow={(row) => (
                <PerformanceRow
                  active={adName === row.ad_name}
                  metrics={metrics}
                  row={row}
                  NameCell={(
                    <td>
                      <LinkButton
                        label={row.ad_name}
                        onClick={() => handleAdName(row.ad_name)}
                        active={adName === row.ad_name}
                      />
                    </td>
                  )}
                  status={ad.status.performance}
                />
              )}
            />
          )}
        </DetailTabWrapper>
      </Tab>

      <Tab eventKey="audience" title="Adset">
        <DetailTabWrapper
          status={adset.status.skeleton}
          data={adset.data}
          GetButton={(
            <button
              type="button"
              className="btn btn-light mt-3"
              onClick={() => adset.request(blockId, 'adset')}
            >
              Get Data
            </button>
          )}
        >
          {(data) => (
            <DetailTab
              label="Adset Name"
              Headers={Headers}
              data={data}
              MetricHeaders={MetricHeaders}
              renderRow={(row) => (
                <PerformanceRow
                  key={row.adset_name}
                  spend={row.spend}
                  row={row}
                  metrics={metrics}
                  cpa={row.cpa}
                  NameCell={<td>{row.adset_name}</td>}
                  status={adset.status.performance}
                />
              )}
              {...{
                sortAsc,
                metricKey,
              }}
            />
          )}
        </DetailTabWrapper>
      </Tab>

      {adName && (
        <Tab eventKey="ad-preview" title="Ad Preview">
          <Layout
            className="d-flex justify-content-center"
            style={{ width: '100%' }}
          >
            <SingleAdPreview adName={adName} />
          </Layout>
        </Tab>
      )}
    </Tabs>
  );
}

BlockDetails.propTypes = {
  blockId: PropTypes.string.isRequired,
  Headers: PropTypes.arrayOf(PropTypes.node).isRequired,
  MetricHeaders: PropTypes.arrayOf(PropTypes.node).isRequired,
};
