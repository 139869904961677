import React, { useEffect, useState } from "react";
import "./PhraseTheme.scss";
import Button from "../../../components/Button";
import { useCreativeAnalyticsGet } from "../contexts";
import { Table } from "../PerformanceFrequency/Table/Table";
import { useCategories } from "../hooks";
import { ReportHeader } from "../PerformanceFrequency/Table/ReportHeader";
import { usePhraseThemeSettings } from "./PhraseThemeSettings/hooks";
import {
  OPEN_SETTINGS,
  CLOSE_SETTINGS,
  CLEAN_UP,
  THROW_ERROR,
  CLEAR_ERROR,
  ADD_SETTING,
  SET_LAST_SAVED,
  SET_CURRENT,
} from "./PhraseThemeSettings/reducer";
import PhraseThemeSettings from "./PhraseThemeSettings";
import { usePortals } from "../hooks/usePortals";
import { EMPTY_SETTING } from "./PhraseThemeSettings/constants";
import { ConfigureIcon } from "./icons/configure";
import LoadingMessages from "../../../components/Helpers/LoadingMessages";
import { LOADING_MESSAGES } from "./constants";

const PhraseTheme = ({ columns, view_id, darwin_client_id }) => {
  const { PhraseThemeSettingsPortal: Portal } = usePortals();
  const {
    category,
    phraseThemeDataApi,
    organizePhraseThemesApi,
    phraseThemeData,
  } = useCreativeAnalyticsGet();
  const { tableData } = useCategories();
  const themeTableData = tableData.find(
    (data) => data.category_name === "Phrase Theme"
  );
  const { category_name, category_data, labels } = themeTableData || {};
  const phraseThemeSettings = usePhraseThemeSettings();
  const { dispatchPhraseThemeSettings: dispatch, current } =
    phraseThemeSettings;
  const [cleanup, setCleanup] = useState(false);
  const loading =
    phraseThemeDataApi.post.loading || organizePhraseThemesApi.post.loading;
  const error =
    phraseThemeDataApi.post.error || organizePhraseThemesApi.post.error;
  const savedSettings = phraseThemeData.PhraseThemeSetting;
  const initialSettings = savedSettings?.candidate_themes?.length
    ? savedSettings?.candidate_themes
    : [EMPTY_SETTING];

  useEffect(() => {
    if (category !== "phrase_theme") {
      dispatch({ type: CLOSE_SETTINGS, payload: {} });
    }
  }, [category]);

  useEffect(() => {
    if (cleanup) {
      validateSettingsInput();
    }
  }, [cleanup]);

  useEffect(() => {
    const { data } = organizePhraseThemesApi.post;
    
    if (data) {
      const updatedSettings = data.PhraseThemeSetting.candidate_themes.length
        ? data.PhraseThemeSetting.candidate_themes
        : [EMPTY_SETTING];
      handleSetSavedAndCurrent(updatedSettings);
    }
  }, [organizePhraseThemesApi.post.data]);

  const handleSetSavedAndCurrent = (update) => {
    dispatch({
      type: SET_LAST_SAVED,
      payload: {
        lastSaved: update ? update : initialSettings,
      },
    });
    dispatch({
      type: SET_CURRENT,
      payload: {
        current: update ? update : initialSettings,
      },
    });
  };

  const handleSaveAndMap = () => {
    if (organizePhraseThemesApi.post.loading) return;
    const settingsPayload = makeSettingsPayload();
    organizePhraseThemesApi.post.request(settingsPayload);
    setCleanup(false);

    dispatch({
      type: CLOSE_SETTINGS,
      payload: {},
    });
  };

  const makeSettingsPayload = () => {
    let candidate_themes = current.filter(
      (setting) => !_.isEqual(setting, EMPTY_SETTING)
    );

    return {
      view_id,
      client_id: darwin_client_id,
      candidate_themes,
    };
  };

  const cleanUpInput = () => {
    dispatch({
      type: CLEAN_UP,
      payload: {},
    });
    setCleanup(true);
  };

  const validateSettingsInput = () => {
    setCleanup(false);
    
    if (current.length) {
      if (current.some((setting) => !setting.phrase_theme)) {
        throwError();
      } else {
        clearError();
        handleSaveAndMap();
      }
    } else {
      dispatch({
        type: ADD_SETTING,
        payload: {},
      });
      clearError();
      handleSaveAndMap();
    }
  };

  const throwError = () => {
    dispatch({
      type: THROW_ERROR,
      payload: {},
    });
  };

  const clearError = () => {
    dispatch({
      type: CLEAR_ERROR,
      payload: {},
    });
  };

  const handleOpenSettings = () => {
    if (loading) return;

    dispatch({
      type: OPEN_SETTINGS,
      payload: {},
    });
  };

  let Render = (
    <div>
      <p>Click here to organize performance by phrase theme.</p>
      <Button onClick={handleSaveAndMap}>Map</Button>
    </div>
  );

  if (loading) {
    Render = (
      <LoadingMessages
        messages={LOADING_MESSAGES}
        className={"phrase-themes"}
        delay={4000}
      />
    );
  } else if (error) {
    Render = (
      <p>
        There was an error. Try{" "}
        <span className="rerun" onClick={handleSaveAndMap}>
          rerunning
        </span>
        .
      </p>
    );
  } else {
    if (themeTableData) {
      Render = (
        <Table
          category_name={category_name}
          categoryData={category_data}
          categoryLabels={labels}
          isCompare={false}
          columns={columns}
        />
      );
    } else if (
      !themeTableData &&
      phraseThemeData.lastSavedPhraseThemes.length
    ) {
      // data was returned but no visuals match
      Render = <p>No data to display.</p>;
    }
  }

  return (
    <div className="phrase-theme">
      <PhraseThemeSettings
        {...{
          phraseThemeSettings,
          Portal,
          cleanUpInput,
          loading,
          handleSetSavedAndCurrent,
        }}
      />
      <div className="report">
        <div className="d-flex">
          <ReportHeader
            category_name={"Phrase Theme"}
            categoryData={{}}
            desc={""}
          />
          <div
            onClick={handleOpenSettings}
            className={`configure-labels ${loading ? "disabled" : ""}`}
          >
            <ConfigureIcon />
            Configure Labels
          </div>
        </div>
        {Render}
      </div>
    </div>
  );
};

export default PhraseTheme;
