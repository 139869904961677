import React, { useState, useRef } from 'react';

import { useDispatch } from 'react-redux';
import { useSearchParams } from '../../../../../components/Hooks/search-params';
import { useClickOutside } from '../../../../../hooks/useClickOutside';
import './DisplaySelect.scss';
import { DollarIcon } from '../../../../../components/Icons/DollarIcon';
import { RankingIcon } from '../../../../../components/Icons/RankingIcon';
import Tooltip from '../../../../../components/Tooltip';

const DisplaySelect = ({
  display = 'spend_based',
  setDisplay,
}: {
  display: 'spend_based' | 'rank_based';
  setDisplay: (
    mode: 'spend_based' | 'rank_based'
  ) => React.SetStateAction<boolean>;
}) => {
  const [open, setOpen] = useState(false);
  // const { display } = useCreativeAnalyticsGet();
  const { setParams } = useSearchParams();
  const ref = useRef();
  const dispatch = useDispatch();
  useClickOutside(ref, () => setOpen(false));

  let selected = '';

  if (display === 'spend_based') {
    selected = 'Spend Based';
  } else if (display === 'rank_based') {
    selected = 'Rank Based';
  }

  const handleSelect = (mode) => {
    setDisplay(mode);
  };

  const toggleDisplay = () =>
    handleSelect(display === 'spend_based' ? 'rank_based' : 'spend_based');

  return (
    <div
      ref={ref}
      className="display-select"
      onClick={() => setOpen((prev) => !prev)}
    >
      {/* <h3 className="display-select__title">{selected}</h3> */}
      <ToggleSwitch selected={selected} toggleDisplay={toggleDisplay} />
    </div>
  );
};

export default DisplaySelect;

const ToggleSwitch = ({ selected, toggleDisplay }) => {
  return (
    <div className="display-toggle-switch" onClick={toggleDisplay}>
      <Tooltip content="Spend based">
        <div
          className={`display-toggle-switch__btn mr-2 ${
            selected === 'Spend Based' ? 'is-selected' : ''
          }`}
        >
          <DollarIcon />
        </div>
      </Tooltip>

      <Tooltip content="Rank based">
        <div
          className={`display-toggle-switch__btn ${
            selected === 'Rank Based' ? 'is-selected' : ''
          }`}
        >
          <RankingIcon />
        </div>
      </Tooltip>
    </div>
  );
};
