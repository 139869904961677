import { useSelector } from 'react-redux';

export function useAccountId() {
  return useSelector(({ ViewSettings: { account_id } }) => account_id || '');
}

export function useIsDirectMail() {
  return useSelector(({ ViewSettings: { isDirectMail } }) => isDirectMail);
}

export function useActionName() {
  return useSelector(({ ViewSettings: { action_name } }) =>
    typeof action_name === 'string'
      ? action_name
      : typeof action_name === 'object'
      ? action_name.value || ''
      : ''
  );
}

export function useViewId() {
  return useSelector(({ ViewSettings: { viewId } }) => viewId);
}

export function useGaEnabled() {
  return useSelector(({ ViewSettings: { ga_enabled } }) => ga_enabled);
}

export function useViewName() {
  return useSelector(({ ViewSettings: { viewName } }) => viewName);
}

export function useCustomEvents() {
  return useSelector(({ ViewSettings: { custom_events } }) => custom_events);
}

export const useCustomMetrics = () => {
  return useSelector(
    ({
      ViewSettings: {
        custom_events,
        additional_metrics,
        customIntegratedMetrics,
      },
    }) => ({
      custom_events,
      additional_metrics,
      customIntegratedMetrics,
    })
  );
};
