export const getCategoryLabelData = (categories = [], isROAS, current) => {
  let metric = 'cpa';
  if (isROAS) metric = 'roas';

  try {
    return [...categories].map((category) => {
      const sortedLabels = [...(category.labels ?? [])]
        ?.sort((a, b) => {
          if (isROAS) {
            return (
              (a.aggregate_roas === 0) - (b.aggregate_roas === 0) ||
              +(b.aggregate_roas < a.aggregate_roas) ||
              -(b.aggregate_roas > a.aggregate_roas)
            );
          } else {
            return (
              (a.aggregate_cpa === 0) - (b.aggregate_cpa === 0) ||
              -(a.aggregate_cpa < b.aggregate_cpa) ||
              +(a.aggregate_cpa > b.aggregate_cpa)
            );
          }
        })
        .filter((label) => label.aggregate_cpa > 0);

      let topLabel = {};

      if (sortedLabels.length) {
        topLabel = sortedLabels.reduce((prev, curr) => {
          if (isROAS) {
            return prev.aggregate_roas >= curr.aggregate_roas ? prev : curr;
          } else {
            return prev.aggregate_cpa <= curr.aggregate_cpa ? prev : curr;
          }
        });
      }

      const activeLabels = sortedLabels.filter((categoryLabel) =>
        categoryLabel.visuals.some(({ id }) => id === current.id)
      );

      let active = sortedLabels
        .map((label, i) => {
          let activeLabel = activeLabels.find((x) => x.value === label.value);
          if (activeLabel) {
            return {
              ...activeLabel,
              value: replaceChars(activeLabel.value),
              rank: i + 1,
              eligible: false,
            };
          }
        })
        .filter((obj) => obj);

      if (active.length) {
        if (!active.some((l) => l.value === replaceChars(topLabel.value))) {
          active = [
            ...active,
            {
              ...topLabel,
              value: replaceChars(topLabel.value),
              rank: 1,
              eligible: true,
            },
          ];
        } else {
          // visual already includes rank 1 label; don't send category
          active = [];
        }
      }

      return {
        category: category.category,
        totalLabels: sortedLabels.length,
        topLabel,
        active,
      };
    });
  } catch (e) {
    console.error(e);
    return [];
  }
};

const replaceChars = (str) => {
  return str.replace(/FX_|"/g, '');
};

export const calculatePercentWorse = (v1, v2) => {
  return +((Math.abs(v1 - v2) / ((v1 + v2) / 2)) * 100).toFixed(2);
};

export const makeEasyGptPayload = (
  categoryLabelData,
  darwin_client_id,
  view_id,
  visual_hash
) => {
  const performanceData = categoryLabelData
    .map((obj) => {
      const labelData = obj.active.map((label) => {
        return {
          label_name: label.value,
          rank: label.rank,
          percent_worse: calculatePercentWorse(
            obj.topLabel.aggregate_cpa,
            label.aggregate_cpa
          ),
          eligible_for_recommendations: label.eligible,
        };
      });

      return {
        category: obj.category,
        total_labels: obj.totalLabels,
        label_data: labelData,
      };
    })
    .filter((dataObj) => dataObj.label_data.length);

  return {
    darwin_client_id,
    view_id,
    visual_id: visual_hash,
    performance_data: performanceData,
  };
};
