import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useGetRequest } from '../../components/Hooks/useGetRequest';
import { useDispatch, useSelector } from 'react-redux';
import { TabList, Tab } from './Tabs';
import { MOCK } from './mock';
import StaticReport from '../StaticReport';
import { Link } from 'react-router-dom';

import './styles.scss';
import { isUrl } from '../../utils';
import { NestedView } from '../NestedView';
import { beginTokenFlow, useViewStatus } from '../../components/App/App';
import { setViewSettings } from '../../store/ViewSettings/actions';
import { useSearchParams } from '../../components/Hooks/search-params';
import { BasicError } from '../../components/Helpers/Error';
import { DATE_PAIRS, toDarwinDate, YESTERDAY } from '../../utils/darwin-dates';
import { useAnalyticsParams } from '../CreativeAnalytics/hooks';
import { setLockedDates } from '../CreativeAnalytics/hooks/useAnalyticsParams';
import { usePermissions } from '../../components/Permissions/usePermissions';
import { GHOST_TABS } from '../../components/Permissions/constants';
import { LinkedInIcon, PinterestIcon, TikTokIcon } from './icons';

const getReportId = (url) => {
  if (!url?.length) return null;
  const regex = /\/static-report\/([a-f0-9]{64})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

const ReportGroup = ({ _id, exit, viewError }) => {
  const dispatch = useDispatch();
  const { getParams, setParams } = useSearchParams();
  const [, , idParam] = window.location.pathname.split('/');
  // const [, , report_group_id] = window.location.pathname.split('/');
  const report_group_id = useMemo(() => {
    return _id ? _id : idParam;
  }, [_id, idParam]);

  const api = useGetRequest(
    ['api', 'get-report-group', report_group_id].join('/'),
    true
  );
  const data = report_group_id === 'test' ? MOCK : api.data;

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const selectedTab = data?.[0]?.report_group_tabs?.find((tab, i) => {
    return selectedTabIndex === i;
  });
  const prev = useRef();

  useEffect(() => {
    if (prev.current !== report_group_id) {
      prev.current = report_group_id;
      api.request();
    }
  }, [report_group_id]);

  const reportUrlOrViewId = selectedTab?.report_url;

  const isStaticReport = isUrl(reportUrlOrViewId);
  const selectedReportId = getReportId(reportUrlOrViewId);
  const viewId =
    !isStaticReport && typeof reportUrlOrViewId === 'string'
      ? reportUrlOrViewId
      : null;

  const { account_id } = useSelector(({ ViewSettings: { account_id } }) => ({
    account_id,
  }));
  const lockedDates = setLockedDates(viewId, account_id, null);

  const handleSelectTab = (tabIndex, tab) => {
    setSelectedTabIndex(tabIndex);

    const lastMonth = DATE_PAIRS.find(({ label }) => label === 'Last Month');

    if (tab.tab_name.includes('Organic')) {
      setParams({ period1: `2023-01-01,${YESTERDAY}` });
    } else if (tab.tab_name.includes('Amazon')) {
      setParams({ period1: `2024-01-01,${toDarwinDate(lastMonth.value[1])}` });
    } else {
      setParams({
        period1: `${toDarwinDate(lastMonth.value[0])},${toDarwinDate(
          lastMonth.value[1]
        )}`,
      });
    }
  };

  const { loggedIn } = useViewStatus();

  const prevViewId = useRef();

  const report_group_name = data?.[0]?.report_group_name;

  useEffect(() => {
    if (report_group_name) {
      dispatch(setViewSettings({ report_group_name }));
    }
  }, [report_group_name]);

  useEffect(() => {
    if (loggedIn) {
      if (prevViewId.current !== viewId) {
        setParams({
          selectedVisuals: '',
          columns: '',
          primary_metric: '',
        });
        if (viewId) {
          dispatch(
            setViewSettings({
              viewId,
              // viewName,
              // isLeverView,
              resetStore: true,
              initialView: false,
            })
          );
        }
        prevViewId.current = viewId;
      }
    }
  }, [viewId, loggedIn]);

  const hasPermission = usePermissions();
  const tabNames = data?.[0]?.report_group_tabs?.map(
    ({ tab_name }) => tab_name
  );
  const hasLinkedInTab = tabNames?.some((name) => name.includes('LinkedIn'));
  const hasTikTokTab = tabNames?.some((name) => name.includes('TikTok'));
  const hasPinterestTab = tabNames?.some((name) => name.includes('Pinterest'));

  const GhostTabs = () => {
    if (tabNames) {
      return (
        <>
          {(!hasLinkedInTab || !hasTikTokTab || !hasPinterestTab) && (
            <span className="divider"></span>
          )}
          {!hasLinkedInTab ? (
            <li className="tab">
              <span className="icon ghost">
                <LinkedInIcon />
              </span>
              <span className="divider"></span>
            </li>
          ) : null}
          {!hasTikTokTab ? (
            <li className="tab">
              <span className="icon ghost">
                <TikTokIcon />
              </span>
              <span className="divider"></span>
            </li>
          ) : null}
          {!hasPinterestTab ? (
            <li className="tab">
              <span className="icon ghost">
                <PinterestIcon />
              </span>
              <span className="divider"></span>
            </li>
          ) : null}
        </>
      );
    }
  };

  let Display = null;

  if (viewError) {
    Display = (
      <div className="m-3">
        <BasicError />
      </div>
    );
  } else if (selectedReportId) {
    if (isStaticReport) {
      Display = <StaticReport rid={selectedReportId} />;
    }
  } else if (viewId) {
    if (!loggedIn) {
      Display = (
        <div className="p-3">
          <p>You must be logged in to access this.</p>
          <Link to="/login">Login</Link>
        </div>
      );
    } else {
      Display = <NestedView viewId={viewId} selectedTab={selectedTab} />;
    }
  }

  beginTokenFlow(viewId);

  if (api.loading) {
    return <p className="m-3">Loading...</p>;
  } else if (api.error && !data) {
    return (
      <p className="m-3">
        There was an error fetching the report group.{' '}
        {api.error.error?.toString()}
      </p>
    );
  } else if (!data) {
    return <p className="m-3">Report group not found.</p>;
  }

  return (
    <div className="report-group">
      <TabList amount={data?.[0]?.report_group_tabs?.length ?? 0} exit={exit}>
        {data?.[0]?.report_group_tabs?.map((tab, i) => {
          return (
            <Tab
              isSelected={i === selectedTabIndex}
              label={tab.tab_name}
              onSelect={() => handleSelectTab(i, tab)}
            ></Tab>
          );
        })}
        {hasPermission(GHOST_TABS) && <GhostTabs />}
      </TabList>

      <div className="report-group-content">
        {Display ?? <p className="m-3">Nothing to display.</p>}
      </div>
    </div>
  );
};

export default ReportGroup;
