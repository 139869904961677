//@ts-check
import React from "react";
import PropTypes from "prop-types";

import Modal from "../BootstrapModal";

import { Wizard } from "./Wizard";

export const StudyModal = ({ handleSubmit, study, visible, hide }) => {
  return (
    <Wizard.Provider initialState={study} visible={visible}>
      <Modal
        id="create-study-flow-modal"
        Header={
          <div className="modal-header" style={{ position: "relative" }}>
            <div className="modal-title">
              <h4 className="modal-title-heading">Create Ad Study</h4>
            </div>
            <button
              type="button"
              className="close"
              aria-label="Close"
              style={{ position: "absolute", top: "16px", right: "16px" }}
              onClick={hide}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        }
        Body={
          visible && (
            <Wizard.Body
              visible={visible}
              handleSubmit={(formData) => (handleSubmit(formData), hide())}
            />
          )
        }
        show={visible}
        handleHide={hide}
        modalClass="modal-lg"
      />
    </Wizard.Provider>
  );
};

StudyModal.propTypes = {
  visible: PropTypes.bool,
  hide: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  study: PropTypes.shape({
    study_name: PropTypes.string.isRequired,
  }).isRequired,
};
