import { useEffect, useMemo, useReducer } from 'react';
import { getCol } from '../../PerformanceFrequency/hooks';
import { useGetAggregate } from '../../utils';
import { VisualObject } from '../../types';
import { useSearchParams } from '../../../../components/Hooks/search-params';
import { INITIAL_STATE, heatmapReducer } from './reducer';
import { getAggregate } from '../../hooks/useFilteredData/utils/getAggregate';

const setDefaults = (params) => {
  const {
    xCategory,
    yCategory,
    organizeAndColor,
    showNum,
    showPerformance,
    showSpend,
  } = params;

  const settings = {
    xCategory: xCategory || null,
    yCategory: yCategory || null,
    metricsToDisplay: {
      numOfCreatives: showNum === 'true' || false,
      primaryMetric: showPerformance === 'false' ? false : true,
      spend: showSpend === 'true' || false,
    },
    organizeAndColor: organizeAndColor || 'primaryMetric',
  };

  return settings;
};

export const useHeatmapSettings = () => {
  const { getParams, setParams } = useSearchParams();
  const params = getParams(
    'xCategory',
    'yCategory',
    'organizeAndColor',
    'showNum',
    'showPerformance',
    'showSpend'
  );

  const [settings, dispatchSettings] = useReducer(
    heatmapReducer,
    setDefaults(params)
  );

  useEffect(() => {
    const update = {
      xCategory: settings.xCategory ?? '',
      yCategory: settings.yCategory ?? '',
      showNum: settings.metricsToDisplay.numOfCreatives ?? '',
      showPerformance: settings.metricsToDisplay.primaryMetric ?? '',
      showSpend: settings.metricsToDisplay.spend ?? '',
      organizeAndColor: settings.organizeAndColor ?? '',
    };

    setParams(update);
  }, [settings]);

  return [settings, dispatchSettings];
};

export const useHeatmapData = (
  visuals,
  settings,
  categoriesLookup,
  primary_metric
) => {
  const { xCategory, yCategory, organizeAndColor } = settings;
  const xCategoryObj = categoriesLookup[xCategory];
  const yCategoryObj = categoriesLookup[yCategory];

  const visualsById = useMemo(
    () =>
      visuals.reduce((obj, visual) => {
        obj[visual.id] = visual;
        return obj;
      }, {}),
    [visuals]
  );

  const xAxisLabels = useMemo(
    () => getSortedLabels(xCategoryObj, organizeAndColor, primary_metric),
    [xCategoryObj, organizeAndColor, primary_metric]
  );
  const yAxisLabels = useMemo(
    () => getSortedLabels(yCategoryObj, organizeAndColor, primary_metric),
    [yCategoryObj, organizeAndColor, primary_metric]
  );

  const {
    data,
    maxNumOfCreatives,
    minPerformance,
    maxPerformance,
    maxSpend,
    removedRows,
  } = useMemo(
    () =>
      yAxisLabels.reduce(
        (result, yLabel) => {
          const cells = xAxisLabels.map((xLabel, j) => {
            const sharedVisuals = getSharedVisuals(xLabel, yLabel);

            const spend = sharedVisuals.reduce(
              (total, id) => total + (visualsById[id]?.spend || 0),
              0
            );

            const performance = getAggregate(
              sharedVisuals.reduce((acc, id) => {
                if (visualsById[id]) {
                  acc.push(visualsById[id]);
                }

                return acc;
              }, []),
              primary_metric
            );

            if (performance > 0) {
              if (!result.minPerformance) {
                result.minPerformance = performance;
              } else {
                result.minPerformance = Math.min(
                  result.minPerformance,
                  performance
                );
              }
              result.maxPerformance = Math.max(
                result.maxPerformance,
                performance
              );
            }

            result.maxNumOfCreatives = Math.max(
              result.maxNumOfCreatives,
              sharedVisuals.length
            );
            result.maxSpend = Math.max(result.maxSpend, spend);

            const cell = createCell(
              sharedVisuals,
              visualsById,
              xLabel,
              yLabel,
              primary_metric,
              sharedVisuals.map((id) => visualsById[id])
            );

            return cell;
          });

          const rowHasNonEmptyCells = cells.some((cell) => !cell.isEmpty);

          if (rowHasNonEmptyCells) {
            result.data.push(cells);
          } else {
            result.removedRows.push(yLabel.value);
          }

          return result;
        },
        {
          data: [],
          maxNumOfCreatives: 0,
          minPerformance: 0,
          maxPerformance: 0.001,
          maxSpend: 0,
          removedRows: [],
        }
      ),
    [xAxisLabels, yAxisLabels, visualsById, primary_metric]
  );

  const { filtered, removedIndexes } = filterCols(data);

  return {
    data: filtered,
    maxNumOfCreatives,
    minPerformance,
    maxPerformance,
    maxSpend,
    xAxisLabels: xAxisLabels.filter((_, i) => !removedIndexes.includes(i)),
    yAxisLabels: yAxisLabels.filter(
      (label) => !removedRows.includes(label.value)
    ),
  };
};

const filterCols = (data: [][]) => {
  if (!data?.[0]) {
    return { filtered: data, removedIndexes: [] };
  }
  const columnShouldBeRemoved = new Array(data[0].length).fill(false);
  const removedIndexes = [];

  for (let col = 0; col < data[0].length; col++) {
    columnShouldBeRemoved[col] = data.every((row) => row[col].isEmpty);
    if (columnShouldBeRemoved[col]) {
      removedIndexes.push(col);
    }
  }

  const filtered = data.map((row) =>
    row.filter((_, colIndex) => !columnShouldBeRemoved[colIndex])
  );

  return { filtered, removedIndexes };
};

const getSortedLabels = (categoryObj, organizeAndColor, primary_metric) =>
  categoryObj?.labels
    ?.map((label) => ({ ...label, label: label.name }))
    .sort(sortLabels(organizeAndColor, primary_metric)) || [];

const createCell = (
  sharedVisuals: string[],
  visualsById,
  yLabel,
  xLabel,
  primary_metric: string,
  visuals: VisualObject[]
) => ({
  num: sharedVisuals.length,
  spend: sharedVisuals.reduce(
    (total, id) => total + (visualsById[id]?.spend || 0),
    0
  ),
  performance: getAggregate(visuals, primary_metric),
  xLabel,
  yLabel,
  visuals,
  isEmpty: sharedVisuals.length < 1,
});

const sortLabels = (organizeAndColor, primary_metric) => (a, b) => {
  switch (organizeAndColor) {
    case 'numOfCreatives':
      return b.visuals.length - a.visuals.length;
    case 'spend':
    case 'spendAndPerformance':
      return b.spend - a.spend;
    case 'primaryMetric':
      return sortByPerformance(a, b, primary_metric);
      return b[primary_metric] - a[primary_metric];
    default:
      return 0;
  }
};

const getSharedVisuals = (l1, l2) => {
  return l1.visuals?.reduce((visuals, { id }) => {
    if (l2.visuals?.some((v) => v.id === id)) {
      visuals.push(id);
    }
    return visuals;
  }, []);
};

const sortByPerformance = (a, b, primary_metric) => {
  const col = getCol(false, primary_metric, false);
  const preferLowerValue = primary_metric?.slice(0, 2) === 'cp';
  const sortDesc = (a, b) => b[col] - a[col];
  const sortAsc = (a, b) => a[col] - b[col];

  if (!a[col]) {
    return 1;
  } else if (!b[col]) {
    return -1;
  } else {
    return preferLowerValue ? sortAsc(a, b) : sortDesc(a, b);
  }
};
