import { AssetChats } from './actions';
import { Marketplace } from '../actions';

const INITIAL_STATE = {
  author: '',
  lookup: {},
  ids: [],
};

const CHAT_TYPES = [
  'internal_messages',
  'contractor_messages',
  'client_messages',
];

function makeId(_id, type) {
  return `${_id}:${type}`;
}

function prepChats({ message_author, message_list }) {
  const ids = [];
  const lookup = {};
  let i = 0;

  const boundToAuthor = (message) => ({
    ...message,
    isSelf: message.message_author === message_author,
  });

  if (message_list) {
    const { asset_id, ...rest } = message_list;

    CHAT_TYPES.forEach((type) => {
      const id = makeId(asset_id, type.replace('_messages', ''));

      ids.push(id);
      lookup[id] = (rest[type] || []).map(boundToAuthor);
    });

    i += 1;
  }

  return {
    ids,
    lookup,
  };
}

function appendMessage(lookup, { data: message, meta: { asset_id } }) {
  const { conversation_thread_type } = message;
  const cid = makeId(asset_id, conversation_thread_type);
  const messages = lookup[cid] || [];

  return {
    ...lookup,
    [cid]: [...messages, message],
  };
}

function editMessage(
  lookup,
  {
    data: {
      asset_id,
      conversation_thread_type,
      message_text,
      new_message,
      message_sent_time,
      message_author,
      author_type,
    },
  }
) {
  const cid = makeId(asset_id, conversation_thread_type);
  const messages = lookup[cid];
  let updatedIndex = messages.findIndex((message) => {
    return (
      message.message_text === message_text &&
      message.message_sent_time === message_sent_time
    );
  });

  messages[updatedIndex].message_text = new_message;

  return {
    ...lookup,
    [cid]: messages,
  };
}

function deleteMessage(
  lookup,
  {
    data: {
      asset_id,
      conversation_thread_type,
      message_text,
      message_sent_time,
    },
  }
) {
  const cid = makeId(asset_id, conversation_thread_type);
  const messages = lookup[cid];

  let update = messages.filter((message) => {
    const match =
      message.message_text === message_text &&
      message.message_sent_time === message_sent_time;

    return !match;
  });

  return {
    ...lookup,
    [cid]: update,
  };
}

function replaceMessageWithError(
  lookup,
  {
    data: {
      asset_id,
      conversation_thread_type,
      error_message,
      message_sent_time,
      message_author,
    },
  }
) {
  const cid = makeId(asset_id, conversation_thread_type);
  const messages = lookup[cid];

  return {
    ...lookup,
    [cid]: messages.slice(0, -1).concat({
      message_text: error_message,
      message_author,
      message_sent_time,
      conversation_thread_type,
    }),
  };
}

export function assetChats(
  state = { author: '', lookup: {}, ids: [] },
  action
) {
  switch (action.type) {
    case AssetChats.SET:
      return {
        author: action.data.message_author,
        ...prepChats(action.data),
      };

    case AssetChats.SEND:
      return {
        author: state.author,
        ids: state.ids,
        lookup: appendMessage(state.lookup, action),
      };

    case AssetChats.REMOVE:
      return {
        author: state.author,
        ids: state.ids,
        lookup: replaceMessageWithError(state.lookup, action),
      };

    case AssetChats.EDIT:
      return {
        author: state.author,
        ids: state.ids,
        lookup: editMessage(state.lookup, action),
      };

    case AssetChats.DELETE:
      return {
        author: state.author,
        ids: state.ids,
        lookup: deleteMessage(state.lookup, action),
      };

    case Marketplace.RESET_ASSETS:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
}
