import React, { useEffect, useRef, useState } from 'react';
import { useCreativeAnalyticsGet } from '../../../contexts';
import { VisualsLookup, VisualsLookupItem } from '../../../ReportCard/types';
import LazyLoader from '../../../../../components/LazyLoader';
import {
  useLabelsLookup,
  useViewId,
  useVisualById,
  useVisualLabels,
  useVisuals,
} from '../../../selectors';

type VisualGridItemProps = {
  visual: VisualsLookupItem;
  handleSelectVisual: (id: string) => void;
};

const VisualGridItem = ({
  visual,
  handleSelectVisual,
}: VisualGridItemProps) => {
  const { id, asset_url, visual_type, thumbnail_url } = visual;

  const handleClick = () => {
    handleSelectVisual(id);
  };

  const media =
    visual_type === 'video' ? (
      <video
        className="visual-grid-item__media visual-grid-item__media--video"
        key={asset_url}
        muted
        plays-inline="true"
        poster={thumbnail_url}
      >
        <source src={asset_url} type="video/mp4" />
      </video>
    ) : (
      <img
        className="visual-grid-item__media visual-grid-item__media--image"
        src={asset_url}
        alt={id}
      />
    );

  return (
    <LazyLoader
      className="align-self-center"
      placeholder={
        <div style={{ height: '12rem', backgroundColor: '#f4f5f8' }} />
      }
    >
      <div className="visual-grid-item" onClick={handleClick}>
        {media}
        <div className="visual-grid-item__overlay">
          <div className="visual-grid-item__id">{id}</div>
        </div>
      </div>
    </LazyLoader>
  );
};

export const VisualsBrowser = ({
  handleSelectVisual,
}: {
  handleSelectVisual: (id: string) => void;
}) => {
  const visuals = useVisuals();

  // If visualsLookup is null, undefined, or not an object, render a message instead of the grid
  if (!visuals) {
    return (
      <div className="visuals-browser__message p-3">Invalid visuals data.</div>
    );
  }

  // If visualsLookup is empty, render a message instead of the grid
  if (visuals.length === 0) {
    return <div className="p-3">No visuals found.</div>;
  }

  return (
    <div className="visuals-browser">
      <div className="visuals-grid">
        {visuals.map((visual) => {
          if (!visual) return null;

          return (
            <VisualGridItem
              key={visual.id}
              visual={visual}
              handleSelectVisual={handleSelectVisual}
            />
          );
        })}
      </div>
    </div>
  );
};
