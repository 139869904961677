import React from "react";
import PropTypes from "prop-types";

export const Details = ({
  summary,
  children,
  open = false,
  className = "",
  style = null,
  summaryStyle = null
}) => (
  <details open={open} className={className} style={style}>
    <summary style={summaryStyle}>{summary}</summary>

    {children}
  </details>
);

Details.propTypes = {
  summary: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  open: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  summaryStyle: PropTypes.object
};

export default Details;
