import React, { createContext, useContext } from "react";

export const ClustersContext = createContext(null);

const ClustersContextProvider = ({
  generalLoading,
  preprocessLoading,
  preprocessError,
  preprocessData,
  clustersLoading,
  clustersError,
  clustersData,
  changeClustersLoading,
  changeClustersError,
  changeClustersData,
  handlePreprocess,
  handleFetchClusters,
  handleChangeClusters,
  numberOfClusters,
  setNumberOfClusters,
  shouldRefresh,
  setShouldRefresh,
  preprocessSuccess,
  changeClustersSuccess,
  clustersSuccess,
  children,
}) => {
  return (
    <ClustersContext.Provider
      value={{
        generalLoading,
        preprocessLoading,
        preprocessError,
        preprocessData,
        clustersLoading,
        clustersError,
        clustersData,
        changeClustersLoading,
        changeClustersError,
        changeClustersData,
        handlePreprocess,
        handleFetchClusters,
        handleChangeClusters,
        numberOfClusters,
        setNumberOfClusters,
        shouldRefresh,
        setShouldRefresh,
        preprocessSuccess,
        changeClustersSuccess,
        clustersSuccess,
      }}
    >
      {children}
    </ClustersContext.Provider>
  );
};

export default ClustersContextProvider;

export function useClustersContext() {
  return useContext(ClustersContext);
}
