import { ADNAME_LABELS, adNamesLabelsLookup } from './adNames_Labels';

export const LOOKUPS = 'Lookups';
export const RESET_LOOKUPS = `[${LOOKUPS}] RESET`;
export const CREATE_LOOKUP = `[${LOOKUPS}] CREATE`;
export const DELETE_LOOKUP = `[${LOOKUPS}] DELETE`;

const INITIAL_STATE = {};

export const resetLookups = () => ({
  type: RESET_LOOKUPS,
  meta: { entity: LOOKUPS },
});

export const deleteLookup = ({ name }) => ({
  type: DELETE_LOOKUP,
  data: { name },
  meta: { entity: LOOKUPS },
});

export const createLookup = ({ name, lookup }) => ({
  type: CREATE_LOOKUP,
  data: { name, lookup },
  meta: { entity: LOOKUPS },
});

export const createAdNamesLabelsLookup = (data) =>
  createLookup({ name: ADNAME_LABELS, lookup: adNamesLabelsLookup(data) });

export function lookupReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESET_LOOKUPS:
      return INITIAL_STATE;

    case DELETE_LOOKUP:
      return removeKey(state, action.data.name);

    case CREATE_LOOKUP:
      const { name, lookup } = action.data;

      return { ...state, [name]: lookup };

    default:
      return state;
  }
}
