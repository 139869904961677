import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { format$ } from './utils';

export const CpaDisplay = ({ label, difference }) => {
  const { chart_type, selected_type, asset_url } = useSelector(
    ({ CreativeReports: { chart_type, selected_type, asset_url } }) => {
      return {
        chart_type,
        selected_type,
        asset_url,
      };
    }
  );
  const isVisual =
    chart_type === 'performance_frequency' && selected_type === 'visual_hash';

  if (!difference) {
    return (
      <div className="right-display__card">
        <h2 className="right-display__title mb-0">{label}</h2>
      </div>
    );
  }

  const descriptor = parseFloat(difference) > 0 ? 'better' : 'worse';

  const parsed = parseFloat(difference);
  let diffClass = 'right-display__score--average';

  if (parsed >= 10) {
    diffClass = 'right-display__score--good';
  } else if (parsed <= -10) {
    diffClass = 'right-display__score--poor';
  }

  let labelText = label;
  let subject = 'element';

  if (isVisual && asset_url) {
    labelText = `Visual Hash - ${label}`;
    if (asset_url) {
      subject = (
        <a href={asset_url} target="_blank">
          visual
        </a>
      );
    }
  }

  let score = (
    <span className={`right-display__score ${diffClass}`}>
      {difference.replace('-', '')} {descriptor}
    </span>
  );

  return (
    <div className="right-display__card">
      {/* <h2 className="right-display__title">{labelText}</h2> */}
      <h3 className="right-display__subtitle">
        This {subject} is performing {score} than other elements.
      </h3>
    </div>
  );
};

CpaDisplay.defaultProps = {
  difference: '',
};

CpaDisplay.propTypes = {
  difference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const FrequencyDisplay = ({ label, ratio }) => {
  const parsed = parseFloat(ratio);
  let diffClass = 'right-display__score--average';

  if (parsed >= 50) {
    diffClass = 'right-display__score--good';
  } else if (parsed < 50) {
    diffClass = 'right-display__score--poor';
  }

  let score = (
    <span className={`right-display__score ${diffClass}`}>{ratio}%</span>
  );

  return (
    <div className="right-display__card">
      <h2 className="right-display__title d-inline">{label}</h2>
      <h3 className="right-display__subtitle d-inline">
        &nbsp;ads perform better than average in {score} of creative tests.
      </h3>
    </div>
  );
};

FrequencyDisplay.propTypes = {
  label: PropTypes.string.isRequired,
  ratio: PropTypes.number.isRequired,
};

const TableDisplay = ({
  title,
  rows,
  placeholder = null,
  colors: { tertiary },
  outcomesLoading,
}) => {
  const { chart_type, selected_type, asset_url, isROAS } = useSelector(
    ({
      CreativeReports: { chart_type, selected_type, asset_url },
      ViewSettings: { isROAS },
    }) => {
      return {
        chart_type,
        selected_type,
        asset_url,
        isROAS,
      };
    }
  );
  const isVisual =
    chart_type === 'performance_frequency' && selected_type === 'visual_hash';

  const Header = (
    <div className="display-table__header">
      <p className="display-table__type">{isVisual ? 'Visual' : 'Type'}</p>
      <p className="display-table__num">{isROAS ? 'ROAS' : 'CPA'}</p>
      <p className="display-table__num">Spend</p>
    </div>
  );

  const Rows = rows.map(({ type, spend, cpa, roas }, i) => {
    let aType = '';

    if (type) {
      if (type.length > 17) {
        aType = `${type.slice(0, 14)}...`;
      } else {
        aType = type;
      }
    }

    return (
      <div
        key={`${type}-${cpa}-${i}`}
        className="display-table__values d-flex flex-row"
      >
        <p className="display-table__type" title={type}>
          {isVisual && asset_url && i === 0 ? (
            <a target="_blank" href={asset_url}>
              {aType}
            </a>
          ) : (
            aType
          )}
        </p>
        <p className="display-table__num">
          {outcomesLoading ? 'Calculating...' : (isROAS ? cpa : format$(cpa))}
        </p>
        <p className="display-table__num">
          {outcomesLoading ? 'Calculating...' : format$(spend)}
        </p>
      </div>
    );
  });

  return (
    <div className="right-display__card">
      <p className="display-table__heading">{title}</p>

      {Header}

      {placeholder || <div>{Rows}</div>}
    </div>
  );
};

TableDisplay.defaultProps = {
  placeholder: null,
};

TableDisplay.propTypes = {
  title: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      spend: PropTypes.string,
      cpa: PropTypes.string,
    })
  ).isRequired,
  colors: PropTypes.shape({
    tertiary: PropTypes.string.isRequired,
  }).isRequired,
  placeholder: PropTypes.node,
};

export default TableDisplay;
