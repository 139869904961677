import { useRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { abortableFetch, delay } from '../../../../../utils';
import {
  useApiState,
  bindFetchToRef,
} from '../../../../../components/Hooks/useApiState';
import { useNotifications } from '../../../../../components/Notification/useNotifications';
import { notifyCreateSuccess, notifyDeleteSuccess } from './utils';
import { usePostRequest } from '../../../../../components/Hooks/usePostRequest';
import { useDispatch } from 'react-redux';
import { nanoid } from 'nanoid';

const HEADERS = { 'content-type': 'application/json' };

export const saveDerivedLabelsCategory = (payload) => {
  return abortableFetch(
    [
      'api',
      'creative-analytics',
      'custom-categories',
      'derived-labels',
      payload.platform_account_id,
      'save',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
};

export const deleteDerivedLabelsCategory = (payload) => {
  return abortableFetch(
    [
      'api',
      'creative-analytics',
      'custom-categories',
      'derived-labels',
      payload.platform_account_id,
      'delete',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
};

const fetchDerivativeCategories = (platform_account_id) => {
  return abortableFetch(
    [
      'api',
      'creative-analytics',
      'custom-categories',
      'derived-labels',
      platform_account_id,
      'fetch-derivative-categories',
    ].join('/'),
    {
      method: 'GET',
      headers: HEADERS,
      credentials: 'include',
    }
  );
};

export const useDerivedLabelsApi = (fetchPerformanceData) => {
  const { add: addNotification } = useNotifications();
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) =>
      withRef(saveDerivedLabelsCategory(payload)).then((data) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );
  const [deleteState, deleteDispatch] = useApiState(
    (payload) =>
      withRef(deleteDerivedLabelsCategory(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  const [getState, getDispatch] = useApiState(
    (platform_account_id) =>
      withRef(fetchDerivativeCategories(platform_account_id)).then(
        ({ data }) => data
      ),
    {
      data: null,
      error: null,
      loading: true,
      count: 0,
    }
  );

  useEffect(() => {
    if (postState.data) {
      notifyCreateSuccess(fetchPerformanceData);
    }
  }, [postState.data]);

  useEffect(() => {
    if (postState.loading) {
      addNotification({
        id: `derived-category-loading`,
        title: 'Please wait',
        message: 'Saving category',
        showEllipses: true,
      });
    } else {
      toast.dismiss('derived-category-loading');
    }
  }, [postState.loading]);

  useEffect(() => {
    if (postState.error) {
      addNotification({
        id: `derived-category-error`,
        title: 'There was an error',
        message: postState.error.toString(),
      });
      toast.dismiss('derived-category-loading');
    }
  }, [postState.error]);

  useEffect(() => {
    if (deleteState.data) {
      notifyDeleteSuccess(fetchPerformanceData);
      toast.dismiss('derived-category-loading');
    }
  }, [deleteState.data]);

  useEffect(() => {
    if (deleteState.loading) {
      addNotification({
        id: `derived-category-delete-loading`,
        title: 'Please wait',
        message: 'Deleting category',
        showEllipses: true,
      });
    } else {
      toast.dismiss('derived-category-delete-loading');
    }
  }, [deleteState.loading]);

  useEffect(() => {
    if (deleteState.error) {
      addNotification({
        id: `derived-category-delete-error`,
        title: 'There was an error',
        message: deleteState.error.toString(),
      });
      toast.dismiss('derived-category-delete-loading');
    }
  }, [deleteState.error]);

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: postDispatch,
    },
    delete: {
      ...deleteState,
      request: deleteDispatch,
    },
  };
};

export const useGetDerivedLabelsApi = () => {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (platform_account_id) =>
      withRef(fetchDerivativeCategories(platform_account_id)).then(
        ({ data }) => data
      ),
    {
      data: null,
      error: null,
      loading: true,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    get: {
      ...getState,
      request: getDispatch,
    },
  };
};

export const save = (payload) => {
  return fetch(
    [
      'api',
      'creative-analytics',
      'custom-categories',
      'save-derived-label',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
};

const pollStatus = async (
  request_id,
  handleData,
  handleError,
  startLoading,
  stopLoading
) => {
  let continuePolling = true;

  const poll = async () => {
    if (!continuePolling) {
      return;
    }

    try {
      const response = await fetch(
        [
          'api',
          'creative-analytics',
          'custom-categories',
          'derived-labels',
          'check-status',
          request_id,
        ].join('/'),
        {
          method: 'GET',
          headers: HEADERS,
          credentials: 'include',
        }
      );

      const jsonData = await response.json();

      const data = jsonData?.data || jsonData;

      if (data?.status === 'IN_PROGRESS') {
        console.log('Data loading...');
        setTimeout(poll, 1000);
      } else if (data?.status === 'DONE') {
        handleData(data);
        stopLoading();
        return;
      } else {
        console.error('Error:', data);
        handleError(data.error);
        stopLoading();
        return;
      }
    } catch (error) {
      console.error('Error:', error);
      handleError(error);
      stopLoading();
      return;
    }
  };

  startLoading();
  poll();

  return {
    abort: () => {
      continuePolling = false;
    },
  };
};

export const usePolling = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const cache = useRef(null);
  const notifications = useNotifications();

  const currentPollingControl = useRef(null);

  const stopPolling = () => {
    if (currentPollingControl?.current?.abort) {
      currentPollingControl.current.abort();
    }
  };

  const fetchData = async (payload) => {
    const request_id = nanoid();
    stopPolling();

    // if not, send request and poll for response
    try {
      const res = await save({
        ...payload,
        request_id,
      });
      const json = await res.json();
      await delay(5000);
      currentPollingControl.current = await pollStatus(
        request_id,
        (data) => setData(data),
        (error) => setError(error),
        () => setLoading(true),
        () => {
          setLoading(false);
          toast.dismiss('derived-category-loading');
        }
      );
    } catch (error) {
      console.log('Error fetching data:', error);
      console.error('Error fetching data:', error);
    }
  };

  const request = (payload) => {
    setLoading(true);
    setError(null);
    setData(null);
    fetchData(payload);
  };

  return {
    postState: {
      data,
      loading,
      error,
    },
    postDispatch: request,
    stopPolling,
  };
};

export const useSaveSingleDerivedLabelApi = () => {
  const {
    postState: { data, loading, error },
    postDispatch,
    stopPolling,
  } = usePolling();

  return { data, loading, error, request: (payload) => postDispatch(payload) };
};
