import React from 'react';
import { getLabelsLookup, makeFormattedDates } from '../../Presets/utils';
import Tooltip from '../../../../components/Tooltip';
import { toInt } from '../../../../utils/numbers';

const DropdownTooltip = ({ preset, metricLabel, filteredData }) => {
  const {
    name,
    description,
    period1,
    period2,
    audience,
    spendThreshold,
    labels: labelsParam,
    fix_dates,
  } = preset;

  let labels = [];
  if (labelsParam && labelsParam.length && filteredData.length) {
    labels = labelsParam.split(',').map((l) => {
      const foundLabel = getLabelsLookup(filteredData, l);
      if (l === 'top_10_spending') return 'Top 10 Spending';
      return foundLabel?.name || l;
    });
  }

  const [startDate1, endDate1] = makeFormattedDates(period1);
  const [startDate2, endDate2] = makeFormattedDates(period2);

  return (
    <>
      <Tooltip
        className="copilot-dropdown-tooltip"
        content={
          <div>
            {description && (
              <div className="field">
                <span>{description}</span>
              </div>
            )}
            <div className="field">
              <div className="label">Date range</div>
              {fix_dates ? (
                <>
                  <div>
                    {`${period2 ? 'Period 1: ' : ''}`}
                    <span>
                      {startDate1} - {endDate1}
                    </span>
                  </div>
                  {period2 && (
                    <div>
                      Period 2:{' '}
                      <span>
                        {startDate2} - {endDate2}
                      </span>
                    </div>
                  )}
                </>
              ) : (
                'No fixed dates'
              )}
            </div>
            <div className="field">
              <div className="label">Spend threshold</div>
              <span>${toInt(spendThreshold)}</span>
            </div>
            <div className="field">
              <div className="label">Audience</div>
              <span>{audience ? audience : 'All audiences'}</span>
            </div>
            <div className="field">
              <div className="label">Primary metric</div>
              <span>{metricLabel}</span>
            </div>
          </div>
        }
      >
        <div>{name}</div>
      </Tooltip>
    </>
  );
};

export default DropdownTooltip;
