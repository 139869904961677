export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';

/**
 * @param {Object} conf
 * @param {String} conf.message
 * @param {String | Number} conf.id
 * @param {String} conf.title
 * @param {"success" | "danger" | "warning" | "info"} conf.status
 * @param {Number} [conf.delay=5000] conf.delay
 * @param {String} entity
 */
export const addNotification = (
  { message, status, title, id, delay = 5000 },
  entity
) => ({
  type: `[${entity}] ${ADD_NOTIFICATION}`,
  id,
  delay,
  message,
  title,
  status,
  meta: { entity },
});

export const removeNotification = (id, entity) => ({
  type: `[${entity}] ${REMOVE_NOTIFICATION}`,
  id,
  meta: { entity },
});

export const showNotification = ({ id, message, status }, entity) => ({
  type: `[${entity}] ${SHOW_NOTIFICATION}`,
  id,
  message,
  status,
  meta: { entity },
});
