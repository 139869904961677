const HEADERS = {
  'Content-Type': 'application/json',
};

const atPath = (viewId) => ['api', viewId, 'bulk-audience'].join('/');

const transformAdsetsFromAPI = (adsets) =>
  adsets.map(({ adset_labels, ...rest }) => ({
    ...rest,
    adset_labels: !adset_labels
      ? []
      : adset_labels.map((label) => ({ label, value: label })),
  }));

const transformAdsetsToAPI = (adsets) =>
  adsets.map((adset) => ({
    ...adset,
    adset_labels: adset.adset_labels.map((label) => label.value),
    adset_id: adset.adset_id.toString(), // The GET route returns as numbers but the POST wants strings (?)
  }));

const getAdsets = async (viewId) => {
  const res = await fetch(atPath(viewId), {
    method: 'GET',
    credentials: 'include',
    headers: HEADERS,
  });
  const { data } = await res.json();

  return transformAdsetsFromAPI(data);
};

const saveAdsets = async (viewId, adsets) => {
  const requestBody = {
    adsets: transformAdsetsToAPI(adsets),
  };

  const res = await fetch(atPath(viewId), {
    method: 'POST',
    body: JSON.stringify(requestBody),
    credentials: 'include',
    headers: HEADERS,
  });
  const json = await res.json();

  if (res.status >= 300 || !!json.error) {
    return Promise.reject(json);
  }
  return res;
};

export default {
  getAdsets,
  saveAdsets,
};
