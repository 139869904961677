import React from "react";
import PropTypes from "prop-types";

const HEIGHT = { height: "38px" };

const INPUT_STYLE = {
  width: "80%",
  ...HEIGHT
};

const BudgetPicker = ({ budget, handleBudget }) => (
  <div className="d-flex flex-row justify-content-between align-items-baseline">
    <label>Daily Budget</label>

    <div className="input-group mb-3" style={INPUT_STYLE}>
      <div className="input-group-prepend">
        <span className="input-group-text">$</span>
      </div>
      <input
        style={HEIGHT}
        type="number"
        className="form-control"
        aria-label="Amount (to the nearest dollar)"
        value={budget}
        onChange={handleBudget}
      />
    </div>
  </div>
);

BudgetPicker.propTypes = {
  budget: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleBudget: PropTypes.func.isRequired
};

export default BudgetPicker;
