import {
  findSynonymOthers,
  shouldIncludeLabel,
  updateSynonymLabel,
} from '../../../filter-utils';
import { LabelObject } from '../../../types';

export const handleSynonymLabel = (
  label,
  allVisualsLabel,
  filteredVisualIds: string[],
  sumsAndAverages,
  addOthersLabel: (synonymOthersLabel: LabelObject) => void,
  isROAS,
  originalLabelData,
  categoryFilters
) => {
  let synonymLabel = null;

  if (allVisualsLabel) {
    synonymLabel = findSynonymOthers(
      label,
      allVisualsLabel,
      sumsAndAverages.sumSpend
    );
  }

  if (!synonymLabel) return;

  const synUpdatedWinners = synonymLabel.winners.filter((x) =>
    filteredVisualIds.includes(x.id)
  );
  const synUpdatedLosers = synonymLabel.losers.filter((x) =>
    filteredVisualIds.includes(x.id)
  );
  const synUpdatedVisuals = [...synUpdatedWinners, ...synUpdatedLosers];

  const shouldInclude = shouldIncludeLabel(synonymLabel, categoryFilters);
  const updatedSynonymLabel = updateSynonymLabel(
    synonymLabel,
    synUpdatedWinners,
    synUpdatedLosers,
    synUpdatedVisuals,
    sumsAndAverages.sumSpend,
    sumsAndAverages.sumResults,
    sumsAndAverages.sumClicks,
    isROAS,
    originalLabelData
  );

  if (
    shouldInclude &&
    (synUpdatedVisuals.length >= 0 || label.testing_opportunity)
  ) {
    addOthersLabel(updatedSynonymLabel);
  }
};
