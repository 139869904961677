import React from 'react';
import PropTypes from 'prop-types';

import { ErrorBoundary, BasicError } from '../Helpers/Error';
import { BasicLoading } from '../Helpers/Loading';
import { Placeholder, deriveOptions } from './Helpers';

const withApi = (Comp) => {
  class AdPreviewsApi extends React.Component {
    componentDidMount() {
      const { fetchAdPreview } = this.props;
      fetchAdPreview();
    }

    renderPreview() {
      const { hasError, isLoading, preview, height, width } = this.props;

      if (hasError) {
        return <BasicError />;
      }
      if (isLoading) {
        return <BasicLoading fallback={<Placeholder />} />;
      }
      return (
        <Comp
          options={deriveOptions(preview.preview_list)}
          height={height}
          width={width}
        />
      );
    }

    render() {
      return <ErrorBoundary>{this.renderPreview()}</ErrorBoundary>;
    }
  }

  AdPreviewsApi.defaultProps = {
    isLoading: false,
    hasError: false,
    preview: [],
  };

  AdPreviewsApi.propTypes = {
    fetchAdPreview: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    hasError: PropTypes.bool,
    preview: PropTypes.shape({
      ad_name: PropTypes.string,
      preview_list: PropTypes.arrayOf(PropTypes.string),
    }),
  };

  return AdPreviewsApi;
};

export default withApi;
