import React, { useMemo, useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import Tooltip from '../../../../../components/Tooltip';
import { DetailDataItem, SummaryDataItem } from '../../../types/types';
import { toCurrency } from '../../../../../utils/numbers';
import { Section } from '../../shared/Helpers';
import { CreativePerformance, SortToggle } from './CreativePerformance';
import IconButton from '../../../../../components/IconButton';
import { exportToCSV } from '../../../utils/export';
import { ShareReport } from '../../../../DNA/ShareReport';
import format from 'date-fns/format';
import { SELECT_STYLES } from '../../../../../constants';
import { DataMode, DATASET_MAPPING, getDataModeColumns } from './DataMode';
import { useAnalyticsUrlProps } from '../../../../CreativeAnalytics/PivotReport/hooks';
import { useSearchParams } from '../../../../../components/Hooks/search-params';
import {
  CREATIVE_DATA_CSV_EXPORT,
  REPORT_BUILDER,
} from '../../../../../store/UserActivity/constants';
import { useTrackEvent } from '../../../../CreativeAnalytics/hooks/useTrackEvent';
import { ColumnPicker } from '../../shared/ColumnPicker';
import DisplaySelect from './DisplaySelect';
import { useShareStaticDetailData } from '../../../hooks/useShareStaticDetailData';
import { VisualsByLabel } from './VisualsByLabel';
import { useSaveOverridesApi } from '../../../../CreativeAnalytics/Explorer/api';
import { OverridesSelect } from './Overrides';
import { BasicLoading } from '../../../../../components/Helpers/Loading';
import { DetailDataHeader } from './components/DetailDataHeader';

import {
  useOverridesNavigation,
  useSafeLabel,
  useOverrides,
  useVisualsData,
  useLabelVisualsData,
  useCategoryData,
} from './hooks';
import { convertFormat1ToFormat2 } from './utils/formatUtils';
import { getColumnOptions } from './utils/metricUtils';
import { DetailDataToolbar } from './Toolbar';

export const SECTION_STYLE = {
  flex: 1,
  padding: '1rem',
  background: 'white',
  margin: '1rem',
  borderRadius: '5px',
  boxShadow: '3px 3px 2px #e4ebf4',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
};

const baseDetailCols = [
  {
    Header: 'Ad Name',
    accessor: 'ad_name',
    maxWidth: 200,
    Cell: ({ row }) => (
      <a
        href={row.original.s3_url}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 underline"
      >
        {row.original.ad_name}
      </a>
    ),
  },
  { Header: 'Ad Name Group', accessor: 'ad_name_group' },
  {
    Header: 'Total Spend',
    accessor: 'total_spend',
    Cell: ({ value }) => toCurrency(value),
  },

  {
    Header: 'Has Chosen Label',
    accessor: 'has_chosen_label',
    Cell: ({ value }) => (value ? 'Yes' : 'No'),
  },
  {
    Header: 'Has Comparison Label',
    accessor: 'has_comparison_label',
    Cell: ({ value }) => (value ? 'Yes' : 'No'),
  },
  {
    Header: 'Total CPC',
    accessor: 'total_cpc',
    Cell: ({ value }) => toCurrency(value),
  },
  {
    Header: 'CVR',
    accessor: 'CVR',
  },
  { Header: 'Total Link Clicks', accessor: 'total_link_clicks' },
];

const roasDetailCols = [
  {
    Header: 'ROAS',
    accessor: 'total_roas',
    Cell: ({ value }) => value?.toFixed(2) || value || '-',
  },
  {
    Header: 'Total Revenue',
    accessor: 'total_revenue',
    Cell: ({ value }) => toCurrency(value),
  },
];

const cpaClientDetailCols = [
  {
    Header: 'Total CPA',
    accessor: 'total_cpa',
    Cell: ({ value }) => toCurrency(value),
  },
  { Header: 'Total Results', accessor: 'total_results' },
];

const useDetailColumns = (isROAS: boolean = false) => {
  return useMemo(() => {
    if (isROAS) {
      return [...baseDetailCols, ...roasDetailCols];
    }

    return [...baseDetailCols, ...cpaClientDetailCols];
  }, [isROAS]);
};

type DetailDataProps = {
  selectedRow: SummaryDataItem;
  detail_data: DetailDataItem[];
  columns: [];
  exportFileName: string;
  dateRange?: any;
  isROAS?: boolean;
  renderAnalyticsToggle?: () => React.ReactNode;
  darwinIqApi?: any;
  darwinIqData?: any;
  mode?: string;
  allowDataMode?: boolean;
  visual_id_data?: any[];
  fetchAnalyticsData?: () => void;
};

export const DetailData = ({
  selectedRow,
  detail_data,
  exportFileName,
  dateRange,
  isROAS = false,
  renderAnalyticsToggle = () => null,
  darwinIqApi = { loading: false },
  darwinIqData = {},
  mode = 'creative',
  allowDataMode = true,
  visual_id_data = [],
  fetchAnalyticsData = () => {},
}: DetailDataProps) => {
  const saveOverridesApi = useSaveOverridesApi({
    successTitle: 'Success!',
    successMsg: (
      <div className="toast__content">
        <h3 className="toast__title">Success!</h3>
        <h4 className="toast__subtitle">
          Override saved.{' '}
          <span
            style={{
              color: '#e1e3ff',
              cursor: 'pointer',
            }}
            onClick={() => fetchAnalyticsData(true)}
          >
            Refresh
          </span>{' '}
          to see results.
        </h4>
      </div>
    ),
  });

  const account_id = useSelector((state: any) => state.ViewSettings.account_id);
  const trackEvent = useTrackEvent();
  const { setParams } = useSearchParams();

  const {
    dataset,
    columns: selectedColumnsParam,
    categories: selectedCategories,
  } = useAnalyticsUrlProps();

  const {
    unsavedOverrides,
    resetOverridesState,
    setMode,
    setDataset,
    handleUnsavedOverridesChange,
    resetOverrides,
  } = useOverridesNavigation(mode, dataset);

  const { selectedLabel, setSelectedLabel, safeSetSelectedLabel } =
    useSafeLabel();

  const datasetConfig = DATASET_MAPPING[dataset] || {};
  const {
    selectedCategory,
    selectedLabelName,
    category_id,
    selectedCategoryData,
    data,
    dataByCategory,
  } = useCategoryData(
    selectedLabel,
    darwinIqData,
    datasetConfig,
    selectedCategories
  );

  const { handleOverride } = useOverrides(
    selectedLabel,
    darwinIqData,
    account_id,
    saveOverridesApi
  );

  const columns = useDetailColumns(isROAS);
  const [sort, setSort] = useState({
    sortBy: 'total_spend',
    desc: true,
  });

  const defaultColumn = useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 200,
    }),
    []
  );

  const { rows } = useTable(
    { columns, data: detail_data, defaultColumn },
    useSortBy
  );

  const {
    shouldShowShareModal,
    openShareModal,
    closeShareModal,
    submitShareReport,
    sharedReportId,
    loadingShareReport,
  } = useShareStaticDetailData(isROAS);

  const colOpts = getColumnOptions(isROAS);

  const handleColumnChange = (selectedOptions) => {
    setParams({ columns: selectedOptions.join(',') });
  };

  const selectedColumns = getDataModeColumns(
    dataset,
    selectedColumnsParam,
    isROAS
  );

  const dataModeColumns = useMemo(
    () => datasetConfig.columns || [],
    [datasetConfig]
  );

  const visibleColumns = useMemo(
    () =>
      !selectedColumns.length
        ? dataModeColumns
        : dataModeColumns.filter((col) =>
            selectedColumns.includes(col.accessor)
          ),
    [dataModeColumns, selectedColumns]
  );

  const visuals = useVisualsData(detail_data, selectedRow, sort);
  const visualsData = useLabelVisualsData(
    darwinIqData,
    selectedLabel,
    dataByCategory
  );

  const visualIdData = useMemo(() => {
    return visual_id_data?.map((obj) => ({
      ...obj,
      array_of_ad_names: obj.array_of_ad_names || obj['Ad Name'],
    }));
  }, [visual_id_data]);

  const current = useMemo(() => {
    if (!selectedLabel) return null;
    return {
      label: convertFormat1ToFormat2(selectedLabel),
      value: convertFormat1ToFormat2(selectedLabel),
    };
  }, [selectedLabel]);

  const options = useMemo(() => {
    if (!selectedLabel) return [];
    return (
      selectedCategoryData?.category_labels?.map((label) => ({
        label,
        value: label,
      })) || []
    );
  }, [selectedCategoryData?.category_labels]);

  // console.log({
  //   visualsData,
  //   visualIdData,
  //   current,
  //   detail_data,
  //   visual_id_data,
  //   darwinIqApi,
  // });

  // visualIdData.forEach((visual) => {
  //   if (visual.visual_id === '674256555388608') {
  //     console.log(visual);
  //   }
  // });

  // visualsData.forEach((visual) => {
  //   if (visual.darwin_visual_id === '674256555388608') {
  //     console.log(visual);
  //   }
  // });
  const handleExportCSV = () => {
    trackEvent(REPORT_BUILDER, CREATIVE_DATA_CSV_EXPORT);
    exportToCSV(rows, exportFileName, [], isROAS);
  };

  const renderView = (mode) => {
    if (mode === 'creative') {
      return (
        <CreativePerformance
          data={visuals}
          columns={[`total_${isROAS ? 'roas' : 'cpa'}`, 'total_spend']}
          sort={sort}
          setSort={setSort}
          columnOptions={colOpts}
          visual_id_data={visualIdData}
        />
      );
    } else if (mode === 'data') {
      return (
        <DataMode
          allowDataMode={allowDataMode}
          darwinIqData={darwinIqData}
          loading={darwinIqApi.loading}
          error={darwinIqApi.error}
          selectedColumns={selectedColumns}
          visibleColumns={visibleColumns}
          selectedRow={selectedRow}
          selectedLabel={selectedLabel}
          setSelectedLabel={(newLabel) =>
            safeSetSelectedLabel(newLabel, unsavedOverrides, () =>
              resetOverrides()
            )
          }
          dataByCategory={dataByCategory}
        />
      );
    }
    return null;
  };

  if (darwinIqApi.loading) {
    return <BasicLoading />;
  }

  return (
    <div
      className="d-flex flex-column flex-lg-row"
      style={{ height: '100%', gap: '1rem', margin: '1rem' }}
    >
      <div
        style={{
          flex: 1,
          minWidth: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Section
          id="detail-data"
          style={{
            ...SECTION_STYLE,
            margin: '1rem 0',
          }}
          title={
            <DetailDataToolbar
              mode={mode}
              dataset={dataset}
              setMode={setMode}
              setDataset={setDataset}
              selectedColumns={selectedColumns}
              dataModeColumns={dataModeColumns}
              handleColumnChange={handleColumnChange}
              handleExportCSV={handleExportCSV}
              openShareModal={openShareModal}
              renderAnalyticsToggle={renderAnalyticsToggle}
              sort={sort}
              setSort={setSort}
            />
          }
        >
          <div style={{ flex: 1, minHeight: 0, overflow: 'auto' }}>
            {renderView(mode)}
          </div>
        </Section>
      </div>

      {!!selectedLabel && mode === 'data' && dataset === 'spend_based' && (
        <div
          style={{
            flex: 1,
            minWidth: 0,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Section style={{ ...SECTION_STYLE, margin: '1rem 0' }}>
            <div style={{ flex: 1, minHeight: 0 }}>
              <VisualsByLabel
                selectedLabel={selectedLabel}
                visualsData={visualsData}
                sort={sort}
                handleOverride={handleOverride}
                selectedCategoryData={selectedCategoryData}
                onUnsavedOverridesChange={handleUnsavedOverridesChange}
                resetOverridesState={resetOverridesState}
                overridesSelect={(
                  visual,
                  onOverrideChange,
                  onOverrideSave,
                  hasUnsavedChanges,
                  onOverrideCancel
                ) => {
                  return (
                    <div className="overrides">
                      <div className="d-flex justify-content-between">
                        <label>Override label:</label>
                        <p>{}</p>

                        {hasUnsavedChanges && (
                          <Tooltip content="Unsaved override">
                            <div className="unsaved-indicator">
                              <i className="fa-solid fa-circle-exclamation"></i>
                            </div>
                          </Tooltip>
                        )}
                      </div>
                      <OverridesSelect
                        visual_id={visual.darwin_visual_id}
                        current={current}
                        options={options}
                        handleSaveOverride={handleOverride}
                        onOverrideChange={onOverrideChange}
                        onOverrideSave={onOverrideSave}
                        onOverrideCancel={onOverrideCancel}
                        loading={saveOverridesApi.loading}
                        hasUnsavedChanges={hasUnsavedChanges}
                      />
                    </div>
                  );
                }}
              />
            </div>
          </Section>
        </div>
      )}

      {!!shouldShowShareModal && (
        <ShareReport
          close={closeShareModal}
          submit={({ report_name, report_description, elementsToInclude }) =>
            submitShareReport({
              data: visuals,
              columns: [`total_${isROAS ? 'roas' : 'cpa'}`, 'total_spend'],
              sort,
              report_name,
              report_description,
              elementsToInclude,
              start_date: format(new Date(dateRange.start), 'yyyy-MM-dd'),
              end_date: format(new Date(dateRange.end), 'yyyy-MM-dd'),
              columnOptions: colOpts,
            })
          }
          sharedReportId={sharedReportId}
          loading={loadingShareReport}
          categorySettings={{}}
          location={{}}
          sharePayloadError={null}
        />
      )}
    </div>
  );
};
