import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';

import { asAsync } from '../../../utils';
import { DARWIN_RED } from '../../../constants';

class Chart extends React.Component {
  constructor(props) {
    super(props);

    this.chartId = this.props.id;

    this.chartRef = React.createRef();

    this.state = {
      // Highchart Object
      chart: null,
      // { type: String, display: [...String] }
      selected: []
    };

    /**
     * @param {[Object]} params list of parameters for wrapped function
     * @param {Function} callback Any -> Any
     * @param {Number} delay
     */
    this.$setChart = asAsync(() => this.setChart(), 200);
  }

  componentWillUnmount() {
    this.state.chart && this.state.chart.destroy();
    this.$setChart = null;
  }

  componentDidMount() {
    this.$setChart();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // chart manipulates the DOM directly,
    // so nextState isn't important to keep
    // track of
    return (
      nextProps.selectedId !== this.props.selectedId ||
      nextProps.data.length !== this.props.data.length
    );
  }

  componentDidUpdate() {
    this.$setChart();
  }

  /**
   * @description Destroys current chart (if it exists) and
   * creates a new one, mutating the DOM in the process.
   */
  setChart() {
    // prevent operation on unmounted component
    if (this.chartRef.current === null) {
      return null;
    } else {
      if (this.state.chart) {
        this.state.chart.destroy();
      }

      const chart = createChart(this.chartId, {
        onSeriesClick: S => this.props.handleSeriesClick(S),
        series: this.props.data
      });

      this.setState({ chart });
    }
  }

  render() {
    return <div ref={this.chartRef} id={this.chartId} />;
  }
}

Chart.propTypes = {
  id: PropTypes.string.isRequired,
  handleSeriesClick: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number.isRequired,
      label: PropTypes.string,
      color: PropTypes.string
    })
  ).isRequired,
  selectedId: PropTypes.string
};

export default Chart;

const createChart = (chartId, { series, showLabels, onSeriesClick }) => {
  return Highcharts.chart(chartId, {
    series: [{ data: series }],
    title: {
      text: ''
    },
    xAxis: {
      title: {
        enabled: true,
        text: 'Spend'
      }
    },
    yAxis: {
      title: {
        enabled: true,
        text: 'CPR'
      }
    },
    chart: {
      type: 'scatter'
    },
    legend: {
      enabled: false
    },

    plotOptions: {
      scatter: {
        tooltip: {
          headerFormat: '',
          pointFormatter() {
            const opts = this.options;
            return `
              <b>${opts.label}</b>
              <br />
              Spend: $${opts.x.toFixed(2)}
              <br />
              CPR: $${opts.y.toFixed(2)}
              <br />
              Period: ${opts.period}
            `;
          }
        }
      },
      series: {
        allowPointSelect: true,
        marker: {
          states: {
            select: {
              fillColor: DARWIN_RED,
              lineWidth: 0
            }
          }
        },
        dataLabels: {
          enabled: showLabels, // update dynamically
          formatter() {
            return this.point.label.slice(0, 20);
          },
          x: 15,
          y: -10
        },
        scatter: {
          marker: {
            radius: 5
          }
        },
        events: {
          click(e) {
            e.point.color = DARWIN_RED;

            const {
              x,
              y,
              label,
              campaign_id,
              adset_id,
              period
            } = e.point.options;

            onSeriesClick({
              period,
              spend: x,
              cpr: y,
              label,
              value: label,
              campaign_id,
              adset_id
            });
          }
        },
        className: 'ui-notify-point'
      }
    }
  });
};
