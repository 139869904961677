import React, { useEffect, useState } from 'react';
import Overlay from '../../../../components/Overlay';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';

import './styles.scss';
import { Form } from 'react-bootstrap';
import { useRerunForAccount, useRerunLabelsApi } from './api';
import { useCreativeAnalyticsGet } from '../../contexts';
import {
  notifyError,
  useNotifications,
} from '../../../../components/Notification/useNotifications';

const KeywordSettings = ({
  setShowKeywordSettingsModal,
  visual_hash,
  view_id,
  phraseData,
  current,
}) => {
  const { searchTermsData } = useCreativeAnalyticsGet();
  const rerunKeywordsApi = useRerunLabelsApi();
  const [minTextSize, setMinTextSize] = useState(searchTermsData?.text_threshold ?? 0);
  const [confidenceThreshold, setConfidenceThreshold] = useState(searchTermsData?.confidence_threshold ?? 0);
  const visual_keywords = phraseData?.visual_keywords ?? [];
  const keywordsToDisplay = rerunKeywordsApi.data ?? visual_keywords;
  const { handleRerunForAccount, searchTermsApi } =
    useRerunForAccount();
  const notifications = useNotifications();

  const handleRerun = () => {
    const payload = {
      text_threshold: minTextSize,
      confidence_threshold: confidenceThreshold,
      visual_url: current.asset_url ?? null,
      view_id,
    };

    rerunKeywordsApi.request(payload);
  };

  useEffect(() => {
    if (searchTermsApi.post.data) {
      console.log(searchTermsApi.post.data);
      notifications.add({
        title: 'Success!',
        message: 'Updated keyword settings. Data may take several minutes to refresh.',
      });
      setShowKeywordSettingsModal(false);
    }
  }, [searchTermsApi.post.data]);

  useEffect(() => {
    if (searchTermsApi.post.error) {
      try {
        notifyError(
          'There was an error.',
          searchTermsApi.post.error.error.toString()
        );
      } catch {
        console.error(searchTermsApi.post.error);
      }
    }
  }, [searchTermsApi.post.error]);

  const applyToAllVisuals = () => {
    handleRerunForAccount(minTextSize, confidenceThreshold);
  };

  let KeywordsDisplay = null;

  if (rerunKeywordsApi.loading) {
    KeywordsDisplay = <p>Loading...</p>;
  } else if (rerunKeywordsApi.error) {
    KeywordsDisplay = (
      <p>
        There was an error retrieving the keywords. <br />
        {rerunKeywordsApi.error.error}
      </p>
    );
  } else if (!keywordsToDisplay || keywordsToDisplay?.length < 1) {
    KeywordsDisplay = <p>No keywords found for this visual.</p>;
  } else {
    KeywordsDisplay = (
      <ul>
        {keywordsToDisplay.map((keyword) => {
          return <li>"{keyword}"</li>;
        })}
      </ul>
    );
  }

  return (
    <Overlay close={() => setShowKeywordSettingsModal(false)}>
      <Modal close={() => setShowKeywordSettingsModal(false)} className="p-5">
        <div className="keyword-settings">
          <h3>Keyword Settings</h3>
          <div className="">
            <div className="mt-5">
              <div>
                <h5>Min. text size threshold</h5>
              </div>
              <Form.Control
                type="number"
                value={minTextSize}
                onChange={(e) => setMinTextSize(parseFloat(e.target.value))}
                min={0}
                max={1}
                step={0.1}
              />
            </div>
            <div className="mt-5">
              <div>
                <h5>Confidence threshold</h5>
              </div>
              <Form.Control
                type="number"
                value={confidenceThreshold}
                onChange={(e) =>
                  setConfidenceThreshold(parseFloat(e.target.value))
                }
                min={0}
                max={1}
                step={0.1}
              />
            </div>
          </div>

          <div className="keyword-settings__results mt-5">
            <div className="mb-4 d-flex justify-content-between align-items-center">
              <h5 className="mr-3">Keywords</h5>
              <Button onClick={handleRerun} appearance="secondary" className="ml-3">
                Run labels for this visual using selected thresholds
              </Button>
            </div>

            {KeywordsDisplay}
          </div>
        </div>

        <div className="mt-5 d-flex w-100 justify-content-end">
          <Button loading={searchTermsApi.post.loading} onClick={applyToAllVisuals}>
            Apply these new rules to all visuals and rerun
          </Button>
        </div>
      </Modal>
    </Overlay>
  );
};

export default KeywordSettings;
