export const QUINTILE_COLORS = {
  border: [
    '#E8BFBF',
    '#E9CECA',
    '#E1C6BD',
    '#E9D9C9',
    '#E9E5C2',
    '#DFECCA',
    '#C7EBC7',
    '#C5E5D2',
    '#B5E2D7',
    '#B8DEE0',
  ],
  bg: [
    '#EF8D8D',
    '#EF8D8D',
    '#FFB39A',
    '#FFB39A',
    '#FFDD64',
    '#FFDD64',
    '#D1F273',
    '#D1F273',
    '#AFF09F',
    '#AFF09F',
  ],
  text: [
    '#8d4040',
    '#BF6F61',
    '#875444',
    '#a97038',
    '#8d6c47',
    '#60773e',
    '#396139',
    '#69967B',
    '#43776a',
    '#619194',
  ],
};

export const SCORE_COLORS = {};

export const RIGHT_RAIL_WIDTH = 250;
