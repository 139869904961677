import React, { useRef, useState } from 'react';
import { useInputState } from './AIDriven/hooks';

import Editor from './AIDriven/Editor';

import { LabelObject } from '../../types';
import { useCustomCategories } from '../hooks';
import {
  useCreativeAnalyticsGet,
  useCreativeAnalyticsSet,
} from '../../contexts';
import { EASY_AI, MULTIPLE_LABELS, MUTUALLY_EXCLUSIVE } from '../constants';

import {
  useDarwinClientId,
  useDates,
  usePlatform,
  useViewId,
} from '../selectors';
import { useCustomCategoriesApi } from '../api';
import {
  makeCreateMmCategoryPayload,
  makeDeleteMmCategoryPayload,
  makeEditMmCategoryPayload,
  makeFetchPayload,
} from '../payloads';
import { useEffect } from 'react';
import { useAnalyticsParams, useDatePeriods } from '../../hooks';
import { fillMissingFrames, makeVisualsObject } from './AIDriven/utils';
import { useAccountId, useVisuals } from '../../selectors';
import { MmDeleteSuccess, MmSaveSuccess, useVertexApi } from './AIDriven/api';
import Setup from './AIDriven/Setup';
import { toast } from 'react-toastify';
import useConfirm from '../../../../hooks/useConfirm';
import { useNotifications } from '../../../../components/Notification/useNotifications';
import CloseIcon from '../../../../components/Icons/CloseIcon';
import { DARWIN_NAVY } from '../../../../constants';

type CustomCategoriesProps = {
  data: LabelObject[];
  category: string;
  visual: string;
  fetchPerformanceData: any;
};

const CustomSingle = ({
  data,
  category,
  category_id,
  visual,
  fetchPerformanceData,
  singleToFull,
  easy_ai,
  originalMetricsData,
}: CustomCategoriesProps) => {
  const vertexApi = useVertexApi();
  const account_id = useAccountId();
  const allVisualsList = useVisuals();
  const [stage, next, prev, dropdownOptions, ccc_type, setCCCType, isNew] =
    useCustomCategories(data, category);
  const [allVisuals, setAllVisuals] = useState({});
  const [input, handleInput, removeLabel, labelsHaveChanged, setFields] =
    useInputState();
  const [existing, setExisting] = useState(null);
  const [annotatedVisuals, setAnnotatedVisuals] = useState(
    existing ? existing.annotated_visuals : {}
  );
  const [predictedVisuals, setPredictedVisuals] = useState(
    existing ? existing.predicted_visuals : {}
  );
  const ref = useRef();
  const api = useCustomCategoriesApi();
  const darwin_client_id = useDarwinClientId();
  const view_id = useViewId();
  const platform = usePlatform();
  const [{ period1, period2 }] = useAnalyticsParams();
  const [period1Start, period1End, period2Start, period2End] = useDatePeriods(
    period1,
    period2
  );

  const notifications = useNotifications();
  const [ConfirmDialog, confirm] = useConfirm('Delete this category?');

  const visualsData = makeVisualsObject(
    allVisualsList?.filter((v) => v.id === visual) ?? []
  );

  const fetch = (category, category_id) => {
    api.get.request(
      makeFetchPayload({
        category_name: category,
        category_id,
        platform,
        darwin_client_id,
        view_id,
        period1Start,
        period1End,
      })
    );
  };

  useEffect(() => {
    fetch(category, category_id);
  }, [category]);

  useEffect(() => {
    if (existing) {
      let type = null;
      const { annotated_visuals, predicted_visuals } = fillMissingFrames(
        existing,
        visualsData
      );

      if (easy_ai) {
        type = EASY_AI;
      } else {
        if (existing.mutually_exclusive) {
          type = MUTUALLY_EXCLUSIVE;
        } else {
          type = MULTIPLE_LABELS;
        }
      }

      setFields({
        name: existing.category,
        type,
        description: existing.description,
      });

      setAnnotatedVisuals(annotated_visuals);
      setPredictedVisuals(predicted_visuals);

      if (existing.output_label_info) {
        Object.keys(existing.output_label_info).map((label) => {
          handleInput('labels', label);
        });
      }
    }
  }, [existing]);

  useEffect(() => {
    if (api.get.data) {
      const { data } = api.get;
      const combined = {
        ...data.annotated_visuals,
        ...data.predicted_visuals,
      };

      setExisting(data);
      setAllVisuals(data.visuals);
    }
  }, [api.get.data]);

  const createVertexCategory = () => {
    const payload = makeCreateMmCategoryPayload({
      account_id,
      prompt: input.description,
      category_name: input.name,
      darwin_visual_id: originalMetricsData.map(({ id }) => id),
    });

    vertexApi.create.request(payload);
  };

  const editVertexCategory = () => {
    console.log({
      originalMetricsData,
      category_id,
      input,
    });
    const payload = makeEditMmCategoryPayload({
      account_id,
      prompt: input.description,
      category_name: input.name,
      category_id,
      darwin_visual_id: originalMetricsData.map(({ id }) => id),
    });

    vertexApi.edit.request(payload);
  };

  const deleteVertexCategory = async () => {
    const payload = makeDeleteMmCategoryPayload({
      account_id,
      category_id,
    });

    const conf = await confirm();

    if (conf) {
      vertexApi.delete.request(payload);
    }
  };

  useEffect(() => {
    if (vertexApi.create.data === undefined) {
      toast(
        <MmSaveSuccess
          name={input.name}
          refresh={() => fetchPerformanceData(true)}
        />,
        {
          position: 'top-right',
          closeOnClick: false,
          draggable: true,
          progress: undefined,
          closeButton: <CloseIcon className="toast__close" />,
          style: {
            background: DARWIN_NAVY,
            padding: 0,
          },
          autoClose: false,
        }
      );
    }
  }, [vertexApi.create.data]);

  useEffect(() => {
    if (vertexApi.edit.data === undefined) {
      toast(
        <MmSaveSuccess
          name={input.name}
          refresh={() => fetchPerformanceData(true)}
        />,
        {
          position: 'top-right',
          closeOnClick: false,
          draggable: true,
          progress: undefined,
          closeButton: <CloseIcon className="toast__close" />,
          style: {
            background: DARWIN_NAVY,
            padding: 0,
          },
          autoClose: false,
        }
      );
    }
  }, [vertexApi.edit.data]);

  useEffect(() => {
    if (vertexApi.delete.data) {
      toast(<MmDeleteSuccess name={input.name} />, {
        position: 'top-right',
        closeOnClick: false,
        draggable: true,
        progress: undefined,
        closeButton: <CloseIcon className="toast__close" />,
        style: {
          background: DARWIN_NAVY,
          padding: 0,
        },
        autoClose: false,
      });
    }
  }, [vertexApi.delete.data]);

  useEffect(() => {
    if (vertexApi.create.error || vertexApi.edit.error) {
      notifications.add({
        title: 'Error',
        message: 'There was an error saving the category.',
      });
    }
  }, [vertexApi.create.error, vertexApi.edit.error]);

  const getLoadingState = () => {
    if (input.type === EASY_AI) {
      return (
        vertexApi.create.loading ||
        vertexApi.edit.loading ||
        vertexApi.delete.loading
      );
    }

    return api.get.loading || api.delete.loading;
  };
  const isLoading = getLoadingState();

  if (api.get.loading) {
    return (
      <div className="category-manager category-manager--loading">
        <p className="category-manager--loading__msg loading-text">
          Fetching category data
        </p>
      </div>
    );
  }

  if (!visualsData) {
    return (
      <div className="category-manager category-manager--loading">
        <p className="category-manager--loading__msg">
          There was an error fetching the data.
        </p>
      </div>
    );
  }

  return (
    <div className={`category-manager custom-categories stage2`} ref={ref}>
      {easy_ai ? (
        <Setup
          easy_ai={true}
          {...{
            dropdownOptions,
            next,
            prev,
            input,
            handleInput,
            existing,
            isNew,
            createVertexCategory,
            editVertexCategory,
            deleteVertexCategory,
            isLoading,
          }}
        />
      ) : (
        <Editor
          {...{
            prev,
            existing,
            fetch,
            fetchPerformanceData,
            visualsData,
            visual,
            category,
            allVisuals,
            annotatedVisuals,
            setAnnotatedVisuals,
            predictedVisuals,
            setPredictedVisuals,
            category_id,
            singleToFull,
          }}
          input={input}
          isSingle
        />
      )}
    </div>
  );
};

export default CustomSingle;
