import React from "react";
import { DARWIN_BLUE, DARWIN_NAVY } from "../../../constants";

export const StarIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="-2 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1646 16.7345L15.0144 19.6678C15.9026 20.2054 16.9894 19.4107 16.7557 18.4056L15.4702 12.8897L19.7591 9.17339C20.5421 8.49558 20.1214 7.21008 19.093 7.12828L13.4484 6.64913L11.2397 1.437C10.8424 0.490401 9.48674 0.490401 9.0894 1.437L6.88067 6.63745L1.23614 7.11659C0.207741 7.19839 -0.21297 8.4839 0.570019 9.16171L4.85893 12.878L3.57342 18.394C3.3397 19.399 4.42653 20.1937 5.3147 19.6561L10.1646 16.7345Z"
        fill="#DDE6F3"
        stroke-width="1.5"
        stroke="#DDE6F3"
      />
    </svg>
  );
};

export const PresetsIcon = ({ open }) => {
  return (
    <svg
      width="15"
      height="21"
      viewBox="-4 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8896 2.31357V18.2386L7.91961 15.7256L7.28961 15.4106L6.65961 15.7256L1.68961 18.2386V2.31357H12.8896ZM12.8896 0.913574H1.68961C1.31831 0.913574 0.962213 1.06107 0.699662 1.32362C0.437111 1.58618 0.289612 1.94227 0.289612 2.31357V20.5136L7.28961 17.0136L14.2896 20.5136V2.31357C14.2896 1.94227 14.1421 1.58618 13.8796 1.32362C13.617 1.06107 13.2609 0.913574 12.8896 0.913574Z"
        fill={open ? DARWIN_BLUE : DARWIN_NAVY} stroke={open ? DARWIN_BLUE : DARWIN_NAVY} stroke-width=".5"
      />
    </svg>
  );
};
