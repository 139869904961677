import React, { useEffect, useState } from "react";
import "./LoadingMessages.scss";

const LoadingMessages = ({ messages, className, delay }) => {
  const loadingMessages = [
    ...messages,
  ];
  const [text, setText] = useState(loadingMessages[0]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timerId = setInterval(
      () => {
        if (index !== loadingMessages.length - 1) setIndex((i) => (i + 1) % loadingMessages.length)
      },
      delay ?? 7000
    );
    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    setText(loadingMessages[index]);
  }, [index]);

  return (
    <div className={`bounce-loading loading-messages ${className}`}>
      <span>{text}</span>
      <span className="dot"></span>
      <span className="dot"></span>
      <span className="dot"></span>
    </div>
  );
};

export default LoadingMessages;
