import React from 'react';
import { toast } from 'react-toastify';
import { DARWIN_NAVY } from '../../../constants';
import { CloseIcon } from '../../../components/Icons';

const Create = () => {
  return (
    <div className="toast__content">
      {
        'Report Refresh has been successfully started. Please check back in 10 minutes to see data'
      }
    </div>
  );
};

export const notifyCreate = (account_id) =>
  toast(<Create />, {
    position: 'top-right',
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: false,
    draggable: true,
    progress: undefined,
    closeButton: <CloseIcon className="toast__close" />,
    style: {
      background: DARWIN_NAVY,
      padding: 0,
    },
    toastId: `refresh-report-${account_id}`,
  });
