import React, { useEffect, useMemo, useState } from 'react';
import {
  AdType,
  CategoryDataType,
  DataItem,
  ExperimentItem,
  InsightsItem,
  SortType,
  TabsState,
} from './types';
import './styles.scss';
import { Experiments, SortToggle } from './Experiments';
import { useAbortableFetch } from '../../hooks/useAbortableFetch';
import { BasicLoading } from '../../components/Helpers/Loading';
import { Chart } from './Chart';
import {
  createCategoryLookup,
  filterAndRankAds,
  sortExperiments,
} from './utils';
import { Dataset, Findings, InsightsList } from './Findings';
import { Toggles } from './Toggles';
import { SORT_OPTIONS } from './constants';
import { useViewId } from '../../components/Hooks/view-id';
import { sortAdsByRank } from './utils';
import { useDispatch } from 'react-redux';
import { trackEvent } from '../../store/UserActivity/actions';
import {
  DARWIN_IQ,
  TOGGLE_DARWIN_IQ,
} from '../../store/UserActivity/constants';

const useApi = () => {
  const {
    data = { insights: [], data: [], experiments: [] },
    loading,
    error,
    request,
    abort,
  } = useAbortableFetch();
  const view_id = useViewId();

  useEffect(() => {
    request(['api', 'creative-analytics', 'fetch-bev-data'].join('/'), {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ view_id }),
    });
  }, [view_id]);

  console.log(data)

  return {
    apiData: typeof data?.data === 'object' ? data?.data : null,
    loading,
    error,
  };
};

const initial: TabsState = {
  experiments: true,
  findings: true,
  chart: true,
};

export const BirdsEyeView = () => {
  const {
    apiData,
    loading,
    error,
  }: {
    data: {
      insights: InsightsItem[];
      data: DataItem[];
      experiments: ExperimentItem[];
    };
  } = useApi();
  const [sort, setSort] = useState<SortType>(SORT_OPTIONS[0]);
  const [tabs, setTabs] = useState<TabsState>(initial);
  const dispatch = useDispatch();

  const {
    insights,
    data,
    experiments: experimentsData,
  } = apiData ?? {
    insights: [],
    data: [],
    experiments: [],
  };

  useEffect(() => {
    dispatch(trackEvent(DARWIN_IQ, TOGGLE_DARWIN_IQ));
  }, []);

  const sortedExperiments: ExperimentItem[] = useMemo(() => {
    if (!experimentsData) return [];

    return sortExperiments(experimentsData, sort).map((experiment) => ({
      ...experiment,
      ads: filterAndRankAds(
        sortAdsByRank(experiment.ads),
        experiment.campaign_name ===
          'c2.US.ABO.HighestVolume.Creative Testing' &&
          experiment.adset_name ===
            'ax.LOCAL.Web.24+ M/F.Broad.Subscription.oCPM.Q1 Males 24-50 Statics Aaron 60+20% | Week 3 2025'
      ),
    }));
  }, [experimentsData, sort]);

  if (loading) return <BasicLoading />;
  if (error) return <p>There was an error. {error?.toString()}</p>;
  if (!data) return <p className="m-5">No data found on this view.</p>;

  const dataByCategory: CategoryDataType = createCategoryLookup(data);

  const debugExperiment = (campaign, adset) => {
    const match = experimentsData?.find((ex: ExperimentItem) => {
      return ex.campaign_name === campaign && ex.adset_name === adset;
    });
    const match2 = sortedExperiments?.find((ex: ExperimentItem) => {
      return ex.campaign_name === campaign && ex.adset_name === adset;
    });

    console.log(campaign);
    console.log(adset);
    console.log(match);
    console.log(match2);
  };

  return (
    <div className="birds-eye-view">
      <Toggles {...{ tabs, setTabs }} />

      {!!tabs.chart && (
        <div className="bev-chart">
          <Chart data={data} />
        </div>
      )}

      <div className="row" style={{ height: 'calc(100vh - 100px)' }}>
        {!!tabs.experiments && (
          <div className="bird-eye-view-left col-12 col-md-8 pb-0 pb-md-3">
            <PageSection
              title="Experiments"
              RightHeader={<SortToggle {...{ sort, setSort }} />}
            >
              <Experiments
                experimentsData={sortedExperiments}
                debugExperiment={debugExperiment}
              />
            </PageSection>
          </div>
        )}

        {!!tabs.findings && (
          <div className="bird-eye-view-right col-12 col-md-4">
            <PageSection title="Findings">
              <Findings
                InsightsList={
                  <InsightsList
                    insights={insights}
                    renderDataset={(category_name) => {
                      return <Dataset data={dataByCategory[category_name]} />;
                    }}
                  />
                }
              />
            </PageSection>
          </div>
        )}
      </div>
    </div>
  );
};

const PageSection = ({ title, RightHeader, children }) => {
  return (
    <div className="birds-eye-section">
      <div className="d-flex justify-content-between">
        <h2 className="birds-eye-section__title">{title}</h2>

        {RightHeader && RightHeader}
      </div>
      <div className="birds-eye-section__box">{children}</div>
    </div>
  );
};
