import React from 'react';
import { lg } from '../columns';
import { toPercent_2 } from '../../../utils/numbers';

export const getCustomEventsCols = (customEvents = [], checkIfExists) => {
  if (!customEvents?.length) return [];

  return customEvents.reduce((result, customEvent) => {
    try {
      checkIfExists(customEvent.name, () => {
        result.push({
          Header: customEvent.name,
          accessor: customEvent.name,
          width: lg,
          maxWidth: lg,
          disableFilters: true,
          Cell: ({ row: { original: visual } }) => {
            const value =
              visual[customEvent.numerator] / visual[customEvent.denominator];

            if (!value || typeof value !== 'number') return <p>-</p>;
            return <p>{toPercent_2(value)}</p>;
          },
        });
      });
    } catch (e) {
      console.error(`There was an error adding custom event column. ${e}`);
    }

    return result;
  }, []);
};

export const getCustomEventsOpts = (customEvents = []) => {
  if (!customEvents?.length) return [];

  return customEvents.reduce((result, customEvent) => {
    try {
      result.push({
        label: customEvent.name,
        value: customEvent.name,
      });
    } catch (e) {
      console.error(
        `There was an error adding custom event column option. ${e}`
      );
    }

    return result;
  }, []);
};
