import React from 'react';
import IconButton from '../../../../components/IconButton';
import Tooltip from '../../../../components/Tooltip';
import { RESET } from './reducer';

export const DisplayToggle = ({
  display,
  setDisplay,
  fetchCustomCategories,
}) => {
  return (
    <div className="qa-header">
      <div></div>

      <div>
        <button
          className={`qa-header__tab ${display === 'ccc' ? 'is-selected' : ''}`}
          onClick={() => setDisplay('ccc')}
        >
          CCC Labels
        </button>
        <button
          className={`qa-header__tab ${
            display === 'phrase-builder' ? 'is-selected' : ''
          }`}
          onClick={() => setDisplay('phrase-builder')}
        >
          Phrase Builder
        </button>
      </div>

      <Tooltip content="Refresh custom categories data">
        <div>
          <IconButton
            onClick={() => {
              fetchCustomCategories();
            }}
            appearance="raised"
            style={{ marginRight: '1rem' }}
          >
            <i className="fa-solid fa-arrow-rotate-right" />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  );
};

export const Header = ({ DisplayToggle }) => {
  return <div>{DisplayToggle}</div>;
};
