export function getCustomScoringValues(visuals, customScoringData) {
  if (!visuals.length || !customScoringData) return 0;

  const values = Object.assign(
    {},
    ...customScoringMetrics.map((metric) => {
      // if (metric.label.includes('Percentage')) {
        const sum = visuals.reduce((result, visual) => {
          const match = customScoringData.find((v) => v.Visual === visual.id);
          
          if (match) {
            result += match[metric.value];
          }

          return result;
        }, 0);

        return {
          [metric.label]: sum / visuals.length,
        };
      // }
    })
  );

  return values;
}


export const customScoringMetrics = [
  {
    label: 'Adset Relative CPA Percentage',
    value: 'line_item_cpa_percent_better_than_adset_cpa',
  },
  {
    label: 'Adset Relative CTR Percentage',
    value: 'line_item_ctr_percent_better_than_adset_ctr',
  },
  {
    label: 'Adset Relative CPC Percentage',
    value: 'line_item_cpc_percent_better_than_adset_cpc',
  },
  {
    label: 'Adset SOV',
    value: 'adset_share_of_voice',
  },
  {
    label: 'Average CPA Score',
    value: 'CPA Score',
  },
  {
    label: 'Average CTR Score',
    value: 'CTR Score',
  },
  {
    label: 'Average CPC Score',
    value: 'CPC Score',
  },
];
