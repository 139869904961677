//@ts-check
import React from "react";
import PropTypes from "prop-types";

// imported from rest of app
import Select from "react-select";

const ChartIcon = ({ icon, title }) => (
  <span>
    <i className={icon}></i>
    &ensp;
    {title}
  </span>
);

const CHART_TYPES = [
  {
    name: "line",
    title: "Line",
    icon: "fa fa-line-chart",
    needsTime: true,
  },

  {
    name: "area",
    title: "Area",
    icon: "fa fa-area-chart",
    needsTime: true,
  },
  {
    name: "bar",
    title: "Bar",
    icon: "fa fa-bar-chart",
    needsTime: true,
  },
  {
    name: "pie",
    title: "Pie",
    icon: "fa fa-pie-chart",
  },
  {
    name: "table",
    title: "Table",
    icon: "fa fa-table",
  },
  {
    name: "number",
    title: "Number",
    icon: "fa fa-hashtag",
  },

  {
    name: "custom_periodComparison",
    title: "Period Comparison",
    icon: "fa fa-table",
  },
].map((chart) => ({
  ...chart,
  value: chart.name,
  label: <ChartIcon title={chart.title} icon={chart.icon} />,
}));

const PICKER_STYLES = {
  control: (base, { selectProps: { width } }) => ({
    ...base,
    width,
  }),
  menu: (base, { selectProps: { dropdownWidth } }) => ({
    ...base,
    width: dropdownWidth,
  }),
};

const SelectChartType = ({ chartType, updateChartType, hasTime }) => {
  const options = hasTime
    ? CHART_TYPES
    : CHART_TYPES.filter(({ needsTime }) => hasTime === !!needsTime);
  const currentChart = chartType
    ? options.find(({ value }) => value === chartType)
    : null;

  return (
    <Select
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      dropdownWidth="100px"
      width="100px"
      styles={PICKER_STYLES}
      value={currentChart}
      options={options}
      onChange={(selected) =>
        selected && selected.value ? updateChartType(selected.value) : selected
      }
    />
  );
};

SelectChartType.propTypes = {
  hasTime: PropTypes.bool,
  chartType: PropTypes.string.isRequired,
  updateChartType: PropTypes.func.isRequired,
};

export default SelectChartType;
