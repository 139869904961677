import React, { useMemo } from 'react';
import './CommonElements.scss';
import { Tag } from '../../components/Tags';
import { useCreativeAnalyticsGet } from '../CreativeAnalytics/contexts';
import Tooltip from '../../components/Tooltip';
import Button from '../../components/Button';
import { getCommonElementsNames } from './utils';

export const CommonElements = ({
  commonElementsData,
  loading,
  filteredData,
  hiddenElements,
  setHiddenElements,
  isHurdleReport,
  isSharedHurdleReport,
  styles,
}) => {
  if (!filteredData.length && !isSharedHurdleReport) return null;

  const {
    reportCardSettings,
  } = useCreativeAnalyticsGet() || {};
  const commonElements = useMemo(() => {
    if (isHurdleReport || isSharedHurdleReport) return commonElementsData; // already formatted

    return getCommonElementsNames(
      commonElementsData,
      filteredData,
      reportCardSettings.categorySettings
    );
  }, [commonElementsData, filteredData]);

  const ToggleButton = (
    <Button
      appearance="raised"
      className="toggle-btn"
      onClick={() => setHiddenElements([])}
    >
      Show all
    </Button>
  );

  if (loading) {
    return (
      <div className="dna-common-elements mb-3">
        <p className="">Fetching common elements...</p>
      </div>
    );
  }

  if (!commonElements.length) {
    return null;
    
    return (
      <div className="dna-common-elements mb-3" style={{ ...styles ? { ...styles } : {} }}>
        <p className="">No common elements.</p>
      </div>
    );
  }

  return (
    <div
      className={`dna-common-elements ${isHurdleReport ? 'hurdle-report' : ''}`}
    >
      <div className="d-flex align-items-center">
        <p className="">
          {isHurdleReport ? 'E' : 'Selected ads have these e'}lements in common:{' '}
        </p>
        {hiddenElements.length ? ToggleButton : null}
      </div>
      <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
        {commonElements.map((el) => (
          <>
            {!hiddenElements.includes(el) && (
              <li className="mr-2" style={{ display: 'inline-block' }}>
                <Tag
                  style={{ background: '#F1F4FB', fontSize: '12px' }}
                  onClick={() => {
                    if (isHurdleReport) return;
                    setHiddenElements((prev) => [...prev, el]);
                  }}
                >
                  {el}
                </Tag>
              </li>
            )}
          </>
        ))}
      </ul>
    </div>
  );
};
