import { LAST_MONTH } from "../../../utils/darwin-dates";
import { endOfMonth, startOfMonth } from "date-fns";

export const lastMonthStart = startOfMonth(LAST_MONTH);
export const lastMonthEnd = endOfMonth(LAST_MONTH);

export const IMPACT_REPORT = "Impact Report";
export const GENERAL_LOADING = "General loading";
export const CHANGE_REPORT = "Changed report";
export const VIEW_LABEL_DATA = "Filtered down category to label data";
export const DUMMY_REPORT = "dummy";

export const DUMMY_DATA = {
	reports: [
		{
			'id': '1235e63saf',
			'name': 'Last Month',
			'status': 'on',
			'start_date': '2023-02-01',
			'end_date': '2023-02-28',
			'audience': '',
			'excluded_labels': [],
			'categories': {
				'Visual Theme': {
					'score': .18,
					'label_breakdown': {
						'Product Stills': {
							'cpa': 4.56,
							'budget_percentage': .23,
              visual_type: "image",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/420969412774644",
						},
						'Lifestyle w/ Movement': {
							'cpa': 5.67,
							'budget_percentage': .34,
              visual_type: "image",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/617139649177258",
						},
						'UGC': {
							'cpa': 7.88,
							'budget_percentage': .43,
              visual_type: "video",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/361302975512979",
              thumbnail_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/634839784927548",
						},
          },
        },
				'Message Position': {
					'score': .12,
					'label_breakdown': {
						'Message Top': {
							'cpa': 5.67,
							'budget_percentage': .13,
              visual_type: "image",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/420969412774644",
						},
						'Message Middle': {
							'cpa': 2.33,
							'budget_percentage': .7,
              visual_type: "image",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/617139649177258",
						},
						'Message Bottom': {
							'cpa': 7.55,
							'budget_percentage': 0.17,
              visual_type: "video",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/361302975512979",
              thumbnail_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/634839784927548",
						}
					}
				},
				'Logo Position': {
					'score': .08,
					'label_breakdown': {
						'Logo Top': {
							'cpa': 4.25,
							'budget_percentage': .45,
              visual_type: "video",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/361302975512979",
              thumbnail_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/634839784927548",
						},
						'Logo Middle': {
							'cpa': 2.12,
							'budget_percentage': .2,
              visual_type: "image",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/617139649177258",
						},
						'Logo Bottom': {
							'cpa': 6.12,
							'budget_percentage': .35,
              visual_type: "video",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/361302975512979",
              thumbnail_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/634839784927548",
						}
					}
				},
			},
			'combined_category_names': ['Visual Theme', 'Message Position', 'Logo Position'],
			'combined_score': .41
		},
		{
			'id': '345sdrwer435345w',
			'name': 'Q4 Prospecting Meeting',
			'status': 'on',
			'start_date': '2022-10-01',
			'end_date': '2022-12-31',
			'audience': 'Prospecting',
			'excluded_labels': ['Promo', 'Video'],
			'categories': {
				'Visual Theme': {
					'score': .03,
					'label_breakdown': {
						'Theme 1': {
							'cpa': 4.56,
							'budget_percentage': .23,
              visual_type: "image",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/420969412774644",
						},
						'Theme 2': {
							'cpa': 5.67,
							'budget_percentage': .34,
              visual_type: "image",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/617139649177258",
						}					},
						'Theme_3': {
							'cpa': 7.88,
							'budget_percentage': .23,
              visual_type: "video",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/361302975512979",
              thumbnail_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/634839784927548",
						}
        },
				'Message Position': {
					'score': .12,
					'label_breakdown': {
						'Message Top': {
							'cpa': 5.67,
							'budget_percentage': .13,
              visual_type: "image",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/420969412774644",
						},
						'Message Middle': {
							'cpa': 2.33,
							'budget_percentage': .84,
              visual_type: "image",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/617139649177258",
						},
						'Message Bottom': {
							'cpa': null,
							'budget_percentage': 0.3,
              visual_type: "video",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/361302975512979",
              thumbnail_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/634839784927548",
						}
					}
				},
				'Logo Position': {
					'score': .38,
					'label_breakdown': {
						'Logo Top': {
							'cpa': 2.67,
							'budget_percentage': .14,
              visual_type: "video",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/361302975512979",
              thumbnail_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/634839784927548",
						},
						'Logo Middle': {
							'cpa': 1.33,
							'budget_percentage': .83,
              visual_type: "image",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/617139649177258",
						},
						'Logo Bottom': {
							'cpa': null,
							'budget_percentage': 0.1,
              visual_type: "video",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/361302975512979",
              thumbnail_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/634839784927548",
						}
					}
				},
			},
			'combined_category_names': ['Logo Position', 'Message Position'],
			'combined_score': .1
		},
		{
			'id': '3453452342313esdf',
			'name': 'Q3 Retargeting Videos',
			'status': 'off',
			'start_date': '2022-06-01',
			'end_date': '2022-09-30',
			'audience': 'Retargeting',
			'excluded_labels': ['Image'],
			'error': 'Not enough data present to calculate meaningful impact. Try using a longer date range or fewer exclusions!'
		},
		{
			'id': '3452342313esdf',
			'name': 'Q3 Retargeting Images',
			'status': 'on',
			'start_date': '2022-06-01',
			'end_date': '2022-09-30',
			'audience': 'Retargeting',
			'excluded_labels': ['Video'],
			'error': 'No category with meaningful impact found. More testing may be needed to uncover meaningful data.'
		},
		{
			'id': '3452342313esdfasdf',
			'name': 'Test Report',
			'status': 'off',
			'start_date': '2022-06-01',
			'end_date': '2022-09-30',
			'audience': 'Retargeting',
			'excluded_labels': ['Video'],
			'error': 'No category with meaningful impact found. More testing may be needed to uncover meaningful data.'
		},
		{
			'id': '123345sdrwer435345w',
			'name': 'Q2 Prospecting Meeting',
			'status': 'on',
			'start_date': '2022-10-01',
			'end_date': '2022-12-31',
			'audience': 'Prospecting',
			'excluded_labels': [],
			'categories': {
				'Visual Theme': {
					'score': .06,
					'label_breakdown': {
						'Theme 1': {
							'cpa': 4.56,
							'budget_percentage': .23,
              visual_type: "image",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/420969412774644",
						},
						'Theme 2': {
							'cpa': 5.67,
							'budget_percentage': .34,
              visual_type: "image",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/617139649177258",
						}					},
						'Theme_3': {
							'cpa': 7.88,
							'budget_percentage': .23,
              visual_type: "video",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/361302975512979",
              thumbnail_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/634839784927548",
						}
        },
				'Message Position': {
					'score': .19,
					'label_breakdown': {
						'Message Top': {
							'cpa': 5.67,
							'budget_percentage': .13,
              visual_type: "image",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/420969412774644",
						},
						'Message Middle': {
							'cpa': 2.33,
							'budget_percentage': .83,
              visual_type: "image",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/617139649177258",
						},
						'Message Bottom': {
							'cpa': null,
							'budget_percentage': 0.4,
              visual_type: "video",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/361302975512979",
              thumbnail_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/634839784927548",
						}
					}
				},
				'Logo Position': {
					'score': .05,
					'label_breakdown': {
						'Logo Top': {
							'cpa': 2.67,
							'budget_percentage': .14,
              visual_type: "video",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/361302975512979",
              thumbnail_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/634839784927548",
						},
						'Logo Middle': {
							'cpa': 1.33,
							'budget_percentage': .83,
              visual_type: "image",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/617139649177258",
						},
						'Logo Bottom': {
							'cpa': null,
							'budget_percentage': 0.1,
              visual_type: "video",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/361302975512979",
              thumbnail_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/634839784927548",
						}
					}
				},
        'Bedding Color': {
          'score': .07,
          'label_breakdown': {
            'No Bedding Visible': {
              'cpa': 3.67,
              'budget_percentage': .5,
              visual_type: "video",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/361302975512979",
              thumbnail_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/634839784927548",
            },
            'White Bedding': {
              'cpa': 1.83,
              'budget_percentage': .4,
              visual_type: "image",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/617139649177258",
            },
            'Natural Bedding': {
              'cpa': null,
              'budget_percentage': 0.04,
              visual_type: "video",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/361302975512979",
              thumbnail_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/634839784927548",
            },
            'Other Bedding': {
              'cpa': null,
              'budget_percentage': 0.06,
              visual_type: "video",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/361302975512979",
              thumbnail_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/634839784927548",
            },
          }
        },
        'Pillowboard Cover Color': {
          'score': .63,
          'label_breakdown': {
            'Fog Grey': {
              'cpa': 3.67,
              'budget_percentage': .07,
              visual_type: "video",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/361302975512979",
              thumbnail_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/634839784927548",
            },
            'White': {
              'cpa': 1.83,
              'budget_percentage': .14,
              visual_type: "image",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/617139649177258",
            },
            'Natural': {
              'cpa': 3.21,
              'budget_percentage': 0.16,
              visual_type: "video",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/361302975512979",
              thumbnail_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/634839784927548",
            },
            'No PB': {
              'cpa': 10.23,
              'budget_percentage': 0.09,
              visual_type: "video",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/361302975512979",
              thumbnail_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/634839784927548",
            },
            'Dark Charcoal': {
              'cpa': 4.56,
              'budget_percentage': 0.04,
              visual_type: "video",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/361302975512979",
              thumbnail_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/634839784927548",
            },
            'Light Linen': {
              'cpa': 2.34,
              'budget_percentage': 0.5,
              visual_type: "video",
              asset_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/361302975512979",
              thumbnail_url:
                "https://fb-assets.s3.us-east-2.amazonaws.com/634839784927548",
            },
          }
        },
			},
			'combined_category_names': ['Pillowboard Cover Color', 'Message Position', 'Visual Theme'],
			'combined_score': 1
		},
	],
	'audience_options': ['Prospecting', 'Retargeting'],
	'label_options': ['Promo', 'No Promo', 'Image', 'Video', 'Brand', 'No Brand'],
}

export const DUMMY_VISUAL_OBJ = {
	label: "Visual Theme",
	visuals: [
		{
			id: "818464239881830",
			spend: 8863.41606,
			results: 20,
			impressions: 1016288,
			ga_landing_page_views: 0,
			clicks: 4380,
			ad_names: [
				"PRO_IMAG_CARO_1x1+9x16_TI-Natural-Staycation+Afterpay-ContinuousCaro-NoSound-na-GreenLinen-B+NS+SL_AllBedTimeActivities_MeetTheBed_LearnMore_Home_FreeShip100NightTrial",
				"PRO_IMAG_CARO_1x1+9x16_TI-Natural-Staycation+Afterpay-ContinuousCaro-NoSound-na-BlueLinen-B+NS+SL_AllBedTimeActivities_MeetTheBed_LearnMore_Home_FreeShip100NightTrial",
			],
			visual_type: "image",
			asset_url:
				"https://fb-assets.s3.us-east-2.amazonaws.com/617139649177258",
			cpa: 443.17,
			percent_diff: 0.461,
		},
		{
			id: "203681085498508",
			spend: 2275.523211,
			results: 25.919444444444444,
			impressions: 98534,
			ga_landing_page_views: 0,
			clicks: 469,
			ad_names: [
				"RET_VIDE_Video_4x5+9x16_TI-Natural-Sustainability+Design-JoineryIntro-HerDreams-15s-GreyPB-N+T_StrongSturdySilent_MeetTheBed_LearnMore_Home_FreeShip100NightTrial",
			],
			visual_type: "video",
			asset_url:
				"https://fb-assets.s3.us-east-2.amazonaws.com/361302975512979",
			cpa: 87.79,
			percent_diff: -0.7106,
			thumbnail_url:
				"https://fb-assets.s3.us-east-2.amazonaws.com/634839784927548",
		},
		{
			id: "276218238493232",
			spend: 2231.718073,
			results: 5.5,
			impressions: 198915,
			ga_landing_page_views: 0,
			clicks: 812,
			ad_names: [
				"PRO_IMAG_CARO_1x1+9x16_TI-Natural-Staycation+Afterpay-ContinuousCaro-NoSound-na-GreenLinen-B+NS+SL_AllBedTimeActivities_MeetTheBed_LearnMore_Home_FreeShip100NightTrial",
			],
			visual_type: "image",
			asset_url:
				"https://fb-assets.s3.us-east-2.amazonaws.com/420969412774644",
			cpa: 405.77,
			percent_diff: 0.3377,
		},
	],
	spend: 20977.59090962,
	cpa: 281.8831968744429,
	median_cpa: 336.48,
	median_roas: 0.0019547224783087788,
};