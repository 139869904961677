import React from 'react';

export const CloseIcon = ({ className, onClick }) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.537519 0.427655C0.583964 0.381092 0.63914 0.344149 0.699885 0.318943C0.76063 0.293736 0.825751 0.280762 0.891519 0.280762C0.957286 0.280762 1.02241 0.293736 1.08315 0.318943C1.1439 0.344149 1.19907 0.381092 1.24552 0.427655L3.89152 3.07466L6.53752 0.427655C6.58401 0.381167 6.6392 0.344291 6.69993 0.319132C6.76067 0.293973 6.82577 0.281024 6.89152 0.281024C6.95726 0.281024 7.02236 0.293973 7.0831 0.319132C7.14384 0.344291 7.19903 0.381167 7.24552 0.427655C7.29201 0.474143 7.32888 0.529332 7.35404 0.590072C7.3792 0.650811 7.39215 0.715911 7.39215 0.781655C7.39215 0.847399 7.3792 0.9125 7.35404 0.973239C7.32888 1.03398 7.29201 1.08917 7.24552 1.13566L4.59852 3.78166L7.24552 6.42766C7.29201 6.47414 7.32888 6.52933 7.35404 6.59007C7.3792 6.65081 7.39215 6.71591 7.39215 6.78166C7.39215 6.8474 7.3792 6.9125 7.35404 6.97324C7.32888 7.03398 7.29201 7.08917 7.24552 7.13566C7.19903 7.18214 7.14384 7.21902 7.0831 7.24418C7.02236 7.26934 6.95726 7.28229 6.89152 7.28229C6.82577 7.28229 6.76067 7.26934 6.69993 7.24418C6.6392 7.21902 6.58401 7.18214 6.53752 7.13566L3.89152 4.48866L1.24552 7.13566C1.19903 7.18214 1.14384 7.21902 1.0831 7.24418C1.02236 7.26934 0.957262 7.28229 0.891519 7.28229C0.825775 7.28229 0.760674 7.26934 0.699935 7.24418C0.639196 7.21902 0.584006 7.18214 0.537519 7.13566C0.491031 7.08917 0.454154 7.03398 0.428995 6.97324C0.403836 6.9125 0.390887 6.8474 0.390887 6.78166C0.390887 6.71591 0.403836 6.65081 0.428995 6.59007C0.454154 6.52933 0.491031 6.47414 0.537519 6.42766L3.18452 3.78166L0.537519 1.13566C0.490955 1.08921 0.454012 1.03403 0.428806 0.973289C0.403599 0.912544 0.390625 0.847423 0.390625 0.781655C0.390625 0.715888 0.403599 0.650767 0.428806 0.590022C0.454012 0.529276 0.490955 0.474101 0.537519 0.427655Z"
        fill="#fff"
      />
    </svg>
  );
};