// import './wdyr.js';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import './stylesheets/main.scss';
// Copied from node_modules because "react-datepicker/dist/react-datepicker.css"
// is not loading when deployed.
import './stylesheets/react-datepicker.min.css';
// Global logger instance, similar API to console, available
// with window.logger.
import './globals/logger';

import store from './store';
import App from './components/App';

window.$ = $;

render(
    <Provider store={store}>
      <App />
    </Provider>
  ,document.getElementById('react-root'),
);

if (module.hot) {
  console.log('hot')
  module.hot.accept();
}
