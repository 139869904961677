import React, { useState } from 'react';
import { useDNAColumnOptions } from './useDNAColumnOptions';
import CustomDropdown from '../../components/CustomDropdown';
import Button from '../../components/Button';
import { useCreativeAnalyticsGet } from '../CreativeAnalytics/contexts';

export function DnaColumnPicker({ onChange, columns, menuHeight }) {
  const [show, setShow] = useState(false);
  const {
    isROAS,
    customEvents = [],
    clientMetrics = [],
  } = useCreativeAnalyticsGet();
  const options = useDNAColumnOptions({
    isROAS,
    customEvents,
    clientMetrics,
  });

  const handleSelect = (i) => {
    const column = options[i].value;
    return onChange({ column, checked: !columns.includes(column) });
  };

  const handleVisibility = (isOpen, meta) => {
    if (isOpen) {
      setShow(true);
    } else if (meta.source !== 'select') {
      setShow(false);
    }
  };

  return (
    <CustomDropdown
      id="block-metric-select"
      toggleClass="dw-dropdown-toggle dw-dropdown-toggle--sm mr-2"
      label={
        <Button appearance="raised">
          <i
            style={{ fontSize: '1rem' }}
            className="fa-solid fa-columns mr-3"
          />
          Edit columns
        </Button>
      }
      onToggle={handleVisibility}
      onSelect={handleSelect}
      menuStyle={{
        transform: 'translateX(-80px) !important',
        maxHeight: `${menuHeight}px`,
        overflow: 'auto',
      }}
      menuClass="dna-column-picker"
      toggleStyle={{ cursor: 'pointer', borderRadius: '5px' }}
      show={show}
    >
      {options.map(({ value, label }, i) => (
        <>
          <CustomDropdown.Item key={value} eventKey={i} className="py-1">
            <div className="form-check d-flex align-items-center justify-content-left">
              <label className="form-check-label" style={{ cursor: 'pointer' }}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  name={value}
                  defaultChecked={columns.includes(value)}
                  style={{ cursor: 'pointer' }}
                />
                <span className="ml-1">{label}</span>
              </label>
            </div>
          </CustomDropdown.Item>
        </>
      ))}
    </CustomDropdown>
  );
}
