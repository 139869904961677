//@ts-check
import React from "react";
import PropTypes from "prop-types";

import CreatableSelect from "react-select/creatable";

const SELECT_STYLES = {
  container: (base, state) => ({ ...base, width: "200px" }),
  menu: (base, state) => ({ ...base }),
};

const isString = (str) => typeof str === "string";
const isNumber = (num) => !isNaN(parseFloat(num));

const FilterCreatable = ({
  isMulti = false,
  disabled,
  validator,
  values,
  onChange,
  placeholder = "Filter Value",
}) => (
  <CreatableSelect
    isDisabled={disabled}
    isClearable
    isMulti={isMulti}
    placeholder={placeholder}
    options={[]}
    value={values || []}
    onChange={onChange}
    createOptionPosition="last"
    isValidNewOption={validator}
    styles={SELECT_STYLES}
  />
);

const FilterInputs = {
  string: ({ values, onChange, disabled }) => (
    <FilterCreatable
      disabled={disabled}
      validator={isString}
      values={values}
      onChange={(values) => onChange(values.map(({ value }) => value))}
      isMulti
    />
  ),

  number: ({ values, onChange, disabled }) => (
    <FilterCreatable
      disabled={disabled}
      validator={isNumber}
      values={values}
      onChange={(selected) =>
        onChange(selected && selected.value ? [selected.value] : selected)
      }
    />
  ),
};

FilterInputs.string.propTypes = {
  values: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

FilterInputs.string.defaultProps = {
  values: [],
};

FilterInputs.number.propTypes = {
  values: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

FilterInputs.number.defaultProps = {
  values: [],
};

const FilterInput = ({ member, updateMethods, disabled }) => {
  const Filter = FilterInputs[member.dimension.type] || FilterInputs.string;
  const derivedValues = member.values
    ? member.values.map((value) => ({ value, label: value }))
    : [];

  return (
    <Filter
      disabled={disabled}
      key="filter"
      values={derivedValues}
      onChange={(values) =>
        updateMethods.update(member, {
          ...member,
          values,
        })
      }
    />
  );
};

FilterInput.propTypes = {
  member: PropTypes.shape({
    // "values" are dynamically created by the react-select creatable component
    // and passed back to "FilterInput".
    values: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  }).isRequired,
  updateMethods: PropTypes.object.isRequired,
};

export default FilterInput;
