/* eslint-disable arrow-body-style */
import React, { useMemo } from 'react';
import ReactPlayer from 'react-player';
import Tooltip from '../../../components/Tooltip';
import { useHover } from '../../../hooks/useHover';
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from '../contexts';
import './HoverWindow.scss';
import './SquaresList.scss';
import { STANDARD_CATEGORIES_LOOKUP, EDITABLE_CATEGORIES } from '../categories';
import { toCurrency, toFixed_2, toPercent_3 } from '../../../utils/numbers';
import { useExplorerContext } from '../Explorer/contexts/explorerContext';
import { hasRoles } from '../../../cookies';
import { toInt } from '../../../utils/numbers';
import { formatMetricLabel } from '../utils/formatMetricLabel';
import { useViewSettings } from '../../../selectors';
import { TooltipWrapper } from '../../../components/Tooltip/Tooltip';
import { formatMetricValue } from '../../DNA/utils';
import { useCreativeRequestGet } from '../CreativeRequest/contexts';
import { CUSTOM_SCORING_METRICS } from './constants';
import { useSelector } from 'react-redux';
import { useSortVisualIdsBySpend, useVisualsLookup } from '../selectors';

const sortVisuals = (visuals) => {
  try {
    return [...visuals].sort((a, b) => b.spend - a.spend);
  } catch (e) {
    console.error(e);
    return visuals;
  }
};

const SquaresList = ({
  winner_ids = [],
  loser_ids = [],
  winners = [],
  losers = [],
  category,
  label_type,
  is_compare,
  udc,
  shouldUseOwnVisualsData,
}) => {
  const {
    newAds = [],
    isROAS,
    isOrganicView,
    primary_metric,
    planningMode,
    coreVisuals,
  } = useCreativeAnalyticsGet();
  const { setParams, handleSelectElement, setCoreVisuals } =
    useCreativeAnalyticsSet();
  const { drawerState, drawerDispatch } = useExplorerContext();
  const visualsLookup = useVisualsLookup();
  const sortVisualIdsBySpend = useSortVisualIdsBySpend();

  const getVisualData = (visual_id) => {
    if (shouldUseOwnVisualsData) {
      return [...winners, ...losers].find((id) => visual_id);
    } else {
      return visualsLookup.get(visual_id);
    }
  };

  const handleClick = (visual_id) => {
    const visual = getVisualData(visual_id);

    if (!visual) return;

    if (planningMode) {
      let update = coreVisuals;

      if (visual.asset_url) {
        if (coreVisuals[`${currentIndex}`].urls.includes(visual.asset_url)) {
          const filteredTypes = update[`${currentIndex}`].types.filter(
            (type, i) =>
              i === update[`${currentIndex}`].urls.indexOf(visual.asset_url)
          );
          const filteredUrls = update[`${currentIndex}`].urls.filter(
            (url) => url !== visual.asset_url
          );

          update = {
            ...update,
            [`${currentIndex}`]: {
              urls: [...filteredUrls],
              types: [...filteredTypes],
              html: filteredUrls.length
                ? filteredUrls
                    .map((url) => {
                      return `<p>Core visual: <a href="${url}" rel="noopener noreferrer" target="_blank">${url}</a></p>`;
                    })
                    .reduce((str, acc) => acc + str)
                : '',
            },
          };
        } else {
          const urls = [...update[`${currentIndex}`].urls, visual.asset_url];
          const types = [
            ...update[`${currentIndex}`].types,
            visual.visual_type,
          ];

          update = {
            ...update,
            [`${currentIndex}`]: {
              urls: [...urls],
              types: [...types],
              html: urls.length
                ? urls
                    .map((url) => {
                      return `<p>Core visual: <a href="${url}" rel="noopener noreferrer" target="_blank">${url}</a></p>`;
                    })
                    .reduce((str, acc) => acc + str)
                : '',
            },
          };
        }

        setCoreVisuals(update);
      }

      return;
    }
    if (handleSelectElement)
      handleSelectElement(visual_id, 'visual_hash', false);
    if (hasRoles(['internal']) && !!drawerDispatch) {
      drawerDispatch({
        type: 'OPEN',
        currentView: { type: 'category_viewer', id: category },
        selectedVisualId: visual_id,
      });
    } else {
      setParams({ rc: visual_id });
    }
  };

  const sortedWinners = useMemo(
    () => sortVisualIdsBySpend(winner_ids),
    [winner_ids, sortVisualIdsBySpend]
  );
  const sortedLosers = useMemo(
    () => sortVisualIdsBySpend(loser_ids),
    [loser_ids, sortVisualIdsBySpend]
  );

  return (
    <div className="squares-list">
      <div className="squares-list__section winners-squares">
        {sortedWinners.map((id) => (
          <Square
            id={id}
            type="winner"
            category={category}
            isNew={newAds?.some((n) => n === id)}
            grayed={checkIfGrayed(id, category, drawerState)}
            {...{
              getVisualData,
              isOrganicView,
              primary_metric,
              handleClick,
              isROAS,
            }}
          />
        ))}
      </div>
      <div className="squares-list__section losers-squares">
        {' '}
        {sortedLosers.map((id) => (
          <Square
            id={id}
            type="loser"
            category={category}
            isNew={newAds?.some((n) => n === id)}
            grayed={checkIfGrayed(id, category, drawerState)}
            {...{
              getVisualData,
              isOrganicView,
              primary_metric,
              handleClick,
              isROAS,
            }}
          />
        ))}
      </div>
    </div>
  );
};

const checkIfGrayed = (id, category, drawerState) => {
  let grayed = false;

  if (drawerState?.isOpen) {
    if (
      drawerState.currentView.type === 'visual_viewer' &&
      drawerState.selectedVisualId &&
      drawerState.selectedVisualId !== id
    ) {
      grayed = true;
    }
    if (
      drawerState.currentView.type === 'category_viewer' &&
      drawerState.currentView.id === category &&
      drawerState.selectedVisualId &&
      drawerState.selectedVisualId !== id
    ) {
      grayed = true;
    }
  }

  return grayed;
};

// don't reference metric data until needed
const Square = ({
  id,
  type,
  category,
  grayed,
  getVisualData,
  isNew,
  isROAS,
  isOrganicView,
  primary_metric,
  handleClick,
}) => {
  const [ref, isHovered] = useHover();

  const NewIcon = <div className="square__new"></div>;

  if (!isHovered)
    return (
      <>
        <div
          ref={ref}
          className={`square ${type} ${grayed ? 'grayed' : ''}`}
          onClick={() => handleClick(id)}
        >
          {isNew && NewIcon}
        </div>
      </>
    );

  return (
    <>
      <TooltipWrapper
        content={
          <AssetDetails
            {...{
              id,
              getVisualData,
              isROAS,
              isOrganicView,
              primary_metric,
            }}
          />
        }
        shouldShow={isHovered}
        tooltipProps={{
          placement: 'left',
          follow: true,
          distance: 0,
          delay: [100, 100],
          maxWidth: 'none',
          noPadding: true,
        }}
      >
        <div
          ref={ref}
          className={`square ${type} ${grayed ? 'grayed' : ''}`}
          onClick={() => handleClick(id)}
        >
          {isNew && NewIcon}
        </div>
      </TooltipWrapper>
    </>
  );
};

export default SquaresList;

export const getMetricValue = (visual, primary_metric, isROAS) => {
  const value = visual[primary_metric];

  if (!value) {
    if (primary_metric === 'Rockerbox_Roas' && visual.spend >= 0) {
      return toFixed_2(visual.value_Rockerbox_ROAS / visual.spend);
    }

    return 'N/A';
  }

  if (
    [
      'Adset SOV',
      'Adset Relative CPA Percentage',
      'Adset Relative CPC Percentage',
      'Adset Relative CTR Percentage',
    ].includes(primary_metric)
  ) {
    const calculatedValue = visual[primary_metric] / visual['Instances'];
    return !isNaN(calculatedValue) ? toFixed_2(calculatedValue) : 'N/A';
  }
  return formatMetricValue(primary_metric, value, isROAS);
};

export const AssetDetails = ({
  id,
  getVisualData,
  active = true,
  primary_metric,
}) => {
  const visual = getVisualData(id);
  if (!active || !visual) return null;
  const { isOrganicView, isEmailView, isCompressionView, isROAS } =
    useCreativeAnalyticsGet();
  const { _id, ad_names, asset_url, visual_type, cpa, added, performance } =
    visual;
  const type = visual_type === 'video' ? 'Video' : 'Image';
  const thumbnail = visual.thumbnail_url || null;
  const isCustomScoringMetric = CUSTOM_SCORING_METRICS.includes(primary_metric);
  const metricValue = isCustomScoringMetric
    ? visual[primary_metric]
    : getMetricValue(visual, primary_metric, isROAS);

  let videoDisplay = (
    <div className="d-flex" style={{ background: '#1a1a3aeb' }}>
      {thumbnail && (
        <div className="element-details__thumbnail">
          <img src={thumbnail} alt="" />
          <p>THUMBNAIL</p>
        </div>
      )}

      {asset_url ? (
        <video
          className="element-details__img"
          key={asset_url}
          autoPlay
          loop
          muted
          plays-inline
        >
          <source src={asset_url} type="video/mp4" />
        </video>
      ) : (
        <p className="missing-asset-url">
          <i className="fa-solid fa-triangle-exclamation"></i>
          Missing Facebook Page Access
          <br />
          for this Asset
        </p>
      )}
    </div>
  );

  const uniqueAdNames = Array.from(new Set(ad_names));
  return (
    <div className="element-details" style={{ maxWidth: 'none' }}>
      <div className="element-details__example">
        {visual_type === 'image' && (
          <img className="element-details__img" src={asset_url} alt="" />
        )}
        {visual_type === 'video' && videoDisplay}
      </div>
      <div className="element-details__summary">
        <div className="d-flex align-items-center">
          <p className="mr-4">
            <span>
              {isOrganicView || isCompressionView
                ? primary_metric
                : isEmailView
                ? 'Total Opens'
                : 'Spend'}
              :
            </span>{' '}
            {isOrganicView
              ? toInt(visual[primary_metric])
              : isEmailView
              ? toInt(visual['Total Opens'])
              : toCurrency(visual.spend)}
          </p>

          {!isOrganicView && (
            <p className="">
              <span>
                {formatMetricLabel(primary_metric).replace(/_/g, ' ')}:
              </span>{' '}
              {metricValue}
            </p>
          )}
        </div>

        <h3 className="element-details__name">
          {type}: Hash {id ?? _id}
        </h3>
        {!isOrganicView && (
          <>
            <p>Used in the following ads:</p>
            <ul className="element-details__list">
              {uniqueAdNames?.map((ad_name) => (
                <li className="element-details__ad-name">{ad_name}</li>
              )) ?? null}
            </ul>
          </>
        )}

        {!!added && (
          <p className="mt-3">
            <span style={{ color: '#95DCFF' }}>Other formats: </span>
            {added.map(({ ratio }, i) => (
              <span>
                {ratio}
                {i < added.length - 1 && ', '}
              </span>
            ))}
          </p>
        )}
      </div>
    </div>
  );
};
