
import React from 'react';
import PropTypes from 'prop-types';

// imported from rest of app
import Select from 'react-select';

const filterAvailableOptions = (options, placeholder) => {
  const yelp_specific_measures = [
    'AdsMetrics.claimStartLanding',
    'AdsMetrics.choosePhotoView',
    'AdsMetrics.chooseTextView',
    'AdsMetrics.createAdView',
    'AdsMetrics.selectGoalView',
    'AdsMetrics.setBudgetView',
    'AdsMetrics.billingInfoView',
    'AdsMetrics.purchaseSuccess',
    'AdsMetrics.marketingLandingPage',
    'AdsMetrics.bizSearchPage',
    'AdsMetrics.newBusinessAddition',
    'AdsMetrics.signUpPage',
    'AdsMetrics.bizStandaloneSignupSuccess',
    'AdsMetrics.claimSignupSuccess',
    'AdsMetrics.crpExistingUserLanding',
    'AdsMetrics.phoneVerificationLandingPage',
    'AdsMetrics.businessClaimed',
    'AdsMetrics.yelpConnectPurchaseSuccess',
    'AdsMetrics.businessHighlightsPurchaseSuccess',
    'AdsMetrics.pageUpgradesPurchaseSuccess',
    'AdsMetrics.upgradePackagePitchView',
    'AdsMetrics.clickPitchLearnMore',
    'AdsMetrics.checkoutView',
    'AdsMetrics.clickExitModalDeclineOffer',
    'AdsMetrics.clickExitModalDiscountPlan',
    'AdsMetrics.clickExitModalGoBack',
    'AdsMetrics.purchaseSuccessUpgradePackage',
  ];
  const osh_specific_dimensions = ['Adsets.market'];
  const osh_specific_measures = [
    'AdsMetrics.booked_wv',
    'AdsMetrics.costPerWelcomeVisit',
    'AdsMetrics.qualified_lead',
    'AdsMetrics.costPerQualifiedLead',
  ];
  const purple_carrot_specific_measures = [
    'AdsMetrics.subscriptions',
    'AdsMetrics.costPerSubscription',
    'AdsMetrics.subscribeWebsite',
    'AdsMetrics.costPerSubscribeWebsite',
  ];
  const thimble_specific_measures = [
    'AdsMetrics.firstPurchase',
    'AdsMetrics.costPerFirstPurchase',
    'AdsMetrics.completeRegistration',
    'AdsMetrics.costPerCompleteRegistration',
  ];

  const osh_valid_view_ids = [
    'WFjEhPrMxWcFE4Bdm',
    '6xAM2dvwcmhvWdLDX',
    'uuJdYrd93nmHuvvus',
    'dHfLb5htWqFfoL23R',
    'mQ6RE7tTmgrA9CQo8',
    'nsnstPas2yu2gWFhN',
    '47Bzx6vBzoLtttf9t',
    'jj3ZH9b7hQLmH9hWt',
    'HvP7gCWwzj7JGnKcD',
    'aHxro2sYXtu3JPb7c',
    'jNScQqwnzTBhgCPKB',
    'jvQQnXTNNN2kSqdBi',
    'K2TmFLd4pkFYqDkKF',
    'z3w7dkWGTiWuwpKGs',
    'zmT9SQeks8zgaJPCf',
    'gd2k5GHThSGre9PMH',
    '755q9uPdLqqik4FpX',
    'DXFJekoXrx3Wn3q56',
    'TzoihEq2fBFBvkK7r',
    'BWLJ8Szrq3CJMvSEy',
    'JAYF9n2gFSuAxdDjh',
    'o8XYFE6oc6gPsd4gh',
    'RF2ksj5dAcXcEB5DC',
    'chzwAbXMNRb4mWaRy',
    'n9AkhJxDEpiM2fziK',
    'is3Tm4z59Ya2tNqhC',
    'AuWaaehC7ZT4sv9d2',
    'tZgkGWPr3dsw2x8hW',
    'x7d9rLbMXysjLKBEy',
    'jJkxbinxGFuAGYzRR',
    'SE4jRd5q78oZ6yK4Z',
    'i37sZ9yxi3APhvJnJ',
    'csD82Y6Bv86gczZsF',
    'iNFsXykpPZYLuToRE',
    'SNjskTr29saYc7kh8',
    'shz25gSuxHfX9NZze',
    'jWmSzRPrMbvakDc59',
    'wjDtsDrqkKRdPuWKy',
    'jTWKmbcAqXoXbKf8n',
    'b648NfNSCMYRc2GEw',
    'GCnBkdyfsjnEndBdW',
    'Tff4biGkns2gmgJoc',
    'b5k5SeMTnBKg4kxu3',
    'WaAkRWtrrewwB72XL',
  ];
  const yelp_valid_view_ids = [
    'qWzMFKkBn8Ch3rvGY',
    '2adMGNytwfoTrcjh6',
    '5JWx76eEeqNbvfF4z',
    'wDnMD3FuSWNC8WXNE',
    'ZneGHF3WshSbND6ve',
    '9cQvMJY7MhFt4ckpH',
    'BPJCt9coEzsEyeCx4',
    'pd6m2LRLvNZqHPQkc',
    '28MLQL3HyWmLbS4mP',
    'fGgupm9vRBn9PLMQo',
    'XghLKmmjvxkaWwe2Z',
    '3E5LTQHDhTAE4ig4a',
    'GYnoyPFQQGszM2G3L',
  ];
  const purple_carrot_view_ids = [
    'TeXH9yzg5t2uT56mq',
    '3dahWhiLQq4J5rTo6',
    'WwN3hbi2cSWaBCcoh',
    'phvBRzcih3H87A7kH',
    'Y5vDbaG4ETQ7iTntd',
    'PPiXZp5yzaeF2ZBGi',
    'F7bRogzQkyseoTzbL',
    'nB3ifMwQ3vNuCSR84',
    'hseHnuzojy2kEaTMS',
  ];
  const thimble_valid_view_ids = [
    'ArkQHFDEMb3cXYKW8',
    'hvBAxH5gSLRJnLDkj',
    'pe5yRAXEG63pBouty',
    'nNB3oyC8qvdy2ckMw',
    'M8wtSJoYhQhcs8iEy',
    'rAbQuPZzssWFozLPi',
    'FNs8uAFgvXHGTThJL',
    'y7TgN7pLLLC8qPb2t',
    'ktpkgMBE55jZCSfjN',
    'QPu46u6nCyGqDQBy4',
  ];

  if (placeholder === 'Measure' && options.length > 0) {
    const view_id = location.pathname;

    if (
      !yelp_valid_view_ids.some((v) => view_id.indexOf(v) >= 0)
    ) {
      options = options.filter((e) => yelp_specific_measures.indexOf(e.name) < 0);
    }

    if (
      !osh_valid_view_ids.some((v) => view_id.indexOf(v) >= 0)
    ) {
      options = options.filter((e) => osh_specific_measures.indexOf(e.name) < 0);
    }

    if (
      !purple_carrot_view_ids.some((v) => view_id.indexOf(v) >= 0)
    ) {
      options = options.filter((e) => purple_carrot_specific_measures.indexOf(e.name) < 0);
    }

    if (
      !thimble_valid_view_ids.some((v) => view_id.indexOf(v) >= 0)
    ) {
      options = options.filter((e) => thimble_specific_measures.indexOf(e.name) < 0);
    }
  } else if (placeholder === 'Dimension' && options.length > 0) {
    const view_id_string = location.pathname;
    if (
      !osh_valid_view_ids.some((v) => view_id_string.indexOf(v) >= 0)
    ) {
      options = options.filter((e) => osh_specific_dimensions.indexOf(e.name) < 0);
    }
  }
  return options;
};

const getSelectStyle = (width) => ({
  container: (base, state) => ({ ...base, width }),
  menu: (base, state) => ({ ...base }),
});

const MemberDropdown = ({
  onChange,
  options,
  selected,
  placeholder = 'Select...',
  size,
  disabled = false,
}) => (
  <Select
    isDisabled={disabled}
    placeholder={placeholder}
    isClearable
    styles={getSelectStyle(size)}
    options={filterAvailableOptions(options, placeholder)}
    value={selected}
    onChange={onChange}
  />
);

MemberDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  selected: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

export default MemberDropdown;
