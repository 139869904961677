import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { toCurrency, toFixed_2 } from '../../../../utils/numbers';

import { darwinDateToChartDate } from '../dates';

function formatInterval(interval, val) {
  switch (interval) {
    case 'weekly':
      return `Week of <b>${val}</b>`;

    case 'monthly':
      return `Month of <b>${val}</b>`;

    default:
      return `<b>${val}<b>`;
  }
}

function formatter(field, n) {
  switch (field) {
    case 'spend':
    case 'cpm':
    case 'cpa':
    case 'cpr':
    case 'cpc':
    case 'cp_thruplay':
    case 'cp_ga_conversions':
    case 'cp_ga_conversion_value':
    case 'cp_ga_fb_initiate_checkout':
    case 'cp_ga_fb_add_to_cart':
    case 'cp_ga_fb_lp':
      return toCurrency(n);

    case 'ctr':
      return `${n}%`;

    default:
      if (n % 1 === 0) {
        return n;
      }
      return toFixed_2(n);
  }
}

function syncDates(dates, periodMetrics, metric) {
  // Assumes dates & dates in periodMetrics are sorted
  // the same way.
  const results = [];
  let i = 0;
  let j = 0;

  while (dates[i]) {
    const date = dates[i];
    const item = periodMetrics[j];

    // periodMetrics is shorter and missing later dates.
    if (!item) {
      results.push(null);
      i += 1;
      j += 1;
    } else if (date === item.period) {
      results.push(item[metric]);
      i += 1;
      j += 1;
      // periodMetrics is shorter and missing earlier dates.
    } else if (date < item.period) {
      results.push(null);
      i += 1;
    } else {
      j += 1;
    }
  }

  return results;
}

function preprocessState(blocks, metric) {
  const L = blocks.length;

  if (L === 0) {
    return { dates: [], series: [] };
  }

  // Some blocks provided by the api might
  // not have all the dates within the range
  // selected by the user.
  const unformattedDates = [...blocks]
    .sort((a, b) => b.metrics_by_period.length - a.metrics_by_period.length)[0].metrics_by_period
    .map(({ period }) => period);

  const series = [];

  for (let i = 0; i < L; i += 1) {
    const { block_name, metrics_by_period } = blocks[i];

    series.push({
      name: block_name,
      data: syncDates(unformattedDates, metrics_by_period, metric),
      marker: {
        enabled: false,
      },
    });
  }

  return {
    dates: unformattedDates.map(darwinDateToChartDate),
    series,
  };
}

const BlockType = PropTypes.shape({
  block_name: PropTypes.string,
  metrics_by_period: PropTypes.arrayOf(PropTypes.shape({
    period: PropTypes.string,
    spend: PropTypes.number,
    results: PropTypes.number,
  })),
});

function makeState({
  series, dates, numberFormatter, intervalFormatter,
}) {
  return {
    series,

    xAxis: [
      {
        type: 'datetime',
        visible: true,
        tickPositions: [0, dates.length - 1],
        categories: dates,
        plotOptions: {
          series: {
            groupPadding: 0,
          },
        },
        labels: {
          enabled: true,
        },
      },
    ],

    tooltip: {
      formatter() {
        const {
          y, color, category, series: { name },
        } = this.point;

        return `
          ${intervalFormatter(category)}
          <br />
          <span style="color: ${color}">\u25CF</span> ${name}: <b>${numberFormatter(y)}</b>`;
      },
    },

    loading: {
      hideDuration: 500,
      showDuration: 500,
    },

    yAxis: {
      title: false,
      labels: {
        align: 'left',
        x: 0,
        y: -2,
      },
    },

    chart: {
      height: 200,
      style: {
        fontFamily: '"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      },
    },

    legend: {
      align: 'left',
      verticalAlign: 'top',
      // line symbol to the left of the name
      // .highcharts-legend-item
      // needs stroke width to match font size
    },

    title: {
      text: '',
    },

    credits: {
      enabled: false,
    },
  };
}

export default function BlockHistoryChart({
  blocks, loading, metric, interval,
}) {
  const $chart = useRef(null);

  useEffect(() => {
    if (loading) {
      $chart.current.showLoading();
    } else {
      $chart.current.hideLoading();
    }
  }, [loading]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      // @ts-ignore
      options={makeState({
        ...preprocessState(blocks, metric),
        numberFormatter: (n) => formatter(metric, n),
        intervalFormatter: (d) => formatInterval(interval, d),
      })}
      callback={(chart) => {
        $chart.current = chart;
      }}
    />
  );
}

BlockHistoryChart.propTypes = {
  loading: PropTypes.bool.isRequired,
  blocks: PropTypes.arrayOf(BlockType).isRequired,
  metric: PropTypes.string.isRequired,
  interval: PropTypes.string.isRequired,
};
