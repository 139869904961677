import React from 'react';
import { CommonElements } from '../../DNA/CommonElements';
import HurdleVisual from './Visual';

const HurdleColumn = ({
  visuals,
  description,
  commonElementsData,
  filteredData,
  hiddenElements,
  setHiddenElements,
  commonElementsLoading,
  error,
  primary_metric,
  primaryMetricLabel,
  isROAS,
  isSharedHurdleReport,
}) => {
  const styles = {
    height: '100px',
    borderTop: '1px solid #e6e9f2',
    borderBottom: '1px solid #e6e9f2',
    padding: '6px 0',
    marginBottom: '2rem',
  }

  return (
    <div className="hurdle__column">
      <div className="description">{description}</div>
      <div className="hurdle-common-elements">
        {visuals?.length ? (
          <>
            {commonElementsLoading ? (
              <div
                style={{...styles}}
              >
                Loading...
              </div>
            ) : !error ? (
              <CommonElements
                isHurdleReport={true}
                isSharedHurdleReport={isSharedHurdleReport}
                loading={commonElementsLoading}
                styles={styles}
                {...{
                  commonElementsData,
                  filteredData,
                  hiddenElements,
                  setHiddenElements,
                }}
              />
            ) : (
              <div>Error fetching common elements.</div>
            )}
          </>
        ) : null}
      </div>
      <div className="visuals-grid">
        {visuals.length ? (
          visuals.map((visual) => {
            return (
              <HurdleVisual
                {...{ visual, primaryMetricLabel, primary_metric, isROAS }}
              />
            );
          })
        ) : (
          <div>No visuals to display here.</div>
        )}
      </div>
    </div>
  );
};

export default HurdleColumn;
