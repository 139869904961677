import React from 'react';
import { DARWIN_BLUE } from '../../../constants';

const TableIcon = ({ active }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.37814 6.19885H14.8781M6.62814 0.948853V14.4489M1.37814 2.44885C1.37814 2.05103 1.53618 1.6695 1.81748 1.38819C2.09879 1.10689 2.48032 0.948853 2.87814 0.948853H13.3781C13.776 0.948853 14.1575 1.10689 14.4388 1.38819C14.7201 1.6695 14.8781 2.05103 14.8781 2.44885V12.9489C14.8781 13.3467 14.7201 13.7282 14.4388 14.0095C14.1575 14.2908 13.776 14.4489 13.3781 14.4489H2.87814C2.48032 14.4489 2.09879 14.2908 1.81748 14.0095C1.53618 13.7282 1.37814 13.3467 1.37814 12.9489V2.44885Z"
      stroke={active ? DARWIN_BLUE : '#616161'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default TableIcon;
