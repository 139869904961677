import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CustomDropdown from '../../../components/CustomDropdown';
import LazyLoader from '../../../components/LazyLoader';

import { InfoIcon, PreviewIcon } from './Helpers';

import { PREVIEW_DROPDOWN_STYLE } from './constants';
import { DARWIN_NAVY } from '../../../constants';

const Layout = ({
  adName,
  AdPreview,
  StrikeZone,
  StandaloneLabelMatrix,
  PerformancePanel,
  VisualAttributes,
  options,
  handleSelectPreview,
}) => {
  const [display, setDisplay] = useState('Creative Stats');
  const [showName, setShowName] = useState(false);
  const [showNamePreview, setShowNamePreview] = useState(false);

  function handleShowName() {
    setShowName(true);
  }

  function handleCloseName() {
    setShowName(false);
  }

  function handleToggleNamePreview() {
    setShowNamePreview(!showNamePreview);
  }

  return (
    <>
      <div className="ad-preview darwin-box-shadow">
        <div className="ad-preview-header">
          <div className="ad-preview__select">
            <Select
              value={{ label: <PreviewIcon style={{}} /> }}
              options={options}
              onChange={(selected) => handleSelectPreview(selected)}
              styles={PREVIEW_DROPDOWN_STYLE}
            />
          </div>
          <InfoIcon
            onShowName={handleShowName}
            onCloseName={handleCloseName}
            onToggleNamePreview={handleToggleNamePreview}
          >
            {!!showName && <div className="ad-preview__name-popup" onClick={(e) => e.stopPropagation()}><p>{adName}</p></div>}
          </InfoIcon>

          <h5 className={!showNamePreview ? 'ad-preview__name' : 'ad-preview__name ad-preview__name--selected'}>{adName}</h5>

        </div>
        {AdPreview}

        <div className="ad-preview-dropdown-wrapper">
          <CustomDropdown
            label={display}
            onSelect={(key) => setDisplay(key)}
            toggleStyle={{
              width: '100%',
              borderRadius: '4px',
              padding: '0.5rem 1rem',
              color: DARWIN_NAVY,
              fontSize: '1.2rem',
              background: '#fff',
              fontWeight: '500',
              marginTop: '3rem',
            }}
            menuStyle={{
              width: 'fit-content',
            }}
          >
            <CustomDropdown.Item eventKey="Creative Stats">
              Creative Stats
            </CustomDropdown.Item>
            <CustomDropdown.Item eventKey="Creative Elements">
              Creative Elements
            </CustomDropdown.Item>
            <CustomDropdown.Item eventKey="Details">
              Details
            </CustomDropdown.Item>
          </CustomDropdown>
        </div>

        <LazyLoader className="preview-details" placeholder={<div style={{ height: '100px' }} />}>
          {display === 'Creative Stats' ? (
            StrikeZone
          ) : display === 'Creative Elements' ? (
            StandaloneLabelMatrix
          ) : display === 'Visual Attributes' ? (
            VisualAttributes
          ) : (
            PerformancePanel
          )}
        </LazyLoader>
      </div>
    </>
  );
};

Layout.propTypes = {
  adName: PropTypes.string.isRequired,
  AdPreview: PropTypes.node.isRequired,
  StrikeZone: PropTypes.node.isRequired,
  PerformancePanel: PropTypes.node.isRequired,
};

export default Layout;
