import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import usePortal from 'react-cool-portal';

// import Portal from './Portal';
import './Overlay.scss';
import { CloseIcon } from '../Icons';

const Overlay = ({
  show = true,
  id,
  style = {},
  labelledby,
  children,
  close,
}) => {
  const { Portal, isShow } = usePortal({ containerId: 'modal-root' });

  return show ? (
    <Portal>
      <div
        className="overlay"
        style={style}
        id={id}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={labelledby}
        aria-hidden="true"
      >
        {close && (
          <CloseIcon
            style={{
              position: 'absolute',
              left: '1rem',
              top: '1rem',
              cursor: 'pointer',
            }}
            onClick={close}
            fill="#fff"
          />
        )}
        {children}
      </div>
    </Portal>
  ) : null;
};

Overlay.destroy = function destroyModal(modalId) {
  window.$(modalId).modal('dispose');
  window.$('.modal-backdrop').remove();
};

// Modal.defaultProps = {
//   modalClass: 'modal-lg',
//   Footer: null,
//   Title: null,
//   Header: null,
//   Body: null,
//   labelledby: '',
//   allowClose: true,
//   footerStyle: {},
// };

// Modal.propTypes = {
//   Title: PropTypes.node,
//   Header: PropTypes.node,
//   Body: PropTypes.node,
//   Footer: PropTypes.node,
//   labelledby: PropTypes.string,
//   id: PropTypes.string.isRequired,
//   show: PropTypes.bool.isRequired,
//   handleHide: PropTypes.func.isRequired,
//   modalClass: PropTypes.string,
//   allowClose: PropTypes.bool,
//   footerStyle: PropTypes.shape({ color: PropTypes.string }),
// };

export default Overlay;
