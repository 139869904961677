import React, { useContext } from 'react';
import { useVisualRows } from './hooks';
import { useSimpleImpactApi } from './SimpleImpact';

const RecommendationsContext = React.createContext(null);

export const RecommendationsProvider = ({ children }) => {
  const simpleImpactApi = useSimpleImpactApi();
  const { rows: visuals, fetchPrevPeriodData, trendData } = useVisualRows();

  return (
    <RecommendationsContext.Provider
      value={{
        visuals,
        simpleImpactApi,
        fetchPrevPeriodData,
        trendData,
      }}
    >
      {children}
    </RecommendationsContext.Provider>
  );
};

export const useRecommendationsContext = () =>
  useContext(RecommendationsContext);
