import { CreativeGroup } from '../types';

export const generateTitle = (group: CreativeGroup) => {
  const { selectBy, metric, count, selectedLabel, sortBy, sortOrder } = group;

  if (selectBy === 'custom') return group;

  if (selectBy === 'top') {
    return `Top ${count} ${
      metric === 'spend' ? 'Spending' : `Performing by ${metric ?? ''} sorted by ${sortBy} ${sortOrder}`
    } `;
  }

  if (selectBy === 'bottom') {
    return `Bottom ${count} ${
      metric === 'spend' ? 'Spending' : `Performing by ${metric ?? ''} sorted by ${sortBy} ${sortOrder}`
    } `;
  }

  if (selectBy === 'element') return `${selectedLabel} sorted by ${sortBy} ${sortOrder}`;
};
