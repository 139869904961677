import { Marketplace } from '../actions';
import { Assets } from '../Assets/actions';
import { Versions } from './actions';

/*
version: string
src: string
type: string
approved: boolean
client_approved: boolean
created: number
*/

function setVersions({ data, byIds }) {
  const lookup = {};
  const ids = [];

  byIds.forEach((aid) => {
    const asset = data[aid];

    asset.versions.forEach((version) => {
      const vid = version.version;

      lookup[vid] = version;
      ids.push(vid);
    });
  });

  return {
    lookup,
    ids,
  };
}

function toggleApproval(
  { ids, lookup },
  { data: { versionId, ...rest } },
  key
) {
  const version = lookup[versionId];
  const value = rest[key];

  return {
    ids,
    lookup: { ...lookup, [versionId]: { ...version, [key]: value } },
  };
}

/**
 * @param {Object} lookup
 * @param {Object} action
 * @param {import('./Assets/Asset').AssetVersion} action.data
 * @param {{ filename: string, versionId: string, project_id: string }} action.meta
 */
function setUpload({ lookup, ids }, { data, meta: { versionId } }) {
  return {
    ids: [...ids, versionId],
    lookup: { ...lookup, [versionId]: data },
  };
}

function removeKey(dict, key) {
  return Object.keys(dict).reduce((acc, k) => {
    if (k === key) {
      return acc;
    }
    acc[k] = dict[k];

    return acc;
  }, {});
}

function removeUpload({ ids, lookup }, { data: { versionId } }) {
  return {
    ids: ids.filter((id) => id !== versionId),
    lookup: removeKey(lookup, versionId),
  };
}

function addVersion({ ids, lookup }, { data: { version, data } }) {
  console.log(version)
  console.log(data)
  return {
    ids: {
      ...ids,
      version,
    },
    lookup: {
      ...lookup,
      [version]: data,
    },
  };
}

function updateVersion({ ids, lookup }, { data: { version, data } }) {
  const item = lookup[version];

  return {
    ids,
    lookup: {
      ...lookup,
      [version]: { ...item, ...data },
    },
  };
}

export function versions(state = { ids: [], lookup: {} }, action) {
  switch (action.type) {
    case Assets.SET:
      return setVersions(action);

    case Marketplace.UPLOAD_FILE:
      return setUpload(state, action);

    case Marketplace.UPLOAD_FAILURE:
      return removeUpload(state, action);

    case Versions.ADD_ONE:
      return addVersion(state, action);

    case Versions.UPDATE_ONE:
      // Merge operation on existing version data.
      return updateVersion(state, action);

    case Versions.TOGGLE_INTERNAL_APPROVAL:
      return toggleApproval(state, action, 'approved');

    case Versions.TOGGLE_CLIENT_APPROVAL:
      return toggleApproval(state, action, 'client_approved');

    case Marketplace.RESET_ASSETS:
      return { lookup: {}, ids: [] };

    default:
      return state;
  }
}
