import clsx from 'clsx';
import React, { useEffect, useReducer } from 'react';
import Button from '../../../../../components/Button';
import Tooltip from '../../../../../components/Tooltip';
import { handleTruncate } from '../../../LeftDisplay/utils';

import { LabelForm, LabelFormPlaceholder } from './LabelForm';

import {
  reducer,
  SELECT_LABEL,
  ADD_LABEL,
  EDIT_LABEL,
  DELETE_LABEL,
} from './reducer';
import Sidebar from './Sidebar';
import {
  makeEmptyLabel,
  makeCreatePayload,
  makeUpdatePayload,
  makeDeletePayload,
} from './utils';
import { validateLabels } from './validate';

function LabelItem({ name, onClick, selected, isEditing, status }) {
  // const btnClass = selected ? 'darwin-btn-selected' : 'darwin-btn';
  // const pClass = selected ? 'text-white' : '';
  // const style = {
  //   width: '99%',
  //   borderRadius: '1px',
  // };

  return (
    <li
      onClick={onClick}
      className={clsx('label-nav__item', {
        ['label-nav__item--selected']: selected,
        ['label-nav__item--is-editing']: isEditing,
      })}
    >
      <Tooltip content={name} follow={false} placement="right">
        <p className="label-nav__label">{handleTruncate(name, 30)}</p>
      </Tooltip>

      {selected && isEditing && (
        <Tooltip content="Currently editing" follow={false} delay={[100, 0]}>
          <div>
            <div className="dot-flashing"></div>
          </div>
        </Tooltip>
      )}
    </li>
  );
}

function LabelsList({ len, children }) {
  return (
    <ul
      className="label-nav"
      style={{ overflow: len > 0 ? 'auto' : 'initial' }}
    >
      {children}
    </ul>
  );
}

function Editor({
  onUpdate,
  prev,
  existing,
  account_id,
  labels,
  index,
  dispatch,
  isEditing,
  setIsEditing,
  data,
  getAdNameLabelsApi,
  getDerivedLabelsApi,
}) {
  const validate = (labels, getAdNameLabelsApi, getDerivedLabelsApi) => {
    try {
      return validateLabels(labels, getAdNameLabelsApi, getDerivedLabelsApi);
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  const errors = validate(labels, getAdNameLabelsApi, getDerivedLabelsApi);

  const handleSubmit = () => {
    onUpdate(makeCreatePayload(labels, account_id));
  };

  const handleSelect = (i) => dispatch({ type: SELECT_LABEL, index: i });

  const addLabel = (label) => {
    dispatch({
      type: ADD_LABEL,
      label,
    });
    setIsEditing(false);
  };

  const handleSelectNew = () =>
    dispatch({ type: ADD_LABEL, label: makeEmptyLabel() });

  const editLabel = (label) => {
    dispatch({
      type: EDIT_LABEL,
      index,
      label,
    });
    setIsEditing(false);
  };

  const handleDeleteLabel = (name, index) => {
    dispatch({ type: DELETE_LABEL, name, index });
  };

  let Details = null;

  if (index === -1) {
    Details = <LabelFormPlaceholder onNewClick={handleSelectNew} />;
  } else {
    const { filters, name, onSaveLabel } =
      index === -2
        ? { ...makeEmptyLabel(), onSaveLabel: addLabel }
        : { ...labels[index], onSaveLabel: editLabel };

    Details = (
      <LabelForm
        onSaveLabel={onSaveLabel}
        onDelete={() => handleDeleteLabel(name, index)}
        {...{
          filters,
          name,
          setIsEditing,
        }}
      />
    );
  }

  const SaveButtonWrapper = ({ children }) => {
    let shouldDisable = false;

    if (errors.length > 0) {
      shouldDisable = true;

      return (
        <Tooltip
          className="derived-labels__error-tooltip"
          content={
            <div>
              The following errors must be resolved:
              <ul>
                {errors.map((err) => (
                  <li>{err}</li>
                ))}
              </ul>
            </div>
          }
        >
          {children(shouldDisable)}
        </Tooltip>
      );
    }

    return children(shouldDisable);
  };

  return (
    <>
      <div className="d-flex justify-content-between h-100">
        <Sidebar
          onSubmit={handleSubmit}
          {...{ prev, handleSelectNew, existing }}
          renderLabelsList={() => (
            <LabelsList len={labels.length}>
              {labels.map(({ id, name }, i) => (
                <LabelItem
                  key={id}
                  name={name.length ? name : 'New Label'}
                  selected={i === index}
                  onClick={() => handleSelect(i)}
                  isEditing={isEditing && i === index}
                />
              ))}
            </LabelsList>
          )}
        />
        <div className="label-form">{Details}</div>
      </div>
      <div className="footer">
        <SaveButtonWrapper>
          {(shouldDisable) => (
            <div>
              <Button disabled={shouldDisable} onClick={handleSubmit}>
                Save Category
              </Button>
            </div>
          )}
        </SaveButtonWrapper>
      </div>
    </>
  );
}

export default Editor;
