export const CREATE_AD = 'CreateAd';
export const CREATE_AD_FETCH = `[${CREATE_AD}] FETCH`;
export const CREATE_AD_SET = `[${CREATE_AD}] SET`;

export const CREATE_AD_SET_AD = `[${CREATE_AD}] SET_AD`;
export const CREATE_AD_FETCH_AD = `[${CREATE_AD}] FETCH_AD`;

export const CREATE_AD_SET_CAROUSEL = `[${CREATE_AD}] SET_CAROUSEL`;
export const CREATE_AD_FETCH_CAROUSEL = `[${CREATE_AD}] FETCH_CAROUSEL`;

export const UPDATE_ELEMENT = `[${CREATE_AD}] UPDATE_ELEMENT`;
export const APPEND_ELEMENT = `[${CREATE_AD}] APPEND_ELEMENT`;

export const fetchCreateAd = () => ({
  type: CREATE_AD_FETCH,
  meta: { entity: CREATE_AD },
});

export const fetchCreateAd_ad = () => ({
  type: CREATE_AD_FETCH_AD,
  meta: { entity: CREATE_AD },
});

export const setCreateAd_ad = (data) => ({
  type: CREATE_AD_SET_AD,
  meta: { entity: CREATE_AD, normalizeId: '_id' },
  data,
});

export const setCreateAd_carousel = (data) => ({
  type: CREATE_AD_SET_CAROUSEL,
  meta: { entity: CREATE_AD },
  data,
});

export const fetchCreateAd_carousel = () => ({
  type: CREATE_AD_FETCH_CAROUSEL,
  meta: { entity: CREATE_AD },
});

export const updateElement = ({ value, type }) => ({
  type: UPDATE_ELEMENT,
  data: { value, type },
  meta: { entity: CREATE_AD },
});

export const appendCarouselElement = ({ value, type }) => ({
  type: APPEND_ELEMENT,
  data: { value, type },
  meta: { entity: CREATE_AD },
});
