import React, { useState, useEffect } from "react";
import { handleTestName, NameTooltip } from "../CreativeReports/BlockReport";
import { parseCurrency } from './utils';

const DirectMailResults = ({ show, selected, apiData, pValue, isIdealImage }) => {
  const [winningBlock, setWinningBlock] = useState("");
  const [losingBlock, setLosingBlock] = useState("");
  const [error, setError] = useState(false);

  const maxLength = 34;
  const test_cpa = selected.test_block_cpa ? parseCurrency(selected.test_block_cpa) : 0;
  const compare_cpa = selected.compare_block_cpa ? parseCurrency(selected.compare_block_cpa) : 0;
  const test_ctr = selected.test_block_ctr ? parseCurrency(selected.test_block_ctr) : 0;
  const compare_ctr = selected.compare_block_ctr ? parseCurrency(selected.compare_block_ctr) : 0;

  useEffect(() => {
    if (pValue > 0.05) {
      setError(true);
    } else if (pValue <= 0.05) {
      if (!isIdealImage) {
        if (test_cpa < compare_cpa) {
          setWinningBlock(selected.test_block_name);
          setLosingBlock(selected.compare_block_name);
          setError(false);
        } else if (test_cpa > compare_cpa) {
          setWinningBlock(selected.compare_block_name);
          setLosingBlock(selected.test_block_name);
          setError(false);
        } else if (test_cpa === compare_cpa) {
          setError(true);
        }
      } else {        
        if (test_ctr > compare_ctr) {
          setWinningBlock(selected.test_block_name);
          setLosingBlock(selected.compare_block_name);
          setError(false);
        } else if (test_ctr < compare_ctr) {
          setWinningBlock(selected.compare_block_name);
          setLosingBlock(selected.test_block_name);
          setError(false);
        } else if (test_ctr === compare_ctr) {
          setError(true);
        }
      }
    }
  }, [pValue, selected]);

  return show ? (
    !!apiData ? (
      <div className="stats-summary">
        <div className="conclusion-wrapper">
          {!error ? (
            <div>
              <div className="conclusion">
                <span className="block-name">
                  {winningBlock.length > 28 ? (
                    <NameTooltip text={winningBlock} />
                  ) : null}
                  <h2 className="winner">
                    {handleTestName(winningBlock, maxLength)}
                  </h2>
                </span>
                <br />
                <h2>is better than</h2>
                <br />
                <div className="block-name">
                  {losingBlock.length > 28 ? (
                    <NameTooltip text={losingBlock} />
                  ) : null}
                  <h2 className="loser">
                    {handleTestName(losingBlock, maxLength)}
                  </h2>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        {!error ? (
          <div>
            <h3>Statistical Summary</h3>
            <p>
              Provided all variables stay the same, there is a{" "}
              <u>
                <b>greater than 80% chance</b>
              </u>{" "}
              that&nbsp;
              <span className="block-name sm">
                {winningBlock.length > maxLength ? (
                  <NameTooltip text={winningBlock} />
                ) : null}
                <span className="winner">
                  {handleTestName(winningBlock, maxLength)}
                </span>
              </span>
              &nbsp;will continue to outperform&nbsp;
              <span className="block-name sm">
                {losingBlock.length > maxLength ? (
                  <NameTooltip text={losingBlock} />
                ) : null}
                <span className="loser">
                  {handleTestName(losingBlock, maxLength)}
                </span>
              </span>
              .
            </p>
          </div>
        ) : (
          <div>
            <h2 className="conclusion error">Expected CPA: Not Enough Data</h2>
            <p>
              There is not enough data or the performance is too similar to
              declare a winner in this test.
            </p>
          </div>
        )}
        {(isIdealImage ? selected.test_block_ctr : selected.test_block_cpa) !== (isIdealImage ? selected.compare_block_ctr : selected.compare_block_cpa) && (
          <p>
            The P Value is{" "}
            <span className={`pvalue ${error ? "error" : ""}`}>{pValue}</span>.
          </p>
        )}
      </div>
    ) : (
      <div></div>
    )
  ) : null;
};

export default DirectMailResults;
