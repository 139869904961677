//@ts-check
import { connect, batch } from "react-redux";
import { fetchReports } from "../../../store/CreativeReports/actions";
import { fetchRunningAdsByName } from "../../../store/RunningAdsByName/actions";
import { addNotification } from "../../../store/Notifications/actions";

const stateToProps = ({ ViewSettings: { viewId } }, props) => ({
  viewId,
});

const dispatchToProps = (dispatch, props) => ({
  fetchStudy: async ({
    viewId,
    studyName,
    description,
    studyType,
    adString,
    adsetString,
  }) => {
    try {
      const res = await fetch(["api", viewId, "ad-study"].join("/"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          studyType,
          studyName,
          description,
          adsetString,
          adString,
        }),
      });

      if (res.status < 400) {
        dispatch(
          addNotification(
            {
              message: "Created Ad study.",
              title: "Ad Study",
              id: Date.now(),
              status: "success",
            },
            "Ad Study"
          )
        );

        batch(() => {
          dispatch(fetchReports());
          dispatch(fetchRunningAdsByName([]));
        });
      } else {
        const { error } = await res.json();
        console.error(error);
        dispatch(
          addNotification(
            {
              message: error.message || "Failed to created Ad study.",
              title: "Ad Study",
              id: Date.now(),
              status: "danger",
            },
            "Ad Study"
          )
        );
      }
    } catch (err) {
      console.error(err);
      dispatch(
        addNotification(
          {
            message: "Failed to create Ad study.",
            title: "Ad Study",
            id: Date.now(),
            status: "danger",
          },
          "Ad Study"
        )
      );
    } finally {
      return null;
    }
  },
});

export default connect(stateToProps, dispatchToProps);
