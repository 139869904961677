import React from 'react';
import { BrowserIcon } from '../../../Explorer/icons/ToolbarIcons';

const Placeholder = ({ handleOpenDrawer, isBaseVisual, loading, disableDrawer }) => {
  return (
    <div className="visual-placeholder" onClick={handleOpenDrawer}>
      {!isBaseVisual ? (
        <div className={`bounce ${loading ? 'loading' : ''}`}>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center">
          <button>
            <BrowserIcon width="40" height="40" />
          </button>
          <span>Select a visual.</span>
          {disableDrawer && <span className="wait">Please wait...</span>}
        </div>
      )}
    </div>
  );
};
export default Placeholder;
