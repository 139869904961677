import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../components/BootstrapModal';
import AdsetPicker from './AdsetPicker';
import BudgetEditor from './BudgetEditor';
import Scatter from './Scatter';

import { DARWIN_RED, DARWIN_BLUE } from '../../../constants';

class BoostModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAdset: null,
      budget: 0,
    };

    this.Body = this.Body.bind(this);
    this.Footer = this.Footer.bind(this);
    this.handleSelectAdset = this.handleSelectAdset.bind(this);
    this.handleBudget = this.handleBudget.bind(this);
    this.handleBoost = this.handleBoost.bind(this);
  }

  componentWillUnmount() {
    this.Body = null;
    this.Footer = null;
    this.handleSelectAdset = null;
    this.handleBudget = null;
    this.handleBoost = null;
  }

  componentDidMount() {
    $(`#${this.props.id} > div.modal-lg`).css('max-width', 1000);
  }

  handleSelectAdset(selectedAdset) {
    this.setState({ selectedAdset });
  }

  handleBudget(e) {
    const val = parseFloat(e.target.value);

    this.setState({ budget: isNaN(val) ? 0 : val });
  }

  handleBoost(e) {
    const { value, adset_id, campaign_id } = this.state.selectedAdset;

    const payload = {
      ad_name: this.props.adName,
      adset_name: value,
      adset_id,
      campaign_id,
      budget: this.state.budget,
    };

    this.props.boostAd(payload);
    this.props.handleHide();
  }

  Body() {
    const { adName, show, adsetOptions } = this.props;
    const { selectedAdset } = this.state;
    const adsetName = selectedAdset ? selectedAdset.value : selectedAdset;

    return (
      <>
        <p>{adName}</p>

        <div className="row">
          <div className="col-md-6">
            {show && (
              <Scatter
                selectedId={adsetName}
                id="boost-scatter"
                data={adsetOptions.map(adjustColor(adsetName))}
                handleSeriesClick={this.handleSelectAdset}
              />
            )}
          </div>

          <div className="col-md-6">
            <h5>Step 1: Choose an adset</h5>
            <p>
              This is the adset that will be copied with the selected creative
            </p>

            <AdsetPicker
              selected={selectedAdset}
              handleSelect={this.handleSelectAdset}
              options={adsetOptions}
            />

            <br />

            <h5>Step 2: Choose a daily budget</h5>
            <p>
              Darwin will create a new adset with the daily budget specified
            </p>

            <BudgetEditor
              budget={this.state.budget}
              handleBudget={this.handleBudget}
            />
          </div>
        </div>
      </>
    );
  }

  Footer() {
    const { budget, selectedAdset } = this.state;

    return (
      <>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={this.props.handleHide}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary"
          disabled={!(selectedAdset && budget > 0)}
          onClick={this.handleBoost}
        >
          Boost
        </button>
        {' '}
      </>
    );
  }

  render() {
    const { id, show, handleHide } = this.props;
    const { Body, Footer } = this;

    return (
      <Modal
        id={id}
        handleHide={(e) => handleHide('')}
        labelledby="boost-modal-title"
        show={show}
        Title={(
          <div className="d-flex flex-column">
            <h5 className="font-weight-bold">Creative Boost</h5>
            <p className="mb-0">Force spend to specific ads using Darwin</p>
          </div>
        )}
        Body={<Body />}
        Footer={<Footer />}
      />
    );
  }
}

BoostModal.propTypes = {
  adName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  adsetOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  handleHide: PropTypes.func.isRequired,
  boostAd: PropTypes.func.isRequired,
};

export default BoostModal;

const adjustColor = (name) => ({ label, ...rest }) => ({
  ...rest,
  label,
  color: label === name ? DARWIN_RED : DARWIN_BLUE,
});
