
import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { OPTIONS } from './constants';

export const Trendline = ({
  data = [],
  axis = [],
  width = 300,
  backgroundColor,
  foregroundColor,
}) => {
  const $chart = useRef(null);

  useLayoutEffect(() => {
    if ($chart.current) {
      $chart.current.reflow();
    }

    return () => {
      $chart.current = null;
    };
  }, [width]);

  return (
    <HighchartsReact
      containerProps={{
        width: `${width}px`,
      }}
      highcharts={Highcharts}
      // @ts-ignore
      options={makeState({
        data,
        backgroundColor,
        foregroundColor,
        axis,
      })}
      callback={(chart) => ($chart.current = chart)}
    />
  );
};

Trendline.propTypes = {
  axis: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  width: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  foregroundColor: PropTypes.string.isRequired,
};

const makeState = ({
  data, backgroundColor, foregroundColor, axis,
}) => {
  const sideMargin = remToPx(1);

  return {
    chart: {
      backgroundColor,
      height: 100,
      margin: [sideMargin / 2, 0, 20, 0],
      spacing: [0, 0, 0, 0],
    },

    ...axisData(axis, data, foregroundColor),

    legend: {
      enabled: false,
    },

    title: {
      text: '',
    },

    exporting: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },

    xAxis: [
      {
        visible: false,
        categories: data.map(({ date }) => date),
        plotOptions: {
          series: {
            groupPadding: 0,
          },
        },
      },
    ],
  };
};

/**
 * @param {[String, String]} selected
 * @param {[Object]} data
 * @param {String} foregroundColor
 */
const axisData = ([left, right], data, foregroundColor) => {
  // foregroundColor needs to be the last element to enforce fgColor over white.
  // A spline chart should always be the last element in order to
  // overlay the line across the columns.
  const L = { ...OPTIONS.get(left), color: '#BFEAFF', side: 'L' };
  const R = { ...OPTIONS.get(right), color: foregroundColor, side: 'R' };

  const order = (R.type === 'spline' ? [L, R] : [R, L]).map((selection, i) => makeAxis(selection, {
    data,
    index: i,
  }));
  const [top, bottom] = order[0].side === 'R' ? [0, 1] : [1, 0];

  return {
    series: order.map(({ series }) => series),
    yAxis: order.map(({ yAxis }) => yAxis),

    tooltip: {
      shared: true,
      formatter() {
        // "this" bound to chart.
        return `
            <b>${this.x}</b>
            <br />
            ${order[top].tooltipFormatter(this)}
            <br />
            ${order[bottom].tooltipFormatter(this)}
        `;
      },
    },
  };
};

/**
 * @param {{ value: String, label: String, type: String, formatNum?: Function, color: String}} selection
 * @param {{data: [Object], index: Number }} chart
 */
const makeAxis = (
  {
    value, label, type, formatNum, color,
  },
  { data, index },
) => {
  const seriesData = data.map((item) => item[value]);
  const yMax = Math.max(...seriesData);

  return {
    tooltipFormatter: (obj) => {
      const pt = obj.points[index];

      return `${label}: <b>${axisValue(pt, formatNum)}</b>`;
    },
    series: {
      type,
      color,
      borderColor: color,
      yAxis: index,
      data: seriesData,
      name: label,
      marker: {
        enabled: false,
      },
    },

    yAxis: {
      gridLineWidth: 0,
      visible: false,
      max: yMax,
    },
  };
};

const remToPx = (n) => {
  const val = window.getComputedStyle(document.documentElement).fontSize;

  if (val === '') {
    // 16px is a reasonable guess.
    return 16 * n;
  }
  return parseFloat(val) * n;
};

const axisValue = (point, formatter = null) => {
  if (!point) {
    return 'N/A';
  }
  const val = point.y;

  if (!isNumber(val)) {
    return 'N/A';
  }
  return formatter ? formatter(val) : val;
};

const isNumber = (n) => typeof n === 'number' && !isNaN(n);
