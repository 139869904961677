import React from "react";
import { DARWIN_NAVY } from "../../../../constants";

export const DashboardIcon = () => {
  return (
    <svg
      className="dashboard-icon no-fill"
      width="15"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8572 5.9827L9.00003 12.4113M16.7143 12.4113C17.2435 11.1413 17.4509 9.7603 17.3182 8.39089C17.1855 7.02149 16.7168 5.70603 15.9536 4.56128C15.1904 3.41653 14.1564 2.4779 12.9434 1.82871C11.7304 1.17951 10.3758 0.839844 9.00003 0.839844C7.62421 0.839844 6.26967 1.17951 5.05664 1.82871C3.84362 2.4779 2.80963 3.41653 2.04647 4.56128C1.2833 5.70603 0.814558 7.02149 0.681852 8.39089C0.549147 9.7603 0.756581 11.1413 1.28574 12.4113H16.7143Z"
        stroke={DARWIN_NAVY}
        stroke-width="1.85"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
