import React, { useState, useMemo, useRef, useEffect } from 'react';
import Tooltip from '../../../components/Tooltip';
import { toCurrency, toFixed_2 } from '../../../utils/numbers';
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from '../contexts';
import { useIsDirectMail, useViewName } from '../selectors';
import CardOptions from '../TopPerformers/CardOptions';

import './VisualCard.scss';

const VisualCard = ({ visualObj, isHeader, viewName }) => {
  const { isROAS } = useCreativeAnalyticsGet();
  const isDirectMail = useIsDirectMail();
  const { setParams } = useCreativeAnalyticsSet();
  const [showOptions, setShowOptions] = useState(false);
  const vidRef = useRef(null);
  const medianMetric = isROAS ? 'median_roas' : 'median_cpa';
  const [hovered, setHovered] = useState(false);
  const headerCPA = isROAS ? 1 / visualObj.cpa : visualObj.cpa;

  let cpa = isHeader ? headerCPA : visualObj[medianMetric];
  const formattedCpa = isROAS ? toFixed_2(cpa) : toCurrency(cpa);
  const spend = visualObj.spend;
  const adNames = visualObj.ad_names;
  const type = visualObj.visual_type?.toLowerCase() === 'video' ? 'Video' : 'Image';

  if (typeof cpa === 'string') {
    cpa = Number(cpa.replace(/[^0-9.-]+/g, ''));
  }

  const handleClick = () => {
    setParams({ rc: visualObj.id });
  };

  const handleMouseEnter = () => {
    setShowOptions(true);
  };

  const handleMouseLeave = () => {
    setShowOptions(false);
  };

  let preview = <p className="text-white">There was an error.</p>;

  if (visualObj.visual_type === 'image') {
    preview = <img onClick={handleClick} src={visualObj.asset_url} alt="" />;
  } else if (visualObj.visual_type === 'video') {
    const url = visualObj.asset_url.length
      ? visualObj.asset_url
      : visualObj.thumbnail_url;
    preview = (
      <video
        onClick={handleClick}
        key={url}
        autoPlay
        loop
        muted
        plays-inline="true"
        ref={vidRef}
      >
        <source src={url} type="video/mp4" />
      </video>
    );
  }

  const Card = (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="visual-card__img"
    >
      {visualObj.visual_type === 'video' && (
        <i className="visual-card__play-icon fa fa-play"></i>
      )}
      {preview}
      {showOptions && (
        <CardOptions
          url={visualObj.asset_url}
          visual_type={visualObj.visual_type}
        />
      )}
    </div>
  );

  let Render = (
      <Tooltip
        follow={false}
        content={
          <div className="p-2">
            <div className="d-flex align-items-center">
              <p className="mr-4"><span>Spend:</span> {toCurrency(spend)}</p>
              <p>
                <span>
                  {!isHeader && 'Median '}
                  {isROAS ? 'ROAS' : 'CPA'}:{' '}
                </span>
                {!isFinite(cpa) ? (isROAS ? 0 : '-') : formattedCpa}
              </p>
            </div>

            <h3 className="element-details__name">
              {type}: Hash {visualObj.id}
            </h3>
            <div>
              <p>Used in the following ads:</p>
              <ul style={{ padding: "0 0 0 16px", marginBottom: "0" }}>
                {adNames.length ? adNames.map((ad) => {
                  if (!ad) return null;
                  return <li>{ad}</li>
                }) : null}
              </ul>
            </div>
          </div>
        }
        className={`visual-card__tooltip ${showOptions ? "visible" : ""}`}
      >
        {Card}
      </Tooltip>
  );

  if (isDirectMail && (viewName.toLowerCase().includes('competitor') || viewName.toLowerCase().includes('organic'))) {
    Render = Card;
  }

  return (
    <div className="visual-card-wrapper"
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}>
      <div className="visual-card">{Render}</div>
    </div>
  );
};

export default VisualCard;
