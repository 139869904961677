import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ErrorBoundary } from '../../../components/Helpers/Error';
import Dashboard from './Dashboard';
import { YellowRule } from '../Helpers';
import BoostModal from '../BoostModal';

const Dashboards = ({ adNames, isLoading }) => {
  const [show, setShow] = useState(false);
  const [adName, setAdName] = useState('');

  const handleModal = (_adName) => {
    setAdName(_adName);
    setShow(true);
  };

  if (isLoading) {
    return <p>Fetching ad previews...</p>
  }

  return (
    <>
      {adNames.length > 0 && (
        <div className="preview-list row mx-md-5">
          {adNames.map((ad) => (
            <ErrorBoundary fallback={<ErrFallback adName={ad} />}>
              <Dashboard
                adName={ad}
                handleBoostModal={() => handleModal(ad)}
              />
            </ErrorBoundary>
          ))
          }
        </div>
      )}
      <BoostModal
        id="boost-modal"
        show={show}
        adName={adName}
        handleHide={() => setShow(false)}
      />
    </>
  );
};

Dashboards.defaultProps = {
  isLoading: false,
};

Dashboards.propTypes = {
  adNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLoading: PropTypes.bool,
};

export default Dashboards;

function ErrFallback({ adName = '<Ad Name>' }) {
  return (
    <>
      <YellowRule />
      <h3>{adName}</h3>
      <p>Failed to get Ad data</p>
    </>
  );
}
