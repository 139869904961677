import React, { useEffect } from 'react';

import CustomDropdown from '../CustomDropdown';
import { useSearchParams } from '../Hooks/search-params';
import { useCreativeAnalyticsGet } from '../../features/CreativeAnalytics/contexts';
import { useViewSettings } from '../../selectors';
import { useMetricOptions } from '../../features/CreativeAnalytics/hooks/useMetricOptions';
import { getCol } from '../../features/CreativeAnalytics/PerformanceFrequency/hooks';

export const Picker = ({ toggleStyle, toggleClass, menuClass }) => {
  const _id = 'primary-metric-button';
  const { getParams, setParams } = useSearchParams();
  const { isROAS, clientMetrics, isOrganicView, account_id, isEmailView } = useCreativeAnalyticsGet();
  const { default_primary_metric } = useViewSettings();
  const params = getParams('primary_metric', 'columns');
  const { additional_metrics } = useViewSettings();

  const handleSelect = (eventKey, _) => {
    if (eventKey === params.primary_metric) return null;
    const current = params.primary_metric;

    let update = { primary_metric: eventKey  };
    
    if (params.columns) {
      const currentCol = getCol(isROAS, current, isOrganicView);
      const newCol = getCol(isROAS, eventKey, isOrganicView);

      if (params.columns.includes(currentCol) && !params.columns.includes(newCol)) {
        update = {
          primary_metric: eventKey,
          columns: params.columns.replace(
            currentCol,
            newCol
          ),
        };
      }
    }

    setParams(update);
  };

  const cpaMetric = isROAS ? 'roas' : 'cpa';
  const primary_metric = params.primary_metric || default_primary_metric || cpaMetric;

  const options = useMetricOptions();

  const label =
    options.find((x) => x.value === primary_metric)?.label || primary_metric;

  return (
    <CustomDropdown
      id={_id}
      onSelect={handleSelect}
      label={`${label}`}
      toggleStyle={toggleStyle}
      toggleClass={toggleClass}
      menuClass={menuClass}
    >
      {options.map(({ value, label }) => (
        <CustomDropdown.Item key={label} eventKey={value} className="py-2">
          {label}
        </CustomDropdown.Item>
      ))}
    </CustomDropdown>
  );
};

export const PrimaryMetricPicker = Picker;
