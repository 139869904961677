export const REPORT_OPTIONS = new Map([
  [
    'trending',
    {
      label: 'Trending',
      value: 'trending',
    },
  ],
  [
    'age',
    {
      label: 'Age',
      value: 'age',
    },
  ],
  [
    'placement',
    {
      label: 'Placement',
      value: 'placement',
    },
  ],
  [
    'gender',
    {
      label: 'Gender',
      value: 'gender',
    },
  ],
  [
    'device',
    {
      label: 'Device',
      value: 'device',
    },
  ],
  [
    'platform',
    {
      label: 'Platform',
      value: 'platform',
    },
  ],
]);

export const UNIFIED_METRIC_OPTIONS = [
  { value: 'spend', label: 'Spend' },
  { value: 'results', label: 'Results' },
  { value: 'impressions', label: 'Impressions' },
  { value: 'link_clicks', label: 'Link Clicks' },
  { value: 'cpa', label: 'CPA' },
  { value: 'cpm', label: 'CPM' },
  { value: 'ctr', label: 'CTR' },
];

export const METRIC_OPTIONS = [
  { value: 'reach', label: 'Reach' },
  { value: 'post', label: 'Shares' },
  { value: 'cpc', label: 'CPC' },
  { value: 'roas', label: 'ROAS' },
  { value: 'a_s', label: 'A/S' },
  { value: 'thruplays', label: 'Thruplays'},
  { value: 'cpl', label: 'Cost per Lead' },
  { value: 'cp_post', label: 'Cost per Share' },
  { value: 'cp_add_to_cart', label: 'Cost per Add To Cart' },
  { value: 'cp_initiate_checkout', label: 'Cost per Initiate Checkout' },
  { value: 'cp_purchase', label: 'Cost per Purchase' },
  { value: 'cp_page_engagement', label: 'Cost per Page Engagement' },
  { value: 'cp_post_engagement', label: 'Cost per Post Engagement' },
  { value: 'cp_view_content', label: 'Cost per View Content' },
  { value: 'cp_thruplay', label: 'Cost per Thruplay'},
];

export const GA_METRIC_OPTIONS = [
  { value: 'ga_fb_lp', label: 'GA FB Landing Page View' },
  { value: 'ga_fb_add_to_cart', label: 'GA FB Add to Cart' },
  { value: 'ga_fb_initiate_checkout', label: 'GA FB Initiate Checkouts' },
  { value: 'ga_fb_conversions', label: 'GA FB Conversions' },
  { value: 'ga_fb_conversion_value', label: 'GA FB Conversion Value' },
  { value: 'cp_ga_fb_lp', label: 'Cost per GA FB Landing Page View' },
  { value: 'cp_ga_fb_add_to_cart', label: 'Cost per GA FB Add to Cart' },
  {
    value: 'cp_ga_fb_initiate_checkout',
    label: 'Cost per GA FB Initiate Checkout',
  },
  { value: 'cp_ga_fb_conversions', label: 'GA FB CPA' },
  { value: 'cp_ga_fb_conversion_value', label: 'GA FB ROAS' },
];

export const G_METRIC_OPTIONS = [
  // { value: 'purchase', label: 'Purchases/Sales' },
  // { value: 'page_view', label: 'Key Page Views' },
  { value: 'phone_call_lead', label: 'Phone Call Leads' },
  // { value: 'imported_lead', label: 'Imported Leads' },
  { value: 'submit_lead_form', label: 'Submit Lead Forms' },
  // { value: 'book_appointment', label: 'Booked Appointments' },
  // { value: 'get_directions', label: 'Get Directions' },
  // { value: 'contact', label: 'Contacts' },
  { value: 'all_conversions', label: 'All Conversions' },
  { value: 'cp_phone_call_lead', label: 'Cost per Phone Call Lead' },
  { value: 'cp_submit_lead_form', label: 'Cost per Submit Lead Form' },
  { value: 'cp_all_conversions', label: 'Cost per All Conversions' },
];

export const LEFT_BORDER = { borderLeft: '6px solid #e9ecef' };
