import React from "react";
import {
  PieChart,
  Pie,
  Cell,
} from "recharts";

const CircleChart = ({ score }) => {
  const COLORS = ["#00B3DB", "transparent"];

  const data = [
    { name: "combined", value: score },
    { name: "rest", value: 100 - score },
  ];

  const filler = [
    { name: "filler", value: 100 }
  ]

  return (
    <div className="impact-circle-chart">
      <PieChart width={200} height={200}>
        <Pie
          data={filler}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={62}
          outerRadius={68}
          startAngle={90}
          endAngle={-270}
          fill="#DCE1ED"
          cornerRadius={0}
          paddingAngle={0}
          isAnimationActive={false}
        >
          {filler.map((entry, index) => (
            <Cell
              fill={"#DCE1ED"}
              stroke="none"
            />
          ))}
        </Pie>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={70}
          startAngle={90}
          endAngle={-270}
          fill="#00B3DB"
          cornerRadius={10}
          isAnimationActive={false} // TODO: false until animation fixed
        >
          {data.map((entry, index) => (
            <Cell
              fill={COLORS[index % COLORS.length]}
              stroke="none"
            />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
};

export default CircleChart;