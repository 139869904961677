import React from 'react';
import {
  toCurrency,
  toFixed_2,
  toInt,
  toPercent_2,
} from '../../../utils/numbers';
import { NOOM_CUSTOM_METRICS } from '../../CreativeAnalytics/PerformanceFrequency/constants';

export const getVisualsPerPage = (width) => {
  if (width >= 2800) return 9;
  if (width >= 2500) return 8;
  if (width >= 1900) return 6;
  if (width >= 1500) return 5;
  if (width >= 1100) return 4;
  if (width >= 1000) return 3;
  return 1;
};

const INT = [
  'spend_quintile',
  'primary_metric_quintile',
  'fitness_score',
  'impressions',
  'results',
  'clicks',
];

const FORMAT_LOOKUP = {
  spend: 'Spend',
  impressions: 'Impressions',
  clicks: 'Clicks',
  results: 'Results',
};

export const formatMetric = (metric) => {
  if (['cpa', 'cpc', 'ctr', 'cpm', 'roas'].includes(metric))
    return metric.toUpperCase();

  if (FORMAT_LOOKUP[metric]) return FORMAT_LOOKUP[metric];

  return metric;
};

export const formatCell = (column, data, visual) => {
  if (column === 'manual_tags' && data?.length < 1) {
    return 'None';
  }

  if (!data) return '-';
  if (['cpa', 'cpc', 'cpm', 'spend'].includes(column)) return toCurrency(data);
  if (column.slice(0, 3) === 'cp_') {
    if (column === 'cp_video_view') {
      return `$${data.toFixed(4)}`;
    }
    return toCurrency(data);
  }
  if (['roas'].includes(column)) return data.toFixed(2);
  if (
    [
      'ctr',
      'Average CPA Score',
      'Average CTR Score',
      'Average CPC Score',
    ].includes(column)
  )
    return toPercent_2(data);
  if (INT.includes(column)) return toInt(data);

  if (column === 'id') {
    return visual.ad_names?.length > 1 ? visual._id : visual.ad_names?.[0];
  }

  if (
    NOOM_CUSTOM_METRICS.includes(column) &&
    !['Signups', 'LTV to CAC Ratio'].includes(column)
  )
    return toCurrency(data);

  if (typeof data === 'number') {
    return data.toFixed(2).toLocaleString();
  }

  return data;
};

export const groupMetadata = (filteredVisuals, columns, metadataFields) => {
  if (!metadataFields) {
    return {
      updatedFields: columns,
      updatedVisuals: filteredVisuals,
    };
  }
  let updatedFields = [];
  let metaDataIsSelected = false;

  for (const col of columns) {
    // remove metadata cols, replace them with Creative Attributes
    if (!metadataFields.includes(col.value)) {
      updatedFields.push(col);
    } else {
      metaDataIsSelected = true;
    }
  }

  if (metaDataIsSelected) {
    updatedFields = [
      ...updatedFields,
      { label: 'Creative Attributes', value: 'Creative Attributes' },
    ];
  }

  const updatedVisuals = filteredVisuals.map((visual) => {
    const metadataValues = [];

    metadataFields.forEach((field) => {
      const isSelected = columns.some((x) => x.value === field);
      if (visual[field] && isSelected)
        metadataValues.push({
          field,
          value: visual[field],
        });
    });

    return {
      ...visual,
      'Creative Attributes': (
        <ul className="creative-attributes">
          {metadataValues.map(({ field, value }) => {
            return (
              <li>
                <p className="creative-attributes__category">{field}</p>
                <p className="creative-attributes__label">{value}</p>
              </li>
            );
          })}
        </ul>
      ),
    };
  });

  return {
    updatedFields,
    updatedVisuals,
  };
};

const getLabel = (metric, columnOptions) => {
  if (!!columnOptions) {
    const maybe = columnOptions.find((opt) => opt.value === metric);
    return maybe?.label || metric;
  }

  return metric;
};

export const formatSortBy = (sortBy, columnOptions) => {
  if (!sortBy?.[0]) return '';
  const order = sortBy[0].desc ? 'Descending' : 'Ascending';
  const metric = sortBy[0]._id;

  const metricLabel = getLabel(metric, columnOptions);

  return `Sorted by ${metricLabel} ${order}`;
};

export const getTooltip = (column, visual, primary_metric) => {
  if (column === 'primary_metric_quintile') {
    return `${primary_metric}: ${formatCell(
      primary_metric,
      visual[primary_metric],
      visual
    )}`;
  }
  if (column === 'spend_quintile') {
    return `Spend: ${toCurrency(visual.spend)}`;
  }
  if (column === 'fitness_score') {
    return 'Spend quintile + performance quintile';
  }

  return null;
};

export const formatPreviousData = (visuals = []) => {
  if (!visuals) return {};

  return visuals.reduce((result, visual) => {
    result[visual._id] = visual;
    return result;
  }, {});
};
