import React from 'react';
import Select from 'react-select';
import { useTrackEvent } from '../../../CreativeAnalytics/hooks/useTrackEvent';
import { TooltipIcon } from '../../../../components/Tooltip/Tooltip';
import Button from '../../../../components/Button';
import { SELECT_STYLES } from '../../constants';
import { REPORT_BUILDER, SEGMENT_SELECTED, SHOW_ADVANCED_DATA } from '../../../../store/UserActivity/constants';
import { DARWIN_BLUE } from '../../../../constants';

const reducer = (state, action) => {
  switch (action.type) {
    case 'add':
      return [...state, action.segment];
  }
};

const toBlockOpt = (block) => {
  return {
    label: block?.name || null,
    value: block?.id || null,
  };
};

export const Segments = ({
  createNewBlock,
  editBlock,
  deleteBlock,
  selectedBlockIds,
  setSelectedBlockIds,
  blockOpts,
  allBlocks,
  setShowAdvancedOptions,
}) => {
  const trackEvent = useTrackEvent();

  const CustomSingleValue = ({ children, data, ...props }) => {
    return (
      <div
        className="d-flex align-items-center justify-content-between"
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <span
          style={{ flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {children}
        </span>
        <div className="d-flex" style={{ gap: '4px', flexShrink: 0 }}>
          <button
            className="btn-reset"
            appearance="subtle"
            onClick={() => props.selectProps.editBlock(data.value)}
          >
            <i
              style={{ fontSize: 11, color: '#566577' }}
              className="fa-solid fa-pencil"
            />
          </button>
          <button
            className="btn-reset"
            appearance="subtle"
            onClick={() => props.selectProps.deleteBlock(data.value)}
          >
            <i
              style={{ fontSize: 11, color: '#566577' }}
              className="fa-solid fa-trash"
            />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="segments">
      <div className="d-flex justify-content-between align-items-center mb-3 w-100">
        <h3 className="mb-0">
          Select Segment(s){' '}
          <TooltipIcon content="Choose an audience segment or build a new one based on naming conventions." />
        </h3>
        <Button appearance="subtle" onClick={() => createNewBlock()}>
          New +
        </Button>
      </div>
      <div className="segments-list">
        {selectedBlockIds.map((id, index) => {
          return (
            <div className="lp-block d-flex align-items-center">
              <Select
                onChange={({ value }) => {
                  setSelectedBlockIds((prev) =>
                    prev.map((block, i) => (i === index ? value : block))
                  );
                }}
                options={blockOpts}
                styles={{
                  ...SELECT_STYLES,
                  container: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                }}
                components={{ SingleValue: CustomSingleValue }}
                placeholder="Select a block..."
                value={toBlockOpt(allBlocks.find((opt) => opt.id === id))}
                editBlock={editBlock}
                deleteBlock={deleteBlock}
              />
              {/* <div className="d-flex" style={{ gap: '4px' }}>
                <Button appearance="subtle" onClick={() => editBlock(id)}>
                  <i className="fa-solid fa-pencil" />
                </Button>
                <Button appearance="subtle" onClick={() => deleteBlock(id)}>
                  <i className="fa-solid fa-trash" />
                </Button>
              </div> */}
            </div>
          );
        })}

        <div className="lp-block">
          <Select
            onChange={({ value }) => {
              trackEvent(REPORT_BUILDER, SEGMENT_SELECTED);
              setSelectedBlockIds((prev) => [...prev, value]);
            }}
            // formatOptionLabel={formatOptionLabel}
            options={blockOpts}
            styles={{
              ...SELECT_STYLES,
              container: (provided) => ({
                ...provided,
                width: '100%',
              }),
            }}
            placeholder="+ Add Segment"
            value={null}
          />
        </div>
      </div>

      <div className="d-flex align-items-center mt-3" style={{ gap: '1.5rem' }}>
        <p
          onClick={() => {
            trackEvent(REPORT_BUILDER, SHOW_ADVANCED_DATA);
            setShowAdvancedOptions(true);
          }}
          className="cursor-pointer mb-0"
          style={{ color: DARWIN_BLUE }}
        >
          Show Advanced Options
        </p>
      </div>
    </div>
  );
};

const SegmentItem = () => {};
