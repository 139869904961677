import clsx from 'clsx';
import React from 'react';

type IconWrapperProps = {
  children: (className: string) => React.ReactNode;
  wrapperClass?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};

const IconWrapper = ({
  children,
  wrapperClass = '',
  className = '',
  style = {},
  onClick,
  ...rest
}: IconWrapperProps) => (
  <div
    className={clsx('category-icon', {
      [wrapperClass]: wrapperClass,
      'is-clickable': !!onClick,
    })}
    onClick={onClick}
    style={style}
    {...rest}
  >
    {children(className)}
  </div>
);

export const AIDrivenIcon = (props) => (
  <IconWrapper {...props}>
    {(className) => (
      <svg
        className={className}
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.375 1V2.25M2.25 5.375H1M16 5.375H14.75M2.25 8.5H1M16 8.5H14.75M2.25 11.625H1M16 11.625H14.75M5.375 14.75V16M8.5 1V2.25M8.5 14.75V16M11.625 1V2.25M11.625 14.75V16M4.125 14.75H12.875C13.3723 14.75 13.8492 14.5525 14.2008 14.2008C14.5525 13.8492 14.75 13.3723 14.75 12.875V4.125C14.75 3.62772 14.5525 3.15081 14.2008 2.79918C13.8492 2.44754 13.3723 2.25 12.875 2.25H4.125C3.62772 2.25 3.15081 2.44754 2.79918 2.79918C2.44754 3.15081 2.25 3.62772 2.25 4.125V12.875C2.25 13.3723 2.44754 13.8492 2.79918 14.2008C3.15081 14.5525 3.62772 14.75 4.125 14.75ZM4.75 4.75H12.25V12.25H4.75V4.75Z"
          stroke="#96A4C8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    )}
  </IconWrapper>
);

export const DerivedIcon = (props) => (
  <IconWrapper {...props}>
    {(className) => (
      <svg
        className={className}
        width="17"
        height="13"
        viewBox="0 0 17 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.6385 2.44369L14.4609 0.242218C14.3075 0.0871282 14.0994 0 13.8825 0C13.6655 0 13.4575 0.0871282 13.304 0.242218C13.1506 0.397307 13.0645 0.607654 13.0645 0.826983C13.0645 1.04631 13.1506 1.25666 13.304 1.41175L14.0873 2.20221H12.7944C12.0884 2.22505 11.3941 2.39115 10.7529 2.69067C9.76685 3.15091 8.52018 4.13538 8.17994 6.19376C7.99689 7.30619 7.45998 8.07877 6.58554 8.49085C6.35146 8.59968 6.10578 8.68093 5.85334 8.73301C5.63904 8.03617 5.18295 7.44106 4.56905 7.05722C3.95514 6.67339 3.22475 6.52669 2.51233 6.64412C1.79992 6.76156 1.15345 7.13522 0.691942 7.69632C0.230435 8.25742 -0.0150389 8.96819 0.000713686 9.69775C0.0164663 10.4273 0.292385 11.1266 0.777671 11.6668C1.26296 12.207 1.92494 12.5518 2.64174 12.6377C3.35855 12.7236 4.08191 12.5448 4.67867 12.1342C5.27542 11.7237 5.70539 11.109 5.8894 10.4034C6.33995 10.3366 6.77947 10.2084 7.19595 10.0223C8.18402 9.57852 9.43545 8.60093 9.78794 6.4655C9.96351 5.40054 10.5011 4.6431 11.3864 4.21243C11.8281 4.00117 12.3064 3.87918 12.7944 3.85332H14.0873L13.3034 4.64516C13.15 4.80025 13.0638 5.0106 13.0638 5.22992C13.0638 5.44925 13.15 5.6596 13.3034 5.81469C13.4568 5.96978 13.6648 6.05691 13.8818 6.05691C14.0987 6.05691 14.3068 5.96978 14.4602 5.81469L16.6378 3.61322C16.7139 3.53657 16.7744 3.44547 16.8157 3.34515C16.857 3.24482 16.8782 3.13725 16.8783 3.0286C16.8784 2.91994 16.8572 2.81234 16.816 2.71197C16.7749 2.6116 16.7145 2.52043 16.6385 2.44369ZM2.99527 11.0081C2.72609 11.0081 2.46296 10.9274 2.23915 10.7762C2.01533 10.625 1.84089 10.4101 1.73788 10.1587C1.63487 9.9073 1.60792 9.63065 1.66043 9.36375C1.71295 9.09685 1.84257 8.85168 2.03291 8.65926C2.22324 8.46683 2.46575 8.33579 2.72975 8.2827C2.99376 8.22961 3.26741 8.25686 3.51609 8.361C3.76478 8.46514 3.97734 8.64149 4.12689 8.86776C4.27643 9.09403 4.35625 9.36005 4.35625 9.63218C4.35625 9.9971 4.21286 10.3471 3.95763 10.6051C3.7024 10.8631 3.35622 11.0081 2.99527 11.0081Z"
          fill="#96A4C8"
        />
      </svg>
    )}
  </IconWrapper>
);

export const AdNameLabelsIcon = (props) => (
  <IconWrapper {...props}>
    {(className) => (
      <svg
        className={className}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.5 11.25V14.25C13.5 14.6625 13.1625 15 12.75 15H3.75C3.3375 15 3 14.6625 3 14.25V5.24999C3 4.83749 3.3375 4.49999 3.75 4.49999H6.015C6.4275 4.49999 6.765 4.16249 6.765 3.74999C6.765 3.33749 6.4275 2.99999 6.015 2.99999H3C2.175 2.99999 1.5 3.67499 1.5 4.49999V15C1.5 15.825 2.175 16.5 3 16.5H13.5C14.325 16.5 15 15.825 15 15V11.25C15 10.8375 14.6625 10.5 14.25 10.5C13.8375 10.5 13.5 10.8375 13.5 11.25ZM11.625 13.5H4.89C4.575 13.5 4.4025 13.14 4.5975 12.8925L5.9025 11.22C5.93707 11.1757 5.98114 11.1398 6.03146 11.1149C6.08177 11.0899 6.13705 11.0766 6.1932 11.0759C6.24935 11.0752 6.30495 11.0871 6.35589 11.1107C6.40683 11.1344 6.4518 11.1691 6.4875 11.2125L7.6575 12.6225L9.42 10.3575C9.57 10.1625 9.87 10.1625 10.0125 10.365L11.925 12.9075C12.1125 13.1475 11.9325 13.5 11.625 13.5ZM14.475 6.66749C14.835 6.08999 15.0375 5.41499 14.9925 4.67249C14.895 3.05999 13.6125 1.69499 12.015 1.52249C11.5421 1.46747 11.0628 1.51314 10.6088 1.6565C10.1547 1.79987 9.73618 2.03768 9.38059 2.35431C9.025 2.67095 8.74043 3.05924 8.54557 3.49367C8.35071 3.92811 8.24999 4.39885 8.25 4.87499C8.25 6.74249 9.7575 8.24999 11.6175 8.24999C12.2775 8.24999 12.8925 8.05499 13.41 7.72499L15.2175 9.53249C15.51 9.82499 15.99 9.82499 16.2825 9.53249C16.575 9.23999 16.575 8.75999 16.2825 8.46749L14.475 6.66749ZM11.625 6.74999C11.1277 6.74999 10.6508 6.55244 10.2992 6.20081C9.94754 5.84918 9.75 5.37227 9.75 4.87499C9.75 4.37771 9.94754 3.90079 10.2992 3.54916C10.6508 3.19753 11.1277 2.99999 11.625 2.99999C12.1223 2.99999 12.5992 3.19753 12.9508 3.54916C13.3025 3.90079 13.5 4.37771 13.5 4.87499C13.5 5.37227 13.3025 5.84918 12.9508 6.20081C12.5992 6.55244 12.1223 6.74999 11.625 6.74999Z"
          fill="#96A4C8"
        />
      </svg>
    )}
  </IconWrapper>
);

export const UDCIcon = (props) => (
  <IconWrapper {...props}>
    {(className) => (
      <svg
        className={className}
        width="17"
        height="15"
        viewBox="0 0 17 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.1067 8.25L15.1967 9.13235L13.3312 7.32353L14.2412 6.44118C14.3348 6.35226 14.4606 6.30245 14.5916 6.30245C14.7226 6.30245 14.8483 6.35226 14.9419 6.44118L16.1067 7.57059C16.2978 7.75588 16.2978 8.06471 16.1067 8.25ZM7.27985 13.1824L12.7943 7.83529L14.6598 9.64412L9.15442 15H7.27985V13.1824ZM7.27985 8.82353C3.25773 8.82353 0 10.4029 0 12.3529V14.1176H5.45989V12.45L9.09982 8.92059C8.49923 8.85 7.88954 8.82353 7.27985 8.82353ZM7.27985 0C6.31449 0 5.38866 0.371848 4.70604 1.03374C4.02342 1.69563 3.63993 2.59335 3.63993 3.52941C3.63993 4.46547 4.02342 5.36319 4.70604 6.02508C5.38866 6.68698 6.31449 7.05882 7.27985 7.05882C8.24522 7.05882 9.17105 6.68698 9.85367 6.02508C10.5363 5.36319 10.9198 4.46547 10.9198 3.52941C10.9198 2.59335 10.5363 1.69563 9.85367 1.03374C9.17105 0.371848 8.24522 0 7.27985 0Z"
          fill="#96A4C8"
        />
      </svg>
    )}
  </IconWrapper>
);
