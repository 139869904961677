import { toCurrency, toPercent } from '../../../../utils/numbers';
import {
  adjustDateRangeWithTimezone,
  toDarwinDate,
} from '../../../../utils/darwin-dates';

export const toFormattedDate = (dateStr) => toDarwinDate(new Date(dateStr));

/**
 * @description Formats numerical display based on
 * column/key name. "spend" & "cost per ..." are currency,
 * while "click thru rate" is a percent.
 * @param {Number} val
 * @param {String} column
 *
 * @return {Number | String}
 */
export const columnFormatter = (val, column) => {
  const normalized = column.toLowerCase();

  if (normalized.includes('spend') || normalized.includes('cost')) {
    return toCurrency(val);
  }
  if (normalized.includes('thru rate')) {
    return toPercent(val);
  }
  if (normalized.includes('date')) {
    return toFormattedDate(val);
  }
  return val;
};

export const adjustQuery = ({
  // Segments will be needed here if ever used.
  measures = [],
  dimensions = [],
  timeDimensions = [],
  filters = [],
}) => ({
  measures: [...measures],
  dimensions: [...dimensions],
  filters: [...filters],
  timeDimensions:
    timeDimensions.length === 0
      ? []
      : [
          {
            dimension: timeDimensions[0].dimension,
            granularity: timeDimensions[0].granularity,
            ...adjustRange(timeDimensions[0]),
          },
        ],
});

function adjustRange({ dateRange, compareDateRange }) {
  if (dateRange) {
    return { dateRange: adjustDateRangeWithTimezone(dateRange) };
  }
  return { compareDateRange };
}
