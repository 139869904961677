import React from 'react';
import PropTypes from 'prop-types';

import { AD_STUDY, EFFICIENCY } from '../../store/CreativeReports/constants';

import { ViewDatePicker } from '../../components/Pickers/ViewDate';
import { AudiencePicker } from '../../components/Pickers/Audience';

import { REPORTS_INDEX } from './constants';

export default function Header({ reportType, showAllMetrics, isPF }) {
  const isAdStudy = reportType === AD_STUDY;
  const isEfficiency = reportType === EFFICIENCY;

  if (['ad_name', 'adset_name'].includes(reportType)) return null;

  return (
    <div
      className={`align-items-center`}
    >
      <h2>{REPORTS_INDEX[reportType]?.title}</h2>

      {!(isEfficiency && !showAllMetrics) && !isPF && (
        <div className="creative-reports__options col-12 col-md-5 pl-0 pr-0 ml-2">
          {!isAdStudy && <AudiencePicker />}
          <ViewDatePicker />
        </div>
      )}
    </div>
  );
}

Header.propTypes = {
  reportType: PropTypes.string.isRequired,
};
