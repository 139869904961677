import React from "react";
import "./Menu.scss";
import Advanced from "./Advanced";
import Button from "../../../../../components/Button";

const Menu = ({
  showForm,
  handleShowForm,
  handleSimpleSelect,
  trackPath,
  fetchRecs,
  handleCloseForm,
  recDepth,
  handleDepthSelect,
  categoryLabels,
  depthOptions,
  fixedLabels,
  handleChecked,
  showMenu,
  recsLoading,
  recsError,
  attemptedDepth,
  recsErrorDisplay,
}) => {
  return (
    <div className={`suggestions-menu ${showMenu ? "show" : ""}`}>
      <MenuSection
        className={`simple ${showForm ? "collapsed" : ""}`}
        title={"Simple"}
        isBeta={false}
        showForm={showForm}
      >
        <p>
          Show simple suggestions on how to change elements one at a time to
          improve upon the creative.
        </p>
        <div className="actions">
          <Button
            onClick={() => {
              handleSimpleSelect(1);
              trackPath("Simple");
            }}
          >
            <i className="fa fa-arrow-right"></i>
            Show simple suggestions
          </Button>
        </div>
      </MenuSection>
      <MenuSection
        className={`intermediate ${showForm ? "collapsed" : ""}`}
        title={"Intermediate"}
        isBeta={true}
        showForm={showForm}
      >
        <p>
          Use AI to make suggestions on how to change{" "}
          <span>two or three elements </span>
          at the same time to improve performance.
        </p>
        <div className="actions">
          <Button
            onClick={() => {
              fetchRecs(2, false);
              trackPath("Intermediate");
            }}
          >
            <i className="fa fa-arrow-right"></i>
            Change two elements
          </Button>
          <Button
            onClick={() => {
              fetchRecs(3, false);
              trackPath("Intermediate");
            }}
          >
            <i className="fa fa-arrow-right"></i>
            Change three elements
          </Button>
        </div>
        {recsLoading && <div className="error-display">Loading...</div>}
        {!recsLoading && !!recsErrorDisplay && attemptedDepth !== 1 && (
          <div className="error-display error">
            {recsErrorDisplay}
          </div>
        )}
      </MenuSection>
      <MenuSection
        className={`advanced`}
        title={"Advanced"}
        isBeta={true}
        showForm={showForm}
      >
        <p>
          Customize the AI to change out specific elements and specify the depth
          of the recommendations.
        </p>
        <div className="actions">
          <Button onClick={() => handleShowForm()}>
            <i className="fa fa-arrow-right"></i>
            Customize the AI
          </Button>
        </div>
      </MenuSection>
      <Advanced
        {...{
          handleCloseForm,
          fetchRecs,
          recDepth,
          trackPath,
          handleDepthSelect,
          categoryLabels,
          depthOptions,
          fixedLabels,
          handleChecked,
          showForm,
          recsLoading,
          recsError,
          attemptedDepth,
          recsErrorDisplay,
        }}
      />
    </div>
  );
};

export default Menu;

const MenuSection = ({ className, title, isBeta, showForm, children }) => {
  return (
    <div className={`suggestions-menu__option ${className}`}>
      <div
        className={`suggestions-menu__option-container  ${
          showForm ? "form-visible" : ""
        }`}
      >
        <div className="suggestions-menu__title">
          <span>{title}</span>
          {isBeta && <span className="beta-label">BETA</span>}
        </div>
        <div className="suggestions-menu__detail">{children}</div>
      </div>
    </div>
  );
};
