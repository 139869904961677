import { useRef, useEffect } from 'react';

import { abortableFetch } from '../../../utils';
import {
  useApiState,
  bindFetchToRef,
} from '../../../components/Hooks/useApiState';

function postRating(payload) {
  return abortableFetch(
    ['api', 'creative-reports', 'rating', payload.account_id].join('/'),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(payload),
    }
  );
}

export function useRatingsApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(postRating(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: postDispatch,
    },
  };
}

function getSavedConcepts(view_id) {
  return abortableFetch(
    [
      'api',
      'creative-reports',
      'performance-frequency',
      'get-saved-concepts',
      view_id,
    ].join('/'),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
    }
  );
}

function postSavedConcepts(payload) {
  return abortableFetch(
    [
      'api',
      'creative-reports',
      'performance-frequency',
      'save-edit-concepts',
    ].join('/'),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(payload),
    }
  );
}

export function useSavedConceptsApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (view_id) =>
      withRef(getSavedConcepts(view_id)).then(({ data }) => data),
    {
      loading: true,
      error: null,
      data: null,
      count: 0,
    }
  );
  const [postState, postDispatch] = useApiState(
    (payload) =>
      withRef(postSavedConcepts(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: postDispatch,
    },

    get: {
      ...getState,
      request: getDispatch,
    },
  };
}

