//@ts-check
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import { BasicLoading } from "../../../components/Helpers/Loading";
import BeagleToggle from "../../../components/BeagleToggle";

import { useLabelSettings, useSaveLabelSettings } from './api';

import './LabelSettings.scss';

const settingsPayload = (account_id, labelSettings) => {
  const data = {
    account_id,
    custom_trained_label_settings: labelSettings.custom_trained_label_settings,
    common_ad_labels: labelSettings.common_ad_labels
  }
  return data;
}

export default function LabelSettings({
  id,
  data,
  handleSeriesClick,
  selectedId,
  dimensions,
}) {
  const [labelSettings, setLabelSettings] = useState({});
  const [saved, setSaved] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const labelSettingsApi = useLabelSettings();
  const saveLabelSettingsApi = useSaveLabelSettings();

  const { account_id } = useSelector(
    ({ ViewSettings: { account_id } }) => ({
      account_id,
    })
  );

  useEffect(() => {
    labelSettingsApi.get.request(account_id);
  }, []);

  useEffect(() => {
    const data = labelSettingsApi.get.data;
    setLabelSettings(data);
  }, [labelSettingsApi.get.data]);

  const handleSave = () => {
    const payload = settingsPayload(account_id, labelSettings);
    saveLabelSettingsApi.post.request(payload);
    setSaved(true);
    setShowMessage(true);
  }

  if (labelSettingsApi.get.error) {
    return <p>An error has occured.</p>;
  }
  if (labelSettingsApi.get.loading) {
    return <BasicLoading />;
  }
  if (labelSettings === null) {
    return <p>No label settings data.</p>;
  }

  return (
    <div className="label-settings-card card darwin-box-shadow">
      <div className="label-settings-header">
        <div className="d-flex mb-2">
          <h5 className="label-settings-heading">Label Settings</h5>
        </div>
        <div className="label-settings-options">
          {showMessage && <SuccessMessage setShowMessage={setShowMessage} />}
          {!saved && (
            <button
              className="save-btn"
              onClick={handleSave}
              type="button"
              title="You have unsaved changes."
            >
              Save
            </button>
          )}
        </div>
      </div>
      <div className="label-settings">
        <div className="label-settings-section custom-trained">
          <h3 className="label-settings-section__heading m-0">
            Custom Trained Labels Settings
          </h3>
          <h4 className="label-settings-section__subheading">
            Add custom labels on your own to train the AI to pick up on new
            concepts automatically for you over time.
          </h4>
          <LabelSettingsDisplay
            type="custom_trained_label_settings"
            account_id={account_id}
            labelSettings={labelSettings}
            setLabelSettings={setLabelSettings}
            setSaved={setSaved}
          />

        </div>

        <div className="label-settings-section common-ad">
          <h3 className="label-settings-section__heading">Common Ad Labels</h3>
          <h4 className="label-settings-section__subheading">
            Common traits found in ads across all platforms. Use the toggles to
            update and make corrections to improve accuracy.
          </h4>
          <LabelSettingsDisplay
            type="common_ad_labels"
            account_id={account_id}
            labelSettings={labelSettings}
            setLabelSettings={setLabelSettings}
            setSaved={setSaved}
          />
        </div>

      </div>

    </div>
  );
}

const SuccessMessage = ({ setShowMessage }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      setShowMessage(false);
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (!visible) return null;

  return <p className="success-msg">Saved!</p>;
};

function LabelSettingsDisplay({
  type,
  account_id,
  labelSettings,
  setLabelSettings,
  setSaved,
}) {
  const [typeId, setTypeId] = useState('custom-trained');

  useEffect(() => {
    switch (type) {
      case 'common_ad_labels':
        setTypeId('common-ad');
        break;
      default:
        setTypeId('custom-trained');
    }
  });

  const handleToggle = (e, index) => {
    let newSettings = labelSettings[type];

    if (newSettings[index].status === 'off') {
      newSettings[index].status = 'on';
    } else {
      newSettings[index].status = 'off';
    }

    setLabelSettings({
      ...labelSettings,
      [type]: newSettings,
    });
    setSaved(false);
  };

  return (
    <ul className="label-settings-list">
      {labelSettings[type].length > 0 ? (
        labelSettings[type].sort((a, b) => ((a.name > b.name) ? 1 : -1)).map((label, index) => (
          <li className="label-settings-list-item" key={`${typeId}-${label.name}`}>
            <BeagleToggle
              checked={label.status === 'on'}
              onChange={(e) => handleToggle(e, index)
              }
              id={`${typeId}-${index}-${account_id}`}
              name={label.name}
            />
            <p className="label-settings-list-item__name" title={label.name}>
              {label.name}
            </p>
          </li>
        ))
      ) : (
        <p>No labels found.</p>
      )}
    </ul>
  );
}
