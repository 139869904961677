import React from "react";
import { DARWIN_NAVY } from "../../../../constants";

export const DeleteIcon = () => {
  return (
    <svg
      className="delete-icon"
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.92295 16.2727C2.43406 16.2727 2.0154 16.0985 1.66695 15.75C1.31851 15.4016 1.14458 14.9832 1.14518 14.4949V2.93937H0.256287V1.16159H4.70073V0.272705H10.0341V1.16159H14.4785V2.93937H13.5896V14.4949C13.5896 14.9838 13.4154 15.4025 13.067 15.7509C12.7185 16.0994 12.3001 16.2733 11.8118 16.2727H2.92295ZM11.8118 2.93937H2.92295V14.4949H11.8118V2.93937ZM4.70073 12.7171H6.47851V4.71715H4.70073V12.7171ZM8.25629 12.7171H10.0341V4.71715H8.25629V12.7171Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};
