import React from 'react';

import connectTo from './Container';
import RequestReset from './RequestReset';
import Boundary from './RequestResetBoundary';

const Comp = ({ fetchSendReset, isLoading, hasError }) => (
  <Boundary isLoading={isLoading} hasError={hasError}>
    <RequestReset fetchSendReset={fetchSendReset} />
  </Boundary>
);

export default connectTo(Comp);
