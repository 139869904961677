import { DARWIN_NAVY, DARWIN_NAVY_2, DARWIN_NAVY_4 } from "../../../../constants";

export const LIBRARY_SEARCH_STYLES = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    border: 'none',
    boxShadow: 'none',
    fontSize: '1rem',
    color: '#1E2B4E',
    cursor: 'pointer',
  }),
  input: (styles) => ({
    ...styles,
    color: '#1E2B4E !important',
    fontWeight: '600 !important',
  }),
  valueContainer: (styles) => ({
    ...styles,
    overflow: 'visible',
    paddingLeft: '3rem',
    color: '#1E2B4E',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: '#dedede',
    borderLeft: 0,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    color: '#dedede',
  }),
  container: (provided) => ({
    ...provided,
    borderRadius: '2px',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '2px',
    border: 'none',
    fontSize: '1.1rem',
    color: '#667394',
    width: 'fit-content',
    padding: '0.5rem',
    zIndex: '50',
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    cursor: 'pointer',
    fontSize: '1.2rem',
    color: '#1E2B4E',
    backgroundColor: isFocused ? '#DDF3FF' : 'white',
    ':hover': {
      backgroundColor: '#DDF3FF',
    },
    borderRadius: '2px',
  }),
  singleValue: (provided, { isDisabled }) => ({
    ...provided,
    color: isDisabled ? DARWIN_NAVY_4 : '#1E2B4E',
    fontSize: '1.1rem',
    fontWeight: '500',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#747C96',
    fontWeight: '600',
  }),
};
