import React from 'react';
import { useDispatch } from 'react-redux';
import { setRightDrawerDisplay } from '../../../store/CreativeAnalytics/creativeAnalyticsSlice';
import { toCurrency } from '../../../utils/numbers';
import {
  ALL,
  BLANK_GROUP,
  BOTTOM_5_PERFORMING,
  BOTTOM_5_SPENDING,
  TOP_5_PERFORMING,
  TOP_5_PERFORMING_BY_FITNESS_SCORE,
  TOP_5_SPENDING,
  allByPrimaryMetric,
  top5ByPrimaryMetric,
} from '../groups';
import IconButton from '../../../components/IconButton';
import { RIGHT_RAIL_WIDTH } from '../constants';
import { useViewSettings } from '../../../selectors';
import { useCreativeAnalyticsGet } from '../../CreativeAnalytics/contexts';

const Header = ({ close }) => {
  return (
    <div className="right-drawer-header">
      <p>Select creatives</p>
    </div>
  );
};

const List = ({
  groupParams,
  setGroupParams,
  isEditing,
  setIsEditing,
  options,
}) => {
  return (
    <ul className="creatives-select-list">
      {options.map((option) => {
        return (
          <Item
            option={option}
            groupParams={groupParams}
            setGroupParams={setGroupParams}
            setIsEditing={setIsEditing}
          />
        );
      })}
    </ul>
  );
};

const ID_MAP = {
  'Top 3 Spending': 'top_3_spending',
  'Top 3 Performing': 'top_3_performing',
};

const Item = ({ option, groupParams, setGroupParams, setIsEditing }) => {
  let isSelected = groupParams.title === option.title;
  const isCustom = groupParams.custom;

  if (isCustom) {
    isSelected = option.custom;
  } else if (option.title === 'All Creatives' && !groupParams.title) {
    isSelected = true;
  }

  return (
    <li
      id={ID_MAP[option.title] ?? option.title}
      className={isSelected ? 'is-selected' : ''}
      onClick={() => {
        if (option.title === 'Custom (BETA)') setIsEditing(true);
        setGroupParams(option);
      }}
    >
      <div className="d-flex justify-content-between align-items-center w-100">
        <p className="m-0">{option.title}</p>
        {isSelected && (
          <IconButton
            className="creatives-select-list__edit-btn"
            style={{
              height: 24,
              width: 24,
              background: 'none',
              borderColor: isSelected ? '#C9E1EB' : 'initial',
            }}
            onClick={() => setIsEditing(true)}
          >
            <i className="fa-solid fa-wrench" style={{ fontSize: '11px' }}></i>
          </IconButton>
        )}
      </div>
    </li>
  );
};

const current = (groupParams) => {
  const invalid = [
    ALL,
    TOP_5_PERFORMING,
    TOP_5_SPENDING,
    BOTTOM_5_PERFORMING,
    BOTTOM_5_SPENDING,
    BLANK_GROUP,
  ].some((g) => g.title === groupParams?.title);

  return invalid ? [] : [groupParams];
};

export const Layout = ({
  groupParams,
  setGroupParams,
  isEditing,
  setIsEditing,
}) => {
  const { isOrganicView } = useViewSettings();
  const { primary_metric } = useCreativeAnalyticsGet();

  let options = [
    // ALL,
    top5ByPrimaryMetric(primary_metric ?? 'cpa'),
    // TOP_5_PERFORMING_BY_FITNESS_SCORE,
    TOP_5_SPENDING,
    // BLANK_GROUP,
  ];

  if (isOrganicView) {
    options = [
      allByPrimaryMetric(primary_metric ?? 'cpa'),
      top5ByPrimaryMetric(primary_metric ?? 'cpa'),
    ];
  }

  return (
    <div
      className="creatives-select"
      style={{ minWidth: `${RIGHT_RAIL_WIDTH}px` }}
    >
      {/* <Header close={() => dispatch(setRightDrawerDisplay(null))} /> */}
      <List
        {...{ groupParams, setGroupParams, isEditing, setIsEditing, options }}
      />
    </div>
  );
};
