import React from 'react';
import CreativeAnalytics from './CreativeAnalytics';
import { useMetadataApi } from '../DNA/api';
import { useFetchPresetsApi } from './Presets/api';
import AnalyticsApiProvider from './contexts';

export const Wrapper = (props) => {
  useMetadataApi();
  const fetchPresetsData: () => void = useFetchPresetsApi();

  return (
    <AnalyticsApiProvider fetchPresetsData={fetchPresetsData}>
      <CreativeAnalytics {...props} />
    </AnalyticsApiProvider>
  );
};
