import React, { useState, useEffect } from "react";
import { IR_SELECT_STYLES, toggleStyle } from "./constants";
import { SET_START_DATE, SET_END_DATE, SET_ERRORS } from "./reducer";
import DatePickers from "./DatePickers";
import Button from "../components/Button";
import Switch from "@mui/material/Switch";
import Select from "react-select";
import { toPercentRounded } from "../../../../utils/numbers";
import ConfirmationModal from "./ConfirmationModal";
import { useFormValidation } from "./hooks";
import { lastMonthEnd, lastMonthStart } from "../constants";
import { isLastMonth } from "../utils";
import { useReportEditor } from "./hooks";

const EditMode = ({
  current,
  reports,
  audienceOptions,
  labelOptions,
  handleSelectDate,
  handleRemoveLabel,
  handleAddLabel,
  handleSelectAudience,
  handleSetStatus,
  activeReports,
  handleSetName,
  handleSave,
  handleUndo,
  handleDelete,
  changesMade,
  showModal,
  setShowModal,
  handleDeleteClickAway,
  handleIncludedCategories,
  updateReportsToDelete,
  saveInProgress,
  handleValidation,
  errors,
}) => {  
  return (
    <>
      <EditModeHeader
        {...{
          current,
          handleSetStatus,
          activeReports,
          handleSetName,
          handleUndo,
          handleDelete,
          changesMade,
          showModal,
          setShowModal,
          handleDeleteClickAway,
          errors,
          handleValidation,
          updateReportsToDelete,
          saveInProgress,
        }}
      />
      <div className="d-flex flex-column form-container">
        <FormSection
          {...{ errors }}
          label={"Active"}
          inputClass={"form-container__toggle"}
        >
          <Switch
            checked={current.status === "on"}
            onChange={() => {
              const newStatus = current.status === "on" ? "off" : "on";
              handleSetStatus(current.id, newStatus);
            }}
            disabled={current.status === "off" && activeReports === 4}
            sx={toggleStyle}
          />
        </FormSection>

        <FormSection
          {...{ errors }}
          label={"Date Range"}
          inputClass={"date-range-opts"}
        >
          <button
            className={`range-btn ${
              isLastMonth(current.start_date, current.end_date) ? "active" : ""
            }`}
            onClick={() => {
              handleSelectDate(lastMonthStart, SET_START_DATE);
              handleSelectDate(lastMonthEnd, SET_END_DATE);
            }}
          >
            <span>Last Month</span>
          </button>
          <DatePickers {...{ current, handleSelectDate }} />
        </FormSection>

        <FormSection {...{ errors }} label={"Audience"} inputClass={""}>
          <div className="audience-selector">
            <Select
              value={{
                label: current.audience || "All Audiences",
                value: current.audience,
              }}
              options={[
                { label: "All Audiences", value: null },
                ...audienceOptions.map((a) => {
                  return {
                    label: a,
                    value: a,
                  };
                }),
              ]}
              placeholder="Select audience"
              onChange={handleSelectAudience}
              styles={IR_SELECT_STYLES}
            />
          </div>
        </FormSection>

        <FormSection
          {...{ errors }}
          label={"Excluded Labels"}
          inputClass={"d-flex"}
        >
          <div className="excluded w-50">
            {current.excluded_labels.length ? (
              <ul>
                {current.excluded_labels.map((label) => {
                  return (
                    <li onClick={() => handleRemoveLabel(label)}>{label}</li>
                  );
                })}
              </ul>
            ) : (
              <ul>
                <li className="no-labels">No excluded labels.</li>
              </ul>
            )}
          </div>
          <div className="label-options">
            <div className="label-selector">
              <Select
                value={null}
                options={labelOptions
                  .filter((opt) => !current.excluded_labels.includes(opt))
                  .map((a) => {
                    return {
                      label: a,
                      value: a,
                    };
                  })}
                placeholder="Add label"
                onChange={(label) => handleAddLabel(label.value)}
                styles={IR_SELECT_STYLES}
              />
            </div>
          </div>
        </FormSection>

        {!current.error ? (
          <>
            <FormSection
              {...{ errors }}
              label={"Categories"}
              inputClass={"d-flex"}
            >
              <div className="categories-list w-100">
                {current.categories ? (
                  <ul>
                    {Object.keys(current.categories).map((cat) => {
                      return (
                        <li>
                          {cat}
                          <Switch
                            checked={current.combined_category_names.includes(
                              cat
                            )}
                            onChange={() => {
                              handleIncludedCategories(cat);
                            }}
                            sx={toggleStyle}
                          />
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <ul>
                    <li className={`${!current.id ? "not-run" : ""}`}>
                      {current.id
                        ? "No categories."
                        : "Report has not been run yet."}
                    </li>
                  </ul>
                )}
              </div>
            </FormSection>
            <FormSection
              {...{ errors }}
              label={"Combined Score"}
              inputClass={"d-flex"}
            >
              <div className={`score w-100 ${!current.id ? "not-run" : ""}`}>
                {Object.hasOwn(current, "combined_score")
                  ? current.combined_score > 0 && current.combined_score < 0.01
                    ? "<1%"
                    : toPercentRounded(current.combined_score)
                  : "Report has not been run yet."}
              </div>
            </FormSection>
          </>
        ) : null}

        {current.error && (
          <div className="form-section row form-section--error">
            <div className="col-12">
              <i
                className="fa-solid fa-triangle-exclamation mr-3"
                style={{ color: "#f0ad4e" }}
              ></i>
              <span className="report-error">{current.error}</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditMode;

const FormSection = ({ label, inputClass, errors, children }) => {
  const hasError = errors.includes(label.toLowerCase());

  return (
    <div
      className={`form-section row form-section--${label
        .toLowerCase()
        .split(" ")
        .join("-")}`}
    >
      <div className="col-2">
        {hasError && (
          <div className="error-icon">
            <i className="fa fa-circle-exclamation" aria-label="error"></i>
          </div>
        )}
        <div className="form-label">{label}</div>
      </div>
      <div className={`col-10 ${inputClass}`}>{children}</div>
    </div>
  );
};

const EditModeHeader = ({
  current,
  handleSetName,
  handleUndo,
  handleDelete,
  changesMade,
  showModal,
  setShowModal,
  handleDeleteClickAway,
  updateReportsToDelete,
  errors,
  handleValidation,
  saveInProgress,
}) => {
  const saveBtnText = current.id
    ? !changesMade
      ? "Saved"
      : "Save"
    : "Run Report";

  return (
    <div className="row report-editor__header">
      <div className="col-12 d-flex justify-content-between align-items-baseline">
        <div className="position-relative d-flex align-items-center">
          {errors.includes("name") && (
            <div className="error-icon">
              <i className="fa fa-circle-exclamation" aria-label="error"></i>
            </div>
          )}
          <input
            className={`report-name`}
            placeholder="Report Title"
            value={current.name}
            onChange={(e) => handleSetName(e.target.value)}
          ></input>
        </div>
        <div className="header-options">
          {saveInProgress && <span className="saving">Saving...</span>}
          <Button
            id="save"
            type="primary"
            onClick={handleValidation}
            disabled={!changesMade || saveInProgress}
          >
            <span className="saving">{saveBtnText}</span>
          </Button>
          <Button
            id="undo-changes"
            type="subtle"
            onClick={() => handleUndo(current.id)}
            disabled={null}
          >
            Undo Changes
          </Button>
          <ConfirmationModal
            handleClickAway={(e) => handleDeleteClickAway(e)}
            show={showModal}
            handleCancel={() => {
              updateReportsToDelete(current.id);
              setShowModal(false);
            }}
            handleConfirm={() => handleDelete()}
            message={`Delete this report?`}
            className={"single-delete"}
          >
            <Button
              id="single-delete"
              type="subtle"
              onClick={() => {
                updateReportsToDelete(current.id);
                setShowModal((prev) => !prev);
              }}
              disabled={!current.id}
            >
              Delete
            </Button>
          </ConfirmationModal>
        </div>
      </div>
    </div>
  );
};
