import { SortType } from './types';

export const BIRDS_EYE_VIEWS = [
  'TVoGYvzV2wVavdOedU',
  'BqYWZmDVCyCiqXJpza',
  'OhpPKqTNGjmD9F7cum',
  'swI5DvxGW1cDndDfnw',
  'XokE2ivMuVlzTRgbbb',
  'VRW3O16LootEP7NlWx',
  'wHDcJZFyOvtNEFEB0f',
  '6ekmvxmawFV7DqWWD7',
  'MjJaV4TDUvHqjV8M1M',
  'tYbJDhk2QMbWdUef1o',
  'fhjVO85ouaSCujGOcf',
  'kkjRt1eB4shBS1h3WK',
  '8BwyAcNMRDpufF1A3W',
  'eEPKQmUJ2JuXi2yH0o',
  'Pqok2c6Z0csm4LVC5Y',
  '3VPzgfFkElbhi9oZT3',
  'UIFzQbAGehU1KyOFFn',
  'F7VGCcnsU4rzINrnwe',
  'ZGFmPiVVwI3lZ3ePt7',
];

export const SORT_OPTIONS: SortType[] = [
  {
    label: 'Launch Date (most recent)',
    value: 'adset_created_time',
    order: 'descending',
  },
  {
    label: 'Launch Date (oldest)',
    value: 'adset_created_time',
    order: 'ascending',
  },
];
[
  {
    platform: 'Meta',
    adset_created_time: '2024-06-24T17:18:49-05:00',
    campaign_name: '',
    campaign_id: '',
    adset_name: '',
    optimization_event: '',
    adset_id: '',
    adset_total_spend: 13,
  },
];
