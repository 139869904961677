export const classNames = {
  base: 'darwin-button',
  appearanceClasses: {
    primary: 'darwin-button--primary',
    secondary: 'darwin-button--secondary',
    navy: 'darwin-button--navy',
    subtle: 'darwin-button--subtle',
    subtleBordered: 'darwin-button--subtle-bordered',
    red: 'darwin-button--red',
    red2: 'darwin-button--red-2',
    yellow: 'darwin-button--yellow',
    seafoam: 'darwin-button--seafoam',
    gray: 'darwin-button--gray',
    raised: 'darwin-button--raised',
  },
  sizeClasses: {
    sm: 'darwin-button--sm',
    lg: 'darwin-button--lg',
  },
  disabled: 'darwin-button--disabled',
  loading: 'darwin-button--disabled darwin-button--loading',
};
