//@ts-check
import React, { useEffect, useState } from "react";

import CustomDropdown from "../CustomDropdown";
import { useSearchParams } from "../Hooks/search-params";
import { useReportsApi, useResetReportsApi } from '../../features/CreativeReports/withApi';

const OPTIONS = [
  { label: "Primary Labels", value: "" },
  { label: "Standardized Labels", value: "standardized" },
  { label: "Unique Client Attributes", value: "visual_attributes"},
];

export function AdLabelTypePicker() {
  let { initialIndex, setType } = useParams();
  let [index, setIndex] = useState(initialIndex);
  const reports = useReportsApi();
  const resetReports = useResetReportsApi();

  useEffect(() => {
    setType(OPTIONS[index].value);
    reports.dispatch();
  }, [index]);
  
  const selectViewType = (i) => {
    setIndex(i);
    resetReports.dispatch();
  }

  return (
    <CustomDropdown
      label={`${OPTIONS[index].label}`}
      onSelect={(i) => i !== index && selectViewType(i)}
    >
      {OPTIONS.map(({ label, value }, i) => (
        <CustomDropdown.Item key={value} eventKey={i.toString()}>
          {label}
        </CustomDropdown.Item>
      ))}
    </CustomDropdown>
  );
}

function useParams() {
  let { setParams, getParams } = useSearchParams();
  let { ad_label_type } = getParams("ad_label_type") || { ad_label_type: "" };
  const found = OPTIONS.findIndex(({ value }) => value === ad_label_type);

  return {
    initialIndex: found > -1 ? found : 0,
    setType: (type) => setParams({ ad_label_type: type }),
  };
}
