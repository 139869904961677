import React from 'react';
import { Link } from 'react-router-dom';
import { useViewStatus } from '../../components/App/App';
import { BasicLoading } from '../../components/Helpers/Loading';
import CreativeAnalytics from '../CreativeAnalytics';

export const NestedView = ({ viewId, selectedTab }) => {
  const { error, loading, loggedIn } = useViewStatus();
  if (loading) {
    return <BasicLoading />;
  } else if (!loggedIn) {
    return (
      <div className="p-3">
        <p>You must be logged in to view this.</p>
        <Link to="/login">Go to Login</Link>
      </div>
    );
  } else if (error) {
    return <p>Error: {error}</p>;
  } else if (loggedIn) {
    return (
      <div>
        {/* <Routes
          loggedIn={loggedIn}
          viewId={viewId}
          loading={loading}
          creative_studio_only={creative_studio_only}
          isNonPaidMediaType={isNonPaidMediaType}
          isUltraLight={isUltraLight}
        /> */}
        <CreativeAnalytics {...{ selectedTab }} />
      </div>
    );
  }

  return null;
};
