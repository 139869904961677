import React, { createContext, useContext } from 'react';

export const CategoryBuilderContext = createContext(null);

export function CategoryBuilderProvider({
  data,
  fetchPerformanceData = {},
  getUdcApi,
  udcApi,
  derivedLabelsApi,
  getDerivedLabelsApi,
  allKeywords,
  getAdNameLabelsApi,
  adNameLabelsApi,
  setEditCategory,
  setEditCategoryVisual,
  children,
}) {
  return (
    <CategoryBuilderContext.Provider
      value={{
        data,
        fetchPerformanceData,
        getUdcApi,
        udcApi,
        derivedLabelsApi,
        getDerivedLabelsApi,
        allKeywords,
        getAdNameLabelsApi,
        adNameLabelsApi,
        setEditCategory,
        setEditCategoryVisual,
      }}
    >
      {children}
    </CategoryBuilderContext.Provider>
  );
}

export function useCategoryBuilderContext() {
  return useContext(CategoryBuilderContext);
}
