import { useState, useRef, useCallback } from 'react';
import { abortableFetch } from '../utils';

export function useAbortableFetch() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchRef = useRef(null);
  const requestIdRef = useRef(0);

  const request = useCallback((request, opts) => {
    const currentRequestId = ++requestIdRef.current;

    if (fetchRef.current) {
      fetchRef.current.abort();
    }

    const { abort, ready } = abortableFetch(request, opts);
    fetchRef.current = { abort };

    setLoading(true);
    setError(null);

    ready
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch');
        }
        return response.json();
      })
      .then((data) => {
        if (requestIdRef.current === currentRequestId) {
          setData(data);
        }
      })
      .catch((err) => {
        if (
          err.name !== 'AbortError' &&
          requestIdRef.current === currentRequestId
        ) {
          setError(err);
        }
      })
      .finally(() => {
        if (requestIdRef.current === currentRequestId) {
          setLoading(false);
        }
      });
  }, []);

  const abort = useCallback(() => {
    if (fetchRef.current) {
      fetchRef.current.abort();
    }
  }, []);

  const clearData = useCallback(() => {
    setData(null);
    setLoading(false);
    setError(false);
    abort();
  }, []);

  return { data, loading, error, request, abort, clearData };
}
