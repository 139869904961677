import React from 'react';
import Placeholder from './Placeholder';

const Visual = ({
  data,
  loading,
  isBaseVisual,
  handleOpenDrawer,
  disableDrawer,
}) => {
  if (!!data && (!loading || isBaseVisual)) {
    const { visual_type, asset_url } = data;

    let videoDisplay = (
      <div className="video-display">
        {asset_url ? (
          <video
            className="element-details__img"
            key={asset_url}
            autoPlay
            loop
            muted
            plays-inline
          >
            <source src={asset_url} type="video/mp4" />
          </video>
        ) : (
          <p className="missing-asset-url">
            <i className="fa-solid fa-triangle-exclamation"></i>
            Missing asset URL
          </p>
        )}
      </div>
    );

    return (
      <div className="element-details__example">
        {(visual_type === 'image' || !visual_type) && (
          <img className="element-details__img" src={asset_url} alt="" />
        )}
        {visual_type === 'video' && videoDisplay}
      </div>
    );
  }

  return (
    <div className="element-details__example">
      <Placeholder
        {...{ handleOpenDrawer, isBaseVisual, loading, disableDrawer }}
      />
    </div>
  );
};

export default Visual;
