import { useRef, useEffect } from 'react';
import { abortableFetch } from '../../../utils';
import {
  useApiState,
  bindFetchToRef,
} from '../../../components/Hooks/useApiState';

const HEADERS = { 'content-type': 'application/json' };

function createCategoryAsync(payload) {
  return abortableFetch(
    ['api', 'creative-analytics', 'custom-categories', 'create-category', 'async', payload.darwin_client_id].join(
      '/'
    ),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useCreateCategoryAsync() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(createCategoryAsync(payload)).then(({ data: { data } }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  const handleCreateCategory = (payload) => {
    postDispatch(payload);
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    ...postState,
    request: handleCreateCategory,
  };
}