import React, { useEffect, useMemo } from 'react';
import useCategories from '../../../hooks/useCategories';
import { MenuItem } from '../../../../../components/LeftMenu/MenuItem';
import {
  useCreativeAnalyticsGet,
  useCreativeAnalyticsSet,
} from '../../../contexts';
import { Bar } from '../../icons/bar';
import { batch, useDispatch, useSelector } from 'react-redux';
import { customNanoId } from '../../../../../utils/nanoid';
import { trackEvent } from '../../../../../store/UserActivity/actions';
import {
  CREATE_CATEGORY_BUTTON_PRESSED,
  CREATIVE_ANALYTICS,
} from '../../../../../store/UserActivity/constants';
import {
  ADSET_NAME,
  AD_NAME,
  PERFORMANCE_FREQUENCY,
} from '../../../../../store/CreativeReports/constants';
import { useReportsApi } from '../../../../CreativeReports/withApi';
import { useSearchParams } from '../../../../../components/Hooks/search-params';
import { useCategoriesList } from '../../../selectors';
import CreativeAnalytics from '../../../CreativeAnalytics';
import { setEditCategory } from '../../../../../store/CreativeAnalytics/creativeAnalyticsSlice';
import { usePermissions } from '../../../../../components/Permissions/usePermissions';
import { PFR } from '../../../../../components/Permissions/constants';

const getNavCategories = () => {};
const Noop = () => null;

export const ReportsNav = ({
  loading,
  itemClass,
  collapsed,
  setCollapsed,
  handleSelection,
  feature,
  page,
}) => {
  const { navCategories } = useSelector(
    ({ CreativeAnalytics: { navCategories } }) => ({ navCategories })
  );
  const { getParams, setParams } = useSearchParams();

  const { category, display, group } = getParams(
    'category',
    'display',
    'group'
  );
  // const { setEditCategory } = useCreativeAnalyticsSet();
  const dispatch = useDispatch();
  const nanoid = customNanoId();
  const reportsApi = useReportsApi(display);
  const hasPermission = usePermissions();

  let reports = navCategories
    ?.filter(
      (category) =>
        !['Phrase Theme', 'Darwin Clusters'].includes(category.label) &&
        !(group === 'yes' && category.label === 'Primary Dimensions')
    )
    .map(({ label, value, variance }) => {
      let isSelectedCategory = category === value;

      if (!category?.length) {
        isSelectedCategory = value === 'All Visuals';
      }

      return (
        <MenuItem
          title={label}
          onMenuItemClick={() => {
            handleSelection('analytics', 'performance_frequency');
            if (isSelectedCategory) {
              setParams({ category: '' });
            } else {
              setParams({ category: value });
            }
          }}
          isSelected={page === 'performance_frequency' && isSelectedCategory}
          className="detailed-report-item"
        />
      );
    });


  return hasPermission(PFR) ? (
    <MenuItem
      title="Performance Frequency"
      // icon={<Bar />}
      onMenuItemClick={
        !navCategories?.length
          ? () => handleSelection('analytics', 'performance_frequency')
          : null
      }
      expandByDefault={page === 'performance_frequency'}
      display={page}
      id="detailed-reports"
      collapsed={collapsed}
      setCollapsed={setCollapsed}
      subMenu={
        loading ? (
          <>
            <div>Loading...</div>
          </>
        ) : (
          <>
            {reports}
            <MenuItem
              title="+ Create new..."
              exclude={feature !== 'analytics' || !page}
              onMenuItemClick={() => {
                batch(() => {
                  dispatch(
                    trackEvent(
                      CREATIVE_ANALYTICS,
                      CREATE_CATEGORY_BUTTON_PRESSED
                    )
                  );
                  dispatch(
                    setEditCategory({
                      category: 'custom',
                      ccc_type: '',
                      category_id: nanoid(),
                    })
                  );
                });
              }}
            />
          </>
        )
      }
      className={itemClass}
    />
  ) : null;
};
