import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomDropdown from '../CustomDropdown';

import { LayoutContainer } from '../../features/CreativeReports/Helpers';

const Layout = ({
  AdName,
  AdPreview,
  ActionPanel,
  StrikeZone,
  PerformancePanel,
  PrimaryColor,
}) => {
  const [display, setDisplay] = useState('overview');

  return (
    <>
      {AdName}

      <LayoutContainer>
        <div className="col-11 pl-0 pr-0 d-flex flex-row">
          {AdPreview}

          <div
            className="darwin-box-shadow p-3"
            style={{
              backgroundColor: '#ffffff',
              width: 'calc(100% - 580px)',
              position: 'relative',
              left: '1rem',
            }}
          >
            {display === 'overview' ? (
              <>
                {ActionPanel}
                <br />
                {PrimaryColor}
                <br />
                {StrikeZone}
              </>
            ) : (
              PerformancePanel
            )}

            <div
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                height: '35px',
              }}
            >
              <CustomDropdown
                label="Overview"
                onSelect={(key) => setDisplay(key.toLowerCase())}
              >
                <CustomDropdown.Item eventKey="overview">
                  Overview
                </CustomDropdown.Item>
                <CustomDropdown.Item eventKey="details">
                  Details
                </CustomDropdown.Item>
              </CustomDropdown>
            </div>
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

Layout.propTypes = {
  AdName: PropTypes.node.isRequired,
  AdPreview: PropTypes.node.isRequired,
  ActionPanel: PropTypes.node.isRequired,
  StrikeZone: PropTypes.node.isRequired,
  PerformancePanel: PropTypes.node.isRequired,
  PrimaryColor: PropTypes.node.isRequired,
};

export default Layout;
