/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';

import Button from '../../../components/Button';
import { darwinDateToDate } from '../../../utils/darwin-dates';

import { useVisualReportGet, useVisualReportSet } from './contexts';

import { useCustomLabels } from './api';

import { METRIC_OPTIONS, currencyMetrics, percentMetrics, percent_2Metrics, decimalMetrics, intMetrics } from './constants';

import { toCurrency, toPercent, toInt, toFixed_2, toPercent_2 } from '../../../utils/numbers';

import { useCustomMetrics, extendCustomMetrics } from './Pickers';

const getColumnHeaders = (metricCols) => {
  const custom = useCustomMetrics();
  const options = [...METRIC_OPTIONS, ...extendCustomMetrics(custom)];
  const lookup = toDict(options, 'value');
  const columnHeaders = metricCols.map((metric) => {
    return {
      label: lookup[metric],
      value: metric,
    }
  })

  return columnHeaders;
}

function toDict(arr, key) {
  const results = {};

  arr.forEach((item) => {
    results[item[key]] = item.label;
  });

  return results;
}

const sortToParam = (sort) => {
  const { metric, order } = sort;

  const param = `${metric} ${order}`;

  return param;
}

const sortParamToObj = (currentSort) => {
  if (currentSort.length) {
    const sort = {
      metric: currentSort.split(' ')[0],
      order: currentSort.split(' ')[1],
    }
    return sort;
  }
  return null;
}

const VisualsTable = ({ metrics, insights_by_asset, data, currentSort, labelsLoading }) => {
  const [sort, setSort] = useState(sortParamToObj(currentSort));
  const metricCols = metrics.length ? metrics.split(' ') : [];
  const columnHeaders = getColumnHeaders(metricCols);
  const { audience_breakdown, period1, period2, level } = useVisualReportGet();
  const setParams = useVisualReportSet();
  const isAssetLevel = level === 'asset';
  const isAudienceBreakdown = audience_breakdown === true || audience_breakdown === 'true';

  let sortedData = data;
  
  useEffect(() => {
    if (sort) {
      setParams({ sort: sortToParam(sort) });
    }
  }, [sort]);

  useEffect(() => {
    if (currentSort.length) {
      setSort(sortParamToObj(currentSort));
    }
  }, [currentSort]);

  if (sort) {
    let result = data;

    if (!isAudienceBreakdown) {
      result = data.sort((a, b) => {
        let first = a.period_breakdown.period_one[sort.metric];
        let second = b.period_breakdown.period_one[sort.metric];

        if (first === '-') {
          first = -1;
        }
        if (second === '-') {
          second = -1;
        }

        switch (sort.order) {
          case 'asc':
            return first - second;
          case 'desc':
            return second - first;
          default:
            return data;
            break;
        } 
      })
      sortedData = result;
    }

    else if (isAudienceBreakdown) {
      result = data.sort((a, b) => {
        let first = a.audience_breakdown.all_audiences[sort.metric];
        let second = b.audience_breakdown.all_audiences[sort.metric];

        if (first === '-') {
          first = -1;
        }
        if (second === '-') {
          second = -1;
        }

        switch (sort.order) {
          case 'asc':
            return first - second;
          case 'desc':
            return second - first;
          default:
            return data;
            break;
        } 
      })
    }
  }

  const handleSort = (value) => {
    if (sort && sort.metric === value) {
      setSort((prev) => ({
        ...prev,
        order: prev.order === 'asc' ? 'desc' : 'asc',
      }))
    } else {
      setSort({
        metric: value,
        order: 'desc', 
      })
    }
  }

  return (
    <table sortable class="visuals-table">
    	<thead class="visuals-table__header">
    		<tr>
    			<th scope="col" className="visuals-table__blank"></th>
          {columnHeaders.map((metric) => (
    			  <th onClick={() => handleSort(metric.value)} scope="col" className="visuals-table__heading">
              {metric.label}
              {sort && sort.metric === metric.value && sort.order === 'desc' && (
                <i class="fa fa-arrow-down"></i>
              )}
              {sort && sort.metric === metric.value && sort.order === 'asc' && (
                <i class="fa fa-arrow-up"></i>
              )}
            </th>
          ))}
    			{insights_by_asset && isAssetLevel && (
            <th scope="col" className="visuals-table__heading">
              custom labels
            </th>
          )}
    		</tr>
    	</thead>
    	<tbody>
      {sortedData.map((row) => (
        <VisualsTableRow
          data={row}
          {...{ metrics, audience_breakdown, period1, period2, isAssetLevel, labelsLoading }}
        />
      ))}
    	</tbody>
    </table>
  )
}

export default VisualsTable;

const VisualsTableRow = ({ metrics, data, audience_breakdown, period1, period2, isAssetLevel, labelsLoading }) => {
  const period2Exists = period2.length;
  const [initialLabels, setInitialLabels] = useState([]);
  const [customLabels, setCustomLabels] = useState([]);
  const [startDate1, endDate1] = period1.split(',').map(darwinDateToDate);
  const [startDate2, endDate2] = period2Exists ? period2.split(',').map(darwinDateToDate) : [null, null];
  const [labelInput, setLabelInput] = useState('');
  const [showName, setShowName] = useState(false);
  const isAudienceBreakdown = audience_breakdown === true || audience_breakdown === 'true';

  const { id, visual_url, visual_type } = data;

  let periodOneLabel = '';
  let periodTwoLabel = '';

  periodOneLabel = `${startDate1.toLocaleDateString()} - ${endDate1.toLocaleDateString()}`;

  if (startDate2) {
    periodTwoLabel = `${startDate2.toLocaleDateString()} - ${endDate2.toLocaleDateString()}`;
  }

  useEffect(() => {
    if (data.custom_trained_labels)  {
      setInitialLabels(data.custom_trained_labels);
      setCustomLabels(data.custom_trained_labels);
    }
  }, [data]);

  const metrics_cols = metrics.length ? metrics.split(' ') : [];

  let dataColumns = [];

  const customLabelsApi = useCustomLabels();

  if (isAudienceBreakdown) {
    dataColumns = Object.entries(data.audience_breakdown).map((audience) => {
      return audience;
    })
  } else {
    dataColumns = Object.entries(data.period_breakdown).map((period) => {
      return period;
    })
  }

  const handleSaveLabels = () => {
    customLabelsApi.post.request({
      visual_hash: id,
      custom_labels: customLabels.map((label) => { 
        return {
          name: label, status: 'on',
        }
      }),
      update_custom_only: true,
    })
  }


  const getLabel = (col, i, metricIndex) => {
    let label = '';

    if (isAudienceBreakdown) {
      label = dataColumns[i][0];
    } else if (metricIndex === 0) {
      label = i === 0 ? periodOneLabel : periodTwoLabel;
    }

    return label;
  }

  const handleRemoveLabel = (i) => {
    const update = customLabels.filter((label, index) => index !== i);
    setCustomLabels(update);
  }

  const handleAddLabel = () => {
    const update = [...customLabels, labelInput];
    setCustomLabels(update);
    setLabelInput('');
  }

  const handleResetLabels = () => {
    setCustomLabels(initialLabels);
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      handleAddLabel();
    }
  };

  const showSegment = (col) => {
    let show = true;

    if ((col[0] === 'period_comparison') || (!period2Exists && col[0] === 'period_two')) {
      show = false;
    }

    return show;
  }


  const formatData = (value, metric) => {
    if (currencyMetrics.includes(metric)) {
      return toCurrency(value);
    }
    if (percentMetrics.includes(metric)) {
      return toPercent(value / 100);
    }
    if (percent_2Metrics.includes(metric)) {
      return toPercent_2(value / 100);
    }
    if (decimalMetrics.includes(metric)) {
      return toFixed_2(value);
    }
    if (intMetrics.includes(metric)) {
      return toInt(value);
    }
    return value;
  }


  return (
    <tr class={`visuals-row ${!period2Exists ? 'one-segment' : ''}`}>
      <td className="visuals-row__img">
        <div
          className="visuals-row__name"
          title={id}
          onMouseEnter={() => setShowName(true)}
          onMouseLeave={() => setShowName(false)}
        >
          {showName && <p className="visuals-row__name-popup">{id}</p>}
          {id}
        </div>
        <a href={visual_url} rel="noreferrer" target="_blank">
          {visual_type === 'image' && (
            <img key={visual_url} src={visual_url}></img>
          )}
          {visual_type === 'video' && (
            <video key={visual_url} autoPlay loop muted plays-inline="true">
              <source src={visual_url} type="video/mp4" />
            </video>
          )}
        </a>
      </td>
      {metrics_cols.map((metric, metricIndex) => (
        <td data-label="spend" className="visuals-row__block">
          {dataColumns.map((col, i) => {
            if (showSegment(col)) {
              return (
                <div className={`visuals-row__segment ${metricIndex === 0 && !isAudienceBreakdown ? 'first-item' : ''}`}>
                  <div className="visuals-row__data">
                    <p className="visuals-row__label">{getLabel(col, i, metricIndex)}</p>
                    {formatData(col[1][metric], metric) || '-'}
                  </div>
                </div>
              )
            }
          })}
        </td>
      ))}
      {isAssetLevel && (
        <td data-label="custom labels" className="visuals-row__block align-top labels-block">
          <div className="custom-labels__add">
            <input
              type="text"
              value={labelInput}
              onChange={(e) => setLabelInput(e.target.value)}
              onKeyDown={onKeyDown}
              className="custom-labels__input"
            />
            <Button onClick={handleAddLabel}>Add</Button>
          </div>
          {customLabels.length ? (
            <div className="custom-labels">
              <ul className="custom-labels__list">
                {customLabels.map((label, i) => (
                  <li className="custom-labels__item" onClick={() => handleRemoveLabel(i)}>{label}</li>
                ))}
              </ul>
            </div>
          ) : labelsLoading ? (
            <p>Loading...</p>
          ) : (
            <p>No labels.</p>
          )}
          <div className="custom-labels__options">
            <Button onClick={handleSaveLabels} className="mr-2" disabled={customLabelsApi.post.loading}>Save</Button>
            <Button onClick={handleResetLabels} appearance="subtle">Reset</Button>
            {customLabelsApi.post.loading && <p className="custom-labels__msg">Saving...</p>}
            {customLabelsApi.post.error && <p className="custom-labels__msg">{customLabelsApi.post.error}</p>}
            {customLabelsApi.post.data && <p className="custom-labels__msg">Saved successfully.</p>}
          </div>
        </td>
      )}
    </tr>
  )
}