import React from 'react';
import Select from 'react-select';
import Tooltip from '../../../../../components/Tooltip';
import { SortToggle } from './CreativePerformance';
import IconButton from '../../../../../components/IconButton';
import { SELECT_STYLES } from '../../../../../constants';
import { ColumnPicker } from '../../shared/ColumnPicker';
import DisplaySelect from './DisplaySelect';

export const DetailDataToolbar = ({
  mode,
  dataset,
  setMode,
  setDataset,
  selectedColumns,
  dataModeColumns,
  handleColumnChange,
  handleExportCSV,
  openShareModal,
  renderAnalyticsToggle,
  sort,
  setSort,
}) => {
  return (
    <div
      className="d-flex align-items-center justify-content-between mb-3"
      style={{ flexWrap: 'wrap', gap: '6px' }}
    >
      <div className="d-flex align-items-center" style={{ flex: 1 }}>
        <h3 className="mr-3 mb-0" style={{ whiteSpace: 'nowrap' }}>
          Detail Data
        </h3>
        <div style={{ marginRight: '1rem' }}>
          <Select
            styles={{
              ...SELECT_STYLES,
              container: (provided) => ({
                ...provided,
                width: '100%',
                minWidth: '200px',
              }),
            }}
            options={[
              { label: 'Creative View', value: 'creative' },
              { label: 'Data View', value: 'data' },
            ]}
            value={
              [
                { label: 'Creative View', value: 'creative' },
                { label: 'Data View', value: 'data' },
              ].find((opt) => opt.value === mode) || null
            }
            onChange={(selected) => {
              setMode(selected.value);
            }}
          />
        </div>

        {mode === 'data' && (
          <>
            <DisplaySelect
              display={dataset}
              setDisplay={(value) => {
                setDataset(value as 'spend_based' | 'rank_based');
                return false;
              }}
            />
            <div className="mx-2">
              <ColumnPicker
                columns={selectedColumns}
                options={dataModeColumns
                  .filter((col) => col.accessor !== 'label_name')
                  .map((col) => ({
                    value: col.accessor,
                    label: col.Header,
                  }))}
                onChange={({ column, checked }) => {
                  let update = [...selectedColumns];
                  if (checked) {
                    update = [...selectedColumns, column];
                  } else {
                    update = selectedColumns.filter((col) => col !== column);
                  }
                  handleColumnChange(update);
                }}
              />
            </div>
          </>
        )}

        {mode === 'creative' && (
          <>
            <div>
              <Tooltip content="Export to CSV">
                <IconButton
                  onClick={handleExportCSV}
                  style={{}}
                  disabled={false}
                >
                  <i className="fa-solid fa-table"></i>
                </IconButton>
              </Tooltip>
            </div>

            <div className="ml-1">
              <Tooltip content="Export static">
                <IconButton
                  onClick={openShareModal}
                  style={{}}
                  disabled={false}
                >
                  <i className="fa-solid fa-share"></i>
                </IconButton>
              </Tooltip>
            </div>
          </>
        )}
      </div>

      <div className="d-flex">
        <span className="mr-2">{renderAnalyticsToggle()}</span>
        <SortToggle sort={sort} setSort={setSort} />
      </div>
    </div>
  );
};
