import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import {
  updateElement,
} from '../../store/CreateAd/actions';
import { useElementPayload } from './hooks';
import { handleIndex } from './utils';

import './Carousel.style.scss';

const ElementTypes = PropTypes.oneOf(['atf', 'btf', 'cta', 'visual']);

function DefaultTextInput({ type }) {
  const [
    {
      data: { value, index },
      len,
    },
    setElement,
  ] = useElementPayload(type);
  const [text, setText] = useState(value);

  const handleSave = (e) => {
    if (text === '') {
      setText(value);
      setElement({ value, index });
    } else {
      setElement({ value: text, index: len });
    }
  };

  useEffect(() => {
    if (value !== text) {
      setText(value);
    }
  }, [value]);

  return (
    <textarea
      className="editable-input p-1"
      name={type}
      onChange={(e) => setText(e.target.value)}
      onBlur={handleSave}
      value={text}
    />
  );
}

DefaultTextInput.propTypes = {
  type: ElementTypes.isRequired,
};

function ImageInput({ type }) {
  const [
    {
      data: { value, image_url },
    },
  ] = useElementPayload(type);

  return (
    <img
      src={image_url}
      alt={value}
      className="editable-input"
      style={{ cursor: 'default' }}
    />
  );
}

ImageInput.propTypes = {
  type: ElementTypes.isRequired,
};

export function EditorPanel({ type }) {
  let Comp = null;

  switch (type) {
    case 'visual':
      Comp = ImageInput;
      break;

    default:
      Comp = DefaultTextInput;
      break;
  }

  return (
    <div className="bg-white d-flex flex-row my-1">
      <div style={{ width: '90%' }}>
        <Comp type={type} />
      </div>
      <NavButtons type={type} />
    </div>
  );
}

EditorPanel.defaultProps = {
  type: '',
};

EditorPanel.propTypes = {
  type: ElementTypes,
};

function NavButtons({ type }) {
  const dispatch = useDispatch();
  const { carousel, len, currentIndex } = useSelector(({ CreateAd }) => {
    const currentCarousel = CreateAd.carousel[type];

    return {
      carousel: currentCarousel,
      currentIndex: CreateAd.payload[type].index || 0,
      len: currentCarousel.length,
    };
  });
  const [index, setIndex] = useState(currentIndex);

  const step = (offset) => setIndex((prev) => handleIndex(len, prev, offset));

  useEffect(() => {
    dispatch(updateElement({ type, value: { ...carousel[index], index } }));
  }, [index]);

  return (
    <div className="d-flex flex-row">
      <button type="button" onClick={() => step(-1)} className="swap-btn">
        <i className="fa fa-chevron-left" />
      </button>
      <button type="button" onClick={() => step(1)} className="swap-btn">
        <i className="fa fa-chevron-right" />
      </button>
    </div>
  );
}

NavButtons.propTypes = {
  type: ElementTypes.isRequired,
};
