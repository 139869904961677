import React, { useMemo } from 'react';
import Tooltip from '../../../../components/Tooltip';
import { toPercentRounded } from '../../../../utils/numbers';

import { EditVisualObject } from '../../types';
import { MULTIPLE_LABELS, MUTUALLY_EXCLUSIVE } from '../constants';
import { filterBySelectedLabel } from './utils';
import Visual from './Visual';

import './VisualsList.scss';

type VisualsListProps = {
  visuals: EditVisualObject[];
  labelOptions: string[];
  categoryType: string;
  annotatedVisuals: any;
  handleSelect: any;
  handleMultipleSelect: any;
  selectedLabel: string;
};

const VisualsList = ({
  visuals,
  labelOptions,
  categoryType,
  annotatedVisuals,
  predictedVisuals,
  handleSelect,
  handleMultipleSelect,
  selectedLabel,
  handleBulkSelect,
  replacePredictionsWithAnnotations,
  allowLockIn,
}: VisualsListProps) => {
  const filterBySelected = useMemo(() => {
    return filterBySelectedLabel(selectedLabel, annotatedVisuals, predictedVisuals, categoryType);
  }, [annotatedVisuals, predictedVisuals, selectedLabel]);

  const sortedVisuals = useMemo(() => {
    return Object.entries(visuals).sort((a, b) => {
      return b[1].spend - a[1].spend;
    });
  }, [visuals]);

  return (
    <div className="visuals-list">
      {sortedVisuals.map(([visual_hash, current]) => {
        const hidden = selectedLabel && !filterBySelected.includes(visual_hash);

        return (
          <Visual
            {...{
              visual_hash,
              current,
              labelOptions,
              categoryType,
              annotatedVisuals,
              predictedVisuals,
              handleSelect,
              handleMultipleSelect,
              hidden,
              handleBulkSelect,
              replacePredictionsWithAnnotations,
              allowLockIn,
            }}
          />
        );
      })}
    </div>
  );
};

export default VisualsList;
