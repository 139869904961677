import React from "react";

export const ArrowIcon = () => {
  return (
    <svg
      className="arrow-icon"
      width="8"
      height="15"
      viewBox="0 0 8 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.708069 14.9766V0.976562L7.70807 7.97656L0.708069 14.9766Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};
