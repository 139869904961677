const isExistingCategory = (category_name, derivedLabelsState) => {
  if (!derivedLabelsState.existingCategory) return false;

  return (
    derivedLabelsState.existingCategory.category_name.toLowerCase() ===
    category_name.toLowerCase()
  );
};

const labelisFromExistingCategory = (label_name, derivedLabelsState) => {
  if (!derivedLabelsState.existingCategory) return false;

  return derivedLabelsState.existingCategory.derived_labels.some(
    (x) => x.label_name.toLowerCase() === label_name.toLowerCase()
  );
};

export const getDependencies = (
  existing_derived_labels,
  category_name,
  derivedLabelsData
) => {
  let deps = new Map();

  if (derivedLabelsData) {
    derivedLabelsData.category_structure.forEach((existingCategory) => {
      existingCategory.derived_labels.forEach(
        ({ label_name, label_value, rules }) => {
          rules.forEach((rule) => {
            const ruleLabels = [
              ...rule.labels_to_include,
              ...rule.labels_to_exclude,
            ];
            ruleLabels.forEach((ruleLabel) => {
              if (ruleLabel.category === category_name) {
                const match = existing_derived_labels.find(
                  (derived_label) => derived_label.label_name === ruleLabel.name
                );

                if (match) {
                  const existingDep = deps.get(match.label_name) || [];
                  deps.set(match.label_name, [
                    ...existingDep,
                    {
                      category_name: existingCategory.category_name,
                      label_name,
                      label_value,
                    },
                  ]);
                }
              }
            });
          });
        }
      );
    });
  }

  return deps;
};

export const findDependenciesForLabel = (
  label_name = '',
  label_category = '',
  derivedLabelsData = null,
) => {
  let deps = [];

  if (derivedLabelsData) {
    derivedLabelsData.category_structure.forEach((existingCategory) => {
      existingCategory.derived_labels.forEach((derived_label) => {
        derived_label.rules.forEach((rule) => {
          const ruleLabels = [
            ...rule.labels_to_include,
            ...rule.labels_to_exclude,
          ];
          ruleLabels.forEach((ruleLabel) => {
            if (
              ruleLabel.category === label_category &&
              ruleLabel.name === label_name
            ) {
              deps.push({
                category: existingCategory.category_name,
                name: derived_label.label_name,
              });
            }
          });
        });
      });
    });
  }

  return deps;
};

export const validate = (
  derived_labels,
  category_name,
  derivedLabelsState,
  derivedLabelsData
) => {
  let errors = new Set();
  const existing_derived_labels = derivedLabelsState.data;
  const deps = getDependencies(
    existing_derived_labels,
    category_name,
    derivedLabelsData
  );

  if (derived_labels.length < 1) {
    return [
      ['At least one derived label is required to create the category.'],
      deps,
    ];
  }

  derived_labels.forEach((label) => {
    if (label.label_name.length < 1)
      errors.add('Empty label names not allowed.');
    if (label.rules.length < 1) {
      errors.add('Every label must have at least one rule.');
    } else {
      label.rules.forEach(({ labels_to_include, labels_to_exclude }) => {
        if (labels_to_include.length < 1 && labels_to_exclude.length < 1) {
          errors.add('Each rule must have at least one label selected.');
        }
      });
    }

    if (derivedLabelsData) {
      const lowered = derivedLabelsData.existing_labels.map((label) =>
        label.toLowerCase()
      );
      if (
        lowered.includes(label.label_name.toLowerCase()) &&
        !labelisFromExistingCategory(label.label_name, derivedLabelsState)
      ) {
        errors.add(
          `The label name "${label.label_name}" is already in use. Please choose a unique label name.`
        );
      }
    }
  });

  if (category_name.length < 1) errors.add('Category name cannot be empty.');

  if (derivedLabelsData) {
    const lowered = derivedLabelsData.existing_category_names.map((name) =>
      name?.toLowerCase()
    );
    if (
      lowered.includes(category_name?.toLowerCase()) &&
      !isExistingCategory(category_name, derivedLabelsState)
    ) {
      errors.add(
        `The category name "${category_name}" is already in use. Please choose a unique category name.`
      );
    }
  }

  if (deps.size > 0) {
    for (let [key, value] of deps.entries()) {
      const match = derived_labels.some((x) => x.label_name === key);
      if (!match) {
        errors.add(
          `The label "${key}" cannot be removed because other labels are derived from it.`
        );
      }
    }
  }

  return [Array.from(errors), deps];
};
