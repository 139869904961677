import React from 'react';
import { ensurePluginOrder } from 'react-table';
import Papa from 'papaparse';
import { toDarwinDate } from '../../../utils/darwin-dates';

export function getExportFileBlob({ columns, data, fileType }) {
  if (fileType === 'csv') {
    const headerNames = columns.map((col) => col.exportValue);

    const csvString = Papa.unparse({
      fields: headerNames,
      data,
    });

    return new Blob([csvString], { type: 'text/csv' });
  }

  return false;
}

export function getFirstDefined(...args) {
  for (let i = 0; i < args.length; i += 1) {
    if (typeof args[i] !== 'undefined') {
      return args[i];
    }
  }
}

// Get exported file name(do not specify extension here)
const defaultGetExportFileName = ({ fileType, all }) => {
  return `${all ? 'all-' : ''}data`;
};

// To get cell value while exporting
const defaultGetCellExportValue = (row, col) => {
  return row[col.id];
};

// To get column name while exporting
const defaultGetColumnExportValue = (col) => {
  let name = col.Header;
  if (typeof name === 'object' || typeof name === 'function') {
    name = col.id;
  }
  return name;
};

const defaultGetExportFileBlob = () => {
  throw new Error('React Table: Export Blob is mandatory');
};

export const useExportData = (hooks) => {
  hooks.useInstance.push(useInstance);
};

useExportData.pluginName = 'useExportData';

export const getExportableColumns = (allColumns) => {
  const exportableColumns = allColumns.filter(
    (col) => col.canExport && col.isVisible
  );
  if (exportableColumns.length === 0) {
    console.warn('No exportable columns are available');
  }

  return exportableColumns;
};

export const getExportableRows = (data, exportableColumns) => {
  let exportableRows = data.map((row) => {
    return exportableColumns.map((col) => {
      const { getCellExportValue = defaultGetCellExportValue } = col;
      const cell = getCellExportValue(row, col) || 'N/A';
      if (typeof cell === 'number') return cell.toFixed(2);
      return cell;
    });
  });

  return exportableRows;
};

function useInstance(instance) {
  const {
    rows,
    data,
    initialRows = [],
    allColumns,
    disableExport,
    getExportFileName = defaultGetExportFileName,
    getExportFileBlob = defaultGetExportFileBlob,
    plugins,
  } = instance;
  ensurePluginOrder(
    plugins,
    ['useColumnOrder', 'useColumnVisibility', 'useFilters', 'useSortBy'],
    'useExportData'
  );

  allColumns.forEach((column) => {
    const { accessor, getColumnExportValue = defaultGetColumnExportValue } =
      column;

    const canExport = accessor
      ? getFirstDefined(
          column.disableExport === true ? false : undefined,
          disableExport === true ? false : undefined,
          true
        )
      : false;

    column.canExport = canExport;
    column.exportValue = getColumnExportValue(column);
  });

  const exportData = React.useCallback(
    (fileType, metadata = {}) => {
      const exportableColumns = allColumns.filter((col) => col.canExport);
      if (exportableColumns.length === 0) {
        console.warn('No exportable columns are available');
      }

      let exportableRows = data.map((row) => {
        return exportableColumns.map((col) => {
          const { getCellExportValue = defaultGetCellExportValue } = col;
          const cell = getCellExportValue(row, col) || 'N/A';
          if (typeof cell === 'number') return cell.toFixed(2);
          return cell;
        });
      });

      const fileName = `darwin event history_${metadata?.start_date}_to_${metadata?.end_date}`;

      let fileBlob = getExportFileBlob({
        columns: exportableColumns,
        data: exportableRows,
        filename: fileName,
        fileType,
      });

      if (fileBlob) {
        downloadFileViaBlob(fileBlob, fileName, fileType);
      }
    },
    [getExportFileBlob, getExportFileName, initialRows, rows, allColumns]
  );

  Object.assign(instance, {
    exportData,
  });
}

function downloadFileViaBlob(fileBlob, fileName, type) {
  if (fileBlob) {
    const dataUrl = URL.createObjectURL(fileBlob);
    const link = document.createElement('a');
    link.download = `${fileName}.${type}`;
    link.href = dataUrl;
    link.click();
  }
}
