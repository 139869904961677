export const REQUEST_ID = 'creative_request_id';
export const RATIO = 'ratio';
export const FORMAT = 'format';
export const CARDS = 'cards';
export const REQUEST_OVERVIEW = 'request_overview';
export const REQUEST_DESCRIPTION = 'request_description';
export const PRIMARY_TEXT = 'primary_text';
export const PRIMARY_POSITION = 'primary_position';
export const SECONDARY_TEXT = 'secondary_text';
export const SECONDARY_POSITION = 'secondary_position';
export const INCLUDE_THESE = 'include_these';
export const AVOID_THESE = 'avoid_these';
export const ASSET_NAME = 'asset_name';
export const DATA_POINTS = 'data_points';
export const CREATIVE_CODE = 'creative_code';

export const PROJECT_NAME = 'project_name';
export const CONTRACTOR = 'contractor';
export const CONTRACTOR_NAMES = 'contractor_names';
export const CONTRACTOR_ONE = 'contractor_one';
export const CONTRACTOR_TWO = 'contractor_two';
export const DUE_DATE = 'due_date';
export const ASSET_TAG = 'asset_tag';
export const MESSSAGE_TO_CONTRACTOR = 'message_to_contractor';

export const RATIO_OPTIONS = ['1:1', '4:5', '9:16', '1.91:1'];
export const FORMAT_OPTIONS = ['Image', 'Video', 'Carousel'];
export const CARDS_OPTIONS = [2, 3, 4, 5, 6, 7, 8]

export const DARWIN_TALENT = "darwin_talent";
export const CUSTOM_EMAIL = "custom_email";
export const RECIPIENT = "recipient";
export const DEFAULT_STATUS = {
  error: false,
  message: "",
}

export const DEFAULT_REQUEST = {
  [RATIO]: {
    "1:1": 0,
    "4:5": 0,
    "9:16": 0,
    "1.91:1": 0,
  },
  [FORMAT]: FORMAT_OPTIONS[0],
  [CARDS]: 2,
  [DATA_POINTS]: [],
  [REQUEST_OVERVIEW]: '',
  [REQUEST_DESCRIPTION]: '',
  [PRIMARY_TEXT]: '',
  [PRIMARY_POSITION]: '',
  [SECONDARY_TEXT]: '',
  [SECONDARY_POSITION]: '',
  [INCLUDE_THESE]: [],
  [CONTRACTOR_ONE]: '',
  [CONTRACTOR_TWO]: '',
  [AVOID_THESE]: [],
  [ASSET_NAME]: '',
  [DATA_POINTS]: [],
  [CUSTOM_EMAIL]: '',
  [RECIPIENT]: DARWIN_TALENT,
};

export const NUM_OF_REQUESTS = 4;

export const INITIAL = [...Array(NUM_OF_REQUESTS)].map(() => {
  return DEFAULT_REQUEST;
});

// export const INITIAL = NUM_OF_REQUESTS.map(() => {
//   return DEFAULT_REQUEST;
// })

export const DEFAULT_STANDALONE_REQUEST = {
  account_id: '',
  attachments: [],
  avoid_these: [],
  creative_request_id: '',
  date_created: '',
  format: 'Image',
  include_these: [],
  primary_text: { text: '', text_position: '' },
  ratio: [],
  secondary_text: { text: '', text_position: '' },
  user_email: '',
  cards: 2,
};

export const DEFAULT_PROJECT = {
  [PROJECT_NAME]: '',
  [DUE_DATE]: null,
  [ASSET_TAG]: '',
  [MESSSAGE_TO_CONTRACTOR]: '',
};

export const quillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
  ],
};

export const quillModules2 = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
  ],
};

export const quillFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

export const quillFormats2 = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];
