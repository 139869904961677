export const capitalize = (str = '') => {
  return str
    .trim()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/**
 * Converts from Format 1 (category->label) to Format 2 (MM_category__label)
 * @param {string} format1 - String in Format 1 (e.g. "has offer->yes")
 * @returns {string} - String in Format 2 (e.g. "MM_Has Offer__yes")
 */
export const convertFormat1ToFormat2 = (format1: string) => {
  if (!format1 || !format1.includes('->')) {
    throw new Error('Invalid Format 1 input. Expected "category->label"');
  }

  const [category, label] = format1.split('->');

  const formattedCategory = capitalize(category);

  const formattedLabel = capitalize(label);

  return `MM_${formattedCategory}__${formattedLabel.trim()}`;
};

/**
 * Converts from Format 2 (MM_category__label) to Format 1 (category->label)
 * @param {string} format2 - String in Format 2 (e.g. "MM_Has Offer__yes")
 * @returns {string} - String in Format 1 (e.g. "has offer->yes")
 */
export const convertFormat2ToFormat1 = (format2: string) => {
  if (!format2 || !format2.startsWith('MM_') || !format2.includes('__')) {
    throw new Error('Invalid Format 2 input. Expected "MM_Category__label"');
  }

  const match = format2.match(/^MM_(.+)__(.+)$/);

  if (!match) {
    throw new Error('Invalid Format 2 pattern');
  }

  const category = match[1];
  const label = match[2];

  const formattedCategory = category.toLowerCase();

  return `${formattedCategory}->${label}`;
}; 