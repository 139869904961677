import React, { createContext, useContext } from 'react';
import { useAssetPredictionStatusApi } from './useAssetPredictionStatusApi';
import { useUploadStudioAssetApi, useUploadCommentAssetApi } from './useUploadStudioAssetApi';

const apiContext = createContext(null);

export const GlobalApiWrapper = ({ children }) => {
  const assetPredictionStatusApi = useAssetPredictionStatusApi();
  const uploadStudioAssetApi = useUploadStudioAssetApi(assetPredictionStatusApi);
  const uploadCommentAssetApi = useUploadCommentAssetApi();

  return (
    <apiContext.Provider value={{ assetPredictionStatusApi, uploadStudioAssetApi, uploadCommentAssetApi }}>
      {children}
    </apiContext.Provider>
  );
};

export const useGlobalApiContext = () => useContext(apiContext);
