import React, { useMemo, useReducer, useState } from 'react';
import Select from 'react-select';
import {
  TOGGLE_METRIC_TO_DISPLAY,
  UPDATE_ORGANIZE_AND_COLOR_BY,
  UPDATE_X_CATEGORY,
  UPDATE_Y_CATEGORY,
} from './reducer';
import { toPercent } from '../../../../utils/numbers';

const organizeOpts = [
  {
    label: 'Number of Creatives',
    value: 'numOfCreatives',
  },
  {
    label: 'Spend',
    value: 'spend',
  },
  {
    label: 'Performance',
    value: 'primaryMetric',
  },
  {
    label: 'Spend / Performance',
    value: 'spendAndPerformance',
  },
];

export const Settings = ({
  settings,
  dispatchSettings,
  categories,
  primary_metric,
  handleClose,
}) => {
  const { metricsToDisplay, organizeAndColor } = settings;

  const toggleMetric = (metric) => {
    dispatchSettings({ type: TOGGLE_METRIC_TO_DISPLAY, metric });
  };

  const updateOrganizeBy = (selection) => {
    dispatchSettings({ type: UPDATE_ORGANIZE_AND_COLOR_BY, metric: selection });
  };

  return (
    <div className="heatmap-settings">
      <i
        onClick={handleClose}
        className="fa fa-xmark"
        style={{
          position: 'absolute',
          top: '1rem',
          right: '1rem',
          cursor: 'pointer',
        }}
      />
      <InputGroup label="Comparison">
        <CategoriesSelect {...{ settings, dispatchSettings, categories }} />
      </InputGroup>

      <InputGroup label="Metrics to Display">
        <div className="checkbox-group">
          <input
            onChange={() => toggleMetric('numOfCreatives')}
            type="checkbox"
            id="numOfCreatives"
            name="numOfCreatives"
            checked={metricsToDisplay.numOfCreatives}
          />
          <label for="numOfCreatives">Number of Creatives (N)</label>
        </div>

        <div className="checkbox-group">
          <input
            onChange={(e) => toggleMetric('spend')}
            type="checkbox"
            id="spend"
            name="spend"
            checked={metricsToDisplay.spend}
          />
          <label for="spend">Spend</label>
        </div>

        <div className="checkbox-group">
          <input
            onChange={(e) => toggleMetric('primaryMetric')}
            type="checkbox"
            id="primaryMetric"
            name="primaryMetric"
            checked={metricsToDisplay.primaryMetric}
          />
          <label for="primaryMetric">{primary_metric}</label>
        </div>
      </InputGroup>

      <InputGroup label="Organize and Color by">
        <Select
          value={organizeOpts.find((opt) => opt.value === organizeAndColor)}
          options={organizeOpts}
          onChange={(selected) => updateOrganizeBy(selected.value)}
        />
      </InputGroup>
    </div>
  );
};

const displayImportance = (c) => {
  if (c.importance !== null && c.importance !== undefined) {
    return ` (${toPercent(c.importance)} importance)`;
  }

  return '';
};

const CategoriesSelect = ({ settings, dispatchSettings, categories }) => {
  const { xCategory, yCategory } = settings;

  const options = categories.map((c) => ({
    label: `${c.category_name}${displayImportance(c)}`,
    value: c.category_name,
  }));

  const handleSelectX = (value) => {
    dispatchSettings({ type: UPDATE_X_CATEGORY, category: value });
  };

  const handleSelectY = (value) => {
    dispatchSettings({ type: UPDATE_Y_CATEGORY, category: value });
  };

  return (
    <div className="heatmap-category-select">
      <InputGroup>
        <div className="heatmap-settings-input-group mt-3" style={{ flex: 1 }}>
          <label>Y-axis</label>
          <Select
            value={options.find((opt) => opt.value === yCategory)}
            options={options}
            onChange={(selected) => handleSelectY(selected.value)}
          />
        </div>
        <div className="heatmap-settings-input-group" style={{ flex: 1 }}>
          <label>X-axis</label>
          <Select
            value={options.find((opt) => opt.value === xCategory)}
            options={options}
            onChange={(selected) => handleSelectX(selected.value)}
          />
        </div>
      </InputGroup>
    </div>
  );
};

const InputGroup = ({ label, children }) => {
  return (
    <div className="heatmap-settings-input-group">
      {label && (
        <label className="heatmap-settings-input-group__label">{label}</label>
      )}
      <div>{children}</div>
    </div>
  );
};
