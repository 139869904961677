import { batch } from 'react-redux';
import { API_SUCCESS, apiRequest, API_FAILURE } from '../Api/actions';
import { addNotification } from '../Notifications/actions';

export const BG = 'BackgroundService';

export const BG_FETCH = `[${BG}] FETCH`;

function handleFetch(dispatch, { data: { body, url, method, meta } }) {
  batch(() => {
    dispatch(
      apiRequest({
        url,
        body,
        method,
        entity: BG,
      })
    );

    dispatch(
      addNotification(
        {
          id: new Date().getMilliseconds(),
          status: 'info',
          title: meta.entity,
          message: `Request for ${meta.entity}`,
        },
        BG
      )
    );
  });
}

function handleSuccess(dispatch, { response: { data, entity } }) {
  dispatch(
    addNotification(
      {
        id: new Date().getMilliseconds(),
        status: 'success',
        title: entity,
        message: data,
      },
      BG
    )
  );
}

function handleFailure(dispatch, { error: { error, entity } }) {
  dispatch(
    addNotification(
      {
        id: new Date().getMilliseconds(),
        status: 'danger',
        title: entity,
        message: error,
      },
      BG
    )
  );
}

export const fetchBackgroundService = ({
  body,
  method,
  url,
  entity,

  ...rest
}) => ({
  type: BG_FETCH,
  data: {
    body,
    method,
    url,
    meta: { entity, ...rest },
  },
});

export const backgroundMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case BG_FETCH:
        handleFetch(dispatch, action);

        break;

      case `[${BG}] ${API_SUCCESS}`:
        handleSuccess(dispatch, action);

        break;

      case `[${BG}] ${API_FAILURE}`:
        handleFailure(dispatch, action);

        break;

      default:
        return result;
    }

    return result;
  };
