import * as ss from 'simple-statistics';

type QuintileValue = 1 | 2 | 3 | 4 | 5;
// lower value = higher quintile by default
export const findQuintileOfValue = (
  quintiles: { first: number; second: number; third: number; fourth: number },
  value: number,
  invert: boolean
): QuintileValue => {
  const { first, second, third, fourth } = quintiles;
  if (!value || value === 0) return 1;

  if (value >= fourth) return invert ? 5 : 1;
  if (value < fourth && value >= third) return invert ? 4 : 2;
  if (value < third && value >= second) return invert ? 3 : 3;
  if (value < second && value >= first) return invert ? 2 : 4;
  if (value < first) return invert ? 1 : 5;
};

export const findQuintiles = (values: []) => {
  const first = ss.quantile(values, 0.2);
  const second = ss.quantile(values, 0.4);
  const third = ss.quantile(values, 0.6);
  const fourth = ss.quantile(values, 0.8);

  return { first, second, third, fourth };
};
