//@ts-check
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { isNumber, toCurrency } from '../../../utils/numbers';

import { DARWIN_BLUE, DARWIN_NAVY, DARWIN_RED } from '../../../constants';
import { useDimensions } from '../../../components/Hooks/dom-utils';
import { useViewSettings } from '../../../selectors';
import Button from '../../../components/Button';

const Scatter = ({ data, handleSeriesClick, dimensions, selectedId }) => {
  let [showLabels, setShow] = useState(false);
  let [$labels, { height }] = useDimensions();
  const { isROAS } = useViewSettings();
  const metric = isROAS ? 'roas' : 'cpr';
  const state = {
    data: data.filter((adset) => isNumber(adset[metric])),
    onSeriesClick: handleSeriesClick,
    showLabels,
    height: dimensions.height - height,
    metric,
  };

  const toggleLabels = (e) => setShow(!showLabels);

  return (
    <>
      <div className="d-flex labels-toggle" ref={$labels}>
        <Button appearance="raised" onClick={toggleLabels}>
          {`${showLabels ? 'Hide' : 'Show'} labels`}
        </Button>
      </div>
      <HighchartsReact
        highcharts={Highcharts}
        options={makeState(state)}
        callback={(chart) => {
          if (selectedId !== '') {
            const maybe = chart.get(selectedId);

            return !!maybe && maybe.select(true, false);
          }
        }}
      />
    </>
  );
};

Scatter.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  handleSeriesClick: PropTypes.func.isRequired,
  dimensions: PropTypes.shape({
    height: PropTypes.number,
  }).isRequired,
  selectedId: PropTypes.string.isRequired,
};

export default Scatter;

const makeState = ({
  data,
  showLabels,
  onSeriesClick,
  metric,
}) => ({
  series: [
    {
      data: data.map((adset) => ({
        x: adset.spend,
        y: adset[metric],
        label: adset.value,
        id: adset.value,
        percent_difference: parseFloat(adset.percent_difference),
      })),
      point: {
        events: {
          click(e) {
            return onSeriesClick({ category: this.label });
          },
        },
      },
      allowPointSelect: true,
      states: {
        select: {
          color: DARWIN_RED,
        },
      },
    },
  ],
  title: {
    text: '',
  },
  xAxis: {
    title: {
      enabled: true,
      text: 'Spend',
      style: {
        fontSize: '1.1em',
        transform: 'translateY(5px)',
      },
    },
    labels: {
      style: {
        fontSize: '1em',
        color: DARWIN_NAVY,
      },
    },
  },
  yAxis: {
    title: {
      enabled: true,
      text: metric.toUpperCase(),
      style: {
        fontSize: '1.1em',
      }
    },
    labels: {
      style: {
        fontSize: '1em',
        color: DARWIN_NAVY,
      }
    }
  },
  chart: {
    type: 'scatter',
    backgroundColor: 'white',
    height: (9 / 16) * 100 + '%',
    events: {
      load: function () {
        const chart = this,
          data = chart.series[0].data;

        data.map((element) => {
          if (element.percent_difference <= 0) {
            element.update({
              radius: 5,
              color: '#E3264190',
              lineWidth: 1,
              marker: {
                radius: 5,
                states: {
                  select: {
                    fillColor: '#E3264190',
                    lineColor: '#E32641',
                  },
                  normal: {
                    fillColor: '#E3264190',
                    lineColor: '#E3264199',
                  },
                  hover: {
                    fillColor: '#E3264190',
                    lineColor: '#E32641',
                    radius: 7,
                  },
                },
              },
            });
          }
        });
      },
    },
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    scatter: {
      tooltip: {
        headerFormat: '',
        pointFormatter() {
          const opts = this.options;
          return `
            <b>${opts.label}</b>
            <br />
            Spend: ${toCurrency(opts.x)}
            <br />
            ${metric.toUpperCase()}: ${
              metric !== 'roas' ? '$' : ''
            }${opts.y.toFixed(2)}
          `;
        },
      },
    },
    series: {
      allowPointSelect: true,
      marker: {
        radius: 5,
        color: '#039BE590',
        lineWidth: 1,
        states: {
          select: {
            fillColor: '#039BE590',
            lineColor: DARWIN_BLUE,
            radius: 8,
          },
          normal: {
            fillColor: '#039BE590',
            lineColor: '#039BE5',
          },
          hover: {
            fillColor: '#039BE590',
            lineColor: DARWIN_BLUE,
            radius: 7,
          },
        },
      },
      dataLabels: {
        enabled: showLabels,
        formatter() {
          return this.point.label.slice(0, 20);
        },
        x: 15,
        y: -10,
      },
      scatter: {
        marker: {
          radius: 5,
        },
      },

      className: 'ui-notify-point',
    },
  },
});
