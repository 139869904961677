import React from 'react';

type DrawerButtonsWrapper = {
  show?: boolean;
  children: React.ReactChildren;
}

export const DrawerButtonsWrapper = ({ show = true, children }) => {
  if (!show) return null;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {children}
    </div>
  );
};
