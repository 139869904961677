import { DebugDataItem } from '../types/types';

export const exportToCSV = (
  rows,
  exportFileName,
  excludeColumns = [],
  isROAS = false
) => {
  const formatCSVField = (value: any): string => {
    if (value === null || value === undefined) {
      return '';
    }

    if (typeof value === 'boolean') {
      return value ? 'true' : 'false';
    }

    if (typeof value === 'number') {
      return value.toString();
    }

    const stringValue = JSON.stringify(value).replace(/(^"|"$)/g, '');

    if (/[",\n\r]/.test(stringValue)) {
      return `"${stringValue.replace(/"/g, '""')}"`;
    }

    return stringValue;
  };

  const exportHeaders = isROAS
    ? [
        { label: 'Ad Name', key: 'ad_name' },
        { label: 'S3 URL', key: 's3_url' },
        { label: 'Ad Name Group', key: 'ad_name_group' },
        { label: 'Total Spend', key: 'total_spend' },
        { label: 'Total Revenue', key: 'total_revenue' },
        { label: 'ROAS', key: 'total_roas' },
        { label: 'Has Chosen Label', key: 'has_chosen_label' },
        { label: 'Has Comparison Label', key: 'has_comparison_label' },
      ]
    : [
        { label: 'Ad Name', key: 'ad_name' },
        { label: 'S3 URL', key: 's3_url' },
        { label: 'Ad Name Group', key: 'ad_name_group' },
        { label: 'Total Spend', key: 'total_spend' },
        { label: 'Total Results', key: 'total_results' },
        { label: 'Total Link Clicks', key: 'total_link_clicks' },
        { label: 'Total CPA', key: 'total_cpa' },
        { label: 'Total CPC', key: 'total_cpc' },
        { label: 'Has Chosen Label', key: 'has_chosen_label' },
        { label: 'Has Comparison Label', key: 'has_comparison_label' },
        { label: 'CVR', key: 'CVR' },
      ];

  const filteredHeaders = exportHeaders.filter(
    (col) => !excludeColumns.includes(col.label)
  );
  const headers = filteredHeaders
    .map((col) => formatCSVField(col.label))
    .join(',');

  const rowsData = rows.map((row) => {
    return filteredHeaders
      .map((col) => formatCSVField(row.original[col.key]))
      .join(',');
  });

  const csvData = [headers, ...rowsData].join('\n');

  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${exportFileName}.csv`);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const downloadDebugDataToCSV = (data: DebugDataItem[]) => {
  const processedData = data.map((item) => ({
    ...item,
    ad_name_groups: item.ad_name_groups.join(', '),
    Impressions: item.Impressions || '0',
    'Link Clicks': item['Link Clicks'] || 0,
    Spend: item.Spend || 0,
    Rank: item.Rank || 0,
    line_item_cpa: item.line_item_cpa || 0,
    adset_cpa: item.adset_cpa || 0,
  }));

  const headers = (
    Object.keys(processedData[0]) as (keyof DebugDataItem)[]
  ).filter((key) => key !== 'all_labels');

  const csvContent = [
    headers.join(','),
    ...processedData.map((item) =>
      headers
        .map((header) => `"${String(item[header]).replace(/"/g, '""')}"`)
        .join(',')
    ),
  ].join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', 'debug_data.csv');
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
