import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function usePrimaryColor(adName) {
  return useSelector(({ RunningAdsByName: { ads } }) => {
    const maybe = ads[adName] || {};

    return maybe.primary_color || '';
  });
}

export function PrimaryColor({ adName }) {
  const color = usePrimaryColor(adName);

  if (!color) {
    return <h4>Primary color not available</h4>;
  }
  return (
    <div className="primary-color" title="Primary Color">
      <p className="mr-1 my-0">Primary Color</p>
      <ColorSwatch color={color} />
      <p className="ml-2 my-0">
        <strong>{color}</strong>
      </p>
    </div>
  );
}

PrimaryColor.propTypes = {
  adName: PropTypes.string.isRequired,
};

function ColorSwatch({ color }) {
  return (
    <div
      className="color-swatch ml-2"
      style={{
        backgroundColor: color,
        borderRadius: '3px',
        height: '2rem',
        width: '2rem',
        marginTop: '0.15rem',
      }}
    />
  );
}

ColorSwatch.propTypes = {
  color: PropTypes.string.isRequired,
};
