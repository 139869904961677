import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TagsList, Tag } from '../../components/Tags';
import Tooltip from '../../components/Tooltip';
import Button from '../../components/Button';
import Overlay from '../../components/Overlay';
import EditTest from './EditTest';
import { toArray, withoutPrefix, parseCurrency } from './utils';
import { GoogleAdsIcon, DirectMailIcon, TikTokIcon } from '../../components/Icons';
import { useAccountPlatformObject, useDarwinClientId, useAccountId } from './selectors';

import {
  useCreativeTestingApi,
  useBlocksApi,
  useBlockStatisticsApi,
} from './api';

import './DarwinTesting.scss';
import TestResults from './TestResults';
import StatSummary from './StatSummary';
import { EfficiencyIcon } from '../../components/Icons';
import { darwinDateToDate } from '../../utils/darwin-dates';

import { isEqual } from 'lodash';
import DirectMailResults from './DirectMailResults';
import Analyse from 'chi-square-p-value';
import { trackEvent } from '../../store/UserActivity/actions';
import { CREATIVE_TESTING, LOADING } from '../../store/UserActivity/constants';

export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => isMounted.current = false;
  }, []);

  return isMounted;
}

const DarwinTesting = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [existing, setExisting] = useState(null);
  const [tests, setTests] = useState([]);
  const [selected, setSelected] = useState(null);
  const [showRail, setShowRail] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [pValue, setPValue] = useState(0);

  const [stats, setStats] = useState({});
  const [conclusion, setConclusion] = useState("");
  const [reason, setReason] = useState("");
  const account_id = useAccountId();
  const darwin_client_id = useDarwinClientId();
  const api = useCreativeTestingApi();
  const blocksApi = useBlocksApi();
  const statsApi = useBlockStatisticsApi();
  const platforms = useAccountPlatformObject();
  
  const isQuickenLoans = account_id === '965561022345767';
  const isIdealImage = account_id === '1478523699632587';
  const isHavasEDF = account_id === '3838383838383838383';

  const fetchTests = () => {
    api.get.request(withoutPrefix(account_id));
    setSelected(null);
  };

  const mergeAndFormatSheetsTests = (apiData) => {
    if (apiData.length) {
      let grouped = _.mapValues(_.groupBy(apiData, 'test_name'));
      let tests = [];
      Object.keys(grouped).forEach((key) => {
        let new_test = {
          account_id: account_id,
          platform: 'direct mail',
          compare_block_category: '', //nothing in sheet
          test_block_category: '', //nothing in sheet
          darwin_client_id: darwin_client_id,
          test_name: key,
          test_description: key,
        }
        for (let i=0; i < grouped[key].length; i++) {
          if (i == 0) {
            new_test.test_block_id = grouped[key][i]['block_name']
            new_test.test_block_name = grouped[key][i]['block_name'];
            new_test.test_block_description = grouped[key][i]['test_description'];
            new_test.start_date = grouped[key][i]['start_date'];
            new_test.end_date = grouped[key][i]['end_date'];
            new_test.test_block_results = grouped[key][i]['results'];
            new_test.test_block_cpa = grouped[key][i]['cpa'];
            new_test.test_block_spend = grouped[key][i]['spend'];
            new_test.test_block_ctr = grouped[key][i]['ctr'];
          } else if (i == 1) {
            new_test.compare_block_id = grouped[key][i]['block_name']
            new_test.compare_block_name = grouped[key][i]['block_name'];
            new_test.compare_block_description = grouped[key][i]['block_description'];
            new_test.compare_block_results = grouped[key][i]['results'];
            new_test.compare_block_cpa = grouped[key][i]['cpa'];
            new_test.compare_block_spend = grouped[key][i]['spend'];
            new_test.compare_block_ctr = grouped[key][i]['ctr'];
          } else if (i == 2) {
            new_test.add_block_id = grouped[key][i]['block_name'];
            new_test.add_block_name = grouped[key][i]['block_name'];
            new_test.add_block_description = grouped[key][i]['block_description'];
          }
          else if (i == 3) {
            new_test.addl_block_id = grouped[key][i]['block_name'];
            new_test.addl_block_name = grouped[key][i]['block_name'];
            new_test.addl_block_description = grouped[key][i]['block_description'];
          }
        }
        tests.push(new_test);
      });
      
      return tests;
    } else {
      return [];
    }
  };

  useEffect(() => {
    fetchTests();
    dispatch(trackEvent(LOADING, CREATIVE_TESTING));
  }, []);

  const [apiData, setApiData] = useState([]);

  const _ = require("lodash");

  function keysToLowerCase(obj){
    Object.keys(obj).forEach(function (key) {
        let k = key.trim();
        k = k.toLowerCase().replace(/ /g,'_');

        if (k !== key) {
            obj[k] = obj[key];
            delete obj[key];
        }
    });
    return (obj);
  }

  const quickenSheet = '17j5KuIrfZDFB1OmMxyS16LjTBYlXwrMwW2OXwHACk9g';
  const idealSheet = '1HdLcgbnOgcYlrMJXx06udmy0Jl6UOK5ltfAR9WKojCw';
  const havasSheet = '1JJFKeka4k4I52xwfHzpRyayrhtQcdkHyoUaEf04TmaY';

  // ---- opensheet api
  useEffect(() => {
    async function fetchData(sheet_id) {
      fetch(
        `https://opensheet.elk.sh/${sheet_id}/1`
      )
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          data.map(item =>  {
            return {...item, account_id: parseInt(account_id) }
          });
        
          data.forEach((row) => {
            keysToLowerCase(row);
          });

          setApiData(data);
        }
      });
    }
    if (isQuickenLoans) {
      fetchData(quickenSheet);
    }
    if (isIdealImage) {
      fetchData(idealSheet);
    }
    if (isHavasEDF) {
      fetchData(havasSheet);
    }
  }, []);

  useEffect(() => {
    if (api.get.data) {
      const { data } = api.get;
      setTests(data.tests);
    }
  }, [api.get.data]);


  useEffect(() => {
    if (api.get.data && apiData) {
      const { data } = api.get;
      setTests(data.tests);

      if (apiData) {
        const formattedApiData = mergeAndFormatSheetsTests(apiData);
        const merged = data.tests.concat(formattedApiData);

        setTests(merged);
      }
    }
  }, [api.get.data, apiData]);

  // stats error
  useEffect(() => {
    if (statsApi.post.error) {
      console.log(statsApi.post.error.error);
      setInfoText("Oops, there was an error.");
    }
  }, [statsApi.post.error]);

  // stats loading
  useEffect(() => {
    if (statsApi.post.loading) {
      setInfoText("Fetching Performance Data");
    }
  }, [statsApi.post.error]);

  const isMounted = useIsMounted();

  const fetchStats = useCallback(() => {
    if (isMounted.current) {
      if (statsApi.post.data) {
        let { data } = statsApi.post.data;
        const data_parsed = JSON.parse(data.body);

        if (data_parsed) {
          setStats(data_parsed);
          setInfoText("");
          console.log(data_parsed);
        }
      }
    }
  }, [statsApi.post.data]);

  useEffect(() => {
    fetchStats();
  }, [fetchStats]);

  const element = document.getElementById("right-rail");

  const handleSelectTest = (test) => {
    setSelected(() => {
      if (_.isEqual(test, selected)) {
        // hide rail when user deselects test
        if (showRail) handleShowRail();
        element.classList.add("hidden");
        return null;
      } else if (!_.isEqual(test, selected)) {
        // hide rail when user selects different test
        if (showRail) handleShowRail();
        element.classList.add("hidden");
        setInfoText("");
      }
      return test;
    });
  };

  if (api.get.loading || blocksApi.get.loading) {
    return <div className="dw-testing"><p className="info-text loading page">Loading</p></div>;
  }

  const TestTooltip = ({ text, start, end }) => {
    const startDate = darwinDateToDate(start);
    const endDate = darwinDateToDate(end);

    return (
      <div className="test-tooltip">
        <p>{text}</p>
        <p style={{color: "#B6E7FF"}}>{startDate.toLocaleDateString()} - {endDate.toLocaleDateString()}</p>
      </div>  
    )
  }

  const Header = (
    <div className="dw-testing__header">
      <div>
        <div className="flex flex-row justify-content-start">
          <h1 className="dw-heading">Darwin Testing</h1>
          {!isQuickenLoans && !isIdealImage && (
            <button
              type="button"
              className="btn btn-link btn-create"
              style={{ padding: 0 }}
              onClick={() => setShowModal((prev) => !prev)}
            >
              <i className="fa fa-plus-circle" /> Create New Test
            </button>
          )}
        </div>
        {tests.length ? (
          <div className="dw-testing__tags">
            <TagsList>
              {tests.map((test) => (
                <div className="test-tag">
                  <Tooltip content={(<TestTooltip text={test.test_description} start={test.start_date} end={test.end_date}/>)}>
                    <div>
                    <Tag
                      active={_.isEqual(test, selected)}
                      onClick={() => handleSelectTest(test)}
                      key={test.test_name}
                    >
                      {test.platform === 'facebook' && (
                        <span className="dw-tag__icon dw-tag__icon--fb">
                          <i className="fa-brands fa-facebook-f"></i>
                        </span>
                      )}
                      {test.platform === 'google_ads' && (
                        <GoogleAdsIcon className="mr-2" />
                      )}
                      {test.platform === 'tiktok' && (
                        <TikTokIcon className="mr-2" />
                      )}
                      {test.platform === 'direct mail' && (
                        <DirectMailIcon className="mr-2" />
                      )}
                      {test.test_name}
                    </Tag>
                    </div>
                    
                  </Tooltip>
                </div>
              ))}
          </TagsList>
          </div>
        ) : (
          <p>No tests found.</p>
        )}
      </div>
    </div>
  );

  const handleEditTest = (test) => {
    setExisting(test);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setExisting(null);
  };

  // get payload for statsApi
  const getPayload = (account_id) => {
    const { start_date, end_date, test_block_id, compare_block_id } = selected;

    return {
      account_id,
      start_date,
      end_date,
      test_block_id,
      compare_block_id
    };
  };

  const handleChiSqTest = () => {
    const test_spend = parseCurrency(selected.test_block_spend);
    const compare_spend = parseCurrency(selected.compare_block_spend);
    const resultsArr = [selected.test_block_results, selected.compare_block_results];
    const spendArr = [test_spend/58, compare_spend/58]; // spend/cost of stamp
    
    const contingencyTable = [
      resultsArr,
      spendArr,
    ];
    
    const results = Analyse(contingencyTable); // { chi, df, pValue, residual }
    const p_value = parseFloat(results.pValue);
    setPValue(p_value.toFixed(2));
  }

  const handleShowRail = (prev) => {
    if ((showRail) || (selected === null)) {
      setShowRail(prev => !prev);
      setInfoText("");
      element.classList.add("hidden");
    }
    else {
      setShowRail(prev => !prev);
      setInfoText("");
      element.classList.remove("hidden");
      if (selected.platform !== 'direct mail') {
        const payload = getPayload(account_id);
        statsApi.post.request(payload);
        fetchStats();
      }
      else if (selected.platform === 'direct mail') {
        handleChiSqTest();
      }
    }
  };

  return (
    <>
      <Overlay show={showModal}>
        <EditTest
          existing={existing}
          handleClose={handleCloseModal}
          account_id={account_id}
          refreshData={fetchTests}
          platforms={platforms}
          darwin_client_id={darwin_client_id}
        />
      </Overlay>
      <div className="row">
        <div className="dw-testing col-12 pb-0">{Header}</div>
      </div>
      <div className="row">
        <div className="dw-testing results-col col-7">
          <TestResults test={selected} handleEditTest={handleEditTest} handleShowRail={handleShowRail} showRail={showRail} isIdealImage={isIdealImage} />
        </div>
        <div id="right-rail" className="dw-testing stats-col col-5 pl-1 hidden">
          {!!selected && (
            <div>
              <div className={showRail && !statsApi.post.loading ? "darwins-insight" : "darwins-insight hidden"}>
                <img src={require('./darwinsinsight.svg')} />
              </div>
              <div className="stats-container">
                <div className="stats-wrapper">
                  {
                    statsApi.post.loading
                    ? <p id="info-text" className="info-text"><span className="info-text loading">Fetching Performance Data</span><br />
                      {selected.platform !== 'direct mail' && (
                        <span className="info-text info-details">To perform this calculation, Darwin is pulling <u>real time</u> data from the platform(s) at the <u>hourly</u> level of granularity before running statistical significance tests. This operation could take some time to complete. This is currently a BETA feature and could error out if pulling too much data.</span>
                      )}
                      </p>
                    : statsApi.post.error
                    ? <p id="info-text" className="info-text">{infoText}</p>
                    : !showRail
                    ? <p id="info-text" className="info-text hidden"></p>
                    : null
                  }
                  {selected.platform !== 'direct mail' &&
                    (Object.keys(stats).length && !statsApi.post.error) ? (
                      <StatSummary
                        show={showRail}
                        loading={statsApi.post.loading}
                        conclusion={conclusion}
                        reason={reason}
                        test={selected.test_name}
                        stats={stats}
                      />
                  ) : null}
                  {selected.platform === 'direct mail' ?
                    (
                      <DirectMailResults
                        show={showRail}
                        apiData={apiData}
                        pValue={pValue}
                        selected={selected}
                        isIdealImage={isIdealImage}
                      />
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DarwinTesting;
