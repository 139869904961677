import React, { useRef, useState } from 'react';
import { PeriodPicker } from '../PerformanceFrequency/Pickers';
import { useCreativeAnalyticsGet } from '../contexts';
import { useViewSettings } from '../../../selectors';
import Button from '../../../components/Button';
import { TOGGLE_STYLE } from './Header';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { MinResultsPicker } from '../../../components/Pickers/MinResults';
import { PrimaryMetricPicker } from '../../../components/Pickers/PrimaryMetric';
import CustomDropdown from '../../../components/CustomDropdown';
import { options } from 'joi';

const DropdownItem = ({ label = null, children }) => {
  return (
    <div className="header-dropdown-item">
      {!!label && <label>{label}</label>}
      {children}
    </div>
  );
};

const Dropdown = ({
  show,
  setShow,
  GroupByRatioToggle,
  showActiveOnly,
  setShowActiveOnly,
  creativesOpts,
  filteredData,
}) => {
  if (!show) return null;
  const { isOrganicView, isROAS } = useViewSettings();
  const ref = useRef();
  useClickOutside(ref, () => setShow(false));

  return (
    <div className="header-dropdown" ref={ref}>
      <>
        {filteredData.length > 0 && (
          <>
            <DropdownItem label="Primary Metric">
              <PrimaryMetricPicker
                toggleClass="analytics__picker analytics__picker--btn darwin-button darwin-button--raised"
                toggleStyle={{ ...TOGGLE_STYLE, padding: '0.4rem 1rem' }}
                menuClass="primary-metric-dropdown"
              />
            </DropdownItem>

            <DropdownItem label="Group by ad name">
              {!isOrganicView && (
                <div className="mr-2">{GroupByRatioToggle}</div>
              )}
            </DropdownItem>

            {!isROAS && !isOrganicView && (
              <DropdownItem label="Min Results">
                <MinResultsPicker
                  toggleClass="analytics__picker analytics__picker--btn darwin-button darwin-button--raised"
                  toggleStyle={{ ...TOGGLE_STYLE, padding: '0.4rem 1rem' }}
                />
              </DropdownItem>
            )}
          </>
        )}

        <DropdownItem label="Creatives">
          <CustomDropdown
            onSelect={(selected) => {
              setShowActiveOnly(selected === 'active');
            }}
            label={
              showActiveOnly ? creativesOpts[1].label : creativesOpts[0].label
            }
            toggleClass="analytics__picker analytics__picker--btn darwin-button darwin-button--raised"
            toggleStyle={{ ...TOGGLE_STYLE, padding: '0.4rem 1rem' }}
          >
            {creativesOpts
              .filter((opt) => !opt.disable)
              .map(({ value, label }) => (
                <CustomDropdown.Item
                  key={label}
                  eventKey={value}
                  className="py-2"
                >
                  {label}
                </CustomDropdown.Item>
              ))}
          </CustomDropdown>
        </DropdownItem>
      </>
      {/* )} */}
    </div>
  );
};

export const AdvancedOptions = ({
  GroupByRatioToggle,
  showActiveOnly,
  setShowActiveOnly,
  creativesOpts,
  filteredData,
}) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <div className="advanced-opts">
      <Button
        className="advanced-opts-toggle"
        onClick={() => setShow((prev) => !prev)}
        active={show}
        appearance="raised"
      >
        Advanced
      </Button>
      <Dropdown
        {...{
          show,
          setShow,
          GroupByRatioToggle,
          showActiveOnly,
          setShowActiveOnly,
          creativesOpts,
          filteredData,
        }}
      />
    </div>
  );
};
