
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import RGL, { WidthProvider } from 'react-grid-layout';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { connect } from 'react-redux';
import { debounce } from '../../../../utils';
import { setViewSettings } from '../../../../store/ViewSettings/actions';

const ReactGridLayout = WidthProvider(RGL);

export const Grid = ({ children, ...props }) => (
  <ReactGridLayout cols={12} rowHeight={45} {...props}>
    {children}
  </ReactGridLayout>
);

const Dashboard = ({ children, dashboardItems, setConfig }) => {
  const mounted = useRef(false);
  const configLookup = dashboardItems.reduce((acc, item) => {
    acc[item.id] = item;

    return acc;
  }, Object.create(null));

  /**
   * @typedef {{ h: Number, w: Number, x: Number, y: Number, i: String}} Layout
   * @param {[Layout]} newLayout
   */
  const onLayoutChange = debounce((newLayout) => {
    const updates = [];

    for (const {
      i, x, y, w, h,
    } of newLayout) {
      const item = configLookup[i];
      const update = {
        x, y, w, h,
      };

      if (item && !sameCoordinates(item.layout || {}, update)) {
        updates.push({ ...item, layout: update });
      } else {
        updates.push(item);
      }
    }

    if (mounted.current) {
      updates.length > 0 && setConfig(updates);
    }
  }, 2000);

  // Memory leak when clicking off the current
  // dashboard page.
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    }

    return () => (mounted.current = false);
  }, []);

  return <Grid onLayoutChange={onLayoutChange}>{children}</Grid>;
};

Dashboard.propTypes = {
  setConfig: PropTypes.func,
  dashboardItems: PropTypes.array,
};

export default connect(null, (dispatch) => ({
  setConfig: (updates) => {
    dispatch(
      setViewSettings({ dashboardConfig: updates, updateDashboardConfig: true }),
    );
  },
}))(Dashboard);

function sameCoordinates(obj1, obj2) {
  return (
    obj1.x === obj2.x
    && obj1.y === obj2.y
    && obj1.w === obj2.w
    && obj1.h === obj2.h
  );
}
