import { labelsAreMatching } from "./utils";

export const SELECT_LABEL = 'select-label';
export const DESELECT_LABEL = 'deselect-label';
export const ADD_LABEL = 'add-label';
export const REMOVE_LABEL = 'remove-label';
export const SELECT_AD_NAME = 'select-ad-name';
export const DESELECT_AD_NAME = 'deselect-ad-name';
export const ADD_AD_NAME = 'add-ad-name';
export const REMOVE_AD_NAME = 'remove-ad-name';


export function reducer(state, action) {
  switch (action.type) {
    case SELECT_LABEL:
      return {
        selectedLabels: [...state.selectedLabels, action.label],
        labels: state.labels,
        selectedAdNames: [...state.selectedAdNames],
        adNames: state.adNames,
      };

    case DESELECT_LABEL:
      return {
        selectedLabels: state.selectedLabels.filter((label) => !labelsAreMatching(label, action.label)),
        labels: state.labels,
        selectedAdNames: [...state.selectedAdNames],
        adNames: state.adNames,
      }

    case REMOVE_LABEL:
      return {
        selectedLabels: state.selectedLabels.filter((label) => !labelsAreMatching(label, action.label)),
        labels: state.labels.filter((label) => !labelsAreMatching(label, action.label)),
        selectedAdNames: [...state.selectedAdNames],
        adNames: state.adNames,
      };

    case ADD_LABEL: {
      return {
        selectedLabels: [...state.selectedLabels, action.label],
        labels: [...state.labels, { ...action.label }],
        selectedAdNames: [...state.selectedAdNames],
        adNames: state.adNames,
      };
    }

    case SELECT_AD_NAME:
      return {
        selectedLabels: [...state.selectedLabels],
        labels: [...state.labels],
        selectedAdNames: [...state.selectedAdNames, action.label],
        adNames: state.adNames,
      };

    case DESELECT_AD_NAME:
      return {
        selectedLabels: [...state.selectedLabels],
        labels: [...state.labels],
        selectedAdNames: state.selectedAdNames.filter((label) => !labelsAreMatching(label, action.label)),
        adNames: state.adNames,
      }

    case REMOVE_AD_NAME:
      return {
        selectedLabels: [...state.selectedLabels],
        labels: [...state.labels],
        selectedAdNames: state.selectedAdNames.filter((label) => !labelsAreMatching(label, action.label)),
        adNames: state.adNames.filter((label) => !labelsAreMatching(label, action.label)),
      };

    case ADD_AD_NAME: {
      return {
        selectedLabels: [...state.selectedLabels],
        labels: [...state.labels],
        selectedAdNames: [...state.selectedAdNames, action.label],
        adNames: [...state.adNames, { ...action.label }],
      };
    }

    default:
      return state;
  }
}