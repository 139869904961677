/* eslint-disable arrow-body-style */
import * as ss from 'simple-statistics';
import {
  TESTING_OPPORTUNITY_CATEGORIES,
  FORBIDDEN_TESTING_OPPORTUNITIES,
  KEY_CATEGORIES,
} from './categories';
import { DARWIN_INDEX_LOOKUP } from './constants';
import {
  useGetAggregate,
  useGetAverage,
  getTotalBudget,
  getPercentBudget,
  getAvgLabelCPA,
  useGetMedian,
  useGetTotalResults,
  useGetPercentResults,
  useGetPercentResultsDiff,
  getSpend,
} from './utils';

export const isNewAd = (ad, newAds) => newAds.some((newAd) => newAd === ad.id);

export const notInData = (label, data) =>
  !data.some((obj) => obj.value === label.value);

export const isThemeOrAttribute = (label) => {
  if (!label.category) return false;
  return (
    label.category.toLowerCase().includes('theme') ||
    label.category.toLowerCase().includes('attribute')
  );
};

export const shouldAddTestingOpportunity = (label, data, filterProps) => {
  if (
    label.value.includes('CAROUSEL+') &&
    filterProps.selectedLabels.some((x) => x.value === 'IMAGE+')
  ) {
    return false;
  }

  const { creativeCount } = filterProps;
  const isTestingOpportunity =
    TESTING_OPPORTUNITY_CATEGORIES.includes(label.category) ||
    label.compliance ||
    label.type === 'client_custom_category' ||
    label.type === 'user_defined_labels' ||
    label.type === 'derived_label' ||
    label.type === 'Ad Name Label' ||
    label.type === 'ad_name_label';

  const isStandardLabel = !label.winners;
  const visuals = !isStandardLabel
    ? getFilteredVisuals(label, filterProps).visuals
    : [];
  const isFilteredOut = isStandardLabel
    ? notInData(label, data)
    : visuals.length < creativeCount;

  const isFormatNoVisuals = data
    .filter((obj) => ['IMAGE+', 'CAROUSEL+', 'VIDEO+'].includes(obj.value))
    .some(
      (obj) =>
        obj.value === label.value &&
        obj.winners.length === 0 &&
        obj.losers.length === 0
    );

  if (
    (isTestingOpportunity &&
      isFilteredOut &&
      !FORBIDDEN_TESTING_OPPORTUNITIES.includes(label.value)) ||
    isFormatNoVisuals
  ) {
    return true;
  }
  return false;
};

export const getPossibleTestingLabels = (standardLabels, data) => [
  ...standardLabels,
  ...data.filter(
    (label) =>
      label.compliance ||
      label.type === 'client_custom_category' ||
      label.type === 'user_defined_labels' ||
      label.type === 'derived_label' ||
      label.type === 'derived_category'
  ),
];

/**
 * key: visual hash
 * value: list of label objects with { value, category }
 * @returns
 */

const checkIfVisualIsSelected = (selectedLabels, square) => {
  return selectedLabels.every((label) => {
    return label.visuals.some((visual) => visual.id === square.id);
  });
};

export const updateSquares = (squares, filterProps, allVisualsList) => {
  const {
    spendThreshold,
    only_new_ads,
    newAds,
    selectedLabels,
    filteredUdcAdNames,
    minResults,
    primary_metric,
  } = filterProps;

  return squares.reduce((acc, square) => {
    const meetsThresholdCriteria = square.spend >= spendThreshold;
    const meetsNewAdCriteria = !only_new_ads || isNewAd(square, newAds);
    const meetsFilteringCriteria =
      !selectedLabels.length || checkIfVisualIsSelected(selectedLabels, square);
    const meetsResultsCriteria = square.results >= parseInt(minResults);
    let metric = primary_metric?.includes('cp_')
      ? primary_metric.replace('cp_', '')
      : null;
    const meetsPrimaryMetricCriteria =
      !metric || square[metric] >= parseInt(minResults);

    // if (square.id === '208249642704302') {
    //   console.log({ square, metric, meetsPrimaryMetricCriteria })
    //   console.log(parseInt(minResults))
    // }

    if (
      meetsThresholdCriteria &&
      meetsNewAdCriteria &&
      meetsFilteringCriteria &&
      meetsResultsCriteria &&
      meetsPrimaryMetricCriteria
    ) {
      acc.push(square);
    }

    return acc;
  }, []);
};

export const getFilteredVisuals = (label, filterProps, allVisualsList) => {
  const updatedWinners = updateSquares(
    label.winners,
    filterProps,
    allVisualsList
  );
  const updatedLosers = updateSquares(
    label.losers,
    filterProps,
    allVisualsList
  );

  return {
    updatedWinners,
    updatedLosers,
    visuals: [...updatedWinners, ...updatedLosers],
  };
};

/**
 * Changes label category or other fields based on certain conditions.
 * @param {Object} curr
 * @returns
 */
export const modifyLabel = (curr) => {
  let label = { ...curr };

  if (label.type === 'synonyms') {
    if (label.value.slice(0, 4) === 'CCC_') {
      label.name = label.value.split('_')[2];
      label.category = label.value.split('_')[1];
      label.synonym_type = 'CCC';
    }
    if (label.value.slice(0, 3) === 'DW-') {
      label.category = label.value.split('-')[1].trim();
      label.synonym_type = 'DW';
    }

    if (!label.category) label.category = 'Keyword';
  }
  if (label.type === 'custom_trained_label_settings') {
    label.category = 'Unique Client Attributes';
  }
  if (label.value === 'all_ads') label.category = 'All Ads';
  if (label.value === 'all_visuals' || label.value === 'new_visuals')
    label.category = 'All Visuals';

  if (label.value.includes('Top Pixel Color')) {
    label.category = 'Top Pixel Color';
  }

  if (label.value === 'CST_PROMO') {
    label.category = 'Promo / No Promo';
    label.name = 'Promo';
  }

  if (label.value === 'CST_NO_PROMO') {
    label.category = 'Promo / No Promo';
    label.name = 'No Promo';
  }

  if (label.value.includes('CSTPROMO')) {
    label.category = 'Promotion Type';
    label.name = label.value.split('_')[1];
  }

  if (label.value === 'CST_BRAND') {
    label.category = 'Brand / No Brand';
    label.name = 'Brand';
  }

  if (label.value === 'CST_NO_BRAND') {
    label.category = 'Brand / No Brand';
    label.name = 'No Brand';
  }

  if (label.type === 'Ad Name Label') {
    label.category = 'Ad Name Label';
  }

  return label;
};

export const shouldIncludeLabel = (label, categoryFilters) => {
  const {
    showOnlyComplianceCategories,
    showComplianceCategories,
    showAdditionalCategories,
    includeUdc,
  } = categoryFilters;
  if (label.type === 'synonyms') return false;
  if (showOnlyComplianceCategories) return label.compliance;
  if (!includeUdc) return !label.udc;

  const complianceCheck = showComplianceCategories || !label.compliance;

  if (complianceCheck) {
    return true;
  }

  return false;
};

export const findSynonymOthers = (label, allVisuals, totalBudget) => {
  if (!(label.synonym_type === 'DW')) return null;

  const label_winners = [...label.winners];
  const label_losers = [...label.losers];

  const all_winners = [...allVisuals.winners];
  const all_losers = [...allVisuals.losers];

  const otherWinners = all_winners.filter((visual) => {
    return !label_winners.find((x) => x.id === visual.id);
  });
  const otherLosers = all_losers.filter((visual) => {
    return !label_losers.find((x) => x.id === visual.id);
  });
  const otherVisuals = [...otherWinners, ...otherLosers];

  return {
    name: `Other (${label.name})`,
    value: `${label.value}_OTHER`,
    category: label.category,
    winners: otherWinners,
    losers: otherLosers,
    numerator: otherWinners.length,
    denominator: otherWinners.length + otherLosers.length,
    visuals: otherVisuals,
    synonym: true,
    visual_mode: label.visual_mode,
    client_down_funnel_events: label.client_down_funnel_events,
    percent_budget: getPercentBudget(otherVisuals, totalBudget),
    performance_percent: Math.round(
      (otherWinners.length / otherVisuals.length) * 100
    ),
    compliance: label.compliance,
    description: label.description,
    example: label.example,
  };
};

export const updateSynonymLabel = (
  synonymLabel,
  synUpdatedWinners,
  synUpdatedLosers,
  synUpdatedVisuals,
  totalBudget,
  totalResults,
  totalClicks,
  isROAS,
  curr
) => ({
  ...synonymLabel,
  category: synonymLabel.category,
  numerator: synUpdatedWinners.length,
  denominator: synUpdatedVisuals.length,
  performance_percent: Math.round(
    (synUpdatedWinners.length / synUpdatedVisuals.length) * 100
  ),
  aggregate_cpa: useGetAggregate(synUpdatedVisuals, 'cpa'),
  average_cpa: useGetAverage(synUpdatedVisuals, 'cpa'),
  median_cpa: useGetMedian(synUpdatedVisuals, 'cpa'),
  aggregate_roas: useGetAggregate(synUpdatedVisuals, 'roas'),
  average_roas: useGetAverage(synUpdatedVisuals, 'roas'),
  median_roas: useGetMedian(synUpdatedVisuals, 'roas'),
  total_results: useGetTotalResults(synUpdatedVisuals, 'results', isROAS),
  percent_of_results: useGetPercentResults(
    synUpdatedVisuals,
    'results',
    totalResults,
    isROAS
  ),
  percent_of_results_diff: useGetPercentResultsDiff(
    synUpdatedVisuals,
    totalResults,
    totalBudget,
    'results',
    isROAS
  ),
  aggregate_cpm: useGetAggregate(synUpdatedVisuals, 'cpm'),
  average_cpm: useGetAverage(synUpdatedVisuals, 'cpm'),
  median_cpm: useGetMedian(synUpdatedVisuals, 'cpm'),
  aggregate_cpc: useGetAggregate(synUpdatedVisuals, 'cpc'),
  average_cpc: useGetAverage(synUpdatedVisuals, 'cpc'),
  median_cpc: useGetMedian(synUpdatedVisuals, 'cpc'),
  aggregate_ctr: useGetAggregate(synUpdatedVisuals, 'ctr'),
  average_ctr: useGetAverage(synUpdatedVisuals, 'ctr'),
  median_ctr: useGetMedian(synUpdatedVisuals, 'ctr'),
  ga_aggregate_ctr: useGetAggregate(synUpdatedVisuals, 'ga_ctr'),
  ga_average_ctr: useGetAverage(synUpdatedVisuals, 'ga_ctr'),
  ga_median_ctr: useGetMedian(synUpdatedVisuals, 'ga_ctr'),
  total_link_clicks: useGetTotalResults(synUpdatedVisuals, 'clicks'),
  percent_of_link_clicks: useGetPercentResults(
    synUpdatedVisuals,
    'clicks',
    totalClicks
  ),
  percent_of_link_clicks_diff: useGetPercentResultsDiff(
    synUpdatedVisuals,
    'clicks',
    totalClicks,
    totalBudget
  ),
  number_unique_creatives: synUpdatedVisuals.length,
  percent_of_creative_count: 0,
  spend: getSpend(synUpdatedVisuals, 'spend'),
  winners: synUpdatedWinners,
  losers: synUpdatedLosers,
  percent_budget: getPercentBudget(synUpdatedVisuals, totalBudget),
  darwin_index: DARWIN_INDEX_LOOKUP[synonymLabel.name] || '-',
  visuals: synUpdatedVisuals,
  wins_over_all: `${synUpdatedWinners.length}/${synUpdatedVisuals.length}`,
  originalLabelData: curr,
});

function convertRange(value, r1, r2) {
  return ((value - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0]) + r2[0];
}

const findLabelQuantile = ({ first, second, third, fourth }, value, isROAS) => {
  if (!value || value === 0) return 1;

  if (value >= fourth) return isROAS ? 5 : 1;
  if (value < fourth && value >= third) return isROAS ? 4 : 2;
  if (value < third && value >= second) return isROAS ? 3 : 3;
  if (value < second && value >= first) return isROAS ? 2 : 4;
  if (value < first) return isROAS ? 1 : 5;
};

export const getCPAQuantile = (label, cpasList, isROAS) => {
  if (!cpasList || cpasList.length === 0) return 0;
  if (!cpasList || cpasList.length === 0) return 0;
  const metric = isROAS ? 'average_roas' : 'average_cpa';

  const first = ss.quantile(cpasList, 0.2);
  const second = ss.quantile(cpasList, 0.4);
  const third = ss.quantile(cpasList, 0.6);
  const fourth = ss.quantile(cpasList, 0.8);

  const cpa_quantile = findLabelQuantile(
    {
      first,
      second,
      third,
      fourth,
    },
    label[metric] || 0,
    isROAS
  );

  return cpa_quantile;
};

export const getCPAQuantileDebug = (label, cpasList, isROAS) => {
  if (!cpasList) return 0;
  const metric = isROAS ? 'average_roas' : 'average_cpa';

  const first = ss.quantile(cpasList, 0.2);
  const second = ss.quantile(cpasList, 0.4);
  const third = ss.quantile(cpasList, 0.6);
  const fourth = ss.quantile(cpasList, 0.8);

  const cpa_quantile = findLabelQuantile(
    {
      first,
      second,
      third,
      fourth,
    },
    label[metric] || 0,
    isROAS
  );

  return {
    cpa: label[metric] || 0,
    cpa_quantile,
    first,
    second,
    third,
    fourth,
  };
};

export const getPhraseTheme = (
  allVisuals,
  allVisualsList,
  filterProps,
  lastSavedPhraseThemes
) => {
  let phraseThemeLabels = [];

  if (lastSavedPhraseThemes.length) {
    const { visuals } = getFilteredVisuals(
      allVisuals,
      filterProps,
      allVisualsList
    );

    phraseThemeLabels = lastSavedPhraseThemes.map((theme) => {
      const themeVisuals = visuals.filter((visual) => {
        return theme.visual_ids?.includes(visual.id);
      });
      const themeWinners = allVisuals.winners.filter((visual) =>
        themeVisuals.some((n) => n.id === visual.id)
      );
      const themeLosers = allVisuals.losers.filter((visual) =>
        themeVisuals.some((n) => n.id === visual.id)
      );

      return {
        name: theme.phrase_theme,
        value: theme.phrase_theme,
        category: 'Phrase Theme',
        denominator: themeVisuals.length,
        numerator: themeWinners.length,
        winners: themeWinners,
        losers: themeLosers,
      };
    });
  }

  return phraseThemeLabels;
};


