import React from 'react';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from 'recharts';
import {
  toCurrency,
  toPercent,
  toPercentRounded,
} from '../../../utils/numbers';
import { ALL_VISUALS } from '../../CreativeAnalytics/categories';
import DarwinTooltip from '../../../components/Tooltip';

import './CircleChart.scss';

const COLORS = [
  '#4B48E9',
  '#FA64A9',
  '#02BCE8',
  '#FFBB55',
  '#6599FF',
  '#FF996D',
  '#22E292',
  '#FE7575',
];

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <ul className="legend">
      {payload.map((entry, index) => (
        <li className="legend__item" key={`item-${index}`}>
          <div className="legend__dot" style={{ background: entry.color }} />
          <div className="legend__value">
            <p>{entry.value}</p>
            <h3>{toPercentRounded(entry.payload.percent)}</h3>
          </div>
        </li>
      ))}
    </ul>
  );
};

const renderTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length && payload[0].payload) {
    return (
      <div className="dw-tooltip legend__tooltip" style={{ color: '#fff' }}>
        <p className="label" style={{ color: '#fff' }}>
          {payload[0].name}
        </p>
        <p className="label" style={{ color: '#fff' }}>{`Spend: ${toCurrency(
          payload[0].payload.spend
        )}`}</p>
      </div>
    );
  }

  return null;
};

const getName = (name) => {
  let result = name;

  switch (name) {

    case 'All New Visuals':
      result = 'New Visuals';
      break;
    default:
      return result;
  }

  return result;
};

const getValue = (label, metric) => {
  let result = label[metric];

  if (metric === 'percent_budget') {
    result = parseFloat(result) / 100.0;
    return result;
  }

  return result;
};

export const modifyLabels = (cardData, labels, metric) => {
  const { category } = cardData;

  // compare new visuals against non-new visuals instead of all visuals
  if (category === ALL_VISUALS && metric === 'spend') {
    const allVisuals = labels.find((x) => x.value === 'all_visuals');
    const newVisuals = labels.find((x) => x.value === 'new_visuals');

    if (!newVisuals) return [allVisuals];

    const otherVisuals = {
      name: 'Others',
      value: 'other_visuals',
      spend: allVisuals.spend - newVisuals.spend,
    }

    return [newVisuals, otherVisuals];
  }

  return labels;
}

const CircleChart = ({ cardData, metric, labels }) => {
  const modified = modifyLabels(cardData, labels, metric);

  const data = modified.map((label) => {
    return {
      name: getName(label.name),
      value: getValue(label, metric),
      spend: label.spend,
    };
  });

  return (
    <div className="circle-chart">
      {/* <ResponsiveContainer> */}
      <PieChart width={170} height={170}>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={32}
          outerRadius={50}
          fill="#82ca9d"
        >
          {data.map((entry, index) => (
            <Cell
              fill={COLORS[index % COLORS.length]}
              stroke="none"
              style={{
                filter: 'drop-shadow(4px 4px 0px rgba(96, 110, 145, 0.25))',
              }}
            />
          ))}
        </Pie>
        <Legend
          content={renderLegend}
          wrapperStyle={{
            marginTop: '10px',
            bottom: '-25px',
            whiteSpace: 'nowrap',
          }}
        />
        <Tooltip cursor={false} content={renderTooltip} wrapperStyle={{ top: 0, left: 150, right: -150 }} />
      </PieChart>
      {/* </ResponsiveContainer> */}
    </div>
  );
};

export default CircleChart;
