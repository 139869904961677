import React from "react";
import { DARWIN_NAVY } from "../../../../constants";

export const BlockIcon = () => {
  return (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.71 3.86313L7.21 0.863132C7.14418 0.832672 7.07253 0.816895 7 0.816895C6.92748 0.816895 6.85582 0.832672 6.79 0.863132L0.290001 3.86313C0.203211 3.9033 0.129761 3.96751 0.0783602 4.04816C0.0269594 4.1288 -0.000235112 4.2225 1.53137e-06 4.31813V12.8181C-0.000235112 12.9138 0.0269594 13.0075 0.0783602 13.0881C0.129761 13.1688 0.203211 13.233 0.290001 13.2731L6.79 16.2731C6.85582 16.3036 6.92748 16.3194 7 16.3194C7.07253 16.3194 7.14418 16.3036 7.21 16.2731L13.71 13.2731C13.7968 13.233 13.8702 13.1688 13.9216 13.0881C13.973 13.0075 14.0002 12.9138 14 12.8181V4.31813C14.0002 4.2225 13.973 4.1288 13.9216 4.04816C13.8702 3.96751 13.7968 3.9033 13.71 3.86313ZM7 6.76813L1.695 4.31813L7 1.86813L12.305 4.31813L7 6.76813ZM13 12.4981L7.5 15.0381V7.63813L13 5.09813V12.4981Z"
        fill={DARWIN_NAVY}
      />
    </svg>
  );
};
