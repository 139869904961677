import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  LabelObject,
  MissingDataType,
  RightDrawerOptions,
  VisualObject,
} from '../../features/CreativeAnalytics/types';
import { CategoriesLookup } from '../../features/CreativeAnalytics/ReportCard/types';
import { AnalyticsState } from './types';
import { createVertexApiCategory } from './thunks';
import { setErrorState } from './utils';
import { setLoadingState } from './utils';

export const initialState: AnalyticsState = {
  sort: 'spend',
  metricsData: [],
  data: [],
  filteredData: [],
  missingData: [],
  categoriesLookup: {},
  min: '0',
  recommendationsReady: false,
  dnaReportData: null,
  selectedVisuals: [],
  rightDrawerDisplay: null,
  bannerDisplay: null,
  metadata: null,
  metrics_only: true,
  pfrLoading: true,
  hiddenLabels: [],
  customCategoriesData: null,
  showQaEditor: false,
  phraseDataLookup: {},
  visuals: {
    ids: [],
    lookup: new Map(),
  },
  labels: {
    ids: [],
    lookup: new Map(),
  },
  presetsData: [],
  isSettingsOpen: false,
  navCategories: [],
  tableData: [],
  editCategory: null,
};

export const creativeAnalyticsSlice = createSlice({
  name: 'CreativeAnalytics',
  initialState,
  reducers: {
    resetAnalytics: () => initialState,
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    setMetricsData: (state, action) => {
      state.metricsData = action.payload;
    },
    setPerformanceData: (state, action) => {
      state.data = action.payload;
    },
    setFilteredPerformanceData: (state, action) => {
      state.filteredData = action.payload;
    },
    setMissingData: (state, action: PayloadAction<MissingDataType>) => {
      state.missingData = action.payload;
    },
    setCategoriesLookup: (state, action: PayloadAction<CategoriesLookup>) => {
      state.categoriesLookup = action.payload;
    },
    setMin: (state, action: PayloadAction<string>) => {
      state.min = action.payload;
    },
    setRecommendationsReady: (state, action: PayloadAction<boolean>) => {
      state.recommendationsReady = action.payload;
    },
    setDNAReportData: (state, action: PayloadAction) => {
      state.dnaReportData = action.payload;
    },
    setRightDrawerDisplay: (
      state,
      action: PayloadAction<RightDrawerOptions>
    ) => {
      state.rightDrawerDisplay = action.payload;
    },
    setBannerDisplay: (state, action) => {
      state.bannerDisplay = action.payload;
    },
    selectVisual: (state, action: PayloadAction<string>) => {
      state.selectedVisuals = [...state.selectedVisuals, action.payload];
    },
    deselectVisual: (state, action: PayloadAction<string>) => {
      state.selectedVisuals = [...state.selectedVisuals].filter(
        (id) => id !== action.payload
      );
    },
    setSelectedVisuals: (state, action: PayloadAction<string[]>) => {
      state.selectedVisuals = [...action.payload];
    },
    toggleSelectVisual: (state, action: PayloadAction<string>) => {
      if (state.selectedVisuals.includes(action.payload)) {
        state.selectedVisuals = [...state.selectedVisuals].filter(
          (id) => id !== action.payload
        );
      } else {
        state.selectedVisuals = [...state.selectedVisuals, action.payload];
      }
    },
    setMetadata: (state, action: PayloadAction<any>) => {
      state.metadata = action.payload;
    },
    setMetricsOnly: (state, action: PayloadAction<any>) => {
      state.metrics_only = action.payload;
    },
    setPfrLoading: (state, action: PayloadAction<any>) => {
      state.pfrLoading = action.payload;
    },
    setHiddenLabels: (state, action: PayloadAction<[]>) => {
      state.hiddenLabels = action.payload;
    },
    setCustomCategoriesData: (state, action: PayloadAction<any>) => {
      state.customCategoriesData = action.payload;
    },
    setShowQaEditor: (state, action: PayloadAction<any>) => {
      state.showQaEditor = action.payload;
    },
    addPhraseData: (
      state,
      action: PayloadAction<{ visual_hash: string; data: {} }>
    ) => {
      console.log({ state, action });
      state.phraseDataLookup = {
        ...state.phraseDataLookup,
        [action.payload.visual_hash]: action.payload.data,
      };
    },
    removePhraseDataForVisual: (state, action: PayloadAction<string>) => {
      const update = { ...state.phraseDataLookup };
      delete update[action.payload];

      state.phraseDataLookup = update;
    },
    setVisuals: (state, action: PayloadAction<any>) => {
      state.visuals = action.payload;
    },
    setLabels: (state, action: PayloadAction<any>) => {
      state.labels = action.payload;
    },
    setPresetsData: (state, action: PayloadAction<any>) => {
      state.presetsData = action.payload;
    },
    setIsSettingsOpen: (state, action: PayloadAction<boolean>) => {
      state.isSettingsOpen = action.payload;
    },
    setNavCategories: (state, action: PayloadAction<[]>) => {
      state.navCategories = action.payload;
    },
    setTableData: (state, action: PayloadAction<[]>) => {
      state.tableData = action.payload;
    },
    setEditCategory: (state, action: PayloadAction<any>) => {
      state.editCategory = action.payload;
    },
  },
});

export const {
  resetAnalytics,
  setSort,
  setMetricsData,
  setPerformanceData,
  setFilteredPerformanceData,
  setMissingData,
  setCategoriesLookup,
  setMin,
  setRecommendationsReady,
  setDNAReportData,
  selectVisual,
  deselectVisual,
  toggleSelectVisual,
  setRightDrawerDisplay,
  setBannerDisplay,
  setSelectedVisuals,
  setMetadata,
  setMetricsOnly,
  setPfrLoading,
  setHiddenLabels,
  setCustomCategoriesData,
  setShowQaEditor,
  addPhraseData,
  removePhraseDataForVisual,
  setVisuals,
  setLabels,
  setPresetsData,
  setIsSettingsOpen,
  setNavCategories,
  setTableData,
  setEditCategory,
} = creativeAnalyticsSlice.actions;

export default creativeAnalyticsSlice.reducer;
