import React, { createContext, useContext } from 'react';
import { CopilotAction, CopilotState, DataState } from '../types';
import { initialDataState } from '../constants';

export const CopilotContext = createContext<CopilotState>({
  copilotState: initialDataState,
  copilotDispatch: () => null,
});

interface Props {
  copilotState: DataState;
  copilotDispatch: React.Dispatch<CopilotAction>
  children: React.ReactNode;
}

const CopilotContextProvider = ({ copilotState, copilotDispatch, children }: Props) => {
  return (
    <CopilotContext.Provider value={{copilotState, copilotDispatch}}>
      {children}
    </CopilotContext.Provider>
  );
};

export default CopilotContextProvider;

export function useCopilotContext() {
  return useContext(CopilotContext);
}
