//@ts-check
import React, { useRef, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";

import { defaultLayout } from "./DashboardPage";
import { Grid } from "../components/Dashboard";
import DashboardItem from "../components/DashboardItem";
import ChartRenderer from "../components/ChartRenderer";

import { abortableFetch } from "../../../../utils";
import { useSearchParams } from "../../../../components/Hooks/search-params";
import {
  useApiState,
  bindFetchToRef,
} from "../../../../components/Hooks/useApiState";
import { BasicError } from "../../../../components/Helpers/Error";
import { BasicLoading } from "../../../../components/Helpers/Loading";

// Chart types - table, line, number

function DiagnosticPage({ viewName }) {
  const {
    params: { viewId },
  } = useRouteMatch("/:viewId/diagnostics");
  const { getParams } = useSearchParams();
  const { type } = getParams("type");
  const { loading, error, data, request } = useApi();

  useEffect(() => {
    if (viewName && type) {
      request({ viewId, viewName, type });
    }
  }, [viewName, type]);

  if(type === null){
    return <p>Choose a diagnostic</p>
  } else if (!!error) {
    return (
      <BasicError
        fallback={
          <>
            <h3>An error occurred</h3>
            <p>{JSON.stringify(error)}</p>
          </>
        }
      />
    );
  } else if (!!loading) {
    return <BasicLoading />;
  } else {
    const { dashboardConfiguration } = data;

    if (dashboardConfiguration.length === 0) {
      return <BasicError fallback={<p>Missing configuration</p>} />;
    } else {
      return (
        <Grid>
          {dashboardConfiguration.map((item) => (
            <div key={item.id} data-grid={defaultLayout(item)}>
              <DashboardItem key={item.id} title={item.name}>
                <ChartRenderer vizState={item.vizState} />
              </DashboardItem>
            </div>
          ))}
        </Grid>
      );
    }
  }
}

export default DiagnosticPage;

function useApi() {
  let mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [state, dispatch] = useApiState(
    (viewId, type) => withRef(getDiagnostic(viewId, type)),
    { loading: true, error: null, data: null, count: 0 }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    ...state,
    request: ({ viewId, viewName, type }) =>
      dispatch(
        viewId,
        type === "funnel" && !viewName.toLowerCase().includes("idx")
          ? "funnel-non-idx"
          : type
      ),
  };
}

function getDiagnostic(viewId, type) {
  const base = ["api", viewId, "diagnostics"].join("/");

  return abortableFetch(`${base}?type=${type}`, {
    method: "GET",
    credentials: "include",
    headers: { "content-type": "application/json" },
  });
}
