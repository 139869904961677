import React from 'react';
import './styles.scss';

export const Callout = ({ style = {}, children }) => {
  return (
    <div style={style} className="callout">
      <p>
        <i className="fa-solid fa-circle-info"></i>
        {children}
      </p>
    </div>
  );
};
