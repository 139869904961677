import React from 'react';
import { DataPoint } from './DataPoint';
import './index.scss';
import { useCreativeAnalyticsGet } from '../../contexts';

export const DataPoints = ({ data_points = [], readOnly=false, currentIndex }) => {
  const { isROAS } = useCreativeAnalyticsGet() || {};

  return (
    <div className="request__form-group">
      <label className="request__label">
        Data Points
      </label>
      <div>
        {data_points.map((dataPoint) => {
          return <DataPoint {...{ dataPoint, readOnly, currentIndex, isROAS }} />;
        })}
      </div>
    </div>
  );
};
