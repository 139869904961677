//@ts-check
import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchRunningAdsByName } from '../../store/RunningAdsByName/actions';
import {
  ADSET_NAME,
  AD_NAME,
  AD_STUDY,
  AD_LABELS,
  PERFORMANCE_FREQUENCY,
} from '../../store/CreativeReports/constants';
import { useUrlProps } from './report-hooks';

export const useFilteredData = (reportType) => {
  const {
    spendThreshold,
    excludeAds,
    visualType,
    study_type,
    ad_label_type,
  } = useUrlProps();
  const report_type = reportType;
  const state = useSelector(
    ({ CreativeReports: { reports, chart_type }, Adsets: { byNames, adsets } }) => {
      // combine ad_name and ad_labels report data for perf freq report
      let merged = [];
      Object.entries(reports).forEach(([key, value]) => {
        merged.push(...value)
      })

      const data =
        report_type === ADSET_NAME
          ? byNames.map((name) => ({ value: name, ...adsets[name] }))
          : chart_type === PERFORMANCE_FREQUENCY
          ? merged
          : reports[report_type];

      return data;
    },
  );

  const data = useMemo(
    () =>
      !!state && state.length > 0
        ? filterData(state, {
            excludeAds,
            spendThreshold,
            visualType,
            reportType: report_type,
            studyType: study_type,
            ad_label_type,
          })
            .slice(0)
            .sort((a, b) => b.difference - a.difference)
        : [],
    [
      report_type,
      spendThreshold,
      visualType,
      excludeAds,
      study_type,
      ad_label_type,
      state,
    ]
  );

  return {
    data,
    report_type,
    spendThreshold,
    visualType,
    study_type,
    ad_label_type,
  };
};

function filterData(
  data,
  {
    spendThreshold,
    excludeAds,
    visualType,
    reportType,
    studyType,
    ad_label_type,
  }
) {
  /**
   * @type {Function[]}
   */
  let predicates = [];
  let len = 0;

  if (spendThreshold >= 1) {
    len = predicates.push(({ spend }) => spend >= spendThreshold);
  }

  if (!!excludeAds) {
    const containsAd = (names) =>
      names.some(({ name }) => excludeAds[name.slice(0, 5)]);

    len = predicates.push(({ ad_names }) => containsAd(ad_names));
  }

  if (reportType === AD_LABELS && ad_label_type === 'standardized') {
    len = predicates.push(({ value }) => value.includes('+'));
  }

  const composedPredicates = (item) => pipeAND(item, ...predicates);

  let results = len === 0 || reportType === 'ad_labels' ? data : data.filter(composedPredicates);

  switch (reportType) {
    case AD_STUDY:
      if (studyType !== '') {
        results = results.filter(({ study_type }) => study_type === studyType);
      }

      break;
  }

  if (!!visualType && visualType !== '') {
    results = filterVisualType(results, reportType, visualType);
  }

  return results;
}

/**
 * @param {[Object]} data
 * @param {String} report
 * @param {"video" | "image"} visual
 */
function filterVisualType(data, report, visual) {
  if (report === AD_NAME) {
    return data.filter(({ ad_names }) => ad_names[0].type === visual);
  } else {
    return data.map(({ ad_names, ...rest }) => ({
      ...rest,
      ad_names: ad_names.filter(({ type }) => type === visual),
    }));
  }
}

/**
 * @param {Object} src
 * @param {...Function} predicates
 *
 * @return {Boolean}
 */
function pipeAND(src, ...predicates) {
  for (const pred of predicates) {
    if (!pred(src)) {
      return false;
    }
  }

  return true;
}

export const useFetchByAdNames = () => {
  const dispatch = useDispatch();

  return (adNames) =>
    dispatch(fetchRunningAdsByName(adNames.map(({ name }) => name)));
};
