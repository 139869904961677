import { useReducer } from 'react';

const INITIAL = {
  error: null,
  loading: false,
  data: null,
  count: 0,
};
const ABORT_ERR = 'Request aborted.';

function apiReducer(state = INITIAL, action) {
  switch (action.type) {
    case 'FETCHING':
      return {
        loading: true,
        data: null,
        error: null,
        count: state.count + 1,
      };

    case 'FAILURE':
      return {
        error: action.error,
        loading: false,
        data: null,
        count: state.count,
      };

    case 'SUCCESS':
      return {
        loading: false,
        error: null,
        data: action.data,
        count: state.count,
      };

    case 'RESET':
      return { ...INITIAL, ...action.state };

    default:
      return state;
  }
}

const middleware = (callApi) => (dispatch) => async (action) => {
  switch (action.type) {
    case 'FETCHING':
      dispatch(action);

      try {
        const res = await callApi(...action.args);

        dispatch({ type: 'SUCCESS', data: res });
      } catch (err) {
        if (err === ABORT_ERR) {
          // eslint-disable-next-line no-console
          console.error(err);
          return null;
        }
        dispatch({ type: 'FAILURE', error: err });
      }

      break;

    default:
      return dispatch(action);
  }

  return null;
};

/**
 * @typedef {(args: ...any) => void} requester
 * @typedef {(action: { type: string, & Object}) => void} dispatcher
 * @typedef {{ data: null | any, loading: boolean, error: null | any, count: number }} state
 *
 * @param {(args: ...any) => Promise} proc
 * @param {state} initial
 *
 * @return {[state, requester, dispatcher]}
 */
export function useApiState(proc, initial = INITIAL) {
  const [state, dispatch] = useReducer(apiReducer, initial);

  const enhancedDispatch = middleware(proc)(dispatch);

  return [
    state,
    (...args) => enhancedDispatch({ type: 'FETCHING', args }),
    dispatch,
  ];
}

/**
 * @description Binds an abortable fetch request to a React ref.
 * If `mountedRef.current` is falsey, the request will abort.
 * @param {{ current: boolean }} mountedRef
 *
 * @return {(conf: { ready: Promise<Response>, abort: () => void }) => Promise}
 */
export function bindFetchToRef(mountedRef) {
  return async ({ ready, abort }) => {
    const res = await ready;
    const json = await res.json();

    if (!mountedRef.current) {
      abort();

      return Promise.reject(ABORT_ERR);
    }
    if (res.status !== 200) {
      return Promise.reject(json);
    }
    return json;
  };
}
