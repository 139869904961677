import { LabelObject, VisualObject } from '../../types';
import {
  checkIfVisualIsSelected,
  checkIfVisualIsValid,
} from '../../hooks/useFilteredData/utils/filterVisuals';
import { debug } from './debug';
import _ from 'lodash';

const defaultMetricsToSum = ['spend', 'results', 'impressions', 'clicks'];

const getAdNamesMap = (visuals) => {
  const adNamesMap = new Map<string, Set<string>>();

  visuals.forEach((visual) => {
    const visualId = visual.id;
    const adNames = [...(visual.ad_names ?? [])].sort();
    const adNamesKey = adNames.join('|||');

    if (adNamesMap.has(adNamesKey)) {
      adNamesMap.get(adNamesKey).add(visualId);
    } else {
      adNamesMap.set(adNamesKey, new Set([visualId]));
    }
  });

  return adNamesMap;
};

const getMetricsToSum = (
  defaultMetricsToSum,
  { customIntegratedMetrics, clientMetrics, additional_metrics }
) => {
  return [
    ...new Set([
      ...defaultMetricsToSum,
      ...customIntegratedMetrics.map((metric) => metric.value),
      ...clientMetrics,
      ...additional_metrics.map((metric) => metric.value),
    ]),
  ];
};

const calculateTotalMetrics = (visuals, metricsToSum) => {
  const totals = metricsToSum.reduce((acc, metric) => {
    acc[metric] = 0;
    return acc;
  }, {});

  visuals.forEach((visual) => {
    metricsToSum.forEach((metric) => {
      totals[metric] += visual[metric] || 0;
    });
  });

  return totals;
};

const verifyTotalMetrics = (beforeTotals, afterTotals) => {
  for (const metric in beforeTotals) {
    if (beforeTotals[metric] !== afterTotals[metric]) {
      console.log(
        `Total ${metric} mismatch! Before: ${beforeTotals[metric]}, After: ${afterTotals[metric]}`
      );
      return false;
    }
  }
  return true;
};

const getAllAdded = (updatedVisuals) => {
  let result = new Set();

  updatedVisuals.forEach((v) => {
    v.added.forEach((x) => {
      result.add(x.id);
    });
  });

  return Array.from(result);
};

type VisualObject = {
  ad_names: string[];
  asset_url: string[];
  cpa: number;
  roas?: number;
  id: string;
  percent_diff: string;
  results: number;
  spend: number;
  visual_type: string;
  impressions: number;
  clicks: number;
  thumbnail_url?: string;
  isNew: boolean;
};

type MergeVisualsProps = {
  visuals: VisualObject[];
  adNamesMap: Map<string, Set<string>>;
  metricsToSum: [];
};

const debugSpend = (
  visuals,
  updatedVisuals,
  beforeSpend,
  visual,
  i,
  removeTheseVisuals
) => {
  const copy = new Map(updatedVisuals);
  const spend = visuals.reduce((total, visual) => {
    if (removeTheseVisuals.has(visual.id)) return total;
    const updatedVisual = copy.get(visual.id)
      ? copy.get(visual.id).updatedVisual
      : visual;
    return total + (updatedVisual.spend || 0);
  }, 0);

  if (!areSpendsEqual(spend, beforeSpend)) {
    console.log('SPEND CHANGED');
  }
  console.log({ spend, beforeSpend, visual, i, copy });
};

function areSpendsEqual(spend1, spend2, threshold = 10.0) {
  return Math.abs(spend1 - spend2) < threshold;
}

const mergeV = ({
  visuals,
  adNamesMap,
  metricsToSum,
  beforeSpend,
  isMain = false,
}: MergeVisualsProps) => {
  const removeTheseVisuals = new Set<string>();
  const updatedVisuals = new Map();

  visuals.forEach((visual, i) => {
    // if (isMain) {
    //   debugSpend(
    //     visuals,
    //     updatedVisuals,
    //     beforeSpend,
    //     visual,
    //     i,
    //     removeTheseVisuals
    //   );
    // }

    const shouldDebug = false;

    if (removeTheseVisuals.has(visual.id)) return;

    if (!visual.ad_names?.length) {
      return updatedVisuals.set(visual.id, {
        updatedVisual: visual,
        original: visual,
        added: [],
      });
    }

    const adNames = [...(visual.ad_names ?? [])].sort();
    const adNamesMatch = findSubsetMatches(adNames, adNamesMap, shouldDebug);

    if (!adNamesMatch || !adNames.length) {
      updatedVisuals.set(visual.id, {
        updatedVisual: visual,
        original: visual,
        added: [],
      });
    }

    const visualsToMerge = findMatches(
      visual,
      visuals,
      adNamesMatch,
      updatedVisuals,
      removeTheseVisuals
    );

    try {
      let topSpend = visual.spend;
      let topSpendingVisualId = visual.id;

      visualsToMerge.forEach((v) => {
        if (v.spend > topSpend) {
          topSpend = v.spend;
          topSpendingVisualId = v.id;
        }
      });

      if (topSpendingVisualId === visual.id) {
        if (updatedVisuals.has(visual.id)) {
          const existing = updatedVisuals.get(visual.id);

          updatedVisuals.set(
            visual.id,
            mergeMetrics(
              existing.original,
              [...existing.added, ...visualsToMerge],
              metricsToSum
            )
          );
        } else {
          updatedVisuals.set(
            visual.id,
            mergeMetrics(visual, visualsToMerge, metricsToSum)
          );
        }

        visualsToMerge.forEach((v) => {
          removeTheseVisuals.add(v.id);
        });
      } else {
        const top = visualsToMerge.find((x) => x.id === topSpendingVisualId);

        if (updatedVisuals.has(visual.id)) {
          return;
        }
        if (shouldDebug) {
          console.log({
            top,
            topSpendingVisualId,
          });
        }
        if (!top) throw new Error('top value is missing');

        const toBeMerged = [
          visual,
          ...visualsToMerge.filter(
            (visual) => visual.id !== topSpendingVisualId
          ),
        ].filter((visual) => {
          return (
            isSubset(top.ad_names, visual.ad_names) ||
            isSubset(visual.ad_names, top.ad_names) ||
            _.isEqual(top.ad_names, visual.ad_names)
          );
        });

        if (updatedVisuals.has(topSpendingVisualId)) {
          const existing = updatedVisuals.get(topSpendingVisualId);

          updatedVisuals.set(
            topSpendingVisualId,
            mergeMetrics(
              existing,
              [...existing.added, ...toBeMerged],
              metricsToSum
            )
          );
        } else {
          updatedVisuals.set(
            topSpendingVisualId,
            mergeMetrics(top, [...toBeMerged], metricsToSum)
          );
        }

        toBeMerged.forEach((visual) => {
          removeTheseVisuals.add(visual.id);
        });
      }
    } catch (e) {
      console.error('Error updating visual' + visual.id);
      console.error(e);
      return;
    }
  });

  return { updatedVisuals, removeTheseVisuals: Array.from(removeTheseVisuals) };
};

const mergeMetrics = (
  visual: VisualObject,
  visualsToMerge: VisualObject[],
  metricsToSum: string[]
) => {
  const updatedVisual: VisualObject = Object.assign({}, visual);

  visualsToMerge.forEach((v) => {
    metricsToSum.forEach((metric) => {
      updatedVisual[metric] = (updatedVisual[metric] ?? 0) + (v[metric] ?? 0);
    });
  });

  return {
    updatedVisual,
    original: visual,
    added: visualsToMerge,
  };
};

const isSubset = (subset: string[], superset: string[]): boolean => {
  return subset.every((value) => superset.includes(value));
};

const findSubsetMatches = (adNames, adNamesMap, test = false) => {
  const matchedKeys = [];
  for (const [key, visualIds] of adNamesMap.entries()) {
    if (!key) {
      continue;
    }
    const existingAdNames = key.split('|||');

    if (test) {
      // console.log({ key, visualIds, existingAdNames, adNames, adNamesMap });
    }

    if (
      isSubset(adNames, existingAdNames) ||
      isSubset(existingAdNames, adNames) ||
      _.isEqual(adNames, existingAdNames)
    ) {
      matchedKeys.push(visualIds);
    }
  }
  return matchedKeys;
};

const findMatches = (
  visual,
  visuals,
  matchedKeys,
  updatedVisuals,
  removeTheseVisuals
): VisualObject[] => {
  const matchedWith: VisualObject[] = [];
  const mergedSet = new Set();

  matchedKeys.forEach((visualIds) => {
    visualIds.forEach((matchingId) => {
      if (
        updatedVisuals.has(matchingId) ||
        mergedSet.has(matchingId) ||
        removeTheseVisuals.has(matchingId)
      )
        return;
      const matchingVisual = visuals.find((v) => v.id === matchingId);

      if (
        matchingVisual &&
        !!matchingVisual.ad_names.length &&
        matchingVisual.id !== visual.id
      ) {
        matchedWith.push(matchingVisual);
        mergedSet.add(matchingVisual.id);
      }
    });
  });

  return matchedWith;
};

export function groupByAdName(
  labels,
  groupByAdName,
  { customIntegratedMetrics, clientMetrics = [], additional_metrics },
  {
    spendThreshold,
    visualIdsFromSelectedLabels,
    metricsData = [],
    minResults,
    primary_metric,
    addNotification,
    turnOffGrouping,
  }
) {
  if (!groupByAdName) {
    return {
      updatedLabels: labels,
      updatedMetricsData: metricsData,
    };
  }

  const visuals = [...metricsData].filter((visual) => {
    return checkIfVisualIsValid({
      visual,
      spendThreshold,
      minResults,
      visualIdsFromSelectedLabels,
      primary_metric,
    });
  });

  const visualMap = new Map();

  const adNamesMap = getAdNamesMap(visuals);

  const metricsToSum = getMetricsToSum(defaultMetricsToSum, {
    customIntegratedMetrics,
    clientMetrics,
    additional_metrics,
  });

  visuals.forEach((visual) => {
    visualMap.set(visual.id, visual);
  });

  const beforeTotals = calculateTotalMetrics(visuals, metricsToSum);

  const beforeSpend = beforeTotals.spend;

  const { updatedVisuals } = mergeV({
    visuals,
    visualMap,
    adNamesMap,
    metricsToSum,
    beforeSpend,
    isMain: true,
  });

  const removeTheseVisuals = getAllAdded(updatedVisuals);

  const updatedMetricsData = visuals
    .filter((visual) => !removeTheseVisuals.includes(visual.id))
    .map((visual) => {
      if (updatedVisuals.has(visual.id)) {
        return { ...updatedVisuals.get(visual.id).updatedVisual };
      } else {
        return visual;
      }
    });

  const afterTotals = calculateTotalMetrics(updatedMetricsData, metricsToSum);

  if (!areSpendsEqual(beforeSpend, afterTotals.spend)) {
    addNotification({
      title: 'Error',
      message: 'There was an error grouping by ad name.',
      toastProps: {
        className: 'error-toast',
      },
    });
    turnOffGrouping();
  }

  if (!verifyTotalMetrics(beforeTotals, afterTotals)) {
    console.error('Total metrics mismatch after grouping!');
  }

  const updatedLabels = updateLabelData(
    labels,
    updatedVisuals,
    removeTheseVisuals,
    (label) => {
      return mergeOneLabel(label, adNamesMap, metricsToSum);
    }
  );

  return {
    updatedLabels,
    updatedMetricsData,
  };
}

export const getTotalSpend = (updatedVisuals) => {
  let totalSpend = 0;

  updatedVisuals.forEach((value) => {
    if (value.updatedVisual && typeof value.updatedVisual.spend === 'number') {
      totalSpend += value.updatedVisual.spend;
    }
  });

  return totalSpend;
};

const mergeOneLabel = (label, adNamesMap, metricsToSum) => {
  const visualMap = new Map();
  const visuals = [
    ...(label?.relevant_visuals ?? [...label.winners, ...label.losers]),
  ];
  visuals.forEach((visual) => {
    visualMap.set(visual.id, visual);
  });
  const { updatedVisuals } = mergeV({
    visuals,
    visualMap,
    adNamesMap,
    metricsToSum,
  });
  const removeTheseVisuals = getAllAdded(updatedVisuals);

  const newVisuals = updateVisuals(
    [...visuals],
    updatedVisuals,
    removeTheseVisuals
  );

  return {
    ...label,
    relevant_visuals: newVisuals,
  };
};

export const updateLabelData = (
  labels: LabelObject[],
  updatedVisuals: Map<string, VisualObject>,
  removeTheseVisuals: string[],
  mergeForSpecificLabel: (label: LabelObject) => void
) =>
  [...labels].map((label) => {
    if (
      label.udc ||
      label.type === 'ad_name_label' ||
      label.category === 'Ad Name Label'
    )
      return mergeForSpecificLabel(label);

    const visual_ids = label.relevant_visuals ?? [
      ...label.winners,
      ...label.losers,
    ];

    return {
      ...label,
      relevant_visuals: visual_ids.filter(
        (id) => !removeTheseVisuals.includes(id)
      ),
    };
  });

const updateVisuals = (
  visuals: VisualObject[],
  updatedVisuals,
  removeTheseVisuals: string[]
) => {
  return visuals
    .filter((visual) => !removeTheseVisuals.includes(visual.id))
    .map((visual) =>
      updatedVisuals.has(visual.id)
        ? { ...updatedVisuals.get(visual.id).updatedVisual }
        : visual
    );
};

/* 
if label.udc is true:

*/
