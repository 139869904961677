import React, { useState } from 'react';
import { ArrowLeft, ArrowRight } from '../../../TryThese/assets';
import { Preview } from '../../../TryThese/Carousel';
import { SET_SECOND_VALUE } from '../../hooks/useDraftCreativeState';

const Slide = ({
  data,
  handleBlockValue,
  setShowCarousel,
  draftCreativeDispatch,
  rowIndex,
}) => {
  console.log(data);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [showOptions, setShowOptions] = useState(false);

  let previews = [];
  let title = '';

  return (
    <div className="d-flex justify-content-between">
      {data.map((v, i) => {
        const getTryTheseData = (examples) => {
          return examples.map(({ asset, type }) => {
            return {
              visual_type: type,
              asset_url: asset,
            };
          });
        };

        try {
          previews = getTryTheseData([...v.example].reverse());
        } catch (e) {
          if (typeof v.example === 'object') {
            previews = [v.example];
          }
        }

        console.log({previews})

        title = v.label;

        const prev = () => {
          if (carouselIndex > 0) {
            setCarouselIndex(carouselIndex - 1);
          } else {
            setCarouselIndex(previews.length - 1);
          }
        };

        const next = () => {
          if (carouselIndex < previews.length - 1) {
            setCarouselIndex(carouselIndex + 1);
          } else {
            setCarouselIndex(0);
          }
        };

        if (!previews[carouselIndex]) {
          return <p>There was an error loading the asset.</p>;
        }

        return (
          <div
            className="d-flex flex-column"
            style={{ width: '30%', cursor: 'pointer' }}
            onClick={() => {
              handleBlockValue(
                SET_SECOND_VALUE,
                previews[carouselIndex].asset_url
              );
              setShowCarousel(false);
              draftCreativeDispatch({
                type: SET_SECOND_VALUE,
                value: previews[carouselIndex].asset_url,
                rowIndex,
              })
            }}
          >
            {title && <h3>{title}</h3>}
            <div
              className={`recommendation-card__preview`}
              // onMouseEnter={() => {
              //   setShowOptions(true);
              // }}
              // onMouseLeave={() => {
              //   setShowOptions(false);
              // }}
            >
              {showOptions && previews.length > 1 && (
                <ArrowLeft {...{ prev }} />
              )}
              {showOptions && previews.length > 1 && (
                <ArrowRight {...{ next }} />
              )}

              <Preview
                asset={previews[carouselIndex]}
                showOptions={showOptions}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Slide;
