import React, { useState } from 'react';
import RoleAccess from '../RoleAccess';
import { withRouter } from 'react-router';

import { AdminIcon } from './assets';
import { Dropdown } from './NavMenu';
import ContainedLogoutLink from './ContainedLogoutLink';
import { hasRoles } from '../../cookies';

const AdminSettings = ({
  history,
  viewId,
  allowedAdmin,
  isDirectMail,
  platforms,
  darwin_light,
  isNonPaidMediaType,
  isUltraLight,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const hasSnapchat = !platforms
    ? true
    : platforms.some((p) => p === 'snapchat');

  const handleSelection = (key, e) => {
    setShowDropdown(false);

    history.push(`/${viewId}/${key}`);
  };

  const AdminDropdown = ({ children }) => {
    return (
      <div className="menu-dropdown menu-dropdown--admin">
        <h3 className="menu-dropdown__title">Settings</h3>
        <ul className="menu-dropdown__list">{children}</ul>
      </div>
    );
  };

  const AdminOption = ({ eventKey, enabled = true, children }) => {
    if (!enabled) return null;

    return (
      <li
        onClick={() => handleSelection(eventKey)}
        className="menu-dropdown__item"
      >
        {children}
      </li>
    );
  };

  return (
    <div
      className="admin-settings"
      onMouseEnter={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
    >
      <AdminIcon className="menu-item__icon" />
      {showDropdown && (
        <AdminDropdown>
          {(isNonPaidMediaType || isUltraLight) && (
            <RoleAccess roles={['internal']}>
              <AdminOption eventKey="analytics" enabled={allowedAdmin}>
                Analytics
              </AdminOption>
            </RoleAccess>
          )}

          <RoleAccess roles={['internal']}>
            <AdminOption eventKey="manage-labels" enabled={allowedAdmin}>
              Manage Labels
            </AdminOption>
          </RoleAccess>

          <RoleAccess roles={['internal', 'client']}>
            <AdminOption
              eventKey="budget-boost"
              enabled={!isDirectMail && !darwin_light}
            >
              Budget Boost
            </AdminOption>
          </RoleAccess>

          <RoleAccess roles={['internal', 'client']}>
            <AdminOption eventKey="view-settings" enabled={!isDirectMail}>
              View Settings
            </AdminOption>
          </RoleAccess>

          <RoleAccess roles={['internal']}>
            <AdminOption
              eventKey="lever-admin"
              enabled={!isDirectMail && !darwin_light}
            >
              LEVER Admin
            </AdminOption>
          </RoleAccess>

          <RoleAccess roles={['internal']}>
            <AdminOption
              eventKey="lever-setup"
              enabled={!isDirectMail && !darwin_light}
            >
              LEVER Setup
            </AdminOption>
          </RoleAccess>

          <RoleAccess roles={['internal']}>
            <AdminOption
              eventKey="block-manager"
              enabled={!isDirectMail && !darwin_light}
            >
              Manage Blocks
            </AdminOption>
          </RoleAccess>

          <RoleAccess roles={['internal']}>
            <AdminOption eventKey="refresh" enabled={!isDirectMail}>
              Refresh Data
            </AdminOption>
          </RoleAccess>

          <RoleAccess roles={['internal']}>
            <AdminOption eventKey="report-groups" enabled={allowedAdmin}>
              Report Groups
            </AdminOption>
          </RoleAccess>

          <li className="menu-dropdown__item menu-dropdown__item--logout">
            <ContainedLogoutLink />
          </li>
        </AdminDropdown>
      )}
    </div>
  );
};

export default withRouter(AdminSettings);
