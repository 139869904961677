import React, { createContext, useContext } from 'react';
import { DraftCreativeState } from '../types';
import { initialDataState } from '../constants';

export const DraftCreativeContext = createContext<DraftCreativeState>({
  draftCreativeState: initialDataState,
  draftCreativeDispatch: () => null,
});

interface Props {
  draftCreativeState: DraftCreativeState;
  children: React.ReactNode;
}

const DraftCreativeContextProvider = ({ draftCreativeState, children }: Props) => {
  return (
    <DraftCreativeContext.Provider value={draftCreativeState}>
      {children}
    </DraftCreativeContext.Provider>
  );
};

export default DraftCreativeContextProvider;

export function useDraftCreativeContext() {
  return useContext(DraftCreativeContext);
}
