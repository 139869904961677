import React, { useEffect, useState } from 'react';
import { CaretDownIcon } from '../Icons';
import './ViewSelector.scss';

export default function ViewSelector({
  options,
  currentView,
  onChange,
  className,
}) {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleToggleNav = () => {
    setMenuOpen(!menuOpen);
  };

  const handleChangeView = (selection) => {
    onChange(selection);
  };

  // Ensuring a named function.
  function bodyClick(e) {
    if (e.target.id !== 'view-selector') {
      setMenuOpen(false);
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', bodyClick);

    return () => {
      document.body.removeEventListener('click', bodyClick);
    };
  }, []);

  return (
    <>
      <div className={`view-selector ${className ? className : ''}`} onClick={handleToggleNav} id="view-selector">
        {currentView.label}
        <CaretDownIcon className="view-selector__caret" />
      </div>
      <div className={menuOpen ? 'view-selector-overlay view-selector-overlay--open' : 'view-selector-overlay'}>
        <ul className="view-selector-list">
          {options.map((option) => (
            <MenuItem {...{ option, handleChangeView }} />
          ))}
        </ul>
      </div>
    </>
  );
}

const MenuItem = ({ option, handleChangeView }) => {
  const { label, value } = option;

  return (
    <li className="view-selector-list-item" onClick={() => handleChangeView(value)} key={value}>
      {option.Icon && option.Icon}
      {label}
    </li>
  )
}