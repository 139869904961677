
import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { addNotification } from '../../../store/Notifications/actions';
import { useDimensions } from '../../../components/Hooks/dom-utils';
import { useViewId } from '../../../components/Hooks/view-id';

import '../../CubeDashboard/cube-source/components/style.css';
import './ad-study.scss';

const STYLE = {
  margin: '10px 0 10px 0',
};

const ReportTable = ({
  id,
  data,
  handleSeriesClick,
  selectedId,
  dimensions,
}) => {
  const [header, { height }] = useDimensions();

  const style = { maxHeight: `${dimensions.height - height - 16}px` };
  const sorted = [...data].sort((a, b) => b.spend - a.spend);

  const Header = (
    <thead className="scroll-thead" ref={header}>
      <tr>
        <th scope="col" className="value-col">
          Value
        </th>
        <th scope="col">Spend</th>
        <th scope="col">CPA</th>
        <th scope="col">CPA Difference</th>
        <th scope="col">Ads</th>
        <th scope="col">Remove</th>
      </tr>
    </thead>
  );

  return (
    <table id={id} className="table" style={STYLE}>
      {Header}
      <Rows
        style={style}
        data={sorted}
        currentValue={selectedId}
        onClick={handleSeriesClick}
      />
    </table>
  );
};

ReportTable.propTypes = {
  id: PropTypes.string.isRequired,
  selectedId: PropTypes.string,
  handleSeriesClick: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  dimensions: PropTypes.shape({
    height: PropTypes.number,
  }),
};

export default ReportTable;

function Rows({
  data, onClick, currentValue, style,
}) {
  return (
    <tbody className="scroll-tbody" style={style}>
      {data.map((props, i) => (
        <Row
          {...props}
          key={`${i}:${props.value}`}
          onClick={onClick}
          selected={currentValue === props.value}
        />
      ))}
    </tbody>
  );
}

Rows.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired,
  currentValue: PropTypes.string.isRequired,
  style: PropTypes.object,
};

function Row({
  value,
  cpr,
  percent_difference,
  spend,
  ads,
  onClick,
  selected,
}) {
  const data = useSelector(
    ({
      CreativeReports: {
        reports: { ad_study },
      },
    }) => ad_study,
  );
  const { removed, loading, send } = useRemovalStatus(data, value);

  const handleRemove = (e) => {
    e.stopPropagation();

    const answer = !!window.confirm(
      'Are you sure you want to delete this study?',
    );

    if (answer) {
      send('FETCHING');

      onClick(null);
    }
  };

  const style = loading
    ? {
      opacity: '50%',
      background: 'inherit',
      color: '#666666',
      cursor: 'progress',
    }
    : {};

  return removed ? null : (
    <tr
      onClick={(e) => (loading ? null : onClick({ category: value }))}
      className={`scroll-tr ${
        selected ? 'darwin-btn-selected' : 'darwin-action'
      }`}
      title={value}
      style={style}
    >
      <td className="value-col">
        {value.length > 30 ? `${value.slice(0, 27)}...` : value}
      </td>
      <td>{`$${spend.toFixed(2)}`}</td>
      <td>{cpr && cpr.toFixed(2)}</td>
      <td>{percent_difference}</td>
      <td>{ads}</td>
      <td>
        <RemoveButton onClick={handleRemove} />
      </td>
    </tr>
  );
}

Row.propTypes = {
  value: PropTypes.string.isRequired,
  cpr: PropTypes.number,
  spend: PropTypes.number.isRequired,
  ads: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

function useRemovalStatus(data, value) {
  const viewId = useViewId();
  const dispatch = useDispatch();
  const [{ removed, loading, error }, setState] = useReducer(reducer, {
    loading: false,
    removed: false,
    error: false,
  });

  useEffect(() => {
    if (removed) {
      dispatch({
        type: '[CreativeReports] SET',
        meta: { entity: 'CreativeReports' },
        data: {
          reports: {
            ad_study: data.filter((study) => study.value !== value),
          },
        },
      });
    }
  }, [removed]);

  useEffect(() => {
    let active = true;

    const _fetch = async () => {
      const res = await removeAdStudy(viewId, { study_name: value });

      if (active) {
        const [nextState, status, message] = res.status === 200
          ? ['SUCCESS', 'success', 'Study successfully removed']
          : ['FAILURE', 'danger', 'Failed to remove study'];

        setState({ type: nextState });

        dispatch(
          addNotification(
            {
              message,
              // @ts-ignore
              status,
              title: 'Ad Study',
              id: Date.now(),
            },
            'AdStudy',
          ),
        );
      }
    };

    if (loading) {
      _fetch();
    }

    return () => (active = false);
  }, [loading]);

  return {
    removed, loading, error, send: (type) => setState({ type }),
  };
}

function reducer(
  state = { removed: false, error: false, loading: false },
  action,
) {
  switch (action.type) {
    case 'PENDING':
      return { removed: false, loading: false, error: false };

    case 'FETCHING':
      return { removed: false, loading: true, error: false };

    case 'SUCCESS':
      return { removed: true, loading: false, error: false };

    case 'FAILURE':
      return { removed: false, loading: false, error: true };

    default:
      return state;
  }
}

const RemoveButton = ({ onClick }) => <i onClick={onClick} className="fa fa-trash study-remove" />;

function removeAdStudy(viewId, { study_name }) {
  return fetch(['api', viewId, 'ad-study'].join('/'), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ study_name }),
  });
}
