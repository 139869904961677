
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Loading } from '../Helpers/Loading';
import { ErrorBoundary } from '../Helpers/Error';
import { toCurrency } from '../../utils/numbers';
import { useApi } from './Api';
import { isEvent } from './utils';

import './styles.scss';
import { DARWIN_BLUE, DARWIN_RED, DARWIN_GRAY } from '../../constants';

export function DetailsContainer(props) {
  const { testDates } = useApi();
  const [useSelected, setSelection] = useState(false);
  const _isEvent = isEvent(props);
  const aLabel = groupALabel(props);

  const Selector = !_isEvent && (
    <DateSelector handleSelection={setSelection} useSelected={useSelected} />
  );

  useEffect(() => {
    if (props.value !== '') {
      testDates.request(props.value, useSelected && !_isEvent);
    }
  }, [props.value, useSelected]);

  if (props.value === '') {
    return (
      <Details {...props} DateSelector={Selector} group_a_label={aLabel} />
    );
  }
  return testDates.error ? (
    <div className="p-2 d-flex flex-col">
      <div className="align-self-end">{Selector}</div>

      <p>{JSON.stringify(testDates.error)}</p>
    </div>
  ) : (
    <Details
      {...({ ...props, ...testDates.data })}
      value={props.value}
      group_a_label={aLabel}
      loading={testDates.loading}
      DateSelector={Selector}
    />
  );
}

DetailsContainer.propTypes = {
  value: PropTypes.string.isRequired,
  in_progress: PropTypes.bool,
  display: PropTypes.arrayOf(
    PropTypes.shape({ performance: PropTypes.string, data: PropTypes.array }),
  ),
  percent_difference: PropTypes.string,
  confidence: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  confidence_level: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function DateSelector({ handleSelection, useSelected }) {
  const Selector = (
    <div className="btn-group" role="group">
      <button
        style={{ borderRadius: 0 }}
        className={`btn btn-${useSelected ? 'primary' : 'success'}`}
        onClick={(e) => handleSelection(false)}
      >
        Study
      </button>
      <button
        style={{ borderRadius: 0 }}
        className={`btn btn-${useSelected ? 'success' : 'primary'}`}
        onClick={(e) => handleSelection(true)}
      >
        Selected
      </button>
    </div>
  );

  return (
    <div className="d-flex flex-row align-items-baseline">
      <p className="text-white mb-0">Dates</p>
      &emsp;
      {Selector}
    </div>
  );
}

function Details({
  display = [],
  value,
  group_a_label,
  test_details,
  percent_difference,
  confidence,
  confidence_level,
  DateSelector,
  loading,
}) {
  const emptyDisplay = display.length === 0;
  const colors = getColors(percent_difference);

  const style = {
    overflow: 'auto',
    height: '100%',
  };

  let toRender = null;

  if (value === '') {
    toRender = (
      <p className="p-4">
        Select an element on the chart to get performance insights.
      </p>
    );
  } else if (loading) {
    toRender = (
      <div
        style={{
          ...style,
          cursor: 'progress',
        }}
        className="p-2"
      >
        <div className="d-flex flex-row align-items-baseline justify-content-between">
          <Loading />
          {DateSelector}
        </div>
      </div>
    );
  } else if (percent_difference === null || percent_difference === undefined) {
    toRender = (
      <div style={{ ...style, color: '#333333' }}>
        <NoCpaDisplay DateSelector={DateSelector} />
      </div>
    );
  } else {
    toRender = (
      <div style={{ ...style, backgroundColor: colors.background }}>
        <CpaDisplay
          value={group_a_label || value}
          comparedValue={test_details && test_details.group_b_label}
          difference={percent_difference}
          confidence={confidence}
          confidence_level={confidence_level}
          DateSelector={DateSelector}
        />

        {!emptyDisplay
          && display.map((value, i) => (
            <DisplayTable
              key={`preview-${value}-${i}`}
              performance={value.performance}
              data={value.data}
              colors={colors}
              group_a_label={test_details.group_a_label || value}
              group_b_label={test_details.group_b_label || 'Others'}
            />
          ))}
      </div>
    );
  }

  return (
    <ErrorBoundary fallback={<h5>An error occurred :(</h5>}>
      {toRender}
    </ErrorBoundary>
  );
}

Details.propTypes = {
  value: PropTypes.string,
  display: PropTypes.arrayOf(
    PropTypes.shape({ performance: PropTypes.string, data: PropTypes.array }),
  ),
  percent_difference: PropTypes.string,
  confidence: PropTypes.number,
  confidence_level: PropTypes.string,
  DateSelector: PropTypes.node.isRequired,
};

function NoCpaDisplay({ DateSelector }) {
  return (
    <div className="display-card">
      <div className="d-flex flex-row align-items-baseline justify-content-between">
        <p className="mb-0">No Data</p>
        {DateSelector}
      </div>

      <p className="mb-0">
        In the selected dates and campaigns, there is currently no data to
        report on for this study
      </p>
    </div>
  );
}

function CpaDisplay({
  value,
  comparedValue,
  confidence,
  confidence_level,
  difference = null,
  DateSelector,
}) {
  const descriptor = parseFloat(difference) > 0 ? 'better' : 'worse';

  return (
    <div className="display-card">
      <div className="d-flex flex-row align-items-baseline justify-content-between">
        <p className="text-white mb-0">
          {value || 'N/A'}
          {' '}
          is performing
        </p>
        {DateSelector}
      </div>

      <h1 className="text-white" style={{ fontSize: '4rem' }}>
        {difference.replace('-', '')}
      </h1>
      <p className="text-white mb-0">
        {descriptor}
        {' '}
        than
        {comparedValue || 'other elements'}
      </p>
      <p className="text-white mb-0">
        Confidence:
        {' '}
        {confidence || 'N/A'}
        {' '}
        {confidence_level || 'Not enough data to assign confidence'}
      </p>
    </div>
  );
}

CpaDisplay.propTypes = {
  value: PropTypes.string.isRequired,
  comparedValue: PropTypes.string,
  confidence: PropTypes.number,
  confidence_level: PropTypes.string,
  difference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  DateSelector: PropTypes.node.isRequired,
};

function DisplayTable({
  performance,
  data = [],
  colors,
  group_a_label,
  group_b_label,
}) {
  const tableData = {
    colors,
    willOverflow: data.length > 3,
    rows: data.filter(({ type }) => !!type),
    group_a_label,
    group_b_label,
  };

  switch (performance) {
    case 'cpa':
      return <TableDisplay title="Element Performance" {...tableData} />;
    case 'funnel':
      return <FunnelTableDisplay title="Funnel Performance" {...tableData} />;

    default:
      return null;
  }
}

DisplayTable.propTypes = {
  performance: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  colors: PropTypes.shape({
    tertiary: PropTypes.string.isRequired,
  }).isRequired,
  group_a_label: PropTypes.string,
  group_b_label: PropTypes.string,
};

function FunnelTableDisplay({
  title,
  rows,
  placeholder = null,
  colors,
  group_a_label,
  group_b_label,
}) {
  const label_one = ('7D Before' in rows[0] && '7D After' in rows[0]) ? '7D Before' : group_a_label;
  const label_two = ('7D Before' in rows[0] && '7D After' in rows[0]) ? '7D After' : group_b_label;

  const _label_one = !label_one
    ? ''
    : label_one.length > 17
      ? `${label_one.slice(0, 14)}...`
      : label_one;

  const _label_two = !label_two
    ? ''
    : label_two.length > 17
      ? `${label_two.slice(0, 14)}...`
      : label_two;

  const Header = (
    <div className="d-flex flex-row">
      <u className="display-type">TYPE</u>
      <u className="display-num">{_label_one}</u>
      <u className="display-num">{_label_two}</u>
    </div>
  );

  const Rows = rows.map((item, i) => {
    const { type } = item;
    const label_one_item = item[label_one];
    const label_two_item = item[label_two];
    const _type = !type
      ? ''
      : type.length > 17
        ? `${type.slice(0, 14)}...`
        : type;

    return (
      <div key={`${type}-${label_one}-${i}`} className="d-flex flex-row">
        <div className="display-type" title={type.toUpperCase()}>
          {_type.toUpperCase()}
        </div>
        <div className="display-num">{format$(label_one_item)}</div>
        <div className="display-num">{format$(label_two_item)}</div>
      </div>
    );
  });

  return (
    <div className="display-card">
      <p className="mb-0" style={{ color: colors.tertiary }}>
        {title}
      </p>
      <hr
        style={{
          padding: 0,
          margin: 0,
          borderTop: `1px solid ${colors.tertiary}`,
        }}
      />

      {Header}

      {placeholder || <div>{Rows}</div>}
    </div>
  );
}

function TableDisplay({
  title,
  rows,
  placeholder = null,
  colors,
  group_a_label,
  group_b_label,
}) {
  const Header = (
    <div className="d-flex flex-row">
      <u className="display-type">TYPE</u>
      <u className="display-num">CPA</u>
      <u className="display-num">SPEND</u>
    </div>
  );

  const Rows = rows.map(({ type, spend, cpa }, i) => {
    const _type = !type
      ? ''
      : type.length > 17
        ? `${type.slice(0, 14)}...`
        : type;

    return (
      <div key={`${type}-${cpa}-${i}`} className="d-flex flex-row">
        <div className="display-type" title={type}>
          {_type}
        </div>
        <div className="display-num">{format$(cpa)}</div>
        <div className="display-num">{format$(spend)}</div>
      </div>
    );
  });

  return (
    <div className="display-card">
      <p className="mb-0" style={{ color: colors.tertiary }}>
        {title}
      </p>
      <hr
        style={{
          padding: 0,
          margin: 0,
          borderTop: `1px solid ${colors.tertiary}`,
        }}
      />

      {Header}

      {placeholder || <div>{Rows}</div>}
    </div>
  );
}

TableDisplay.propTypes = {
  title: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      spend: PropTypes.string,
      cpa: PropTypes.string,
    }),
  ).isRequired,
  colors: PropTypes.shape({
    tertiary: PropTypes.string.isRequired,
  }).isRequired,
};

function getColors(diff) {
  const parsed = parseFloat(diff);

  const [background, foreground, tertiary] = parsed >= 10
    ? [DARWIN_BLUE, DARWIN_RED, '#1b6980']
    : parsed <= -10
      ? [DARWIN_RED, DARWIN_BLUE, '#1b6980']
      : [DARWIN_GRAY, DARWIN_RED, '#cccccc'];

  return { foreground, background, tertiary };
}

function format$(n) {
  if (typeof n !== 'string') {
    return '-';
  }
  const forParsing = n.replace('$', '');
  const parsed = parseFloat(forParsing);

  return toCurrency(parsed);
}

function groupALabel({ test_details }) {
  if (!test_details) {
    return '';
  }
  return test_details.group_a_label || '';
}
