import React, { forwardRef, HTMLAttributes } from 'react';
import _ from 'lodash';

import { CSS } from '@dnd-kit/utilities';
import { useSortable, defaultAnimateLayoutChanges } from '@dnd-kit/sortable';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import './InsightsDashboard.scss';
import './InsightsDashboard.EditMode.scss';

import clsx from 'clsx';

export const Grid = ({ editMode, children, changeHandler, ...props }) => {
  return <div className="insights-grid">{children}</div>;
};

export type ItemProps = HTMLAttributes<HTMLDivElement> & {
  id: string;
  key: string;
  withOpacity?: boolean;
  isDragging?: boolean;
};

export const Item = forwardRef<HTMLDivElement, ItemProps>(
  ({ id, withOpacity, isDragging, style, children, ...props }, ref) => {
    const inlineStyles = {
      opacity: withOpacity ? '0.5' : '1',
      background: withOpacity ? '#0084ff' : '',
      transformOrigin: '50% 50%',
      cursor: isDragging ? 'grabbing' : 'auto',
      transform: isDragging ? 'scale(1.05)' : 'scale(1)',
      ...style,
    };

    return (
      <div
        className={clsx('card-wrapper', {
          'card-wrapper--with-opacity': withOpacity,
        })}
        ref={ref}
        id={id}
        style={inlineStyles}
        {...props}
      >
        {children}
      </div>
    );
  }
);

export const SortableItem = ({ id, children, editMode, cardWrapperProps }) => {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    transition: {
      duration: 200,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
    disabled: !editMode,
  });
  const { isExpanded, amountOfCards, indexFromRight } = cardWrapperProps;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
    height: '100%',
  };

  return (
    <div
      ref={setNodeRef}
      className={clsx('insights-card-wrapper', {
        'is-expanded': isExpanded && !editMode,
        'more-than-3': amountOfCards > 3,
        'more-than-4': amountOfCards > 4,
        'span-two-cols': id.includes('Phrases'),
        [`prefer-right-${indexFromRight}`]:
          indexFromRight !== null &&
          indexFromRight !== undefined &&
          isExpanded &&
          !editMode,
      })}
    >
      <Item
        style={style}
        withOpacity={isDragging}
        {...{ id, children }}
        {...attributes}
        {...listeners}
      />
    </div>
  );
};
