import React, { useEffect, useState } from 'react';
import Tooltip from '../../../components/Tooltip';
import { toCurrency } from '../../../utils/numbers';
import { useCreativeAnalyticsGet } from '../../CreativeAnalytics/contexts';
import { useDatePeriods } from '../../CreativeAnalytics/hooks';
import { useAudienceBreakdownApi } from '../api';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import '../Card.scss';
import { useAudience } from '../hooks';
import AnalyticsButton from '../assets/AnalyticsButton';

import './AudienceBreakdown.scss';
import Card from '../Card';
import { Table } from './Table';
import DeleteButton from '../assets/DeleteButton';

const METRIC_FORMAT_LOOKUP = {
  cpa: 'CPA',
  roas: 'ROAS',
  spend: 'Spend',
};

const METRIC_OPTIONS = ['cpa', 'spend'];

const adjustMetric = (metric, isROAS) => {
  if (metric === 'cpa' && isROAS) return 'roas';
  return metric;
};

export const AudienceBreakdown = ({
  key,
  cardData,
  category,
  openAnalyticsTable,
  isROAS,
  amountOfCards,
  remove,
  editMode,
  openCategoryInNewTab,
}) => {
  const { audiences, selectedAudience } = useAudience();
  const { account_id, period1 } = useCreativeAnalyticsGet();
  const [start_date, end_date] = useDatePeriods(period1);
  const [data, setData] = useState([]);
  const audienceBreakdownApi = useAudienceBreakdownApi();
  const [metric, setMetric] = useState('cpa');
  const metric_options = [...METRIC_OPTIONS].map((metric) => {
    if (isROAS && metric.includes('cpa')) return metric.replace('cpa', 'roas');
    return metric;
  });
  const adjustedMetric = adjustMetric(metric, isROAS);

  const cycleMetric = () => {
    setMetric((prev) => {
      const index = metric_options.findIndex((x) => x === prev);
      if (index === metric_options.length - 1) return metric_options[0];
      return metric_options[index + 1] || '';
    });
  };

  useEffect(() => {
    if (!editMode) {
      audienceBreakdownApi.post.request({
        account_id,
        start_date,
        end_date,
        category,
        audiences: selectedAudience,
      });
    }
    
  }, []);

  useEffect(() => {
    if (audienceBreakdownApi.post.data) {
      const apiData = audienceBreakdownApi.post.data;

      const retargeting = apiData.find(
        ({ audience }) => audience === 'Retargeting'
      );
      const prospecting = apiData.find(
        ({ audience }) => audience === 'Prospecting'
      );

      if (!retargeting || !prospecting) return;
      const bothHaveData = [retargeting, prospecting].every((aud) =>
        aud.labels.some((label) => label.results > 0)
      );

      if (bothHaveData) {
        setData(apiData);
      }
    }
  }, [audienceBreakdownApi.post.data]);

  let Render = <p className="insights-card__text">No data available.</p>;

  if (audienceBreakdownApi.post.loading)
    Render = (
      <Skeleton baseColor="#EBF3FA" highlightColor="#F6FCFF" count={5} />
    );
  if (audienceBreakdownApi.post.error) {
    Render = (
      <div key={key}>
        <p className="insights-card__text">
          There was an error fetching the audience data.
        </p>
        <p className="insights-card__text" style={{ opacity: 0.8 }}>
          {audienceBreakdownApi.post.error?.error.toString()}
        </p>
      </div>
    );
  }

  if (data.length)
    Render = (
      <Table audiences={data} metric={adjustedMetric} category={category} isROAS={isROAS} />
    );

  if (
    audienceBreakdownApi.post.error ||
    (audienceBreakdownApi.post.data && !data.length)
  ) {
    return (
      <Card
        cardData={cardData}
        {...{ isROAS, openAnalyticsTable, openCategoryInNewTab, remove, amountOfCards, editMode }}
      />
    );
  }

  return (
    <div
      // style={{ gridColumn: '1 / 3' }}
      key={key}
      className={`insights-card insights-card--${category.replaceAll(
        /\s/g,
        ''
      )} ${data.length > 2 ? 'expand' : ''}`}
    >
      <AnalyticsButton onClick={openCategoryInNewTab} />
      {editMode && <DeleteButton onClick={remove} />}
      <Tooltip content="This shows how your assets are performing in the prospecting and retargeting audiences">
        <h3
          // onClick={toggleMetric}
          className="insights-card__title"
        >
          {
            <span>
              <span
                className={`insights-card__title-btn ${
                  metric_options.length > 1 ? 'can-switch-metric' : ''
                }`}
                onClick={cycleMetric}
              >
                {METRIC_FORMAT_LOOKUP[adjustedMetric]}
              </span>{' '}
              by {category} <br />{' '}
              <div
                style={{
                  fontSize: '1.1rem',
                  marginTop: '0.5rem',
                }}
              >
                Audience Breakdown
              </div>
            </span>
          }
        </h3>
      </Tooltip>
      <div className="insights-card__content">{Render}</div>
    </div>
  );
};
