import { useReducer, useState, useEffect } from "react";
import { phraseBuilderReducer } from "./reducer";

export const usePhraseBuilder = () => {
  const [{ phrases, original, existingLabels }, dispatchPhraseBuilder] =
    useReducer(phraseBuilderReducer, {
      phrases: [
        {
          name: "",
          rules: [
            {
              include: [],
              exclude: [],
            },
          ],
        },
      ],
      original: {
        name: "",
        rules: [
          {
            include: [],
            exclude: [],
          },
        ],
      },
      existingLabels: [],
    });

  return {
    phrases,
    original,
    existingLabels,
    dispatchPhraseBuilder,
  };
};