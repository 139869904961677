import React, { useEffect, useState } from 'react';
import {
  GripIcon,
  RecommendationsIcon,
  ReportCardIcon,
} from '../../../../components/Icons';
import Tooltip from '../../../../components/Tooltip';
import './EditorItem.scss';
import { CloseIcon } from '../../../../components/Icons';

const EditorItem = ({
  metric,
  isDragging,
  isDraggingOver,
  handleToggle,
}) => {
  return (
    <li
      className={`library-stats__item ${isDragging ? 'is-dragging' : ''} ${
        isDraggingOver ? 'is-dragging-over' : ''
      }`}
    >
      <CloseIcon
        fill="#616E8B"
        className='library-stats__close'
        style={{
          width: '20px',
          position: 'absolute',
          top: '-9px',
          right: '0px',
        }}
        onClick={() => handleToggle(metric)}
      />
      <h3 className="library-stats__label">{metric.metric}</h3>
      <p className="library-stats__value">{metric.value}</p>
    </li>
  );
};

export default EditorItem;
