import React, { ReactChildren } from 'react';

import './Menu.scss';
import { ChevronDoubleRightIcon } from '../../features/CreativeAnalytics/CreativeRequest/icons';

interface MenuProps {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
  children: ReactChildren;
}

export const LeftMenu: React.FC<MenuProps> = ({
  collapsed,
  setCollapsed,
  mainView,
  children,
}) => {
  return (
    <div
      id="left-menu"
      className={`left-menu`}
      style={{
        width: collapsed ? 'fit-content' : 'auto',
        padding: collapsed ? '0.5rem' : '1rem',
        paddingTop: '1.5rem',
      }}
    >
      {!!setCollapsed && (
        <div
          className="collapse-toggle"
          onClick={() => setCollapsed((prev) => !prev)}
        >
          <div className={`collapse-toggle__icon ${collapsed ? 'flip' : ''}`}>
            <ChevronDoubleRightIcon />
          </div>
        </div>
      )}

      <ul
        className="menu"
        style={{
          width: collapsed ? 'fit-content' : '100%',
          paddingTop: '1rem',
        }}
      >
        {children}
      </ul>
    </div>
  );
};
