//@ts-check
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { StudyModal } from "./StudyModal";
import { EventModal } from "./EventModal";
import { eventToForm, newFormToForm, isEvent } from "./utils";
import {
  TrashBin,
  SaveDisk,
  FilledPlus,
  EditPencil,
  FilledFlag,
} from "../SVG-Icons";

const EMPTY_STUDY = { study_name: "" };

export function ActionButtons({
  study,
  handleSubmit,
  handleDelete,
  handleSave,
  saveDisabled,
  removeDisabled,
}) {
  const [layout, setLayout] = useState(buttonLayout(study));
  const [modal, setModal] = useState("");

  const showModal = (e) =>
    setModal(isEvent(study) ? "EDIT_EVENT" : "EDIT_STUDY");

  const layouts = {
    CREATE: <Create onClick={(e) => setModal("STUDY")} />,
    DELETE: <Delete onClick={handleDelete} disabled={removeDisabled} />,
    SAVE: <Save onClick={handleSave} disabled={saveDisabled} />,
    FLAG: <Flag onClick={(e) => setModal("EVENT")} />,
    EDIT: <Edit onClick={showModal} />,
  };

  const Btn = (_type, i) => (
    <React.Fragment key={`${_type}-${i}`}>{layouts[_type]}</React.Fragment>
  );

  useEffect(() => {
    setLayout(buttonLayout(study));
  }, [study]);

  return (
    <>
      <StudyModal
        handleSubmit={(study) => handleSubmit(newFormToForm(study))}
        study={modal === "EDIT_STUDY" ? study : EMPTY_STUDY}
        visible={modal.includes("STUDY")}
        hide={() => setModal("")}
      />
      <EventModal
        handleSubmit={(study) => handleSubmit(eventToForm(study))}
        study={modal === "EDIT_EVENT" ? study : EMPTY_STUDY}
        visible={modal.includes("EVENT")}
        hide={() => setModal("")}
      />
      {layout.map((btnType, i) => Btn(btnType, i))}
    </>
  );
}

ActionButtons.propTypes = {
  study: PropTypes.shape({ study_name: PropTypes.string.isRequired })
    .isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

function buttonLayout({ study_name = "" }) {
  if (study_name === "") {
    return ["TEST", "CREATE", "SAVE", "FLAG"];
  } else {
    return ["DELETE", "EDIT", "TEST", "CREATE", "SAVE", "FLAG"];
  }
}

function Create({ onClick }) {
  return (
    <button
      className="btn btn-no-display"
      type="button"
      title="Add study"
      onClick={onClick}
    >
      <FilledPlus height={21.1} width={21.1} />
    </button>
  );
}

function Edit({ onClick }) {
  return (
    <button
      type="button"
      className="btn-no-display"
      onClick={onClick}
      title="Edit selected"
    >
      <EditPencil height={21.1} width={21.1} />
    </button>
  );
}

function Delete({ onClick, disabled }) {
  return (
    <button
      disabled={disabled}
      type="button"
      className="btn-no-display"
      onClick={onClick}
      title="Remove selected"
    >
      <TrashBin height={21.1} width={21.1} />
    </button>
  );
}

function Save({ onClick, disabled }) {
  return (
    <button
      disabled={disabled}
      type="button"
      className="btn-no-display"
      onClick={onClick}
      title="Save chart"
    >
      <SaveDisk
        style={{
          fontSize: "21.1px",
          color: disabled ? "#dddddd" : "#00aaca",
        }}
      />
    </button>
  );
}

function Flag({ onClick }) {
  return (
    <button
      type="button"
      className="btn-no-display"
      onClick={onClick}
      title="Add event"
    >
      <FilledFlag
        style={{
          fontSize: "21.1px",
        }}
        className="icon-btn"
      />
    </button>
  );
}
