import React from "react";
import PropTypes from "prop-types";

import { ZoneContent, ZoneLabel } from "./Testing.Zone.Content";
import { SizedChevronDown, HelpIcon } from "./Helpers";
import { DEFAULT_NUMBER, SLIGHTLY_LARGER_FONT } from "./constants";
import { activeBGTesting } from "./utils";

class Zone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFeatures: false,
    };
  }

  toggleFeatures() {
    this.setState({ showFeatures: !this.state.showFeatures });
  }

  handleElement(e) {
    const {
      value,
      label,
      spend,
      spend_without,
      cpr,
      cpr_without,
      cpr_percentage,
      ctr,
      ctr_without,
      num_ads,
      comparisons,
      compared_to,
      rank,
      ranks,
      certainty,
      type,
    } = this.props;

    this.props.handleSelection({
      value,
      type,
      label,
      spend,
      spend_without,
      cpr,
      cpr_without,
      cpr_percentage,
      ctr,
      ctr_without,
      num_ads,
      comparisons,
      compared_to,
      rank,
      ranks,
      certainty,
    });
  }

  handleFeature(data) {
    this.props.handleSelection(data);
  }

  render() {
    const {
      features,
      label,
      cpr,
      rank,
      ranks,
      type,
      value,
      activeKey,
      toggleTooltip,
    } = this.props;

    const isActive = activeKey === `${type}-${value}`;
    const featuresHeightLimit =
      this.state.showFeatures && features.length > 4
        ? { height: "240px", overflow: "auto" }
        : null;

    return (
      <div>
        <div
          className="zone basic-border align-items-center"
          style={activeBGTesting(isActive)}
        >
          {features && features.length > 0 && (
            <span onClick={(e) => this.toggleFeatures()}>
              <SizedChevronDown />
            </span>
          )}

          <div
            className="width_100pct align-items-center justify-content-between d-flex"
            onClick={(e) => this.handleElement()}
          >
            <ZoneContent
              Label={
                <div style={SLIGHTLY_LARGER_FONT}>
                  {label}{" "}
                  <HelpIcon
                    onClick={(e) => (e.stopPropagation(), toggleTooltip(type))}
                  />
                </div>
              }
              cpr={cpr}
              rank={rank}
              total={ranks}
              active={isActive}
            />
          </div>
        </div>

        <div className="d-flex flex-column" style={featuresHeightLimit}>
          {this.state.showFeatures &&
            features.map((feature, i) => (
              <FeatureZone
                {...feature}
                key={`${feature.value}-${i}`}
                active={activeKey === `${feature.type}-${feature.value}`}
                handleFeature={(e) => this.handleFeature(feature)}
                toggleTooltip={(e) => (
                  e.stopPropagation(), toggleTooltip(feature.type)
                )}
              />
            ))}
        </div>
      </div>
    );
  }
}

Zone.defaultProps = {
  cpr: DEFAULT_NUMBER,
  value: DEFAULT_NUMBER,
  rank: 0,
  ranks: 0,
};

const StringNum = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

Zone.propTypes = {
  certainty: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  compared_to: PropTypes.string,
  comparisons: PropTypes.number,
  cpr: StringNum,
  cpr_percentage: StringNum,
  cpr_without: StringNum,
  ctr: StringNum,
  ctr_without: StringNum,
  features: PropTypes.array,
  label: PropTypes.string,
  num_ads: PropTypes.number,
  rank: PropTypes.number,
  ranks: PropTypes.number,
  spend: PropTypes.string,
  spend_without: StringNum,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  // methods
  handleSelection: PropTypes.func.isRequired,
  toggleTooltip: PropTypes.func,
};

export default Zone;

const FeatureZone = ({
  cpr,
  value,
  ranks,
  rank,
  type,
  active,
  handleFeature,
  toggleTooltip,
}) => {
  const display = !value
    ? ""
    : value.length > 15
    ? `${value.slice(0, 12)}...`
    : value;

  return (
    <div
      onClick={handleFeature}
      className="zone basic-border width_98pct align-self-end"
      style={activeBGTesting(active)}
    >
      <ZoneContent
        Label={
          <ZoneLabel>
            <div style={SLIGHTLY_LARGER_FONT}>
              {display} <HelpIcon onClick={toggleTooltip} />
            </div>
            <div className="md-lt-gray">Type: {type}</div>
          </ZoneLabel>
        }
        cpr={cpr}
        rank={rank}
        total={ranks}
        type={type}
        active={active}
      />
    </div>
  );
};
