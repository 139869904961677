import React, { useState, useReducer, useEffect, useMemo } from 'react';

import { useInputState } from './hooks';

import EditorContainer from './Editor';

import { reducer } from './reducer';
import { useAccountId } from '../../../selectors';

import '../FilterBasedCCC.scss';
import {
  formatExistingLabels,
  notifyCreateSuccess,
  notifySaving,
} from './utils';
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from '../../../contexts';
import { ErrorBoundary } from '../../../../../components/Helpers/Error';

export function makeEmptyLabel() {
  return {
    name: '',
    filters: [
      {
        name: 'ad',
        include: '',
        exclude: '',
      },
    ],
  };
}
export const INITIAL_UPDATED = {
  name: false,
  description: false,
  visual_mode: false,
  labels: false,
};

const formatExisting = (ad_name_labels) => {
  if (Array.isArray(ad_name_labels)) return ad_name_labels;

  return Object.entries(ad_name_labels).map(([key, value]) => {
    const filters = Object.entries(value).map(([k, { include, exclude }]) => {
      return {
        name: k,
        include,
        exclude,
      };
    });

    return {
      name: key,
      filters,
    };
  });
};

const getIndex = (existing) => {
  if (existing && Array.isArray(existing)) return 0;
  return -1;
}

const AdNameLabels = ({
  stage,
  prev,
  category,
  isNew,
  data,
  udcApi,
  getAdNameLabelsApi,
  adNameLabelsApi,
}) => {
  const { getDerivedLabelsApi } = useCreativeAnalyticsGet();
  const { setEditCategory } = useCreativeAnalyticsSet();
  const existing = getAdNameLabelsApi.data
    ? getAdNameLabelsApi.data.ad_name_labels
    : null;
  const [{ labels, index }, dispatch] = useReducer(reducer, {
    index: getIndex(existing),
    labels: existing ? formatExisting(existing) : [],
  });
  const [isEditing, setIsEditing] = useState(false);
  const account_id = useAccountId();

  const handleUpdate = (payload) => {
    adNameLabelsApi.request(payload);
    setEditCategory(null);
  };

  if (getAdNameLabelsApi.loading) {
    return (
      <div className="category-manager--loading filter-based-ccc ad-name-labels-ccc">
        <p className="category-manager--loading__msg">Loading...</p>
      </div>
    );
  }

  if (getAdNameLabelsApi.error) {
    return (
      <div className="category-manager--loading filter-based-ccc ad-name-labels-ccc">
        <p className="category-manager--loading__msg">There was an error fetching ad name labels.</p>
      </div>
    );
  }

  return (
    <div className="filter-based-ccc ad-name-labels-ccc">
      {stage === 1 && (
        <ErrorBoundary content="There was an error.">
          <EditorContainer
            onUpdate={handleUpdate}
            {...{
              prev,
              existing,
              account_id,
              labels,
              index,
              dispatch,
              isEditing,
              setIsEditing,
              data,
              getAdNameLabelsApi,
              getDerivedLabelsApi,
            }}
          />
        </ErrorBoundary>
      )}
    </div>
  );
};

export default AdNameLabels;
