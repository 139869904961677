import { useRef, useEffect } from 'react';

import { abortableFetch } from '../../../utils';
import {
  useApiState,
  bindFetchToRef,
} from '../../../components/Hooks/useApiState';
import { useAccountId, useDarwinClientId } from './selectors';

function fetchSkeleton(account_id, breakdown, darwin_client_id) {
  return abortableFetch(
    [
      'api',
      'blocks',
      account_id,
      `block-skeleton?breakdown=${breakdown}&darwin_client_id=${darwin_client_id}`,
    ].join('/'),
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
  );
}

function postPerformance(account_id, payload) {
  const isQuickenLoans = payload.darwin_client_id === '965561022345767';
  const isIdealImage = payload.darwin_client_id === '203458948474117';
  const quickenSheet = '17j5KuIrfZDFB1OmMxyS16LjTBYlXwrMwW2OXwHACk9g';
  const idealSheet = '1HdLcgbnOgcYlrMJXx06udmy0Jl6UOK5ltfAR9WKojCw';
  const havasSheet = '1JJFKeka4k4I52xwfHzpRyayrhtQcdkHyoUaEf04TmaY';

  if (payload.platform.includes('direct mail')) {
    return abortableFetch(
      `https://opensheet.elk.sh/${isQuickenLoans ? quickenSheet : isIdealImage ? idealSheet : havasSheet}/1`,
      {
        method: 'GET',
      }
    );
  } else {
    return abortableFetch(
      ['api', 'blocks', account_id, 'block-performance'].join('/'),
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      }
    );
  }
}

function useSkeleton(account_id, darwin_client_id) {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const getSkeleton = (breakdown) =>
    withRef(fetchSkeleton(account_id, breakdown, darwin_client_id)).then(
      ({ data }) => data
    );
  const [getState, dispatchGet] = useApiState(getSkeleton, {
    loading: true,
    error: null,
    data: null,
    count: 0,
  });

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return {
    ...getState,
    request: dispatchGet,
  };
}

function usePerformance(accountId) {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const getPerformance = (payload) =>
    withRef(postPerformance(accountId, payload)).then(({ data }) => data);
  const [postState, dispatchPost] = useApiState(getPerformance, {
    loading: true,
    error: null,
    data: null,
    count: 0,
  });

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return {
    ...postState,
    request: dispatchPost,
  };
}

function manipulateSheetData(sheetData, payload) {
  let returnObject = {};
  let relevantBlock = sheetData.filter(
    (item) => item['Block Name'] == payload.block_id
  );
  if (relevantBlock) {
    returnObject.block_name = relevantBlock[0]['Block Name'];
    returnObject.column_data = [
      {
        name: 'Period 1',
        spend: relevantBlock[0]['Spend'],
        results: relevantBlock[0]['Results'],
        cpa: relevantBlock[0]['CPA'],
        ctr: relevantBlock[0]['CTR'],
      },
    ];
  } else {
    returnObject.block_name = payload.block_id;
    returnObject.column_data = [
      {
        name: 'Period 1',
        spend: '-',
        results: '-',
        cpa: '-',
        ctr: '-',
      },
    ];
  }
  return returnObject;
}

function useSheetPerformance(accountId) {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const getPerformance = (payload) =>
    withRef(postPerformance(accountId, payload)).then((data) =>
      manipulateSheetData(data, payload)
    );
  const [postState, dispatchPost] = useApiState(getPerformance, {
    loading: true,
    error: null,
    data: null,
    count: 0,
  });

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return {
    ...postState,
    request: dispatchPost,
  };
}

export function useSkeletonApi() {
  const accountId = useAccountId();
  const darwin_client_id = useDarwinClientId();

  return useSkeleton(accountId, darwin_client_id);
}

export function usePerformanceApi() {
  const accountId = useAccountId();
  const darwin_client_id = useDarwinClientId();  
  const isQuickenLoans = darwin_client_id === '965561022345767';
  const isIdealImage = darwin_client_id === '203458948474117';
  const isHavasEDF = darwin_client_id === '230121004617203';
  let response = {};
  
  if (isQuickenLoans || isIdealImage || isHavasEDF ) {
    response = useSheetPerformance(accountId);
  } else {
    response = usePerformance(accountId);
  }
  return response;
}

function fetchDetailSkeleton(blockId, detailType) {
  return abortableFetch(
    ['api', 'blocks', blockId, `details-skeleton?type=${detailType}`].join('/'),
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }
  );
}

function fetchDetailPerformance({
  blockId,
  type,
  block_detail_id,
  start_date,
  end_date,
}) {
  return abortableFetch(
    ['api', 'blocks', blockId, 'details-performance'].join('/'),
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        type,
        block_detail_id,
        start_date,
        end_date,
      }),
    }
  );
}

function mergeData(existing, newer, sortKey) {
  if (existing.length === 0) {
    return [];
  }
  if (newer.length === 0) {
    return existing;
  }
  if (newer.length > 0) {
    newer = JSON.parse(newer);
  }

  const compare = (a, b) => a[sortKey].localeCompare(b[sortKey]);

  existing.sort(compare);
  newer.sort(compare);

  const merged = [];
  let i = 0;
  let j = 0;

  while (existing[i]) {
    const ex = existing[i];
    const d = newer[j];

    if (ex[sortKey] === d[sortKey]) {
      merged.push({ ...ex, ...d });
      i += 1;
      j += 1;
    } else if (ex[sortKey] < d[sortKey]) {
      i += 1;
    } else {
      j += 1;
    }
  }

  return merged;
}

export const ApiStatus = Object.assign(Object.create(null), {
  none: 'none',
  loading: 'loading',
  success: 'success',
  failure: 'failure',
});

function getStatus({ error, loading, data }) {
  if (error) {
    return ApiStatus.failure;
  }
  if (loading) {
    return ApiStatus.loading;
  }
  if (data) {
    return ApiStatus.success;
  }
  return ApiStatus.none;
}

export function useDetailsApi(blockId, type, period1) {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);

  const getSkeleton = async () => withRef(fetchDetailSkeleton(blockId, type));
  const [skeletonState, dispatchSkeleton, rawDispatchSkel] =
    useApiState(getSkeleton);

  const getPerf = async (payload) =>
    withRef(fetchDetailPerformance({ blockId, type, ...payload }));
  const [perfState, dispatchPerf, rawDispatchPerf] = useApiState(getPerf);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    rawDispatchSkel({ type: 'RESET' });
    rawDispatchPerf({ type: 'RESET' });
  }, [blockId]);

  useEffect(() => {
    if (skeletonState.data) {
      const { block_detail_id } = skeletonState.data;
      const [start_date, end_date] = period1.split(',');

      if (block_detail_id) {
        dispatchPerf({ block_detail_id, start_date, end_date });
      }
    }
  }, [skeletonState.data]);

  let skelList = null;

  if (skeletonState.data && skeletonState.data.name_list) {
    skelList = skeletonState.data.name_list;
  }

  let perfList = [];

  if (perfState.data) {
    perfList = perfState.data;
  }

  return {
    data: !skelList ? null : mergeData(skelList, perfList, `${type}_name`),
    request: dispatchSkeleton,
    status: {
      skeleton: getStatus(skeletonState),
      performance: getStatus(perfState),
    },
  };
}

function getBlockSections(account_id, darwin_client_id) {
  return abortableFetch(
    [
      'api',
      'blocks',
      account_id,
      `block-sections?darwin_client_id=${darwin_client_id}`,
    ].join('/'),
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
  );
}

function postBlockSections(account_id, payload, darwin_client_id) {
  const save_sections_payload = {
    account_id: account_id,
    block_order: payload.sectionList,
    darwin_client_id: darwin_client_id,
  };
  return abortableFetch(
    ['api', 'blocks', account_id, 'save-block-sections'].join('/'),
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(save_sections_payload),
    }
  );
}

export function useBlockSectionsApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const account_id = useAccountId();
  const darwin_client_id = useDarwinClientId();
  const [postState, postDispatch] = useApiState(
    (payload) =>
      withRef(postBlockSections(account_id, payload, darwin_client_id)).then(
        ({ data }) => data
      ),
    {
      loading: false,
      error: null,
      data: null,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: postDispatch,
    },
  };
}

export function useGetSections() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const account_id = useAccountId();
  const darwin_client_id = useDarwinClientId();
  const [getState, getDispatch] = useApiState(
    () => withRef(getBlockSections(account_id, darwin_client_id)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: true,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return {
    get: {
      ...getState,
      request: getDispatch,
    },
  };
}
