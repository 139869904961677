import React from 'react';
import { formatCell } from '../CreativePerformance/utils';
import { format } from 'date-fns';
import { toPercent } from '../../../utils/numbers';
import Skeleton from 'react-loading-skeleton';

const formatDate = (date) => format(date, 'P');

export const Display = ({
  mainValue,
  comparedTo,
  start_date,
  end_date,
  previous_start_date,
  previous_end_date,
  metric,
  isPositive,
  percentDiff,
  TrendIcon,
  formatMetric,
  loadingPreviousPeriodData,
}) => {
  let textColor = isPositive ? '#169859' : '#B72E2E';
  if (!percentDiff) textColor = '#1e2b4e';

  if (loadingPreviousPeriodData) {
    return <Skeleton count={6} />
  }
  return (
    <div>
      <p className="period-comparison-display__title">{formatMetric(metric)}</p>
      <div
        className="date-period-section"
        style={{ borderColor: isPositive ? '#B4E2C6' : '#F4CDCD' }}
      >
        <p className="date-period-section__label mb-1">
          {formatDate(start_date)}-{formatDate(end_date)}
        </p>
        <div className="d-flex justify-content-between mb-0">
          <p className="date-period-section__value">
            {formatCell(metric, mainValue)}
          </p>
          <p
            className="d-flex mb-0 date-period-section__value"
            style={{
              color: textColor,
              fontWeight: 500,
            }}
          >
            {TrendIcon}
            <span className="ml-2">{toPercent(percentDiff)}</span>
          </p>
        </div>
      </div>

      <p className="date-period-section__label mb-3 text-center">
        Compared to:
      </p>

      <div className="date-period-section">
        <p className="date-period-section__label mb-1">
          {formatDate(previous_start_date)}-{formatDate(previous_end_date)}
        </p>
        <p className="date-period-section__value">
          {formatCell(metric, comparedTo)}
        </p>
      </div>
    </div>
  );
};
