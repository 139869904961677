import React, { useState, useEffect } from 'react';
import Button from '../../../components/Button';
import Form from 'react-bootstrap/Form';
import { useCreativeAnalyticsGet } from '../contexts';
import { useSearchTermsApi } from '../BasicLabelsSetup/api';

export const GlobalKeywordSettings = ({ searchTermsData }) => {
  const { brand_search_terms, promotion_search_terms, keywords, confidence_threshold, text_threshold } =
  searchTermsData ?? {};

  const api = useSearchTermsApi();
  const { account_id } = useCreativeAnalyticsGet();
  const [minTextSize, setMinTextSize] = useState(text_threshold ?? 0);
  const [confidenceThreshold, setConfidenceThreshold] = useState(confidence_threshold ?? 0);


  const submit = () => {
    const payload = {
      platform_account_id: account_id,
      brand_search_terms: brand_search_terms ?? [],
      promotion_search_terms: promotion_search_terms ?? [],
      keywords: keywords ?? [],
      confidence_threshold: confidenceThreshold,
      text_threshold: minTextSize,
    };

    api.post.request(payload);
  };

  useEffect(() => {
    if (api.post.data) {
      console.log(api.post.data);
    }
  }, [api.post.data]);

  return (
    <div className="settings-form">
      <div className="settings-form__content">
        <NumberInput
          text="Min. text size threshold"
          subtext="When running labels for visuals, only include keywords containing at least this number of characters of text, unless specified otherwise in the report card."
          step={0.1}
          max={1}
          min={0}
          value={minTextSize}
          onChange={(e) => setMinTextSize(parseFloat(e.target.value))}
          className="mt-3"
        />

        <NumberInput
          text="Confidence threshold"
          subtext="When running labels for visuals, only include keywords that have this level of confidence."
          step={0.1}
          max={1}
          min={0}
          value={confidenceThreshold}
          onChange={(e) => setConfidenceThreshold(parseFloat(e.target.value))}
          className="mt-3"
        />
      </div>

      <div className="settings-form__footer">
        <Button className="m-3" onClick={submit}>
          Submit
        </Button>
      </div>
    </div>
  );
};

const NumberInput = ({ text, subtext, ...rest }) => {
  return (
    <div className="input-item d-block">
      <div>
        <h4 className="input-item__text">{text}</h4>
        {!!subtext && <p className="input-item__subtext">{subtext}</p>}
      </div>
      <Form.Control type="number" {...rest} />
    </div>
  );
};
