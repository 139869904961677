import React from 'react';

export const ArrowUpIcon = ({}) => {
  return <i style={{ color: '#169859' }} className="fa-solid fa-arrow-trend-up"></i>;
};

export const ArrowDownIcon = ({}) => {
  return (
    <i style={{ color: '#B72E2E' }} className="fa-solid fa-arrow-trend-down"></i>
  );
};
