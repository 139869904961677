import { EMPTY_PHRASE, EMPTY_RULE } from "./constants";

export const SET_PHRASE_DATA = "set-phrase-data";
export const SET_ORIGINAL = "set-original";

export const ADD_PHRASE = "add-phrase";
export const ADD_RULE = "add-rule";
export const ADD_INCLUSION = "add-inclusion";
export const ADD_EXCLUSION = "add-exclusion";

export const REMOVE_PHRASE = "remove-phrase";
export const REMOVE_RULE = "remove-rule";
export const REMOVE_INCLUSION = "remove-inclusion";
export const REMOVE_EXCLUSION = "remove-exclusion";

export const UPDATE_PHRASE_NAME = "update-phrase-name";
export const RESET_PHRASE = "reset-phrase";

export function phraseBuilderReducer(state, action) {
  const payload = action.payload;

  const { phraseIndex, ruleIndex, keyword, name, phrases, existingLabels } = {
    ...payload,
    phraseIndex:
      payload.phraseIndex || payload.phraseIndex === 0
        ? payload.phraseIndex
        : null,
    ruleIndex:
      payload.ruleIndex || payload.ruleIndex === 0 ? payload.ruleIndex : null,
    keyword: payload.keyword || "",
    name: payload.name || "",
    phrases: payload.phrases || [],
    existingLabels: payload.existingLabels || [],
  };

  switch (action.type) {
    case SET_PHRASE_DATA:
      return {
        ...state,
        phrases: [...phrases, EMPTY_PHRASE],
        // original: EMPTY_PHRASE,
        existingLabels: [...existingLabels],
      };

    case SET_ORIGINAL:
      return {
        ...state,
        original: payload,
      }

    case RESET_PHRASE:
      return {
        ...state,
        phrases: state.phrases.map((phrase, i) =>
          i === phraseIndex
          ? payload.phrase
          : phrase
        ), 
        original: EMPTY_PHRASE,
      }

    case ADD_PHRASE:
      return {
        ...state,
        phrases: [...state.phrases, payload],
      };

    case ADD_RULE:
      return {
        ...state,
        phrases: state.phrases.map((phrase, i) =>
          i === phraseIndex
            ? { ...phrase, rules: [...phrase.rules, EMPTY_RULE] }
            : phrase
        ),
      };

    case ADD_INCLUSION:
      return {
        ...state,
        phrases: state.phrases.map((phrase, i) =>
          i === phraseIndex
            ? {
                ...phrase,
                rules: phrase.rules.map((rule, ix) =>
                  ix === ruleIndex
                    ? { ...rule, include: [...rule.include, keyword] }
                    : rule
                ),
              }
            : phrase
        ),
      };

    case ADD_EXCLUSION:
      return {
        ...state,
        phrases: state.phrases.map((phrase, i) =>
          i === phraseIndex
            ? {
                ...phrase,
                rules: phrase.rules.map((rule, ix) =>
                  ix === ruleIndex
                    ? { ...rule, exclude: [...rule.exclude, keyword] }
                    : rule
                ),
              }
            : phrase
        ),
      };

    case REMOVE_INCLUSION:
      return {
        ...state,
        phrases: state.phrases.map((phrase, i) =>
          i === phraseIndex
            ? {
                ...phrase,
                rules: phrase.rules.map((rule, ix) =>
                  ix === ruleIndex
                    ? {
                        ...rule,
                        include: rule.include.filter((k) => k !== keyword),
                      }
                    : rule
                ),
              }
            : phrase
        ),
      };

    case REMOVE_EXCLUSION:
      return {
        ...state,
        phrases: state.phrases.map((phrase, i) =>
          i === phraseIndex
            ? {
                ...phrase,
                rules: phrase.rules.map((rule, ix) =>
                  ix === ruleIndex
                    ? {
                        ...rule,
                        exclude: rule.exclude.filter((k) => k !== keyword),
                      }
                    : rule
                ),
              }
            : phrase
        ),
      };

    case REMOVE_RULE:
      return {
        ...state,
        phrases: state.phrases.map((phrase, i) =>
          i === phraseIndex
            ? {
                ...phrase,
                rules: phrase.rules.filter((_, i) => i !== ruleIndex),
              }
            : phrase
        ),
      };

    case REMOVE_PHRASE:
      return {
        ...state,
        phrases: state.phrases.filter((_, i) => i !== phraseIndex),
      };

    case UPDATE_PHRASE_NAME:
      return {
        ...state,
        phrases: state.phrases.map((phrase, i) =>
          i === phraseIndex ? { ...phrase, name: name } : phrase
        ),
      };
  }
}
