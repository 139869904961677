import React from 'react';
import { Link } from 'react-router-dom';

export const DashboardItemDropdown = ({
  itemId,
  handleShowModalWithFooter,
  handleHideModal,
  handleRemove,
  deleteOnly = false,
}) => {
  const Footer = () => (
    <>
      <button className="btn btn-light" onClick={handleHideModal}>
        No
      </button>

      <button
        type="button"
        className="btn btn-danger"
        onClick={(e) => (handleHideModal(), handleRemove())}
      >
        Yes
      </button>
    </>
  );

  const DropdownButton = ({ id }) => (
    <div className="dropdown">
      <button
        className="btn btn-link"
        id={id}
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i className="fa fa-bars" />
      </button>

      <div className="dropdown-menu" aria-labelledby={id}>
        {!deleteOnly && (
          <div className="dropdown-item">
            <Link
              to={window.location.pathname.replace(
                'dashboard',
                `create-new-report?itemId=${itemId}`,
              )}
            >
              Edit
            </Link>
          </div>
        )}
        <div
          className="dropdown-item"
          onClick={() => handleShowModalWithFooter(<Footer />)}
        >
          Delete
        </div>
      </div>
    </div>
  );

  return <DropdownButton id={`${itemId}-dropdown`} />;
};

const DASH_CARD_STYLE = { border: 'none', borderRadius: 0 };

const DashboardItem = ({ title, children, dropdown = null }) => (
  <div className="card darwin-box-shadow" style={DASH_CARD_STYLE}>
    <div className="card-body">
      <div className="card-title d-flex flex-row justify-content-between align-items-baseline">
        {title}
        {!!dropdown && dropdown}
      </div>
      {children}
    </div>
  </div>
);

export default DashboardItem;
