import React from 'react';
import { useDispatch } from 'react-redux';
import { setRightDrawerDisplay } from '../../../store/CreativeAnalytics/creativeAnalyticsSlice';
import { toCurrency } from '../../../utils/numbers';
import CollapsibleItem from '../../../components/CollapsibleItem/CollapsibleItem';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';

const Header = ({ close }) => {
  return (
    <div className="right-drawer-header">
      <p>Missing Data</p>
      <div className="d-flex">
        <button className="right-drawer-header__icon btn-reset" onClick={close}>
          <i className="fa fa-xmark" />
        </button>
      </div>
    </div>
  );
};

const List = ({ missingData }) => {
  const { isCopied, copyToClipboard, copiedText } = useCopyToClipboard();

  return (
    <ul className="missing-data-list">
      {missingData.map(({ label, visuals, spend, ad_ids, visual_hashes }) => (
        <li>
          <CollapsibleItem
            AnchorChild={
              <>
                <div>
                  <p className="missing-data-list__name">{label}</p>
                  <p className="missing-data-list__spend">
                    {!!spend ? toCurrency(spend) : 'N/A'}
                  </p>
                </div>
                <div className="missing-data-list__visuals">
                  <p>{!!visuals ? `${visuals} visuals` : 'N/A'}</p>
                </div>
              </>
            }
            ExpandableChild={
              <>
                {(ad_ids || visual_hashes) &&
                (ad_ids.length || visual_hashes.length) ? (
                  <div className="missing-data-list__ids">
                    <div>Ad IDs:</div>
                    <ul>
                      {[...ad_ids, ...visual_hashes].map((id) => (
                        <li>{id}</li>
                      ))}
                    </ul>
                    {isCopied && <div className="copied">Copied!</div>}
                    <button
                      className="copy-button"
                      onClick={() =>
                        copyToClipboard(
                          [...ad_ids, ...visual_hashes].join(', ')
                        )
                      }
                    >
                      <i className="fa-regular fa-copy" />
                    </button>
                  </div>
                ) : (
                  <div>No ids to display.</div>
                )}
              </>
            }
          />
        </li>
      ))}
    </ul>
  );
};

export const Layout = ({ missingData }) => {
  const dispatch = useDispatch();

  return (
    <div className="missing-data">
      <Header close={() => dispatch(setRightDrawerDisplay(null))} />
      <List missingData={missingData} />
    </div>
  );
};
