import React, { useMemo } from 'react';
import { useReportCardContext } from '../contexts';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { SET_METRICS, TOGGLE_METRIC } from '../reducer';
import EditorItem from './EditorItem';
import { getFilteredMetrics } from '../../VisualLibrary/LibraryStats/filtered-metrics';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  borderRadius: '7px',
  userSelect: 'none',
  padding: '0.5rem',
  background: isDragging ? '#FFEFB5' : '',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#FFF9E3' : '#fff',
  padding: grid,
  width: 'fit-content',
  height: 'fit-content',
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const MetricEditor = ({}) => {
  const {
    metric_order,
    dispatchReportCardSettings,
    metricsData,
    isBestPerformingView,
    filteredMetrics,
  } = useReportCardContext();

  const extendMetrics = (metric_order, metricsData) => {
    let result = [...metric_order];

    metricsData.forEach((metric) => {
      if (!metric_order.some((opt) => opt.metric === metric.metric)) {
        result.push(metric);
      }
    });

    return result;
  };

  const options = useMemo(() => {
    const allMetrics = extendMetrics(metric_order, metricsData);

    return allMetrics.reduce((acc, custom_metric, i) => {
      const match = metricsData.find((x) => x.metric === custom_metric.metric);
      if (!match) return acc;

      acc.push({
        ...custom_metric,
        metricIndex: i,
        value: match.value,
      });

      return acc;
    }, []);
  }, [metric_order, metricsData]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const ordered = reorder(
      options,
      result.source.index,
      result.destination.index
    );

    dispatchReportCardSettings({
      type: SET_METRICS,
      metric_order: ordered,
    });
  };

  const handleToggle = (metric) => {
    dispatchReportCardSettings({
      type: TOGGLE_METRIC,
      metric,
      filteredMetrics,
    });
  };

  return (
    <div className="rc-dropdown__submenu metric-editor">
      {!options || !options.length ? (
        <p>Metric data not available.</p>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable2">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                className={`dnd-container ${
                  snapshot.isDraggingOver ? 'is-dragging-over' : ''
                }`}
              >
                {options.map((metric, i) => (
                  <Draggable
                    key={metric.metric}
                    draggableId={metric.metric}
                    index={metric.metricIndex}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        className="dnd-item"
                      >
                        <EditorItem
                          metric={metric}
                          enabled={metric.enabled}
                          isDragging={snapshot.isDragging}
                          isDraggingOver={snapshot.isDraggingOver}
                          isBestPerformingView={isBestPerformingView}
                          handleToggle={handleToggle}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
};

export default MetricEditor;
