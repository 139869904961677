import React, { createContext, useContext, useReducer, useState } from 'react';
import { ReportCardProvider } from '../../ReportCard/contexts';
import { usePhraseBuilderApi } from '../../PhraseBuilder/api';

export const QaContext = createContext(null);

const QaContextProvider = ({ qaState, qaDispatch, visualsData, children }) => {
  return (
    <QaContext.Provider
      value={{
        qaState,
        qaDispatch,
        visualsData,
      }}
    >
      {children}
    </QaContext.Provider>
  );
};

export default QaContextProvider;

export function useQaContext() {
  return useContext(QaContext);
}
