import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const styles = (route) => (route === 'dashboard'
  ? { className: 'col-12 mt-2 mb-0', style: { marginLeft: '0.7rem' } }
  : { className: 'col-12 my-2' });

const Header = ({ currentRoute }) => (
  <div className="row">
    <div {...styles(currentRoute)}>
      {currentRoute === 'dashboard' ? (
        <Link to="create-new-report">Create New Report</Link>
      ) : (
        <Link to="dashboard">Dashboard</Link>
      )}
    </div>
  </div>
);

Header.propTypes = {
  currentRoute: PropTypes.string.isRequired,
};

export default Header;
