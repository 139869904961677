import React from 'react';
import Button from '../../../../components/Button';

const RevisionRequestForm = ({
  revision_type,
  setRevisionType,
  emailInput,
  setEmailInput,
  additional_message,
  setAdditionalMessage,
  handleSubmit,
}) => {
  const handleSelect = (selection) => setRevisionType(selection);

  return (
    <div className="revision-select">
      <h1 className="revision-select__title">Choose Revision Type</h1>
      <div
        onClick={() => handleSelect('darwin_talent')}
        className={`revision-select__option ${
          revision_type === 'darwin_talent'
            ? 'revision-select__option--active'
            : ''
        }`}
      >
        <h2>Darwin Talent</h2>
        <p>
          Let Darwin resources make the revision for you. Assets will be ready
          to review in Creative Studio in less than 48 hours.
        </p>
      </div>
      <div
        onClick={() => handleSelect('custom_email')}
        className={`revision-select__option ${
          revision_type === 'custom_email'
            ? 'revision-select__option--active'
            : ''
        }`}
      >
        <h2>Custom Email Address</h2>
        <p>
          Route the revision request to an email address of your choosing. User
          will need to have a login to view the full request.
        </p>
        {revision_type === 'custom_email' && (
          <input
            className="revision-select__input mt-3"
            style={{ background: '#fff' }}
            type="text"
            placeholder="Enter comma separated email addresses"
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
          />
        )}
      </div>

      <div className="mb-3 mt-2">
        <p className="">Add additonal instructions (optional)</p>
        <textarea
          className="revision-select__input"
          type="text"
          value={additional_message}
          onChange={(e) => setAdditionalMessage(e.target.value)}
        />
      </div>

      <Button
        disabled={!revision_type}
        onClick={handleSubmit}
        className="category-setup__btn"
      >
        Submit
      </Button>
    </div>
  );
};

export default RevisionRequestForm;
