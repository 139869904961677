import React, { useReducer } from 'react';
import { DataState, VariationAction } from '../types';
import { EMPTY_ROW, initialDataState } from '../constants';

export const ADD_ROW = 'ADD_ROW';
export const REMOVE_ROW = 'REMOVE_ROW';
export const SET_BASE_VISUAL = 'SET_BASE_VISUAL';
export const SET_VARIANT_DATA = 'SET_VARIANT_DATA';
export const TOGGLE_FAVORITE = 'TOGGLE_FAVORITE';
export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const SET_PRIMARY_METRIC = 'SET_PRIMARY_METRIC';

export const variationReducer = (
  state: DataState,
  action: VariationAction
): DataState => {
  switch (action.type) {
    case ADD_ROW:
      return {
        ...state,
        rows: [...state.rows, EMPTY_ROW],
      };

    case REMOVE_ROW:
      return {
        ...state,
        rows: state.rows.filter((_, i) => i !== action.rowIndex),
      }

    case SET_BASE_VISUAL:
      return {
        ...state,
        rows: state.rows.map((row, i) =>
          i === action.rowIndex ? { ...row, baseVisual: action.visual } : row
        ),
      };

    case SET_VARIANT_DATA:
      return {
        ...state,
        rows: state.rows.map((row, i) =>
          i === action.rowIndex ? { ...row, variantData: action.data } : row
        ),
      };

    case TOGGLE_FAVORITE:
      return {
        ...state,
        rows: state.rows.map((row, i) =>
          i === action.rowIndex
            ? {
                ...row,
                variantData: row.variantData.map((variant, ix) =>
                  ix === action.variantIndex
                    ? {
                        ...variant,
                        data: { ...variant.data, favorite: action.favorite },
                      }
                    : variant
                ),
              }
            : row
        ),
      };

    case SET_LOADING:
      return {
        ...state,
        rows: state.rows.map((row, i) =>
          i === action.rowIndex
            ? {
                ...row,
                loading: row.loading ? false : true,
              }
            : row
        ),
      };

    case SET_ERROR:
      return {
        ...state,
        rows: state.rows.map((row, i) =>
          i === action.rowIndex
            ? {
                ...row,
                error: action.error,
              }
            : row
        ),
      };
    
    case SET_PRIMARY_METRIC:
      return  {
        ...state,
        rows: state.rows.map((row, i) =>
          i === action.rowIndex
            ? {
                ...row,
                primaryMetric: action.metric,
              }
            : row
        ),
      };

    default:
      return {
        ...state,
      };
  }
};

export const useVariationState = () => {
  const [variationState, variationDispatch] = useReducer(
    variationReducer,
    initialDataState
  );

  return { variationState, variationDispatch };
};
