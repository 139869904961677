import React, { useEffect, useRef, useState } from 'react';
import './Carousel.scss';
import Slide from './Slide';

const Carousel = ({
  slides,
  handleBlockValue,
  setShowCarousel,
  draftCreativeDispatch,
  rowIndex,
}) => {
  const ref = useRef();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const [slide, setSlide] = useState(null);

  useEffect(() => {
    if (slides.length > 0 && slideIndex >= slides.length) {
      setSlideIndex(slides.length - 1);
    }
  }, [slides]);

  useEffect(() => {
    const found = slides.find((c, i) => {
      if (i === slideIndex) return c;
    });
    if (found) setSlide(found);
  }, [slides, slideIndex]);

  console.log({slide});

  return (
    <div ref={ref} className="draft-creative-carousel">
      <div onClick={() => setShowCarousel(false)} style={{ cursor: 'pointer' }}>
        <i className="fa fa-close" />
      </div>
      {slides.length > 1 ? (
        <div className="d-flex justify-content-center align-items-center draft-creative-carousel__nav-container">
          <div className="draft-creative-carousel__nav">
            <div
              className="draft-creative-carousel__arrow"
              onClick={() => setSlideIndex(0)}
            >
              <i className="fa-solid fa-angles-left"></i>
            </div>
            <div
              ref={prevRef}
              className="draft-creative-carousel__arrow draft-creative-carousel__prev"
              onClick={() => {
                if (slideIndex === 0) return;
                setSlideIndex((prev) => prev - 1);
              }}
            >
              <i className="fa-solid fa-angle-left"></i>
            </div>
            <div className="px-2">
              {slideIndex + 1} of {slides.length}
            </div>
            <div
              ref={nextRef}
              className="draft-creative-carousel__arrow draft-creative-carousel__next"
              onClick={() => {
                if (slideIndex === slides.length - 1) return;
                setSlideIndex((prev) => prev + 1);
              }}
            >
              <i className="fa-solid fa-angle-right"></i>
            </div>
            <div
              className="draft-creative-carousel__arrow"
              onClick={() => setSlideIndex(slides.length - 1)}
            >
              <i className="fa-solid fa-angles-right"></i>
            </div>
          </div>
        </div>
      ) : null}
      {slide && (
        <Slide
          data={slide}
          {...{
            handleBlockValue,
            setShowCarousel,
            draftCreativeDispatch,
            rowIndex,
          }}
        />
      )}
    </div>
  );
};

export default Carousel;
