import { useGetMedian } from '../utils';

export const getVisualsData = (metric, allVisuals, isROAS) => {
  let result = [...allVisuals];

  if (metric === 'cpa') {
    return result.sort((a, b) => {
      if (a[metric] === 0 && b[metric] !== 0) return 1;
      if (b[metric] === 0 && a[metric] !== 0) return -1;
      if (a[metric] < b[metric]) return -1;
      if (a[metric] > b[metric]) return 1;
    });
  }

  return result.sort((a, b) => {
    if (a[metric] === 0 && b[metric] !== 0) return 1;
    if (b[metric] === 0 && a[metric] !== 0) return -1;
    if (a[metric] > b[metric]) return -1;
    if (a[metric] < b[metric]) return 1;
  });
};

export const getSections = (
  filteredData,
  metric,
  isROAS
) => {
  let messageTheme = [];
  let visualTheme = [];
  let attribute = [];
  let keyword = [];

  filteredData.forEach((label) => {
    let category = label.category ? label.category.toLowerCase() : null;
    const winners = label.winners || [];
    const losers = label.losers || [];
    const visuals = [...winners, ...losers];

    visuals.forEach((visual) => {
      if (category) {
        if (
          category.includes('messaging theme') ||
          category.includes('message theme')
        ) {
          let existing = messageTheme.find(
            (messageThemeLabel) => messageThemeLabel.label === label.value
          );

          if (existing) {
            if (
              !existing.visuals.some(
                (existingVisual) => visual.id === existingVisual.id
              )
            ) {
              existing.visuals.push(visual);
            }
          } else {
            messageTheme.push({ label: label.name, visuals: [visual] });
          }
        } else if (category.includes('visual theme')) {
          let existing = visualTheme.find(
            (visualThemeLabel) => visualThemeLabel.label === label.value
          );

          if (existing) {
            if (
              !existing.visuals.some(
                (existingVisual) => visual.id === existingVisual.id
              )
            ) {
              existing.visuals.push(visual);
            }
          } else {
            visualTheme.push({ label: label.name, visuals: [visual] });
          }
        } else if (category.includes('attribute')) {
          let existing = attribute.find(
            (attributeLabel) => attributeLabel.label === label.value
          );

          if (existing) {
            if (
              !existing.visuals.some(
                (existingVisual) => visual.id === existingVisual.id
              )
            ) {
              existing.visuals.push(visual);
            }
          } else {
            attribute.push({ label: label.name, visuals: [visual] });
          }
        } else if (category.includes('keyword')) {
          let existing = keyword.find(
            (keywordLabel) => keywordLabel.label === label.name
          );

          if (existing) {
            if (
              !existing.visuals.some(
                (existingVisual) => visual.id === existingVisual.id
              )
            ) {
              existing.visuals.push(visual);
            }
          } else {
            keyword.push({ label: label.name, visuals: [visual] });
          }
        }
      }
    });
  });

  const sortArgs = {
    metric,
    isROAS,
  };

  const messageThemeSorted = sortLabels(messageTheme, sortArgs);
  const visualThemeSorted = sortLabels(visualTheme, sortArgs);
  const attributeSorted = sortLabels(attribute, sortArgs);
  const keywordSorted = sortLabels(keyword, sortArgs);

  return {
    messageTheme: messageThemeSorted,
    visualTheme: visualThemeSorted,
    attribute: attributeSorted,
    keyword: keywordSorted,
  };
};

const sortLabels = (group, sortArgs) => {
  const { metric, isROAS } = sortArgs;

  return group
    .map((label) => {
      const { visuals } = label;
      const spend = getSpend(visuals);
      const cpa = useGetCPA(visuals, isROAS);
      const median_cpa = useGetMedian(visuals, 'cpa');
      const median_roas = useGetMedian(visuals, 'roas');

      return {
        ...label,
        visuals: getVisualsData(metric, visuals, isROAS),
        spend,
        cpa,
        median_cpa,
        median_roas,
      };
    })
    .sort((a, b) => {
      let aMetric = a[metric];
      let bMetric = b[metric];

      if (metric === 'cpa') {
        const cpaMetric = isROAS ? 'median_roas' : 'median_cpa';
        aMetric = a[cpaMetric]
        bMetric = b[cpaMetric]
        //aMetric = Number(a[cpaMetric].replace(/[^0-9.-]+/g, ''));
        //bMetric = Number(b[cpaMetric].replace(/[^0-9.-]+/g, ''));

        if (isROAS) {
          if (aMetric > bMetric) return -1;
          if (aMetric < bMetric) return 1;
        } else {
          // ascending with NaN last
          if(isFinite(aMetric - bMetric)) {
            return aMetric - bMetric;
          } else {
            return isFinite(aMetric) ? -1 : 1;
          }
        }
      }

      if (aMetric > bMetric) return -1;
      if (aMetric < bMetric) return 1;
    });
};

export const getSpend = (ads) => {
  let sum = 0;

  ads.forEach((ad) => {
    sum += ad.spend;
  });

  return sum;
};

export const useGetCPA = (ads, isROAS) => {
  let total_spend = 0;
  let total_results = 0;

  ads.forEach((ad) => {
    total_spend += ad.spend;
    total_results += ad.results;
  });

  const cpa = total_spend / total_results;
  const roas = 1 / (total_spend / total_results);

  return Number.isFinite(total_spend / total_results)
    ? isROAS
      ? roas
      : cpa
    : 0;
};
