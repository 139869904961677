import React from 'react';
import Button from '../../../../../components/Button';

const Sidebar = ({
  prev,
  handleSelectNew,
  renderLabelsList,
}) => {
  const goBack = () => {
    if (window.confirm('Going back will revert unsaved changes.')) {
      prev();
    }
  }
  return (
    <div className="filter-based-ccc__sidebar">
      <div>
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex justify-content-between">
            <button className="sidebar__back" onClick={goBack}>
              <i className="fas fa-arrow-left"></i>
              Setup
            </button>
          </div>
        </div>

        {renderLabelsList()}
        <button onClick={handleSelectNew} className="add-new-btn">
          + Add new label
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
