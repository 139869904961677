import { useReducer } from "react";
import { impactReportReducer } from "./reducer";

export const useImpactReport = () => {
  const [
    {
      selectedReport,
      selectedCategory,
      selectedLabel,
    },
    dispatchImpactReport,
  ] = useReducer(impactReportReducer, {
    selectedReport: {},
    selectedCategory: "",
    selectedLabel: "",
  });

  return {
    selectedReport,
    selectedCategory,
    selectedLabel,
    dispatchImpactReport,
  };
};
