import { useState } from "react";
import { useSelector } from "react-redux";

import { useViewId } from "../Hooks/view-id";
import { useUrlProps } from "../../features/CreativeReports/report-hooks";

/**
 * @return {{ view_id: string, account_id: string, campaigns: [string], startDate: string, endDate: string}}
 */
export function useViewProps() {
  const view_id = useViewId();
  const { account_id, campaigns } = useSelector(
    ({ ViewSettings: { account_id, campaigns } }) => ({
      account_id,
      campaigns,
    })
  );
  const { start_date, end_date } = useUrlProps();

  return {
    view_id,
    account_id,
    campaigns,
    start_date,
    end_date,
  };
}

export function useSelectedStudy(data) {
  const lookup = arrayToMap(data, (item) => [item.value, item]);
  const [study, _setStudy] = useState({ value: "" });

  const setStudy = (_id, message = "") => {
    const shouldBeEmpty =
      _id === "" || message === "REMOVED" || study.value === _id;

    if (shouldBeEmpty) {
      _setStudy({ value: "" });
    } else {
      const found = lookup.get(_id) || { value: _id };

      _setStudy(found);
    }
  };

  return [study, setStudy];
}

function arrayToMap(arr, setter) {
  let results = new Map();

  for (const item of arr) {
    results.set(...setter(item));
  }

  return results;
}
