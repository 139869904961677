//@ts-check
import React from "react";
import PropTypes from "prop-types";
import MemberDropdown from "./MemberDropdown";

import { memberToSelection } from "./select-derivers";

const ModifyForSelect = ({ members, availableMembers, children }) => {
  return children({
    members: members.map(memberToSelection),
    availableMembers: availableMembers.map(memberToSelection),
  });
};

const MemberGroup = ({
  members,
  availableMembers,
  addMemberName,
  updateMethods,
  size,
  disabled,
}) => (
  <span>
    {members.map((m, i) => (
      <div className="mb-2" key={`${m.name}-${i}`}>
        <MemberDropdown
          disabled={disabled}
          options={availableMembers}
          selected={m}
          onChange={(newMember) => {
            if (newMember) {
              updateMethods.update(m, newMember);
            } else {
              updateMethods.remove(m);
            }
          }}
          size={size}
        />
      </div>
    ))}

    <MemberDropdown
      disabled={disabled}
      selected={null}
      placeholder={addMemberName}
      options={availableMembers}
      onChange={(member) => updateMethods.add(member)}
      size={size}
    />
  </span>
);

MemberGroup.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      // other properties are optional ?
      index: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  availableMembers: PropTypes.arrayOf(
    PropTypes.shape({
      // other properties are optional ?
      name: PropTypes.string,
      type: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  addMemberName: PropTypes.string.isRequired,
  updateMethods: PropTypes.object.isRequired,
};

export default (props) => (
  <ModifyForSelect
    members={props.members}
    availableMembers={props.availableMembers}
  >
    {(derivedProps) => <MemberGroup {...props} {...derivedProps} />}
  </ModifyForSelect>
);
