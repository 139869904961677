import { toPercent_2 } from '../../../../../utils/numbers';
import { isNewAd } from '../../../filter-utils';
import {
  CUSTOM_SCORING_METRICS,
  CUSTOM_SCORING_VALUES_LOOKUP,
  NOOM_CUSTOM_METRICS,
  SKY_ZONE_CUSTOM_METRICS,
} from '../../../PerformanceFrequency/constants';
import { findPerformanceScore } from '../../../utils/findPerformanceScore';
import { getSumsAndAverages } from '../../../utils/getSumsAndAverages';
import { normalizeVisuals } from '../../../utils/normalize';
import { customPartition } from './customPartition';

export const checkIfVisualIsValid = ({
  visual,
  spendThreshold,
  minResults,
  visualIdsFromSelectedLabels,
  primary_metric,
}) => {
  const metric = primary_metric.replace('median_', '');

  let customMetric = metric?.includes('cp_') ? metric.replace('cp_', '') : null;

  const predicates = [
    (visual) => visual.spend >= spendThreshold,
    (visual) => visual.results >= minResults,
    (visual) => !!visual.asset_url?.length,
    (visual) => !customMetric || visual[customMetric] >= minResults,
    (visual) => checkIfVisualIsSelected(visual, visualIdsFromSelectedLabels),
  ];

  return predicates.every((filter) => filter(visual));
};

const checkIfPrimaryMetricIsCustom = ({
  primary_metric,
  customIntegratedMetrics = [],
  customEvents = [],
  clientMetrics = [],
  additional_metrics = [],
}) => {
  if (
    additional_metrics?.some(({ value }) => {
      return primary_metric.includes(value);
    })
  ) {
    return true;
  }

  if (
    customIntegratedMetrics?.some(({ value }) => {
      return value === primary_metric;
    })
  ) {
    return true;
  }

  if (
    clientMetrics?.some((metric) => {
      return metric === primary_metric;
    })
  ) {
    return true;
  }

  if (
    customEvents?.some(({ name }) => {
      return name === primary_metric;
    })
  ) {
    return true;
  }

  return false;
};

export const filterVisuals = ({
  visuals,
  spendThreshold,
  minResults,
  visualIdsFromSelectedLabels,
  primary_metric,
  isOrganicView,
  clientMetrics,
  additional_metrics,
  newAds,
  selectedVisuals,
  customIntegratedMetrics,
  customEvents,
  custom_performance_scores,
  visual_success_conditions,
  sumsAndAverages: existingSumsAndAverages = null,
  customScoringData,
  isNoom,
  isSkyZone,
  isWisdomPanel,
  selectedTab,
}) => {
  let ids = [];
  let winnerIds = [];
  let loserIds = [];

  const filteredVisuals = [...visuals]
    .filter((visual) => {
      const isValid = checkIfVisualIsValid({
        visual,
        spendThreshold,
        minResults,
        visualIdsFromSelectedLabels,
        primary_metric,
      });
      return isValid;
    })
    .sort((a, b) => b.spend - a.spend);

  const { totalImpressions, numOfVisuals } = ((filteredVisuals) => {
    const totalImpressions = filteredVisuals.reduce((acc, curr) => {
      acc += curr.impressions;
      return acc;
    }, 0);

    return {
      totalImpressions,
      numOfVisuals: filteredVisuals.length,
    };
  })(filteredVisuals);

  const withMetrics = filteredVisuals.map(
    addMetrics({
      clientMetrics,
      additional_metrics,
      newAds,
      selectedVisuals,
      customIntegratedMetrics,
      customEvents,
      custom_performance_scores,
      totalImpressions,
      numOfVisuals,
      customScoringData,
      isNoom,
      isSkyZone,
      isWisdomPanel,
      selectedTab,
    })
  );

  const sumsAndAverages =
    existingSumsAndAverages ??
    getSumsAndAverages(
      withMetrics,
      isOrganicView,
      clientMetrics,
      customScoringData,
      isNoom,
      isSkyZone,
      isWisdomPanel
    );

  const primaryMetricIsCustom = checkIfPrimaryMetricIsCustom({
    primary_metric,
    clientMetrics,
    additional_metrics,
    customIntegratedMetrics,
    customEvents,
  });

  const result = withMetrics.map(
    partitionWinnersAndLosers({
      sumsAndAverages,
      primary_metric,
      primaryMetricIsCustom,
      isOrganicView,
      visual_success_conditions,
      pushId: (id, isWinner) => {
        ids.push(id);
        if (isWinner) {
          winnerIds.push(id);
        } else {
          loserIds.push(id);
        }
      },
    })
  );

  const normalized = normalizeVisuals(result);

  return {
    filteredAllVisuals: result,
    visualsData: normalized,
    // byId: result.reduce((lookup, obj) => {
    //   lookup[obj.id] = obj;
    //   return lookup;
    // }, {}),
    sumsAndAverages,
    filteredVisualIds: ids,
    filteredWinnerIds: winnerIds,
    filteredLoserIds: loserIds,
    winners: result.filter((visual) => visual.isWinner),
    losers: result.filter((visual) => visual.isLoser),
    totalImpressions,
  };
};

const addMetrics =
  ({
    clientMetrics,
    additional_metrics,
    newAds,
    selectedVisuals,
    customIntegratedMetrics,
    customEvents,
    custom_performance_scores,
    totalImpressions,
    numOfVisuals,
    customScoringData,
    isNoom,
    isSkyZone,
    isWisdomPanel,
    selectedTab,
  }) =>
  (visual) => {
    const cpa = isFinite(visual.spend / visual.results)
      ? visual.spend / visual.results
      : 0;
    const cpm =
      visual.impressions === 0 ? 0 : (visual.spend / visual.impressions) * 1000;
    const cpc = visual.clicks === 0 ? 0 : visual.spend / visual.clicks;
    const ctr =
      visual.impressions === 0 ? 0 : visual.clicks / visual.impressions;
    const roas = cpa === 0 ? 0 : 1 / cpa;
    const cpm_reach =
      visual.reach === 0 ? 0 : (visual.spend / visual.reach) * 1000;
    const isNew = isNewAd(visual, newAds);

    const custom = {};
    const cpClientMetrics = {};
    const customScoringMetrics = {};

    const isWpPro =
      isWisdomPanel && selectedTab?.tab_name.includes('Prospecting');
    const isWpRtg =
      isWisdomPanel && selectedTab?.tab_name.includes('Retargeting');

    const wisdomPanelCustomMetrics =
      isWpPro || isWpRtg
        ? {
            [`WP ${isWpPro ? 'PRO' : 'RTG'} ROAS`]: isWpPro
              ? roas * 0.45
              : roas * 0.2,
          }
        : {};

    const noomCustomMetrics = isNoom
      ? NOOM_CUSTOM_METRICS.reduce((acc, metric) => {
          if (
            [
              'Signups',
              'LTV',
              'offsite_conversion.fb_pixel_custom.AcceptedTerms',
            ].includes(metric)
          ) {
            acc[metric] = visual[metric];
            const costPerMetric =
              visual[metric] !== 0 ? visual.spend / visual[metric] : null;
            acc[`cp_${metric}`] = costPerMetric;
          } else if (visual['Signups'] > 0) {
            if (metric === 'cp_Signups') {
              acc[metric] = visual.spend / visual['Signups'];
            }

            if (metric === 'Average LTV') {
              acc[metric] = visual['LTV'] / visual['Signups'];
            }

            if (metric === 'LTV to CAC Ratio') {
              acc[metric] =
                visual['LTV'] /
                visual['Signups'] /
                (visual.spend / visual['Signups']);
            }
          } else {
            acc[metric] = 0;
          }

          return acc;
        }, {})
      : {};

    const skyZoneCustomMetrics = isSkyZone
      ? SKY_ZONE_CUSTOM_METRICS.reduce((acc, metric) => {
          if (visual.spend > 0) {
            if (metric === 'Ticket ROAS') {
              const webPurchase =
                visual['onsite_web_purchase'] -
                (visual['offsite_conversion.fb_pixel_custom.purchase_group'] +
                  visual[
                    'offsite_conversion.fb_pixel_custom.purchase_membership'
                  ] +
                  visual['offsite_conversion.fb_pixel_custom.purchase_party'] +
                  visual[
                    'offsite_conversion.fb_pixel_custom.PurchaseLittleLeaper'
                  ] +
                  visual[
                    'offsite_conversion.fb_pixel_custom.PurchaseAnnualPass'
                  ]);

              const revenue = (webPurchase * 32) / visual.spend;
              acc[metric] = revenue;
            } else if (metric === 'Annual Pass ROAS') {
              acc[metric] =
                (visual[
                  'offsite_conversion.fb_pixel_custom.PurchaseAnnualPass'
                ] *
                  195) /
                visual.spend;
            } else if (metric === 'Discounted Annual Pass ROAS') {
              acc[metric] =
                (visual[
                  'offsite_conversion.fb_pixel_custom.PurchaseAnnualPass'
                ] *
                  175) /
                visual.spend;
            }
          } else {
            acc[metric] = 0;
          }

          return acc;
        }, {})
      : {};

    if (customScoringData.length) {
      CUSTOM_SCORING_METRICS.forEach((metric) => {
        const match = customScoringData.find((v) => v.Visual === visual.id);
        if (match) {
          const value = CUSTOM_SCORING_VALUES_LOOKUP[metric];
          customScoringMetrics[metric] = match[value];
        }
      });
    }

    if (clientMetrics && !isNoom) {
      [...clientMetrics, ...additional_metrics].forEach((metric) => {
        if (visual.hasOwnProperty(metric)) {
          const metricValue = visual[metric];
          const costPerMetric =
            metricValue !== 0 ? visual.spend / metricValue : null;
          cpClientMetrics[`cp_${metric}`] = costPerMetric;
        }
      });
    }

    if (customIntegratedMetrics && !isNoom) {
      [...customIntegratedMetrics].forEach(({ label, value }) => {
        if (visual.hasOwnProperty(value)) {
          const metricValue = visual[value];
          const costPerMetric =
            metricValue !== 0 ? visual.spend / metricValue : null;
          cpClientMetrics[`cp_${value}`] = costPerMetric;
        }
      });
    }

    if (customEvents) {
      [...customEvents].forEach(
        ({ name, calculation, numerator, denominator }) => {
          let metricValue = 0;

          try {
            if (calculation === 'share_of_impressions') {
              metricValue = visual.impressions / totalImpressions ?? 0;
            } else if (calculation === 'share_of_assets') {
              metricValue = 1 / numOfVisuals ?? 0;
            } else {
              metricValue = visual[numerator] / visual[denominator];
            }

            if (!metricValue || typeof metricValue !== 'number') {
              custom[name] = 0;
            } else if (!isFinite(metricValue)) {
              custom[name] = 0;
            } else {
              custom[name] = metricValue;
            }
          } catch (e) {
            console.error(`Error adding custom event data. ${e}`);
            custom[name] = 0;
          }
        }
      );
    }
    return {
      ...visual,
      isNew,
      cpm,
      cpc,
      ctr,
      cpa,
      roas,
      cpm_reach,
      isSelected: selectedVisuals.includes(visual.id),
      custom_performance_score: findPerformanceScore(
        visual,
        custom_performance_scores
      ),
      ...custom,
      ...cpClientMetrics,
      ...customScoringMetrics,
      ...noomCustomMetrics,
      ...skyZoneCustomMetrics,
      ...wisdomPanelCustomMetrics,
    };
  };

const partitionWinnersAndLosers =
  ({
    sumsAndAverages,
    primary_metric,
    primaryMetricIsCustom,
    isOrganicView,
    visual_success_conditions,
    pushId,
  }) =>
  (visual) => {
    const isWinner: boolean = checkIfWinner(
      visual,
      sumsAndAverages,
      primary_metric,
      isOrganicView,
      visual_success_conditions,
      primaryMetricIsCustom
    );

    pushId(visual.id, isWinner);

    return {
      ...visual,
      isWinner,
      isLoser: !isWinner,
    };
  };

export const checkIfVisualIsSelected = (
  visual,
  visualIdsFromSelectedLabels
) => {
  if (visualIdsFromSelectedLabels.size < 1) return true;
  return visualIdsFromSelectedLabels.has(visual.id);
};

const checkIfWinner = (
  visual,
  sumsAndAverages,
  metric,
  isOrganicView,
  visual_success_conditions,
  primaryMetricIsCustom
): boolean => {
  const primary_metric = metric.replace('median_', '');
  if (visual_success_conditions.length) {
    return customPartition(visual, visual_success_conditions, sumsAndAverages);
  }
  const {
    cpmAverage,
    cpcAverage,
    ctrAverage,
    cpaAverage,
    roasAverage,
    cpmReachAverage,
  } = sumsAndAverages;

  const isCustomMetric = primaryMetricIsCustom;
  const primary_value =
    isCustomMetric || isOrganicView
      ? visual[primary_metric]
      : primary_metric === 'Rockerbox_Roas'
      ? visual['value_Rockerbox_ROAS'] / visual['spend']
      : visual['results'];

  if (!primary_value) {
    return false;
  } else {
    if (primary_metric.toLowerCase() === 'cpm') {
      return visual.cpm < cpmAverage;
    } else if (primary_metric.toLowerCase() === 'cpc') {
      return visual.cpc < cpcAverage;
    } else if (primary_metric.toLowerCase() === 'ctr') {
      return visual.ctr >= ctrAverage;
    } else if (primary_metric.toLowerCase() === 'cpa') {
      return visual.cpa < cpaAverage;
    } else if (primary_metric.toLowerCase() === 'roas') {
      return visual.roas > roasAverage;
    } else if (primary_metric.toLowerCase() === 'cpm_reach') {
      return visual.cpm_reach > cpmReachAverage;
    } else if (CUSTOM_SCORING_METRICS.includes(primary_metric)) {
      return (
        visual[primary_metric] > sumsAndAverages[`average_${primary_metric}`]
      );
    } else if (isCustomMetric) {
      const withoutCP = primary_metric.replace('cp_', '');
      const average = sumsAndAverages[`average_${withoutCP}`] || null;

      const cpMetricValue = visual[primary_metric];

      if (!cpMetricValue) {
        return false;
      } else {
        if (
          NOOM_CUSTOM_METRICS.includes(primary_metric) &&
          primary_metric !== 'Cost per Signup'
        ) {
          return cpMetricValue > average;
        }
        return cpMetricValue < average;
      }
    } else if (primary_metric === 'Rockerbox_Roas') {
      const average = sumsAndAverages[`average_value_Rockerbox_ROAS`];
      const cpMetricValue = visual['value_Rockerbox_ROAS'] / visual['spend'];

      if (!cpMetricValue) {
        return false;
      } else {
        return cpMetricValue > average;
      }
    } else if (isOrganicView) {
      const average = sumsAndAverages[`average_${primary_metric}`] || null;

      if (!visual[primary_metric]) {
        return false;
      } else {
        return visual[primary_metric] > average;
      }
    }
  }
};
