import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import joi from 'joi';
import Button from '../../../../components/Button';
import { ApiStatus, FilterTypes } from '../../../BulkActions/BlockManager/constants';
import CategorySelect from '../../../BulkActions/BlockManager/CategorySelect';
import { useDarwinClientId } from '../selectors';

const FILTER_NAMES = ['campaign', 'adset', 'ad'];
const DEFAULT_FILTER = { include: '', exclude: '' };

function setFilters(filters) {
  const lookup = filters.reduce((acc, filter) => {
    acc[filter.name] = filter;

    return acc;
  }, {});

  return FILTER_NAMES.map((name) => {
    if (lookup[name]) {
      return lookup[name];
    }
    return { ...DEFAULT_FILTER, name };
  });
}

function makeSchemaValidator(joiSchema) {
  return (value) => {
    const { error } = joiSchema.validate(value);

    return !error;
  };
}

function useInputState(initialValue = '', validator) {
  const [val, setV] = useState(initialValue);

  return {
    value: val,
    valid: validator(val),
    handleChange: setV,
  };
}

function getEmptyBlock(platforms, darwin_client_id) {
  return {
    id: `IGNORE-${Date.now().toString()}`,
    name: '',
    category: '',
    platforms: platforms.map(account_platform => { return account_platform.value }),
    darwin_client_id: darwin_client_id,
    filters: [
      {
        name: 'campaign', include: '', exclude: '',
      },
      {
        name: 'adset', include: '', exclude: '',
      },
      {
        name: 'ad', include: '', exclude: '',
      },
    ],
  };
}

function BlockForm({ data, predeterminedCategory, categories, onSubmit, onClose, formType, blockId, refreshBlocks, handleDelete, platformOptions }) {
  const darwin_client_id = useDarwinClientId();
  const emptyBlock = getEmptyBlock(platformOptions, darwin_client_id);
  const [id, setId] = useState(emptyBlock.id);
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [confirm, setConfirm] = useState(false);
  const handleClose = onClose;
  const defaultCategory = predeterminedCategory ? predeterminedCategory : emptyBlock.category;
  const Name = useInputState(emptyBlock.name, makeSchemaValidator(joi.string().min(1).required()));
  const Category = useInputState(defaultCategory, makeSchemaValidator(joi.string().min(1).required()));
  const Filters = useInputState(
    setFilters(emptyBlock.filters),
    makeSchemaValidator(joi.array().items(joi.object({
      name: joi.string().min(1).optional(),
      include: joi.string().allow('').optional(),
      exclude: joi.string().allow('').optional(),
    }))),
  );

  const handleFilterChange = (e) => {
    const [i,, strType] = e.target.name.split(':');

    Filters.handleChange((prev) => {
      const clone = [...prev];

      clone[i][strType] = e.target.value;

      return clone;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onClose();

    return onSubmit({
      id,
      platforms: [selectedPlatform && selectedPlatform.value ? selectedPlatform.value : selectedPlatform],
      name: Name.value,
      category: Category.value,
      filters: Filters.value,
    }).then(() => {
      if (refreshBlocks) refreshBlocks();
    });
  };

  useEffect(() => {
    if (formType === 'Edit') {
      const { name, category, filters, platforms } = data.filter((block) => block.id === blockId)[0];
      const element = document.getElementById("ad-form-row");

      setId(blockId);
      Name.handleChange(name);
      Category.handleChange(category);
      Filters.handleChange(filters);
      setSelectedPlatform(platforms[0]);
      
      if (platforms.includes('google_ads')) {
        element.classList.add("hidden");
      }
    }
  }, [blockId]);

  useEffect(() => {    
    const element = document.getElementById("ad-form-row");

    if (selectedPlatform.value === 'google_ads') {
      element.classList.add("hidden");
    } else if (selectedPlatform.value === 'facebook') {
      element.classList.remove("hidden");
    }
  }, [selectedPlatform.value]);

  const saveDisabled = !(Name.valid && Category.valid);

  const onDelete = () => {
    if (confirm) {
      handleDelete();
      setConfirm(false);
    } else {
      setConfirm(true);
    }

  }

  return (
      <div className="block-form">
        <div className="d-flex flex-row justify-content-between">
          <h3 className="mb-4"><strong>{formType} Segment</strong></h3>
          <div className="d-flex align-items-start">
            <Button appearance="subtle" className="" onClick={handleClose} disabled={blockId.includes('IGNORE')}>
              Cancel
            </Button>
            <Button className="ml-2" onClick={handleSubmit} disabled={saveDisabled}>
              Save
            </Button>
          </div>
        </div>
        <div style={{ height: '80vh' }}>
          {status === ApiStatus.failure && <p className="text-danger">Your previous action failed</p>}
          <form>
            <div className="form-group">
              <label>Platform</label>
              <Select
                options={platformOptions}
                value={platformOptions.length && platformOptions.find((p) => p.value === selectedPlatform)}
                onChange={(value) => setSelectedPlatform(value)}
              />
            </div>

            <div className="form-group">
              <label>Block Name</label>
              <input
                max={100}
                type="text"
                className="form-control"
                value={Name.value}
                placeholder="Enter a name..."
                onChange={(e) => Name.handleChange(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>Block Category</label>
              <CategorySelect
                categories={categories}
                value={Category.value}
                disabled={predeterminedCategory}
                onChange={Category.handleChange}
                maxInputLength={50}
              />
            </div>

            <div style={{ color: '#666' }} className="d-flex flex-row align-items-baseline mt-4 mb-2">
              Filter Strings (optional)
              &emsp;
              <small className="form-text text-muted">Exclusions support comma-separated entries</small>
            </div>

            {Filters.value && Filters.value.map((filter, i) => (
              <FilterItem
                key={filter.name}
                name={filter.name}
                include={filter.include}
                exclude={filter.exclude}
                index={i}
                onChange={handleFilterChange}
              />
            ))}
          </form>
          {formType === 'Edit' && (
            <Button appearance="red" className="mt-3" style={{ float: 'right'}} onClick={onDelete} disabled={blockId.includes('IGNORE')}>
              {confirm ? 'Confirm?' : 'Delete'}
            </Button>
          )}
        </div>
      </div>
  );
}

BlockForm.defaultProps = {
  data: [],
  blockId: ''
}

BlockForm.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    category: PropTypes.string,
    platforms: PropTypes.arrayOf(PropTypes.string),
    filters: PropTypes.arrayOf(FilterTypes),
  })),
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  formType: PropTypes.string.isRequired,
  blockId: PropTypes.string,
  refreshBlocks: PropTypes.func.isRequired
}

export default BlockForm;

function FilterItem({
  onChange, name, include, exclude, index,
}) {
  const handleChange = (e) => {
    e.persist();

    return onChange(e);
  };

  return (
    <div className="form-group" id={`${name === 'ad' ? 'ad-form-row' : ''}`}>
      <label>
        <strong>
          {`${name[0].toUpperCase()}${name.slice(1)}`}
        </strong>
      </label>
      <div className="form-row">
        <div className="col">
          <label>Include</label>
          <input
            max={300}
            type="text"
            name={`${index}:${name}:include`}
            onChange={handleChange}
            value={include}
            className="form-control"
          />
        </div>
        <div className="col">
          <label>Exclude</label>
          <input
            max={300}
            type="text"
            name={`${index}:${name}:exclude`}
            onChange={handleChange}
            value={exclude}
            className="form-control"
          />
        </div>
      </div>
    </div>
  );
}

FilterItem.propTypes = {
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  include: PropTypes.string.isRequired,
  exclude: PropTypes.string.isRequired,
};
