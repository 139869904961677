import React, { useRef } from 'react';
import { Layout } from './Layout';
import './index.scss';
import { useClickOutside } from '../../../hooks/useClickOutside';

export const Wrapper = ({
  groupParams,
  setGroupParams,
  metadata,
  setShowCreativeSelect,
  isEditing,
  setIsEditing,
  allRows,
}) => {
  const ref = useRef();
  useClickOutside(ref, () => setShowCreativeSelect((prev) => false));

  return (
    <div ref={ref} className="creatives-select-wrapper">
      <Layout
        {...{ groupParams, setGroupParams, isEditing, setIsEditing, allRows }}
      />
    </div>
  );
};
