import { connect } from "react-redux";

const stateToProps = (
  { ViewSettings: { loggedIn, allowedAdmin, viewId } },
  props
) => ({
  loggedIn,
  allowedAdmin,
  viewId,
});

export default connect(stateToProps, null);
