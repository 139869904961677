import React, { useState } from 'react';
import Toggle from '../../../components/BeagleToggle';
import { useCreativeAnalyticsGet } from '../contexts';
import {
  ADDITIONAL_TOGGLE,
  TESTING_OPPS_TOGGLE,
} from '../hooks/useCategoryFilters';

export const MoreSettings = ({ dispatchCategoryFilter }) => {
  const { categoryFilters } = useCreativeAnalyticsGet();

  const toggleAdditionalCategories = () =>
    dispatchCategoryFilter({ type: ADDITIONAL_TOGGLE });
  const toggleTestingOpportunities = () =>
    dispatchCategoryFilter({ type: TESTING_OPPS_TOGGLE });

  return (
    <div className="settings-form">
      <ToggleItem
        text="Show additional categories"
        subtext="Include categories that are disabled"
        onToggle={toggleAdditionalCategories}
        checked={categoryFilters.showAdditionalCategories}
        disabled={categoryFilters.showOnlyComplianceCategories}
        id="additional-toggle"
      />
      <ToggleItem
        text="Show testing opportunities"
        subtext="Include labels that have not been tested for the current spend threshold, audience, and date range."
        onToggle={toggleTestingOpportunities}
        checked={categoryFilters.showTestingOpportunities}
        id="testing-opps-toggle"
      />
    </div>
  );
};

const ToggleItem = ({ text, subtext, onToggle, checked, disabled, id }) => {
  return (
    <div className="input-item">
      <div>
        <h4 className="input-item__text">{text}</h4>
        <p className="input-item__subtext">{subtext}</p>
      </div>
      <Toggle
        onChange={onToggle}
        checked={checked}
        disabled={disabled}
        id={id}
      />
    </div>
  );
};