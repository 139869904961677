import React from "react";
import PropTypes from "prop-types";

const BOLD_FONT = { fontWeight: "bold" };

const PerformancePanel = ({ label, selection }) => {
  if (label === "explanation") {
    return <Explanations sections={selection} />;
  } else {
    const { rank, ranks } = selection;

    if (rank > 0 && ranks > 0) {
      return <Details {...selection} label={label} />;
    } else {
      return <UnknownRank label={label} />;
    }
  }
};

PerformancePanel.propTypes = {
  label: PropTypes.string.isRequired,
  selection: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default PerformancePanel;

const Explanations = ({ sections }) => (
  <div>
    <h4>
      <strong>Why Test This Ad?</strong>
    </h4>

    {sections.map(({ name, explanation }, i) => (
      <div key={`${name}-${i}`}>
        <h4>{name}</h4>
        <p>{explanation}</p>
      </div>
    ))}
  </div>
);

Explanations.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      explanation: PropTypes.string,
    })
  ),
};

const Details = ({
  label,
  cpr_percentage,
  spend,
  cpr,
  spend_without,
  cpr_without,
  certainty,
  num_ads,
  comparisons,
  compared_to,
  rank,
  ranks,
}) => (
  <div>
    <h4>
      <strong>{formatLabel(label)} Performance</strong>
    </h4>

    <p>
      Advertisements containing this element perform{" "}
      <span style={BOLD_FONT}>{cpr_percentage} </span>
      {betterOrWorse(cpr_percentage)} than ads without them.
    </p>

    <table className="table table-bordered table-hover table-striped">
      <thead>
        <tr>
          <th>Type</th>
          <th>Spend</th>
          <th>CPR</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>With Element</td>
          <td>{spend}</td>
          <td>{cpr}</td>
        </tr>
        <tr>
          <td>Without Element</td>
          <td>{spend_without}</td>
          <td>{cpr_without}</td>
        </tr>
      </tbody>
    </table>

    {certainty && (
      <h4>
        Certainty: <span>{certainty}</span>
      </h4>
    )}

    <CertaintyText
      num_ads={num_ads}
      comparisons={comparisons}
      compared_to={compared_to}
      certainty={certainty}
      spend={spend}
    />

    <h4>
      Rank {rank} of {ranks}
    </h4>
  </div>
);

Details.propTypes = {
  label: PropTypes.string.isRequired,
  cpr_percentage: PropTypes.string,
  spend: PropTypes.string,
  cpr: PropTypes.string,
  spend_without: PropTypes.string,
  cpr_without: PropTypes.string,
  certainty: PropTypes.string,
  num_ads: PropTypes.number,
  comparisons: PropTypes.number,
  compared_to: PropTypes.string,
  rank: PropTypes.number,
  ranks: PropTypes.number,
};

const betterOrWorse = (cpr_pct) =>
  !cpr_pct || cpr_pct[0] === "-" ? "worse" : "better";

const UnknownRank = ({ label }) => (
  <div>
    <h4>
      <strong>{formatLabel(label)} Performance</strong>
    </h4>
    <p>
      This is an untested element. Creating ads with this element will allow you
      to test the effectiveness of this element to make better data driven
      creative decisions.
    </p>
  </div>
);

UnknownRank.propTypes = {
  label: PropTypes.string,
};

export const formatLabel = (type) => {
  switch (type) {
    case "atf":
      return "Primary Copy";

    case "btf":
      return "Headline";

    case "cta":
      return "Button";

    case "visual":
      return "Visual";

    case "keyword":
      return "Keyword";

    case "color":
      return "Color";

    case "trigger":
      return "Trigger";

    case "explanation":
      return "";

    default:
      return "";
  }
};

const CertaintyText = ({
  certainty,
  num_ads,
  compared_to,
  comparisons,
  spend,
}) => {
  if (!certainty) {
    return null;
  } else if (certainty === "Needs More Variation") {
    return (
      <p>
        This element has been used in a total of {num_ads} ads. It also has only
        been paired with {comparisons} {compared_to} element(s). It also has
        spent only {spend} during the period. Create more ads with this element
        to increase the certainty and add variation.
      </p>
    );
  } else {
    return (
      <p>
        This element has been used in a total of {num_ads} ads. It also has been
        paired with {comparisons} {compared_to} element(s) and spent {spend}{" "}
        during the period. There is high confidence in this data. You should try
        different variations to avoid stale creative.
      </p>
    );
  }
};

CertaintyText.propTypes = {
  certainty: PropTypes.string,
  num_ads: PropTypes.number,
  compared_to: PropTypes.string,
  comparisons: PropTypes.number,
  spend: PropTypes.string,
};
