import { useMemo } from 'react';

export const useCategoryData = (
  selectedLabel: string | null,
  darwinIqData: any,
  datasetConfig: any,
  selectedCategories: string[]
) => {
  const [selectedCategory, selectedLabelName] = useMemo(
    () => selectedLabel?.split('->') ?? [null, null],
    [selectedLabel]
  );

  const category_id = useMemo(
    () =>
      darwinIqData?.category_performance_data?.find(
        (c) => c.category_name === selectedCategory
      )?.['category_id'],
    [darwinIqData, selectedCategory]
  );

  const selectedCategoryData = useMemo(
    () =>
      darwinIqData?.category_details?.find(
        (x) => x.category_id === category_id
      ),
    [darwinIqData, category_id]
  );

  const data = useMemo(
    () => darwinIqData?.[datasetConfig.field] || [],
    [darwinIqData, datasetConfig]
  );

  const dataByCategory = useMemo(() => {
    return new Map(
      selectedCategories.map((category_name) => [
        category_name,
        data.filter((label) => label.category_name === category_name),
      ])
    );
  }, [selectedCategories, data]);

  return {
    selectedCategory,
    selectedLabelName,
    category_id,
    selectedCategoryData,
    data,
    dataByCategory,
  };
};
