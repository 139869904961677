import { Marketplace } from '../actions';
import { Assets } from './actions';

function prepAssets(data, ids) {
  const lookup = {};

  ids.forEach((id) => {
    const { versions, ...rest } = data[id];

    // In case no versions are returned in an asset.
    // Prevents project-editor crashing for a "client".
    if (versions.length > 0) {
      lookup[id] = {
        ...rest,
        versionIds: [...versions]
          .sort((a, b) => a.created - b.created)
          .map(({ version }) => version),
      };
    }
  });

  return lookup;
}

/**
 * @typedef {Object} ActionMeta
 * @property {string} filename
 * @property {string} versionId
 * @property {string} project_id
 * @property {string} contractor
 * @property {string} placeholderUpload
 *
 * @param {Object} lookup
 * @param {Object} action
 * @param {import('./Asset').AssetVersion} action.data
 * @param {ActionMeta} action.meta
 */
function setUpload(
  { ids, lookup, cachedView },
  { meta: { filename, versionId, contractor, placeholderUpload } }
) {
  const asset = lookup[filename];

  if (asset) {
    if (placeholderUpload) {
      return {
        ids,
        lookup: {
          ...lookup,
          [filename]: { ...asset, versionIds: [versionId], placeholder: false },
        },
        cachedView,
      };
    }
    return {
      ids,
      lookup: {
        ...lookup,
        [filename]: { ...asset, versionIds: [...asset.versionIds, versionId] },
      },
      cachedView,
    };
  }
  return {
    ids: [...ids, filename],
    lookup: {
      ...lookup,
      [filename]: {
        filename,
        contractor,
        s3_filename: '',
        versionIds: [versionId],
        placeholder: false,
      },
    },
  };
}

function removeUpload({ ids, lookup, cachedView }, { data: { filename, versionId } }) {
  const asset = lookup[filename];

  return {
    ids,
    lookup: {
      ...lookup,
      [filename]: {
        ...asset,
        versionIds: asset.versionIds.filter((id) => id !== versionId),
      },
    },
    cachedView,
  };
}

function addAsset({ ids, lookup, cachedView }, { filename, asset }) {
  const existingCount = ids.reduce((count, id) => {
    if (id === filename && lookup[filename]) {
      return count + 1;
    }
    return count;
  }, 0);

  if (existingCount > 0) {
    const update = `${filename}_${existingCount + 1}`;
    return {
      ids: [...ids, update],
      lookup: {
        ...lookup,
        [update]: {
          ...asset,
          filename: update,
        },
      },
    };
  }

  return {
    ids: [...ids, filename],
    lookup: {
      ...lookup,
      [filename]: asset,
    },
    cachedView,
  };
}

function setAsset(lookup, { filename, update }) {
  const asset = lookup[filename];

  if (!asset) {
    return {
      ...lookup,
      [filename]: { ...asset, ...update },
    };
  }

  return { ...lookup, [filename]: { ...asset, ...update } };
}

export function assets(state = { ids: [], lookup: {}, cachedView: '' }, action) {
  switch (action.type) {
    case Assets.SET:
      return {
        ...state,
        lookup: prepAssets(action.data, action.byIds),
        ids: action.byIds,
      };
    case Assets.SET_CACHED_VIEW:
      return {
        ...state,
        cachedView: action.data.view_id,
      };

    case Assets.ADD_ONE:
      return addAsset(state, action.data);

    case Assets.UPDATE_ONE:
      return {
        ids: state.ids,
        lookup: setAsset(state.lookup, action.data),
        cachedView: state.cachedView,
      };

    case Marketplace.UPLOAD_FILE:
      return setUpload(state, action);

    case Marketplace.UPLOAD_FAILURE:
      return removeUpload(state, action);

    case Marketplace.RESET_ASSETS:
      return {
        lookup: {},
        ids: [],
        cachedView: state.cachedView,
      };

    default:
      return state;
  }
}
