import React, { useState } from 'react';
import Menu from './components/Menu/Menu';
import ToggleButton from './components/ToggleButton/ToggleButton';

import './LeftMenu.scss';
import { useCreativeAnalyticsGet } from '../contexts';
import DisplaySelect from './components/DisplaySelect/DisplaySelect';
import ElementPerformance from './ElementPerformance';
import { ChevronDoubleRightIcon } from '../CreativeRequest/icons';
import { ChevronDoubleDownIcon } from '../ReportCard/icons';
import { MenuIcon } from './icons/menu';
import Tooltip from '../../../components/Tooltip';
import { useViewGroupContext } from '../../ViewGroup/context';

const isStudio = window.location.pathname.includes('studio');
const isRoadmap = window.location.pathname.includes('roadmap');

const LeftMenu: React.FC = () => {
  // const { selected } = useCreativeAnalyticsGet();
  const { mainView } = useViewGroupContext();
  const [collapsed, setCollapsed] = useState(true);

  const handleMenuItemClick = (menuItem: string) => {
    console.log(`Clicked on menu item: ${menuItem}`);
  };

  return (
    <div
      className={`left-menu__container ${collapsed ? 'collapsed' : ''}`}
      style={{
        marginTop: '56px',
        width: collapsed ? 'auto' : '240px',
      }}
    >
      <Menu
        // loading={loading}
        // mode={mode}
        onMenuItemClick={handleMenuItemClick}
        // setIsSettingsOpen={setIsSettingsOpen}
        // isDirectMail={isDirectMail}
        // isNonPaidMediaType={isNonPaidMediaType}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        mainView={mainView}
      />
      {/* {isTableMode && <ElementPerformance data={selected.element} />} */}
    </div>
  );
};

export default LeftMenu;
