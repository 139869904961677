
import React from 'react';

import './styles.css';

export function SaveDisk({ style = {}, className = '' }) {
  return (
    <i className={`fa fa-floppy-o ${className}`} style={style} aria-hidden />
  );
}

export function CircleCheck({ style = {}, className = '' }) {
  return (
    <i
      className={`fa fa-check-circle-o ${className}`}
      style={style}
      aria-hidden
    />
  );
}

export function FilledFlag({ style = {}, className = '' }) {
  return (
    <i className={`fa fa-flag ${className}`} style={style} aria-hidden />
  );
}

export function CircleTimes({ style = {}, className = '' }) {
  return (
    <i
      className={`fa fa-times-circle-o ${className}`}
      style={style}
      aria-hidden
    />
  );
}

export function TrashBin({
  height = 32,
  width = 32,
  viewBox = '0 0 132 150',
  disabled = false,
}) {
  const circleClass = disabled ? 'st-disabled' : 'st0';

  return (
    <svg
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={circleClass}
    >
      <g>
        <path
          d="M65.8,49.3c-16.2,0-32.4,0-48.5,0c-0.6,0-1.2,0-1.9,0c-2.6-0.1-5-0.8-7.2-2.2c-2.8-1.7-4.7-4-5.9-7
		c-1-2.4-1.2-4.9-0.9-7.4c0.5-4.1,2.5-7.5,5.8-10.1c1.5-1.2,3.3-2,5.2-2.5c1.3-0.3,2.6-0.5,4-0.5c9.1,0,18.1,0,27.2,0
		c0.7,0,0.9-0.1,1.1-0.9c0.7-2.9,1.8-5.7,3.6-8.1c1.2-1.6,2.5-3.1,4-4.3c1.6-1.3,3.4-2.4,5.3-3.2c1.9-0.8,3.8-1.2,5.8-1.5
		c1.3-0.2,2.5-0.3,3.8-0.2c3.6,0.3,6.9,1.3,10,3.2c2.3,1.5,4.3,3.3,6,5.5c1.2,1.7,2.2,3.4,2.9,5.4c0.4,1,0.7,2.1,0.9,3.2
		c0.1,0.5,0.3,0.8,1,0.8c6.1,0,12.3,0,18.4,0c3.2,0,6.4,0,9.6,0c3.2,0.1,6.1,1.1,8.7,2.9c2.1,1.5,3.8,3.5,4.8,5.9
		c0.5,1.1,0.9,2.4,1,3.6c0.1,0.9,0.3,1.9,0.2,2.8c-0.4,4.8-2.3,8.7-6.2,11.6c-1.6,1.2-3.3,1.9-5.2,2.4c-0.7,0.2-1.5,0.4-2.3,0.4
		c-13.2,0-26.4,0-39.6,0C73.5,49.3,69.7,49.3,65.8,49.3C65.8,49.3,65.8,49.3,65.8,49.3z M65.9,26.5c-16.5,0-33,0-49.6,0
		c-1.2,0-2.4,0.3-3.4,0.8c-3.8,1.9-5.4,6.2-3.7,10c1.4,3,3.9,4.7,7.3,4.8c8.8,0,17.6,0,26.4,0c24.1,0,48.2,0,72.3,0
		c1.1,0,2.2-0.2,3.2-0.7c2.5-1.1,4.1-3.1,4.7-5.7c0.5-2.2,0.1-4.3-1.4-6.1c-1.6-2-3.7-3.1-6.3-3.1C98.9,26.5,82.4,26.5,65.9,26.5z
		 M65.5,19.6C65.5,19.6,65.5,19.6,65.5,19.6c4.4,0,8.8,0,13.2,0c0.7,0,0.8-0.2,0.6-0.8c-0.3-0.6-0.5-1.2-0.8-1.9
		c-1.1-2.5-2.9-4.5-5.2-6c-1.4-0.9-2.8-1.5-4.4-1.8c-1.1-0.2-2.2-0.4-3.3-0.4c-1.4,0.1-2.9,0.2-4.3,0.7c-1.8,0.6-3.5,1.5-4.9,2.7
		c-2,1.7-3.4,3.8-4.3,6.1c-0.5,1.3-0.5,1.3,0.9,1.3C57.1,19.6,61.3,19.6,65.5,19.6z"
        />
        <path
          d="M14.2,94.7c0-11.4,0-22.7,0-34.1c0-1.2,0.2-2.3,1.2-3.1c1.7-1.4,4.4-1,5.5,0.9c0.3,0.6,0.5,1.2,0.5,1.9
		c0,12.9,0,25.8,0,38.7c0,9.5,0,18.9,0,28.4c0,3.3,1.1,6.1,3.1,8.7c1.5,2,3.5,3.4,5.7,4.3c1.2,0.5,2.5,0.9,3.9,1
		c6.7,0.5,13.3,0.2,20,0.2c9.6,0.1,19.2,0,28.8,0c4.2,0,8.3,0.1,12.5,0c4.5-0.1,8.3-1.8,11.2-5.3c1.2-1.5,2.1-3.1,2.7-5
		c0.5-1.6,0.6-3.2,0.6-4.8c0-19.3,0-38.6,0-57.9c0-2.5,0-5,0-7.6c0-0.9,0.1-1.7,0.5-2.6c0.6-1.3,2.9-2.1,4.2-1.6
		c1,0.4,1.9,0.9,2.3,1.9c0.3,0.6,0.4,1.3,0.4,2c0,16.6,0,33.3,0,49.9c0,5.7,0,11.4,0,17.1c0,1.6-0.3,3.2-0.7,4.8
		c-0.6,2.4-1.6,4.6-3,6.7c-1.3,2-3,3.7-4.9,5.2c-1.6,1.3-3.3,2.2-5.2,3c-1.1,0.5-2.2,0.8-3.3,1c-3.8,0.9-7.7,0.5-11.6,0.5
		c-17.5,0-35,0-52.4,0c-1.8,0-3.6-0.2-5.4-0.6c-2.4-0.6-4.7-1.6-6.7-2.9c-2.6-1.7-4.8-3.9-6.4-6.5c-0.9-1.4-1.6-3-2.2-4.6
		c-1-2.7-1.2-5.5-1.2-8.3C14.2,115.6,14.2,105.1,14.2,94.7C14.2,94.7,14.2,94.7,14.2,94.7z"
        />
        <path
          d="M43.6,96.3c0,10.1,0,20.2,0,30.3c0,2-1.2,3.6-2.9,4.1c-1.8,0.6-4.1-0.5-4.4-2.7c-0.3-2.1-0.2-4.3-0.2-6.4
		c0-18.4,0-36.8,0-55.2c0-1.3,0.1-2.7,1.3-3.6c1.9-1.4,4.7-0.9,5.7,1.3c0.3,0.6,0.4,1.3,0.4,2C43.6,76.1,43.6,86.2,43.6,96.3z"
        />
        <path
          className="st0"
          d="M69.3,96.3c0,10.1,0,20.2,0,30.2c0,2.1-0.8,3.5-2.6,4.1c-1.7,0.6-3.7-0.2-4.4-2c-0.3-0.8-0.3-1.6-0.3-2.4
		c0-20.2,0-40.4,0-60.6c0-1.4,0.6-2.6,1.8-3.2c1.9-1,4.4-0.5,5.3,1.9c0.1,0.2,0.1,0.5,0.1,0.8c0,5.9,0,11.8,0,17.6
		C69.3,87.3,69.3,91.8,69.3,96.3C69.3,96.3,69.3,96.3,69.3,96.3z"
        />
        <path
          d="M88,96.3c0-10.2,0-20.4,0-30.5c0-1,0.2-1.9,0.8-2.7c0.7-0.9,2.9-1.4,3.9-0.9c1.8,0.8,2.5,1.7,2.5,3.6
		c0,20.4,0,40.9,0,61.3c0,2.2-2,4.3-4.6,3.8c-1.4-0.3-2.6-1.6-2.6-3.1c0-5.6,0-11.2,0-16.8C88,106,88,101.2,88,96.3z"
        />
      </g>
    </svg>
  );
}

export function FilledPlus({
  height = 32,
  width = 32,
  viewBox = '0 0 150 150',
  disabled = false,
}) {
  const circleClass = disabled ? 'st-disabled' : 'st0';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      height={height}
      width={width}
    >
      <circle className={circleClass} cx="74.9" cy="74.6" r="74.1" />
      <g>
        <path
          className="st1"
          d="M79.2,43.5v27h27v6.4h-27v27h-6.4v-27h-27v-6.4h27v-27H79.2z"
        />
      </g>
    </svg>
  );
}

export function EditPencil({
  height = 32,
  width = 32,
  viewBox = '0 0 135 150',
  disabled = false,
}) {
  const circleClass = disabled ? 'st-disabled' : 'st0';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      height={height}
      width={width}
      className={circleClass}
    >
      <g>
        <path
          d="M19,131.7c-3.8,0-7.6,0-11.4,0c-3.5,0-5.4-1.6-5.4-5.1C2,119,2,111.3,2.1,103.7c0-2.5,1.6-4.2,3.3-5.9
		C21.7,81.5,37.9,65.2,54.2,49C69.1,34,84,19.1,99,4.2c0.4-0.4,0.8-0.8,1.2-1.2c2.7-2.5,5.1-2.6,7.7,0.1c4.8,4.7,9.5,9.5,14.2,14.2
		c2.9,2.8,5.7,5.7,8.5,8.6c3.1,3.1,3.1,5.3,0,8.4c-18.5,18.5-37,37.1-55.6,55.6c-13,13-26.1,26-39.1,39.1c-1.9,1.9-3.9,2.9-6.7,2.8
		C25.9,131.5,22.5,131.7,19,131.7z M20.6,122.5c2.2,0,4.3-0.1,6.5,0c1.5,0.1,2.7-0.4,3.8-1.5c29.8-29.8,59.5-59.6,89.3-89.3
		c1.3-1.3,1.3-2.1,0-3.3c-4.8-4.7-9.7-9.5-14.3-14.4c-1.4-1.5-2.2-1.3-3.6,0.1c-9.7,9.8-19.4,19.5-29.1,29.2
		c-20,20-39.9,39.9-59.9,59.8c-1,1-1.9,1.9-1.9,3.5c0.1,4.6,0.1,9.2,0,13.8c0,1.6,0.7,2.2,2.2,2.1C16,122.5,18.3,122.5,20.6,122.5z"
        />
        <path
          d="M47.6,148c-13.3,0-26.6,0-39.9,0c-4,0-6.2-3.3-4.7-6.7c0.7-1.5,1.9-2,3.4-2.5c1.1-0.3,2.3-0.3,3.4-0.3
		c25.2,0,50.4,0,75.5,0c1.1,0,2.2,0.1,3.2,0.3c2.6,0.5,4.4,2.8,4.2,4.9c-0.2,2.4-2.3,4.2-5.2,4.2c-10.2,0-20.4,0-30.6,0
		C53.7,148,50.6,148,47.6,148z"
        />
      </g>
    </svg>
  );
}
