import { REQUEST, SUCCESS, FAILURE } from '../constants';
import { AD_PREVIEWS_FETCH } from './actions';
import INITIAL_STATE from '../initial-state';

const additionalIndexEntries = (startingIndex, responses) =>
  responses.reduce(
    (acc, res, i) => ({
      ...acc,
      [res.ad_name || res._id]: { previews: startingIndex + i },
    }),
    {}
  );

const setPreview = (
  { hasError, isLoading, previews, previewsIndex },
  { status, error, response }
) => {
  switch (status) {
    case REQUEST:
      return {
        previews,
        previewsIndex,
        hasError: false,
        isLoading: true,
      };

    case FAILURE:
      return {
        previews,
        previewsIndex,
        hasError: error,
        isLoading: false,
      };

    case SUCCESS: {
      const res = Array.isArray(response) ? response : [response];

      return {
        isLoading: false,
        hasError: false,
        previews: [...previews, ...res],
        previewsIndex: {
          ...previewsIndex,
          ...additionalIndexEntries(previews.length, res),
        },
      };
    }

    default:
      return {
        hasError,
        isLoading,
        previews,
        previewsIndex,
      };
  }
};

const Previews = (state = INITIAL_STATE.AdPreviews, action) => {
  switch (action.type) {
    case AD_PREVIEWS_FETCH:
      return setPreview(state, action);

    default:
      return state;
  }
};

export default Previews;
