import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'react-bootstrap';

import CustomDatePicker from './CustomPicker';

import './styles.scss';
import { darwinDateToFormat, toDarwinDate } from '../../utils/darwin-dates';

const UnboundCustomToggle = (
  toggleStyle = { fontWeight: 500, fontSize: '1.1rem', height: '100%' },
  toggleClass = ''
) =>
  forwardRef(({ children, onClick }, ref) => (
    <a
      href="#"
      className={`${toggleClass}`}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={toggleStyle}
    >
      {children}
    </a>
  ));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => (
    <div
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
    >
      <ul className="list-unstyled">{children}</ul>
    </div>
  )
);

const datesAreSameDay = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

function makeLabel(start, end, dateRanges = []) {
  let string = `${darwinDateToFormat(
    toDarwinDate(start),
    'MM/dd/yyyy'
  )} - ${darwinDateToFormat(toDarwinDate(end), 'MM/dd/yyyy')}`;
  let maybe = null;

  dateRanges.forEach((range) => {
    if (
      datesAreSameDay(range.value[0], start) &&
      datesAreSameDay(range.value[1], end)
    )
      maybe = range;
  });

  if (maybe) return maybe.label;

  return string;
}

function MainDropdown({
  startDate,
  endDate,
  handleChange,
  dateRanges,
  allowNoSelection,
  noneSelected,
  toggleStyle,
  toggleClass,
  show,
  setShow,
  disable = false,
}) {
  const activeLabel = noneSelected
    ? 'No Comparison'
    : makeLabel(startDate, endDate, dateRanges);
  const CustomToggle = UnboundCustomToggle(toggleStyle, toggleClass);

  return (
    <Dropdown show={show}>
      <Dropdown.Toggle
        onClick={() => {
          if (disable) return;

          setShow(!show);
        }}
        as={CustomToggle}
        id="dropdown-custom-components"
      >
        <i className="fa fa-calendar" />
        &ensp;
        {activeLabel}
        &ensp;
        {!disable && <i className="fa fa-caret-down" />}
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu}>
        {allowNoSelection && (
          <Dropdown.Item
            eventKey="none-selected"
            active={activeLabel === 'No Comparison'}
          >
            <button
              style={{ color: 'inherit' }}
              type="button"
              className={
                activeLabel === 'None Selected'
                  ? 'btn btn-link w-100 p-0 text-left text-white'
                  : 'btn btn-link w-100 p-0 text-left'
              }
              onClick={() => handleChange('')}
            >
              {'None Selected'}
            </button>
          </Dropdown.Item>
        )}
        {dateRanges.map(({ label, value, disabled }, i) => {
          const isActive = activeLabel === makeLabel(...value);
          const className = isActive
            ? 'btn btn-link w-100 p-0 text-left text-white'
            : 'btn btn-link w-100 p-0 text-left';

          return (
            <Dropdown.Item eventKey={i} key={label} active={isActive}>
              <button
                style={{ color: 'inherit' }}
                type="button"
                className={className}
                onClick={() => handleChange(value)}
                disabled={disabled}
              >
                {label}
              </button>
            </Dropdown.Item>
          );
        })}
        <Dropdown.Divider />
        <Dropdown.Item eventKey="custom-picker">
          <CustomDatePicker
            startDate={startDate}
            endDate={endDate}
            handleChange={handleChange}
            btnStyle={{ color: 'inherit', fontSize: 'inherit' }}
          />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

const DateType = PropTypes.instanceOf(Date);

MainDropdown.propTypes = {
  startDate: DateType,
  endDate: DateType,
  handleChange: PropTypes.func.isRequired,
  dateRanges: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.arrayOf(DateType),
    })
  ).isRequired,
};

export default MainDropdown;
