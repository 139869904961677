import React, { useEffect, useMemo, useState } from 'react';
import { LabelPerformanceReportState } from '../../types/types';
import { Section } from '../shared/Helpers';
import Select from 'react-select';
import Button from '../../../../components/Button';
import {
  CHOSEN_LABEL,
  COMPARISON_LABEL,
  HARD_REFRESH,
  INCLUDE_MISSING_PERMISSIONS,
} from '../../constants';
import InputForm from 'react-bootstrap/Form';
import BlockFormWrapper from '../../../CreativeReports/BlockReport/BlockForm';
import Modal from '../../../../components/Modal';
import Overlay from '../../../../components/Overlay';
import { useAccountId } from '../../../CreativeAnalytics/selectors';
import { DARWIN_BLUE, SELECT_STYLES } from '../../../../constants';
import { useDarwinClientId } from '../../../CreativeStudio/selectors';
import { Segments } from './Segments';
import { useSearchParams } from '../../../../components/Hooks/search-params';
import { BasicLoading } from '../../../../components/Helpers/Loading';
import {
  REPORT_BUILDER_GROUP_BY_LOOKUP,
  REPORT_BUILDER_GROUP_BY_OPTS,
} from '../../../Clients/components/EditorTabs.Main';
import { useGroupByContext } from '../../contexts/GroupByContext';
import { downloadDebugDataToCSV } from '../../utils/export';

const getLabelOpts = (labels: string[]) => {
  return [
    { label: 'IMAGE+', value: 'IMAGE+' },
    { label: 'VIDEO+', value: 'VIDEO+' },
    ...labels.map((str) => ({ label: str, value: str })),
  ];
};

const getSelectOpts = (labels) => {
  return {
    [CHOSEN_LABEL]: getLabelOpts(labels || []),
    [COMPARISON_LABEL]: getLabelOpts(labels || []),
    [HARD_REFRESH]: [
      { label: 'True', value: 'true' },
      { label: 'False', value: 'false' },
    ],
  };
};

export const toBlockOpt = (block) => {
  return {
    label: block?.name || null,
    value: block?.id || null,
    category: block?.category || null,
    platform: block?.platforms?.[0] || null,
  };
};

const formatOptionLabel = ({ label, category, platform }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ color: '#808BA6', fontSize: 12 }}>
        {platform} {'>'} {category}
      </div>
      <div>{label}</div>
    </div>
  );
};

export const Form = ({
  labels,
  state,
  handleUpdateField,
  onSubmit,
  loading,
  debug_data,
  PeriodPicker,
  AnalyticsToggle,
  selectedBlockIds,
  allBlocks,
  blockOpts,
  blocksApi,
  refreshBlocks,
  labelsLoading,
  chosen_label,
  comparison_label,
}: {
  labels: string[];
  state: LabelPerformanceReportState;
  handleUpdateField: (string, any) => void;
  onSubmit: () => void;
  loading: boolean;
  debug_data: any;
  PeriodPicker: React.ComponentType;
  AnalyticsToggle?: React.ComponentType;
  selectedBlockIds: string[];
  allBlocks: any[];
  blockOpts: any[];
  blocksApi: any;
  refreshBlocks: () => void;
  labelsLoading: boolean;
  chosen_label: string | null;
  comparison_label: string | null;
}) => {
  const account_id = useAccountId();
  const darwin_client_id = useDarwinClientId();
  const { setParams } = useSearchParams();
  const [showAdvancedOptions, setShowAdvancedOptions] =
    useState<boolean>(false);
  const prev = [...selectedBlockIds];

  const setSelectedBlockIds = (callback) => {
    const update = typeof callback === 'function' ? callback(prev) : callback;
    setParams({ segments: encodeURIComponent(update?.join(',') || []) });
  };

  const [showBlockForm, setShowBlockForm] = useState({
    show: false,
    type: '',
    id: '',
  });

  const createNewBlock = () => {
    setShowBlockForm({
      show: true,
      type: 'Create',
      id: '',
    });
  };

  const editBlock = (id) => {
    setShowBlockForm({
      show: true,
      type: 'Edit',
      id,
    });
  };

  const deleteBlock = (id) => {
    setSelectedBlockIds((prev) => prev.filter((blockId) => blockId !== id));
  };

  const handleSubmit = () => {
    try {
      if (!Array.isArray(selectedBlockIds) || !Array.isArray(allBlocks)) {
        console.error('selectedBlockIds or allBlocks is not an array.');
        return;
      }

      const blocks = selectedBlockIds.reduce(
        (acc, id) => {
          const match = allBlocks.find((x) => x.id === id);
          if (match)
            acc.push(reformatBlock(match, darwin_client_id, account_id));
          return acc;
        },
        [] as ReturnType<typeof reformatBlock>[]
      );

      setParams({ selectedRowId: '' });
      onSubmit({ array_of_segments: blocks ?? [] });
    } catch (error) {
      console.error('Error in handleSubmit:', error);
    }
  };

  const selectOpts: {} = useMemo(() => getSelectOpts(labels), [labels]);

  return (
    <div className="d-flex justify-content-between p-4">
      <Segments
        {...{
          createNewBlock,
          editBlock,
          deleteBlock,
          selectedBlockIds,
          setSelectedBlockIds,
          blockOpts,
          allBlocks,
          setShowAdvancedOptions,
        }}
      />{' '}
      {showBlockForm.show === true && (
        <Overlay
          show={true}
          close={() => {
            setShowBlockForm(false);
          }}
        >
          <Modal>
            <BlockFormWrapper
              style={{
                position: 'static',
                width: '80vw',
                height: '80vh',
              }}
              formType={showBlockForm.type}
              blockId={showBlockForm.id}
              refreshBlocks={refreshBlocks}
              onClose={() =>
                setShowBlockForm({
                  show: false,
                  type: '',
                  id: '',
                })
              }
            />
          </Modal>
        </Overlay>
      )}
      {showAdvancedOptions === true && (
        <Overlay
          show={true}
          close={() => {
            setShowAdvancedOptions(false);
          }}
        >
          <Modal>
            <AdvancedOptions
              labelsLoading={labelsLoading}
              DownloadDebugDataButton={
                <p
                  onClick={() => downloadDebugDataToCSV(debug_data)}
                  className="mb-0"
                  style={{ color: DARWIN_BLUE, cursor: 'pointer' }}
                >
                  Download debug data
                </p>
              }
              {...{
                createNewBlock,
                selectedBlockIds,
                setSelectedBlockIds,
                blockOpts,
                allBlocks,
                editBlock,
                deleteBlock,
                handleUpdateField,
                selectOpts,
                state,
                handleSubmit,
                loading,
                debug_data,
                setShowAdvancedOptions,
                chosen_label,
                comparison_label,
              }}
            />
          </Modal>
        </Overlay>
      )}
      <div className="d-flex align-items-start">
        <span className="mr-2">{AnalyticsToggle}</span>
        {PeriodPicker}
        <Button loading={loading} onClick={handleSubmit}>
          Run
        </Button>
      </div>
    </div>
  );
};

const AdvancedOptions = ({
  createNewBlock,
  selectedBlockIds,
  setSelectedBlockIds,
  blockOpts,
  allBlocks,
  editBlock,
  deleteBlock,
  handleUpdateField,
  selectOpts,
  state,
  handleSubmit,
  loading,
  setShowAdvancedOptions,
  DownloadDebugDataButton,
  labelsLoading,
  chosen_label,
  comparison_label,
}) => {
  const { reportBuilderGroupBy, setReportBuilderGroupBy } = useGroupByContext();

  if (labelsLoading) {
    return (
      <div className="lp-form d-flex flex-column">
        <BasicLoading />
      </div>
    );
  }
  return (
    <div className="lp-form d-flex flex-column">
      <Section
        title="Dimension 1: Audience Segment (optional)"
        description={
          <>
            Choose an audience segment or build a new one based on naming
            conventions. Create a new segment{' '}
            <span
              onClick={createNewBlock}
              style={{ color: DARWIN_BLUE, cursor: 'pointer', fontWeight: 500 }}
            >
              here
            </span>
            .
          </>
        }
      ></Section>

      <div className="lp-blocks">
        {selectedBlockIds.map((id, index) => {
          return (
            <div className="lp-block d-flex align-items-center">
              <Select
                onChange={({ value }) => {
                  setSelectedBlockIds((prev) =>
                    prev.map((block, i) => (i === index ? value : block))
                  );
                }}
                formatOptionLabel={formatOptionLabel}
                options={blockOpts}
                styles={{
                  ...SELECT_STYLES,
                  container: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                }}
                placeholder="Select a block..."
                value={toBlockOpt(allBlocks.find((opt) => opt.id === id))}
              />
              <div className="d-flex" style={{ gap: '4px' }}>
                <Button appearance="subtle" onClick={() => editBlock(id)}>
                  <i className="fa-solid fa-pencil" />
                </Button>
                <Button appearance="subtle" onClick={() => deleteBlock(id)}>
                  <i className="fa-solid fa-trash" />
                </Button>
              </div>
            </div>
          );
        })}

        <Select
          onChange={({ value }) =>
            setSelectedBlockIds((prev) => [...prev, value])
          }
          formatOptionLabel={formatOptionLabel}
          options={blockOpts}
          styles={SELECT_STYLES}
          placeholder="Select a block..."
          value={null}
        />
      </div>

      <ChosenLabelSelect
        labelOpts={selectOpts[CHOSEN_LABEL]}
        state={state}
        chosen_label={chosen_label}
        comparison_label={comparison_label}
        handleUpdateField={handleUpdateField}
      />

      <Section
        title="Hard Refresh Meta Data"
        description='This will re-pull metrics from Meta. This will slow the report down by up to 10 minutes. Recommended setting is "False"'
      >
        <Select
          value={selectOpts[HARD_REFRESH].find(
            (opt) => opt.value === state[HARD_REFRESH].toString()
          )}
          options={selectOpts[HARD_REFRESH]}
          onChange={(selected) => {
            handleUpdateField(HARD_REFRESH, selected.value === 'true');
          }}
        />
      </Section>

      <Section
        title="Group by"
        // description='This will re-pull metrics from Meta. This will slow the report down by up to 10 minutes. Recommended setting is "False"'
      >
        <Select
          value={{
            label: REPORT_BUILDER_GROUP_BY_LOOKUP[reportBuilderGroupBy],
            value: reportBuilderGroupBy,
          }}
          options={REPORT_BUILDER_GROUP_BY_OPTS}
          onChange={(selected) => {
            setReportBuilderGroupBy(selected.value);
          }}
        />
      </Section>

      <div className="p-4">
        {' '}
        <div className="d-flex" style={{ gap: '0.5rem' }}>
          <Button
            appearance="subtle"
            fullWidth
            onClick={() => setShowAdvancedOptions(false)}
          >
            {'Back'}
          </Button>
          <Button
            fullWidth
            onClick={() => {
              handleSubmit();
              setShowAdvancedOptions(false);
            }}
            loading={loading}
          >
            {'Run'}
          </Button>
        </div>
        <div className="mt-3">{DownloadDebugDataButton}</div>
      </div>
    </div>
  );
};

export const reformatBlock = (block, darwin_client_id, account_id): {} => {
  const output = { ...block, darwin_client_id, account_id };
  delete output.filters;

  // Map filters to the new structure
  block.filters.forEach(({ name, include, exclude }) => {
    output[`${name}_filters`] = { include, exclude };
  });

  return output;
};

const OPTS = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'String',
    value: 'string',
  },
  {
    label: 'Easy AI',
    value: 'easy_ai',
  },
];

const ChosenLabelSelect = ({
  labelOpts = [],
  state,
  handleUpdateField,
  chosen_label,
  comparison_label,
}) => {
  const [selected, setSelected] = useState(!!chosen_label ? OPTS[1] : OPTS[0]);
  const [label1, setLabel1] = useState(chosen_label);
  const [label2, setLabel2] = useState(comparison_label);
  const { setParams } = useSearchParams();

  useEffect(() => {
    handleUpdateField(CHOSEN_LABEL, null);
    handleUpdateField(COMPARISON_LABEL, null);
  }, [selected]);

  // wait until input el loses focus before setting URL param
  const handleUpdateLabel1Param = () => {
    if (label1 !== chosen_label) {
      setParams({ [CHOSEN_LABEL]: label1 });
    }
  };

  const handleUpdateLabel2Param = () => {
    if (label2 !== comparison_label) {
      setParams({ [COMPARISON_LABEL]: label2 });
    }
  };

  let Render = null;

  switch (selected.value) {
    case 'string':
      Render = (
        <div key="string" className="lp-input-section">
          <div className="lp-input-field">
            <InputForm.Label>Label 1</InputForm.Label>
            <InputForm.Control
              type="input"
              value={label1 || ''}
              onChange={(e) => {
                // setParams({ [CHOSEN_LABEL]: e.target.value });
                setLabel1(e.target.value);
              }}
              onBlur={handleUpdateLabel1Param}
            />
          </div>

          <div className="lp-input-field">
            <InputForm.Label>Label 2</InputForm.Label>
            <InputForm.Control
              type="input"
              value={label2 || ''}
              onChange={(e) => {
                // setParams({ [COMPARISON_LABEL]: e.target.value });
                setLabel2(e.target.value);
              }}
              onBlur={handleUpdateLabel2Param}
            />
          </div>
        </div>
      );
      break;
    case 'easy_ai':
      Render = (
        <div key="easy_ai" className="lp-input-section">
          <div className="lp-input-field">
            <label>Label 1</label>
            <Select
              value={labelOpts.find((opt) => opt.value === chosen_label)}
              placeholder={<div>Type to search for label...</div>}
              options={labelOpts}
              openMenuOnClick={chosen_label}
              isClearable
              onChange={(selected) => {
                setLabel1(selected.value);
                setParams({ [CHOSEN_LABEL]: selected.value });
                // handleUpdateField(CHOSEN_LABEL, selected?.value ?? null);
              }}
            />
          </div>

          <div className="lp-input-field">
            <label>Label 2</label>
            <Select
              value={labelOpts.find((opt) => opt.value === comparison_label)}
              options={labelOpts}
              placeholder={<div>Type to search for label...</div>}
              openMenuOnClick={comparison_label}
              isClearable
              onChange={(selected) => {
                setLabel2(selected.value);
                setParams({ [COMPARISON_LABEL]: selected.value });
                // handleUpdateField(COMPARISON_LABEL, selected?.value ?? null);
              }}
            />
          </div>
        </div>
      );
      break;
    default:
      Render = null;
  }

  return (
    <Section
      title="Dimension 2: Ad Name or Label Breakdowns (Optional)"
      description="You can further breakdown performance based on ad name conventions or Darwin EasyAI labels."
    >
      <div className="flex space-x-4">
        {OPTS.map((opt) => (
          <span className="mr-3">
            <label key={opt.value} className="flex items-center">
              <input
                type="radio"
                name="option"
                value={opt.value}
                checked={selected === opt}
                onChange={() => setSelected(opt)}
                className="cursor-pointer"
              />
              <span className="cursor-pointer ml-1">{opt.label}</span>
            </label>
          </span>
        ))}
      </div>
      <div>
        {' '}
        {selected.value === 'easy_ai' && (
          <div
            onClick={() => {
              handleUpdateField(
                INCLUDE_MISSING_PERMISSIONS,
                !state[INCLUDE_MISSING_PERMISSIONS]
              );
            }}
            className="form-group d-flex align-items-center mt-2"
          >
            <input
              type="checkbox"
              name="option"
              value={state[INCLUDE_MISSING_PERMISSIONS]}
              checked={state[INCLUDE_MISSING_PERMISSIONS]}
              // onChange={(e) => {
              //   handleUpdateField(
              //     INCLUDE_MISSING_PERMISSIONS,
              //     e.target.checked
              //   );
              // }}
              className="cursor-pointer mr-2"
            />
            <label key={'missing-permissions'} className="cursor-pointer mb-0">
              Include missing permissions
            </label>
          </div>
        )}
      </div>
      {Render}
    </Section>
  );
};
