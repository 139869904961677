import React, { useState } from 'react';
import Overlay from '../../../../../components/Overlay';
import Button from '../../../../../components/Button';
import '../../../../InsightsDashboard/AddCategories/AddCategories.scss';

export const CategoriesModal = ({
  selectedCategories,
  setSelectedCategories,
  uniqueCategoryNames,
  setShow,
  showList,
  setShowList,
}) => {
  const isAlreadySelected = (category) => {
    return selectedCategories.includes(category);
  };

  const toggleCategorySelect = (category) => {
    console.log(category);
    setSelectedCategories((prev: string[]) => {
      if (isAlreadySelected(category)) {
        return [...prev].filter((x) => x !== category);
      } else {
        return [...prev, category];
      }
    });
  };

  const handleSelectAll = () => {
    const allAreSelected = !uniqueCategoryNames.some(
      (x) => !selectedCategories.includes(x)
    );
    if (allAreSelected) {
      setSelectedCategories(() => {
        return [];
      });
    } else {
      setSelectedCategories(() => {
        return uniqueCategoryNames;
      });
    }
  };

  let Render = (
    <div className="add-categories">
      <h1>Toggle categories</h1>
      {uniqueCategoryNames.length < 1 && (
        <p>There are no categories that currently have data.</p>
      )}

      <ul>
        {uniqueCategoryNames.map((opt) => {
          const checked = isAlreadySelected(opt);
          return (
            <li
              key={opt}
              className="d-flex align-items-center"
              onClick={(e) => {
                e.preventDefault();
                toggleCategorySelect(opt);
              }}
              style={{ background: checked ? '#D7E7FF' : '#F3F7FD' }}
            >
              <input
                type="checkbox"
                id={`category-${opt}`}
                checked={checked}
                onChange={() => toggleCategorySelect(opt)}
                className="mr-2"
              />
              <label
                htmlFor={`category-${opt}`}
                className="cursor-pointer mb-0"
              >
                {opt}
              </label>
            </li>
          );
        })}
      </ul>
      <div className="add-categories__footer w-100 align-items-center justify-content-between">
        <div className="d-flex">
          <label className="mr-2 mb-0">Display list in interface</label>
          <input
            type="checkbox"
            checked={showList}
            onChange={(e) => setShowList(e.target.checked)}
          />
        </div>

        <div className="d-flex">
          <Button
            onClick={() => handleSelectAll()}
            className="mr-2"
            appearance="subtleBordered"
          >
            Select all
          </Button>
          <Button onClick={() => setShow(false)}>Close</Button>
          {/* {opts.length >= 1 && (
          <Button className="ml-3" onClick={handleSave}>
            Save
          </Button>
        )} */}
        </div>
      </div>
    </div>
  );

  return (
    <Overlay show close={() => setShow(false)}>
      {Render}
    </Overlay>
  );
};
