import React from 'react';
import flatten from 'lodash/flatten';
import { toPercent_2 } from '../../../../utils/numbers';
import { customScoringMetrics } from '../../hooks/useFilteredData/utils/getCustomScoringValues';

export const useCustomScoringColumns = () => {
  const customScoringColumns = customScoringMetrics.map((metric) => {
    return [
      {
        Header: `${metric.label}`,
        accessor: metric.label,
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          const value = original[metric.label];
          if (!value) return <p>0</p>;

          return (
            <p>
              {metric.label.includes('Average')
                ? toPercent_2(value)
                : value.toFixed(2)}
            </p>
          );
        },
      },
    ];
  });

  return flatten(customScoringColumns);
};
