import { useRef, useEffect } from 'react';
import joi from 'joi';
import { abortableFetch } from '../../../utils';

import {
  useApiState,
  bindFetchToRef,
} from '../../../components/Hooks/useApiState';

const HEADERS = { 'content-type': 'application/json' };

function postHidePhrase(payload) {
  return abortableFetch(
    [
      'api',
      'creative-reports',
      'performance-frequency',
      payload.account_id,
      'hide-phrase',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

function postMergePhrase(payload) {
  return abortableFetch(
    [
      'api',
      'creative-reports',
      'performance-frequency',
      payload.account_id,
      'merge-phrase',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

function postRenamePhrase(payload) {
  return abortableFetch(
    [
      'api',
      'creative-reports',
      'performance-frequency',
      payload.account_id,
      'rename-phrase',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function usePrimaryPhraseApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [hidePhraseState, hidePhraseDispatch] = useApiState(
    (payload) => withRef(postHidePhrase(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );
  const [mergePhraseState, mergePhraseDispatch] = useApiState(
    (payload) => withRef(postMergePhrase(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );
  const [renamePhraseState, renamePhraseDispatch] = useApiState(
    (payload) => withRef(postRenamePhrase(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    hide: {
      ...hidePhraseState,
      request: hidePhraseDispatch,
    },
    merge: {
      ...mergePhraseState,
      request: mergePhraseDispatch,
    },
    rename: {
      ...renamePhraseState,
      request: renamePhraseDispatch,
    },
  };
}
