import subDays from 'date-fns/subDays';
import format from 'date-fns/format';

import {
  DATE_PAIRS,
  toDarwinDate,
  darwinDateToFormat,
} from '../../../utils/darwin-dates';

export const DATES = (() => {
  const now = new Date();
  const [yesterday, ...rest] = [...DATE_PAIRS];
  const last3 = {
    label: 'Last 3 Days',
    value: [subDays(now, 3), yesterday.value[1]],
  };

  return [{ label: 'Today', value: [now, now] }, yesterday, last3, ...rest];
})();

export const equalDateRanges = (r1, r2) =>
  r1.map((d) => d.toString()).join('-') ===
  r2.map((d) => d.toString()).join('-');

export const isToday = (() => {
  const today = DATES[0].value.map(toDarwinDate).join(',');

  return (dateRange) => {
    if (Array.isArray(dateRange)) {
      return dateRange.join(',') === today;
    }
    return dateRange === today;
  };
})();

export const formatDateRange = (range, formatAs = 'MM/dd/yyyy') =>
  range.map((d) => format(d, formatAs)).join(' - ');

export const darwinDateToChartDate = (dateStr) =>
  darwinDateToFormat(dateStr, 'MM/dd/yyyy');
