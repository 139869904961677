import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Filters } from '../Filters/Filters';
import Button from '../../../../components/Button';
import IconButton from '../../../../components/IconButton';
import {
  useCreativeAnalyticsGet,
  useCreativeAnalyticsSet,
} from '../../contexts';
import useIsVisibleAtCenter from '../../../../hooks/useVisibleAtCenter';

const getCCCType = (categoryData, getDerivedLabelsApi, currentCategory) => {
  if (getDerivedLabelsApi.get.data) {
    try {
      const match = getDerivedLabelsApi.get.data.category_structure.find(
        (x) => x.category_name === currentCategory
      );
      if (match) return 'derived_labels';
    } catch (e) {
      console.error(e);
    }
  }

  if (categoryData.derived) return 'derived_labels';
  if (categoryData.udc) return 'user_defined';
  if (categoryData.ad_name_label) return 'ad_name_labels';
  return 'ai_driven';
};

export const ReportHeader = ({ category_name, categoryData, desc }) => {
  const { period2, getUdcApi, getDerivedLabelsApi, filteredData } =
    useCreativeAnalyticsGet();
  const currentCategory = categoryData.name || '';
  const category_id = categoryData.category_id || '';
  const { setEditCategory, setActiveBoxPlotCategory, setParams } =
    useCreativeAnalyticsSet();
  const isPhrases = category_name.toLowerCase() === 'phrases';
  const isCustomLabel = category_name.toLowerCase() === 'custom label';
  const [ref, isVisible] = useIsVisibleAtCenter();

  const editable = categoryData.ccc && !isPhrases && !isCustomLabel;
  const ccc_type = getCCCType(
    categoryData,
    getDerivedLabelsApi,
    currentCategory
  );

  const handleEditCategory = (shouldShowBoxPlot) => {
    if (editable) {
      setEditCategory({
        category: category_name,
        category_id,
        ccc_type,
        shouldShowBoxPlot,
      });
    }
  };

  return (
    <div className='report-header' ref={ref}>
      <div className='mb-4'>
        <h3 className='report__title'>
          Performance by <span>{category_name}</span>
          <div className='d-inline-flex ml-3'>
            {editable && (
              <IconButton onClick={handleEditCategory}>
                <i className='fa fa-pen-to-square'></i>
              </IconButton>
            )}
          </div>
        </h3>
        {desc && (
          <p className='report__desc'>
            {/* <i className='fa fa-circle-info'></i> */}
            {desc}
          </p>
        )}
      </div>
    </div>
  );
};
