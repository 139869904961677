import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  AnalyticsIcon,
  StudioIcon,
  TestingIcon,
  ReportsIcon,
  LibraryIcon,
  RoadmapIcon,
} from './assets';
import { setAnalyticsParams, setReportParams, usePath } from './utils';
import { hasRoles } from '../../cookies';
import { usePermissions } from '../Permissions/usePermissions';
import {
  ROADMAP,
  ANALYTICS,
  STUDIO,
  CREATIVE_REPORTS,
} from '../Permissions/constants';
import { useViewGroupContext } from '../../features/ViewGroup/context';

const NavMenu = ({
  viewId,
  viewName,
  reportOptions,
  history,
  darwin_light,
  isDirectMail,
  allowedAdmin,
  isNonPaidMediaType,
  isUltraLight,
  analyticsUrl,
}) => {
  const { mainView } = useViewGroupContext();
  const [openMenu, setOpenMenu] = useState(null);
  const [selected, setSelected] = useState('analytics');
  const { initialEventKey } = usePath();
  const { search } = useLocation();
  const prefixView = (rest) => `/${viewId}/${rest}`;
  const hasPermission = usePermissions();

  const allowRoadmap = hasPermission(ROADMAP);
  const allowAnalytics = hasPermission(ANALYTICS);
  const allowStudio = hasPermission(STUDIO);
  const allowReports = hasPermission(CREATIVE_REPORTS);

  const isCompetitorView =
    isDirectMail && viewName.toLowerCase().includes('competitor');
  const isOrganicView =
    isDirectMail && viewName.toLowerCase().includes('organic');

  useEffect(() => {
    let feature = initialEventKey;

    if (initialEventKey.includes('creative-reports')) {
      feature = 'reports';
    }

    setSelected(feature);
  }, [viewId, initialEventKey]);

  const handleToggleMenu = (value) => {
    setOpenMenu(value);
  };

  const handleSelection = (key, e) => {
    let feature = key;

    if (key.includes('creative-reports')) {
      feature = 'reports';
    }

    setSelected(feature);
    setOpenMenu(null);

    const modes = ['analytics', 'library'];

    const withParams = key.includes('creative-reports')
      ? setReportParams(key)
      : feature === 'analytics' && analyticsUrl
      ? setAnalyticsParams(analyticsUrl)
      : modes.includes(key) && modes.includes(selected)
      ? setAnalyticsParams(key)
      : key;

    history.push(prefixView(withParams));
  };

  return (
    <ul className="col-0 col-lg-6 menu" onMouseLeave={() => setOpenMenu(null)}>
      <MenuItem
        show={allowRoadmap}
        name="Roadmap"
        value="roadmap"
        className="roadmap"
        active={selected === 'roadmap'}
        openMenu={openMenu}
        handleToggleMenu={handleToggleMenu}
        handleSelection={handleSelection}
        Icon={<RoadmapIcon className="menu-item__icon" />}
      />

      <MenuItem
        show={
          allowAnalytics &&
          ((!isNonPaidMediaType && !isUltraLight) || isOrganicView)
        }
        name="Analytics"
        value="analytics"
        className="analytics"
        active={selected === 'analytics'}
        openMenu={openMenu}
        handleToggleMenu={handleToggleMenu}
        handleSelection={handleSelection}
        Icon={<AnalyticsIcon className="menu-item__icon" />}
      />

      <MenuItem
        show={allowStudio}
        name="Studio"
        value="studio"
        active={selected === 'studio'}
        openMenu={openMenu}
        handleToggleMenu={handleToggleMenu}
        handleSelection={handleSelection}
        Icon={<StudioIcon className="menu-item__icon" />}
      />

      <MenuItem
        show={
          allowReports &&
          !!reportOptions.length &&
          ((!darwin_light && !isDirectMail) || allowedAdmin) &&
          !isCompetitorView &&
          !isOrganicView &&
          !isNonPaidMediaType &&
          !isUltraLight &&
          !mainView?.current
        }
        name="Reports"
        value="reports"
        primary={'block_report'}
        active={selected === 'reports'}
        menuTitle="Creative Reports"
        options={reportOptions}
        openMenu={openMenu}
        handleToggleMenu={handleToggleMenu}
        handleSelection={handleSelection}
        Icon={<ReportsIcon className="menu-item__icon" />}
      />
    </ul>
  );
};

export default withRouter(NavMenu);

const MenuItem = ({
  show = true,
  name,
  value,
  menuTitle,
  active,
  options,
  openMenu,
  handleToggleMenu,
  handleSelection,
  Icon,
  className,
  primary,
}) => {
  if (!show) return null;
  const showDropdown = openMenu === value;

  const handleClick = () => {
    if (primary) {
      let key = primary;
      if (value === 'reports') key = `analytics?display=${primary}`;
      handleSelection(key);
    } else if (!options) {
      handleSelection(value);
    }
  };

  const handleMouseEnter = () => {
    if (options) {
      handleToggleMenu(value);
    }
  };

  return (
    <>
      <li
        className={`menu-item menu-item--${className ? className : value} ${
          active ? 'menu-item--active' : ''
        }`}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onFocus={handleMouseEnter}
      >
        {Icon}
        <span className="menu-item__name">{name}</span>
        {showDropdown && options && (
          <Dropdown
            value={value}
            menuTitle={menuTitle}
            options={options}
            isReports={value === 'reports'}
            handleSelection={handleSelection}
            handleToggleMenu={handleToggleMenu}
          />
        )}
      </li>
    </>
  );
};

export const Dropdown = ({
  options,
  value,
  menuTitle,
  isReports,
  handleSelection,
}) => {
  const handleClick = (key, e) => {
    e.stopPropagation();
    handleSelection(key);
  };

  return (
    <div className={`menu-dropdown menu-dropdown--${value}`}>
      <h3 className="menu-dropdown__title">{menuTitle}</h3>
      <ul className="menu-dropdown__list">
        {options.map(({ label, value }) => {
          let key = value;
          if (isReports && value !== 'dashboard')
            key = `analytics?display=${value}`;

          return (
            <li
              onClick={(e) => handleClick(key, e)}
              className="menu-dropdown__item"
            >
              {label}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
