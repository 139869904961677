import { connect } from 'react-redux';
import { fetchRegister, REGISTER } from '../../store/background/api.register';

const stateToProps = (
  { Loaders, Errors, ViewSettings: { loggedIn, viewId } },
  props
) => ({
  isLoading: Loaders[REGISTER],
  hasError: Errors[REGISTER],
  loggedIn,
  viewId,
});

export default connect(stateToProps, { fetchRegister });
