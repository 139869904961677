export const LOADING_MESSAGES = [
  "Configuring labels",
  "Analyzing themes",
  "Dividing by zero",
  "Roping seaturtles",
  "Bending spoons",
  "Reading terms and conditions",
  "Reticulating splines",
  "Boiling water",
  "Still working on it",
]