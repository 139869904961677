import React from "react";
import { DARWIN_RED } from "../../constants";

const ErrorIcon = ({ className = "", style = {}, fill }) => (
  <svg
    className={className}
    style={style}
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 7.70654C14 9.56306 13.2625 11.3435 11.9497 12.6563C10.637 13.969 8.85652 14.7065 7 14.7065C5.14348 14.7065 3.36301 13.969 2.05025 12.6563C0.737498 11.3435 0 9.56306 0 7.70654C0 5.85003 0.737498 4.06955 2.05025 2.7568C3.36301 1.44404 5.14348 0.706543 7 0.706543C8.85652 0.706543 10.637 1.44404 11.9497 2.7568C13.2625 4.06955 14 5.85003 14 7.70654ZM7 4.20654C6.88941 4.20661 6.78005 4.22983 6.67898 4.27473C6.57791 4.31963 6.48736 4.3852 6.41318 4.46722C6.33899 4.54923 6.2828 4.64588 6.24824 4.75094C6.21368 4.85599 6.2015 4.96712 6.2125 5.07717L6.51875 8.14579C6.52904 8.26634 6.5842 8.37864 6.67331 8.46047C6.76243 8.54231 6.87901 8.58771 7 8.58771C7.12099 8.58771 7.23757 8.54231 7.32669 8.46047C7.4158 8.37864 7.47096 8.26634 7.48125 8.14579L7.7875 5.07717C7.7985 4.96712 7.78632 4.85599 7.75176 4.75094C7.7172 4.64588 7.66101 4.54923 7.58682 4.46722C7.51264 4.3852 7.42209 4.31963 7.32102 4.27473C7.21995 4.22983 7.11059 4.20661 7 4.20654ZM7.00175 9.45654C6.76969 9.45654 6.54713 9.54873 6.38303 9.71282C6.21894 9.87692 6.12675 10.0995 6.12675 10.3315C6.12675 10.5636 6.21894 10.7862 6.38303 10.9503C6.54713 11.1144 6.76969 11.2065 7.00175 11.2065C7.23381 11.2065 7.45637 11.1144 7.62047 10.9503C7.78456 10.7862 7.87675 10.5636 7.87675 10.3315C7.87675 10.0995 7.78456 9.87692 7.62047 9.71282C7.45637 9.54873 7.23381 9.45654 7.00175 9.45654Z"
      fill={fill ?? DARWIN_RED}
    />
  </svg>
);

export default ErrorIcon;
