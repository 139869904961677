import { useEffect, useState } from 'react';
import { usePostRequest } from '../../../components/Hooks/usePostRequest';
import { useCreativeAnalyticsGet } from '../contexts';
import { useSelector } from 'react-redux';

export const useShareDraftCreative = () => {
  const { view_id } = useSelector(({ ViewSettings: { viewId } }) => ({
    view_id: viewId,
  }));
  const { start_date, end_date, isROAS } = useCreativeAnalyticsGet();
  const {
    data: apiData,
    loading,
    error,
    request,
  } = usePostRequest(
    ['api', 'creative-analytics', 'create-static-report'].join('/')
  );
  const [shouldShowShareModal, setShouldShowShareModal] = useState(false);
  const [sharedReportId, setSharedReportId] = useState(null);

  const generateReport = ({ rows, report_name, report_description }) => {
    request({
      report_name,
      report_description,
      start_date,
      end_date,
      data: {
        report_type: 'draft-creative',
        rows,
      },
    });
  };

  useEffect(() => {
    const report_id = extractReportId(apiData);

    if (report_id) {
      setSharedReportId(report_id);
    }
  }, [apiData]);

  return {
    shouldShowShareModal,
    openShareModal: () => setShouldShowShareModal(true),
    closeShareModal: () => {
      setShouldShowShareModal(false);
      setSharedReportId(null);
    },
    submitShareReport: ({ rows, report_name, report_description }) =>
      generateReport({
        rows,
        report_name,
        report_description,
      }),
    sharedReportId,
    loadingShareReport: loading,
  };
};

const extractReportId = (apiData) => {
  if (apiData?.message) {
    const reportIdMatch = apiData.message.match(/report id (\w+)/);

    const reportId = reportIdMatch ? reportIdMatch[1] : null;

    return reportId;
  } else {
    return null;
  }
};
