import React from 'react';
import { Link } from 'react-router-dom';
import AccountPageFooter from '../AccountPageFooter/AccountPageFooter';

class Logout extends React.Component {
  componentDidMount() {
    console.log('logging out!');
  }

  render() {
    return (
      <div className="Logout p-3">
        <h4 className="page-header">You have been logged out.</h4>
        <AccountPageFooter>
          <p>
            Would you like to <Link to="/login">Log in</Link>?
          </p>
        </AccountPageFooter>
      </div>
    );
  }
}

export default Logout;
