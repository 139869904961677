import React from 'react';
import Tooltip from '../../components/Tooltip/Tooltip';
import './ExitEditMode.scss';

export const ExitEditMode = ({ setEditMode }) => {
  return (
    <Tooltip content="Stop editing" placement="top" delay={[0,0]} follow={false}>
      <div onClick={() => setEditMode(false)} className={`exit-btn`}>
        <i className="fa-solid fa-plus"></i>
      </div>
    </Tooltip>
  );
};
