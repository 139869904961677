import React, { useState, useEffect } from 'react';
import Tooltip from '../../../components/Tooltip';
import ExportIcon from '../../../components/Icons/ExportIcon';

export const useDnaTable = ({
  view_id,
  account_id,
  period1Start,
  period1End,
  dnaTableApi,
}) => {
  const handleExportTable = () => {
    let payload = {
      account_id: account_id,
      view_id: view_id,
      start_date: period1Start,
      end_date: period1End,
    };

    dnaTableApi.post.request(payload);
  };

  const ExportButton = (
    <>
      {dnaTableApi.post.loading && (
        <div style={{ paddingTop: '0.5rem' }}>
          <i className="fas fa-spinner fa-spin export-spinner"></i>
        </div>
      )}
      <Tooltip content="Export DNA Table">
        <div>
          <ExportIcon onClick={handleExportTable} className="export-icon" />
        </div>
      </Tooltip>
    </>
  );

  useEffect(() => {
    if (dnaTableApi.post.data) {
      const { data } = dnaTableApi.post;
      const exportLink = data.replace('edit', 'export?format=csv');
      window.open(exportLink, '_blank').focus();
    }
  }, [dnaTableApi.post.data]);

  return { ExportButton };
};
