import React, { useEffect, useMemo } from 'react';
import useCategories from '../../../hooks/useCategories';
import { MenuItem } from '../../../../../components/LeftMenu/MenuItem';
import {
  useCreativeAnalyticsGet,
  useCreativeAnalyticsSet,
} from '../../../contexts';
import { Bar } from '../../icons/bar';
import { useDispatch } from 'react-redux';
import { customNanoId } from '../../../../../utils/nanoid';
import { trackEvent } from '../../../../../store/UserActivity/actions';
import {
  CREATE_CATEGORY_BUTTON_PRESSED,
  CREATIVE_ANALYTICS,
} from '../../../../../store/UserActivity/constants';
import {
  ADSET_NAME,
  AD_NAME,
  PERFORMANCE_FREQUENCY,
} from '../../../../../store/CreativeReports/constants';
import { useReportsApi } from '../../../../CreativeReports/withApi';
import { useSearchParams } from '../../../../../components/Hooks/search-params';

export const ReportsNav = ({ loading }) => {
  const { navCategories, data } = useCategories();
  const { category, period2, display, filteredAllVisualsLabel } =
    useCreativeAnalyticsGet();
  const { setEditCategory } = useCreativeAnalyticsSet();
  const { getParams, setParams } = useSearchParams();
  const { group } = getParams('group');
  const dispatch = useDispatch();
  const nanoid = customNanoId();
  const reportsApi = useReportsApi(display);

  let reports = navCategories
    ?.filter(
      (category) =>
        !['Phrase Theme', 'Darwin Clusters'].includes(category.label) &&
        !(group === 'yes' && category.label === 'Primary Dimensions')
    )
    .map(({ label, value, variance }) => {
      const isSelectedCategory = category === value;

      const handleSelect = (value) => {
        if (isSelectedCategory) {
          setParams({ display: PERFORMANCE_FREQUENCY, category: '' });
        } else {
          setParams({ display: PERFORMANCE_FREQUENCY, category: value });
        }
      };

      return (
        <MenuItem
          title={label}
          onMenuItemClick={handleSelect}
          isSelected={isSelectedCategory}
          className="detailed-report-item"
        />
      );
    });

  useEffect(() => {
    if (![AD_NAME, ADSET_NAME].includes(display)) return;
    reportsApi.dispatch();
  }, [display]);

  return (
    <MenuItem
      title="Detailed Reports"
      icon={<Bar />}
      expandByDefault={display === 'performance_frequency'}
      display={display}
      id="detailed-reports"
      subMenu={
        loading ? (
          <>
            <div>Loading...</div>
          </>
        ) : (
          <>
            {reports}
            <MenuItem
              title="+ Create new..."
              onMenuItemClick={() => {
                dispatch(
                  trackEvent(CREATIVE_ANALYTICS, CREATE_CATEGORY_BUTTON_PRESSED)
                );
                if (!period2) {
                  setEditCategory({
                    category: 'custom',
                    ccc_type: '',
                    category_id: nanoid(),
                  });
                }
              }}
            />
          </>
        )
      }
    />
  );
};
