import PropTypes from 'prop-types';

export const ApiStatus = Object.assign(Object.create(null), {
  none: 'none',
  loading: 'loading',
  success: 'success',
  failure: 'failure',
});
export const PlatformsType = PropTypes.arrayOf(PropTypes.oneOf(['facebook']));

export const ApiStatusTypes = PropTypes.oneOf(Object.keys(ApiStatus));

export const FilterTypes = PropTypes.shape({
  name: PropTypes.oneOf(['campaign', 'adset', 'ad']),
  include: PropTypes.string,
  exclude: PropTypes.string,
});
