import React, { useState, useMemo } from 'react';
import { useCreativeAnalyticsGet } from '../contexts';
import { SPEND } from './constants';
import { getVisualsData, getSections } from './utils';
import Header from './Header';
import SectionRows from './SectionRows';

import './TopPerformers.scss';

const TopPerformers = ({ Header, SectionRows, TryThese, requestWidth }) => {
  const maxWidth = { maxWidth: `calc(100vw - (${requestWidth}px + 50px))` };

  return (
    <div className="top-performers" style={maxWidth}>
      {Header}
      {/* {SectionRows} */}
      {TryThese}
    </div>
  );
};

const TopPerformersWrapper = ({ recsTab, TryThese, isROAS }) => {
  const { filteredData, requestWidth } = useCreativeAnalyticsGet();
  const [metric, setMetric] = useState(SPEND);

  const allVisuals = useMemo(() => {
    const label = filteredData.find((label) => label.value === 'all_visuals');

    return label ? [...label.winners, ...label.losers] : [];
  }, [filteredData]);

  const visualsData = useMemo(() => {
    return getVisualsData(metric, allVisuals, isROAS);
  }, [metric, allVisuals]);

  const sections = useMemo(
    () => getSections(filteredData, metric, isROAS),
    [visualsData, filteredData, metric]
  );

  return (
    <TopPerformers
      Header={
        <Header {...{ metric, setMetric, visualsData, recsTab, isROAS }} />
      }
      // SectionRows={(
      //   <SectionRows {...{ visualsData, sections, recsTab, metric, isROAS }} />
      // )}
      TryThese={TryThese}
      {...{ requestWidth }}
    />
  );
};

export default TopPerformersWrapper;
