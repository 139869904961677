export const TOGGLE_CATEGORY = 'toggle-category';
export const TOGGLE_METRIC = 'toggle-metric';
export const SET_CATEGORIES = 'set-categories';
export const SET_DATA_TYPE = 'set-data-type';
export const SET_DATA = 'set-data';
export const SET_SELECTED_METRIC = 'set-selected-metric';
export const SET_METRICS = 'set-metrics';

const handleCategoryToggle = (categorySettings, category_name, toggleType) => {
  return categorySettings.map((c) => {
    if (c.category_name === category_name) {
      return {
        ...c,
        enabled: {
          ...c.enabled,
          [toggleType]: !c.enabled[toggleType],
        },
      };
    }
    return c;
  });
};

const shouldCountAsEnabled = (metric, filteredMetrics) => {
  return filteredMetrics.some((x) => x.metric === metric.metric);
}

const handleMetricToggle = (settings, metric, filteredMetrics) => {
  let enabledCount = 0;
  let found = false;

  let update = settings.map((x) => {
    if (x.metric === metric.metric) {
      if (!x.enabled === true) enabledCount += 1;
      found = true;

      return {
        ...x,
        enabled: !x.enabled,
      };
    }

    if (x.enabled && shouldCountAsEnabled(x, filteredMetrics)) enabledCount += 1;
    return x;
  });

  if (!found) {
    return [
      ...settings,
      {
        metric: metric.metric,
        id: metric.id,
        enabled: true,
      },
    ];
  }

  return update;
};

export function reportCardSettingsReducer(state, action) {
  switch (action.type) {
    case TOGGLE_CATEGORY:
      return {
        ...state,
        categorySettings: handleCategoryToggle(
          state.categorySettings,
          action.category_name,
          action.toggleType
        ),
        settings_changed: true,
      };

    case TOGGLE_METRIC:
      return {
        ...state,
        metric_order: handleMetricToggle(state.metric_order, action.metric, action.filteredMetrics),
        settings_changed: true,
      };

    case SET_CATEGORIES:
      return {
        ...state,
        categorySettings: action.categorySettings,
        settings_changed: true,
      };

    case SET_DATA_TYPE:
      return {
        ...state,
        settings_changed: true,
        data_type: action.data_type,
      };

    case SET_DATA:
      return {
        ...state,
        ...action,
        metric_order: action.metric_order || state.metric_order,
        settings_changed: false,
      };

    case SET_SELECTED_METRIC:
      return {
        ...state,
        settings_changed: true,
        selected_metric: action.selected_metric,
      };

    case SET_METRICS:
      return {
        ...state,
        metric_order: action.metric_order,
        settings_changed: true,
      };

    default:
      return state;
  }
}
