import React, { useRef, useState } from 'react';
import Button from '../../../components/Button';
import Tooltip from '../../../components/Tooltip';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from '../contexts';
import { InputField, TextEditorField } from './InputFields';
import './Presets.scss';
import Saved from './Saved';
import Toggle from './Toggle';
import { StarIcon } from './icons';

const Presets = ({
  presets,
  current,
  handleInput,
  handleChecked,
  selectedLabels,
  handleCreate,
  handleDelete,
  filteredData,
  loading,
  getLoading,
  view_id,
  groupByAdName,
  showSaved,
  setShowSaved,
}) => {
  const { primary_metric, data, presetsOpen } =
    useCreativeAnalyticsGet();
  const { setPresetsOpen } = useCreativeAnalyticsSet();
  const ref = useRef();
  useClickOutside(ref, () => setPresetsOpen(false));

  const handleToggle = () => {
    if (loading) return;
    setPresetsOpen((prev) => !prev);
  };

  return (
    <div className="presets" ref={ref}>
      <Toggle onClick={handleToggle} presetsOpen={presetsOpen} />
      <div className={`presets__container ${presetsOpen ? 'show' : ''}`}>
        <div className={`presets__inner`}>
          <div className={`presets__default-create ${showSaved ? 'hide' : ''}`}>
            <div
              className="saved-toggle"
              onClick={() => {
                if (loading || getLoading) return;
                setShowSaved(true);
              }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <span>Saved Presets</span>
                <span className="arrow">{`>`}</span>
              </div>
            </div>
            <div className="presets__title">Create View Preset</div>
            <InputField
              label="Name*"
              value={current.preset_name}
              onChange={(e) => handleInput('preset_name', e.target.value)}
            />
            <TextEditorField
              label="Description"
              value={current.preset_description}
              onChange={(html) => handleInput('preset_description', html)}
              hideToolbar={true}
            />
            <div
              className="text-editor-field d-flex align-items-center"
              onClick={() => handleChecked('is_default')}
            >
              <div className="icon-container">
                <span className={`star-icon`}>
                  <StarIcon />
                </span>
                <span
                  className={`star-icon ${current.is_default ? 'active' : ''}`}
                >
                  <StarIcon />
                </span>
              </div>
              <label className="mb-0 ml-2">Set as default preset</label>
            </div>
            <div className="text-editor-field">
              <label>Label Filters</label>
              <div className="current-detail">
                {selectedLabels.length
                  ? selectedLabels.map((l) => (
                      <span className="list-item">{l.label}</span>
                    ))
                  : 'None'}
              </div>
            </div>
            <div className="text-editor-field">
              <label>Primary Metric</label>
              <div className="current-detail">{primary_metric}</div>
            </div>
            {/* <div className="text-editor-field">
              <label>Group by Ad Name</label>
              <div className="current-detail">
                {groupByAdName ? 'Yes' : 'No'}
              </div>
            </div> */}
            <Tooltip
              disabled={current.preset_name.trim().length}
              content={'Preset name cannot be empty.'}
            >
              <div>
                <Button
                  appearance="secondary"
                  disabled={!current.preset_name.trim().length}
                  onClick={handleCreate}
                >
                  Create Preset
                </Button>
              </div>
            </Tooltip>
          </div>
          <Saved
            show={showSaved}
            {...{
              setShowSaved,
              presets,
              handleDelete,
              data,
              loading,
              view_id,
              setPresetsOpen,
              getLoading,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Presets;
