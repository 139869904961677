import React from 'react';
import { AIDrivenIcon, AdNameLabelsIcon, DerivedIcon, UDCIcon } from "./icons/CategoryIcons";
import { DrawerState } from "./types";

export const initialDrawerState: DrawerState = {
  isOpen: false,
  currentView: null,
  previousViews: [],
  selectedVisualId: '',
};

export const AI_DRIVEN = 'ai_driven';
export const DERIVED_LABELS = 'derived_labels';
export const USER_DEFINED = 'user_defined';
export const AD_NAME_LABELS = 'ad_name_labels';
export const STANDARD = 'standard';


export const CATEGORY_TYPES_INDEX = {
  [AI_DRIVEN]: {
    label: 'A.I. Driven',
    icon: AIDrivenIcon,
  },
  [DERIVED_LABELS]: {
    label: 'Derived Labels',
    icon: DerivedIcon,
  },
  [USER_DEFINED]: {
    label: 'User Defined',
    icon: UDCIcon,
  },
  [AD_NAME_LABELS]: {
    label: 'Ad Name Labels',
    icon: AdNameLabelsIcon,
  },
  [STANDARD]: {
    label: 'Standard',
    icon: null,
  },
};

export const DRAWER_TYPE_LABELS = {
  visual_viewer: 'Visual view',
  category_viewer: 'Category view',
  label_viewer: 'Label viewer',
  browser: 'Browser',
  ccc_editor: 'Category editor'
}

export const CATEGORY_TYPE_LABELS = {
  'ai_driven': 'A.I. Driven',
  'derived_labels': 'Derived Labels',
  'user_defined': 'User Defined',
  'ad_name_labels': 'Ad Name Labels',
  'standard': 'Standard',
};