import React, { useEffect, useMemo, useState } from 'react';
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from '../contexts';
import { useViewSettings } from '../../../selectors';
import { useHistory } from 'react-router-dom';
import { toDarwinDate } from '../../../utils/darwin-dates';
import { useMetricOptions } from '../hooks/useMetricOptions';
import DropdownTooltip from './components/DropdownTooltip';
import {
  SET_DETAILS,
  SET_FEEDBACK,
  SET_INSTRUCTIONS,
  SET_OUTPUT,
  SET_PREFERENCES,
  TOGGLE_INCLUDE_DATA,
  useCopilotState,
} from './hooks/useCopilotState';
import CopilotContextProvider from './contexts/copilotContext';
import Settings from './Settings/Settings';
import Output from './Output/Output';
import {
  makeDnaTableData,
  makeImpactReportData,
  makeSummaryData,
} from './utils';
import { useCategories } from '../hooks';
import { useCreativeAnalyticsApis } from '../api';
import {
  getCategories,
  useSimpleImpactApi,
} from '../Recommendations/SimpleImpact';
import { useSelector } from 'react-redux';
import { getRows } from '../../DNA/utils';
import { useDNAColumnOptions } from '../../DNA/useDNAColumnOptions';
import { useCategoriesLookup, useVisuals } from '../selectors';

const _ = require('lodash');

const Wrapper = ({ columns, handleColumns }) => {
  const {
    presetsApi,
    defaultDates,
    filteredData,
    isROAS,
    period1,
    period2,
    spendThreshold,
    selectedAudience,
    primary_metric,
    customEvents,
    clientMetrics,
  } = useCreativeAnalyticsGet();
  const categoriesLookup = useCategoriesLookup();
  const {
    viewId: view_id,
    darwin_client_id: client_id,
    default_primary_metric,
  } = useViewSettings();
  const { copilotState, copilotDispatch } = useCopilotState();
  const {
    dataToInclude,
    currentOutput,
    instructions,
    userFeedback,
    clientPreferences,
    advertiserDetails,
    requestType,
    baseVisual,
  } = copilotState;
  const cpaMetric = isROAS ? 'ROAS' : 'CPA';
  const currentUrl = window.location.href.split(`${view_id}/`)[1];
  const currentViewOpt = {
    label: (
      <DropdownTooltip
        preset={{
          name: 'Current view',
          period1,
          audience: selectedAudience,
          spendThreshold,
          fix_dates: true,
        }}
        metricLabel={default_primary_metric || cpaMetric}
        {...{ filteredData }}
      />
    ),
    value: 'current',
    is_default: false,
    url: currentUrl,
  };
  const [contextOptions, setContextOptions] = useState([currentViewOpt]);
  const [context, setContext] = useState(
    contextOptions.find((opt) => opt.is_default ?? contextOptions[0])
  );
  const [presets, setPresets] = useState([]);
  const [changesMade, setChangesMade] = useState(false);
  const [prevState, setPrevState] = useState(copilotState);
  const [showHint, setShowHint] = useState(false);
  const [impactData, setImpactData] = useState([]);
  const metricOptions = useMetricOptions();
  const history = useHistory();
  const { navCategories } = useCategories();
  const { copilotApi } = useCreativeAnalyticsApis();
  const { data, error, loading, request } = copilotApi.post;
  const {
    data: saveSettingsData,
    error: saveSettingsError,
    loading: saveSettingsLoading,
    request: saveSettingsRequest,
  } = copilotApi.save;
  const {
    data: getSettingsData,
    error: getSettingsError,
    loading: getSettingsLoading,
    request: getSettingsRequest,
  } = copilotApi.get;
  const simpleImpactApi = useSimpleImpactApi();
  const {
    request: simpleImpactRequest,
    data: simpleImpactData,
    loading: simpleImpactLoading,
    error: simpleImpactError,
  } = simpleImpactApi;
  const { dnaReportData } = useSelector(
    ({ CreativeAnalytics: { dnaReportData } }) => ({ dnaReportData })
  );
  const dnaColumnOptions = useDNAColumnOptions({
    isROAS,
    customEvents,
    clientMetrics,
  });
  const all_visuals_array = useVisuals();

  const categorical_columns = useMemo(
    () => getCategories(dnaReportData),
    [dnaReportData]
  );

  const [activeCategories, setActiveCategories] = useState(categorical_columns);

  useEffect(() => {
    setActiveCategories(categorical_columns);
  }, [view_id, period1, period2, dnaReportData]);

  const fetchImpactReportData = (data_list) => {
    simpleImpactRequest({
      data_list,
      primary_metric,
      categorical_columns: activeCategories,
    });
  };

  const rows = useMemo(() => {
    return getRows(all_visuals_array, dnaReportData, primary_metric);
  }, [all_visuals_array, dnaReportData, primary_metric]);

  const isAnswerQuestions = requestType === 'answer-questions';

  const dnaRows = useMemo(() => {
    if (
      dnaReportData &&
      dnaColumnOptions &&
      !dnaColumnOptions.some((opt) => opt.label === 'Phrases')
    ) {
      const withPhrases = rows.map((row) => {
        const match = categoriesLookup['Phrases'].labels
          .filter((label) => label.visuals.some((v) => v.id === row.id))
          .map(({ name }) => name);

        row['Phrases'] = match;

        return row;
      });

      return withPhrases;
    }

    return rows;
  }, [dnaColumnOptions, rows]);

  const requestSuccess =
    data &&
    ((!isAnswerQuestions && data?.recommendation_summary) ||
      (isAnswerQuestions && data?.answer_summary));

  const requestError =
    error || (data && !data?.recommendation_summary && !data?.answer_summary);

  const saveSuccess =
    Array.isArray(saveSettingsData) && saveSettingsData[1] === 201;

  useEffect(() => {
    presetsApi.get.request({
      data: {
        client_id,
        view_id,
      },
      method: 'GET',
    });
  }, []);

  useEffect(() => {
    if (!_.isEqual(prevState, copilotState)) {
      setChangesMade(true);
    } else {
      setChangesMade(false);
    }
  }, [prevState, copilotState]);

  useEffect(() => {
    setPrevState(copilotState);
  }, [currentOutput]);

  useEffect(() => {
    if (dataToInclude.impact && activeCategories.length) {
      fetchImpactReportData(dnaRows);
    }
  }, [dataToInclude.impact, activeCategories.length]);

  useEffect(() => {
    if (Array.isArray(simpleImpactData)) {
      setImpactData(simpleImpactData);
    }
  }, [simpleImpactData]);

  useEffect(() => {
    if (error) console.error(error);
  }, [error]);

  useEffect(() => {
    if (data) {
      if (requestSuccess) {
        console.log(data.recommendation_summary?.summary);
        copilotDispatch({
          type: SET_OUTPUT,
          summary:
            data.recommendation_summary ??
            data.answer_summary ??
            data.recommendation_summary.summary,
          reasons: data.recommendation_reasons_array ?? [],
        });
      } else if (requestError) {
        console.error(data);
      }
    }
  }, [data]);

  useEffect(() => {
    if (isAnswerQuestions && dataToInclude.index) {
      copilotDispatch({
        type: TOGGLE_INCLUDE_DATA,
        dataType: 'index',
      });
    }

    copilotDispatch({
      type: SET_INSTRUCTIONS,
      text: '',
    });
    copilotDispatch({
      type: SET_PREFERENCES,
      text: '',
    });
    copilotDispatch({
      type: SET_DETAILS,
      text: '',
    });
    copilotDispatch({
      type: SET_FEEDBACK,
      text: '',
    });
  }, [requestType]);

  useEffect(() => {
    getSettingsRequest(view_id);
  }, []);

  useEffect(() => {
    if (getSettingsData) {
      if (Array.isArray(getSettingsData)) {
        copilotDispatch({
          type: SET_PREFERENCES,
          text: getSettingsData[0].client_preferences,
        });
        copilotDispatch({
          type: SET_DETAILS,
          text: getSettingsData[0].advertiser_details,
        });
      } else {
        console.log(getSettingsData);
      }
    }
  }, [getSettingsData]);

  const handleSendRequest = () => {
    let dnaTableData = [];
    let impactReportData = {};
    let summaryData = {};

    if (dataToInclude.dna && dnaRows.length) {
      let cols = dnaColumnOptions.map(({ value }) => value);

      if (!dnaColumnOptions.some((opt) => opt.label === 'Phrases')) {
        cols = [...cols, 'Phrases'];
      }

      dnaTableData = makeDnaTableData(dnaRows, cols);
    }

    if (dataToInclude.impact && impactData.length) {
      impactReportData = makeImpactReportData(impactData);
    }

    if (dataToInclude.summary && navCategories.length) {
      summaryData = makeSummaryData(navCategories, isROAS);
    }

    const isRevision = currentOutput.summary.length && userFeedback.length;

    let payload = {
      PRIMARY_METRIC: primary_metric,
      ADVERTISER_DETAILS: advertiserDetails || 'None',
      CLIENT_PREFERENCES: clientPreferences || 'None',
      DNA_TABLE: dnaTableData,
      IMPACT_REPORT: impactReportData,
      SUMMARY_DATA: summaryData,
      INDEX_DATA: dataToInclude.index ? true : false,
      IS_REVISION: isRevision ? true : false,
      USER_INSTRUCTIONS: instructions,
      PREVIOUS_OUTPUT: isRevision ? currentOutput.summary : 'None',
      USER_FEEDBACK: userFeedback || 'None',
      REQUEST_TYPE: requestType.replace('-', ' '),
      visual_example_included: baseVisual.asset_url ? true : false,
      visual_example_labels: baseVisual.labels,
      visual_example_id: baseVisual.id || 'None',
    };

    if (baseVisual.visual_type === 'image') {
      payload = {
        ...payload,
        visual_example_url: baseVisual.asset_url,
      };
    }

    copilotDispatch({
      type: SET_OUTPUT,
      summary: '',
      reasons: [],
    });

    request(payload);

    if (userFeedback.trim().length) {
      copilotDispatch({
        type: SET_INSTRUCTIONS,
        text: userFeedback,
      });
    }
  };

  const handleSaveSettings = () => {
    saveSettingsRequest({
      view_id,
      client_preferences: clientPreferences || 'None',
      advertiser_details: advertiserDetails || 'None',
    });
  };

  return (
    <CopilotContextProvider {...{ copilotState, copilotDispatch }}>
      <div className="copilot">
        <h2>Creative Copilot</h2>
        <div className="copilot__content">
          <Settings
            {...{
              handleSendRequest,
              loading,
              changesMade,
              setChangesMade,
              setShowHint,
              simpleImpactLoading,
              simpleImpactError,
              isAnswerQuestions,
              prevState,
              handleSaveSettings,
              saveSettingsLoading,
              saveSettingsError,
              saveSuccess,
            }}
          />
          <Output
            {...{
              loading,
              requestError,
              setChangesMade,
              showHint,
              columns,
              handleColumns,
              handleSendRequest,
              prevState,
              data,
              isAnswerQuestions,
            }}
          />
        </div>
      </div>
    </CopilotContextProvider>
  );
};

export default Wrapper;
