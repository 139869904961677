import { useEffect, useState } from 'react';
import { notifyCreate } from '../utils/notifyCreate';
import { useInterval } from '../../../hooks/useInterval';

export const useReportRefresh = (
  account_id,
  postRefreshApi,
  refreshStatusApi
) => {
  const [pollingRefreshStatus, setPollingRefreshStatus] = useState(false);

  const handleRefreshReport = () => {
    postRefreshApi.post.request({ account_id });
    notifyCreate(account_id);
    setPollingRefreshStatus(true);
  };

  useInterval(
    async () => {
      if (pollingRefreshStatus) {
        refreshStatusApi.post.request({ account_id: account_id });
      }
    },
    pollingRefreshStatus ? 60000 : null
  );

  useEffect(() => {
    if (postRefreshApi.post.data) {
      const apiData = postRefreshApi.post.data.body;
    }
  }, [postRefreshApi.post.data]);

  useInterval(
    async () => {
      if (pollingRefreshStatus) {
        refreshStatusApi.post.request({ account_id: account_id });
      }
    },
    pollingRefreshStatus ? 60000 : null
  );

  return { handleRefreshReport };
};
