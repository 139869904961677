import { useRef, useEffect } from 'react';
import { abortableFetch } from '../../../utils';
import {
  useApiState,
  bindFetchToRef,
} from '../../../components/Hooks/useApiState';
import { notifyError, notifySaving, notifySuccess } from './utils';

function getSearchTerms(account_id) {
  return abortableFetch(
    ['api', 'creative-analytics', account_id, 'get-search-terms'].join('/'),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
    }
  );
}

function saveSearchTerms(payload) {
  return abortableFetch(
    ['api', 'creative-analytics', 'save-search-terms'].join('/'),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(payload),
    }
  );
}

export function useSearchTermsApi(disableNotifications = false) {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (account_id) =>
      withRef(getSearchTerms(account_id)).then(({ data }) => data),
    {
      loading: true,
      error: null,
      data: null,
      count: 0,
    }
  );
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(saveSearchTerms(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);

  useEffect(() => {
    if (!disableNotifications && postState.loading) {
      notifySaving();
    }
  }, [postState.loading]);

  useEffect(() => {
    if (!disableNotifications && postState.error) {
      notifyError();
    }
  }, [postState.error]);

  useEffect(() => {
    if (!disableNotifications && postState.data) {
      notifySuccess();
    }
  }, [postState.data]);

  return {
    post: {
      ...postState,
      request: postDispatch,
    },
    get: {
      ...getState,
      request: getDispatch,
    },
  };
}
