import { toCurrency, toPercent } from '../../../utils/numbers';

const formatData = (num, format) => {
  switch (format) {
    case 'integer':
      return parseInt(num);
    case 'percentage':
      return toPercent(num);
    case 'currency':
      return toCurrency(num);
  }
};

export const getRowData = (data, value, format) => {
  let newData = [];

  Object.keys(data).forEach((key, i) => {
    if (key === value) {
      newData = data[key].map((num) => formatData(num, format));
    }
  });

  if (newData.length) {
    return newData;
  } else {
    switch (value) {
      case 'newCreativeShareOfVoice':
        newData = data.allNewVisualSpend.map((item, i) => {
          let num = item / data.spend[i];
          return formatData(num, format);
        });
        break;
      case 'percentSpendOnSuccessfulTests':
        newData = data.allNewVisualWinnerSpend.map((item, i) => {
          let num = item / data.spend[i];
          return formatData(num, format);
        });
        break;
      case 'percentSpendOnFailedTests':
        newData = data.allNewVisualLoserSpend.map((item, i) => {
          let num = item / data.spend[i];
          return formatData(num, format);
        });
        break;
      default:
        newData = [];
    }
  }

  return newData;
};
