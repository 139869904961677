import { groupBy } from '../../utils/wranglers';

const POINT_SIZE = 30;

export function useChartData(value, data, colors) {
  const frames = groupByDate(value, data, colors);
  const spend = data.map((d) => d.spend);
  const metrics = data.map((item) => item[value]);

  return {
    dates: frames.map(({ name }) => name),
    xAxisRange: [
      rangeBuffer(Math.min(...spend), 'LOWER'),
      rangeBuffer(Math.max(...spend)),
    ],
    yAxisRange: [
      rangeBuffer(Math.min(...metrics), 'LOWER'),
      rangeBuffer(Math.max(...metrics)),
    ],
    frames,
    traces: frames[0].data.map((item) => ({
      ...item,
      name: item.id[0],
      mode: 'markers',
    })),
  };
}

function rangeBuffer(n, direction, size = 10) {
  const buffer = direction === 'LOWER' ? -0.1 : size;

  return n + buffer;
}

export function useChartLayout({
  width,
  xAxisTitle,
  dates,
  xAxisRange,
  yAxisRange,
}) {
  const sliderSteps = makeSliderSteps(dates);

  return {
    autosize: true,
    margin: {
      l: 32,
      r: 32,
      t: 16,
      b: 16,
    },
    showlegend: false,
    width,
    xaxis: {
      title: 'Spend',
      range: xAxisRange,
    },
    yaxis: {
      title: xAxisTitle,
      range: yAxisRange,
    },
    hovermode: 'closest',
    // We'll use updatemenus (whose functionality includes menus as
    // well as buttons) to create a play button and a pause button.
    // The play button works by passing `null`, which indicates that
    // Plotly should animate all frames. The pause button works by
    // passing `[null]`, which indicates we'd like to interrupt any
    // currently running animations with a new list of frames. Here
    // The new list of frames is empty, so it halts the animation.
    updatemenus: [
      {
        x: 0,
        y: 0,
        yanchor: 'top',
        xanchor: 'left',
        showactive: false,
        direction: 'left',
        type: 'buttons',
        pad: { t: 35, l: 0 },
        buttons: [
          {
            method: 'animate',
            args: [
              null,
              {
                mode: 'immediate',
                fromcurrent: true,
                transition: { duration: 300 },
                frame: { duration: 500, redraw: false },
              },
            ],
            label: 'Play',
          },
          {
            method: 'animate',
            args: [
              [null],
              {
                mode: 'immediate',
                transition: { duration: 0 },
                frame: { duration: 0, redraw: false },
              },
            ],
            label: 'Pause',
          },
        ],
      },
    ],
    // Finally, add the slider and use `pad` to position it
    // nicely next to the buttons.
    sliders: [
      {
        pad: { l: 130, t: 35 },
        font: { size: 12 },
        ticklen: 4,
        currentvalue: {
          visible: true,
          prefix: 'Date: ',
          xanchor: 'right',
          font: { size: 16, color: '#666' },
        },
        steps: sliderSteps,
      },
    ],
  };
}

function makeSliderSteps(dates) {
  return dates.map((date) => ({
    method: 'animate',
    label: date,
    args: [
      [date],
      {
        mode: 'immediate',
        transition: { duration: 300 },
        frame: { duration: 300, redraw: false },
      },
    ],
  }));
}

function groupByDate(metric, data, colors) {
  const accum = () => ({
    name: '',
    data: [],
  });

  const push = (arr, item) => {
    arr.push(item);

    return arr;
  };

  const reducer = (acc, item) => ({
    name: item.date,
    data: push(acc.data, {
      id: [item.name],
      marker: {
        size: [POINT_SIZE],
        sizemode: 'area',
        color: colors[item.name],
      },
      text: [item.name],
      x: [item.spend],
      y: [item[metric]],
    }),
  });

  return [...groupBy(reducer, data, ['date'], accum)];
}
