import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useFetch } from '../../../components/Hooks/fetch';
import { useViewId } from '../../../components/Hooks/view-id';
import { useUrlProps } from '../report-hooks';
import { useViewSettings } from '../../../selectors';

function BreakdownWrapper({
  title,
  valueId,
  ad_ids,
  TableComp,
  breakdowns,
  colors,
}) {
  const { error, loading, data } = useBreakdowns(ad_ids, breakdowns);

  const placeholder = error ? (
    <BasicError />
  ) : loading ? (
    <div>Fetching data</div>
  ) : null;

  if (error) {
    return <BasicError />;
  }
  return (
    <TableComp
      title={title}
      valueId={valueId}
      rows={data.filter(({ type }) => !!type)}
      placeholder={placeholder}
      colors={colors}
    />
  );
}

BreakdownWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  valueId: PropTypes.string.isRequired,
  ad_ids: PropTypes.arrayOf(PropTypes.string),
  TableComp: PropTypes.func.isRequired,
  breakdowns: PropTypes.arrayOf(PropTypes.string).isRequired,
  colors: PropTypes.shape({
    tertiary: PropTypes.string.isRequired,
  }).isRequired,
};

export default BreakdownWrapper;

export function useBreakdowns(ad_ids, breakdowns = []) {
  const viewId = useViewId();
  const { start_date, end_date } = useUrlProps();
  const { campaigns, account_id } = useSelector(
    ({ ViewSettings: { campaigns, account_id } }) => ({
      campaigns,
      account_id,
    }),
  );

  const { error, loading, data } = useFetch(
    ['api', viewId, 'adset-breakdowns'].join('/'),
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        campaigns,
        account_id,
        start_date,
        end_date,
        ad_ids,
        breakdowns,
      }),
    },
    [ad_ids.join(',')],
  );

  return { error, loading, data: setData(data) };
}

function setData(data) {
  return !data || !data.data ? [] : data.data;
}
