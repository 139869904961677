import React, { useMemo } from 'react';
import { LeftMenu } from '../../../../../components/LeftMenu/Menu';
import TableModeMenu from './Menu.TableMode';

import './Menu.scss';

interface MenuItemData {
  name: string;
  hasSubMenu: boolean;
  icon: JSX.Element;
}

interface MenuProps {
  mode: 'table' | 'visuals';
  onMenuItemClick: (menuItem: string) => void;
  setIsSettingsOpen: () => void;
  loading: boolean;
  isDirectMail: boolean;
  isNonPaidMediaType: boolean;
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
  mainView: any;
}

const Menu: React.FC<MenuProps> = ({
  // mode,
  onMenuItemClick,
  // setIsSettingsOpen,
  // loading,
  // isDirectMail,
  // isNonPaidMediaType,
  collapsed,
  setCollapsed,
  mainView,
}) => {
  return (
    <LeftMenu {...{ collapsed, setCollapsed, mainView }}>
      <TableModeMenu
        {...{
          collapsed,
          setCollapsed,
        }}
      />
    </LeftMenu>
  );
};

export default Menu;
