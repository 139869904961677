import { batch } from 'react-redux';
import {
  RUNNING_ADS_BY_NAME,
  RUNNING_ADS_BY_NAME_FETCH,
  setRunningAdsByName,
  setRunningAdsByNameSelected,
} from './actions';
import { API_SUCCESS, apiRequest, API_FAILURE } from '../Api/actions';
import { setLoader } from '../Loaders/actions';
import { setError } from '../Errors/actions';

const middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case RUNNING_ADS_BY_NAME_FETCH: {
        const { ad_names } = action.data;

        // Only retrieve new ad names.
        if (ad_names.length === 0) {
          dispatch(setRunningAdsByNameSelected([]));
        } else {
          const {
            ViewSettings: {
              start_date,
              end_date,
              selectedAudience,
              viewId,
              account_id,
              campaigns,
            },
          } = getState();

          dispatch(setRunningAdsByNameSelected(ad_names));

          batch(() => {
            dispatch(setLoader(RUNNING_ADS_BY_NAME, true));
            dispatch(
              apiRequest({
                body: {
                  ad_names,
                  start_date,
                  end_date,
                  audience: [selectedAudience].filter((a) => a !== ''),
                  account_id,
                  campaigns,
                },
                url: ['api', viewId, '/creative-testing-by-ad-names'].join('/'),
                entity: RUNNING_ADS_BY_NAME,
                method: 'POST',
              })
            );
          });
        }

        break;
      }

      case `[${RUNNING_ADS_BY_NAME}] ${API_SUCCESS}`: {
        const { response } = action;

        batch(() => {
          dispatch(setRunningAdsByName(response));
          dispatch(setLoader(RUNNING_ADS_BY_NAME, false));
        });

        break;
      }

      case `[${RUNNING_ADS_BY_NAME}] ${API_FAILURE}`: {
        const { error } = action;

        batch(() => {
          dispatch(setError(RUNNING_ADS_BY_NAME, error));
          dispatch(setLoader(RUNNING_ADS_BY_NAME, false));
        });

        break;
      }

      default:
        return result;
    }

    return result;
  };

export default middleware;
