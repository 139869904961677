import { useEffect, useState } from 'react';
import { useViewSettings } from '../../../selectors';
import {
  useFetchSourceApi,
  useViewSettingsApi,
} from '../../BulkActions/ViewSettings/api';
import { getConceptCampaigns } from '../../BulkActions/ViewSettings/utils';
import { useDispatch } from 'react-redux';
import { setViewSettings } from '../../../store/ViewSettings/actions';

export const useViewSettingsPayload = () => {
  const {
    platforms = [],
    viewName,
    viewId,
    concepts,
    default_primary_metric,
    darwin_client_id,
  } = useViewSettings();
  const [sourceCampaigns, setSourceCampaigns] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [campaignConcepts, setCampaignConcepts] = useState([]);
  const [conceptCampaigns, setConceptCampaigns] = useState([]);
  const fetchSourceApi = useFetchSourceApi();
  const viewSettingsApi = useViewSettingsApi();
  const { fetch_fb_campaigns } = viewSettingsApi;

  useEffect(() => {
    fetchSourceApi.get.request(viewId);
    fetch_fb_campaigns.request({
      darwin_client_id,
      platform: platforms[0],
      concepts:
        concepts && concepts.length
          ? typeof concepts === 'string'
            ? concepts
            : concepts.join(',')
          : '',
    });
  }, [viewId, concepts]);

  useEffect(() => {
    if (concepts.length && conceptCampaigns.length) {
      if (typeof concepts === 'string') {
        setCampaignConcepts(concepts.split(','));
      } else {
        setCampaignConcepts(concepts);
      }
    }
  }, [concepts, conceptCampaigns]);

  useEffect(() => {
    if (fetchSourceApi.get.data) {
      const { data } = fetchSourceApi.get;
      const campaigns = data.campaigns.map((campaign) => {
        return {
          label: campaign.name,
          value: campaign.id,
        };
      });

      setSourceCampaigns(campaigns);
    }
  }, [fetchSourceApi.get.data]);

  useEffect(() => {
    if (fetch_fb_campaigns.data?.campaigns) {
      const { data } = fetch_fb_campaigns;

      if (
        concepts.length &&
        data.concept_campaigns &&
        data.concept_campaigns.length
      ) {
        setConceptCampaigns(
          data.concept_campaigns.map((c) => {
            return {
              label: c.name,
              value: c.id,
            };
          })
        );
      }
    }
  }, [fetch_fb_campaigns.data]);

  useEffect(() => {
    if (sourceCampaigns.length || conceptCampaigns.length) {
      const merged = [...sourceCampaigns, ...conceptCampaigns];
      const ids = merged.map((c) => c.value);
      const uniqueCampaigns = merged.filter(
        ({ value }, index) => !ids.includes(value, index + 1)
      );

      setSelectedCampaigns(uniqueCampaigns);
    }
  }, [sourceCampaigns, conceptCampaigns]);

  return {
    payload: {
      _id: viewId,
      viewName,
      platforms: platforms ?? [],
      campaigns: selectedCampaigns?.map((c) => c.value) || [],
      concepts: campaignConcepts,
      concept_campaigns: getConceptCampaigns(
        campaignConcepts,
        selectedCampaigns
      ),
      primary_metric: default_primary_metric ?? '',
    },
    isLoading: fetchSourceApi.get.loading || fetch_fb_campaigns.loading,
    error: fetchSourceApi.get.error || fetch_fb_campaigns.error,
  };
};

export function useUpdateSucesssConditions() {
  const dispatch = useDispatch();

  return (update) => {
    const action = setViewSettings({
      visual_success_conditions: update
    });

    return dispatch(action);
  };
}
