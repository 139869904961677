import clsx from 'clsx';
import React, { ReactChildren } from 'react';

type DrawerButton = {
  onClick: () => void;
  style?: React.CSSProperties;
  className?: string;
  children: React.ReactChildren;
};

export const DrawerButton = ({
  onClick,
  style = {},
  className = null,
  children,
}) => {
  // text
  // icon
  // color (based on type)
  // multi-section? (dependents)

  return (
    <button
      style={style}
      className={clsx('drawer__btn', {
        [className]: className,
      })}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

type SectionProps = {
  className? : string;
  style?: React.CSSProperties;
  children: ReactChildren;
}

const Section = ({ className, style, children }: SectionProps) => {
  return (
    <div
      style={style}
      className={`drawer__btn__section ${className ? className : ''}`}
    >
      {children}
    </div>
  );
};

DrawerButton.Section = Section;
