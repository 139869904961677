import React, { useMemo, useState } from 'react';
import './index.scss';
import {
  useTable,
  useSortBy,
  useFilters,
  useFlexLayout,
  usePagination,
  useRowSelect,
} from 'react-table';
import {
  getExportFileBlob,
  useExportData,
} from '../../../BulkActions/EventHistory/export';
import Button from '../../../../components/Button';
import { Filter } from '../../../Marketplace/components/Filters';
import { useOverflowActive } from '../../../../hooks/useOverflowActive';
import Tooltip from '../../../../components/Tooltip';

const generateSortingIndicator = (column) =>
  column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';

export const ImportancesTable = ({ memoed }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, filters },
    setFilter,
    exportData,
  } = useTable(
    {
      columns: memoed.cols,
      data: memoed.rows,
      getExportFileBlob,
      initialState: {
        pageSize: 10,
      },
    },
    useFilters,
    useSortBy,
    usePagination,
    useExportData
  );

  const pageProps = {
    gotoPage,
    previousPage,
    nextPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    pageIndex,
    pageSize,
    setPageSize,
  };

  const handleFilterChange = (columnId, value) => {
    setFilter(columnId, value.length > 0 ? value : null, { exactMatch: true });
  };

  const columnOptions = {};

  headerGroups.forEach((headerGroup) => {
    headerGroup.headers.forEach((column) => {
      if (column.canFilter) {
        let id = column.id;

        const columnValues = column.preFilteredRows.reduce((acc, row) => {
          acc.push(row.values[id]);
          return acc;
        }, []);
        columnOptions[column.id] = [...new Set(columnValues)].filter(Boolean);
      }
    });
  });

  return (
    <>
      <Button
        appearance="raised"
        className="mr-2"
        onClick={() => exportData('csv', false)}
      >
        Export CSV
      </Button>
      <table
        {...getTableProps()}
        className="table table-striped importances-table"
        id="creative-projects"
      >
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className="importances-table__headercell"
                >
                  <div {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                    {generateSortingIndicator(column)}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={`tr-${i}`}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className="importances-table__headercell"
                >
                  {/* {column.canFilter && (
                    <select
                      className="mt-2"
                      onClick={(e) => e.stopPropagation()}
                      value={
                        filters.find((filter) => filter.id === column.id)
                          ?.value || ''
                      }
                      onChange={(e) =>
                        handleFilterChange(column.id, e.target.value)
                      }
                    >
                      <option value={''}>All</option>
                      {columnOptions[column.id].map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  )} */}
                </th>
              ))}
            </tr>
          ))}
          {page.map((row, i) => {
            prepareRow(row);

            return <DataRow key={`data-row-${i}`} row={row} />;
          })}
        </tbody>
      </table>
      <PageButtons {...pageProps} />
    </>
  );
};

function DataRow({ row }) {
  return (
    <tr {...row.getRowProps()} className="data-row-link">
      {row.cells.map((cell, i) => (
        <td {...cell.getCellProps()} className="importances-table__cell">
          {cell.render('Cell')}
        </td>
      ))}
    </tr>
  );
}

function PageButtons({
  gotoPage,
  previousPage,
  nextPage,
  canNextPage,
  canPreviousPage,
  pageOptions,
  pageCount,
  pageIndex,
  pageSize,
  setPageSize,
}) {
  return (
    <>
      <div className="pagination justify-content-center flex-column">
        <div className="btn-group">
          <button
            className="btn btn-secondary"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </button>{' '}
          <button
            className="btn btn-secondary"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {'<'}
          </button>{' '}
          <button
            className="btn btn-secondary"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {'>'}
          </button>{' '}
          <button
            className="btn btn-secondary"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'>>'}
          </button>{' '}
        </div>
        <div className="d-flex align-items-center justify-content-center mt-2">
          <span className="mr-4">
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <span className="mr-4">
            Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: '30px' }}
            />
          </span>{' '}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}
