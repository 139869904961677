import React from 'react';
import { DARWIN_BLUE } from '../../../constants';

const SparkleIcon = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.78326 0.663477C9.83324 0.525758 9.92442 0.406768 10.0444 0.32268C10.1644 0.238591 10.3073 0.193481 10.4538 0.193481C10.6003 0.193481 10.7433 0.238591 10.8633 0.32268C10.9833 0.406768 11.0744 0.525758 11.1244 0.663477L11.8064 2.52684C12.3413 3.9876 13.1882 5.31419 14.2882 6.41417C15.3882 7.51415 16.7148 8.36107 18.1755 8.89594L20.0374 9.57793C20.1752 9.62792 20.2942 9.7191 20.3782 9.83907C20.4623 9.95905 20.5074 10.102 20.5074 10.2485C20.5074 10.395 20.4623 10.538 20.3782 10.658C20.2942 10.7779 20.1752 10.8691 20.0374 10.9191L18.1755 11.6011C16.7148 12.136 15.3882 12.9829 14.2882 14.0829C13.1882 15.1828 12.3413 16.5094 11.8064 17.9702L11.1244 19.8321C11.0744 19.9698 10.9833 20.0888 10.8633 20.1729C10.7433 20.257 10.6003 20.3021 10.4538 20.3021C10.3073 20.3021 10.1644 20.257 10.0444 20.1729C9.92442 20.0888 9.83324 19.9698 9.78326 19.8321L9.10126 17.9702C8.56639 16.5094 7.71947 15.1828 6.61949 14.0829C5.51951 12.9829 4.19292 12.136 2.73216 11.6011L0.8688 10.9191C0.73108 10.8691 0.612091 10.7779 0.528002 10.658C0.443913 10.538 0.398804 10.395 0.398804 10.2485C0.398804 10.102 0.443913 9.95905 0.528002 9.83907C0.612091 9.7191 0.73108 9.62792 0.8688 9.57793L2.73216 8.89594C4.19292 8.36107 5.51951 7.51415 6.61949 6.41417C7.71947 5.31419 8.56639 3.9876 9.10126 2.52684L9.78326 0.663477Z"
      fill={DARWIN_BLUE}
    />
  </svg>
);

export default SparkleIcon;
