import React from 'react';
import {
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from 'recharts';
import { DARWIN_BLUE } from '../../../../../constants';

function pointValues([x, y]) {
  return {
    x: { label: x.name, value: x.value },
    y: { label: y.name, value: y.value },
    label: x.payload.category,
  };
}

function minMax(values) {
  return [Math.min(...values), Math.max(...values)];
}

function getSeriesNames(resultSet) {
  const names = resultSet.seriesNames();

  if (names.length < 2) {
    return [
      { key: '', title: '' },
      { key: '', title: '' },
    ];
  }
  return names;
}

export default function AdScatter({ resultSet }) {
  const [x, y] = getSeriesNames(resultSet);
  const data = resultSet.chartPivot();

  const xDomain = minMax(data.map((d) => d[x.key]));
  const yDomain = minMax(data.map((d) => d[y.key]));

  return (
    <ResponsiveContainer width="100%" height={350}>
      <ScatterChart>
        <XAxis
          type="number"
          dataKey={x.key}
          domain={xDomain}
          name={x.title}
          label={x.title}
          scale="linear"
        />
        <YAxis
          type="number"
          dataKey={y.key}
          domain={yDomain}
          name={y.title}
          label={y.title}
          scale="linear"
        />
        <CartesianGrid vertical={false} />

        <Scatter
          data={data}
          stackId="a"
          name={`${x.title} x ${y.title}`}
          fill={DARWIN_BLUE}
        />

        <Legend />
        <Tooltip content={<ScatterTooltip />} />
      </ScatterChart>
    </ResponsiveContainer>
  );
}

function ScatterTooltip({ active, payload }) {
  if (!active) {
    return null;
  }
  // Each payload item has duplicate data for the whole point.
  const { x, y, label } = pointValues(payload);

  return (
    <div className="bg-white darwin-box-shadow px-2">
      <p>
        Name:
        {' '}
        <strong>{label}</strong>
      </p>
      <p>
        {x.label}
        :
        <strong>{x.value}</strong>
      </p>
      <p>
        {y.label}
        :
        <strong>{y.value}</strong>
      </p>
    </div>
  );
}
