import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { RUNNING_ADS_BY_NAME } from "../../store/RunningAdsByName/actions";
import { ZoneLabel, ZoneContent } from "./Running.Zone.Content";
import { SizedChevronDown, HelpIcon } from "./Helpers";
import { SLIGHTLY_LARGER_FONT } from "./constants";
import { activeBG } from "./utils";

class Zone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showNested: this.props.name === "Primary Stats"
    };

    this.toggleNested = this.toggleNested.bind(this);
    this.handleElement = this.handleElement.bind(this);
  }

  componentWillUnmount() {
    this.toggleNested = null;
    this.handleElement = null;
  }

  toggleNested(e) {
    this.setState({ showNested: !this.state.showNested });
  }

  handleElement({ toggleTooltip, handleSelection, activeKey, ...props }) {
    this.props.handleSelection(props);
  }

  render() {
    const {
      nested_zones,
      name,
      rank,
      ranks,
      type,
      value,
      activeKey,
      toggleTooltip
    } = this.props;

    const isActive = activeKey === `${type}-${value}`;
    const nestedHeightLimit =
      this.state.showNested && nested_zones.length > 4
        ? { height: "240px", overflow: "auto" }
        : null;

    return (
      <>
        <div
          className="zone"
          style={activeBG(isActive)}
        >

          <div
            className="zone-content"
            onClick={e => this.handleElement(this.props)}
          >
            <ZoneContent
              Label={
                <div style={SLIGHTLY_LARGER_FONT}>
                  {name}
                </div>
              }
              value={value}
              type={type}
              rank={rank}
              total={ranks}
              active={isActive}
            />
          </div>
        </div>

        <div className="d-flex flex-col" style={nestedHeightLimit}>
          {this.state.showNested &&
            nested_zones &&
            nested_zones.map((zone, i) => (
              <NestedZone
                {...zone}
                key={`${zone.value}-${i}`}
                active={activeKey === `${zone.type}-${zone.value}`}
                handleElement={e => this.handleElement(zone)}
                toggleTooltip={e => (
                  e.stopPropagation(), toggleTooltip(zone.type)
                )}
              />
            ))}
        </div>
      </>
    );
  }
}

Zone.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rank: PropTypes.number,
  ranks: PropTypes.number,
  nested_zones: PropTypes.arrayOf(
    PropTypes.shape({
      zone_name: PropTypes.string,
      type: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired
    })
  ),
  // methods
  handleSelection: PropTypes.func.isRequired,
  toggleTooltip: PropTypes.func
};

const stateToProps = (
  { RunningAdsByName: { ads }, Loaders },
  { adName, zoneType }
) => {
  const _ads = ads[adName];

  return {
    isLoading: Loaders[RUNNING_ADS_BY_NAME],
    ...(_ads && _ads.zones.find(({ type }) => type === zoneType))
  };
};

export default connect(
  stateToProps,
  null
)(({ isLoading, ...rest }) => isLoading || <Zone {...rest} />);

const NestedZone = ({
  name,
  type,
  value,
  ranks,
  rank,
  active,
  handleElement,
  toggleTooltip
}) => {
  return (
    <div
      onClick={handleElement}
      className="zone width_100pct align-self-end"
      style={activeBG(active)}
    >
      <ZoneContent
        Label={
          <ZoneLabel>
            <div style={SLIGHTLY_LARGER_FONT}>
              {name} <HelpIcon onClick={toggleTooltip} />
            </div>
          </ZoneLabel>
        }
        value={value}
        rank={rank}
        total={ranks}
        type={type}
        active={active}
      />
    </div>
  );
};
