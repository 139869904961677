import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { SubMenu } from './SubMenu';

import './MenuItem.scss';
import Tooltip from '../Tooltip';

const ColorSquare = ({ color }) => {
  return <div className="menu-item__square" style={{ background: color }} />;
};

interface MenuItemProps {
  title: string;
  icon?: JSX.Element | React.Component;
  onMenuItemClick?: (menuItem: string) => void;
  subMenu?: any;
  isSelected?: boolean;
  colorSquare?: string;
  expandByDefault?: boolean;
  exclude?: boolean;
  display?: string;
  id?: string;
  className?: string;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  title,
  icon,
  onMenuItemClick,
  subMenu,
  colorSquare,
  isSelected = false,
  expandByDefault = true,
  exclude = false,
  display,
  id,
  className,
  collapsed,
  setCollapsed,
  isPrimaryItem = false,
}) => {
  if (exclude) return null;
  const hasSubMenu = !!subMenu;
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(!!expandByDefault);
  const alwaysExpanded = hasSubMenu && !!onMenuItemClick;

  const handleSubMenuToggle = () => {
    setIsSubMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    if (title === 'Detailed Reports') {
      if (display === 'performance_frequency') {
        setIsSubMenuOpen(true);
      } else {
        setIsSubMenuOpen(false);
      }
    }
  }, [display]);

  const handleMenuItemClick = () => {
    if (hasSubMenu && !alwaysExpanded) {
      handleSubMenuToggle();

      if (title === 'Detailed Reports' && setCollapsed) {
        setCollapsed(false);
      }
    } else if (!!onMenuItemClick) {
      onMenuItemClick(title);
    }
  };

  return (
    <Tooltip
      disabled={
        !collapsed || ['Legacy Reports', 'BETA Reports'].includes(title)
      }
      content={title}
      placement={'right'}
      follow={false}
    >
      <div>
        <li
          id={id}
          className={clsx('menu-item', {
            'is-selected': isSelected,
            'is-primary-item': isPrimaryItem,
            [className]: !!className,
          })}
        >
          <div className="menu-item-content" onClick={handleMenuItemClick}>
            <div className="d-flex align-items-center">
              {colorSquare && <ColorSquare color={colorSquare} />}
              {icon && (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ width: '20px' }}
                >
                  <span className="menu-item__icon">{icon}</span>
                </div>
              )}
              <span
                className={`menu-item__title ${icon ? 'ml-3' : ''} ${
                  collapsed ? 'collapsed' : ''
                }`}
              >
                {title}
              </span>
            </div>
            {hasSubMenu && !alwaysExpanded && !collapsed && (
              <p className="toggle-submenu ml-3">{isSubMenuOpen ? '-' : '+'}</p>
            )}
          </div>
          {!collapsed && (alwaysExpanded || (hasSubMenu && isSubMenuOpen)) && (
            <SubMenu
              subMenuContent={subMenu}
              padding={className === 'icon-only' ? '0' : '1rem'}
            />
          )}
        </li>
      </div>
    </Tooltip>
  );
};
