import React, { useEffect, useRef, useState } from 'react';
import _isEqual from 'lodash/isEqual';
import { useCreativeAnalyticsGet } from '../contexts';
import { isTodayOrFuture, LabelPerformanceReport } from '../../Analytics2';
import { PeriodPicker } from '../PerformanceFrequency/Pickers';
import { useSearchParams } from '../../../components/Hooks/search-params';
import useDatePeriods from '../hooks/useDatePeriods';
import { darwinDateToDate } from '../../../utils/darwin-dates';
import { useAccountId, useActionName } from '../selectors';
import { useSelector } from 'react-redux';
import { useAbortableFetch } from '../../../hooks/useAbortableFetch';
import Tooltip from '../../../components/Tooltip';
import { useRecommendationsStatus } from '../Recommendations/api';
import IconButton from '../../../components/IconButton';
import { IQ_MOCK } from './mock';
import { RightDrawerWrapper } from '../RightDrawer';
import { Insights } from './Insights';
import './styles.scss';
import { ErrorBoundary } from '../../../components/Helpers/Error';
import { useAnalyticsUrlProps } from './hooks';
import {
  CHANGE_DATES,
  REPORT_BUILDER,
} from '../../../store/UserActivity/constants';
import { useTrackEvent } from '../hooks/useTrackEvent';
import { useLabelPerformanceApi } from '../../Analytics2/api';
import { useUrlParams } from '../hooks/useUrlParams';
import { getMetric } from '../../Analytics2/api/run-query';

const ENDPOINT = ['api', 'creative-analytics', 'fetch-analytics'].join('/');

export const useDarwinIqApi = () => {
  const { data, loading, error, request, clearData } = useAbortableFetch();
  const actionName = useActionName();
  const { account_id, view_id, isROAS } = useSelector(
    ({ ViewSettings: { viewId, account_id, isROAS } }) => ({
      view_id: viewId,
      account_id,
      isROAS,
    })
  );
  const { setParams } = useSearchParams();
  const { categories: selectedCategories } = useAnalyticsUrlProps();
  const setSelectedCategories = (update) =>
    setParams({ categories: encodeURIComponent(update?.join(',') || []) });
  const { primary_metric } = useUrlParams();
  const lastPayloadRef = useRef(null);

  const fetchDarwinIqData = (
    start_date = '2025-01-01',
    end_date = '2025-01-31',
    selected_segment = null,
    refresh_insights = false
  ) => {
    const payload = {
      account_id,
      view_id,
      start_date,
      end_date,
      optimization_event:
        getMetric(primary_metric, actionName) || primary_metric,
      is_roas: isROAS,
      refresh_insights: refresh_insights || isTodayOrFuture(end_date) || false,
      selected_segment,
      platform: 'Meta',
    };

    lastPayloadRef.current = payload;

    request(ENDPOINT, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(payload),
    });
  };

  const refetchLastRequest = (overrides = {}) => {
    if (lastPayloadRef.current) {
      const updatedPayload = { ...lastPayloadRef.current, ...overrides };
      request(ENDPOINT, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(updatedPayload),
      });
    }
  };
  const parsed = parseData(data);

  useEffect(() => {
    if (parsed?.insights?.length) {
      const elementsSection = parsed.insights.find(
        (section) => section.section_name === 'Creative Elements'
      );

      if (!selectedCategories?.length) {
        if (elementsSection) {
          const categories = elementsSection.section_recommendations?.map(
            (item) => item.category_name
          );

          setSelectedCategories(categories);
        } else {
          setSelectedCategories(['Format']);
        }
      }
    }
  }, [parsed]);

  return {
    data: parsed,
    loading,
    error,
    fetchDarwinIqData,
    refetchLastRequest,
    clearData,
  };
};

const parseData = (data) => data?.data || data;

export const PivotReport = () => {
  const { getParams } = useSearchParams();
  const { period1 } = getParams('period1') || {};
  const [startDate, endDate] = useDatePeriods(period1);
  const dateRange = {
    start: startDate,
    end: endDate,
  };
  const { fetchLabelsApi, runQueryApi } = useLabelPerformanceApi(dateRange);
  const darwinIqApi = useDarwinIqApi();
  const recommendationsStatus = useRecommendationsStatus();
  const [isRecsOpen, setIsRecsOpen] = useState(false);
  const params = useAnalyticsUrlProps();
  const trackEvent = useTrackEvent();

  const allowDataMode =
    runQueryApi.data?.summary_data?.length === 1 ||
    !params.selectedRowId?.includes('No Grouping');

  const getStatus = () => {
    if (darwinIqApi.loading) return 'loading';
    if (!!darwinIqApi.data) return 'success';
    if (darwinIqApi.error) return 'error';
    return null;
  };

  return (
    <div className="d-flex justify-content-between row">
      <div className={`col-${isRecsOpen ? 8 : 12}`} style={{ flex: 4 }}>
        <LabelPerformanceReport
          darwinIqApi={darwinIqApi}
          fetchLabelsApi={fetchLabelsApi}
          runQueryApi={runQueryApi}
          renderAnalyticsToggle={() => {
            const status = getStatus();

            return (
              <Tooltip
                content={`Analytics intelligence${
                  !!status
                    ? status === 'success'
                      ? ' ready to view'
                      : ` (${status})`
                    : ''
                }`}
                follow={false}
                placement={'left'}
              >
                <div>
                  <IconButton
                    style={{ height: '30px', padding: '0 6px' }}
                    appearance="raised"
                    active={isRecsOpen}
                    status={status}
                    onClick={() => {
                      setIsRecsOpen((prev) => !prev);
                    }}
                  >
                    <i className="fa-solid fa-wand-magic-sparkles"></i>
                  </IconButton>
                </div>
              </Tooltip>
            );
          }}
          PeriodPicker={
            <PeriodPicker
              toggleClass="analytics__picker analytics__picker--btn darwin-button darwin-button--raised mr-2"
              name="period1"
              disableToday={false}
              fetchPerformanceData={() => {}}
              isOrganicView={false}
              startDate={darwinDateToDate(startDate)}
              endDate={darwinDateToDate(endDate)}
              onDateChange={() => trackEvent(REPORT_BUILDER, CHANGE_DATES)}
            />
          }
          dateRange={dateRange}
        />
      </div>

      {!!isRecsOpen && (
        <div className="col-4 darwin-iq-right-rail">
          <ErrorBoundary>
            <Insights
              loading={darwinIqApi.loading || runQueryApi.loading}
              close={() => setIsRecsOpen(false)}
              insightsData={getInsightsData(darwinIqApi.data)}
              refetchIqData={(overrides: {}) =>
                darwinIqApi.refetchLastRequest(overrides)
              }
              allowDataMode={allowDataMode}
            />
          </ErrorBoundary>
        </div>
      )}
    </div>
  );
};

const getInsightsData = (data) => {
  return data?.insights || [];
};
