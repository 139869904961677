import React, { useEffect, useState, useRef } from 'react';
import { ErrorBoundary } from '../../../components/Helpers/Error';
import { useSelector } from 'react-redux';
import { REPORTS } from '../../../store/CreativeReports/actions';
import { format$ } from './utils';

import './ElementPerformance.scss';
import { useCreativeAnalyticsGet } from '../contexts';
import { useOutcomes } from './hooks';
import { useActionName } from '../selectors';
import { toFixed_2 } from '../../../utils/numbers';

const useLoading = () => {
  return useSelector(({ Loaders }) => {
    return Loaders[REPORTS];
  });
};

const ElementPerformance = ({ data }) => {
  const loading = useLoading();
  const { selected } = useCreativeAnalyticsGet();
  const { outcomes } = useOutcomes();
  const isVisualHash = selected.type === 'visual_hash';
  const showOutcomes = !!outcomes;

  let Render = null;

  if (loading) {
    Render = <p className="element-performance__subtitle">Fetching data...</p>;
  }

  let text = 'asset';

  if (!data) return null;

  if (isVisualHash) {
    text = (
      <a target="_blank" href={selected.element.url}>
        visual
      </a>
    );
  }

  Render = (
    <div>
      <h3 className="element-performance__title">{data.id}</h3>
      <h4 className="element-performance__subtitle">
        This {text} is performing {data.percent_difference} better than other
        elements.
      </h4>
      <TableDisplay
        rows={data.display.find((obj) => obj.performance === 'cpa').data || []}
      />
      {showOutcomes && (
        <TableDisplay
          rows={outcomes.outcomes_avg}
          title="Outcomes - Average Values"
        />
      )}
      {showOutcomes && (
        <TableDisplay
          rows={outcomes.outcomes_breakdown}
          title="Outcomes - Performance Breakdown"
        />
      )}
    </div>
  );

  return (
    <ErrorBoundary fallback={<h5>An error occurred :(</h5>}>
      <div className="element-performance">{Render}</div>
    </ErrorBoundary>
  );
};

export default ElementPerformance;

const TableDisplay = ({ rows, title }) => {
  const { selected } = useCreativeAnalyticsGet();
  const isVisualHash = selected.type === 'visual_hash';
  const actionName = useActionName();
  const isROAS = actionName.includes('value_');


  const Header = (
    <div className="performance-table__header">
      <p className="performance-table__type">TYPE</p>
      <p className="performance-table__num">{isROAS ? 'ROAS' : 'CPA'}</p>
      <p className="performance-table__num">SPEND</p>
    </div>
  );

  const Rows = rows.map(({ type, spend, cpa }, i) => {
    let aType = '';

    if (type) {
      if (type.length > 17) {
        aType = `${type.slice(0, 14)}...`;
      } else {
        aType = type;
      }
    }

    const cpaFloat = cpa ? Number(cpa.toString().replace(/[^0-9.-]+/g, '')) : 0;
    const roas = cpaFloat > 0 ? toFixed_2(1 / cpaFloat) : '0.00';

    return (
      <div
        key={`${type}-${cpa}-${i}`}
        className="performance-table__values d-flex flex-row"
      >
        <p className="performance-table__type" title={type}>
          {isVisualHash && i === 0 ? (
            <a target="_blank" href={selected.element.url}>
              {aType}
            </a>
          ) : (
            aType
          )}
        </p>
        <p className="performance-table__num" title={isROAS ? roas : cpa}>
          {isROAS ? roas : cpa}
        </p>
        <p className="performance-table__num" title={spend}>
          {spend}
        </p>
      </div>
    );
  });

  return (
    <div className="performance-table">
      <h3>{title || 'Element Performance'}</h3>
      {Header}
      {Rows}
    </div>
  );
};
