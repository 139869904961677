import Tippy from "@tippyjs/react";
import React, { useState } from "react";
import Button from "../../../components/Button";
import { BRANDS, PROMOS, KEYWORDS } from "./constants";
import { SuggestionIcon } from "./Helpers";
import { useLabelSetupValidation } from "./hooks";
import LabelsList from "./LabelsList";
import {
  ADD_BRAND,
  ADD_PROMO,
  ADD_KEYWORD,
} from "./reducer";
import { sortTerms, formatLabel } from "./utils";

const BasicLabelSetup = ({
  labelSetup,
  loading,
  error,
  setEditBasicLabels,
  handleSaveSearchTerms,
  saving,
  recentlyRemoved,
  setRecentlyRemoved,
}) => {
  const [brandSortAz, setBrandSortAz] = useState(false);
  const [promoSortAz, setPromoSortAz] = useState(false);
  const [keywordSortAz, setKeywordSortAz] = useState(false);
  const [labelsChanged, setLabelsChanged] = useState(false);
  const {
    activeBrands,
    inactiveBrands,
    activePromos,
    inactivePromos,
    activeKeywords,
    inactiveKeywords,
  } = labelSetup;

  const underline = <span className="underline"></span>;

  const sectionProps = {
    labelSetup,
    brandSortAz,
    setBrandSortAz,
    promoSortAz,
    setPromoSortAz,
    keywordSortAz,
    setKeywordSortAz,
    setLabelsChanged,
    setRecentlyRemoved,
  };

  const brandLabels = [...activeBrands, ...inactiveBrands];
  const promoLabels = [...activePromos, ...inactivePromos];
  const keywordLabels = [...activeKeywords, ...inactiveKeywords];

  if (loading) {
    return (
      <div className="label-setup__loading" style={{ textAlign: "center" }}>
        <span>Loading labels</span>
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="label-setup__error" style={{ textAlign: "center" }}>
        <p>Sorry, there was an error.</p>
        <span className="error-back" onClick={() => setEditBasicLabels(false)}>
          Go back
        </span>
      </div>
    );
  }

  return (
    <>
      <SetupSection
        title="Brand"
        description={
          <>If {underline} shows in the ad, the ad has the brand in it.</>
        }
        labels={sortTerms(brandLabels, brandSortAz)}
        inputAction={ADD_BRAND}
        section={BRANDS}
        recentlyRemoved={recentlyRemoved.brands}
        {...{ ...sectionProps }}
      />
      <SetupSection
        title="Words/symbols that indicate promotion"
        description={
          <>If {underline} shows in the ad, the ad has a promotion in it.</>
        }
        labels={sortTerms(promoLabels, promoSortAz)}
        inputAction={ADD_PROMO}
        section={PROMOS}
        recentlyRemoved={recentlyRemoved.promos}
        {...{ ...sectionProps }}
      />
      <SetupSection
        title="Keywords"
        description=""
        labels={sortTerms(keywordLabels, keywordSortAz)}
        inputAction={ADD_KEYWORD}
        section={KEYWORDS}
        recentlyRemoved={recentlyRemoved.keywords}
        {...{ ...sectionProps }}
      />
      <div className="d-flex justify-content-end mt-3">
        {saving && <i className="fa-solid fa-floppy-disk fa-fade saving-icon"></i>}
        <Button
          onClick={() => {
            handleSaveSearchTerms(activeBrands, activePromos, activeKeywords);
            setLabelsChanged(false);
          }}
          disabled={!labelsChanged}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default BasicLabelSetup;

const SetupSection = ({
  title,
  description,
  labels,
  inputAction,
  section,
  labelSetup,
  brandSortAz,
  setBrandSortAz,
  promoSortAz,
  setPromoSortAz,
  keywordSortAz,
  setKeywordSortAz,
  setLabelsChanged,
  recentlyRemoved,
  setRecentlyRemoved,
}) => {
  const isBrands = section === BRANDS;
  const isPromos = section === PROMOS;
  const isKeywords = section === KEYWORDS;
  const [existingLabel, setExistingLabel] = useState("");

  const findExisting = (input) => {
    setExistingLabel(input);

    setTimeout(() => {
      setExistingLabel("");
    }, 1000);
  };

  const addLabel = (label) => {
    labelSetup.dispatchLabelSetup({
      type: inputAction,
      label: formatLabel(label),
    });

    setLabelsChanged(true);
  };

  return (
    <section className="label-setup__section">
      <div className="d-flex justify-content-between">
        <div className="pr-5 mb-3">
          <h5 className="label-setup__section--title">{title}</h5>
          <p className="label-setup__section--description">{description}</p>
        </div>
        <LabelInput
          {...{
            labelSetup,
            inputAction,
            labels,
            findExisting,
            setLabelsChanged,
            section,
            setRecentlyRemoved,
          }}
        />
      </div>
      <div className="label-setup__sort">
        <span
          onClick={() => {
            if (isBrands) setBrandSortAz((prev) => !prev);
            if (isPromos) setPromoSortAz((prev) => !prev);
            if (isKeywords) setKeywordSortAz((prev) => !prev);
          }}
        >
          <i
            className={`${
              (isBrands && brandSortAz) ||
              (isPromos && promoSortAz) ||
              (isKeywords && keywordSortAz)
                ? "fa-solid fa-square-check"
                : "fa-regular fa-square"
            } mr-2`}
          ></i>
          Sort A-Z
        </span>
      </div>
      {labels.length ? (
        <LabelsList
          {...{
            labelSetup,
            labels,
            isBrands,
            isPromos,
            isKeywords,
            existingLabel,
            setLabelsChanged,
          }}
        />
      ) : (
        <div style={{ marginTop: "1.3rem", color: "#1E2B4E" }}>
          No labels yet.
        </div>
      )}
      {isKeywords && 
        <div className="d-flex align-items-center pt-2">
          <SuggestionIcon />
          <span style={{ fontSize: "0.9rem", color: "#1E2B4E" }} className="ml-2">Suggested keyword</span>
        </div>
      }
      {recentlyRemoved.length ? (
        <div className="label-setup__recently-removed">
          <span>Recently removed:</span>
          {recentlyRemoved.map((label) => {
            return (
              <li
                onClick={() => {
                  addLabel(label);
                  setRecentlyRemoved((prev) => ({
                    ...prev,
                    [section]: prev[section].filter((l) => label !== l),
                  }));
                }}
              >
                <span>{label}</span>
                <i className="fa-solid fa-arrow-turn-up restore-label"></i>
              </li>
            );
          })}
        </div>
      ) : null}
    </section>
  );
};

const LabelInput = ({
  labelSetup,
  inputAction,
  labels,
  findExisting,
  setLabelsChanged,
  section,
  setRecentlyRemoved,
}) => {
  const [labelInput, setLabelInput] = useState("");
  const { meetsRequirements, labelInUse } = useLabelSetupValidation(
    labels,
    labelInput
  );

  const addLabel = () => {
    if (!meetsRequirements) return;
    if (labelInUse) {
      findExisting(labelInput);
      return;
    }

    labelSetup.dispatchLabelSetup({
      type: inputAction,
      label: formatLabel(labelInput),
    });
    setLabelInput("");
    setLabelsChanged(true);
    setRecentlyRemoved((prev) => ({
      ...prev,
      [section]: prev[section].filter((l) => labelInput !== l),
    }));
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 32) {
      e.preventDefault();
    }

    if (e.key === "Enter" || e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      addLabel();
    }
  };

  return (
    <div className="d-flex align-items-center">
      <Tippy
        content="Single words only (no spaces)"
        className="label-setup__tooltip"
      >
        <i className="fa-solid fa-circle-question input-tooltip__icon"></i>
      </Tippy>
      <input
        type="text"
        value={labelInput}
        onChange={(e) => setLabelInput(e.target.value)}
        onKeyDown={onKeyDown}
        className={`${labelInUse && labelInput.length ? "input-error" : ""}`}
        placeholder="Add new label"
      />
      <Button
        className="ml-3 "
        appearance="secondary"
        onClick={addLabel}
        disabled={!meetsRequirements || labelInUse}
      >
        Add
      </Button>
    </div>
  );
};
