import { batch } from 'react-redux';
import { API_SUCCESS, apiRequest, API_FAILURE } from '../Api/actions';
import { setLoader } from '../Loaders/actions';
import { addNotification } from '../Notifications/actions';

export const RESET = 'Reset';

export const RESET_FETCH = `[${RESET}] FETCH`;

export const fetchReset = ({ email, password, code }) => ({
  type: RESET_FETCH,
  data: {
    email,
    password,
    code,
  },
});

export const resetMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case RESET_FETCH: {
        const {
          data: { email, password, code },
        } = action;

        batch(() => {
          dispatch(
            apiRequest({
              body: { email, password, code },
              url: ['api', 'reset-password'].join('/'),
              entity: RESET,
              method: 'POST',
            })
          );
          dispatch(setLoader(RESET, true));
        });
        break;
      }

      case `[${RESET}] ${API_SUCCESS}`: {
        const { response } = action;
        if (response.STATUS === 'FAILURE') {
          batch(() => {
            dispatch(setLoader(RESET, false));
            dispatch(
              addNotification(
                {
                  id: new Date().getMilliseconds(),
                  status: 'danger',
                  message: response.Reason || response,
                  title: 'Reset Password',
                },
                RESET
              )
            );
          });
        } else {
          batch(() => {
            dispatch(setLoader(RESET, false));
            dispatch(
              addNotification(
                {
                  id: new Date().getMilliseconds(),
                  status: 'success',
                  message: response.body || response,
                  title: 'Reset Password',
                },
                RESET
              )
            );
          });
        }
        break;
      }

      case `[${RESET}] ${API_FAILURE}`: {
        const { error } = action;
        batch(() => {
          dispatch(setLoader(RESET, false));
          dispatch(
            addNotification(
              {
                id: new Date().getMilliseconds(),
                status: 'danger',
                message: error.body || error,
                title: 'Reset Password',
              },
              RESET
            )
          );
        });
        break;
      }

      default:
        return result;
    }

    return result;
  };
