import { useRef, useEffect } from 'react';
import { abortableFetch } from '../../../utils';

import {
  useApiState,
  bindFetchToRef,
} from '../../../components/Hooks/useApiState';

const HEADERS = { 'content-type': 'application/json' };

function postPredictions(asset) {
  return abortableFetch(
    ['api', 'marketplace', asset.project_id, 'predictions'].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      body: JSON.stringify(asset),
      credentials: 'include',
    }
  );
}

export function usePredictionsApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (asset) => withRef(postPredictions(asset)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: true,
      count: 0,
    }
  );

  const handlePost = (asset) => {
    postDispatch(asset);
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: handlePost,
    },
  };
}
