import React from "react";
import "./ConfirmationModal.scss";
import { ClickAwayListener } from "@mui/material";
import Button from "../components/Button";

const ConfirmationModal = ({
  handleClickAway,
  show,
  handleCancel,
  handleConfirm,
  message,
  className,
  children,
}) => {
  return (
    <>
      <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
        <div className={`clickaway clickaway--${className}`}>
          {show ? (
            <div className={`impact-report__modal${show ? " show" : ""} impact-report__modal--${className}`}>
              <span className="message">{message}</span>
              <div className="buttons">
                <Button
                  onClick={handleCancel}
                  type="subtle"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleConfirm}
                  type="primary"
                >
                  Confirm
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </ClickAwayListener>
      {children}
    </>
  );
};

export default ConfirmationModal;
