import { batch } from 'react-redux';

function shouldReset(action) {
  if (!action.type) {
    return false;
  }
  return action.type.includes('RESET_STORE');
}

function resetStore(entities) {
  return {
    type: `[${entities.join(', ')}] RESETTING`,
    meta: { entities },
  };
}

/**
 * @description Resets certain aspects of state based on given
 * actions.
 * action:
 * {
 *    type: [entity] RESET_STORE,
 *    meta: { entities: [String] },
 *    actions: [...Redux-Actions]
 * }
 */
const middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    if (shouldReset(action)) {
      dispatch(resetStore(action.meta.entities));

      batch(() => {
        action.actions.forEach(dispatch);
      });
    }
  };

export default middleware;
