import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { ErrorBoundary } from '../../components/Helpers/Error';
import { Loading } from '../../components/Helpers/Loading';
import { YellowHR } from '../../components/HorizontalRules/YellowBars';
import { useAdIds, usePairingsApi } from './hooks';
import AdPreview from './PreviewContainer';

function LoadListing({ ids }) {
  const {
    error, loading, data, request,
  } = usePairingsApi();

  useEffect(() => {
    request(ids);
  }, []);

  if (error) {
    return <div className="col-12"><p>{error.toString()}</p></div>;
  } if (loading || !data) {
    return <div className="col-12"><Loading /></div>;
  } if (data.length === 0) {
    return <div className="col-12"><p>No previews available.</p></div>;
  }

  return (
    <>
      {data.map(({ ad_id, view_id, ad_name }) => (
        <React.Fragment key={ad_id}>
          <div className="row mt-3">
            <div className="col-12">
              <YellowHR />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-5 d-flex flex-col align-items-end">
              <h4><strong>{ad_id}</strong></h4>
              <p>{ad_name}</p>
            </div>
            <div
              className="col-7"
              key={ad_id}
            >
              {
                !view_id
                  ? <p>Ad not found.</p>
                  : (
                    <AdPreview
                      viewId={view_id}
                      previewKey={ad_name}
                      previewAttrbute="ad_name"
                    />
                  )
              }
            </div>
          </div>
        </React.Fragment>
      ))}
    </>
  );
}

LoadListing.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AdPreviewsPage() {
  const ids = useAdIds();

  if (ids.length === 0) {
    return (
      <div className="row mt-3">
        <div className="col-12">
          <p>
            No ad ids found in the url. Try
            {' '}
            <code>{`${window.location.host}/previews?ids=<your-ad-id-1>,<your-ad-id-2>`}</code>
            .
          </p>
        </div>
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <LoadListing ids={ids} />
    </ErrorBoundary>
  );
}
