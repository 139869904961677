import React, { createContext, useContext } from 'react';

const Context = createContext(null);

export function ViewGroupProvider({ mainView, setMainView, children }) {
  return (
    <Context.Provider
      value={{
        mainView,
        setMainView,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useViewGroupContext() {
  return useContext(Context);
}
