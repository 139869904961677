import React from 'react';
import './index.scss';
import {
  useCreativeRequestGet,
  useCreativeRequestSet,
} from '../../CreativeRequest/contexts';
import { DATA_POINTS } from '../../CreativeRequest/constants';
import Tooltip from '../../../../components/Tooltip';

const DISABLED = ['All Visuals', 'All New Visuals', 'Top 10 Spending Visuals']

export const PlanningSelect = ({ row: { original: label } }) => {
  const { requests } = useCreativeRequestGet();
  const { handleAddDataPoint, handleRemoveDataPoint } = useCreativeRequestSet();

  const isDisabled = DISABLED.some((c) => c === label.category);

  const isActive = (index) => {
    return requests[index][DATA_POINTS]?.some(
      (dp) => dp.reqIndex === index && dp.labelSnapshot.value === label.value
    );
  };

  const handleSelect = (index, active) => {
    if (active) {
      handleRemoveDataPoint(label, index);
    } else {
      handleAddDataPoint(label, index);
    }
  };

  if (isDisabled) return 'N/A';

  return (
    <div className="planning-select">
      {[...Array(4)].map((num, i) => {
        return (
          <Item index={i} handleSelect={handleSelect} isActive={isActive} />
        );
      })}
    </div>
  );
};

const Item = ({ index, handleSelect, isActive }) => {
  const active = isActive(index);

  return (
    <Tooltip content={`Add data point to Creative Request ${index + 1}`}>
      <div
        onClick={() => handleSelect(index, active)}
        className={active ? 'active' : ''}
      >
        {index + 1}
      </div>
    </Tooltip>
  );
};
