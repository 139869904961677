import React, { useEffect, useMemo, useReducer, useState } from 'react';
import './CategoryEditor.scss';
import { useReportCardContext } from '../contexts';
import CategoryItem from './CategoryItem';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { reducer, SET_CATEGORIES, TOGGLE_CATEGORY } from './reducer';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  borderRadius: '4px',
  userSelect: 'none',
  // padding: '0.5rem',
  // marginBottom: '1rem',

  // change background colour if dragging
  background: isDragging ? '#FFEFB5' : '',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#FFF9E3' : '#fff',
  padding: grid,
  width: 'fit-content',
  height: 'fit-content',
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const CategoryEditor = ({
  categorySettings,
  dispatchReportCardSettings,
  analyticsView,
  style={},
  className={},
}) => {
  const { categorySettings: ctxSettings } = useReportCardContext() || {};

  const [catSettings, setCatSettings] = useState(categorySettings);

  useEffect(() => {
    if (analyticsView) {
      setCatSettings(categorySettings)
    } else {
      setCatSettings(ctxSettings)
    }
  }, [ctxSettings, analyticsView, categorySettings]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const ordered = reorder(
      categorySettings = analyticsView ? categorySettings : ctxSettings,
      result.source.index,
      result.destination.index
    );

    dispatchReportCardSettings({
      type: SET_CATEGORIES,
      categorySettings: ordered,
    });
  };

  const handleToggle = (category_name, toggleType) => {
    dispatchReportCardSettings({
      type: TOGGLE_CATEGORY,
      category_name: category_name,
      toggleType,
    });
  };

  return (
    <div style={style} className={`category-editor ${className}`}>
      {/* {saving_settings && <p className="category-editor__saving">Saving...</p>} */}
      {!categorySettings || !categorySettings.length ? (
        <p>Category data not available.</p>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                className={`dnd-container ${
                  snapshot.isDraggingOver ? "is-dragging-over" : ""
                }`}
              >
                {catSettings.map((category, i) => {
                return (
                  <Draggable
                    key={category.category_name}
                    draggableId={category.category_name}
                    index={i}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        className="dnd-item"
                      >
                        <CategoryItem
                          category_name={category.category_name}
                          enabled={{
                            report_card: category.enabled.report_card,
                            recommendations: category.enabled.recommendations,
                          }}
                          isDragging={snapshot.isDragging}
                          isDraggingOver={snapshot.isDraggingOver}
                          handleToggle={handleToggle}
                        />
                      </div>
                    )}
                  </Draggable>
                )})}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
};

export default CategoryEditor;
