import React from 'react';
import { DataState, Row } from './types';

export const S3_VISUAL = 's3_visual';
export const S3_IMAGE_URL = 's3_image_url';
export const S3_VIDEO_URL = 's3_video_url';
export const DESCRIPTION = 'description';
export const SMART_VARIATION = 'smart_variation';

export const EMPTY_ROW: Row = {
  base_block_value: '',
  base_block_type: '',
  base_block_visual_type: '',
  second_block_value: '',
  second_block_type: '',
  second_block_visual_type: '',
  final_asset_url: '',
  final_asset_type: '',
  final_asset_visual_type: '',
  additional_note: '',
  loading: false,
  error: false,
};

export const initialDataState: DataState = {
  projectName: '',
  projectId: '',
  status: 'active',
  rows: [],
};

export const DROPDOWN_STYLES = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
  }),
  valueContainer: (styles) => ({
    ...styles,
    overflow: 'visible',
    padding: 0,
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: '#888',
    borderLeft: 0,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    color: '#fff',
  }),
  container: (provided) => ({
    ...provided,
    borderRadius: '2px',
  }),
};
