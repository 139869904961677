export const formatMetricLabel = (metric: string = '') => {
  let result = metric;
  // expects a lowercase, underscore separated string
  // returns capitalized words with spaces
  if (['cpa', 'roas', 'cpc', 'ctr', 'cpm'].some((x) => metric.includes(x))) {
    return metric;
  }

  if (result.includes('cp')) {
    result = result.replace('cp', 'Cost Per');
  }

  return result;
};
