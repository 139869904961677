import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { VisualsWrapper } from './CreativePerformance';
import IconButton from '../../../../../components/IconButton';
import Tooltip from '../../../../../components/Tooltip';

const updateMetric = (metric) => {
  if (metric === 'roas') return 'total_roas';
  if (metric === 'cpa') return 'total_cpa';
  if (metric === 'cpc') return 'total_cpc';
  if (metric === 'spend') return 'total_spend';
  return metric || null;
};

export const VisualsByLabel = ({
  visualsData,
  selectedLabel,
  sort,
  overridesSelect,
}) => {
  const [allowOverrides, setAllowOverrides] = useState(false);
  const isROAS = useSelector((state) => state.ViewSettings.isROAS);
  const isFormat = selectedLabel?.split('->')?.[0]?.toLowerCase() === 'format';

  const colOpts = useMemo(() => {
    if (isROAS) {
      return [
        {
          label: 'Spend',
          value: 'total_spend',
        },
        {
          label: 'ROAS',
          value: 'total_roas',
        },
        {
          label: 'Total Revenue',
          value: 'total_revenue',
        },
        {
          label: 'CPC',
          value: 'total_cpc',
        },
      ];
    } else {
      return [
        {
          label: 'Spend',
          value: 'total_spend',
        },
        {
          label: 'CPA',
          value: 'total_cpa',
        },
        {
          label: 'Results',
          value: 'total_results',
        },
        {
          label: 'CPC',
          value: 'total_cpc',
        },
      ];
    }
  }, [isROAS]);

  const sortedVisuals = useMemo(() => {
    if (!sort?.sortBy) return visualsData;

    return [...visualsData].sort((a, b) => {
      const metric = updateMetric(sort.sortBy) ?? 'total_spend';
      const preferHigher = metric === 'total_roas';

      const aValue = a[metric] ?? null;
      const bValue = b[metric] ?? null;

      const aInvalid = isNaN(aValue) || aValue === 0;
      const bInvalid = isNaN(bValue) || bValue === 0;

      if (aInvalid && !bInvalid) return 1;
      if (bInvalid && !aInvalid) return -1;

      if (preferHigher) {
        return sort.desc ? aValue - bValue : bValue - aValue;
      }

      return sort.desc ? bValue - aValue : aValue - bValue;
    });
  }, [visualsData, sort]);

  return (
    <div style={{ height: '100%' }}>
      <div className="d-flex justify-content-between">
        <h3>
          Visuals with{' '}
          <span style={{ fontWeight: 600, color: '#1C7AA2' }}>
            {selectedLabel}
          </span>
        </h3>
        <div>
          <Tooltip content="Show override option">
            <div className="p-2 pt-0">
              <IconButton
                active={allowOverrides}
                onClick={() => {
                  setAllowOverrides((prev) => !prev);
                }}
              >
                <i className="fa-solid fa-square-pen"></i>
              </IconButton>
            </div>
          </Tooltip>
        </div>
      </div>

      <VisualsWrapper
        visuals={sortedVisuals}
        columns={[`total_${isROAS ? 'roas' : 'cpa'}`, 'total_spend']}
        columnOptions={colOpts}
        allowOverrides={allowOverrides && !isFormat}
        overridesSelect={overridesSelect}
      />
    </div>
  );
};
