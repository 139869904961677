/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from './constants';
import './styles.scss';
import { CloseIcon } from './assets';
import Tooltip from '../Tooltip';

function Tag({
  appearance,
  className,
  style,
  id,
  active,
  children,
  onClick,
  disabled,
  deletable,
  onDelete,
  title,
  tagRef,
  tooltip = null,
  ...restProps
}) {
  const baseClass = classNames.base;
  const activeClass = classNames.active;
  const disabledClass = classNames.disabled;

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <Tooltip content={tooltip} shouldShow={!!tooltip}>
      <div className="dw-tag__container">
        <div
          {...restProps}
          ref={tagRef}
          onClick={handleClick}
          id={id}
          className={[
            baseClass,
            className ? className : '',
            active ? activeClass : '',
            disabled ? disabledClass : '',
          ]
            .filter(Boolean)
            .join(' ')}
          title={title}
          style={style}
        >
          {children}
          {/* {deletable && <i className="fa fa-times dw-tag__close" onClick={onDelete}></i>} */}
        </div>
        {deletable && (
          <CloseIcon className="dw-tag__close" onClick={onDelete} />
        )}
      </div>
    </Tooltip>
  );
}

export default Tag;

Tag.defaultProps = {
  appearance: 'primary',
  className: null,
  id: null,
  children: null,
  onClick: null,
  active: false,
  disabled: false,
};

Tag.propTypes = {
  appearance: PropTypes.node,
  className: PropTypes.node,
  id: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
};
