import React from 'react';
import PropTypes from 'prop-types';

// https://github.com/AvraamMavridis/react-intersection-visible/blob/master/src/index.js
class IntersectionVisible extends React.Component {
  constructor(props) {
    super(props);

    this.handleObserverUpdate = this.handleObserverUpdate.bind(this);
  }

  componentDidMount() {
    const { options, active } = this.props;

    this.observer = new IntersectionObserver(
      this.handleObserverUpdate,
      options,
    );

    if (active) {
      this.startObserving();
    }
  }

  componentDidUpdate(prevProps) {
    const { active } = this.props;

    if (active && !prevProps.active) {
      this.startObserving();
    }

    if (!active && prevProps.active) {
      this.stopObserving();
    }
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  handleObserverUpdate(entries) {
    const { onIntersect, onShow, onHide, } = this.props;

    const { intersectionRect } = entries[0];

    const {
      top, left, bottom, right,
    } = intersectionRect;

    if ([top, left, right, bottom].some(Boolean) && onShow) {
      onShow(entries);
    } else {
      onHide(entries);
    }

    onIntersect(entries);
  }

  startObserving() {
    this.observer.observe(this.node);
  }

  stopObserving() {
    this.observer.unobserve(this.node);
  }

  render() {
    const {
      className, style, children, id, onContextMenu,
    } = this.props;

    return (
      <div
        id={id}
        className={className}
        style={style}
        onContextMenu={onContextMenu}
        ref={(node) => { (this.node = node); }}
      >
        {children}
      </div>
    );
  }
}

IntersectionVisible.defaultProps = {
  active: true,
  className: 'intersection-visible-wrapper',
  style: {},
  onHide: () => null,
  onShow: () => null,
  onIntersect: () => null,
  options: {},
  id: null,
};

IntersectionVisible.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.shape({ color: PropTypes.string }),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  onHide: PropTypes.func,
  onIntersect: PropTypes.func,
  onShow: PropTypes.func,
  options: PropTypes.shape({
    root: PropTypes.node,
    rootMargin: PropTypes.string,
    threshold: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.number),
    ]),
  }),
};

export default IntersectionVisible;
