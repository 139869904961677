import React from 'react';
import { Cell } from './Cell';

export const Row = ({ isSelected, row, isCompare, multiplier }) => {
  return (
    <>
      <tr
      {...row.getRowProps()}
        className={`frequency-table__row ${
          isSelected ? 'frequency-table__row--selected' : ''
        }`}
      >
        {row.cells.map((cell, i) => (
          <Cell cell={cell} isCompare={isCompare} multiplier={multiplier} />
        ))}
      </tr>
    </>
  );
};