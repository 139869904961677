import React, { useState } from 'react';
import Rule from './Rule';
import Button from '../../../components/Button';
import Tippy from '@tippyjs/react';
import PhraseBuilderModal from './Modal';
import { EMPTY_PHRASE, EMPTY_RULE } from './constants';
import { ADD_RULE } from './reducer';

const CreateEditPhrase = ({
  data,
  editMode,
  currentPhrase,
  phraseBuilder,
  handleDeletePhrase,
  handleUpdatePhraseName,
  handleSavePhraseName,
  changesMade,
  handleSave,
  handleSaveOne,
  isSaving,
}) => {
  const { index: phraseIndex, phrase } = currentPhrase;
  const [phraseError, setPhraseError] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const { rules, name: phraseBuilderName } =
    phraseBuilder.phrases[phraseIndex] || EMPTY_PHRASE;

  const format = (val) => val.trim().toLowerCase();

  const checkNameInUse = (value) => {
    if (
      value.length &&
      format(value) !== format(phraseBuilder.original.name) &&
      format(value) !== format(phraseBuilderName) &&
      (phraseBuilder.phrases.some(
        ({ name }) => format(name) === format(value)
      ) ||
        phraseBuilder.existingLabels.some(
          (label) => format(label) === format(value)
        ))
    ) {
      setPhraseError(true);
      return true;
    } else {
      setPhraseError(false);
      return false;
    }
  };

  const handleAddRule = () => {
    phraseBuilder.dispatchPhraseBuilder({
      type: ADD_RULE,
      payload: {
        phraseIndex: phraseIndex,
      },
    });
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      checkNameInUse(e.target.value);
    }
  };

  const handleClickAway = (e) => {
    if (e.target.classList.value.includes('delete-btn')) return;
    setShowConfirmDelete(false);
  };

  return (
    <div className="phrase-builder__section create-edit">
      <div className="phrase-builder__section-header">
        <h4>{`${editMode ? 'Edit' : 'Create'}`} Phrase</h4>
        <div className="pb-2 d-flex">
          <span>
            <Button
              onClick={() => handleSave()}
              className="save-phrase-btn mr-2"
              appearance="primary"
              loading={isSaving}
              disabled={
                !changesMade ||
                phraseError ||
                !phrase.name.trim().length ||
                rules.some((rule) => _.isEqual(rule, EMPTY_RULE))
              }
            >
              Reprocess all phrases
            </Button>
            <Button
              onClick={() => handleSaveOne()}
              className="save-phrase-btn mr-2"
              appearance="primary"
              loading={isSaving}
              disabled={
                !changesMade ||
                phraseError ||
                !phrase.name.trim().length ||
                rules.some((rule) => _.isEqual(rule, EMPTY_RULE))
              }
            >
              Save
            </Button>
          </span>
          {editMode && (
            <PhraseBuilderModal
              handleClickAway={handleClickAway}
              show={showConfirmDelete}
              handleSetShow={() => setShowConfirmDelete(false)}
              handleConfirm={() => {
                setShowConfirmDelete(false);
                handleDeletePhrase();
              }}
              message={'Delete this phrase?'}
              className={'delete-phrase'}
            >
              <Tippy
                content="Unable to delete phrase. This phrase is used in a derived category that must be updated first."
                className="phrase-builder-tooltip"
                duration={200}
                delay={250}
                placement="left"
                disabled={
                  (phrase.dependent_on_self &&
                    !phrase.dependent_on_self.length) ||
                  !phrase.dependent_on_self
                }
              >
                <span>
                  <Button
                    onClick={() => setShowConfirmDelete((prev) => !prev)}
                    className="delete-btn"
                    appearance="red"
                    disabled={
                      phrase.dependent_on_self &&
                      phrase.dependent_on_self.length
                    }
                  >
                    Delete
                  </Button>
                </span>
              </Tippy>
            </PhraseBuilderModal>
          )}
        </div>
      </div>
      <div className="create-edit__form-section">
        <h4>Phrase Name</h4>
        <input
          type="text"
          value={phrase.name}
          placeholder="Phrase Name"
          className={`text-input${editMode ? ' edit' : ''}`}
          onChange={(e) => {
            checkNameInUse(e.target.value);
            handleUpdatePhraseName(e.target.value);
            handleSavePhraseName(
              phraseIndex,
              e.target.value.trim().replace(/\s\s+/g, ' ')
            );
          }}
          onKeyDown={onKeyDown}
        />
        {phraseError && (
          <span className="phrase-error">Phrase name already in use.</span>
        )}
      </div>
      {rules
        ? rules.map((rule, index) => {
            return (
              <Rule
                rules={rules}
                {...{
                  phraseBuilder,
                  data,
                  rule,
                  index,
                  handleAddRule,
                  phraseIndex,
                  changesMade,
                  editMode,
                }}
              />
            );
          })
        : null}
      <button className="create-new-btn add-rule" onClick={handleAddRule}>
        + Add rule
      </button>
    </div>
  );
};

export default CreateEditPhrase;
