import React, { useState, useMemo, useEffect, useRef } from 'react';
import Header from './Header';
import { useCreativeAnalyticsGet } from '../contexts';
import {
  useTable,
  useSortBy,
  useFilters,
  useFlexLayout,
  useColumnOrder,
} from 'react-table';
import { useCopyReportColumns } from './columns';
import CopyTable from './Table';
import { CopyColumnPicker } from './CopyColumnPicker';
import { useCreativeAnalyticsApis } from '../api';
import { toDarwinDate } from '../../../utils/darwin-dates';
import { createShareCopyUrl } from './utils/createShareUrl';
import Tooltip from '../../../components/Tooltip';
import { useActionName, useViewId } from '../selectors';
import { BREAKDOWN_OPTIONS } from './constants';
import { useGetClients, useGetOptions } from '../../Clients/api';
import { useShareCopyReport } from './share';
import { ShareReport } from '../../DNA/ShareReport';
import Overlay from '../../../components/Overlay';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';

type breakdownTypes =
  | 'title_asset'
  | 'description_asset'
  | 'body_asset'
  | 'call_to_action_asset';

const Wrapper = ({ account_id, filteredData }) => {
  const { defaultDates, isROAS } = useCreativeAnalyticsGet();
  const { copyReportApi } = useCreativeAnalyticsApis();
  const [startDate, endDate] = defaultDates;
  const [dateRange, setDateRange] = useState({
    start: startDate,
    end: endDate,
  });
  const [breakdownType, setBreakdownType] =
    useState<breakdownTypes>('body_asset');
  const [copyData, setCopyData] = useState([]);
  const [copyReportColumns, setCopyReportColumns] = useState([
    'breakdown_value',
    isROAS ? 'roas' : 'cpa',
    'total_spend',
  ]);
  const { data, loading, error, request } = copyReportApi.post;
  const columns = useCopyReportColumns();
  const actionName = useActionName();
  const view_id = useViewId();
  const breakdownLabel = BREAKDOWN_OPTIONS.find(
    (opt) => opt.value === breakdownType
  ).label;
  const dataRequestSuccess = Array.isArray(data) && data?.length;

  const {
    shouldShowShareModal,
    openShareModal,
    closeShareModal,
    submitShareReport,
    sharedReportId,
    loadingShareReport,
  } = useShareCopyReport();

  const fetchData = () => {
    request({
      account_id,
      view_id,
      start_date: toDarwinDate(startDate),
      end_date: toDarwinDate(endDate),
      optimization_event: actionName,
      breakdown_type: breakdownType,
    });
  };

  useEffect(() => {
    if (dataRequestSuccess && !loading) {
      setCopyData(data);
    }
  }, [dataRequestSuccess, loading]);

  const rows = useMemo(() => copyData, [copyData]);

  const tableColumns = useMemo(() => {
    return columns.filter((col) =>
      ['breakdown_value', ...copyReportColumns].includes(col.accessor)
    );
  }, [columns, copyReportColumns]);

  console.log({ rows, tableColumns, columns });
  const tableInstance = useTable(
    {
      columns: tableColumns,
      data: rows,
      initialState: {
        sortBy: [
          {
            id: 'cpa',
            desc: isROAS,
          },
        ],
      },
      autoResetHiddenColumns: false,
      autoResetSortBy: false,
      autoResetFilters: false,
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    useColumnOrder
  );

  const handleColumns = ({ column, checked }) => {
    let update = [...copyReportColumns];
    if (checked) {
      update = [...copyReportColumns, column];
    } else {
      update = copyReportColumns.filter((col) => col !== column);
    }

    setCopyReportColumns(update);
  };

  let Render = null;

  if (loading) {
    Render = <div className="mt-2">Loading...</div>;
  }

  if (error) {
    console.error(error ?? data);
    Render = <div className="mt-2">There was an error.</div>;
  }

  if (dataRequestSuccess && rows.length) {
    Render = (
      <CopyTable
        tableInstance={tableInstance}
        ColumnPicker={
          <CopyColumnPicker
            columns={copyReportColumns}
            onChange={handleColumns}
          />
        }
        ShareButton={
          <>
            <Tooltip content="Share report">
              <button
                className="share-button mr-3"
                onClick={() => openShareModal()}
                disabled={!rows.length}
              >
                <i className="fa-solid fa-share"></i>
              </button>
            </Tooltip>
          </>
        }
        title={breakdownLabel}
      />
    );
  }

  return (
    <>
      <div className="copy-report">
        <h2>Copy Report</h2>
        <Header
          {...{
            dateRange,
            setDateRange,
            breakdownType,
            setBreakdownType,
            fetchData,
            loading,
          }}
        />
        {Render}
      </div>
      {!!shouldShowShareModal && (
        <Share
          close={closeShareModal}
          submit={(report_name, report_description) =>
            submitShareReport({
              report_name,
              report_description,
              copyReportColumns,
              rows,
              breakdownLabel,
            })
          }
          sharedReportId={sharedReportId}
          loading={loadingShareReport}
          location="copy"
        />
      )}
    </>
  );
};

export default Wrapper;

export const Share = ({ close, submit, sharedReportId, loading }) => {
  const [report_name, setName] = useState('');
  const [report_description, setDesc] = useState('');
  useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const onSubmit = () => {
    submit(report_name, report_description, selectedCategories);
  };

  const form = (
    <div className="p-1 w-100">
      <div className="form-group mb-5">
        <label>Report Name</label>
        <input
          className="w-100"
          onChange={(e) => setName(e.target.value)}
        ></input>
      </div>

      <div className="form-group mb-5">
        <label>Report Description</label>
        <textarea
          className="w-100"
          onChange={(e) => setDesc(e.target.value)}
        ></textarea>
      </div>
    </div>
  );

  const { protocol, hostname, port } = window.location;
  const rootUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
  const url = `${rootUrl}/static-report/${sharedReportId}`;

  const success = (
    <div>
      <h3>Report successfully created.</h3>
      <p>
        Click{' '}
        <a target="_blank" href={url} style={{ fontSize: '1.2rem' }}>
          here
        </a>{' '}
        to open and share.
      </p>
    </div>
  );

  return (
    <Overlay close={close} show>
      <Modal className="share-report-modal" close={close}>
        <h3 className="mb-5" style={{ fontSize: '1.4rem' }}>
          Create Static Report
        </h3>
        {!!sharedReportId ? success : form}
        {!!sharedReportId ? (
          <div className="align-self-end w-100">
            <Button fullWidth className="mt-3" onClick={close}>
              Confirm
            </Button>
          </div>
        ) : (
          <div className="align-self-end w-100">
            <Button
              fullWidth
              className="mt-3 mr-3"
              onClick={close}
              appearance="subtle"
            >
              Cancel
            </Button>
            <Button
              fullWidth
              className="mt-3"
              loading={loading}
              onClick={onSubmit}
            >
              Generate report
            </Button>
          </div>
        )}
      </Modal>
    </Overlay>
  );
};
