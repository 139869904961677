import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Select, { components } from 'react-select';
import { trackEvent } from '../../../../store/UserActivity/actions';
import {
  CREATIVE_LIBRARY,
  SEARCHED_TERM_IN_LIBRARY,
} from '../../../../store/UserActivity/constants';
import { ADD_AD_NAME, ADD_LABEL } from '../reducer';
import { LIBRARY_SEARCH_STYLES } from './constants';

import './LibrarySearch.scss';

const LibrarySearch = ({ searchLookup, library, dispatchLibrary }) => {
  const dispatch = useDispatch();
  const handleAddSearchTerm = ({ labelObj, adName }) => {
    if (!adName) {
      dispatch(
        trackEvent(CREATIVE_LIBRARY, SEARCHED_TERM_IN_LIBRARY, {
          label_category: labelObj.category,
          label_name: labelObj.value,
        })
      );
      dispatchLibrary({
        type: ADD_LABEL,
        label: labelObj,
      });
    } else {
      dispatchLibrary({
        type: ADD_AD_NAME,
        label: labelObj,
      })
    }
  };

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {!!children && (
            <i
              className="fa fa-search"
              aria-hidden="true"
              style={{ position: 'absolute', left: 6, color: '#A9B3CD' }}
            />
          )}
          {children}
        </components.ValueContainer>
      )
    );
  };

  const CustomOption = (props) => {
    const { selectProps, data } = props;
    const input = selectProps.inputValue;
    const option = data.label ? data.label : data.adName;

    const getMatchingText = (input, option) => {
      const num = option.toLowerCase().indexOf(input.toLowerCase());
      const len = input.length;
      return (
        <div style={{ color: '#1E2B4E' }}>
          <span>{option.substr(0, num)}</span>
          <span style={{ fontWeight: '600' }}>{input}</span>
          <span>{option.substr(num + len)}</span>
        </div>
      );
    };

    return (
      <components.Option {...props}>
        {getMatchingText(input, option)}
      </components.Option>
    );
  };

  return (
    <div className="library-search">
      <Select
        styles={LIBRARY_SEARCH_STYLES}
        isSearchable
        options={searchLookup}
        value={null}
        onChange={handleAddSearchTerm}
        components={{
          Option: CustomOption,
          ValueContainer: CustomValueContainer,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        placeholder="Search by element..."
        noOptionsMessage={() => null}
        // openMenuOnClick={false}
      />
    </div>
  );
};

export default LibrarySearch;
