import React, { useReducer } from 'react';
import { DataState, CopilotAction } from '../types';
import { initialDataState } from '../constants';

export const TOGGLE_INCLUDE_DATA = 'TOGGLE_INCLUDE_DATA';
export const CHANGE_REQUEST_TYPE = 'CHANGE_REQUEST_TYPE';
export const SET_INSTRUCTIONS = 'SET_INSTRUCTIONS';
export const SET_OUTPUT = 'SET_OUTPUT';
export const SET_PREVIOUS = 'SET_PREVIOUS';
export const SET_FEEDBACK = 'SET_FEEDBACK';
export const RESET = 'RESET';
export const SET_PREFERENCES = 'SET_PREFERENCES';
export const SET_DETAILS = 'SET_DETAILS';
export const SET_BASE_VISUAL = 'SET_BASE_VISUAL';

export const copilotReducer = (
  state: DataState,
  action: CopilotAction
): DataState => {
  switch (action.type) {
    case TOGGLE_INCLUDE_DATA:
      return {
        ...state,
        dataToInclude: {
          ...state.dataToInclude,
          [action.dataType]: state.dataToInclude[action.dataType]
            ? false
            : true,
        },
      };

    case CHANGE_REQUEST_TYPE:
      return {
        ...state,
        requestType: action.requestType,
      };

    case SET_INSTRUCTIONS:
      return {
        ...state,
        instructions: action.text,
      };

    case SET_OUTPUT:
      return {
        ...state,
        currentOutput: { summary: action.summary, reasons: action.reasons },
      };

    case SET_FEEDBACK:
      return {
        ...state,
        userFeedback: action.text,
      };

    case RESET: {
      return {
        ...state,
        currentOutput: {
          summary: '',
          reasons: [],
        },
      };
    }

    case SET_PREFERENCES: {
      return {
        ...state,
        clientPreferences: action.text,
      };
    }

    case SET_DETAILS: {
      return {
        ...state,
        advertiserDetails: action.text,
      };
    }

    case SET_BASE_VISUAL: {
      return {
        ...state,
        baseVisual: action.visual,
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export const useCopilotState = () => {
  const [copilotState, copilotDispatch] = useReducer(
    copilotReducer,
    initialDataState
  );

  return { copilotState, copilotDispatch };
};
