import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import './VisualReport.scss';

import { ColumnPicker, CampaignPicker, PeriodPicker } from './Pickers';
import VisualsTable from './VisualsTable';
import { useVisualReportParams } from './hooks';
import { VisualReportProvider } from './contexts';
import { useVisualReportApi, useCustomLabels } from './api';
import { PARAM_DEFAULTS, FILTER_TAG_CLASSES } from './constants';
import { getUniqueLabels } from './utils';
import LabelFilters from './LabelFilters';

const getPayload = (
  account_id, campaigns, period1, period2, audience_breakdown, level, all_campaigns, darwin_client_id
) => {
  const all = campaigns === 'all';

  return {
    account_id,
    start_date_1: period1.split(',')[0],
    end_date_1: period1.split(',')[1],
    start_date_2: period2.split(',')[0] || '',
    end_date_2: period2.split(',')[1] || '',
    level,
    campaigns: all ? all_campaigns : campaigns.split(' '),
    audience: audience_breakdown === 'true' || audience_breakdown === true,
    darwin_client_id: darwin_client_id
  }
}

const VisualReport = () => {
  const [{ metrics, campaigns, period1, period2, audience_breakdown, level, sort, labels }, setParams] = useVisualReportParams();
  const [dataType, setDataType] = useState(level);
  const [data, setData] = useState([]);
  const [labelsData, setLabelsData] = useState([]);
  const [labelFilters, setLabelFilters] = useState([]);
  const [labelOptions, setLabelOptions] = useState([]);
  const [openFilters, setOpenFilters] = useState(false);
  const visualReportApi = useVisualReportApi();
  const customLabelsApi = useCustomLabels();
  const isAssetLevel = level === 'asset';

  const { view_id, account_id, insights_by_asset, all_campaigns, initialView,
    darwin_client_id, } = useSelector(({ ViewSettings: { viewId, account_id, insights_by_asset, campaigns, initialView, darwin_client_id, } }) => ({
    view_id: viewId, account_id, insights_by_asset, all_campaigns: campaigns, initialView, darwin_client_id
  }));

  const fetchData = () => {

    const payload = getPayload(
      account_id,
      campaigns,
      period1,
      period2,
      audience_breakdown,
      level,
      all_campaigns,
      darwin_client_id,
    );
    visualReportApi.post.request(payload)
  }

  const resetParams = () => {
    setParams(PARAM_DEFAULTS);
  }

  function bodyClick(e) {
    if (!FILTER_TAG_CLASSES.some((str) => e.target.classList.contains(str))) {
      setOpenFilters(false);
    };
  }

  useEffect(() => {
    document.body.addEventListener('click', bodyClick);

    return () => {
      document.body.removeEventListener('click', bodyClick);
    };
  }, []);

  useEffect(() => {
    if (labelFilters.length) {
      const urlTags = labelFilters.map((label) => encodeURIComponent(label));
      setParams({ labels: urlTags.join(',') });
    }
  }, [labelFilters]);

  useEffect(() => {
    const str = decodeURIComponent(labels);
    let label_params = [];
    if (str && str.length) {
      label_params = str.split(',');
    }
    setLabelFilters(label_params);
  }, [labels]);

  useEffect(() => {
    if (visualReportApi.post.data) {
      const { data } = visualReportApi.post;
      setData(data);

      if (isAssetLevel) {
        const hashes = data.map((row) => {
          return row.id;
        });

        customLabelsApi.get.request({ visual_hashes: hashes });
      }

    }
  }, [visualReportApi.post.data]);

  useEffect(() => {
    if (customLabelsApi.get.data) {
      const { data } = customLabelsApi.get;
      setLabelsData(data);
      setLabelOptions(getUniqueLabels(data));
    }
  }, [customLabelsApi.get.data]);

  useEffect(() => {
    fetchData();
  }, [campaigns, period1, period2, audience_breakdown, level]);

  useEffect(() => {
    setParams({ level: dataType });
  }, [dataType]);

  useEffect(() => {
    if (!initialView) {
      resetParams();
      setLabelFilters([]);
    }
  }, [view_id]);

  useEffect(() => {
    if (!insights_by_asset) {
      setParams({ level: 'ad' });
    }
  }, [insights_by_asset]);

  const LevelToggle = ({ dataType, setDataType }) =>(
    <div className="d-flex">
      <button className={`level-toggle mr-2 ${dataType === 'asset' ? 'level-toggle--active' : ''}`} onClick={() => setDataType('asset')}>Visuals</button>
      <button className={`level-toggle ${dataType === 'ad' ? 'level-toggle--active' : ''}`} onClick={() => setDataType('ad')}>Ads</button>
    </div>
  )

  const handleAudienceBreakdown = (e) => {
    setData([]);
    setParams({ audience_breakdown: e.target.checked });
  }

  const matchesFilter = (customLabels) => {
    let match = true;

    match = labelFilters.every((filterLabel) => customLabels.includes(filterLabel));

    return match;
  }

  const getTableData = (data) => {
    if (labelsData.length) {
      // take custom labels list, append to row object by hash
      const update = data.reduce((acc, row) => {
        let custom_trained_labels = [];
        const match = labelsData.find((label) => label.visual_hash === row.id);

        if (match) custom_trained_labels = match.custom_trained_labels;

        // push to array if 1) no filter 2) matches filter

        if (!labelFilters.length || matchesFilter(custom_trained_labels)) {
          acc.push({
            ...row,
            custom_trained_labels,
          });
        }

        return acc;
      }, []);

      return update;
    }

    return data;
  }

  const Header = (
    <div className="visual-report__header">
      <div className="visual-report__left">
        <div className="d-flex">
          <h2 className="visual-report__heading">Visual Report</h2>
          <div className="filters-wrapper d-flex">
            <ColumnPicker metrics={metrics} />
            <CampaignPicker selectedCampaigns={campaigns} />
            {isAssetLevel && (
              <>
                <button type="button" className="filters-toggle" onClick={() => setOpenFilters(!openFilters)}>
                  <i className="fa fa-filter"></i>
                  Custom Labels
                </button>
                {openFilters && (
                  <LabelFilters
                    {...{ labelOptions, labelFilters, setLabelFilters }}
                  />
                )}
              </>
            )}
          </div>
        </div>
        {insights_by_asset && <LevelToggle {...{ dataType, setDataType }} /> }
      </div>
      <div className="visual-report__right">
        <div className="d-flex">
          <div className="visual-report__audience-breakdown">
            <label htmlFor="audience_breakdown">Audience Breakdown</label>
            <input
              name="audience_breakdown"
              type="checkbox"
              checked={audience_breakdown === 'true' || audience_breakdown === true}
              onChange={handleAudienceBreakdown}
            />
          </div>
          <div className="visual-report__date-pickers">
            <div className="visual-report__date-picker d-flex">
              <span>Period 1</span>
              <PeriodPicker name="period1" isPerformanceFrequency={false}/>
            </div>
            <div className="visual-report__date-picker d-flex">
              <span>Period 2</span>
              {audience_breakdown === 'true' || audience_breakdown === true ? <p className="visual-report__disabled-date">Disabled</p> : <PeriodPicker name="period2" />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  let Table = <p>No visuals found.</p>

  const tableData = useMemo(() => getTableData(data), [data, labelsData, labelFilters]);

  if (tableData.length) {
    Table = (
      <VisualsTable
        {...{ metrics, insights_by_asset }}
        currentSort={sort}
        data={tableData}
        labelsLoading={customLabelsApi.get.loading}
      />
    );
  }


  return (
    <VisualReportProvider
      metrics={metrics}
      campaigns={campaigns}
      period1={period1}
      period2={period2}
      audience_breakdown={audience_breakdown}
      level={level}
      setParams={setParams}
    >
      <div className="visual-report darwin-box-shadow">
        {Header}
        {visualReportApi.post.error && `Error: ${visualReportApi.post.error.error}`}
        {visualReportApi.post.loading && 'Loading...'}
        {visualReportApi.post.data && Table}
      </div>
    </VisualReportProvider>
  )
}

export default VisualReport;
