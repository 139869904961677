import { addNotification } from '../Notifications/actions';
import { makeVersionFromUpload } from './Assets/Asset';

export const MARKETPLACE = 'Marketplace';

export const Marketplace = {
  FETCH: `[${MARKETPLACE}] FETCH`,
  SET_PROJECTS: `[${MARKETPLACE}] SET PROJECTS`,
  UPDATE_PROJECTS: `[${MARKETPLACE}] UPDATE PROJECTS`,

  UPLOAD_FILE: `[${MARKETPLACE}] UPLOAD FILE`,
  UPLOAD_FAILURE: `[${MARKETPLACE}] UPLOAD FAILURE`,
  RESET_ASSETS: `[${MARKETPLACE}] RESET ASSETS`,

  FETCH_BULK_OPTIONS: `[${MARKETPLACE}] FETCH BULK OPTIONS`,
  SET_BULK_OPTIONS: `[${MARKETPLACE}] SET BULK OPTIONS`,
};

export function fetchMarketplace() {
  return { type: Marketplace.FETCH };
}

export function setMarketplaceProjects(data) {
  return {
    type: Marketplace.SET_PROJECTS,
    data,
    meta: { entity: MARKETPLACE, normalizeId: 'project_id' },
  };
}

export function updateMarketplaceProjects(project) {
  return {
    type: Marketplace.UPDATE_PROJECTS,
    data: project,
  };
}

/**
 *
 * @param {Object} payload
 * @param {string} payload.project_id
 * @param {string} payload.filename
 * @param {File} payload.file
 * @param {string} payload.versionId
 * @param {string} payload.contractor
 * @param {string?} payload.status
 * @param {boolean?} payload.placeholderUpload
 */
export function uploadMarketplaceFile({
  project_id,
  filename,
  file,
  versionId,
  contractor,
  status = '',
  placeholderUpload = false,
}) {
  return {
    type: Marketplace.UPLOAD_FILE,
    data: makeVersionFromUpload(file, versionId),
    meta: {
      filename,
      versionId,
      project_id,
      contractor,
      status,
      placeholderUpload,
    },
  };
}

export function marketplaceUploadFailure({ versionId, filename }) {
  return { type: Marketplace.UPLOAD_FAILURE, data: { versionId, filename } };
}

export function marketplaceResetAssets() {
  return { type: Marketplace.RESET_ASSETS };
}

export function marketplaceFetchBulkOptions({ project_id }) {
  return { type: Marketplace.FETCH_BULK_OPTIONS, data: { project_id } };
}

export function marketplaceSetBulkOptions(options) {
  return { type: Marketplace.SET_BULK_OPTIONS, data: options };
}

export function fetchMarketplaceBulkCreate({
  destination_adsets,
  project_id,
  version,
  filename,
  ad_name,
  lead_form,
}) {
  return async (dispatch) => {
    try {
      const res = await fetch(
        ['api', 'marketplace', project_id, 'bulk-create'].join('/'),
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            destination_adsets,
            filename,
            version,
            ad_name,
            lead_form,
          }),
        }
      );

      if (res.status !== 200) {
        return dispatch(
          addNotification(
            {
              title: 'Bulk Create',
              status: 'danger',
              id: Date.now(),
              delay: 6000,
              message: 'An error occurred.',
            },
            `${MARKETPLACE}.bulkOptions.create`
          )
        );
      }
      return dispatch(
        addNotification(
          {
            title: 'Bulk Create',
            status: 'success',
            id: Date.now(),
            delay: 6000,
            message: 'Successfully created',
          },
          `${MARKETPLACE}.bulkOptions.create`
        )
      );
    } catch (e) {
      return dispatch(
        addNotification(
          {
            title: 'Bulk Create',
            status: 'danger',
            id: Date.now(),
            delay: 6000,
            message: e.toString(),
          },
          `${MARKETPLACE}.bulkOptions.create`
        )
      );
    }
  };
}

export function addStudioAsset({
  project_id,
  filename,
  file,
  versionId,
  contractor,
  status = '',
  placeholderUpload = false,
}) {
  return {
    type: Marketplace.UPLOAD_FILE,
    data: makeVersionFromUpload(file, versionId),
    meta: {
      filename,
      versionId,
      project_id,
      contractor,
      status,
      placeholderUpload,
    },
  };
}