import React, { useState, useMemo } from 'react';
import { useCreativeAnalyticsGet } from '../contexts';
import {
  INSPIRATION,
  SAVED,
  SPEND,
  TOP_PERFORMERS,
  TRENDING,
} from './constants';
import { getVisualsData, getSections } from './utils';
import Header from './Header';
import SectionRows from './SectionRows';

import './TopPerformers.scss';

const TopPerformers = ({
  Header,
  SectionRows,
  TryThese,
  requestWidth,
  activeTTSection,
  setActiveTTSection,
  fromDraftCreative,
  setShowRightRail,
}) => {
  const maxWidth = { maxWidth: `calc(100vw - (${requestWidth}px + 50px))` };

  const tabOpts = [TOP_PERFORMERS, TRENDING, SAVED, INSPIRATION];
  const [tabOrder, setTabOrder] = useState(0);

  return (
    <div className="top-performers" style={maxWidth}>
      {fromDraftCreative && (
        <div>
          <i
            className="fa fa-close"
            onClick={() => setShowRightRail(false)}
            style={{ cursor: 'pointer', color: "#616161" }}
          />
        </div>
      )}
      <ul className="top-performers__tab-list">
        <div className={`active-tab-bg order${tabOrder}`}></div>
        {tabOpts.map((opt, i) => {
          return (
            <div
              className={`top-performers__tab ${
                activeTTSection === opt ? 'active' : ''
              }`}
              onClick={() => {
                setActiveTTSection(opt);
                setTabOrder(i);
              }}
              style={{ fontSize: fromDraftCreative ? '1rem' : '1.2rem' }}
            >
              <span>{opt}</span>
              <i
                className={`fa-solid fa-${
                  i === 0
                    ? 'fire'
                    : i === 1
                    ? 'arrow-trend-up'
                    : i === 2
                    ? 'heart'
                    : 'bolt'
                } ml-3 ${fromDraftCreative ? 'absolute' : ''}`}
              />
            </div>
          );
        })}
      </ul>
      {activeTTSection === TOP_PERFORMERS ? Header : TryThese}
      {/* {SectionRows} */}
      {/* {TryThese} */}
    </div>
  );
};

const TopPerformersWrapper = ({
  recsTab,
  TryThese,
  isROAS,
  activeTTSection,
  setActiveTTSection,
  fromDraftCreative,
  setShowRightRail,
  handleBlockValue,
  handleBlockVisualType,
}) => {
  const { filteredData, requestWidth } = useCreativeAnalyticsGet();
  const [metric, setMetric] = useState(SPEND);

  const allVisuals = useMemo(() => {
    const label = filteredData.find((label) => label.value === 'all_visuals');

    return label ? [...label.winners, ...label.losers] : [];
  }, [filteredData]);

  const visualsData = useMemo(() => {
    return getVisualsData(metric, allVisuals, isROAS);
  }, [metric, allVisuals]);

  const sections = useMemo(
    () => getSections(filteredData, metric, isROAS),
    [visualsData, filteredData, metric]
  );

  return (
    <TopPerformers
      Header={
        <Header
          {...{
            metric,
            setMetric,
            visualsData,
            recsTab,
            isROAS,
            fromDraftCreative,
            setShowRightRail,
            handleBlockValue,
            handleBlockVisualType,
          }}
        />
      }
      // SectionRows={(
      //   <SectionRows {...{ visualsData, sections, recsTab, metric, isROAS }} />
      // )}
      TryThese={TryThese}
      {...{
        requestWidth,
        activeTTSection,
        setActiveTTSection,
        fromDraftCreative,
        setShowRightRail,
      }}
    />
  );
};

export default TopPerformersWrapper;
