import React from "react";
import CircleChart from "../CircleChart";
import { toPercentRounded } from "../../../../../utils/numbers";
import Tippy from "@tippyjs/react";
import { CombinedIcon } from "../../Helpers";

const CombinedImpactTable = ({ combinedScore, combinedPercentage }) => {
  const content = () => {
    return (
      <>
        <span>
          This is the % of performance that can be explained by the included{" "}
        </span>
        <span className="combined-icon">
          <CombinedIcon />
        </span>
        <span> categories together.</span>
      </>
    );
  };

  return (
    <table className="impact-report__table">
      <thead>
        <tr className="d-flex justify-content-around">
          <th className="table-header frequency-table__header">
            <Tippy
              content={content()}
              className="impact-report__tooltip"
              delay={[500, 0]}
            >
              <div>
                <div>
                  Combined Impact % (r<span className="superscript">2</span>)
                </div>
              </div>
            </Tippy>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          className={`table-row table-row--combined`}
          style={{ borderRadius: "4px" }}
        >
          <td className={`table-cell table-cell--combined position-relative`}>
            <CircleChart score={combinedScore * 100} />
            <span className="combined-score">{combinedPercentage}</span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CombinedImpactTable;
