import React from 'react';
import { toast } from 'react-toastify';
import Notification from './Notification';
import { DARWIN_NAVY } from '../../constants';
import { CloseIcon } from '../Icons';

export const notifyError = (name, error, id='error-toast') => {
  const notifications = useNotifications();
  
  notifications.add({
    id,
    title: name,
    message: error,
    toastProps: {
      className: 'error-toast',
    },
  });
};

export const useNotifications = () => {
  const handleRemove = (id) => toast.dismiss(id);

  return {
    add: ({
      id,
      title,
      message,
      showEllipses,
      position = 'top-right',
      toastProps,
    }) => {
      const Content = (
        <Notification {...{ message, title, id, showEllipses, handleRemove }} />
      );
      return toast(Content, {
        position,
        closeOnClick: false,
        draggable: true,
        progress: undefined,
        autoClose: false,
        closeButton: <CloseIcon className="toast__close" />,
        style: {
          background: DARWIN_NAVY,
          padding: 0,
        },
        ...(id && { toastId: id }),
        ...toastProps,
      });
    },
    remove: handleRemove,
  };
};
