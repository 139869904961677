import React from "react";
import PropTypes from "prop-types";

const withStrikeZone = (Comp) => {
  class StrikeZoneMethods extends React.Component {
    constructor(props) {
      super(props);

      this.defaultPerfPanel = this.props.testing_information
        ? {
            selection: this.props.testing_information,
            selectedType: "explanation",
          }
        : {
            selection: null,
            selectedType: "",
          };

      this.state = { ...this.defaultPerfPanel };

      this.handleSelection = this.handleSelection.bind(this);
    }

    componentWillUnmount() {
      this.handleSelection = null;
    }

    handleSelection({ type, ...rest }) {
      const update = type
        ? { selection: rest, selectedType: type }
        : { ...this.defaultPerfPanel };

      this.setState(update);
    }

    render() {
      const { handleBoostModal, toggleTooltip } = this.props;

      return (
        <Comp
          {...this.state}
          toggleTooltip={toggleTooltip}
          handleBoostModal={handleBoostModal}
          handleSelection={this.handleSelection}
        />
      );
    }
  }

  StrikeZoneMethods.propTypes = {
    toggleTooltip: PropTypes.func,
    handleBoostModal: PropTypes.func.isRequired,
    testing_information: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        explanation: PropTypes.string,
      })
    ),
  };

  return StrikeZoneMethods;
};

export default withStrikeZone;
