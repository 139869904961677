import { useState, useEffect } from 'react';
import { useSearchParams } from '../../components/Hooks/search-params';
import { PARAM_DEFAULTS } from '../../store/constants';
import { darwinDateToDate } from '../../utils/darwin-dates';
import { CHART_TYPES, LABEL_MATRIX, STUDY_FLOW } from './constants';
import {
  ADSET_NAME,
  AD_NAME,
  AD_STUDY,
  AD_LABELS,
  ADSET_LABELS,
  LABEL_SETTINGS,
  PERFORMANCE_FREQUENCY,
} from '../../store/CreativeReports/constants';
import { CREATIVE_INSIGHTS_VIEWS } from '../CreativeAnalytics/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setReports } from '../../store/CreativeReports/actions';

export function useReportParam() {
  const params = new window.URLSearchParams(window.location.search);

  return params.get('report') || PARAM_DEFAULTS.report_type;
}

const REPORT_PARAMS = [
  'report',
  'start',
  'end',
  'audience',
  'exclude',
  'type',
  'spendThreshold',
  'study_type',
  'ad_label_type',
  'creativeCount',
  'insights_view',
  'show_categories',
  'ratings',
  'display',
];

export const useUrlProps = () => {
  const { getParams, replaceParams } = useSearchParams();

  const params = getParams(...REPORT_PARAMS);

  useEffect(() => {
    if (!!params.report) {
      const keep = REPORT_PARAMS.reduce((acc, k) => {
        if (params[k]) {
          console.log(params[k])
          acc[k] = params[k];
        }
  
        return acc;
      }, {});
  
      replaceParams(keep);
    }
  }, [params.report]);

  const {
    report,
    start,
    end,
    audience,
    exclude,
    spendThreshold,
    type,
    study_type,
    ad_label_type,
    creativeCount,
    insights_view,
    show_categories,
    ratings,
    display,
  } = params;

  const defaults = {
    report_type: report || PARAM_DEFAULTS.report_type,
    start_date: start ? darwinDateToDate(start) : PARAM_DEFAULTS.start_date,
    end_date: end ? darwinDateToDate(end) : PARAM_DEFAULTS.end_date,
    selectedAudience: audience || PARAM_DEFAULTS.selectedAudience,
    spendThreshold:
      typeof spendThreshold === 'string'
        ? parseInt(spendThreshold)
        : PARAM_DEFAULTS.spendThreshold,
    excludeAds: exclude || PARAM_DEFAULTS.exclude,
    visualType: setType(type || PARAM_DEFAULTS.visualType),
    study_type: study_type || '',
    ad_label_type: ad_label_type || '',
    creativeCount:
      typeof creativeCount === 'string' ? parseInt(creativeCount) : 1,
    insights_view: insights_view || PARAM_DEFAULTS.insights_view,
    show_categories: show_categories || 'true',
    ratings: ratings || false,
    display: display || 'adset_name',
  };

  return defaults;
};

const setType = (t) => (t === 'video' || t === 'image' ? t : '');

export const useChartSettings = ({ report }) => {
  const { report_type } = useUrlProps();
  const [chartOptions, setOptions] = useState(setChartOptions(report));
  const [chart, setChart] = useState(chartOptions[0]);

  useEffect(() => {
    const opts = setChartOptions(report_type);

    setOptions(opts);
    setChart(opts[0]);
  }, [report]);

  return {
    options: chartOptions,
    chart,
    setChart,
  };
};

function setChartOptions(reportType) {
  if (reportType === AD_NAME || reportType === ADSET_NAME) {
    return CHART_TYPES;
  }
  if (reportType === AD_STUDY) {
    return [{ label: 'Flow Chart', value: STUDY_FLOW }];
  }
  const [bar, scatter, table] = CHART_TYPES.slice(0, 3);

  switch (reportType) {
    case ADSET_LABELS:
      return [table, bar, scatter];

    case AD_LABELS:
      return [
        { label: 'Label Matrix', value: LABEL_MATRIX },
        { label: 'Label Settings', value: LABEL_SETTINGS },
        bar,
        table,
        scatter,
      ];

    default:
      return [bar, scatter, table];
  }
};
