import React, { createContext, useContext } from 'react';
import { VariationState } from '../types';
import { initialDataState } from '../constants';

export const VariationContext = createContext<VariationState>({
  variationState: initialDataState,
  variationDispatch: () => null,
});

interface Props {
  variationState: VariationState;
  children: React.ReactNode;
}

const VariationContextProvider = ({ variationState, children }: Props) => {
  return (
    <VariationContext.Provider value={variationState}>
      {children}
    </VariationContext.Provider>
  );
};

export default VariationContextProvider;

export function useVariationContext() {
  return useContext(VariationContext);
}
