import { useDispatch } from 'react-redux';

export const USER_ACTIVITY = 'User Activity';
export const TRACK_EVENT = 'TRACK_EVENT';
export const FETCH_EVENTS = 'FETCH_EVENTS';

export const trackEvent = (
  event_category = null,
  event_type = null,
  rest = {}
) => ({
  type: `${TRACK_EVENT}`,
  data: {
    event_category,
    event_type,
    rest,
  },
  meta: { entity: USER_ACTIVITY },
});

export const fetchEvents = (data) => ({
  type: `${FETCH_EVENTS}`,
  data,
  meta: { entity: USER_ACTIVITY },
});
