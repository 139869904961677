import React, { createContext, useReducer } from 'react';
import { isEqual } from 'lodash';
import { DrawerAction, DrawerState } from '../types';
import { initialDrawerState } from '../constants';

export const OPEN = 'OPEN';
export const CLOSE = 'CLOSE';
export const SET_VIEW = 'SET_VIEW';
export const GO_BACK = 'GO_BACK';

export const drawerReducer = (
  state: DrawerState,
  action: DrawerAction
): DrawerState => {
  switch (action.type) {
    case OPEN:
      let selectedVisualId = '';

      if (action.currentView.type === 'visual_viewer') {
        selectedVisualId = action.currentView.id;
      }

      if (action.selectedVisualId) {
        selectedVisualId = action.selectedVisualId;
      }

      return {
        ...state,
        isOpen: true,
        currentView: action.currentView,
        selectedVisualId,
      };

    case CLOSE:
      return {
        ...initialDrawerState,
        selectedVisualId: action.keepState ? state.selectedVisualId : '',
        isSubDrawer: state.isSubDrawer,
      };

    case SET_VIEW:
      if (action.currentView.type === 'ccc_editor') {
        if (
          action.currentView.category.category_name ===
          state.currentView?.category.category_name
        ) {
          if (
            action.currentView.category.ccc_type !== 'ai_driven' ||
            action.currentView.category.visual_hash ===
              state.currentView.category.visual_hash
          ) {
            return state;
          }
        }
      }

      let updatedVisualId = state.selectedVisualId;
      let previousViews = [...state.previousViews];

      if (state.currentView)
        previousViews = [...previousViews, state.currentView];

      if (action.currentView.type === 'visual_viewer') {
        updatedVisualId = action.currentView.id;
      }

      if (action.clearSelectedVisual) {
        updatedVisualId = null;
      }

      const updatedCurrentView = {
        ...action.currentView,
        ...(action.currentView.opts || {}) // add the opts to the currentView object if present, or an empty object if not
      };

      return {
        ...state,
        isOpen: true,
        previousViews,
        currentView: updatedCurrentView,
        selectedVisualId: updatedVisualId,
      };

    case GO_BACK:
      if (state.previousViews.length === 0) return state;

      const previousView = state.previousViews[state.previousViews.length - 1];

      if (!previousView) return state;

      return {
        ...state,
        currentView: previousView,
        previousViews: state.previousViews.slice(0, -1),
      };

    default:
      if (state.isSubDrawer) {
        return {
          ...state,
          isSubDrawer: true,
        };
      }
      return state;
  }
};

export const useExplorerState = () => {
  const [drawerState, drawerDispatch] = useReducer(
    drawerReducer,
    initialDrawerState
  );
  const [subDrawerState, subDrawerDispatch] = useReducer(drawerReducer, {
    ...initialDrawerState,
    isSubDrawer: true,
  });

  return { drawerState, drawerDispatch, subDrawerState, subDrawerDispatch };
};
