import React, { useMemo, useState } from 'react';
import {
  DESCRIPTION,
  S3_IMAGE_URL,
  S3_VIDEO_URL,
  S3_VISUAL,
} from '../../constants';
import Select from 'react-select';
import Button from '../../../../../components/Button';
import _chunk from 'lodash/chunk';
import Carousel from '../Carousel/Carousel';
import { SET_SECOND_VALUE } from '../../hooks/useDraftCreativeState';

const Block = ({
  blockType,
  blockValue,
  blockOrder,
  blockOptions,
  typeAction,
  valueAction,
  visualTypeAction,
  showUrl,
  blockUrl,
  setBlockUrl,
  blockProps,
  className,
  Card,
}) => {
  const {
    rowIndex,
    isSharedReport,
    draftCreativeDispatch,
    setActiveRow,
    savedConcepts,
    trendingRecs,
    inspirationRecs,
    showCarousel,
    setShowCarousel,
  } = blockProps;

  const chunkSaved = useMemo(() => {
    return _chunk(savedConcepts, 3);
  }, [savedConcepts]);

  const chunkTrending = useMemo(() => {
    return _chunk(trendingRecs, 3);
  }, [trendingRecs]);

  const chunkInspiration = useMemo(() => {
    return _chunk(inspirationRecs, 3);
  }, [inspirationRecs]);

  const handleBlockType = (value) => {
    draftCreativeDispatch({
      type: typeAction,
      value,
      rowIndex,
    });
  };

  const handleBlockValue = (value) => {
    draftCreativeDispatch({
      type: valueAction,
      value,
      rowIndex,
    });
  };

  const handleBlockVisualType = (value) => {
    draftCreativeDispatch({
      type: visualTypeAction,
      value,
      rowIndex,
    });
  };

  const BlockTitle = () => {
    const isVisual = [S3_VISUAL, S3_IMAGE_URL, S3_VIDEO_URL].includes(
      blockType
    );

    if (blockOrder === 1) {
      return <div>{`Base ${isVisual ? 'visual' : 'data point'}`}</div>;
    } else if (blockOrder === 2) {
      return <div>{`${isVisual ? 'Second visual' : 'Data point'}`}</div>;
    } else if (blockOrder === 3) {
      return <div>Final asset</div>;
    }
  };

  return (
    <div
      className={`draft-creative__block ${
        blockType?.toLowerCase() === DESCRIPTION ? 'text' : ''
      } ${className}`}
    >
      <div
        className="draft-creative__card-top"
        style={{
          fontWeight: 500,
          paddingTop: isSharedReport ? '10px' : '0',
        }}
      >
        {!isSharedReport ? (
          <Select
            options={blockOptions}
            value={blockOptions.find((opt) => opt.value === blockType) || null}
            onChange={(e) => {
              if ([S3_VISUAL, S3_IMAGE_URL, S3_VIDEO_URL].includes(e.value)) {
                handleBlockType(e.value);
              } else {
                handleBlockType(DESCRIPTION);
              }
              handleBlockValue('');

              if (e.visual_type) {
                handleBlockVisualType(e.visual_type);
              } else {
                handleBlockVisualType('');
              }
            }}
            onClick={() => setActiveRow(rowIndex)}
          />
        ) : (
          <>
            <BlockTitle />
          </>
        )}
      </div>
      {Card}
      {showUrl && !isSharedReport && (
        <div className="url-editor">
          <input
            type="text"
            placeholder={`Enter ${
              blockType === S3_IMAGE_URL ? 'image' : 'video'
            } URL`}
            onChange={(e) => setBlockUrl(e.target.value)}
            value={blockUrl}
            onBlur={() => {
              draftCreativeDispatch({
                type: valueAction,
                rowIndex,
                value: blockUrl,
              });
            }}
          />
        </div>
      )}
      {showCarousel && valueAction === SET_SECOND_VALUE && (
        <Carousel
          slides={
            [S3_VISUAL, S3_IMAGE_URL, S3_VIDEO_URL].includes(blockType)
              ? [...chunkSaved, ...chunkTrending, ...chunkInspiration]
              : []
          }
          {...{
            handleBlockValue,
            setShowCarousel,
            draftCreativeDispatch,
            rowIndex,
          }}
        />
      )}
    </div>
  );
};

export default Block;
