import React, { useMemo } from 'react';
import { MenuItem } from '../../../../../components/LeftMenu/MenuItem';
import { ReportsNav } from './ReportsNav';
import {
  useCreativeAnalyticsGet,
  useCreativeAnalyticsSet,
} from '../../../contexts';
import { DashboardIcon } from '../../icons/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import { trackEvent } from '../../../../../store/UserActivity/actions';
import {
  CREATIVE_ANALYTICS,
  TOGGLE_COPILOT,
  TOGGLE_COPY,
  TOGGLE_DASHBOARD,
  TOGGLE_VISUAL_MODE,
} from '../../../../../store/UserActivity/constants';
import {
  COPILOT,
  COPY,
  DASHBOARD,
  PERFORMANCE_FREQUENCY,
  RECOMMENDATIONS,
} from '../../../../../store/CreativeReports/constants';
import { TrendingIcon } from '../../icons/trending';
import { LegacyReports } from './LegacyReports';
import { BetaReports } from './BetaReports';
import { usePermissions } from '../../../../../components/Permissions/usePermissions';
import {
  LEGACY_REPORTS,
  BETA_REPORTS,
  TRENDING,
  COPY_REPORT,
  ANNOTATE_VISUALS,
  SETTINGS,
  DRAFT_CREATIVE,
} from '../../../../../components/Permissions/constants';
import { useSearchParams } from '../../../../../components/Hooks/search-params';
import { useSetShowQaEditor } from './hooks';
import RoleAccess from '../../../../../components/RoleAccess';
import { CopyIcon } from '../../icons/copy';
import { isAdminRole } from '../../../../../cookies';
import { DraftCreativeIcon } from '../../icons/draftCreative';
import Tooltip from '../../../../../components/Tooltip';

const TableModeMenu = ({
  setIsSettingsOpen,
  loading,
  isDirectMail,
  isNonPaidMediaType,
  collapsed,
  setCollapsed,
}) => {
  const dispatch = useDispatch();
  const { display, isOrganicView, viewName } = useCreativeAnalyticsGet();
  const { setParams } = useCreativeAnalyticsSet();
  const { setParams: setSearchParams } = useSearchParams();
  const hasPermission = usePermissions();
  const setShowQaEditor = useSetShowQaEditor();
  const isUltraLight = useSelector((state) => state.ViewSettings.isUltraLight);

  const handleOpenSettings = () => {
    setIsSettingsOpen(true);
  };

  const isCompetitorView =
    isDirectMail && viewName.toLowerCase().includes('competitor');

  const { darwin_light, allowedAdmin, isTikTokView } = useSelector(
    ({ ViewSettings: { darwin_light, allowedAdmin, isTikTokView } }) => ({
      darwin_light,
      allowedAdmin,
      isTikTokView,
    })
  );

  const itemClass = collapsed ? 'icon-only' : '';

  return (
    <>
      <MenuItem
        title="Creative Performance"
        icon={<i className="fa-regular fa-images"></i>}
        onMenuItemClick={() => {
          setSearchParams({ display: 'dna_table', category: '' });
        }}
        isSelected={display === 'dna_table'}
        className={itemClass}
        {...{ collapsed, setCollapsed }}
      />
      <ReportsNav {...{ loading, itemClass, collapsed, setCollapsed }} />
      <MenuItem
        title="Copy Report"
        icon={<CopyIcon />}
        onMenuItemClick={() => {
          dispatch(trackEvent(CREATIVE_ANALYTICS, TOGGLE_COPY));
          setSearchParams({ display: COPY, category: '' });
        }}
        isSelected={display === COPY}
        exclude={
          isTikTokView ||
          isOrganicView ||
          isCompetitorView ||
          isDirectMail ||
          !hasPermission(COPY_REPORT)
        }
        className={itemClass}
        {...{ collapsed, setCollapsed }}
      />
      <div className={`divider ${collapsed ? 'collapsed' : ''}`}></div>
      <LegacyReports
        {...{
          loading,
          isDirectMail,
          isNonPaidMediaType,
          darwin_light,
          allowedAdmin,
          isCompetitorView,
          itemClass,
          collapsed,
        }}
        exclude={
          !hasPermission(LEGACY_REPORTS) ||
          isUltraLight ||
          viewName.includes('Email')
        }
      />
      <BetaReports
        {...{
          loading,
          isDirectMail,
          isNonPaidMediaType,
          darwin_light,
          allowedAdmin,
          isCompetitorView,
          itemClass,
          collapsed,
        }}
        exclude={
          !hasPermission(BETA_REPORTS) ||
          isUltraLight ||
          isOrganicView ||
          viewName.includes('Email')
        }
      />
      <div className={`divider ${collapsed ? 'collapsed' : ''}`}></div>
      <MenuItem
        title="Annotate visuals"
        icon={<i className="fa-regular fa-pen-to-square"></i>}
        onMenuItemClick={() => setShowQaEditor(true)}
        exclude={!hasPermission(ANNOTATE_VISUALS) && !isAdminRole()}
        className={itemClass}
        {...{ collapsed }}
      />
      <MenuItem
        title="Draft Creative"
        icon={<DraftCreativeIcon />}
        onMenuItemClick={() =>
          setSearchParams({ display: DRAFT_CREATIVE, category: '' })
        }
        exclude={!hasPermission(DRAFT_CREATIVE)}
        className={itemClass}
        {...{ collapsed }}
      />
      <MenuItem
        title="Settings"
        icon={<i className="fa fa-gear"></i>}
        onMenuItemClick={handleOpenSettings}
        exclude={!hasPermission(SETTINGS) && !isAdminRole()}
        className={itemClass}
        {...{ collapsed }}
      />
    </>
  );
};

export default TableModeMenu;
