import React, { useState } from 'react';

export const useLibraryAnalyticsState = () => {
  const [openLibraryAnalytics, setOpenLibraryAnalytics] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedFromDashboard, setSelectedFromDashboard] = useState(false);


  return {
    openLibraryAnalytics,
    setOpenLibraryAnalytics,
    selectedCategory,
    setSelectedCategory,
    selectedFromDashboard,
    setSelectedFromDashboard,
  }
}