import React from 'react';
import { MUTUALLY_EXCLUSIVE, MULTIPLE_LABELS } from '../constants';
import MutuallyExclusive from './MutuallyExclusive';
import MultipleLabels from './MultipleLabels';
import Button from '../../../../components/Button';

const SingleLabel = ({
  categoryType,
  current,
  label,
  annotatedVisuals,
  visual_hash,
  onSelect,
  onMultipleSelect,
  selectedFrame,
  predictedVisual,
}) => {
  let OptionType = null;

  if (categoryType === MUTUALLY_EXCLUSIVE) {
    OptionType = (
      <MutuallyExclusive
        {...{
          label,
          onSelect,
          current,
          annotatedVisuals,
          visual_hash,
          selectedFrame,
          predictedVisual,
        }}
      />
    );
  }

  if (categoryType === MULTIPLE_LABELS) {
    OptionType = (
      <MultipleLabels
        {...{
          label,
          onMultipleSelect,
          current,
          annotatedVisuals,
          visual_hash,
          selectedFrame,
          predictedVisual,
        }}
      />
    );
  }

  return OptionType;
};

const BulkSelect = ({ onBulkSelect }) => {
  return (
    <div className="bulk-select">
      <p style={{ whiteSpace: 'nowrap' }}>Select All</p>
      <button onClick={() => onBulkSelect('no')} className="mr-2">
        No
      </button>
      <button onClick={() => onBulkSelect('yes')}>Yes</button>
    </div>
  );
};

export const LabelSelect = ({
  labelOptions,
  categoryType,
  visual_hash,
  current,
  annotatedVisuals,
  onSelect,
  onMultipleSelect,
  selectedFrame,
  onBulkSelect,
  predictedVisual,
  onLockInPredictions,
  allowLockIn,
}) => {
  return (
    <div className="label-select-wrapper">
      {categoryType === MULTIPLE_LABELS && (
        <div className="d-flex align-items-center mb-5">
          {allowLockIn && !!predictedVisual && (
            <Button
              className="mr-5"
              onClick={onLockInPredictions}
              appearance="subtleBordered"
            >
              <i class="fa fa-lock mr-2"  style={{ fontSize: '10px' }}></i> Lock in predictions
            </Button>
          )}
          <BulkSelect onBulkSelect={onBulkSelect} />
        </div>
      )}
      <ul className="label-select">
        {labelOptions.map((label) => {
          return (
            <>
              <SingleLabel
                {...{
                  categoryType,
                  current,
                  label,
                  annotatedVisuals,
                  visual_hash,
                  onSelect,
                  onMultipleSelect,
                  selectedFrame,
                  predictedVisual,
                }}
              />
            </>
          );
        })}
      </ul>
    </div>
  );
};
