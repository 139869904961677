import React from 'react';

export const DollarIcon = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div {...props}>
      <svg
        width="15"
        height="12"
        viewBox="0 0 15 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 3.375C6.25734 3.375 5.25 4.55016 5.25 6C5.25 7.44961 6.25734 8.625 7.5 8.625C8.74219 8.625 9.75 7.45008 9.75 6C9.75 4.55016 8.74266 3.375 7.5 3.375ZM8.4375 7.3125C8.4375 7.41609 8.35359 7.5 8.25 7.5H6.75C6.64641 7.5 6.5625 7.41609 6.5625 7.3125V6.9375C6.5625 6.83391 6.64641 6.75 6.75 6.75H7.125V5.45062L7.11398 5.45789C7.09351 5.47156 7.07054 5.48106 7.04639 5.48585C7.02223 5.49063 6.99738 5.49061 6.97323 5.48578C6.94909 5.48094 6.92614 5.4714 6.90569 5.45768C6.88524 5.44397 6.8677 5.42636 6.85406 5.40586L6.64594 5.09391C6.63226 5.07343 6.62276 5.05046 6.61798 5.02631C6.61319 5.00216 6.61322 4.9773 6.61805 4.95316C6.62288 4.92901 6.63243 4.90606 6.64614 4.88561C6.65986 4.86516 6.67747 4.84762 6.69797 4.83398L7.05727 4.59445C7.14965 4.53287 7.25819 4.50001 7.36922 4.5H7.6875C7.79109 4.5 7.875 4.58391 7.875 4.6875V6.75H8.25C8.35359 6.75 8.4375 6.83391 8.4375 6.9375V7.3125ZM14.25 1.5H0.75C0.335859 1.5 0 1.83586 0 2.25V9.75C0 10.1641 0.335859 10.5 0.75 10.5H14.25C14.6641 10.5 15 10.1641 15 9.75V2.25C15 1.83586 14.6641 1.5 14.25 1.5ZM13.875 7.875C13.0465 7.875 12.375 8.54648 12.375 9.375H2.625C2.625 8.54648 1.95352 7.875 1.125 7.875V4.125C1.95352 4.125 2.625 3.45352 2.625 2.625H12.375C12.375 3.45352 13.0465 4.125 13.875 4.125V7.875Z"
          fill="#434C64"
        />
      </svg>
    </div>
  );
};
