
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import FooterWrapper from '../ReportChartFooter';
import {
  EntityPicker,
  MetricPicker,
  LookbackPicker,
  METRICS,
  LOOKBACKS,
  Value,
} from './Pickers';
import { useApi } from './api';
import { useChartData, useChartLayout } from './chart';

import { useAsyncEffect } from '../Hooks/async-effect';
import { ErrorBoundary, BasicError } from '../Helpers/Error';
import { BasicLoading } from '../Helpers/Loading';

const COLORS = ['#fd6969', '#ff9c3e', '#ffe347', 
'#86f76d', '#7c94fd', '#6c6adb', '#ad76eb', 
'#db41db', '#d8326f', '#61aae9', '#519be0', 
'#8cd364', '#ffbc6a', '#ff8c8c', '#e48ae7', 
'#4dd200', '#9D8189', '#757bda', '#FFD670', 
'#71e2ad', '#f19b72', '#ff7086', '#FFAC81',
'#FF928B', '#d48a65', '#e2d985', '#b7f5a5',
'#86BBD8', '#9381FF'];

function useContainerWidth(className = 'ci-chart') {
  const container = document.getElementsByClassName(className);

  return container[0] ? container[0].scrollWidth * 0.9 : 500;
}

function setEntity(reportType) {
  const [type] = reportType.split('_');

  return `${type}s`;
}

const History = ({ reportType, ChartPicker }) => {
  const [metric, setMetric] = useState(METRICS[0]);
  const [lookback, setLookback] = useState(LOOKBACKS[0]);
  const { error, loading, scatterData } = useApi(
    setEntity(reportType),
    lookback.value,
  );

  let toRender = null;

  if (error) {
    toRender = (
      <div style={{ width: '520px' }}>
        <BasicError
          fallback={(
            <>
              <h3>An error occurred</h3>
              <p>{JSON.stringify(error)}</p>
            </>
        )}
        />
      </div>
    );
  } else if (loading) {
    return (
      <div style={{ width: '520px' }}>
        <BasicLoading />
      </div>
    );
  } else {
    toRender = (
      <Display data={scatterData} metric={metric} />
    );
  }

  return (
    <ErrorBoundary message="An error occurred retrieving history data.">
      <div className="row mt-2 ml-5 mr-5 mb-2">
        <div
          className="col-7 d-flex flex-col darwin-box-shadow bg-white p-3"
          style={{ height: '450px' }}
        >
          {toRender}

          <FooterWrapper height={500}>
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-row">
                <MetricPicker handleSelect={setMetric} selected={metric} />
                <LookbackPicker
                  handleSelect={setLookback}
                  selected={lookback}
                />
              </div>

              {ChartPicker}
            </div>
          </FooterWrapper>
        </div>
      </div>
    </ErrorBoundary>
  );
};

History.propTypes = {
  ChartPicker: PropTypes.node.isRequired,
  reportType: PropTypes.string.isRequired,

};

export default History;

function uniqNameOptions(data) {
  const seen = new Set();
  const names = [];

  data.forEach(({ name }) => {
    if (!seen.has(name)) {
      names.push(name);
      seen.add(name);
    }
  });

  const results = names
    .sort((a, b) => a.localeCompare(b))
    .map((name, i) => ({ label: name, value: i + 1 }));

  return [{ label: 'All', value: 0 }, ...results];
}

const uniqColors = (options) => {
  let colors = {};
  options.forEach((option, i) => {
    colors[option.label] = COLORS[i] || COLORS[i - 30];
  });
  return colors;
}

function Display({ data, metric }) {
  const options = useMemo(() => uniqNameOptions(data), [metric.value]);
  const colors = useMemo(() => uniqColors(options), [options]);

  const [index, setIndex] = useState(0);
  const [filtered, setFiltered] = useState(data);

  const handleSelect = (i) => i !== index && setIndex(i);

  useEffect(() => {
    const { label, value } = options[index];
    const update = value === 0 ? data : data.filter(({ name }) => name === label);

    setFiltered(update);
  }, [index]);

  return (
    <>
      <EntityPicker
        menuStyle={{ height: '250px', overflow: 'auto', color: '#666666' }}
        options={options}
        selected={options[index]}
        handleSelect={handleSelect}
      />
      <HistoryPlot metric={metric} data={filtered} colors={colors} />
    </>
  );
}

Display.propTypes = {
  metric: Value.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

function HistoryPlot({ metric: { value, label }, data, colors }) {
  const width = useContainerWidth();
  const {
    traces, frames, xAxisRange, yAxisRange, dates,
  } = useMemo(
    () => useChartData(value, data, colors),
    [value, data.length],
  );
  const layout = useMemo(
    () => useChartLayout({
      width,
      xAxisTitle: label,
      dates,
      xAxisRange,
      yAxisRange,
    }),
    [value, data.length],
  );

  const { error, loading, result } = useAsyncEffect(async () => {
    // Need to await -> await.
    const promise = await import('./_Plotly');
    const Comp = await promise.default;
    // Returning a function will call itself ? How ?
    return { Comp };
  }, []);

  if (error) {
    return (
      <BasicError
        fallback={(
          <>
            <h3>An error occurred</h3>
            <p>{JSON.stringify(error)}</p>
          </>
        )}
      />
    );
  } if (loading) {
    return <BasicLoading fallback={<p>Initializing History</p>} />;
  }
  const Plot = result.Comp;

  return (
    <Plot
      data={traces}
      frames={frames}
      layout={layout}
      useResizeHandler
      style={{ width: '100%', height: '85%' }}
    />
  );
}

HistoryPlot.propTypes = {
  metric: Value.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};
