import React, { useState } from 'react';
import CustomDropdown from '../../../../components/CustomDropdown';
import Button from '../../../../components/Button';

export function ColumnPicker({ onChange, columns, options }) {
  const [show, setShow] = useState(false);

  const handleVisibility = (isOpen, meta) => {
    if (isOpen) {
      setShow(true);
    } else if (meta.source !== 'select') {
      setShow(false);
    }
  };

  const handleSelect = (i) => {
    const column = options[i].value;
    return onChange({ column, checked: !columns.includes(column) });
  };

  return (
    <CustomDropdown
      toggleClass="dw-dropdown-toggle dw-dropdown-toggle--sm"
      label={
        <Button
          appearance="raised"
          style={{ height: '30px', padding: '0 6px' }}
        >
          <i
            style={{ color: '#07063E', fontSize: '1rem' }}
            className="fa-solid fa-columns mr-3"
          />
          Edit columns
        </Button>
      }
      onToggle={handleVisibility}
      onSelect={handleSelect}
      menuStyle={{ transform: 'translateX(-80px) !important' }}
      menuClass="analytics-columns-dropdown"
      toggleStyle={{ cursor: 'pointer', borderRadius: '5px' }}
      show={show}
    >
      {options.map(({ value, label }, i) => {
        return (
          <>
            <CustomDropdown.Item key={value} eventKey={i} className="py-1">
              <div className="form-check d-flex align-items-center justify-content-left">
                <label
                  className="form-check-label"
                  style={{ cursor: 'pointer' }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name={value}
                    defaultChecked={columns.includes(value)}
                    style={{ cursor: 'pointer' }}
                  />
                  <span className="ml-1">{label}</span>
                </label>
              </div>
            </CustomDropdown.Item>
          </>
        );
      })}
    </CustomDropdown>
  );
}
