import { useRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { abortableFetch } from '../../../../../utils';
import {
  useApiState,
  bindFetchToRef,
} from '../../../../../components/Hooks/useApiState';
import {
  notifyCreateSuccess,
  notifyDeleteSuccess,
  notifyError,
  notifySaving,
} from './utils';

const HEADERS = { 'content-type': 'application/json' };

function getCategory(payload) {
  return abortableFetch(
    [
      'api',
      'creative-analytics',
      'custom-categories',
      'user-defined',
      payload.account_id,
      'get',
    ].join('/'),
    {
      method: 'GET',
      headers: HEADERS,
      credentials: 'include',
    }
  );
}

export function useGetApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (payload) => withRef(getCategory(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: true,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    ...getState,
    request: getDispatch,
  };
}

function postCreateCategory(payload) {
  return abortableFetch(
    [
      'api',
      'creative-analytics',
      'custom-categories',
      'user-defined',
      payload.account_id || payload.platform_account_id,
      'create',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useCreateApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(postCreateCategory(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    ...postState,
    request: postDispatch,
  };
}

function postUpdateCategory(payload) {
  return abortableFetch(
    [
      'api',
      'creative-analytics',
      'custom-categories',
      'user-defined',
      payload.account_id,
      'update',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useUpdateApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(postUpdateCategory(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    ...postState,
    request: postDispatch,
  };
}

function postDeleteCategory(payload) {
  return abortableFetch(
    [
      'api',
      'creative-analytics',
      'custom-categories',
      'user-defined',
      payload.account_id,
      'delete',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useDeleteApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(postDeleteCategory(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    ...postState,
    request: postDispatch,
  };
}

export const useUDCApi = (setEditCategory, fetchPerformanceData, reportCardSettings) => {
  const [currentProcess, setCurrentProcess] = useState(null);
  const createApi = useCreateApi();
  const updateApi = useUpdateApi();
  const deleteApi = useDeleteApi();

  const loading =
    currentProcess &&
    (createApi.loading || updateApi.loading || deleteApi.loading);
  const error =
    currentProcess && (createApi.error || updateApi.error || deleteApi.error);

  const reset = () => {
    toast.dismiss();
    setCurrentProcess(null);
    fetchPerformanceData();
  };

  useEffect(() => {
    if (loading) {
      setEditCategory(null);
      notifySaving(currentProcess.category_name);
    }
  }, [loading]);

  useEffect(() => {
    if (error) {
      notifyError(currentProcess.category_name, error.error);
      toast.dismiss('udc-loading');
    }
  }, [error]);

  useEffect(() => {
    if (createApi.data) {
      notifyCreateSuccess(currentProcess.category_name, reset);
      toast.dismiss('udc-loading');
    }
  }, [createApi.data]);

  useEffect(() => {
    if (updateApi.data) {
      notifyCreateSuccess(currentProcess.category_name, reset);
      toast.dismiss('udc-loading');
    }
  }, [updateApi.data]);

  useEffect(() => {
    if (deleteApi.data) {
      reportCardSettings.deleteCategoryFromSettings(deleteApi.data);
      setEditCategory(null);
      notifyDeleteSuccess(currentProcess.category_name, fetchPerformanceData);
      toast.dismiss('udc-loading');
    }
  }, [deleteApi.data]);

  useEffect(() => {
    if (currentProcess && currentProcess.action) {
      const { action, payload } = currentProcess;
      if (action === 'create') {
        createApi.request(payload);
      } else if (action === 'update') {
        updateApi.request(payload);
      } else if (action === 'delete') {
        deleteApi.request(payload);
      } else {
        setCurrentProcess(null);
      }
    }
  }, [currentProcess]);

  const saveUdc = ({ action, category_name, payload }) => {
    setCurrentProcess({
      action,
      category_name,
      payload,
    });
  };

  return {
    saveUdc,
    udcDeleteApi: deleteApi,
  };
};
