import React from "react";
import { toCurrency, toFixed_2, toPercentRounded } from '../../../../utils/numbers';
import { STANDARD_CATEGORIES_LOOKUP } from "../../../CreativeAnalytics/categories";

export const useImpactReportColumns = () => {
  return [
    {
      id: "included_in_combined",
      Header: "",
      accessor: "included_in_combined",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      id: "name",
      Header: "Category",
      accessor: "name",
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: { name },
        },
      }) => {
        return (
          <div className="table-cell-inner table-cell-inner--name">{STANDARD_CATEGORIES_LOOKUP[name] || name}</div>
        );
      },
    },
    {
      id: "score",
      Header: "Individual Impact % (r2)",
      accessor: "score",
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: { score },
        },
      }) => {
        return (
          <div className="table-cell-inner table-cell-inner--score">
            {toPercentRounded(score)}
          </div>
        );
      },
    },
  ];
};

export const useCategoryDetailsColumns = (selectedCategory, isROAS) => {
  return [
    {
      id: "label_name",
      Header: selectedCategory,
      accessor: "name",
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: { name },
        },
      }) => {
        return (
          <div className="table-cell-inner table-cell-inner--label-name">
            {STANDARD_CATEGORIES_LOOKUP[name] || name}
          </div>
        );
      },
    },
    {
      id: "budget_percentage",
      Header: "% Budget",
      accessor: "budget_percentage",
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: { budget_percentage },
        },
      }) => {
        const percentage =
          budget_percentage > 0 && budget_percentage < 0.01
            ? "<1%"
            : toPercentRounded(budget_percentage);

        return (
          <div className="table-cell-inner table-cell-inner--budget">
            {percentage}
          </div>
        );
      },
    },
    {
      id: isROAS ? "roas" : "cpa",
      Header: isROAS ? "ROAS" : "CPA",
      accessor: isROAS ? "roas" : "cpa",
      disableSortBy: true,
      disableFilters: true,
      Cell: ({
        row: {
          original: { cpa, roas },
        },
      }) => {
        const value = isROAS ? toFixed_2(roas) : toCurrency(cpa);
        if (!!value) {
          return (
            <div className={`table-cell-inner table-cell-inner--cpa`}>
              {value}
            </div>
          );
        }
        return (
          <div className={`table-cell-inner table-cell-inner--null`}>-</div>
        );
      },
    },
  ];
};
