import React, { useState } from "react";
import connectTo from "./Container";
import StudyModal from "./Modal";

const ContainedStudyModal = connectTo(StudyModal);

export default ContainedStudyModal;

export function AdStudyButton() {
  const [show, setShow] = useState(false);

  return (
    <>
      <button className="btn btn-primary" onClick={e => setShow(true)}>
        Create Study
      </button>

      <ContainedStudyModal
        id="cr-study-modal"
        show={show}
        handleHide={e => setShow(false)}
      />
    </>
  );
}
