import React from 'react';
import PropTypes from 'prop-types';

import { LayoutContainer, YellowRule } from '../Helpers';

const AdName = ({ adName }) => (
  <LayoutContainer>
    <div className="col-11 pr-0 pl-0">
      <YellowRule />

      <h5>{adName}</h5>
    </div>
  </LayoutContainer>
);

AdName.propTypes = {
  adName: PropTypes.string.isRequired,
};

export default AdName;
