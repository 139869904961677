import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './ProjectSetup.scss';
import DatePicker from 'react-datepicker';
import Button from '../../../components/Button';
import { SuccessScreen } from '../../Marketplace/components/AssetUpload/CreativeRequest/CreativeRequest';

import { CloseIcon } from '../../../components/Icons';
import { ASSET_TAG, DEFAULT_PROJECT, DUE_DATE, MESSSAGE_TO_CONTRACTOR, PROJECT_NAME } from './constants';
import { useCreativeRequestApi } from './api';
import { toDarwinDate } from '../../../utils/darwin-dates';
import { withTryThese } from './utils';
import { useDispatch } from 'react-redux';
import { trackEvent } from '../../../store/UserActivity/actions';
import { CREATIVE_ANALYTICS, SUBMIT_REQUEST } from '../../../store/UserActivity/constants';

const ProjectSetup = ({ requests, selectedContractors, onClose, account_id, view_id, handleRefresh, descriptions, overviews }) => {
  const [projects, setProjects] = useState([]);
  const [submissionValid, setSubmissionValid] = useState(true);
  const { post: { request, data, error, loading } } = useCreativeRequestApi();
  const dispatch = useDispatch();

  useEffect(() => {
    const contractorsToProjects = selectedContractors.map(({ name, assignedRequests }) => {
      return {
        ...DEFAULT_PROJECT,
        contractor: name,
        requests: assignedRequests,
      }
    })

    setProjects(contractorsToProjects);
  }, [selectedContractors]);

  const handleSubmit = () => {
    dispatch(trackEvent(CREATIVE_ANALYTICS, SUBMIT_REQUEST));
    request({
      account_id,
      view_id,
      projects: getProjectsPayload(projects, withTryThese(requests), descriptions, overviews),
    })
  }

  const handleEmbraceSuccess = () => {
    onClose();
    handleRefresh();
  }

  if (data) {
    return (
      <div className="request__popup">
        <div className="success-screen-wrapper">
          <SuccessScreen
            url={`https://dashboard.darwinsoftware.io/creative-requests/${data[0]}`}
            onCancel={handleEmbraceSuccess}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="project-setup">
      <div className="project-setup__header">
        <h1>Project Setup</h1>
        <CloseIcon onClick={onClose} className="project-setup__close" />
      </div>

      <ProjectList>
        {projects.map((project, index) => (
          <Project {...{ project, index, projects, setProjects, setSubmissionValid }}  />
        ))}
      </ProjectList>

      <div className="d-flex justify-content-end align-items-center">
        {loading && <p className="project-setup__message">Saving...</p>}
        {error && <p className="project-setup__message">{error.error}</p>}
        <Button onClick={handleSubmit} disabled={loading || !submissionValid}>Submit</Button>
      </div>
    </div>
  );
};

ProjectSetup.defaultProps = {
  selectedContractors: [],
};

ProjectSetup.propTypes = {
  requests: PropTypes.node.isRequired,
  selectedContractors: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  account_id: PropTypes.string.isRequired,
  view_id: PropTypes.string.isRequired,
};

export default ProjectSetup;

const getProjectsPayload = (projects, requests, descriptions, overviews) => {
  return projects.map((proj) => {
    const creative_requests = requests.map((req, i) => ({
      ...req,
      request_description: descriptions[i],
      request_overview: overviews[i],
    })).filter((req, i) => proj.requests.includes(i))

    return {
      asset_tags: proj.asset_tag,
      contractor: proj.contractor,
      project_name: proj.project_name,
      due_date: proj.due_date ? toDarwinDate(proj.due_date) : '',
      message_to_contractor: proj.message_to_contractor,
      creative_requests,
    }
  })
}

const ProjectList = ({ children }) => {
  return (
    <div className="project-setup__carousel">
      {children}
    </div>
  )
}

const Project = ({ project, index, projects, setProjects, setSubmissionValid }) => {
  const { contractor, asset_tag, due_date, message_to_contractor, project_name, requests } = project;

  const handleInput = (key, value) => {
    const update = projects.map((proj, i) => {
      if (i === index) {
        return { ...proj, [key]: value };
      }
      return proj;
    });
    if (key === ASSET_TAG && value.includes(",")) {
      setSubmissionValid(false);
    }
    else {
      setSubmissionValid(true);
    }

    setProjects(update);
  }

  return (
    <div className="project-card">
      <div>
        <h2 className="project-card__title">{contractor}</h2>
        <ul className="project-card__requests">
          {requests.map((req) => (
            <li>Creative Request {req + 1}</li>
          ))}
        </ul>

        <div className="project-card__input">
          <label htmlFor="">Project Name</label>
          <input type="text" value={project_name} onChange={(e) => handleInput(PROJECT_NAME, e.target.value)}/>
        </div>

        <div className="project-card__input">
          <label htmlFor="">Due Date</label>
            <DatePicker
              selected={due_date}
              className="text-center"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              onChange={(date) => handleInput(DUE_DATE, date)}
              popperClassName="project-card__datepicker"
              popperPlacement="bottom"
              placeholderText="Choose a date"
            />
        </div>

        <div className="project-card__input">
          <label htmlFor="">Asset Tag</label>
          <input type="text" value={asset_tag} onChange={(e) => handleInput(ASSET_TAG, e.target.value)}/>
          <span style={{ color: "red" }}>{(asset_tag.includes(",") ? "Tag cannot include commas." : null)}</span>
        </div>

        <div className="project-card__input">
          <label htmlFor="">Message to Contractor</label>
          <textarea type="text" value={message_to_contractor} onChange={(e) => handleInput(MESSSAGE_TO_CONTRACTOR, e.target.value)}/>
        </div>
      </div>
    </div>
  )
}

Project.propTypes = {
  project: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  projects: PropTypes.node.isRequired,
  setProjects: PropTypes.func.isRequired,
  setSubmissionValid: PropTypes.func.isRequired,
};
