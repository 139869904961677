import React, { useState, useEffect, useRef, useMemo } from 'react';
import './PhraseBuilder.scss';
import CreateEditPhrase from './CreateEditPhrase';
import { EditingIcon, PinIcon, RefreshIcon, WarningIcon } from './Helpers';
import Tippy from '@tippyjs/react';
import 'tippy.js/animations/scale.css';
import { usePhraseBuilder } from './hooks';
import {
  ADD_PHRASE,
  REMOVE_PHRASE,
  RESET_PHRASE,
  SET_ORIGINAL,
  SET_PHRASE_DATA,
  UPDATE_PHRASE_NAME,
} from './reducer';
import Button from '../../../components/Button';
import { EMPTY_PHRASE, KEYWORD, PHRASES } from './constants';
import { useCreativeAnalyticsGet } from '../contexts';
import { useReportCardContext } from '../ReportCard/contexts';
import { DARWIN_NAVY_4 } from '../../../constants';
import { ClickAwayListener } from '@mui/material';
import Tooltip from '../../../components/Tooltip';
import { notifySaveSuccess } from './api';
import { toast } from 'react-toastify';
import { useSaveSingleDerivedLabelApi } from '../CategoryBuilder/Custom/DerivedLabels/api';
import { useNotifications } from '../../../components/Notification/useNotifications';
import { useDispatch } from 'react-redux';
import { removePhraseDataForVisual } from '../../../store/CreativeAnalytics/creativeAnalyticsSlice';

const useInvalidate = () => {
  const dispatch = useDispatch();

  return (visual_hash) => {
    dispatch(removePhraseDataForVisual(visual_hash));
  };
};

const PhraseBuilder = ({ data, error, phraseBuilderApi, visual_hash }) => {
  const { account_id, period1 } = useCreativeAnalyticsGet();
  const {
    changesMade,
    setChangesMade,
    refreshing,
    setRefreshing,
    fetchPhraseBuilderData,
    setShouldHaveData,
    current,
  } = useReportCardContext();
  const phraseBuilder = usePhraseBuilder();
  const saveDerivedLabelApi = useSaveSingleDerivedLabelApi();
  const [editMode, setEditMode] = useState(false);
  const [currentPhrase, setCurrentPhrase] = useState({
    index: 0,
    phrase: EMPTY_PHRASE,
  });
  const [phrases, setPhrases] = useState(data.phrases);
  const [switchPhrase, setSwitchPhrase] = useState({
    phrase: null,
    index: null,
    new: false,
  });
  const [showSaveWarning, setShowSaveWarning] = useState(false);
  const delay = 30;
  const [promptRefresh, setPromptRefresh] = useState(false);
  const notifications = useNotifications();

  const isSaving = phraseBuilderApi.post.loading || saveDerivedLabelApi.loading;
  const invalidateStoredData = useInvalidate();

  console.log(current);
  useEffect(() => {
    if (phraseBuilderApi.post.data) {
      toast.dismiss('derived-category-loading');
      setPromptRefresh(true);
    }
  }, [phraseBuilderApi.post.data]);

  useEffect(() => {
    let phrases = [];

    if (Object.keys(data).length) {
      if (refreshing && changesMade) {
        phrases = data.phrases[currentPhrase.index]
          ? data.phrases.map((phrase, i) => {
              if (i === currentPhrase.index) {
                return currentPhrase.phrase;
              } else {
                return phrase;
              }
            })
          : [...data.phrases, currentPhrase.phrase];
      } else {
        phrases = data.phrases;
      }

      phraseBuilder.dispatchPhraseBuilder({
        type: SET_PHRASE_DATA,
        payload: {
          phrases: phrases,
          existingLabels: data.existing_label_names,
        },
      });

      setRefreshing(false);
    }
  }, [data]);

  useEffect(() => {
    if (!editMode) {
      setCurrentPhrase({
        index: phraseBuilder.phrases.length - 1,
        phrase: EMPTY_PHRASE,
      });
    }
  }, [editMode, phraseBuilder.phrases.length]);

  useEffect(() => {
    setPhrases(phraseBuilder.phrases);
    setRefreshing(false);
  }, [phraseBuilder.phrases]);

  const createPayload = () => {
    const phrases = phraseBuilder.phrases.filter(
      (phrase) =>
        phrase.name &&
        phrase.rules.every((rule) => rule.include.length || rule.exclude.length)
    );

    return {
      platform_account_id: account_id,
      category_name: PHRASES,
      start_date: period1.split(',')[0],
      end_date: period1.split(',')[1],
      derived_labels: phrases.map((phrase) => {
        return {
          label_name: phrase.name.trim(),
          label_value: `FX_${phrase.name.trim()}`,
          rules: phrase.rules.map((rule) => {
            return {
              labels_to_include: rule.include.map((label) => {
                return {
                  label: label,
                  name: label,
                  category: KEYWORD,
                  value: label,
                };
              }),
              labels_to_exclude: rule.exclude.map((label) => {
                return {
                  label: label,
                  name: label,
                  category: KEYWORD,
                  value: label,
                };
              }),
            };
          }),
        };
      }),
    };
  };

  const createPayloadForSinglePhrase = () => {
    const phraseToSave = phraseBuilder.phrases.find((x, i) => {
      return i === currentPhrase.index;
    });

    return {
      platform_account_id: account_id,
      category_name: PHRASES,
      start_date: period1.split(',')[0],
      end_date: period1.split(',')[1],
      derived_labels: [
        {
          label_name: phraseToSave.name.trim(),
          label_value: `FX_${phraseToSave.name.trim()}`,
          rules: phraseToSave.rules.map((rule) => {
            return {
              labels_to_include: rule.include.map((label) => {
                return {
                  label: label,
                  name: label,
                  category: KEYWORD,
                  value: label,
                };
              }),
              labels_to_exclude: rule.exclude.map((label) => {
                return {
                  label: label,
                  name: label,
                  category: KEYWORD,
                  value: label,
                };
              }),
            };
          }),
        },
      ],
    };
  };

  const handleSave = () => {
    setPromptRefresh(false);

    const payload = createPayload();
    phraseBuilderApi.post.request(payload);
    invalidateStoredData(visual_hash);
  };

  const handleSaveOne = () => {
    notifications.add({
      id: 'saving-phrase',
      title: 'Saving phrases',
      message: 'Please wait',
      showEllipses: true,
    });
    setPromptRefresh(false);
    const payload = createPayloadForSinglePhrase();
    saveDerivedLabelApi.request(payload);
  };

  useEffect(() => {
    if (saveDerivedLabelApi.data) {
      notifications.remove('saving-phrase');
      notifications.add({
        title: 'Success!',
        message: 'Phrase saved successfully.',
      });
      setPromptRefresh(true);
    }
  }, [saveDerivedLabelApi.data]);

  useEffect(() => {
    if (phraseBuilderApi.post.data) {
      handleAddPhrase();

      setCurrentPhrase({
        index: phraseBuilder.phrases.length - 1,
        phrase: EMPTY_PHRASE,
      });

      phraseBuilder.dispatchPhraseBuilder({
        type: SET_ORIGINAL,
        payload: EMPTY_PHRASE,
      });
    }
  }, [phraseBuilderApi.post.data]);

  const handleDeletePhrase = () => {
    phraseBuilder.dispatchPhraseBuilder({
      type: REMOVE_PHRASE,
      payload: {
        phraseIndex: currentPhrase.index,
      },
    });

    setPromptRefresh(false);
  };

  const handleDeleteCategory = () => {
    setPromptRefresh(false);

    phraseBuilderApi.delete.request({
      platform_account_id: account_id,
      category: PHRASES,
    });
  };

  useEffect(() => {
    // handleSave after REMOVE_PHRASE is done
    if (
      phraseBuilder.original.name.length &&
      phraseBuilder.phrases.every((phrase) => {
        return phrase.name !== phraseBuilder.original.name;
      })
    ) {
      if (
        phraseBuilder.phrases.length === 0 ||
        (phraseBuilder.phrases.length === 1 &&
          _.isEqual(phraseBuilder.phrases[0], EMPTY_PHRASE))
      ) {
        handleDeleteCategory();
      } else {
        handleSave();
      }
      handleAddPhrase();
      setCurrentPhrase({
        index: phraseBuilder.phrases.length - 2,
        phrase: EMPTY_PHRASE,
      });

      setEditMode(false);
    }
  }, [phraseBuilder.phrases.length]);

  const handleAddPhrase = () => {
    if (
      !_.isEqual(
        phraseBuilder.phrases[phraseBuilder.phrases.length - 1],
        EMPTY_PHRASE
      )
    ) {
      phraseBuilder.dispatchPhraseBuilder({
        type: ADD_PHRASE,
        payload: EMPTY_PHRASE,
      });
    }

    setCurrentPhrase({
      index: phraseBuilder.phrases.length - 1,
      phrase: EMPTY_PHRASE,
    });

    setShowSaveWarning(false);
    setEditMode(false);
    handleOriginal(EMPTY_PHRASE);
  };

  const handleUpdatePhraseName = (value) => {
    setCurrentPhrase((prev) => {
      return {
        ...prev,
        phrase: { ...prev.phrase, name: value },
      };
    });
  };

  const handleSavePhraseName = (phraseIndex, name) => {
    phraseBuilder.dispatchPhraseBuilder({
      type: UPDATE_PHRASE_NAME,
      payload: {
        phraseIndex,
        name,
      },
    });
  };

  useEffect(() => {
    if (
      currentPhrase &&
      phraseBuilder.original &&
      phraseBuilder.phrases[currentPhrase.index]
    ) {
      const { phrase: current } = currentPhrase;
      const { original } = phraseBuilder;
      const compare = phraseBuilder.phrases[currentPhrase.index];

      if (
        current.name.trim() !== original.name.trim() ||
        compare.rules.length !== original.rules.length ||
        compare.rules.some(
          (rule, i) =>
            !_.isEqual(
              [...rule.include].sort(),
              [...original.rules[i].include].sort()
            ) ||
            !_.isEqual(
              [...rule.exclude].sort(),
              [...original.rules[i].exclude].sort()
            )
        )
      ) {
        setChangesMade(true);
      } else {
        setChangesMade(false);
      }
    }
  }, [currentPhrase, phraseBuilder]);

  const handleSaveClickAway = (e) => {
    if (['edit-btn', 'create-new-btn'].includes(e.target.classList.value))
      return;
    setShowSaveWarning(false);
  };

  const handleOriginal = (phrase) => {
    phraseBuilder.dispatchPhraseBuilder({
      type: SET_ORIGINAL,
      payload: phrase,
    });
  };

  const handleEditMode = (phrase, index) => {
    setShowSaveWarning(false);
    setEditMode(true);
    setCurrentPhrase({
      index: index,
      phrase: phrase,
    });
    handleOriginal(phrase);
  };

  const handleSwitchPhrase = (phrase, index, isNew) => {
    if (changesMade) {
      setShowSaveWarning(true);
      setSwitchPhrase({ phrase, index, new: isNew });
    } else {
      if (isNew) {
        handleAddPhrase();
      } else {
        handleEditMode(phrase, index);
      }
    }
  };

  const handleResetPhrase = () => {
    phraseBuilder.dispatchPhraseBuilder({
      type: RESET_PHRASE,
      payload: {
        phraseIndex: currentPhrase.index,
        phrase: phraseBuilder.original,
      },
    });
  };

  const handleRefresh = () => {
    const timer = setTimeout(() => fetchPhraseBuilderData(), 1000);
    return () => clearTimeout(timer);
  };

  if (error) {
    return <div>There was an error ):</div>;
  }

  return (
    <div className="phrase-builder d-flex flex-column w-100">
      {refreshing && (
        <div className="phrase-builder__overlay">
          <div className="loading__container">
            <span className="loading__circle"></span>
            <span className="loading__circle"></span>
            <span className="loading__circle"></span>
          </div>
        </div>
      )}
      <div className="d-flex phrase-builder__container">
        <div className="d-flex col-5 phrase-builder__column">
          <div className="refresh">
            <span className={`refresh__tooltip${promptRefresh ? ' show' : ''}`}>
              Updated data ready! Click to refresh phrases.
            </span>
            <Tippy
              content="Refresh not available yet."
              className="phrase-builder-tooltip"
              placement="left"
              disabled={promptRefresh}
            >
              <span
                className={`refresh__icon${promptRefresh ? ' enabled' : ''}`}
                onClick={() => {
                  if (!promptRefresh) {
                    return;
                  } else {
                    setRefreshing(true);
                    setPromptRefresh(false);
                    setCurrentPhrase({
                      ...currentPhrase,
                      phrase: {
                        ...currentPhrase.phrase,
                        rules: phraseBuilder.phrases[currentPhrase.index].rules,
                      },
                    });
                    handleRefresh();
                  }
                }}
              >
                <RefreshIcon />
              </span>
            </Tippy>
          </div>
          <Phrases
            phrases={phrases.map((phrase, i) => ({
              ...phrase,
              indexBeforeSort: i,
            }))}
            {...{
              currentPhrase,
              setCurrentPhrase,
              handleSwitchPhrase,
            }}
          />
          <Keywords {...{ data }} />
        </div>
        <div className="d-flex col-7 phrase-builder__column">
          {showSaveWarning && <div className="column-overlay"></div>}
          <>
            <ClickAwayListener onClickAway={(e) => handleSaveClickAway(e)}>
              <div className="clickaway-div">
                {showSaveWarning ? (
                  <div
                    className={`pb-modal${
                      showSaveWarning ? ' show' : ''
                    } ${'save-warning'}`}
                  >
                    <span className="mb-2 d-flex align-items-center">
                      <span className="save-warning-icon">
                        <WarningIcon />
                      </span>
                      There are unsaved changes to this phrase.
                    </span>
                    <div className="mt-2 d-flex">
                      <Button
                        onClick={() => {
                          handleResetPhrase();

                          if (switchPhrase.new) {
                            handleAddPhrase();
                          } else {
                            handleEditMode(
                              switchPhrase.phrase,
                              switchPhrase.index
                            );
                          }
                        }}
                      >
                        Discard changes
                      </Button>
                      <Button
                        className="darwin-button--active"
                        onClick={() => setShowSaveWarning(false)}
                      >
                        Keep editing
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>
            </ClickAwayListener>
          </>
          <CreateEditPhrase
            {...{
              data,
              editMode,
              currentPhrase,
              phraseBuilder,
              handleDeletePhrase,
              handleAddPhrase,
              handleUpdatePhraseName,
              handleSavePhraseName,
              changesMade,
              handleSave,
              showSaveWarning,
              setShowSaveWarning,
              handleSaveOne,
              isSaving,
            }}
          />
        </div>
      </div>
      <div className="phrase-builder__footer">
        <div className="d-flex justify-content-end align-items-center">
          {changesMade && (
            <span>
              <i className="fa-regular fa-pen-to-square changes-made-icon"></i>
              Changes in progress.
            </span>
          )}
          {!changesMade && (
            <span>
              <i className="fa-regular fa-circle-check up-to-date-icon"></i>
              Everything up to date.
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default PhraseBuilder;

const Phrases = ({
  phrases,
  currentPhrase,
  setCurrentPhrase,
  handleSwitchPhrase,
}) => {
  const [selectedPhrase, setSelectedPhrase] = useState('');
  const [selectedRule, setSelectedRule] = useState('');
  const [numberApplied, setNumberApplied] = useState(0);

  const handleClick = (phrase) => {
    if (!selectedPhrase || selectedPhrase !== phrase) {
      setSelectedPhrase(phrase);
    }

    if (selectedPhrase === phrase) {
      setSelectedPhrase('');
    }

    setSelectedRule('');
  };

  const handleSelectRule = (phraseName, i) => {
    const tempRuleName = `${phraseName}_rule${i}`;

    if (!selectedRule || selectedRule !== tempRuleName) {
      setSelectedRule(tempRuleName);
    }

    if (selectedRule === tempRuleName) {
      setSelectedRule('');
    }
  };

  const sortedPhrases = [...phrases].sort((a, b) => {
    return a.applied_to_visual === b.applied_to_visual
      ? 0
      : a.applied_to_visual
      ? -1
      : 1;
  });

  useEffect(() => {
    setNumberApplied(phrases.filter((p) => p.applied_to_visual).length);
  }, [phrases]);

  const phraseOrder = useMemo(
    () => sortedPhrases.map(({ name }) => name),
    [numberApplied]
  );

  useEffect(() => {
    const index = phrases.findIndex(
      (p) => p.name === currentPhrase.phrase.name
    );

    if (index !== currentPhrase.index) {
      setCurrentPhrase((prev) => ({
        ...prev,
        index: index,
      }));
    }
  }, [phraseOrder]);

  return (
    <div className="phrase-builder__section phrases">
      <div className="phrase-builder__section-header">
        <h4>Phrases</h4>
        <button
          className="create-new-btn"
          onClick={() => handleSwitchPhrase(null, null, true)}
        >
          + New
        </button>
      </div>
      <div>
        <ul className="phrase-list">
          {sortedPhrases.length > 1 ||
          !(
            sortedPhrases.length === 1 &&
            _.isEqual(sortedPhrases[0], EMPTY_PHRASE)
          ) ? (
            sortedPhrases.map((phrase, i) => {
              const activePhrase = selectedPhrase === phrase.name;
              const index = phrase.indexBeforeSort || i;
              const isBeingEdited = currentPhrase.index === index;
              const isIncomplete =
                !isBeingEdited &&
                (!phrase.name.length ||
                  phrase.rules.some(
                    (r) => !r.include.length && !r.exclude.length
                  ));

              return (
                (phrase.name.length || phrase.dependent_on_self) && (
                  <>
                    <li
                      onClick={() => handleClick(phrase.name)}
                      className={`phrase-list__item${
                        activePhrase || isBeingEdited ? ' active' : ''
                      }${isBeingEdited ? ' editing' : ''}`}
                    >
                      {phrase.applied_to_visual && (
                        <Tippy
                          content="Applied to this visual"
                          className="phrase-builder-tooltip"
                          duration={200}
                          delay={250}
                        >
                          <span className="pin-icon">
                            <PinIcon />
                          </span>
                        </Tippy>
                      )}
                      <span>{`${
                        phrase.name.length ? phrase.name : `Phrase ${index + 1}`
                      }`}</span>
                      {isBeingEdited ? (
                        <EditingIcon />
                      ) : (
                        <span className="rule-count">
                          ({phrase.rules.length} rule
                          {phrase.rules.length !== 1 ? 's' : ''})
                        </span>
                      )}
                      {isIncomplete && (
                        <Tippy
                          content="Incomplete"
                          className="phrase-builder-tooltip"
                          duration={200}
                          delay={250}
                        >
                          <span className="warning-icon">
                            <WarningIcon />
                          </span>
                        </Tippy>
                      )}
                    </li>
                    <div className="phrase-list__item-details">
                      <div className="item-details-container">
                        {phrase.rules.map((rule, i) => {
                          const activeRule =
                            selectedRule === `${phrase.name}_rule${i}`;

                          return (
                            <div
                              className="phrase-list__rule"
                              onClick={() => handleSelectRule(phrase.name, i)}
                            >
                              <div
                                className={`rule-name${
                                  activeRule ? ' active' : ''
                                }`}
                              >
                                <i className="fa-solid fa-chevron-right"></i>
                                <span>Rule {i + 1}</span>
                              </div>
                              <div className="details-panel">
                                <div className="rule-detail">
                                  Include:{' '}
                                  {rule.include.length ? (
                                    rule.include.map((k, i) => {
                                      return (
                                        <span className="ml-2">
                                          "{k}"
                                          {i < rule.include.length - 1 && ','}
                                        </span>
                                      );
                                    })
                                  ) : (
                                    <span
                                      className="ml-2"
                                      style={{ color: '#808BA6' }}
                                    >
                                      No inclusions.
                                    </span>
                                  )}
                                </div>
                                <div className="rule-detail">
                                  Exclude:{' '}
                                  {rule.exclude.length ? (
                                    rule.exclude.map((k, i) => {
                                      return (
                                        <span className="ml-2">
                                          "{k}"
                                          {i < rule.exclude.length - 1 && ','}
                                        </span>
                                      );
                                    })
                                  ) : (
                                    <span
                                      className="ml-2"
                                      style={{ color: '#808BA6' }}
                                    >
                                      No exclusions.
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        {!isBeingEdited && (
                          <span
                            className="edit-btn"
                            onClick={() =>
                              handleSwitchPhrase(phrase, index, false)
                            }
                          >
                            Edit {`>`}
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                )
              );
            })
          ) : (
            <span
              style={{
                color: DARWIN_NAVY_4,
                fontSize: '1.1rem',
                fontWeight: 500,
              }}
            >
              No phrases yet.
            </span>
          )}
        </ul>
      </div>
    </div>
  );
};

const Keywords = ({ data }) => {
  const { searchTermsData } = useCreativeAnalyticsGet();
  console.log({ data });
  return (
    <div className="phrase-builder__section keywords">
      <div className="phrase-builder__section-header">
        <h4>
          Keywords ({data.visual_keywords.length})
          <Tooltip
            content={
              <div>
                <p className="m-0">
                  Min text size: {searchTermsData.text_threshold ?? 'N/A'}
                </p>
                <p className="m-0">
                  Confidence threshold:{' '}
                  {searchTermsData.confidence_threshold ?? 'N/A'}
                </p>
              </div>
            }
          >
            <i
              className="ml-2 fa fa-circle-info"
              style={{ fontSize: '13px' }}
            ></i>
          </Tooltip>
        </h4>
      </div>
      <div>
        <ul className="keywords-list">
          {[...data.visual_keywords]
            .sort((a, b) => {
              return a.localeCompare(b);
            })
            .map((keyword) => {
              return <li className="keywords-list__item">"{keyword}"</li>;
            })}
        </ul>
      </div>
    </div>
  );
};
