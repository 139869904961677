//@ts-check
import React, { createContext, useReducer, useEffect } from "react";
import PropTypes from "prop-types";

import { toDarwinDate, daysFrom } from "../../../utils/darwin-dates";

import { useIdLookup } from "../Api";
import {
  DATE_RX,
  capitalize,
  shorten,
  isNonEmptyString,
  isNonEmptyList,
  isOneOfValues,
  regexpMatch,
  isString,
  isNumber,
  maybe,
} from "../validation";
import { useDetails } from "./DetailSwitch";

const FORM_STATE = {
  study_name: "",
  automation_study_type: "automatic",
  testing_study_type: "abz_test",
  study_type: "creative",
  start_date: toDarwinDate(daysFrom(new Date(), 7)),
  end_date: toDarwinDate(daysFrom(new Date(), 14)),
  campaign_id: "",
  comparison_campaigns: [],
  group_a_label: "",
  group_a_adset_id: "",
  group_b_adset_id: "",
  group_b_label: "",
  status: "active",
};

export const WizardContext = createContext({
  ...FORM_STATE,
  handleChange: () => null,
  validators: {},
});

function formReducer(state = FORM_STATE, action) {
  switch (action.type) {
    case "RESET":
      return FORM_STATE;

    case "SET":
      return {
        ...state,
        [action.key]: action.value,
      };

    case "BULK_SET":
      return action.data;

    default:
      return state;
  }
}

function bulkMiddleware(dispatch, state) {
  return (action) => {
    switch (action.type) {
      case "SET":
        if (action.key === "testing_study_type" && state.study_type !== "") {
          const purged = purgeGroups(state);
          const details = useDetails(
            state.automation_study_type,
            action.value,
            state.study_type
          );

          dispatch({
            type: "BULK_SET",
            data: {
              ...purged,
              ...details.state,
              [action.key]: action.value,
            },
          });
        } else if (
          action.key === "study_type" &&
          state.testing_study_type !== ""
        ) {
          const purged = purgeGroups(state);
          const details = useDetails(
            state.automation_study_type,
            state.testing_study_type,
            action.value
          );

          dispatch({
            type: "BULK_SET",
            data: {
              ...purged,
              ...details.state,
              [action.key]: action.value,
            },
          });
        } else {
          return dispatch(action);
        }

      default:
        return dispatch(action);
    }
  };
}

function formValidators(state, idLookup) {
  const getNameFrom = (key) => shorten(idLookup(state[key]), 60);

  return {
    study_name: {
      valid: isNonEmptyString(state.study_name),
    },
    study_type: {
      valid: isOneOfValues("creative", "audience")(state.study_type),
      display: capitalize,
    },
    automation_study_type: {
      valid: isOneOfValues("automatic", "manual")(state.automation_study_type),
      display: capitalize,
    },
    testing_study_type: {
      valid: isOneOfValues("ab_test", "abz_test")(state.testing_study_type),
      display: (_) =>
        state.testing_study_type === "ab_test" ? "A/B Test" : "A/B-Z Test",
    },
    start_date: {
      valid: regexpMatch(DATE_RX)(state.start_date),
    },
    end_date: {
      valid: regexpMatch(DATE_RX)(state.end_date),
    },
    status: {
      display: capitalize,
    },
    campaign_id: {
      valid: isNonEmptyString(state.campaign_id),
      display: (_) => getNameFrom("campaign_id"),
    },
    comparison_campaigns: {
      valid: isNonEmptyList(state.comparison_campaigns),
      display: (_) => getNameFrom("comparison_campaigns")
    },
    group_a_label: {
      valid: isNonEmptyString(state.group_a_label),
    },
    // depends on combo of "testing_study_type", "study_type"
    group_a_ad_id: {
      valid:
        state.study_type !== "creative" ||
        isNonEmptyString(state.group_a_ad_id),
      display: (_) => getNameFrom("group_a_ad_id"),
    },
    group_a_adset_id: {
      valid:
        state.study_type !== "audience" ||
        isNonEmptyString(state.group_a_adset_id),
      display: (_) => getNameFrom("group_a_adset_id"),
    },
    group_b_label: {
      valid:
        state.testing_study_type !== "ab_test" ||
        isNonEmptyString(state.group_b_label),
    },
    group_b_ad_id: {
      valid:
        state.testing_study_type !== "ab_test" ||
        state.study_type !== "creative" ||
        isNonEmptyString(state.group_b_ad_id),
      display: (_) => getNameFrom("group_b_ad_id"),
    },
    group_b_adset_id: {
      valid:
        state.testing_study_type !== "ab_test" ||
        state.study_type !== "audience" ||
        isNonEmptyString(state.group_b_adset_id),
      display: (_) => getNameFrom("group_b_adset_id"),
    },
    group_a_ad_string: {
      valid: maybe(isString),
    },
    group_a_adset_string: {
      valid: maybe(isString),
    },
    group_b_ad_string: {
      valid: maybe(isString),
    },
    group_b_adset_string: {
      valid: maybe(isString),
    },
    // optional
    description: {
      valid: isString,
    },
    spend_goal: {
      valid: isNumber,
    },
  };
}

export function WizardProvider({ children, initialState }) {
  const initial = initialState.study_name === "" ? FORM_STATE : initialState;
  const [state, _dispatch] = useReducer(formReducer, initial);
  const idLookup = useIdLookup();

  const dispatch = bulkMiddleware(_dispatch, state);

  const handleChange = (key, value) => dispatch({ type: "SET", key, value });

  const validators = formValidators(state, idLookup);

  const value = {
    ...state,
    inProgress: !!initialState.in_progress,
    validators,
    handleChange,
    reset: () => dispatch({ type: "RESET" }),
  };

  useEffect(() => {
    if (initialState.study_name === "") {
      dispatch({ type: "RESET" });
    } else {
      dispatch({ type: "BULK_SET", data: initialState });
    }
  }, [initialState.study_name]);

  return (
    <WizardContext.Provider value={value}>{children}</WizardContext.Provider>
  );
}

WizardProvider.propTypes = {
  initialState: PropTypes.shape({ study_name: PropTypes.string }).isRequired,
};

function purgeGroups(state) {
  return Object.keys(state).reduce((acc, key) => {
    if (key.indexOf("group") === 0) {
      return acc;
    } else {
      acc[key] = state[key];

      return acc;
    }
  }, {});
}
