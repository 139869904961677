import React from 'react';
import CategoryBuilder from '../../../CategoryBuilder';
import { useCreativeAnalyticsGet } from '../../../contexts';

export const CCCEditor = ({ currentView, singleToFull }) => {
  const { category, showDerivativeFlow } = currentView;
  const { category_name, category_id, ccc_type, visual_hash, easy_ai } =
    category;
  let Render = null;
  const {
    udcApi,
    allKeywords,
    getAdNameLabelsApi,
    adNameLabelsApi,
    setEditCategory,
    setEditCategoryVisual,
    originalMetricsData,
  } = useCreativeAnalyticsGet();

  console.log({
    category_name,
    category_id,
    ccc_type,
    visual_hash,
    easy_ai,
    category,
    udcApi,
    allKeywords,
    getAdNameLabelsApi,
    adNameLabelsApi,
    setEditCategory,
    setEditCategoryVisual,
    originalMetricsData,
  });
  if (ccc_type === 'ai_driven' && visual_hash) {
    Render = (
      <CategoryBuilder
        singleToFull={singleToFull}
        originalMetricsData={originalMetricsData}
        editCategoryVisual={{
          category: category_name,
          category_id,
          visual_hash,
          ccc_type,
          type: ccc_type === 'standard' ? 'standard' : 'custom',
          show: true,
          shouldShowBoxPlot: false,
          easy_ai,
        }}
      />
    );
  } else {
    Render = (
      <CategoryBuilder
        category={{
          category: category_name,
          category_id,
          ccc_type,
          shouldShowBoxPlot: false,
          easy_ai,
        }}
        showDerivativeFlow={showDerivativeFlow || false}
        editCategoryVisual={null}
        {...{
          singleToFull,
          udcApi,
          allKeywords,
          getAdNameLabelsApi,
          adNameLabelsApi,
          setEditCategory,
          setEditCategoryVisual,
          originalMetricsData,
        }}
      />
    );
  }
  return <div className="ccc-drawer-wrapper">{Render}</div>;
};
