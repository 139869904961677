import React from 'react';
import PropTypes from 'prop-types';

import { Loading } from '../Helpers/Loading';

const LOADING_STLYE = { height: '840px', cursor: 'progress' };

export const PlaceholderContainer = ({ style, children }) => (
  <div className="ad-preview-wrapper">
    {children}
  </div>
);

PlaceholderContainer.defaultProps = {
  style: {},
  children: null,
};

PlaceholderContainer.propTypes = {
  style: PropTypes.shape({ width: PropTypes.number }),
  children: PropTypes.node,
};

export const Placeholder = () => (
  <div className="ad-preview-wrapper">
    <Loading />
  </div>
);

export const DeriveOptions = ({ previews, children }) => children(
  previews.map(({ type, preview }, i) => ({
    label: type,
    value: preview,
    index: i,
  })),
);

export function deriveOptions(previews) {
  return previews.map(({ type, preview }, i) => ({
    label: type,
    value: preview,
    index: i,
  }));
}
