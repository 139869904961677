import React from 'react';
import { BrowserIcon } from '../../icons/ToolbarIcons';
import { CloseIcon } from '../../../../../components/Icons';

type OverlayToolbar = {
  openVisualsBrowser: () => void;
  handleClose: () => void;
  isSubDrawer: boolean;
};

export const OverlayToolbar = ({
  openVisualsBrowser,
  handleClose,
  isSubDrawer,
}) => {
  return (
    <div className="overlay-toolbar">
      <div className="overlay-toolbar__left">
        {isSubDrawer ?? (
          <button className="overlay-toolbar__btn" onClick={openVisualsBrowser}>
            <BrowserIcon />
          </button>
        )}
      </div>
      <div className="overlay-toolbar__right">
        <button className="overlay-toolbar__btn" onClick={handleClose}>
          <CloseIcon width='20' height='20' fill="#fff" />
        </button>
      </div>
    </div>
  );
};
