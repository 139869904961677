export const findPerformanceScore = (visual, custom_performance_scores) => {
  if (!custom_performance_scores?.scores) return null;
  
  return custom_performance_scores.scores.reduce((result, current) => {
    const { score, conditions } = current;
    const predicates = getPredicates(conditions);

    if (
      predicates.every((meetsCondition) => meetsCondition(visual))
    ) {
      result = score;
    }

    return result;
  }, null);
};

const getPredicates = (conditions) => {
  return conditions.reduce((acc, { metric, comparison, value }) => {
    if (!metric || !comparison) return acc;

    try {
      if (value) {
        acc.push((visual) => {
          const visualMetricValue = visual[metric];

          switch (comparison) {
            case 'greater':
              return visualMetricValue > value;
            case 'greater_or_equal':
              return visualMetricValue >= value;
            case 'less':
              return visualMetricValue < value;
            case 'less_or_equal':
              return visualMetricValue <= value;
            case 'equal':
              return visualMetricValue === value;
            default:
              return false;
          }
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      return acc;
    }
  }, []);
};

