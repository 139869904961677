export const DISABLED_CATEGORIES = [
  'Colors',
  'Essentials',
  'Execution Styles',
  'Inspiration',
  'Number of People INACTIVE',
  'People',
  'People Traits',
  'People vs. No People',
  'Primary Phrase',
  'Primary Phrase Font Size',
  'Primary Phrase Position',
  'Secondary Phrase',
  'Secondary Phrase Font Size',
  'Secondary Phrase Position',
  'Tertiary Phrase Font Size',
  'Tertiary Phrase Position',
  'Text Hierarchy',
  'Text Position',
  'Text Treatment',
  'Trending',
  'Word Count',
  'Brand Font Size',
  'derived category',
];
