import format from 'date-fns/format';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import { parse } from 'date-fns/esm';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import dayjs from 'dayjs';

const moment = require('moment');
/**
 * moment.js is not well supported by newer libraries
 */
const DATE_FORMAT = 'yyyy-MM-dd';
const QUERY_DATE_FORMAT = 'YYYY-MM-DD';
const DATE_FORMAT_RX = /\d{4}-\d{2}-\d{2}/;
const CHICAGO_TZ = 'America/Chicago';

/**
 * @param {Date} date
 */
export const toDarwinDate = (date) => format(date, DATE_FORMAT);
// formats ISO to yyyy-MM-dd
// ex: "Wed Mar 08 2023 09:20:22 GMT-0600 (Central Standard Time)" => "2023-03-08"
/**
 * @param {string} d
 */
export const darwinDateToDate = (d) => parse(d, DATE_FORMAT, new Date());
// formats yyyy-MM-dd to ISO
// ex: "2023-03-08" => "Wed Mar 08 2023 09:20:22 GMT-0600 (Central Standard Time)"
/**
 * @param {string} d
 */
export const isValidDarwinDate = (d) =>
  DATE_FORMAT_RX.test(d) && new Date(d).toString() !== 'Invalid Date';

/**
 * @param {string} dateStr
 * @param {string} formatAs
 *
 * @return {string}
 */
export const darwinDateToFormat = (dateStr, formatAs) =>
  format(parse(dateStr, DATE_FORMAT, new Date()), formatAs);
// ex: darwinDateToFormat("2023-03-08", "MM/dd/yyyy") => 03/08/2023

export const NOW = new Date();
export const END = subDays(NOW, 1);
export const START = subDays(NOW, 7);
export const START_30 = subDays(NOW, 30);
export const START_90 = subDays(NOW, 90);
export const LAST_MONTH = dayjs().month(dayjs().month() - 1).$d;

/**
 * @param {Date} date
 * @param {number} days
 */
export const daysFrom = (date, days) => {
  const calc = days < 0 ? subDays : addDays;

  return calc(date, Math.abs(days));
};

export function dateInChicago(d) {
  const currentUtc = zonedTimeToUtc(d);

  return utcToZonedTime(currentUtc, CHICAGO_TZ);
}

export const YESTERDAY = moment
  .utc()
  .utcOffset(-360)
  .subtract(1, 'day')
  .format(QUERY_DATE_FORMAT);
const SEVEN_DAYS_AGO = moment
  .utc()
  .utcOffset(-360)
  .subtract(7, 'days')
  .format(QUERY_DATE_FORMAT);
const EIGHT_DAYS_AGO = moment
  .utc()
  .utcOffset(-360)
  .subtract(8, 'days')
  .format(QUERY_DATE_FORMAT);
const FOURTEEN_DAYS_AGO = moment
  .utc()
  .utcOffset(-360)
  .subtract(14, 'days')
  .format(QUERY_DATE_FORMAT);
const FIFTEEN_DAYS_AGO = moment
  .utc()
  .utcOffset(-360)
  .subtract(15, 'days')
  .format(QUERY_DATE_FORMAT);
const TWENTY_EIGHT_DAYS_AGO = moment
  .utc()
  .utcOffset(-360)
  .subtract(28, 'days')
  .format(QUERY_DATE_FORMAT);
export const THIRTY_DAYS_AGO = moment
  .utc()
  .utcOffset(-360)
  .subtract(30, 'days')
  .format(QUERY_DATE_FORMAT);

export const DATE_PAIRS = [
  { label: 'Yesterday', value: [END, END] },
  { label: 'Last 7 Days', value: [START, END] },
  { label: 'Last 28 Days', value: [subDays(new Date(), 28), END] },
  { label: 'Last 30 Days', value: [subDays(new Date(), 30), END] },
  { label: 'Last 6 Weeks', value: [subDays(new Date(), 42), END] },
  { label: 'This Month', value: [startOfMonth(new Date()), END] },
  {
    label: 'Last Month',
    value: [startOfMonth(LAST_MONTH), endOfMonth(LAST_MONTH)],
  },
];

export const PF_DATE_PAIRS = [
  { label: 'Yesterday', value: [END, END] },
  { label: 'Last 7 Days', value: [START, END] },
  // {
  //   label: 'Previous 7 Days',
  //   value: [subDays(new Date(), 14), subDays(new Date(), 8)],
  // },
  { label: 'Last 30 Days', value: [subDays(new Date(), 30), END] },
  // {
  //   label: 'Previous 30 Days',
  //   value: [subDays(new Date(), 60), subDays(new Date(), 31)],
  // },
  { label: 'This Month', value: [startOfMonth(new Date()), END] },
  {
    label: 'Last Month',
    value: [startOfMonth(LAST_MONTH), endOfMonth(LAST_MONTH)],
  },
  // { label: 'Last 90 Days', value: [subDays(new Date(), 90), END] },
];

const addStartTimeString = (dateString) => `${dateString}T00:00:00.000`;

const addEndTimeString = (dateString) => `${dateString}T23:59:59.999`;

const DATE_PAIRS_QUERIES = [
  ['Yesterday', [addStartTimeString(YESTERDAY), addEndTimeString(YESTERDAY)]],
  [
    'Last 7 days',
    [addStartTimeString(SEVEN_DAYS_AGO), addEndTimeString(YESTERDAY)],
  ],
  [
    'Last 14 days',
    [addStartTimeString(FOURTEEN_DAYS_AGO), addEndTimeString(YESTERDAY)],
  ],
  [
    'Last 30 days',
    [addStartTimeString(THIRTY_DAYS_AGO), addEndTimeString(YESTERDAY)],
  ],
  [
    'Previous 7 days',
    [addStartTimeString(FOURTEEN_DAYS_AGO), addEndTimeString(EIGHT_DAYS_AGO)],
  ],
  [
    'Previous 14 days',
    [
      addStartTimeString(TWENTY_EIGHT_DAYS_AGO),
      addEndTimeString(FIFTEEN_DAYS_AGO),
    ],
  ],
];

export const adjustDateRangeWithTimezone = (rangeString) => {
  const maybe = DATE_PAIRS_QUERIES.find(([key]) => rangeString === key);

  return maybe ? maybe[1] : rangeString;
};
