import { useRef, useEffect } from 'react';
import { abortableFetch } from '../../../utils';
import { toast } from 'react-toastify';

import {
  useApiState,
  bindFetchToRef,
} from '../../../components/Hooks/useApiState';
import { notifyError, notifySaving, notifySuccess } from './RequestRevision/utils';

const HEADERS = { 'content-type': 'application/json' };

function postRevisionRequest(payload) {
  return abortableFetch(
    ['api', 'creative-analytics', 'revision-request'].join(
      '/'
    ),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useRevisionRequestApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(postRevisionRequest(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  const handlePost = (payload) => {
    postDispatch(payload);
  }

  useEffect(() => {
    if (postState.loading) {
      notifySaving();
    }
  }, [postState.loading]);

  useEffect(() => {
    if (postState.error) {
      notifyError(postState.error.error);
      toast.dismiss('revision-request-loading');
    }
  }, [postState.error]);

  useEffect(() => {
    if (postState.data) {
      notifySuccess();
      toast.dismiss('revision-request-loading');
    }
  }, [postState.data]);

  return {
    ...postState,
    request: handlePost,
  };
}

function getNewPhrases(payload) {
  return abortableFetch(
    ['api', 'creative-analytics', 'get-new-phrases'].join(
      '/'
    ),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useNewPhrasesApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(getNewPhrases(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  const handlePost = (payload) => {
    postDispatch(payload);
  }

  return {
    ...postState,
    request: handlePost,
  };
}

function getGeneralSuggestions(payload) {
  return abortableFetch(
    ['api', 'creative-analytics', 'get-general-suggestions'].join(
      '/'
    ),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useGetGeneralSuggestionsApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(getGeneralSuggestions(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  const handlePost = (payload) => {
    postDispatch(payload);
  }

  return {
    ...postState,
    request: handlePost,
  };
}

function postFavoriteSuggestions(payload) {
  return abortableFetch(
    ['api', 'creative-analytics', 'post-favorite-suggestions'].join(
      '/'
    ),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useFavoriteSuggestionsApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (payload) => withRef(postFavoriteSuggestions(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(postFavoriteSuggestions(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );
  const [deleteState, deleteDispatch] = useApiState(
    (payload) => withRef(postFavoriteSuggestions(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    get: {
      ...getState,
      request: getDispatch,
    },
    post: {
      ...postState,
      request: postDispatch,
    },
    delete: {
      ...deleteState,
      request: deleteDispatch,
    }
  };
}

function getEasySuggestions(payload) {
  return abortableFetch(
    ['api', 'creative-analytics', 'get-easy-suggestions'].join(
      '/'
    ),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useGetEasySuggestionsApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(getEasySuggestions(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  const handlePost = (payload) => {
    postDispatch(payload);
  }

  return {
    ...postState,
    request: handlePost,
  };
}
