import {
  FORBIDDEN_TESTING_OPPORTUNITIES,
  TESTING_OPPORTUNITY_CATEGORIES,
} from '../../../categories';
import { notInData } from '../../../filter-utils';

const TESTING_OPPORTUNITY_SET = new Set(TESTING_OPPORTUNITY_CATEGORIES);
const FORBIDDEN_TESTING_OPPORTUNITIES_SET = new Set(
  FORBIDDEN_TESTING_OPPORTUNITIES
);

export const shouldAddTestingOpportunity = (
  label,
  data,
  handleFilterVisuals,
  dataFormatsSet,
  filteredVisualIds
) => {
  if (
    !TESTING_OPPORTUNITY_SET.has(label.category) &&
    !label.compliance &&
    ![
      'client_custom_category',
      'user_defined_labels',
      'derived_label',
      'Ad Name Label',
      'ad_name_label',
    ].includes(label.type)
  ) {
    return false;
  }

  const isStandardLabel = !label.relevant_visuals;
  if (isStandardLabel) {
    return notInData(label, data);
  }

  const visualIdsInData = label.relevant_visuals.filter((id) =>
    filteredVisualIds.includes(id)
  );

  if (visualIdsInData.length < 1) return true;

  const isFormatNoVisuals =
    dataFormatsSet.has(label.value) &&
    data.some(
      (obj) =>
        obj.value === label.value &&
        obj.winners.length === 0 &&
        obj.losers.length === 0
    );

  return (
    isFormatNoVisuals && !FORBIDDEN_TESTING_OPPORTUNITIES_SET.has(label.value)
  );
};

export const getPossibleTestingLabels = (standardLabels, data) => [
  ...standardLabels,
  ...data.filter(
    (label) =>
      [
        'client_custom_category',
        'user_defined_labels',
        'derived_label',
        'derived_category',
      ].includes(label.type) || label.compliance
  ),
];

export const getTestingOpportunities = (
  data,
  standardLabels,
  handleFilterVisuals,
  categoryFilters,
  filteredVisualIds
) => {
  let testingOpportunities = [];

  try {
    if (!categoryFilters?.showTestingOpportunities) {
      return [];
    }

    const dataFormatsSet = new Set(
      data
        .filter((obj) => ['IMAGE+', 'CAROUSEL+', 'VIDEO+'].includes(obj.value))
        .map((obj) => obj.value)
    );

    const possibleTestingLabels = getPossibleTestingLabels(
      standardLabels,
      data
    );

    if (possibleTestingLabels.length) {
      possibleTestingLabels.forEach((label) => {
        if (
          shouldAddTestingOpportunity(
            label,
            data,
            handleFilterVisuals,
            dataFormatsSet,
            filteredVisualIds
          )
        ) {
          testingOpportunities.push({
            ...label,
            name: label.label || label.name,
            winners: [],
            losers: [],
            visuals: [],
            performance_percent: null,
            numerator: null,
            denominator: null,
            synonym: false,
            testing_opportunity: true,
          });
        }
      });
    }
  } catch (e) {
    console.log('Error finding testing opportunities');
    console.error(e);
  } finally {
    return testingOpportunities;
  }
};
