import React, { useEffect, useState, useMemo } from "react";
import "./CommonElements.scss";
import {
  ApproxEqualIcon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from "../../ReportCard/icons";
import { ErrorIcon } from "../../../../components/Icons";
import { RetryButton } from "../components/RetryButton";

const CommonElements = ({ commonElementsProps, fetchCommonElements }) => {
  const winnersData = {
    type: "Above",
    elements: commonElementsProps.winnersCommonElements,
    loading: commonElementsProps.winnersLoading,
    error: commonElementsProps.winnersError,
    ids: commonElementsProps.winnerIds,
  };

  const losersData = {
    type: "Below",
    elements: commonElementsProps.losersCommonElements,
    loading: commonElementsProps.losersLoading,
    error: commonElementsProps.losersError,
    ids: commonElementsProps.loserIds,
  };

  const allAssetsData = {
    type: "All",
    elements: commonElementsProps.allCommonElements,
    loading: commonElementsProps.allAssetsLoading,
    error: commonElementsProps.allAssetsError,
    ids: [...commonElementsProps.loserIds, ...commonElementsProps.winnerIds],
  }

  return (
    <div className="common-elements">
      <CommonElementsSection {...{ ...winnersData, fetchCommonElements }} />
      <CommonElementsSection {...{ ...losersData, fetchCommonElements }} />
      <CommonElementsSection {...{ ...allAssetsData, fetchCommonElements }} />
    </div>
  );
};

export default CommonElements;

const CommonElementsSection = ({
  type,
  elements,
  loading,
  error,
  ids,
  fetchCommonElements,
}) => {
  let Render = (
    <div className="d-flex flex-column">
      <div className="common-elements__section-top">
        <div className={`common-elements__icon common-elements__icon--${type}`}>
          {type === "Above" && <ChevronDoubleUpIcon fill={"#0277BD"} />}
          {type === "Below" && <ChevronDoubleDownIcon fill={"#ad5660"} />}
          {type === "All" && <ApproxEqualIcon fill={"#4a9281"} />}
        </div>
        <div className="common-elements__title">
          Common Elements <span>{`${type !== "All" ? `${type} Average` : "All Assets"}`}</span>
        </div>
      </div>
      {elements.length && ids.length ? (
        <div>
          <ul className="common-elements__list">
            {elements.map((el) => {
              return (
                <li className="common-elements__list-item">
                  {el.replace("FX_", "")}
                </li>
              );
            })}
          </ul>
        </div>
      ) : !elements.length && ids.length ? (
        <div>
          Your {`${type !== "All" ? `${type.toLowerCase()} average ` : ""}`}assets don't share any elements.
        </div>
      ) : (
        <div>
          No {`${type !== "All" ? `${type.toLowerCase()} average ` : ""}`}assets selected.
        </div>
      )}
    </div>
  );

  if (loading) {
    Render = <div>Loading...</div>;
  }

  if (error && ids.length) {
    Render = (
      <div className="d-flex align-items-center">
        <ErrorIcon className="mr-2" /> There was an error.{" "}
        <RetryButton fn={fetchCommonElements} />
      </div>
    );
  }

  return <div className="common-elements__section">{Render}</div>;
};
