import React, { useState } from 'react';
import './Recommendations.scss';
import { TryThese } from './Recommendations';
import { useCreativeAnalyticsGet } from '../contexts';

const Wrapper = ({
  filteredData,
  recsTab,
  only_new_ads,
  trending,
  executionStyles,
  inspiration,
  show_request,
  account_id,
  view_id,
  activeTTSection,
  setActiveTTSection,
  fromDraftCreative,
  handleBlockValue,
  handleBlockVisualType,
}) => {
  const [appliedFilter, setAppliedFilter] = useState(null);
  const { requestWidth } = useCreativeAnalyticsGet();
  const maxWidth = { maxWidth: `calc(100vw - (${requestWidth}px + 50px))` };

  const notInData = (standardLabel, filteredLabels) => {
    let exists = false;
    let matchesFilter = false;

    if (filteredLabels.length) {
      exists = filteredLabels.some(
        (label) => label.label_id === standardLabel.label_id
      );

      matchesFilter =
        !!appliedFilter &&
        ((Array.isArray(standardLabel.example) &&
          standardLabel.example.some(
            (asset) => asset.type !== appliedFilter
          )) ||
          (!Array.isArray(standardLabel.example) &&
            standardLabel.example.type !== appliedFilter));
    }
    return !exists && !matchesFilter;
  };

  const tryTheseTrending = () =>
    trending.filter((trendingLabel) => notInData(trendingLabel, filteredData));

  const tryTheseInspiration = () => {
    const inspirationLabels = inspiration;
    const executionStylesLabels = executionStyles.filter(
      (label) => label.status === 'active'
    );

    const merged = [...inspirationLabels, ...executionStylesLabels];

    return merged.filter((standardLabel) =>
      notInData(standardLabel, filteredData)
    );
  };

  const handleSetFilter = (type) => {
    setAppliedFilter(type);
  };

  const recProps = {
    trending,
    only_new_ads,
    account_id,
    view_id,
    appliedFilter,
    handleSetFilter,
    activeTTSection,
    setActiveTTSection,
    fromDraftCreative,
    handleBlockValue,
    handleBlockVisualType,
  };

  return (
    <div
      className={`recommendations-wrapper ${
        show_request ? 'show-request' : ''
      }`}
      id="asset-chat-wrapper"
      style={maxWidth}
    >
      <TryThese
        tab={recsTab}
        trendingRecs={tryTheseTrending(filteredData)}
        inspirationRecs={tryTheseInspiration(filteredData)}
        {...recProps}
      />
    </div>
  );
};

export default Wrapper;
