import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import Button from '../../../components/Button';
import './CreativeRequest.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { hasRoles } from '../../../cookies';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';

import Overlay from '../../../components/Overlay';
import ProjectSetup from './ProjectSetup';
import { TagsList, Tag } from '../../../components/Tags';

import { useDispatch, useSelector } from 'react-redux';

import { RefreshIcon, CopyIcon, ArrowIcon, SaveIcon } from './Helpers';

import {
  useCreativeRequestApi,
  useGetSavedRequestApi,
  useSaveCreativeRequestApi,
} from './api';

import { SuccessScreen } from '../../Marketplace/components/AssetUpload/CreativeRequest/CreativeRequest';

import {
  DEFAULT_REQUEST,
  RATIO,
  FORMAT,
  CARDS,
  REQUEST_OVERVIEW,
  PRIMARY_TEXT,
  SECONDARY_TEXT,
  INCLUDE_THESE,
  AVOID_THESE,
  RATIO_OPTIONS,
  FORMAT_OPTIONS,
  CARDS_OPTIONS,
  PRIMARY_POSITION,
  SECONDARY_POSITION,
  REQUEST_ID,
  REQUEST_DESCRIPTION,
  CONTRACTOR_NAMES,
  CONTRACTOR_ONE,
  CONTRACTOR_TWO,
  ASSET_NAME,
  quillFormats,
  quillFormats2,
  quillModules,
  quillModules2,
  DATA_POINTS,
  CREATIVE_CODE,
  DARWIN_TALENT,
  CUSTOM_EMAIL,
  RECIPIENT,
  DEFAULT_STATUS,
  CUSTOM_CREATOR,
} from './constants';

import { useCreativeRequestGet, useCreativeRequestSet } from './contexts';
import RequestNav from './RequestNav';
import RoleAccess from '../../../components/RoleAccess';
import { withTryThese } from './utils';
import { trackEvent } from '../../../store/UserActivity/actions';
import {
  CREATIVE_ANALYTICS,
  CREATIVE_STUDIO,
  SUBMIT_REQUEST,
} from '../../../store/UserActivity/constants';
import { DataPoints } from './DataPoints/DataPoints';
import { generateRandomNumberString } from '../../../utils/numbers';
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from '../contexts';
import { ChevronDoubleRightIcon } from './icons';
import Tooltip from '../../../components/Tooltip';
import { useCreativeAnalyticsApis } from '../api';
import { useCopilotPayload } from '../hooks/useCopilotPayload';
import { useCategories } from '../hooks';
import Wizard from './Wizard';
import WizardToggle from './WizardToggle';
import { useScrollIntoView } from '../../../hooks/useScrollIntoView';
import SparkleIcon from './SparkleIcon';
import { OVERVIEW } from '../ReportCard/constants';

const generateCreativeCode = () => `DWAI${generateRandomNumberString(6)}`;

const getPayload = (account_id, view_id, requests, descriptions, overviews) => {
  const creative_requests = requests
    .map((req, i) => ({
      ...req,
      request_overview: overviews[i],
      request_description: descriptions[i],
    }))
    .filter((req, i) => {
      return !_.isEqual(req, DEFAULT_REQUEST);
    });

  return {
    account_id,
    view_id,
    creative_requests: creative_requests.map((req) => ({
      creative_request_id: req[REQUEST_ID] || '',
      ratio: req[RATIO],
      format: req[FORMAT],
      cards: req[CARDS],
      request_overview: req[REQUEST_OVERVIEW],
      request_description: req[REQUEST_DESCRIPTION],
      primary_text: {
        text: req[PRIMARY_TEXT],
        text_position: req[PRIMARY_POSITION],
      },
      secondary_text: {
        text: req[SECONDARY_TEXT],
        text_position: req[SECONDARY_POSITION],
      },
      include_these: req[INCLUDE_THESE],
      avoid_these: req[AVOID_THESE],
      ...(req[DATA_POINTS] && { data_points: req[DATA_POINTS] }),
      creative_code: req[CREATIVE_CODE] || generateCreativeCode(),
      custom_email:
        (req[CUSTOM_EMAIL].length && req[CUSTOM_EMAIL].split(',')) || [],
      read_only_url:
        (req[CUSTOM_EMAIL].length &&
          `https://dashboard.darwinsoftware.io/creative-requests/${req[REQUEST_ID]}`) ||
        '',
    })),
  };
};

const getSavedPayload = (
  account_id,
  view_id,
  requests,
  descriptions,
  overviews
) => {
  const creative_requests = requests
    .map((req, i) => ({
      ...req,
      request_overview: overviews[i] || '',
      request_description: descriptions[i] || '',
    }))
    .filter((req) => {
      return !_.isEqual(req, DEFAULT_REQUEST); // dont include invalid request
    });

  return {
    account_id,
    view_id,
    creative_requests: creative_requests.map((req) => ({
      asset_name: req[ASSET_NAME] || '',
      contractor_names: [req[CONTRACTOR_ONE], req[CONTRACTOR_TWO]].filter(
        (str) => str.length
      ),
      request_id: req[REQUEST_ID] || '',
      ratio: req[RATIO],
      format: req[FORMAT],
      cards: req[CARDS],
      request_overview: req[REQUEST_OVERVIEW],
      request_description: req[REQUEST_DESCRIPTION],
      primary_text: {
        text: req[PRIMARY_TEXT],
        text_position: req[PRIMARY_POSITION],
      },
      secondary_text: {
        text: req[SECONDARY_TEXT],
        text_position: req[SECONDARY_POSITION],
      },
      include_these: req[INCLUDE_THESE],
      avoid_these: req[AVOID_THESE],
      ...(req[DATA_POINTS] && { data_points: req[DATA_POINTS] }),
      creative_code: req[CREATIVE_CODE] || generateCreativeCode(),
      custom_email:
        (req[CUSTOM_EMAIL].length && req[CUSTOM_EMAIL].split(',')) || [],
    })),
  };
};

const CreativeRequest = ({ open, ratioRate, formatRate }) => {
  const [successUrl, setSuccessUrl] = useState(false);
  const [currentSaved, setCurrentSaved] = useState([]);
  const [savedOverviews, setSavedOverviews] = useState([]);
  const [savedDescriptions, setSavedDescriptions] = useState([]);
  const [showProjectSetup, setShowProjectSetup] = useState(false);
  const [contractorOptions, setContractorOptions] = useState([]);
  const [allContractors, setAllContractors] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(DEFAULT_STATUS);
  const [saveStatus, setSaveStatus] = useState(DEFAULT_STATUS);
  const { requests, currentIndex, currentRequest, descriptions, overviews } =
    useCreativeRequestGet();
  const dispatch = useDispatch();
  const { isCopied, copyToClipboard } = useCopyToClipboard();
  const { setRequests, removeLabel, setDescriptions, setOverviews } =
    useCreativeRequestSet();
  const { setRequestOpen } = useCreativeAnalyticsSet();
  const { filteredData } = useCreativeAnalyticsGet();
  const [showWizard, setShowWizard] = useState(false);
  const { copilotApi } = useCreativeAnalyticsApis();
  const {
    data: copilotData,
    loading: copilotLoading,
    error: copilotError,
    request: copilotRequest,
  } = copilotApi.post;

  const copilotRequestSuccess =
    copilotData && copilotData.recommendation_summary;
  const copilotRequestError =
    copilotError || (copilotData && !copilotData.recommendation_summary);

  const descriptionRef = useRef();
  const [descriptionUpdated, setDescriptionUpdated] = useState(false);
  const [wizard, setWizard] = useState([
    {
      additionalInstructions: '',
      previousOutput: '',
      userFeedback: '',
    },
    {
      additionalInstructions: '',
      previousOutput: '',
      userFeedback: '',
    },
    {
      additionalInstructions: '',
      previousOutput: '',
      userFeedback: '',
    },
    {
      additionalInstructions: '',
      previousOutput: '',
      userFeedback: '',
    },
  ]);

  useEffect(() => {
    if (!open) setShowWizard(false);
  }, [open]);

  useEffect(() => {
    if (copilotRequestSuccess) {
      handleDescription(copilotData.recommendation_summary, null, 'user');
      let update = wizard.map((w, i) =>
        i === currentIndex
          ? { ...w, previousOutput: copilotData.recommendation_summary }
          : w
      );

      if (wizard[currentIndex].userFeedback.length) {
        update = wizard.map((w, i) =>
          i === currentIndex
            ? { ...w, userFeedback: '', additionalInstructions: w.userFeedback }
            : w
        );
      }

      setWizard(update);
      setDescriptionUpdated(true);
      const timer = setTimeout(() => setDescriptionUpdated(false), 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [copilotRequestSuccess]);

  const handleInstructions = (value) => {
    let update = wizard.map((w, i) =>
      i === currentIndex ? { ...w, additionalInstructions: value } : w
    );

    setWizard(update);
  };

  const handleFeedback = (value) => {
    let update = wizard.map((w, i) =>
      i === currentIndex ? { ...w, userFeedback: value } : w
    );

    setWizard(update);
  };

  useScrollIntoView(descriptionRef, descriptionUpdated, {
    behavior: 'smooth',
    block: 'center',
  });
  const { planningMode, coreVisuals, requestWidth } = useCreativeAnalyticsGet();
  const { setCoreVisuals, setRequestWidth } = useCreativeAnalyticsSet();
  const ref = useRef(null);
  const [isResizing, setIsResizing] = useState(false);
  const minRequestWidth = 400;

  const startResizing = useCallback((mouseDownEvent) => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent) => {
      const newWidth =
        ref.current.getBoundingClientRect().right - mouseMoveEvent.clientX;
      if (isResizing && newWidth >= minRequestWidth) {
        setRequestWidth(newWidth);
      }
    },
    [isResizing]
  );

  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  const include = currentRequest[INCLUDE_THESE];
  const avoid = currentRequest[AVOID_THESE];
  const [previousVisuals, setPreviousVisuals] = useState({
    0: { urls: [], types: [], html: '' },
    1: { urls: [], types: [], html: '' },
    2: { urls: [], types: [], html: '' },
    3: { urls: [], types: [], html: '' },
  });
  const overview = overviews[currentIndex];
  const description = descriptions[currentIndex];

  const updateCoreVisuals = (userText) => {
    const update = [...overviews].map((overview, i) => {
      if (i === currentIndex) {
        return coreVisuals[`${currentIndex}`].html + userText;
      }

      return overview;
    });

    // set the overview to the new urls html
    setOverviews(update);
    setPreviousVisuals(coreVisuals);
  };

  useEffect(() => {
    const userText = overview.replace(
      previousVisuals[`${currentIndex}`].html,
      ''
    );
    updateCoreVisuals(userText);
  }, [coreVisuals]);

  useEffect(() => {
    handleSelect(RECIPIENT, CUSTOM_CREATOR);
  }, [currentRequest[CONTRACTOR_ONE], currentRequest[CONTRACTOR_TWO]]);

  const creativeCode = useMemo(() => {
    return (
      currentRequest[CREATIVE_CODE] || `DWAI${generateRandomNumberString(6)}`
    );
  }, [currentRequest[CREATIVE_CODE], currentIndex]);

  const handleCopyCreativeCode = useCallback(() => {
    copyToClipboard(creativeCode);
  }, [copyToClipboard]);

  const { account_id, view_id, only_new_ads } = useSelector(
    ({
      ViewSettings: { account_id, viewId },
      CreativeReports: { only_new_ads },
    }) => ({
      account_id,
      view_id: viewId,
      only_new_ads,
    })
  );

  const creativeRequestApi = useCreativeRequestApi();

  const saveCreativeRequest = useSaveCreativeRequestApi();
  const getSavedRequest = useGetSavedRequestApi();

  const selectedContractors = useMemo(() => {
    let result = [];
    let distinctContractors = new Set();

    requests.forEach((request, index) => {
      const { contractor_one, contractor_two } = request;
      const contractors = [contractor_one, contractor_two];

      contractors.forEach((contractor) => {
        if (contractor.length) {
          if (!distinctContractors.has(contractor)) {
            result.push({ name: contractor, assignedRequests: [index] });
          } else {
            const foundIndex = result.findIndex(
              (obj) => obj.name === contractor
            );
            const found = result[foundIndex];
            result[foundIndex].assignedRequests = [
              ...found.assignedRequests,
              index,
            ];
          }

          distinctContractors.add(contractor);
        }
      });
    });

    return result;
  }, [requests]);

  // Submit request
  useEffect(() => {
    if (creativeRequestApi.post.data) {
      setSubmitStatus({
        error: false,
        message: 'Request submitted successfully.',
      });
      setSaveStatus(DEFAULT_STATUS);
      handleRefreshAll();
    }
  }, [creativeRequestApi.post.data]);

  const handleSubmit = () => {
    const postPayload = getPayload(
      account_id,
      view_id,
      withTryThese(requests),
      descriptions,
      overviews
    );

    const { reqsMissingEmail, reqsMissingRatio } = checkRequiredFields();

    if (reqsMissingRatio.length) {
      setSubmitStatus({
        error: true,
        message: `Must select a ratio (Request${
          reqsMissingRatio.length > 1 ? 's' : ''
        } ${reqsMissingRatio.join(', ')})`,
      });
    } else if (reqsMissingEmail.length) {
      setSubmitStatus({
        error: true,
        message: `No email address entered (Request${
          reqsMissingEmail.length > 1 ? 's' : ''
        } ${reqsMissingEmail.join(', ')})`,
      });
    } else if (
      hasRoles(['internal']) &&
      requests.some((req) => req[RECIPIENT] === CUSTOM_CREATOR)
    ) {
      if (selectedContractors.length) {
        setShowProjectSetup(true);
        setSubmitStatus(DEFAULT_STATUS);
      } else {
        setSubmitStatus({
          error: true,
          message: 'Must select at least one contractor.',
        });
      }
    } else {
      setSubmitStatus(DEFAULT_STATUS);
      dispatch(trackEvent(CREATIVE_STUDIO, SUBMIT_REQUEST));
      creativeRequestApi.post.request(postPayload);
    }
  };

  useEffect(() => {
    if (creativeRequestApi.post.error) {
      setSubmitStatus({
        error: true,
        message: creativeRequestApi.post.error.error,
      });
    }
  }, [creativeRequestApi.post.error]);

  useEffect(() => {
    if (creativeRequestApi.post.loading) {
      setSubmitStatus({
        error: false,
        message: 'Sending request...',
      });
    }
  });

  const checkRequiredFields = () => {
    let reqsMissingRatio = [];
    let reqsMissingEmail = [];

    requests.forEach((req) => {
      if (!_.isEqual(req, DEFAULT_REQUEST)) {
        if (Object.values(req.ratio).every((value) => value === 0)) {
          reqsMissingRatio.push(requests.indexOf(req) + 1);
        }
        if (req[RECIPIENT] === CUSTOM_EMAIL && !req[CUSTOM_EMAIL].length) {
          reqsMissingEmail.push(requests.indexOf(req) + 1);
        }
      }
    });

    return {
      reqsMissingRatio,
      reqsMissingEmail,
    };
  };

  const filterContractors = (data) => {
    return data.filter(({ designer_type }) => {
      if (
        designer_type === 'designer' &&
        ['Image', 'Carousel'].includes(currentRequest[FORMAT])
      )
        return true;
      if (designer_type === 'animator' && currentRequest[FORMAT] === 'Video')
        return true;
    });
  };

  useEffect(() => {
    setContractorOptions(filterContractors(allContractors));
  }, [currentRequest[FORMAT]]);

  useEffect(() => {
    const fetchData = async () => {
      await fetch(['api', 'marketplace', 'options'].join('/'), {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })
        .then((res) => res.json())
        .then(({ data }) => {
          setAllContractors(data.contractor_options);
          setContractorOptions(filterContractors(data.contractor_options));
        })
        .catch((error) => console.log(error));
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (saveCreativeRequest.post.error) {
      setSaveStatus({
        error: true,
        message: saveCreativeRequest.post.error.error,
      });
    }
  }, [saveCreativeRequest.post.error]);

  const handleSave = () => {
    if (!unsavedChanges) return;

    const postSavedPayload = getSavedPayload(
      account_id,
      view_id,
      requests,
      descriptions,
      overviews
    );

    saveCreativeRequest.post.request(postSavedPayload);
    updateCurrentSaved();
  };

  const updateCurrentSaved = () => {
    setCurrentSaved(requests);
    setSavedDescriptions(descriptions);
    setSavedOverviews(overviews);
  };

  useEffect(() => {
    getSavedRequest.get.request();
  }, []);

  // Populate requests object
  useEffect(() => {
    if (getSavedRequest.get.data) {
      let { data } = getSavedRequest.get;
      data = JSON.parse(data);

      if (data.length) {
        const creative_requests = data.map((req) => ({
          creative_request_id: req[REQUEST_ID],
          ratio: req[RATIO] || [],
          format: req[FORMAT] || '',
          cards: req[CARDS] || 2,
          request_overview: req[REQUEST_OVERVIEW] || '',
          request_description: req[REQUEST_DESCRIPTION] || '',
          primary_text: req.primary_text.text,
          primary_position: req.primary_text.text_position,
          secondary_text: req.secondary_text.text,
          secondary_position: req.secondary_text.text_position,
          include_these: req[INCLUDE_THESE] || [],
          avoid_these: req[AVOID_THESE] || [],
          asset_name: req[ASSET_NAME] || '',
          contractor_one:
            (req[CONTRACTOR_NAMES] && req[CONTRACTOR_NAMES][0]) || '',
          contractor_two:
            (req[CONTRACTOR_NAMES] && req[CONTRACTOR_NAMES][1]) || '',
          data_points: req[DATA_POINTS] || [],
          creative_code:
            req[CREATIVE_CODE] || `DWAI${generateRandomNumberString(6)}`,
          custom_email:
            (req[CUSTOM_EMAIL] && req[CUSTOM_EMAIL].join(', ')) || '',
          recipient: req[RECIPIENT]
            ? req[RECIPIENT]
            : req[CONTRACTOR_NAMES] && hasRoles(['internal'])
            ? CUSTOM_CREATOR
            : DARWIN_TALENT,
        }));
        const desc = data.map((req) => {
          return req[REQUEST_DESCRIPTION] || '';
        });
        const ov = data.map((req) => {
          return req[REQUEST_OVERVIEW] || '';
        });

        while (data.length < 4 && creative_requests.length < 4) {
          creative_requests.push(DEFAULT_REQUEST);
          desc.push('');
          ov.push('');
        }

        setRequests(creative_requests);
        setDescriptions(desc);
        setOverviews(ov);
        setCurrentSaved(creative_requests);
        setSavedDescriptions(desc);
        setSavedOverviews(ov);
      }
    }
  }, [getSavedRequest.get.data]);

  useEffect(() => {
    // Changes have been made and saved
    if (
      _.isEqual(requests, currentSaved) &&
      saveCreativeRequest.post.data &&
      _.isEqual(descriptions, savedDescriptions) &&
      _.isEqual(overviews, savedOverviews)
    ) {
      setSaveStatus({
        error: false,
        message: 'Saved.',
      });
      setUnsavedChanges(false);
    }

    // Changes have been made and are not saved
    if (
      !_.isEqual(requests, currentSaved) ||
      !_.isEqual(descriptions, savedDescriptions) ||
      !_.isEqual(overviews, savedOverviews)
    ) {
      setSaveStatus({
        error: true,
        message: 'Updates have been made. Please save changes.',
      });
      setUnsavedChanges(true);
    }

    // No changes have been made or saved yet
    if (
      (_.isEqual(requests, currentSaved) &&
        !saveCreativeRequest.post.data &&
        _.isEqual(descriptions, savedDescriptions) &&
        _.isEqual(overviews, savedOverviews)) ||
      _.isEqual(requests[0], DEFAULT_REQUEST)
    ) {
      setSaveStatus(DEFAULT_STATUS);
      setUnsavedChanges(false);
    }
  }, [
    requests,
    currentSaved,
    saveCreativeRequest.post.data,
    overviews,
    descriptions,
  ]);

  useEffect(() => {
    if (saveCreativeRequest.post.loading) {
      setSaveStatus({
        error: false,
        message: 'Saving...',
      });
    }
  }, [saveCreativeRequest.post.loading]);

  useEffect(() => {
    if (saveCreativeRequest.post.error) {
      setSaveStatus({
        error: true,
        message: 'There was an error.',
      });
    }
  }, [saveCreativeRequest.post.error]);

  const handleInput = (key, value) => {
    const update = requests.map((req, i) => {
      if (i === currentIndex) {
        return { ...req, [key]: value };
      }

      return req;
    });

    setRequests(update);
  };

  const handleSelect = (key, e) => {
    const update = requests.map((req, i) => {
      if (i === currentIndex) {
        if (key === 'cards') {
          return { ...req, [key]: parseInt(e) };
        } else {
          return { ...req, [key]: e };
        }
      }
      return req;
    });

    setRequests(update);
  };

  const handleRefreshAll = () => {
    const update = requests.map((req, i) => {
      return DEFAULT_REQUEST;
    });

    setRequests(update);
    setOverviews(['', '', '', '']);
    setDescriptions(['', '', '', '']);
  };

  const handleRefresh = () => {
    const update = requests.map((req, i) => {
      if (i === currentIndex) {
        return DEFAULT_REQUEST;
      }
      return req;
    });

    setRequests(update);
    setSubmitStatus(DEFAULT_STATUS);
  };

  const handleOverview = (value, delta, source) => {
    if (source === 'user') {
      const update = [...overviews].map((desc, i) => {
        if (i === currentIndex) {
          return value;
        }

        return desc;
      });

      setOverviews(update);
    }
  };

  const handleDescription = (value, delta, source) => {
    if (source === 'user') {
      const update = [...descriptions].map((desc, i) => {
        if (i === currentIndex) {
          return value;
        }

        return desc;
      });

      setDescriptions(update);
    }
  };

  const overviewEditor = useMemo(
    () => (
      <ReactQuill
        key="overview"
        className="request-overview"
        theme="snow"
        value={overview}
        onChange={handleOverview}
        modules={quillModules}
        formats={quillFormats}
      />
    ),
    [overview, currentIndex]
  );

  const descriptionEditor = useMemo(
    () => (
      <ReactQuill
        key="description"
        className="request-overview"
        theme="snow"
        value={description}
        onChange={handleDescription}
        modules={quillModules2}
        formats={quillFormats2}
      />
    ),
    [description, currentIndex]
  );

  useEffect(() => {
    if (Array.isArray(currentRequest[RATIO])) {
      const update = {
        '1:1': currentRequest[RATIO].includes('1:1') ? 1 : 0,
        '4:5': currentRequest[RATIO].includes('4:5') ? 1 : 0,
        '9:16': currentRequest[RATIO].includes('9:16') ? 1 : 0,
        '1.91:1': currentRequest[RATIO].includes('1.91:1') ? 1 : 0,
      };

      handleInput(RATIO, update);
    }
  }, [currentRequest[RATIO]]);

  const updateRatioQuantity = (ratio, qty) => {
    const update = { ...currentRequest[RATIO], [ratio]: parseInt(qty) };
    handleInput(RATIO, update);
  };

  const getDefaultValue = (ratio) => {
    if (Array.isArray(currentRequest[RATIO])) {
      if (currentRequest[RATIO].includes(ratio)) {
        return 1;
      }
      return 0;
    } else {
      return currentRequest[RATIO][ratio];
    }
  };

  return (
    <>
      <RoleAccess roles={['internal']}>
        <Overlay show={showProjectSetup}>
          <ProjectSetup
            requests={requests}
            descriptions={descriptions}
            overviews={overviews}
            selectedContractors={selectedContractors}
            onClose={() => setShowProjectSetup(false)}
            account_id={account_id}
            view_id={view_id}
            handleRefresh={handleRefreshAll}
          />
        </Overlay>
      </RoleAccess>

      {successUrl && (
        <div className="request__popup">
          <div className="success-screen-wrapper">
            <SuccessScreen
              url={successUrl}
              onCancel={() => setSuccessUrl(false)}
            />
          </div>
        </div>
      )}

      <div
        className=""
        style={{
          position: 'relative',
          height: '100vh',
          width: open ? '460px' : '0',
        }}
      >
        {!!filteredData.length && showWizard && (
          <Wizard
            {...{
              currentRequest,
              open,
              copilotRequestSuccess,
              copilotLoading,
              copilotRequestError,
              copilotRequest,
              showWizard,
              setShowWizard,
              wizard,
              currentIndex,
              handleInstructions,
              handleFeedback,
              requestWidth,
            }}
          />
        )}
        <div
          className={`request${open ? ' open' : ''}${
            planningMode ? ' planning-mode' : ''
          }`}
          ref={ref}
          style={{
            width: requestWidth,
            userSelect: isResizing ? 'none' : 'auto',
          }}
        >
          <div onMouseDown={startResizing} className="request__handle"></div>
          <div
            className="collapse-drawer-btn"
            onClick={() => setRequestOpen(false)}
          >
            <div>
              <ChevronDoubleRightIcon />
            </div>
          </div>
          <div className="request__header">
            <div className="d-flex align-items-center">
              <h2 className="request__title">
                Creative Request&nbsp;{currentIndex + 1}
              </h2>
              <Tooltip content={'Undo changes'} disabled={!unsavedChanges}>
                <div>
                  <RefreshIcon
                    onClick={handleRefresh}
                    {...{ unsavedChanges }}
                  />
                </div>
              </Tooltip>
              <Tooltip content={'Save changes'} disabled={!unsavedChanges}>
                <div>
                  <SaveIcon onClick={handleSave} {...{ unsavedChanges }} />
                </div>
              </Tooltip>
            </div>
            <Button
              disabled={
                Array.isArray(currentRequest[RATIO])
                  ? !currentRequest[RATIO].length
                  : Object.values(currentRequest[RATIO]).every((x) => x === 0)
              }
              onClick={handleSubmit}
            >
              Submit{' '}
              <i
                className="fa fa-chevron-right"
                style={{ fontSize: '12px', marginLeft: '8px' }}
              />
            </Button>
          </div>
          <div className="request__messages">
            {saveStatus.message && (
              <p>
                <i
                  className={
                    saveStatus.message === 'Saving...'
                      ? 'fa-regular fa-hourglass'
                      : !saveStatus.error
                      ? 'fa-solid fa-circle-check'
                      : 'fa-solid fa-triangle-exclamation'
                  }
                ></i>
                {saveStatus.message}
              </p>
            )}
            {submitStatus.message && (
              <p className="request__info">
                <i
                  className={
                    creativeRequestApi.post.loading
                      ? 'fa-regular fa-hourglass'
                      : !submitStatus.error
                      ? 'fa-solid fa-circle-check'
                      : 'fa fa-exclamation-circle'
                  }
                ></i>
                {submitStatus.message}
              </p>
            )}
          </div>

          <div className="request__form">
            <div className="request__form-group">
              <WizardToggle {...{ setShowWizard }} />
              <label className="request__label" htmlFor={RATIO}>
                Ratio
              </label>
              <div className="request__input">
                <TagsList>
                  {RATIO_OPTIONS.map((ratio) => (
                    <div
                      className="d-flex align-items-center mb-1 mr-3"
                      style={{ height: '26px' }}
                    >
                      <div className="tag-container">
                        <Tag
                          active={currentRequest[RATIO][ratio] > 0}
                          style={{ borderRadius: '0' }}
                        >
                          {ratio}
                        </Tag>
                      </div>
                      <input
                        type="number"
                        defaultValue={getDefaultValue(ratio)}
                        onChange={(e) =>
                          updateRatioQuantity(ratio, e.target.value)
                        }
                        value={currentRequest[RATIO][ratio]}
                        min={0}
                      />
                    </div>
                  ))}
                </TagsList>
                {currentRequest[RATIO].length === 1 && (
                  <h3 className="request__rate ml-0">
                    <span>{ratioRate}%</span> Success Rate -{' '}
                    {only_new_ads ? 'New Ads' : 'All Ads'}
                  </h3>
                )}
              </div>
            </div>

            <div className="request__form-group">
              <label className="request__label" htmlFor="format">
                Format
              </label>
              <div className="request__input align-items-center">
                <select
                  id={FORMAT}
                  name={FORMAT}
                  type="select"
                  value={currentRequest[FORMAT]}
                  onChange={(e) => handleSelect(FORMAT, e.target.value)}
                >
                  {FORMAT_OPTIONS.map((opt) => (
                    <option value={opt}>{opt}</option>
                  ))}
                </select>
                <h3 className="request__rate">
                  {Number.isFinite(formatRate) ? (
                    <>
                      <span>{formatRate}%</span> Success Rate -{' '}
                      {only_new_ads ? 'New Ads' : 'All Ads'}
                    </>
                  ) : (
                    'No Data'
                  )}
                </h3>
              </div>
            </div>

            {currentRequest.data_points?.length ? (
              <DataPoints
                data_points={currentRequest.data_points}
                currentIndex={currentIndex}
              />
            ) : null}

            {currentRequest.format === 'Carousel' && (
              <div className="request__form-group">
                <label className="request__label" htmlFor={CARDS}>
                  # of Carousel Cards
                </label>
                <div className="request__input align-items-center">
                  <select
                    id={CARDS}
                    name={CARDS}
                    type="select"
                    value={currentRequest[CARDS]}
                    onChange={(e) => handleSelect(CARDS, e.target.value)}
                  >
                    {CARDS_OPTIONS.map((opt) => (
                      <option value={opt}>{opt}</option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            <div className="request__form-group">
              <label className="request__label" htmlFor={REQUEST_OVERVIEW}>
                Request Overview
              </label>
              {coreVisuals[`${currentIndex}`].urls.length ? (
                <CoreVisualsPreview
                  {...{ coreVisuals, currentIndex, setCoreVisuals }}
                />
              ) : null}
              <div className="request__input">{overviewEditor}</div>
            </div>
            <div
              className={`request__form-group position-relative ${
                descriptionUpdated ? 'sparkle' : ''
              }`}
              ref={descriptionRef}
            >
              <label className="request__label" htmlFor={REQUEST_DESCRIPTION}>
                Request Description
              </label>
              {descriptionUpdated && (
                <div class="sparkle" id="sparkle1">
                  <SparkleIcon />
                </div>
              )}
              {descriptionUpdated && (
                <div class="sparkle" id="sparkle2">
                  <SparkleIcon />
                </div>
              )}
              {descriptionUpdated && (
                <div class="sparkle" id="sparkle3">
                  <SparkleIcon />
                </div>
              )}
              {descriptionUpdated && (
                <div class="sparkle" id="sparkle4">
                  <SparkleIcon />
                </div>
              )}
              <div className="request__input">{descriptionEditor}</div>
            </div>

            <div className="request__form-group">
              <label className="request__label" htmlFor="format">
                Asset Name
              </label>
              <div className="request__input align-items-center">
                <input
                  id={ASSET_NAME}
                  name={ASSET_NAME}
                  type="select"
                  value={currentRequest[ASSET_NAME] || ''}
                  onChange={(e) => handleInput(ASSET_NAME, e.target.value)}
                />
              </div>
            </div>

            <div className="request__form-group">
              <label className="request__label" htmlFor="contractor">
                Request Recipient
              </label>
              <div className="request__input recipient-option">
                <input
                  type="radio"
                  value={DARWIN_TALENT}
                  checked={currentRequest[RECIPIENT] === DARWIN_TALENT}
                  onChange={(e) => handleSelect(RECIPIENT, e.target.value)}
                />
                <div className="d-flex flex-column">
                  <label
                    className="recipient-option__label"
                    onClick={() => handleSelect(RECIPIENT, DARWIN_TALENT)}
                  >
                    Darwin team
                  </label>
                  <span>Send this creative request to Darwin resources.</span>
                </div>
              </div>

              <div
                className="request__input recipient-option"
                style={{ marginBottom: '8px' }}
              >
                <input
                  type="radio"
                  value={CUSTOM_EMAIL}
                  checked={currentRequest[RECIPIENT] === CUSTOM_EMAIL}
                  onChange={(e) => handleSelect(RECIPIENT, e.target.value)}
                />
                <div className="d-flex flex-column">
                  <label
                    className="recipient-option__label"
                    onClick={() => handleSelect(RECIPIENT, CUSTOM_EMAIL)}
                  >
                    Custom email address
                  </label>
                  <span>
                    Send a read-only URL for this creative request to the email
                    address(es) of your choosing. Recipient(s) will need to have
                    a login to view the request.
                  </span>
                </div>
              </div>
              <input
                className="email-input"
                type="text"
                placeholder="Enter comma separated email addresses"
                value={currentRequest[CUSTOM_EMAIL] || ''}
                onChange={(e) => handleInput(CUSTOM_EMAIL, e.target.value)}
                onFocus={() => handleSelect(RECIPIENT, CUSTOM_EMAIL)}
              />

              <RoleAccess roles={['internal']}>
                <div className="request__input recipient-option">
                  <input
                    type="radio"
                    value={CUSTOM_CREATOR}
                    checked={currentRequest[RECIPIENT] === CUSTOM_CREATOR}
                    onChange={(e) => handleSelect(RECIPIENT, e.target.value)}
                  />
                  <div className="d-flex flex-column">
                    <label
                      className="recipient-option__label"
                      onClick={() => handleSelect(RECIPIENT, CUSTOM_CREATOR)}
                    >
                      Your custom creators
                    </label>
                    <span>
                      Choose from a list of existing creators you work with.
                    </span>
                    <div
                      className="request__input align-items-center"
                      style={{ margin: '8px 0' }}
                    >
                      <select
                        id={CONTRACTOR_ONE}
                        defaultValue=""
                        name={CONTRACTOR_ONE}
                        type="select"
                        value={currentRequest[CONTRACTOR_ONE]}
                        onChange={(e) =>
                          handleSelect(CONTRACTOR_ONE, e.target.value)
                        }
                        className="mr-1"
                      >
                        <option value="">None Selected</option>
                        {contractorOptions.map((opt) => (
                          <option value={opt.name}>{opt.name}</option>
                        ))}
                      </select>

                      <select
                        id={CONTRACTOR_TWO}
                        defaultValue=""
                        name={CONTRACTOR_TWO}
                        type="select"
                        value={currentRequest[CONTRACTOR_TWO]}
                        onChange={(e) =>
                          handleSelect(CONTRACTOR_TWO, e.target.value)
                        }
                      >
                        <option value="">None Selected</option>
                        {contractorOptions.map((opt) => (
                          <option value={opt.name}>{opt.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </RoleAccess>
            </div>

            {currentRequest.creative_code ? (
              <div className="request__form-group">
                <label className="request__label" htmlFor="creative_code">
                  Creative Code
                </label>
                <div className="request__input align-items-center">
                  <input
                    id={CREATIVE_CODE}
                    name={CREATIVE_CODE}
                    disabled
                    value={isCopied ? 'Copied!' : creativeCode}
                    style={{
                      display: 'inline-block',
                      width: 'auto',
                      marginRight: '1rem',
                    }}
                  />
                  <Button onClick={handleCopyCreativeCode}>Copy</Button>
                </div>
              </div>
            ) : null}

            <RequestNav {...{ setSaveStatus }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreativeRequest;

const CoreVisualsPreview = ({ coreVisuals, currentIndex, setCoreVisuals }) => {
  const handleRemove = (asset_url) => {
    let update = coreVisuals;

    const filteredTypes = update[`${currentIndex}`].types.filter(
      (type, i) => i === update[`${currentIndex}`].urls.indexOf(asset_url)
    );
    const filteredUrls = update[`${currentIndex}`].urls.filter(
      (url) => url !== asset_url
    );

    update = {
      ...update,
      [`${currentIndex}`]: {
        urls: [...filteredUrls],
        types: [...filteredTypes],
        html: filteredUrls.length
          ? filteredUrls
              .map((url) => {
                return `<p>Core visual: <a href="${url}" rel="noopener noreferrer" target="_blank">${url}</a></p>`;
              })
              .reduce((str, acc) => acc + str)
          : '',
      },
    };

    setCoreVisuals(update);
  };

  return (
    <div className="core-visuals">
      {coreVisuals[`${currentIndex}`].urls.map((url, i) => {
        return (
          <div className="core-visuals__preview">
            <div className="remove-icon" onClick={() => handleRemove(url)}>
              <i className="fa fa-close" />
            </div>
            {coreVisuals[`${currentIndex}`].types[i] === 'video' ? (
              <video
                key={url}
                autoPlay={false}
                loop
                muted
                playsInline
                style={{ height: '100px' }}
                onMouseOver={(event) => event.target.play()}
                onMouseOut={(event) => {
                  event.target.pause();
                  event.target.currentTime = 0;
                }}
              >
                <source src={url} type="video/mp4" />
              </video>
            ) : (
              <img key={url} src={url} alt="" style={{ height: '100px' }} />
            )}
          </div>
        );
      })}
    </div>
  );
};
