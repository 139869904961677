import React from "react";
import "./Button.scss";

const Button = ({ id, type, onClick, disabled, children }) => {
  return (
    <button
      id={id}
      className={`impact-report__btn impact-report__btn--${type} ${
        disabled ? "disabled" : ""
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
