import React from 'react';

const DraftCreativeHeader = ({
  selectedProject,
  setShowCreateModal,
  handleDeleteProject,
  deleteDraftProjectLoading,
  handleSaveProject,
  saveDraftProjectLoading,
  openShareModal,
  getDraftProjectsError,
  message,
  setShowProjectsModal,
}) => {
  return (
    <>
      <div className="draft-creative__header">
        <div className="d-flex align-items-center">
          <button className="header-button header-button--open">
            <i
              className="fa-regular fa-folder-open"
              onClick={() => setShowProjectsModal(true)}
            />
          </button>
          <button
            className="header-button header-button--open ml-3"
            onClick={() => setShowCreateModal(true)}
          >
            <i className="fa-solid fa-circle-plus" />
          </button>
        </div>
        <div className="d-flex align-items-center">
          {message && (
            <div className="mr-2">
              <i
                className={`mr-2 fa-regular fa-circle-${
                  message.includes('successfully') ? 'check' : 'xmark'
                }`}
              />
              {message}
            </div>
          )}
          <button
            className="header-button header-button--delete"
            onClick={handleDeleteProject}
            disabled={!selectedProject}
          >
            {!deleteDraftProjectLoading && <i className="fa-solid fa-trash" />}
            {deleteDraftProjectLoading && (
              <i className="fas fa-spinner fa-spin" />
            )}
          </button>
          <button
            className="header-button header-button--save ml-3"
            onClick={handleSaveProject}
            disabled={!selectedProject}
          >
            {!saveDraftProjectLoading && (
              <i className="fa-solid fa-floppy-disk" />
            )}
            {saveDraftProjectLoading && (
              <i className="fas fa-spinner fa-spin" />
            )}
          </button>
          <button
            className="header-button header-button--share ml-3"
            onClick={() => openShareModal()}
            disabled={!selectedProject}
          >
            <i className="fa-solid fa-share"></i>
          </button>
        </div>
      </div>
      {getDraftProjectsError && (
        <div style={{ position: 'absolute', top: '100px' }}>
          {`${
            getDraftProjectsError.error.includes('No draft creative projects found')
              ? ''
              : 'Error fetching projects'
          }`}
        </div>
      )}
    </>
  );
};

export default DraftCreativeHeader;
