import React, { useRef } from "react";
import PropTypes from "prop-types";

// Imported from rest of app
import BootstrapModal from "../../../../components/BootstrapModal";

const TitleModal = ({
  handleSave,
  titleModalVisible,
  hideTitleModal,
  finalTitle
}) => {
  const $inputRef = useRef();

  const handleSaveWithTitle = () => {
    if (!$inputRef) {
      return null;
    } else {
      handleSave($inputRef.current.value);
    }
  };

  return (
    <BootstrapModal
      id="save-chart-modal"
      key="add-to-modal"
      Title="Save Chart"
      Footer={<Footer onCancel={hideTitleModal} onSave={handleSaveWithTitle} />}
      Body={
        <div className="form-group">
          <label htmlFor="dashboard-chart-title">Form Name</label>
          <input
            id="dashboard-chart-title"
            type="text"
            placeholder="New Chart"
            className="form-control"
            required
            defaultValue={finalTitle}
            ref={$inputRef}
          />
        </div>
      }
      handleHide={hideTitleModal}
      show={titleModalVisible}
    />
  );
};

TitleModal.propTypes = {
  hideTitleModal: PropTypes.func,
  handleSave: PropTypes.func,
  titleModalVisible: PropTypes.bool,
  finalTitle: PropTypes.string
};

export default TitleModal;

function Footer({ onCancel, onSave }) {
  return (
    <>
      <button className="btn btn-danger" onClick={onCancel}>
        Cancel
      </button>

      <button type="submit" className="btn btn-primary" onClick={onSave}>
        Ok
      </button>
    </>
  );
}
