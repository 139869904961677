import React from 'react';
import { useNotifications } from '../../../../../components/Notification/useNotifications';
import { RuleObject } from './types';
import uuid from 'uuid';

export const deriveListOfRules = (data) => {
  const result: RuleObject[] = data.reduce((acc, current) => {
    current.rules.forEach((rule: RuleObject, i) => {
      acc.push({
        rule_id: rule.rule_id || `${uuid.v4()}`,
        label_name: current.label_name,
        labels_to_include: rule.labels_to_include,
        labels_to_exclude: rule.labels_to_exclude,
        rule_num: i + 1,
      });
    });

    return acc;
  }, []);

  return result;
};

export const deriveDerivedLabels = (rules) => {
  const result: RuleObject[] = rules.reduce((acc, current) => {
    let grouped = {};

    rules.forEach((rule) => {
      if (!grouped[rule.label_name]) {
        grouped[rule.label_name] = [rule];
      } else {
        grouped[rule.label_name].push(rule);
      }
    });

    let result = [];

    Object.entries(grouped).forEach(([key, val]) => {
      const label_value = key?.startsWith('FX_') ? key : `FX_${key}`;

      result.push({
        label_name: key,
        label_value,
        rules: val.map(({ labels_to_include, labels_to_exclude }) => ({
          labels_to_include,
          labels_to_exclude,
        })),
      });
    });

    return result;
  }, []);

  return result;
};

export const updateRuleOrder = (rules) => {
  // organize rules into groups and assign rule numbers

  let grouped = {};

  rules.forEach((rule) => {
    if (!grouped[rule.label_name]) {
      grouped[rule.label_name] = [rule];
    } else {
      grouped[rule.label_name].push(rule);
    }
  });

  let result = [];

  Object.values(grouped).forEach((value) => {
    value.forEach((rule, i) => {
      let update = { ...rule };
      update.rule_num = i + 1;
      result.push(update);
    });
  });

  return result;
};

export const notifyCreateSuccess = (refresh) => {
  const notifications = useNotifications();

  const message = (
    <>
      Category has been successfully saved.{' '}
      <span
        style={{
          color: '#e1e3ff',
          cursor: 'pointer',
        }}
        onClick={refresh}
      >
        Click here
      </span>{' '}
      to refresh your page to view the changes.
    </>
  );

  notifications.add({
    title: 'Success!',
    message,
  });
};

export const notifyDeleteSuccess = (fetchPerformanceData) => {
  const notifications = useNotifications();

  const refresh = () => {
    fetchPerformanceData();
  };

  const message = (
    <>
      Category deleted sucessfully.{' '}
      <span
        style={{
          color: '#e1e3ff',
          cursor: 'pointer',
        }}
        onClick={refresh}
      >
        Click here
      </span>{' '}
      to refresh your page to view the changes.
    </>
  );

  notifications.add({
    title: 'Success!',
    message,
  });
};
