import { connect } from "react-redux";
import { fetchBackgroundService } from "../../../store/background/api.notify";

const stateToProps = ({ Adsets: { adsets } }, { adsetName }) => {
  const maybe = adsets[adsetName];

  return {
    adsetId: !!maybe ? maybe.adset_id : ""
  };
};

const dispatchToProps = (dispatch, props) => ({
  fetchSplits: (
    { viewId, adsetId, adsetName, dailyBudget, ages, genders },
    callback
  ) => {
    dispatch(
      fetchBackgroundService({
        body: {
          adsetId,
          adsetName,
          dailyBudget,
          ages,
          genders
        },
        method: "POST",
        url: ["api", viewId, "split-adset"].join("/"),
        entity: "Adset Splits"
      })
    );

    callback && callback();
  }
});

export default connect(stateToProps, dispatchToProps);
