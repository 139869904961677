import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

const Home = ({ loggedIn, viewId }) => (loggedIn && viewId ? (
  <Redirect to={`/${viewId}/home`} />
) : (
  <Redirect to="/login" />
));

Home.defaultProps = {
  viewId: '',
};

Home.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  viewId: PropTypes.string,
};

export default Home;
