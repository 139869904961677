import React, { useReducer } from 'react';
import { DataState, DraftCreativeAction } from '../types';
import { EMPTY_ROW, initialDataState } from '../constants';

export const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT';
export const SET_PROJECT_NAME = 'SET_PROJECT_NAME';
export const SET_PROJECT_STATUS = 'SET_PROJECT_STATUS';
export const ADD_ROW = 'ADD_ROW';
export const REMOVE_ROW = 'REMOVE_ROW';
export const SET_BASE_VALUE = 'SET_BASE_VALUE';
export const SET_BASE_TYPE = 'SET_BASE_TYPE';
export const SET_BASE_VISUAL_TYPE = 'SET_BASE_VISUAL_TYPE';
export const SET_SECOND_VALUE = 'SET_SECOND_VALUE';
export const SET_SECOND_TYPE = 'SET_SECOND_TYPE';
export const SET_SECOND_VISUAL_TYPE = 'SET_SECOND_VISUAL_TYPE';
export const SET_FINAL_ASSET = 'SET_FINAL_ASSET';
export const SET_FINAL_TYPE = 'SET_FINAL_TYPE';
export const SET_FINAL_VISUAL_TYPE = 'SET_FINAL_VISUAL_TYPE';
export const SET_ADDITIONAL_NOTE = 'SET_ADDITIONAL_NOTE';
export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';

export const draftCreativeReducer = (
  state: DataState,
  action: DraftCreativeAction
): DataState => {
  switch (action.type) {
    case SET_SELECTED_PROJECT:
      return {
        projectName: action.projectName,
        projectId: action.projectId,
        status: action.status,
        rows: action.rows,
      };

    case SET_PROJECT_NAME:
      return {
        ...state,
        projectName: action.value,
      };

    case SET_PROJECT_STATUS:
      return {
        ...state,
        projectName: action.value,
      };

    case ADD_ROW:
      return {
        ...state,
        rows: [...state.rows, EMPTY_ROW],
      };

    case REMOVE_ROW:
      return {
        ...state,
        rows: state.rows.filter((_, i) => i !== action.rowIndex),
      };

    case SET_BASE_VALUE:
      return {
        ...state,
        rows: state.rows.map((row, i) =>
          i === action.rowIndex
            ? { ...row, base_block_value: action.value }
            : row
        ),
      };

    case SET_BASE_TYPE:
      return {
        ...state,
        rows: state.rows.map((row, i) =>
          i === action.rowIndex
            ? { ...row, base_block_type: action.value }
            : row
        ),
      };

    case SET_BASE_VISUAL_TYPE:
      return {
        ...state,
        rows: state.rows.map((row, i) =>
          i === action.rowIndex
            ? { ...row, base_block_visual_type: action.value }
            : row
        ),
      };

    case SET_SECOND_VALUE:
      return {
        ...state,
        rows: state.rows.map((row, i) =>
          i === action.rowIndex
            ? { ...row, second_block_value: action.value }
            : row
        ),
      };

    case SET_SECOND_TYPE:
      return {
        ...state,
        rows: state.rows.map((row, i) =>
          i === action.rowIndex
            ? { ...row, second_block_type: action.value }
            : row
        ),
      };

    case SET_SECOND_VISUAL_TYPE:
      return {
        ...state,
        rows: state.rows.map((row, i) =>
          i === action.rowIndex
            ? { ...row, second_block_visual_type: action.value }
            : row
        ),
      };

    case SET_FINAL_ASSET:
      return {
        ...state,
        rows: state.rows.map((row, i) =>
          i === action.rowIndex
            ? { ...row, final_asset_url: action.value }
            : row
        ),
      };

    case SET_FINAL_TYPE:
      return {
        ...state,
        rows: state.rows.map((row, i) =>
          i === action.rowIndex
            ? { ...row, final_asset_type: action.value }
            : row
        ),
      };

    case SET_FINAL_VISUAL_TYPE:
      return {
        ...state,
        rows: state.rows.map((row, i) =>
          i === action.rowIndex
            ? { ...row, base_final_visual_type: action.value }
            : row
        ),
      };

    case SET_ADDITIONAL_NOTE:
      return {
        ...state,
        rows: state.rows.map((row, i) =>
          i === action.rowIndex
            ? { ...row, additional_note: action.value }
            : row
        ),
      };

    case SET_LOADING:
      return {
        ...state,
        rows: state.rows.map((row, i) =>
          i === action.rowIndex
            ? {
                ...row,
                loading: row.loading ? false : true,
              }
            : row
        ),
      };

    case SET_ERROR:
      return {
        ...state,
        rows: state.rows.map((row, i) =>
          i === action.rowIndex
            ? {
                ...row,
                error: action.error,
              }
            : row
        ),
      };
  }
};

export const useDraftCreativeState = () => {
  const [draftCreativeState, draftCreativeDispatch] = useReducer(
    draftCreativeReducer,
    initialDataState
  );

  return { draftCreativeState, draftCreativeDispatch };
};
