import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { RESET_APP } from "../../store/background/api.logout";

const LogoutLink = ({ logout }) => {
  return (
    <div onClick={logout}>
      <Link to="/logout">Logout</Link>
    </div>
  );
};

const dispatchToProps = (dispatch, props) => ({
  logout: () => dispatch({ type: RESET_APP })
});

export default connect(null, dispatchToProps)(LogoutLink);
