export const CONCLUSION_ERROR = 'conclusion_error';
export const CONCLUSION_ERROR_MESSAGE = 'conclusion_error_message';
export const ESIMATED_CPA = 'estimated_cpa';
export const HIGH_CPA = 'high_cpa';
export const LOW_CPA = 'low_cpa';
export const BLOCK_ID = 'block_id';
export const BLOCK_NAME = 'block_name';
export const CONFIDENCE_INTERVAL_ARRAY = 'confidence_interval_array';
export const OUTCOMES_OVER_TIME_ARRAY = 'outcomes_over_time_array';
export const ACCOUNT_AVG_CPA = 'account_avg_cpa';
export const DARWIN_CREATIVE_TESTING = 'Darwin Creative Testing';
