import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';

import { getDefaultViewFeature } from '../../root-routes';

import Login from '../Login';
import Signup from '../Signup';
import Logout from '../Logout';
import RequestReset from '../RequestReset';
import ResetPassword from '../ResetPassword';
import RoleAccess from '../RoleAccess';

import { BasicLoading } from '../Helpers/Loading';
import YellowBars from '../HorizontalRules/YellowBars';

import CubeRoutes from '../../features/CubeDashboard';
import StandaloneCreativeRequest from '../../features/CreativeAnalytics/CreativeRequest/Standalone';

import AdPreviews from '../../features/AdPreviewsPage';

import Home from './Home';
import PrivateRoute from '../PrivateRoute';
import PrivateAdminRoute from '../PrivateAdminRoute';
import PrivateAdminLeverRoute from '../PrivateAdminLeverRoute';
import { hasRoles } from '../../cookies';

const CreativeAnalytics = React.lazy(() =>
  import('../../features/CreativeAnalytics')
);
const CreativeReports = React.lazy(() =>
  import('../../features/CreativeReports')
);
const CreativeTesting = React.lazy(() =>
  import('../../features/CreativeTesting')
);
const BulkCreative = React.lazy(() =>
  import('../../features/BulkActions/AdWidget')
);
const BulkAudience = React.lazy(() =>
  import('../../features/BulkActions/Audience')
);
const LeverAdmin = React.lazy(() =>
  import('../../features/BulkActions/LeverAdmin')
);
const LeverSetup = React.lazy(() =>
  import('../../features/BulkActions/LeverSetup')
);
const Clients = React.lazy(() => import('../../features/Clients'));
const Marketplace = React.lazy(() => import('../../features/Marketplace'));
const ViewAssets = React.lazy(() =>
  import('../../features/Marketplace/ViewAssets')
);
const CreativeStudio = React.lazy(() =>
  import('../../features/CreativeStudio')
);
const ClientAssets = React.lazy(() =>
  import('../../features/Marketplace/ClientAssets')
);
const Contractors = React.lazy(() => import('../../features/Contractors'));
const EventHistory = React.lazy(() =>
  import('../../features/BulkActions/EventHistory')
);

const LeverDashboard = React.lazy(() => import('../../features/Lever'));
const SystemStatus = React.lazy(() => import('../../features/SystemStatus'));
const RequestRefresh = React.lazy(() =>
  import('../../features/BulkActions/RequestRefresh')
);
const BlockManager = React.lazy(() =>
  import('../../features/BulkActions/BlockManager')
);
const LabelManager = React.lazy(() =>
  import('../../features/BulkActions/LabelManager')
);
const LabelSynonyms = React.lazy(() =>
  import('../../features/BulkActions/LabelSynonyms')
);
const BudgetBoost = React.lazy(() =>
  import('../../features/BulkActions/BudgetBoost')
);
const ViewSettings = React.lazy(() =>
  import('../../features/BulkActions/ViewSettings')
);
const CreativeTest = React.lazy(() =>
  import('../../features/BulkActions/CreativeTest')
);
const DarwinTesting = React.lazy(() => import('../../features/DarwinTesting'));
const LandingPage = React.lazy(() => import('../../features/LandingPage'));
const ProjectTracker = React.lazy(() =>
  import('../../features/ProjectTracker')
);
const Roadmap = React.lazy(() => import('../../features/Roadmap'));
const SharedVariationReport = React.lazy(() =>
  import('../../features/CreativeAnalytics/Variation/SharedReport')
);
const SharedHurdleReport = React.lazy(() =>
  import('../../features/CreativeAnalytics/Hurdle/SharedReport')
);

const StaticReport = React.lazy(() => import('../../features/StaticReport'));
const SharedCopyReport = React.lazy(() =>
  import('../../features/CreativeAnalytics/CopyReport/SharedReport')
);

const ReportGroup = React.lazy(() => import('../../features/ReportGroup'));
const ReportGroups = React.lazy(() => import('../../features/ReportGroupsManager'));

export const Routes = ({
  viewId,
  loggedIn,
  loading,
  creative_studio_only,
  isNonPaidMediaType,
  isUltraLight,
}) => (
  <Switch>
    <Route path="/" exact>
      <Home loggedIn={loggedIn} viewId={viewId} />
    </Route>
    <Route path="/signup" exact>
      <Signup />
    </Route>
    <Route path="/login" exact>
      <Login />
    </Route>
    <Route path="/logout" exact>
      <Logout />
    </Route>
    <Route path="/request-reset" exact>
      <RequestReset />
    </Route>
    <Route path="/reset-password" exact>
      <ResetPassword />
    </Route>
    <Route path="/variation-report">
      <Suspense fallback={<BasicLoading />}>
        <SharedVariationReport />
      </Suspense>
    </Route>
    <Route path="/hurdle-report">
      <Suspense fallback={<BasicLoading />}>
        <SharedHurdleReport />
      </Suspense>
    </Route>
    <Route path="/copy-report">
      <Suspense fallback={<BasicLoading />}>
        <SharedCopyReport />
      </Suspense>
    </Route>

    <Route path="/previews" exact>
      <AdPreviews />
    </Route>

    <Route path="/creative-requests/:requestId" exact>
      <StandaloneCreativeRequest />
    </Route>

    <Route path="/clients">
      {!loggedIn ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <Clients loading={loading} />
        </Suspense>
      )}
    </Route>

    <Route path="/event-history">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <RoleAccess roles={['internal']}>
            <EventHistory viewId={viewId} />
          </RoleAccess>
        </Suspense>
      )}
    </Route>

    <Route path="/marketplace">
      {!loggedIn ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <RoleAccess roles={['marketplace']}>
            <Marketplace />
          </RoleAccess>
        </Suspense>
      )}
    </Route>

    <Route path="/contractors" exact>
      {!loggedIn ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <Contractors loading={loading} />
        </Suspense>
      )}
    </Route>

    <Route
      exact
      path="/:viewId"
      render={(props) => (
        <Redirect to={getDefaultViewFeature(props.match.params.viewId)} />
      )}
    />

    {hasRoles(['contractor'], { useAdmin: false }) && (
      <Route
        path="/:viewId"
        render={(props) => (
          <Redirect to={getDefaultViewFeature(props.match.params.viewId)} />
        )}
      />
    )}

    <PrivateRoute exact path="/:viewId/studio">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          {/* <ViewAssets /> */}
          <CreativeStudio />
        </Suspense>
      )}
    </PrivateRoute>

    <PrivateRoute exact path="/:viewId/client-assets">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <ClientAssets />
        </Suspense>
      )}
    </PrivateRoute>

    <PrivateRoute exact path="/:viewId/creative-testing">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <CreativeTesting viewId={viewId} />
        </Suspense>
      )}
    </PrivateRoute>

    <Route exact path="/:viewId/roadmap">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <Roadmap />
        </Suspense>
      )}
    </Route>

    <PrivateRoute exact path="/:viewId/library" key="library">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <CreativeAnalytics mode="library" />
        </Suspense>
      )}
    </PrivateRoute>

    <PrivateRoute exact path="/:viewId/analytics" key="analytics">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <CreativeAnalytics mode="analytics" />
        </Suspense>
      )}
    </PrivateRoute>

    <PrivateRoute
      exact
      path="/:viewId/creative-reports/:start?/:end?/:audience?/:spend_threshold?/:report?"
    >
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <CreativeReports />
        </Suspense>
      )}
    </PrivateRoute>

    <PrivateRoute exact path="/:viewId/bulk-creative">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <BulkCreative variation="bulk-creative" />
        </Suspense>
      )}
    </PrivateRoute>

    <PrivateRoute exact path="/:viewId/bulk-audience">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <BulkAudience />
        </Suspense>
      )}
    </PrivateRoute>

    <PrivateAdminLeverRoute exact path="/:viewId/lever-admin">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <YellowBars>
            <LeverAdmin viewId={viewId} />
          </YellowBars>
        </Suspense>
      )}
    </PrivateAdminLeverRoute>

    <PrivateAdminRoute exact path="/:viewId/lever-setup">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <YellowBars>
            <LeverSetup viewId={viewId} />
          </YellowBars>
        </Suspense>
      )}
    </PrivateAdminRoute>

    <PrivateAdminLeverRoute exact path="/:viewId/lever-dashboard">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <LeverDashboard />
        </Suspense>
      )}
    </PrivateAdminLeverRoute>

    <PrivateAdminRoute exact path="/:viewId/system-status">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <YellowBars>
            <SystemStatus />
          </YellowBars>
        </Suspense>
      )}
    </PrivateAdminRoute>

    <PrivateRoute exact path="/:viewId/block-manager">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <BlockManager />
        </Suspense>
      )}
    </PrivateRoute>

    <PrivateRoute exact path="/:viewId/report-groups">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <ReportGroups />
        </Suspense>
      )}
    </PrivateRoute>

    <PrivateRoute exact path="/:viewId/manage-labels">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <LabelManager />
        </Suspense>
      )}
    </PrivateRoute>

    <PrivateRoute exact path="/:viewId/label-synonyms">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <LabelSynonyms />
        </Suspense>
      )}
    </PrivateRoute>

    <PrivateRoute exact path="/:viewId/budget-boost">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <BudgetBoost />
        </Suspense>
      )}
    </PrivateRoute>

    <PrivateRoute exact path="/:viewId/view-settings">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <ViewSettings />
        </Suspense>
      )}
    </PrivateRoute>

    <PrivateRoute exact path="/:viewId/creative-test">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <CreativeTest />
        </Suspense>
      )}
    </PrivateRoute>

    <PrivateRoute exact path="/:viewId/testing">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <DarwinTesting />
        </Suspense>
      )}
    </PrivateRoute>

    <PrivateRoute exact path="/:viewId/home">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          {creative_studio_only ? (
            <Redirect to={`/${viewId}/studio`} />
          ) : isNonPaidMediaType || isUltraLight ? (
            <Redirect to={`/${viewId}/library`} />
          ) : (
            <LandingPage viewId={viewId} />
          )}
        </Suspense>
      )}
    </PrivateRoute>

    <PrivateRoute exact path="/:viewId/refresh">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <RequestRefresh />
        </Suspense>
      )}
    </PrivateRoute>

    <PrivateRoute exact path="/:viewId/tracker">
      {loading ? (
        <BasicLoading />
      ) : (
        <Suspense fallback={<BasicLoading />}>
          <ProjectTracker />
        </Suspense>
      )}
    </PrivateRoute>

    <Route path="/static-report/:report_id">
      <Suspense fallback={<BasicLoading />}>
        <StaticReport />
      </Suspense>
    </Route>

    <Route path="/report-group/:report_group_id">
      <Suspense fallback={<BasicLoading />}>
        <ReportGroup />
      </Suspense>
    </Route>
    <CubeRoutes />

    <Route path="/:viewId/*">
      <NoMatch />
    </Route>
  </Switch>
);

Routes.defaultProps = {
  loggedIn: false,
  loading: false,
};

Routes.propTypes = {
  viewId: PropTypes.string.isRequired,
  loggedIn: PropTypes.bool,
  loading: PropTypes.bool,
};

const NoMatch = () => <h3>{window.location.pathname} doesn&apos;t exist</h3>;
