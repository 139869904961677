import React from "react";

import { Rank } from "./Helpers";
import { CPR_HEIGHT } from "./constants";
import { fontColor } from "./utils";

export const ZoneContent = ({ cpr, rank, total, Label, active }) => {
  const style = { fontWeight: "bold", color: fontColor(active) };

  return (
    <div className="d-flex flex-row justify-content-between align-items-center width_100pct">
      {Label}

      <div className="d-flex flex-column">
        <span className="d-flex justify-content-end md-lt-gray align-items-baseline">
          <span style={CPR_HEIGHT}>
            <span style={style}>{cpr}</span>
          </span>
          &ensp;CPA
        </span>
        <Rank rank={rank} outOf={total} />
      </div>
    </div>
  );
};

export const ZoneLabel = ({ children }) => {
  return (
    <div className="d-flex flex-row align-items-center height_100pct">
      {children}
    </div>
  );
};
