import React, { useEffect, useRef, useState } from 'react';

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Select from 'react-select';
import Button from '../../../components/Button';
import { BREAKDOWN_OPTIONS } from './constants';
import { useCreativeAnalyticsGet } from '../contexts';
import { useAbortableFetch } from '../../../hooks/useAbortableFetch';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { MOCK } from './MOCK';
import DataTable from './Table';
import { Insights } from './Insights';

import './styles.scss';
import '../../Analytics2/styles.scss';
import { BasicLoading } from '../../../components/Helpers/Loading';
import { LabelPerformanceReport } from '../../Analytics2';
import { ErrorBoundary } from '../../../components/Helpers/Error';
import { trackEvent } from '../../../store/UserActivity/actions';
import { useTrackEvent } from '../hooks/useTrackEvent';
import {
  REPORT_BUILDER,
  TOGGLE_REPORT_BUILDER,
} from '../../../store/UserActivity/constants';

const OPTS = [
  // {
  //   label: 'Darwin Pivot Report',
  //   value: 'meta_label_performance',
  // },
  {
    label: 'Performance by Ad Name',
    value: 'performance_by_ad_name',
  },
];

const updatePrimaryMetric = (primary_metric) => {
  if (primary_metric === 'cpc') return 'link_click';
  if (primary_metric === 'cpa') return 'purchase';
  return primary_metric;
};

const useApi = () => {
  const { data, loading, error, request } = useAbortableFetch();
  const { primary_metric } = useCreativeAnalyticsGet();
  const { view_id, account_id } = useSelector(
    ({ ViewSettings: { viewId, account_id } }) => ({
      view_id: viewId,
      account_id,
    })
  );
  const hasFetched = useRef(false);

  const fetchData = (start_date, end_date) => {
    hasFetched.current = true;
    request(
      ['api', 'creative-analytics', 'get_meta_performance_by_ad_name'].join(
        '/'
      ),
      {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
          view_id,
          account_id,
          start_date: format(new Date(start_date), 'yyyy-MM-dd'),
          end_date: format(new Date(end_date), 'yyyy-MM-dd'),
          primary_metric: updatePrimaryMetric(primary_metric),
        }),
      }
    );
  };

  return {
    data: data?.data?.data || [],
    insights: data?.data?.insights || [],
    loading,
    error,
    fetchData,
    hasFetched,
  };
};

export const ReportBuilder = () => {
  const [selectedReport, setSelectedReport] = useState(OPTS[0].value);
  const { defaultDates, isROAS } = useCreativeAnalyticsGet();
  const [startDate, endDate] = defaultDates;
  const [dateRange, setDateRange] = useState({
    start: startDate,
    end: endDate,
  });
  const [shouldRenderReport, setShouldRenderReport] = useState(false);
  const trackEvent = useTrackEvent();

  useEffect(() => {
    trackEvent(REPORT_BUILDER, TOGGLE_REPORT_BUILDER);
  }, []);

  useEffect(() => {
    setShouldRenderReport(false);
  }, [selectedReport, dateRange]);

  const renderReportComponent = () => {
    if (!shouldRenderReport) {
      return <p>Nothing to show. Select a report above to load data.</p>;
    }

    switch (selectedReport) {
      case 'performance_by_ad_name':
        return <AdNameReport dateRange={dateRange} />;
      case 'meta_label_performance':
        return <LabelPerformanceReport dateRange={dateRange} />;
      default:
        return <p>There was an error.</p>;
    }
  };

  return (
    <ErrorBoundary>
      <div className="report-builder">
        <Header
          fetchReport={() => setShouldRenderReport(true)}
          {...{
            selectedReport,
            setSelectedReport,
            dateRange,
            setDateRange,
          }}
        />
        <div className="row mx-0 w-100">{renderReportComponent()}</div>
      </div>
    </ErrorBoundary>
  );
};

const AdNameReport = ({ dateRange }) => {
  const { data, insights, loading, error, fetchData, hasFetched } = useApi();

  useEffect(() => {
    fetchData(dateRange.start, dateRange.end);
  }, []);

  let Comp = null;

  if (loading) {
    Comp = <BasicLoading />;
  } else if (
    error ||
    (data && !Array.isArray(data)) ||
    (insights && !Array.isArray(insights))
  ) {
    Comp = <p>There was an error fetching the report.</p>;
  } else if (data) {
    Comp = (
      <>
        <DataTable {...{ data }} />

        {insights && (
          <div className="col-12 col-xl-4 p-3">
            {' '}
            <h3 className="mb-4">Insights</h3>
            <Insights insights={insights} />
          </div>
        )}
      </>
    );
  }

  return Comp;
};

const Header = ({
  selectedReport,
  setSelectedReport,
  dateRange,
  setDateRange,
  fetchReport,
}) => {
  const setDate = (type, value) => {
    setDateRange((prev) => ({ ...prev, [type]: value }));
  };

  return (
    <div className="report-builder-header">
      <form className="breakdown-selector">
        <Select
          value={OPTS.find((opt) => opt.value === selectedReport)}
          options={OPTS}
          onChange={(selected) => setSelectedReport(selected.value)}
        />
      </form>
      <form autoComplete="off" className="date-picker">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start date"
            value={dateRange.start}
            onChange={(selected) => setDate('start', selected.$d)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </form>
      <form autoComplete="off" className="date-picker">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="End date"
            value={dateRange.end}
            onChange={(selected) => setDate('end', selected.$d)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </form>
      <Button
        // onClick={() => fetchData(dateRange.start, dateRange.end)}
        onClick={() => fetchReport()}
        // disabled={loading}
        // loading={loading}
      >
        Fetch data
      </Button>
    </div>
  );
};
