import React from "react";
import PropTypes from "prop-types";

import Zone from "./Running.Zone";

const Strikezone = ({ toggleTooltip, handleSelection, activeKey, adName }) => {
  const commonProps = { adName, toggleTooltip, handleSelection, activeKey };

  return (
    <div className="strikezone d-flex flex-col">
      <Zone {...commonProps} zoneType="cpa" />
    </div>
  );
};

Strikezone.propTypes = {
  adName: PropTypes.string.isRequired,
  activeKey: PropTypes.string.isRequired,
  handleSelection: PropTypes.func.isRequired,
  toggleTooltip: PropTypes.func
};

export default Strikezone;
