import React, { useEffect, useState } from 'react';
import { usePhraseBuilderApi } from '../../PhraseBuilder/api';
import { ReportCardProvider } from '../../ReportCard/contexts';
import PhraseBuilder from '../../PhraseBuilder';
import { useAccountId } from '../../selectors';
import LoadingAnvil from '../../../../components/Helpers/LoadingAnvil';
import { useDispatch, useSelector } from 'react-redux';
import CreativeAnalytics from '../../CreativeAnalytics';
import {
  addPhraseData,
  removePhraseDataForVisual,
} from '../../../../store/CreativeAnalytics/creativeAnalyticsSlice';

const usePhraseDataStore = () => {
  const dispatch = useDispatch();
  const { phraseDataLookup } = useSelector(
    ({ CreativeAnalytics: { phraseDataLookup } }) => ({
      phraseDataLookup,
    })
  );

  return [
    Object.assign({}, phraseDataLookup ?? {}),
    (visual_hash, data) => dispatch(addPhraseData({ visual_hash, data })),
    (visual_hash) => dispatch(removePhraseDataForVisual({ visual_hash })),
  ];
};

export const QaPhraseBuilderWrapper = ({ visual_hash }) => {
  const [storedPhraseData, addPhraseData, removePhraseData] =
    usePhraseDataStore();
  const account_id = useAccountId();
  const phraseBuilderApi = usePhraseBuilderApi();
  const [changesMade, setChangesMade] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [shouldHaveData, setShouldHaveData] = useState(false);

  const fetchPhraseBuilderData = () => {
    phraseBuilderApi.get.request({ visual: visual_hash, account_id });
  };

  const cached = storedPhraseData?.[visual_hash];

  useEffect(() => {
    if (!cached) {
      fetchPhraseBuilderData();
    }
  }, [visual_hash]);

  useEffect(() => {
    if (phraseBuilderApi.get.data) {
      addPhraseData(visual_hash, phraseBuilderApi.get.data);
    }
  }, [phraseBuilderApi.get.data]);

  const phraseData = cached || phraseBuilderApi.get.data;

  let Content = null;

  if (phraseBuilderApi.get.error || !phraseData) {
    Content = (
      <h4 className="p-5">
        There was an error loading the Phrase Builder. Go back to the{' '}
        <span
          // onClick={() => setReportCardMode(REPORT_CARD)}
          style={{
            textDecoration: 'underline',
            color: '#039BE5',
            cursor: 'pointer',
          }}
        >
          Report Card
        </span>
        .
      </h4>
    );
  } else {
    Content = (
      <PhraseBuilder
        data={phraseData}
        error={phraseBuilderApi.get.error}
        phraseBuilderApi={phraseBuilderApi}
        visual_hash={visual_hash}
      />
    );
  }

  let Render = (
    <>
      {phraseBuilderApi.get.loading && !refreshing ? (
        <LoadingAnvil />
      ) : (
        <div id="main-content" className={`content`}>
          {Content}
        </div>
      )}
    </>
  );

  return (
    <ReportCardProvider
      {...{
        changesMade,
        setChangesMade,
        refreshing,
        setRefreshing,
        fetchPhraseBuilderData,
        setShouldHaveData,
      }}
    >
      {Render}
    </ReportCardProvider>
  );
};
