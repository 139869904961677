import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useCreativeAnalyticsGet } from '../contexts';
import './HoverWindow.scss';
import {
  useGetCommonElements,
  useGetCommonElementsWinners,
  useGetCommonElementsLosers,
} from './api';
import { common } from '@mui/material/colors';
import { DISABLED_CATEGORIES } from '../ReportCard/CategoryEditor/constants';
import { useCategories } from '../hooks';

const HoverWindow = ({
  cell,
  isHovered,
  handleCommonLabels: handleCommonLabels,
  topSpendingVisual,
}) => {
  const {
    column: { id: columnId },
    row: { original: rowData },
  } = cell;

  let Render = null;

  const updateHandler = (data, loading) => {
    const apiData = { ...data };
    handleCommonLabels(apiData, loading);
  };

  switch (columnId) {
    case 'name':
      Render = (
        <ElementDetails
          rowData={rowData}
          onUpdate={updateHandler}
          topSpender={topSpendingVisual}
          isHovered={isHovered}
        />
      );
      break;
    case 'performance_percent':
      Render = <PValue rowData={rowData} />;
      break;
    default:
      break;
  }

  return <div className="hover-window">{Render}</div>;
};

export default HoverWindow;

export const ElementDetails = ({
  rowData,
  onUpdate: onUpdate,
  topSpender,
  isHovered,
}) => {
  const { filteredData, reportCardSettings } = useCreativeAnalyticsGet();
  const { name, example, description, winners, losers } = rowData;
  const vidRef = useRef();
  const commonWinnersApi = useGetCommonElements();
  const commonLosersApi = useGetCommonElements();
  const commonAllApi = useGetCommonElements();
  const winnerHashes = winners.map(({ id }) => id);
  const loserHashes = losers.map(({ id }) => id);
  const allHashes = winnerHashes.concat(loserHashes);
  const [commonWinners, setCommonWinners] = useState([]);
  const [commonLosers, setCommonLosers] = useState([]);
  const [commonAll, setCommonAll] = useState([]);
  const hiddenAll = (commonAll.length - 6).toString();
  const hiddenWinners = (commonWinners.length - 6).toString();
  const hiddenLosers = (commonLosers.length - 6).toString();
  const loading =
    commonAllApi.post.loading ||
    commonWinnersApi.post.loading ||
    commonLosersApi.post.loading;
  const { navCategories } = useCategories();

  const timer = useRef(null);
  const hasFetched = useRef(false);

  const fetchCommonElements = () => {
    hasFetched.current = true;
    commonWinnersApi.post.request(winnerHashes);
    commonLosersApi.post.request(loserHashes);
    commonAllApi.post.request(allHashes);
  };

  // wait until window is visible for 1 second before fetching
  useEffect(() => {
    timer.current = setTimeout(() => {
      // Fetch the API after 2 seconds
      fetchCommonElements();
    }, 2000);

    return () => clearTimeout(timer.current);
  }, []);

  const visibleLabels = useMemo(
    () =>
      filteredData.filter((item) =>
        navCategories.some((c) => item.category === c.label)
      ),
    [filteredData, reportCardSettings.categorySettings]
  );

  const filterLabels = (labels) =>
    labels.filter((l) =>
      visibleLabels.some(
        (x) => x.value === l && !DISABLED_CATEGORIES.includes(x.category)
      )
    );

  useEffect(() => {
    if (commonAllApi.post.data) {
      const apiData = JSON.parse(commonAllApi.post.data.data.body);
      if (Array.isArray(apiData)) {
        setCommonAll(filterLabels(apiData));
      }
    }
    if (commonWinnersApi.post.data) {
      const apiData = JSON.parse(commonWinnersApi.post.data.data.body);
      if (Array.isArray(apiData)) {
        setCommonWinners(filterLabels(apiData));
      }
    }
    if (commonLosersApi.post.data) {
      const apiData = JSON.parse(commonLosersApi.post.data.data.body);
      if (Array.isArray(apiData)) {
        setCommonLosers(filterLabels(apiData));
      }
    }
  }, [
    commonAllApi.post.data,
    commonWinnersApi.post.data,
    commonLosersApi.post.data,
  ]);

  useEffect(() => {
    onUpdate({ commonAll, commonWinners, commonLosers }, loading);
  }, [commonAll, commonWinners, commonLosers, loading]);

  const Loading = (
    <div className="common-list">
      <ul className="placeholder-list m-0">
        <li>
          <span className="animated-background"></span>
        </li>
        <li>
          <span className="animated-background"></span>
        </li>
        <li>
          <span className="animated-background"></span>
        </li>
        <li>
          <span className="animated-background"></span>
        </li>
        <li>
          <span className="animated-background"></span>
        </li>
        <li>
          <span className="animated-background"></span>
        </li>
      </ul>
    </div>
  );

  const LabelsList = ({ list, hidden, type }) => {
    return (
      <div className="common-list">
        <ul className="m-0">
          {list.map((i, index) => {
            let res = _.truncate(i, {
              length: 18,
            });
            return (
              <li>
                <span>{res}</span>
              </li>
            );
          })}
        </ul>
        {list.length > 6 && (
          <p className="common-list__more">
            <span className={`common-list__more--${type}`}>+{hidden} more</span>
          </p>
        )}
      </div>
    );
  };

  const renderSection = (title, list, hidden, type) => (
    <div>
      <p>{`${title} (${list.length})`}</p>
      {loading || !hasFetched.current ? (
        <div>{Loading}</div>
      ) : list.length ? (
        <LabelsList list={list} hidden={hidden} type={type} />
      ) : (
        <p className="no-common">No common elements found.</p>
      )}
    </div>
  );

  return (
    <>
      <div className="element-details">
        {topSpender && (
          <div className="element-details__example">
            {topSpender.visual_type === 'image' ? (
              <img
                className="element-details__img"
                src={topSpender.asset_url}
                alt=""
              />
            ) : topSpender.visual_type === 'video' && topSpender.asset_url ? (
              <video
                ref={vidRef}
                className="element-details__img"
                key={topSpender.asset_url}
                autoPlay
                loop
                muted
                plays-inline
              >
                <source src={topSpender.asset_url} type="video/mp4" />
              </video>
            ) : (
              <>
                <img
                  className="element-details__img"
                  src={topSpender.thumbnail_url}
                  alt=""
                />
                <p className="missing-asset-url">
                  <i className="fa-solid fa-triangle-exclamation"></i>
                  Missing Facebook page access for this asset
                </p>
              </>
            )}
          </div>
        )}
        <div className="element-details__summary">
          <h3 className="element-details__name">
            {name}
            {/* {hex.match && <HexPreview color={hex.hexCode} />} */}
            {/* {trending === true && <span className="element-details-summary__trending">TRENDING</span>} */}
          </h3>
          <p className="element-details__desc">
            {description ? description : 'No description available.'}
          </p>
        </div>
      </div>
      <div className="element-details__common">
        <h5 className="m-0 p-0">Common Elements:</h5>
        {renderSection('Common Elements (All)', commonAll, hiddenAll, 'all')}
        {renderSection(
          'Common Elements (Above Average)',
          commonWinners,
          hiddenWinners,
          'above'
        )}
        {renderSection(
          'Common Elements (Below Average)',
          commonLosers,
          hiddenLosers,
          'below'
        )}
      </div>
    </>
  );
};

const PValue = ({ rowData }) => {
  const { filteredData } = useCreativeAnalyticsGet();
  const pValue = getPValue(filteredData, rowData);

  return (
    <div className="p-value-message">
      <span className="p-value-message__title">p value</span>
      <span className="p-value-message__value">
        {parseFloat(pValue).toFixed(2)}
      </span>
    </div>
  );
};
