import React from 'react';

const AddCardIcon = ({ onClick }) => {
  return (
    <svg
      width="35"
      height="34"
      viewBox="0 0 35 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.90926 8.50004H3.07593V28.3334C3.07593 29.8917 4.35093 31.1667 5.90926 31.1667H25.7426V28.3334H5.90926V8.50004ZM28.5759 2.83337H11.5759C10.0176 2.83337 8.74259 4.10837 8.74259 5.66671V22.6667C8.74259 24.225 10.0176 25.5 11.5759 25.5H28.5759C30.1343 25.5 31.4093 24.225 31.4093 22.6667V5.66671C31.4093 4.10837 30.1343 2.83337 28.5759 2.83337ZM28.5759 22.6667H11.5759V5.66671H28.5759V22.6667ZM18.6593 19.8334H21.4926V15.5834H25.7426V12.75H21.4926V8.50004H18.6593V12.75H14.4093V15.5834H18.6593V19.8334Z"
        fill="#1E2B4E"
        style={{ boxShadow: '2px 2px 0px 0px rgba(206, 217, 234, 1)' }}
      />
    </svg>
  );
};

export default AddCardIcon;
