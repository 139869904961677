import { useEffect, useState } from 'react';
import { usePostRequest } from '../../../components/Hooks/usePostRequest';
import { useTrackEvent } from '../../CreativeAnalytics/hooks/useTrackEvent';
import {
  CREATIVE_DATA_STATIC_EXPORT,
  REPORT_BUILDER,
} from '../../../store/UserActivity/constants';
import { darwinDateToDate } from '../../../utils/darwin-dates';

const replaceInvalidCharacters = (obj = {}) => {
  const result = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (key.includes('.')) {
        const newKey = key.replace(/\./g, '___');
        result[newKey] = obj[key];
      } else {
        result[key] = obj[key];
      }
    }
  }

  return result;
};

const replaceInvalidCharactersArray = (arr = []) => {
  return arr.map((obj) => replaceInvalidCharacters(obj));
};

export const useShareStaticDetailData = (isROAS: boolean = false) => {
  const {
    data: apiData,
    loading,
    error,
    request,
  } = usePostRequest(
    ['api', 'creative-analytics', 'create-static-report'].join('/')
  );
  const [shouldShowShareModal, setShouldShowShareModal] = useState(false);
  const [sharedReportId, setSharedReportId] = useState(null);
  const trackEvent = useTrackEvent();

  const generateReport = ({
    report_name,
    report_description,
    start_date,
    end_date,
    data,
    columns,
    columnOptions,
    sort,
  }) => {
    trackEvent(REPORT_BUILDER, CREATIVE_DATA_STATIC_EXPORT);
    request({
      report_name,
      report_description,
      start_date: darwinDateToDate(start_date),
      end_date: darwinDateToDate(end_date),
      data: {
        report_type: 'report-builder',
        reportSection: 'detail_data',
        data: replaceInvalidCharactersArray(data),
        columns,
        columnOptions,
        sort,
        isROAS,
      },
    });
  };

  const generateSummaryReport = ({
    report_name,
    report_description,
    start_date,
    end_date,
    tableData,
  }) => {
    request({
      report_name,
      report_description,
      start_date: darwinDateToDate(start_date),
      end_date: darwinDateToDate(end_date),
      data: replaceInvalidCharacters({
        report_type: 'report-builder',
        reportSection: 'summary_data',
        tableData: tableData?.data?.map((obj) => replaceInvalidCharacters(obj)),
        isROAS,
      }),
    });
  };

  useEffect(() => {
    const report_id = extractReportId(apiData);

    if (report_id) {
      setSharedReportId(report_id);
    }
  }, [apiData]);

  return {
    shouldShowShareModal,
    openShareModal: () => setShouldShowShareModal(true),
    closeShareModal: () => {
      setShouldShowShareModal(false);
      setSharedReportId(null);
    },
    submitShareReport: (payload) => generateReport(payload),
    submitSummaryReport: (payload) => generateSummaryReport(payload),
    sharedReportId,
    loadingShareReport: loading,
  };
};

const extractReportId = (apiData) => {
  if (apiData?.message) {
    const reportIdMatch = apiData.message.match(/report id (\w+)/);

    const reportId = reportIdMatch ? reportIdMatch[1] : null;

    return reportId;
  } else {
    return null;
  }
};
