import React, { useMemo, useState } from 'react';
import CopyTable from '../CreativeAnalytics/CopyReport/Table';

import {
  useTable,
  useSortBy,
  useFilters,
  useFlexLayout,
  useColumnOrder,
} from 'react-table';
import { CopyColumnPicker } from '../CreativeAnalytics/CopyReport/CopyColumnPicker';
import { useCreativeAnalyticsGet } from '../CreativeAnalytics/contexts';
import { useCopyReportColumns } from '../CreativeAnalytics/CopyReport/columns';

export const StaticCopyReport = ({
  report_name,
  report_description,
  start_date,
  end_date,
  data,
}) => {
  const { isROAS } = useCreativeAnalyticsGet();
  const { rows, copyReportColumns, breakdownLabel } = data;
  const columns = useCopyReportColumns();

  const tableColumns = useMemo(() => {
    return columns.filter((col) =>
      ['breakdown_value', ...copyReportColumns].includes(col.accessor)
    );
  }, [columns, copyReportColumns]);

  const tableInstance = useTable(
    {
      columns: tableColumns,
      data: rows,
      initialState: {
        sortBy: [
          {
            id: 'cpa',
            desc: isROAS,
          },
        ],
      },
      autoResetHiddenColumns: false,
      autoResetSortBy: false,
    },
    useSortBy,
    useFlexLayout,
    useColumnOrder
  );

  let Render = null;

  // if (loading) {
  //   Render = <div className="mt-2">Loading...</div>;
  // }

  // if (error) {
  //   console.error(error ?? data);
  //   Render = <div className="mt-2">There was an error.</div>;
  // }

  if (rows.length) {
    Render = <CopyTable tableInstance={tableInstance} title={breakdownLabel} />;
  }

  return <div className="pl-5">{Render}</div>;
};
