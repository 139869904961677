import React from 'react';
import { DARWIN_NAVY } from '../../../../constants';

export const DraftCreativeIcon = () => {
  return (
    <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.30909 5.31339L9.21701 5.22196C8.78567 4.80702 8.2562 4.50817 7.67805 4.35331C5.80619 3.8517 4.00838 4.91288 3.49775 6.81951C3.47006 6.92254 3.45525 7.03844 3.43078 7.32885C3.33162 8.53297 3.0483 9.43124 2.27174 10.3424C3.72699 10.9142 5.45139 11.3173 6.76241 11.3173C7.54181 11.3163 8.29915 11.0584 8.91724 10.5836C9.53533 10.1088 9.97971 9.44353 10.1816 8.69073C10.3399 8.1003 10.3427 7.47894 10.1897 6.8871C10.0367 6.29527 9.73373 5.75312 9.30909 5.31339ZM7.91244 3.08416L11.0927 0.610231C11.2166 0.514125 11.3713 0.466528 11.5278 0.476368C11.6843 0.486207 11.8318 0.552808 11.9427 0.663676L13.8712 2.59156C13.982 2.70252 14.0485 2.85008 14.0582 3.00657C14.0679 3.16306 14.0202 3.31771 13.924 3.44153L11.4514 6.62183C11.6257 7.33297 11.6362 8.07444 11.482 8.79022C11.3278 9.506 11.013 10.1774 10.5613 10.7537C10.1096 11.3299 9.53289 11.7961 8.87467 12.1168C8.21646 12.4375 7.49396 12.6045 6.76176 12.6051C4.50741 12.6051 1.60979 11.6379 0 10.6721C2.56279 8.74031 1.96201 7.57483 2.25371 6.48661C2.93497 3.94314 5.3709 2.45698 7.91244 3.08416ZM10.1095 4.29407C10.1516 4.33486 10.1932 4.37628 10.2344 4.41835L10.9659 5.14919L12.5596 3.10025L11.4353 1.97598L9.38636 3.56967L10.1095 4.29407Z" fill={DARWIN_NAVY}/>
</svg>

  );
};
