import React, { useState } from 'react';
import { useSearchParams } from '../../../components/Hooks/search-params';
import Tooltip from '../../../components/Tooltip';
import { useViewSettings } from '../../../selectors';
import { useCreativeAnalyticsGet } from '../contexts';
import { StarIcon } from './icons';
import { MinimalTextEditorField } from './InputFields';
import './Saved.scss';
import { getLabelsLookup } from './utils';

const Saved = ({
  show,
  setShowSaved,
  presets,
  handleDelete,
  loading,
  view_id,
  setPresetsOpen,
  getLoading,
}) => {
  const { default_primary_metric } = useViewSettings();
  const { isROAS, data } = useCreativeAnalyticsGet();

  let Render = <div>No saved view presets.</div>;

  if (getLoading) Render = <div>Loading...</div>;

  if (presets.length) {
    Render = (
      <div>
        {presets.map((preset) => {
          return (
            <PresetItem
              {...{
                preset,
                data,
                handleDelete,
                default_primary_metric,
                isROAS,
                loading,
                view_id,
                setPresetsOpen,
              }}
            />
          );
        })}
      </div>
    );
  }

  return (
    <div className={`presets__saved ${show ? 'show' : ''}`}>
      <div className="">
        <div
          onClick={() => setShowSaved(false)}
          className="back-button"
        >{`<`}</div>
        <div className="presets__title">Saved Presets</div>
      </div>
      {Render}
    </div>
  );
};

export default Saved;

const PresetItem = ({
  preset,
  data,
  handleDelete,
  default_primary_metric,
  isROAS,
  loading,
  view_id,
  setPresetsOpen,
}) => {
  const {
    _id,
    preset_name: name,
    preset_description: description,
    is_default,
    url,
  } = preset;

  const [showDetails, setShowDetails] = useState(false);

  const withoutPrefix = url.includes(view_id)
    ? url.split(`${view_id}/`)[1]
    : url;
  const withPrefix = `https://dashboard.darwinsoftware.io/${withoutPrefix}`;

  const newUrl = new URL(withPrefix);
  const args = new URLSearchParams(newUrl.search);
  const primary_metric = args.get('primary_metric');
  const labelsParam = args.get('labels');
  const cpaMetric = isROAS ? 'ROAS' : 'CPA';
  const { setParams: setSearchParams } = useSearchParams();

  let udc_labels = [];
  let labels = [];

  if (labelsParam && labelsParam.length && data.length) {
    const [udcLabels, labelsArr] = labelsParam.split(',').reduce(
      (arr, l) => {
        let label = l.includes('->') ? l.split('->')[1] : l;
        if (l === 'top_10_spending') label = 'Top 10 Spending Visuals';
        const foundLabel = getLabelsLookup(data, label);
        if (!foundLabel) console.log(label);
        if (foundLabel) {
          if (foundLabel?.udc) {
            arr[0].push(`${foundLabel?.category}->${foundLabel?.name}`);
          }

          arr[1].push(`${foundLabel?.category}->${foundLabel?.name}`);
        }

        return arr;
      },
      [[], []]
    );

    udc_labels = udcLabels;
    labels = labelsArr;
  }

  const handleRoute = () => {
    setSearchParams({
      primary_metric: primary_metric || cpaMetric,
      labels,
      udc_labels,
    });
    setPresetsOpen(false);
  };

  return (
    <div
      className={`preset__item ${showDetails ? 'expanded' : ''}`}
      onClick={() => setShowDetails((prev) => !prev)}
    >
      <ItemField
        field={'name'}
        icon={
          <i
            className={`fa-solid fa-caret-${showDetails ? 'down' : 'right'}`}
          />
        }
        value={<span>{name}</span>}
      >
        <div className="d-flex">
          {is_default && (
            <Tooltip content="Default">
              <div className="ml-3">
                <StarIcon />
              </div>
            </Tooltip>
          )}
          <div>
            <span className={`open-link`} onClick={handleRoute}>
              <i className="fa-solid fa-arrow-up-right-from-square" />
            </span>
          </div>
        </div>
      </ItemField>
      <div className={`preset__item-details ${showDetails ? 'expanded' : ''}`}>
        <ItemField
          field={'description'}
          icon={<i className="fa-solid fa-align-left" />}
          tooltip="Description"
        >
          <MinimalTextEditorField
            value={description || '<p>No description</p>'}
            hideToolbar={true}
          />
        </ItemField>
        <ItemField
          field={'labels'}
          icon={<i className="fa fa-tags" />}
          tooltip="Label Filters"
        >
          <span>
            {labels.length
              ? labels.map((l) => <span>{l}</span>)
              : 'No label filters'}
          </span>
        </ItemField>
        <ItemField
          field={'primary_metric'}
          icon={<i className="fa-solid fa-chart-line" />}
          tooltip="Primary Metric"
        >
          <span>{primary_metric || default_primary_metric || cpaMetric}</span>
        </ItemField>
        <div className="d-flex justify-content-end">
          {loading && <span>Deleting...</span>}
          <Tooltip content="Delete this preset">
            <span onClick={() => handleDelete(_id)}>
              <i className="fa fa-trash" />
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

const ItemField = ({ field, value, icon, tooltip, children }) => {
  return (
    <div className={`preset__item-field preset__item-field--${field}`}>
      <div className="d-flex align-items-center justify-content-start">
        {icon && (
          <Tooltip content={tooltip} disabled={!tooltip}>
            <div className="field-icon">{icon}</div>
          </Tooltip>
        )}
        {value && value}
      </div>
      {children}
    </div>
  );
};
