import { useState, useEffect } from 'react';

/**
 * @param {RequestInfo} url
 * @param {RequestInit} options
 * @param {Array} dependencies
 * @param {Boolean} startLoading
 *
 * @return {{ error: false | any, loading: boolean, data: any }}
 */
export function useFetch(
  url,
  options = {},
  dependencies = [],
  startLoading = true
) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(startLoading);
  const [error, setError] = useState(false);

  useEffect(() => {
    const cancel = new AbortController();
    const opts = { ...options, signal: cancel.signal };
    const fetcher = async () => {
      setLoading(true);

      try {
        const res = await window.fetch(url, opts);
        const json = await res.json();

        setData(json);
        setLoading(false);
      } catch (err) {
        if (cancel.signal.aborted) {
          // eslint-disable-next-line no-console
          console.warn(err);
        } else {
          setError(err);
        }
      }
    };

    fetcher();

    return () => cancel.abort();
  }, dependencies);

  return { data, error, loading };
}
