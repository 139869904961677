import React from 'react';

import { useCreativeRequestGet, useCreativeRequestSet } from '../CreativeRequest/contexts';
import { SECTION_HEADERS } from './constants';

const DISABLE_AVOID = [
  'IMAGE+', 'VIDEO+', '1:1+', '4:5+', '16:9+', '9:16+',
]

const CardOptions = ({ label=null, overviewDisplay='', url, visual_type }) => {
  const { addToOverview } = useCreativeRequestSet();
  const assetUrl = url;
  const assetType = visual_type;
  
  const handleAddToOverview = () => {
    addToOverview(overviewDisplay, assetUrl, assetType, '', '');
  }

  let style = {};

  if (label) {
    style = { bottom: '6.5rem' };
  }
  
  return (
    <ul className="recommendation-card__options" style={style}>
      <li className="recommendation-card__overview" onClick={handleAddToOverview}>Add to request <span>(Overview)</span></li>
    </ul>
  )
}

export default CardOptions;