import { useGetMedian } from '../utils';
import useEffect from 'react';
import { DISABLED_CATEGORIES } from '../ReportCard/CategoryEditor/constants';

export const getVisualsData = (sort, allVisuals = []) => {
  let result = [...allVisuals];

  if (sort === 'cpa') {
    return result.sort((a, b) => {
      if (a[sort] === 0 && b[sort] !== 0) return 1;
      if (b[sort] === 0 && a[sort] !== 0) return -1;
      if (a[sort] < b[sort]) return -1;
      if (a[sort] > b[sort]) return 1;
    });
  }

  return result.sort((a, b) => {
    if (a[sort] === 0 && b[sort] !== 0) return 1;
    if (b[sort] === 0 && a[sort] !== 0) return -1;
    if (a[sort] > b[sort]) return -1;
    if (a[sort] < b[sort]) return 1;
  });
};

const getAllVisualsAndAds = (filteredData) => {
  let uniqVisualHashes = new Set();
  let result = [];

  filteredData.forEach((label) => {
    label.visuals.forEach((visual) => {
      if (!uniqVisualHashes.has(visual.id) && !!visual.ad_names) {
        result.push(visual);
      }
      uniqVisualHashes.add(visual.id);
    });
  });

  return result;
};

export const getAllVisuals = (
  filteredData,
  allVisualsLabel,
  filteredUdcVisualHashes
) => {
  let all = [...allVisualsLabel.winners, ...allVisualsLabel.losers];
  if (!filteredUdcVisualHashes.length) return all;

  return getAllVisualsAndAds(filteredData);
};

const fillMissingLabels = (data, filteredData) => {
  const notInData = filteredData.filter(
    ({ name }) => !data.some((label) => label.name === name)
  );

  return [...data, ...notInData];
};

export const makeSearchLookup = (
  data,
  filteredData,
  library,
  allKeywords,
  keywordsData,
  adNames
) => {
  if (!data.length || !filteredData.length) return [];
  const fullData = fillMissingLabels(data, filteredData);
  const allVisualsLabel = fullData.find((l) => l.value === 'all_visuals');

  const allVisuals = allVisualsLabel
    ? [...allVisualsLabel.winners, ...allVisualsLabel.losers]
    : [];

  const keywordLabels = allKeywords.map((k) => {
    const visualHashesFound = Object.entries(keywordsData).reduce(
      (acc, [key, val]) => {
        if (val.includes(k)) {
          acc.push(key);
        }

        return acc;
      },
      []
    );

    const visuals = visualHashesFound.reduce((acc, curr) => {
      const match = allVisuals.find((v) => v.id === curr);
      if (match) acc.push(match);
      return acc;
    }, []);

    return {
      name: k,
      value: k,
      category: 'Keyword',
      isUdc: false,
    };
  });

  const visualsAdNames = adNames.map((name) => {
    const nameTrimmed = name && name.length ? name.replace(/  +/g, ' ') : null;

    return {
      adName: nameTrimmed,
      name: nameTrimmed,
      label: nameTrimmed,
      value: nameTrimmed,
      category: null,
    };
  });

  return [...fullData, ...keywordLabels, ...visualsAdNames]
    .reduce((acc, label, i) => {
      // if (i % 20 === 0) {
      //   console.log({ label, i });
      // }
      if (
        (!label.category ||
          !label.name ||
          DISABLED_CATEGORIES.includes(label.category)) &&
        !label.adName
      ) {
        return acc;
      }
      const combined = !label.adName
        ? `${label.category} -> ${label.name}`
        : label.adName;
      const isAllVisuals = label.value === 'all_visuals';
      const isAudience = label.category === 'Audience';
      let visuals = [];

      if (label.winners) visuals = [...visuals, ...label.winners];
      if (label.losers) visuals = [...visuals, ...label.losers];

      if (
        !isAllVisuals &&
        !isAudience &&
        !library.labels.some((x) => labelsAreMatching(x, label)) &&
        !library.adNames.some((x) => labelsAreMatching(x, label)) &&
        !acc.some((x) => x.value === combined)
      ) {
        acc.push({
          adName: label.adName,
          name: label.name,
          label: combined,
          value: combined,
          labelObj: label,
          category: label.category,
          visuals,
        });
      }

      return acc;
    }, [])
    .sort((a, b) => {
      return (
        !!a.adName - !!b.adName ||
        stripQuotes(a.value).localeCompare(stripQuotes(b.value))
      );
    });
};

const stripQuotes = (val) => val.replace(/['"]+/g, '');

export const labelsAreMatching = (label, comparison) => {
  return (
    label.value === comparison.value && label.category === comparison.category
  );
};
