/* eslint-disable no-fallthrough */
import { batch } from 'react-redux';
import {
  REPORTS,
  REPORTS_FETCH,
  REPORTS_SET,
  REPORTS_P2,
  REPORTS_FETCH_P2,
  REPORTS_SET_P2,
  setReports,
  fetchReports,
  setReportsP2,
  fetchReportsP2,
} from './actions';
import { API_SUCCESS, apiRequest, API_FAILURE } from '../Api/actions';
import { setLoader } from '../Loaders/actions';
import { setError } from '../Errors/actions';
import {
  ADSET_NAME,
  AD_STUDY,
  AD_LABELS,
  AD_NAME,
  PERFORMANCE_FREQUENCY,
  VISUAL_HASH,
} from './constants';
import { getAppSearchParams } from '../utils';

import { createAdNamesLabelsLookup } from '../Lookups';

/**
 * @description Extend report store with ad-label data.
 */
function extendWith(dispatch, getState, { report_type, data }, chart_type) {
  if (report_type === AD_LABELS) {
    if (chart_type === PERFORMANCE_FREQUENCY) {
      batch(() => {
        dispatch(fetchReports(AD_NAME));
        dispatch(fetchReports(VISUAL_HASH));
      });
    }
    dispatch(createAdNamesLabelsLookup(data));
  } else {
    // const {
    //   CreativeReports: { reports },
    // } = getState();

    // if (!reports[AD_LABELS]) {
    //   dispatch(fetchReports(AD_LABELS));
    // }
  }
}

function reportExists({ CreativeReports, Adsets: { adsets } }, report_type, period) {
  let existingReport = CreativeReports.reports[report_type];

  if (period === "two") {
    existingReport = CreativeReports.reports_p2[report_type];
  }

  switch (report_type) {
    case AD_STUDY:
      return false;
      
      case ADSET_NAME:
      return !!adsets;

    default:
      return !!existingReport;
  }
}

const middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case REPORTS_FETCH: {
        const {
          ViewSettings: { campaigns, account_id, isDirectMail },
        } = getState();

        const {
          start_date,
          end_date,
          selectedAudience,
          // report_type,
          view_type,
        } = getAppSearchParams(isDirectMail, action.data.period);
        // Override URL params if present.
        const reportType = action.data.reportType;
        const ignoreCheck = action.data.ignoreCheck || false;
        const period = action.data.period || "one";
        const uri = `api${window.location.pathname}`.replace(
          'analytics',
          'creative-reports',
        );

        dispatch(setError(REPORTS, false));

        // if (!reportExists(getState(), reportType, period) && !ignoreCheck) {
        //   console.log(reportExists(getState(), reportType, period), ignoreCheck)
        //   dispatch({
        //     type: `[${REPORTS}] FETCHED_FROM_STORE`,
        //   });
        // } else {
          console.log(reportType)
          batch(() => {
            dispatch(setLoader(REPORTS, true));
            dispatch(
              apiRequest({
                body: {
                  report_type: reportType,
                  start_date,
                  end_date,
                  audience: [selectedAudience].filter((a) => a !== ''),
                  campaigns,
                  account_id,
                  view_type,
                  period,
                },
                url: uri,
                entity: REPORTS,
                method: 'POST',
              })
            );
          });
        // }

        break;
      }

      case `[${REPORTS}] ${API_SUCCESS}`: {
        const { response } = action;
        const {
          CreativeReports: { chart_type },
        } = getState();

        const period = response.data.period;


        let handleSetReports = setReports({
          reports: { [response.report_type]: response.report_type == AD_NAME ? response.data || [] : response.data.data || [] },
        });

        if (period && period === "two") {
          handleSetReports = setReports({
            reports_p2: { [response.report_type]: response.data.data || [] },
          });
        }

        batch(() => {
          dispatch(handleSetReports);
          dispatch(setLoader(REPORTS, false));
        });

        extendWith(dispatch, getState, response.data, chart_type);

        break;
      }

      case `[${REPORTS}] ${API_FAILURE}`: {
        const { error } = action;

        batch(() => {
          dispatch(setError(REPORTS, error));
          dispatch(setLoader(REPORTS, false));
        });

        break;
      }

      default:
        return result;
    }

    return result;
  };

export default middleware;
