import React, { useState, useMemo, useRef, useEffect } from 'react';
import Tooltip from '../../../components/Tooltip';
import { toCurrency, toFixed_2 } from '../../../utils/numbers';
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from '../contexts';
import CardOptions from './CardOptions';
import { copyTextToClipboard } from '../TryThese/utils';

import './VisualCard.scss';
import { SECTION_HEADERS } from './constants';
import { useWindowSize } from '../PerformanceFrequency/hooks';
import {
  SET_SECOND_VALUE,
  SET_SECOND_VISUAL_TYPE,
} from '../DraftCreative/hooks/useDraftCreativeState';

const VisualCard = ({
  visualObj,
  index,
  isHeader,
  section,
  isImpactReport,
  isROAS,
  setParams,
  fromDraftCreative,
  handleBlockValue,
  handleBlockVisualType,
}) => {
  // const { isROAS } = useCreativeAnalyticsGet();
  // const { setParams } = useCreativeAnalyticsSet();
  const size = useWindowSize();
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [showNav, setShowNav] = useState(size.width > 768 ? false : true);
  const [showOptions, setShowOptions] = useState(false);
  console.log({showOptions})
  const [copied, setCopied] = useState(false);
  const vidRef = useRef(null);
  const medianMetric = isROAS ? 'median_roas' : 'median_cpa';

  // contains filter options, and all visuals carousel
  const { visuals } = visualObj;
  const headerCPA = isROAS ? 1 / visuals[0]?.cpa : visuals[0]?.cpa;

  const format = (val) => {
    if (!isROAS) {
      return toCurrency(val);
    }
    return toFixed_2(val);
  };

  let cpa = isHeader ? headerCPA : visualObj[medianMetric];
  const spend = isHeader ? visuals[0].spend : visualObj.spend;

  if (typeof cpa === 'string') {
    cpa = Number(cpa.replace(/[^0-9.-]+/g, ''));
  }

  let label = visualObj.label || null;

  const currentVisual = useMemo(
    () => visuals[carouselIndex] || visuals[0],
    [visualObj, carouselIndex]
  );

  const prev = () => {
    if (carouselIndex > 0) {
      setCarouselIndex((prevState) => prevState - 1);
    }
  };
  const next = () => {
    if (carouselIndex < visuals.length - 1) {
      setCarouselIndex((prevState) => prevState + 1);
    }
  };

  useEffect(() => {
    setCarouselIndex(0);
  }, [visualObj]);

  const handleClick = () => {
    if (isImpactReport) return;
    if (fromDraftCreative) {
      handleBlockValue(SET_SECOND_VALUE, currentVisual.asset_url);
      handleBlockVisualType(SET_SECOND_VISUAL_TYPE, currentVisual.visual_type);
      return;
    }
    setParams({ rc: currentVisual.id });
  };

  let preview = <p className="text-white">There was an error.</p>;

  const {
    visual_type,
    asset_url,
    thumbnail_url,
    spend: currentSpend,
  } = currentVisual;

  const currentCpa = isROAS ? currentVisual?.roas : currentVisual?.cpa;

  useEffect(() => {
    vidRef.current?.load();
  }, [asset_url, thumbnail_url]);

  if (visual_type === 'image') {
    preview = <img onClick={handleClick} src={asset_url} alt="" />;
  } else if (visual_type === 'video') {
    const url = asset_url.length ? asset_url : thumbnail_url;
    preview = (
      <>
        {asset_url ? (
          <video
            onClick={handleClick}
            key={url}
            autoPlay
            loop
            muted
            plays-inline="true"
            ref={vidRef}
          >
            <source src={url} type="video/mp4" />
          </video>
        ) : (
          <div
            onClick={handleClick}
            style={{ height: '100%' }}
            className="d-flex justify-content-center"
          >
            <img
              src={thumbnail_url}
              alt=""
              style={{ objectFit: 'contain', height: '100%' }}
            />
          </div>
        )}
      </>
    );
  }

  let Overlay = (
    <div className="visual-card__label">
      <p>{label}</p>
    </div>
  );

  const cardNav = (
    <div className="visual-card__nav">
      <div className="d-flex justify-content-between align-items-center w-100">
        <i
          onClick={prev}
          className={`fa fa-arrow-left nav-arrow ${
            carouselIndex > 0 ? 'visible' : ''
          }`}
        ></i>
        <p style={{ userSelect: 'none' }}>
          {carouselIndex + 1} of {visuals.length}
        </p>
        <i
          onClick={next}
          className={`fa fa-arrow-right nav-arrow ${
            carouselIndex < visuals.length - 1 ? 'visible' : ''
          }`}
        ></i>
      </div>
    </div>
  );

  if (showNav && visuals.length > 1) {
    Overlay = cardNav;
  }

  if (isImpactReport || fromDraftCreative) Overlay = null;

  const handleMouseEnter = () => {
    setShowNav(true);
    setShowOptions(true);
  };

  const handleMouseLeave = () => {
    setShowNav(false);
    setShowOptions(false);
  };

  let display = `${SECTION_HEADERS[section]}: ${label} (Rank ${(
    '00' + index
  ).slice(-2)})`;

  if (!section) {
    display = `Top Spending: Rank ${('00' + index).slice(-2)}`;
  }

  return (
    <div
      className={`visual-card-wrapper ${isImpactReport ? 'impact' : ''}`}
      onMouseLeave={handleMouseLeave}
    >
      {copied && <CopyPopup setCopied={setCopied} />}
      <div className="visual-card">
        <Tooltip
          follow={false}
          content={
            <div>
              {label && <div>{label}</div>}
              <div>Spend: {toCurrency(spend)}</div>
              <div>
                {!isHeader && 'Median '}
                {isROAS ? 'ROAS' : 'CPA'}:{' '}
                {!isFinite(cpa) ? (isROAS ? '0.00' : '-') : format(cpa)}
              </div>
            </div>
          }
          className={`visual-card__tooltip ${showOptions ? 'visible' : ''}`}
          disabled={isImpactReport}
        >
          <div
            className={`visual-card__img`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {visual_type === 'video' && (
              <i
                className={`visual-card__play-icon ${
                  asset_url ? 'fa fa-play' : 'fa-solid fa-video-slash'
                }`}
              ></i>
            )}
            {preview}
            {showOptions && !isImpactReport && !fromDraftCreative && (
              <CardOptions
                overviewDisplay={display}
                label={label}
                url={asset_url}
                visual_type={visual_type}
              />
            )}

            {label && Overlay}
          </div>
        </Tooltip>

        {!isImpactReport && (
          <p className="visual-card__index">Rank {('00' + index).slice(-2)}</p>
        )}
        {isImpactReport && (
          <>
            <div className="visual-card__details">
              <div>
                <span>Spend: </span>
                {toCurrency(currentSpend)}
              </div>
              <div>
                <span>{isROAS ? 'ROAS' : 'CPA'}: </span>
                {!isFinite(currentCpa) || currentCpa === null
                  ? isROAS
                    ? '0.00'
                    : '-'
                  : format(currentCpa)}
              </div>
            </div>
            {cardNav}
          </>
        )}
      </div>
    </div>
  );
};

export default VisualCard;

export const CopyPopup = ({ setCopied }) => {
  const [alert, setAlert] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
      setCopied(false);
    }, 3000);
  }, []);

  if (!alert) return null;
  return <div className="recommendation-card__popup">Copied asset URL</div>;
};
