import React from 'react';
import { DARWIN_NAVY_5 } from '../../../constants';

const StackedIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.18556 1.17004H2.11526C1.70346 1.17004 1.36963 1.50387 1.36963 1.91568V6.98598C1.36963 7.39778 1.70346 7.73161 2.11526 7.73161H7.18556C7.59736 7.73161 7.93119 7.39778 7.93119 6.98598V1.91568C7.93119 1.50387 7.59736 1.17004 7.18556 1.17004Z"
      stroke={DARWIN_NAVY_5}
      stroke-width="1.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.1332 1.17004H11.0629C10.6511 1.17004 10.3173 1.50387 10.3173 1.91568V6.98598C10.3173 7.39778 10.6511 7.73161 11.0629 7.73161H16.1332C16.545 7.73161 16.8788 7.39778 16.8788 6.98598V1.91568C16.8788 1.50387 16.545 1.17004 16.1332 1.17004Z"
      stroke={DARWIN_NAVY_5}
      stroke-width="1.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.18556 10.1176H2.11526C1.70346 10.1176 1.36963 10.4514 1.36963 10.8632V15.9335C1.36963 16.3453 1.70346 16.6791 2.11526 16.6791H7.18556C7.59736 16.6791 7.93119 16.3453 7.93119 15.9335V10.8632C7.93119 10.4514 7.59736 10.1176 7.18556 10.1176Z"
      stroke={DARWIN_NAVY_5}
      stroke-width="1.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.1332 10.1176H11.0629C10.6511 10.1176 10.3173 10.4514 10.3173 10.8632V15.9335C10.3173 16.3453 10.6511 16.6791 11.0629 16.6791H16.1332C16.545 16.6791 16.8788 16.3453 16.8788 15.9335V10.8632C16.8788 10.4514 16.545 10.1176 16.1332 10.1176Z"
      stroke={DARWIN_NAVY_5}
      stroke-width="1.125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default StackedIcon;
