export const getUniqueLabels = (data) => {
  const allLabels = data.reduce((acc, row) => {
    const updated = [...acc, ...row.custom_trained_labels];
    return updated;
  }, []);

  const unique = [...new Set(allLabels)];

  return unique;
};
