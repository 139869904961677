import React, { createContext, useContext, useReducer } from 'react';
import { ExplorerState, DrawerState } from '../types';
import { initialDrawerState } from '../constants';

export const ExplorerContext = createContext<ExplorerState>({
  drawerState: initialDrawerState,
  drawerDispatch: () => null,
  subDrawerState: initialDrawerState,
  subDrawerDispatch: () => null,
});

interface Props {
  explorerState: ExplorerState;
  children: React.ReactNode;
}

// explorer state is declared in CreativeAnalytics.tsx
const ExplorerContextProvider = ({ explorerState, children }: Props) => {
  return (
    <ExplorerContext.Provider
      value={explorerState}
    >
      {children}
    </ExplorerContext.Provider>
  );
};

export default ExplorerContextProvider;

export function useExplorerContext() {
  return useContext(ExplorerContext);
}