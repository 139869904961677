//@ts-check
import React, { useContext } from "react";

import { WizardContext } from "./Context";
import { TargetCampaign, ComparisonCampaigns } from "./FormElements";
import { useDetails } from "./DetailSwitch";

export function Details() {
  const {
    handleChange,
    campaign_id,
    comparison_campaigns,
    automation_study_type,
    testing_study_type,
    study_type,
    inProgress,
  } = useContext(WizardContext);
  const Details = useDetails(
    automation_study_type,
    testing_study_type,
    study_type
  );

  const alwaysRendered = (
    <>
      <TargetCampaign
        name="campaign_id"
        onChange={handleChange}
        value={campaign_id}
        disabled={inProgress}
        automation_study_type={automation_study_type}
      />
      <ComparisonCampaigns
        name="comparison_campaigns"
        onChange={handleChange}
        value={comparison_campaigns}
        disabled={inProgress}
        automation_study_type={automation_study_type}
      />
    </>
  );

  return (
    <>
      {alwaysRendered}

      <Details.Component />
    </>
  );
}
