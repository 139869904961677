import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const GetCtx = createContext(null);
const SetCtx = createContext(null);

export function BlockParamsProvider({
  breakdown, period1, period2, include_ga_data, setParams, children,
}) {
  return (
    <GetCtx.Provider value={{ breakdown, period1, period2, include_ga_data }}>
      <SetCtx.Provider value={setParams}>
        {children}
      </SetCtx.Provider>
    </GetCtx.Provider>
  );
}

BlockParamsProvider.defaultProps = {
  period2: '',
  include_ga_data: false,
};

BlockParamsProvider.propTypes = {
  breakdown: PropTypes.string.isRequired,
  period1: PropTypes.string.isRequired,
  period2: PropTypes.string,
  include_ga_data: PropTypes.bool,
  setParams: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export function useBlockParamsGet() {
  return useContext(GetCtx);
}

export function useBlockParamsSet() {
  return useContext(SetCtx);
}
