import React from "react";

export const FilterIcon = ({ active }) => {
  return (
    <svg
      className={`filter-icon ${active || "inactive"}`}
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.615417 0.859375H12.6154M2.75827 3.85938H10.4726M5.3297 6.85938H7.90113"
        stroke="#BDBDBD"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
