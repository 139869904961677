export const validate = (conditions) => {
  const seenConditions = {};
  let errors = [];

  conditions.forEach((cond) => {
    const key = `${cond.metric}_${cond.comparison}`;

    if (seenConditions[key]) {
      errors.push(
        'Two conditions cannot share the same metric and comparison.'
      );
    }
    seenConditions[key] = true;
  });

  return errors;
};
