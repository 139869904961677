/* eslint-disable arrow-body-style */
import * as ss from 'simple-statistics';
import { DARWIN_INDEX_LOOKUP } from './constants';
import {
  useGetAggregate,
  useGetAverage,
  getPercentBudget,
  useGetMedian,
  useGetTotalResults,
  useGetPercentResults,
  useGetPercentResultsDiff,
  getSpend,
} from './utils';

export const isNewAd = (ad, newAds) => newAds.some((newAd) => newAd === ad.id);

export const notInData = (label, data) =>
  !data.some((obj) => obj.value === label.value);

export const shouldIncludeLabel = (label, categoryFilters) => {
  const {
    showOnlyComplianceCategories,
    showComplianceCategories,
    showAdditionalCategories,
    includeUdc,
  } = categoryFilters;
  if (label.type === 'synonyms') return false;
  if (showOnlyComplianceCategories) return label.compliance;
  if (!includeUdc) return !label.udc;

  const complianceCheck = showComplianceCategories || !label.compliance;

  if (complianceCheck) {
    return true;
  }

  return false;
};

export const findSynonymOthers = (label, allVisuals, totalBudget) => {
  if (!(label.synonym_type === 'DW')) return null;

  const label_winners = [...label.winners];
  const label_losers = [...label.losers];

  const all_winners = [...allVisuals.winners];
  const all_losers = [...allVisuals.losers];

  const otherWinners = all_winners.filter((visual) => {
    return !label_winners.find((x) => x.id === visual.id);
  });
  const otherLosers = all_losers.filter((visual) => {
    return !label_losers.find((x) => x.id === visual.id);
  });
  const otherVisuals = [...otherWinners, ...otherLosers];

  return {
    name: `Other (${label.name})`,
    value: `${label.value}_OTHER`,
    category: label.category,
    winners: otherWinners,
    losers: otherLosers,
    numerator: otherWinners.length,
    denominator: otherWinners.length + otherLosers.length,
    visuals: otherVisuals,
    synonym: true,
    visual_mode: label.visual_mode,
    client_down_funnel_events: label.client_down_funnel_events,
    percent_budget: getPercentBudget(otherVisuals, totalBudget),
    performance_percent: Math.round(
      (otherWinners.length / otherVisuals.length) * 100
    ),
    compliance: label.compliance,
    description: label.description,
    example: label.example,
  };
};

export const updateSynonymLabel = (
  synonymLabel,
  synUpdatedWinners,
  synUpdatedLosers,
  synUpdatedVisuals,
  totalBudget,
  totalResults,
  totalClicks,
  isROAS,
  curr
) => ({
  ...synonymLabel,
  category: synonymLabel.category,
  numerator: synUpdatedWinners.length,
  denominator: synUpdatedVisuals.length,
  performance_percent: Math.round(
    (synUpdatedWinners.length / synUpdatedVisuals.length) * 100
  ),
  aggregate_cpa: useGetAggregate(synUpdatedVisuals, 'cpa'),
  average_cpa: useGetAverage(synUpdatedVisuals, 'cpa'),
  median_cpa: useGetMedian(synUpdatedVisuals, 'cpa'),
  aggregate_roas: useGetAggregate(synUpdatedVisuals, 'roas'),
  average_roas: useGetAverage(synUpdatedVisuals, 'roas'),
  median_roas: useGetMedian(synUpdatedVisuals, 'roas'),
  total_results: useGetTotalResults(synUpdatedVisuals, 'results', isROAS),
  percent_of_results: useGetPercentResults(
    synUpdatedVisuals,
    'results',
    totalResults,
    isROAS
  ),
  percent_of_results_diff: useGetPercentResultsDiff(
    synUpdatedVisuals,
    totalResults,
    totalBudget,
    'results',
    isROAS
  ),
  aggregate_cpm: useGetAggregate(synUpdatedVisuals, 'cpm'),
  average_cpm: useGetAverage(synUpdatedVisuals, 'cpm'),
  median_cpm: useGetMedian(synUpdatedVisuals, 'cpm'),
  aggregate_cpc: useGetAggregate(synUpdatedVisuals, 'cpc'),
  average_cpc: useGetAverage(synUpdatedVisuals, 'cpc'),
  median_cpc: useGetMedian(synUpdatedVisuals, 'cpc'),
  aggregate_ctr: useGetAggregate(synUpdatedVisuals, 'ctr'),
  average_ctr: useGetAverage(synUpdatedVisuals, 'ctr'),
  median_ctr: useGetMedian(synUpdatedVisuals, 'ctr'),
  ga_aggregate_ctr: useGetAggregate(synUpdatedVisuals, 'ga_ctr'),
  ga_average_ctr: useGetAverage(synUpdatedVisuals, 'ga_ctr'),
  ga_median_ctr: useGetMedian(synUpdatedVisuals, 'ga_ctr'),
  total_link_clicks: useGetTotalResults(synUpdatedVisuals, 'clicks'),
  percent_of_link_clicks: useGetPercentResults(
    synUpdatedVisuals,
    'clicks',
    totalClicks
  ),
  percent_of_link_clicks_diff: useGetPercentResultsDiff(
    synUpdatedVisuals,
    'clicks',
    totalClicks,
    totalBudget
  ),
  number_unique_creatives: synUpdatedVisuals.length,
  percent_of_creative_count: 0,
  spend: getSpend(synUpdatedVisuals, 'spend'),
  winners: synUpdatedWinners,
  losers: synUpdatedLosers,
  percent_budget: getPercentBudget(synUpdatedVisuals, totalBudget),
  darwin_index: DARWIN_INDEX_LOOKUP[synonymLabel.name] || '-',
  visuals: synUpdatedVisuals,
  wins_over_all: `${synUpdatedWinners.length}/${synUpdatedVisuals.length}`,
});

const findLabelQuantile = ({ first, second, third, fourth }, value, isROAS) => {
  if (!value || value === 0) return 1;

  if (value >= fourth) return isROAS ? 5 : 1;
  if (value < fourth && value >= third) return isROAS ? 4 : 2;
  if (value < third && value >= second) return isROAS ? 3 : 3;
  if (value < second && value >= first) return isROAS ? 2 : 4;
  if (value < first) return isROAS ? 1 : 5;
};

export const getCPAQuantile = (label, cpasList, isROAS) => {
  if (!cpasList || cpasList.length === 0) return 0;
  if (!cpasList || cpasList.length === 0) return 0;
  const metric = isROAS ? 'average_roas' : 'average_cpa';

  const first = ss.quantile(cpasList, 0.2);
  const second = ss.quantile(cpasList, 0.4);
  const third = ss.quantile(cpasList, 0.6);
  const fourth = ss.quantile(cpasList, 0.8);

  const cpa_quantile = findLabelQuantile(
    {
      first,
      second,
      third,
      fourth,
    },
    label[metric] || 0,
    isROAS
  );

  return cpa_quantile;
};

export const getCPAQuantileDebug = (label, cpasList, isROAS) => {
  if (!cpasList) return 0;
  const metric = isROAS ? 'average_roas' : 'average_cpa';

  const first = ss.quantile(cpasList, 0.2);
  const second = ss.quantile(cpasList, 0.4);
  const third = ss.quantile(cpasList, 0.6);
  const fourth = ss.quantile(cpasList, 0.8);

  const cpa_quantile = findLabelQuantile(
    {
      first,
      second,
      third,
      fourth,
    },
    label[metric] || 0,
    isROAS
  );

  return {
    cpa: label[metric] || 0,
    cpa_quantile,
    first,
    second,
    third,
    fourth,
  };
};
