import React, { useState, useEffect } from "react";
import "./Rule.scss";
import Tippy from "@tippyjs/react";
import Select from "react-select";
import { EMPTY_RULE, PB_SELECT_STYLES } from "./constants";
import {
  ADD_INCLUSION,
  ADD_EXCLUSION,
  REMOVE_RULE,
  REMOVE_INCLUSION,
  REMOVE_EXCLUSION,
} from "./reducer";
import { TrashIcon, WarningIcon } from "./Helpers";
import PhraseBuilderModal from "./Modal";

const Rule = ({
  rules,
  phraseBuilder,
  data,
  rule,
  index,
  handleAddRule,
  phraseIndex,
  changesMade,
  editMode,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const isEmpty = _.isEqual(rule, EMPTY_RULE);
  const isIncomplete = collapsed && isEmpty;

  const toggleCollapse = () => {
    setCollapsed((prev) => !prev);
  };

  const handleAddInclusion = (selected, index) => {
    phraseBuilder.dispatchPhraseBuilder({
      type: ADD_INCLUSION,
      payload: {
        phraseIndex: phraseIndex,
        ruleIndex: index,
        keyword: selected.label,
      },
    });
  };

  const handleAddExclusion = (selected, index) => {
    phraseBuilder.dispatchPhraseBuilder({
      type: ADD_EXCLUSION,
      payload: {
        phraseIndex: phraseIndex,
        ruleIndex: index,
        keyword: selected.label,
      },
    });
  };

  const handleRemoveInclusion = (keyword, index) => {
    phraseBuilder.dispatchPhraseBuilder({
      type: REMOVE_INCLUSION,
      payload: {
        phraseIndex: phraseIndex,
        ruleIndex: index,
        keyword: keyword,
      },
    });
  };

  const handleRemoveExclusion = (keyword, index) => {
    phraseBuilder.dispatchPhraseBuilder({
      type: REMOVE_EXCLUSION,
      payload: {
        phraseIndex: phraseIndex,
        ruleIndex: index,
        keyword: keyword,
      },
    });
  };

  const handleDeleteRule = (index) => {
    phraseBuilder.dispatchPhraseBuilder({
      type: REMOVE_RULE,
      payload: {
        phraseIndex: phraseIndex,
        ruleIndex: index,
      },
    });

    if (rules.length === 1 && index === 0) {
      handleAddRule();
    }

    setShowConfirm(false);
  };

  const handleClickAway = (e) => {
    if (e.target.classList.value.includes("delete-icon-overlay")) return;
    setShowConfirm(false);
  };

  const handleShowConfirm = () => {
    setShowConfirm((prev) => !prev);
  };

  const keywordOptions = data.visual_keywords.filter(
    (k) => ![...rule.include, ...rule.exclude].includes(k)
  );

  const selectionProps = {
    rule,
    index,
    keywordOptions,
    handleAddInclusion,
    handleAddExclusion,
    handleRemoveInclusion,
    handleRemoveExclusion,
  };

  return (
    <div className="create-edit__form-section">
      <div
        className={`rule-heading__container ${collapsed ? " collapsed" : ""}${
          isIncomplete ? " incomplete" : ""
        }`}
      >
        {changesMade || editMode ? (
          <>
            {isEmpty ? (
              <Tippy
                content="Incomplete rule"
                className="phrase-builder-tooltip"
                duration={200}
                delay={250}
                placement="left"
              >
                <i className="fa-regular fa-square-minus rule-in-progress"></i>
              </Tippy>
            ) : (
              <i className="fa-solid fa-check rule-complete"></i>
            )}
          </>
        ) : null}
        <div
          onClick={toggleCollapse}
          className={`rule-heading${collapsed ? " collapsed" : ""}${
            isIncomplete ? " incomplete" : ""
          }`}
        >
          <div className="d-flex align-items-center justify-content-center">
            <i className="fa-solid fa-chevron-down mr-3"></i>
            <h4>Rule {index + 1}</h4>
          </div>
          {isIncomplete && (
            <span className="incomplete-rule">Incomplete rule</span>
          )}
        </div>
      </div>
      <div className="rule-setup">
        <div className="rule-setup__container">
          <KeywordSelection type="Include" {...{ ...selectionProps }} />
          <KeywordSelection type="Exclude" {...{ ...selectionProps }} />
          {rules.length > 1 && (
            <div className="d-flex justify-content-end position-relative">
              <PhraseBuilderModal
                handleClickAway={handleClickAway}
                show={showConfirm}
                handleSetShow={handleShowConfirm}
                handleConfirm={() => handleDeleteRule(index)}
                message={"Delete this rule?"}
                className=""
              >
                <>
                  <span
                    className="delete-icon-overlay"
                    onClick={handleShowConfirm}
                  ></span>
                  <TrashIcon className={"delete-icon"} />
                </>
              </PhraseBuilderModal>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Rule;

const KeywordSelection = ({
  type,
  rule,
  index,
  keywordOptions,
  handleAddInclusion,
  handleAddExclusion,
  handleRemoveInclusion,
  handleRemoveExclusion,
}) => {
  const isInclude = type === "Include";

  return (
    <div className="keyword-selection">
      <Tippy
        content={`The phrase must contain ${
          isInclude ? "ALL" : "NONE"
        } of these keywords`}
        placement="right"
        className="phrase-builder-tooltip"
      >
        <h4>{type}:</h4>
      </Tippy>
      <Select
        options={keywordOptions.map((k) => {
          return {
            label: k,
            value: k,
          };
        })}
        onChange={(selected) => {
          if (isInclude) {
            handleAddInclusion(selected, index);
          } else {
            handleAddExclusion(selected, index);
          }
        }}
        value={null}
        placeholder="Choose keywords..."
        className="keyword-selection__dropdown"
        styles={PB_SELECT_STYLES}
      />
      <div>
        <ul className="keyword-selection__list">
          {rule[type.toLowerCase()].length ? (
            rule[type.toLowerCase()].map((k) => {
              return (
                <li className="d-flex justify-content-between align-items-center">
                  <span className="keyword-label">"{k}"</span>
                  <span
                    className="keyword-delete"
                    onClick={() => {
                      if (isInclude) {
                        handleRemoveInclusion(k, index);
                      } else {
                        handleRemoveExclusion(k, index);
                      }
                    }}
                  >
                    <i className="fa-regular fa-circle-xmark"></i>
                  </span>
                </li>
              );
            })
          ) : (
            <span>No {`${isInclude ? "inclusions" : "exclusions"}`}.</span>
          )}
        </ul>
      </div>
    </div>
  );
};
