import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import Tooltip from '../Tooltip';

import CustomDropdown from '../CustomDropdown';
import { useSearchParams } from '../Hooks/search-params';
import { setViewSettings } from '../../store/ViewSettings/actions';
import { trackEvent } from '../../store/UserActivity/actions';
import {
  CHANGE_SPEND_THRESHOLD,
  CREATIVE_ANALYTICS,
} from '../../store/UserActivity/constants';
import {
  resetReports,
  resetPeriod2,
} from '../../store/CreativeReports/actions';

const useOptions = (isROAS) => {
  const standard = [
    {
      label: '$1',
      value: 1,
    },
    {
      label: '$50',
      value: 50,
    },
    {
      label: '$100',
      value: 100,
    },
    {
      label: '$500',
      value: 500,
    },
    {
      label: '$1000',
      value: 1000,
    },
  ];

  const analytics = [
    ...standard,
    {
      label: '3x Average CPA',
      value: 3,
    },
    {
      label: '5x Average CPA',
      value: 5,
    },
    {
      label: '10x Average CPA',
      value: 10,
    },
  ];

  if (isAnalytics() && !isROAS) {
    return analytics;
  }
  return standard;
};

function isAnalytics() {
  return window.location.pathname.includes('analytics');
}
function Picker({
  setView,
  creativeCount,
  chart_type,
  toggleStyle,
  toggleClass,
  aggregate,
  isROAS,
}) {
  const _id = 'spend-threshold-picker-button';
  const dispatch = useDispatch();

  const { getParams, setParams } = useSearchParams();
  const params = getParams('spendThreshold');

  const handleSelect = (eventKey, _) => {
    const parsed = parseFloat(eventKey);
    const multiplier = [3, 5, 10].includes(parsed);

    if (parsed === params.spendThreshold) {
      return null;
    }

    if (isAnalytics()) {
      dispatch(trackEvent(CREATIVE_ANALYTICS, CHANGE_SPEND_THRESHOLD));
    }

    const update = {
      spendThreshold: !multiplier ? parsed : parsed * aggregate,
      creativeCount,
    };

    setParams(update, () => setView(update));
    dispatch(resetPeriod2());
  };

  useEffect(() => {
    if (isAnalytics()) {
      if (
        params.spendThreshold === null ||
        params.spendThreshold === undefined
      ) {
        handleSelect(500);
      } else {
        handleSelect(parseInt(params.spendThreshold, 10));
      }
    }
  }, [chart_type]);

  return (
    <CustomDropdown
      id={_id}
      onSelect={handleSelect}
      label={
        <Tooltip content="Minimum spend of visuals">
          <span>
            <i className="fa fa-money-bill mr-2"></i> ${params.spendThreshold}
          </span>
        </Tooltip>
      }
      toggleStyle={toggleStyle}
      toggleClass={toggleClass}
    >
      {useOptions(isROAS).map(({ value, label }) => (
        <CustomDropdown.Item key={label} eventKey={value} className="py-2">
          {label}
        </CustomDropdown.Item>
      ))}
    </CustomDropdown>
  );
}

Picker.propTypes = {
  setView: PropTypes.func.isRequired,
};

const stateToProps = (
  {
    ViewSettings: { spendThreshold, creativeCount },
    CreativeReports: { chart_type },
  },
  props
) => ({
  spendThreshold,
  creativeCount,
  chart_type,
});

const dispatchToProps = (dispatch, props) => ({
  setView: (settings) => dispatch(setViewSettings(settings)),
});

export const SpendThresholdPicker = connect(
  stateToProps,
  dispatchToProps
)(Picker);
