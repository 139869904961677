import React, { useEffect, useRef, useState, useMemo } from 'react';
import './EditTest.scss';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import Button from '../../components/Button';
import { DARWIN_YELLOW_2, SELECT_STYLES, SELECT_STYLES_GRAY } from '../../constants';
import { useCreativeTestingApi, useBlocksApi } from './api';
import { darwinDateToDate, toDarwinDate } from '../../utils/darwin-dates';
import { withoutPrefix, toOptions } from './utils';
import BlockFormWrapper from '../CreativeReports/BlockReport/BlockForm';
import { DARWIN_CREATIVE_TESTING } from './constants';

const DEFAULT_TEST = {
  test_name: '',
  test_description: '',
  start_date: new Date(),
  end_date: new Date(),
  test_block_id: '',
  compare_block_id: '',
  platform: '',
}

const EditTest = ({ existing, handleClose, account_id, refreshData, platforms, darwin_client_id }) => {
  const [test, setTest] = useState(
    existing ? {
      ...existing,
      start_date: darwinDateToDate(existing.start_date),
      end_date: darwinDateToDate(existing.end_date),
    } : DEFAULT_TEST
  );
  const [confirm, setConfirm] = useState(false);
  const [blocks, setBlocks] = useState([]);
  const [error, setError] = useState(null);
  const [editBlock, setEditBlock] = useState(null);
  const api = useCreativeTestingApi();
  const blocksApi = useBlocksApi();
  const loading = api.post.loading || api.delete.loading;

  const testBlock = useMemo(() => blocks.find((block) => block.value === test.test_block_id) || null, [blocks, test.test_block_id]);
  const compareBlock = useMemo(() => blocks.find((block) => block.value === test.compare_block_id) || null, [blocks, test.compare_block_id]);
  const platformOptions = platforms;

  const allowTestBlockEdit = testBlock && testBlock.value.length && testBlock.category === DARWIN_CREATIVE_TESTING;
  const allowCompareBlockEdit = compareBlock && compareBlock.value.length && compareBlock.category === DARWIN_CREATIVE_TESTING;

  const handleInput = (key, value) => {
    setTest((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  const fetchBlocks = () => {
    blocksApi.get.request(withoutPrefix(account_id));
  };

  useEffect(() => {
    fetchBlocks();
  }, []);

  useEffect(() => {
    if (blocksApi.get.data) {
      const { data } = blocksApi.get;
      setBlocks(toOptions(data));
    }
  }, [blocksApi.get.data]);

  useEffect(() => {
    if (api.post.data) {
      handleClose();
      refreshData();
    }
  }, [api.post.data]);

  useEffect(() => {
    if (api.delete.data) {
      handleClose();
      refreshData();
    }
  }, [api.delete.data]);

  useEffect(() => {
    if (api.post.error) {
      setError(api.post.error.error)
    }
  }, [api.post.error]);

  useEffect(() => {
    if (api.delete.error) {
      setError(api.delete.error.error)
    }
  }, [api.delete.error]);

  const isValid = () => {
    const { compare_block_id, test_block_id, test_name, test_description, start_date, end_date } = test;
    if (start_date > end_date) {
      setError('You chose a start date that is later than the end date.');
      return false;
    }

    if (!(test_block_id.length && compare_block_id.length && test_name.length && test_description.length)) {
      setError('Please fill out all fields.\nThey\'re not optional.');
      return false;
    }
    if (compare_block_id === test_block_id) {
      setError('You selected the same block twice.');
      return false;
    }

    return true;
  }
  const handleSubmit = () => {
    if (isValid()) {
      api.post.request({
        ...test,
        start_date: toDarwinDate(test.start_date),
        end_date: toDarwinDate(test.end_date),
        account_id: withoutPrefix(account_id),
        darwin_client_id: darwin_client_id,
      })
    }
  }

  const handleDelete = () => {
    if (confirm) {
      api.delete.request({
        ...test,
        test_name: test.test_name,
        account_id: withoutPrefix(account_id),
        test_block_id: test.test_block_id,
        compare_block_id: test.compare_block_id,
        darwin_client_id: darwin_client_id,
      })
    } else {
      setConfirm(true);
    }
  }

  const handleUpdateBlock = (blockId, field) => {
    if (editBlock) {
      switch (field) {
        case 'test':
          setTest((prev) => ({
            ...prev,
            test_block_id: blockId,
          }));
          break;
        case 'compare':
          setTest((prev) => ({
            ...prev,
            compare_block_id: blockId,
          }));
          break;
      }
    }
  }

  if (editBlock) {
    return (
      <BlockFormWrapper
        location="testing"
        predeterminedCategory="Darwin Creative Testing"
        formType={editBlock.type}
        blockId={editBlock.id}
        testField={editBlock.field}
        onClose={() => setEditBlock(null)}
        handleUpdateBlock={handleUpdateBlock}
        refreshBlocks={fetchBlocks}
      />
    )
  }

  const formatOptionLabel = ({ label, category }) => (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ color: "#808BA6", fontSize: 12 }}>
        {category}
      </div>
      <div>{label}</div>
    </div>
  );

  return (
    <div className="create-test">
      <div className="create-test__left">
        <div className="create-test__info">
          <input value={test.test_name} type="text" placeholder="Test name..." onChange={(e) => handleInput('test_name', e.target.value)} />
          <textarea value={test.test_description} name="" placeholder="Test description..." id="" cols="20" rows="3" onChange={(e) => handleInput('test_description', e.target.value)} />
          <div className="mt-3">
            <label htmlFor="">Platform</label>
            <div className="d-inline">
              <Select
                onChange={({ value }) => handleInput('platform', value)}
                options={platformOptions}
                styles={SELECT_STYLES_GRAY}
                placeholder="Select a platform..."
                value={platformOptions.find((p) => p.value === test.platform)}
              />
            </div>
          </div>
        </div>
        <div className="create-test__dates">
          <div className="mr-3">
            <label htmlFor="">Start Date</label>
            <DatePicker
              selected={test.start_date}
              className="text-center"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              onChange={(date) => handleInput('start_date', date)}
              popperClassName="create-test__datepicker"
              popperPlacement="bottom"
              placeholderText="Choose a date"
            />
          </div>
          <div>
            <label htmlFor="">End Date</label>
            <DatePicker
              selected={test.end_date}
              className="text-center"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              onChange={(date) => handleInput('end_date', date)}
              popperClassName="create-test__datepicker"
              popperPlacement="bottom"
              placeholderText="Choose a date"
            />
          </div>
        </div>
        <div className="create-test__note">
          <p><span>Note:</span> for test results to be valid the <span>test</span> and <span>control</span> blocks must pass the “All Things Being Equal” test where there are no different variables between the two blocks during the time period selected.</p>
        </div>
      </div>

      <div className="create-test__right">

        {blocks.length ? (
          <div>
            <div className="block-field pb-3">
              <label htmlFor="">Test Block</label>
              <Select
                onChange={({ value }) => handleInput('test_block_id', value)}
                formatOptionLabel={formatOptionLabel}
                options={blocks}
                styles={SELECT_STYLES}
                placeholder="Select a block..."
                value={testBlock}
              />
              <div>
                <button className="block-field__btn mr-3" onClick={() => setEditBlock({
                  field: 'test', type: 'Create', id: '',
                })}>
                  <i className="fa fa-plus" />
                  Create
                </button>

                {allowTestBlockEdit ? (
                  <button className="block-field__btn" onClick={() => setEditBlock({
                    field: 'test', type: 'Edit', id: test.test_block_id,
                    })}>
                  <i className="fa fa-pencil" />
                  Edit
                  </button>
                ) : null}
              </div>
              <BlockTooltip text="What you are  testing. Mix of campaign, adset, and ad name strings." required />
            </div>
            <div className="block-field">
              <label htmlFor="">Compare Block</label>
              <Select
                onChange={({ value }) => handleInput('compare_block_id', value)}
                formatOptionLabel={formatOptionLabel}
                options={blocks}
                styles={SELECT_STYLES}
                placeholder="Select a block..."
                value={compareBlock}
              />
              <div>
                <button className="block-field__btn mr-3" onClick={() => setEditBlock({
                  field: 'compare', type: 'Create', id: '',
                })}>
                  <i className="fa fa-plus" />
                  Create
                </button>

                {allowCompareBlockEdit ? (
                  <button className="block-field__btn" onClick={() => setEditBlock({
                    field: 'compare', type: 'Edit', id: test.compare_block_id,
                    })}>
                  <i className="fa fa-pencil" />
                  Edit
                  </button>
                ) : null}
              </div>
              <BlockTooltip text="What you are comparing. Mix of campaign, adset, and ad name strings." required />
            </div>
          </div>
        ) : (
          <p className="p-4">Fetching blocks...</p>
        )}

        <div className="create-test__submit">
          {error && <p>{error}</p>}
          {loading && <p>Saving...</p>}
          <div className={`d-flex justify-content-end ${existing ? 'my-3' : 'mt-3 mb-2'}`}>
            <Button fullWidth appearance="gray" onClick={handleClose}>Cancel</Button>
            {existing && <Button fullWidth appearance="red" className="ml-3" onClick={handleDelete} disabled={api.post.loading}>{confirm ? 'Confirm?' : 'Delete'}</Button>}
          </div>
          <Button fullWidth onClick={handleSubmit} disabled={api.post.loading}>{existing ? 'Save' : 'Create'}</Button>
        </div>
      </div>
    </div>
  )
}

export default EditTest;

const BlockTooltip = ({ text, required }) => {
  return (
    <div className="block-field__tooltip">
      <p>{text}</p>
      {required && <p style={{ color: DARWIN_YELLOW_2 }} className="mt-3">Required.</p>}
    </div>
  )
}
