import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import Multiselect from 'multiselect-react-dropdown';

import RequiredField from './RequiredField';

const easyAiModelOpts = ['Flash'];
const easyAiCustomOpts = ['Flash'];
const defaultVertexSettings = {
  seconds: 3,
  model_standard_categories: 'Flash',
  model_custom_categories: 'Flash',
};

export const REPORT_BUILDER_GROUP_BY_OPTS = [
  {
    value: 'ad_name',
    label: 'Group By Ad Name',
  },
  {
    value: 'visual_id',
    label: 'Group by Darwin Visual ID',
  },
];
export const REPORT_BUILDER_GROUP_BY_LOOKUP = {
  ad_name: 'Group By Ad Name',
  visual_id: 'Group by Darwin Visual ID',
};

export function Main({ client, handleEdit, error }) {
  const clientTypes = ['Strategic', 'Normal', 'Dormant', ''];

  const createIntegrityEmailSelections = (client) => {
    if (client && client.data_integrity_emails) {
      const values = Object.keys(client.data_integrity_emails).map(
        function (key) {
          return {
            value: `${client.data_integrity_emails[key]}`,
            label: `${client.data_integrity_emails[key]}`,
          };
        }
      );
      return values;
    } else {
      return [];
    }
  };

  const createPerformanceEmailSelections = (client) => {
    if (client && client.performance_emails) {
      const values = Object.keys(client.performance_emails).map(function (key) {
        return {
          value: `${client.performance_emails[key]}`,
          label: `${client.performance_emails[key]}`,
        };
      });
      return values;
    } else {
      return [];
    }
  };

  const internalEmails = client.all_internal_users || [];
  const clientEmails = client.client_users || [];

  const handleVertexSettings = (field, value) => {
    handleEdit({
      vertex_settings: client.vertex_settings
        ? {
            ...client.vertex_settings,
            [field]: value,
          }
        : {
            ...defaultVertexSettings,
            [field]: value,
          },
    });
  };

  return (
    <>
      <ReactTooltip />
      <div className="w-100">
        <RequiredField
          error={error}
          label={'Client Name'}
          value={'client_name'}
        >
          <div className="col-sm-12 col-md-9">
            <input
              type="email"
              className="form-control"
              placeholder="Client Name"
              value={client.client_name || ''}
              onChange={(e) => handleEdit({ client_name: e.target.value })}
            />
          </div>
        </RequiredField>
        <div className="row form-group">
          <label className="col-sm-12 col-md-3 ">Primary View ID</label>
          <div className="col-sm-12 col-md-9">
            <input
              type="email"
              className="form-control"
              placeholder="Primary View ID"
              value={client.primary_view || ''}
              onChange={(e) => handleEdit({ primary_view: e.target.value })}
            />
          </div>
        </div>
        <div className="row form-group">
          <label className="col-sm-12 col-md-3 ">Type</label>
          <div className="col-sm-12 col-md-9">
            <Select
              value={{
                label: client && client.type ? client.type : '',
                value: client && client.type ? client.type : '',
              }}
              options={clientTypes.map((n) => ({
                value: `${n}`,
                label: `${n}`,
              }))}
              onChange={(e) =>
                handleEdit({
                  type: e.value,
                })
              }
            />
          </div>
        </div>
        <div className="row form-group">
          <label className="col-sm-12 col-md-3 ">Performance Emails</label>
          <div className="col-sm-12 col-md-9">
            <Multiselect
              options={[...internalEmails, ...clientEmails].map((n) => ({
                value: `${n}`,
                label: `${n}`,
              }))}
              selectedValues={createPerformanceEmailSelections(client)}
              onSelect={(e) =>
                handleEdit({
                  performance_emails: e.map((email) => email.value),
                })
              }
              onRemove={(e) =>
                handleEdit({
                  performance_emails: e.map((email) => email.value),
                })
              }
              displayValue="value"
              closeIcon="close"
            />
          </div>
        </div>
        <div className="row form-group">
          <label className="col-sm-12 col-md-3 ">Data Integrity Emails</label>
          <div className="col-sm-12 col-md-9">
            <Multiselect
              options={(client.all_internal_users
                ? client.all_internal_users
                : []
              ).map((n) => ({ value: `${n}`, label: `${n}` }))}
              selectedValues={createIntegrityEmailSelections(client)}
              onSelect={(e) =>
                handleEdit({
                  data_integrity_emails: e.map((email) => email.value),
                })
              }
              onRemove={(e) =>
                handleEdit({
                  data_integrity_emails: e.map((email) => email.value),
                })
              }
              displayValue="value"
              closeIcon="close"
            />
          </div>
        </div>
        <div className="row form-group">
          <label className="col-sm-12 col-md-3 d-flex align-items-center">
            <span>
              Creative Insights Should Pull Data by Asset (FB Clients should
              always be TRUE)
            </span>
          </label>
          <div className="col-sm-12 col-md-9">
            <Select
              value={{
                label: client.creative_insights_by_asset ? 'TRUE' : 'FALSE',
                value: client.creative_insights_by_asset,
              }}
              options={[
                { value: true, label: 'TRUE' },
                { value: false, label: 'FALSE' },
              ]}
              onChange={(e) =>
                handleEdit({ creative_insights_by_asset: e.value })
              }
            />
          </div>
        </div>
        <div className="row form-group">
          <label className="col-sm-12 col-md-3 d-flex align-items-center">
            <span>Use Darwin Universal ID for Asset Level Data</span>
          </label>
          <div className="col-sm-12 col-md-9">
            <Select
              value={{
                label: client.use_darwin_visual_id ? 'TRUE' : 'FALSE',
                value: client.use_darwin_visual_id,
              }}
              options={[
                { value: true, label: 'TRUE' },
                { value: false, label: 'FALSE' },
              ]}
              onChange={(e) => handleEdit({ use_darwin_visual_id: e.value })}
            />
          </div>
        </div>
        <div className="row form-group">
          <label className="col-sm-12 col-md-3 ">Darwin Light</label>
          <div className="col-sm-12 col-md-1">
            <input
              type="checkbox"
              className="form-control"
              name="darwin-light"
              checked={client.darwin_light}
              disabled={client.creative_studio_only}
              onChange={(e) => handleEdit({ darwin_light: e.target.checked })}
              style={{
                width: '16px',
                height: '18px',
              }}
            />
          </div>
        </div>
        <div className="row form-group">
          <label className="col-sm-12 col-md-3 ">Creative Studio Only</label>
          <div className="col-sm-12 col-md-1">
            <input
              type="checkbox"
              className="form-control"
              name="darwin-light"
              checked={client.creative_studio_only}
              disabled={client.darwin_light}
              onChange={(e) =>
                handleEdit({ creative_studio_only: e.target.checked })
              }
              style={{
                width: '16px',
                height: '18px',
              }}
            />
          </div>
        </div>
        <div className="row form-group">
          <label className="col-sm-12 col-md-3 ">
            Number of Seconds to Analyze
          </label>
          <div className="col-sm-12 col-md-1">
            <input
              type="number"
              className="form-control"
              name="darwin-light"
              value={client.vertex_settings?.seconds}
              defaultValue={client.vertex_settings?.seconds || 3}
              onChange={(e) => handleVertexSettings('seconds', e.target.value)}
              min={1}
            />
          </div>
        </div>
        <div className="row form-group">
          <label className="col-sm-12 col-md-3 ">
            Easy AI Model to Run: Standard Categories
          </label>
          <div className="col-sm-12 col-md-9">
            <Select
              value={{
                label:
                  client && client.vertex_settings?.model_standard_categories
                    ? client.vertex_settings.model_standard_categories
                    : defaultVertexSettings.model_standard_categories,
                value:
                  client && client.vertex_settings?.model_standard_categories
                    ? client.vertex_settings.model_standard_categories
                    : defaultVertexSettings.model_standard_categories,
              }}
              options={easyAiModelOpts.map((n) => ({
                value: `${n}`,
                label: `${n}`,
              }))}
              onChange={(e) =>
                handleVertexSettings('model_standard_categories', e.value)
              }
            />
          </div>
        </div>
        <div className="row form-group">
          <label className="col-sm-12 col-md-3 ">
            Easy AI Model to Run: Custom Categories
          </label>
          <div className="col-sm-12 col-md-9">
            <Select
              value={{
                label:
                  client && client.vertex_settings?.model_custom_categories
                    ? client.vertex_settings.model_custom_categories
                    : defaultVertexSettings.model_custom_categories,
                value:
                  client && client.vertex_settings?.model_custom_categories
                    ? client.vertex_settings.model_custom_categories
                    : defaultVertexSettings.model_custom_categories,
              }}
              options={easyAiCustomOpts.map((n) => ({
                value: `${n}`,
                label: `${n}`,
              }))}
              onChange={(e) =>
                handleVertexSettings('model_custom_categories', e.value)
              }
            />
          </div>
        </div>

        <div className="row form-group">
          <label className="col-sm-12 col-md-3 d-flex align-items-center">
            <span>Enable Global View</span>
          </label>
          <div className="col-sm-12 col-md-9">
            <Select
              value={{
                label: client.enable_global_view ? 'TRUE' : 'FALSE',
                value: client.enable_global_view,
              }}
              options={[
                { value: true, label: 'TRUE' },
                { value: false, label: 'FALSE' },
              ]}
              onChange={(e) => handleEdit({ enable_global_view: e.value })}
            />
          </div>
        </div>

        <div className="row form-group">
          <label className="col-sm-12 col-md-3 ">Report Builder Group By</label>
          <div className="col-sm-12 col-md-9">
            <Select
              value={{
                label: client?.report_builder_group_by
                  ? REPORT_BUILDER_GROUP_BY_LOOKUP[
                      client.report_builder_group_by
                    ]
                  : 'Group By Ad Name',
                value: client?.report_builder_group_by
                  ? client.report_builder_group_by
                  : 'ad_name',
              }}
              options={REPORT_BUILDER_GROUP_BY_OPTS}
              onChange={(e) =>
                handleEdit({
                  report_builder_group_by: e.value,
                })
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

Main.propTypes = {
  client: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
};
