import React, { useState } from 'react';
import { default as CustomTooltip } from '../../../components/Tooltip';
import {
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import { useCreativeAnalyticsGet } from '../contexts';
import { AssetDetails } from '../PerformanceFrequency/SquaresList';
import { VisualObject } from '../types';
import { useDispatch } from 'react-redux';
import {
  deselectVisual,
  selectVisual,
  toggleSelectVisual,
} from '../../../store/CreativeAnalytics/creativeAnalyticsSlice';
import { useCreativeAnalyticsStore } from '../../../selectors';
import clsx from 'clsx';
import { toCurrency } from '../../../utils/numbers';

type SelectedVisuals = {
  [id: string]: boolean;
};

const VisualsScatter = () => {
  const dispatch = useDispatch();
  const { filteredAllVisualsLabel, isROAS } = useCreativeAnalyticsGet();
  const { selectedVisuals } = useCreativeAnalyticsStore();
  const [hoveredVisual, setHoveredVisual] = useState<VisualObject | null>(null);

  const visuals = filteredAllVisualsLabel?.visuals ?? null;
  if (!visuals) return <div className="scatter">No data.</div>;

  const handleClickVisual = (visualId: string) => {
    dispatch(toggleSelectVisual(visualId));
  };

  const handleDotClick = (data: VisualObject) => {
    handleClickVisual(data.id);
  };

  const handleDotMouseEnter = (data: VisualObject) => {
    if (data) {
      // dispatch(selectVisual(data.id))
      setHoveredVisual(data);
    }
  };

  const handleDotMouseLeave = (data: VisualObject) => {
    if (hoveredVisual !== null) {
      console.log('Hover off dot');
    }
    setHoveredVisual(null);
  };

  const CustomDot = (props) => {
    const { cx, cy, x, y, stroke, payload, value, isSelected } = props;
    const fill = isSelected ? '#30A8FF' : '#47566E';

    return (
      <svg x={cx} y={cy} width={20} height={20} fill={fill}>
        <circle cx={10} cy={10} r={5} />
      </svg>
    );
  };

  console.log({ hoveredVisual });

  const Tip = () => {
    if (!hoveredVisual) return null;

    return (
      <div
        style={{
          fontFamily: 'Fira Code',
          background: 'rgba(255, 255, 255, 0.80)',
          backdropFilter: 'blur(3px)',
          padding: '1rem',
          border: '1px solid #CACDD2',
        }}
        onClick={() => handleClickVisual(hoveredVisual.id)}
      >
        <p>{hoveredVisual.id}</p>
        {isROAS ? (
          <p>ROAS: {toCurrency(hoveredVisual.roas)}</p>
        ) : (
          <p>CPA: {toCurrency(hoveredVisual.cpa)}</p>
        )}
        <p className="m-0">Spend: {toCurrency(hoveredVisual.spend)}</p>
      </div>
    );
  };

  return (
    <div className="scatter">
      <ScatterChart width={450} height={300}>
        <CartesianGrid />
        <XAxis type="number" dataKey="spend" name="Spend" label="Spend" />
        <YAxis type="number" dataKey="cpa" name="CPA" label="CPA" />
        <Tooltip
          isAnimationActive={false}
          // allowEscapeViewBox={{ x: true, y: true }}
          active={!!hoveredVisual}
          cursor={{ strokeDasharray: '3 3' }}
          content={Tip}
          // content={<AssetDetails visual={hoveredVisual} />}
        />
        {/* <Tooltip
        content={
          <CustomTooltip show={!!hoveredVisual}>
            <AssetDetails visual={hoveredVisual} />
          </CustomTooltip>
        } */}
        {/* /> */}
        <Scatter
          data={filteredAllVisualsLabel.visuals}
          onMouseEnter={handleDotMouseEnter}
          onMouseLeave={handleDotMouseLeave}
          dataKey="id" // Specify the dataKey as "id"
          shape={CustomDot}
        />
      </ScatterChart>
      {/* {<Tip />} */}
    </div>
  );
};

export default VisualsScatter;
