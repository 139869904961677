import { useState, useCallback } from 'react';
import { useSearchParams } from '../../../../../../components/Hooks/search-params';

/**
 * Handle navigating away when there's unsaved overrides
 */
export const useOverridesNavigation = (initialMode: string, initialDataset: string) => {
  const [unsavedOverrides, setUnsavedOverrides] = useState<Set<any>>(new Set());
  const [resetOverridesState, setResetOverridesState] = useState<boolean>(false);
  const { setParams } = useSearchParams();

  const originalSetMode = useCallback(
    (update: string) => setParams({ mode: update }), 
    [setParams]
  );
  
  const originalSetDataset = useCallback(
    (update: 'spend_based' | 'rank_based') => setParams({ dataset: update }),
    [setParams]
  );

  const resetOverrides = useCallback(() => {
    setUnsavedOverrides(new Set());
    setResetOverridesState((prev) => !prev);
  }, []);

  const setMode = useCallback(
    (update: string) => {
      if (unsavedOverrides.size > 0 && initialMode === 'data' && update === 'creative') {
        if (
          window.confirm(
            'You have unsaved override changes. Are you sure you want to navigate away?'
          )
        ) {
          originalSetMode(update);
          resetOverrides();
        }
      } else {
        originalSetMode(update);
      }
    },
    [unsavedOverrides, initialMode, originalSetMode, resetOverrides]
  );

  const setDataset = useCallback(
    (update: 'spend_based' | 'rank_based') => {
      if (
        unsavedOverrides.size > 0 &&
        initialDataset === 'spend_based' &&
        update === 'rank_based'
      ) {
        if (
          window.confirm(
            'You have unsaved override changes. Are you sure you want to navigate away?'
          )
        ) {
          originalSetDataset(update);
          resetOverrides();
        }
      } else {
        originalSetDataset(update);
      }
    },
    [unsavedOverrides, initialDataset, originalSetDataset, resetOverrides]
  );

  const handleUnsavedOverridesChange = useCallback((overrides: Set<any>) => {
    setUnsavedOverrides(overrides);
  }, []);

  return {
    unsavedOverrides,
    resetOverridesState,
    setMode,
    setDataset,
    handleUnsavedOverridesChange,
    resetOverrides
  };
}; 