import React, { useEffect, useReducer, useState } from 'react';
import {
  INITIAL_STATE,
  UPDATE_X_CATEGORY,
  UPDATE_Y_CATEGORY,
  heatmapReducer,
} from './reducer';
import { useRecommendationsContext } from '../context';
import { useSelector } from 'react-redux';
import { CategoriesLookup } from '../../ReportCard/types';
import { useCreativeAnalyticsGet } from '../../contexts';
import { Settings } from './Settings';
import { useHeatmapData, useHeatmapParams, useHeatmapSettings } from './hooks';
import { HeatmapGrid } from './HeatmapGrid';
import './styles.scss';
import Button from '../../../../components/Button';
import { getCategories, useSimpleImpactApi } from '../SimpleImpact';
import IconButton from '../../../../components/IconButton';
import Tooltip from '../../../../components/Tooltip';
import { getCategoryOptions } from './utils';
import { CategoryOptionType } from './types';

export const Heatmap = () => {
  const { dnaReportData } = useSelector(
    ({ CreativeAnalytics: { dnaReportData } }) => ({ dnaReportData })
  );
  const [showSettings, setShowSettings] = useState(false);
  const [settings, dispatchSettings] = useHeatmapSettings();
  const { categoriesLookup, primary_metric } = useCreativeAnalyticsGet();
  const { visuals, simpleImpactApi } = useRecommendationsContext();
  const { request, data: simpleImpactData, loading, error } = simpleImpactApi;

  const categories: CategoryOptionType[] = getCategoryOptions(
    dnaReportData,
    categoriesLookup,
    simpleImpactData
  );

  useEffect(() => {
    if (simpleImpactData.length) {
      if (!settings.xCategory) {
        dispatchSettings({
          type: UPDATE_X_CATEGORY,
          category: simpleImpactData[1]?.['Category Name'] ?? null,
        });
      }
      if (!settings.yCategory) {
        dispatchSettings({
          type: UPDATE_Y_CATEGORY,
          category: simpleImpactData[0]?.['Category Name'] ?? null,
        });
      }
    }
  }, [simpleImpactData]);

  const {
    data,
    maxNumOfCreatives,
    minPerformance,
    maxPerformance,
    maxSpend,
    xAxisLabels,
    yAxisLabels,
  } = useHeatmapData(visuals, settings, categoriesLookup, primary_metric);

  let Render = (
    <p className="m-3">No data available for selected categories.</p>
  );

  if (data.length) {
    Render = (
      <div className="grid-wrapper mt-3">
        <div></div>
        <p className="x-axis-label">{settings.xCategory}</p>
        <p className="y-axis-label">{settings.yCategory}</p>

        <HeatmapGrid
          {...{
            data,
            minPerformance,
            maxNumOfCreatives,
            settings,
            xAxisLabels,
            yAxisLabels,
            primary_metric,
            maxPerformance,
            maxSpend,
          }}
        />
      </div>
    );
  }

  let Title = (
    <p>
      Select 2 categories to compare.{' '}
      <IconButton
        active={showSettings}
        style={{ minWidth: '25px', height: '25px' }}
        onClick={() => setShowSettings((prev) => !prev)}
        className="heatmap-settings-toggle ml-3"
      >
        <Tooltip content="Show/hide settings">
          <i className="fa fa-gear" />
        </Tooltip>
      </IconButton>
    </p>
  );

  if (settings.xCategory && settings.yCategory) {
    Title = (
      <h4 className="heatmap__title">
        Matrix Report comparing <span>{settings.yCategory}</span> and{' '}
        <span>{settings.xCategory}</span>
        <IconButton
          active={showSettings}
          style={{ minWidth: '25px', height: '25px' }}
          onClick={() => setShowSettings((prev) => !prev)}
          className="heatmap-settings-toggle ml-3"
        >
          <Tooltip content="Show/hide settings">
            <i className="fa fa-gear" />
          </Tooltip>
        </IconButton>
      </h4>
    );
  }

  return (
    <div className="heatmap-wrapper">
      <div className="d-flex align-items-center m-3">{Title}</div>

      {Render}

      {showSettings && (
        <Settings
          handleClose={() => setShowSettings((prev) => !prev)}
          {...{ settings, dispatchSettings, categories, primary_metric }}
        />
      )}
    </div>
  );
};
