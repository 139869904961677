import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { LIBRARY_SEARCH_STYLES } from "../VisualLibrary/LibrarySearch/constants";
import Tooltip from "../../../components/Tooltip";

import "./RecommendationsSearch.scss";
import { FilterIcon } from "./icons";

const RecommendationsSearch = ({
  searchOptions,
  handleSearch,
  appliedFilter,
  handleSetFilter,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleAddSearchTerm = (selected) => {
    setSearchTerm(selected.label);
  };

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm]);

  const clearSearch = () => {
    setSearchTerm("");
    handleSearch("");
  };

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {!!children && (
            <i
              className="fa fa-search"
              aria-hidden="true"
              style={{ position: "absolute", left: 6, color: "#A9B3CD" }}
            />
          )}
          {children}
        </components.ValueContainer>
      )
    );
  };

  const CustomOption = (props) => {
    const { selectProps, data } = props;
    const input = selectProps.inputValue;
    const option = data.label;

    const getMatchingText = (input, option) => {
      const num = option.toLowerCase().indexOf(input.toLowerCase());
      const len = input.length;
      return (
        <div style={{ color: "#1E2B4E" }}>
          <span>{option.substr(0, num)}</span>
          <span style={{ fontWeight: "600" }}>{input}</span>
          <span>{option.substr(num + len)}</span>
        </div>
      );
    };

    return (
      <components.Option {...props}>
        {getMatchingText(input, option)}
      </components.Option>
    );
  };

  const trendingSection = document.querySelector("#trending-section");
  const savedSection = document.querySelector("#saved-section");
  const inspirationSection = document.querySelector("#inspiration-section");

  const handleScroll = (section) => {
    if (!section) return;
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleApplyFilter = (type) => {
    if (appliedFilter === type) {
      handleSetFilter(null);
    } else {
      handleSetFilter(type);
    }
  };

  return (
    <div className="recommendations-search__container">
      <div className={`recommendations-search ${searchTerm ? "searched" : ""}`}>
        <CreatableSelect
          styles={LIBRARY_SEARCH_STYLES}
          isSearchable
          options={searchOptions}
          value={null}
          onChange={(selected) => handleAddSearchTerm(selected)}
          components={{
            Option: CustomOption,
            ValueContainer: CustomValueContainer,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          placeholder="Search..."
          noOptionsMessage={() => null}
          // promptTextCreator={() => false}
          // isValidNewOption={() => false}
          formatCreateLabel={(userInput) => `Search for "${userInput}"`}
          createOptionPosition="first"
        />
      </div>
      <div className={`search-options ${searchTerm ? "minimize" : ""}`}>
        <div className="filter">
          <Tooltip content="Filter concepts">
            <div>
              <FilterIcon active={!!appliedFilter} />
            </div>
          </Tooltip>
          <FilterOption
            type={"image"}
            label={"Image"}
            appliedFilter={appliedFilter}
            handleApplyFilter={handleApplyFilter}
          />
          <FilterOption
            type={"video"}
            label={"Video"}
            appliedFilter={appliedFilter}
            handleApplyFilter={handleApplyFilter}
          />
        </div>
        {/* <div className="jump-to">
          <span>Jump to</span>
          <JumpToOption
            section={trendingSection}
            label={"Trending"}
            handleScroll={handleScroll}
          />
          <JumpToOption
            section={savedSection}
            label={"Saved"}
            handleScroll={handleScroll}
          />
          <JumpToOption
            section={inspirationSection}
            label={"High Performers & Inspiration"}
            handleScroll={handleScroll}
          />
        </div> */}
      </div>
      <div className={`search-results ${searchTerm ? "visible" : ""}`}>
        Showing results for "{searchTerm}"
        <Tooltip content="Clear search" follow={false}>
          <i
            onClick={clearSearch}
            className="fa-solid fa-circle-xmark ml-2"
          ></i>
        </Tooltip>
      </div>
    </div>
  );
};

export default RecommendationsSearch;

const FilterOption = ({ type, label, appliedFilter, handleApplyFilter }) => {
  return (
    <div
      className={`filter__option ${appliedFilter === type ? "active" : ""}`}
      onClick={() => handleApplyFilter(type)}
    >
      {label}
    </div>
  );
};

const JumpToOption = ({ section, label, handleScroll }) => {
  return (
    <div className="jump-to__option" onClick={() => handleScroll(section)}>
      {label}
    </div>
  );
};
