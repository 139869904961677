import React, { useEffect, useMemo, useRef, useState } from 'react';
import _isEqual from 'lodash/isEqual';
import Tooltip from '../../../../../components/Tooltip';

import { useTable, useSortBy } from 'react-table';
import IconButton from '../../../../../components/IconButton';
import { exportToCSV } from '../../../utils/export';
import { ShareReport } from '../../../../DNA/ShareReport';
import format from 'date-fns/format';
import { useSelector } from 'react-redux';
import { useAnalyticsUrlProps } from '../../../../CreativeAnalytics/PivotReport/hooks';
import { useSearchParams } from '../../../../../components/Hooks/search-params';
import {
  REPORT_BUILDER,
  SUMMARY_DATA_CSV_EXPORT,
  SUMMARY_DATA_STATIC_EXPORT,
} from '../../../../../store/UserActivity/constants';
import { useTrackEvent } from '../../../../CreativeAnalytics/hooks/useTrackEvent';
import { ColumnPicker } from '../../shared/ColumnPicker';
import { useSummaryCols } from './columns';
import { useShareStaticDetailData } from '../../../hooks/useShareStaticDetailData';
import { Section, SECTION_STYLE } from '../../shared/Helpers';
import { SummaryTable } from './SummaryTable';

const appendAdNameGroup = (summary_columns) => {
  if (!summary_columns?.includes('ad_name_group')) {
    return ['ad_name_group', ...summary_columns];
  }

  return summary_columns;
};

const getColumns = (summary_columns, isROAS = false) => {
  if (!!summary_columns?.filter((x) => x !== 'label_name').length)
    return appendAdNameGroup(summary_columns);

  if (isROAS) {
    return [
      'ad_name_group',
      'label_name',
      'total_spend',
      'total_roas',
      ...summary_columns,
    ];
  } else {
    return [
      'ad_name_group',
      'label_name',
      'total_spend',
      'total_cpa',
      ...summary_columns,
    ];
  }
};

export const SummaryTableWrapper = ({
  data,
  dateRange,
  exportFileName,
  onRowSelect,
  selectedRowId,
}) => {
  const { summary_columns } = useAnalyticsUrlProps();
  const { setParams } = useSearchParams();
  const trackEvent = useTrackEvent();
  const isROAS = useSelector((state) => state.ViewSettings.isROAS);
  const selectedColumns = useMemo(
    () => getColumns(summary_columns, isROAS),
    [summary_columns, isROAS]
  );

  const {
    shouldShowShareModal,
    openShareModal,
    closeShareModal,
    submitSummaryReport,
    sharedReportId,
    loadingShareReport,
  } = useShareStaticDetailData(isROAS);

  const defaultColumn = useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 200,
    }),
    []
  );
  const columns = useSummaryCols(isROAS, data);

  const visibleColumns = useMemo(
    () =>
      !selectedColumns.length
        ? columns
        : columns.filter((col) => selectedColumns.includes(col.accessor)),
    [columns, selectedColumns]
  );

  const tableInstance = useTable(
    { columns: visibleColumns, data, defaultColumn },
    useSortBy
  );

  const handleColumnChange = (selectedOptions) => {
    setParams({ summary_columns: selectedOptions.join(',') });
  };

  return (
    <>
      {!!shouldShowShareModal && (
        <ShareReport
          close={closeShareModal}
          submit={({ report_name, report_description }) => {
            trackEvent(REPORT_BUILDER, SUMMARY_DATA_STATIC_EXPORT);
            submitSummaryReport({
              report_name,
              report_description,
              start_date: format(new Date(dateRange.start), 'yyyy-MM-dd'),
              end_date: format(new Date(dateRange.end), 'yyyy-MM-dd'),
              tableData: {
                data,
              },
            });
          }}
          sharedReportId={sharedReportId}
          loading={loadingShareReport}
        />
      )}

      <Section
        style={SECTION_STYLE}
        title={
          <div className="d-flex align-items-center mb-3">
            <h3 className="mr-3 mr-3 mb-0">Summary Data</h3>
            <Tooltip content="Export to CSV">
              <div>
                <IconButton
                  onClick={() => {
                    trackEvent(REPORT_BUILDER, SUMMARY_DATA_CSV_EXPORT);
                    exportToCSV(
                      tableInstance?.rows || [],
                      exportFileName,
                      ['S3 URL', 'Has Chosen Label', 'Has Comparison Label'],
                      isROAS
                    );
                  }}
                >
                  <i className="fa-solid fa-table"></i>
                </IconButton>
              </div>
            </Tooltip>

            <Tooltip content="Export static table">
              <div className="mx-2">
                <IconButton onClick={() => openShareModal()}>
                  <i className="fa-solid fa-share"></i>
                </IconButton>
              </div>
            </Tooltip>

            <ColumnPicker
              columns={selectedColumns}
              options={columns
                .filter((col) => col.accessor !== 'ad_name_group')
                .map((col) => ({
                  value: col.accessor,
                  label: col.Header,
                }))}
              onChange={({ column, checked }) => {
                let update = [...selectedColumns];
                if (checked) {
                  update = [...selectedColumns, column];
                } else {
                  update = selectedColumns.filter((col) => col !== column);
                }
                handleColumnChange(update);
              }}
            />
          </div>
        }
      >
        <SummaryTable
          tableInstance={tableInstance}
          onRowSelect={onRowSelect}
          selectedRowId={selectedRowId}
        />
      </Section>
    </>
  );
};
