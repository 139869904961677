export const TEST_TYPE_OPTIONS = [
  {
    value: 'ab_test',
    label: 'A/B Test',
  },
  {
    value: 'abz_test',
    label: 'A/B-Z Test',
  },
];

export const SUB_TYPE_OPTIONS = [
  { value: 'audience', label: 'Audience' },
  { value: 'creative', label: 'Creative' },
];

export const ADD = 'ADD';
export const REMOVE = 'REMOVE';

export const BAR_HEIGHT = 40;
export const BAR_COUNT = 9;
export const ACTIVE_BAR_COUNT = 8;

export const ZOOM_BUTTONS = [
  {
    type: 'month',
    count: 1,
    text: '1m',
  },
  {
    type: '3month',
    count: 3,
    text: '3m',
  },
  {
    type: '6month',
    count: 6,
    text: '6m',
  },
  {
    type: 'ytd',
    text: 'YTD',
  },
  {
    type: 'year',
    count: 1,
    text: '1y',
  },
  {
    type: 'all',
    text: 'All',
  },
];

export const AUTOMATION_STUDY_TYPE_OPTIONS = [
  {
    value: 'automatic',
    label: 'Automatic',
  },
  {
    value: 'manual',
    label: 'Manual',
  },
];
