
export const getConceptCampaigns = (concepts, campaigns) => {
  let conceptCampaignIds = [];

  concepts.forEach((concept) => {
    campaigns.forEach((campaign) => {
      if (
        concept.split(/[\s_-]+/).every((str) =>
        campaign.label
          .toLowerCase()
          .split(/[\s_-]+/)
          .includes(str)
        )
      ) {
        conceptCampaignIds.push(campaign.value)
      }
    })
  })

  return [...new Set(conceptCampaignIds)];
};

export const containsWholeWordMatch = (concept, campaign) => {
  return concept.split(/[\s_-]+/).every((str) =>
    campaign.label
      .toLowerCase()
      .replace(/\(|\)/g, "")
      .split(/[\s_-]+/)
      .includes(str)
  );
};

export const containsMatch = (concept, campaign) => {
  return campaign.label.toLowerCase().includes(concept);
};

export const makeFbAdsManagerUrl = (account_id, selectedCampaigns) => {
  const act = account_id.replace('act_', '');
  const selected_campaign_ids = selectedCampaigns.map((c) => c.value).join(",");

  return `https://adsmanager.facebook.com/adsmanager/manage/campaigns?act=${act}&selected_campaign_ids=${selected_campaign_ids}&breakdown_regrouping=true`;
}