export const DASHBOARD = 'CreativeTesting';
export const NAV = `${DASHBOARD}.Nav`;
export const ADS = `${DASHBOARD}.Ads`;
export const AD = `${DASHBOARD}.Ad`;
export const SUPPLEMENTAL = `${DASHBOARD}.Supplemental`;

export const DASHBOARD_FETCH_NAV = `[${NAV}] FETCH`;
export const DASHBOARD_SET_NAV = `[${NAV}] SET`;
export const DASHBOARD_FETCH_ADS = `[${ADS}] FETCH`;
export const DASHBOARD_SET_ADS = `[${ADS}] SET`;
export const DASHBOARD_FETCH_SUPPLEMENTAL = `[${SUPPLEMENTAL}] FETCH`;
export const DASHBOARD_SET_SUPPLEMENTAL = `[${SUPPLEMENTAL}] SET`;

export const DASHBOARD_SET_AD = `[${AD}] SET`;

export const DASHBOARD_SET = `[${DASHBOARD}] SET`;

export const setCreativeTesting = (obj) => ({
  type: DASHBOARD_SET,
  meta: { entity: DASHBOARD },
  data: obj,
});

export const setCreativeTestingAd = ({ ad_id, ...rest }) => ({
  type: DASHBOARD_SET_AD,
  data: {
    ad_id,
    ...rest,
  },
});

export const setCreativeTestingNav = ({ nav }) => ({
  type: DASHBOARD_SET_NAV,
  meta: { entity: DASHBOARD, normalizeId: 'ad_id' },
  data: nav,
});

export const setCreativeTestingAds = ({ ad }) => ({
  type: DASHBOARD_SET_ADS,
  meta: { entity: DASHBOARD },
  data: ad,
});

export const setCreativeTestingSupplemental = ({ ads, stateType }) => ({
  type: DASHBOARD_SET_SUPPLEMENTAL,
  meta: { entity: DASHBOARD },
  data: ads,
  stateType,
});

export const fetchCreativeTestingNav = () => ({
  type: DASHBOARD_FETCH_NAV,
  meta: { entity: DASHBOARD },
});

export const fetchCreativeTestingAds = ({ ad_id, ad_state }) => ({
  type: DASHBOARD_FETCH_ADS,
  meta: { entity: DASHBOARD },
  data: {
    ad_id,
    ad_state,
  },
});

export const fetchCreativeTestingSupplmental = ({ ad_id }) => ({
  type: DASHBOARD_FETCH_SUPPLEMENTAL,
  meta: { entity: DASHBOARD },
  data: {
    ad_id,
  },
});
