import React, { useEffect } from "react";
import "./PhraseThemeSettings.scss";
import {
  ADD_EXAMPLE,
  ADD_SETTING,
  CLOSE_SETTINGS,
  DELETE_SETTING,
  REMOVE_EXAMPLE,
  UPDATE_DESCRIPTION,
  UPDATE_EXAMPLE,
  UPDATE_NAME,
} from "./reducer";
import SettingItem from "./components/SettingItem";
import Button from "../../../../components/Button";
import { ChevronDoubleRightIcon } from "../../CreativeRequest/icons";
import { AddIcon } from "../icons/add";

const PhraseThemeSettings = ({
  phraseThemeSettings,
  Portal,
  cleanUpInput,
  loading,
  handleSetSavedAndCurrent,
}) => {
  const {
    dispatchPhraseThemeSettings: dispatch,
    show,
    lastSaved,
    current,
    error,
  } = phraseThemeSettings;

  useEffect(() => {
    handleSetSavedAndCurrent();
  }, []);

  const handleUpdateName = (e, index) => {
    dispatch({
      type: UPDATE_NAME,
      payload: {
        index,
        phrase_theme: e.target.value,
      },
    });
  };

  const handleUpdateDescription = (e, index) => {
    dispatch({
      type: UPDATE_DESCRIPTION,
      payload: {
        index,
        description: e.target.value,
      },
    });
  };

  const handleAddExample = (e, index) => {
    if (!e.keyCode || e.keyCode === 13) {
      dispatch({
        type: ADD_EXAMPLE,
        payload: {
          index,
        },
      });
    }
  };

  const handleUpdateExample = (e, index, exampleIndex) => {
    dispatch({
      type: UPDATE_EXAMPLE,
      payload: {
        index,
        exampleText: e.target.value,
        exampleIndex,
      },
    });
  };

  const handleRemoveExample = (index, exampleIndex) => {
    dispatch({
      type: REMOVE_EXAMPLE,
      payload: {
        index,
        exampleIndex,
      },
    });
  };

  const handleAddSetting = () => {
    dispatch({
      type: ADD_SETTING,
      payload: {},
    });
  };

  const handleDeleteSetting = (index) => {
    dispatch({
      type: DELETE_SETTING,
      payload: {
        index,
      },
    });

    if (index === current.length - 1) {
      handleAddSetting();
    }
  };

  return (
    <Portal.Portal>
      <div className={`phrase-theme-settings ${show ? "show" : ""}`}>
        <div className="phrase-theme-settings__container">
          <div className="phrase-theme-settings__close">
            <div
              onClick={() => dispatch({ type: CLOSE_SETTINGS, payload: {} })}
              className="close-icon"
            >
              <ChevronDoubleRightIcon />
            </div>
          </div>
          <h3 className="phrase-theme-settings__header">
            Configure Phrase Theme Labels
          </h3>
          <div className="phrase-theme-settings__list">
            {current?.map((currentItem, index) => {
              return (
                <SettingItem
                  {...{
                    currentItem,
                    index,
                    handleUpdateName,
                    handleUpdateDescription,
                    handleAddExample,
                    handleUpdateExample,
                    handleRemoveExample,
                    handleDeleteSetting,
                    error,
                  }}
                />
              );
            })}
          </div>
          <div className="add-setting" onClick={handleAddSetting}>
            <div className="add-setting__inner">
              <AddIcon /> Add setting
            </div>
          </div>
        </div>
        <div className="phrase-theme-settings__footer">
          <Button
            appearance="primary"
            onClick={cleanUpInput}
            disabled={loading}
            loading={loading}
          >
            Save and Rerun
          </Button>
        </div>
      </div>
    </Portal.Portal>
  );
};

export default PhraseThemeSettings;
