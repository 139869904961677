
import React from 'react';
import PropTypes from 'prop-types';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';

function getCorrectAccountId(accountId, snapchatAccountId) {
  if (
    snapchatAccountId !== undefined
    && snapchatAccountId !== ''
    && snapchatAccountId !== null
  ) {
    return snapchatAccountId;
  }
  return accountId;
}

function useCubeApi({
  token,
  campaigns,
  actionName,
  viewId,
  accountId,
  snapchatAccountId,
}) {
  return cubejs(token, {
    apiUrl: ['cube', 'dashboard', 'v1'].join('/'),
    headers: {
      campaigns,
      action_name: actionName.value,
      view_id: viewId,
      account_id: getCorrectAccountId(accountId, snapchatAccountId),
    },
  });
}

const CubeApp = ({
  children,
  viewId,
  campaigns,
  token,
  actionName,
  accountId,
  snapchatAccountId,
}) => {
  const api = useCubeApi({
    viewId,
    token,
    campaigns,
    actionName,
    accountId,
    snapchatAccountId,
  });

  return <CubeProvider cubejsApi={api}>{children}</CubeProvider>;
};

export default CubeApp;

CubeApp.defaultProps = {
  snapchatAccountId: '',
  token: '',
};

CubeApp.propTypes = {
  children: PropTypes.node.isRequired,
  viewId: PropTypes.string.isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.string).isRequired,
  token: PropTypes.string,
  actionName: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  snapchatAccountId: PropTypes.string,
};
