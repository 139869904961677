
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import TableDisplay from './PerformanceTable';
import DemographicTable from './DemographicTable';
import BreakdownWrapper from './Breakdowns';

const DisplayTable = ({
  value: { performance, data = [] },
  valueId,
  ad_ids,
  colors,
  outcomesLoading,
}) => {
  const { chart_type, selected_type, only_new_ads, new_ads_data } = useSelector(({ CreativeReports: { chart_type, selected_type, only_new_ads, new_ads_data }}) => {
    return { chart_type, selected_type, only_new_ads, new_ads_data };
  })

  const isPerformanceFrequency = (chart_type === 'performance_frequency');
  const isLabel = (selected_type === 'label');

  const tableData = {
    colors,
    willOverflow: data.length > 3,
    rows: data.filter(({ type }) => !!type),
  };

  let Cpa = <TableDisplay title="Element Performance" {...tableData} />;

  if (only_new_ads && isPerformanceFrequency && isLabel && new_ads_data) {
    const { new_ads_cpa } = new_ads_data; 

    const newAdsCpaData = {
      colors,
      willOverflow: data.length > 3,
      rows: new_ads_cpa.filter(({ type }) => !!type),
    }

    Cpa = <TableDisplay title="Element Performance" {...newAdsCpaData} />;
  }

  switch (performance) {
    case 'cpa':
      return Cpa;
    case 'new_ads_cpa':
      return <TableDisplay title="Element Performance" {...tableData} />;
    case 'targeting':
      // leaving this in case it should be re added later
      // return <TableDisplay title="Targeting Performance" {...tableData} />;
      return null;

    case 'outcomes_breakdown':
      return <TableDisplay title="Outcomes - Performance Breakdown" {...{ outcomesLoading }} {...tableData} />;

    case 'outcomes_avg':
      return <TableDisplay title="Outcomes - Average Values" {...{ outcomesLoading }} {...tableData} />;

    case 'audience':
      return <TableDisplay title="Audience Performance" {...tableData} />;

    case 'placement':
      return (
        <BreakdownWrapper
          colors={colors}
          title="Placement Performance"
          valueId={valueId}
          ad_ids={ad_ids}
          TableComp={TableDisplay}
          breakdowns={['platform', 'placement']}
        />
      );

    case 'demographic':
      return (
        <BreakdownWrapper
          colors={colors}
          title="Demographic Performance"
          valueId={valueId}
          ad_ids={ad_ids}
          TableComp={DemographicTable}
          breakdowns={['age', 'gender']}
        />
      );

    case 'audience+theme':
      return (
        <TableDisplay title="Audience x Theme Performance" {...tableData} />
      );

    default:
      return null;
  }
};

DisplayTable.defaultProps = {
  colors: {},
  ad_ids: [],
};

DisplayTable.propTypes = {
  valueId: PropTypes.string.isRequired,
  value: PropTypes.shape({
    performance: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
  }).isRequired,
  ad_ids: PropTypes.arrayOf(PropTypes.string),
  colors: PropTypes.shape({
    background: PropTypes.string,
    foreground: PropTypes.string,
    tertiary: PropTypes.string,
  }),
};

export default DisplayTable;
