import Tippy from "@tippyjs/react";
import React, { useState } from "react";
import { Tag, TagsList } from "../../../components/Tags";
import {
  REMOVE_BRAND,
  REMOVE_PROMO,
  REMOVE_KEYWORD,
  ADD_BRAND,
  ADD_PROMO,
  ADD_KEYWORD,
} from "./reducer";
import { SuggestionIcon } from "./Helpers";

const CloseIcon = ({ setCloseIsHovered }) => {
  return (
    <div
      onMouseEnter={() => setCloseIsHovered(true)}
      onMouseLeave={() => setCloseIsHovered(false)}
      style={{
        background: "#EBBCA8",
        borderRadius: "50px",
        position: "absolute",
        top: "-7px",
        right: "-7px",
        width: "15px",
        height: "15px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <i
        className="fa fa-xmark"
        style={{
          color: "#B45A46",
          fontSize: "1.3rem",
          transform: "translateY(1px)",
        }}
      ></i>
    </div>
  );
};

const PlusIcon = ({ setCloseIsHovered }) => {
  return (
    <div
      onMouseEnter={() => setCloseIsHovered(true)}
      onMouseLeave={() => setCloseIsHovered(false)}
      style={{
        background: "#a0ecc4",
        borderRadius: "50px",
        position: "absolute",
        top: "-7px",
        right: "-7px",
        width: "15px",
        height: "15px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <i
        className="fa-solid fa-plus"
        style={{
          color: "#339661",
          fontSize: "1.3rem",
          transform: "translateY(1px)",
        }}
      ></i>
    </div>
  );
};

const LabelTag = ({
  label,
  labelSetup,
  isBrands,
  isPromos,
  isKeywords,
  existingLabel,
  setLabelsChanged,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [closeIsHovered, setCloseIsHovered] = useState(false);
  const {
    activeBrands,
    activePromos,
    existingKeywords,
    activeKeywords,
    suggestedKeywords,
  } = labelSetup;
  let active = false;
  let clickAction = "";
  const existing = label.toLowerCase() === existingLabel.toLowerCase();
  const existingKeyword = isKeywords && existingKeywords.includes(label);

  if (isBrands) {
    active = activeBrands.includes(label);
    
    if (active) {
      clickAction = REMOVE_BRAND;
    } else {
      clickAction = ADD_BRAND;
    }
  }

  if (isPromos) {
    active = activePromos.includes(label);
    if (active) {
      clickAction = REMOVE_PROMO;
    } else {
      clickAction = ADD_PROMO;
    }
  }

  if (isKeywords) {
    active = activeKeywords.includes(label);
    if (active) {
      clickAction = REMOVE_KEYWORD;
    } else {
      clickAction = ADD_KEYWORD;
    }
  }

  const handleLabel = () => {
    labelSetup.dispatchLabelSetup({
      type: clickAction,
      label,
    });
    setLabelsChanged(true);
  };

  return (
    <>
      <Tippy
        content='Any number with a % at the end'
        className="label-setup__tooltip"
        disabled={label !== "n%"}
      >
        <li>
          <Tag
            className={`label-setup__tag ${
              active ? "label-setup__tag--active" : ""
            } ${existing ? "label-setup__tag--existing" : ""}`}
            onClick={handleLabel}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {isHovered && (
              <>
                {active ? (
                  <CloseIcon setCloseIsHovered={setCloseIsHovered} />
                ) : (
                  <PlusIcon setCloseIsHovered={setCloseIsHovered} />
                )}
              </>
            )}
            {isKeywords && suggestedKeywords.includes(label) && !existingKeywords.includes(label) && (
              <SuggestionIcon />
            )}
            {label}
          </Tag>
        </li>
      </Tippy>
    </>
  );
};

const LabelsList = ({
  labels,
  labelSetup,
  isBrands,
  isPromos,
  isKeywords,
  existingLabel,
  setLabelsChanged,
}) => {
  return (
    <div className="label-setup__labels">
      <TagsList>
        {labels.map((label) => {
          return (
            <LabelTag
              {...{
                label,
                labelSetup,
                isBrands,
                isPromos,
                isKeywords,
                existingLabel,
                setLabelsChanged,
              }}
            />
          );
        })}
      </TagsList>
    </div>
  );
};

export default LabelsList;
