export const DEFAULT_metric_order = [
  {
    metric: 'CPA',
    id: 'aggregate_cpa',
    enabled: true,
  },
  {
    metric: 'Median CPA',
    id: 'median_cpa',
  },
  {
    metric: 'ROAS',
    id: 'aggregate_roas',
    enabled: true,
  },
  {
    metric: 'Median ROAS',
    id: 'median_roas',
  },
  {
    metric: 'CPM',
    id: 'aggregate_cpm',
    enabled: true,
  },
  {
    metric: 'Median CPM',
    id: 'median_cpm',
  },
  {
    metric: 'CPC',
    id: 'aggregate_cpc',
    enabled: true,
  },
  {
    metric: 'Median CPC',
    id: 'median_cpc',
  },
  {
    metric: 'CTR',
    id: 'aggregate_ctr',
    enabled: true,
  },
  {
    metric: 'Median CTR',
    id: 'median_ctr',
  },
  {
    metric: 'CPIK',
    id: 'aggregate_cpik',
  },
  {
    metric: 'Median CPIK',
    id: 'median_cpik',
  },
  {
    metric: 'GA CTR',
    id: 'ga_aggregate_ctr',
  },
  {
    metric: 'GA Median CTR',
    id: 'ga_median_ctr',
  },
  {
    metric: 'Total Results',
    id: 'total_results',
  },
  {
    metric: '% of Results',
    id: 'percent_of_results',
  },
  {
    metric: '% of Results Difference',
    id: 'percent_of_results_diff',
  },
  {
    metric: 'Total Link Clicks',
    id: 'total_link_clicks',
  },
  {
    metric: '% of Link Clicks',
    id: 'percent_of_link_clicks',
  },
  {
    metric: '# of Unique Creatives',
    id: 'number_unique_creatives',
  },
  {
    metric: '% of Creative Count',
    id: 'percent_of_creative_count',
  },
  {
    metric: '% Spend',
    id: 'percent_budget',
  },
  {
    metric: 'Spend',
    id: 'spend',
  },
  {
    metric: 'Total Likes',
    id: 'total_likes',
  },
  {
    metric: 'Likes per Post',
    id: 'likes_per_post',
  },
]

export const REPORT_CARD = "Report Card";
export const PHRASE_BUILDER = "Phrase Builder";
export const OVERVIEW = "How to Improve";
export const ITERATIONS = "Iterations";
export const DETAILS = "Details";
export const CATEGORIES = "Report Card";
export const RECO_ENGINE = "Recommendations engine";
export const PHRASE_ALTERNATIVES = "Phrase alternatives";
export const PHRASE_GENERAL_IDEAS = "Phrase ideas";
export const GENERAL_SUGGESTIONS = "General suggestions";
export const EXECUTION_STYLES = "Execution styles";
export const PERFORMANCE_SUGGESTIONS = "Performance suggestions";
export const EASY_SUGGESTIONS = "Easy suggestions";