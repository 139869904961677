import React from "react";

export const ExamplesIcon = () => {
  return (
    <svg
      className="examples-icon"
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.90247 2.08862H13.733M4.90247 6.02612H13.733M4.90247 9.96362H13.733"
        stroke="#9E9E9E"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.08997 2.65112C2.40063 2.65112 2.65247 2.39928 2.65247 2.08862C2.65247 1.77796 2.40063 1.52612 2.08997 1.52612C1.77931 1.52612 1.52747 1.77796 1.52747 2.08862C1.52747 2.39928 1.77931 2.65112 2.08997 2.65112Z"
        stroke="#9E9E9E"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.08997 6.58862C2.40063 6.58862 2.65247 6.33678 2.65247 6.02612C2.65247 5.71546 2.40063 5.46362 2.08997 5.46362C1.77931 5.46362 1.52747 5.71546 1.52747 6.02612C1.52747 6.33678 1.77931 6.58862 2.08997 6.58862Z"
        stroke="#9E9E9E"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.08997 10.5261C2.40063 10.5261 2.65247 10.2743 2.65247 9.96362C2.65247 9.65296 2.40063 9.40112 2.08997 9.40112C1.77931 9.40112 1.52747 9.65296 1.52747 9.96362C1.52747 10.2743 1.77931 10.5261 2.08997 10.5261Z"
        stroke="#9E9E9E"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
