import { useRef, useEffect } from 'react';

import { abortableFetch } from '../../../utils';
import {
  useApiState,
  bindFetchToRef,
} from '../../../components/Hooks/useApiState';

function getSourceCampaigns(viewId) {
  return abortableFetch(['api', viewId, 'bulk-creative'].join('/'), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
  });
}

export function useFetchSourceApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (viewId) => withRef(getSourceCampaigns(viewId)).then((data) => data),
    {
      loading: false,
      error: null,
      data: null,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    get: {
      ...getState,
      request: getDispatch,
    },
  };
}

function listFBCampaigns(payload) {
  return abortableFetch(
    ['api', 'bulk-actions', 'view-editor', payload.darwin_client_id, 'campaigns'].join(
      '/'
    ),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(payload),
    }
  );
}

function putViewSettings(payload) {
  return abortableFetch(
    ['api', 'bulk-actions', 'view-editor', payload._id].join('/'),
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(payload),
    }
  );
}

function getTikTokData(payload) {
  return abortableFetch(
    ['api', 'bulk-actions', 'view-editor', 'get-tiktok-data'].join(
      '/'
    ),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(payload),
    }
  );
}

export function useViewSettingsApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [putState, putDispatch] = useApiState(
    (payload) => withRef(putViewSettings(payload)).then(({ data }) => data),
    {
      loading: false,
      error: null,
      data: null,
      count: 0,
    }
  );
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(listFBCampaigns(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );
  const [getState, getDispatch] = useApiState(
    (payload) => withRef(getTikTokData(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    fetch_fb_campaigns: {
      ...postState,
      request: postDispatch,
    },
    put: {
      ...putState,
      request: putDispatch,
    },
    tiktokData: {
      ...getState,
      request: getDispatch,
    },
  };
}


function postRefresh(payload) {
  return abortableFetch(['api', 'admin', 'refresh-data'].join('/'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    body: JSON.stringify(payload),
  });
}


function postTiktokRefresh(payload) {
  return abortableFetch(['api', 'admin', 'refresh-tiktok-data'].join('/'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    },
    body: JSON.stringify(payload),
  });
}

export function useRefreshApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(postRefresh(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: postDispatch,
    },
  };
}

export function useTiktokRefreshApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(postTiktokRefresh(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: postDispatch,
    },
  };
}
