import React, { useRef, useState } from 'react';
import Tooltip from '../../../../../components/Tooltip';
import BeagleToggle from '../../../../../components/BeagleToggle';

import { MULTIPLE_LABELS, MUTUALLY_EXCLUSIVE } from '../../constants';

import { Tag, TagsList } from '../../../../../components/Tags';

import Button from '../../../../../components/Button';
import { useSetupValidation } from './hooks';

const validate = (input) => {
  if (input.compliance) {
    if (input.name.length && input.description.length) {
      return true;
    }
  } else if (input.name.length && input.type.length) {
    if (input.type === MUTUALLY_EXCLUSIVE && input.labels.length > 1) {
      return true;
    } else if (
      input.type === MULTIPLE_LABELS &&
      input.labels.length > 0 &&
      input.labels.length < 5
    ) {
      return true;
    }
  }

  return false;
};

type CategorySetup = {
  dropdownOptions: any;
  next: () => void;
  input: any;
  handleInput: () => void;
  removeLabel: () => void;
  existing: any;
};

const CategorySetup = ({
  next,
  prev,
  input,
  handleInput,
  removeLabel,
  existing,
  existingNames,
  isNew,
}) => {
  const isCompliance = input.compliance;
  const [labelInput, setLabelInput] = useState('');
  const { errors, labelInputErrors, categoryInputErrors } = useSetupValidation(
    input,
    existing,
    existingNames,
    labelInput
  );

  const addLabel = () => {
    handleInput('labels', labelInput);
    setLabelInput('');
  };

  const onKeyDown = (e) => {
    if (labelInputErrors.length < 1) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        addLabel();
      }
    }
  };

  const changeType = (type) => {
    if (!existing) {
      handleInput('type', type);
    }
  };

  return (
    <div className="category-setup">
      <h2>A.I. Driven Category</h2>
      {isNew && (
        <button
          style={{ position: 'absolute', left: '10px', top: '15px' }}
          className="sidebar__back"
          onClick={prev}
        >
          <i className="fas fa-arrow-left"></i>
          Back
        </button>
      )}

      <form action="">
        <div className="category-setup__group">
          <label htmlFor="">Category Name</label>
          <input
            value={input.name}
            type="text"
            onChange={(e) => handleInput('name', e.target.value)}
            className={`${categoryInputErrors.length ? 'input-error' : ''}`}
          />
          {categoryInputErrors.map((error) => (
            <p className="category-setup__error mt-2">{error}</p>
          ))}
        </div>

        <div className="category-setup__group">
          <label htmlFor="">Description</label>
          <input
            value={input.description}
            type="text"
            onChange={(e) => handleInput('description', e.target.value)}
          />
        </div>

        {!isCompliance && (
          <div className="category-setup__group">
            <label htmlFor="">Category Type</label>
            <div className="d-flex justify-content-between">
              <Tooltip content="Choose ‘Mutually Exclusive’ as the category type if all visuals can only have one label. Examples would be ‘background color’, ‘theme’, ‘promo type’, etc.">
                <div
                  className={`category-setup__type ${
                    input.type === MUTUALLY_EXCLUSIVE
                      ? 'category-setup__type--selected'
                      : ''
                  }`}
                  onClick={() => changeType(MUTUALLY_EXCLUSIVE)}
                >
                  Mutually Exclusive
                </div>
              </Tooltip>

              <Tooltip content="Choose ‘Multiple Labels’ as a category type if a visual can have more than one label. Examples might be ‘actor name’ (in the case of multiple actors), ‘ice cream flavors’ (if there is more than one ice cream flavor in a visual, etc.)">
                <div
                  className={`category-setup__type ${
                    input.type === MULTIPLE_LABELS
                      ? 'category-setup__type--selected'
                      : ''
                  }`}
                  onClick={() => changeType(MULTIPLE_LABELS)}
                >
                  Multiple Labels
                </div>
              </Tooltip>
            </div>
          </div>
        )}
        {!isCompliance && (
          <div className="category-setup__group">
            <label htmlFor="">Labels</label>
            <div className="d-inline">
              <div className="d-flex">
                <input
                  type="text"
                  value={labelInput}
                  onChange={(e) => setLabelInput(e.target.value)}
                  onKeyDown={onKeyDown}
                  className={`${labelInputErrors.length ? 'input-error' : ''}`}
                />
                <Button
                  className="ml-3"
                  appearance="secondary"
                  onClick={addLabel}
                  disabled={labelInputErrors.length}
                >
                  Add
                </Button>
              </div>
            </div>
            {labelInputErrors.map((error) => (
              <p className="category-setup__error mt-2">{error}</p>
            ))}
            <div className={input.labels.length ? 'mt-4' : ''}>
              <TagsList>
                {input.labels &&
                  input.labels.map((label) => (
                    <Tag onClick={() => removeLabel(label)}>{label}</Tag>
                  ))}
              </TagsList>
            </div>
          </div>
        )}
        {isCompliance && (
          <div className="category-setup__group">
            <label htmlFor="">Description</label>
            <input
              value={input.description}
              type="text"
              onChange={(e) => handleInput('description', e.target.value)}
            />
          </div>
        )}
      </form>
      <div className="category-setup__btn">
        {errors.length ? (
          <Tooltip
            content={
              <ul style={{ listStyle: 'none', padding: 0, marginBottom: 0 }}>
                {errors.map((err) => (
                  <li>{err}</li>
                ))}
              </ul>
            }
          >
            <i
              className="fa fa-circle-exclamation mr-3"
              style={{ color: '#E08797' }}
            ></i>
          </Tooltip>
        ) : null}
        <Button disabled={errors.length} onClick={next}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default CategorySetup;
