import React, { useRef, useState, useEffect } from 'react';
import Tooltip from '../../components/Tooltip';
import { useViewGroupContext } from '../ViewGroup/context';
import {
  AmazonIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  MetaIcon,
  OrganicIcon,
  TikTokIcon,
  DisplayIcon,
  PinterestIcon,
  TvIcon,
  XIcon,
} from './icons';

export const TabList = ({ amount, exit, children }) => {
  const ref = useRef(null);
  const { mainView } = useViewGroupContext();

  const [isOverflowing, setIsOverflowing] = useState(false);

  const checkOverflow = () => {
    const { scrollWidth, clientWidth } = ref.current;
    setIsOverflowing(scrollWidth > clientWidth);
  };

  const scrollLeft = (scrollOffset) => {
    if (ref.current) {
      ref.current.scrollLeft += scrollOffset;
    }
  };

  const scrollRight = (scrollOffset) => {
    if (ref.current) {
      ref.current.scrollLeft += scrollOffset;
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [amount]);

  return (
    <div className="tab-list-wrapper">
      {isOverflowing && (
        <button
          onClick={() => scrollLeft(-200)}
          className="tab-list__arrow tab-list__left-arrow"
        >
          <i className="fa-solid fa-angle-left"></i>
        </button>
      )}

      <ul className="tab-list" ref={ref}>
        {children}
      </ul>

      {isOverflowing && (
        <button
          onClick={() => scrollRight(200)}
          className="tab-list__arrow tab-list__right-arrow"
        >
          <i className="fa-solid fa-angle-right"></i>
        </button>
      )}

      {!!exit && (
        <Tooltip
          content={`Exit view group and load ${mainView?.viewName ?? ''} view${
            mainView?.viewName ? '' : ' as normal'
          }`}
        >
          <button onClick={exit} className="tab-list-wrapper__exit-btn">
            <i className="fa-solid fa-x"></i>
          </button>
        </Tooltip>
      )}
    </div>
  );
};

type TabProps = {
  label: string;
  onSelect: () => void;
  isSelected: boolean;
};

export const Tab = ({ label, onSelect, isSelected }: TabProps) => {
  const types = [
    'Meta',
    'Tiktok',
    'Instagram',
    'Facebook',
    'LinkedIn',
    'Amazon',
    'Organic',
    'Pinterest',
    'X |',
    'TV',
    'Display',
  ];

  const tabType = types.find((t) => label.includes(t));

  const getTabIcon = (type) => {
    switch (type) {
      case 'Meta':
        return <MetaIcon />;
      case 'TikTok':
        return <TikTokIcon />;
      case 'Instagram':
        return <InstagramIcon />;
      case 'Facebook':
        return <FacebookIcon />;
      case 'LinkedIn':
        return <LinkedInIcon />;
      case 'Amazon':
        return <AmazonIcon />;
      case 'Organic':
        return <OrganicIcon />;
      case 'Pinterest':
        return <PinterestIcon />;
      case 'X |':
        return <XIcon />;
      case 'TV':
        return <TvIcon />;
      case 'Display':
        return <DisplayIcon />;
      default:
        return null;
    }
  };

  return (
    <>
      <li className={`tab ${isSelected ? 'is-selected' : ''}`}>
        <button onClick={onSelect}>
          {!!getTabIcon(tabType) && (
            <span className="icon">{getTabIcon(tabType)}</span>
          )}
          {label}
        </button>
        <span className="divider"></span>
      </li>
    </>
  );
};
