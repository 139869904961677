import { useState, useCallback } from 'react';

// check if theres unsaved overrides before switching selected label
export const useSafeLabel = () => {
  const [selectedLabel, setSelectedLabel] = useState<string | null>(null);
  
  const safeSetSelectedLabel = useCallback(
    (
      newLabel: string | null, 
      unsavedOverrides: Set<any>, 
      resetCallback: () => void
    ) => {
      if (unsavedOverrides.size > 0 && selectedLabel !== newLabel) {
        if (
          window.confirm(
            'You have unsaved override changes. Are you sure you want to change the selected label?'
          )
        ) {
          setSelectedLabel(newLabel);
          resetCallback();
        }
      } else {
        setSelectedLabel(newLabel);
      }
    },
    [selectedLabel]
  );

  return {
    selectedLabel,
    setSelectedLabel,
    safeSetSelectedLabel,
  };
}; 