import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Select from 'react-select';
import Button from '../../../components/Button';
import { BREAKDOWN_OPTIONS } from './constants';

const CopyHeader = ({
  dateRange,
  setDateRange,
  breakdownType,
  setBreakdownType,
  fetchData,
  loading,
}) => {
  const setDate = (type, value) => {
    setDateRange((prev) => ({ ...prev, [type]: value }));
  };

  return (
    <div className="copy-report__header">
      <form autoComplete="off" className="date-picker">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start date"
            value={dateRange.start}
            onChange={(selected) => setDate('start', selected.$d)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </form>
      <form autoComplete="off" className="date-picker">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="End date"
            value={dateRange.end}
            onChange={(selected) => setDate('end', selected.$d)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </form>
      <form className="breakdown-selector">
        <Select
          value={BREAKDOWN_OPTIONS.find((opt) => opt.value === breakdownType)}
          options={BREAKDOWN_OPTIONS}
          onChange={(selected) => setBreakdownType(selected.value)}
        />
      </form>
      <Button
        onClick={fetchData}
        disabled={loading}
        loading={loading}
      >
        Fetch data
      </Button>
    </div>
  );
};

export default CopyHeader;
