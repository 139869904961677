import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { dateInChicago } from '../../utils/darwin-dates';

const Placeholder = ({ now }) => {
  const current = useCurrentTime(now);

  return (
    <>
      <p>
        Data is currently processing. Dashboard is unavailable until 7:00AM CST
      </p>
      <p>
        Current time CST:
        {dateInChicago(current).toLocaleString()}
      </p>
    </>
  );
};

Placeholder.propTypes = {
  now: PropTypes.object,
};

export default Placeholder;

export function withinETL(d) {
  const hours = dateInChicago(d).getHours();

  return hours >= 0 && hours <= 6;
}

function useCurrentTime(date) {
  const [now, setNow] = useState(date);

  useInterval(() => {
    setNow(new Date());
  }, 1000);

  return now;
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = () => savedCallback.current();

    if (delay !== null) {
      const id = setInterval(tick, delay);

      return () => clearInterval(id);
    }
  }, [delay]);
}
