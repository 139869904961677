import React from "react";
import { connect } from "react-redux";

import Toast from "./Toast";

const STYLE = { zIndex: 10 };

const ToastWrapper = ({ notifications }) => (
  <div
    className="fixed-top w-100 d-flex flex-column align-items-end"
    style={STYLE}
  >
    <div className="w-25">
      {notifications.map(({ id, ...alert }) => (
        <Toast key={id} {...alert} />
      ))}
    </div>
  </div>
);

const stateToProps = ({ Notifications }, props) => ({
  notifications: Notifications
});

export default connect(stateToProps, null)(ToastWrapper);
