import { useRef, useEffect } from 'react';
import { abortableFetch } from '../../../utils';
import { CCC_RESPONSE, PREDICTIONS_RESPONSE } from './response';
import {
  useApiState,
  bindFetchToRef,
} from '../../../components/Hooks/useApiState';

const HEADERS = { 'content-type': 'application/json' };

function fetchCustomCategory(payload) {
  return abortableFetch(
    ['api', 'creative-analytics', 'custom-categories', payload.darwin_client_id, 'get'].join(
      '/'
    ),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

function deleteCustomCategory(payload) {
  return abortableFetch(
    ['api', 'creative-analytics', 'custom-categories', payload.darwin_client_id, 'delete'].join(
      '/'
    ),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useCustomCategoriesApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (payload) => withRef(fetchCustomCategory(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );
  const [deleteState, deleteDispatch] = useApiState(
    (payload) => withRef(deleteCustomCategory(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    get: {
      ...getState,
      request: getDispatch,
      // data: CCC_RESPONSE.data.body.ccc_doc,
    },
    delete: {
      ...deleteState,
      request: deleteDispatch,
    },
  };
}
