import React, { useEffect, useMemo, useState } from 'react';
import { Creative } from './Creative';
import './VisualsWrapper.scss';
import { formatPreviousData, getVisualsPerPage } from './utils';
import { EmptyState } from './EmptyState';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { Pagination } from '@mui/material';
import LazyLoader from '../../../components/LazyLoader';
import { usePreviousDateRangeData } from '../PeriodComparison/api';
import { generateMockPreviousPeriodData } from '../PeriodComparison/utils';
import { useFetchPFRData } from '../../CreativeAnalytics/hooks/useFetchPFRData';

export const VisualsWrapper = ({
  visuals,
  columns,
  metadataFields,
  metadata,
  groupParams,
  formatMetric,
  columnOptions,
  handleCreateManualTag,
  stacked,
  iterationsData,
  isOrganicView,
}) => {
  const { width } = useWindowSize();
  const visualsPerPage = getVisualsPerPage(width);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const numOfPages = Math.ceil(visuals.length / visualsPerPage);

  const startIndex = (currentPage - 1) * visualsPerPage;
  const visibleData = stacked
    ? visuals
    : visuals.slice(startIndex, startIndex + visualsPerPage);

  useEffect(() => {
    if (numOfPages > 0) {
      if (currentPage > numOfPages) {
        setCurrentPage(numOfPages);
      }

      if (currentPage < 1) {
        setCurrentPage(1);
      }
    } else {
      setCurrentPage(0);
    }
  }, [visuals, visualsPerPage, currentPage]);

  if (visuals.length < 1) return <EmptyState />;

  return (
    <div className="dna-grid">
      <ul>
        {visibleData.map((visual, i) => {
          const hasIterations = iterationsData?.some(
            (x) => x.source_asset_id === visual.id
          );
          
          if (visual) {
            return (
              <LazyLoader>
                <li className="dna-grid-item" style={{ position: 'relative' }}>
                  <Creative
                    visual={visual}
                    columns={columns}
                    metadata={metadata}
                    metadataFields={metadataFields}
                    rank={visual?.rank}
                    showRankings={true}
                    formatMetric={formatMetric}
                    columnOptions={columnOptions}
                    handleCreateManualTag={handleCreateManualTag}
                    hasIterations={hasIterations}
                    isOrganicView={isOrganicView}
                  />
                </li>
              </LazyLoader>
            );
          }
        })}
      </ul>
      {!stacked && (
        <Pagination
          onChange={(e, value) => handlePageChange(value)}
          count={numOfPages}
          variant="outlined"
          color="primary"
        />
      )}
    </div>
  );
};
