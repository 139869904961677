import React from "react";
import { useSuggestionsContext } from "../contexts";
import Tooltip from "../../../../../components/Tooltip";

const Header = ({
  setShowMenu,
  isDemoClient,
  setRecsErrorDisplay,
}) => {
  const {
    showMenu,
    bestPerforming,
    fromCreativeStudio,
  } = useSuggestionsContext();

  return (
    <div className="header">
      {(bestPerforming || fromCreativeStudio) && (
        <div className="subheader subheader--sm">How to Improve</div>
      )}
      {isDemoClient && !bestPerforming && (
        <>
          <Tooltip
            content={`${showMenu ? "Close" : "Open"} suggestions menu`}
          >
            <div
              className={`hamburger ${showMenu ? "open" : ""}`}
              onClick={() => {
                setShowMenu((prev) => !prev);
                setRecsErrorDisplay("");
              }}
            >
              <div></div>
            </div>
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default Header;
