import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './CreativeRequest.scss';

import CreativeRequest from './CreativeRequest';
import { CreativeRequestToggle } from './Toggle';

import { setReports } from '../../../store/CreativeReports/actions';

import { RATIO, FORMAT } from './constants';
import { useCreativeRequestGet, useCreativeRequestSet } from './contexts';
import { trackEvent } from '../../../store/UserActivity/actions';
import {
  CREATIVE_ANALYTICS,
  OPEN_CREATIVE_REQUEST_DRAWER,
} from '../../../store/UserActivity/constants';

const Wrapper = ({ filteredData, CreativeRequestPortal, requestOpen, setRequestOpen }) => {
  const { currentRequest } = useCreativeRequestGet();
  const open = requestOpen;
  const dispatch = useDispatch();

  const selectedRatio = currentRequest[RATIO];
  const selectedFormat = currentRequest[FORMAT];

  const ratioRate = getRatioRate(filteredData, selectedRatio);
  const formatRate = getFormatRate(filteredData, selectedFormat);

  return (
    <div className={`request-wrapper${open ? ' open' : ''}`}>
      <CreativeRequestPortal.Portal>
        <CreativeRequest {...{ open, ratioRate, formatRate }} />
      </CreativeRequestPortal.Portal>
    </div>
  );
};

export default Wrapper;

const getRatioRate = (data, value) => {
  const selectedLabel = data.find((label) => {
    return label.name === `${value} Ratio`;
  });

  if (selectedLabel) {
    return selectedLabel.performance_percent;
  }

  return 0;
};

const getFormatRate = (data, value) => {
  const selectedLabel = data.find((label) => {
    return label.name === value;
  });

  if (selectedLabel) {
    return selectedLabel.performance_percent;
  }

  return 0;
};
