/* eslint-disable linebreak-style */
/* eslint-disable no-use-before-define */
import { strToNum } from '../../utils/numbers';

export function parsePercent(str) {
  return !str ? null : strToNum(str) * 100;
}

/**
 * @param {any[]} arr
 * @param {number} size
 *
 * @return {any[]}
 */
export function partition(arr, size) {
  const results = [];
  let current = [];
  let i = 0;

  arr.forEach((item) => {
    if (i >= size) {
      results.push(current);
      current = [];
    }

    i = current.push(item);
  });

  if (i > 0) {
    results.push(current);
  }

  return results;
}

/**
 * @param { { value: string }[] } reportData
 * @param { { value: string, label: string, description: string }[] } standardLabels
 *
 * @return { { value: string, label: string, description: string }[] }
 */
export function setLabels(reportData, ad_label_type, standardLabels) {
  const possibleCustom = [
    ...standardLabels,
    ...customLabels(reportData),
    ...rekognitionLabels(reportData, '.', '+'),
  ];

  if (ad_label_type === 'visual_attributes') {
    return matchLabels(reportData, rekognitionLabels(reportData));
  }
  if (ad_label_type === 'standardized') {
    return matchLabels(reportData, standardLabels);
  }
  return matchLabels(reportData, possibleCustom);
}

function customLabels(data, delimeter = '.') {
  return data
    .filter(({ value }) => value[0] === delimeter)
    .map(({ value, belowThreshold }) => {
      const label = value.slice(0, 10);

      return {
        value,
        label,
        description: `${label} is a user defined label.`,
        category: 'Unique Client Attributes',
        example: {},
        belowThreshold: !!belowThreshold,
      };
    });
}

function rekognitionLabels(data, delimeter, delimeter2) {
  return data
    .filter(
      ({ value }) => value[0] !== delimeter && value.slice(-1) !== delimeter2
    )
    .map(({ value, belowThreshold }) => {
      const label = value.slice(0, 10);

      return {
        value,
        label,
        description: `${label} is an AI detected label.`,
        category: 'Unique Client Attributes',
        example: {},
        belowThreshold: !!belowThreshold,
      };
    });
}

// This function is called dozens of times.
// Each call can yield a lookup hundreds of KB's
// in size.
// Need to instantiate the lookup once and
// give each Matrix instance access to it.
function matchLabels(data, labels) {
  const lookup = toDict(data, 'value');

  const results = [];

  labels.forEach(({ value, label, description, example, category }) => {
    const maybe = lookup[value];

    let rating = 'rating-average';
    let disabled = false;
    let difference = 0;

    if (!!maybe && !maybe.belowThreshold) {
      const diff = setDifference(maybe);

      if ((diff === null || diff === 0)) {
        rating = 'rating-absent';
      } else {
        rating = setRatingClass(diff);
      }
      disabled = !!maybe.disabled;
      difference = diff;
    } else {
      rating = 'rating-absent';
    }

    results.push({
      value,
      label,
      description,
      example,
      category,
      rating,
      disabled,
      difference,
    });
  });

  const priority = ['rating-average', 'rating-absent'];

  results.sort((a, b) => {
    if (priority.includes(a.rating) && priority.includes(b.rating)) {
      return priority.indexOf(a.rating) - priority.indexOf(b.rating);
    }
    return b.difference - a.difference;
  });

  return results;
}

function setDifference({ percent_difference, cpr, other_cpr }) {
  if (isNonValue(other_cpr)) {
    return 0;
  }
  if (isNonValue(cpr)) {
    return null;
  }
  return parsePercent(percent_difference);
}

function isNonValue(n) {
  return n === null || n === undefined;
}

function setRatingClass(diff, threshold = 10) {
  if (diff === null || diff === 0) {
    return 'rating-average';
  }

  if (-threshold <= diff && diff <= threshold) {
    return 'rating-average';
  }

  if (diff > 0) {
    if (diff > 25) {
      return 'rating-good-4';
    }
    if (diff > 20) {
      return 'rating-good-3';
    }
    if (diff > 15) {
      return 'rating-good-2';
    }
    return 'rating-good-1';
  }

  if (diff < 0) {
    if (diff < -25) {
      return 'rating-bad-4';
    }
    if (diff < -20) {
      return 'rating-bad-3';
    }
    if (diff < -15) {
      return 'rating-bad-2';
    }
    return 'rating-bad-1';
  }
}

function toDict(arr, key) {
  const results = {};

  arr.forEach((item) => {
    results[item[key]] = item;
  });

  return results;
}
