import React, { useState } from "react";

import { Popover } from "@mui/material";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import "./CommonLabels.scss";
import Tooltip from "../../../../components/Tooltip";
import { CloseIcon } from "../../../../components/Icons";
import { ChevronDoubleDownIcon, ChevronDoubleUpIcon } from "../../ReportCard/icons";
import { ApproxEqualIcon } from "../../ReportCard/icons";

export const CommonLabels = ({ labels, name, loading }) => {
  const [anchor, setAnchor] = useState(null);
  const { commonAll, commonWinners, commonLosers } = labels;

  const openPopover = (e) => {
    setAnchor(e.currentTarget);
  };

  // for overwriting MUI styles
  const cache = createCache({
    key: "css",
    prepend: true,
  });

  const LabelsList = ({ list, type }) => {
    return (
      <div className="common-list--full">
        <ul className={`m-0 labels-list--${type}`}>
          {list.map((i, index) => {
            let res = _.truncate(i, {
              length: 18,
            });
            return (
              <li>
                <span>{res}</span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const sortedList = (list) => {
    return list
      .filter((x) => !!x)
      .sort(
        (a, b) =>
          a &&
          a.localeCompare(b, undefined, {
            sensitivity: "base",
            ignorePunctuation: true,
          })
      );
  };

  if (loading) return null;

  return (
    <CacheProvider value={cache}>
      <>
        <Tooltip content="View common elements">
          <span className="view-labels">
            <button
              onClick={openPopover}
              data-title="Show more common elements"
            >
              <i className="fa-solid fa-tags view-labels__icon"></i>
            </button>
          </span>
        </Tooltip>
        <Popover
          id={"common-labels-popover"}
          open={Boolean(anchor)}
          anchorEl={anchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          onClose={() => {
            setAnchor(null);
          }}
        >
          <div className="common-labels">
            <div className="d-flex justify-content-between align-items-end common-labels__header">
              <p className="common-labels__name">
                <span>Common Elements</span> - {name}
              </p>
              <button onClick={() => setAnchor(null)}>
                <CloseIcon height={24} width={24} />
              </button>
            </div>
            {commonAll && commonWinners && commonLosers && (
              <div className="common-labels__container">
                <div className="labels-list">
                  <div className="labels-list__title">
                    <ApproxEqualIcon fill={"#039BE5"} />
                  <p>All ({commonAll.length.toString()})</p>
                  </div>
                  {commonAll.length ? (
                    <LabelsList list={sortedList(commonAll)} type={"all"} />
                  ) : (
                    <span>No common elements found.</span>
                  )}
                </div>
                <div className="labels-list">
                  <div className="labels-list__title">
                    <ChevronDoubleUpIcon fill={"#25A778"} />
                    <p>Above Average ({commonWinners.length.toString()})</p>
                  </div>                  
                  {commonWinners.length ? (
                    <LabelsList
                      list={sortedList(commonWinners)}
                      type={"above"}
                    />
                  ) : (
                    <span>No common elements found.</span>
                  )}
                </div>
                <div className="labels-list">
                  <div className="labels-list__title">
                    <ChevronDoubleDownIcon fill={"#B95D7E"} />
                    <p>Below Average ({commonLosers.length.toString()})</p>
                  </div>
                  {commonLosers.length ? (
                    <LabelsList
                      list={sortedList(commonLosers)}
                      type={"below"}
                    />
                  ) : (
                    <span>No common elements found.</span>
                  )}
                </div>
              </div>
            )}
          </div>
        </Popover>
      </>
    </CacheProvider>
  );
};
