
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ErrorBoundary } from '../../../components/Helpers/Error';
import DisplayTable from './DisplayTable';
import { CpaDisplay, FrequencyDisplay } from './PerformanceTable';
import Trendline from '../Trendline';
import { useDimensions } from '../../../components/Hooks/dom-utils';

import '../StudyModal';

const OUTCOMES = 'CreativeReports.outcomes';
const FREQUENCY = 'CreativeReports.performanceFrequency';

import './styles.scss';
import { DARWIN_RED, DARWIN_SEAFOAM, DARWIN_YELLOW } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import Suggestions from './Suggestions';
import { ADSET_NAME, PERFORMANCE_FREQUENCY, VISUAL_HASH } from '../../../store/CreativeReports/constants';
import { setReports } from '../../../store/CreativeReports/actions';
import { InfoMessage } from '../Charts/Helpers';

const Displays = ({ values, valueId, percent_difference, ad_ids, report_type, asset }) => {
  const [display, dimensions] = useDimensions();
  const colors = getColors(percent_difference);
  const {
    performance_frequency_data,
    ad_label_data,
    chart_type,
    selected_type,
    frequency_ratio,
    hide_others,
    only_new_ads,
    showPreviews,
    start_date,
    end_date,
    spendThreshold,
    isDirectMail,
  } = useSelector(
    ({
      CreativeReports: {
        reports,
        performance_frequency,
        chart_type,
        selected_type,
        frequency_ratio,
        outcomes,
        hide_others,
        only_new_ads,
        showPreviews,
      },
      ViewSettings: {
        start_date,
        end_date,
        spendThreshold,
        isDirectMail,
      },
      Loaders,
    }) => ({
      ad_label_data: reports.ad_labels || [],
      performance_frequency_data: performance_frequency || [],
      chart_type,
      selected_type,
      frequency_ratio,
      outcomes,
      hide_others,
      only_new_ads,
      showPreviews,
      start_date,
      end_date,
      spendThreshold,
      isDirectMail,
      loadingOutcomes: Loaders[OUTCOMES],
      loadingFrequency: Loaders[FREQUENCY],
    })
  );

  const ratio = frequency_ratio || 0;
  const dispatch = useDispatch();

  const style = {
    overflow: 'auto',
    height: '100%',
    backgroundColor: colors.background,
  };

  useEffect(() => {
    if (hide_others) {
      values[0].data.splice(1, 1);
    }
  }, [hide_others]);

  const handleShowPreviews = () => {
    dispatch(setReports({
      showPreviews: true,
    }))
  }

  return (
    <ErrorBoundary fallback={<h5>An error occurred :(</h5>}>
      {valueId === "" ? (
        <p className="p-4">
          Select an element on the chart to get performance insights.
        </p>
      ) : (
        <div style={style} className="right-display" ref={display}>
          <CpaDisplay label={valueId} difference={percent_difference} />
          {values.length > 0 &&
            values.map((value, i) => {
              let assetPreview = <img src={asset?.asset_url ?? ''} />;

              if (asset?.visual_type === "video") {
                assetPreview = (
                  <video
                    key={asset?.asset_url ?? ''}
                    muted
                    plays-inline="true"
                    controls
                  >
                    <source src={asset?.asset_url ?? ''} type="video/mp4" />
                  </video>
                );
              }

              return (
                <>
                  {!isDirectMail &&
                  i === 0 &&
                  !showPreviews &&
                  report_type !== ADSET_NAME &&
                  asset ? (
                    <div className="asset-preview">{assetPreview}</div>
                  ) : null}
                  <DisplayTable
                    key={`preview-${value}-${i}`}
                    value={value}
                    valueId={valueId}
                    ad_ids={ad_ids}
                    colors={colors}
                  />
                </>
              );
            })
          }
          {/* <Trendline
            ad_ids={ad_ids}
            isDirectMail={isDirectMail}
            width={dimensions.width || 300}
            backgroundColor={colors.background}
            foregroundColor={colors.foreground}
            tertiary={colors.tertiary}
          /> */}
        </div>
      )}
    </ErrorBoundary>
  );
};

Displays.propTypes = {
  valueId: PropTypes.string.isRequired,
  percent_difference: PropTypes.string,
  values: PropTypes.array,
  handleModal: PropTypes.func,
};

export default Displays;

const getColors = (diff) => {
  const parsed = parseFloat(diff);

  const [background, foreground, tertiary] =
    parsed >= 10
      ? ['#fff', DARWIN_SEAFOAM, '#666']
      : parsed <= -10
        ? ['#fff', DARWIN_RED, '#666']
        : ['#fff', DARWIN_YELLOW, '#666'];

  return { foreground, background, tertiary };
};
