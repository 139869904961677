import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useGetRequest } from '../../../../components/Hooks/useGetRequest';
import { useDispatch, useSelector } from 'react-redux';
import { useCustomCategoriesData } from './selectors';
import { useFetchCustomCategoriesApi } from './api';
import Overlay from '../../../../components/Overlay';
import Modal from '../../../../components/Modal';
import { ErrorBoundary } from '../../../../components/Helpers/Error';
import { useCreativeAnalyticsGet } from '../../contexts';
import { RESET, SET_DATA, SET_DISPLAY, useQaState } from './reducer';
import { DisplayToggle, Header } from './Header';
import { AssetDisplay } from './AssetDisplay';
import QaContextProvider from './context';
import { CategoriesEditor } from './CategoriesEditor';
import { VisualObject } from '../../types';

import './styles.scss';
import '../CategoryBuilder.scss';
import { setShowQaEditor } from '../../../../store/CreativeAnalytics/creativeAnalyticsSlice';
import { RightDrawerWrapper } from '../../RightDrawer';
import PhraseBuilder from '../../PhraseBuilder';
import { QaPhraseBuilderWrapper } from './PhraseBuilderWrapper';
import { useVisuals } from '../../selectors';

export const useShowQaEditor = () => {
  const dispatch = useDispatch();
  const { showQaEditor } = useSelector(
    ({ CreativeAnalytics: { showQaEditor } }) => ({ showQaEditor })
  );

  return [showQaEditor, (shouldShow) => dispatch(setShowQaEditor(shouldShow))];
};

export const QA = ({ loading: pfrLoading, setShowQaEditor }) => {
  const { customCategoriesData, request, loading, error } =
    useFetchCustomCategoriesApi();
  const [qaState, qaDispatch] = useQaState();
  const visuals: VisualObject[] = useVisuals();

  const visualsData: VisualObject[] = useMemo(() => {
    const list = visuals || [];
    return list.map((visual) => ({
      id: visual.id,
      asset: visual.asset,
      asset_url: visual.asset_url,
      spend: visual.spend,
      visual_type: visual.visual_type,
    }));
  }, [visuals]);

  const { display } = qaState;

  const setDisplay = (update) =>
    qaDispatch({ type: SET_DISPLAY, display: update });

  const fetchCustomCategories = () => {
    qaDispatch({ type: RESET });
    request();
  };

  useEffect(() => {
    fetchCustomCategories();
  }, []);

  useEffect(() => {
    if (pfrLoading) {
      fetchCustomCategories();
    }
  }, [pfrLoading]);

  useEffect(() => {
    if (customCategoriesData) {
      qaDispatch({
        type: SET_DATA,
        data: customCategoriesData,
      });
    }
  }, [customCategoriesData]);

  return (
    <QaContextProvider
      qaState={qaState}
      qaDispatch={qaDispatch}
      visualsData={visualsData}
    >
      <Overlay show close={() => setShowQaEditor(false)}>
        <Modal styles={{ overflowY: 'initial' }}>
          <Display
            loading={loading}
            error={error}
            display={display}
            setDisplay={setDisplay}
            visual_hash={visualsData[qaState.currentVisualIndex]?.id || null}
            fetchCustomCategories={fetchCustomCategories}
          />
        </Modal>
      </Overlay>
    </QaContextProvider>
  );
};

const Display = ({
  error,
  loading,
  display,
  setDisplay,
  visual_hash,
  fetchCustomCategories,
}) => {
  if (error) {
    return <p>Error: {error?.error?.toString()}</p>;
  }
  if (loading) {
    return (
      <div className="category-manager category-manager--loading">
        <p className="category-manager--loading__msg loading-text">
          Fetching category data
        </p>
      </div>
    );
  }

  let MainDisplay = <CategoriesEditor />;

  if (display === 'phrase-builder') {
    MainDisplay = <QaPhraseBuilderWrapper visual_hash={visual_hash} />;
  }

  return (
    <ErrorBoundary>
      <Layout
        display={display}
        Header={
          <Header
            DisplayToggle={
              <DisplayToggle
                display={display}
                setDisplay={setDisplay}
                fetchCustomCategories={fetchCustomCategories}
              />
            }
          />
        }
        AssetDisplay={<AssetDisplay shouldShowFrames={display === 'ccc'} />}
      >
        {MainDisplay}
      </Layout>
    </ErrorBoundary>
  );
};

const Layout = ({ Header, AssetDisplay, display, children }) => {
  return (
    <div className={`qa-editor qa-editor--${display}`}>
      {Header}
      <div className="qa-editor-content">
        <div className="qa-editor-left">{AssetDisplay}</div>
        {/* <RightDrawerWrapper> */}
        <div className="qa-editor-right">{children}</div>
        {/* </RightDrawerWrapper> */}
      </div>
    </div>
  );
};
