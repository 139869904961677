import React from 'react';
import { DARWIN_BLUE } from '../../../constants';

const ScatterIcon = ({ active }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.65179 15.1664H15.652V13.5194H2.47533V0.342712H0.828247V14.3429C0.828247 14.5613 0.915013 14.7708 1.06946 14.9252C1.2239 15.0797 1.43337 15.1664 1.65179 15.1664Z"
      fill={active ? DARWIN_BLUE : '#616161'}
    />
    <path
      d="M6.59303 6.10752C7.50269 6.10752 8.24012 5.37009 8.24012 4.46044C8.24012 3.55078 7.50269 2.81335 6.59303 2.81335C5.68338 2.81335 4.94595 3.55078 4.94595 4.46044C4.94595 5.37009 5.68338 6.10752 6.59303 6.10752Z"
      fill={active ? DARWIN_BLUE : '#616161'}
    />
    <path
      d="M13.1814 9.40165C14.091 9.40165 14.8284 8.66422 14.8284 7.75456C14.8284 6.84491 14.091 6.10748 13.1814 6.10748C12.2717 6.10748 11.5343 6.84491 11.5343 7.75456C11.5343 8.66422 12.2717 9.40165 13.1814 9.40165Z"
      fill={active ? DARWIN_BLUE : '#616161'}
    />
    <path
      d="M7.82833 10.2252C8.51057 10.2252 9.06364 9.67213 9.06364 8.98989C9.06364 8.30765 8.51057 7.75458 7.82833 7.75458C7.14609 7.75458 6.59302 8.30765 6.59302 8.98989C6.59302 9.67213 7.14609 10.2252 7.82833 10.2252Z"
      fill={active ? DARWIN_BLUE : '#616161'}
    />
    <path
      d="M11.946 4.46043C12.6283 4.46043 13.1813 3.90736 13.1813 3.22512C13.1813 2.54287 12.6283 1.98981 11.946 1.98981C11.2638 1.98981 10.7107 2.54287 10.7107 3.22512C10.7107 3.90736 11.2638 4.46043 11.946 4.46043Z"
      fill={active ? DARWIN_BLUE : '#616161'}
    />
  </svg>
);

export default ScatterIcon;
