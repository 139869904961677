import { connect } from 'react-redux';
import { toDarwinDate } from '../../utils/darwin-dates';

const stateToProps = (
  {
    ViewSettings: {
      viewId,
      viewName,
      account_id,
      loggedIn,
      start_date,
      end_date,
      spendThreshold,
      selectedAudience,
      allowedAdmin,
      platforms,
      snapchatAccountId,
      isLeverView,
      isDirectMail,
      darwin_light,
      creative_studio_only,
      isNonPaidMediaType,
      isUltraLight,
      darwin_client_id,
    },
    CreativeReports: { report_type, chart_type }
  },
  props
) => ({
  viewId,
  viewName,
  account_id,
  loggedIn,
  start: toDarwinDate(start_date),
  end: toDarwinDate(end_date),
  spendThreshold,
  audience: selectedAudience,
  allowedAdmin,
  platforms,
  snapchatAccountId,
  isLeverView,
  isDirectMail,
  report: report_type,
  chart: chart_type,
  darwin_light,
  creative_studio_only,
  isNonPaidMediaType,
  isUltraLight,
  darwin_client_id,
});

export default connect(stateToProps, null);
