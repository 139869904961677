import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import Button from '../../../../../components/Button';
import PropTypes from 'prop-types';
import joi from 'joi';

const FILTER_NAMES = ['ad'];
const DEFAULT_FILTER = { include: '', exclude: '' };

function setFilters(filters = []) {
  const lookup = filters.reduce((acc, filter) => {
    acc[filter.name] = filter;

    return acc;
  }, {});

  return FILTER_NAMES.map((name) => {
    if (lookup[name]) {
      return lookup[name];
    }
    return { ...DEFAULT_FILTER, name };
  });
}

function makeSchemaValidator(joiSchema) {
  return (value) => {
    const { error } = joiSchema.validate(value);

    return !error;
  };
}

const findIfValueChanged = (initialValue, val) => {
  if (Array.isArray(val)) {
    return !_(initialValue).xorWith(val, _.isEqual).isEmpty();
  }
  return !_.isEqual(initialValue, val);
};

function useInputState(initialValue, validator) {
  const [val, setV] = useState(initialValue);

  return {
    value: val,
    valid: validator(val),
    handleChange: setV,
    hasChanged: findIfValueChanged(initialValue, val),
  };
}

function FilterItem({ onChange, name, include, exclude, index }) {
  const handleChange = (e) => {
    e.persist();

    return onChange(e);
  };

  return (
    <div className="label-form__group">
      <h3 className="label-form__filter-heading">Ad Name String</h3>
      <div className="d-flex flex-column">
        <div className="mb-4">
          <label className="label-form__input-label">Include</label>
          <input
            max={300}
            type="text"
            name={`${index}:${name}:include`}
            onChange={handleChange}
            value={include}
            className="form-control"
          />
        </div>
        <div className="">
          <label className="label-form__input-label">Exclude</label>
          <input
            max={300}
            type="text"
            name={`${index}:${name}:exclude`}
            onChange={handleChange}
            value={exclude}
            className="form-control"
          />
        </div>
      </div>
    </div>
  );
}

export function LabelForm({
  onSaveLabel,
  onDelete,
  name,
  filters,
  setIsEditing,
}) {
  const Name = useInputState(
    name,
    makeSchemaValidator(joi.string().min(1).required())
  );
  const Filters = useInputState(
    setFilters(filters),
    makeSchemaValidator(
      joi.array().items(
        joi.object({
          name: joi.string().min(1).optional(),
          include: joi.string().allow('').optional(),
          exclude: joi.string().allow('').optional(),
        })
      )
    )
  );
  const hasChanged = Name.hasChanged || Filters.hasChanged;

  const handleNameChange = (e) => {
    Name.handleChange(e.target.value);
  };

  const handleFilterChange = (e) => {
    const [i, , strType] = e.target.name.split(':');

    Filters.handleChange((prev) => {
      const clone = [...prev];

      clone[i][strType] = e.target.value;

      return clone;
    });
    setIsEditing(true);
  };

  useEffect(() => {
    return onSaveLabel({
      name: Name.value,
      filters: Filters.value,
    });
  }, [Name.value, Filters.value]);

  useEffect(() => {
    if (hasChanged) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [hasChanged]);

  useEffect(() => {
    Name.handleChange(name);
    Filters.handleChange(filters);
  }, [name]);

  return (
    <>
      <div className="label-form__options mt-2">
        <Button
          appearance="subtle"
          onClick={onDelete}
        >
          <i
            className="fa fa-trash mr-3"
            style={{ fontSize: '1.3rem', color: '#667394' }}
          ></i>
          Delete Label
        </Button>
      </div>
      <form>
        <div className="label-form__group">
          <label>Label Name</label>
          <input
            max={100}
            type="text"
            className="form-control"
            value={Name.value}
            placeholder="Enter a name..."
            onChange={handleNameChange}
          />
        </div>

        {Filters.value?.map((filter, i) => (
          <FilterItem
            key={filter.name}
            name={filter.name}
            include={filter.include}
            exclude={filter.exclude}
            index={i}
            onChange={handleFilterChange}
          />
        ))}
      </form>
    </>
  );
}

export function LabelFormPlaceholder({ onNewClick }) {
  return (
    <>
      <div className="label-form__placeholder">
        <p className="">
          Pick a label on the left or
          <button
            type="button"
            className="btn btn-link p-1"
            onClick={onNewClick}
          >
            create a new one
          </button>
          .
        </p>
      </div>
    </>
  );
}
