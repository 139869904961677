export function getFeatureUrl(location) {
  const [, , maybeFeature] = location.pathname.split('/');

  return maybeFeature || 'dashboard';
}

function indexByClient(views) {
  const groups = Object.create(null);

  views.forEach((view) => {
    const { client_id, is_primary } = view;

    if (groups[client_id]) {
      if (is_primary) {
        groups[client_id] = [view, ...groups[client_id]];
      } else {
        groups[client_id].push(view);
      }
    } else {
      groups[client_id] = [view];
    }
  });

  return groups;
}

function setRestViews(views) {
  return views.map((view) => ({
    ...view,
    label: view.view_name,
    value: view.view_id,
  }));
}

export function groupViews(views) {
  const groups = indexByClient(views);
  const toResult = (key) => {
    const [first, ...rest] = groups[key];

    return {
      ...first,
      value: first.view_id,
      label: first.client_name,
      rest: setRestViews(rest),
    };
  };

  return Object.keys(groups)
    .map(toResult)
    .sort((a, b) => a.label.localeCompare(b.label));
}
