import React from "react";
import PropTypes from "prop-types";

const withMethods = Comp => {
  class SZ_Methods extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        activeKey: this.props.initialKey || ""
      };

      this.handleSelection = this.handleSelection.bind(this);
    }

    componentWillUnmount() {
      this.handleSelection = null;
    }

    handleSelection(data) {
      let newKey = `${data.type}-${data.value}`;
      let update = {};

      if (newKey === this.state.activeKey) {
        update = { type: "" };
        newKey = "";
      } else {
        update = data;
      }

      this.setState({ activeKey: newKey }, () =>
        this.props.handleSelection(update)
      );
    }

    render() {
      const { toggleTooltip, handleSelection, ...props } = this.props;

      return (
        <Comp
          {...props}
          {...this.state}
          toggleTooltip={toggleTooltip}
          handleSelection={this.handleSelection}
        />
      );
    }
  }

  SZ_Methods.propTypes = {
    initialKey: PropTypes.string,
    handleSelection: PropTypes.func.isRequired,
    toggleTooltip: PropTypes.func
  };

  return SZ_Methods;
};

export default withMethods;
