import React from 'react';
import { Row, Col, FormGroup, FormLabel, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AccountPageFooter from '../AccountPageFooter/AccountPageFooter';
import InputHint from '../InputHint/InputHint';
import validate from '../Login/validateLogin';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const component = this;
    validate(component.form, {
      rules: {
        emailAddress: {
          required: true,
          email: true,
        },
        password: {
          required: true,
          minlength: 6,
        },
        code: {
          required: true,
        },
      },
      messages: {
        emailAddress: {
          required: 'Need an email address here.',
          email: 'Is this email address correct?',
        },
        password: {
          required: 'Need a password here.',
          minlength: 'Please use at least six characters.',
        },
        code: {
          required: 'Please provide code.',
        },
      },
      submitHandler() {
        component.handleSubmit();
      },
    });
  }

  handleSubmit() {
    this.props.fetchReset({
      email: this.emailAddress.value,
      password: this.password.value,
      code: this.code.value,
    });
  }

  renderSuccessMessage() {
    return (
      <div className="success">
        <h4>Your password has been reset.</h4>
        <p>
          <TaggedLink to="/login">
            Click here to login with your new credentials.
          </TaggedLink>
        </p>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Row>
          <Col xs={12} sm={6} md={5} lg={4}>
            <h4 className="page-header">Reset Password</h4>
            <form
              ref={(form) => (this.form = form)}
              onSubmit={(event) => event.preventDefault()}
            >
              <FormGroup>
                <FormLabel>Confirmation Code</FormLabel>
                <input
                  type="text"
                  name="Confirmation Code"
                  ref={(code) => (this.code = code)}
                  className="form-control"
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>Email Address</FormLabel>
                <input
                  type="email"
                  name="emailAddress"
                  ref={(emailAddress) => (this.emailAddress = emailAddress)}
                  className="form-control"
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>Password</FormLabel>
                <input
                  type="password"
                  name="password"
                  ref={(password) => (this.password = password)}
                  className="form-control"
                />
                <InputHint>Use at least six characters.</InputHint>
              </FormGroup>
              <Button type="submit" bsstyle="success">
                Reset Password
              </Button>
              <AccountPageFooter>
                <h3>
                  <Link to="/login">Return to Login</Link>.
                </h3>
              </AccountPageFooter>
            </form>
          </Col>
        </Row>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  fetchReset: PropTypes.func,
};

export default ResetPassword;
