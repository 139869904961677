import React from 'react';
import PropTypes from 'prop-types';

import { isNumber } from '../../../utils/numbers';
import { xAxisCategory, differenceToDollars } from '../utils';
import {
  CREATIVE_MATRIX,
  AD_STUDY,
  AD_LABELS,
  PERFORMANCE_FREQUENCY,
  TOP_PERFORMERS,
  RECOMMENDATIONS,
} from '../../../store/CreativeReports/constants';
import {
  SCATTER, TABLE, LABEL_MATRIX, LABEL_SETTINGS,
} from '../constants';
import SplitBar from './SplitBar';
import Scatter from './Scatter';
import HeatMatrix from './HeatMatrix';
import Table from './Table';
import AdStudyTable from './AdStudyTable';
import { LabelMatrix } from '../../../components/LabelMatrix';
import LabelSettings from './LabelSettings';

import CustomDropdown from '../../../components/CustomDropdown';
import { useCreativeInsights, useUrlProps } from '../report-hooks';

const Wrapper = ({
  id,
  chartType,
  data,
  handleSeriesClick,
  selectedId,
  dimensions,
  ...rest
}) => {
  const { creativeCount, spendThreshold } = useUrlProps();

  let chartProps = {
    data,
    handleSeriesClick,
    selectedId,
    dimensions,
  };

  if (rest.reportType === CREATIVE_MATRIX) {
    return <HeatMatrix {...chartProps} />;
  } if (rest.reportType === AD_STUDY && chartType === TABLE) {
    chartProps.id = id;

    return <AdStudyTable {...chartProps} />;
  }
  let Comp = null;
  
  switch (chartType) {
    case SCATTER:
      Comp = Scatter;
      break;

    case TABLE:
      Comp = Table;
      break;

    case LABEL_MATRIX:
      Comp = LabelMatrix;
      break;

    case LABEL_SETTINGS:
      Comp = LabelSettings;
      break;


    default:
      Comp = null;
  }

  switch (rest.reportType) {
    case AD_LABELS:
      chartProps.data = filterThresholds({
        data: chartProps.data,
        count: creativeCount,
        spendThreshold,
        chartType,
      });
      break;
  }

  if (Comp === null) {
    Comp = SplitBar;
    chartProps = { ...chartProps, ...deriveHistogramData(chartProps.data) };
  }

  return <Comp {...chartProps} />;
};

Wrapper.defaultProps = {
  id: 'creative-reports-chart',
  data: [],
};

Wrapper.propTypes = {
  handleSeriesClick: PropTypes.func.isRequired,
  selectedId: PropTypes.string.isRequired,
  dimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  chartType: PropTypes.string.isRequired,
  data: PropTypes.array,
  reportType: PropTypes.string.isRequired,
  id: PropTypes.string,
};

export default Wrapper;

const deriveHistogramData = (data) => {
  // Array.sort will mutate causing issues elsewhere.
  const sorted = [...data]
    .filter(({ cpr }) => isNumber(cpr))
    .sort((a, b) => b.difference - a.difference);

  return {
    seriesData: sorted.map(differenceToDollars),
    xAxisCategories: sorted.map(xAxisCategory),
    data: sorted,
  };
};

export const ChartPicker = ({ options, handleSelect, label }) => (
  <CustomDropdown
    id="chart-type-picker"
    label={label}
    onSelect={handleSelect}
  >
    {options.map(({ value, label }, i) => (
      <CustomDropdown.Item key={value} eventKey={i.toString()}>
        {label}
      </CustomDropdown.Item>
    ))}
  </CustomDropdown>
);

ChartPicker.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  options: PropTypes.array,
  label: PropTypes.string.isRequired,
};

function filterThresholds({ data, count = 1, spendThreshold, chartType }) {
  if (chartType === LABEL_MATRIX) {
    return data.map((item) => ((item.ads < count || item.spend < spendThreshold) ? { ...item, belowThreshold: true } : item));
  }
  return data.filter(({ ads }) => ads >= count);
}
