import { useReducer } from "react";

const INITIAL = {
  error: null,
  loading: false,
  data: null,
};

function apiReducer(state = INITIAL, action) {
  switch (action.type) {
    case "FETCHING":
      return {
        loading: true,
        data: null,
        error: null,
      };

    case "FAILURE":
      return {
        error: action.error,
        loading: false,
        data: null,
      };

    case "SUCCESS":
      return {
        loading: false,
        error: null,
        data: action.data,
      };

    default:
      return state;
  }
}

const middleware = (callApi) => (dispatch) => async (action) => {
  switch (action.type) {
    case "FETCHING":
      dispatch(action);

      try {
        const res = await callApi(...action.args);

        dispatch({ type: "SUCCESS", data: res });
      } catch (err) {
        dispatch({ type: "FAILURE", error: err });
      }

      break;

    default:
      return dispatch(action);
  }
};

/**
 * @return {[Object, Function]}
 */
export function useApiState(proc, initial = INITIAL) {
  const [state, dispatch] = useReducer(apiReducer, initial);

  const enhancedDispatch = middleware(proc)(dispatch);

  return [state, (...args) => enhancedDispatch({ type: "FETCHING", args })];
}
