import { useReducer, useState, useEffect } from "react";
import { labelSetupReducer } from "./reducer";

export const useLabelsSetup = () => {
  const [
    {
      activeBrands,
      inactiveBrands,
      activePromos,
      inactivePromos,
      existingKeywords,
      activeKeywords,
      inactiveKeywords,
      suggestedKeywords,
    },
    dispatchLabelSetup,
  ] = useReducer(labelSetupReducer, {
    activeBrands: [],
    inactiveBrands: [],
    activePromos: [],
    inactivePromos: [],
    existingKeywords: [],
    activeKeywords: [],
    inactiveKeywords: [],
    suggestedKeywords: [],
  });

  return {
    activeBrands,
    inactiveBrands,
    activePromos,
    inactivePromos,
    existingKeywords,
    activeKeywords,
    inactiveKeywords,
    suggestedKeywords,
    dispatchLabelSetup,
  }
};

export const useLabelSetupValidation = (labels, labelInput) => {
  const [meetsRequirements, setMeetsRequirements] = useState(false);
  const [labelInUse, setLabelInUse] = useState(false);
  
  useEffect(() => {
    if (labelInput.trim().length) {
      setMeetsRequirements(true);

      setLabelInUse(labels.map((l) => l.toLowerCase()).includes(labelInput.toLowerCase()));
    } else {
      setMeetsRequirements(false);
    }
  }, [labels, labelInput]);

  return {
    meetsRequirements,
    labelInUse,
  }
}