import React, { useState, useReducer, useEffect, useMemo } from 'react';
import Button from '../../../../../components/Button';

import './DerivedLabels.scss';
import { useDerivedLabelsState } from './hooks';
import { RulesList } from './RulesList';
import { SET_CATEGORY_NAME, SET_DATA, SET_DESCRIPTION } from './reducer';
import { RuleEditor } from './RuleEditor';
import { useDerivedLabelsApi } from './api';
import {
  useCreativeAnalyticsGet,
  useCreativeAnalyticsSet,
} from '../../../contexts';
import { deriveDerivedLabels, deriveListOfRules } from './utils';
import Confirm from './Confirm';
import { validate } from './validate';
import Tooltip from '../../../../../components/Tooltip';
import { useNotifications } from '../../../../../components/Notification/useNotifications';
import { END, START_30, toDarwinDate } from '../../../../../utils/darwin-dates';
import DerivativeFlow from '../../../DerivativeFlow';
import { DerivedLabelsSaveStructure } from './types';
import { nanoid } from 'nanoid';

const Wrapper = ({ Header, Left, Right, Footer }) => {
  return (
    <div className="derived-labels">
      {Header}
      <div className="derived-labels__main">
        <div className="derived-labels__left">{Left}</div>
        <div className="derived-labels__right">{Right}</div>
      </div>
      {Footer}
    </div>
  );
};

const DerivedLabels = ({
  derivedLabelsState,
  dispatchDerivedLabels,
  prev,
  derivedLabelsApi,
  getDerivedLabelsApi,
  allKeywords,
  showFlow,
  setShowFlow,
}) => {
  const { category_name } = derivedLabelsState;
  const [showConfirmScreen, setShowConfirmScreen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { account_id, period1 } = useCreativeAnalyticsGet();
  const { setEditCategory } = useCreativeAnalyticsSet();

  const period1Start = period1 ? period1.split(',')[0] : toDarwinDate(START_30);
  const period1End = period1 ? period1.split(',')[1] : toDarwinDate(END);

  const derived_labels: DerivedLabelsSaveStructure = useMemo(() => {
    if (derivedLabelsState.rules && derivedLabelsState.rules.length) {
      return deriveDerivedLabels(derivedLabelsState.rules);
    }
    return [];
  }, [derivedLabelsState.rules]);

  const [errors, deps] = useMemo(
    () =>
      validate(
        derived_labels,
        category_name,
        derivedLabelsState,
        getDerivedLabelsApi.get.data
      ),
    [
      derived_labels,
      category_name,
      derivedLabelsState,
      getDerivedLabelsApi.get.data,
    ]
  );

  const deleteCategory = () => {
    if (confirmDelete) {
      derivedLabelsApi.delete.request({
        platform_account_id: account_id,
        category: derivedLabelsState.category_name,
      });

      setEditCategory(null);
    } else {
      setConfirmDelete(true);
    }
  };

  const save = () => {
    const request_id = nanoid();

    derivedLabelsApi.post.request({
      platform_account_id: account_id,
      category_name: derivedLabelsState.category_name,
      original_category_name: derivedLabelsState.original_category_name,
      description: derivedLabelsState.description,
      derived_labels,
      start_date: period1Start,
      end_date: period1End,
      request_id,
    });
    setEditCategory(null);
  };

  const undoChanges = () => {
    dispatchDerivedLabels({
      type: SET_DATA,
      data: {
        rules: deriveListOfRules(derivedLabelsState.data),
      },
    });
  };

  if (showConfirmScreen) {
    return (
      <Confirm
        {...{ derived_labels, category_name, setShowConfirmScreen, save }}
      />
    );
  }

  const DeleteButtonWrapper = ({ children }) => {
    let shouldDisable = false;

    if (deps.size > 0) {
      shouldDisable = true;
      let messages = [];

      for (let [key, value] of deps.entries()) {
        messages.push(
          <li>
            The label "{key}" cannot be deleted because the following labels are
            derived from it:{' '}
            <ul>
              {value.map((label) => (
                <li>
                  {label.category_name}: {label.label_name}
                </li>
              ))}
            </ul>
          </li>
        );
      }

      return (
        <Tooltip
          className="derived-labels__error-tooltip"
          content={
            <div>
              <ul>{messages.map((message) => message)}</ul>
            </div>
          }
        >
          {children(shouldDisable)}
        </Tooltip>
      );
    }

    return children(shouldDisable);
  };

  const SaveButtonWrapper = ({ children }) => {
    let shouldDisable = false;

    if (errors.length > 0) {
      shouldDisable = true;

      return (
        <Tooltip
          className="derived-labels__error-tooltip"
          content={
            <div>
              The following errors must be resolved:
              <ul>
                {errors.map((err) => (
                  <li>{err}</li>
                ))}
              </ul>
            </div>
          }
        >
          {children(shouldDisable)}
        </Tooltip>
      );
    }

    return children(shouldDisable);
  };

  if (showFlow) {
    return (
      <DerivativeFlow
        category_name={category_name}
        derived_labels={derived_labels}
        onBack={() => setShowFlow(false)}
      />
    );
  }

  return (
    <Wrapper
      Header={
        <div className="derived-labels__header">
          <button className="sidebar__back" onClick={prev}>
            <i className="fas fa-arrow-left"></i>
            Setup
          </button>
          <Button appearance="subtleBordered" onClick={() => setShowFlow(true)}>
            Diagram view
          </Button>
        </div>
      }
      Left={
        <>
          <div className="mb-5">
            <label className="derived-labels__heading" htmlFor="">
              Category Name
            </label>
            <input
              maxLength={45}
              onChange={(e) =>
                dispatchDerivedLabels({
                  type: SET_CATEGORY_NAME,
                  category_name: e.target.value,
                })
              }
              value={derivedLabelsState.category_name}
            />
          </div>
          <div className="mb-5">
            <label className="derived-labels__heading" htmlFor="">
              Description
            </label>
            <input
              maxLength={200}
              onChange={(e) =>
                dispatchDerivedLabels({
                  type: SET_DESCRIPTION,
                  description: e.target.value,
                })
              }
              value={derivedLabelsState.description}
            />
          </div>
          <RulesList {...{ derivedLabelsState, dispatchDerivedLabels }} />
        </>
      }
      Right={
        <RuleEditor
          {...{ derivedLabelsState, dispatchDerivedLabels, deps, allKeywords }}
        />
      }
      Footer={
        <div className="derived-labels__footer">
          <Button onClick={undoChanges} appearance={'subtle'} className="mr-3">
            Undo All Changes
          </Button>
          <div className="d-flex justify-content-end align-items-center">
            {confirmDelete && (
              <p
                onClick={() => setConfirmDelete(false)}
                style={{ cursor: 'pointer' }}
                className="mb-0 mr-3"
              >
                Are you sure you want to delete this category?
              </p>
            )}
            {confirmDelete && (
              <Button appearance="gray" onClick={() => setConfirmDelete(false)}>
                Cancel
              </Button>
            )}

            {derivedLabelsState.isExisting && (
              <DeleteButtonWrapper>
                {(shouldDisable) => (
                  <div>
                    <Button
                      onClick={deleteCategory}
                      appearance={confirmDelete ? 'red' : 'subtle'}
                      className={confirmDelete ? 'ml-3' : 'mr-3'}
                      disabled={shouldDisable}
                    >
                      {confirmDelete ? 'Confirm' : 'Delete'}
                    </Button>
                  </div>
                )}
              </DeleteButtonWrapper>
            )}

            {!confirmDelete && (
              <SaveButtonWrapper>
                {(shouldDisable) => (
                  <div>
                    <Button
                      disabled={shouldDisable}
                      onClick={save}
                      style={{ color: '#fff' }}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </SaveButtonWrapper>
            )}
          </div>
        </div>
      }
    />
  );
};

export default DerivedLabels;
