import React from 'react';

import {
  useCreativeRequestGet,
  useCreativeRequestSet,
} from '../CreativeRequest/contexts';
import { INCLUDE_THESE, AVOID_THESE } from '../CreativeRequest/constants';

const DISABLE_AVOID = ['IMAGE+', 'VIDEO+', '1:1+', '4:5+', '16:9+', '9:16+'];

const CardOptions = ({ label, display, value, url, isTopPerformers, visual_type }) => {
  const { category } = label;
  const { currentRequest } = useCreativeRequestGet();
  const { setInclude, setAvoid, addToOverview } = useCreativeRequestSet();

  const assetUrl = url;
  const assetType = visual_type;

  const disableInclude =
    currentRequest[INCLUDE_THESE].some((item) => item.value === value) ||
    isTopPerformers;
  const disableAvoid =
    currentRequest[AVOID_THESE].some((item) => item.value === value) ||
    DISABLE_AVOID.includes(value) ||
    isTopPerformers;

  const handleIncludeLabel = () => {
    setInclude(value, url, category);
  };

  const handleAvoidLabel = () => {
    setAvoid(value, url);
  };

  const handleAddToOverview = () => {
    addToOverview(display, assetUrl, assetType, category, value);
  }
  
  return (
    <ul className="recommendation-card__options">
      <li
        className="recommendation-card__overview"
        onClick={handleAddToOverview}
      >
        Add to request <span>(Overview)</span>
      </li>
      {/* {!disableInclude && (
        <li
          className="recommendation-card__include"
          onClick={handleIncludeLabel}
        >
          Add to request <span>(Include)</span>
        </li>
      )}
      {!disableAvoid && (
        <li className="recommendation-card__avoid" onClick={handleAvoidLabel}>
          Add to request <span>(Avoid)</span>
        </li>
      )} */}
    </ul>
  );
};

export default CardOptions;
