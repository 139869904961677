export const createShareUrl = (
  visualsBelowThreshold,
  visualsBelowAverage,
  visualsAboveAverage,
  belowThresholdElements,
  belowAverageElements,
  aboveAverageElements,
  spendThreshold,
  primary_metric,
  primaryMetricLabel,
  viewId,
  isROAS,
) => {
  const mapElements = (els) => {
    return els.map((el) => `${el}`);
  };

  const mapVisuals = (visuals) => {
    return visuals.map(
      (v) =>
        `${v.visual_type},${replaceBucketUrl(
          v.asset_url,
          true
        )},${replaceBucketUrl(v.thumbnail_url, true)},${v[primary_metric]},${v.spend}`
    );
  };

  const col1 = `&elements1=${mapElements(
    belowThresholdElements
  )}&visuals1=${mapVisuals(visualsBelowThreshold)}`;

  const col2 = `&elements2=${mapElements(
    belowAverageElements
  )}&visuals2=${mapVisuals(visualsBelowAverage)}`;

  const col3 = `&elements3=${mapElements(
    aboveAverageElements
  )}&visuals3=${mapVisuals(visualsAboveAverage)}`;

  const prefix = window.location.href.split(`${viewId}/`)[0];

  return `${prefix}hurdle-report?${encodeURIComponent(
    `spendThreshold=${spendThreshold}` +
      `&primary_metric=${primary_metric}` +
      `&label=${primaryMetricLabel}` +
      `&roas=${isROAS}` +
      col1 +
      col2 +
      col3
  )}`;
};

export const replaceBucketUrl = (url, compress) => {
  if (!url) return 'undefined';

  if (compress) {
    if (url.includes('fb-assets.s3.us-east-2.amazonaws.com')) {
      return url.replace('fb-assets.s3.us-east-2.amazonaws.com', 'fb-assets');
    }
  
    if (url.includes('creative-man-assets.s3.us-east-2.amazonaws.com')) {
      return url.replace(
        'creative-man-assets.s3.us-east-2.amazonaws.com',
        'creative-man-assets'
      );
    }
  } else {
    if (url.includes('fb-assets')) {
      return url.replace('fb-assets', 'fb-assets.s3.us-east-2.amazonaws.com')
    }
  
    if (url.includes('creative-man-assets')) {
      return url.replace(
        'creative-man-assets',
        'creative-man-assets.s3.us-east-2.amazonaws.com'
      );
    }
  }

  return url;
};
