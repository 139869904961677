import React from 'react';

const GoogleAnalyticsIcon = ({ className = ""}) => (
  <svg className={className} width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.2501 2.14772V15.6477C11.2501 17.1583 12.2932 18 13.3978 18C14.4205 18 15.5455 17.2841 15.5455 15.6477V2.25C15.5455 0.865223 14.5228 -3.8147e-06 13.3978 -3.8147e-06C12.2728 -3.8147e-06 11.2501 0.954201 11.2501 2.14772Z" fill="#F9AB00"/>
    <path d="M5.62503 9V15.6477C5.62503 17.1583 6.66822 18 7.77277 18C8.7955 18 9.92051 17.2841 9.92051 15.6477V9.10227C9.92051 7.7175 8.89778 6.85227 7.77277 6.85227C6.64776 6.85227 5.62503 7.80647 5.62503 9Z" fill="#E37400"/>
    <path d="M2.14774 18C3.3339 18 4.29548 17.0384 4.29548 15.8523C4.29548 14.6661 3.3339 13.7045 2.14774 13.7045C0.961576 13.7045 0 14.6661 0 15.8523C0 17.0384 0.961576 18 2.14774 18Z" fill="#E37400"/>
  </svg>
)

export default GoogleAnalyticsIcon;
