import React, { useState } from 'react';
import Button from '../../components/Button';
import { InsightsItem } from './types';

export const Findings = ({ InsightsList }) => {
  return <div className="be-right-display">{InsightsList}</div>;
};

export const InsightsList = ({ insights, renderDataset }) => {
  const [showAll, setShowAll] = useState(false);

  const dataToDisplay = showAll ? insights : insights.slice(0, 3);

  return (
    <div>
      <ul>
        {dataToDisplay.map((insight: InsightsItem) => {
          const {
            statement_header,
            statement_description,
            statement_outcomes,
            category_name,
          } = insight;

          return (
            <li>
              <SectionWrapper
                {...{
                  statement_header,
                  statement_description,
                  statement_outcomes,
                  category_name,
                  renderDataset,
                }}
              />
            </li>
          );
        })}
      </ul>
      <Button
        appearance="subtleBordered"
        onClick={() => setShowAll((prev) => !prev)}
      >
        Show {showAll ? 'Fewer' : 'All'}
      </Button>
    </div>
  );
};

const SectionWrapper = ({
  statement_header,
  statement_description,
  statement_outcomes,
  category_name,
  renderDataset,
}) => {
  const [showFullDataset, setShowFullDataset] = useState(false);

  return (
    <div className="takeaway-section">
      <div className="takeaway-section__block">
        <h4 className="takeaway-section__heading">{statement_header}</h4>
        <p className="takeaway-section__desc">
          {statement_description} <span>{statement_outcomes}</span>
        </p>
      </div>

      <button
        className="takeaway-section__toggle"
        onClick={() => setShowFullDataset((prev) => !prev)}
      >
        Show Full Dataset
        <i
          className={`fa-solid fa-caret-${showFullDataset ? 'up' : 'down'}`}
        ></i>
      </button>
      {showFullDataset && renderDataset(category_name)}
    </div>
  );
};

export const Dataset = ({ data }) => {
  const sorted = [...data].sort((a, b) => b.win_percentage - a.win_percentage);

  return (
    <div className="table-container">
      <table className="experiment-table">
        <thead>
          <tr>
            <th>Category Name</th>
            <th>Label Name</th>
            <th>Favorable Outcomes</th>
            <th>Total Experiments</th>
            <th>Success Percentage</th>
          </tr>
        </thead>
        <tbody>
          {sorted.map((item, index) => (
            <tr key={index}>
              <td>{item.category_name}</td>
              <td>{item.label_name}</td>
              <td>{item.experiments_with_favorable_outcome}</td>
              <td>{item.experiments_with_comparison}</td>
              <td>{item.win_percentage}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
