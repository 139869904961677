import { useReducer, useEffect, useRef, useState, useCallback } from 'react';
import { abortableFetch } from '../../utils';
import {
  useApiState,
  bindFetchToRef,
} from '../../components/Hooks/useApiState';

const post = (url, payload) => {
  return abortableFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    body: JSON.stringify(payload),
  });
};

export const usePostRequest = (url, startLoading = false) => {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(post(url, payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: startLoading,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);

  const request = useCallback(
    (payload) => {
      postDispatch(payload);
    },
    [url]
  );

  return {
    ...postState,
    request,
  };
};
