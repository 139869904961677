import React, { useContext } from "react";

import { WizardContext } from "./Context";
import { Description, SpendGoal } from "./FormElements";

export function Optional() {
  const {
    handleChange,
    description,
    spend_goal,
    inProgress,
    automation_study_type,
  } = useContext(WizardContext);

  if (automation_study_type === "manual") {
    return (
      <>
        <Description
          onChange={handleChange}
          name="description"
          value={description}
          disabled={inProgress}
        />
      </>
    );
  } else {
    return (
      <>
        <Description
          onChange={handleChange}
          name="description"
          value={description}
          disabled={inProgress}
        />

        <SpendGoal
          onChange={handleChange}
          value={spend_goal}
          name="spend_goal"
        />
      </>
    );
  }
}
