import React from 'react';
import { findQuintileOfValue, findQuintiles } from '../../utils/quintiles';
import { VisualsLookup } from '../CreativeAnalytics/ReportCard/types';
import { CountSelect } from './Helpers';
import { CreativeGroup } from './types';
import {
  toCurrency,
  toCurrency_4,
  toFixed_2,
  toInt,
  toPercent_2,
} from '../../utils/numbers';
import { format } from 'date-fns';

export const getMetadata = (metadataForVisual) => {
  // return object in the format of { [category]: [labels in comma separated string] }
  const { visual_categories = [] } = metadataForVisual ?? {};
  let result = {};

  visual_categories.forEach((x) => {
    const [category_name, labels] = Object.entries(x)[0];
    result[category_name] = labels?.join(', ');
  });

  return result;
};

export const extendWithMetadata = (visuals, metadata) => {
  if (!metadata) return visuals;

  const metadataMap = new Map(metadata.visuals.map((v) => [v.visual_id, v]));

  return visuals.map((visual) => {
    const metadataForVisual = metadataMap.get(visual.id || visual._id);

    if (!metadataForVisual) return visual;

    const metadataCategories = getMetadata(metadataForVisual);

    return {
      ...visual,
      ...metadataCategories,
      manual_tags: metadataForVisual?.manual_tags ?? [],
    };
  });
};

const addQuintileValues = (all_visuals_array, primary_metric) => {
  const invert = ['roas', 'ctr'].includes(primary_metric);

  try {
    const spendValues: [] = [];
    const primaryMetricValues: [] = [];

    all_visuals_array.forEach((visual) => {
      spendValues.push(visual.spend);
      primaryMetricValues.push(visual[primary_metric]);
    });

    const spendQuintiles = findQuintiles(spendValues);
    const primaryMetricQuintiles = findQuintiles(primaryMetricValues);

    return all_visuals_array.map((visual) => {
      const spend_quintile = findQuintileOfValue(
        spendQuintiles,
        visual['spend'],
        true
      );
      const primary_metric_quintile = findQuintileOfValue(
        primaryMetricQuintiles,
        visual[primary_metric],
        invert
      );
      return {
        ...visual,
        spend_quintile,
        primary_metric_quintile,
        fitness_score: spend_quintile + primary_metric_quintile,
      };
    });
  } catch (e) {
    console.error(e);
    return all_visuals_array;
  }
};

const appendCustomScoringData = (visuals, customScoringData) => {
  if (!customScoringData?.length) return visuals;

  return visuals.map((visual) => {
    const match = customScoringData.find((x) => x.Visual === visual.id);

    if (!match) return visual;

    let update = Object.assign({}, visual);

    Object.entries(match).forEach(([key, val]) => {
      if (!update[key]) {
        update[key] = val;
      }
    });

    return update;
  });
};


export const getCustomScoringHeader = (col) => {
  switch (col) {
    case 'line_item_cpa_percent_better_than_adset_cpa':
      return 'Adset Relative CPA Percentage';
    case 'line_item_ctr_percent_better_than_adset_ctr':
      return 'Adset Relative CTR Percentage';
    case 'line_item_cpc_percent_better_than_adset_cpc':
      return 'Adset Relative CPC Percentage';
    case 'adset_share_of_voice':
      return 'Adset SOV';
    case 'CPA Score':
      return 'Average CPA Score';
    case 'CTR Score':
      return 'Average CTR Score';
    case 'CPC Score':
      return 'Average CPC Score';
    default:
      return col;
  }
}

export const getRows = (
  all_visuals_array,
  metadata,
  primary_metric,
  trendData,
  start_date,
  end_date,
  customScoringData=[]
) => {
  let visuals = [];
  visuals = addQuintileValues(all_visuals_array, primary_metric);
  visuals = extendWithMetadata(visuals, metadata);
  visuals = appendCustomScoringData(visuals, customScoringData);

  let topSpend = 0;
  let topPerformance = 0;

  try {
    visuals.forEach((visual) => {
      topSpend = Math.max(topSpend, visual.spend);
      const performance = visual[primary_metric] ?? 0;
      if (primary_metric.slice(0, 2) === 'cp') {
        topPerformance = Math.min(topPerformance, performance);
      } else {
        topPerformance = Math.max(topPerformance, performance);
      }
    });

    return visuals.reduce((acc, visual) => {
      let new_visual = '-';

      if (trendData?.status === 'success') {
        const match = trendData.data[visual._id ?? visual.id];

        if (match) {
          acc.push({
            ...match,
            isTopSpending: match.spend === topSpend,
            isTopPerforming: match[primary_metric]
              ? match[primary_metric] === topPerformance
              : false,
            period_type: 'previous',
            start_date: trendData.previous_start_date,
            end_date: trendData.previous_end_date,
            period_dates: `${format(
              trendData.previous_start_date,
              'MM/dd/yyyy'
            )} - ${format(trendData.previous_end_date, 'MM/dd/yyyy')}`,
            new_visual: 'False',
          });

          new_visual = 'False';
        } else {
          new_visual = 'True';
        }
      }

      acc.push({
        ...visual,
        isTopSpending: visual.spend === topSpend,
        isTopPerforming: visual[primary_metric]
          ? visual[primary_metric] === topPerformance
          : false,
        period_type: 'selected',
        start_date,
        end_date,
        period_dates: `${format(start_date, 'MM/dd/yyyy')} - ${format(
          end_date,
          'MM/dd/yyyy'
        )}`,
        new_visual,
      });

      return acc;
    }, []);
  } catch (e) {
    console.error(e);

    return visuals;
  }
};

export const getFilteredCreatives = (
  visuals,
  groupParams,
  visualsLookup: VisualsLookup,
  metadata,
  custom_performance_scores
) => {
  const {
    selectBy,
    count,
    selectedLabel,
    metric,
    sortBy,
    sortOrder,
  }: CreativeGroup = groupParams;
  const preferLowerValue =
    metric?.slice(0, 2) === 'cp' ||
    (metric === 'custom_performance_score' &&
      custom_performance_scores?.preferLowerScore);
  const sortDesc = (a, b) => b[metric] - a[metric];
  const sortAsc = (a, b) => a[metric] - b[metric];

  const labelIsInMetadata = metadata?.categories?.some((c) =>
    c.category_values.includes(selectedLabel)
  );

  let updatedVisuals = [...visuals];

  let sorted = [];

  switch (selectBy) {
    case 'top':
      sorted = [...visuals].sort((a, b) => {
        if (!a[metric]) {
          return 1;
        } else if (!b[metric]) {
          return -1;
        } else {
          return preferLowerValue ? sortAsc(a, b) : sortDesc(a, b);
        }
      });
      updatedVisuals = sorted.slice(0, Number(count));
      break;

    case 'bottom':
      sorted = [...visuals].sort((a, b) => {
        if (metric === 'cpa') {
          if (!a[metric]) {
            return 1;
          } else if (!b[metric]) {
            return -1;
          }
        } else {
          return preferLowerValue ? sortDesc(a, b) : sortAsc(a, b);
        }
      });
      updatedVisuals = sorted.slice(0, Number(count));
      break;

    case 'element':
      if (!labelIsInMetadata) break;
      updatedVisuals = [...visuals].reduce((result, visual) => {
        const match = visualsLookup[visual._id];
        if (!match) return;

        if (match.labels?.some((label) => label.value === selectedLabel)) {
          result.push(visual);
        }

        return result;
      }, []);
      break;

    case 'custom':
      break;

    default:
      break;
  }

  const rows = updatedVisuals
    .sort((a, b) => {
      if (!a[sortBy]) {
        return 1;
      } else if (!b[sortBy]) {
        return -1;
      } else {
        if (sortOrder === 'ascending') {
          return a[sortBy] - b[sortBy];
        } else if (!sortOrder || sortOrder === 'descending') {
          return b[sortBy] - a[sortBy];
        } else {
          if (sortOrder === 'ascending') {
            return a[sortBy] - b[sortBy];
          } else if (!sortOrder || sortOrder === 'descending') {
            return b[sortBy] - a[sortBy];
          }
        }
      }

      return 0;
    })
    .map((visual, i) => ({
      ...visual,
      rank: i + 1,
    }));

  return {
    rows,
  };
};

export const getExistingManualTags = (
  activeManualTagsModal: string,
  metadata
) => {
  const maybe = metadata?.visuals?.find(
    ({ visual_id }) => visual_id === activeManualTagsModal
  );
  if (!maybe) return [];

  return maybe.manual_tags ?? [];
};

export const makeTitle = ({
  title,
  selectBy,
  count,
  metric,
  selectedLabel,
  selectedVisuals,
  sortBy = '',
  sortOrder = '',
  setGroupParams,
  formattedMetric = '',
  formattedSortMetric = '',
}: CreativeGroup & {
  setGroupParams: React.SetStateAction<any>;
  formattedMetric: string;
  formattedSortMetric: string;
}) => {
  return `Creative Performance sorted by ${formattedSortMetric} ${sortOrder}`;
  if (selectedVisuals?.length && Array.isArray(selectedVisuals)) {
    return `Selected creatives sorted by ${formattedSortMetric} ${sortOrder}`;
  }

  if (!selectBy?.length) {
    if (sortBy) {
      return `All Creatives sorted by ${formattedSortMetric} ${sortOrder}`;
    }
    return `All Creatives sorted by Fitness Score Descending`;
  }

  if (title == 'null') return 'All Creatives';

  switch (selectBy) {
    case 'top':
      return (
        <span>
          Top{' '}
          <CountSelect
            count={count}
            setCount={(update) => setGroupParams({ count: update })}
          />{' '}
          {metric === 'spend'
            ? 'spending '
            : `performing by ${formattedMetric} `}
          sorted by {formattedSortMetric} {sortOrder}
        </span>
      );
    case 'bottom':
      return `Bottom ${count} performing by ${formattedMetric} sorted by ${formattedSortMetric} ${sortOrder}`;
    case 'element':
      return `${selectedLabel} ads sorted by ${formattedMetric} ${sortOrder}`;
    default:
      return `All visuals sorted by ${formattedSortMetric} ${sortOrder}`;
  }
};

export const checkSortCacheWithParams = (groupParams, sortCache): boolean => {
  // check if sort is equal to cached sort. if so, dont update params
  if (!groupParams.sortBy || !sortCache?.current) return false;

  const tableSortOrder = sortCache.current.desc ? 'descending' : 'ascending';

  return (
    groupParams.sortBy === sortCache.current.id &&
    groupParams.sortOrder === tableSortOrder
  );
};

export const checkSortCache = (tableSort, sortCache): boolean => {
  if (!tableSort?.id || !sortCache) return false;

  return (
    tableSort.id === sortCache.current.id &&
    tableSort.desc === sortCache.current.desc
  );
};

export const formatMetricValue = (metric, value, isROAS) => {
  if (metric.startsWith('cp_')) {
    return toCurrency_4(value);
  }

  if (metric.startsWith('cp') || metric === 'spend') {
    return metric === 'cpa' && value === 0 ? 'N/A' : toCurrency(value);
  }

  if (metric === 'roas' || (metric === 'cpa' && isROAS)) {
    return toFixed_2(value);
  }

  if (metric.includes('%') || metric === 'ctr') {
    return toPercent_2(value);
  }

  return toInt(value);
};

export const getVisualsStats = (arr, metric) => {
  return arr.reduce(
    ({ count, spend, metric_sum }, curr) => ({
      count: count + 1,
      spend: spend + curr.spend,
      metric_sum: metric_sum + curr[metric],
    }),
    { count: 0, spend: 0, metric_sum: 0 }
  );
};

export const getCommonElementsNames = (
  commonElementsData,
  filteredData,
  categorySettings
) => {
  // only show elements that are present in PFR data. show name instead of value.
  return commonElementsData?.reduce((arr, el) => {
    const maybe = filteredData.find((label) => label.value === el);
    const enabled: boolean = categorySettings?.find(
      (c) => c.category_name === maybe?.category
    )?.enabled?.report_card;

    if (maybe && enabled) {
      arr.push(maybe.name);
    }

    return arr;
  }, []);
};
