import React, { useEffect, useState } from 'react';
import Card from '../Card/Card';
import { ExplorerState } from '../../../Explorer/types';
import { useExplorerContext } from '../../../Explorer/contexts/explorerContext';
import { SET_VIEW } from '../../../Explorer/hooks/useExplorerState';
import { VisualsLookup } from '../../../ReportCard/types';
import { useCreativeAnalyticsGet } from '../../../contexts';
import { useDraftCreativeContext } from '../../contexts/draftCreativeContext';
import {
  REMOVE_ROW,
  SET_BASE_VALUE,
  SET_BASE_TYPE,
  SET_SECOND_VALUE,
  SET_SECOND_TYPE,
  SET_FINAL_ASSET,
  SET_ADDITIONAL_NOTE,
  SET_ERROR,
  SET_LOADING,
  SET_BASE_VISUAL_TYPE,
  SET_SECOND_VISUAL_TYPE,
  SET_FINAL_TYPE,
  SET_FINAL_VISUAL_TYPE,
  SET_ROW_INDEX,
} from '../../hooks/useDraftCreativeState';
import { useCreativeAnalyticsApis } from '../../../api';
import './Row.scss';
import {
  DESCRIPTION,
  S3_VISUAL,
  S3_IMAGE_URL,
  S3_VIDEO_URL,
} from '../../constants';
import { useWindowSize } from '../../../PerformanceFrequency/hooks';
import _chunk from 'lodash/chunk';
import Tooltip from '../../../../../components/Tooltip';
import Block from './Block';

const DraftCreativeRow = ({
  activeRow,
  setActiveRow,
  rowIndex,
  isSharedReport,
  sharedRows,
  showRightRail,
  setShowRightRail,
}) => {
  const { draftCreativeState, draftCreativeDispatch } =
    useDraftCreativeContext();
  const currentRow = sharedRows
    ? sharedRows[rowIndex]
    : draftCreativeState.rows[rowIndex];
  const {
    loading,
    error,
    base_block_value,
    base_block_type,
    base_block_visual_type,
    second_block_value,
    second_block_type,
    second_block_visual_type,
    final_asset_url,
    final_asset_type,
    final_asset_visual_type,
    additional_note,
    baseValue,
    baseType,
    baseVisualType,
    secondValue,
    secondType,
    secondVisualType,
    finalAsset,
    finalAssetVisualType: finalAssetType,
    additionalNote: note,
  } = currentRow;
  const baseBlockValue = base_block_value || baseValue;
  const baseBlockType = base_block_type || baseType;
  const baseBlockVisualType = base_block_visual_type || baseVisualType;
  const secondBlockValue = second_block_value || secondValue;
  const secondBlockType = second_block_type || secondType;
  const secondBlockVisualType = second_block_visual_type || secondVisualType;
  const finalAssetUrl = final_asset_url || finalAsset;
  const finalAssetVisualType = final_asset_visual_type || finalAssetType;
  const finalBlockType = final_asset_type;
  const additionalNote = additional_note || note;

  const disableDrawer =
    draftCreativeState.rows.some((row) => row.loading) || isSharedReport;
  const { width } = useWindowSize();
  const [showCarousel, setShowCarousel] = useState(false);
  const [baseUrl, setBaseUrl] = useState('');
  const [secondUrl, setSecondUrl] = useState('');
  const [finalUrl, setFinalUrl] = useState('');

  useEffect(() => {
    setBaseUrl(baseBlockValue || '');
    setSecondUrl(secondBlockValue || '');
    setFinalUrl(finalAssetUrl || '');
  }, [currentRow]);

  const handleOpenDrawer = (i) => {
    if (disableDrawer) return;

    drawerDispatch({
      type: 'OPEN',
      currentView: { type: 'category_viewer', id: '' },
      selectedVisualId: '',
    });
    drawerDispatch({
      type: SET_VIEW,
      currentView: {
        type: 'browser',
        browserType: 'visuals',
      },
    });
  };

  const { drawerState, drawerDispatch }: ExplorerState = useExplorerContext();
  const { visualsLookup }: { visualsLookup: VisualsLookup } =
    useCreativeAnalyticsGet();
  const { draftCreativeProjectsApi } = useCreativeAnalyticsApis();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (drawerState.selectedVisualId.length > 0) {
        const found = Object.values(visualsLookup).find(
          (visual) => visual.data.id === drawerState.selectedVisualId
        );

        draftCreativeDispatch({
          type: SET_BASE_VALUE,
          rowIndex: activeRow,
          value: found.data.asset_url,
        });

        draftCreativeDispatch({
          type: SET_BASE_VISUAL_TYPE,
          rowIndex: activeRow,
          value: found.data.visual_type,
        });
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [drawerState.selectedVisualId]);

  useEffect(() => {
    if (draftCreativeProjectsApi.post.error) {
      console.error(draftCreativeProjectsApi.post.error);
      draftCreativeDispatch({
        type: SET_ERROR,
        rowIndex: activeRow,
        error: true,
      });
      draftCreativeDispatch({
        type: SET_LOADING,
        rowIndex: activeRow,
        loading: false,
      });
    }
  }, [draftCreativeProjectsApi.post.error]);

  useEffect(() => {
    if (
      draftCreativeProjectsApi.post.loading &&
      !!base_block_value &&
      activeRow === rowIndex
    ) {
      draftCreativeDispatch({
        type: SET_LOADING,
        rowIndex: activeRow,
        loading: true,
      });
    }
  }, [draftCreativeProjectsApi.post.loading]);

  const handleRemoveRow = () => {
    if (disableDrawer) return;

    draftCreativeDispatch({
      type: REMOVE_ROW,
      rowIndex,
    });
  };

  const baseBlockOptions = [
    {
      label: 'Select a base visual',
      value: S3_VISUAL,
    },
    {
      label: 'Add a data point',
      value: DESCRIPTION,
    },
    {
      label: 'Add an image URL',
      value: S3_IMAGE_URL,
      visual_type: 'image',
    },
    {
      label: 'Add a video URL',
      value: S3_VIDEO_URL,
      visual_type: 'video',
    },
  ];

  const secondBlockOptions = [
    {
      label: 'Add a trending visual',
      value: S3_VISUAL,
    },
    {
      label: 'Add a data point',
      value: DESCRIPTION,
    },
    {
      label: 'Add an image URL',
      value: S3_IMAGE_URL,
      visual_type: 'image',
    },
    {
      label: 'Add a video URL',
      value: S3_VIDEO_URL,
      visual_type: 'video',
    },
  ];

  const finalBlockOptions = [
    {
      label: 'Add an image URL',
      value: S3_IMAGE_URL,
      visual_type: 'image',
    },
    {
      label: 'Add a video URL',
      value: S3_VIDEO_URL,
      visual_type: 'video',
    },
  ];

  const showBaseUrl = [S3_IMAGE_URL, S3_VIDEO_URL].includes(baseBlockType);
  const showSecondUrl = [S3_IMAGE_URL, S3_VIDEO_URL].includes(secondBlockType);
  const showFinalUrl = [S3_IMAGE_URL, S3_VIDEO_URL].includes(finalBlockType);

  const handleRowIndex = (index) => {
    setActiveRow(rowIndex);
    draftCreativeDispatch({
      type: SET_ROW_INDEX,
      currentRowIndex: rowIndex,
    });
  };

  const blockProps = {
    rowIndex,
    isSharedReport,
    draftCreativeDispatch,
    setActiveRow,
    handleRowIndex,
    handleOpenDrawer,
  };

  const cardProps = {
    draftCreativeDispatch,
    rowIndex,
    loading,
    handleOpenDrawer,
    disableDrawer,
    isSharedReport,
  };

  const Render = (
    <div className="row-container">
      <div
        className="draft-creative__row"
        onClick={() => handleRowIndex(rowIndex)}
      >
        <Block
          blockType={baseBlockType}
          blockValue={baseBlockValue}
          blockOrder={1}
          blockOptions={baseBlockOptions}
          typeAction={SET_BASE_TYPE}
          valueAction={SET_BASE_VALUE}
          visualTypeAction={SET_BASE_VISUAL_TYPE}
          showUrl={showBaseUrl}
          blockUrl={baseUrl}
          setBlockUrl={setBaseUrl}
          Card={
            <Card
              value={baseBlockValue}
              type={baseBlockType}
              visualType={baseBlockVisualType}
              onClick={() => {
                if (
                  !isSharedReport &&
                  [S3_VISUAL, S3_IMAGE_URL, S3_VIDEO_URL].includes(
                    baseBlockType
                  )
                ) {
                  handleOpenDrawer(rowIndex);
                }
              }}
              valueAction={SET_BASE_VALUE}
              {...{ cardProps }}
            />
          }
          {...{ blockProps }}
        />
        <div className="plus">
          <i className="fa-solid fa-plus" />
        </div>
        <Block
          blockType={secondBlockType}
          blockValue={secondBlockValue}
          blockOrder={2}
          blockOptions={secondBlockOptions}
          typeAction={SET_SECOND_TYPE}
          valueAction={SET_SECOND_VALUE}
          visualTypeAction={SET_SECOND_VISUAL_TYPE}
          showUrl={showSecondUrl}
          blockUrl={secondUrl}
          setBlockUrl={setSecondUrl}
          Card={
            <Card
              value={secondBlockValue}
              type={secondBlockType}
              visualType={secondBlockVisualType}
              onClick={() => {
                if (!isSharedReport && [S3_VISUAL].includes(secondBlockType)) {
                  setShowRightRail(true);
                }
              }}
              valueAction={SET_SECOND_VALUE}
              {...{ cardProps }}
            />
          }
          {...{ blockProps }}
        />
        <div className="equals">
          <i className="fa-solid fa-equals" />
        </div>
        <Block
          blockType={finalBlockType}
          blockValue={finalAssetUrl}
          blockOrder={3}
          blockOptions={finalBlockOptions}
          typeAction={SET_FINAL_TYPE}
          valueAction={SET_FINAL_ASSET}
          visualTypeAction={SET_FINAL_VISUAL_TYPE}
          showUrl={showFinalUrl}
          blockUrl={finalUrl}
          setBlockUrl={setFinalUrl}
          Card={
            <Card
              value={finalAssetUrl}
              type={finalAssetType}
              visualType={finalAssetVisualType}
              onClick={null}
              valueAction={SET_FINAL_ASSET}
              isFinal={true}
              {...{ cardProps }}
            />
          }
          {...{ blockProps }}
        />
        <div className="equals"></div>
        <div className="draft-creative__block text additional-note">
          <div
            className="draft-creative__card-top"
            style={{ fontWeight: 500, paddingTop: '10px' }}
          >
            Additional note
          </div>
          <Card
            value={additionalNote}
            type={'note'}
            visualType={null}
            onClick={null}
            valueAction={SET_ADDITIONAL_NOTE}
            {...{ cardProps }}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div style={{ opacity: error && !loading ? 1 : 0, userSelect: 'none' }}>
          There was an error. Please try again.
        </div>
        <Tooltip content={'Delete row'}>
          <div
            className={`delete-row ${disableDrawer ? 'disabled' : ''}`}
            onClick={handleRemoveRow}
          >
            <i className="fa-solid fa-trash" />
          </div>
        </Tooltip>
      </div>
    </div>
  );

  return Render;
};

export default DraftCreativeRow;
