import { useCallback, useEffect, useMemo, useRef } from 'react';
import { filterVisuals } from './utils/filterVisuals';
import {
  useCreativeAnalyticsStore,
  useViewSettings,
} from '../../../../selectors';
import { LabelObject, VisualObject } from '../../types';

import { getTestingOpportunities } from './utils/getTestingOpportunities';
import { handleSynonymLabel } from './utils/handleSynonymLabel';
import { filterLabels } from './utils/filterLabels';
import { getPhraseTheme } from './utils/getPhraseTheme';
import { getClusterLabels } from './utils/getClusterLabels';
import { getTopTenSpending } from './utils/getTopTenSpending';
import { batch, useDispatch, useSelector } from 'react-redux';
import { normalizeLabels, normalizeVisuals } from '../../utils/normalize';
import {
  setCategoriesLookup,
  setLabels,
  setVisuals,
} from '../../../../store/CreativeAnalytics/creativeAnalyticsSlice';
import { makeCategoriesLookup } from '../../utils/makeCategoriesLookup';
import { CategoriesLookup } from '../../ReportCard/types';
import { updateVisualsLookup } from './utils/updateVisualsLookup';
import { CUSTOM_SCORING_METRICS } from '../../PerformanceFrequency/constants';

const regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
const comparePerformance = (a, b) =>
  b.performance_percent - a.performance_percent;
const compareDenominator = (a, b) => b.denominator - a.denominator;
export const compareValue = (a, b) => {
  return a.value.replace(regex, '').localeCompare(b.value.replace(regex, ''));
};
const compareCategory = (a, b) =>
  a.category < b.category ? -1 : a.category > b.category ? 1 : 0;

export const sortLabels = (a, b) => {
  return (
    comparePerformance(a, b) ||
    compareCategory(a, b) ||
    compareDenominator(a, b) ||
    compareValue(a, b)
  );
};

const getAllVisualsLabel = (data) => {
  if (data) {
    const maybe = data.find(
      (label) => label.value === 'all_visuals' || label.value === 'all_ads'
    );

    if (maybe) return maybe;
  }

  return {
    winners: [],
    losers: [],
    visuals: [],
  };
};

export const useFilteredData = ({
  data,
  metricsData,
  standardLabels,
  newAds,
  spendThreshold,
  creativeCount = 1,
  categoryFilters,
  visualIdsFromSelectedLabels,
  isDirectMail,
  isROAS,
  min: minResults = 0,
  isOrganicView,
  isCompetitorView,
  primary_metric = isROAS ? 'roas' : 'cpa',
  viewName,
  phraseThemeData,
  clustersData,
  customScoringData,
  reportCardDataType,
  reportCardSettings,
  isNoom,
  isSkyZone,
  setClientMetrics,
}) => {
  const dispatch = useDispatch();

  const {
    additional_metrics,
    customIntegratedMetrics,
    custom_events: customEvents,
    custom_performance_scores,
    visual_success_conditions,
  } = useSelector((state) => state.ViewSettings);
  const { selectedVisuals, visuals } = useCreativeAnalyticsStore();

  const clientMetrics = useMemo(
    () => (data.length ? data[0].client_down_funnel_events ?? [] : []),
    [data]
  );

  const allVisualsLabel = useMemo(() => getAllVisualsLabel(data), [data]);

  const handleFilterVisuals = useCallback(
    (visuals, sumsAndAverages) =>
      filterVisuals({
        visuals,
        spendThreshold,
        minResults,
        visualIdsFromSelectedLabels,
        primary_metric,
        isOrganicView,
        clientMetrics,
        additional_metrics,
        newAds,
        selectedVisuals,
        customIntegratedMetrics,
        customEvents,
        custom_performance_scores,
        visual_success_conditions,
        sumsAndAverages,
        customScoringData,
        isNoom,
        isSkyZone,
      }),
    [
      spendThreshold,
      minResults,
      visualIdsFromSelectedLabels,
      primary_metric,
      isOrganicView,
      clientMetrics,
      additional_metrics,
      newAds,
      selectedVisuals,
      customIntegratedMetrics,
      customEvents,
      custom_performance_scores,
      visual_success_conditions,
      customScoringData,
      isNoom,
      isSkyZone,
    ]
  );

  const {
    filteredAllVisuals,
    visualsData,
    sumsAndAverages,
    filteredVisualIds,
    filteredWinnerIds,
    filteredLoserIds,
    totalImpressions,
  } = useMemo(
    () =>
      handleFilterVisuals(
        !!metricsData?.length
          ? metricsData
          : [
              ...(allVisualsLabel?.winners ?? []),
              ...(allVisualsLabel?.losers ?? []),
            ]
      ),
    [metricsData, handleFilterVisuals]
  );

  const testingOpportunities = useMemo(
    () =>
      getTestingOpportunities(
        data,
        standardLabels,
        handleFilterVisuals,
        categoryFilters,
        filteredVisualIds
      ),
    [
      data,
      standardLabels,
      handleFilterVisuals,
      categoryFilters,
      filteredVisualIds,
    ]
  );

  const topTenSpending = useMemo(
    () => getTopTenSpending(allVisualsLabel, filteredAllVisuals),
    [allVisualsLabel, filteredAllVisuals]
  );

  const phraseThemeLabels = useMemo(
    () =>
      getPhraseTheme(
        allVisualsLabel,
        filteredAllVisuals,
        phraseThemeData?.lastSavedPhraseThemes || []
      ),
    [allVisualsLabel, filteredAllVisuals, phraseThemeData]
  );

  const clusterLabels = useMemo(
    () =>
      getClusterLabels(
        allVisualsLabel,
        filteredAllVisuals,
        filteredWinnerIds,
        filteredLoserIds,
        clustersData || []
      ),
    [
      allVisualsLabel,
      filteredAllVisuals,
      filteredWinnerIds,
      filteredLoserIds,
      clustersData,
    ]
  );

  const dataToUse = useMemo(() => {
    const baseData = [...data, ...phraseThemeLabels, ...clusterLabels];
    return isOrganicView || viewName.includes('Email')
      ? baseData
      : [...baseData, topTenSpending];
  }, [data, phraseThemeLabels, clusterLabels, isOrganicView, viewName]);

  const addSynonymLabel = useCallback(
    (label, curr, addOthersLabel) =>
      handleSynonymLabel(
        label,
        allVisualsLabel,
        filteredVisualIds,
        sumsAndAverages,
        addOthersLabel,
        isROAS,
        curr,
        categoryFilters
      ),
    [
      allVisualsLabel,
      filteredVisualIds,
      sumsAndAverages,
      isROAS,
      categoryFilters,
    ]
  );

  const labels = useMemo(
    () =>
      filterLabels({
        data: [...dataToUse, ...testingOpportunities],
        addSynonymLabel,
        filteredAllVisuals,
        handleFilterVisuals,
        visualsData,
        filteredWinnerIds,
        filteredLoserIds,
        isOrganicView,
        sumsAndAverages,
        isROAS,
        categoryFilters,
        customIntegratedMetrics,
        additional_metrics,
        clientMetrics,
        isDirectMail,
        isCompetitorView,
        creativeCount,
        customEvents,
        totalImpressions,
        customScoringData,
        isNoom,
        isSkyZone,
      }).sort(sortLabels),
    [
      dataToUse,
      testingOpportunities,
      addSynonymLabel,
      filteredAllVisuals,
      handleFilterVisuals,
      visualsData,
      filteredWinnerIds,
      filteredLoserIds,
      isOrganicView,
      sumsAndAverages,
      isROAS,
      categoryFilters,
      customIntegratedMetrics,
      additional_metrics,
      clientMetrics,
      customEvents,
      isDirectMail,
      isCompetitorView,
      creativeCount,
      totalImpressions,
      customScoringData,
      isNoom,
      isSkyZone,
    ]
  );
  const filteredAllVisualsLabel = useMemo(
    () => getAllVisualsLabel(labels),
    [labels]
  );

  const categoriesLookup: CategoriesLookup = useMemo(
    () =>
      makeCategoriesLookup({
        labels,
        isROAS,
        isDirectMail,
        reportCardDataType,
        viewName,
        reportCardMetric: reportCardSettings.selected_metric,
        spendThreshold,
      }),
    [labels, reportCardSettings, reportCardDataType]
  );

  useEffect(() => {
    batch(() => {
      if (visualsData) {
        dispatch(
          setVisuals(updateVisualsLookup(visualsData, categoriesLookup, labels))
        );
      }
      if (labels?.length) {
        dispatch(setLabels(normalizeLabels(labels)));
      }

      dispatch(setCategoriesLookup(categoriesLookup));
    });
  }, [labels, visualsData, categoriesLookup]);

  useEffect(() => {
    if (labels) {
      if (labels.length) {
        let metrics = labels[0].client_down_funnel_events;

        if (customScoringData) {
          metrics = [...metrics, ...CUSTOM_SCORING_METRICS];
        }
        setClientMetrics(metrics);
      }
    }
  }, [labels, customScoringData]);

  return [
    {
      filteredData: labels,
      allVisualsLabel,
      filteredAllVisuals,
      totalBudget: sumsAndAverages.sumSpend,
    },
  ];
};
