export const CREATIVE_ANALYTICS_OPTIONS = [
  {
    label: 'Recommendations',
    value: 'creative-reports?report=ad_labels&insights_view=recommendations',
  },
  {
    label: 'Performance Frequency',
    value:
      'creative-reports?report=ad_labels&insights_view=performance_frequency',
  },
];

export const VISUAL_REPORT_WHITELIST = [
  'act_10154807526407886',
  'act_2210554242517430',
  'act_376081513600891',
];

export const THIMBLE_REPORTS = [
  { label: 'Trigger', value: 'trigger' },
  { label: 'Copy', value: 'copy' },
];
