import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { DARWIN_RED } from '../../../constants';

process.env.NODE_ENV === 'development' && (window.Highcharts = Highcharts);

const Chart = ({
  handleSeriesClick,
  seriesData,
  xAxisCategories,
  data,
  dimensions: { height },
  selectedId,
}) => {
  const state = {
    seriesData,
    xAxisCategories,
    data,
    height,
    onSeriesClick: handleSeriesClick,
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={makeState(state)}
      callback={(chart) => {
        if (selectedId !== '') {
          const maybe = chart.get(selectedId);

          return !!maybe && maybe.select(true, false);
        }
      }}
    />
  );
};

Chart.propTypes = {
  handleSeriesClick: PropTypes.func.isRequired,
  seriesData: PropTypes.arrayOf(
    PropTypes.shape({
      y: PropTypes.number,
      id: PropTypes.string,
    }),
  ),
  xAxisCategories: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.object),
  selectedId: PropTypes.string.isRequired,
  dimensions: PropTypes.shape({
    height: PropTypes.number,
  }).isRequired,
};

export default Chart;

/**
 * @param {{seriesData: [Object], xAxisCategories: [String], onSeriesClick: Function, data: [Object]}} config
 */
function makeState({
  seriesData,
  xAxisCategories,
  onSeriesClick,
  data,
  height,
}) {
  return {
    chart: {
      type: 'bar',
      height,
    },
    title: {
      text: '',
    },

    legend: {
      enabled: false,
    },

    tooltip: {
      // format number in db query or on server?
      formatter() {
        return `
            Value: <b>${this.x}</b>
            <br />
            CPA Difference: $ <b>${this.y}</b>
            <br />
            Spend: $ <b>${data[this.point.index].spend.toFixed(2)}</b>
            <br />
            CPA: $ <b>${data[this.point.index].cpr.toFixed(2)}</b>
          `;
      },
    },
    yAxis: {
      labels: { enabled: false },
    },
    xAxis: {
      gridLineWidth: 0,
      categories: xAxisCategories,
      labels: {
        enabled: false,
      },
    },
    credits: {
      enabled: false,
    },

    plotOptions: {
      series: {
        pointPadding: 0,
        groupPadding: 0,
      },
    },
    series: [
      {
        name: 'CPA Difference',
        data: seriesData,

        // @ts-ignore
        point: {
          events: {
            click(e) {
              return onSeriesClick(this);
            },
          },
        },
        allowPointSelect: true,
        states: {
          select: {
            color: DARWIN_RED,
            borderColor: '#ffffff',
          },
        },

        zones: [
          {
            value: -0.01,
            color: '#666666',
          },
          {
            color: '#6bf886',
          },
        ],
      },
    ],
  };
}
