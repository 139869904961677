import React, { useEffect, useState } from 'react';
import { useOverflowActive } from '../../../../hooks/useOverflowActive';
import Tooltip from '../../../../components/Tooltip';
import { toFixed_2 } from '../../../../utils/numbers';
import CollapsibleItem from '../../../../components/CollapsibleItem/CollapsibleItem';
import { useCreativeAnalyticsGet } from '../../contexts';
import LazyLoader from '../../../../components/LazyLoader';

const CombinationsCard = ({ data, handleHideCategory, handleHideLabel }) => {
  return (
    <div className="combinations__card">
      {data.map((combo) => {
        return <Row {...{ combo, handleHideCategory, handleHideLabel }} />;
      })}
    </div>
  );
};

export default CombinationsCard;

const Row = ({ combo, handleHideCategory, handleHideLabel }) => {
  const [categoryRef1, categoryOverflow1] = useOverflowActive();
  const [labelRef1, labelOverflow1] = useOverflowActive();
  const [categoryRef2, categoryOverflow2] = useOverflowActive();
  const [labelRef2, labelOverflow2] = useOverflowActive();

  const calculatePercentPx = (score) => +score * 20 - 2;

  const spendPercent = calculatePercentPx(combo['Predicted Spend Quintile']);
  const performancePercent = calculatePercentPx(
    combo['Predicted Performance Quintile']
  );
  const fitnessPercent = +combo['Predicted Fitness Score'] * 10 - 2;

  const { filteredAllVisualsLabel } = useCreativeAnalyticsGet();
  const [isHovered, setIsHovered] = useState(false);

  let Preview = null;

  if ([combo['Category1'], combo['Category2'].includes('asset_url')]) {
    const found = filteredAllVisualsLabel.visuals.find((v) =>
      [combo.Label1, combo.Label2].includes(v.asset_url)
    );

    if (found) {
      const { visual_type, asset_url, thumbnail_url } = found;

      let videoDisplay = (
        <div className={`video-display ${isHovered ? 'hovered' : ''}`}>
          <div className="video-icon">
            <i className="fa fa-play" />
          </div>
          <img src={thumbnail_url} />
          {isHovered && asset_url && (
            <video
              className=""
              key={asset_url}
              autoPlay
              loop
              muted
              plays-inline
            >
              <source src={asset_url} type="video/mp4" />
            </video>
          )}
          {!asset_url && !thumbnail_url && (
            <p className="missing-asset-url">
              <i className="fa-solid fa-triangle-exclamation"></i>
              Missing asset URL
            </p>
          )}
        </div>
      );

      Preview = (
        <LazyLoader placeholder={<div style={{ height: '100px' }} />}>
          <a href={asset_url} target="_blank">
            <div className="visual-preview">
              <div
                className="overlay"
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'transparent',
                }}
                onMouseOver={() => setIsHovered(true)}
                onMouseOut={() => setIsHovered(false)}
              ></div>
              {visual_type === 'image' ? (
                <img src={asset_url} alt="" />
              ) : (
                videoDisplay
              )}
            </div>
          </a>
        </LazyLoader>
      );
    }
  }

  const formatCategory = (category) =>
    category === 'asset_url' ? 'Asset URL' : category;

  return (
    <div className="combinations__row">
      <div className="combinations__row-container">
        <div className="box">
          <Tooltip disabled={!categoryOverflow1} content={combo.Category1}>
            <div className="box__category">
              <Tooltip content="Hide this category">
                <i
                  className="fa-solid fa-ban"
                  onClick={() => handleHideCategory(combo.Category1)}
                />
              </Tooltip>
              <div style={{ width: '100%' }}>
                <div ref={categoryRef1}>{formatCategory(combo.Category1)}</div>
              </div>
            </div>
          </Tooltip>
          <Tooltip disabled={!labelOverflow1} content={combo.Label1}>
            <div className="box__label">
              <Tooltip content="Hide this label">
                <i
                  className="fa-solid fa-ban"
                  onClick={() => handleHideLabel(combo.Label1)}
                />
              </Tooltip>
              {combo.Category1 !== 'asset_url' && (
                <div style={{ width: '100%' }}>
                  <div ref={labelRef1}>{combo.Label1}</div>
                </div>
              )}
              {combo.Category1 === 'asset_url' && Preview}
            </div>
          </Tooltip>
        </div>
        <div className="plus">
          <i className="fa-solid fa-plus" />
        </div>
        <div className="box">
          <Tooltip disabled={!categoryOverflow2} content={combo.Category2}>
            <div className="box__category">
              <Tooltip content="Hide this category">
                <i
                  className="fa-solid fa-ban"
                  onClick={() => handleHideCategory(combo.Category2)}
                />
              </Tooltip>
              <div style={{ width: '100%' }}>
                <div ref={categoryRef2}>{formatCategory(combo.Category2)}</div>
              </div>
            </div>
          </Tooltip>
          <Tooltip disabled={!labelOverflow2} content={combo.Label2}>
            <div className="box__label">
              <Tooltip content="Hide this label">
                <i
                  className="fa-solid fa-ban"
                  onClick={() => handleHideLabel(combo.Label2)}
                />
              </Tooltip>
              {combo.Category2 !== 'asset_url' && (
                <div style={{ width: '100%' }}>
                  <div ref={labelRef2}>{combo.Label2}</div>
                </div>
              )}
            </div>
          </Tooltip>
          {combo.Category2 === 'asset_url' && Preview}
        </div>
      </div>
      <div className="scores__summary">
        Darwin Predicts this will score a
        {`${
          combo['Predicted Fitness Score'].toString().startsWith('8') ? 'n' : ''
        } `}
        <span style={{ fontWeight: 600 }}>
          {`${toFixed_2(combo['Predicted Fitness Score'])}`} fitness score.
        </span>{' '}
        It is predicted to perform{' '}
        {`${
          combo['Predicted Performance Quintile'] < 2
            ? 'below'
            : combo['Predicted Performance Quintile'] >= 3
            ? 'above'
            : ''
        } average`}{' '}
        (Quintile {`${toFixed_2(combo['Predicted Performance Quintile'])}`}) and
        spend{' '}
        {`${
          combo['Predicted Spend Quintile'] < 2
            ? 'below'
            : combo['Predicted Spend Quintile'] >= 3
            ? 'above'
            : ''
        } average`}{' '}
        (Quintile {`${toFixed_2(combo['Predicted Spend Quintile'])}`}).
      </div>
      <CollapsibleItem
        AnchorChild={
          <div className="scores__show-details">
            Show details
            <i className="fa fa-chevron-right ml-2" />
          </div>
        }
        ExpandableChild={
          <div className="scores">
            <div>Predicted scores</div>
            <div className="scores__container">
              <div>
                <span>
                  Spend:{' '}
                  <span className="score">
                    {toFixed_2(combo['Predicted Spend Quintile'])}
                    <span>/5</span>
                  </span>
                  <Tooltip
                    content={`How much we predict this combination will spend based on the dataset provided. A score of 1 would be the "Lowest Predicted Spend" and a score of 5 would be the "Highest Predicted Spend"`}
                  >
                    <i className="ml-2 fa fa-info-circle" />
                  </Tooltip>
                </span>
                <div className="score-meter">
                  <div
                    className="score-meter-marker"
                    style={{
                      left: `${spendPercent}%`,
                    }}
                  />
                </div>
              </div>
              <div className="scores__container">
                <span>
                  Performance:{' '}
                  <span className="score">
                    {toFixed_2(combo['Predicted Performance Quintile'])}
                    <span>/5</span>
                  </span>
                  <Tooltip
                    content={`How well we think this combination would perform based on the primary metric you have selected. A score of 1 would be "Bad Predicted Performance" and a score of 5 would be Good Predicted Performance"`}
                  >
                    <i className="ml-2 fa fa-info-circle" />
                  </Tooltip>
                </span>
                <div className="score-meter">
                  <div
                    className="score-meter-marker"
                    style={{
                      left: `${performancePercent}%`,
                    }}
                  />
                </div>
              </div>
              <div className="scores__container">
                <span>
                  Fitness:{' '}
                  <span className="score">
                    {toFixed_2(combo['Predicted Fitness Score'])}
                    <span>/10</span>
                  </span>
                  <Tooltip
                    content={` Whether we think the combination of labels will both spend and perform. It is the sum of the "Predicted Spend" and "Predicted Performance" scores. A score of 2 would mean very low likelihood of spending or performing, and a score of 10 would mean very high likelihood of both spending and performing.`}
                  >
                    <i className="ml-2 fa fa-info-circle" />
                  </Tooltip>
                </span>
                <div className="score-meter">
                  <div
                    className="score-meter-marker"
                    style={{
                      left: `${fitnessPercent}%`,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};
