import React, { useEffect, useRef, useState } from 'react';
import { useCreativeAnalyticsApis } from '../api';
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from '../contexts';
import {
  ADD_ROW,
  SET_PROJECT_NAME,
  SET_SELECTED_PROJECT,
  useDraftCreativeState,
} from './hooks/useDraftCreativeState';
import DraftCreativeContextProvider from './contexts/draftCreativeContext';
import DraftCreativeRow from './components/Row/Row';
import { useShareDraftCreative } from './share';
import { Share } from '../CopyReport/Wrapper';
import { EMPTY_ROW, S3_IMAGE_URL } from './constants';
import Button from '../../../components/Button';
import CreateModal from './components/CreateModal';
import DraftCreativeHeader from './Header';
import { useSavedConceptsApi } from '../TryThese/api';
import { useViewId } from '../selectors';
import { sortTryThese } from '../TryThese/utils';
import { current } from '@reduxjs/toolkit';

const DraftCreativeProjects = () => {
  const { draftCreativeProjectsApi } = useCreativeAnalyticsApis();
  const {
    account_id,
    savedConcepts,
    trendingRecs,
    inspirationRecs,
    selectedDraftProject: selectedProject,
    draftCreativeState,
  } = useCreativeAnalyticsGet();
  const { setSavedConcepts, setSelectedDraftProject: setSelectedProject } =
    useCreativeAnalyticsSet();
  const {
    data: getDraftProjectsData,
    error: getDraftProjectsError,
    loading: getDraftProjectsLoading,
    request: getDraftProjectsRequest,
  } = draftCreativeProjectsApi.get;
  const {
    data: deleteDraftProjectData,
    error: deleteDraftProjectError,
    loading: deleteDraftProjectLoading,
    request: deleteDraftProjectRequest,
  } = draftCreativeProjectsApi.delete;
  const {
    data: saveDraftProjectData,
    error: saveDraftProjectError,
    loading: saveDraftProjectLoading,
    request: saveDraftProjectRequest,
  } = draftCreativeProjectsApi.post;

  const [draftProjects, setDraftProjects] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [activeRow, setActiveRow] = useState(0);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newProjectName, setNewProjectName] = useState('');
  const disableCreate = draftProjects.some(
    (p) => p.creative_project_name === newProjectName
  );

  const {
    draftCreativeState: { projectName, projectId, status, rows },
    draftCreativeDispatch,
  } = draftCreativeState;
  const savedConceptsApi = useSavedConceptsApi();
  const view_id = useViewId();
  const [editProjectName, setEditProjectName] = useState(false);
  const [editedName, setEditedName] = useState(projectName);
  const [focusName, setFocusName] = useState(false);
  const ref = useRef();

  useEffect(() => {
    setEditedName(projectName);
  }, [projectName]);

  useEffect(() => {
    savedConceptsApi.get.request(view_id);
  }, []);

  useEffect(() => {
    if (
      savedConceptsApi.get.data &&
      inspirationRecs.length &&
      trendingRecs.length
    ) {
      const { data } = savedConceptsApi.get;
      setSavedConcepts(
        [...trendingRecs, ...inspirationRecs]
          .filter((x) =>
            data.saved_concepts.some(
              (f) => f.value === x.label && f.category === x.category
            )
          )
          .sort(sortTryThese)
      );
    }
  }, [savedConceptsApi.get.data]);

  const fetchProjects = () => {
    getDraftProjectsRequest(account_id);
  };

  useEffect(() => {
    if (!getDraftProjectsData) fetchProjects();
  }, []);

  useEffect(() => {
    if (getDraftProjectsData) {
      setDraftProjects(getDraftProjectsData);

      if (newProjectName) {
        const newProject = getDraftProjectsData.find(
          (p) => p.creative_project_name === newProjectName
        );

        if (newProject) {
          setSelectedProject(newProject);
          dispatchSelectedProject(newProject);
        }

        setNewProjectName('');
      }
    }
  }, [getDraftProjectsData]);

  useEffect(() => {
    const opts = draftProjects.map((p) => ({
      label: p.creative_project_name,
      value: p.draft_creative_project_id,
    }));

    setProjectOptions(opts);
  }, [draftProjects]);

  const [message, setMessage] = useState('');

  useEffect(() => {
    const messageTimer = setTimeout(() => {
      setMessage('');
    }, 3000);
    return () => clearTimeout(messageTimer);
  }, [message]);

  useEffect(() => {
    if (saveDraftProjectData) {
      fetchProjects();
      setMessage('Project saved successfully.');

      if (showCreateModal) setShowCreateModal(false);
    }
  }, [saveDraftProjectData]);

  useEffect(() => {
    if (saveDraftProjectError || deleteDraftProjectError) {
      setMessage('There was an error.');
    }
  }, [saveDraftProjectError, deleteDraftProjectError]);

  useEffect(() => {
    if (deleteDraftProjectData) {
      fetchProjects();
      setSelectedProject(null);
      setMessage('Project deleted successfully.');
    }
  }, [deleteDraftProjectData]);

  const dispatchSelectedProject = (project) => {
    draftCreativeDispatch({
      type: SET_SELECTED_PROJECT,
      projectName: project.creative_project_name,
      projectId: project.draft_creative_project_id,
      status: project.status,
      rows: project.draft_creatives.map(
        ({
          base_block_value,
          base_block_type,
          base_block_visual_type,
          second_block_value,
          second_block_type,
          second_block_visual_type,
          final_asset_url,
          final_asset_type,
          final_asset_visual_type,
          additional_note,
        }) => {
          return {
            base_block_value,
            base_block_type,
            base_block_visual_type,
            second_block_value,
            second_block_type,
            second_block_visual_type,
            final_asset_url,
            final_asset_type: final_asset_type || S3_IMAGE_URL,
            final_asset_visual_type: final_asset_visual_type || 'image',
            additional_note,
          };
        }
      ),
    });
  };

  const handleSelectProject = (id) => {
    const found = draftProjects.find((p) => p.draft_creative_project_id === id);
    if (found) {
      setSelectedProject(found);
      dispatchSelectedProject(found);
    }
  };

  const handleCreateProject = (projectName) => {
    saveDraftProjectRequest({
      account_id,
      creative_project_name: projectName,
      status: 'active',
      draft_creatives: [EMPTY_ROW],
    });
  };

  const handleSaveProject = () => {
    const payload = {
      account_id,
      creative_project_name: projectName,
      draft_creative_project_id: projectId,
      status,
      draft_creatives: rows,
    };

    saveDraftProjectRequest(payload);
  };

  const handleDeleteProject = () => {
    const id = projectId || selectedProject.draft_creative_project_id;
    deleteDraftProjectRequest(id);
  };

  const {
    shouldShowShareModal,
    openShareModal,
    closeShareModal,
    submitShareReport,
    sharedReportId,
    loadingShareReport,
  } = useShareDraftCreative();

  const handleEditName = () => {
    draftCreativeDispatch({
      type: SET_PROJECT_NAME,
      value: editedName,
    });
  };

  useEffect(() => {
    if (editProjectName && !focusName) {
      if (ref.current) {
        ref.current.focus();
      }
    }
  }, [editProjectName]);

  return (
    <DraftCreativeContextProvider
      {...{ draftCreativeState, draftCreativeDispatch }}
    >
      <div className="draft-creative">
        <DraftCreativeHeader
          {...{
            projectOptions,
            selectedProject,
            handleSelectProject,
            setShowCreateModal,
            handleDeleteProject,
            deleteDraftProjectLoading,
            handleSaveProject,
            saveDraftProjectLoading,
            openShareModal,
            getDraftProjectsLoading,
            getDraftProjectsError,
            saveDraftProjectData,
            saveDraftProjectError,
            deleteDraftProjectData,
            deleteDraftProjectError,
            message,
          }}
        />
        {selectedProject && (
          <>
            <div className="draft-creative__name d-flex">
              {!editProjectName && <h3>{projectName}</h3>}
              {editProjectName && (
                <input
                  ref={ref}
                  type="text"
                  onChange={(e) => setEditedName(e.target.value)}
                  onBlur={() => {
                    handleEditName();
                    setEditProjectName(false);
                    setFocusName(false);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleEditName();
                      setEditProjectName(false);
                    }
                  }}
                  value={editedName}
                  onFocus={() => setFocusName(true)}
                />
              )}
              <div
                className={`edit-icon ${editProjectName ? 'editing' : ''}`}
                onClick={() => {
                  setEditProjectName(true);
                }}
              >
                <i className="fa-solid fa-pencil" />
              </div>
            </div>
            <div className="draft-creative__rows">
              {rows?.length
                ? rows?.map((row, index) => {
                    return (
                      <DraftCreativeRow
                        rowIndex={index}
                        {...{
                          activeRow,
                          setActiveRow,
                          savedConcepts,
                          trendingRecs,
                          inspirationRecs,
                        }}
                      />
                    );
                  })
                : null}
            </div>
            <Button
              onClick={() => {
                draftCreativeDispatch({
                  type: ADD_ROW,
                });
                setActiveRow(rows.length > 0 ? rows.length - 1 : 0);
              }}
              disabled={rows.some((row) => row.loading)}
            >
              + Add draft creative
            </Button>
          </>
        )}
        {!!shouldShowShareModal && (
          <Share
            close={closeShareModal}
            submit={(report_name, report_description) =>
              submitShareReport({
                report_name,
                report_description,
                rows,
              })
            }
            sharedReportId={sharedReportId}
            loading={loadingShareReport}
          />
        )}
      </div>
      {showCreateModal && (
        <CreateModal
          {...{
            disableCreate,
            setShowCreateModal,
            newProjectName,
            setNewProjectName,
            saveDraftProjectLoading,
            saveDraftProjectError,
            handleCreateProject,
          }}
        />
      )}
    </DraftCreativeContextProvider>
  );
};

export default DraftCreativeProjects;
