import { useNotifications } from "../../../components/Notification/useNotifications";

export const labelsAreMatching = (label, comparison) => {
  return label.trim().toLowerCase() === comparison.trim().toLowerCase();
};

export const sortSearchTerms = (a, b) => {
  return a.localeCompare(b, undefined, { ignorePunctuation: true }) || 0;
};

export const sortTerms = (list, alpha) => {
  if (alpha) return list.sort(sortSearchTerms);
  return list;
};

export const getInactiveLabels = (arr, comparison) =>
  arr.filter((label) => !comparison.includes(label.toLowerCase()));

export const formatLabel = (label) => label.toLowerCase().replace(/\s|"/g, '');

export const notifySaving = () => {
  const notifications = useNotifications();

  notifications.add({
    id: 'basic-labels-saving',
    title: "Saving labels",
    message: "Please wait",
    showEllipses: true,
  });
};

export const notifyError = (error) => {
  const notifications = useNotifications();
  notifications.remove('basic-labels-saving');

  notifications.add({
    title: "There was an error.",
    message: error,
    toastProps: {
      className: "error-toast",
    },
  });
};

export const notifySuccess = () => {
  const notifications = useNotifications();
  notifications.remove('basic-labels-saving');

  notifications.add({
    title: "Basic Labels Saved!",
    message:
      "Please check back in 5 minutes to see performance data for your basic labels.",
    showEllipses: false,
    toastProps: {
      autoClose: 10000,
      hideProgressBar: false,
    }
  });
};
