import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const SELECT_STYLES = {
  container: (base, state) => ({ ...base, width: "80%" }),
  menu: (base, state) => ({ ...base })
};

const AdsetPicker = ({ selected, options, handleSelect }) => (
  <div className="d-flex flex-row justify-content-between align-items-baseline">
    <label>Adset</label>
    <Select
      isClearable
      isSearchable
      styles={SELECT_STYLES}
      value={selected}
      options={options}
      onChange={handleSelect}
    />
  </div>
);

AdsetPicker.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  )
};

export default AdsetPicker;
