import React from "react";
import { DARWIN_NAVY } from "../../../../constants";

export const LibraryIcon = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8821 13.9L12.5121 4.99496C12.4655 4.87216 12.3951 4.75975 12.3051 4.66414C12.215 4.56854 12.107 4.49161 11.9872 4.43775C11.8674 4.38389 11.7382 4.35415 11.6069 4.35024C11.4756 4.34633 11.3449 4.36832 11.2221 4.41496L9.64209 5.00996V3.54496C9.64209 3.29035 9.54095 3.04617 9.36091 2.86614C9.18088 2.6861 8.9367 2.58496 8.68209 2.58496H6.14209V1.54496C6.14209 1.29035 6.04095 1.04617 5.86091 0.866138C5.68088 0.686104 5.4367 0.584961 5.18209 0.584961H1.64209C1.37687 0.584961 1.12252 0.690318 0.934983 0.877854C0.747447 1.06539 0.64209 1.31974 0.64209 1.58496V15.085C0.64209 15.2176 0.694768 15.3447 0.788536 15.4385C0.882305 15.5323 1.00948 15.585 1.14209 15.585H9.14209C9.2747 15.585 9.40188 15.5323 9.49564 15.4385C9.58941 15.3447 9.64209 15.2176 9.64209 15.085V8.71996L12.1421 15.325C12.1654 15.3864 12.2006 15.4426 12.2456 15.4904C12.2906 15.5382 12.3446 15.5766 12.4045 15.6036C12.4644 15.6305 12.529 15.6454 12.5947 15.6473C12.6603 15.6493 12.7257 15.6383 12.7871 15.615L15.5921 14.545C15.6535 14.5217 15.7097 14.4865 15.7575 14.4415C15.8053 14.3964 15.8438 14.3424 15.8707 14.2825C15.8976 14.2226 15.9125 14.158 15.9144 14.0924C15.9164 14.0267 15.9054 13.9614 15.8821 13.9ZM5.14209 3.49996V14.585H1.64209V1.58496H5.14209V3.49996ZM8.64209 14.585H6.14209V3.58496H8.64209V14.585ZM12.8971 14.46L9.70709 6.04496L11.5771 5.33496L14.7721 13.75L12.8971 14.46Z"
        fill={DARWIN_NAVY}
      />
    </svg>
  );
};
