import React from 'react';
import PropTypes from 'prop-types';

import { WhenLoading } from '../Helpers/Loading';
import { WhenError } from '../Helpers/Error';

const RequestResetBoundary = ({ hasError, isLoading, children }) => (
  <WhenError hasError={hasError}>
    <WhenLoading isLoading={isLoading}>{children}</WhenLoading>
  </WhenError>
);

RequestResetBoundary.propTypes = {
  isLoading: PropTypes.bool,
  hasError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default RequestResetBoundary;
