import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';

import { CustomDatePicker } from '../../../../components/DateSpanDropdown/CustomPicker';

import { formatDateRange, equalDateRanges } from '../dates';
import { ClickAwayListener } from '@mui/material';

const OptionType = PropTypes.shape({
  value: PropTypes.any,
  label: PropTypes.string,
});

export function Picker({
  id, value, onChange, options, disabled, style,
}) {
  const [show, setShow] = useState(false);

  const toggleMenu = () => {
    setShow((prev) => !prev);
  }

  const handleClickAway = () => {
    setShow(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="dropdown">
        <button
          style={style}
          className="btn btn-link dropdown-toggle"
          type="button"
          id={id}
          // data-bs-toggle="collapse"
          // data-bs-target=".dropdown-menu"
          aria-haspopup="true"
          aria-expanded="false"
          disabled={disabled}
          onClick={toggleMenu}
        >
          {value}
        </button>
        {show ?
          <div className="dw-dropdown-menu history-picker-row__dropdown" aria-labelledby={id}>
            {options.map((m) => {
              const className = m.active
                ? 'dropdown-item active'
                : 'dropdown-item';

              return (
                <button
                  type="button"
                  key={m.value}
                  className={className}
                  onClick={() => onChange(m)}
                  disabled={m.disabled}
                >
                  {m.label}
                </button>
              );
            })}
          </div>
          : null}
      </div>
    </ClickAwayListener>
  );
}

Picker.defaultProps = {
  disabled: false,
  style: { color: 'inherit', fontSize: 'inherit' },
};

Picker.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(OptionType).isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.shape({
    color: PropTypes.string,
  }),
};

const STYLE = {
  fontWeight: 'inherit',
  fontSize: 'inherit',
  color: 'inherit',
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    type="button"
    className="btn btn-link dropdown-toggle"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    style={STYLE}
  >
    {children}
  </button>
));

export function DatePicker({
  value, onChange, options,
}) {
  const { value: [startDate, endDate] } = value;

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle}>
        {formatDateRange([startDate, endDate])}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {options.map((opt) => {
          const className = equalDateRanges([startDate, endDate], opt.value)
            ? 'dropdown-item active'
            : 'dropdown-item';

          return (
            <button
              key={opt.label}
              type="button"
              className={className}
              onClick={() => onChange(opt)}
            >
              {opt.label}
            </button>
          );
        })}
        <Dropdown.Divider />
        <Dropdown.Item eventKey="custom-picker">
          <CustomDatePicker
            startDate={startDate}
            endDate={endDate}
            handleChange={(dates) => onChange({ label: formatDateRange(dates), value: dates })}
            btnStyle={{ color: 'inherit', fontSize: 'inherit' }}
          />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: OptionType.isRequired,
  options: PropTypes.arrayOf(OptionType).isRequired,
};
