import React, { useContext, useState } from 'react';

import { useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

import {
  useApiState,
  bindFetchToRef,
} from '../../../../components/Hooks/useApiState';
import { abortableFetch } from '../../../../utils';
import { toPercentRounded } from '../../../../utils/numbers';

import './styles.scss';
import { useCreativeRequestSet } from '../../CreativeRequest/contexts';
import Button from '../../../../components/Button';
import ReactDOMServer from 'react-dom/server';
import { useNotifications } from '../../../../components/Notification/useNotifications';

const HEADERS = {
  'content-type': 'application/json',
  'x-api-key': 'darwin2025',
};

const fetchVisuals = (payload) => {
  return abortableFetch(
    ['api', 'creative-analytics', 'fetch-similar-visuals'].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
};

const useSuggestSimilarVisualsApi = () => {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(fetchVisuals(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  const handlePost = (payload) => {
    postDispatch(payload);
  };

  return {
    ...postState,
    request: handlePost,
  };
};

const VariationsContext = React.createContext(null);
export const useVariationsContext = () => {
  return useContext(VariationsContext);
};

export const VariationsProvider = ({ current, children }) => {
  const { data, loading, error, request } = useSuggestSimilarVisualsApi();
  const { addToOverviewCustom } = useCreativeRequestSet();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const notifications = useNotifications();

  const prevUrl = useRef(null);

  useEffect(() => {
    handleRequest();
  }, [current]);

  const handleRequest = () => {
    if (current?.asset_url && prevUrl.current !== current?.asset_url) {
      request({ url: current.asset_url });
      prevUrl.current = current.asset_url;
    }
  };

  const visuals = data?.filter(
    (visual) => visual.file_type === 'image' || visual.file_type === 'video'
  );
  const selectedVisual = visuals?.[selectedIndex];

  const prev = () => {
    setSelectedIndex((prev) => {
      if (prev === 0) return visuals.length - 1;
      return prev - 1;
    });
  };

  const next = () => {
    setSelectedIndex((prev) => {
      if (prev === visuals.length - 1) return 0;
      return prev + 1;
    });
  };

  const addToCreativeRequest = () => {
    if (!current?.asset_url || !selectedVisual?.id)
      console.error('Error adding to creative request overview');

    const staticMarkup = ReactDOMServer.renderToStaticMarkup(
      <div>
        <p>Darwin AI has suggested a variation on the below base visual:</p>
        <a href={current?.asset_url}>{current?.asset_url}</a>
        <p>The variation suggested is here with a similarity score of 46%:</p>
        <a href={selectedVisual.id}>{selectedVisual.id}</a>
      </div>
    );

    addToOverviewCustom(staticMarkup);
    notifications.add({
      title: 'Success!',
      message: 'Suggestion added to Request Overview.',
    });
  };

  return (
    <VariationsContext.Provider
      value={{
        visuals,
        loading,
        error,
        request,
        selectedIndex,
        prev,
        next,
        addToCreativeRequest,
        handleRequest,
      }}
    >
      {children}
    </VariationsContext.Provider>
  );
};

const Toggle = ({ isVisible, onToggle }) => {
  return (
    <button className="variations-toggle" onClick={onToggle}>
      Suggest Similar Visuals (BETA)
      <i className={`fa-solid fa-angle-${isVisible ? 'up' : 'down'}`} />
    </button>
  );
};

export const SuggestSimilarVisuals = () => {
  const [show, setShow] = useState(false);

  const {
    visuals,
    loading,
    selectedIndex,
    prev,
    next,
    error,
    request,
    addToCreativeRequest,
    handleRequest,
  } = useVariationsContext();

  useEffect(() => {
    handleRequest();
  }, []);

  let Display = null;

  if (loading) {
    Display = <p>Loading similar visuals...</p>;
  } else {
    Display = (
      <>
        <div className="explanation">
          A variation creative is when you take the base asset (the asset on the
          left) and try a different execution style or visual presentation. We
          have provided a library of similar styles for you to choose from
          below.
        </div>
        <Carousel
          {...{ visuals, selectedIndex, prev, next, addToCreativeRequest }}
        />
      </>
    );
  }

  return (
    <>
      {/* <Toggle isVisible={show} onToggle={() => setShow((prev) => !prev)} /> */}
      {Display}
    </>
  );
};

const Carousel = ({
  visuals = [],
  selectedIndex = 0,
  prev,
  next,
  addToCreativeRequest,
}) => {
  const selectedVisual = visuals?.[selectedIndex];

  if (!selectedVisual) return <p>There was an error</p>;

  let asset = null;

  if (selectedVisual.file_type === 'image') {
    asset = <img src={selectedVisual.id} />;
  } else if (selectedVisual.file_type === 'video') {
    asset = (
      <video key={selectedVisual.id} autoPlay loop muted controls plays-inline>
        <source src={selectedVisual.id} type="video/mp4" />
      </video>
    );
  }

  return (
    <div className="variations">
      <p className="variations__heading">Suggested Variation</p>

      <div className="variations-nav">
        <button onClick={prev}>
          <i className="fa-solid fa-caret-left"></i>
        </button>
        <span>{`${selectedIndex + 1} of ${visuals.length}`}</span>
        <button onClick={next}>
          <i className="fa-solid fa-caret-right"></i>
        </button>
      </div>

      <p className="variations-similarity">
        Similarity score: {toPercentRounded(selectedVisual.distance)}
      </p>

      <div className="variations-asset">{asset}</div>
      <Button className="mt-3" onClick={addToCreativeRequest}>
        Add to Creative Request
      </Button>
    </div>
  );
};
