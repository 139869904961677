/* eslint-disable import/prefer-default-export */

/**
 * @param {number} vh
 *
 * @return {number}
 */
export function vhToPx(vh) {
  return (window.innerHeight * vh) / 100;
}
