import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useInputState } from './hooks';

import { LabelObject } from '../../../types';
import { useCustomCategories } from '../../hooks';
import { MULTIPLE_LABELS, MUTUALLY_EXCLUSIVE } from '../../constants';

import {
  useDarwinClientId,
  useDates,
  usePlatform,
  useViewId,
} from '../../selectors';
import { useCustomCategoriesApi } from '../../api';
import { makeFetchPayload } from '../../payloads';
import { useAnalyticsParams, useDatePeriods } from '../../../hooks';

import Setup from './Setup';
import Editor from './Editor';
import {
  backfillAnnotatedVisuals,
  fillMissingFrames,
  makeVisualsObject,
  reformatAnnotatedVisuals,
} from './utils';
import { useCreativeAnalyticsGet } from '../../../contexts';

const getExistingAnnotatedVisuals = (existing) => {
  if (!existing) return null;

  if (existing.mutually_exclusive) {
    return existing.annotated_visuals;
  }

  return reformatAnnotatedVisuals(existing.annotated_visuals);
};

const AIDriven = ({
  stage,
  prev,
  next,
  dropdownOptions,
  category: { category: category_name, category_id },
  fetchPerformanceData,
}) => {
  const [input, handleInput, removeLabel, labelsHaveChanged] = useInputState();
  const [existing, setExisting] = useState(null);
  const [existingNames, setExistingNames] = useState({
    categories: [],
    labels: [],
  });
  const [annotatedVisuals, setAnnotatedVisuals] = useState(
    existing ? getExistingAnnotatedVisuals(existing) : {}
  );
  const [predictedVisuals, setPredictedVisuals] = useState(
    existing ? existing.predicted_visuals : {}
  );
  const [visuals, setVisuals] = useState(null);
  const ref = useRef();
  const api = useCustomCategoriesApi();
  const darwin_client_id = useDarwinClientId();
  const view_id = useViewId();
  const platform = usePlatform();
  const isNew = category_name === 'custom';
  const [initialSelected, setInitialSelected] = useState({}); // existing predicted + annotated visuals
  const [visualModeChanged, setVisualModeChanged] = useState(false);
  const [{ period1, period2 }] = useAnalyticsParams();
  const [period1Start, period1End, period2Start, period2End] = useDatePeriods(
    period1,
    period2
  );
  const { allVisualsList } = useCreativeAnalyticsGet();

  const visualsData = makeVisualsObject(allVisualsList);
  const fetch = (category_name, category_id) => {
    api.get.request(
      makeFetchPayload({
        category_name,
        category_id,
        platform,
        darwin_client_id,
        view_id,
        period1Start,
        period1End,
      })
    );
  };

  useEffect(() => {
    if (isNew) {
      fetch('');
    } else {
      fetch(category_name, category_id);
    }
  }, [category_name]);

  useEffect(() => {
    if (existing) {
      const { annotated_visuals, predicted_visuals } = fillMissingFrames(existing, visualsData);

      if (input.type === MULTIPLE_LABELS) {
        setAnnotatedVisuals(reformatAnnotatedVisuals(annotated_visuals));
      } else {
        setAnnotatedVisuals(annotated_visuals);
      }

      setPredictedVisuals(predicted_visuals);
    }
  }, [existing]);

  useEffect(() => {
    if (api.get.data) {
      const { data } = api.get;

      const selected = {
        ...data.annotated_visuals,
        ...data.predicted_visuals,
      };

      const lower = (arr) => arr.map((name) => name?.toLowerCase());

      setInitialSelected(selected);
      setExistingNames({
        categories: data.existing_category_names
          ? lower(data.existing_category_names)
          : [],
        labels: data.existing_labels ? lower(data.existing_labels) : [],
      });

      if (isNew) {
      } else {
        setExisting(data);
        next();
      }
    }
  }, [api.get.data]);

  console.log({ visualsData, api, allVisualsList });

  useEffect(() => {
    if (existing) {
      handleInput('name', existing.category);
      handleInput(
        'type',
        existing.mutually_exclusive ? MUTUALLY_EXCLUSIVE : MULTIPLE_LABELS
      );
      handleInput('label_type', existing.label_type);
      handleInput('description', existing.description);

      Object.keys(existing.output_label_info).map((label) => {
        handleInput('labels', label);
        handleInput('initial_labels', label);
      });
    }
  }, [existing]);

  if (api.get.loading) {
    return (
      <div className="category-manager category-manager--loading">
        <p className="category-manager--loading__msg loading-text">
          Fetching category data
        </p>
      </div>
    );
  }

  if (api.get.error) {
    return (
      <div className="category-manager category-manager--loading">
        <p className="category-manager--loading__msg">
          Error: Darwin category not found
        </p>
      </div>
    );
  }

  return (
    <div ref={ref}>
      {stage === 1 && (
        <Setup
          {...{
            dropdownOptions,
            next,
            prev,
            input,
            handleInput,
            removeLabel,
            existing,
            existingNames,
            setVisualModeChanged,
            isNew,
          }}
        />
      )}
      {stage === 2 && (
        <Editor
          {...{
            prev,
            existing,
            fetch,
            fetchPerformanceData,
            visualsData,
            annotatedVisuals,
            setAnnotatedVisuals,
            predictedVisuals,
            setPredictedVisuals,
            initialSelected,
            labelsHaveChanged,
            visualModeChanged,
            category_id,
          }}
          category={category_name}
          input={input}
          isSingle={false}
        />
      )}
    </div>
  );
};

export default AIDriven;
