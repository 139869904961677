import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { BasicLoading } from '../../components/Helpers/Loading';
import { Results } from './components/Results/Results';
import { Form, reformatBlock, toBlockOpt } from './components/Form/Form';
import { useLabelPerformanceApi } from './api';
import { HARD_REFRESH, INITIAL } from './constants';
import { trackEvent } from '../../store/UserActivity/actions';
import {
  REPORT_BUILDER,
  REPORT_BUILDER_RUN,
  TOGGLE_REPORT_BUILDER,
} from '../../store/UserActivity/constants';
import { useDispatch } from 'react-redux';
import { useTrackEvent } from '../CreativeAnalytics/hooks/useTrackEvent';
import { useGetApi } from '../BulkActions/BlockManager/useApi';
import { useAccountId, useViewId } from '../CreativeAnalytics/selectors';
import { useDarwinClientId } from '../CreativeAnalytics/CategoryBuilder/selectors';
import { withoutPrefix } from '../DarwinTesting/utils';
import { ErrorBoundary } from '../../components/Helpers/Error';
import { useAnalyticsUrlProps } from '../CreativeAnalytics/PivotReport/hooks';
import Joyride from 'react-joyride';
import { DARWIN_BLUE, DARWIN_NAVY } from '../../constants';
import { LocalStorage } from '../../store/utils';
import { DarwinRobotIcon } from '../../components/Icons';
import { useUserId } from '../CreativeStudio/selectors';
import { GroupByProvider } from './contexts/GroupByContext';
import { steps } from './joyride';
import { LabelPerformanceReportState } from './types/types';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA':
      return action.data;
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.field]: action.updatedValue,
      };
    case 'RESET':
      return INITIAL;
    default:
      break;
  }
};

export const isTodayOrFuture = (dateString) => {
  const inputDate = new Date(dateString);
  const today = new Date();

  today.setHours(0, 0, 0, 0);
  inputDate.setHours(0, 0, 0, 0);

  return inputDate >= today;
};

const getInitialState = (shouldRefresh = false) => {
  return {
    ...INITIAL,
    [HARD_REFRESH]: !!shouldRefresh,
  };
};
const getInitialSegments = (
  selectedBlockIds,
  allBlocks,
  darwin_client_id,
  account_id
) => {
  try {
    if (!Array.isArray(selectedBlockIds) || !Array.isArray(allBlocks)) {
      console.error('selectedBlockIds or allBlocks is not an array.');
      return;
    }

    const blocks = selectedBlockIds.reduce(
      (acc, id) => {
        const match = allBlocks.find((x) => x.id === id);
        if (match) acc.push(reformatBlock(match, darwin_client_id, account_id));
        return acc;
      },
      [] as ReturnType<typeof reformatBlock>[]
    );

    return blocks ?? [];
  } catch (error) {
    console.error('Error in handleSubmit:', error);
    return [];
  }
};

export const LabelPerformanceReport = ({
  dateRange,
  PeriodPicker,
  renderAnalyticsToggle,
  darwinIqApi,
  runQueryApi,
  fetchLabelsApi,
}: {
  dateRange: { start: string; end: string };
  PeriodPicker: React.ComponentType;
  renderAnalyticsToggle: () => React.ReactNode;
  darwinIqApi: any;
  runQueryApi: any;
  fetchLabelsApi: any;
}) => {
  const account_id = useAccountId();
  const darwin_client_id = useDarwinClientId();
  const [state, dispatch] = useReducer(
    reducer,
    getInitialState(isTodayOrFuture(dateRange?.end), {})
  );

  const trackEvent = useTrackEvent();
  // const [selectedBlockIds, setSelectedBlockIds] = useState<string[]>([]);
  const {
    segments: selectedBlockIds,
    chosen_label,
    comparison_label,
  } = useAnalyticsUrlProps();
  const blocksApi = useGetApi(account_id, darwin_client_id, false);
  const allBlocks = blocksApi.data ?? [];
  const blockOpts = allBlocks
    .map((block) => toBlockOpt(block))
    .filter(({ value }) => !selectedBlockIds.includes(value));

  const handleUpdateField = (field, updatedValue) => {
    dispatch({ type: 'UPDATE_FIELD', field, updatedValue });
  };

  const refreshBlocks = () => {
    blocksApi.request(withoutPrefix(account_id));
  };

  const array_of_segments = useMemo(() => {
    return getInitialSegments(
      selectedBlockIds,
      allBlocks,
      darwin_client_id,
      account_id
    );
  }, [selectedBlockIds, allBlocks, darwin_client_id, account_id]);

  const handleSubmit = (fields: {}) => {
    trackEvent(REPORT_BUILDER, REPORT_BUILDER_RUN, {});

    runQueryApi.runQuery({
      ...state,
      ...fields,
      array_of_segments,
      chosen_label,
      comparison_label,
      array_of_ad_name_strings: null,
    });
  };

  useEffect(() => {
    fetchLabelsApi.fetchLabels();
    refreshBlocks();
  }, []);

  const initialBlocksFetched = useRef(false);

  useEffect(() => {
    if (!blocksApi.loading && !initialBlocksFetched.current) {
      handleSubmit({
        array_of_segments: getInitialSegments(
          selectedBlockIds,
          allBlocks,
          darwin_client_id,
          account_id
        ),
      });

      initialBlocksFetched.current = true;
    }
  }, [blocksApi.loading]);

  if (fetchLabelsApi.loading) {
    return <BasicLoading />;
  }

  function hasDatePassed(date) {
    const now = new Date();
    return date < now;
  }

  const joyrideDate = new Date('2025-03-21');

  const showJoyride = !hasDatePassed(joyrideDate);

  let Comp = null;

  if (runQueryApi.loading) {
    Comp = <BasicLoading />;
  } else if (runQueryApi.data) {
    Comp = (
      <Results
        {...runQueryApi.data}
        darwinIqApi={darwinIqApi}
        dateRange={dateRange}
        renderAnalyticsToggle={renderAnalyticsToggle}
        selectedBlockIds={selectedBlockIds}
        allBlocks={allBlocks}
      />
    );
  }

  return (
    <ErrorBoundary>
      <GroupByProvider visual_id_data={runQueryApi?.data?.visual_id_data}>
        <div className="label-performance-report">
          {showJoyride && (
            <Joyride
              steps={steps.map((step) => ({
                ...step,
                placement: step.placement as
                  | 'auto'
                  | 'center'
                  | 'top'
                  | 'top-start'
                  | 'top-end'
                  | 'bottom'
                  | 'bottom-start'
                  | 'bottom-end'
                  | 'left'
                  | 'left-start'
                  | 'left-end'
                  | 'right'
                  | 'right-start'
                  | 'right-end',
              }))}
              continuous={true}
              showProgress
              showSkipButton
              styles={{
                options: {
                  arrowColor: '#fff',
                  backgroundColor: '#fff',
                  overlayColor: '#1E2B4E20',
                  primaryColor: DARWIN_BLUE,
                  textColor: DARWIN_NAVY,
                },
                spotlight: {
                  backgroundColor: 'transparent',
                },
                tooltip: {
                  width: 360,
                },
                buttonClose: {
                  display: 'none',
                },
              }}
              locale={{
                last: 'Finish',
              }}
              disableScrolling={true}
            />
          )}
          <Form
            state={state}
            handleUpdateField={handleUpdateField}
            onSubmit={() => handleSubmit({})}
            labels={fetchLabelsApi.labels}
            loading={runQueryApi.loading}
            debug_data={runQueryApi?.data?.debug_data}
            PeriodPicker={PeriodPicker}
            selectedBlockIds={selectedBlockIds}
            blockOpts={blockOpts}
            allBlocks={allBlocks}
            blocksApi={blocksApi}
            refreshBlocks={refreshBlocks}
            labelsLoading={fetchLabelsApi.loading}
            chosen_label={chosen_label}
            comparison_label={comparison_label}
          />
          {Comp}
        </div>
      </GroupByProvider>
    </ErrorBoundary>
  );
};
