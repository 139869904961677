import React from 'react';
import PropTypes from 'prop-types';

export default class Iframe extends React.Component {
  renderSource() {
    const { src, style = { }, className = '' } = this.props;

    if (src.includes('<iframe')) {
      const html = {
        __html: src,
      };
      console.log(html)

      return (
        <div
          dangerouslySetInnerHTML={html}
          style={style}
          className={className}
        />
      );
    }
    return (
      <iframe src={src} style={style} className={className} />
    );
  }

  render() {
    return this.renderSource();
  }
}

Iframe.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  src: PropTypes.string,
};
