import React from "react";
import Button from "../../../../../components/Button";
import Select from "react-select";
import { Checkbox, FormControlLabel } from "@mui/material";

const Advanced = ({
  handleCloseForm,
  fetchRecs,
  recDepth,
  trackPath,
  handleDepthSelect,
  categoryLabels,
  depthOptions,
  fixedLabels,
  handleChecked,
  showForm,
  recsLoading,   
  recsError,
  attemptedDepth,
  recsErrorDisplay,
}) => {
  return (
    <form className={`advanced-recs-form ${showForm ? "show" : ""}`}>
      <div className="form-sections">
        <div className="rec-depth">
          <span className="section-title">Recommendation Depth</span>
          <p>Set how many changes you want the AI to suggest making.</p>
          <Select
            value={recDepth < 2 ? 2 : recDepth}
            placeholder={recDepth < 2 ? "2" : recDepth.toString()}
            options={depthOptions}
            onChange={(selected) => handleDepthSelect(selected)}
            classNamePrefix="advanced-dropdown"
          />
        </div>
        <div className="fixed-categories">
          <span className="section-title">Fixed Labels</span>
          <p>Specify which labels cannot be changed.</p>
          <ul>
            {categoryLabels.map((label, i) => {
              return (
                <li>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={label}
                        checked={fixedLabels.includes(label)}
                        onChange={(e) => handleChecked(e, i)}
                      />
                    }
                    label={label}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="form-actions">
        <Button
          onClick={handleCloseForm}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            fetchRecs(attemptedDepth, true);
            trackPath("Advanced");
          }}
          className="darwin-button--active"
        >
          Run
        </Button>
      </div>
      {recsLoading && (
        <div className="error-display">Loading...</div>
      )}
      {!recsLoading && !!recsErrorDisplay && (
        <div className="error-display error">{recsErrorDisplay}</div>
      )}
    </form>
  );
};

export default Advanced;
