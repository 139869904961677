import React from 'react';

import useCategories from '../../../hooks/useCategories';
import { MenuItem } from '../../../../../components/LeftMenu/MenuItem';
import {
  useCreativeAnalyticsGet,
  useCreativeAnalyticsSet,
} from '../../../contexts';
import { useDispatch } from 'react-redux';
import { customNanoId } from '../../../../../utils/nanoid';
import { trackEvent } from '../../../../../store/UserActivity/actions';
import {
  CREATIVE_ANALYTICS,
  TOGGLE_ADSET_REPORT,
  TOGGLE_AD_REPORT,
  TOGGLE_BLOCK_REPORT,
  TOGGLE_DASHBOARD,
  TOGGLE_LIBRARY,
  TOGGLE_PHRASE_THEME,
} from '../../../../../store/UserActivity/constants';
import {
  ADSET_NAME,
  AD_NAME,
  DASHBOARD,
  LIBRARY,
  PHRASE_THEME,
} from '../../../../../store/CreativeReports/constants';
import { AdsetIcon } from '../../icons/adset';
import { AdIcon } from '../../icons/adName';
import { useReportsApi } from '../../../../CreativeReports/withApi';
import { PhraseThemeIcon } from '../../icons/phraseTheme';
import { BlockIcon } from '../../icons/block';
import { LibraryIcon } from '../../icons/library';
import { usePermissions } from '../../../../../components/Permissions/usePermissions';
import {
  ADSET_REPORT,
  AD_REPORT,
  BLOCK_REPORT,
} from '../../../../../components/Permissions/constants';
import { useSearchParams } from '../../../../../components/Hooks/search-params';
import { DashboardIcon } from '../../icons/dashboard';
import Tooltip from '../../../../../components/Tooltip';

export const LegacyReports = ({
  loading,
  isDirectMail,
  isNonPaidMediaType,
  darwin_light,
  exclude,
  allowedAdmin,
  isCompetitorView,
  itemClass,
  collapsed,
}) => {
  const { display, isOrganicView } =
    useCreativeAnalyticsGet();
  const { setParams } = useSearchParams();
  const dispatch = useDispatch();
  const hasPermission = usePermissions();

  return (
    <MenuItem
      title="Legacy Reports"
      id="legacy-reports"
      icon={<i className="fa-regular fa-file-lines"></i>}
      expandByDefault={
        ['block_report', 'ad_name', 'adset_name'].includes(display)
          ? true
          : false
      }
      exclude={exclude}
      className={itemClass}
      {...{ collapsed }}
      subMenu={
        loading ? (
          <>
            <div>Loading...</div>
          </>
        ) : (
          <>
            <Tooltip
              disabled={!collapsed}
              content={'Dashboard'}
              placement={'right'}
              follow={false}
            >
              <div>
                <MenuItem
                  title="Dashboard"
                  icon={<DashboardIcon />}
                  onMenuItemClick={() => {
                    dispatch(trackEvent(CREATIVE_ANALYTICS, TOGGLE_DASHBOARD));
                    setParams({ display: DASHBOARD, category: '' });
                  }}
                  isSelected={display === DASHBOARD}
                  exclude={!hasPermission(DASHBOARD) || isOrganicView}
                  className={itemClass}
                />
              </div>
            </Tooltip>

            <Tooltip
              disabled={!collapsed}
              content={'Creative Library'}
              placement={'right'}
              follow={false}
            >
              <div>
                <MenuItem
                  title="Creative Library"
                  icon={<LibraryIcon />}
                  onMenuItemClick={() => {
                    dispatch(trackEvent(CREATIVE_ANALYTICS, TOGGLE_LIBRARY));
                    setParams({ display: LIBRARY, category: '' });
                  }}
                  isSelected={display === LIBRARY}
                  exclude={!hasPermission(LIBRARY)}
                  className={itemClass}
                />
              </div>
            </Tooltip>

            <Tooltip
              disabled={!collapsed}
              content={'Block Report'}
              placement={'right'}
              follow={false}
            >
              <div>
                <MenuItem
                  title="Block Report"
                  icon={<BlockIcon />}
                  id="block-report"
                  onMenuItemClick={() => {
                    dispatch(
                      trackEvent(CREATIVE_ANALYTICS, TOGGLE_BLOCK_REPORT)
                    );
                    setParams({ display: 'block_report', category: '' });
                  }}
                  isSelected={display === 'block_report'}
                  exclude={
                    !hasPermission(BLOCK_REPORT) ||
                    isNonPaidMediaType ||
                    isCompetitorView ||
                    ((darwin_light || isDirectMail) && !allowedAdmin) ||
                    isOrganicView
                  }
                  className={itemClass}
                />
              </div>
            </Tooltip>
            {/* <MenuItem
              title="Adset"
              icon={<AdsetIcon />}
              onMenuItemClick={() => {
                dispatch(trackEvent(CREATIVE_ANALYTICS, TOGGLE_ADSET_REPORT));
                setParams({ display: ADSET_NAME, category: '' });
              }}
              isSelected={display === ADSET_NAME}
              exclude={
                !hasPermission(ADSET_REPORT) || isOrganicView || isUltraLight || viewName.includes('Email')
              }
            />
            <MenuItem
              title="Ad"
              icon={<AdIcon />}
              onMenuItemClick={() => {
                dispatch(trackEvent(CREATIVE_ANALYTICS, TOGGLE_AD_REPORT));
                setParams({ display: AD_NAME, category: '' });
              }}
              isSelected={display === AD_NAME}
              exclude={
                !hasPermission(AD_REPORT) || isOrganicView || isUltraLight || viewName.includes('Email')
              }
            /> */}
          </>
        )
      }
    />
  );
};
