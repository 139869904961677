import React, { ReactChildren, useState } from 'react';

import './Menu.scss';
import Tooltip from '../Tooltip';
import { ChevronDoubleRightIcon } from '../../features/CreativeAnalytics/CreativeRequest/icons';

interface MenuProps {
  children: ReactChildren;
}

export const LeftMenu: React.FC<MenuProps> = ({ children }) => {
  const [minimized, setMinimized] = useState(false);

  return (
    <div className={`left-menu ${minimized ? 'minimized' : ''}`}>
      {/* <div
        className="minimize-toggle"
        onClick={() => setMinimized((prev) => !prev)}
      >
        <Tooltip
          content={`${minimized ? 'Open' : 'Close'} sidebar`}
          follow={false}
          delay={[500, 0]}
        >
          <div className={`minimize-toggle__icon ${minimized ? '' : 'left'}`}>
            <ChevronDoubleRightIcon />
          </div>
        </Tooltip>
      </div> */}
      <ul className="menu">{children}</ul>
    </div>
  );
};
