//@ts-check
import React from "react";
import PropTypes from "prop-types";

import { useAd } from "./Container";
import AdPreview from "../AdPreview/Preview";
import { AD_WIDTH } from "./constants";

import AdCreator from "../../components/AdCreator";

const PLACEHOLDER = { width: `${AD_WIDTH}px` };

export const Ad = ({ showEditor }) => {
  if (showEditor) {
    return <AdCreator />;
  } else {
    return <AdPreviewDisplay />;
  }
};

Ad.propTypes = {
  showEditor: PropTypes.bool.isRequired,
};

const AdPreviewDisplay = () => {
  const {
    adPreviews,
    adPreviewsIndex,
    ad: { _id },
  } = useAd();

  let adPreview = null;

  if (_id !== "") {
    const maybe = adPreviewsIndex[_id];

    adPreview = maybe ? adPreviews[maybe.previews].preview : adPreview;
  }

  return !!adPreview ? (
    <AdPreview
      style={{
        backgroundColor: "#ffffff",
        width: "580px",
        padding: "3rem",
      }}
      className="darwin-box-shadow d-flex flex-col justify-content-center align-items-center"
      options={[{ label: "DESKTOP_FEED_STANDARD", value: adPreview }]}
    />
  ) : (
    <div className="darwin-box-shadow bg-white p-3" style={{ width: "580px" }}>
      <p>The preview is not available.</p>{" "}
      <p>
        Make sure valid copy, visual, headline, and button elements are
        selected.
      </p>
    </div>
  );
};

export const Placeholder = ({ children = null }) => (
  <div style={PLACEHOLDER}>{children}</div>
);
