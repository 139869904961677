import { useLocation } from 'react-router-dom';

import { useApiState } from '../../components/Hooks/useApiState';

export function useAdIds() {
  const loc = useLocation();
  const query = new URLSearchParams(loc.search);
  const ids = query.get('ids');

  if (!ids) {
    return [];
  }
  return ids.split(',').filter((id, i, arr) => arr.indexOf(id) === i);
}

function matchParamsToResponse(ids, res) {
  const lookup = res.reduce((acc, item) => {
    acc[item.ad_id] = item;

    return acc;
  }, {});

  return ids.map(
    (id) => lookup[id] || { ad_id: id, ad_name: 'N/A', view_id: null }
  );
}

function fetchAdViewPairings(adIds) {
  return fetch(['api', 'previews', 'pairings'].join('/'), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ values: adIds, attribute: 'ad_id' }),
  })
    .then((r) => r.json())
    .then(({ data }) => matchParamsToResponse(adIds, data));
}

export function usePairingsApi() {
  const [state, dispatchFetch] = useApiState(fetchAdViewPairings);

  return {
    ...state,
    request: dispatchFetch,
  };
}
