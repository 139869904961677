import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Select from 'react-select';

import { darwinDateToDate, toDarwinDate } from '../../../utils/darwin-dates';
import CustomDropdown from '../../../components/CustomDropdown';
import DateSpan from '../../../components/DateSpanDropdown';
import {
  GoogleAnalyticsIcon,
  DarwinRobotIcon,
  GoogleAdsIcon,
} from '../../../components/Icons';

import { DATES } from './dates';
import { PF_DATE_PAIRS } from '../../../utils/darwin-dates';
import {
  REPORT_OPTIONS,
  METRIC_OPTIONS,
  GA_METRIC_OPTIONS,
  UNIFIED_METRIC_OPTIONS,
  G_METRIC_OPTIONS,
} from './constants';
import { OptionType } from './feature-prop-types';
import { useBlockParamsGet, useBlockParamsSet } from './Contexts';
import { useCustomMetrics } from './selectors';
import {
  useCreativeAnalyticsGet,
  useCreativeAnalyticsSet,
} from '../../CreativeAnalytics/contexts';

const REPORT_KEYS = [...REPORT_OPTIONS].map(([key]) => key);

export function ReportPicker() {
  const { breakdown } = useBlockParamsGet();
  const setParams = useBlockParamsSet();
  const selected = REPORT_OPTIONS.get(breakdown) || { label: 'N/A' };

  return (
    <CustomDropdown
      id="block-report-select"
      label={selected.label}
      onSelect={(key) =>
        setParams({ breakdown: REPORT_OPTIONS.get(key).value })
      }
      menuStyle={{ overflow: 'auto' }}
    >
      {REPORT_KEYS.map((key) => {
        const report = REPORT_OPTIONS.get(key);

        return (
          <CustomDropdown.Item key={report.value} eventKey={report.value}>
            {report.label}
          </CustomDropdown.Item>
        );
      })}
    </CustomDropdown>
  );
}

function setDateRanges(disableToday) {
  if (disableToday) {
    return [{ ...DATES[0], disabled: true }, ...DATES.slice(1)];
  }

  return DATES;
}
export function PeriodPicker({ name, disableToday }) {
  const params = useBlockParamsGet();
  const period = params[name];
  const setParams = useBlockParamsSet();
  const [startDate, endDate] = period.split(',').map(darwinDateToDate);
  const [show, setShow] = useState(false);

  const handleChange = (dates) => {
    setShow(false);
    
    if (!dates || dates === '') {
      return setParams({ [name]: '' });
    }
    const str = dates.map(toDarwinDate).join(',');
    console.log(str);

    return setParams({ [name]: str });
  };

  if (!period || period.length < 1) {
    return (
      <DateSpan
        startDate={new Date()}
        endDate={new Date()}
        handleChange={handleChange}
        dateRanges={setDateRanges(disableToday)}
        allowNoSelection={name === 'period2'}
        noneSelected
        {...{ show, setShow }}
      />
    );
  }

  return (
    <DateSpan
      startDate={startDate}
      endDate={endDate}
      handleChange={handleChange}
      dateRanges={setDateRanges(disableToday)}
      allowNoSelection={name === 'period2'}
      {...{ show, setShow }}
    />
  );
}

PeriodPicker.defaultProps = {
  disableToday: false,
};

PeriodPicker.propTypes = {
  name: PropTypes.oneOf(['period1', 'period2']).isRequired,
  disableToday: PropTypes.bool,
};

function extendCustomMetrics(metrics) {
  return metrics.reduce((acc, { value, label, remove_cost_per }) => {
    acc.push({ value, label, remove_cost_per, isCustom: true });
    if (!remove_cost_per) {
      acc.push({
        value: `cp_${value}`,
        label: `Cost per ${label}`,
        isCustom: true,
      });
    }

    return acc;
  }, []);
}

function useColumnOptions() {
  const custom = useCustomMetrics();
  const merged = [...METRIC_OPTIONS, ...extendCustomMetrics(custom)];

  return merged;
}

export function ColumnPicker({ onChange, values, disableCustom }) {
  const options = useColumnOptions();
  let { ga_enabled } = useSelector(({ ViewSettings: { ga_enabled } }) => ({
    ga_enabled,
  }));
  const { include_ga_data } = useBlockParamsGet() || {};

  const selected = values.reduce((acc, { value }) => {
    acc[value] = 1;

    return acc;
  }, Object.create(null));
  const [show, setShow] = useState(false);

  const handleVisibility = (isOpen, meta) => {
    if (isOpen) {
      setShow(true);
    } else if (meta.source !== 'select') {
      setShow(false);
    }
  };

  const handleSelect = (index) => {
    const metric = index.includes('ga')
      ? GA_METRIC_OPTIONS[parseInt(index.split('_')[1])]
      : index.includes('u')
      ? UNIFIED_METRIC_OPTIONS[parseInt(index.split('_')[1])]
      : index.includes('g')
      ? G_METRIC_OPTIONS[parseInt(index.split('_')[1])]
      : options[index];

    if (metric.isCustom && disableCustom) {
      return null;
    }

    return onChange({ metric, checked: !selected[metric.value] });
  };

  return (
    <CustomDropdown
      id="block-metric-select"
      label="Columns"
      onToggle={handleVisibility}
      onSelect={handleSelect}
      menuStyle={{ overflow: 'scroll', height: '650px' }}
      show={show}
    >
      <CustomDropdown.Header>Unified Data</CustomDropdown.Header>
      {UNIFIED_METRIC_OPTIONS.map(({ value, label }, i) => (
        <CustomDropdown.Item key={value} eventKey={`u_${i}`} className="py-0">
          <DarwinRobotIcon className="dw-tag__icon d-inline-block" />
          <div className="form-check d-inline-block align-vertical">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="form-check-input"
                name={value}
                defaultChecked={selected[value]}
              />
              {label}
            </label>
          </div>
        </CustomDropdown.Item>
      ))}

      <CustomDropdown.Header>Facebook Data</CustomDropdown.Header>
      {options.map(({ value, label, isCustom }, i) => (
        <CustomDropdown.Item key={value} eventKey={i} className="py-0">
          <span className="dw-tag__icon dw-tag__icon--fb d-inline-block">
            <i className="fa-brands fa-facebook-f"></i>
          </span>
          <div className="form-check d-inline-block align-vertical">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="form-check-input"
                name={value}
                defaultChecked={selected[value]}
                disabled={isCustom && disableCustom}
              />
              {label}
            </label>
          </div>
        </CustomDropdown.Item>
      ))}

      {ga_enabled && include_ga_data && (
        <>
          <CustomDropdown.Header>Google Analytics Data</CustomDropdown.Header>
          {GA_METRIC_OPTIONS.map(({ value, label }, i) => (
            <CustomDropdown.Item
              key={value}
              eventKey={`ga_${i}`}
              className="py-0"
            >
              <GoogleAnalyticsIcon className="dw-tag__icon dw-tag__icon--ga d-inline-block" />
              <div className="form-check d-inline-block align-vertical">
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name={value}
                    defaultChecked={selected[value]}
                  />
                  {label}
                </label>
              </div>
            </CustomDropdown.Item>
          ))}
        </>
      )}

      <CustomDropdown.Header>Google Data</CustomDropdown.Header>
      {G_METRIC_OPTIONS.map(({ value, label }, i) => (
        <CustomDropdown.Item key={value} eventKey={`g_${i}`} className="py-0">
          <GoogleAdsIcon className="dw-tag__icon dw-tag__icon--ga d-inline-block" />
          <div className="form-check d-inline-block align-vertical">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="form-check-input"
                name={value}
                defaultChecked={selected[value]}
              />
              {label}
            </label>
          </div>
        </CustomDropdown.Item>
      ))}
    </CustomDropdown>
  );
}

ColumnPicker.defaultProps = {
  disableCustom: false,
};

ColumnPicker.propTypes = {
  values: PropTypes.arrayOf(OptionType).isRequired,
  onChange: PropTypes.func.isRequired,
  disableCustom: PropTypes.bool,
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
