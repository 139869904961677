/* eslint-disable no-nested-ternary */

import React, { useEffect, useState, useCallback } from 'react';
import './PerformanceFrequency.scss';
import { darwinDateToChartDate } from '../../CreativeReports/BlockReport/dates';
import { TablesWrapper } from './Table/Wrapper';
import Button from '../../../components/Button';
import GridIcon from '../../../components/Icons/GridIcon';
import { ColumnPicker, PeriodPicker } from './Pickers';
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from '../contexts';
import useTrackDeps from '../../../hooks/useTrackDeps';

export default function PerformanceFrequency({
  data,
  filteredData,
  creativeCount,
  columns,
  period1,
  period2,
  loading,
  showLoading,
  handleColumns,
  handleSharePfr,
}) {
  const { clientMetrics } = useCreativeAnalyticsGet();
  const formatDate = (d, i) => {
    return darwinDateToChartDate(d.split(',')[i]).replace(/\b0/g, '');
  };
  const p1 = period2 && (
    <span>
      {formatDate(period1, 0)} - {formatDate(period1, 1)}
    </span>
  );

  return (
    <>
      {filteredData && creativeCount && (
        <div className="d-flex">
          <div className="col performance-frequency clearfix">
            <div
              className="d-flex"
              style={{
                padding: '1rem 0 1rem 4rem',
                borderBottom: '1px solid #e4e9f0',
              }}
            >
              <Button
                className="mr-2"
                style={{ height: '30px', padding: '0 6px' }}
                appearance="raised"
                active={columns.includes('visuals')}
                onClick={() => {
                  handleColumns({
                    column: 'visuals',
                    checked: !columns.includes('visuals'),
                  });
                }}
              >
                <GridIcon className="mr-2" /> Show visuals
              </Button>

              <ColumnPicker
                columns={columns}
                clientMetrics={clientMetrics}
                onChange={handleColumns}
              />

              <Button
                className="ml-2"
                appearance="raised"
                onClick={handleSharePfr}
              >
                <i
                  class="fa-solid fa-share mr-2"
                  style={{ transform: 'translateY(-1px)' }}
                ></i>
                Share
              </Button>
            </div>

            <div>
              {(!loading || showLoading) && (
                <div>
                  {period2 && (
                    <div className="text-center">
                      <h4>{p1}</h4>
                    </div>
                  )}
                  {data.length > 0 ? (
                    <TablesWrapper columns={columns} isCompare={false} />
                  ) : null}
                </div>
              )}
              {loading && !showLoading && (
                <h3 className="performance-frequency__loading m-3">
                  Fetching performance data
                </h3>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
