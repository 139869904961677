import React, { useEffect, useReducer } from 'react';
import { useDeleteApi, useGetApi, usePostApi } from '../../../BulkActions/BlockManager/useApi';
import { Loading } from '../../../../components/Helpers/Loading';
import { useAccountId, useDarwinClientId, useAccountPlatformObject } from '../selectors';
import { ApiStatus } from '../../../BulkActions/BlockManager/constants';
import BlockForm from './BlockForm';

function getIdFromResponse(json) {
  if (json && json.data && json.data.id) {
    return json.data.id;
  }
  return '';
}

function updateBlock(blocks, block) {
  return blocks.map((blk) => {
    if (blk.id === block.id) {
      return { ...blk, ...block };
    }
    return blk;
  });
}

const ADD_BLOCK = 'add-block';
const EDIT_BLOCK = 'edit-block';
const DELETE_BLOCK = 'delete-block';
const FAILURE = 'failure';
const SUCCESS = 'success';

function reducer(state, action) {
  switch (action.type) {
    case DELETE_BLOCK:
      return {
        index: -1,
        blocks: updateBlock(state.blocks, { id: action.id, status: ApiStatus.loading }),
      };

    case ADD_BLOCK: {
      return {
        index: -1,
        blocks: [...state.blocks, { ...action.block, status: ApiStatus.loading }],
      };
    }

    case EDIT_BLOCK: {
      return {
        index: -1,
        blocks: updateBlock(state.blocks, { ...action.block, status: ApiStatus.loading }),
      };
    }

    case FAILURE:
      return {
        index: state.index,
        blocks: state.blocks.map((block) => {
          if (block.id !== action.id) {
            return block;
          }
          return { ...block, status: ApiStatus.failure };
        }),
      };

    case SUCCESS: {
      const { index, blocks } = state;
      const { id, previousAction, updatedId } = action;
      const nextState = { index, blocks };

      if (previousAction === DELETE_BLOCK) {
        nextState.blocks = blocks.filter((block) => block.id !== id);
      } else {
        nextState.blocks = blocks.map((block) => {
          if (block.id === id) {
            const nextId = updatedId || id;

            return { ...block, id: nextId, status: ApiStatus.success };
          }
          return block;
        });
      }

      return nextState;
    }

    default:
      return state;
  }
}

export default function BlockFormWrapper({
  location, predeterminedCategory, onClose, formType, blockId, testField, refreshBlocks, handleUpdateBlock
}) {
  const accountId = useAccountId();
  const platforms = useAccountPlatformObject();
  const darwin_client_id = useDarwinClientId();
  const include_testing_blocks = location === 'testing' ? true : false;
  const getApi = useGetApi(accountId, darwin_client_id, include_testing_blocks);
  const postApi = usePostApi(accountId, darwin_client_id);
  const deleteApi = useDeleteApi(accountId);
  const [{ blocks, index }, dispatch] = useReducer(
    reducer,
    {
      index: -1, blocks: getApi.data,
    },
  );

  useEffect(() => {
    getApi.request();
  }, []);

  const addBlock = (block) => dispatch({
    type: ADD_BLOCK,
    block,
  });

  const editBlock = (block) => dispatch({
    type: EDIT_BLOCK,
    index,
    block,
  });

  const saveWith = (dispatchSetBlock) => async (block) => {
    dispatchSetBlock(block);

    try {
      const res = await postApi.request_P(block);

      if (res.status !== 200) {
        dispatch({ type: FAILURE, id: block.id });
      } else {
        const updatedId = getIdFromResponse(await res.json());
        handleUpdateBlock(updatedId, testField);

        dispatch({ type: SUCCESS, id: block.id, updatedId });
      }
    } catch (e) {
      window.logger.error(e);

      dispatch({ type: FAILURE, id: block.id });
    }
  };

  const handleDelete = async () => {
    dispatch({ type: DELETE_BLOCK, blockId });
    onClose();

    try {
      const res = await deleteApi.request_P(blockId);

      if (res.status !== 200) {
        dispatch({ type: FAILURE, blockId });
      } else {
        dispatch({ type: SUCCESS, blockId, previousAction: DELETE_BLOCK });
        handleUpdateBlock('', testField);

        refreshBlocks();
      }
    } catch (e) {
      window.logger.error(e);

      dispatch({ type: FAILURE, blockId });
    }
  };

  function getUniqCategories(blocks) {
    return [...new Set(blocks.map(({ category }) => category))];
  }

  let Render = null;
  const onSubmit = formType === 'Create' ? saveWith(addBlock) : saveWith(editBlock);

  if (formType === 'Delete') {
    Render = (
      <div className="block-form delete-block">
        <h3>Are you sure you want to delete this block?</h3>
        <div className="d-flex">
          <button type="button" onClick={onClose} className="btn btn-danger">Cancel</button>
          <button type="button" onClick={handleDelete} className="btn btn-primary">Yes</button>
        </div>
      </div>
    )
  } else if (getApi.error) {
    Render = (
      <div>
        <h3>Whoops &#58;&#40;</h3>
        <p style={{ color: 'red' }}>{getApi.error.toString()}</p>
      </div>
    );
  } else if (getApi.loading) {
    Render = <Loading />;
  } else {
    Render = (
      <BlockForm
        predeterminedCategory={predeterminedCategory}
        formType={formType}
        data={getApi.data}
        categories={getUniqCategories(getApi.data)}
        platformOptions={platforms}
        onClose={onClose}
        blockId={blockId}
        refreshBlocks={refreshBlocks}
        onSubmit={onSubmit}
        handleDelete={handleDelete}
      />
    );
  }

  return (
    <div className="block-form-wrapper">
      {Render}
    </div>
  );
}
