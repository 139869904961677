import { connect } from 'react-redux';
import { fetchLogin, LOGIN } from '../../store/background/api.login';
import { setError } from '../../store/Errors/actions';

const stateToProps = ({
  Loaders,
  Errors,
  ViewSettings: { loggedIn, viewId },
}) => ({
  isLoading: Loaders[LOGIN],
  hasError: Errors[LOGIN],
  loggedIn,
  viewId: viewId || '',
});

const dispatchToProps = (dispatch) => ({
  fetchLogin: ({ email, password }) =>
    dispatch(fetchLogin({ email, password })),
  removeError: () => dispatch(setError(LOGIN, false)),
});

export default connect(stateToProps, dispatchToProps);
