import React from 'react';
import { Row, Col, FormGroup, FormLabel, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AccountPageFooter from '../AccountPageFooter/AccountPageFooter';

import validate from '../Login/validateLogin';

class RequestReset extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const component = this;
    validate(component.form, {
      rules: {
        emailAddress: {
          required: true,
          email: true,
        },
      },
      messages: {
        emailAddress: {
          required: 'Need an email address here.',
          email: 'Is this email address correct?',
        },
      },
      submitHandler() {
        component.handleSubmit();
      },
    });
  }

  handleSubmit() {
    this.props.fetchSendReset({
      email: this.emailAddress.value,
    });
  }

  renderRequestCodeForm() {
    return (
      <div>
        <Row>
          <Col xs={12} sm={6} md={5} lg={4}>
            <h4 className="page-header">Request Password Reset</h4>
            <form
              ref={(form) => (this.form = form)}
              onSubmit={(event) => event.preventDefault()}
            >
              <FormGroup>
                <FormLabel>Email Address</FormLabel>
                <input
                  type="email"
                  name="emailAddress"
                  ref={(emailAddress) => (this.emailAddress = emailAddress)}
                  className="form-control"
                />
              </FormGroup>
              <Button type="submit" bsstyle="success">
                Send Confirmation Code
              </Button>
              <AccountPageFooter>
                <p>
                  <Link to="/login">Log In</Link> | Already have a code?{' '}
                  <Link to="/reset-password">Reset Password</Link>
                </p>
              </AccountPageFooter>
            </form>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    return <div className="RequestReset">{this.renderRequestCodeForm()}</div>;
  }
}

RequestReset.propTypes = {
  fetchSendReset: PropTypes.func,
};

export default RequestReset;
