import React from 'react';

export const Bar = () => {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3125 0.876831H8.9375C8.75516 0.876831 8.5803 0.941244 8.45136 1.0559C8.32243 1.17056 8.25 1.32607 8.25 1.48821V11.8768H11V1.48821C11 1.32607 10.9276 1.17056 10.7986 1.0559C10.6697 0.941244 10.4948 0.876831 10.3125 0.876831ZM6.1875 4.54513H4.8125C4.63016 4.54513 4.4553 4.60954 4.32636 4.7242C4.19743 4.83885 4.125 4.99436 4.125 5.15651V11.8768H6.875V5.15651C6.875 4.99436 6.80257 4.83885 6.67364 4.7242C6.5447 4.60954 6.36984 4.54513 6.1875 4.54513ZM2.0625 8.21343H0.6875C0.505164 8.21343 0.330295 8.27784 0.201364 8.3925C0.0724328 8.50715 0 8.66266 0 8.82481V11.8768H2.75V8.82481C2.75 8.66266 2.67757 8.50715 2.54864 8.3925C2.4197 8.27784 2.24484 8.21343 2.0625 8.21343Z"
        fill="#434C64"
      />
    </svg>
  );
};
