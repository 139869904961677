export const getPhraseTheme = (
  allVisualsLabel,
  filteredAllVisuals,
  lastSavedPhraseThemes
) => {
  let phraseThemeLabels = [];

  if (lastSavedPhraseThemes.length) {
    phraseThemeLabels = lastSavedPhraseThemes.map((theme) => {
      const themeVisuals = filteredAllVisuals.filter((visual) => {
        return theme.visual_ids?.includes(visual.id);
      });
      const themeWinners = allVisualsLabel.winners.filter((visual) =>
        themeVisuals.some((n) => n.id === visual.id)
      );
      const themeLosers = allVisualsLabel.losers.filter((visual) =>
        themeVisuals.some((n) => n.id === visual.id)
      );

      return {
        name: theme.phrase_theme,
        value: theme.phrase_theme,
        category: 'Phrase Theme',
        denominator: themeVisuals.length,
        numerator: themeWinners.length,
        winners: themeWinners,
        losers: themeLosers,
      };
    });
  }

  return phraseThemeLabels;
};