import numeral from 'numeral';

/**
 * @param {number | string | NaN | Infinity} n
 */
export const isNumber = (n) =>
  !Number.isNaN(parseFloat(n)) && Number.isFinite(n);

export const toCurrency = (n) => numeral(n).format('$0,0.00');
export const toCurrency_4 = (n) => numeral(n).format('$0,0.0000');

export const toPercent = (n) => numeral(n).format('0.0%');
export const toPercent_2 = (n) => numeral(n).format('0.00%');
export const toPercent_3 = (n) => numeral(n).format('0.000%');
export const toPercentRounded = (n) => numeral(n).format('0%');

export const toFixed_2 = (n) => numeral(n).format('0.00');
export const toFixed_3 = (n) => numeral(n).format('0.000');

export const toInt = (n) => numeral(n).format('0,0');
export const toLocale_2 = (n) =>
  Number(n).toLocaleString('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }); // 1,000.00

export const strToNum = (n) => numeral(n).value();

export const generateRandomNumberString = (numDigits) => {
  const min = Math.pow(10, numDigits - 1);
  const max = Math.pow(10, numDigits) - 1;
  const randomNumber = Math.floor(min + Math.random() * (max - min + 1));
  return randomNumber.toString();
};

/**
 * by default, lower change is given positive percent diff
 */
export const findPercentDifference = (originalValue, newValue, preferLower) => {
  if (!originalValue) {
    return null;
  }

  const percentDifference = (newValue - originalValue) / originalValue;
  

  return percentDifference;
};
