import React from "react";

export const ChevronDoubleRightIcon = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.58578 6.58578L0.960782 12.2108C0.855234 12.3163 0.71208 12.3756 0.562813 12.3756C0.413546 12.3756 0.270392 12.3163 0.164844 12.2108C0.0592961 12.1052 2.94241e-09 11.9621 0 11.8128C-2.94241e-09 11.6635 0.0592961 11.5204 0.164844 11.4148L5.39258 6.18781L0.164844 0.960781C0.0592961 0.855234 -1.11213e-09 0.71208 0 0.562813C1.11213e-09 0.413546 0.0592961 0.270392 0.164844 0.164844C0.270392 0.0592961 0.413546 1.11213e-09 0.562813 0C0.71208 -1.11213e-09 0.855234 0.0592961 0.960782 0.164844L6.58578 5.78984C6.63808 5.84208 6.67957 5.90412 6.70788 5.97241C6.73619 6.0407 6.75075 6.11389 6.75075 6.18781C6.75075 6.26173 6.73619 6.33493 6.70788 6.40322C6.67957 6.4715 6.63808 6.53354 6.58578 6.58578ZM12.2108 5.78984L6.58578 0.164844C6.48023 0.0592961 6.33708 0 6.18781 0C6.03855 0 5.89539 0.0592961 5.78984 0.164844C5.6843 0.270392 5.625 0.413546 5.625 0.562813C5.625 0.71208 5.6843 0.855234 5.78984 0.960781L11.0176 6.18781L5.78984 11.4148C5.6843 11.5204 5.625 11.6635 5.625 11.8128C5.625 11.9621 5.6843 12.1052 5.78984 12.2108C5.89539 12.3163 6.03855 12.3756 6.18781 12.3756C6.33708 12.3756 6.48023 12.3163 6.58578 12.2108L12.2108 6.58578C12.2631 6.53354 12.3046 6.4715 12.3329 6.40322C12.3612 6.33493 12.3758 6.26173 12.3758 6.18781C12.3758 6.11389 12.3612 6.0407 12.3329 5.97241C12.3046 5.90412 12.2631 5.84208 12.2108 5.78984Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};
