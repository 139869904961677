/* eslint-disable max-len */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Layout from './Layout';
import AdName from './AdName';
import { PrimaryColor } from './PrimaryColor';
import Preview from '../../../components/AdPreview/Preview';
import { RunningStrikezone } from '../../../components/Strikezone';
import PerformancePanel from '../../../components/RunningPerformancePanel';
import { DESKTOP_FEED_STANDARD } from '../../../components/AdPreview/constants';

import VisualAttributes from '../../../components/VisualAttributes/VisualAttributes';
import { StandaloneLabelMatrix } from '../../../components/LabelMatrix';
import { useLazyLoader } from '../../../components/LazyLoader/useLazyLoader';
import { BasicLoading } from '../../../components/Helpers/Loading';

import { useDispatch, useSelector } from 'react-redux';
import { previewsFetch } from '../../../store/AdPreviews/actions';
import { useGetAdPreviews } from './api';
import { SUCCESS } from '../../../store/constants';

const Wrapper = (props) => {
  const { adName, handleBoostModal } = props;
  const [selectedType, setSelectedType] = useState('');
  const [source, setSource] = useState({});
  const [domRef, visible, pause] = useLazyLoader();
  const dispatch = useDispatch();
  const adPreviewsApi = useGetAdPreviews();
  const [previews, setPreviews] = useState([]);
  const [adIds, setAdIds] = useState('');

  const { view_id, adPreviews, previewsIndex, adNameData } = useSelector(
    ({ ViewSettings: { viewId }, AdPreviews: { previews, previewsIndex }, CreativeReports: { reports } }) => ({
      view_id: viewId,
      adPreviews: previews,
      previewsIndex,
      adNameData: reports.ad_name,
    }),
  );

  let ad_ids = [];
  if (adNameData && adName) {
    const current = adNameData.find((obj) => obj.value === adName);
    if (current.ad_ids.length) {
      ad_ids = current.ad_ids;
    }
  }

  useEffect(() => {
    if (previews.length) {
      setSelectedType('cpa');
      if (previews[0].label !== 'DESKTOP_FEED_STANDARD') {
        setSource(previews[0] || null);
      } else {
        setSource(previews[1] || null);
      }
    }
  }, [previews]);

  const handleSelectedZone = (selection) => {
    const update = selection.type
      ? selection.type
      : '';

    setSelectedType(update);
  }

  const handleSelectPreview = (selected) => {
    const update = !selected ? previews[0] : selected;

    setSource(update);
  }

  const alreadyPresent = (adName, previewsIndex) => !!previewsIndex[adName]

  const getPreviewsFromStore = (adName) => adPreviews.find((adPreview) => adPreview.ad_name === adName);

  const formatPreviews = (arr) => arr.map(({ type, preview }, i) => ({
    label: type,
    value: preview,
    index: i,
  }))

  const getPreviews = () => {
    // check if the preview already exists in the AdPreviews store
    const shouldFetch = !alreadyPresent(adName, previewsIndex);
    if (shouldFetch) {
      adPreviewsApi.post.request(view_id, { attribute: 'ad_name', values: [adName] });
    } else {
      const existingPreviews = getPreviewsFromStore(adName);
      setPreviews(formatPreviews(existingPreviews.preview_list));
    }
  }

  useEffect(() => {
    if (adPreviewsApi.post.data) {
      const { data } = adPreviewsApi.post;

      const fetchedPreviews = formatPreviews(data[0].preview_list);

      setPreviews(fetchedPreviews);
      dispatch(previewsFetch(SUCCESS, { response: data, error: null }));
    }
  }, [adPreviewsApi.post.data]);

  useEffect(() => {
    if (visible && adName) {
      getPreviews();
    }
  }, [visible, adName]);

  useEffect(() => {
    if (visible) {
      pause();
    }
  }, [visible]);

  let PreviewDisplay = null;

  if (adPreviewsApi.post.error) {
    PreviewDisplay = (
      <div>
        {adPreviewsApi.post.error}
      </div>
    );
  } else if (adPreviewsApi.post.loading && !alreadyPresent(adName, previewsIndex)) {
    PreviewDisplay = (
      <BasicLoading
        fallback={(
          <h3 className="ad-previews-loading">Fetching ad preview</h3>
        )}
      />
    );
  } else {
    PreviewDisplay = (
      <Preview
        options={previews}
        reportsPreview={source}
        style={{
          backgroundColor: '#ffffff',
          width: '580px',
          padding: '3rem',
        }}
        className="darwin-box-shadow d-flex flex-col justify-content-center align-items-center"
        mobileOnly
        location="reports"
      />
    );
  }

  return (
    <Layout
      adName={adName}
      AdPreview={(
        <div ref={domRef}>
          {PreviewDisplay}
        </div>
      )}
      ActionPanel={<CurrentlyRunning handleBoostModal={handleBoostModal} />}
      StrikeZone={(
        <RunningStrikezone
          adName={adName}
          handleSelection={handleSelectedZone}
        />
      )}
      StandaloneLabelMatrix={(
        <StandaloneLabelMatrix 
          adName={adName} 
          PrimaryColor={<PrimaryColor adName={adName} />}
        />
      )}
      PerformancePanel={
        <PerformancePanel adName={adName} selectedType={selectedType} />
      }
      VisualAttributes={(
        <VisualAttributes
          location="ad_preview"
          getPayload={{ adIds }}
          adIds={{ adIds }}
        />
      )}
      options={previews.length ? previews.filter((opt) => opt.label !== DESKTOP_FEED_STANDARD) : []}
      handleSelectPreview={handleSelectPreview}
    />
  );
}

Wrapper.propTypes = {
  adName: PropTypes.string.isRequired,
  handleBoostModal: PropTypes.func.isRequired,
  adPreviews: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};

export default Wrapper;

const CurrentlyRunning = ({ handleBoostModal }) => (
  <div>
    <div className="d-flex flex-row justify-content-between align-items-baseline mb-1">
      <h4 className="font-weight-bold">Currently Running Ad</h4>

      <button
        type="button"
        className="btn btn-primary"
        onClick={handleBoostModal}
      >
        Boost
      </button>
    </div>

    <p>
      This is a currently running ad. Below you can see the performance stats of
      the ad within the dates selected.
    </p>
  </div>
);

CurrentlyRunning.propTypes = {
  handleBoostModal: PropTypes.func.isRequired,
};
