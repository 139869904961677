import { getTotal } from './getTotal';
import { getAggregate } from './getAggregate';
import { getAverage } from './getAverage';
import { getMedian } from './getMedian';

export const getClientMetricValues = ({
  clientMetrics,
  isDirectMail,
  isCompetitorView,
  isOrganicView,
  visuals,
}): {} => {
  let clientMetricsValues;

  if (clientMetrics?.length) {
    if (isCompetitorView) {
      clientMetricsValues = Object.assign(
        {},
        ...clientMetrics.map((metric) => ({
          [`${metric}`]: getTotal(visuals, metric),
          [`${metric} per post`]: getTotal(visuals, metric) / visuals.length,
        }))
      );
    } else if (isOrganicView) {
      clientMetricsValues = Object.assign(
        {},
        ...clientMetrics.map((metric) => ({
          [`Average ${metric}`]: getAverage(
            visuals,
            metric,
            clientMetrics,
            isDirectMail,
            isOrganicView
          ).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          [`Total ${metric}`]: getTotal(visuals, metric).toLocaleString(
            'en-US',
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          ),
        }))
      );
    } else {
      clientMetricsValues = Object.assign(
        {},
        ...clientMetrics.map((metric) => ({
          [`Aggregate ${metric}`]: getAggregate(
            visuals,
            metric,
            clientMetrics,
            isDirectMail
          ),
          [`Average ${metric}`]: getAverage(
            visuals,
            metric,
            clientMetrics,
            isDirectMail
          ),
          [`Median ${metric}`]: getMedian(
            visuals,
            metric,
            clientMetrics,
            isDirectMail
          ),
        }))
      );
    }
  }

  return clientMetricsValues;
};
