import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { CloseIcon, ErrorIcon, WarningIcon } from '../../../components/Icons';
import CollapsibleItem from '../../../components/CollapsibleItem/CollapsibleItem';
import CommonElements from './CommonElements/CommonElements';
import { ChevronDownIcon } from '../ReportCard/icons';
import TopSpending from './TopSpending/TopSpending';
import { LoadingIcon } from '../ReportCard/Suggestions/icons';
import Tooltip from '../../../components/Tooltip';
import { RetryButton } from './components/RetryButton';
import Button from '../../../components/Button';
import { useCreativeAnalyticsGet } from '../contexts';
import { getCategories, SimpleImpactReport } from './SimpleImpact';
import { Heatmap } from './Heatmap';
import RecsList from './RecsList/RecsList';
import { Facts } from './Facts';
import UntestedCombinations from './UntestedCombinations';
import { useViewGroupContext } from '../../ViewGroup/context';
import CategoryImportances from './CategoryImportances';
import { useDNAColumnOptions } from '../../DNA/useDNAColumnOptions';
import { useSelector } from 'react-redux';
import { getRows } from '../../DNA/utils';

const Header = ({ close }) => {
  return (
    <div className="recs-header">
      <span className="recs-header__title">Analytics Intelligence</span>
      <div className="d-flex">
        <button className="recs-header__icon btn-reset" onClick={close}>
          <i className="fa fa-xmark" />
        </button>
      </div>
    </div>
  );
};

const LoadingDisplay = () => {
  return (
    <div className="recs__loading loading-text">Getting recommendations</div>
  );
};

const ErrorDisplay = ({ error }) => {
  return (
    <div className="recs__error">
      <ErrorIcon className="mr-2" />
      <h4>There was an error.</h4>
      <p>{error?.error?.toString()}</p>
    </div>
  );
};

const Section = ({ title, loading, onClick }) => {
  return (
    <div className={`recs__section d-flex`} onClick={onClick}>
      <div className={`recs__section-loading`}>
        {loading && <LoadingIcon />}
      </div>
      <div className="d-flex">
        <div className={`recs__section-title`}>{title}</div>
        <ChevronDownIcon />
      </div>
    </div>
  );
};

export const Layout = ({
  recs,
  loading,
  error,
  close,
  fetchRecs,
  commonElementsProps,
  topSpendingProps,
  isROAS,
  fetchSuggestions,
  fetchCommonElements,
  display,
  selectedVisualsChanged,
  selectedVisuals,
  setCommonElementsIsOpen,
  intelData,
  intelLoading,
  intelError,
  intelRequest,
}) => {
  const {
    primary_metric,
    planningMode,
    customEvents,
    clientMetrics,
    filteredAllVisualsLabel,
  } = useCreativeAnalyticsGet();
  const { mainView } = useViewGroupContext();
  let Render = (
    <RecsList
      {...{
        recs,
        fetchRecs,
        loading,
        selectedVisualsChanged,
        selectedVisuals,
      }}
    />
  );

  const dnaColumnOptions = useDNAColumnOptions({
    isROAS,
    customEvents,
    clientMetrics,
  });

  const { dnaReportData } = useSelector(
    ({ CreativeAnalytics: { dnaReportData } }) => {
      return {
        dnaReportData,
      };
    }
  );

  const all_visuals_array = useMemo(() => {
    return filteredAllVisualsLabel?.visuals ?? [];
  }, [filteredAllVisualsLabel]);

  const dnaRows = useMemo(() => {
    return getRows(all_visuals_array, dnaReportData, primary_metric);
  }, [all_visuals_array, dnaReportData, primary_metric]);

  const categorical_columns = useMemo(
    () =>
      getCategories(dnaReportData).filter(
        (col) => !['Additional Attributes', 'Keywords'].includes(col)
      ),
    [dnaReportData]
  );

  const dnaCols = dnaColumnOptions
    .filter(({ label }) =>
      [
        'Visual',
        'Fitness Score',
        'Spend Quintile',
        'Performance Quintile',
        ...categorical_columns,
      ].includes(label)
    )
    .map(({ value }) => value);

  const getLabel = (value) => {
    switch (value) {
      case 'id':
        return 'Visual';
      case 'fitness_score':
        return 'Fitness Score';
      case 'spend_quintile':
        return 'Spend Quintile';
      case 'primary_metric_quintile':
        return 'Performance Quintile';
      default:
        return value;
    }
  };

  const dnaData = {
    dna_table: dnaRows.map((row) => {
      return [...dnaCols, 'asset_url'].reduce((prev, curr) => {
        const label = getLabel(curr);
        return { ...prev, [`${label}`]: row[curr] };
      }, {});
    }),
  };

  const handleIntelRequest = () => {
    if (!intelData && !intelLoading) {
      intelRequest(dnaData);
    }
  };

  if (loading) Render = <LoadingDisplay />;
  if (error) Render = <ErrorDisplay error={error} />;

  const HowToImproveSection = (
    <CollapsibleItem
      AnchorChild={
        <Section
          title={
            'What are my top spending assets and how do I improve upon them?'
          }
          loading={
            topSpendingProps.winnerSuggestionsLoading ||
            topSpendingProps.loserSuggestionsLoading
          }
        />
      }
      ExpandableChild={
        <TopSpending {...{ topSpendingProps, isROAS, fetchSuggestions }} />
      }
      className={null}
    />
  );

  const RecommendationsSection = (
    <CollapsibleItem
      id="test-more-test-less"
      AnchorChild={
        <Section
          title={
            'What should I be testing more of? What should I be testing less of?'
          }
          loading={loading}
        />
      }
      ExpandableChild={Render}
      className={null}
      expandOnLoad={true}
    />
  );

  const CommonElementsSection = (
    <CollapsibleItem
      AnchorChild={
        <Section
          title={
            'What do my top performers have in common? What about my bottom performers?'
          }
          loading={
            commonElementsProps.winnersLoading ||
            commonElementsProps.losersLoading ||
            commonElementsProps.allAssetsLoading
          }
        />
      }
      ExpandableChild={
        <CommonElements {...{ commonElementsProps, fetchCommonElements }} />
      }
      className={null}
      expandOnLoad={false}
      onToggle={(collapsed) => setCommonElementsIsOpen(!collapsed)}
    />
  );

  const SimpleImpactSection = (
    <CollapsibleItem
      AnchorChild={
        <Section
          title={`Which categories have the most impact on my performance?`}
        />
      }
      ExpandableChild={<SimpleImpactReport {...{ primary_metric }} />}
      className={null}
      expandOnLoad={planningMode ? true : false}
    />
  );

  const HeatMapSection = (
    <CollapsibleItem
      AnchorChild={
        <Section title={`What should I be focusing my testing efforts on?`} />
      }
      ExpandableChild={<Heatmap {...{ primary_metric }} />}
      className={null}
      expandOnLoad={false}
    />
  );

  // const FactsSection = (
  //   <CollapsibleItem
  //     AnchorChild={
  //       <Section
  //         title={`What are some insights into my data that I need to know?`}
  //       />
  //     }
  //     ExpandableChild={<Facts />}
  //     className={null}
  //     expandOnLoad={false}
  //   />
  // );

  const UntestedCombinationsSection = (
    <CollapsibleItem
      AnchorChild={
        <Section
          title={`What combinations of elements aren’t in the dataset and how would they perform? (BETA)`}
          onClick={handleIntelRequest}
        />
      }
      ExpandableChild={
        <UntestedCombinations {...{ intelData, intelLoading, intelError }} />
      }
      className={null}
      expandOnLoad={false}
    />
  );

  const CategoryImportancesSection = (
    <CollapsibleItem
      AnchorChild={
        <Section
          title={`What elements are most predictive of performance? (BETA)`}
          onClick={handleIntelRequest}
        />
      }
      ExpandableChild={
        <CategoryImportances {...{ intelData, intelLoading, intelError }} />
      }
      className={null}
      expandOnLoad={false}
    />
  );

  const Sections = planningMode ? (
    <>{SimpleImpactSection}</>
  ) : display === 'dna_table' ? (
    <>
      {RecommendationsSection}
      {/* FactsSection */}
      {UntestedCombinationsSection}
      {CategoryImportancesSection}
    </>
  ) : (
    <>
      {RecommendationsSection}
      {SimpleImpactSection}
      {HeatMapSection}
      {HowToImproveSection}
      {CommonElementsSection}
      {/* FactsSection */}
      {UntestedCombinationsSection}
      {CategoryImportancesSection}
    </>
  );

  return (
    <div className={`recs ${!!mainView ? '' : 'full-height'}`}>
      <div className="recs-wrapper">
        <Header close={close} />
        {Sections}
      </div>
    </div>
  );
};
