import {
  AD_LABELS,
  ADSET_LABELS,
  AD_STUDY,
  KEYWORD,
  COPY,
  TRIGGER,
  AD_NAME,
  ADSET_NAME,
  TESTING,
  CREATIVE_MATRIX,
  THEME,
  COLOR,
  EFFICIENCY,
} from '../../store/CreativeReports/constants';

export const REPORTS_INDEX = {
  [AD_NAME]: {
    report_type: AD_NAME,
    title: 'Ad Performance',
    description:
      'Show spend vs. CPA for individual ads and boost them across audiences',
  },
  [ADSET_NAME]: {
    report_type: ADSET_NAME,
    title: 'Adset Performance',
    description: 'Show spend vs. CPA for individual adsets',
  },
  [AD_LABELS]: {
    report_type: AD_LABELS,
    title: 'Ad Labels Report',
    description:
      'Identify insights using our supervised machine learning technology',
  },

  [ADSET_LABELS]: {
    report_type: ADSET_LABELS,
    title: 'Audience Report',
    description:
      'Identify insights using our supervised machine learning technology',
  },

  [AD_STUDY]: {
    report_type: AD_STUDY,
    title: 'Ad Study',
    description: 'Track study performance over time across ads and adsets',
  },
  [KEYWORD]: {
    report_type: KEYWORD,
    title: 'Keyword Report',
    description:
      'Identify insights using our supervised machine learning technology',
  },
  [COPY]: {
    report_type: COPY,
    title: 'Copy Report',
    description: 'Identify high performing copy across all of your creatives',
  },
  [TRIGGER]: {
    report_type: TRIGGER,
    title: 'Trigger Report',
    description:
      'Identify high performing keyword groups across your creatives',
  },

  [THEME]: {
    report_type: THEME,
    title: 'Theme Report',
    description: 'Identify high performing themes across your creatives',
  },

  [COLOR]: {
    report_type: COLOR,
    title: 'Primary Color Report',
    description: 'Identify high performing colors across your creatives',
  },

  [TESTING]: {
    report_type: TESTING,
    title: 'Best Available',
    description: 'Combination of the best performing elements from other ads',
    exclude: ['snapchat'],
  },

  [CREATIVE_MATRIX]: {
    report_type: CREATIVE_MATRIX,
    title: 'Creative Matrix',
    description: 'Break down audience performance by creative theme',
  },

  [EFFICIENCY]: {
    report_type: EFFICIENCY,
    title: 'Efficiency',
  },
};

export const SCATTER = 'scatter';
export const HISTORY = 'history';
export const TABLE = 'table';
export const LABEL_MATRIX = 'label_matrix';
export const STUDY_FLOW = 'study_flow';
export const LABEL_SETTINGS = 'label_settings';

export const CHART_TYPES = [
  // { label: 'Bar Chart', value: 'histogram' },
  { label: 'Scatter', value: SCATTER },
  // { label: 'Table', value: TABLE },
  // { label: 'History', value: HISTORY },
];
