import { useMemo } from 'react';
import { useCreativeAnalyticsGet } from '../../contexts';

export const useFilteredCategories = (
  categories,
  removeEmptyCategories = false
) => {
  const { reportCardSettings } = useCreativeAnalyticsGet();
  const categorySettings = reportCardSettings?.categorySettings || [];

  const filteredCategories = useMemo(() => {
    let result = [];

    categorySettings.forEach((curr) => {
      const category = curr.category_name;
      const maybe = categories.find((x) => x.category === category);
      if (!maybe) return;
      if (removeEmptyCategories && maybe.labels.length < 1) return;

      const shouldInclude = curr.enabled.report_card;

      if (shouldInclude) {
        result.push(maybe);
      }
    });

    return result;
  }, [categories, removeEmptyCategories, categorySettings]);

  return filteredCategories;
};
