import {
  AdType,
  CategoryDataType,
  DataItem,
  ExperimentItem,
  SortType,
} from './types';

export const sortExperiments = (
  data: ExperimentItem[],
  sort: SortType
): ExperimentItem[] => {
  if (!sort?.value) return [...data];

  return [...data].sort((a, b) => {
    const dateA = new Date(a.adset_created_time).getTime();
    const dateB = new Date(b.adset_created_time).getTime();

    if (isNaN(dateA) || isNaN(dateB)) return 0;

    return sort.order === 'ascending' ? dateA - dateB : dateB - dateA;
  });
};

export const filterAndRankAds = (ads: AdType[], debug): AdType[] => {
  let rankedAds = [];
  let unrankedAds = [];

  for (let i = 0; i < ads.length; i++) {
    const ad = ads[i];
    const hasRank = ad.ad_spend > 0 && !(ad.rank === 'Not Enough Spend');
    if (ad.visual_type && ad.s3_url) {
      if (hasRank) {
        rankedAds.push({
          ...ad,
          rank: rankedAds.length + 1,
        });
      } else {
        unrankedAds.push(ad);
      }
    }
  }

  return [...rankedAds, ...unrankedAds];
};

export const sortAdsByRank = (ads: AdType[] = []): AdType[] => {
  return [...ads].sort((a, b) => {
    if (a === b) return 0;

    if (!a.ad_spend || a.rank === 'Not Enough Spend') {
      return 1;
    }

    if (!b.ad_spend || b.rank === 'Not Enough Spend') {
      return -1;
    }

    return a.rank - b.rank;
  });
};

export const createCategoryLookup = (data: DataItem[]): CategoryDataType => {
  return data.reduce((lookup, item) => {
    const { category_name } = item;

    if (!lookup[category_name]) {
      lookup[category_name] = [];
    }

    lookup[category_name].push(item);
    return lookup;
  }, {});
};
