export const useCustomScoringTipText = (customScoringLabels, metric) => {
  const customScoringText = customScoringLabels.reduce((acc, metric) => {
    if (metric.includes('Average')) {
      return {
        ...acc,
        [metric]: `The ${metric} is the % of times a given ad spends and performs better than its peers in a given adset`,
      };
    }
  }, {});

  return customScoringText;
};

export const useClientMetricsTipText = (clientMetrics) => {
  let clientMetricsText = {};
  if (clientMetrics && clientMetrics.length) {
    clientMetricsText = Object.assign(
      {},
      ...clientMetrics.map((metric) => ({
        [`Aggregate ${metric}`]: `This is the value you are most familiar with. This takes all the spend divided by total number of ${metric} for the category (within the spend threshold and audience selected). This metric will give more weight to creative that spend more money.`,
        [`Average ${metric}`]: `First we take the average ${metric} of each individual creative that meets the spend threshold for the selected audience, and then we take the average of all these values. This metric prevents a few high spending creatives from skewing the dataset.`,
        [`Median ${metric}`]: `First we find the ${metric} of each individual creative, order them from highest to lowest, and then find the middle number. This metric prevents a few high or low spending creatives from skewing the dataset.`,
      }))
    );
  }

  return clientMetricsText;
};

const useMetricsText = (customEvents, multiplier) => {
  return Object.assign(
    {},
    ...customEvents.map((event) => ({
      [`Aggregate ${event.name}`]: `This is the value you are most familiar with. This takes all the ${event.numerator} divided by total number of ${event.denominator} for the category (within the spend threshold and audience selected) and multiplies it by ${multiplier} to calculate the ${event.name} value. This metric will give more weight to creative that spend more money.`,
      [`Average ${event.name}`]: `First we take the average ${event.name} of each individual creative that meets the spend threshold for the selected audience, and then we take the average of all these values. This metric prevents a few high spending creatives from skewing the dataset.`,
      [`Median ${event.name}`]: `First we find the ${event.name} of each individual creative, order them from highest to lowest, and then find the middle number. This metric prevents a few high or low spending creatives from skewing the dataset.`,
    }))
  );
};

export const useCustomEventsTipText = (customEvents, metric) => {
  let customEventsText = {};
  if (customEvents) {
    switch (metric) {
      case 'CPIK':
        customEventsText = useMetricsText(customEvents, '100,000');
        break;
      default:
        customEventsText = useMetricsText(customEvents, '100,000');
        break;
    }
  }

  return customEventsText;
};

export const TIP_TEXT = {
  aggregate_cpa:
    'This is the value you are most familiar with. This takes all of the spend and all of the results for the category (within the spend threshold and audience selected) to calculate the CPA value. Note this metric will give more weight to creatives that spend more money.',
  average_cpa:
    'First we take the average CPA of each individual creative that meets the spend threshold for the selected audience, and then we take the average of all of these values. This metric prevents a few high spending creatives from skewing the dataset.',
  median_cpa:
    'First we find the CPA of each individual creative, order them from highest to lowest, and then find the middle number. This metric prevents a few high or low spending creatives from skewing the dataset.',
  aggregate_roas:
    'This is the value you are most familiar with. This takes all of the spend and all of the results for the category (within the spend threshold and audience selected) to calculate the ROAS value. Note this metric will give more weight to creatives that spend more money.',
  average_roas:
    'First we take the average ROAS of each individual creative that meets the spend threshold for the selected audience, and then we take the average of all of these values. This metric prevents a few high spending creatives from skewing the dataset.',
  median_roas:
    'First we find the ROAS of each individual creative, order them from highest to lowest, and then find the middle number. This metric prevents a few high or low spending creatives from skewing the dataset.',
  aggregate_cpm:
    'This is the value you are most familiar with. This takes all the spend divided by total number of impressions for the category (within the spend threshold and audience selected) and multiplies it by 1000 to calculate the CPM value. This metric will give more weight to creative that spend more money.',
  average_cpm:
    'First we take the average CPM of each individual creative that meets the spend threshold for the selected audience, and then we take the average of all these values. This metric prevents a few high spending creatives from skewing the dataset.',
  median_cpm:
    'First we find the CPM of each individual creative, order them from highest to lowest, and then find the middle number. This metric prevents a few high or low spending creatives from skewing the dataset.',
  aggregate_cpc:
    'This is the value you are most familiar with. This takes all the spend divided by total number of link clicks for the category (within the spend threshold and audience selected) to calculate the CPC value. This metric will give more weight to creative that spend more money.',
  average_cpc:
    'First we take the average CPC of each individual creative that meets the spend threshold for the selected audience, and then we take the average of all these values. This metric prevents a few high spending creatives from skewing the dataset.',
  median_cpc:
    'First we find the CPC of each individual creative, order them from highest to lowest, and then find the middle number. This metric prevents a few high or low spending creatives from skewing the dataset.',
  aggregate_ctr:
    'This is the value you are most familiar with. This takes all the clicks divided by total number of impressions for the category (within the spend threshold and audience selected) and multiplies them by 100 to calculate the CTR value. This metric will give more weight to creative that spend more money.',
  average_ctr:
    'First we take the average CTR of each individual creative that meets the spend threshold for the selected audience, and then we take the average of all these values. This metric prevents a few high spending creatives from skewing the dataset.',
  median_ctr:
    'First we find the CTR of each individual creative, order them from highest to lowest, and then find the middle number. This metric prevents a few high or low spending creatives from skewing the dataset.',
  ga_aggregate_ctr:
    'This is the value you are most familiar with. This takes all the GA sessions divided by total number of impressions for the category (within the spend threshold and audience selected) and multiplies them by 100 to calculate the CTR value. This metric will give more weight to creative that spend more money.',
  ga_average_ctr:
    'First we take the average GA CTR of each individual creative that meets the spend threshold for the selected audience, and then we take the average of all these values. This metric prevents a few high spending creatives from skewing the dataset.',
  ga_median_ctr:
    'First we find the GA CTR of each individual creative, order them from highest to lowest, and then find the middle number. This metric prevents a few high or low spending creatives from skewing the dataset.',
  percent_budget: 'The amount of budget being spent on this category.',
  spend: 'The total spend for this label.',
  p_value:
    'This is the probability that the chances of the “Visuals” dataset occurring for this label is random chance.',
  darwin_index:
    'The “Average Win Rate” for all the advertisers in the Darwin Index. You should use this value to see how your “Win Rate” compares.',
  performance_percent:
    'The calculation made from the “Wins / All” column. Note if you hover over this value you will see the P Value (the probability that the distribution of occurrences is likely due to random chance).',
  wins_over_all:
    'The number of creatives that perform better than average for your downfunnel metric during the selected period and the total number of creatives. This metric helps you understand the probability of finding success in future creative tests.',
  visuals:
    'A visual representation of the creatives running in this category where the green squares denote “Better than average” and the red squares denote “Worse than average”. Note the average is calculated from your dataset using your down funnel metric.',
  total_results: 'The total number of results for this label.',
  percent_of_results: 'Percent of Results',
  percent_of_results_diff: 'Percent of Results Diff',
  total_link_clicks: 'The total number of link clicks for this label.',
  percent_of_link_clicks: 'Percent of Link Clicks',
  percent_of_link_clicks_diff: 'Percent of Link Clicks Diff',
  number_unique_creatives: 'Number of Unique Creatives',
  percent_of_creative_count: 'Percent of the total creative count',
};

export const CUSTOM_SCORING_METRICS = [
  'Adset Relative CPA Percentage',
  'Adset Relative CTR Percentage',
  'Adset Relative CPC Percentage',
  'Adset SOV',
  'Average CPA Score',
  'Average CTR Score',
  'Average CPC Score',
];

export const CUSTOM_SCORING_VALUES_LOOKUP = {
  'Adset Relative CPA Percentage':
    'line_item_cpa_percent_better_than_adset_cpa',
  'Adset Relative CTR Percentage':
    'line_item_ctr_percent_better_than_adset_ctr',
  'Adset Relative CPC Percentage':
    'line_item_cpc_percent_better_than_adset_cpc',
  'Adset SOV': 'adset_share_of_voice',
  'Average CPA Score': 'CPA Score',
  'Average CTR Score': 'CTR Score',
  'Average CPC Score': 'CPC Score',
};
