import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useApiState } from '../Hooks/useApiState';

export const StandardLabelAssetType = PropTypes.shape({
  type: PropTypes.oneOf(['video', 'image']),
  asset: PropTypes.string,
});

export const StandardLabelType = PropTypes.shape({
  description: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  example: StandardLabelAssetType,
});

// Hold labels locally after initial load.
// There should only be 1 request per client
// session.
let cache = [];

async function fetchLabels() {
  if (cache.length) {
    return cache;
  }

  const res = await fetch(
    ['api', 'creative-reports', 'matrix', 'standard-labels'].join('/')
  );

  if (res.status !== 200) {
    throw new Error(`Could not get standard labels, code ${res.status}.`);
  }

  cache = await res.json();

  return cache;
}

function getStatus({ error, loading }) {
  if (error) {
    return 'error';
  }
  if (loading) {
    return 'loading';
  }
  return 'done';
}

export default function useStandardLabelsOnMount() {
  const [state, dispatch] = useApiState(fetchLabels, {
    loading: true,
    error: null,
    data: [],
    count: 0,
  });

  useEffect(() => {
    dispatch();
  }, []);

  useEffect(() => {
    if (state.error) {
      console.error(state.error);
    }
  }, [state.error]);

  return {
    status: getStatus(state),
    standardLabels: state.data,
  };
}
