import React, { useState } from 'react';

export const BrowserTypeSelect = ({ onSelect, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (type) => {
    onSelect(type);
    setIsOpen(false);
  }

  return (
    <span style={{ position: 'relative', cursor: 'pointer' }}>
      <h1
        onClick={() => setIsOpen((prev) => !prev)}
        className="browser-header__title mb-0"
      >
        {title} <i className="fa-solid fa-angle-down"></i>
      </h1>
      {isOpen && (
        <div className="browser-type-select">
          <div onClick={() => handleClick('visuals')}>Visuals</div>
          <div onClick={() => handleClick('categories')}>Categories</div>
        </div>
      )}
    </span>
  );
};
