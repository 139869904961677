
import React from 'react';
import PropTypes from 'prop-types';

import { arraysEqual } from '../../utils';
import { ErrorBoundary, BasicError } from '../Helpers/Error';
import { Placeholder, deriveOptions } from './Helpers';

import { PreviewItemAdName } from './ad-preview-prop-types';

const withApi = (Comp) => {
  class AdPreviewsApiMulti extends React.Component {
    componentDidMount() {
      const { fetchAdPreviews } = this.props;

      fetchAdPreviews();
    }

    componentDidUpdate(prevProps) {
      const { adNames, fetchAdPreviews } = this.props;

      if (
        !arraysEqual(
          (adName1, adName2) => adName1 === adName2,
          prevProps.adNames,
          adNames,
        )
      ) {
        fetchAdPreviews();
      }
    }

    renderPreviews() {
      const {
        hasError,
        isLoading,
        previews,
        fetchAdPreviews,
        ...rest
      } = this.props;

      if (hasError) {
        return <BasicError />;
      } if (isLoading) {
        return <Placeholder />;
      }
      return (
        <>
          {previews
            .filter(({ ad_name }) => !!ad_name)
            .map(({ ad_name, preview_list }, i) => (
              <Comp
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
                // eslint-disable-next-line react/no-array-index-key
                key={`${i}-${ad_name}`}
                adPreviews={deriveOptions(preview_list)}
                adName={ad_name}
              />
            ))}
        </>
      );
    }

    render() {
      return <ErrorBoundary>{this.renderPreviews()}</ErrorBoundary>;
    }
  }

  AdPreviewsApiMulti.defaultProps = {
    hasError: false,
    isLoading: false,
    previews: [],
  };

  AdPreviewsApiMulti.propTypes = {
    adNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    fetchAdPreviews: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    hasError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object]),
    previews: PropTypes.arrayOf(PreviewItemAdName),
  };

  return AdPreviewsApiMulti;
};

export default withApi;
