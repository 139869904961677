import { useRef, useEffect } from 'react';
import { abortableFetch } from '../../../utils';
import {
  useApiState,
  bindFetchToRef,
} from '../../../components/Hooks/useApiState';

const HEADERS = { 'content-type': 'application/json' };

export const saveImpactReport = (payload) => {
  return abortableFetch(
    [
      'api',
      'stats',
      'impact-report',
      payload.view_id,
      'save',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
};

export const deleteImpactReport = (payload) => {
  return abortableFetch(
    [
      'api',
      'stats',
      'impact-report',
      'delete',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
};

const fetchImpactReports = (view_id) => {
  console.log(view_id)
  return abortableFetch(
    [
      'api',
      'stats',
      'impact-report',
      view_id,
      'fetch-impact-reports',
    ].join('/'),
    {
      method: 'GET',
      headers: HEADERS,
      credentials: 'include',
    }
  );
};

export const useImpactReportApi = () => {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) =>
      withRef(saveImpactReport(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );
  const [deleteState, deleteDispatch] = useApiState(
    (payload) =>
      withRef(deleteImpactReport(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: postDispatch,
    },
    delete: {
      ...deleteState,
      request: deleteDispatch,
    },
  };
};

export const useGetImpactReportsApi = () => {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (view_id) =>
      withRef(fetchImpactReports(view_id)).then(
        ({ data }) => data
      ),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    get: {
      ...getState,
      request: getDispatch,
    },
  };
};
