import React from 'react';
import PropTypes from 'prop-types';

export default function BootstrapTable({
  rows,
  headers,
  formatter = null,
  showRowNumber = true,
}) {
  const Headers = (
    <thead className="scroll-thead">
      <tr>
        {showRowNumber && <th scope="col">#</th>}
        {headers.map(({ title }, i) => (
          <th scope="col" key={`${title}-${i}`}>
            {title}
          </th>
        ))}
      </tr>
    </thead>
  );

  const CellText = formatter
    ? (data, key, i) => formatter(data[key], key, i)
    : (data, key) => data[key];

  const Row = ({ rowNum, ...rest }) => (
    <tr className="scroll-tr">
      {showRowNumber && <th scope="row">{rowNum}</th>}
      {headers.map(({ key }, i) => (
        <td key={`${key}-${rowNum}-${i}`}>{CellText(rest, key, rowNum)}</td>
      ))}
    </tr>
  );

  const Body = (
    <tbody className="scroll-tbody">
      {rows.map((row, i) => (
        <Row key={`row-${i + 1}`} {...row} rowNum={i + 1} />
      ))}
    </tbody>
  );

  return (
    <table className="table table-striped table-hover">
      {Headers}
      {Body}
    </table>
  );
}

BootstrapTable.defaultProps = {
  formatter: null,
  showRowNumber: true,
};

BootstrapTable.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, title: PropTypes.string }),
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  formatter: PropTypes.func,
  showRowNumber: PropTypes.bool,
};
