import React, { useState } from 'react';
import { DataPoint } from '../types';
import { toCurrency, toFixed_2, toPercent_2 } from '../../../../utils/numbers';
import Tooltip from '../../../../components/Tooltip';
import { useCreativeRequestSet } from '../contexts';

export const DataPoint = ({ dataPoint, readOnly, currentIndex, isROAS }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  let Comp = SimpleView;

  if (isExpanded) {
    Comp = ExpandedView;
  }

  return (
    <Comp
      dataPoint={dataPoint}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      readOnly={readOnly}
      reqIndex={currentIndex}
      isROAS={isROAS}
    />
  );
};

const NonEditable = () => {
  return;
};

const SimpleView = ({
  dataPoint,
  isExpanded,
  setIsExpanded,
  readOnly,
  reqIndex,
  isROAS,
}) => {
  const handleRemoveDataPoint =
    !!useCreativeRequestSet() && !readOnly
      ? useCreativeRequestSet().handleRemoveDataPoint
      : null;
  const { labelSnapshot, allVisualsSnapshot, opts, viewName }: DataPoint =
    dataPoint;
  const {
    name,
    category,
    aggregate_cpa,
    numerator,
    denominator,
    percent_budget,
    aggregate_roas,
  } = labelSnapshot;
  const { spendThreshold, audience, start_date, end_date, filters } = opts;
  const { aggregate_cpa: overall_cpa, aggregate_roas: overall_roas } = allVisualsSnapshot;
  const cpa = isROAS ? toFixed_2(aggregate_roas) : toCurrency(aggregate_cpa);
  const percent = toPercent_2(percent_budget);
  const wins_over_all = `${numerator}/${denominator}`;

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to remove this data point?')) {
      handleRemoveDataPoint(labelSnapshot, reqIndex);
    }
  };

  const filterTxt = filters.length ? ` Filtered to ${filters}` : '';

  return (
    <div className="data-point simple">
      {!readOnly && (
        <i
          onClick={handleDelete}
          className="data-point__delete fa-solid fa-xmark"
        ></i>
      )}

      <div className="data-point__left">
        <Tooltip content={`This is part of the category ${category}.`}>
          <div>{name}</div>
        </Tooltip>
      </div>
      <div className="data-point__middle">
        <div className="data-point__stats-header">
          <div>{isROAS ? 'ROAS' : 'CPA'}</div>
          <div>Spend</div>
          <div>Wins/All</div>
        </div>
        <div className="data-point__stats">
          <div>{cpa}</div>
          <div>{percent}</div>
          <div>{wins_over_all}</div>
        </div>
      </div>
      <div className="data-point__right">
        <div className="data-point__stats-header">
          <div>{isROAS ? 'Overall ROAS' : 'Overall CPA'}</div>
        </div>
        {isROAS ? toFixed_2(overall_roas) : toCurrency(overall_cpa)}
        <Tooltip
          content={`Data: ${viewName} view for ${audience} audiences. Between ${start_date} and ${end_date} with a ${toCurrency(
            spendThreshold
          )} spend threshold.${filterTxt}`}
        >
          <i
            style={{ color: '#8B9EBC', marginLeft: '0.5rem' }}
            className="fa-solid fa-circle-info"
          ></i>
        </Tooltip>
      </div>
    </div>
  );
};

const ExpandedView = ({ dataPoint, isExpanded, setIsExpanded }) => {
  const { labelSnapshot, allVisualsSnapshot, opts } = dataPoint;

  const handleToggle = () => setIsExpanded((prev) => !prev);

  return <div className="data-point expanded"></div>;
};
