import React from 'react';

const DirectMailIcon = ({ className = "" }) => (
  <svg className={className} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_13_2)">
    <path d="M3.75 10.5C3.15 10.5 2.55 9.975 2.25 9.75C0.75 8.7 0.3 8.325 0 8.1V11.25C0 11.6641 0.335859 12 0.75 12H6.75C7.16414 12 7.5 11.6641 7.5 11.25V8.1C7.2 8.325 6.75 8.7 5.25 9.75C4.95 9.975 4.35 10.5 3.75 10.5ZM6.75 6H0.75C0.335859 6 0 6.33586 0 6.75V7.125C0.6 7.575 0.525 7.575 2.7 9.15C2.925 9.3 3.375 9.75 3.75 9.75C4.125 9.75 4.575 9.3 4.8 9.225C6.975 7.65 6.9 7.65 7.5 7.2V6.75C7.5 6.33586 7.16414 6 6.75 6ZM12.75 3.75H5.25C4.83586 3.75 4.5 4.08586 4.5 4.5V5.25H6.75C7.52836 5.25 8.17008 5.84578 8.2432 6.60516L8.25 6.6V9.75H12.75C13.1641 9.75 13.5 9.41414 13.5 9V4.5C13.5 4.08586 13.1641 3.75 12.75 3.75ZM12 6.75H10.5V5.25H12V6.75ZM3.75 4.5C3.75 3.67289 4.42289 3 5.25 3H10.5V0.75C10.5 0.335859 10.1641 0 9.75 0H2.25C1.83586 0 1.5 0.335859 1.5 0.75V5.25H3.75V4.5Z" fill="#F9405A"/>
  </g>
  <defs>
  <clipPath id="clip0_13_2">
  <rect width="13.5" height="12" fill="white"/>
  </clipPath>
  </defs>
  </svg>
)

export default DirectMailIcon;
