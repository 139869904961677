import { toFixed_2, toPercentRounded } from '../../../../utils/numbers';
import { calculateMetric } from '../../ReportCard/utils';
import {
  useGetAggregate,
  useGetAverage,
  getTotalBudget,
  getPercentBudget,
  getAvgLabelCPA,
  useGetMedian,
  getSpend,
  useGetTotalResults,
  useGetPercentResults,
  useGetPercentResultsDiff,
  useGetTotalLength,
  getTotalLikes,
  getTotal,
} from '../../utils';

export const getStats = ({
  visuals,
  allVisuals,
  isROAS,
  spendThreshold,
  clientMetrics,
  isDirectMail,
  totalBudget,
  viewName,
  filteredData,
  gaEnabled,
  customEvents,
}) => {
  const allVisualsLabel = filteredData.find(
    ({ value }) => value === 'all_visuals'
  );
  const { winners, losers } = allVisualsLabel;
  const isCompetitor =
    isDirectMail && viewName.toLowerCase().includes('competitor');
  const isOrganic = isDirectMail && viewName.toLowerCase().includes('organic');

  const totalResultsAllVisuals = useGetTotalResults(
    allVisuals,
    'results',
    isROAS,
    spendThreshold
  );
  const totalClicksAllVisuals = useGetTotalResults(
    allVisuals,
    'clicks',
    false,
    spendThreshold
  );
  const totalNumberVisuals = useGetTotalLength(allVisuals, spendThreshold);

  let clientMetricsValues = [];
  let customEventsValues = [];

  if (isCompetitor) {
    return [
      {
        metric: 'Total Visuals',
        id: 'total_visuals',
        value: visuals.length,
      },
      {
        metric: '% of Visuals',
        id: 'percent_of_visuals',
        value: toPercentRounded(visuals.length / allVisuals.length),
      },
    ];
  }

  if (clientMetrics && clientMetrics.length && isDirectMail) {
    [...clientMetrics].forEach((metric) => {
      if (isOrganic || isCompetitor) {
        const total = getTotal(visuals, metric);
        clientMetricsValues.push({
          metric: `${metric}`,
          id: `${metric}`,
          value: total,
        });
        clientMetricsValues.push({
          metric: `${metric} per post`,
          id: `${metric}_per_post`,
          value: (total / visuals.length).toFixed(2) || '-',
        });
      } else {
        clientMetricsValues.push({
          metric: `Aggregate ${metric}`,
          id: `aggregate_${metric}`,
          value: useGetAggregate(
            visuals,
            metric,
            clientMetrics,
            isDirectMail
          ).toFixed(2),
        });
        clientMetricsValues.push({
          metric: `Median ${metric}`,
          id: `median_${metric}`,
          value: useGetMedian(
            visuals,
            metric,
            clientMetrics,
            isDirectMail
          ).toFixed(2),
        });
      }
    });
  }

  if (
    customEvents &&
    customEvents.some((x) => x.calculation === 'results_per_click')
  ) {
    customEventsValues.push({
      metric: 'Conversion Rate',
      value: useGetAggregate(visuals, 'conversion_rate'),
    });
    customEventsValues.push({
      metric: 'Median Conversion Rate',
      value: useGetMedian(visuals, 'conversion_rate'),
    });
  }

  if (isOrganic) {
    return [
      ...clientMetricsValues,
      ...customEventsValues,
      {
        metric: 'Number of Posts',
        value: visuals.length,
      },
    ];
  }

  const gaLabels = gaEnabled
    ? [
        {
          metric: 'GA CTR',
          id: 'ga_aggregate_ctr',
          value: useGetAggregate(visuals, 'ga_ctr'),
          format: 'percent',
        },
        {
          metric: 'Median GA CTR',
          id: 'ga_median_ctr',
          value: useGetMedian(visuals, 'ga_ctr'),
          format: 'percent',
        },
      ]
    : [];

  return [
    ...(isROAS
      ? [
          {
            metric: 'ROAS',
            id: 'aggregate_roas',
            value: useGetAggregate(visuals, 'roas'),
            format: 'decimal',
          },
          {
            metric: 'Median ROAS',
            id: 'median_roas',
            value: useGetMedian(visuals, 'roas'),
            format: 'decimal',
          },
        ]
      : [
          {
            metric: 'CPA',
            id: 'aggregate_cpa',
            value: useGetAggregate(visuals, 'cpa'),
            format: 'currency',
          },
          {
            metric: 'Median CPA',
            id: 'median_cpa',
            value: useGetMedian(visuals, 'cpa'),
            format: 'currency',
          },
        ]),

    {
      metric: 'CPM',
      id: 'aggregate_cpm',
      value: useGetAggregate(visuals, 'cpm'),
      format: 'currency',
    },
    {
      metric: 'Median CPM',
      id: 'median_cpm',
      value: useGetMedian(visuals, 'cpm'),
      format: 'currency',
    },
    {
      metric: 'CPC',
      id: 'aggregate_cpc',
      value: useGetAggregate(visuals, 'cpc'),
      format: 'currency',
    },
    {
      metric: 'Median CPC',
      id: 'median_cpc',
      value: useGetMedian(visuals, 'cpc'),
      format: 'currency',
    },
    {
      metric: 'CTR',
      id: 'aggregate_ctr',
      value: useGetAggregate(visuals, 'ctr'),
      format: 'percent',
    },
    {
      metric: 'Median CTR',
      id: 'median_ctr',
      value: useGetMedian(visuals, 'ctr'),
      format: 'percent',
    },
    ...gaLabels,
    {
      metric: 'Total Results',
      id: 'total_results',
      value: useGetTotalResults(visuals, 'results', isROAS),
    },
    {
      metric: '% of Results',
      id: 'percent_of_results',
      value: useGetPercentResults(
        visuals,
        'results',
        totalResultsAllVisuals,
        isROAS
      ),
    },
    {
      metric: '% of Results Difference',
      id: 'percent_of_results_diff',
      value: useGetPercentResultsDiff(
        visuals,
        'results',
        totalResultsAllVisuals,
        totalBudget,
        isROAS
      ),
    },
    {
      metric: 'Total Link Clicks',
      id: 'total_link_clicks',
      value: useGetTotalResults(visuals, 'clicks'),
    },
    {
      metric: '% of Link Clicks',
      id: 'percent_of_link_clicks',
      value: useGetPercentResults(visuals, 'clicks', totalClicksAllVisuals),
    },
    {
      metric: '# of Unique Creatives',
      id: 'number_unique_creatives',
      value: visuals.length,
    },
    {
      metric: '% of Creative Count',
      id: 'percent_of_creative_count',
      value: toPercentRounded(visuals.length / totalNumberVisuals),
    },
    {
      metric: '% Spend',
      id: 'percent_budget',
      value: getPercentBudget(visuals, totalBudget),
    },
    {
      metric: 'Spend',
      id: 'spend',
      value: getSpend(visuals),
      format: 'currency',
    },
    {
      metric: 'Wins/All',
      id: 'wins_over_all',
      value: `${winners.length}/${visuals.length}`,
    },
    {
      metric: 'Win rate',
      id: 'performance_percent',
      value: toPercentRounded(winners.length / visuals.length),
    },
    {
      metric: '% of Link Click Difference',
      id: 'percent_of_link_clicks_diff',
      value: useGetPercentResultsDiff(
        visuals,
        'clicks',
        totalClicksAllVisuals,
        totalBudget
      ),
    },
    ...clientMetricsValues,
    ...customEventsValues,
    ...(isOrganic || isCompetitor
      ? [
          {
            metric: 'Number of Posts',
            value: visuals.length,
          },
        ]
      : []),
  ];
};
