import { connect } from 'react-redux';

const stateToProps = ({
  ViewSettings: {
    viewId,
    viewName,
    account_id,
    selectedAudience,
    insights_by_asset,
    isDirectMail,
    action_name,
    ga_enabled,
    isNonPaidMediaType,
    isUltraLight,
    customIntegratedMetrics,
    permissions,
    isNoom,
    isSkyZone,
  },
  CreativeReports: { only_new_ads, show_request },
  CreativeAnalytics: { rightDrawerDisplay, pfrLoading, showQaEditor },
}) => ({
  view_id: viewId,
  viewName,
  account_id,
  selectedAudience,
  only_new_ads,
  insights_by_asset,
  show_request,
  isDirectMail,
  actionName: action_name,
  gaEnabled: ga_enabled,
  isNonPaidMediaType,
  isUltraLight,
  rightDrawerDisplay,
  customIntegratedMetrics,
  isOrganicView: isDirectMail && viewName.toLowerCase().includes('organic'),
  loading: pfrLoading,
  showQaEditor,
  shouldGroupByAdName: permissions.group_by_ad_name,
  isNoom,
  isSkyZone,
});

export default connect(stateToProps, null);
