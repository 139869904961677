import { useEffect, useRef } from 'react';

export const useTrackDeps = (dependencies, skip=false, key) => {
  const prevDepsRef = useRef({}); 

  useEffect(() => {
    if (skip) return;
    
    const changedDeps = Object.entries(dependencies).reduce(
      (acc, [key, val]) => {
        if (prevDepsRef.current[key] !== val) {
          acc.push(key);
        }
        return acc;
      },
      []
    );

    if (key) console.log(key)

    if (changedDeps.length > 0) {
      console.log('Changed dependencies:', changedDeps);
    }

    prevDepsRef.current = { ...dependencies };
  }, [...Object.values(dependencies)]);
};

export default useTrackDeps;
