import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useCreativeAnalyticsGet } from '../contexts';
import HurdleColumn from './Column';
import { useGetCommonElements } from '../PerformanceFrequency/api';
import { useCategories } from '../hooks';
import { filterLabels } from '../Recommendations/utils';
import { useMetricOptions } from '../hooks/useMetricOptions';
import { createShareUrl } from './utils/createShareUrl';
import { getCommonElementsNames } from '../../DNA/utils';
import Tooltip from '../../../components/Tooltip';
import { useViewId } from '../selectors';
import HurdleHeader from './Header';

const Wrapper = () => {
  const {
    allVisualsList,
    filteredAllVisualsLabel,
    spendThreshold,
    reportCardSettings: { categorySettings },
    filteredData,
    primary_metric,
    isROAS,
  } = useCreativeAnalyticsGet();
  const visualsBelowThreshold = allVisualsList.filter(
    (visual) => visual.spend < parseInt(spendThreshold)
  );
  const visualsAboveAverage = filteredAllVisualsLabel.winners;
  const visualsBelowAverage = filteredAllVisualsLabel.losers;
  const [belowThresholdElements, setBelowThresholdElements] = useState([]);
  const [aboveAverageElements, setAboveAverageElements] = useState([]);
  const [belowAverageElements, setBelowAverageElements] = useState([]);
  const [hiddenElements, setHiddenElements] = useState([]);
  const [copied, setCopied] = useState(false);
  const [copiedText, setCopiedText] = useState('');
  const metricOptions = useMetricOptions();
  const primaryMetricLabel =
    metricOptions.find((x) => x.value === primary_metric)?.label ||
    primary_metric;
  const ref = useRef();
  const viewId = useViewId();

  const {
    post: {
      data: belowThresholdData,
      request: belowThresholdRequest,
      loading: belowThresholdLoading,
      error: belowThresholdError,
    },
  } = useGetCommonElements();
  const {
    post: {
      data: belowAverageData,
      request: belowAverageRequest,
      loading: belowAverageLoading,
      error: belowAverageError,
    },
  } = useGetCommonElements();
  const {
    post: {
      data: aboveAverageData,
      request: aboveAverageRequest,
      loading: aboveAverageLoading,
      error: aboveAverageError,
    },
  } = useGetCommonElements();

  useEffect(() => {
    if (allVisualsList.length) {
      belowThresholdRequest(visualsBelowThreshold.map(({ id }) => id));
      belowAverageRequest(visualsBelowAverage.map(({ id }) => id));
      aboveAverageRequest(visualsAboveAverage.map(({ id }) => id));
    }
  }, [allVisualsList, filteredAllVisualsLabel]);

  useEffect(() => {
    if (belowThresholdData?.data) {
      if (Array.isArray(JSON.parse(belowThresholdData.data.body))) {
        setBelowThresholdElements(
          getCommonElementsNames(
            JSON.parse(belowThresholdData.data?.body),
            filteredData,
            categorySettings
          )
        );
      }
    }
    if (belowAverageData?.data) {
      if (Array.isArray(JSON.parse(belowAverageData.data.body))) {
        setBelowAverageElements(
          getCommonElementsNames(
            JSON.parse(belowAverageData.data?.body),
            filteredData,
            categorySettings
          )
        );
      }
    }
    if (aboveAverageData?.data) {
      if (Array.isArray(JSON.parse(aboveAverageData.data.body))) {
        setAboveAverageElements(
          getCommonElementsNames(
            JSON.parse(aboveAverageData.data?.body),
            filteredData,
            categorySettings
          )
        );
      }
    }
  }, [belowThresholdData, belowAverageData, aboveAverageData]);

  const commonElementsLoading =
    belowThresholdLoading || belowAverageLoading || aboveAverageLoading;

  const columnProps = {
    filteredData,
    hiddenElements,
    setHiddenElements,
    commonElementsLoading,
    primary_metric,
    primaryMetricLabel,
    isROAS,
  };

  const handleFocus = (e) => {
    e.target.select();
    // execCommand is deprecated; only used for the workaround
    document.execCommand('copy');
  };

  useEffect(() => {
    ref.current?.focus();
  }, [copiedText]);

  const copyTextToClipboard = (text) => {
    console.log(text, text.length);
    // navigator.clipboard only exists on localhost or https (does not work in dev environment)
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(
        function () {
          console.log('Async: Copying to clipboard was successful!');
          setCopied(true);

          setTimeout(() => {
            setCopied(false);
          }, 3000);
        },
        function (err) {
          console.error('Async: Could not copy text: ', err);
        }
      );
    } else {
      setCopiedText(text);
      console.log(ref.current?.value);
      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  };

  const sortVisuals = (visuals) => {
    return [...visuals].sort((a, b) => {
      if (
        ['ctr', 'impressions', 'results', 'clicks', 'roas'].includes(primary_metric)
      ) {
        return b[primary_metric] - a[primary_metric];
      }
      if (primary_metric === 'cpa') {
        return (
          (a.cpa === 0) - (b.cpa === 0) || -(a.cpa < b.cpa) || +(a.cpa > b.cpa)
        );
      }
      return a[primary_metric] - b[primary_metric];
    });
  };

  const handleShareReport = () => {
    copyTextToClipboard(
      createShareUrl(
        sortVisuals(visualsBelowThreshold).slice(0, 10),
        sortVisuals(visualsBelowAverage).slice(0, 10),
        sortVisuals(visualsAboveAverage).slice(0, 10),
        belowThresholdElements,
        belowAverageElements,
        aboveAverageElements,
        spendThreshold,
        primary_metric,
        primaryMetricLabel,
        viewId,
        isROAS
      )
    );
  };

  return (
    <div className="hurdle">
      <HurdleHeader {...{ copied, handleShareReport, allVisualsList }} />
      <div className="columns-container">
        <HurdleColumn
          visuals={sortVisuals(visualsBelowThreshold)}
          description={`Didn't spend more than $${spendThreshold}...`}
          commonElementsData={belowThresholdElements}
          error={belowThresholdError}
          isSharedHurdleReport={false}
          {...columnProps}
        />
        <HurdleColumn
          visuals={sortVisuals(visualsBelowAverage)}
          description={
            <span>
              Spent budget but performed <span className="below">below</span>{' '}
              average.
            </span>
          }
          commonElementsData={belowAverageElements}
          error={belowAverageError}
          isSharedHurdleReport={false}
          {...columnProps}
        />
        <HurdleColumn
          visuals={sortVisuals(visualsAboveAverage)}
          description={
            <span>
              Spent budget and performed <span className="above">above</span>{' '}
              average.
            </span>
          }
          commonElementsData={aboveAverageElements}
          error={aboveAverageError}
          isSharedHurdleReport={false}
          {...columnProps}
        />
      </div>
      <div className="clipboard-workaround">
        <input
          ref={ref}
          type="text"
          value={copiedText}
          style={{ position: 'absolute', left: '-5000px' }}
          onFocus={handleFocus}
        />
      </div>
    </div>
  );
};

export default Wrapper;
