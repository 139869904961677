import { MULTIPLE_LABELS, MUTUALLY_EXCLUSIVE } from '../constants';

export const filterBySelectedLabel = (
  selectedLabel='',
  annotatedVisuals,
  predictedVisuals,
  categoryType
) => {
  const lastIndex = selectedLabel.lastIndexOf(' - ');
  const selected = [
    selectedLabel.substring(0, lastIndex),
    selectedLabel.substring(lastIndex + 2).trim(),
  ];
  try {
    const all = { ...annotatedVisuals, ...predictedVisuals };

    return Object.entries(all).reduce((acc, visual) => {
      const labels = visual[1].asset.map((a) => a.labels || a.labelName);

      if (selectedLabel) {
        if (
          labels.length &&
          labels.some((l) => {
            if (l && categoryType === MULTIPLE_LABELS) {
              return Object.entries(l).some((k) => {
                return (
                  k[0] === selected[0] &&
                  (k[1] === selected[1].toLowerCase() ||
                    k[1].status === selected[1].toLowerCase())
                );
              });
            } else if (l && categoryType === MUTUALLY_EXCLUSIVE) {
              return l === selectedLabel;
            }
          })
        ) {
          acc.push(visual[0]);
        }
      }

      return acc;
    }, []);
  } catch (e) {
    console.error(e);
    return [];
  }
};
