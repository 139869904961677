//@ts-check
import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import Modal from "../../../components/BootstrapModal";

const TYPE_OPTIONS = [
  { value: "audience", label: "Audience" },
  { value: "creative", label: "Creative" }
];

const DEFAULT_STATE = {
  studyName: "",
  description: "",
  studyType: TYPE_OPTIONS[0],
  adString: "",
  adsetString: ""
};

const REQUIRED = ["studyName", "description"];
const OPTIONAL = ["adString", "adsetString"];

class StudyModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { ...DEFAULT_STATE };

    this.Body = this.Body.bind(this);
    this.Footer = this.Footer.bind(this);
    this.handleText = this.handleText.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.notEmpty = f => this.state[f] !== "";
  }

  componentWillUnmount() {
    this.Body = null;
    this.Footer = null;
    this.handleText = null;
    this.handleSubmit = null;
    this.notEmpty = null;
  }

  handleText(e) {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  }

  async handleSubmit(e) {
    const {
      studyType,
      studyName,
      description,
      adsetString,
      adString
    } = this.state;
    // Hide first to prevent memory leak.
    this.props.handleHide();

    await this.props.fetchStudy({
      viewId: this.props.viewId,
      studyType: studyType.value,
      studyName,
      description,
      adsetString,
      adString
    });
  }

  isFormValid() {
    return REQUIRED.every(this.notEmpty) && OPTIONAL.some(this.notEmpty);
  }

  Body() {
    const {
      studyName,
      studyType,
      description,
      adString,
      adsetString
    } = this.state;

    return (
      <form action="">
        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right">
            Study Name
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              name="studyName"
              className="form-control"
              value={studyName}
              onChange={this.handleText}
              required
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right">
            Description
          </label>
          <div className="col-sm-10">
            <textarea
              name="description"
              className="form-control"
              value={description}
              onChange={this.handleText}
              required
            ></textarea>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right">
            Study Type
          </label>
          <div className="col-sm-10">
            <Select
              value={studyType}
              onChange={studyType => this.setState({ studyType })}
              options={TYPE_OPTIONS}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right">
            Ad String
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              name="adString"
              className="form-control"
              value={adString}
              onChange={this.handleText}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right">
            Adset String
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              name="adsetString"
              className="form-control"
              value={adsetString}
              onChange={this.handleText}
            />
          </div>
        </div>
      </form>
    );
  }

  Footer() {
    const disabled = !this.isFormValid();

    return (
      <>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={this.props.handleHide}
        >
          Cancel
        </button>
        <button
          type="button"
          className={`btn btn-${disabled ? "secondary" : "primary"}`}
          disabled={disabled}
          onClick={this.handleSubmit}
        >
          Create
        </button>{" "}
      </>
    );
  }

  render() {
    const { id, show, handleHide } = this.props;
    const { Body, Footer } = this;

    return (
      <Modal
        id={id}
        handleHide={handleHide}
        labelledby="boost-modal-title"
        show={show}
        Title={
          <div className="d-flex flex-column">
            <h5 className="font-weight-bold">Create A New Study</h5>
            <p className="mb-0">
              Create a new study based on Ad or Adset name details
            </p>
          </div>
        }
        Body={<Body />}
        Footer={<Footer />}
      />
    );
  }
}

StudyModal.propTypes = {
  id: PropTypes.string.isRequired,
  show: PropTypes.bool,
  handleHide: PropTypes.func.isRequired,
  fetchStudy: PropTypes.func.isRequired
};

export default StudyModal;
