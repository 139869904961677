import React, { useEffect, useMemo, useRef, useState } from 'react';
import Button from '../../components/Button';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useCreativeAnalyticsStore } from '../../selectors';
import clsx from 'clsx';
import { Tag, TagsList } from '../../components/Tags';
import LazyLoader from '../../components/LazyLoader';
import { SelectMenu, SelectMenuWrapper } from '../../components/SelectMenu';
import { useClickOutside } from '../../hooks/useClickOutside';
import { useInView } from 'react-intersection-observer';

const getItemStyle = ({ isDragging, isDropAnimating }, draggableStyle) => ({
  ...draggableStyle,
  userSelect: 'none',

  background: isDragging ? 'lightgreen' : 'grey',
  color: 'green',

  ...(!isDragging && { transform: 'translate(0,0)' }),
  ...(isDropAnimating && { transitionDuration: '0.001s' }),
});

const Table = ({
  tableInstance,
  manualTags = [],
  QuickSelect,
  ColumnPicker,
  TrendData,
  CustomScoringData,
  groupParams,
  selectAllVisuals,
  hasTrendData,
}) => {
  const { selectedVisuals } = useCreativeAnalyticsStore();
  const [enableFilters, setEnableFilters] = useState(true);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows: r,
    prepareRow,
    exportData,
    setColumnOrder,
    setFilter,
    visibleColumns,
    state: { filters, selectedRowIds },
  } = tableInstance;

  const rows = useMemo(() => {
    return r.filter((row) => {
      return row.original.period_type !== 'previous';
    });
  }, [r]);

  useEffect(() => {
    if (!groupParams.selectedVisuals.filter((id) => id).length) {
      selectAllVisuals();
    }
  }, []);

  const currentColOrder = React.useRef();

  const handleFilterChange = (columnId, value) => {
    setFilter(columnId, value.length > 0 ? value : null, { exactMatch: true });
  };

  const columnOptions = {};

  headerGroups.forEach((headerGroup) => {
    headerGroup.headers.forEach((column) => {
      if (column.canFilter) {
        let id = column.id;

        const columnValues = column.preFilteredRows.reduce((acc, row) => {
          if (column.Header === 'Visual') {
            acc.push(
              row.original._id
              // row.original.ad_names?.length > 1
              //   ? row.original._id
              //   : row.original.ad_names?.[0]
            );
            return acc;
          }

          if (column.Header === 'Manual Tags') {
            manualTags.forEach((tag) => acc.push(tag));
            return acc;
          }

          acc.push(row.values[id]);
          return acc;
        }, []);
        columnOptions[column.id] = [...new Set(columnValues)].filter(Boolean);
      }
    });
  });

  const validFilters = filters?.filter(({ value }) => !!value) ?? [];
  const hasFilters = validFilters.length > 0;

  const [exportMenuIsOpen, setExportMenuIsOpen] = useState(false);

  return (
    <div>
      <div className="d-flex align-items-center mb-3 justify-content-between">
        <div className="d-flex align-items-center">
          {QuickSelect}
          {TrendData}
          {CustomScoringData}
          {ColumnPicker}

          <SelectMenuWrapper>
            <Button
              appearance="raised"
              className="mr-2"
              onClick={() =>
                hasTrendData
                  ? setExportMenuIsOpen((prev) => !prev)
                  : exportData('csv', false)
              }
            >
              Export CSV
            </Button>
            <SelectMenu
              isOpen={exportMenuIsOpen}
              setIsOpen={setExportMenuIsOpen}
              options={[
                {
                  title: 'Export Current Period',
                  value: 'current',
                },
                {
                  title: 'Export Current and Previous Period',
                  value: 'current_and_prev',
                },
              ]}
              onSelect={(value) =>
                exportData('csv', false, value === 'current_and_prev')
              }
            />
          </SelectMenuWrapper>
          <Button
            onClick={() => setEnableFilters((prev) => !prev)}
            appearance="raised"
            active={enableFilters}
          >
            <i
              style={{ fontSize: '1rem' }}
              className="fa-solid fa-filter mr-2"
            />
            Filters
            {hasFilters ? <span>&nbsp;({validFilters.length})</span> : null}
          </Button>
          <p className="mb-0 ml-3">
            {rows.length} visual{rows.length === 1 ? '' : 's'}
          </p>
          {/* {!!SortText && SortText} */}
        </div>
        {hasFilters && enableFilters && (
          <div
            className="d-flex align-items-start"
            style={{ maxWidth: '50%', overflow: 'auto' }}
          >
            <span className="" style={{ transform: 'translateY(3px)' }}>
              Filters:&nbsp;
            </span>
            <TagsList>
              {validFilters.map(({ id, value }) => {
                if (!value?.length) return null;
                return (
                  <Tag
                    onClick={() => handleFilterChange(id, '')}
                    className="mb-2 mr-2"
                    tooltip={value}
                  >
                    {value}
                  </Tag>
                );
              })}
            </TagsList>
          </div>
        )}
      </div>

      <table
        {...getTableProps()}
        className="dna-table table table-responsive mt-4"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <DragDropContext
              onDragStart={() => {
                currentColOrder.current = visibleColumns.map((o) => o.id);
              }}
              onDragUpdate={(dragUpdateObj, b) => {
                const colOrder = [...currentColOrder.current];
                const sIndex = dragUpdateObj.source.index;
                const dIndex =
                  dragUpdateObj.destination && dragUpdateObj.destination.index;

                if (dIndex === 0) return;

                if (typeof sIndex === 'number' && typeof dIndex === 'number') {
                  colOrder.splice(sIndex, 1);
                  colOrder.splice(dIndex, 0, dragUpdateObj.draggableId);
                  setColumnOrder(colOrder);
                }
              }}
            >
              <Droppable droppableId="droppable" direction="horizontal">
                {(droppableProvided, snapshot) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    ref={droppableProvided.innerRef}
                  >
                    {headerGroup.headers.map((column, index) => {
                      return (
                        <Draggable
                          key={column.id}
                          draggableId={column.id}
                          index={index}
                          isDragDisabled={
                            !column.accessor ||
                            column.disableColumOrder ||
                            index === 0
                          }
                        >
                          {(provided, snapshot) => {
                            return (
                              <th
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                // {...extraProps}
                                ref={provided.innerRef}
                                className="dna-table__headercell "
                                title={column.Header}
                                style={{
                                  ...getItemStyle(
                                    snapshot,
                                    provided.draggableProps.style
                                  ),
                                  // ...style
                                }}
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    position: 'relative',
                                    overflowWrap: 'break-word',
                                    textAlign: 'center',
                                  }}
                                >
                                  <span>
                                    {column.render('Header')}
                                    {column.isSorted
                                      ? column.isSortedDesc
                                        ? ' 🔽'
                                        : ' 🔼'
                                      : ''}
                                  </span>
                                  {column.canFilter && enableFilters && (
                                    <select
                                      className="mt-2"
                                      onClick={(e) => e.stopPropagation()}
                                      value={
                                        filters.find(
                                          (filter) => filter.id === column.id
                                        )?.value || ''
                                      }
                                      onChange={(e) =>
                                        handleFilterChange(
                                          column.id,
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value={''}>All</option>
                                      {columnOptions[column.id].map(
                                        (option, index) => (
                                          <option key={index} value={option}>
                                            {option}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  )}
                                </div>
                              </th>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                  </tr>
                )}
              </Droppable>
            </DragDropContext>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return <Row {...{ row, selectedVisuals }} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

const Row = ({ row, selectedVisuals }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <LazyLoader>
      <tr
        ref={ref}
        className={clsx('dna-table__row', {
          selected: selectedVisuals.includes(row.original.id),
        })}
        {...row.getRowProps()}
      >
        {inView
          ? row.cells.map((cell) => {
              return (
                <td className={`dna-table__cell`} {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </td>
              );
            })
          : null}
      </tr>
    </LazyLoader>
  );
};

export default Table;
