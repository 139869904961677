import { useState, useEffect } from 'react';

export const useNewAds = (newAdsApi) => {
  const [newAds, setNewAds] = useState([]);
  const [onlyNewAds, setOnlyNewAds] = useState(false);

  useEffect(() => {
    if (newAdsApi.get.data) {
      const newAdsData = newAdsApi.get.data;
      setNewAds(newAdsData);
    }
  }, [newAdsApi.get.data]);

  return {
    newAds, setNewAds, onlyNewAds, setOnlyNewAds
  }
}