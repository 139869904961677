import { connect } from 'react-redux';
import {
  fetchSendReset,
  SEND_RESET,
} from '../../store/background/api.send-reset-password';

const stateToProps = ({ Loaders, Errors, ViewSettings: {} }, props) => ({
  isLoading: Loaders[SEND_RESET],
  hasError: Errors[SEND_RESET],
});

export default connect(stateToProps, { fetchSendReset });
