import React from 'react';
import { Redirect } from 'react-router-dom';

import connectTo from './Container';
import Signup from './Signup';
import Boundary from './SignupBoundary';

const Comp = ({ fetchRegister, isLoading, hasError, viewId, loggedIn }) => (
  <Boundary isLoading={isLoading} hasError={hasError}>
    <Signup fetchRegister={fetchRegister} />
  </Boundary>
);

export default connectTo(Comp);
