import React from 'react';
import { toCurrency, toFixed_2, toInt } from '../../../utils/numbers';
import { customSortFn } from '../../DNA/columns';

export const useCopyReportColumns = () => {
  return [
    {
      Header: 'Name',
      accessor: 'breakdown_value',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'CPA',
      accessor: 'cpa',
      disableFilters: true,
      sortType: customSortFn(`cpa`),
      Cell: ({
        row: {
          original: { cpa },
        },
      }) => {
        return <p>{cpa > 0 ? toCurrency(cpa) : 'N/A'}</p>;
      },
    },
    {
      Header: 'ROAS',
      accessor: 'roas',
      disableFilters: true,
      Cell: ({
        row: {
          original: { roas },
        },
      }) => {
        return <p>{toFixed_2(roas)}</p>;
      },
    },
    {
      Header: 'Impressions',
      accessor: 'total_impressions',
      disableFilters: true,
      Cell: ({
        row: {
          original: { total_impressions },
        },
      }) => {
        return <p>{toInt(total_impressions)}</p>;
      },
    },
    {
      Header: 'Spend',
      accessor: 'total_spend',
      disableFilters: true,
      Cell: ({
        row: {
          original: { total_spend },
        },
      }) => {
        return <p>{toCurrency(total_spend)}</p>;
      },
    },
    {
      Header: 'CPM',
      accessor: 'cpm',
      disableFilters: true,
      Cell: ({
        row: {
          original: { cpm },
        },
      }) => {
        return <p>{toCurrency(cpm)}</p>;
      },
    },
    {
      Header: 'CPC',
      accessor: 'cpc',
      disableFilters: true,
      Cell: ({
        row: {
          original: { cpc },
        },
      }) => {
        return <p>{toCurrency(cpc)}</p>;
      },
    },
    {
      Header: 'CTR',
      accessor: 'ctr',
      disableFilters: true,
      Cell: ({
        row: {
          original: { ctr },
        },
      }) => {
        return <p>{toFixed_2(ctr)}</p>;
      },
    },
    {
      Header: 'Total Link Clicks',
      accessor: 'total_link_clicks',
      disableFilters: true,
      Cell: ({
        row: {
          original: { total_link_clicks },
        },
      }) => {
        return <p>{toInt(total_link_clicks)}</p>;
      },
    },
    {
      Header: 'Results',
      accessor: 'total_results',
      disableFilters: true,
      Cell: ({
        row: {
          original: { total_results },
        },
      }) => {
        return <p>{toInt(total_results)}</p>;
      },
    },
    {
      Header: '# Unique Ad Names',
      accessor: 'ad_name',
      disableFilters: true,
      Cell: ({
        row: {
          original: { ad_name },
        },
      }) => {
        return <p>{toInt(ad_name)}</p>;
      },
    },
    {
      Header: '# Unique Ad Ids',
      accessor: 'ad_id',
      disableFilters: true,
      Cell: ({
        row: {
          original: { ad_id },
        },
      }) => {
        return <p>{toInt(ad_id)}</p>;
      },
    },
  ];
};
