import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { useEffect } from 'react';
import { useFetchPFRData } from '../../features/CreativeAnalytics/hooks/useFetchPFRData';
import { END, START_30, toDarwinDate } from '../../utils/darwin-dates';
import { METRICS_DATA } from './constants';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from '../Loaders/actions';
import { setError } from '../Errors/actions';
import { setMetricsData } from './creativeAnalyticsSlice';
import {
  useAnalyticsParams,
  useDatePeriods,
} from '../../features/CreativeAnalytics/hooks';
import { useSearchParams } from '../../components/Hooks/search-params';

const HEADERS = { 'content-type': 'application/json' };

export const analyticsApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: '/api' }),
  serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
    const { view_id, all_visuals_array } = queryArgs;
    // This can return a string, an object, a number, or a boolean.
    // If it returns an object, number or boolean, that value
    // will be serialized automatically via `defaultSerializeQueryArgs`
    return {
      view_id,
      ids: all_visuals_array.map(({ visual_id }) => visual_id),
    }; // omit `client` from the cache key

    // Alternately, you can use `defaultSerializeQueryArgs` yourself:
    // return defaultSerializeQueryArgs({
    //   endpointName,
    //   queryArgs: { id },
    //   endpointDefinition
    // })
    // Or  create and return a string yourself:
    // return `getPost(${id})`
  },
  endpoints: (builder) => ({
    getDnaReport: builder.query<
      {},
      { view_id: string; client_id: string; all_visuals_array: [] }
    >({
      query: (payload) => ({
        url: ['creative-analytics', 'get-dna-report'].join('/'),
        method: 'POST',
        body: JSON.stringify(payload),
        headers: HEADERS,
      }),
    }),
    getAdNameLabels: builder.query<{}, { account_id: string }>({
      query: (account_id) => ({
        url: [
          'api',
          'creative-analytics',
          'custom-categories',
          account_id,
          'fetch-ad-name-labels',
        ].join('/'),
        method: 'GET',
        headers: HEADERS,
      }),
    }),
  }),
});

export const { useLazyGetDnaReportQuery, useGetAdNameLabelsQuery } =
  analyticsApi;

export const resetApiState = () => {
  return analyticsApi.util.resetApiState();
};

export const useAnalyticsFetch = () => {
  const { getParams } = useSearchParams();
  const dispatch = useDispatch();
  const {
    viewId: view_id,
    start_date,
    end_date,
  } = useSelector(({ ViewSettings }) => ViewSettings);
  const { period1, period2, refresh } =
    getParams('period1', 'period2', 'refresh') || {};
  const [period1Start, period1End, period2Start, period2End] = useDatePeriods(
    period1,
    period2
  );

  const { data, loading, error, request } = useFetchPFRData({
    start_date: period1Start,
    end_date: period1End,
    metrics_only: true,
    autoFetch: false,
  });

  const fetchMetricsData = () => {
    request({
      view_id,
      start_date: period1Start,
      end_date: period1End,
      metrics_only: true,
      active_only: false,
    });
  };

  useEffect(() => {
    if (refresh === 'true') {
      fetchMetricsData();
    }
  }, [period1Start, period1End, view_id, refresh]);

  useEffect(() => {
    dispatch(setLoader(METRICS_DATA, loading));
  }, [loading]);

  useEffect(() => {
    if (data && data.length) {
      dispatch(setMetricsData(data));
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      dispatch(setError(METRICS_DATA, error));
    } else {
      dispatch(setError(METRICS_DATA, false));
    }
  }, [error]);

  // console.log({
  //   standardLabelsApi,
  //   newAdsApi,
  //   derivedLabelsApi,
  //   udcApi,
  //   adNameLabelsApi,
  //   keywordsApi,
  //   phraseThemeDataApi,
  //   peformanceDataApi,
  // });

  // return {
  //   fetchPerformanceData,
  //   fetchCompareData,
  //   isLoading:
  //     standardLabelsApi.isLoading ||
  //     newAdsApi.isLoading ||
  //     derivedLabelsApi.isLoading ||
  //     udcApi.isLoading ||
  //     adNameLabelsApi.isLoading ||
  //     keywordsApi.isLoading ||
  //     phraseThemeDataApi.isLoading ||
  //     peformanceDataApi.isLoading ||
  //     compareDataApi.isLoading,
  // };
};

export const useApiResponseHandler = (api, onSuccess, onError) => {
  useEffect(() => {
    if (api.data) {
      onSuccess(api.data.data ?? api.data);
    }
  }, [api.data]);

  useEffect(() => {
    if (api.error) {
      console.error(api.error);

      if (onError) {
        onError(api.error);
      }
    }
  }, [api.error]);
};
