export const ACCEPT = 'image/*, video/*';

export const PREDICTIONS = 'predictions';
export const FEEDBACK = 'feedback';
export const ATTRIBUTES = 'attributes';

export const MARKETPLACE = 'marketplace';

export const ASSET_FILTER_CATEGORIES = ['approval', 'type'];

export const ASSET_FILTERS = [
  {
    name: 'Client Approved',
    value: 'client_approved',
    category: 'approval',
    checked: false,
  },
  {
    name: 'Darwin Approved',
    value: 'darwin_approved',
    category: 'approval',
    checked: false,
  },
  {
    name: 'Not Approved',
    value: 'not_approved',
    category: 'approval',
    checked: false,
  },
  {
    name: 'Static',
    value: 'static_asset',
    category: 'type',
    checked: false,
  },
  {
    name: 'Video',
    value: 'video_asset',
    category: 'type',
    checked: false,
  },
];

export const FILTER_TAG_CLASSES = [
  'filter-tags__header',
  'filter-tags__body',
  'noselect-all',
  'filter-tags__heading',
  'filter-tags',
  'filter-tags__list',
  'filter-tags__item',
  'close-icon',
  'filter-tags__tag-name',
  'filter-tags__select',
  'tags-icon',
  'tags-icon-path',
];
