import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { SELECT_STYLES } from '../../../constants';
import Button from '../../../../../components/Button';
import useTrackDeps from '../../../../../hooks/useTrackDeps';

type SelectOption = { label: string; value: string };

export const OverridesSelect = ({
  visual_id,
  current,
  options,
  handleSaveOverride,
  loading,
  onOverrideChange,
  onOverrideSave,
  onOverrideCancel,
  hasUnsavedChanges,
}) => {
  const [selected, setSelected] = useState(current);

  useEffect(() => {
    setSelected(current);
  }, [current]);

  const handleSelect = (selection: SelectOption[]) => {
    setSelected(selection);
    if (onOverrideChange) {
      onOverrideChange(visual_id);
    }
  };

  const handleSave = () => {
    handleSaveOverride({
      label: selected?.value,
      visual: visual_id,
    });
    if (onOverrideSave) {
      onOverrideSave(visual_id);
    }
  };

  const handleCancel = () => {
    setSelected(current);
    if (onOverrideCancel) {
      onOverrideCancel(visual_id);
    }
  };

  return (
    <div className="d-flex" style={{ flexWrap: 'wrap', gap: '0.5rem' }}>
      <span className="lbl-display">
        {hasUnsavedChanges && selected?.value !== current?.value
          ? (
              <>
                {current?.value || ''} -&gt; <span className="lbl-display has-changed">{selected?.value || ''}</span>
              </>
            )
          : selected?.value}
      </span>
      <Select
        isClearable
        options={options}
        styles={SELECT_STYLES}
        placeholder="Select label"
        value={selected || null}
        isSearchable
        onChange={handleSelect}
        title={selected?.value}
        // onCreateOption={handleCreate}
      />
      <Button onClick={handleSave} disabled={loading} loading={loading}>
        Save
      </Button>
      {hasUnsavedChanges && (
        <Button onClick={handleCancel} appearance="subtle" loading={false}>
          Cancel
        </Button>
      )}
    </div>
  );
};
