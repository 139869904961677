import React, { useState } from 'react';
import './ActionButtons.scss';

const ActionButtons = ({ children, ActionButtonsPortal, show = true }) => {
  if (!show) return null;
  const [expandMenu, setExpandMenu] = useState(false);
  let shown = 0;

  const Options = React.Children.map(children, (child) => {
    const shouldShow = child?.props?.show;
    const className = child?.props?.className || '';
    const label = child?.props?.label || null;

    if (shouldShow) shown += 1;

    return (
      <div
        onClick={child?.props?.onClick || null}
        style={{ display: shouldShow ? 'flex' : 'none' }}
        className={`action-button .slide-in-fwd-right ${className}`}
      >
        {child}
        {label && <div className="action-button__label">{label}</div>}
      </div>
    );
  });

  let Render = Options;

  if (shown > 1) {
    Render = (
      <>
        <MenuToggle
          {...{ expandMenu }}
          onClick={() => setExpandMenu((prev) => !prev)}
        />
        {expandMenu && Options}
      </>
    );
  }

  return (
    <ActionButtonsPortal.Portal>
      <div className="action-buttons">{Render}</div>
    </ActionButtonsPortal.Portal>
  );
};

export default ActionButtons;

const MenuToggle = ({ onClick, expandMenu }) => {
  return (
    <div
      onClick={onClick}
      className={`action-button menu-toggle ${expandMenu ? 'is-expanded' : ''}`}
    >
      <i className="fa-solid fa-plus"></i>
    </div>
  );
};
