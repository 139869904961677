import React from 'react';

export const Toolbar = ({ 
  left,
  center,
  right,
 }) => {
  return (
    <div
      className="drawer-toolbar"
    >
      <div className="drawer-toolbar__left">{left}</div>
      <div className="drawer-toolbar__center">{center}</div>
      <div className="drawer-toolbar__right">{right}</div>
    </div>
  );
}