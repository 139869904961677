const currency = (n) => `$${n.toFixed(2)}`;
const percent = (n) => `${n.toFixed(2)}%`;
const integer = (n) => parseInt(n);

const COLUMN = 'column';
const SPLINE = 'spline';

export const OPTIONS = new Map([
  [
    'spend',
    {
      value: 'spend',
      label: 'Spend',
      type: COLUMN,
      formatNum: currency,
    },
  ],
  ['results', { value: 'results', label: 'Results', type: COLUMN }],
  [
    'clicks',
    {
      value: 'clicks',
      label: 'Clicks',
      type: COLUMN,
      formatNum: integer,
    },
  ],
  [
    'impressions',
    {
      value: 'impressions',
      label: 'Impressions',
      type: COLUMN,
      formatNum: integer,
    },
  ],

  [
    'cpr',
    {
      value: 'cpr',
      label: 'CPR',
      type: SPLINE,
      formatNum: currency,
    },
  ],
  [
    'ctr',
    {
      value: 'ctr',
      label: 'CTR',
      type: SPLINE,
      formatNum: percent,
    },
  ],
  [
    'cpm',
    {
      value: 'cpm',
      label: 'CPM',
      type: SPLINE,
      formatNum: currency,
    },
  ],
  [
    'share_of_voice',
    {
      value: 'share_of_voice',
      label: 'Share of Voice',
      type: SPLINE,
      formatNum: percent,
    },
  ],
]);
