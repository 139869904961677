
import React from 'react';
import {
  Row, Col, FormGroup, FormLabel, Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AccountPageFooter from '../AccountPageFooter/AccountPageFooter';
import validate from './validateLogin';
import { NavLogo } from '../Nav/assets';
import './Login.scss';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // Clears any existing login related error on mount.
    this.props.removeError();

    const component = this;

    validate(component.form, {
      rules: {
        emailAddress: {
          required: true,
          email: true,
        },
        password: {
          required: true,
        },
      },
      messages: {
        emailAddress: {
          required: 'Need an email address here.',
          email: 'Is this email address correct?',
        },
        password: {
          required: 'Need a password here.',
        },
      },
      submitHandler() {
        component.handleSubmit();
      },
    });
  }

  handleSubmit() {
    this.props.fetchLogin({
      email: this.emailAddress.value,
      password: this.password.value,
    });
  }

  render() {
    return (
      <div className="login p-3">
        <Row>
          <Col xs={12} sm={6} md={5} lg={4}>
            <NavLogo className="login__logo" />
            <form
              ref={(form) => (this.form = form)}
              onSubmit={(event) => event.preventDefault()}
              className="login__form"
            >
              <FormGroup>
                <input
                  type="email"
                  name="emailAddress"
                  ref={(emailAddress) => (this.emailAddress = emailAddress)}
                  className=""
                  placeholder="Email"
                />
              </FormGroup>
              <FormGroup>
                <input
                  type="password"
                  name="password"
                  ref={(password) => (this.password = password)}
                  className=""
                  placeholder="Password"
                />
              </FormGroup>
              <Button type="submit" bsstyle="success" className="login__button">
                Log In
              </Button>
              <p>
                <Link to="/request-reset">Forgot password?</Link>
              </p>
              <img className="darwin-logo" src={require('./darwin.svg')} />
            </form>
          </Col>
        </Row>
      </div>
    );
  }
}

Login.propTypes = {
  fetchLogin: PropTypes.func.isRequired,
  removeError: PropTypes.func.isRequired,
};

export default Login;
