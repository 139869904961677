import React from 'react';
import { DataState, Row } from './types';

export const EMPTY_VARIANT_DATA = [
  {
    data: null,
  },
  {
    data: null,
  },
  {
    data: null,
  },
];

export const EMPTY_ROW: Row = {
  baseVisual: null,
  variantData: EMPTY_VARIANT_DATA,
  loading: false,
  error: false,
  primaryMetric: 'cpa',
};

export const initialDataState: DataState = {
  rows: [EMPTY_ROW],
};
