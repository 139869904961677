import { setHours, setMinutes, set } from 'date-fns';

import { groupBy, project } from '../../../../../utils/wranglers';
import { daysFrom, toDarwinDate } from '../../../../../utils/darwin-dates';

import { columnFormatter } from '../utils';

/**
 * @param {Date} date
 */
function daysFromToday(date) {
  const diffMs = new Date().getTime() - date.getTime();

  return Math.floor(diffMs / 1000 / 60 / 60 / 24);
}

function daysFromDarwinDate(date, days) {
  return toDarwinDate(daysFrom(date, days));
}

function costPer(a, b) {
  if (b === 0) {
    return Infinity;
  }
  return a / b;
}

function expandMeasures(metric) {
  switch (metric.toLowerCase()) {
    case 'costperresult':
      return { fields: ['spend', 'results'], calc: costPer };

    case 'costperlinkclick':
      return { fields: ['spend', 'linkClick'], calc: costPer };

    case 'costperimpressions':
      return {
        fields: ['spend', 'impressions'],
        calc: (a, b) => costPer(a, b) * 1000,
      };

    case 'costperaddtocart':
      return { fields: ['spend', 'addToCart'], calc: costPer };

    case 'clickthrurate':
      return {
        fields: ['linkClick', 'impressions'],
        calc: (a, b) => costPer(a, b) * 100,
      };

    case 'landingpageviewstolinkclicks':
      return { fields: ['landingPageView', 'linkClick'], calc: costPer };

    case 'addtocarttolinkclick':
      return { fields: ['addToCart', 'linkClick'], calc: costPer };

    case 'checkoutinitiatedtolinkclicks':
      return { fields: ['checkoutInitiated', 'linkClick'], calc: costPer };

    case 'purchasestolinkclicks':
      return { fields: ['purchase', 'linkClick'], calc: costPer };

    case 'addtocartstolandingpageviews':
      return { fields: ['addToCart', 'landingPageView'], calc: costPer };

    case 'checkoutinitiatedtoaddtocarts':
      return { fields: ['checkoutInitiated', 'addToCart'], calc: costPer };

    case 'purchasestocheckoutinitiated':
      return { fields: ['purchase', 'checkoutInitiated'], calc: costPer };

    case 'revenueoveradspend':
      return { fields: ['revenue', 'spend'], calc: costPer };

    default:
      return { fields: [metric] };
  }
}

export function translateMeasures(measures) {
  const results = new Set();
  // {...<table.column>: [<table.column>] }
  const mappings = {};

  measures.forEach((m) => {
    const [table, measure] = m.split('.');

    const { fields, calc } = expandMeasures(measure);

    mappings[measure] = { fields, calc };

    fields.forEach((f) => {
      results.add(`${table}.${f}`);
    });
  });

  return {
    mappings,
    measures: [...results],
  };
}

export function comparisonFormatter(value, key, rowNumber) {
  const k = key.toLowerCase();
  // rowNumber == array index + 1
  if (k === 'date') {
    return value;
  }
  if (rowNumber === 3) {
    return Number.isNaN(value) ? '0%' : `${Math.round(value * 100)}%`;
  }
  if (k === 'addtocart' || k === 'costperaddtocart') {
    return value === undefined ? 0 : columnFormatter(value, key);
  }
  if (k.includes('to')) {
    return Number.isNaN(value) ? '0%' : `${Math.round(value * 100)}%`;
  }
  if (k === 'clickthrurate') {
    return value === undefined ? '0%' : `${value.toFixed(2)}%`;
  }

  return value === undefined ? 0 : columnFormatter(value, key);
}

export function formatHeader(name) {
  if (name.length === 3 && name.slice(0, 2) === 'cp') {
    return name.toUpperCase();
  }

  switch (name.toLowerCase()) {
    case 'costperresult':
      return 'CPA';

    case 'costperlinkclick':
      return 'CPC';

    case 'costperimpressions':
      return 'CPM';

    case 'costperaddtocart':
      return 'CPATC';

    case 'costpercheckoutinitiated':
      return 'CPIC';

    case 'clickthrurate':
      return 'CTR';

    case 'linkclick':
      return 'Link Click';

    case 'landingpageviewstolinkclicks':
      return 'Page View to Link Click';

    case 'addtocarttolinkclick':
      return 'Add To Cart to Link Click';

    case 'checkoutinitiatedtolinkclicks':
      return 'Checkout Initiated to Link Click';

    case 'purchasestolinkclicks':
      return 'Purchase to Link Click';

    case 'addtocartstolandingpageviews':
      return 'Add To Cart to Page View';

    case 'checkoutinitiatedtoaddtocarts':
      return 'Checkout Initiated to Add To Cart';

    case 'purchasestocheckoutinitiated':
      return 'Purchase to Checkout Initiated';

    case 'revenueoveradspend':
      return 'ROAS';

    default:
      return `${name[0].toUpperCase()}${name.slice(1)}`;
  }
}

/**
 * Data processing
 */

/**
 * @description Inserts placeholder data for the missing period.
 * @param {{ date: string }[]} series
 * @param {{ label: string, value: [number, number], key: string }} p1
 * @param {{ label: string, value: [number, number], key: string }} p1
 */
function setPlaceholder([data], p1, p2) {
  const { date } = data;
  const diff = -daysFromToday(new Date(date));

  if (p1.value[0] === diff) {
    return [data, { date: p2.label }];
  }
  if (p2.value[0] === diff) {
    return [{ date: p1.label }, data];
  }
  return [];
}

function deriveWithMappings(mappings) {
  const keys = Object.keys(mappings);

  return (row) => {
    const derivedRow = { date: row.date };

    keys.forEach((key) => {
      const {
        fields: [dividend, divisor],
        calc,
      } = mappings[key];

      if (!divisor) {
        derivedRow[key] = row[dividend];
      } else {
        derivedRow[key] = calc(row[dividend], row[divisor]);
      }
    });

    return derivedRow;
  };
}

function setPeriod(row, period) {
  if (!row) {
    return { date: period };
  }
  const { date, ...rest } = row;

  return {
    date: period,
    ...rest,
  };
}

function makeChangeRow(rows, headers) {
  const L = rows.length;

  if (L === 0) {
    return [];
  }
  const [older, newer] = rows;
  const getChange = (key) => {
    const previous = older[key] || 0;
    const current = newer[key] || 0;

    if (previous === 0 && current === 0) {
      return 0;
    }
    if (previous === 0) {
      return 1;
    }
    if (current === 0) {
      return -1;
    }
    return (current - previous) / current;
  };

  const results = {};

  headers.forEach(({ key }) => {
    results[key] = getChange(key);
  });

  results.date = 'Change';

  return results;
}

export function generateHeaders(rows) {
  const seen = new Set();

  rows.forEach((row) => {
    Object.keys(row).forEach((k) => seen.add(k));
  });

  return [...seen].map((key) => ({ title: formatHeader(key), key }));
}

export function makeDateRanges(periodPairs) {
  const now = new Date();

  return periodPairs.map(([start, end]) => [
    daysFromDarwinDate(now, start),
    daysFromDarwinDate(now, end),
  ]);
}

/**
 * @param {number} daysFromNow
 *
 * @return {[string, string]}
 */
export function getLookbackRange(daysFromNow) {
  const date = daysFrom(
    set(new Date(), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    }),
    daysFromNow
  );

  return [
    setMinutes(setHours(date, 18), 1).toString(),
    setHours(date, 24).toString(),
  ];
}

/// / v2
function generateProjection(row) {
  const toNum = (k) => (doc) => parseFloat(doc[k]);

  return Object.keys(row)
    .filter((k) => !k.toLowerCase().includes('date'))
    .reduce(
      (acc, k) => {
        acc[k] = toNum(k);

        return acc;
      },
      { compareDateRange: 1 }
    );
}

/**
 * @return {{date: string }[]}
 */
function groupByDate(rows, headers) {
  const accum = () =>
    headers.reduce(
      (acc, header) => {
        acc[header] = 0;

        return acc;
      },
      { compareDateRange: '' }
    );

  const reducer = (acc, row) => {
    acc.compareDateRange = row.compareDateRange;

    headers.forEach((header) => {
      acc[header] += row[header];
    });

    return acc;
  };

  return groupBy(reducer, rows, ['compareDateRange'], accum);
}

function transformRawData(rawData) {
  const L = rawData.length;

  if (L === 0) {
    return { headers: [], rows: [] };
  }

  const rawHeaderTranslations = generateProjection(rawData[0]);
  const rawHeaders = Object.keys(rawHeaderTranslations);
  // [...{ compareDateRange: string, ...<string>: number }]
  const projected = project(rawData, rawHeaderTranslations);
  // [...{ compareDateRange: string, ...<string>: number }]
  const grouped = groupByDate(
    projected,
    rawHeaders.filter((k) => k !== 'compareDateRange')
  );

  return { headers: rawHeaders, rows: grouped };
}

/**
 * @param {Object<string, any>[]} series
 * @param {Object} mappings
 *
 * @return {{ date: string}[]}
 */
function deriveFromRawData(series, mappings) {
  const { rows, headers } = transformRawData(series);
  const setDate = ({ compareDateRange }) => {
    const [start] = compareDateRange.split(' - ');

    return start;
  };
  const formattingTranslation = headers
    .filter((k) => k !== 'compareDateRange')
    .reduce(
      (acc, k) => {
        const [, metric] = k.split('.');

        acc[metric] = `$${k}`;

        return acc;
      },
      {
        date: setDate,
      }
    );

  // [...{ date: string, ...<string>: number }]
  const formatted = project(rows, formattingTranslation);
  const deriveCalculation = deriveWithMappings(mappings);

  return formatted.map(deriveCalculation);
}

/**
 * @param {Object[]} series
 * @param {{ label: string, value: [number, number], key: string }} period1
 * @param {{ label: string, value: [number, number], key: string }} period2
 * @param {Object} mappings
 *
 * @return {{ headers: any[], rows: any[] }}
 */
export function prepSeries(series, period1, period2, mappings) {
  if (series.length === 0) {
    return { headers: [], rows: [] };
  }

  // [...{ date: string, ...<string>: number }]
  const derivedRows = deriveFromRawData(series, mappings);
  let [d1, d2] = derivedRows;

  // There is a sync issue between cube receiving a new query
  // and cube updating the loading flag. This means the period
  // data here may not reflect the series data.
  if (!d2) {
    [d1, d2] = setPlaceholder(derivedRows, period1, period2);

    if (!d1) {
      return { headers: [], rows: [] };
    }
  }

  // Newer data is top row. (Compare <older> to <newer>).
  const period2row = setPeriod(d2, period2.label);
  const period1row = setPeriod(d1, period1.label);

  const headers = generateHeaders([period1row, period2row]);
  const rows = [
    period2row,
    period1row,
    makeChangeRow([period1row, period2row], headers),
  ];

  return { headers, rows };
}
