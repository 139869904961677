import React from 'react';

import { EditorPanel } from './EditorPanel';

const AD_WIDTH = '510';
const PLACEHOLDER = { width: `${AD_WIDTH}px`, padding: '3rem' };

function AdCreator() {
  return (
    <div style={PLACEHOLDER} className="bg-white darwin-box-shadow">
      <EditorPanel type="atf" />
      <EditorPanel type="visual" />
      <EditorPanel type="btf" />
      <EditorPanel type="cta" />
    </div>
  );
}

export default AdCreator;
