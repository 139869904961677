//@ts-check
import React from "react";
import PropTypes from "prop-types";

import CustomDropdown from "../../../components/CustomDropdown";
import { OPTIONS } from "./constants";

export const AxisPicker = ({ selected, onSelect, foregroundColor }) => {
  const [left, right] = selected;
  const options = useRemainingOptions(left, right);

  return (
    <div className="d-flex flex-row justify-content-center">
      <Picker
        toggleStyle={{ backgroundColor: "#BFEAFF", padding: "0.3rem 0.5rem" }}
        caretStyle={{ color: '#667394'}}
        name={OPTIONS.get(left).label}
        onSelect={(key) => onSelect([key, right])}
        options={options}
      />
      <Picker
        toggleStyle={{
          backgroundColor: foregroundColor,
          color: "#ffffff",
          marginLeft: "1rem",
          padding: "0.3rem 0.5rem",
        }}
        caretStyle={{ color: '#fff'}}
        name={OPTIONS.get(right).label}
        onSelect={(key) => onSelect([left, key])}
        options={options}
      />
    </div>
  );
};

AxisPicker.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
};

const Picker = ({ name, options, onSelect, toggleStyle, caretStyle }) => {
  return (
    <CustomDropdown
      id={`axis-${name}`}
      label={name}
      onSelect={onSelect}
      toggleStyle={toggleStyle}
      caretStyle={caretStyle}
    >
      {options.map(({ value, label }) => (
        <CustomDropdown.Item key={value} eventKey={value}>
          {label}
        </CustomDropdown.Item>
      ))}
    </CustomDropdown>
  );
};

Picker.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }).isRequired
  ),
  onSelect: PropTypes.func.isRequired,
  toggleStyle: PropTypes.object,
};

const useRemainingOptions = (left, right) => {
  let opts = [];

  for (const [key, config] of OPTIONS) {
    if (key !== left && key !== right) {
      opts.push(config);
    }
  }

  return opts;
};
