import { LocalStorage } from '../utils';

export const setLocalStorage = (entity, data) => ({
  type: `[${entity}] TO_LOCAL_STORAGE`,
  data,
  meta: { entity },
});

export const getLocalStorage = (entity, key) => ({
  type: `[${entity}] FROM_LOCAL_STORAGE`,
  key,
  meta: { entity },
});

const shouldSet = ({ type, meta }) =>
  meta && type.includes('SET') && meta.setLocalStorage;

const shouldGet = ({ type, meta }) =>
  meta && type.includes('FETCH') && meta.getLocalStorage;

const setStorage = (Storage, forLocalStorage) => {
  if (Array.isArray(forLocalStorage)) {
    forLocalStorage.forEach(({ key, value }) => Storage.setItem(key, value));

    return Storage;
  }
  return Storage.setItem(forLocalStorage.key, forLocalStorage.value);
};

export const localStorageMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (shouldSet(action)) {
      dispatch(
        setLocalStorage(action.meta.entity, action.meta.setLocalStorage)
      );

      setStorage(LocalStorage, action.meta.setLocalStorage);

      return next({
        ...action,
        meta: {
          ...action.meta,
          setLocalStorage: null,
        },
      });
    }
    if (shouldGet(action)) {
      dispatch(
        getLocalStorage(action.meta.entity, action.meta.getLocalStorage.key)
      );

      const data = LocalStorage.getItem(action.meta.getLocalStorage.key);

      return next({
        ...action,
        meta: {
          ...action.meta,
          // Want to ensure no repeated FETCHs
          fromLocalStorage: data,
          getLocalStorage: null,
        },
      });
    }
    return next(action);
  };
