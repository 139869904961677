import PropTypes from 'prop-types';

export const OptionType = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
});

export const SkeletonType = PropTypes.arrayOf(
  PropTypes.shape({
    category: PropTypes.string,
    blocks: PropTypes.arrayOf(
      PropTypes.shape({
        block_id: PropTypes.string,
        block_name: PropTypes.string,
      })
    ),
  })
);

export const BreakdownType = PropTypes.oneOf([
  'trending',
  'placement',
  'age',
  'gender',
  'platform',
  'device',
]);

export const MultiplePeriodColumns = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    spend: PropTypes.string,
    cpa: PropTypes.string,
  })
);
