import { useRouteMatch } from 'react-router-dom';

import { isRootRoute } from '../../root-routes';

/**
 * @description Uses the current viewId from the URL.
 * Can only be called from an element within a
 * Router element.
 *
 * @return {String}
 */
export function useViewId() {
  const match = useRouteMatch('/:viewId');

  if (!match) {
    return '';
  }
  const id = match.params.viewId;

  return isRootRoute(id) ? '' : id;
}

/**
 * @description Non-hook version, safe to use wherever.
 *
 * @return {String}
 */
export function getViewId() {
  const [, maybeId] = window.location.pathname.split('/');

  if (!maybeId) {
    return '';
  }
  return isRootRoute(maybeId) ? '' : maybeId;
}
