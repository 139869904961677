import { findMedian, useGetMedian } from '../utils';

export const getAdditionalMetrics = (
  visuals,
  additional_metrics = [],
  spend
) => {
  let result = {};

  additional_metrics.forEach((metric) => {
    const total = visuals.reduce((total, visual) => total + visual[metric.value], 0);
    let costPer = total > 0 ? spend / total : 0;
    const cp_arr = visuals.map((visual) => visual[`cp_${metric.value}`] || 0);
    const medianCostPer = findMedian(cp_arr);
    const median = findMedian(visuals, metric.value);

    result[metric.value] = total || 0;
    result[`cp_${metric.value}`] = costPer || 0;
    result[`median_cp_${metric.value}`] = medianCostPer || 0;
    result[`median_${metric.value}`] = medianCostPer || 0;
  });
  return result;
};
