import React from 'react';
import ReportGroup from '../ReportGroup';

export const ViewGroup = ({ report_group_id, exit, error }) => {
  return (
    <div className="d-flex" id="wrapper">
      <div id="page-content-wrapper">
        <ReportGroup _id={report_group_id} exit={exit} viewError={error} />
      </div>
    </div>
  );
};
