import { findDependenciesForLabel } from '../DerivedLabels/validate';

export const validateLabels = (
  labels,
  getAdNameLabelsApi,
  getDerivedLabelsApi
) => {
  let errors = [];
  let existing_labels = [];
  let existing_ad_name_labels = [];
  let deps = [];
  const derivedLabelsData = getDerivedLabelsApi.get.data || null;
  const isArr = Array.isArray(getAdNameLabelsApi?.data?.ad_name_labels);

  if (getAdNameLabelsApi.data) {
    existing_labels = getAdNameLabelsApi.data.existing_labels.filter((x) =>
      isArr ? true : !getAdNameLabelsApi?.data?.ad_name_labels?.[x]
    );
    existing_ad_name_labels = Object.keys(
      getAdNameLabelsApi.data.ad_name_labels
    ).map((x) => x);
  }

  labels.forEach((label) => {
    if (
      existing_labels.some(
        (x) => x.toLowerCase().trim() === label.name.toLowerCase().trim()
      ) && !existing_ad_name_labels.includes(label.name)
    ) {
      errors.push(`The label name "${label.name}" is already in use.`);
    }

    if (label.filters.some((x) => !x.include.length && !x.exclude.length)) {
      errors.push(
        `Include and exclude cannot both be empty. ${
          label.name.length ? `(${label.name})` : ''
        }`
      );
    }
  });

  existing_ad_name_labels.forEach((ad_name_label) => {
    const labelDeps = findDependenciesForLabel(
      ad_name_label,
      'Ad Name Labels',
      derivedLabelsData
    );

    if (labelDeps.length) {
      deps.push({ label: ad_name_label, labelDeps });
    }
  });

  deps.forEach((dep) => {
    const maybe = labels.find((label) => label.name === dep.label);

    if (!maybe) {
      dep.labelDeps.forEach(({ category, name }) => {
        errors.push(
          `The label "${dep.label}" cannot be removed because the derived label "${name}" in the category "${category}" depends on it.`
        );
      });
    }
  });

  let seen = new Set();

  var hasDuplicates = labels.some((curr) => {
    return seen.size === seen.add(curr.name).size;
  });

  if (hasDuplicates) errors.push('Duplicate label names not allowed.');

  return errors;
};
