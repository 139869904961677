import { toDarwinDate } from '../../../utils/darwin-dates';
import { MULTIPLE_LABELS, MUTUALLY_EXCLUSIVE } from './constants';

export const makeFetchPayload = ({
  category_name,
  category_id,
  platform,
  darwin_client_id,
  view_id,
  period1Start,
  period1End,
}) => {
  const includeDates = true; //!category.length;

  return {
    darwin_client_id,
    view_id,
    platform,
    category: category_name,
    category_id,
    ...(includeDates && { start_date: period1Start }),
    ...(includeDates && { end_date: period1End }),
  };
};

export const makePostPayload = ({
  input,
  visuals,
  annotatedVisuals,
  darwin_client_id,
  platform,
  view_id,
  changedLabels,
  labelOptions,
  service_type,
  user_id,
  category_id,
}) => {
  const unannotated_visuals = {};
  Object.keys(visuals).forEach((key) => {
    if (!annotatedVisuals[key]) {
      unannotated_visuals[key] = visuals[key];
    }
  });

  const withoutSpend = (dict) => {
    let update = {};

    Object.entries(dict).forEach(([key, { asset, type }]) => {
      update[key] = {
        asset,
        type,
      };
    });

    return update;
  };

  return {
    category: input.name,
    compliance: input.compliance,
    description: input.description,
    mutually_exclusive: input.type === MUTUALLY_EXCLUSIVE,
    output_labels: labelOptions,
    annotated_visuals: withoutSpend(annotatedVisuals),
    unannotated_visuals: withoutSpend(unannotated_visuals),
    darwin_client_id,
    platform: platform,
    view_id,
    user_id,
    changed_labels: [...changedLabels],
    visual_mode: input.visual_mode,
    service_type: service_type,
    category_id,
  };
};

export const makeRunPredictionsPayload = ({
  darwin_category_name,
  category,
  darwin_client_id,
  platform,
  unannotated_visuals,
  annotated_visuals,
  compliance,
  changedLabels,
  service_type,
  user_id,
  predictionsRequestId,
  category_id,
}) => {
  return {
    category_id,
    request_id: predictionsRequestId,
    category,
    darwin_client_id,
    platform: platform,
    unannotated_visuals,
    compliance,
    changed_labels: changedLabels,
    user_id,
    service_type,
    annotated_visuals,
  };
};

export const makeUpdateOnePayload = ({
  input,
  visual,
  category,
  custom_category,
  darwin_client_id,
  platform,
  view_id,
  labelOptions,
  annotatedVisuals,
  service_type,
  user_id,
  category_id,
}) => {
  const label_changes = annotatedVisuals[visual]
    ? annotatedVisuals[visual].asset
    : {};

  return {
    visual_hash: visual,
    category,
    custom_category,
    label_changes,
    multiple_labels: input.type === MULTIPLE_LABELS,
    darwin_client_id,
    platform,
    view_id,
    compliance: input.compliance,
    visual_mode: input.visual_mode,
    output_labels: labelOptions,
    user_id: user_id,
    service_type: service_type,
    category_id,
  };
};

export const makeUpdateOneMultiplePayload = (
  selectedLabel,
  input,
  visual,
  category,
  custom_category,
  darwin_client_id,
  platform,
  view_id,
  labelOptions,
  service_type,
  user_id,
  category_id
) => {
  let label_changes = {};

  labelOptions.forEach((label) => {
    if (label === selectedLabel) {
      label_changes[label] = 'yes';
    } else {
      label_changes[label] = 'no';
    }
  });

  return {
    visual_hash: visual,
    category,
    custom_category,
    label_changes:
      input.type === MULTIPLE_LABELS ? selectedLabel : label_changes,
    multiple_labels: input.type === MULTIPLE_LABELS,
    darwin_client_id,
    platform,
    view_id,
    compliance: input.compliance,
    visual_mode: input.visual_mode,
    service_type: service_type,
    user_id: user_id,
    category_id,
  };
};

export const makeDeletePayload = (category, darwin_client_id, platform, category_id) => {
  return {
    category,
    category_id,
    darwin_client_id,
    platform,
  };
};
