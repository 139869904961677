import {
  ALL_VISUALS,
  BRAND,
  FORMAT,
  KEYWORD,
  PHRASES,
  PROMO,
  PROMOTION_TYPE,
} from '../CreativeAnalytics/categories';

export const BAR_CHART = 'bar_chart';
export const PIE_CHART = 'pie_chart';
export const SINGLE_VALUE = 'single_value';
export const AUDIENCE_BREAKDOWN = 'audience_breakdown';

export const INSIGHTS_CATEGORIES = [
  BRAND,
  PROMO,
  PROMOTION_TYPE,
  KEYWORD,
  FORMAT,
  PHRASES,
];

export const PIE_METRICS = ['percent_budget', 'spend'];
export const BAR_METRICS = ['cpa', 'roas'];

export const METRIC_FORMAT_LOOKUP = {
  aggregate_cpa: 'CPA',
  aggregate_roas: 'ROAS',
  percent_budget: '% Spend',
  spend: '% Spend',
};

export const CATEGORY_FORMAT_LOOKUP = {
  [BRAND]: 'Brand Name in Ad',
  [PROMO]: 'Promo',
  [PROMOTION_TYPE]: 'Promo Type',
  [ALL_VISUALS]: 'New Visuals',
  [PHRASES]: 'Phrase',
};

export const CARD_TEMPLATES = [
  {
    category: BRAND,
    metric_options: ['spend'],
    data: [],
    tooltip: {
      spend:
        'This shows the percentage of spend that goes to your ads with your brand name in them for the selected time period and audience',
    },
  },
  {
    category: BRAND,
    metric_options: ['aggregate_cpa'],
    data: [],
    tooltip: {
      aggregate_cpa:
        'This shows how your ads with your brand name perform compared to the ads without your brand name for the selected time period and audience',
    },
  },
  {
    metric_options: ['aggregate_cpa', 'spend'],
    isAudienceBreakdown: true,
    category: FORMAT,
    tooltip: {
      aggregate_cpa:
        'This shows how each format is performing for the given audience and date range',
      spend:
        'This shows how each format is spending for the given audience and date range',
    },
  },
  {
    category: KEYWORD,
    metric_options: ['aggregate_cpa', 'percent_budget'],
    size: 'full',
    useBarForPercent: true,
    tooltip: {
      aggregate_cpa:
        'This shows how each keyword is performing for the given audience and date range',
      percent_budget:
        'This shows the percentage of spend that goes to each keyword for the selected time period and audience',
    },
  },
  {
    category: PHRASES,
    metric_options: ['aggregate_cpa', 'percent_budget'],
    size: 'full',
    useBarForPercent: true,
    data: [],
    isResizable: true,
    tooltip: {
      aggregate_cpa:
        'This shows how each phrase is performing for the given audience and date range',
      percent_budget:
        'This shows the percentage of spend that goes to each phrase for the selected time period and audience',
    },
  },
  {
    cardType: SINGLE_VALUE,
    category: ALL_VISUALS,
    metric_options: ['number_unique_creatives'],
    labelToUse: 'new_visuals',
    size: 'full',
    data: [],
    title: 'New Visuals',
    tooltip: {
      number_unique_creatives:
        'This is the number of new visuals introduced since the start of the period selected',
    },
  },
  {
    category: ALL_VISUALS,
    metric_options: ['aggregate_cpa', 'spend'],
    size: 'full',
    data: [],
    tooltip: {
      spend:
        'This shows the percentage of spend that goes to your new ads compared to all ads for the selected time period and audience',
      aggregate_cpa:
        'This shows how new ads are performing compared to all ads for the selected time period and audience',
    },
  },
  {
    category: PROMO,
    metric_options: ['aggregate_cpa', 'spend'],
    size: 'full',
    data: [],
    tooltip: {
      aggregate_cpa:
        'This shows how your ads with promotions in them perform compared to the ads without promotions for the selected time period and audience',
      spend:
        'This shows the percentage of spend that goes to your new ads compared to all ads for the selected time period and audience',
    },
  },
  {
    category: PROMOTION_TYPE,
    metric_options: ['aggregate_cpa', 'percent_budget'],
    useBarForPercent: true,
    size: 'full',
    data: [],
    tooltip: {
      aggregate_cpa:
        'This shows how each promotion type performs for the selected time period and audience',
      percent_budget:
        'This shows the percentage of spend that goes to your new ads compared to all ads for the selected time period and audience',
    },
  },
];

export const DEFAULT_TEMPLATE = {
  category: 'other',
  metric_options: ['aggregate_cpa', 'percent_budget'],
  useBarForPercent: true,
  size: 'full',
  data: [],
  tooltip: {
    aggregate_cpa:
      'This shows how each label performs for the selected time period and audience',
    percent_budget:
      'This shows the percentage of spend that goes to each label for the selected time period and audience',
  },
};

export const PIE_COLORS = [
  '#4B48E9',
  '#FA64A9',
  '#283A69',
  '#00C0ED',
  '#FFBB55',
];

const defaultOptions = ['aggregate_cpa', 'percent_budget'];
const withCircleChart = ['aggregate_cpa', 'spend'];

export const CARD_VARIANTS_BY_CATEGORY = {
  [BRAND]: [['spend'], ['aggregate_cpa']],
  [KEYWORD]: [defaultOptions],
  [PHRASES]: [defaultOptions],
  [ALL_VISUALS]: [['number_unique_creatives'], withCircleChart],
  [PROMO]: [withCircleChart],
  [PROMOTION_TYPE]: [defaultOptions],
  'default': [defaultOptions],
}