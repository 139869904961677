import { connect } from 'react-redux';

import { RUNNING_ADS_BY_NAME } from '../../../store/RunningAdsByName/actions';

const sortBySpend = (ads, names, spendThreshold, chart_type) => names
  .map((name) => ads[name])
  .filter((ad) => {
    return !!ad;
  })
  .filter((ad) => {
    return chart_type === 'performance_frequency' ? ad.spend_numeric >= spendThreshold : !!ad;
  })
  .sort((a, b) => b.spend_numeric - a.spend_numeric)
  .map(({ ad_name }) => ad_name);

const stateToProps = (
  { RunningAdsByName: { selectedNames, ads },
    ViewSettings: { spendThreshold },
    CreativeReports: { chart_type },
    Loaders },
  { isLoading },
) => {
  const loading = isLoading || Loaders[RUNNING_ADS_BY_NAME];

  return {
    isLoading: loading,
    adNames: loading && !ads ? [] : sortBySpend(ads, selectedNames, spendThreshold, chart_type),
  };
};

export default connect(stateToProps, null);
