import { useRef, useEffect } from 'react';
import joi from 'joi';
import { abortableFetch } from '../../utils';

import { useApiState, bindFetchToRef } from '../Hooks/useApiState';

const HEADERS = { 'content-type': 'application/json' };

function getLabels(location, getPayload) {
  const { project_id, filename, version, adName } = getPayload;

  if (location === 'marketplace') {
    return abortableFetch(
      ['api', 'marketplace', project_id, filename, version, 'labels'].join('/'),
      {
        method: 'GET',
        headers: HEADERS,
        credentials: 'include',
      }
    );
  }

  return abortableFetch(
    ['api', 'creative-reports', 'ad-preview', 'label-settings'].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(getPayload),
    }
  );
}

export function useGetLabels() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (location, getPayload) =>
      withRef(getLabels(location, getPayload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: true,
      count: 0,
    }
  );

  const handleGet = (getPayload, location) => {
    getDispatch(location, getPayload);
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    get: {
      ...getState,
      request: handleGet,
    },
  };
}

export const saveLabels = (postPayload, location) => {
  if (location === 'marketplace') {
    return abortableFetch(
      [
        'api',
        'marketplace',
        postPayload.project_id,
        postPayload.filename,
        postPayload.version,
        'labels',
        'save',
      ].join('/'),
      {
        method: 'POST',
        headers: HEADERS,
        body: JSON.stringify(postPayload),
        credentials: 'include',
      }
    );
  }
  return abortableFetch(
    ['api', 'creative-reports', 'ad-preview', 'label-settings', 'save'].join(
      '/'
    ),
    {
      method: 'POST',
      headers: HEADERS,
      body: JSON.stringify(postPayload),
      credentials: 'include',
    }
  );
};

export function useSaveLabels() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);

  const [postState, postDispatch] = useApiState((payload, location) =>
    withRef(saveLabels(payload, location)).then(extract)
  );

  const handlePost = (postPayload, location) => {
    // const { error } = SAVE_LABELS_SCHEMA.validate(payload);

    // if (error) {
    //   throw new Error(error.message);
    // } else {
    postDispatch(postPayload, location);
    // }
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: handlePost,
    },
  };
}
