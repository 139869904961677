import { useRef } from 'react';
import isEqual from 'lodash/isEqual';

export const useDeepCompare = (value) => {
  const ref = useRef();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}