import Wrapper from "./Wrapper";
import './index.scss';
import './components/Drawer/Drawer.scss';
import './components/Drawer/DrawerSection.scss';
import './components/VisualViewer/VisualViewer.scss';
import './components/Drawer/Toolbar.scss';
import './components/Button/DrawerButton.scss';
import './components/Browser/VisualsBrowser.scss';
import './components/Browser/Browser.scss';
import './components/Browser/BrowserTypeSelect.scss';
import './icons/icons.scss';

export default Wrapper;