import React, { useCallback, useMemo } from 'react';
import { DrawerSection } from '../Drawer/DrawerSection';
import { VisualPreview } from './Preview';
import { VisualInfo } from './VisualInfo';
import { LabelsList } from '../Drawer/LabelsList';
import { SET_VIEW } from '../../hooks/useExplorerState';
import { CATEGORY_TYPES_INDEX } from '../../constants';
import { useCopyToClipboard } from '../../../../../hooks/useCopyToClipboard';
import Tooltip from '../../../../../components/Tooltip';
import { Toolbar } from '../Drawer/Toolbar';
import { ReportCardIcon } from '../../../../../components/Icons';
import {
  useCreativeAnalyticsGet,
  useCreativeAnalyticsSet,
} from '../../../contexts';
import {
  CategoriesLookup,
  CategoriesLookupItem,
} from '../../../ReportCard/types';
import { useFilteredCategories } from '../../hooks/useFilteredCategories';
import { useCategoriesLookup } from '../../../selectors';

export const CategoriesBrowser = ({ handleSelectCategory }) => {
  const categoriesLookup: CategoriesLookup = useCategoriesLookup();
  const categories = Object.entries(categoriesLookup).map(([key, val]) => {
    return {
      ...val,
      category: key,
    };
  });
  const filteredCategories = useFilteredCategories(categories, true);

  return (
    <div className="categories-browser">
      {filteredCategories.map((category) => {
        const { category: category_name, ccc_type, labels } = category;

        const { label: categoryTypeLabel = null, icon: Icon = null } =
          CATEGORY_TYPES_INDEX[ccc_type] || {};

        const handleClick = () => handleSelectCategory(category_name);

        let Content = <p>-</p>;
        if (labels.length > 0) Content = <LabelsList labels={labels} />;

        return (
          <DrawerSection
            className={`drawer-section--${ccc_type}`}
            title={category_name}
            icon={
              Icon && (
                <Icon
                  className={`drawer-section__icon drawer-section__icon--${ccc_type}`}
                />
              )
            }
            iconTooltip={categoryTypeLabel}
            content={Content}
            onClick={handleClick}
          />
        );
      })}
    </div>
  );
};
