import React from 'react';
import { Tag, TagsList } from '../../../../../components/Tags';

export const LabelsList = ({ labels }) => {
  return (
    <div className="labels-list">
      <TagsList>
        {labels.map((label) => (
          // <Tag {...{ label, library, selectedSearchTerms }} />
          <LabelTag name={label.name} />
        ))}
      </TagsList>
    </div>
  );
};

export const LabelTag = ({ name, style }) => {
  return (
    <Tag style={{ ...style, fontFamily: 'Fira Code' }} title={name}>
      {name}
    </Tag>
  );
};
