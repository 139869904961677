import { SetStateAction, useEffect, useRef, useState } from 'react';
import { useSearchParams } from '../../components/Hooks/search-params';
import { CreativeGroup } from './types';
import { checkSortCache, checkSortCacheWithParams } from './utils';
import { useViewSettings } from '../../selectors';
import { useCreativeAnalyticsGet } from '../CreativeAnalytics/contexts';
import { usePostRequest } from '../../components/Hooks/usePostRequest';

function isEmpty(str) {
  return !str || str === 'null' || str === 'undefined';
}

/**
 * @param {{ breakdown?: string, period1?: string, period2?: string, include_ga_data?: boolean } }} params
 */
function setDefaultParams(
  {
    title,
    selectBy,
    count,
    metric,
    labels,
    sortBy,
    sortOrder,
    selectedVisuals,
    selectedLabel,
    custom,
    mode,
    columnFilters,
  },
  isOrganicView,
  primary_metric
) {
  let defaultSortBy = 'spend';

  if (isOrganicView) {
    defaultSortBy = primary_metric;
  }

  const base = {
    title: title || null,
    selectBy: selectBy || null,
    count: count || null,
    metric: metric || null,
    labels: labels || [],
    sortBy: sortBy || defaultSortBy,
    sortOrder: sortOrder || 'descending',
    selectedVisuals: parseVisualIds(selectedVisuals) ?? [],
    selectedLabel: selectedLabel || null,
    custom: custom || false,
    mode: mode || 'visuals',
    columnFilters: decodeColumnFilters(columnFilters) ?? [],
  };

  return base;
}

const parseVisualIds = (selectedVisuals) => {
  try {
    return selectedVisuals.split(',');
  } catch (e) {
    console.error(e);
    return [];
  }
};

const decodeColumnFilters = (columnFilters) => {
  if (!columnFilters) return [];

  try {
    return JSON.parse(decodeURIComponent(columnFilters));
  } catch (e) {
    console.error(e);
    return [];
  }
};

const processParams = (state = {}) => {
  const processedParams = {};
  Object.keys(state).forEach((key) => {
    if (state[key] !== null) {
      if (key === 'columnFilters') {
        processedParams[key] = encodeURIComponent(JSON.stringify(state[key]));
      } else {
        processedParams[key] = state[key];
      }
    }
  });
  return processedParams;
};

/**
 * @returns {[{ breakdown: string, period1: string, period2?: string, include_ga_data?: boolean }, Function]}
 */
export const useDNAParams = (): [
  CreativeGroup,
  React.Dispatch<SetStateAction<CreativeGroup>>,
] => {
  const { isOrganicView } = useViewSettings();
  const { primary_metric } = useCreativeAnalyticsGet();
  const { getParams, setParams } = useSearchParams();
  const params =
    getParams(
      'title',
      'selectBy',
      'count',
      'metric',
      'labels',
      'sortBy',
      'sortOrder',
      'selectedLabel',
      'selectedVisuals',
      'mode',
      'custom',
      'columnFilters'
    ) || {};
  const defaults = setDefaultParams(params, isOrganicView, primary_metric);
  const [state, setState] = useState<CreativeGroup>(defaults);

  const makeUpdate = (prev, update) => {
    const merged = { ...prev, ...update };

    return merged;
  };

  const setStateMerge = (update) =>
    setState((prev) => makeUpdate(prev, update));

  useEffect(() => {
    try {
      const paramsToSet = processParams(state);
      setParams(paramsToSet);
    } catch (e) {
      console.error(e);
      setParams(state);
    }
  }, [
    state.title,
    state.selectBy,
    state.count,
    state.metric,
    state.labels,
    state.selectedVisuals,
    state.selectedLabel,
    state.mode,
    state.custom,
    state.columnFilters,
  ]);

  return [state, setStateMerge];
};

export const useSortBinding = ({
  tableInstance,
  groupParams,
  setGroupParams,
}) => {
  const { setParams } = useSearchParams();
  // ensure param sort and table sort always match

  const tableSort = tableInstance.state?.sortBy?.[0] ?? null;
  const sortCache = useRef<{ desc: boolean; id: string }>(tableSort); // cache previous to avoid infinite render

  // when params sort changes, update table sort
  useEffect(() => {
    const isEqualToLast = checkSortCacheWithParams(groupParams, sortCache);

    try {
      if (!isEqualToLast) {
        const update = {
          id: groupParams.sortBy,
          desc: groupParams.sortOrder === 'descending',
        };
        tableInstance.setSortBy([update]);
        sortCache.current = { ...update };
      }
    } catch (e) {
      console.error(e);
    }
  }, [groupParams.sortBy, groupParams.sortOrder]);

  // when table sort changes, update params sort
  useEffect(() => {
    if (tableSort) {
      try {
        if (!checkSortCache(tableSort, sortCache)) {
          setParams({
            sortBy: tableSort.id,
            sortOrder: tableSort.desc ? 'descending' : 'ascending',
          });
          setGroupParams({
            sortBy: tableSort?.id ?? '',
            sortOrder: tableSort.desc ? 'descending' : 'ascending',
          });
          sortCache.current = { ...tableSort };
        }
      } catch (e) {
        console.error('Error updating sort params.');
      }
    }
  }, [tableSort]);
};

const REQUIRED_FIELDS = [
  'id',
  '_id',
  'asset_url',
  'thumbnail_url',
  'visual_type',
  'rank',
  'ad_names',
];

const replaceInvalidCharacters = (str) => {
  return str.replaceAll('.', '___');
};

export const useShareReport = () => {
  const { start_date, end_date, filteredAllVisualsLabel } =
    useCreativeAnalyticsGet();
  const {
    data: apiData,
    loading,
    error,
    request,
  } = usePostRequest(
    ['api', 'creative-analytics', 'create-static-report'].join('/')
  );
  const [shouldShowShareModal, setShouldShowShareModal] = useState(false);
  const [sharedReportId, setSharedReportId] = useState(null);

  const generateReport = ({
    data,
    columns,
    metadataFields,
    report_name,
    report_description,
    params,
    columnOptions,
    metadata = {},
    elementsToInclude = {},
  }) => {
    const filtered = data.map((visual) => {
      let update = {};

      Object.keys(visual).forEach((key) => {
        if ([...REQUIRED_FIELDS, ...columns].includes(key)) {
          update[replaceInvalidCharacters(key)] = visual[key];
        }
      });

      return update;
    });

    request({
      report_name,
      report_description,
      report_type: 'dna',
      start_date,
      end_date,
      data: {
        visuals: filtered,
        columns,
        metadataFields,
        params,
        columnOptions,
        metadata: {
          ...metadata,
          visuals:
            metadata?.visuals?.map((v) => {
              return {
                ...v,
                visual_categories: v.visual_categories?.map((c) => {
                  let result = {};

                  Object.entries(c).forEach(([key, val]) => {
                    result[replaceInvalidCharacters(key)] = val;
                  });

                  return result;
                }),
              };
            }) || [],
        },
        elementsToInclude,
      },
    });
  };

  useEffect(() => {
    const report_id = extractReportId(apiData);

    if (report_id) {
      setSharedReportId(report_id);
    }
  }, [apiData]);

  return {
    shouldShowShareModal,
    openShareModal: () => setShouldShowShareModal(true),
    closeShareModal: () => {
      setShouldShowShareModal(false);
      setSharedReportId(null);
    },
    submitShareReport: (payload) => generateReport(payload),
    sharedReportId,
    loadingShareReport: loading,
  };
};

const extractReportId = (apiData) => {
  if (apiData?.message) {
    const reportIdMatch = apiData.message.match(/report id (\w+)/);

    const reportId = reportIdMatch ? reportIdMatch[1] : null;

    return reportId;
  } else {
    return null;
  }
};
