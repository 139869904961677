import React from 'react';

export const InfoIcon = ({ style, onShowName, onCloseName, onToggleNamePreview, children }) => {

  const handleShowName = onShowName;
  const handleCloseName = onCloseName;

  const toggleNamePreview = () => {
    onToggleNamePreview();
  }

  return (
    <div 
      className="help-icon" 
      onMouseEnter={handleShowName} 
      onMouseLeave={handleCloseName}
      onClick={toggleNamePreview}
    >
      <svg style={style} width="25" height="25" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle className="help-icon__outline" cx="8.03056" cy="9.32355" r="6.046" fill="white" stroke="#666"/>
        <path d="M6.76593 12.9595V12.3442L7.56183 12.2075V8.43311L6.76593 8.29639V7.67627H8.52375V12.2075L9.31964 12.3442V12.9595H6.76593ZM7.513 6.37256V5.34229H8.52375V6.37256H7.513Z" fill="#666"/>
      </svg>
      {children}
    </div>
  )
}

export const PreviewIcon = ({ style }) => {
  return (
    <svg className="preview-icon" style={style} width="25" height="25" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="preview-icon__bg" d="M12.1063 5.24457H9.55236L9.31857 4.59105L9.00548 3.71332H5.58068L5.26759 4.59105L5.0338 5.24457H2.48126C2.42111 5.24457 2.37189 5.29379 2.37189 5.35394V11.5883C2.37189 11.6485 2.42111 11.6977 2.48126 11.6977H12.1063C12.1664 11.6977 12.2156 11.6485 12.2156 11.5883V5.35394C12.2156 5.29379 12.1664 5.24457 12.1063 5.24457ZM7.29376 10.4946C6.08517 10.4946 5.10626 9.51566 5.10626 8.30707C5.10626 7.09847 6.08517 6.11957 7.29376 6.11957C8.50236 6.11957 9.48126 7.09847 9.48126 8.30707C9.48126 9.51566 8.50236 10.4946 7.29376 10.4946Z" fill="#fff"/>
      <path d="M7.29376 6.11957C6.08517 6.11957 5.10626 7.09847 5.10626 8.30707C5.10626 9.51566 6.08517 10.4946 7.29376 10.4946C8.50236 10.4946 9.48126 9.51566 9.48126 8.30707C9.48126 7.09847 8.50236 6.11957 7.29376 6.11957ZM7.29376 9.61957C6.56915 9.61957 5.98126 9.03168 5.98126 8.30707C5.98126 7.58246 6.56915 6.99457 7.29376 6.99457C8.01837 6.99457 8.60626 7.58246 8.60626 8.30707C8.60626 9.03168 8.01837 9.61957 7.29376 9.61957Z" fill="#666"/>
      <path d="M12.1063 4.26019H10.2469L9.80392 3.01879C9.77331 2.93384 9.71723 2.86041 9.64333 2.80854C9.56943 2.75666 9.48132 2.72886 9.39103 2.72894H5.1965C5.01193 2.72894 4.8465 2.84515 4.78497 3.01879L4.34064 4.26019H2.48126C1.87697 4.26019 1.38751 4.74965 1.38751 5.35394V11.5883C1.38751 12.1926 1.87697 12.6821 2.48126 12.6821H12.1063C12.7106 12.6821 13.2 12.1926 13.2 11.5883V5.35394C13.2 4.74965 12.7106 4.26019 12.1063 4.26019ZM12.2156 11.5883C12.2156 11.6485 12.1664 11.6977 12.1063 11.6977H2.48126C2.42111 11.6977 2.37189 11.6485 2.37189 11.5883V5.35394C2.37189 5.29379 2.42111 5.24457 2.48126 5.24457H5.0338L5.26759 4.59105L5.58068 3.71332H9.00548L9.31857 4.59105L9.55236 5.24457H12.1063C12.1664 5.24457 12.2156 5.29379 12.2156 5.35394V11.5883Z" fill="#666"/>
    </svg>
  )
}