import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import CustomDropdown from '../CustomDropdown';
import { useSearchParams } from '../Hooks/search-params';

import { setViewSettings } from '../../store/ViewSettings/actions';
import { trackEvent } from '../../store/UserActivity/actions';
import {
  CREATIVE_ANALYTICS,
  CHANGE_AUDIENCE,
} from '../../store/UserActivity/constants';
import { useCreativeAnalyticsGet } from '../../features/CreativeAnalytics/contexts';

function isAnalytics() {
  return window.location.pathname.includes('analytics');
}
export function Picker({
  setAudience,
  audience,
  selectedAudienceFromStore,
  toggleStyle,
  toggleClass,
}) {
  const dispatch = useDispatch();
  const _id = 'audience-picker-button';

  const { setParams, getParams } = useSearchParams();

  const params = getParams('audience', 'display');
  const currentAudience = selectedAudienceFromStore || params.audience || '';

  const updateAudience = useUpdateAudience(audience, currentAudience);

  return (
    <CustomDropdown
      id={_id}
      label={
        <span>
          <i style={{ fontSize: '11px' }} className="fa fa-user-group mr-2"></i>{' '}
          {adjustAudience(currentAudience)}
        </span>
      }
      onSelect={updateAudience}
      menuStyle={{ maxHeight: '300px', overflow: 'auto' }}
      toggleStyle={toggleStyle}
      toggleClass={toggleClass}
    >
      {audience.map((aud) => (
        <CustomDropdown.Item key={aud} eventKey={aud} className="py-2">
          {aud}
        </CustomDropdown.Item>
      ))}
    </CustomDropdown>
  );
}

Picker.propTypes = {
  setAudience: PropTypes.func.isRequired,
  audience: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const stateToProps = (
  { ViewSettings: { audience, selectedAudience } },
  props
) => ({
  audience: ['All Audiences', ...audience],
  selectedAudienceFromStore: selectedAudience,
});

const dispatchToProps = (dispatch, props) => ({
  setAudience: (selectedAudience) =>
    dispatch(setViewSettings({ selectedAudience, resetStore: true })),
});

export const AudiencePicker = connect(stateToProps, dispatchToProps)(Picker);

const adjustAudience = (audience) =>
  audience === '' ? 'All Audiences' : audience;

export const useUpdateAudience = (audience = [], current) => {
  const dispatch = useDispatch();
  const _id = 'audience-picker-button';

  const { setParams, getParams } = useSearchParams();

  const params = getParams('audience', 'display');
  const currentAudience = current || params.audience || '';

  const { selectedLabels, audienceLabels } =
    isAnalytics() && params.display !== 'library'
      ? useCreativeAnalyticsGet()
      : { selectedLabels: [] };

  return (selectedAudience) => {
    if (isAnalytics()) {
      dispatch(trackEvent(CREATIVE_ANALYTICS, CHANGE_AUDIENCE));
    }

    const isAll = selectedAudience === 'All Audiences';

    const audienceLabel = audienceLabels.find(
      (label) => label.name === selectedAudience
    );

    if (
      selectedAudience === currentAudience ||
      (isAll && currentAudience === '')
    ) {
      return null;
    }
    const update = isAll ? '' : selectedAudience;

    let result = [...selectedLabels];

    if (update?.length) {
      result = [
        ...result,
        {
          label: update,
          category: 'Audience',
          isUdc: audienceLabel?.udc,
          isAudienceLabel: true,
        },
      ];
    }

    if (isAll) {
      result = [...result].filter((label) => !audience.includes(label.label));
    }

    const udc_labels = result.filter((label) => label.isUdc);

    setParams({
      audience: update,
      labels: result
        .map((label) => `${label.category}->${label.label}`)
        .join(','),
      udc_labels: udc_labels
        .map((label) => {
          const l = label.isAudienceLabel ? label.label : label.value;
          return `${label.category}->${l}`;
        })
        .join(','),
    });

    dispatch(setViewSettings({ selectedAudience, resetStore: true }));

    return null;
  };
};
