import React from 'react';

export const EditToggle = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: 'translate(3px, 3px)'}}
    >
      <path
        d="M2.47689 1.06226H11.2519V10.7548H1.17572V5.55008V1.06226H2.47689Z"
        fill="#8EE9FF"
      />
      <rect
        x="11.6761"
        y="11.354"
        width="10.0376"
        height="10.5333"
        fill="#A19FFF"
      />
      <rect
        x="22.0875"
        y="1.06226"
        width="9.77234"
        height="9.6925"
        fill="#94FCE3"
      />
      <path
        d="M1.17578 22.6964H11.2519V33.2297H5.94669H1.17574L1.17576 27.963L1.17578 22.6964Z"
        fill="#FFA9D1"
      />
      <path
        d="M14.9989 34.0504C10.7275 34.62 2.80274 35.3647 1.41985 33.1551C0.639477 31.9082 0.639511 28.8867 0.639527 23.7879V2.98344C0.716436 1.63759 1.18771 0.946802 3.86959 0.946847C9.23058 0.946936 29.2291 0.411264 31.4506 0.857047C32.6346 1.4257 32.5621 2.25897 32.5621 5.4109C32.5621 8.73454 32.2864 12.7904 32.248 14.4439"
        stroke="#1E2B4E"
      />
      <path
        d="M0.754272 10.9831H32.3627M0.754272 22.173H21.865M21.865 1.06226V23.6727"
        stroke="#1E2B4E"
      />
      <path
        d="M18.6603 32.0233L17.4337 36.7935C17.6154 37.4749 18.2378 38.8242 19.2736 38.7697C20.5684 38.7015 24.1801 37.6794 24.7252 37.3386C25.1613 37.0661 25.9064 36.4528 26.2244 36.1802C29.6089 32.7048 36.6097 25.5223 37.5365 24.5955C38.4632 23.6688 38.6949 22.7102 38.6949 22.3467C39.0357 19.0076 35.3558 17.304 33.9929 17.1677C32.9026 17.0587 32.0849 17.6675 31.8123 17.9855C28.6322 21.2564 21.9449 28.1118 20.6365 29.3657C19.3281 30.6196 18.7739 31.6599 18.6603 32.0233Z"
        fill="#1E2B4E"
        stroke="#1E2B4E"
      />
      <path d="M11.252 33.2474V1.06226" stroke="#1E2B4E" />
    </svg>
  );
};
