import React, { useRef, useEffect, useState } from 'react';
import { abortableFetch } from '../../../utils';
import { delay } from '../../../utils';
import { useDispatch } from 'react-redux';
import {
  useApiState,
  bindFetchToRef,
} from '../../../components/Hooks/useApiState';
import { useNotifications } from '../../../components/Notification/useNotifications';
import {
  saveDerivedLabelsCategory,
  deleteDerivedLabelsCategory,
} from '../CategoryBuilder/Custom/DerivedLabels/api';
import { toast } from 'react-toastify';
import { nanoid } from 'nanoid';

const HEADERS = { 'content-type': 'application/json' };

function fetchPhrases(payload) {
  return abortableFetch(
    ['api', 'creative-analytics', payload.account_id, 'fetch-phrases'].join(
      '/'
    ),
    {
      method: 'POST',
      headers: HEADERS,
      body: JSON.stringify(payload),
    }
  );
}

export const save = (payload) => {
  return fetch(
    [
      'api',
      'creative-analytics',
      'custom-categories',
      'derived-labels',
      payload.platform_account_id,
      'save',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
};

const pollStatus = async (
  request_id,
  handleData,
  handleError,
  startLoading,
  stopLoading
) => {
  let continuePolling = true;

  const poll = async () => {
    if (!continuePolling) {
      return;
    }

    try {
      const response = await fetch(
        [
          'api',
          'creative-analytics',
          'custom-categories',
          'derived-labels',
          'check-status',
          request_id,
        ].join('/'),
        {
          method: 'GET',
          headers: HEADERS,
          credentials: 'include',
        }
      );

      const jsonData = await response.json();

      const data = jsonData?.data || jsonData;

      if (data?.status === 'IN_PROGRESS') {
        console.log('Data loading...');
        setTimeout(poll, 1000);
      } else if (data?.status === 'DONE') {
        handleData(data);
        stopLoading();
      } else {
        console.error('Error:', data);
        handleError(data.error);
        stopLoading();
      }
    } catch (error) {
      console.error('Error:', error);
      handleError(error);
      stopLoading();
    }
  };

  startLoading();
  poll();

  return {
    abort: () => {
      continuePolling = false;
    },
  };
};

export const usePolling = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const cache = useRef(null);
  const notifications = useNotifications();

  const currentPollingControl = useRef(null);

  const stopPolling = () => {
    if (currentPollingControl?.current?.abort) {
      currentPollingControl.current.abort();
    }
  };

  const fetchData = async (payload) => {
    const request_id = nanoid();
    console.log({ payload });
    stopPolling();

    // if not, send request and poll for response
    try {
      const res = await save({
        ...payload,
        request_id,
      });
      const json = await res.json();
      console.log({ json });
      await delay(5000);
      currentPollingControl.current = await pollStatus(
        request_id,
        (data) => setData(data),
        (error) => setError(error),
        () => setLoading(true),
        () => {
          setLoading(false);
          toast.dismiss('derived-category-loading');
        }
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const request = (payload) => {
    notifications.add({
      id: `derived-category-loading`,
      title: 'Please wait',
      message: 'Saving phrases',
      showEllipses: true,
    });
    setLoading(true);
    setError(null);
    setData(null);
    fetchData(payload);
  };

  return {
    postState: {
      data,
      loading,
      error,
    },
    postDispatch: request,
    stopPolling,
  };
};

export function usePhraseBuilderApi(fetchPhraseBuilderData) {
  const notifications = useNotifications();
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (payload) => withRef(fetchPhrases(payload)).then(({ data }) => data),
    {
      loading: false,
      error: null,
      data: null,
      count: 0,
    }
  );

  const { postState, postDispatch, stopPolling } = usePolling();

  const [deleteState, deleteDispatch] = useApiState(
    (payload) =>
      withRef(deleteDerivedLabelsCategory(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );


  useEffect(() => {
    if (postState.error) {
      notifications.add({
        id: `derived-category-error`,
        title: 'There was an error',
        message: postState.error.toString(),
      });
    }
  }, [postState.error]);

  useEffect(() => {
    if (deleteState.data) {
      notifications.add({
        id: `derived-category-delete-success`,
        title: 'Phrases deleted sucessfully.',
        message: successMessage,
        toastProps: {
          autoClose: 5000,
          hideProgressBar: false,
        },
      });
    }
  }, [deleteState.data]);

  useEffect(() => {
    if (deleteState.loading) {
      notifications.add({
        id: `derived-category-delete-loading`,
        title: 'Please wait',
        message: 'Deleting phrases',
        showEllipses: true,
      });
    } else {
      toast.dismiss('derived-category-delete-loading');
    }
  }, [deleteState.loading]);

  useEffect(() => {
    if (deleteState.error) {
      notifications.add({
        id: `derived-category-delete-error`,
        title: 'There was an error',
        message: deleteState.error.toString(),
      });
      toast.dismiss('derived-category-delete-loading');
    }
  }, [deleteState.error]);

  useEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);

  return {
    get: {
      ...getState,
      request: getDispatch,
    },
    post: {
      ...postState,
      request: postDispatch,
    },
    delete: {
      ...deleteState,
      request: deleteDispatch,
    },
  };
}

export const notifySaveSuccess = () => {
  const notifications = useNotifications();

  notifications.add({
    id: `derived-category-success`,
    title: 'Phrases saved!',
    // message: (
    //   <>
    //     We're refreshing your data in the background. This could take up to 5
    //     minutes. Feel free to continue building!
    //   </>
    // ),
    // showEllipses: true,
    toastProps: {
      autoClose: 6000,
      hideProgressBar: false,
    },
  });
};
