import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import LazyLoader from '../LazyLoader';
import Iframe from '../HTML_Components/Iframe';
import { Placeholder } from './Helpers';

import './styles.css';

const adjustPreview = (preview, height = 750, width = 510) =>
  preview
    .replace('="yes"', '="no"')
    .replace('="690"', `="${height}"`)
    .replace('="540"', `="${width}"`);

class Preview extends React.Component {
  constructor(props) {
    super(props);

    const { options } = this.props;

    this.state = {
      source: options[0] || null,
    };
  }

  // The default source may not be available to either
  // the constructor or componentDidMount.
  componentDidUpdate(prevProps) {
    const { options } = this.props;

    if (options[0] && !prevProps.options[0]) {
      this.setDefaultSource(options);
    }
  }

  handleSelect(selected) {
    const { options } = this.props;
    const source = !selected ? options[0] : selected;

    this.setState({ source });
  }

  setDefaultSource([first]) {
    this.setState({ source: first });
  }

  render() {
    const {
      style,
      className,
      options,
      mobileOnly,
      location,
      reportsPreview,
      height,
      width,
    } = this.props;
    const { source } = this.state;
    const wrapClass = `ad-preview-observer`;
    let mobileOptions = [];
    if (options.length) {
      mobileOptions = options.filter(
        (opt) => opt.label !== 'DESKTOP_FEED_STANDARD'
      );
    }

    const Display = !(source && source.value) ? (
      <p>No preview to display.</p>
    ) : (
      <Iframe
        src={adjustPreview(
          reportsPreview ? reportsPreview.value : source.value,
          height,
          width
        )}
        className={
          reportsPreview
            ? `ad-preview-wrapper ${location} ${reportsPreview.label}`
            : 'ad-preview-wrapper'
        }
      />
    );

    return (
      <LazyLoader placeholder={<Placeholder />} className={wrapClass}>
        <>
          {Display}

          {location !== 'reports' &&
            !window.location.pathname.includes('roadmap') && (
              <div className={`preview-select ${location}`}>
                <Select
                  value={source}
                  options={!mobileOnly ? options : mobileOptions}
                  onChange={(selected) => this.handleSelect(selected)}
                />
              </div>
            )}
        </>
      </LazyLoader>
    );
  }
}

Preview.defaultProps = {
  className: '',
  style: {},
};

Preview.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  className: PropTypes.string,
  style: PropTypes.shape({ color: PropTypes.string }),
};

export default Preview;
