export const RUNNING_ADS_BY_NAME = 'RunningAdsByName';
export const RUNNING_ADS_BY_NAME_FETCH = `[${RUNNING_ADS_BY_NAME}] FETCH`;
export const RUNNING_ADS_BY_NAME_SET = `[${RUNNING_ADS_BY_NAME}] SET`;
export const RUNNING_ADS_BY_NAME_SET_NAMES = `[${RUNNING_ADS_BY_NAME}] SET_NAMES`;

export const fetchRunningAdsByName = (ad_names) => ({
  type: RUNNING_ADS_BY_NAME_FETCH,
  data: {
    ad_names,
  },
});

export const setRunningAdsByNameSelected = (selected) => ({
  type: RUNNING_ADS_BY_NAME_SET_NAMES,
  selected,
});

export const setRunningAdsByName = (runningAds) => ({
  type: RUNNING_ADS_BY_NAME_SET,
  meta: { entity: RUNNING_ADS_BY_NAME, normalizeId: 'ad_name' },
  data: runningAds,
});
