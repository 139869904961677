import React, { useState, useMemo, useEffect, useRef } from 'react';
import usePortal from 'react-cool-portal';
import PrimaryPhraseOptions from '../../PrimaryPhraseOptions';
import { useScrollIntoView } from '../../../../hooks/useScrollIntoView';
import { useHover } from '../../../../hooks/useHover';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { PRIMARY_PHRASE } from '../../categories';

import {
  useCreativeAnalyticsGet,
  useCreativeAnalyticsSet,
} from '../../contexts';

// import '../FrequencyTable.scss';
import { Dropdown } from '../../../../components/Dropdowns';
import Tooltip from '../../../../components/Tooltip';
import IconButton from '../../../../components/IconButton';
import { useSearchParams } from '../../../../components/Hooks/search-params';
import { useUpdateAudience } from '../../../../components/Pickers/Audience';

export const Cell = ({ cell, isCompare, multiplier }) => {
  const {
    column: { id: columnId },
    row: { original: rowData },
  } = cell;
  const [cellRef, isHovered] = useHover();
  const [showMenu, setShowMenu] = useState(false);
  useClickOutside(cellRef, () => setShowMenu(false));
  const { selectedLabels, period2 } =
    useCreativeAnalyticsGet();
  const { handleSelectElement, setParams } = useCreativeAnalyticsSet();
  const isVisuals = cell.column.id === 'visuals';
  const isName = cell.column.id === 'name';
  const isOptions = cell.column.id === 'options';
  const { name, value, category, visuals } = rowData;
  const { setParams: setSearchParams, getParams } = useSearchParams();

  const updateAudience = useUpdateAudience();

  const handleSelectLabel = () => {
    const result = [
      ...selectedLabels,
      {
        label: name,
        value,
        category,
        isUdc: !!rowData.udc,
      },
    ];
    const isAudienceLabel = category === 'Audience';
    const udc_labels = result.filter((label) => label.isUdc);

    if (isAudienceLabel) {
      updateAudience(name);
    }

    setSearchParams({
      udc_labels: udc_labels
        .map((label) => {
          const l = label.category === 'Audience' ? label.label : label.value;
          return `${label.category}->${l}`;
        })
        .join(','),
      labels: result
        .map((label) => `${label.category}->${label.label}`)
        .join(','),
    });
  };

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const commonLabelsOpen = !!document.getElementById('common-labels-popover');

    if (
      ['view-labels__icon', 'fa-xmark'].some((className) =>
        e.target.classList.value.includes(className)
      ) ||
      commonLabelsOpen
    ) {
      return;
    }

    if (e.nativeEvent.button === 2) {
      switch (columnId) {
        case 'visuals':
          break;
        default:
          handleSelectElement(value, 'ad_label', isCompare);
          break;
      }
    } else if (e.nativeEvent.button === 0) {
      switch (columnId) {
        case 'visuals':
          break;
        default:
          break;
      }
    }
  };

  const handleFilter = () => {
    handleSelectLabel();
  };

  if (rowData.testing_opportunity && !isName && !isVisuals && !isOptions) {
    return (
      <td
        className={`frequency-table__cell frequency-table__cell--${cell.column.id}`}
        style={{ color: '#516B94' }}
        ref={cellRef}
      >
        -
      </td>
    );
  }

  const shouldTruncate = isName && period2;

  let Render = (
    <td
      {...cell.getCellProps()}
      className={`frequency-table__cell frequency-table__cell--${
        cell.column.id
      } ${rowData.placeholder && isCompare ? 'test-opp-placeholder' : ''} ${
        shouldTruncate ? 'truncate' : ''
      }`}
      onClick={handleClick}
      onContextMenu={handleClick}
      // ref={cellRef}
    >
      {cell.render('Cell')}
      {isName && <FilterBtn onClick={handleFilter} />}
      {rowData.placeholder && isCompare && <div class="cell-overlay"></div>}
    </td>
  );

  return (
    <>
      {(cell.column.Header === 'Median ROAS' ||
        cell.column.Header === 'Median CPA') &&
      cell.value === '$0.00' ? (
        <Tooltip
          content={
            'Note, the median is the middle of your dataset. In instances where you are running ads with 0 results, the median of the dataset might be $0.00.'
          }
        >
          <div>{Render}</div>
        </Tooltip>
      ) : (
        Render
      )}
    </>
  );
};

const FilterBtn = ({ onClick }) => {
  return (
    <Tooltip content="Filter by this label">
      <div className="label-filter-btn">
        <IconButton onClick={onClick} style={{ height: '20px', width: '20px' }}>
          +
        </IconButton>
      </div>
    </Tooltip>
  );
};
