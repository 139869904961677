import React from 'react';

export const RankingIcon = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.43071 2.01537C7.68404 1.56004 7.81071 1.33337 8.00004 1.33337C8.18937 1.33337 8.31604 1.56004 8.56937 2.01537L8.63471 2.13271C8.70671 2.26204 8.74271 2.32604 8.79871 2.36871C8.85537 2.41137 8.92537 2.42737 9.06537 2.45871L9.19204 2.48804C9.68404 2.59937 9.93004 2.65471 9.98871 2.84271C10.0474 3.03071 9.87937 3.22737 9.54404 3.61937L9.45737 3.72071C9.36204 3.83204 9.31404 3.88737 9.29271 3.95671C9.27137 4.02604 9.27871 4.10004 9.29271 4.24871L9.30604 4.38404C9.35671 4.90737 9.38204 5.16937 9.22937 5.28537C9.07604 5.40204 8.84537 5.29537 8.38471 5.08337L8.26604 5.02871C8.13471 4.96871 8.06937 4.93804 8.00004 4.93804C7.93071 4.93804 7.86537 4.96871 7.73404 5.02871L7.61537 5.08337C7.15471 5.29537 6.92404 5.40204 6.77071 5.28537C6.61737 5.16937 6.64337 4.90737 6.69404 4.38404L6.70737 4.24871C6.72137 4.10004 6.72871 4.02604 6.70737 3.95671C6.68604 3.88737 6.63804 3.83204 6.54271 3.72071L6.45604 3.61937C6.12071 3.22737 5.95271 3.03137 6.01137 2.84271C6.07004 2.65471 6.31604 2.59937 6.80804 2.48804L6.93471 2.45871C7.07471 2.42737 7.14471 2.41204 7.20137 2.36871C7.25737 2.32604 7.29337 2.26204 7.36537 2.13271L7.43071 2.01537ZM8.66671 6.66671H7.33337C6.39071 6.66671 5.91937 6.66671 5.62671 6.96004C5.33337 7.25204 5.33337 7.72337 5.33337 8.66671V14.6667H10.6667V8.66671C10.6667 7.72404 10.6667 7.25271 10.3734 6.96004C10.0814 6.66671 9.61004 6.66671 8.66671 6.66671Z"
          fill="#434C64"
        />
        <path
          opacity="0.5"
          d="M5.04004 12.96C4.74804 12.6666 4.27671 12.6666 3.33337 12.6666C2.39004 12.6666 1.91937 12.6666 1.62671 12.96C1.33337 13.252 1.33337 13.7233 1.33337 14.6666H5.33337C5.33337 13.724 5.33337 13.2526 5.04004 12.96ZM10.6667 12.6666V14.6666H14.6667V12.6666C14.6667 11.724 14.6667 11.2526 14.3734 10.96C14.0814 10.6666 13.61 10.6666 12.6667 10.6666C11.7234 10.6666 11.2527 10.6666 10.96 10.96C10.6667 11.252 10.6667 11.7233 10.6667 12.6666Z"
          fill="#434C64"
        />
      </svg>
    </div>
  );
};
