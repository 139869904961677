import React, { useState, useEffect } from 'react';
import LazyLoader from '../../../../components/LazyLoader';

import { MUTUALLY_EXCLUSIVE } from '../constants';
import { DARWIN_NAVY } from '../../../../constants';
import { LabelSelect } from './LabelSelect';
import FrameSelect from './FrameSelect';
import Tooltip from '../../../../components/Tooltip';
import { toCurrency } from '../../../../utils/numbers';

type VisualProps = {
  visual_hash: string;
  current: any;
  labelOptions: string[];
  categoryType: string;
  annotatedVisuals: any;
  handleSelect: any;
  handleMultipleSelect: any;
};

const Visual = ({
  visual_hash,
  current,
  labelOptions,
  categoryType,
  annotatedVisuals,
  predictedVisuals,
  handleSelect,
  handleMultipleSelect,
  hidden = false,
  handleBulkSelect,
  replacePredictionsWithAnnotations,
  allowLockIn,
}: VisualProps) => {
  const type = current.type;
  const isVideo = type === 'video';
  const [selectedFrame, setSelectedFrame] = useState({
    ...current.asset[0],
    index: 0,
  });
  let url = current.asset.url || current.asset.asset_url || '';
  const predictedVisual = predictedVisuals[visual_hash]?.asset;
  const framesList = isVideo ? current.asset : null;

  if (selectedFrame) url = selectedFrame.url;

  if (current.asset.thumbnail_url) {
    url = current.asset.thumbnail_url;
  }


  if (!url || !url.length) return null;

  let visual = (
    <div className="visuals-list__img">
      <p>No preview found. :(</p>
      <p>hash {visual_hash}</p>
    </div>
  );

  if (url && url.length) {
    visual = (
      <img className="visuals-list__img" src={selectedFrame.url} alt="" />
    );
  }

  const onSelect = (label) => {
    handleSelect(
      MUTUALLY_EXCLUSIVE,
      label,
      visual_hash,
      current,
      selectedFrame
    );
  };

  const onMultipleSelect = (label, selection) => {
    handleMultipleSelect(label, selection, visual_hash, current, selectedFrame);
  };

  const onBulkSelect = (selection) => {
    handleBulkSelect(visual_hash, selection, current, selectedFrame);
  };

  const onLockInPredictions = () => {
    replacePredictionsWithAnnotations({
      visual_hash,
      predictedVisual: predictedVisuals[visual_hash],
      visual: current,
    });
  };
  return (
    <LazyLoader
      className={`visuals-list__item ${hidden ? 'hidden' : ''}`}
      placeholder={<div style={{ height: '50px' }} />}
    >
      <div className="d-flex">
        <AssetDisplay
          {...{ visual, type, selectedFrame, visual_hash }}
          spend={current.spend}
        />
        <LabelSelect
          {...{
            visual_hash,
            current,
            labelOptions,
            categoryType,
            annotatedVisuals,
            onSelect,
            onMultipleSelect,
            selectedFrame,
            onBulkSelect,
            predictedVisual,
            onLockInPredictions,
            allowLockIn,
          }}
        />
      </div>
      {isVideo && (
        <FrameSelect
          framesList={framesList}
          selectedFrame={selectedFrame}
          setSelectedFrame={setSelectedFrame}
        />
      )}
    </LazyLoader>
  );
};

export default Visual;

const AssetDisplay = ({ visual, type, selectedFrame, visual_hash, spend }) => {
  const frame =
    selectedFrame.frame_num || selectedFrame.frame_num === 0
      ? selectedFrame.frame_num
      : 'thumbnail';
  const timestamp =
    frame === 'thumbnail' ? 'Thumbnail' : `Frame at 00:00:0${frame}`;

  return (
    <Tooltip
      content={
        <div>
          <p className="m-0 text-white">ID: {visual_hash}</p>
          <p className="m-0 text-white">Spend: {toCurrency(spend)}</p>
        </div>
      }
    >
      <div>
        {visual}
        {type === 'video' && (
          <p
            style={{
              marginTop: '1rem',
              color: DARWIN_NAVY,
              fontWeight: '500',
              textAlign: 'center',
            }}
          >
            <i className="fa-solid fa-film mr-2"></i>
            {timestamp}
          </p>
        )}
      </div>
    </Tooltip>
  );
};
