import React from "react";

export const Layout = {
  Row: ({ children, classExtension = "", styleExtension = {} }) => {
    return (
      <div
        className={`row mt-2 ml-5 mb-2 ${classExtension}`}
        style={styleExtension}
      >
        {children}
      </div>
    );
  },
  RightPanel: ({ children, classExtension = "", styleExtension = {} }) => {
    return (
      <div
        className={`col-3 pl-0 pr-0 ml-3 darwin-box-shadow bg-white ${classExtension}`}
        style={styleExtension}
      >
        {children}
      </div>
    );
  },
  LeftPanel: ({ children, classExtension = "", styleExtension = {} }) => {
    return (
      <div
        className={`col-8 d-flex flex-col darwin-box-shadow bg-white p-3 ${classExtension}`}
        style={styleExtension}
      >
        {children}
      </div>
    );
  },
};
