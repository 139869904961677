import React from 'react';
import './styles.scss';
import { ArrowDownIcon, ArrowUpIcon } from './Helpers';
import { Display } from './Display';
import { useCreativeAnalyticsGet } from '../../CreativeAnalytics/contexts';
import { getPreviousDates } from './utils';
import Tooltip from '../../../components/Tooltip';
import { findPercentDifference } from '../../../utils/numbers';

export const PeriodComparison = ({
  visual,
  previousPeriodData,
  metric,
  formatMetric,
  loadingPreviousPeriodData,
}) => {
  const { start_date, end_date } = useCreativeAnalyticsGet();
  const { previous_start_date, previous_end_date } = getPreviousDates(
    start_date,
    end_date
  );
  const mainValue = visual[metric];
  const comparedTo = previousPeriodData?.[metric] ?? null;

  const percentDiff = findPercentDifference(
    comparedTo,
    mainValue,
    metric.slice(0, 2) === 'cp'
  );
  const isPositive = percentDiff > 0;

  const NoneIcon = (
    <div
      style={{
        background: '#e9f0fa',
        fontWeight: 600,
        color: '#6777A2',
        userSelect: 'none',
      }}
      className="arrow-icon"
    >
      -
    </div>
  );

  const ArrowIcon = (
    <div
      style={{ background: isPositive ? '#CDF3DC' : '#F4CDCD' }}
      className="arrow-icon"
    >
      {isPositive ? <ArrowUpIcon /> : <ArrowDownIcon />}
    </div>
  );

  const TrendIcon = !percentDiff ? NoneIcon : ArrowIcon;

  return (
    <div className="ml-2" style={{ position: 'relative' }}>
      <Tooltip
        className="period-comparison-display"
        onlyUseCustomClass
        follow={false}
        content={
          <Display
            {...{
              metric,
              mainValue,
              comparedTo,
              start_date,
              end_date,
              previous_start_date,
              previous_end_date,
              isPositive,
              percentDiff,
              TrendIcon,
              formatMetric,
              loadingPreviousPeriodData,
            }}
          />
        }
      >
        {TrendIcon}
      </Tooltip>
    </div>
  );
};
