import React from 'react';

const GoogleAdsIcon = ({ className = "" }) => (
  <svg className={className} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.299 8.68199L4.615 1.09042C5.16324 1.42204 7.92777 2.99394 8.37424 3.29289L4.05824 10.8849C3.58623 11.5256 -0.299001 9.65425 0.299 8.68158V8.68199V8.68199Z" fill="#FBBC04"/>
    <path d="M12.6996 8.68184L8.38358 1.09074C7.7802 0.0590577 6.4807 -0.315864 5.41333 0.293475C4.34596 0.902814 4.02096 2.21541 4.62434 3.29316L8.94035 10.8852C9.54373 11.9164 10.8433 12.2913 11.9106 11.682C12.9314 11.0726 13.303 9.71347 12.6996 8.68273V8.68184V8.68184Z" fill="#4285F4"/>
    <path d="M2.16653 11.9754C3.36307 11.9754 4.33306 11.0036 4.33306 9.80474C4.33306 8.60592 3.36307 7.63409 2.16653 7.63409C0.969989 7.63409 0 8.60592 0 9.80474C0 11.0036 0.969989 11.9754 2.16653 11.9754Z" fill="#34A853"/>
  </svg>
)

export default GoogleAdsIcon;
