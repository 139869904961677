import React from 'react';

import { toCurrency } from '../../../../../utils/numbers';

function getCategory([item]) {
  if (!item) {
    return 'N/A';
  }
  return item.category;
}

export default function DollarCategory({ resultSet }) {
  const category = getCategory(resultSet.categories());

  return (
    <h3>
      <strong>{!Number.isNaN(category) ? toCurrency(category) : category}</strong>
    </h3>
  );
}
