import React from 'react';
import Overlay from '../../../../components/Overlay';
import Modal from '../../../../components/Modal';
import dayjs from 'dayjs';

const moment = require('moment');

const ProjectsModal = ({
  setShowProjectsModal,
  draftProjects,
  selectedProject,
  handleSelectProject,
  setShowCreateModal,
  getDraftProjectsLoading,
}) => {
  return (
    <Overlay close={() => setShowProjectsModal(false)} show>
      <Modal className="draft-projects-modal">
        <div className="close-btn" onClick={() => setShowProjectsModal(false)}>
          <i className="fa fa-close" />
        </div>
        <div className="w-100">
          {getDraftProjectsLoading ? (
            <div>Fetching projects...</div>
          ) : draftProjects.length ? (
            <ul className="projects-list">
              {draftProjects.map((project) => {
                const isSelected =
                  selectedProject &&
                  selectedProject.creative_project_name ===
                    project.creative_project_name;

                const date = project.created_time.split(' ')[0].split('/');

                const time = moment(`${project.created_time.replace('/', '-')}`)
                  .subtract({ hours: 6, minutes: 0 })
                  .format('hh:mm A');

                return (
                  <li
                    className={`projects-list__item ${
                      isSelected ? 'selected' : ''
                    }`}
                    onClick={() => {
                      handleSelectProject(project.draft_creative_project_id);
                      setShowProjectsModal(false);
                    }}
                  >
                    {project.creative_project_name}
                    <div className="date">
                      Last saved {`${date[1]}/${date[2]}/${date[0]} at ${time}`}
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            <>
              <div className="mt-2">No projects yet.</div>
              <div
                onClick={() => {
                  setShowCreateModal(true);
                  setShowProjectsModal(false);
                }}
                style={{
                  fontSize: '1.2rem',
                  color: '#039BE5',
                  cursor: 'pointer',
                  marginTop: '1rem',
                }}
              >
                <i className="fa-solid fa-circle-plus mr-2" />
                Create a project
              </div>
            </>
          )}
        </div>
      </Modal>
    </Overlay>
  );
};

export default ProjectsModal;
