import React from 'react';
import { Row, Col, FormGroup, FormLabel, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import InputHint from '../InputHint/InputHint';
import AccountPageFooter from '../AccountPageFooter/AccountPageFooter';
import validate from '../Login/validateLogin';

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const component = this;

    validate(component.form, {
      rules: {
        firstName: {
          required: true,
        },
        lastName: {
          required: true,
        },
        emailAddress: {
          required: true,
          email: true,
        },
        password: {
          required: true,
          minlength: 6,
        },
        password2: {
          required: true,
          minlength: 6,
        },
      },
      messages: {
        firstName: {
          required: "What's your first name?",
        },
        lastName: {
          required: "What's your last name?",
        },
        emailAddress: {
          required: 'Need an email address here.',
          email: 'Is this email address correct?',
        },
        password: {
          required: 'Need a password here.',
          minlength: 'Please use at least six characters.',
        },
        password2: {
          required: 'Need a password here.',
          minlength: 'Please use at least six characters.',
        },
      },
      submitHandler() {
        component.handleSubmit();
      },
    });
  }

  handleSubmit() {
    this.props.fetchRegister({
      first_name: this.firstName.value,
      last_name: this.lastName.value,
      email: this.emailAddress.value,
      password: this.password.value,
      password2: this.password2.value,
    });
  }

  render() {
    return (
      <div className="Signup">
        <Row>
          <Col xs={12} sm={6} md={5} lg={4}>
            <h4 className="page-header">Sign Up</h4>
            <form
              ref={(form) => (this.form = form)}
              onSubmit={(event) => event.preventDefault()}
            >
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <FormLabel>First Name</FormLabel>
                    <input
                      type="text"
                      name="firstName"
                      ref={(firstName) => (this.firstName = firstName)}
                      className="form-control"
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <FormLabel>Last Name</FormLabel>
                    <input
                      type="text"
                      name="lastName"
                      ref={(lastName) => (this.lastName = lastName)}
                      className="form-control"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <FormLabel>Email Address</FormLabel>
                <input
                  type="email"
                  name="emailAddress"
                  ref={(emailAddress) => (this.emailAddress = emailAddress)}
                  className="form-control"
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>Password</FormLabel>
                <input
                  type="password"
                  name="password"
                  ref={(password) => (this.password = password)}
                  className="form-control"
                />
                <InputHint>Use at least six characters.</InputHint>
              </FormGroup>
              <FormGroup>
                <FormLabel>Confirm Password</FormLabel>
                <input
                  type="password"
                  name="password2"
                  ref={(password2) => (this.password2 = password2)}
                  className="form-control"
                />
                <InputHint>Must match password.</InputHint>
              </FormGroup>
              <Button type="submit" bsstyle="success">
                Sign Up
              </Button>
              <AccountPageFooter>
                <p>
                  Already have an account? <Link to="/login">Log In</Link>.
                </p>
              </AccountPageFooter>
            </form>
          </Col>
        </Row>
      </div>
    );
  }
}

Signup.propTypes = {
  registerLogin: PropTypes.func,
};

export default Signup;
