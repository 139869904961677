import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { SubMenu } from './SubMenu';

import './MenuItem.scss';

const ColorSquare = ({ color }) => {
  return <div className="menu-item__square" style={{ background: color }} />;
};

interface MenuItemProps {
  title: string;
  icon?: JSX.Element | React.Component;
  onMenuItemClick?: (menuItem: string) => void;
  subMenu?: any;
  isSelected?: boolean;
  colorSquare?: string;
  expandByDefault?: boolean;
  exclude?: boolean;
  display?: string;
  id?: string;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  title,
  icon,
  onMenuItemClick,
  subMenu,
  colorSquare,
  isSelected = false,
  expandByDefault = true,
  exclude = false,
  display,
  id,
  className,
}) => {
  if (exclude) return null;
  const hasSubMenu = !!subMenu;
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(!!expandByDefault);

  const handleSubMenuToggle = () => {
    setIsSubMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    if (title === 'Detailed Reports') {
      if (display === 'performance_frequency') {
        setIsSubMenuOpen(true);
      } else {
        setIsSubMenuOpen(false);
      }
    }
  }, [display]);

  const handleMenuItemClick = () => {
    if (hasSubMenu) {
      handleSubMenuToggle();
    } else if (!!onMenuItemClick) {
      onMenuItemClick(title);
    }
  };

  return (
    <li
      id={id}
      className={clsx('menu-item', {
        'is-selected': isSelected,
        [className]: !!className,
      })}
    >
      <div className="menu-item-content" onClick={handleMenuItemClick}>
        <div className="d-flex align-items-center">
          {colorSquare && <ColorSquare color={colorSquare} />}

          {icon && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: '20px' }}
            >
              <span className="menu-item__icon">{icon}</span>
            </div>
          )}
          <span className={`menu-item__title ${icon ? 'ml-3' : ''}`}>
            {title}
          </span>
        </div>

        {hasSubMenu && (
          <p className="toggle-submenu">{isSubMenuOpen ? '-' : '+'}</p>
        )}
      </div>
      {hasSubMenu && isSubMenuOpen && <SubMenu subMenuContent={subMenu} />}
    </li>
  );
};
