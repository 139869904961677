import React, { useEffect, useMemo, useState } from 'react';
import './index.scss';
import CombinationsCarousel from './Carousel';
import _chunk from 'lodash/chunk';

const UntestedCombinations = ({ intelData, intelLoading, intelError }) => {
  const [combinationsData, setCombiniationsData] = useState([]);
  const [hiddenCategories, setHiddenCategories] = useState([]);
  const [hiddenLabels, setHiddenLabels] = useState([]);

  const handleHideCategory = (category) => {
    const update = [...hiddenCategories, category];
    setHiddenCategories(update);
  };

  const handleHideLabel = (label) => {
    const update = [...hiddenLabels, label];
    setHiddenLabels(update);
  };

  const resetFilters = () => {
    setHiddenCategories([]);
    setHiddenLabels([]);
  };

  useEffect(() => {
    if (intelData) {
      setCombiniationsData(intelData.combinations);
      resetFilters();
    }
  }, [intelData]);

  const chunked = useMemo(() => {
    const combos = combinationsData.filter((c) => {
      return (
        !hiddenCategories.some((category) =>
          [c.Category1, c.Category2].includes(category)
        ) && !hiddenLabels.some((label) => [c.Label1, c.Label2].includes(label))
      );
    });
    return _chunk(combos, 3);
  }, [combinationsData, hiddenCategories, hiddenLabels]);

  if (intelLoading)
    return (
      <div className="m-5 d-flex justify-content-center w-100 text-center">
        <i className="fas fa-spinner fa-spin" style={{ fontSize: '1.2rem' }} />
      </div>
    );

  if (intelError) return <div>There was an error.</div>;

  return (
    <div className="combinations">
      <CombinationsCarousel
        combinations={chunked}
        {...{ resetFilters, handleHideCategory, handleHideLabel }}
      />
    </div>
  );
};

export default UntestedCombinations;
