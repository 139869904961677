import React, { useEffect, useState, useMemo, useContext, useRef } from 'react';
import PropTypes from 'prop-types';

import { ErrorBoundary } from '../../../components/Helpers/Error';

import { useSkeletonApi } from './api';
import { useBlockParams } from './hooks';
import { BlockParamsProvider } from './Contexts';
import { isToday } from './dates';
import Button from '../../../components/Button';
import { ReportPicker, PeriodPicker, ColumnPicker } from './Pickers';
import { BlockTable, BlockRow } from './BlocksTable';
import { BlockDetails } from './BlockDetails';
import BlockHistory from './BlockHistory';
import {
  useMetricOptions,
  useHeaderProps,
  getSortedHeaders,
} from './metric-columns';
import { LEFT_BORDER } from './constants';

import './styles.scss';
import BlockFormWrapper from './BlockForm';
import { darwinDateToDate } from '../../../utils/darwin-dates';

import { TestingContext } from '../../DarwinTesting/DarwinTesting';

import {
  EfficiencyIcon,
  GoogleAdsIcon,
  DirectMailIcon,
  TikTokIcon,
} from '../../../components/Icons';
import { useDispatch } from 'react-redux';
import { trackEvent } from '../../../store/UserActivity/actions';
import { BLOCK_REPORT, LOADING } from '../../../store/UserActivity/constants';

function HeaderLayout({ className, Left, Right }) {
  return (
    <div className={className}>
      <div className="blocks-header__title d-flex flex-row justify-content-between justify-content-md-start align-items-baseline pl-0">
        {' '}
        {Left}
      </div>

      <div className="blocks-header__dates d-flex flex-column flex-md-row align-items-start justify-content-between justify-content-md-end">
        {Right}
      </div>
    </div>
  );
}

HeaderLayout.propTypes = {
  Left: PropTypes.node.isRequired,
  Right: PropTypes.node.isRequired,
};

function getFirstBlock(blocksByCategories, id) {
  for (let i = blocksByCategories.length - 1; i >= 0; i -= 1) {
    const { blocks } = blocksByCategories[i];

    const maybe = blocks.find(({ block_id }) => block_id === id);

    if (maybe) {
      return maybe;
    }
  }

  return {};
}

export const handleTestName = (str, maxLength) => {
  const ellipsis = '...';

  if (str.length > maxLength) {
    return str.slice(0, maxLength) + ellipsis;
  }
  return str;
};

export const NameTooltip = ({ text }) => {
  return (
    <div className="test-tooltip name">
      <p>{text}</p>
    </div>
  );
};

export default function BlockReport({
  creativeTest,
  handleEditTest,
  handleShowRail,
  showRail,
  isIdealImage,
}) {
  const dispatch = useDispatch();
  const [{ breakdown, period1, period2, include_ga_data }, setParams] =
    useBlockParams();
  const creativeTestPlatform = creativeTest ? creativeTest.platform : null;
  const [metrics, handleColumns] = useMetricOptions(
    creativeTestPlatform,
    isIdealImage
  );
  const { error, loading, data, request } = useSkeletonApi();

  useEffect(() => {
    dispatch(trackEvent(LOADING, BLOCK_REPORT));
  }, []);

  const skeleton = creativeTest
    ? getCreativeTestBlocks(creativeTest)
    : data
    ? data.blocks
    : [];

  function getCreativeTestBlocks(creativeTest) {
    const compare_block = {
      block_id: creativeTest.compare_block_id,
      block_name: creativeTest.compare_block_name,
      block_platforms: creativeTest.platform,
    };

    const add_block = {
      block_id: creativeTest.add_block_id,
      block_name: creativeTest.add_block_name,
      block_platforms: creativeTest.platform,
    };

    const addl_block = {
      block_id: creativeTest.addl_block_id,
      block_name: creativeTest.addl_block_name,
      block_platforms: creativeTest.platform,
    };

    let skeletonStructure = [
      {
        category: creativeTest.test_name,
        blocks: [
          {
            block_id: creativeTest.test_block_id,
            block_name: creativeTest.test_block_name,
            block_platforms: creativeTest.platform,
          },
        ],
      },
    ];
    // 2 blocks
    if (creativeTest.compare_block_id) {
      skeletonStructure[0].blocks.push(compare_block);
    }
    // 3 blocks
    if (creativeTest.add_block_id) {
      skeletonStructure[0].blocks.push(add_block);
    }
    // 4 blocks
    if (creativeTest.addl_block_id) {
      skeletonStructure[0].blocks.push(addl_block);
    }
    return skeletonStructure;
  }

  const caretDown = <i className="fa fa-caret-down ml-1" />;

  const caretUp = <i className="fa fa-caret-up ml-1" />;

  const [sortAsc, setSortAsc] = useState(false);
  const [metricKey, setMetricKey] = useState('spend');
  const [arrow, setArrow] = useState(caretDown);

  const toggleSort = (key, isBlockDetails) => {
    if (creativeTest) return;

    if (!creativeTest && isBlockDetails) {
      const keyName = key.split('-')[1];

      if (keyName !== metricKey) {
        setSortAsc(false);
      } else {
        setSortAsc((prev) => !prev);
      }
    }
  };

  useEffect(() => {
    if (!sortAsc) {
      setArrow(caretDown);
    } else {
      setArrow(caretUp);
    }
  }, [sortAsc]);

  const {
    headers,
    metricHeaders,
    numColumns,
    numMetrics,
    createRow,
    // @ts-ignore
  } = useHeaderProps({
    columns: data
      ? getSortedHeaders(breakdown, data.columns, period2, creativeTest)
      : [],
    metrics,
  });

  const [blockIds, setBlockIds] = useState([]);
  const [showBlockForm, setShowBlockForm] = useState({
    show: false,
    type: '',
    id: '',
  });

  const refreshBlocks = () => {
    if (breakdown) {
      request(breakdown);
      setBlockIds([]);
    }
  };

  useEffect(() => {
    if (creativeTest) {
      setParams({
        period1: `${creativeTest.start_date},${creativeTest.end_date}`,
        period2: '',
      });

      // deselect block when user selects different test
      setBlockIds([]);
    }
  }, [creativeTest]);

  useEffect(() => {
    refreshBlocks();
  }, [breakdown]);

  const createNewBlock = () => {
    setShowBlockForm({
      show: true,
      type: 'Create',
      id: '',
    });
  };

  const editBlock = (id) => {
    setShowBlockForm({
      show: true,
      type: 'Edit',
      id,
    });
  };

  const deleteBlock = (id) => {
    setShowBlockForm({
      show: true,
      type: 'Delete',
      id,
    });
  };

  const displayDate = (key) => {
    let period = '';
    if (key === 'Period 1') {
      period = period1;
    } else if (key === 'Period 2') {
      period = period2;
    } else {
      return '';
    }
    const [start, end] = period.split(',').map(darwinDateToDate);
    return `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`;
  };

  const { Headers, MetricHeaders } = useMemo(
    () => ({
      Headers: (isBlockDetails) => {
        return headers.map(({ key, children, colSpan }) => (
          <th key={key} colSpan={colSpan} scope="colgroup">
            {children}
            <p
              style={{
                color: isBlockDetails && !creativeTest ? '#fff' : '#1E2B4E',
                marginBottom: '0',
                fontWeight: 400,
              }}
            >
              {breakdown === 'trending' && displayDate(key)}
            </p>
          </th>
        ));
      },
      MetricHeaders: (isBlockDetails) => {
        return metricHeaders.map(({ key, children, useLeftBorder }) => (
          <th
            onClick={() => {
              setMetricKey(key.split('-')[1]);
              toggleSort(key, isBlockDetails);
            }}
            key={key}
            style={useLeftBorder ? LEFT_BORDER : null}
            className={'px-1'}
          >
            {children}
            {key.includes(metricKey) &&
              isBlockDetails &&
              !creativeTest &&
              arrow}
          </th>
        ));
      },
    }),
    [numColumns, numMetrics, period1, period2, metricKey, arrow]
  );

  const disableToday = metrics.some(({ isCustom }) => isCustom);
  const disableCustom = isToday(period1) || isToday(period2);
  const wrapperClass = creativeTest ? 'test-results-wrapper' : 'block-wrapper';

  let Header = null;

  Header = (
    <HeaderLayout
      className="blocks-header row mx-md-5 my-md-2 align-items-center justify-content-between"
      Left={
        <>
          <h2>Block Performance</h2>
          <ReportPicker />
          <ColumnPicker
            onChange={handleColumns}
            values={metrics}
            disableCustom={disableCustom}
          />
        </>
      }
      Right={
        <>
          <div className="date-block">
            <p className="date-block__label">Period 1</p>
            <PeriodPicker
              name="period1"
              disableToday={disableToday}
              isPerformanceFrequency={false}
            />
          </div>
          {breakdown === 'trending' && (
            <div className="date-block ml-md-3">
              <p className="date-block__label">Period 2</p>
              <PeriodPicker
                name="period2"
                disableToday={disableToday}
                isPerformanceFrequency={false}
              />
            </div>
          )}
        </>
      }
    />
  );

  if (creativeTest) {
    Header = (
      <HeaderLayout
        className="test-results__header"
        Left={
          <>
            <div className="test-name">
              {creativeTest.test_name.length > 44 ? (
                <NameTooltip text={creativeTest.test_name} />
              ) : null}
              <h2>{handleTestName(creativeTest.test_name, 44)}</h2>
            </div>
            {creativeTest.platform !== 'direct mail' && (
              <ColumnPicker
                onChange={handleColumns}
                values={metrics}
                disableCustom={disableCustom}
              />
            )}
          </>
        }
        Right={
          <div>
            {creativeTest.platform !== 'direct mail' && (
              <button
                type="button"
                className="btn btn-link btn-edit"
                style={{ padding: 0 }}
                onClick={() => handleEditTest(creativeTest)}
              >
                <i className="fa fa-edit" />{' '}
                <div className="btn-edit__label edit-label">Edit Test</div>
              </button>
            )}
            {skeleton[0].blocks.length == 2 ? (
              <button
                type="button"
                className="btn btn-link btn-stats stats-icon"
                style={{ padding: 0 }}
                onClick={handleShowRail}
              >
                <EfficiencyIcon className="btn-link btn-stats" />{' '}
                <div className="stats-icon__label stats-label">
                  {!showRail ? 'Show' : 'Hide'} Statistical Summary
                </div>
              </button>
            ) : null}
          </div>
        }
      />
    );
  }

  let Tables = null;

  if (error) {
    const err = error.error ? error.error : error.toString();

    Tables = (
      <>
        <p>An error occurred</p>
        <p>{err}</p>
      </>
    );
  } else if (loading) {
    Tables = <p className="ct-block-table--loading">Loading</p>;
  } else {
    Tables = (
      <BlockTable
        Headers={Headers(false)}
        MetricHeaders={MetricHeaders(false)}
        skeleton={skeleton}
        onCreateBlock={createNewBlock}
        creativeTest={creativeTest}
        renderRow={({ id, name, platform }) => {
          const selected = blockIds.indexOf(id) > -1;

          return (
            <BlockRow
              id={id}
              selected={selected}
              platforms={platform}
              Name={
                <button
                  id={`btn-${id}`}
                  type="button"
                  className={`btn btn-link text-left ${
                    selected ? 'text-white' : ''
                  }`}
                  style={{ padding: 0 }}
                  onClick={() =>
                    setBlockIds((prev) => {
                      if (selected) {
                        return prev.filter((bid) => bid !== id);
                      }
                      return creativeTest ? [id] : [...prev, id];
                    })
                  }
                >
                  {platform.includes('tiktok') ? (
                    <TikTokIcon className="block-row__tiktok-icon mr-2" />
                  ) : platform.includes('direct mail') ? (
                    <DirectMailIcon className="mr-2" />
                  ) : platform.includes('google_ads') ? (
                    <GoogleAdsIcon className="mr-2" />
                  ) : (
                    <span className="dw-tag__icon dw-tag__icon--fb d-inline-block">
                      <i className="fa-brands fa-facebook-f"></i>
                    </span>
                  )}
                  {name}
                </button>
              }
              Options={
                !creativeTest ? (
                  <div className="d-flex">
                    <button
                      onClick={() => editBlock(id)}
                      className={`btn btn-link p-0 ${
                        selected ? 'text-white' : ''
                      }`}
                    >
                      <i className="fa fa-edit" />
                    </button>
                    <button
                      onClick={() => deleteBlock(id)}
                      className={`btn btn-link p-0 ml-1 ${
                        selected ? 'text-white' : ''
                      }`}
                    >
                      <i className="fa fa-trash" />
                    </button>
                  </div>
                ) : null
              }
              numMetrics={numMetrics}
              numColumns={numColumns}
              createRow={createRow}
            />
          );
        }}
      />
    );
  }

  return (
    <div className={wrapperClass}>
      <ErrorBoundary>
        <BlockParamsProvider
          breakdown={breakdown}
          period1={period1}
          period2={period2}
          include_ga_data={include_ga_data}
          setParams={setParams}
        >
          {showBlockForm.show === true && (
            <BlockFormWrapper
              formType={showBlockForm.type}
              blockId={showBlockForm.id}
              refreshBlocks={refreshBlocks}
              onClose={() =>
                setShowBlockForm({
                  show: false,
                  type: '',
                  id: '',
                })
              }
            />
          )}
          {Header}
          <div className="row position-relative mx-0" id="block-report-row">
            <div
              id="blocks-table"
              className={`col-12 ${
                !creativeTest ? 'col-md-7' : ''
              } mr-2 darwin-box-shadow bg-white`}
              style={{
                padding: '1.25rem',
                overflow: 'auto',
                maxHeight: '80vh',
              }}
            >
              {Tables}
            </div>

            {!creativeTest && (
              <BlockHistory ids={blockIds} onClose={() => setBlockIds([])} />
            )}
          </div>

          {breakdown === 'trending' && blockIds.length === 1 && data && (
            <>
              <div className="row my-2">
                <div
                  className={`col-12 ${
                    !creativeTest ? 'col-md-7' : ''
                  } mr-2 pl-0`}
                >
                  <h4 className="my-3 ml-3">
                    Selected Block: &ensp;
                    <strong>
                      {getFirstBlock(data.blocks, blockIds[0]).block_name}
                    </strong>
                  </h4>
                </div>
              </div>
              <div className="row my-2">
                <div
                  className={`col-12 ${
                    !creativeTest ? 'col-md-7' : ''
                  } mr-2 darwin-box-shadow bg-white py-2`}
                  style={{ overflow: 'auto', maxHeight: '80vh' }}
                >
                  <BlockDetails
                    blockId={blockIds[0]}
                    Headers={Headers(true)}
                    MetricHeaders={MetricHeaders(true)}
                    metrics={metrics}
                    creativeTest={creativeTest}
                    sortAsc={sortAsc}
                    metricKey={metricKey}
                  />
                </div>
              </div>
            </>
          )}
        </BlockParamsProvider>
      </ErrorBoundary>
    </div>
  );
}
