import _isEqual from 'lodash/isEqual';
import { useDispatch } from 'react-redux';
import { setViewSettings } from '../../../store/ViewSettings/actions';
import { useEffect } from 'react';

export const useLabelSelection = ({
  selectedLabels,
  selectedAudience,
  setSearchParams,
  setParams,
}) => {
  const dispatch = useDispatch();

  const handleSelectLabel = (selected) => {
    const isAudienceLabel = selected.category === 'Audience';

    const result = [
      ...selectedLabels,
      {
        label: selected.name ?? selected.label,
        value: selected.value,
        category: selected.category,
        visuals: selected.visual_ids,
        isUdc: selected.udc,
        isAudienceLabel,
      },
    ];
    const udc_labels = result.filter((label) => label.isUdc);

    const updatedLabelsParam = result
      .map((label) => `${label.category}->${label.label}`)
      .join(',');

    if (isAudienceLabel) {
      dispatch(
        setViewSettings({
          selectedAudience: selected.name ?? selected.label,
          resetStore: true,
        })
      );
      setSearchParams({
        audience: selected.name ?? selected.label,
        labels: updatedLabelsParam,
      });
    }

    setSearchParams({
      udc_labels: udc_labels
        .map((label) => {
          const l = label.isAudienceLabel ? label.label : label.value;
          return `${label.category}->${l}`;
        })
        .join(','),
      labels: updatedLabelsParam,
    });
  };

  return {
    handleSelectLabel,
    handleFilter: (i) => {
      // remove label by clicking
      let result = [];
      const isAudienceLabel = selectedLabels?.[i]?.category === 'Audience';

      if (i < 0) {
        result = [];
      } else if (i !== selectedLabels.length - 1) {
        result = selectedLabels.slice(0, i + 1);
      } else {
        result = selectedLabels.slice(0, i);
      }

      const udc_labels = result.filter((label) => label.isUdc);
      const updatedLabelsParam = result
        .map((label) => `${label.category}->${label.label}`)
        .join(',');

      setSearchParams({
        udc_labels: udc_labels
          .map((label) => {
            const l = label.isAudienceLabel ? label.label : label.value;
            return `${label.category}->${l}`;
          })
          .join(','),
        labels: updatedLabelsParam,
      });

      const last = selectedLabels[selectedLabels.length - 1];

      if (last && (last.label === selectedAudience || i < 0)) {
        setSearchParams({ audience: '', labels: '', udc_labels: '' });
      }

      if (isAudienceLabel) {
        dispatch(
          setViewSettings({
            selectedAudience: 'All Audiences',
            resetStore: true,
          })
        );
        setSearchParams({
          audience: '',
          labels: updatedLabelsParam,
          udc_labels: udc_labels
            .map((label) => {
              const l = label.isAudienceLabel ? label.label : label.value;
              return `${label.category}->${l}`;
            })
            .join(','),
        });
      }
    },
  };
};
