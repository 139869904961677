import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useCreateCategoryAsync } from '../api/createCategory';
import {
  useRunLabelProcessor,
  useRunLabelProcessorAsync,
} from '../api/runLabelProcessor';
import { useUpdateOneHashApi } from '../api/updateOneHash';
import { collapseToast, toast } from 'react-toastify';
import { CloseIcon } from '../../../components/Icons';
import { DARWIN_NAVY } from '../../../constants';

import {
  makePostPayload,
  makeDeletePayload,
  makeRunPredictionsPayload,
  makeUpdateOnePayload,
} from './payloads';
import { useInterval } from '../../../hooks/useInterval';
import { useRunLabelProcessorStatus } from '../api/checkPredictionStatus';
import { useDarwinClientId, usePlatform, useUserId } from './selectors';
import { useCreateCategoryStatus } from '../api/checkCreateStatus';
import { makeRequestId } from './utils';
import { customNanoId } from '../../../utils/nanoid';
import { useAnalyticsParams, useDatePeriods } from '../hooks';
import { useCreativeAnalyticsGet } from '../contexts';
import { useViewSettings } from '../../../selectors';
import { makeVisualsObject } from './Custom/AIDriven/utils';

type ProcessObject = {
  category: string;
  isSingle: boolean;
  payload: any;
  shouldTrain: boolean;
  batchedIndex?: number;
  batchedAmount?: number;
  changedLabels?: string[];
  isValid: boolean;
  category_id?: string;
};

type AsyncCCCProcessProps = {
  process: ProcessObject;
  remove: any;
  setEditCategory: any;
  fetchPerformanceData: any;
  processes: any;
  ongoingBatched: boolean;
};

const AsyncCCCProcess = ({
  process,
  processIndex,
  remove,
  setEditCategory,
  fetchPerformanceData,
  processes,
  ongoingBatched,
  isLastItem,
  isFirstItem,
}: AsyncCCCProcessProps) => {
  const {
    category,
    shouldTrain,
    isValid,
    category_id,
    shouldNotify = true,
    onlyNotifyOnce = false,
  } = process;
  const createCategoryAsync = useCreateCategoryAsync();
  const createCategoryStatus = useCreateCategoryStatus();
  const runLabelProcessorAsync = useRunLabelProcessorAsync();
  const runLabelProcessorStatus = useRunLabelProcessorStatus();
  const { viewId: view_id } = useViewSettings();
  const darwin_client_id = useDarwinClientId();
  const platform = usePlatform();
  const user_id = useUserId();
  const updateOne = useUpdateOneHashApi();
  const [pollingCreate, setPollingCreate] = useState(false);
  const [polling, setPolling] = useState(false);
  const [predictionsPayload, setPredictionsPayload] = useState({});
  const { period1, period2, allVisualsList } = useCreativeAnalyticsGet();
  const [period1Start, period1End, period2Start, period2End] = useDatePeriods(
    period1,
    period2
  );

  const createToastId = `create-toast-${category}`;
  const updateOneToastId = `update-one-toast-${category}`;
  const trainToastId = `train-toast-${category}`;
  const trainSuccessToastId = `train-toast-success-${category}`;
  const errorToastId = `error-toast-${category}`;
  const successToastId = `success-toast-${category}`;
  const successToastUpload = `success-toast-${category}`;

  const nanoid = customNanoId();

  const requestId = useMemo(() => nanoid(), []);
  const predictionsRequestId = useMemo(() => nanoid(), []);

  const unmount = () => remove(process.category);

  const refresh = () => {
    if (processes.length > 1) {
      if (
        window.confirm(
          'Refreshing will abort any other requests still in progress.'
        )
      ) {
        remove();
        fetchPerformanceData();
        toast.dismiss();
      }
    } else {
      remove();
      fetchPerformanceData();
      toast.dismiss();
    }
  };

  const openCategoryBuilder = () => {
    setEditCategory({
      category,
      ccc_type: 'ai_driven',
    });
  };

  const Create = () => {
    let text = (
      <h4 className="toast__subtitle loading-text">Saving category</h4>
    );

    return (
      <div className="toast__content">
        <h3 onClick={openCategoryBuilder} className="toast__title">
          {process.category}
        </h3>
        {text}
      </div>
    );
  };

  const SavingMultiple = () => {
    let text = <h4 className="toast__subtitle loading-text">Please wait</h4>;

    return (
      <div className="toast__content">
        <h3 onClick={openCategoryBuilder} className="toast__title">
          Saving categories
        </h3>
        {text}
      </div>
    );
  };

  const CreateSuccess = () => {
    return (
      <div className="toast__content">
        <h3 onClick={openCategoryBuilder} className="toast__title">
          {process.category}
        </h3>
        <h4 className="toast__subtitle loading-text">Category created.</h4>
      </div>
    );
  };

  const Train = () => {
    return (
      <div className="toast__content">
        <h3 onClick={openCategoryBuilder} className="toast__title">
          {process.category}
        </h3>
        <h4 className="toast__subtitle loading-text">Running predictions</h4>
      </div>
    );
  };

  const TrainSuccess = () => {
    return (
      <div className="toast__content">
        <h3 onClick={openCategoryBuilder} className="toast__title">
          {process.category}
        </h3>
        <h4 className="toast__subtitle">
          Training complete.{' '}
          <span
            style={{
              color: '#e1e3ff',
              cursor: 'pointer',
            }}
            onClick={refresh}
          >
            Refresh
          </span>{' '}
          to see results.
        </h4>
      </div>
    );
  };

  const SaveSuccess = () => {
    return (
      <div className="toast__content">
        <h3 onClick={openCategoryBuilder} className="toast__title">
          {process.category}
        </h3>
        <h4 className="toast__subtitle">
          Changes have been made to the labels.{' '}
          <span
            style={{
              color: '#e1e3ff',
              cursor: 'pointer',
            }}
            onClick={refresh}
          >
            Refresh
          </span>{' '}
          to see results.{' '}
          {isValid && (
            <>
              <span
                style={{
                  color: '#e1e3ff',
                  cursor: 'pointer',
                }}
                onClick={() => trainFuncRef.current(predictionsPayload)}
              >
                Click here
              </span>{' '}
              to retrain the model to improve the accuracy moving forward.
            </>
          )}
        </h4>
      </div>
    );
  };

  const SaveSuccessMultiple = () => {
    return (
      <div className="toast__content">
        <h3 onClick={openCategoryBuilder} className="toast__title">
          Success!
        </h3>
        <h4 className="toast__subtitle">
          Changes have been made to the labels.{' '}
          <span
            style={{
              color: '#e1e3ff',
              cursor: 'pointer',
            }}
            onClick={refresh}
          >
            Refresh
          </span>{' '}
          to see results.{' '}
          {isValid && (
            <>
              <span
                style={{
                  color: '#e1e3ff',
                  cursor: 'pointer',
                }}
                onClick={() => trainFuncRef.current(predictionsPayload)}
              >
                Click here
              </span>{' '}
              to retrain the model to improve the accuracy moving forward.
            </>
          )}
        </h4>
      </div>
    );
  };

  const Error = ({ msg }) => {
    return (
      <div className="toast__content">
        <h3 onClick={openCategoryBuilder} className="toast__title">
          {process.category}
        </h3>
        <h4 className="toast__subtitle">Error: {msg}</h4>
      </div>
    );
  };

  const notifyCreate = () =>
    toast(<Create />, {
      position: 'top-right',
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: true,
      progress: undefined,
      closeButton: <CloseIcon className="toast__close" />,
      style: {
        background: DARWIN_NAVY,
        padding: 0,
      },
      toastId: createToastId,
    });

  const notifyCreateSuccess = () =>
    toast(<CreateSuccess />, {
      position: 'top-right',
      autoClose: 10000,
      closeOnClick: false,
      draggable: true,
      progress: undefined,
      onClose: () => unmount(),
      closeButton: <CloseIcon className="toast__close" />,
      style: {
        background: DARWIN_NAVY,
        padding: 0,
      },
      toastId: createToastId,
    });

  const notifyUpdateOne = () =>
    toast(<Create />, {
      position: 'top-right',
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: true,
      progress: undefined,
      closeButton: <CloseIcon className="toast__close" />,
      style: {
        background: DARWIN_NAVY,
        padding: 0,
      },
      toastId: updateOneToastId,
    });

  const notifyBatched = () =>
    toast(<SavingMultiple />, {
      position: 'top-right',
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: true,
      progress: undefined,
      closeButton: <CloseIcon className="toast__close" />,
      style: {
        background: DARWIN_NAVY,
        padding: 0,
      },
      toastId: updateOneToastId,
    });

  const notifyTrain = () =>
    toast(<Train />, {
      position: 'top-right',
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: true,
      progress: undefined,
      closeButton: <CloseIcon className="toast__close" />,
      style: {
        background: DARWIN_NAVY,
        padding: 0,
      },
      toastId: trainToastId,
    });

  const notifyTrainSuccess = () =>
    toast(<TrainSuccess />, {
      position: 'top-right',
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: true,
      progress: undefined,
      onClose: () => unmount(),
      closeButton: <CloseIcon className="toast__close" />,
      style: {
        background: DARWIN_NAVY,
        padding: 0,
      },
      toastId: trainSuccessToastId,
    });

  const notifySaveSuccess = () =>
    toast(<SaveSuccess />, {
      position: 'top-right',
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: true,
      progress: undefined,
      closeButton: <CloseIcon className="toast__close" />,
      style: {
        background: DARWIN_NAVY,
        padding: 0,
      },
      toastId: successToastId,
    });

  const notifySaveSuccessMultiple = () =>
    toast(<SaveSuccessMultiple />, {
      position: 'top-right',
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: true,
      progress: undefined,
      closeButton: <CloseIcon className="toast__close" />,
      style: {
        background: DARWIN_NAVY,
        padding: 0,
      },
      toastId: successToastId,
    });

  const notifyError = (msg) =>
    toast(<Error msg={msg} />, {
      position: 'top-right',
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: true,
      progress: undefined,
      onClose: () => unmount(),
      closeButton: <CloseIcon className="toast__close" />,
      className: 'error-toast',
      style: {
        background: DARWIN_NAVY,
        padding: 0,
      },
      toastId: errorToastId,
    });

  const startCreatePolling = () => {
    setTimeout(function () {
      setPollingCreate(true);
    }, 5000);
  };

  const startPredictionsPolling = () => {
    setTimeout(function () {
      setPolling(true);
    }, 5000);
  };

  const handleCreate = (createPayload) => {
    createCategoryAsync.request({
      ...createPayload,
      request_id: requestId,
    });
    startCreatePolling();
    notifyCreate();
  };

  const handleTrain = () => {
    toast.dismiss(successToastId);
    notifyTrain();
    runLabelProcessorAsync.request(predictionsPayload);
    startPredictionsPolling();
  };

  const trainFuncRef = useRef(null);
  trainFuncRef.current = handleTrain;
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    if (process) {
      if (process.isSingle) {
        handleUpdateOne(process.postPayload);
      } else {
        handleCreate(process.postPayload);
      }
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleUpdateOne = (updateOnePayload) => {
    const delay = processIndex * 2000;

    setTimeout(() => {
      updateOne.request(updateOnePayload);
    }, delay);
  };

  useEffect(() => {
    if (ongoingBatched) {
      if (process.batchedIndex === 0) {
        notifyCreate();
      }
    }
  }, [ongoingBatched]);

  useEffect(() => {
    if (!ongoingBatched) {
      if (updateOne.loading) {
        if (shouldNotify && !onlyNotifyOnce) {
          notifyUpdateOne();
        } else if (onlyNotifyOnce && isFirstItem) {
          notifyBatched();
        }
      } else {
        toast.dismiss(updateOneToastId);
      }
    }
  }, [updateOne.loading]);

  useInterval(
    async () => {
      if (polling) {
        console.log('checking prediction status 😳🥶🤫');
        runLabelProcessorStatus.request({
          darwin_client_id,
          view_id,
          category: process.category,
          category_id,
          platform,
          request_id: predictionsRequestId,
        });
      }
    },
    polling ? 15000 : null
  );

  useEffect(() => {
    if (runLabelProcessorStatus.data) {
      const { data } = runLabelProcessorStatus;
      if (
        data.Message &&
        data.Message === 'Predictions are still being made.'
      ) {
        console.log('Predictions 🍬 are 🤩 still 🥵 being 💯 made ⭐');
      } else if (data.Message) {
        toast.dismiss(trainToastId);
        notifyError(data.Message);
        setPolling(false);
        unmount();
      } else {
        toast.dismiss(trainToastId);
        notifyTrainSuccess();
        setPolling(false);
        unmount();
      }
    }
  }, [runLabelProcessorStatus.data]);

  useEffect(() => {
    if (runLabelProcessorStatus.error) {
      const { error } = runLabelProcessorStatus;
      if (error.error) {
        toast.dismiss(trainToastId);
        notifyError(error.error);
        setPolling(false);
        unmount();
      }
    }
  }, [runLabelProcessorStatus.error]);

  useInterval(
    async () => {
      if (pollingCreate) {
        createCategoryStatus.request({
          view_id,
          darwin_client_id,
          category: process.category,
          category_id,
          platform,
          request_id: requestId,
          start_date: period1Start,
          end_date: period1End,
        });
      }
    },
    pollingCreate ? 5000 : null
  );

  useEffect(() => {
    if (createCategoryStatus.data) {
      const { data } = createCategoryStatus;

      if (
        data.Message &&
        data.Message === 'Custom category is still being created.'
      ) {
        console.log('Custom 🍬 category 🤩 is 🥵 still 💯 being ⭐ created 💧');
      }

      if (data.ccc_doc) {
        const {
          darwin_category_name,
          darwin_client_id,
          platform,
          annotated_visuals,
          compliance,
          category_name,
          category_id,
        } = data.ccc_doc;

        const visuals = makeVisualsObject(allVisualsList);

        const unannotated_visuals = {};

        Object.keys(visuals).forEach((key) => {
          if (!annotated_visuals[key]) {
            unannotated_visuals[key] = visuals[key];
          }
        });

        const payload = makeRunPredictionsPayload({
          darwin_category_name,
          darwin_client_id,
          platform,
          unannotated_visuals,
          compliance,
          annotated_visuals,
          category: category_name,
          changedLabels: [...process.changedLabels],
          user_id: user_id,
          service_type: 'predictions',
          predictionsRequestId,
          category_id,
        });

        setPredictionsPayload(payload);

        if (!shouldTrain) {
          toast.dismiss(createToastId);
          notifySaveSuccess();
          setPollingCreate(false);
          unmount();
        } else {
          if (Object.keys(unannotated_visuals).length < 1) {
            toast.dismiss(createToastId);
            notifyCreateSuccess();
            setPollingCreate(false);
            unmount();
          } else {
            toast.dismiss(createToastId);
            notifyTrain();
            setPollingCreate(false);
            runLabelProcessorAsync.request(payload);
            startPredictionsPolling();
          }
        }
      }
    }
  }, [createCategoryStatus.data]);


  useEffect(() => {
    if (isMounted.current && updateOne.data) {
      if (
        !ongoingBatched ||
        process.batchedIndex === process.batchedAmount - 1
      ) {
        const { data } = updateOne;
        const isBatched = process.batchedIndex === process.batchedAmount - 1;

        const {
          darwin_category_name,
          darwin_client_id,
          platform,
          annotated_visuals,
          compliance,
        } = data;

        const visuals = makeVisualsObject(allVisualsList);

        const unannotated_visuals = {};

        Object.keys(visuals).forEach((key) => {
          if (!annotated_visuals[key]) {
            unannotated_visuals[key] = visuals[key];
          }
        });

        const payload = makeRunPredictionsPayload({
          darwin_category_name,
          darwin_client_id,
          platform,
          unannotated_visuals,
          compliance,
          annotated_visuals,
          changedLabels: [...process.changedLabels],
          user_id: user_id,
          service_type: 'predictions',
          predictionsRequestId,
          category_id,
        });

        setPredictionsPayload(payload);

        if (!shouldTrain && !isBatched) {
          toast.dismiss(createToastId);
          if (shouldNotify) notifySaveSuccess();

          if (onlyNotifyOnce && isLastItem) {
            notifySaveSuccessMultiple();
          }

          setPollingCreate(false);
          unmount();
          return;
        }

        if (isBatched) {
          toast.dismiss(createToastId);
          setPollingCreate(false);
          if (shouldNotify) notifySaveSuccess();

          if (onlyNotifyOnce && isLastItem) {
            notifySaveSuccess();
          }

          unmount();
          return;
        }
        notifyTrain();
        runLabelProcessorAsync.request(payload);
        setPolling(true);
      }
    }
  }, [updateOne.data]);

  useEffect(() => {
    if (createCategoryStatus.error) {
      setPollingCreate(false);
      notifyError(createCategoryStatus.error.error);
      toast.dismiss(createToastId);
      unmount();
    }
    if (runLabelProcessorAsync.error) {
      setPolling(false);
      notifyError(runLabelProcessorAsync.error.error);
      toast.dismiss(trainToastId);
      unmount();
    }
    if (updateOne.error) {
      notifyError(updateOne.error.error);
      toast.dismiss(updateOneToastId);
      unmount();
    }
  }, [
    createCategoryStatus.error,
    runLabelProcessorAsync.error,
    updateOne.error,
  ]);

  return null;
};

export default AsyncCCCProcess;
