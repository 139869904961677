import { useRef, useEffect } from 'react';
import { abortableFetch } from '../../../../../utils';
import {
  useApiState,
  bindFetchToRef,
} from '../../../../../components/Hooks/useApiState';
import { toast } from 'react-toastify';
import { useNotifications } from '../../../../../components/Notification/useNotifications';
import { notifyCreateSuccess } from './utils';

const HEADERS = { 'content-type': 'application/json' };

function getAdNameLabels(account_id) {
  return abortableFetch(
    [
      'api',
      'creative-analytics',
      'custom-categories',
      account_id,
      'fetch-ad-name-labels',
    ].join('/'),
    {
      method: 'GET',
      headers: HEADERS,
      credentials: 'include',
    }
  );
}

export function useGetAdNameLabelsApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (account_id) =>
      withRef(getAdNameLabels(account_id)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    ...getState,
    request: getDispatch,
  };
}

function postCreateCategory(payload) {
  return abortableFetch(
    [
      'api',
      'creative-analytics',
      'custom-categories',
      payload.account_id || payload.platform_account_id,
      'create-edit-ad-name-labels',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useAdNameLabelsApi(
  fetchPerformanceData,
  getAdNameLabelsApi,
  account_id
) {
  const { add: addNotification } = useNotifications();
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(postCreateCategory(payload)).then((data) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    if (postState.data) {
      setTimeout(() => {
        notifyCreateSuccess(fetchPerformanceData);
        getAdNameLabelsApi.request(account_id);
      }, 15000);
    }
  }, [postState.data]);

  useEffect(() => {
    if (postState.loading) {
      addNotification({
        id: `derived-category-loading`,
        title: 'Please wait',
        message: 'Saving category',
        showEllipses: true,
      });
    } else {
      setTimeout(() => {
        toast.dismiss('derived-category-loading');
      }, 15000);
    }
  }, [postState.loading]);

  useEffect(() => {
    if (postState.error) {
      addNotification({
        id: `derived-category-error`,
        title: 'There was an error',
        message: postState.error.toString(),
      });
      toast.dismiss('derived-category-loading');
    }
  }, [postState.error]);

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    ...postState,
    request: postDispatch,
  };
}
