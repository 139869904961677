import { useEffect, useReducer } from 'react';
import { reducer } from './reducer';

export const useLibrary = () => {
  const [{ labels, selectedLabels, adNames, selectedAdNames }, dispatch] =
    useReducer(reducer, {
      labels: [],
      selectedLabels: [],
      adNames: [],
      selectedAdNames: [],
    });

  return {
    library: {
      labels,
      selectedLabels,
      adNames,
      selectedAdNames,
    },
    dispatchLibrary: dispatch,
  };
};
