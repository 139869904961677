export const toOptions = (data) => {
  return data.map(({ name, id, category }) => {
    return {
      label: name,
      value: id,
      category: category,
    };
  });
};

export const withoutPrefix = (account_id) => {
  return account_id.replace('act_', '');
};

export const parseCurrency = (c) => {
  return Number(c.replace(/[^0-9.-]+/g, ''));
};
