import React from "react";
import {
  GripIcon,
  RecommendationsIcon,
  ReportCardIcon,
} from "../../../../components/Icons";
import { STANDARD_CATEGORIES_LOOKUP } from "../../categories";
import "./CategoryItem.scss";
import { Bar } from "../../LeftDisplay/icons/bar";
import Tooltip from "../../../../components/Tooltip";

const CategoryItem = ({
  category_name,
  enabled,
  isDragging,
  isDraggingOver,
  handleToggle,
}) => {
  const isTopTen = category_name === "Top 10 Spending Visuals";

  const Toggles = (
    <div className="category-editor__item__toggles">
      <Tooltip
        content={`Show this category in the${!isTopTen ? " report card and" : ""} performance frequency report`}
        delay={[500, 0]}
      >
        <button
          className={`category-editor__toggle-btn mr-2 ${
            enabled.report_card ? "is-enabled" : ""
          }`}
          onClick={() => handleToggle(category_name, "report_card")}
        >
          {!isTopTen ? <ReportCardIcon /> : <Bar />}
        </button>
      </Tooltip>
      {/* <Tooltip
        content="Enable suggestions for this category"
        delay={[500, 0]}
      >
        <button
          className={`category-editor__toggle-btn ${
            enabled.recommendations ? "is-enabled" : ""
          }`}
          onClick={() => handleToggle(category_name, "recommendations")}
        >
          <RecommendationsIcon />
        </button>
      </Tooltip> */}
    </div>
  );
  return (
    <div
      className={`category-editor__item ${isDragging ? "is-dragging" : ""} ${
        isDraggingOver ? "is-dragging-over" : ""
      }`}
    >
      <div className="d-flex align-items-center">
        {Toggles}
        <p className="category-editor__item__title">
          {STANDARD_CATEGORIES_LOOKUP[category_name] || category_name}
        </p>
      </div>

      <div className="d-flex align-items-center justify-content-end">
        <GripIcon className="grip-icon ml-3" />
      </div>
    </div>
  );
};

export default CategoryItem;
