import { connect } from "react-redux";
import { fetchAdBoost } from "../../../store/background/api.ad-boost";
import { toDarwinDate } from "../../../utils/darwin-dates";

const stateToProps = (
  { Adsets: { byNames, adsets }, ViewSettings: { start_date, end_date } },
  props
) => {
  const period = `${toDarwinDate(start_date)} - ${toDarwinDate(end_date)}`;
  const boundToOption = name => toOption(adsets, period, name);

  return {
    adsetOptions: byNames.map(boundToOption)
  };
};

const toOption = (lookup, period, name) => {
  const { spend, cpr, adset_id, campaign_id } = lookup[name];

  return {
    period,
    x: isNaN(spend) || spend === null ? 0 : parseFloat(spend.toFixed(2)),
    y: isNaN(cpr) || cpr === null ? Infinity : parseFloat(cpr.toFixed(2)),
    label: name,
    value: name,
    adset_id,
    campaign_id
  };
};

const dispatchToProps = (dispatch, { viewId }) => ({
  boostAd: ({ ad_name, adset_name, budget, campaign_id, adset_id }) => {
    dispatch(
      fetchAdBoost({
        viewId,
        ad_name,
        adset_name,
        budget,
        adset_id,
        campaign_id
      })
    );
  }
});

export default connect(stateToProps, dispatchToProps);
