import React, { useEffect, useRef, useState } from 'react';
import { ADD_ROW, useVariationState } from './hooks/useVariationState';
import VariationContextProvider from './contexts/variationContext';
import VariationRow from './components/Row/Row';
import Button from '../../../components/Button';
import { createShareUrl } from './utils/createShareUrl';
import Header from './Header';
import { useViewId } from '../selectors';
import { useCreativeAnalyticsGet } from '../contexts';
import { useMetricOptions } from '../hooks/useMetricOptions';
import Preprocess from '../Clusters/Preprocess';
import usePreprocess from '../Clusters/hooks/usePreprocess';
import { makePreprocessPayload } from '../Clusters/utils';

const Wrapper = ({ isROAS }) => {
  const [activeRow, setActiveRow] = useState(0);
  const [filterFavorites, setFilterFavorites] = useState(false);
  const [copied, setCopied] = useState(false);
  const [copiedText, setCopiedText] = useState('');
  const [showContent, setshowContent] = useState(false);
  const ref = useRef();
  const variationState = useVariationState();
  const {
    variationState: { rows },
    variationDispatch,
  } = variationState;
  const viewId = useViewId();
  const metricOptions = useMetricOptions();
  const { primary_metric, clustersApi, allVisuals } = useCreativeAnalyticsGet();
  const {
    save: { request: preprocessRequest },
  } = clustersApi;
  const [
    preprocessLoading,
    preprocessError,
    preprocessData,
    preprocessSuccess,
  ] = usePreprocess();

  const handleFocus = (e) => {
    e.target.select();
    // execCommand is deprecated; only used for the workaround
    document.execCommand('copy');
  };

  useEffect(() => {
    ref.current?.focus();
  }, [copiedText]);

  const copyTextToClipboard = (text) => {
    // navigator.clipboard only exists on localhost or https (does not work in dev environment)
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(
        function () {
          console.log('Async: Copying to clipboard was successful!');
          setCopied(true);

          setTimeout(() => {
            setCopied(false);
          }, 3000);
        },
        function (err) {
          console.error('Async: Could not copy text: ', err);
        }
      );
    } else {
      setCopiedText(text);
      console.log(ref.current?.value);
      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  };

  const handleShareReport = () => {
    const filteredRows = rows
      .filter((row) => hasFavorites(row.variantData))
      .map((row) => {
        return {
          ...row,
          variantData: row.variantData.filter((v) => v.data.favorite),
          primaryMetricLabel:
            metricOptions.find((x) => x.value === row.primaryMetric)?.label ||
            row.primaryMetric,
        };
      });

    copyTextToClipboard(createShareUrl(filteredRows, viewId, isROAS));
  };

  const hasFavorites = (variantData) => {
    return variantData.some(
      (variant) => !!variant.data && variant.data.favorite
    );
  };

  const handlePreprocess = () => {
    const payload = makePreprocessPayload(viewId, allVisuals);
    preprocessRequest({ all_visuals: payload });
  };

  useEffect(() => {
    if (preprocessSuccess) {
      setTimeout(() => {
        setshowContent(true);
      }, 1500);
    }
  }, [preprocessSuccess]);

  return (
    <VariationContextProvider {...{ variationState, variationDispatch }}>
      <div className="variation">
        <Header
          rows={rows}
          {...{
            copied,
            handleShareReport,
            filterFavorites,
            setFilterFavorites,
            hasFavorites,
          }}
        />
        <div className={`variation__preprocess ${!showContent ? 'show' : ''}`}>
          <Preprocess
            isVariationReport={true}
            loading={preprocessLoading}
            error={preprocessError}
            data={preprocessData}
            success={preprocessSuccess}
            handlePreprocess={handlePreprocess}
          />
        </div>
        <div className={`variation__content ${showContent ? 'show' : ''}`}>          
          {rows?.length ? (
            rows?.map((row, index) => {
              return (
                <VariationRow
                  variantData={row.variantData}
                  rowIndex={index}
                  isSharedReport={false}
                  primary_metric={primary_metric}
                  rowMetric={row.primaryMetric}
                  primaryMetricLabel={
                    metricOptions.find((x) => x.value === row.primaryMetric)
                      ?.label || row.primaryMetric
                  }
                  sharedRows={null}
                  {...{
                    isROAS,
                    activeRow,
                    setActiveRow,
                    filterFavorites,
                  }}
                />
              );
            })
          ) : (
            <div>No data</div>
          )}
          <Button
            onClick={() => {
              variationDispatch({
                type: ADD_ROW,
              });
              setActiveRow(rows.length + 1);
            }}
            disabled={rows.some((row) => row.loading)}
          >
            Add row
          </Button>
          <div className="clipboard-workaround">
            <input
              ref={ref}
              type="text"
              value={copiedText}
              style={{ position: 'absolute', left: '-5000px' }}
              onFocus={handleFocus}
            />
          </div>
        </div>
      </div>
    </VariationContextProvider>
  );
};

export default Wrapper;
