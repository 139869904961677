export const makePreprocessPayload = (view_id, allVisuals) => {
  const visuals = allVisuals
    .filter((v) => v.asset_url)
    .map((visual) => {
      return {
        view_id,
        visual_id: visual.id,
        visual_type: visual.visual_type,
        preview_url: visual.asset_url,
      };
    });

  return visuals;
};
