export const getTopTenSpending = (allVisualsLabel, filteredAllVisuals) => {
  const topTenSpendingVisuals = filteredAllVisuals
    .sort((a, b) => b.spend - a.spend)
    .slice(0, 10);

  const topTenSpending = {
    ...allVisualsLabel,
    name: 'Top 10 Spending Visuals',
    value: 'top_10_spending',
    category: 'Top 10 Spending Visuals',
    description:
      'This category displays your top 10 spending visuals based on the filters you have selected.',
    relevant_visuals: topTenSpendingVisuals.map((visual) => visual.id),
  };

  return topTenSpending;
};
