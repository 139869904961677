import React, { useEffect, useMemo, useState } from 'react';
import {
  toCurrency,
  toFixed_2,
  toPercentRounded,
  toPercent_2,
} from '../../../../utils/numbers';
import { useCreativeAnalyticsGet } from '../../contexts';
import { useViewName } from '../../selectors';
import {
  getSpend,
  getTotal,
  getTotalLikes,
  useGetAggregate,
} from '../../utils';
import { formatMetric, calculateMetric } from '../utils';

export const useReportCardMetrics = ({
  current,
  isROASClient,
  isDirectMail,
  reportCardMetric,
  metricOptions,
  clientMetrics,
  gaEnabled,
  customEvents,
  filteredData,
}) => {
  const viewName = useViewName();
  const { cpa, spend, clicks, impressions, leads, results } = current.data;
  const isVeterans = metricOptions.some((option) => option.value === 'cpik');
  const isOrganic = isDirectMail && viewName.toLowerCase().includes('organic');
  const isCompetitor =
    isDirectMail && viewName.toLowerCase().includes('competitor');
  const allVisualsLabel = filteredData.find((x) => x.value === 'all_visuals');

  const adData = { cpa, spend, clicks, impressions, leads };
  const standard = [
    'cpa',
    'roas',
    'cpm',
    'cpc',
    'ctr',
    ...(isVeterans ? ['cpik'] : []),
  ];
  const rcm = reportCardMetric;

  const metrics = useMemo(() => {
    let clientMetricsValues = [];
    let customEventsValues = [];
    if (isDirectMail && isCompetitor) {
      return [
        {
          metric: '% of Visuals',
          id: 'percent_of_visuals',
          value: null,
        },
        {
          metric: 'Total Visuals',
          id: 'total_visuals',
          value: allVisualsLabel.visuals.length || 0,
        },
      ];
    }

    // if (isDirectMail && isOrganic) {
    //   if (current['Like Count'] === null) return [];
    //   return [
    //     {
    //       metric: 'Total Likes',
    //       value: current.data['Like Count'],
    //     },
    //   ];
    // }

    if (clientMetrics && clientMetrics.length && isDirectMail) {
      [...clientMetrics].forEach((metric, i) => {
        if (isOrganic || isCompetitor) {
          const value = current.data[metric];
          let total = allVisualsLabel[metric];
          // allVisualsLabel.visuals.forEach((x) => {
          //   total =+ x[metric];
          // })
          clientMetricsValues.push({
            metric: `${metric}`,
            id: `${metric}`,
            value,
          });
          // clientMetricsValues.push({
          //   metric: `${metric} per post`,
          //   id: `${metric}_per_post`,
          //   value: (total / allVisualsLabel.visuals.length).toFixed(2) || '-',
          // });
        } else {
          clientMetricsValues.push({
            metric: `Aggregate ${metric}`,
            id: `aggregate_${metric}`,
            value: null,
          });
          clientMetricsValues.push({
            metric: `Median ${metric}`,
            id: `median_${metric}`,
            value: null,
          });
        }
      });
    }

    if (
      customEvents &&
      customEvents.some((x) => x.calculation === 'results_per_click')
    ) {
      customEventsValues.push({
        metric: 'Conversion Rate',
        value: isFinite(results / clicks)
          ? toPercent_2(results / clicks)
          : '0%',
      });
      customEventsValues.push({
        metric: 'Median Conversion Rate',
        value: null,
      });
    }

    const gaLabels = gaEnabled
      ? [
          {
            metric: 'GA CTR',
            id: 'ga_aggregate_ctr',
            value: null,
          },
          {
            metric: 'Median GA CTR',
            id: 'ga_median_ctr',
            value: null,
          },
        ]
      : [];
    if (isOrganic) {
      return [...clientMetricsValues, ...customEventsValues];
    }

    let roas = 1 / cpa;
    if (isNaN(roas) || !isFinite(roas)) roas = 0;

    return [
      ...(isROASClient
        ? [
            {
              metric: 'ROAS',
              id: 'roas',
              value: toFixed_2(roas) || null,
            },
            {
              metric: 'Median ROAS',
              id: 'median_roas',
              value: null,
            },
          ]
        : [
            {
              metric: 'CPA',
              id: 'cpa',
              value: toCurrency(cpa) || null,
            },
            {
              metric: 'Median CPA',
              id: 'median_cpa',
              value: null,
            },
          ]),
      {
        metric: 'CPM',
        id: 'cpm',
        value: calculateMetric('cpm', adData),
      },
      {
        metric: 'Median CPM',
        id: 'median_cpm',
        value: null,
      },
      {
        metric: 'CPC',
        id: 'aggregate_cpc',
        value: calculateMetric('cpc', adData),
      },
      {
        metric: 'Median CPC',
        id: 'median_cpc',
        value: null,
      },
      {
        metric: 'CTR',
        id: 'ctr',
        value: calculateMetric('ctr', adData),
      },
      {
        metric: 'Median CTR',
        id: 'median_ctr',
        value: null,
      },
      ...gaLabels,
      {
        metric: 'Total Results',
        id: 'total_results',
        value: Math.round(current.data.results),
      },
      {
        metric: '% of Results',
        id: 'percent_of_results',
        value: null,
      },
      {
        metric: '% of Results Difference',
        id: 'percent_of_results_diff',
        value: null,
      },
      {
        metric: 'Total Link Clicks',
        id: 'total_link_clicks',
        value: current.data.clicks,
      },
      {
        metric: '% of Link Clicks',
        id: 'percent_of_link_clicks',
        value: null,
      },
      {
        metric: '# of Unique Creatives',
        id: 'number_unique_creatives',
        value: null,
      },
      {
        metric: '% of Creative Count',
        id: 'percent_of_creative_count',
        value: null,
      },
      {
        metric: '% Spend',
        id: 'percent_budget',
        value: null,
      },
      {
        metric: 'Spend',
        id: 'spend',
        value: toCurrency(spend) || null,
      },
      {
        metric: 'Wins/All',
        id: 'wins_over_all',
        value: null,
      },
      {
        metric: 'Win rate',
        id: 'performance_percent',
        value: null,
      },
      {
        metric: '% of Link Click Difference',
        id: 'percent_of_link_clicks_diff',
        value: null,
      },
      ...clientMetricsValues,
      ...customEventsValues,
      ...(isVeterans
        ? [
            {
              metric: 'CPIK',
              value: calculateMetric('cpik', adData),
            },
            {
              metric: 'Median CPIK',
              id: 'median_cpik',
              value: null,
            },
          ]
        : []),
      ...(!standard.includes(reportCardMetric) &&
      !clientMetricsValues.some((x) => x.metric.includes(reportCardMetric)) &&
      current.data[rcm]
        ? [
            {
              metric: formatMetric(reportCardMetric, isROASClient),
              value: rcm.startsWith('c')
                ? calculateMetric(rcm, adData)
                : current && current.data && current.data[rcm]
                ? current.data[rcm].toLocaleString()
                : 0,
            },
          ]
        : []),
    ];
  }, [isDirectMail, viewName, current]);

  return metrics;
};
