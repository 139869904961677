import React, { useState } from 'react';
import { LeftMenu } from '../../components/LeftMenu/Menu';

import '../CreativeAnalytics/LeftDisplay/LeftMenu.scss';
import { MenuItem } from '../../components/LeftMenu/MenuItem';
import { PERFORMANCE_FREQUENCY } from '../../store/CreativeReports/constants';
import { useCategories } from '../CreativeAnalytics/hooks';
import { useSearchParams } from '../../components/Hooks/search-params';
import { useCreativeAnalyticsGet } from '../CreativeAnalytics/contexts';
import ElementPerformance from '../CreativeAnalytics/LeftDisplay/ElementPerformance';

export const CategoriesNav: React.FC = ({
  display,
}) => {
  const { selected } = useCreativeAnalyticsGet();
  const isTableMode = display === 'performance_frequency';
  const [minimized] = useState(false);

  const { navCategories, data } = useCategories();
  const { category } =
    useCreativeAnalyticsGet();
  const { setParams } = useSearchParams();

  let reports = navCategories
    ?.filter(
      (category) =>
        !['Phrase Theme', 'Darwin Clusters'].includes(category.label)
    )
    .map(({ label, value, variance }) => {
      const isSelectedCategory = category === value;

      const handleSelect = (value) => {
        if (isSelectedCategory) {
          setParams({ display: PERFORMANCE_FREQUENCY, category: '' });
        } else {
          setParams({ display: PERFORMANCE_FREQUENCY, category: value });
        }
      };

      return (
        <MenuItem
          title={label}
          onMenuItemClick={handleSelect}
          isSelected={isSelectedCategory}
          className="detailed-report-item"
        />
      );
    });

    return (
    <div className={`left-menu__container ${minimized ? 'minimized' : ''}`}>
      <LeftMenu>{reports}</LeftMenu>
      {isTableMode && <ElementPerformance data={selected.element} />}
    </div>
  );
};

export default CategoriesNav;
