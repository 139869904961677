import React, { createRef } from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import Gantt from "highcharts/modules/gantt";
import HighchartsReact from "highcharts-react-official";
import Draggable from "highcharts/modules/draggable-points";

import { vhToPx } from "../../utils/dom-related";
import { ApiContext } from "./Api";
import { ActionButtons } from "./ActionButtons";
import {
  apiToBar,
  formToBar,
  barToForm,
  formToNewForm,
  adjustDisplayName,
} from "./utils";
import {
  DAY,
  TODAY,
  endOfMonth,
  startOfMonth,
  endOfYear,
  startOfYear,
  msToDarwinDate,
} from "./date-utils";
import {
  ADD,
  REMOVE,
  ZOOM_BUTTONS,
  BAR_COUNT,
  ACTIVE_BAR_COUNT,
} from "./constants";

Gantt(Highcharts);
Draggable(Highcharts);

export const GanttChart = (props) => {
  return (
    <ApiContext.Consumer>
      {({ flowchart }) => <Chart {...props} flowchart={flowchart} />}
    </ApiContext.Consumer>
  );
};

GanttChart.propTypes = {
  _id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  handleSelection: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
};

class Chart extends React.PureComponent {
  constructor(props) {
    super(props);

    this.$chart = createRef(null);

    const self = this;

    this.state = {
      zoomType: "3month",
      changes: [],
      chartOptions: {
        series: makeSeries(this.props.data.map(apiToBar)),

        chart: {
          marginTop: 48,
          height: vhToPx(80) - 70,
        },

        xAxis: zoomMultiplier("3month"),

        yAxis: {
          type: "category",
          categories: makeCategories(BAR_COUNT),
          min: 0,
          max: BAR_COUNT - 1,
          visible: true,
          lineColor: "transparent",
          gridLineColor: "transparent",
          tickColor: "transparent",

          plotLines: [
            {
              color: "#dddddd",
              width: 1,
              value: ACTIVE_BAR_COUNT - 0.5,
            },
          ],
        },

        tooltip: {
          xDateFormat: "%Y-%m-%d",
        },

        navigator: {
          adaptToUpdatedData: false,
          enabled: true,
          liveRedraw: true,
          margin: 5,
          series: {
            type: "gantt",
            pointPlacement: 0.5,
            pointPadding: 0.25,
          },
          yAxis: {
            min: 0,
            max: BAR_COUNT - 1,
            reversed: true,
            categories: [],
          },
        },
        scrollbar: {
          enabled: true,
        },

        plotOptions: {
          gantt: {
            borderRadius: 1,
            pointPadding: 0,
            groupPadding: 0.01,
            states: {
              select: {
                borderColor: "#333333",
                color: "#17a2b8",
              },
              hover: {
                borderColor: "#ffcb36",
              },
            },
          },
          series: {
            animation: false, // Do not animate dependency connectors
            tooltip: {
              pointFormatter() {
                return `
                  <strong>${this.id}</strong>
                  <br />
                  Start: ${msToDarwinDate(this.start)}
                  <br />
                  End: ${msToDarwinDate(this.end)}
                `;
              },
            },
            dragDrop: {
              draggableX: true,
              draggableY: true,
              dragMinY: 0,
              dragMaxY: BAR_COUNT - 1,
              dragPrecisionX: DAY / 2,
            },
            dataLabels: {
              enabled: true,
              inside: true,
              align: "left",

              formatter() {
                const days = parseInt(this.point.duration) || 7;
                const weeks = days < 7 ? 1 : Math.ceil(days / 7);

                return `
                  <strong>${adjustDisplayName(this.point.name, days)}</strong>
                  <br />
                  ${weeks} Weeks
                `;
              },
              style: {
                fontFamily: "Roboto,sans-serif",
                fontWeight: 400,
                textOutline: "none",
                cursor: "default",
                pointerEvents: "none",
              },
            },
            allowPointSelect: true,
            point: {
              events: {
                select(e) {
                  return self.handleSelection(this);
                },

                drop(e) {
                  const updated = barToForm(e.newPoints[this.id].point);

                  return self.addStudy(updated);
                },
              },
            },
          },
        },
      },
    };
  }

  componentWillUnmount() {
    this.$chart = null;
  }

  componentDidMount() {
    removeAxisLabels();
  }

  componentDidUpdate(prevProps, prevState) {
    this.resetChangesOnSuccess(prevProps.flowchart, this.props.flowchart);
    removeAxisLabels();

    const currAxis = this.state.chartOptions.xAxis;

    if (prevState.zoomType !== this.state.zoomType) {
      this.$chart.current.series[0].xAxis.setExtremes(
        currAxis.min,
        currAxis.max
      );

      this.$chart.current.series[0].xAxis.update({
        dateTimeLabelFormats: {
          month: "%b",
          year: "%Y",
        },
      });
    }
  }

  /**
   * @description Only reset the changes on a successful save
   * request.
   */
  resetChangesOnSuccess(prevFlowchart, currFlowchart) {
    const prevPut = prevFlowchart.put;
    const currPut = currFlowchart.put;

    if (!prevPut.data && !!currPut.data) {
      this.setState({ changes: [] });
    }
  }

  handleSelection(point) {
    return this.props.handleSelection(!point ? "" : point.id);
  }

  addStudy(study, fromNewForm = false) {
    const { changes, chartOptions } = this.state;
    const { name, data } = chartOptions.series[0];
    const filtered = changes.filter((change) => change.value !== study.value);
    const updatedData = data.filter((item) => item.id !== study.value);

    this.setState(
      {
        changes: [...filtered, { value: study.value, action: ADD }],
        chartOptions: {
          xAxis: [...chartOptions.xAxis],
          series: [
            {
              name,
              data: [...updatedData, formToBar(study)],
            },
          ],
        },
      },
      () => fromNewForm && this.handleSave()
    );
  }

  removeStudy() {
    const { _id } = this.props;

    if (_id === "") {
      return null;
    } else {
      const answer = window.confirm("Do you want to remove this study?");

      if (!answer) {
        return null;
      } else {
        const { changes, chartOptions } = this.state;
        const { name, data } = chartOptions.series[0];

        const updatedChanges = [
          ...changes.filter((item) => item.value !== _id),
          { value: _id, action: REMOVE },
        ];
        const updatedData = data.filter((item) => item.id !== _id);

        this.setState(
          {
            changes: updatedChanges,
            chartOptions: {
              xAxis: [...chartOptions.xAxis],
              series: [{ name, data: updatedData }],
            },
          },
          () => {
            this.props.handleSelection(_id, "REMOVED");
            this.props.handleRemove(_id);
          }
        );
      }
    }
  }

  handleSave(e) {
    const {
      chartOptions: { series },
      changes,
    } = this.state;

    const lookup = series[0].data.reduce((acc, item) => {
      acc[item.id] = barToForm(item);

      return acc;
    }, {});
    const preppedChanges = changes.reduce((acc, { value, action }) => {
      const maybe = lookup[value];

      if (!!maybe) {
        acc.push({ ...maybe, action });
      } else {
        acc.push({ value, action });
      }

      return acc;
    }, []);

    this.props.handleSave(preppedChanges);
  }

  setZoom({ type }) {
    if (type === this.state.zoomType) {
      return null;
    } else {
      this.setState({
        zoomType: type,
        chartOptions: {
          series: [...this.state.chartOptions.series],
          xAxis: zoomMultiplier(type),
        },
      });
    }
  }

  renderZoomButtons() {
    return (
      <div className="d-flex flex-row align-items-baseline">
        Zoom
        {ZOOM_BUTTONS.map((btnConfig) => (
          <button
            key={btnConfig.type}
            className="btn btn-default btn-lt-gray-border btn-no-radius ml-1"
            onClick={(e) => this.setZoom(btnConfig)}
          >
            {btnConfig.text}
          </button>
        ))}
      </div>
    );
  }

  renderButtons() {
    const { flowchart } = this.props;
    const { changes, chartOptions } = this.state;
    const selection = chartOptions.series[0].data.find(
      ({ id }) => id === this.props._id
    );

    const Layout = (
      <ActionButtons
        saveDisabled={
          flowchart.put.loading ||
          flowchart.delete.loading ||
          changes.length === 0
        }
        removeDisabled={!selection || flowchart.delete.loading}
        study={
          !selection ? { study_name: "" } : formToNewForm(barToForm(selection))
        }
        handleDelete={() => this.removeStudy()}
        handleSave={(e) => this.handleSave(e)}
        handleSubmit={(study) => this.addStudy(study, true)}
      />
    );

    return (
      <div className="d-flex flex-row justify-content-between">
        {this.renderZoomButtons()}
        <div
          className="d-flex flex-row justify-content-end flow-btn-group"
          style={
            flowchart.put.loading || flowchart.delete.loading
              ? { cursor: "progress" }
              : {}
          }
        >
          {Layout}
        </div>
      </div>
    );
  }

  render() {
    const { chartOptions } = this.state;

    return (
      <>
        {this.renderButtons()}

        <HighchartsReact
          constructorType={"ganttChart"}
          highcharts={Highcharts}
          options={chartOptions}
          containerProps={{ style: { height: "100%" } }}
          callback={(chart) => (this.$chart.current = chart)}
        />
      </>
    );
  }
}

Chart.propTypes = {
  ...GanttChart.propTypes,
  flowchart: PropTypes.shape({
    delete: PropTypes.object.isRequired,
    put: PropTypes.object.isRequired,
    get: PropTypes.object.isRequired,
  }),
};

function makeSeries(data) {
  return [
    {
      name: "Study",
      data,
    },
  ];
}

/**
 * @param {string} type
 *
 * @return {[Object]}
 */
function zoomMultiplier(type) {
  let zoomed = _zoomMultiplier(type);

  zoomed.grid = {
    cellHeight: 16,
  };

  return [
    zoomed,
    {
      visible: true,
      grid: {
        cellHeight: 16,
      },
    },
  ];
}

function _zoomMultiplier(
  type,
  theDefault = {
    min: TODAY - 21 * DAY,
    max: TODAY + 21 * DAY,
    currentDateIndicator: true,
  }
) {
  switch (type) {
    case "month":
      return {
        currentDateIndicator: true,
        min: startOfMonth(TODAY).getTime(),
        max: endOfMonth(TODAY).getTime(),
      };

    case "3month":
      return {
        currentDateIndicator: true,
        min: TODAY - 42 * DAY,
        max: TODAY + 42 * DAY,
      };

    case "6month":
      return {
        currentDateIndicator: true,
        min: TODAY - 90 * DAY,
        max: TODAY + 90 * DAY,
      };

    case "year":
      return {
        currentDateIndicator: true,
        min: TODAY - 180 * DAY,
        max: TODAY + 180 * DAY,
      };

    case "ytd":
      return {
        currentDateIndicator: true,
        min: startOfYear(TODAY).getTime(),
        max: endOfYear(TODAY).getTime(),
      };

    case "all":
      return {
        currentDateIndicator: true,
        min: TODAY - 180 * DAY,
        max: TODAY + 180 * DAY,
      };

    default:
      return theDefault;
  }
}

function makeCategories(barCount, activeCount = ACTIVE_BAR_COUNT) {
  let base = new Array(activeCount).fill("Active");
  let remaining = barCount - base.length;

  for (let i = 0; i < remaining; i++) {
    base.push("Backlog");
  }

  return base;
}

function removeAxisLabels() {
  const yLabels = document.querySelector(
    ".highcharts-axis-labels.highcharts-yaxis-labels"
  );

  Array.from(yLabels.children).forEach((child, i) => {
    if (i !== 0 && i !== ACTIVE_BAR_COUNT) {
      child.textContent = "";
    }
  });

  return yLabels;
}
