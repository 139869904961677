import { GOOGLE_VISION_CATEGORIES, PHRASES, REKOGNITION_CATEGORIES } from "../../categories";

export const getCategoryOverview = ({ category_name }): { desc: string } => {
  let desc = null;
  const isPhrases = category_name === PHRASES;

  if (isPhrases) {
    desc = 'This category is managed in the report card interface.';
  } if (GOOGLE_VISION_CATEGORIES.includes(category_name)) {
    desc =
      'This category looks at only the thumbnail to make calculations.';
  } else if (REKOGNITION_CATEGORIES.includes(category_name)) {
    desc =
      'This category looks at the thumbnail and the first 3 seconds of content to make calculations.';
  }

  return desc;
};