import React, { useState, useEffect } from 'react';
import BarChart from './Charts/BarChart';
import {
  BAR_CHART,
  BAR_METRICS,
  CATEGORY_FORMAT_LOOKUP,
  METRIC_FORMAT_LOOKUP,
  PIE_CHART,
  PIE_METRICS,
  SINGLE_VALUE,
} from './constants';
import CircleChart from './Charts/CircleChart';

import './Card.scss';
import Tooltip from '../../components/Tooltip';
import SingleValue from './Charts/SingleValue';
import AnalyticsButton from './assets/AnalyticsButton';
import { CardObject } from './types';
import clsx from 'clsx';
import { checkIsEmpty } from '../CreativeAnalytics/LibraryAnalytics/utils';
import DeleteButton from './assets/DeleteButton';
import { useCreativeAnalyticsGet } from '../CreativeAnalytics/contexts';

const getMetric = (metric_options, metricIndex, isROAS) => {
  let metric = metric_options[metricIndex]
    ? metric_options[metricIndex]
    : metric_options[0];

  if (isROAS && metric.includes('cpa')) {
    metric = metric.replace('cpa', 'roas');
  }

  return metric;
};

export const formatMetric = (metric) => {
  return METRIC_FORMAT_LOOKUP[metric] || metric;
};

export const formatCategory = (category) => {
  return CATEGORY_FORMAT_LOOKUP[category] || category;
};

const getCardType = (metric, cardData) => {
  if (cardData.cardType) return cardData.cardType;

  if (PIE_METRICS.some((x) => metric.includes(x))) {
    if (cardData.useBarForPercent) {
      return BAR_CHART;
    }

    return PIE_CHART;
  } else if (BAR_METRICS.some((x) => metric.includes(x))) {
    return BAR_CHART;
  }
};

export const sortByPerformance = (metric, labels) => {
  const preferLowerValue = metric.includes('cpa');

  const parse = (value, metric) => {
    if (metric === 'percent_budget') return parseFloat(value) / 100.0;
    return value;
  };

  const sorted = [...labels].sort((a, b) => {
    if (!a[metric]) return 1;
    if (!b[metric]) return -1;
    return preferLowerValue
      ? parse(a[metric], metric) - parse(b[metric], metric)
      : parse(b[metric], metric) - parse(a[metric], metric);
  });

  return sorted;
};

const filterLabels = (sorted) => {
  const top2 = sorted.slice(0, 2);
  const bottom2 = sorted
    .slice(-2)
    .filter((x) => !top2.some((y) => y.value === x.value));

  return [...top2, ...bottom2];
};

type CardProps = {
  key?: string,
  cardData: CardObject,
  isROAS: boolean,
  amountOfCards: number,
  openAnalyticsTable: () => void,
  setExpandedCategories: React.Dispatch<React.SetStateAction<[]>>,
  editMode?: boolean,
  remove: () => void,
  openCategoryInNewTab: () => void,
}

const Card = ({
  key,
  cardData,
  isROAS,
  amountOfCards,
  openAnalyticsTable,
  setExpandedCategories,
  editMode = false,
  remove,
  openCategoryInNewTab,
}: CardProps) => {
  const { title, category, id, metric_options }: CardObject = cardData;
  const [metricIndex, setMetricIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (editMode) setIsExpanded(false);
  }, [editMode]);

  const metric = getMetric(metric_options, metricIndex, isROAS);
  const cardType = getCardType(metric, cardData);

  let Render = null;

  const filteredCardData = cardData?.data?.filter(
    (item) => !checkIsEmpty(item)
  );

  const canExpand = filteredCardData.length > 4;
  const shouldExpand = isExpanded && !editMode;
  const sorted = sortByPerformance(metric, filteredCardData);

  let labels = shouldExpand ? sorted.slice(0, 8) : filterLabels(sorted);

  switch (cardType) {
    case BAR_CHART:
      Render = <BarChart {...{ cardData, isROAS, metric, labels }} />;
      break;
    case PIE_CHART:
      Render = <CircleChart {...{ cardData, metric, labels }} />;
      break;
    case SINGLE_VALUE:
      return (
        <SingleValue
          {...{ cardData, metric, openAnalyticsTable, openCategoryInNewTab, editMode, remove }}
        />
      );
    default:
      return null;
  }

  const cycleMetric = () => {
    setMetricIndex((prev) => {
      if (prev === metric_options.length - 1) return 0;
      return prev + 1;
    });
  };

  if (!labels?.length)
    Render = <p className="insights-card__text">No data available.</p>;

  const ActionButton = editMode ? (
    <DeleteButton onClick={remove} />
  ) : (
    <AnalyticsButton onClick={openCategoryInNewTab} />
  );

  return (
    <div
      key={key}
      className={clsx(
        'insights-card',
        `insights-card--${category.replaceAll(/\s/g, '')}`,
        {
          'is-expanded': shouldExpand,
          'more-than-3': amountOfCards > 3,
          'more-than-4': amountOfCards > 4,
        }
      )}
    >
      {ActionButton}
      <TooltipWrapper cardData={cardData} metric={metric}>
        <h3 className="insights-card__title">
          {' '}
          {title || (
            <span>
              <span
                className={`insights-card__title-btn ${
                  metric_options.length > 1 ? 'can-switch-metric' : ''
                }`}
                onClick={cycleMetric}
              >
                {formatMetric(metric)}
              </span>{' '}
              by {formatCategory(category)}
            </span>
          )}
        </h3>
      </TooltipWrapper>
      <div className="insights-card__content">{Render}</div>
      {canExpand && !editMode && (
        <ExpandButton
          onClick={() => {
            setIsExpanded((prev) => !prev);
            setExpandedCategories((prev) => {
              if (prev.includes(id)) return prev.filter((x) => x !== id);
              return [...prev, id];
            });
          }}
          shouldExpand={shouldExpand}
        />
      )}
    </div>
  );
};
export default Card;

const ExpandButton = ({ onClick, shouldExpand }) => {
  return (
    <button
      className="insights-card__expand-btn insights-card__text"
      onClick={onClick}
    >
      {shouldExpand ? 'Show fewer' : 'Show more'}
    </button>
  );
};

export const TooltipWrapper = ({ cardData, metric, children }) => {
  let m = metric;
  if (metric.includes('roas')) m = metric.replace('roas', 'cpa');

  let tooltip = cardData.tooltip?.[m];

  if (tooltip) {
    return (
      <Tooltip follow={false} content={tooltip}>
        {children}
      </Tooltip>
    );
  }

  return children;
};
