import React from 'react';
import { SpendGoal } from '../../../../components/StudyFlow/Wizard/FormElements';
import {
  toCurrency,
  toFixed_2,
  toPercent,
  toPercentRounded,
  toPercent_2,
} from '../../../../utils/numbers';
import { getDiff } from '../utils';
import * as ss from 'simple-statistics';
import Tooltip from '../../../../components/Tooltip';
import { getCPAQuantile } from '../../filter-utils';

const TEMP_LABEL_MAPPING = {
  percent_of_visuals: '% of Visuals',
  number_unique_creatives: 'Total Visuals',
};

export const getColumnHeaders = (
  dataTypeLabel,
  dataMetricLabel,
  isOrganicView,
  isCompetitor
) => {
  if (isOrganicView) {
    return [`${dataMetricLabel}`, 'Total Posts'];
  }

  if (isCompetitor) {
    return [`% of Visuals`, 'Total Visuals'];
  }

  return [`${dataTypeLabel} ${dataMetricLabel}`, '% Spend'];
};

export const getColumnData = ({
  label,
  typeMetric,
  selectedMetric,
  isDuff,
  isOrganicView,
  isCompetitorView,
}) => {
  let formattedMetric = label[typeMetric];

  if (isOrganicView) {
    formattedMetric = label[`Average ${typeMetric}`];
    return [formattedMetric?.toFixed(2) || 0, label.visuals.length];
  }

  if (isCompetitorView) {
    formattedMetric = label[`${typeMetric}`];
    return [label['percent_of_visuals'] || '0%', label.visuals.length];
  }

  switch (selectedMetric) {
    case 'CTR':
      if (typeMetric === 'median_ctr') {
        formattedMetric = toPercent_2(label[typeMetric] / 100);
      } else {
        formattedMetric = toPercent_2(label[typeMetric]);
      }
      break;
    case 'CPIK':
      formattedMetric = label[typeMetric].toFixed(2);
      break;
    case 'CPA':
    case 'CPM':
    case 'CPC':
      formattedMetric = toCurrency(label[typeMetric]);
      break;
    case 'ROAS':
      formattedMetric = toFixed_2(label[typeMetric]);
      break;
    default:
      try {
        if (
          selectedMetric.includes('cp') ||
          selectedMetric.includes('OppAttempted')
        ) {
          formattedMetric = toCurrency(label[selectedMetric]);
        } else {
          formattedMetric = label[selectedMetric];
        }

        // formattedMetric = label[typeMetric].toFixed(isDuff ? 0 : 2);
      } catch (e) {
        console.error(e);
      }
      break;
  }

  return [formattedMetric, label.percent_budget];
};

export const getDarwinIndexCPADisplay = (label, cpasList, isROASClient) => {
  let cpaIndexText = '-';
  let cpaIndexColor = '#2B437E';
  const isTestingOpportunity = label.spend === 0 && label.total_results === 0;

  const cpaQuintile = getCPAQuantile(label, cpasList, isROASClient);
  const getDiff = (currentQuintile, indexQuintile) => {
    if (!currentQuintile || !indexQuintile) return null;

    return ((currentQuintile - indexQuintile) / indexQuintile) * 100;
  };

  const percentDiff = getDiff(cpaQuintile, label.average_cpa_quintile);

  if (percentDiff === null) return ['No Darwin Index data available.'];

  if (isTestingOpportunity) {
    return [
      `You aren't testing any ${
        label.name
      } ads and they perform (on average) ${percentDiff.toFixed()}% ${'better'} than the market average.`,
    ];
  }

  if (percentDiff === 0) {
    return ['You are performing equal to the market average.'];
  }

  const comparison = percentDiff > 0 ? 'better' : 'worse';

  if (percentDiff >= 50) {
    cpaIndexText = 'much better than';
  } else if (percentDiff >= 10 && percentDiff < 50) {
    cpaIndexText = 'better than';
  } else if (percentDiff >= -10 && percentDiff < 10) {
    cpaIndexText = 'roughly in line with';
  } else if (percentDiff >= -50 && percentDiff < -10) {
    cpaIndexText = 'worse than';
  } else if (percentDiff < -50) {
    cpaIndexText = 'much worse than';
  }

  return [
    <p className="index-table__cpa">
      You are performing{' '}
      <span
        className={`index-table__percent index-table__percent--${comparison}`}
      >
        {cpaIndexText}
      </span>{' '}
      the market average.
    </p>,
  ];
};

export const getDarwinIndexHeaders = (isCPATable) => {
  if (isCPATable) return [];

  return ['Your % Spend', 'Index % Spend', 'Difference'];
};

export const getDarwinIndexCells = ({
  isCPATable,
  label,
  cpasList,
  isROASClient,
}) => {
  if (isCPATable) {
    return getDarwinIndexCPADisplay(label, cpasList, isROASClient);
  }

  const indexPercentSpend = label.average_percent_spend
    ? label.average_percent_spend * 100
    : null;
  const percentDiff = getPercentDifference(
    parseFloat(label.percent_budget),
    indexPercentSpend
  );

  return [
    label.percent_budget,
    indexPercentSpend ? `${indexPercentSpend.toFixed()}%` : '-',
    percentDiff,
  ];
};

const getPercentDifference = (currentPercentSpend, indexPercentSpend) => {
  if (!indexPercentSpend) return '-';
  return toPercentRounded((currentPercentSpend - indexPercentSpend) / 100);
};
