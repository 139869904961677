import React from "react";
import PropTypes from "prop-types";

import { useFetchStatus, useAd, usePayload } from "./Container";
import { DARWIN_RED, DARWIN_SEAFOAM } from "../../constants";

export const ActionPanel = ({ showEditor, handleBoostModal, toggleEditor }) => {
  const {
    ad: {
      exists,
      atf: { value },
    },
    validAd,
  } = useAd();
  const { loadingCreateAd } = useFetchStatus();
  const { fetchCreation } = usePayload();
  const existsAndNotEdited = exists && value === "";
  const disabled = loadingCreateAd || showEditor;

  const Btn = existsAndNotEdited ? (
    <button
      className="btn btn-primary"
      disabled={disabled}
      onClick={(e) => handleBoostModal("AD_NAME_HERE")}
    >
      Boost
    </button>
  ) : (
    <button
      className="btn btn-primary"
      disabled={disabled}
      onClick={(e) => fetchCreation()}
    >
      Create
    </button>
  );

  return (
    <>
      <AdExists maybe={existsAndNotEdited} />
      <button
        className="btn btn-success"
        disabled={loadingCreateAd || (showEditor && !validAd)}
        onClick={toggleEditor}
      >
        {showEditor ? "Preview" : "Modify"}
      </button>
      &emsp;
      {Btn}
    </>
  );
};

ActionPanel.propTypes = {
  showEditor: PropTypes.bool.isRequired,
  handleBoostModal: PropTypes.func.isRequired,
  toggleEditor: PropTypes.func.isRequired,
};

const EXIST_STYLE = {
  color: DARWIN_SEAFOAM,
};

const NON_EXIST_STYLE = {
  color: DARWIN_RED,
};

export const AdExists = ({ maybe }) =>
  maybe ? (
    <p style={EXIST_STYLE}>
      <strong>Ad Exists</strong>
    </p>
  ) : (
    <p style={NON_EXIST_STYLE}>
      <strong>Ad Does Not Exist</strong>
    </p>
  );
