import React from 'react';
import { DataState, Row } from './types';

export const S3_VISUAL = 's3_visual';
export const S3_IMAGE_URL = 's3_image_url';
export const S3_VIDEO_URL = 's3_video_url';
export const DESCRIPTION = 'description';
export const SMART_VARIATION = 'smart_variation';

export const EMPTY_ROW: Row = {
  base_block_value: '',
  base_block_type: 's3_visual',
  base_block_visual_type: '',
  second_block_value: '',
  second_block_type: 's3_visual',
  second_block_visual_type: '',
  final_asset_url: '',
  final_asset_type: 's3_image_url',
  final_asset_visual_type: 'image',
  additional_note: '',
  loading: false,
  error: false,
};

export const initialDataState: DataState = {
  projectName: '',
  projectId: '',
  status: 'active',
  rows: [],
};
