import React, { useEffect, useRef, useState } from 'react';

export const useOverflowActive = () => {
  const ref = useRef(null);
  const [overflowActive, setOverflowActive] = useState(false);

  const isOverflowActive = (e) => {
    return e.offsetWidth < e.scrollWidth;
  };

  useEffect(() => {
    if (ref.current && isOverflowActive(ref.current)) {
      setOverflowActive(true);
      return;
    }

    setOverflowActive(false);
  }, [isOverflowActive]);

  return [ref, overflowActive];
};
