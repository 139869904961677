/**
 * @description Determines if both arrays are equal.
 */
export const arraysEqual = (predicate, arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  let i = 0;

  while (arr1[i]) {
    if (!predicate(arr1[i], arr2[i])) {
      return false;
    }

    i++;
  }

  return true;
};

export const xAxisCategory = ({ value }) => value;

export const differenceToDollars = ({ difference, value }, i) =>
  // return parseFloat(parseFloat(difference).toFixed(2));

  ({
    id: value,
    y: parseFloat(parseFloat(difference).toFixed(2)),
    x: i,
  });
