import { DISABLED_CATEGORIES } from '../ReportCard/CategoryEditor/constants';
import { LabelObject } from '../types';


export const buildRequestShape = (
  filteredAllVisualsLabel: LabelObject,
  primary_metric: string
) => {
  if (!filteredAllVisualsLabel) {
    return null;
  }
  const { visuals } = filteredAllVisualsLabel;

  const all_new_visuals_array = [...visuals].filter((x) => x.isNew);
  const top_10_spending_visuals_array = [...visuals]
    .sort((a, b) => b.spend - a.spend)
    .slice(0, 10);

  const all_visuals_metric_performance = getAllVisualsMetricPerformance(
    filteredAllVisualsLabel,
    primary_metric
  );

  const formatVisuals = (arr) => {
    return arr.map((visual) => {
      return {
        visual_id: visual.id,
        metric_performance: visual[primary_metric] || 0,
        spend: visual.spend,
      };
    });
  };

  return {
    primary_metric,
    all_visuals_array: formatVisuals(visuals),
    all_new_visuals_array: formatVisuals(all_new_visuals_array),
    top_10_spending_visuals_array: formatVisuals(top_10_spending_visuals_array),
    all_visuals_metric_performance,
  };
};

const getAllVisualsMetricPerformance = (
  filteredAllVisualsLabel,
  primary_metric
) => {
  let result = 0;
  const p = primary_metric.toLowerCase();

  switch (p) {
    case 'cpa':
    case 'ctr':
    case 'roas':
    case 'cpm':
    case 'cpc':
      result = filteredAllVisualsLabel[`aggregate_${p}`];
      break;
    default:
      result = filteredAllVisualsLabel[p] || 0;
  }

  return result;
};

export const formatRecs = (data) => {
  return [...data].map((item) => {
    const category =
      item.category;
    const recommendation = (item.recommendation ?? '').replace(
      /Test (more|less) /,
      ''
    );

    const why = (item.why ?? '')
      .replace(/\(Creative Score: -?\d+\)/, '')
      .trim();
    const creativeScoreMatch = item.why?.match(/\(Creative Score: (-?\d+)\)/);
    const creativeScore = creativeScoreMatch
      ? parseInt(creativeScoreMatch[1])
      : null;

    return {
      category,
      recommendation,
      why,
      creative_score: creativeScore,
    };
  }) ;
};

export const filterLabels = (labels, visibleLabels) =>
  JSON.parse(labels).filter((l) =>
    visibleLabels.some(
      (x) => x.value === l && !DISABLED_CATEGORIES.includes(x.category)
    )
  );

export const getTopSpender = (visuals) => 
  visuals.length
    ? visuals.reduce((prev, curr) => (prev.spend >= curr.spend ? prev : curr))
    : null;

export const getCategoriesWithLabelsData = (categories) =>
  categories.filter((category) => category.labels.some((label) => label.aggregate_roas > 0 && label.aggregate_cpa > 0));

export const getProjections = (allVisuals, topSpendingWinner, topSpendingLoser, isROAS) => {
  const { aggregate_roas, aggregate_cpa, spend, total_results } = allVisuals;
  const currentMetricAllVisuals = isROAS ? aggregate_roas : aggregate_cpa;

  return {
    winner: {
      percent_improvement: getProjectedImprovement(currentMetricAllVisuals, spend, total_results, topSpendingWinner, isROAS),
      savings: getProjectedSavings(currentMetricAllVisuals, total_results, topSpendingWinner, isROAS),
    },
    loser: {
      percent_improvement: getProjectedImprovement(currentMetricAllVisuals, spend, total_results, topSpendingLoser, isROAS),
      savings: getProjectedSavings(currentMetricAllVisuals, total_results, topSpendingLoser, isROAS),
    },
  }
};

const getProjectedMetric = (currentMetric, isROAS) => isROAS ? currentMetric * 1.2 : currentMetric * .8;

const getProjectedCurrentResults = (currentSpend, projectedMetric, isROAS) =>
  isROAS ? currentSpend * projectedMetric : currentSpend / projectedMetric;

const getProjectedTotalResults = (totalResults, currentResults, projectedCurrentResults) =>
  totalResults - currentResults + projectedCurrentResults;

export const getProjectedImprovement = (
  currentMetricAllVisuals,
  totalSpend,
  totalResults,
  asset,
  isROAS
) => {
  const { roas, cpa, spend: currentSpend, results: currentResults } = asset;
  const currentMetric = isROAS ? roas : cpa;
  const projectedCurrentResults = getProjectedCurrentResults(
    currentSpend,
    getProjectedMetric(currentMetric, isROAS),
    isROAS
  );
  const projectedTotalResults = getProjectedTotalResults(
    totalResults,
    currentResults,
    projectedCurrentResults
  );
  const projectedMetricAllVisuals = isROAS
    ? projectedTotalResults / totalSpend
    : totalSpend / projectedTotalResults;

    if (isROAS) return (projectedMetricAllVisuals - currentMetricAllVisuals) / currentMetricAllVisuals;

  return (currentMetricAllVisuals - projectedMetricAllVisuals) / currentMetricAllVisuals;
};

export const getProjectedSavings = (
  currentMetricAllVisuals,
  totalResults,
  asset,
  isROAS
) => {
  const { roas, cpa, spend: currentSpend, results: currentResults } = asset;
  const currentMetric = isROAS ? roas : cpa;
  const projectedCurrentResults = getProjectedCurrentResults(
    currentSpend,
    getProjectedMetric(currentMetric, isROAS),
    isROAS
  );
  const projectedTotalResults = getProjectedTotalResults(
    totalResults,
    currentResults,
    projectedCurrentResults
  );

  if (isROAS) return projectedTotalResults - totalResults;

  return (projectedTotalResults - totalResults) * currentMetricAllVisuals;
};
