import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { NavLogo } from './assets';

import AdminSettings from './AdminSettings';
import { useViewId } from '../Hooks/view-id';
import ViewPicker from '../ViewPicker';
import NavMenu from './NavMenu';
import { getReportOptions } from './utils';
import { VISUAL_REPORT_WHITELIST } from './constants';

import './styles.scss';
import './NavMenu.scss';
import { useCreativeAnalyticsApis } from '../../features/CreativeAnalytics/api';
import { getViewFeatureUrl } from '../../root-routes';
import { usePermissions } from '../Permissions/usePermissions';

const Nav = ({
  viewName,
  allowedAdmin,
  platforms,
  loggedIn,
  isDirectMail,
  account_id,
  darwin_light,
  creative_studio_only,
  isNonPaidMediaType,
  isUltraLight,
  darwin_client_id,
  mainView,
}) => {
  const [analyticsUrl, setAnalyticsUrl] = useState('');
  const [shouldReroute, setShouldReroute] = useState(false);
  const { presetsApi } = useCreativeAnalyticsApis();
  const viewId = useViewId();
  const hist = useHistory();
  const hasPermission = usePermissions();

  const { pathname } = useLocation();

  const isThimble = account_id === 'act_1150076978379250';
  const enableFacebookReports =
    platforms && platforms.length === 1 && platforms[0] === 'facebook';
  const enableVisualReport =
    enableFacebookReports && VISUAL_REPORT_WHITELIST.includes(account_id);

  const reportOptions = getReportOptions(
    enableFacebookReports,
    enableVisualReport,
    isThimble,
    isDirectMail,
    darwin_light,
    hasPermission
  );

  const showViews = !(
    pathname.includes('contractors') ||
    pathname.includes('marketplace') ||
    pathname.includes('event-history') ||
    pathname.includes('variation-report') ||
    pathname.includes('hurdle-report') ||
    pathname.includes('copy-report') ||
    pathname.includes('static-report')
  );

  const params = new URLSearchParams(window.location.search);
  const ignore_presets = params.get('ignore_presets') === 'true';

  let feature = '';

  useEffect(() => {
    if (darwin_client_id && !ignore_presets) {
      fetchPresetsData();
      setShouldReroute(true);
    }
  }, [darwin_client_id, viewId]);

  useEffect(() => {
    if (params.get('ignore_presets') === 'true') {
      return;
    }

    if (presetsApi.get.data) {
      const { data } = presetsApi.get;

      if (Array.isArray(data) && data.some((preset) => preset.is_default)) {
        let url = data.find((preset) => preset.is_default).url;

        const isAnalytics =
          getViewFeatureUrl(viewId).split('/')[2] === 'analytics';

        if (!isAnalytics) return;

        setAnalyticsUrl(url);

        if (url.includes(viewId)) {
          url = url.split(`${viewId}/`)[1];
        }

        if (shouldReroute) {
          hist.push(`/${viewId}/${url}`);
          setShouldReroute(false);
        }
      } else {
      }
    }
  }, [presetsApi.get.data]);

  useEffect(() => {
    if (analyticsUrl) {
      if (analyticsUrl.includes(viewId)) {
        setAnalyticsUrl(analyticsUrl.split(`${viewId}/`)[1]);
      }
    }
  }, [analyticsUrl]);

  const fetchPresetsData = () => {
    presetsApi.get.request({
      data: {
        client_id: darwin_client_id,
        view_id: viewId,
      },
      method: 'GET',
    });
  };

  if (pathname.includes('creative-reports')) {
    feature = params.get('report') === 'ad_labels' ? 'analytics' : 'reports';
  } else {
    feature = pathname.split('/').pop();
  }
  return (
    <nav className="dw-nav fixed-top dw-drop-shadow row m-0">
      <div className="col-3 dw-nav__left d-flex align-items-center">
        <div className="dw-nav__brand">
          <Link to={`${isNonPaidMediaType || isUltraLight ? 'library' : '/'}`}>
            <NavLogo className={`dw-nav__logo dw-nav__logo--${feature}`} />
          </Link>
        </div>
      </div>

      {loggedIn && viewId && !creative_studio_only && (
        <NavMenu
          {...{
            viewId,
            viewName,
            reportOptions,
            enableFacebookReports,
            darwin_light,
            isDirectMail,
            allowedAdmin,
            creative_studio_only,
            isNonPaidMediaType,
            isUltraLight,
            analyticsUrl,
          }}
        />
      )}

      <div className="col-9 col-lg-3 dw-nav__right d-flex align-items-center ">
        {loggedIn && (
          <>
            {showViews && (
              <ViewPicker
                {...{
                  analyticsUrl,
                  setAnalyticsUrl,
                  setShouldReroute,
                  mainView,
                }}
              />
            )}
            {!creative_studio_only &&
              !pathname.includes('copy-report') &&
              !pathname.includes('variation-report') &&
              !pathname.includes('hurdle-report') &&
              !pathname.includes('static-report') && (
                <AdminSettings
                  {...{
                    allowedAdmin,
                    isDirectMail,
                    viewId,
                    platforms,
                    darwin_light,
                    isNonPaidMediaType,
                    isUltraLight,
                  }}
                />
              )}
          </>
        )}
        {/* <RoleAccess roles={['internal']}>
          <Diagnostics
            allowedAdmin={allowedAdmin}
            isDirectMail={isDirectMail}
            viewId={viewId}
            platforms={platforms}
          />
        </RoleAccess> */}
        {/* <LogoutLink /> */}
      </div>
    </nav>
    // <BaseNav root={path === '/'} viewId={viewId} history={history} loggedIn={loggedIn}>
    //   {loggedIn && (
    //     <AuthedNav
    //       feature={feature}
    //       viewId={viewId}
    //       handlePage={handlePage}
    //       reportType={report}
    //       loggedIn={loggedIn}
    //     />
    //   )}
    // </BaseNav>
  );
};

// TopNav.defaultProps = {
//   creativeCount: null,
// };

// TopNav.propTypes = {
//   loggedIn: PropTypes.bool.isRequired,
//   start: PropTypes.string.isRequired,
//   end: PropTypes.string.isRequired,
//   audience: PropTypes.string.isRequired,
//   spendThreshold: PropTypes.number.isRequired,
//   creativeCount: PropTypes.number,
//   report: PropTypes.string.isRequired,
// };

export default Nav;
