import React from 'react';
import { useSelector } from 'react-redux';

export const useViewSettings = () => {
  const viewSettings = useSelector(({ ViewSettings }) => ViewSettings);

  return viewSettings;
};

export const useCreativeAnalyticsStore = () => {
  const state = useSelector(({ CreativeAnalytics }) => CreativeAnalytics);

  return state;
};

export const useLoading = (entity) => {
  return useSelector(({ Loaders }) => {
    return Loaders[entity];
  });
};

export const useError = (entity) => {
  return useSelector(({ Errors }) => {
    return Errors[entity];
  });
};
