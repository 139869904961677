import { useRef, useEffect, useMemo } from 'react';
import { isEqual } from 'lodash';
import { abortableFetch } from '../../utils';
import {
  useApiState,
  bindFetchToRef,
} from '../../components/Hooks/useApiState';
import {
  useApiResponseHandler,
  useLazyGetDnaReportQuery,
} from '../../store/CreativeAnalytics/api';
import {
  setDNAReportData,
  setMetadata,
  setSelectedVisuals,
} from '../../store/CreativeAnalytics/creativeAnalyticsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useViewSettings } from '../../selectors';
import { useCreativeAnalyticsGet } from '../CreativeAnalytics/contexts';
import { useSearchParams } from '../../components/Hooks/search-params';
import { usePostRequest } from '../../components/Hooks/usePostRequest';
import { toDarwinDate } from '../../utils/darwin-dates';
import { useActionName } from '../CreativeAnalytics/selectors';

function getDNAReport(payload) {
  return abortableFetch(
    ['api', 'creative-analytics', 'get-dna-report'].join('/'),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(payload),
    }
  );
}

export function useDNAReportApi() {
  const dispatch = useDispatch();
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [data, postDispatch] = useApiState(
    (payload) => withRef(getDNAReport(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    if (data?.data) {
      dispatch(setDNAReportData(data.data));
    }
  }, [data]);

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    ...data,
    request: (payload) => {
      postDispatch(payload);
    },
  };
}

export const useMetadataApi = () => {
  const dispatch = useDispatch();
  const { getParams } = useSearchParams();
  const { period1, period2 } = getParams('period1', 'period2') || {};
  const { viewId: view_id, darwin_client_id: client_id } = useViewSettings();
  const {
    data: metadata = { categories: [], visuals: [] },
    request,
    loading,
    error,
  } = useDNAReportApi();
  const { metricsData } = useSelector((state) => state.CreativeAnalytics);

  const visuals = metricsData ?? [];
  const ids = visuals.map(({ _id }) => _id);

  const selectAllVisuals = () => {
    dispatch(setSelectedVisuals(ids));
  };

  const cache = useRef(null);

  const fetch = () => {
    const payload = {
      view_id,
      client_id,
      all_visuals_array: ids.map((id) => ({ visual_id: id })),
    };
    const str = JSON.stringify(payload);

    if (isEqual(str, cache.current)) {
    } else {
      request(payload);
      selectAllVisuals();
      cache.current = str;
    }
  };

  useEffect(() => {
    cache.current = null;
  }, [view_id, client_id, period1, period2]);

  useEffect(() => {
    if (ids?.length) {
      fetch();
    }
  }, [ids]);

  useEffect(() => {
    if (metadata) {
      dispatch(setDNAReportData(metadata));
    }
  }, [metadata]);
};

const parseData = (data) => {
  const res = data?.Data || data;

  try {
    return JSON.parse(res);
  } catch (e) {
    console.error(e);
    return [];
  }
};
export const useCustomScoringApi = () => {
  const { account_id, campaigns } = useSelector(
    ({ ViewSettings: { account_id, campaigns } }) => ({
      account_id,
      campaigns,
    })
  );
  const { start_date, end_date, clientMetrics, primary_metric } =
    useCreativeAnalyticsGet();
  const { data, request, loading, error } = usePostRequest(
    ['api', 'creative-analytics', 'get-custom-scoring-data'].join('/')
  );
  const actionName = useActionName();

  let status = 'idle';

  if (data) status = 'success';
  else if (error) status = 'error';
  else if (loading) status = 'loading';

  const errString = error?.error?.toString() || error?.toString();

  const parsed = useMemo(() => {
    return parseData(data);
  }, [data])

  return {
    data: parsed,
    request: (dna_table: []) => {
      request({
        start_date: toDarwinDate(start_date),
        end_date: toDarwinDate(end_date),
        account_id,
        optimization_event: actionName,
        dna_table,
        campaign_id_array: campaigns,
      });
    },
    loading,
    error: errString,
    status,
  };
};
