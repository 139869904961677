import { useState, useMemo } from 'react';

import { METRIC_OPTIONS, UNIFIED_METRIC_OPTIONS } from './constants';

/**
 * @returns {[{ value: string, label: string}[], (args: ...any) => void]}
 */
export function useMetricOptions(platform, isIdealImage) {
  const [metrics, setMetrics] =
    platform == 'direct mail'
      ? useState([
          UNIFIED_METRIC_OPTIONS.find(({ value }) => value === 'spend'),
          UNIFIED_METRIC_OPTIONS.find(({ value }) => value === 'results'),
          UNIFIED_METRIC_OPTIONS.find(({ value }) => value === `${isIdealImage ? 'ctr' : 'cpa'}`),
        ])
      : useState([
          UNIFIED_METRIC_OPTIONS.find(({ value }) => value === 'spend'),
          UNIFIED_METRIC_OPTIONS.find(({ value }) => value === 'cpa'),
        ]);

  const handleColumns = ({ metric, checked }) =>
    setMetrics((prev) => {
      if (checked) {
        return [...prev, metric];
      }
      return prev.filter(({ value }) => value !== metric.value);
    });

  return [metrics, handleColumns];
}

export function sortSelectedMetrics(metrics) {
  const keys = METRIC_OPTIONS.map(({ value }) => value);
  const sorted = Array(keys.length);
  const custom = [];

  metrics.forEach((opt) => {
    const index = keys.indexOf(opt.value);

    if (index >= 0) {
      sorted[index] = opt;
    } else {
      custom.push(opt);
    }
  });

  return [...sorted.filter((s) => s), ...custom];
}

/**
 * @param {string[]} metaCols
 * @param {{ value: string}[]} sortedMetrics
 * @param {number} offset
 *
 * @returns {(columnData: Object<string, string | number>) => Object<string | number>[]}
 */
function makeCreateRow(metaCols, sortedMetrics, offset) {
  // Offset by the number of metrics selected.
  const lookup = (name) =>
    metaCols.findIndex((header) => header === name) * offset;
  const defaultArr = Array(metaCols.length * offset).fill('-');

  return (columnData) => {
    const results = [...defaultArr];

    columnData.forEach(({ name, ...rest }) => {
      const index = lookup(name);

      sortedMetrics.forEach(({ value }, i) => {
        results[index + i] =
          rest[value] === null || rest[value] === undefined ? '-' : rest[value];
      });
    });

    return results;
  };
}

export function useHeaderProps({ columns, metrics }) {
  const L = columns.length;
  const ML = metrics.length;

  const headerProps = useMemo(() => {
    const sortedMetrics = sortSelectedMetrics(metrics);
    const headers = columns.map((col) => ({
      key: col,
      colSpan: ML,
      children: col,
    }));
    const metricHeaders = columns.reduce((acc, col) => {
      sortedMetrics.forEach(({ value, label }, i) => {
        acc.push({
          key: `${col}-${value}-${i}`,
          useLeftBorder: i % ML === 0,
          children: label,
        });
      });

      return acc;
    }, []);

    return {
      numColumns: L,
      numMetrics: ML,
      headers,
      metricHeaders,
      createRow: makeCreateRow(columns, sortedMetrics, ML),
    };
  }, [L, ML]);

  return headerProps;
}

export function getSortedHeaders(breakdown, headers, period2) {
  switch (breakdown) {
    case 'platform':
      return ['Facebook', 'Instagram', 'Audience Network'];

    case 'device':
      return ['iPhone', 'Android Phone', 'Desktop', 'Other'];

    case 'placement':
      return ['FB Feed', 'IG Feed', 'IG Stories', 'FB Stories', 'Other'];

    case 'trending':
      return period2 && period2.length > 0 ? headers : ['Period 1'];

    default:
      return headers;
  }
}
