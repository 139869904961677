import { useReducer } from "react";
import { darwinDateToDate } from "../../../../utils/darwin-dates";
import { reportEditorReducer } from "./reducer";

export const useReportEditor = () => {
  const [{ current, errors }, dispatchReportEditor] = useReducer(reportEditorReducer, {
    current: {
      // id: "",
      name: "",
      status: "off",
      start_date: null,
      end_date: null,
      audience: null,
      excluded_labels: [],
      combined_category_names: [],
    },
    errors: [],
  });

  return {
    current,
    errors,
    dispatchReportEditor,
  };
};

export const useFormValidation = (current, reports) => {
  const errors = [];
  const { start_date, end_date, name, id } = current;

  if (
    !start_date ||
    !end_date ||
    darwinDateToDate(start_date) > darwinDateToDate(end_date) ||
    start_date === end_date
  ) {
    errors.push("date range");
  }

  const f = (str) => str.trim().toLowerCase();

  const nameInUse = 
    (!id && reports.some(
      (r) => f(r.name) === f(name)
    )) ||
    (id && reports.some(
      (r) => f(r.name) === f(name) && r.id !== id
    ))

  if (
    !name ||
    f(name) === "default" ||
    nameInUse
  ) {
    errors.push("name");
  }

  return errors;
};
