import { useRef, useEffect } from 'react';
import { abortableFetch } from '../../../utils';
import {
  useApiState,
  bindFetchToRef,
} from '../../../components/Hooks/useApiState';

const HEADERS = { 'content-type': 'application/json' };

function runLabelProcessorStatus({ view_id, darwin_client_id, category, platform, request_id, category_id }) {
  return abortableFetch(
    ['api', 'creative-analytics', 'custom-categories', 'run-label-processor', 'check-status', darwin_client_id].join(
      '/'
    ),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify({
        view_id, darwin_client_id, category, platform, request_id, category_id
      }),
    }
  );
}

export function useRunLabelProcessorStatus() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (payload) => withRef(runLabelProcessorStatus(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    ...getState,
    request: getDispatch,
  };
}