// DEFAULTS

import { CreativeGroup } from './types';

export const BLANK_GROUP: CreativeGroup = {
  title: 'Custom (BETA)',
  selectBy: 'all',
  count: 5,
  metric: null,
  labels: [],
  sortBy: 'spend',
  sortOrder: 'descending',
  selectedVisuals: [],
  custom: true,
};

export const ALL: CreativeGroup = {
  title: 'All Creatives',
  selectBy: null,
  count: 0,
  metric: null,
  labels: [],
  sortBy: 'spend',
  sortOrder: 'descending',
  selectedVisuals: [],
  isDefaultOption: true,
  custom: false,
};

export const TOP_5_PERFORMING: CreativeGroup = {
  title: 'Top Performing',
  selectBy: 'top',
  count: 5,
  metric: 'cpa',
  labels: [],
  sortBy: 'cpa',
  sortOrder: 'ascending',
  selectedVisuals: [],
  hideSort: true,
  isDefaultOption: true,
  custom: false,
};

export const TOP_5_PMAX: CreativeGroup = {
  title: 'Top Performing',
  selectBy: 'top',
  count: 5,
  metric: 'performance_score',
  labels: [],
  sortBy: 'performance_score',
  sortOrder: 'descending',
  selectedVisuals: [],
  hideSort: true,
  isDefaultOption: true,
  custom: false,
};

export const allByPrimaryMetric = (primary_metric) => ({
  title: 'All Creatives',
  selectBy: null,
  count: 0,
  metric: null,
  labels: [],
  sortBy: primary_metric,
  sortOrder: 'descending',
  selectedVisuals: [],
  isDefaultOption: true,
  custom: false,
});

export const top5ByPrimaryMetric = (primary_metric) => {
  return {
    title: 'Top 3 Performing',
    selectBy: 'top',
    count: 3,
    metric: primary_metric,
    labels: [],
    sortBy: primary_metric,
    sortOrder: primary_metric.startsWith('cp') ? 'ascending' : 'descending',
    selectedVisuals: [],
    hideSort: true,
    isDefaultOption: true,
    custom: false,
  };
};

export const TOP_5_PERFORMING_BY_FITNESS_SCORE: CreativeGroup = {
  title: 'Top Fitness Scores',
  selectBy: 'top',
  count: 5,
  metric: 'fitness_score',
  labels: [],
  sortBy: 'fitness_score',
  sortOrder: 'descending',
  selectedVisuals: [],
  hideSort: true,
  isDefaultOption: true,
  custom: false,
};

export const BOTTOM_5_PERFORMING: CreativeGroup = {
  title: 'Bottom 5 Performing by cpa',
  selectBy: 'bottom',
  count: 5,
  metric: 'cpa',
  labels: [],
  sortBy: 'cpa',
  sortOrder: 'ascending',
  isDefaultOption: true,
  custom: false,
};

export const TOP_5_SPENDING: CreativeGroup = {
  title: 'Top 3 Spending',
  selectBy: 'top',
  count: 3,
  metric: 'spend',
  labels: [],
  sortBy: 'spend',
  sortOrder: 'descending',
  selectedVisuals: [],
  hideSort: true,
  isDefaultOption: true,
  custom: false,
};

export const BOTTOM_5_SPENDING: CreativeGroup = {
  // title: 'Bottom 5 Spending',
  selectBy: 'bottom',
  count: 5,
  metric: 'spend',
  labels: [],
  sortBy: 'spend',
  sortOrder: 'ascending',
  isDefaultOption: true,
  custom: false,
};

export const IMAGES: CreativeGroup = {
  // title: 'Images',
  selectBy: 'element',
  selectedLabel: 'element',
  labels: [],
  sortBy: 'spend',
  sortOrder: 'ascending',
  custom: false,
};
