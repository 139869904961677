
import React from 'react';
import PropTypes from 'prop-types';
import { useCubeQuery } from '@cubejs-client/react';
import {
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
  LineChart,
  Line,
} from 'recharts';

import BootstrapTable from './CustomVisuals/BootstrapTable';
import AdScatter from './CustomVisuals/AdScatter';
import Status from './CustomVisuals/Status';
import DollarCategory from './CustomVisuals/DollarCategory';

import PeriodComparison from './CustomVisuals/PeriodComparison';

import { columnFormatter, adjustQuery, toFormattedDate } from './utils';

import './style.css';

// imported from rest of app
import { BasicError, ErrorBoundary } from '../../../../components/Helpers/Error';
import { BasicLoading } from '../../../../components/Helpers/Loading';
import { DARWIN_BLUE, DARWIN_RED, DARWIN_SEAFOAM } from '../../../../constants';

const COLORS = [
  DARWIN_BLUE,
  DARWIN_RED,
  DARWIN_SEAFOAM,
  '#ff00ff',
  '#379ff8',
  '#ffcb36',
  '#00d490',
  '#d9534f',
  '#5cb85c',
];

const TypeToChartComponent = {
  line: ({ resultSet }) => (
    <CartesianChart resultSet={resultSet} ChartComponent={LineChart}>
      {resultSet.seriesNames().map((series, i) => (
        <Line
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.title}
          stroke={COLORS[i]}
          type="monotone"
          dot={false}
          strokeWidth={adjustStroke(series.key)}
        />
      ))}
    </CartesianChart>
  ),

  bar: ({ resultSet }) => (
    <CartesianChart resultSet={resultSet} ChartComponent={BarChart}>
      {resultSet.seriesNames().map((series, i) => (
        <Bar
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.title}
          fill={COLORS[i]}
        />
      ))}
    </CartesianChart>
  ),
  area: ({ resultSet }) => (
    <CartesianChart resultSet={resultSet} ChartComponent={AreaChart}>
      {resultSet.seriesNames().map((series, i) => (
        <Area
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.title}
          stroke={COLORS[i]}
          fill={COLORS[i]}
        />
      ))}
    </CartesianChart>
  ),
  pie: ({ resultSet }) => (
    <ResponsiveContainer width="100%" height={350}>
      <PieChart>
        <Pie
          isAnimationActive={false}
          data={resultSet.chartPivot()}
          nameKey="x"
          dataKey={resultSet.seriesNames()[0].key}
          fill="#8884d8"
        >
          {resultSet.chartPivot().map((e, index) => (
            <Cell key={index} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  ),
  number: ({ resultSet }) => (
    <div className="row">
      <div className="col-md-12">
        {resultSet.seriesNames().map(({ key }) => (
          <div className="h3" key={key}>
            {columnFormatter(resultSet.totalRow()[key], key)}
          </div>
        ))}
      </div>
    </div>
  ),
  table: ({ resultSet }) => (
    <BootstrapTable
      headers={resultSet.tableColumns()}
      rows={resultSet.tablePivot()}
      formatter={columnFormatter}
    />
  ),
  // Only available for funnel diagnostics.
  custom_dollarCategory: DollarCategory,
  custom_adScatter: AdScatter,
  custom_status: Status,
  // Uses its own query/sub-queries.
  custom_periodComparison: PeriodComparison,
};
const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key]),
  }))
  .reduce((a, b) => ({ ...a, ...b }));

const errorToString = (e) => {
  if (e instanceof Error) {
    return e.toString();
  } if (typeof e === 'string') {
    return e;
  }
  return JSON.stringify(e);
};

const renderChart = (Component) => (props) => {
  const { error, resultSet } = props;

  if (error) {
    return (
      <BasicError
        fallback={(
          <>
            <h5>An Error occurred</h5>
            <p>{errorToString(error)}</p>
          </>
          )}
      />
    );
  } if (!resultSet) {
    return <BasicLoading />;
  }
  return (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );
};

function SwitchRenderer({
  vizState,
  useCustomRenderer = false,
  setVizState = null,
}) {
  const { query, chartType } = vizState;
  const component = TypeToMemoChartComponent[chartType];

  if (!component) {
    return (
      <p>
        No display for
        {' '}
        {chartType || 'an unselected chart type'}
        .
      </p>
    );
  }

  // Period comparison uses its own date query.
  if (useCustomRenderer || chartType === 'custom_periodComparison') {
    return renderChart(component)({
      query,
      setVizState,
      error: false,
      resultSet: {},
    });
  }

  // A date selection is required for timeseries charts (line, etc...).
  if (isTimeRequired(vizState)) {
    return <p>A date range is required for this chart but none was selected.</p>;
  }

  return <ChartRenderer component={component} query={query} />;
}

SwitchRenderer.defaultProps = {
  useCustomRenderer: false,
  vizState: {},
  setVizState: null,
};

SwitchRenderer.propTypes = {
  setVizState: PropTypes.func,
  vizState: PropTypes.shape(({
    chartType: PropTypes.string,
    query: PropTypes.shape({
      measures: PropTypes.arrayOf(PropTypes.string),
    }),
  })),
  useCustomRenderer: PropTypes.bool,
};

export default SwitchRenderer;

const ChartRenderer = ({ component, query }) => {
  const newQuery = adjustQuery(query);
  const renderProps = useCubeQuery(newQuery);

  return renderChart(component)(renderProps);
};

function numbersOnly(arr) {
  // eslint-disable-next-line no-self-compare
  return arr.filter((val) => typeof val === 'number' && val === val);
}

function chartPivotDomain(pivot) {
  const numbers = pivot
    .map(Object.values)
    .map(numbersOnly)
    .flat()
    .sort((a, b) => a - b);

  return [numbers[0], numbers.slice(-1)[0]];
}

const CartesianChart = ({ resultSet, children, ChartComponent }) => {
  const pivotData = resultSet.chartPivot();
  const [yMin] = chartPivotDomain(pivotData);

  return (
    <ResponsiveContainer width="100%" height={350}>
      <ChartComponent data={pivotData}>
        <XAxis dataKey="x" tickFormatter={toFormattedDate} />
        <YAxis
          domain={[Math.floor(yMin), 'auto']}
        />
        <CartesianGrid vertical={false} />
        {children}
        <Legend />
        <Tooltip formatter={columnFormatter} labelFormatter={toFormattedDate} />
      </ChartComponent>
    </ResponsiveContainer>
  );
};

CartesianChart.propTypes = {
  children: PropTypes.node.isRequired,
  resultSet: PropTypes.shape({ chartPivot: PropTypes.func }).isRequired,
  ChartComponent: PropTypes.func.isRequired,
};

function adjustStroke(key) {
  switch (key) {
    case 'ViewMetrics.cpaPercentChangeFrom30DAverage':
    case 'ViewMetrics.cpmPercentChangeFrom30DAverage':
    case 'ViewMetrics.cpcPercentChangeFrom30DAverage':
    case 'ViewMetrics.ctrPercentChangeFrom30DAverage':
    case 'ViewMetrics.cpatcPercentChangeFrom30DAverage':
      return '3';

    default:
      return '1';
  }
}

function isTimeRequired({ chartType, query: { timeDimensions = [] } }) {
  const hasTime = timeDimensions.length > 0;

  switch (chartType) {
    case 'line':
    case 'area':
    case 'bar':
      return !hasTime;

    default:
      return false;
  }
}
