import React, { useState } from "react";
import { DeleteIcon } from "../../icons/delete";
import { LabelIcon } from "../../icons/label";
import { DescriptionIcon } from "../../icons/description";
import { ExamplesIcon } from "../../icons/examples";
import { AddIcon } from "../../icons/add";
import { DotsIcon } from "../../icons/dots";
import { ArrowIcon } from "../../icons/arrow";
import { ClickAwayListener } from "@mui/material";
import Tooltip from "../../../../../components/Tooltip";
import { CaretDownIcon } from "../../icons/caretDown";

const SettingItem = ({
  currentItem,
  index,
  handleUpdateName,
  handleUpdateDescription,
  handleAddExample,
  handleUpdateExample,
  handleRemoveExample,
  handleDeleteSetting,
  error,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  const handleToggleCollapse = () => {
    setCollapsed((prev) => !prev);
  };

  const handleClickAway = (e) => {
    if (!e.target.classList.contains(`overlay-${index}`)) {
      setShowPopover(false);
    }
    
    return;
  };

  return (
    <div className="phrase-theme-settings__setting">
      <div className="setting-options">
        <Tooltip content={`${collapsed ? "Expand" : "Collapse"} this setting`}>
          <div
            className={`setting-options__toggle setting-options__toggle--collapse ${
              collapsed ? "collapsed" : ""
            }`}
            onClick={handleToggleCollapse}
          >
            <div className="setting-options__toggle-icon setting-options__toggle-icon--collapse">
              <ArrowIcon />
            </div>
          </div>
        </Tooltip>
        <div
          className={`setting-options__toggle setting-options__toggle--popover`}
        >
          <div
            className={`setting-options__toggle-overlay overlay-${index}`}
            onClick={() => setShowPopover((prev) => !prev)}
          ></div>
          <div className="setting-options__toggle-icon setting-options__toggle-icon--popover">
            <DotsIcon />
          </div>
          <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
            <div
              className={`setting-options__popover ${
                showPopover ? "show" : ""
              }`}
            >
              {showPopover && (
                <>
                  <Tooltip content={"Delete this setting"}>
                    <div
                      className="popover-option popover-option--delete"
                      onClick={() => handleDeleteSetting(index)}
                    >
                      <DeleteIcon />
                    </div>
                  </Tooltip>
                </>
              )}
            </div>
          </ClickAwayListener>
        </div>
      </div>
      <div className="setting-inputs">
        <div
          className={`setting-input setting-input--name ${
            collapsed ? "collapsed" : ""
          }`}
        >
          <label>
            <LabelIcon />
            <span>
              Label Name <span>*</span>
              {error && !currentItem.phrase_theme && (
                <span className="error"></span>
              )}
            </span>
          </label>
          <div className="input-container">
            <input
              placeholder="Empty"
              value={currentItem.phrase_theme}
              onChange={(e) => handleUpdateName(e, index)}
            ></input>
          </div>
        </div>
        <div
          onClick={handleToggleCollapse}
          className={`show-hidden ${collapsed ? "show" : ""}`}
        >
          <CaretDownIcon />
          Show hidden properties
        </div>
        <div
          className={`setting-input setting-input--description ${
            collapsed ? "collapsed" : ""
          }`}
        >
          <label>
            <DescriptionIcon />
            <span>Description</span>
          </label>
          <div className="input-container">
            <textarea
              placeholder="Empty"
              value={currentItem.description || ""}
              onChange={(e) => handleUpdateDescription(e, index)}
            ></textarea>
          </div>
        </div>
        <div
          className={`setting-input setting-input--examples ${
            collapsed ? "collapsed" : ""
          }`}
        >
          <label>
            <ExamplesIcon />
            <span>Examples</span>
          </label>
          <div className="d-flex flex-column flex-grow-1">
            {currentItem.examples.map((exampleText, exampleIndex) => {
              return (
                <div className="input-container">
                  <textarea
                    placeholder="Empty"
                    value={exampleText}
                    onChange={(e) => {
                      if (e.target.value === "\n" && !exampleText.length)
                        return;
                      handleUpdateExample(e, index, exampleIndex);
                    }}
                    autoFocus={
                      exampleIndex === currentItem.examples?.length - 1
                        ? true
                        : false
                    }
                  ></textarea>
                  <div
                    onClick={() => handleRemoveExample(index, exampleIndex)}
                    className="delete-example"
                  >
                    <DeleteIcon />
                  </div>
                </div>
              );
            })}
            <div
              className="add-example"
              onClick={(e) => handleAddExample(e, index)}
              onKeyDown={(e) => handleAddExample(e, index)}
              tabIndex={0}
            >
              <AddIcon /> Add example
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingItem;
