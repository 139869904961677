import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

/**
 * 
 * Content rendered in the toast.
 */
const Notification = ({ message, title, showEllipses }) => {
  return (
    <div className="toast__content">
      <h3 className="toast__title">
        {title}
      </h3>
      <h4 className={`toast__subtitle ${showEllipses ? 'loading-text' : ''}`}>{message}</h4>
    </div>
  );
}

export default Notification;