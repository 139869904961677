import { useEffect } from 'react';
import { useGetRequest } from '../../../../components/Hooks/useGetRequest';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomCategoriesData } from '../../../../store/CreativeAnalytics/creativeAnalyticsSlice';
import { usePostRequest } from '../../../../components/Hooks/usePostRequest';
import { useMakeUpdateOnePayload } from './hooks';

const makeEndpoint = (darwin_client_id) => {
  return [
    'api',
    'creative-analytics',
    'custom-categories',
    darwin_client_id,
    'fetch-all-ccc',
  ].join('/');
};

const formatData = (data) => {};

const handleRes = async (data) => {
  const s3_url = data.s3_url || null;

  if (!s3_url) return null;

  const jsonData = await getJSON(s3_url)
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.error(error);

      return null;
    });

  return jsonData;
};

export const getJSON = (url) => {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.onload = function () {
      var status = xhr.status;
      if (status === 200) {
        resolve(JSON.parse(xhr.response));
      } else {
        console.log('Error pulling json');
        reject(null);
      }
    };
    xhr.send();
  });
};

export const useFetchCustomCategoriesApi = () => {
  const dispatch = useDispatch();
  const { darwin_client_id, customCategoriesData } = useSelector(
    ({
      ViewSettings: { darwin_client_id },
      CreativeAnalytics: { customCategoriesData },
    }) => ({ darwin_client_id, customCategoriesData })
  );

  const { data, request, loading, error } = useGetRequest(
    makeEndpoint(darwin_client_id)
  );

  useEffect(() => {
    const handleDataResponse = async () => {
      if (data) {
        const res = await handleRes(data);
        if (res?.list_categories) {
          dispatch(setCustomCategoriesData(res.list_categories));
        }
      }
    };

    handleDataResponse();
  }, [data]);

  return {
    customCategoriesData,
    request,
    loading,
    error,
  };
  // when changes made, make sure to invalidate store
};

export const useSaveBulkVisuals = () => {
  const { darwin_client_id } = useSelector(
    ({ ViewSettings: { darwin_client_id } }) => ({
      darwin_client_id,
    })
  );
  const { data, loading, error, request } = usePostRequest([
    'api',
    'creative-analytics',
    'custom-categories',
    darwin_client_id,
    'bulk-update-ccc-visuals',
  ].join('/'));

  useEffect

  return {
    data,
    loading,
    error,
    request,
  };
};
