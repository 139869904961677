import React from 'react';

export const CreativeRequestIcon = ({ onClick }) => {
  return (
    <div className="" onClick={onClick}>
      <svg
        width="52"
        height="51"
        viewBox="0 0 52 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M33.3387 42.8772L42.3046 33.3004L47.6777 38.3308C48.0809 38.7082 48.1017 39.341 47.7243 39.7442L40.1252 47.861C39.7478 48.2642 39.1149 48.285 38.7118 47.9076L33.3387 42.8772Z"
          fill="#FFA4A4"
        />
        <path
          opacity="0.8"
          d="M10.0727 7.99533L6.12902 11.4943C5.96486 10.682 5.08556 8.66506 5.51304 7.45289C5.80801 6.61648 7.94069 7.57741 10.0727 7.99533Z"
          fill="#667394"
        />
        <rect
          width="12.8563"
          height="47.5105"
          rx="1"
          transform="matrix(0.727798 0.685792 -0.684382 0.729124 37.6578 6.60767)"
          fill="#B6E7FF"
        />
        <rect
          width="34.255"
          height="13.0442"
          transform="matrix(0.727798 0.685792 -0.684382 0.729124 17.1526 10.0706)"
          fill="#FFE6A5"
        />
        <path
          d="M36.7058 4.94342L6.14573 37.5321C5.7613 37.9421 5.79009 38.5882 6.20945 38.9623L13.8209 45.753C14.2246 46.1131 14.8414 46.087 15.2131 45.694L45.9968 13.1509C46.3872 12.7382 46.3558 12.0836 45.9278 11.7102L38.0927 4.87395C37.6871 4.52003 37.0741 4.55073 36.7058 4.94342Z"
          stroke="#1E2B4E"
        />
        <path
          opacity="0.8"
          d="M17.8225 42.8309L14.5613 39.8276"
          stroke="#1E2B4E"
        />
        <path
          opacity="0.8"
          d="M20.5342 39.8243L17.3633 36.9482"
          stroke="#1E2B4E"
        />
        <path
          opacity="0.8"
          d="M9.57212 7.85292C9.57212 7.85292 6.52 6.72785 5.76538 7.38001C5.01076 8.03217 6.02379 11.5122 6.02379 11.5122L9.57212 7.85292Z"
          fill="#667394"
        />
        <path d="M22.9882 36.9481L20.1949 34.667" stroke="#1E2B4E" />
        <path
          opacity="0.8"
          d="M9.25432 7.75028L17.1968 10.0943L13.5361 14.3926L8.19982 19.6755L6.0113 11.5105L9.25432 7.75028Z"
          fill="#FFE6CF"
        />
        <path
          d="M33.8931 25.4287L25.5414 17.5916L16.4193 27.3474L24.6607 35.0115L33.8931 25.4287Z"
          fill="#BCF0C1"
          fillOpacity="0.52"
        />
        <path
          d="M8.84498 17.98C17.814 26.1278 34.7611 41.5005 38.4053 44.7239C38.7875 45.062 39.347 45.0294 39.7024 44.6631L47.3494 36.7808C47.7431 36.375 47.7223 35.7238 47.3037 35.3439L16.2022 7.12265C16.0544 6.9885 15.8698 6.90167 15.6722 6.87334L6.94549 5.6221C6.23999 5.52094 5.65816 6.16923 5.83473 6.85972L8.55364 17.4922C8.60212 17.6818 8.70012 17.8484 8.84498 17.98Z"
          stroke="#1E2B4E"
        />
        <path d="M33.575 40.644L42.4399 31.3251" stroke="#1E2B4E" />
        <path
          opacity="0.8"
          d="M6.75493 10.1583C7.35426 9.73712 9.4858 7.29626 10.4766 6.12847"
          stroke="#1E2B4E"
        />
        <path
          opacity="0.8"
          d="M8.36168 16.2628L10.4156 15.5187C10.7628 15.393 11.0124 15.086 11.0648 14.7205L11.1316 14.2547C11.1934 13.8236 11.5271 13.4821 11.9566 13.4103L12.3838 13.3389C12.8105 13.2676 13.142 12.9283 13.2034 12.5001L13.2281 12.3274C13.2923 11.8796 13.7073 11.5687 14.1551 11.6329V11.6329C14.6028 11.6971 15.0178 11.3862 15.082 10.9385L15.135 10.5683C15.1869 10.2069 15.4602 9.91716 15.818 9.8444V9.8444C16.1757 9.77164 16.4491 9.48186 16.5009 9.12049L16.6819 7.85852"
          stroke="#1E2B4E"
        />
        <path d="M38.3735 21.0632L36.1226 18.8303" stroke="#1E2B4E" />
        <path d="M40.6744 18.8305L37.6146 15.7903" stroke="#1E2B4E" />
        <path d="M42.8867 16.5503L40.4293 14.2983" stroke="#1E2B4E" />
      </svg>
      {/* <div className="request-icon__label">Creative Request</div> */}
    </div>
  );
};

export const CopyIcon = ({ onClick, setHeaderText }) => {
  return (
    <div
      className="request__icon"
      onClick={onClick}
      onMouseEnter={() => setHeaderText('Save and copy URL to clipboard')}
      onMouseLeave={() => setHeaderText('')}
    >
      <svg
        width="12"
        height="15"
        viewBox="0 0 12 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M11.052 2.10233L9.77813 0.683681C9.55711 0.437539 9.25733 0.299257 8.94476 0.299255L4.71865 0.299255C4.06773 0.299255 3.54007 0.886873 3.54007 1.61176V2.92426H1.57579C0.924873 2.92426 0.397217 3.51187 0.397217 4.23676V12.9868C0.397217 13.7116 0.924873 14.2993 1.57579 14.2993H7.07579C7.7267 14.2993 8.25436 13.7116 8.25436 12.9868V11.6743H10.2186C10.8696 11.6743 11.3972 11.0866 11.3972 10.3618V3.0304C11.3972 2.68231 11.273 2.34847 11.052 2.10233ZM6.92847 12.9868H1.72311C1.68404 12.9868 1.64657 12.9695 1.61894 12.9387C1.59131 12.9079 1.57579 12.8662 1.57579 12.8227V4.40082C1.57579 4.35731 1.59131 4.31558 1.61894 4.28481C1.64657 4.25404 1.68404 4.23676 1.72311 4.23676H3.54007V10.3618C3.54007 11.0866 4.06773 11.6743 4.71865 11.6743H7.07579V12.8227C7.07579 12.8662 7.06027 12.9079 7.03264 12.9387C7.00501 12.9695 6.96754 12.9868 6.92847 12.9868ZM10.0713 10.3618H4.86597C4.82689 10.3618 4.78942 10.3445 4.76179 10.3137C4.73417 10.2829 4.71865 10.2412 4.71865 10.1977V1.77582C4.71865 1.73231 4.73417 1.69058 4.76179 1.65981C4.78942 1.62904 4.82689 1.61176 4.86597 1.61176H7.46865V4.01801C7.46865 4.38045 7.73247 4.67426 8.05793 4.67426H10.2186V10.1977C10.2186 10.2412 10.2031 10.2829 10.1755 10.3137C10.1479 10.3445 10.1104 10.3618 10.0713 10.3618ZM10.2186 3.36176H8.64722V1.61176H8.88372C8.92278 1.61176 8.96025 1.62904 8.9879 1.6598L10.1755 2.98236C10.1892 2.9976 10.2 3.01569 10.2074 3.03559C10.2148 3.0555 10.2186 3.07683 10.2186 3.09838V3.36176Z"
            fill="#3A4B77"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="11"
              height="14"
              fill="white"
              transform="translate(0.397217 0.299255)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export const RefreshIcon = ({ className, onClick, setHeaderText, unsavedChanges }) => {
  return (
    <div
      className={`request__icon ${className ? className : ''} ${!unsavedChanges ? "disabled" : ""}`}
      onClick={onClick}
      // onMouseEnter={() => setHeaderText('Undo changes')}
      // onMouseLeave={() => setHeaderText('')}
    >
      <svg
        width="15"
        height="17"
        viewBox="0 0 15 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.01915 0.468846C8.17042 0.31762 8.37556 0.232666 8.58946 0.232666C8.80336 0.232666 9.0085 0.31762 9.15977 0.468846L11.5798 2.88884C11.731 3.04011 11.8159 3.24525 11.8159 3.45915C11.8159 3.67305 11.731 3.87819 11.5798 4.02946L9.15977 6.44946C9.00763 6.5964 8.80387 6.6777 8.59236 6.67587C8.38086 6.67403 8.17854 6.58919 8.02897 6.43963C7.87941 6.29007 7.79458 6.08775 7.79274 5.87624C7.7909 5.66474 7.87221 5.46097 8.01915 5.30883L9.06217 4.26582H7.78279C4.92075 4.26582 2.53948 6.64709 2.53948 9.50913C2.53948 12.3712 4.92075 14.7525 7.78279 14.7525C10.6448 14.7525 13.0261 12.3712 13.0261 9.50913C13.0261 9.29519 13.1111 9.09002 13.2624 8.93874C13.4137 8.78746 13.6188 8.70247 13.8328 8.70247C14.0467 8.70247 14.2519 8.78746 14.4032 8.93874C14.5545 9.09002 14.6394 9.29519 14.6394 9.50913C14.6394 13.2617 11.5354 16.3658 7.78279 16.3658C4.03019 16.3658 0.926147 13.2617 0.926147 9.50913C0.926147 5.75653 4.03019 2.65249 7.78279 2.65249H9.06217L8.01915 1.60947C7.86792 1.4582 7.78297 1.25306 7.78297 1.03916C7.78297 0.82526 7.86792 0.620118 8.01915 0.468846V0.468846Z"
          fill="#3A4B77"
        />
      </svg>
    </div>
  );
};

export const ArrowIcon = ({ url }) => {
  return (
    <a className="request__arrow" target="_blank" href={url}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M11.4752 3.86279L10.8123 4.52569L13.6247 7.33811H1.40076V8.27564H13.6246L10.8123 11.088L11.4752 11.7509L15.4193 7.80683L11.4752 3.86279Z"
            fill="#1E2B4E"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="15"
              height="15"
              fill="white"
              transform="translate(0.932007 0.306885)"
            />
          </clipPath>
        </defs>
      </svg>
    </a>
  );
};

export const SaveIcon = ({ onClick, setHeaderText, unsavedChanges }) => {
  return (
    <div
      className={`request__icon ${!unsavedChanges ? "disabled" : ""}`}
      onClick={onClick}
      // onMouseEnter={() => setHeaderText('Save')}
      // onMouseLeave={() => setHeaderText('')}
    >
      <svg
        width="15"
        height="17"
        viewBox="0 0 15 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.5587 4.06066L10.9378 1.43934C10.6566 1.15804 10.2751 1 9.87735 1H1.50011C0.671815 1 0.000369344 1.67156 0.000369344 2.5V13.5C0.000369344 14.3284 0.671815 15 1.50011 15H12.4982C13.3265 15 13.9979 14.3284 13.9979 13.5V5.12131C13.9979 4.72349 13.8399 4.34196 13.5587 4.06066V4.06066ZM8.4989 2.5V5H4.49959V2.5H8.4989ZM12.3107 13.5H1.68758C1.63786 13.5 1.59017 13.4802 1.55502 13.4451C1.51986 13.4099 1.50011 13.3622 1.50011 13.3125V2.6875C1.50011 2.63777 1.51986 2.59008 1.55502 2.55492C1.59017 2.51975 1.63786 2.5 1.68758 2.5H2.99985V5.75C2.99985 6.16422 3.33557 6.5 3.74972 6.5H9.24877C9.66291 6.5 9.99864 6.16422 9.99864 5.75V2.62131L12.4433 5.06641C12.4607 5.08382 12.4745 5.10449 12.4839 5.12724C12.4934 5.14999 12.4982 5.17438 12.4982 5.199V13.3125C12.4982 13.3622 12.4785 13.4099 12.4433 13.4451C12.4081 13.4802 12.3605 13.5 12.3107 13.5V13.5ZM6.99916 7.25C5.48308 7.25 4.24963 8.48366 4.24963 10C4.24963 11.5163 5.48308 12.75 6.99916 12.75C8.51524 12.75 9.74868 11.5163 9.74868 10C9.74868 8.48366 8.51524 7.25 6.99916 7.25ZM6.99916 11.25C6.31003 11.25 5.74937 10.6892 5.74937 10C5.74937 9.31075 6.31003 8.75 6.99916 8.75C7.68829 8.75 8.24894 9.31075 8.24894 10C8.24894 10.6892 7.68829 11.25 6.99916 11.25Z"
          fill="#3A4B77"
        />
      </svg>
    </div>
  );
};
