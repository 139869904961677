import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { Loading } from "../Helpers/Loading";
import FooterWrapper from "../ReportChartFooter";

import { Layout } from "./Layout";
import { GanttChart } from "./GanttChart";
import { DetailsContainer } from "./Details";
import { useViewProps, useSelectedStudy } from "./Container";
import { useApi, ApiProvider } from "./Api";
import { SuccessModal, FailureModal } from "./StatusModals";

const HEIGHT = { height: "80vh" };

function ContainedStudyFlow({ ChartPicker }) {
  const viewProps = useViewProps();

  return (
    <ApiProvider {...viewProps}>
      <StudyFlow ChartPicker={ChartPicker} viewId={viewProps.view_id} />
    </ApiProvider>
  );
}

ContainedStudyFlow.propTypes = {
  ChartPicker: PropTypes.node.isRequired,
};

export default ContainedStudyFlow;

function StudyFlow({ ChartPicker }) {
  let mounted = useRef(false);
  const { flowchart } = useApi();
  const [showModal, setShowModal] = useState("");

  const save = async (studies) => {
    const toSave = studies.filter(({ action }) => action.includes("ADD"));

    flowchart.put.request(toSave);
  };

  const remove = async (study_name) => {
    flowchart.delete.request([{ study_name }]);
  };

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, [mounted.current]);

  useEffect(() => {
    flowchart.get.request();
  }, []);

  useEffect(() => {
    if (flowchart.put.error || flowchart.delete.error) {
      setShowModal("FAILURE");
    }
  }, [flowchart.put.error, flowchart.delete.error]);

  useEffect(() => {
    if (flowchart.put.data || flowchart.delete.data) {
      setShowModal("SUCCESS");
    }
  }, [flowchart.put.data, flowchart.delete.data]);

  if (flowchart.get.error) {
    return (
      <Layout.Row>
        <LeftPanelWithFooter ChartPicker={ChartPicker}>
          <p>{errorMessage(flowchart.get.error)}</p>
        </LeftPanelWithFooter>
      </Layout.Row>
    );
  } else if (flowchart.get.loading || !flowchart.get.data) {
    return (
      <Layout.Row>
        <LeftPanelWithFooter>
          <Loading />
        </LeftPanelWithFooter>
      </Layout.Row>
    );
  } else {
    const { chart } = flowchart.get.data;

    return (
      <>
        <StudyFlowDisplay
          data={chart}
          ChartPicker={ChartPicker}
          handleSave={save}
          handleRemove={remove}
        />
        <SuccessModal
          show={showModal === "SUCCESS"}
          handleHide={() => setShowModal("")}
        />
        <FailureModal
          show={showModal === "FAILURE"}
          handleHide={() => setShowModal("")}
        />
      </>
    );
  }
}

StudyFlow.propTypes = {
  ChartPicker: ContainedStudyFlow.propTypes.ChartPicker,
};

function StudyFlowDisplay({ data, ChartPicker, handleSave, handleRemove }) {
  const [study, handleSelection] = useSelectedStudy(data);

  return (
    <Layout.Row styleExtension={{ position: "relative" }}>
      <LeftPanelWithFooter ChartPicker={ChartPicker}>
        <GanttChart
          data={data}
          handleSelection={handleSelection}
          handleSave={handleSave}
          handleRemove={handleRemove}
          _id={study.value}
        />
      </LeftPanelWithFooter>

      <Layout.RightPanel styleExtension={HEIGHT}>
        <DetailsContainer {...study} />
      </Layout.RightPanel>
    </Layout.Row>
  );
}

StudyFlowDisplay.propTypes = {
  ChartPicker: ContainedStudyFlow.propTypes.ChartPicker,
  handleSave: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
    })
  ),
};

function LeftPanelWithFooter({ children, ChartPicker }) {
  return (
    <Layout.LeftPanel styleExtension={HEIGHT}>
      {children}

      <FooterWrapper height={500}>
        <div className="d-flex flex-row justify-content-between">
          <div className="align-self-end">{ChartPicker}</div>
        </div>
      </FooterWrapper>
    </Layout.LeftPanel>
  );
}

function errorMessage(error) {
  return error.error || "An error occurred";
}
