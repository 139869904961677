import React, { useCallback, useEffect, useRef } from 'react';
import { DrawerAction, DrawerState, DrawerViewState } from '../../types';
import { VisualsLookup, VisualsLookupItem } from '../../../ReportCard/types';
import { LabelObject } from '../../../types';
import { VisualViewer } from '../VisualViewer/VisualViewer';
import { VisualPreview } from '../VisualViewer/Preview';
import { CategoryViewer } from '../CategoryViewer/CategoryViewer';
import { CCCEditor } from './ccc-editor';
import clsx from 'clsx';
import { CLOSE, SET_VIEW } from '../../hooks/useExplorerState';
import { BrowserWrapper } from '../Browser/Browser';
import { OverlayToolbar } from './OverlayToolbar';
import { useOnScreen } from '../../../../../hooks/useOnScreen';
import Tooltip from '../../../../../components/Tooltip/Tooltip';
import { DRAWER_TYPE_LABELS } from '../../constants';
import {
  useCreativeAnalyticsGet,
  useCreativeAnalyticsSet,
} from '../../../contexts';
import { useCopyToClipboard } from '../../../../../hooks/useCopyToClipboard';
import { useCategoriesLookup, useVisualById } from '../../../selectors';

const appendCategories = (currentVisual, categoriesLookup) => {
  if (!currentVisual) return null;

  try {
    return {
      ...currentVisual,
      categories: currentVisual?.category_ids?.map(
        (id) => categoriesLookup[id]
      ),
    };
  } catch (e) {
    return currentVisual;
  }
};

type DrawerProps = {
  filteredData: LabelObject[];
  visualsLookup?: VisualsLookup;
  isOpen: boolean;
  dispatch: React.Dispatch<DrawerAction>;
  handleClose: () => void;
  handleBack: () => void;
  currentView: DrawerViewState;
  previousViews: DrawerViewState[];
  selectedVisualId?: string;
  isSubDrawer?: boolean;
  keepState: boolean;
};

export const Drawer = ({
  dispatch,
  subDrawerDispatch,
  handleClose,
  handleBack,
  currentView,
  previousViews,
  selectedVisualId,
  isSubDrawer,
  keepState,
}: DrawerProps) => {
  const { setParams } = useCreativeAnalyticsSet();
  const { display } = useCreativeAnalyticsGet();
  const getVisualById = useVisualById();
  let DrawerContent;
  const categoriesLookup = useCategoriesLookup();
  const currentVisual = appendCategories(
    getVisualById(selectedVisualId) || null,
    categoriesLookup
  );
  const allowGoBack = previousViews.length > 0;
  let shouldShowPreview = false;
  const previewRef = useRef(null);
  const previewIsVisible = useOnScreen(previewRef, '0px', [currentView]);
  const { isCopied, copyToClipboard } = useCopyToClipboard();

  const handleCopyId = useCallback(() => {
    copyToClipboard(selectedVisualId);
  }, [copyToClipboard]);

  const singleToFull = () => {
    subDrawerDispatch({
      type: SET_VIEW,
      currentView: {
        type: 'ccc_editor',
        category: {
          category_name: currentView.category?.category_name,
          category_id: currentView.category?.category_id,
          ccc_type: currentView.category?.ccc_type,
          visual_hash: null,
        },
      },
    });
  };

  const openVisualsBrowser = () => {
    dispatch({
      type: SET_VIEW,
      currentView: {
        type: 'browser',
        browserType: 'visuals',
      },
    });
  };
  const openReportCard = () => {
    if (selectedVisualId) {
      setParams({ rc: selectedVisualId });
    }
  };

  const Preview = currentVisual ? (
    <VisualPreview
      previewRef={previewRef}
      data={currentVisual}
      OverlayToolbar={
        <OverlayToolbar {...{ openVisualsBrowser, handleClose, isSubDrawer }} />
      }
    />
  ) : null;

  switch (currentView.type) {
    case 'visual_viewer':
      if (!currentVisual) break;

      DrawerContent = (
        <VisualViewer
          {...{
            selectedVisualId,
            currentVisual,
            dispatch,
            Preview,
            handleClose,
            previewIsVisible,
            openReportCard,
            isCopied,
            handleCopyId,
          }}
        />
      );

      shouldShowPreview = true;
      break;

    case 'category_viewer':
      DrawerContent = (
        <CategoryViewer
          category_name={currentView.id}
          {...{
            selectedVisualId,
            currentVisual,
            dispatch,
            subDrawerDispatch,
            Preview,
            handleBack,
            handleClose,
            previewIsVisible,
            openReportCard,
            isCopied,
            handleCopyId,
          }}
        />
      );

      if (selectedVisualId) shouldShowPreview = true;
      break;

    case 'ccc_editor':
      DrawerContent = (
        <CCCEditor currentView={currentView} singleToFull={singleToFull} />
      );

      break;
    case 'browser':
      DrawerContent = (
        <BrowserWrapper
          browserType={currentView.browserType}
          {...{ dispatch, handleClose, keepState }}
        />
      );
      break;
    default:
      DrawerContent = <p>There was an error.</p>;
  }

  return (
    <DrawerWrapper
      currentView={currentView}
      isSubDrawer={isSubDrawer}
      isCCCEditor={currentView.type === 'ccc_editor'}
      handleClose={handleClose}
      handleBack={handleBack}
      allowGoBack={allowGoBack}
      shouldShowPreview={shouldShowPreview}
      previousViews={previousViews}
    >
      {(shouldShowPreview && Preview) || null}
      {DrawerContent}
    </DrawerWrapper>
  );
};

const DrawerWrapper = ({
  children,
  currentView,
  isSubDrawer,
  isCCCEditor,
  handleClose,
  handleBack,
  allowGoBack,
  previousViews,
}) => {
  const componentRef = useRef(null);
  const previousView =
    previousViews.length > 0 ? previousViews[previousViews.length - 1] : null;

  useEffect(() => {
    const node = componentRef.current;
    if (node) {
      node.scrollTop = 0;
    }
  }, [currentView]);

  const previousViewTooltip = getPreviousViewTooltip(previousView);

  return (
    <div
      ref={componentRef}
      className={clsx('drawer', {
        'is-sub-drawer': isSubDrawer,
        'is-ccc-editor': isCCCEditor,
      })}
    >
      {children}

      {isCCCEditor && (
        <div className="drawer-controls">
          <button title="Close window" onClick={handleClose}>
            <i className="drawer__close fa fa-xmark"></i>
          </button>

          {allowGoBack && (
            <Tooltip
              shouldShow={!!previousViewTooltip}
              content={previousViewTooltip}
              placement="left"
              follow={false}
            >
              <button
                title={!!previousViewTooltip ? null : 'Previous view'}
                onClick={handleBack}
              >
                <i className="fa fa-arrow-left" />
              </button>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

const getPreviousViewTooltip = (previousView: DrawerViewState) => {
  if (!previousView) return null;
  const { type } = previousView;

  const title = DRAWER_TYPE_LABELS[previousView.type];
  let id = '';

  if (type === 'visual_viewer' || type === 'category_viewer')
    id = previousView.id;
  if (type === 'browser') id = previousView.browserType;
  if (type === 'ccc_editor') id = previousView.category.category_name;

  return (
    <div>
      <p className="mb-2">Previous view</p>
      <hr className="my-2" />
      <div>{title}:</div>
      <div>• {id}</div>
    </div>
  );
};
