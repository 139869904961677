import React from "react";
import {
  END
} from "../../../../utils/darwin-dates";
import { MINIMUM } from "./constants";
import { SET_END_DATE, SET_START_DATE } from "./reducer";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const DatePickers = ({ current, handleSelectDate }) => {
  return (
    <>
      <form autoComplete="off">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start date"
            value={current.start_date}
            minDate={dayjs(MINIMUM)}
            maxDate={dayjs(END)}
            onChange={(newValue) => {
              console.log(newValue)
              if (newValue) handleSelectDate(newValue.$d, SET_START_DATE);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </form>
      <form autoComplete="off">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="End date"
            value={current.end_date}
            minDate={dayjs(MINIMUM)}
            maxDate={dayjs(END)}
            onChange={(newValue) => {
              if (newValue) handleSelectDate(newValue.$d, SET_END_DATE);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </form>
    </>
  );
};

export default DatePickers;
