/**
 * Truncates a string to a specified length and appends ellipses if the string exceeds that length.
 *
 * @param text - The string to be truncated.
 * @param maxLength - The maximum length of the string before truncation.
 * @returns The truncated string with ellipses if it exceeds the specified length, otherwise the original string.
 */
export const truncate = (text: string, maxLength: number): string => {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }

  return text;
};
