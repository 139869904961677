export const SET_SELECTED_REPORT = "set-selected-report";
export const SET_SELECTED_CATEGORY = "set-selected-category";
export const SET_SELECTED_LABEL = "set-selected-label";

export function impactReportReducer(state, action) {
  switch (action.type) {
    case SET_SELECTED_REPORT:
      return {
        ...state,
        selectedReport: action.payload,
        selectedCategory: "",
        selectedLabel: "",
      }
    
    case SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: action.payload,
        selectedLabel: "",
      }

    case SET_SELECTED_LABEL:
      return {
        ...state,
        selectedLabel: action.payload,
      }
  }
}