import { useEffect, useMemo, useState } from 'react';
import { getAllKeywords } from '../utils';
import { formatLabel } from '../BasicLabelsSetup/utils';

export const useKeywords = ({ filteredData, fullData, keywordsApi }) => {
  const [keywordsData, setKeywordsData] = useState({});
  const [existingKeywords, setExistingKeywords] = useState([]);

  useEffect(() => {
    if (keywordsApi.data) {
      setKeywordsData(keywordsApi.data);
    }
  }, [keywordsApi.data]);

  const allKeywords = useMemo(() => {
    return getAllKeywords(keywordsData);
  }, [keywordsData]);

  useEffect(() => {
    if (filteredData.length && !fullData) {
      setExistingKeywords(
        filteredData
          .filter((label) => label.category === 'Keyword')
          .map((label) => formatLabel(label.name))
      );
    }
  }, [filteredData.length]);

  return {
    keywordsData,
    setKeywordsData,
    existingKeywords,
    setExistingKeywords,
    allKeywords,
  };
};
