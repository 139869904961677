import React from 'react';

const CarouselNav = ({ prevRef, nextRef }) => {
  return (
    <div className="variant-carousel__nav">
      <div className="nav-buttons">
        <div
          ref={prevRef}
          className="variant-carousel__arrow variant-carousel__prev"
        >
          <i className="fa fa-caret-left"></i>
        </div>
        <div
          ref={nextRef}
          className="variant-carousel__arrow variant-carousel__next"
        >
          <i className="fa fa-caret-right"></i>
        </div>
      </div>
    </div>
  );
};

export default CarouselNav;
