import { useEffect, useRef, useState } from 'react';
import { missingSpendModal } from '../MissingSpend';
import { DATE_PAIRS, darwinDateToDate } from '../../../utils/darwin-dates';

export const useMissingSpend = ({
  data = [],
  view_id,
  isPF,
  missingSpendApi,
  start_date,
  end_date,
  pull_live_metrics,
  label_queue,
}) => {
  const [missingSpend, setMissingSpend] = useState({
    total: null,
  });

  const getMissingSpend = () => {
    let payload = {
      view_id,
      start_date,
      end_date,
    };
    if ((!pull_live_metrics) && (!label_queue)) {
      missingSpendApi.post.request(payload);
    }
  };

  function getDateString(start_date: string, end_date: string): string {
    var date_string = 'from ' + start_date + ' to ' + end_date;
    for (var i = 0; i < DATE_PAIRS.length; i++) {
      if (
        DATE_PAIRS[i].value[0].toDateString() ===
          darwinDateToDate(start_date).toDateString() &&
        DATE_PAIRS[i].value[1].toDateString() ===
          darwinDateToDate(end_date).toDateString()
      ) {
        if (DATE_PAIRS[i].label.includes('Last')) {
          date_string = 'for the ' + DATE_PAIRS[i].label.toLowerCase();
        } else {
          date_string = 'for ' + DATE_PAIRS[i].label.toLowerCase();
        }
        break;
      }
    }
    return date_string;
  }

  const apiCalled = useRef(false)

  useEffect(() => {
    if (isPF && data.length > 0 && apiCalled.current === false) {
      getMissingSpend();
      apiCalled.current = true;
    }
  }, [data.length]);

  useEffect(() => {
    if (missingSpendApi.post.data) {
      let { missing_permissions_spend, missing_DPA, total_missing_spend } =
        missingSpendApi.post.data;
      let date_string = getDateString(start_date, end_date);
      setMissingSpend({
        permissions: missing_permissions_spend,
        dpa: missing_DPA,
        total: total_missing_spend,
        date_string,
      });
    }
  }, [missingSpendApi.post.data]);

  useEffect(() => {
    if (isPF && Object.values(missingSpend).some((spend) => !!spend && spend > 0) && data) {
      missingSpendModal(missingSpend);
    }
  }, [missingSpend]);
};
