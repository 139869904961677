import React, { useMemo } from 'react';
import Tooltip from '../../../../../components/Tooltip';
import { toCurrency } from '../../../../../utils/numbers';
import { useAccountId } from '../../../../CreativeAnalytics/selectors';

export const CLIENT_SPECIFIC_COLS = {
  act_693999105130803: [
    { Header: 'Welcome Visits', accessor: 'total_welcome_visits' },
    {
      Header: 'CWPV',
      accessor: 'cost_per_welcome_visits',
      Cell: ({ value }) => toCurrency(value),
    },
  ],
};

export const useSummaryCols = (isROAS: boolean = false, data) => {
  const account_id = useAccountId();

  return useMemo(() => {
    let result = [];
    const additional = CLIENT_SPECIFIC_COLS[account_id] ?? [];

    if (isROAS) {
      result = [...baseSummaryCols, ...roasSummaryCols];
    } else {
      result = [...baseSummaryCols, ...cpaSummaryCols];
    }

    return filterCols([...result, ...additional], data);
  }, [isROAS, data, account_id]);
};

export const filterCols = (result, data) => {
  const dataHasAdNameGroup = data.some(
    (group) => !!group.ad_name_group && group.ad_name_group !== 'No Grouping'
  );
  const dataHasLabelName = data.some(
    (group) => !!group.label_name && group.label_name !== 'None'
  );

  return result.filter((col) => {
    if (col.accessor === 'ad_name_group') {
      return !!dataHasAdNameGroup;
    } else if (col.accessor === 'label_name') {
      return !!dataHasLabelName;
    } else {
      return true;
    }
  });
};

export const baseSummaryCols = [
  { Header: 'Ad Name Group', accessor: 'ad_name_group' },
  {
    width: 150,
    Header: 'Label Name',
    accessor: 'label_name',
    Cell: ({ value }) => (
      <Tooltip content={value}>
        <div>{value}</div>
      </Tooltip>
    ),
  },
  {
    Header: 'Total Spend',
    accessor: 'total_spend',
    Cell: ({ value }) => toCurrency(value),
  },
];

const roasSummaryCols = [
  {
    Header: 'ROAS',
    accessor: 'total_roas',
    Cell: ({ value }) => value?.toFixed(2) || value || '-',
  },
  {
    Header: 'Total Revenue',
    accessor: 'total_revenue',
    Cell: ({ value }) => toCurrency(value),
  },
];

const cpaSummaryCols = [
  { Header: 'Total Link Clicks', accessor: 'total_link_clicks' },
  { Header: 'Total Results', accessor: 'total_results' },

  {
    Header: 'Total CPA',
    accessor: 'total_cpa',
    Cell: ({ value }) => toCurrency(value),
  },
  {
    Header: 'Total CPC',
    accessor: 'total_cpc',
    Cell: ({ value }) => toCurrency(value),
  },
  {
    Header: 'CVR',
    accessor: 'CVR',
  },
];
