import { connect } from 'react-redux';
import { setViewSettings } from '../../store/ViewSettings/actions';

const stateToProps = ({
  ViewSettings: { viewName, views, report_group_name },
}) => ({
  viewName,
  views,
  report_group_name,
});

const dispatchToProps = (dispatch) => ({
  setView: (viewId, viewName, isLeverView) =>
    dispatch(
      setViewSettings({
        viewId,
        viewName,
        isLeverView,
        resetStore: true,
        initialView: false,
        report_group_id: null,
        report_group_name: null,
      })
    ),
});

export default connect(stateToProps, dispatchToProps);
