import React, { useMemo, useRef } from 'react';
import { ViewSelector } from '../../../components/Dropdowns';
import { useScrollIntoView } from '../../../hooks/useScrollIntoView';
import Carousel from './Carousel';

import './Header.scss';

const Header = ({
  metric,
  setMetric,
  visualsData,
  recsTab,
  isROAS,
  fromDraftCreative,
  handleBlockValue,
  handleBlockVisualType,
}) => {
  const ref = useRef();
  // useScrollIntoView(ref, recsTab === 'top', {
  //   behavior: 'smooth',
  //   block: 'center',
  // });

  const Header = <MetricSelect {...{ metric, setMetric }} />;

  const updatedData = useMemo(() => {
    return visualsData.map((visual) => ({
      visuals: [visual],
    }));
  }, [visualsData]);

  return (
    <div ref={ref} style={{ position: 'relative' }}>
      <Carousel
        Header={Header}
        visualsData={updatedData}
        isHeader
        isROAS={isROAS}
        {...{ fromDraftCreative, handleBlockValue, handleBlockVisualType }}
      />
    </div>
  );
};

export default Header;

const MetricSelect = ({ metric, setMetric }) => {
  const options = [
    { value: 'cpa', label: 'Top Performing' },
    { value: 'spend', label: 'Top Spending' },
  ];

  return (
    <div className="top-performers__header d-flex align-items-center">
      <ViewSelector
        options={options}
        currentView={options.find((opt) => opt.value === metric) || options[0]}
        onChange={(selection) => setMetric(selection)}
      />
    </div>
  );
};

const SwiperPagination = () => {};
