import { useCallback } from 'react';
import { convertFormat1ToFormat2 } from '../utils/formatUtils';

/**
 * Custom hook to handle override operations
 */
export const useOverrides = (
  selectedLabel: string | null,
  darwinIqData: any,
  account_id: string,
  saveOverridesApi: any
) => {
  const handleOverride = useCallback(
    ({ label, visual }) => {
      try {
        const category = selectedLabel?.split('->')[0];
        const category_id = darwinIqData?.category_performance_data?.find(
          (c) => c.category_name === category
        )?.['category_id'];

        const category_details_match = darwinIqData?.category_details?.find(
          (x) => x.category_id === category_id
        );

        const selectedCategoryData = category_details_match;

        const prevLabel = convertFormat1ToFormat2(selectedLabel);
        const match = selectedCategoryData?.category_labels?.find((x) => {
          return x.toLowerCase() === prevLabel.toLowerCase();
        });

        const newLabelMatch = selectedCategoryData?.category_labels?.find(
          (x) => {
            return x.toLowerCase() === label.toLowerCase();
          }
        );

        if (!category_id || !label || !visual) return;

        const payload = {
          hash_id: visual,
          category_id,
          account_id,
          assigned_labels: { [newLabelMatch ?? label]: match ?? prevLabel },
        };

        saveOverridesApi.request(payload);
      } catch (e) {
        console.error('Could not perform override');
        console.error(e);
      }
    },
    [selectedLabel, darwinIqData, account_id, saveOverridesApi]
  );

  return {
    handleOverride,
  };
};
