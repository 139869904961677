import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Button from '../../../components/Button';
import Tooltip from '../../../components/Tooltip';

const DraftCreativeHeader = ({
  projectOptions,
  selectedProject,
  handleSelectProject,
  setShowCreateModal,
  handleDeleteProject,
  deleteDraftProjectLoading,
  handleSaveProject,
  saveDraftProjectLoading,
  openShareModal,
  getDraftProjectsLoading,
  getDraftProjectsError,
  message,
}) => {
  return (
    <>
      <div className="draft-creative__header">
        <div className="d-flex align-items-center">
          <div className="projects-dropdown">
            Draft projects
            <Select
              options={projectOptions}
              onChange={(e) => handleSelectProject(e.value)}
              value={
                selectedProject
                  ? projectOptions.find(
                      (opt) =>
                        opt.value === selectedProject.draft_creative_project_id
                    )
                  : null
              }
              disabled={getDraftProjectsLoading}
              placeholder={getDraftProjectsLoading ? 'Loading...' : 'Select...'}
            />
          </div>
          <div className="create-new" onClick={() => setShowCreateModal(true)}>
            <i className="fa-solid fa-circle-plus mr-2 pt-4" />
            Create new draft project
          </div>
        </div>
        <div className="d-flex align-items-center">
          {message && (
            <div className="mr-2">
              <i
                className={`mr-2 fa-regular fa-circle-${
                  message.includes('successfully') ? 'check' : 'xmark'
                }`}
              />
              {message}
            </div>
          )}
          <Button
            className="delete"
            onClick={handleDeleteProject}
            appearance="subtle"
            disabled={!selectedProject}
            loading={deleteDraftProjectLoading}
          >
            Delete
          </Button>
          <Button
            onClick={handleSaveProject}
            loading={saveDraftProjectLoading}
            disabled={!selectedProject}
          >
            Save
          </Button>
          <Tooltip content="Share report">
            <button
              className="share-button"
              onClick={() => openShareModal()}
              disabled={!selectedProject}
            >
              <i className="fa-solid fa-share"></i>
            </button>
          </Tooltip>
        </div>
      </div>
      {getDraftProjectsError && (
        <div style={{ position: 'absolute', top: '100px' }}>
          Error fetching projects
        </div>
      )}
    </>
  );
};

export default DraftCreativeHeader;
