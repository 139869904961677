
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// @ts-ignore
import { Switch, useRouteMatch } from 'react-router-dom';

import PrivateRoute from '../../components/PrivateRoute';

import Header from './cube-source/components/Header';
import CubeProvider from './cube-source/CubeProvider';
import ExplorePage from './cube-source/pages/ExplorePage';
import DashboardPage from './cube-source/pages/DashboardPage';
import DiagnosticPage from './cube-source/pages/DiagnosticPage';
import ETLPlaceholder, { withinETL } from './EtlPlaceholder';

import { VIEW_SETTINGS, VIEW_AUDIENCE } from '../../store/ViewSettings/actions';

import { ErrorBoundary } from '../../components/Helpers/Error';
import { BasicLoading } from '../../components/Helpers/Loading';
import { trackEvent } from '../../store/UserActivity/actions';
import { LOADING, CUSTOM_REPORTS } from '../../store/UserActivity/constants';

const formatAccountIdForAnalytics = (accountId) => accountId.replace('act_', '');

const CubeRoutesConditonal = () => {
  const dispatch = useDispatch();
  const now = new Date();
  const etlProcessing = withinETL(now);

  useEffect(() => {
    dispatch(trackEvent(LOADING, CUSTOM_REPORTS));
  }, []);

  if (etlProcessing) {
    return <ETLPlaceholder now={now} />;
  }
  return <CubeRoutes />;
};

export default CubeRoutesConditonal;

function useViewData() {
  const {
    // @ts-ignore
    params: { viewId, cubeRoute },
  } = useRouteMatch('/:viewId/:cubeRoute');

  const data = useSelector(
    ({
      ViewSettings: {
        token,
        campaigns,
        action_name,
        account_id,
        snapchatAccountId,
        viewName,
      },
      Loaders,
    }) => ({
      loading: Loaders[VIEW_SETTINGS] || Loaders[VIEW_AUDIENCE],
      token,
      campaigns,
      actionName: action_name,
      accountId: account_id,
      snapchatAccountId,
      viewName,
    }),
  );

  return { ...data, viewId, cubeRoute };
}

const CubeRoutes = () => {
  const {
    loading,
    campaigns,
    token,
    actionName,
    accountId,
    snapchatAccountId,
    viewName,
    viewId,
    cubeRoute,
  } = useViewData();

  const showHeader = cubeRoute === 'create-new-report' || cubeRoute === 'dashboard';

  return (
    <ErrorBoundary message="An error occurred in the dasboard.">
      {loading ? (
        <BasicLoading />
      ) : (
        <CubeProvider
          viewId={viewId}
          campaigns={campaigns}
          token={token}
          actionName={actionName}
          accountId={formatAccountIdForAnalytics(accountId)}
          snapchatAccountId={snapchatAccountId}
        >
          {showHeader && (
            <Layout>
              <Header currentRoute={cubeRoute} />
            </Layout>
          )}
          <Layout>
            <Switch>
              <PrivateRoute exact key="index" path="/:viewId/dashboard">
                <DashboardPage />
              </PrivateRoute>
              <PrivateRoute
                exact
                key="create-new-report"
                path="/:viewId/create-new-report"
              >
                <ExplorePage />
              </PrivateRoute>
              <PrivateRoute
                exact
                key="diagnostics-ad"
                path="/:viewId/diagnostics"
              >
                <DiagnosticPage viewName={viewName} />
              </PrivateRoute>
            </Switch>
          </Layout>
        </CubeProvider>
      )}
    </ErrorBoundary>
  );
};

const Layout = ({ children }) => (
  <div className="row">
    <div className="col-md-12">{children}</div>
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
