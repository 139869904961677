import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setShowQaEditor } from '../../../../../store/CreativeAnalytics/creativeAnalyticsSlice';

export const useSetShowQaEditor = () => {
  const dispatch = useDispatch();

  return (shouldShow: boolean) => dispatch(setShowQaEditor(shouldShow));
};

export const useUrlFeature = () => {
  const location = useLocation();
  const [, viewId, feature, page] = location.pathname.split('/');

  return { feature, page };
};
