const LOGGER_URL = '/api/client-log';
const send =
  process.env.NODE_ENV === 'production'
    ? function send0(level, body, meta) {
        const currentLocation = window.location.toString();

        return fetch(LOGGER_URL, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            body,
            level,
            location: currentLocation,
            meta,
          }),
        }).catch(() => null);
      }
    : function send1(level, body, meta) {
        // eslint-disable-next-line no-console
        return console[level](body, meta);
      };

const ERR_KEYS = ['message', 'name', 'fileName', 'stack'];

function parseError(e) {
  if (e instanceof Error) {
    return ERR_KEYS.reduce((acc, k) => {
      if (k === 'stack') {
        acc[k] = `${e[k].slice(0, 200)}...`;
      } else {
        acc[k] = e[k];
      }

      return acc;
    }, {});
  }
  if (typeof e === 'object' && e !== null) {
    return JSON.stringify(e);
  }
  return e;
}

/**
 * @typedef {string | number | Object<string, string | number>} LoggerMessage
 * @typedef {string | number | Object<string, string | number>} LoggerMeta
 * @typedef {Object} Logger
 * @property {(body: LoggerMessage, meta?: LoggerMeta) => Promise<any>} info
 * @property {(body: LoggerMessage, meta?: LoggerMeta) => Promise<any>} log
 * @property {(body: LoggerMessage, meta?: LoggerMeta) => Promise<any>} warn
 * @property {(body: LoggerMessage, meta?: LoggerMeta) => Promise<any>} warning
 * @property {(body: LoggerMessage, meta?: LoggerMeta) => Promise<any>} error
 *
 * @return {Logger}
 */
function Logger() {
  return {
    info(body, meta = undefined) {
      return send('info', body, meta);
    },
    log(body, meta = undefined) {
      return this.info(body, meta);
    },
    warn(body, meta = undefined) {
      return send('warn', parseError(body), meta);
    },
    warning(body, meta = undefined) {
      return this.warn(body, meta);
    },
    error(body, meta) {
      return send('error', parseError(body), meta);
    },
    critical(body, meta) {
      return send('critical', parseError(body), meta);
    },
  };
}

window.logger = Logger();

window.onerror = function winOnErr(e, src, lineno, colno, error) {
  return send('error', parseError(error), {
    event: e,
    caller: 'window.onerror',
  });
};
