import React from 'react';
import Tooltip from '../../../components/Tooltip';

const HurdleHeader = ({ copied, handleShareReport, allVisualsList }) => {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <h2>Hurdle Report</h2>
      <div className="d-flex align-items-center pr-4">
        {copied && <div className="copied">Link copied to clipboard</div>}
        <Tooltip content="Share report" disabled={copied}>
          <button
            onClick={() => handleShareReport()}
            disabled={!allVisualsList.length}
            className="share-button"
          >
            <i className="fa-solid fa-share"></i>
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default HurdleHeader;
