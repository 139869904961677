import { useMemo } from 'react';
import { useSearchParams } from '../../../components/Hooks/search-params';
import useTrackDeps from '../../../hooks/useTrackDeps';

export type AnalyticsParamsType = {
  selectedView: string;
  segments: string[];
  selectedRowId: string;
  mode: 'creative' | 'data';
  categories: string[];
  columns: string[];
  summary_columns: string[];
  dataset: 'spend_based' | 'rank_based';
  chosen_label?: string;
  comparison_label?: string;
  selected_label?: string;
};
const ANALYTICS_PARAMS = [
  'selectedView',
  'segments',
  'selectedRowId',
  'mode',
  'categories',
  'columns',
  'summary_columns',
  'dataset',
  'chosen_label',
  'comparison_label',
  'selected_label',
];

export const useAnalyticsUrlProps = () => {
  const { getParams, replaceParams } = useSearchParams();

  const params = getParams(...ANALYTICS_PARAMS);

  const {
    selectedView,
    segments,
    selectedRowId,
    mode,
    categories,
    summary_columns,
    columns,
    dataset,
    chosen_label,
    comparison_label,
    selected_label
  } = params;

  const result: AnalyticsParamsType = useMemo(() => {
    return {
      selectedView: selectedView || null,
      segments: segments ? decodeURIComponent(segments).split(',') : [],
      selectedRowId: selectedRowId || null,
      mode: mode || 'creative',
      categories: categories
        ? decodeURIComponent(categories).split(',')
        : [],
      columns: columns ? decodeURIComponent(columns).split(',') : [],
      summary_columns: summary_columns
        ? decodeURIComponent(summary_columns).split(',')
        : [],
      dataset: dataset || 'spend_based',
      chosen_label: chosen_label || null,
      comparison_label: comparison_label || null,
      selected_label: selected_label || null
    };
  }, [
    selectedView,
    segments,
    selectedRowId,
    mode,
    categories,
    summary_columns,
    columns,
    dataset,
    chosen_label,
    comparison_label,
    selected_label
  ]);

  return result;
};
