import { connect } from "react-redux";
import { RUNNING_ADS_BY_NAME } from "../../store/RunningAdsByName/actions";

const stateToProps = (
  { RunningAdsByName: { ads }, Loaders },
  { adName, selectedType }
) => {
  if (Loaders[RUNNING_ADS_BY_NAME]) {
    return {};
  } else {
    const ad = ads[adName];

    return ad ? derive(ad, selectedType) : {};
  }
};

export default connect(stateToProps, null);

const derive = (
  {
    ad_name,
    spend,
    spend_without,
    prospecting_spend,
    retargeting_spend,
    audience_splits,
    testing_information,
    zones
  },
  selectedType
) => ({
  ad_name,
  spend,
  spend_without,
  prospecting_spend,
  retargeting_spend,
  audience_splits,
  testing_information,
  selection: getZoneByType(selectedType, zones)
});

const getZoneByType = (type, zones) => {
  switch (type) {
    case "":
      return zones;

    case "cpa":
      return zones[0];

    case "cpm":
      return zones[1];

    case "ctr":
      return zones[0].nested_zones[0];

    case "share_of_voice":
      return zones[0].nested_zones[1];

    default:
      throw new Error(`Invalid zone type - ${type}`);
  }
};
