import React from 'react';
import PropTypes from 'prop-types';

import { ADSET_NAME } from '../../store/CreativeReports/constants';
import { LayoutContainer } from './Helpers';
import { Loading } from '../../components/Helpers/Loading';

const ReportStatus = {
  get({ reportsApi, adsetsApi, report_type }) {
    if (report_type === ADSET_NAME) {
      if (adsetsApi.error) {
        return { status: 'ERROR', message: JSON.stringify(adsetsApi.error) };
      } if (adsetsApi.loading) {
        return { status: 'LOADING' };
      }
      return { status: 'DONE' };
    }
    if (reportsApi.error) {
      return { status: 'ERROR', message: JSON.stringify(reportsApi.error) };
    } if (reportsApi.loading) {
      return { status: 'LOADING' };
    }
    return { status: 'DONE' };
  },

  Error({ error }) {
    return (
      <PlaceholderContainer>
        <div>
          <h3>Error retrieving adset data</h3>
          <p>{JSON.stringify(error)}</p>
        </div>
      </PlaceholderContainer>
    );
  },

  Loading() {
    return (
      <PlaceholderContainer>
        <Loading />
      </PlaceholderContainer>
    );
  },
};

ReportStatus.Error.defaultProps = {
  error: false,
};

ReportStatus.Error.propTypes = {
  error: PropTypes.objectOf(PropTypes.string),
};

export default ReportStatus;

function PlaceholderContainer({ children }) {
  return (
    <LayoutContainer>
      <div
        className="col-12 p-3 m-0 darwin-box-shadow bg-white"
        style={{ height: '70vh' }}
      >
        {children}
      </div>
    </LayoutContainer>
  );
}

PlaceholderContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
