import React, { useEffect, useState } from 'react';
import './BasicLabelsSetup.scss';
import Overlay from '../../../components/Overlay';
import BasicLabelSetup from './Setup';
import { useCreativeAnalyticsSet } from '../contexts';
import { useLabelsSetup } from './hooks';
import { SET_LABEL_DATA } from './reducer';
import { useSearchTermsApi } from './api';
import { DEFAULT_PROMO_TERMS } from './constants';
import { getInactiveLabels } from './utils';

type LabelsSetupProps = {
  account_id: string;
  existingKeywords: string[];
  data: any;
  loading: boolean;
  error: boolean;
};

const LabelsSetup = ({
  account_id,
  existingKeywords,
  data,
  loading,
  error,
  fetchSearchTerms,
}: LabelsSetupProps) => {
  const { setEditBasicLabels } = useCreativeAnalyticsSet();
  const labelSetup = useLabelsSetup();
  const searchTermsApi = useSearchTermsApi();
  const [recentlyRemoved, setRecentlyRemoved] = useState({
    brands: [],
    promos: [],
    keywords: [],
  });

  useEffect(() => {
    fetchSearchTerms(account_id);
  }, []);

  useEffect(() => {
    if (data?.changes) {
      setRecentlyRemoved({
        brands: data.changes.brand.removed_brand_search_terms || [],
        promos: data.changes.promotion.removed_promotion_search_terms || [],
        keywords: data.changes.keywords.removed_keywords || [],
      });

      labelSetup.dispatchLabelSetup({
        type: SET_LABEL_DATA,
        activeBrands: data.brand_search_terms,
        inactiveBrands: [],
        activePromos: data.promotion_search_terms,
        inactivePromos: getInactiveLabels(
          DEFAULT_PROMO_TERMS,
          data.promotion_search_terms
        ),
        existingKeywords: existingKeywords,
        activeKeywords: _.union(existingKeywords, data.keywords),
        // labels in "Keywords" category and suggested_keywords not included in activeKeywords
        inactiveKeywords: getInactiveLabels(
          data.suggested_keywords,
          _.union(existingKeywords, data.keywords)
        ),
        suggestedKeywords: data.suggested_keywords,
      });
    }
  }, [data, existingKeywords]);

  const handleSaveSearchTerms = (brands, promos, keywords) => {
    const payload = {
      platform_account_id: account_id,
      brand_search_terms: brands,
      promotion_search_terms: promos,
      keywords: keywords,
    };

    searchTermsApi.post.request(payload);
  };

  useEffect(() => {
    if (searchTermsApi.post.data) {
      setEditBasicLabels(false);
    }
  }, [searchTermsApi.post.data]);

  const saving = searchTermsApi.post.loading;

  return (
    <div className="label-setup">
      <BasicLabelSetup
        {...{
          labelSetup,
          loading,
          error,
          setEditBasicLabels,
          handleSaveSearchTerms,
          saving,
          recentlyRemoved,
          setRecentlyRemoved,
        }}
      />
    </div>
  );
};

export default LabelsSetup;
