import React, { useEffect, useMemo, useState } from 'react';
import '../../../../DNA/CreativePerformance/VisualsWrapper.scss';
import { EmptyState } from '../../../../DNA/CreativePerformance/EmptyState';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import Tooltip from '../../../../../components/Tooltip';
import { useOverflowActive } from '../../../../../hooks/useOverflowActive';
import { formatMetric } from '../../../../DNA/CreativePerformance/utils';
import { toCurrency } from '../../../../../utils/numbers';
import Button from '../../../../../components/Button';
import { SortDropdown } from '../../../../DNA/CreativePerformance/SortOptions';
import { TopSpendingIndicator } from '../../../../DNA/CreativePerformance/Creative';
import { DARWIN_NAVY, DARWIN_NAVY_3 } from '../../../../../constants';
import { useUrlParams } from '../../../../CreativeAnalytics/hooks/useUrlParams';
import { useGroupByContext } from '../../../contexts/GroupByContext';
import { VirtuosoGrid } from 'react-virtuoso';

const sortDetailData = (data = [], sortBy, desc) => {
  return [...data].sort((a, b) => {
    const valA = a[sortBy] ?? 0;
    const valB = b[sortBy] ?? 0;

    return desc ? valB - valA : valA - valB;
  });
};

export const CreativePerformance = ({
  data = [],
  columns,
  columnOptions,
  sort,
  setSort,
  visual_id_data = [],
}) => {
  const { reportBuilderGroupBy } = useGroupByContext() ?? {
    reportBuilderGroupBy: 'ad_name',
  };
  const dataset =
    reportBuilderGroupBy === 'visual_id' ? [...visual_id_data] : [...data];

  const sorted = useMemo(() => {
    if (sort?.sortBy) {
      return sortDetailData(dataset, sort.sortBy, sort.desc);
    } else {
      return data;
    }
  }, [sort, dataset, reportBuilderGroupBy]);

  return (
    <div
      className="creative-performance"
      style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <VisualsWrapper
        visuals={sorted}
        columns={columns}
        columnOptions={columnOptions}
        allowOverrides={false}
      />
    </div>
  );
};

export const SortToggle = ({ sort, setSort }) => {
  const [show, setShow] = useState(false);
  const isSorted = !!sort?.sortBy?.length;
  const { primary_metric } = useUrlParams();

  const handleSort = (e, desc) => {
    let metric = e;

    if (e === 'cpa') metric = 'total_cpa';
    if (e === 'spend') metric = 'total_spend';

    setSort({
      sortBy: metric,
      desc,
    });
  };

  const SortText = (
    <p
      style={{
        fontWeight: 500,
        marginBottom: 0,
        marginLeft: '1rem',
      }}
      className="sort-text"
    >
      {isSorted && (
        <i
          className={`fa-solid fa-caret${sort?.desc ? '-down' : '-up'} mr-2`}
        />
      )}
      {!isSorted && (
        <i className="fa-solid fa-minus mr-2" style={{ color: '#616161' }} />
      )}
      {isSorted ? (
        <span>Sorted by {formatMetric(sort?.sortBy)} </span>
      ) : (
        <span style={{ color: '#616161' }}>No sort applied</span>
      )}
      {isSorted && <span>{formatMetric(sort?.sortOrder)}</span>}
    </p>
  );

  return (
    <div className="sort-options">
      <Button appearance="raised" onClick={() => setShow((prev) => !prev)}>
        {SortText}
      </Button>
      <SortDropdown
        show={show}
        setShow={setShow}
        setSort={handleSort}
        sortDesc={false}
        primary_metric={primary_metric}
      />
    </div>
  );
};

type VisualProps = {
  asset_url;
  visual_type;
  _id;
  id;
  thumbnail_url;
};

export const VisualsWrapper = ({
  visuals = [],
  columns = [],
  columnOptions,
  overridesSelect = null,
  allowOverrides,
  zoomOnHover = false,
  onOverrideChange,
  onOverrideSave,
  unsavedOverrides = new Set(),
}) => {
  const { width } = useWindowSize();

  const visibleData = visuals;

  if (visuals.length < 1)
    return <EmptyState text="Select a row above to view detailed stats." />;

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div className="dna-grid-wrapper" style={{ flex: 1, minHeight: 0 }}>
        <VirtuosoGrid
          style={{ height: '100%' }}
          data={visibleData}
          itemContent={(index, visual) => {
            const isUnsavedChange = unsavedOverrides.has(
              visual.darwin_visual_id
            );

            return (
              <div
                className="dna-grid-item"
                style={{
                  position: 'relative',
                  // background: isUnsavedChange ? 'rgb(185 231 255)' : 'initial',
                  padding: isUnsavedChange ? '6px' : 'initial',
                  border: isUnsavedChange ? '3px solid rgb(185, 231, 255)' : 'initial',
                }}
                data-allow-overrides={allowOverrides}
              >
                <Creative
                  visual={visual}
                  columns={columns}
                  rank={visual?.rank}
                  showRankings={!!visual?.rank}
                  columnOptions={columnOptions}
                  overridesSelect={overridesSelect}
                  allowOverrides={allowOverrides}
                  zoomOnHover={zoomOnHover}
                  index={index}
                  onOverrideChange={onOverrideChange}
                  onOverrideSave={onOverrideSave}
                />
              </div>
            );
          }}
          components={{
            Item: 'div',
            List: 'div',
          }}
          listClassName="dna-grid"
        />
      </div>
    </div>
  );
};

export const Creative = ({
  visual,
  columns = [],
  rank,
  showRankings,
  columnOptions,
  overridesSelect,
  allowOverrides,
  zoomOnHover,
  index,
  onOverrideChange,
  onOverrideSave,
}) => {
  return (
    <div className="dna-creative">
      <div className="dna-creative__top-banner">
        {visual.isTopSpending && <TopSpendingIndicator />}
      </div>

      <Visual
        visual={visual}
        rank={rank}
        showRankings={showRankings}
        zoomOnHover={zoomOnHover}
        index={index}
      />
      {/* <Toggle display={display} setDisplay={setDisplay} /> */}
      <div className="dna-creative-details">
        <MetricData
          visual={visual}
          columns={columns}
          formatMetric={formatMetric}
          columnOptions={columnOptions}
        />
      </div>

      {allowOverrides && overridesSelect && (
        <div className="p-3" style={{ background: '#fff' }}>
          {overridesSelect(visual, onOverrideChange, onOverrideSave)}
        </div>
      )}
    </div>
  );
};

const Visual = ({
  visual,
  rank,
  showRankings,
  thumbnails_only,
  zoomOnHover,
  index,
}) => {
  let VisualDisplay = null;
  let EnlargedVisualDisplay = null;

  if (
    !visual.s3_url ||
    !visual.visual_type ||
    visual.visual_type === 'Unknown'
  ) {
    return (
      <div className="img-wrapper p-4" style={{ background: DARWIN_NAVY }}>
        <p className="text-white">
          <i
            className="fa-solid fa-circle-exclamation mr-2"
            style={{ opacity: 0.5 }}
          ></i>
          Deleted Visual or Missing Permissions
        </p>
      </div>
    );
  }

  if (visual.visual_type.toLowerCase() === 'video') {
    VisualDisplay = (
      <video
        key={visual.s3_url}
        autoPlay={false}
        loop
        muted
        plays-inline="true"
        controls
      >
        <source src={visual.s3_url} type="video/mp4" />
      </video>
    );
    EnlargedVisualDisplay = (
      <video
        key={`enlarged-${visual.s3_url}`}
        autoPlay={false}
        loop
        muted
        plays-inline="true"
        controls
        style={{
          width: '50vw',
          maxHeight: '50vh',
          objectFit: 'contain',
          backgroundColor: '#000',
        }}
      >
        <source src={visual.s3_url} type="video/mp4" />
      </video>
    );
  } else {
    VisualDisplay = (
      <img
        className="dna-creative__img"
        src={visual.s3_url}
        alt={visual.ad_name}
      />
    );
    EnlargedVisualDisplay = (
      <a href={visual.s3_url} target="_blank" rel="noopener noreferrer">
        <img
          src={visual.s3_url}
          alt={visual.ad_name}
          style={{
            width: '50vw',
            maxHeight: '50vh',
            objectFit: 'contain',
            backgroundColor: '#000',
          }}
        />
      </a>
    );
  }

  return (
    <div className="img-wrapper">
      <Tooltip
        style={{
          maxWidth: 'none',
        }}
        content={EnlargedVisualDisplay}
        follow={false}
        interactive
        maxWidth="none"
      >
        {VisualDisplay}
      </Tooltip>
      {showRankings && <div className="rank">{rank}</div>}
    </div>
  );
};

const MetricData = (props) => {
  return (
    <>
      <div className="mb-3">
        <Data {...props} />
      </div>
    </>
  );
};

const Data = ({ visual, columns, columnOptions = [] }) => {
  const { reportBuilderGroupBy } = useGroupByContext() ?? {
    reportBuilderGroupBy: 'ad_name',
  };
  const shouldGroupByVisualId =
    reportBuilderGroupBy === 'visual_id' && !!visual.array_of_ad_names;

  const cols = columnOptions?.reduce((acc, { label, value }) => {
    if (columns.includes(value)) {
      acc.push(<DataItem {...{ visual, label, value }} />);
    }

    return acc;
  }, []);

  if (cols.length % 2 !== 0) cols.push(<li></li>);

  return (
    <div className="dna-creative-data dna-creative-data--grid">
      <ul>
        {cols}

        <li className="data-item" key={`${visual._id}_ad-name`}>
          <Tooltip
            content={
              shouldGroupByVisualId ? (
                <ul style={{ listStyle: 'square', padding: '0.5rem' }}>
                  {visual.array_of_ad_names.map((name) => (
                    <li className="mb-3">{name}</li>
                  ))}
                </ul>
              ) : (
                visual.ad_name ?? ''
              )
            }
            interactive
            follow={false}
          >
            <div className="w-100">
              <p className="data-item__label">
                Ad name{shouldGroupByVisualId ? '(s)' : ''}
              </p>
              <p
                style={{
                  maxWidth: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                className="data-item__value"
              >
                {shouldGroupByVisualId
                  ? visual.array_of_ad_names[0]
                  : visual.ad_name}
              </p>
            </div>
          </Tooltip>
        </li>
      </ul>
    </div>
  );
};

const DataItem = ({ visual, label, value }) => {
  const [ref, overflowActive] = useOverflowActive();

  return (
    <li className="data-item" key={`${visual._id}_${value}`}>
      <Tooltip disabled={!overflowActive} content={formatMetric(label)}>
        <p className="data-item__label">{formatMetric(label)}</p>
      </Tooltip>
      <p className="data-item__value">
        {formatCell(value, visual[value], visual)}{' '}
      </p>
    </li>
  );
};

export const formatCell = (column, data, visual) => {
  if (!data) return '-';
  if (['total_spend', 'total_cpa'].includes(column)) return toCurrency(data);
  if (['roas'].includes(column)) return data.toFixed(2);

  if (typeof data === 'number') {
    return data.toFixed(2).toLocaleString();
  }

  return data;
};
