export const fillMissingFrames = (existing, visualsData) => {
  if (!existing?.annotated_visuals && !existing?.predicted_visuals) return {};

  const processVisuals = (visuals, visualsData) => {
    const result = {};

    Object.entries(visualsData).forEach(([key, visual]) => {
      const match = visuals?.[key];

      if (!match) return;

      try {
        const isLabelled = match.asset.some((x) => !!x.labels);
        if (!isLabelled) return;
      } catch (e) {
        console.log(`Wrong asset format: ${key}`);
        return;
      }

      const frames = visual.asset.map((frame) => {
        const matchingFrame = match.asset.find(
          (x) => x.frame_num === frame.frame_num
        );
        return matchingFrame || frame;
      });

      result[key] = {
        type: visual.type,
        spend: visual.spend,
        asset: frames,
      };
    });

    return result;
  };

  return {
    annotated_visuals: processVisuals(existing.annotated_visuals, visualsData),
    predicted_visuals: processVisuals(existing.predicted_visuals, visualsData),
  };
};

const updateLabelsFormat = (labels) => {
  let result = {};
  if (!labels) return {};

  Object.entries(labels).forEach(([label_name, value]) => {
    switch (typeof value) {
      case 'string':
        result[label_name] = {
          status: value,
          confidence: null,
        };
        break;
      case 'object':
        result[label_name] = value;
        break;
      default:
        result[label_name] = {
          status: null,
          confidence: null,
        };
    }
  });

  return result;
};

export const reformatAnnotatedVisuals = (annotated_visuals) => {
  let result = {};

  Object.entries(annotated_visuals).forEach(([visual_hash, obj]) => {
    let update = {};

    try {
      update = {
        ...obj,
        asset: obj.asset.map((frame) => {
          return {
            ...frame,
            labels: updateLabelsFormat(frame?.labels),
          };
        }),
      };
    } catch (e) {
      console.error(e);
    }

    result[visual_hash] = update;
  });

  return result;
};

export const removeConfidenceFromLabels = (asset) => {
  return asset.map((frame) => {
    if (!frame.labels) {
      console.error('No labels found.');
      return frame;
    }

    const updatedLabels = updateFrameLabels(frame.labels);

    return {
      ...frame,
      labels: updatedLabels,
    };
  });
};

export const removeConfidenceFromLabelsME = (asset) => {
  return asset.map((frame) => {
    if (!frame.labels) {
      console.error('No labels found.');
      return frame;
    }

    const updatedLabels = updateFrameLabelsME(frame.labels);

    return {
      ...frame,
      confidence: null,
    };
  });
};

const updateFrameLabels = (labels) => {
  let update = {};

  Object.entries(labels).forEach(([key, value]) => {
    let updatedLabel = {
      ...value,
      confidence: null,
    };

    update[key] = updatedLabel;
  });

  return update;
};

const updateFrameLabelsME = (labels) => {
  let update = {};

  Object.entries(labels).forEach(([key, value]) => {
    let updatedLabel = {
      ...value,
      confidence: null,
    };

    update[key] = updatedLabel;
  });

  return update;
};

export const makeVisualsObject = (allVisualsList = []) => {
  if (!allVisualsList) return {};

  return [...allVisualsList].reduce((acc, visual) => {
    const { id = '', asset = [], spend = 0, visual_type = '' } = visual;

    acc[id] = {
      asset,
      spend,
      type: visual_type,
    };

    return acc;
  }, {});
};

export const getAssetObjWhenLockingInPredictions = (
  currentAnnotatedV,
  predictedVisual
) => {
  try {
    const existing = currentAnnotatedV?.asset;
    const predicted = predictedVisual.asset;

    return predicted.reduce((result, p) => {
      if (existing) {
        for (const e of existing) {
          if (e?.frame_num === p?.frame_num && e?.labels) {
            let updatedLabels = {};
            Object.entries(e.labels).forEach(([key, value]) => {
              updatedLabels[key] = value;
            });

            result.push({
              ...p,
              labels: {
                ...p.labels,
                ...updatedLabels,
              },
            });

            return result;
          }
        }
      }

      result.push(p);
      return result;
    }, []);
  } catch (e) {
    console.error(e);
    return predicted;
  }
};
