export const getDistPValue = (
  labelVisuals,
  allVisuals,
  primary_metric,
  numPermutations = 1000
) => {
  if (!labelVisuals || !allVisuals) {
    console.error('No visuals array found');
    return null;
  }
  let subsetCPAs = labelVisuals.map((item) => item[primary_metric]);
  let allItemsCPAs = allVisuals.map((item) => item[primary_metric]);

  let observedDiff = mean(subsetCPAs) - mean(allItemsCPAs);

  let combined = [...subsetCPAs, ...allItemsCPAs];

  let count = 0;
  for (let i = 0; i < numPermutations; i++) {
    combined = shuffle(combined);

    let newSubset = combined.slice(0, subsetCPAs.length);
    let newAllItems = combined.slice(subsetCPAs.length);

    let newDiff = mean(newSubset) - mean(newAllItems);

    if (newDiff >= observedDiff) {
      count++;
    }
  }

  let pValue = count / numPermutations;

  return pValue;
};

function mean(arr) {
  return arr.reduce((a, b) => a + b, 0) / arr.length;
}

function shuffle(array) {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}
