import { useRef, useEffect } from 'react';
import joi from 'joi';
import { abortableFetch } from '../../../../utils';

import {
  useApiState,
  bindFetchToRef,
} from '../../../../components/Hooks/useApiState';

const HEADERS = { 'content-type': 'application/json' };

function postPredictions(asset) {
  return abortableFetch(
    ['api', 'marketplace', asset.project_id, 'predictions'].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      body: JSON.stringify(asset),
      credentials: 'include',
    }
  );
}

export function usePostPredictions() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (asset) => withRef(postPredictions(asset)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: true,
      count: 0,
    }
  );

  const handlePost = (asset) => {
    postDispatch(asset);
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: handlePost,
    },
  };
}

function fetchReports(viewId, payload) {
  return abortableFetch(['api', viewId, 'creative-reports'].join('/'), {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify(payload),
    credentials: 'include',
  });
}

export function useFetchReports() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (viewId, payload) =>
      withRef(fetchReports(viewId, payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: true,
      count: 0,
    }
  );

  const handlePost = (viewId, payload) => {
    postDispatch(viewId, payload);
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: handlePost,
    },
  };
}

function postCreativeRequests(viewId, payload) {
  let payload2 = payload;
  if (payload.account_id.includes('act_')) {
    payload2 = {
      ...payload,
      account_id: payload.account_id.replace('act_', ''),
    };
  }

  return abortableFetch(
    ['api', 'marketplace', viewId, 'creative-requests'].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      body: JSON.stringify(payload2),
      credentials: 'include',
    }
  );
}

export function usePostCreativeRequests() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (viewId, payload) =>
      withRef(postCreativeRequests(viewId, payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  const handlePost = (viewId, payload) => {
    postDispatch(viewId, payload);
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: handlePost,
    },
  };
}

function postThumbnails(project_id, formData) {
  return abortableFetch(
    ['api', 'marketplace', 'assets', project_id, 'thumbnails'].join('/'),
    {
      method: 'POST',
      body: formData,
      credentials: 'include',
    }
  );
}
export function usePostThumbnails() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (project_id, formData) => withRef(postThumbnails(project_id, formData)),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  const handlePost = (project_id, formData) => {
    postDispatch(project_id, formData);
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: handlePost,
    },
  };
}
