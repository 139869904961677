export function findMedian(arr, metric) {
  const sortedArr = [...arr].sort((a, b) => a - b);
  const midIndex = Math.floor(sortedArr.length / 2);
  let result;

  if (sortedArr.length === 2) {
    return (sortedArr[0] + sortedArr[1]) / 2;
  }

  if (sortedArr.length % 2 === 0) {
    const medianLow = sortedArr[midIndex - 1];
    const medianHigh = sortedArr[midIndex];
    result = (medianLow + medianHigh) / 2;

    if ((medianLow === 0 || medianHigh === 0) && metric === 'cpa') {
      return Infinity;
    }
  } else {
    result = sortedArr[midIndex];
  }

  return result;
}

export const getMedian = (ads, metric, clientMetrics = []) => {
  let values = [];
  const customMetrics = {};
  const shouldAddClientMetric = clientMetrics.length;

  if (shouldAddClientMetric) {
    clientMetrics.forEach((metric) => {
      customMetrics[metric] = [];
    });
  }

  ads.forEach((ad) => {
    if (metric in ad && !!ad[metric]) {
      values.push(ad[metric]);
    } else if (shouldAddClientMetric && clientMetrics.includes(metric)) {
      customMetrics[metric].push(ad[metric] !== undefined ? ad[metric] : 0);
    }
  });

  if (
    shouldAddClientMetric &&
    clientMetrics.includes(metric) &&
    customMetrics[metric].length > 0
  ) {
    values = customMetrics[metric];
  }

  return findMedian(values, metric);
};
