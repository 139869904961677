import React from 'react';
import { toCurrency, toInt } from '../../../utils/numbers';
import { formatMetric } from '../../CreativeAnalytics/ReportCard/utils';
import { useCreativeAnalyticsGet } from '../../CreativeAnalytics/contexts';
import { formatMetricValue } from '../utils';

const Preview = ({ visual, isROAS, primaryMetricLabel }) => {
  if (!visual) return null;
  const { isOrganicView, primary_metric, isEmailView } =
    useCreativeAnalyticsGet();
  const { id, ad_names, asset_url, visual_type } = visual;
  const type = visual_type === 'video' ? 'Video' : 'Image';
  const thumbnail = visual.thumbnail_url || null;
  const metricValue = formatMetricValue(
    primary_metric,
    visual[primary_metric],
    isROAS
  );

  let videoDisplay = (
    <div className="video-display">
      {/* {thumbnail && (
        <div className="element-details__thumbnail">
          <img src={thumbnail} alt="" />
          <p>THUMBNAIL</p>
        </div>
      )} */}

      {asset_url ? (
        <video
          className="element-details__img"
          key={asset_url}
          autoPlay
          loop
          muted
          plays-inline
        >
          <source src={asset_url} type="video/mp4" />
        </video>
      ) : (
        <p className="missing-asset-url">
          <i className="fa-solid fa-triangle-exclamation"></i>
          Missing Facebook Page Access
          <br />
          for this Asset
        </p>
      )}
    </div>
  );

  return (
    <div
      className="element-details"
    >
      <div className="element-details__summary">
        <div className="">
          <div className="d-flex flex-column mb-2">
            <span>
              {isOrganicView
                ? primary_metric
                : isEmailView
                ? 'Total Opens'
                : 'Spend'}
              :
            </span>{' '}
            {isOrganicView
              ? toInt(visual[primary_metric])
              : isEmailView
              ? toInt(visual['Total Opens'])
              : toCurrency(visual.spend)}
          </div>
          {!isOrganicView && (
            <div className="d-flex flex-column">
              <span>
                {primaryMetricLabel}
              </span>{' '}
              {metricValue}
            </div>
          )}
        </div>
      </div>
      <div className="element-details__example">
        {visual_type === 'image' && (
          <img
            className="element-details__img"
            src={asset_url}
            alt=""
          />
        )}
        {visual_type === 'video' && videoDisplay}
      </div>
    </div>
  );
};

export default Preview;
