import React from 'react';
import CategoryEditor from '../CategoryEditor';
import MetricEditor from '../MetricEditor';
import { CategoriesIcon, DataTypeIcon, DisplayedMetricsIcon } from './icons';
import { Dropdown } from './Dropdown';
import { formatMetric } from '../utils';
import { SET_DATA_TYPE, SET_SELECTED_METRIC } from '../reducer';

export const getPreferencesOptions = ({
  categorySettings,
  dispatchReportCardSettings,
  reportCardSettings,
  isDuff,
  primary_metric,
  isROAS,
  data_type,
  isGoHealth,
}) => {
  return [
    {
      title: 'Manage categories',
      Icon: (
        <span className="prefs-dropdown-icon">
          <CategoriesIcon />
        </span>
      ),
      submenu: (
        <CategoryEditor
          analyticsView={false}
          className="rc-dropdown__submenu category-editor"
          {...{
            categorySettings,
            dispatchReportCardSettings,
            reportCardSettings,
          }}
        />
      ),
    },
    ...(!isDuff
      ? [
          {
            title: 'Set data type',
            Icon: (
              <span className="prefs-dropdown-icon">
                <DataTypeIcon />
              </span>
            ),
            submenu: (
              <Dropdown
                className=""
                options={[
                  {
                    title:
                      isGoHealth && primary_metric.includes('OppAttempted')
                        ? 'Cost per Opp Attempted'
                        : formatMetric(primary_metric, isROAS),
                    action: {
                      type: SET_DATA_TYPE,
                      data_type: 'aggregate',
                    },
                    selected: data_type === 'aggregate',
                    primary_metric,
                  },
                  {
                    title:
                      isGoHealth && primary_metric.includes('OppAttempted')
                        ? 'Median cost per Opp Attempted'
                        : `Median ${formatMetric(primary_metric, isROAS)}`,
                    action: {
                      type: SET_DATA_TYPE,
                      data_type: 'median',
                    },
                    selected: data_type === 'median',
                    primary_metric,
                  },
                ]}
              />
            ),
          },
        ]
      : []),
    {
      title: 'Edit displayed metrics',
      Icon: (
        <span className="prefs-dropdown-icon">
          <DisplayedMetricsIcon />
        </span>
      ),
      submenu: <MetricEditor />,
    },
  ];
};

export const getPhraseBuilderOptions = (setShowKeywordSettingsModal) => {
  return [
    {
      title: 'Keyword settings',
      Icon: (
        <span className="prefs-dropdown-icon">
          <i className="fa fa-spell-check"></i>
        </span>
      ),
      clickFn: () => {
        setShowKeywordSettingsModal(true);
        console.log('test');
      },
    },
  ];
};
