import React from 'react';
import Tooltip from '../../../components/Tooltip';
import { toCurrency, toFixed_2 } from '../../../utils/numbers';
import { ALL_VISUALS, PHRASES } from '../../CreativeAnalytics/categories';
import { useGetAggregate } from '../../CreativeAnalytics/utils';
import { sortByPerformance } from '../Card';

import './BarChart.scss';

const parse = (value, metric) => {
  if (metric === 'percent_budget') return parseFloat(value) / 100.0;
  return value;
};

const Bar = ({ isBest, value, formattedValue, max, metric, name }) => {
  // const preferLowerValue = metric.includes('cpa');
  const parsed = parse(value, metric);
  const parsedMax = parse(max, metric);

  const proportion = (parsed / parsedMax) * 100;

  return (
    <div className="bar">
      <p className="bar__value">{formattedValue}</p>
      <div
        style={{ width: `${proportion}%` }}
        className={`bar__fill ${isBest ? 'best-performing' : ''}`}
      />
    </div>
  );
};

const CURRENCY = ['aggregate_cpa', 'spend'];
const DECIMAL = ['aggregate_roas'];

export const modifyLabels = (cardData, labels, metric) => {
  const { category } = cardData;

  if (
    category === ALL_VISUALS &&
    (metric.includes('cpa') || metric.includes('roas'))
  ) {
    try {
      const allVisualsLabel = labels.find((x) => x.value === 'all_visuals');
      const newVisualsLabel = labels.find((x) => x.value === 'new_visuals');
      if (!newVisualsLabel) return [allVisualsLabel];

      const allVisuals = allVisualsLabel.visuals;
      const newVisuals = newVisualsLabel.visuals;
      const otherVisuals = allVisuals.filter(
        (visual) => !newVisuals.some((newV) => newV.id === visual.id)
      );

      const otherVisualsLabel = {
        name: 'Others',
        value: 'other_visuals',
        [metric]: useGetAggregate(otherVisuals, 'cpa'),
      };

      return sortByPerformance(metric, [newVisualsLabel, otherVisualsLabel]);
    } catch (e) {
      console.error(e);
    }
  }

  return labels;
};

const BarChart = ({ cardData, metric, labels }) => {
  const modified = modifyLabels(cardData, labels, metric);
  const preferLowerValue = metric.includes('cpa');
  const labelsWithValues = modified.filter((x) => !!x?.[metric]);
  let max = 0;

  try {
    max = labelsWithValues[0][metric];
    if (preferLowerValue)
      max = labelsWithValues[labelsWithValues.length - 1][metric];
  } catch (e) {
    console.error(e);
  }

  return (
    <div className="bar-chart">
      {modified.map((label, i) => {
        const value = label[metric] || null;
        let formattedValue = value;

        if (CURRENCY.includes(metric)) formattedValue = toCurrency(value);
        if (DECIMAL.includes(metric)) formattedValue = toFixed_2(value);
        if (!value) formattedValue = 'N/A';

        return (
          <div
            className="bar-chart__item d-flex align-items-center justify-content-between w-100"
            style={{ zIndex: `${labels.length - i}` }}
          >
            <Tooltip
              className="bar-chart__tooltip"
              content={label.name}
              delay={[0, 0]}
              placement="left"
              follow="false"
            >
              <p
                className={`bar-chart__label insights-card__label mb-0${
                  true ? ' relative-label' : ''
                }`}
              >
                {label.name}{' '}
              </p>
            </Tooltip>

            <Bar
              isBest={i === 0 || value === modified[0][metric]}
              name={label.name}
              {...{ value, formattedValue, max, metric }}
            />
          </div>
        );
      })}
    </div>
  );
};
export default BarChart;
