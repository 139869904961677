import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import usePortal from 'react-cool-portal';
import { useHover } from '../../hooks/useHover';
import { useClickOutside } from '../../hooks/useClickOutside';
import { useCreativeAnalyticsGet } from '../CreativeAnalytics/contexts';
import { useSearchParams } from '../../components/Hooks/search-params';
import Tooltip from '../../components/Tooltip/Tooltip';
import IconButton from '../../components/IconButton';

export const Cell = ({ cell }) => {
  const {
    column: { id: columnId },
    row: { original: rowData },
  } = cell;
  const [cellRef, isHovered] = useHover();
  const { selectedLabels } = useCreativeAnalyticsGet();
  const isVisuals = cell.column.id === 'visuals';
  const isName = cell.column.id === 'name';
  const isOptions = cell.column.id === 'options';
  const { name, value, category, visuals } = rowData;
  let { path, url } = useRouteMatch();
  const history = useHistory();

  const { setParams: setSearchParams, getParams } = useSearchParams();

  let options = [];

  // const handleSelectLabel = () => {
  //   const result = [
  //     ...selectedLabels,
  //     {
  //       label: name,
  //       value,
  //       category,
  //       isUdc: !!rowData.udc,
  //     },
  //   ];
  //   const isAudienceLabel = category === 'Audience';
  //   const udc_labels = result.filter((label) => label.isUdc);

  //   if (isAudienceLabel) {
  //     updateAudience(name);
  //   }

  //   setSearchParams({
  //     udc_labels: udc_labels
  //       .map((label) => {
  //         const l = label.category === 'Audience' ? label.label : label.value;
  //         return `${label.category}->${l}`;
  //       })
  //       .join(','),
  //     labels: result
  //       .map((label) => `${label.category}->${label.label}`)
  //       .join(','),
  //   });
  // };
  const navigateToVisuals = (value) => {
    history.push(`${url}/visuals/${value}`);
  };

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    switch (columnId) {
      case 'name':
        navigateToVisuals(value);
        break;
      default:
        break;
    }
    // const commonLabelsOpen = !!document.getElementById('common-labels-popover');

    // if (
    //   ['view-labels__icon', 'fa-xmark'].some((className) =>
    //     e.target.classList.value.includes(className)
    //   ) ||
    //   commonLabelsOpen
    // ) {
    //   return;
    // }

    // if (e.nativeEvent.button === 2) {
    //   switch (columnId) {
    //     case 'visuals':
    //       break;
    //     default:
    //       // handleSelectElement(value, 'ad_label', isCompare);
    //       break;
    //   }
    // } else if (e.nativeEvent.button === 0) {
    //   switch (columnId) {
    //     case 'options':
    //       handleOptions(e, value);
    //       break;
    //     case 'visuals':
    //       break;
    //     default:
    //       break;
    //   }
    // }
  };

  if (rowData.testing_opportunity && !isName && !isVisuals && !isOptions) {
    return (
      <td
        className={`frequency-table__cell frequency-table__cell--${cell.column.id}`}
        style={{ color: '#516B94' }}
        ref={cellRef}
      >
        -
      </td>
    );
  }

  let Render = (
    <td
      {...cell.getCellProps()}
      className={`frequency-table__cell cursor-pointer frequency-table__cell--${
        cell.column.id
      } ${rowData.placeholder ? 'test-opp-placeholder' : ''}`}
      onClick={handleClick}
      onContextMenu={handleClick}
      // ref={cellRef}
    >
      {cell.render('Cell')}
      {/* {isName && <FilterBtn onClick={handleFilter} />} */}
      {rowData.placeholder && <div class="cell-overlay"></div>}
    </td>
  );

  return (
    <>
      {(cell.column.Header === 'Median ROAS' ||
        cell.column.Header === 'Median CPA') &&
      cell.value === '$0.00' ? (
        <Tooltip
          content={
            'Note, the median is the middle of your dataset. In instances where you are running ads with 0 results, the median of the dataset might be $0.00.'
          }
        >
          <div>{Render}</div>
        </Tooltip>
      ) : (
        Render
      )}
    </>
  );
};

const FilterBtn = ({ onClick }) => {
  return (
    <Tooltip content="Filter by this label">
      <div className="label-filter-btn">
        <IconButton onClick={onClick} style={{ height: '20px', width: '20px' }}>
          +
        </IconButton>
      </div>
    </Tooltip>
  );
};
