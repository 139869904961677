import React, { useMemo } from 'react';
import { LeftMenu }  from '../../../../../components/LeftMenu/Menu';
import TableModeMenu from './Menu.TableMode';

import './Menu.scss';

interface MenuItemData {
  name: string;
  hasSubMenu: boolean;
  icon: JSX.Element;
}

interface MenuProps {
  mode: 'table' | 'visuals';
  onMenuItemClick: (menuItem: string) => void;
  setIsSettingsOpen: () => void;
  loading: boolean;
  isDirectMail: boolean;
  isNonPaidMediaType: boolean;
}

const Menu: React.FC<MenuProps> = ({ mode, onMenuItemClick, setIsSettingsOpen, loading, isDirectMail, isNonPaidMediaType }) => {
  let Render = null;

  switch (mode) {
    case 'table':
      Render = <TableModeMenu setIsSettingsOpen={setIsSettingsOpen} {...{ loading, isDirectMail, isNonPaidMediaType }} />;
      break;
    case 'visuals':
      break;
    default:
      break;
  }

  return <LeftMenu>{Render}</LeftMenu>;
};

export default Menu;
