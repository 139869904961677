import React from "react";

export const CaretDownIcon = () => {
  return (
    <svg
      className="caret-down-icon"
      width="13"
      height="7"
      viewBox="0 0 13 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9595 0.994633L6.33448 6.61963C6.25538 6.69864 6.14815 6.74301 6.03635 6.74301C5.92455 6.74301 5.81733 6.69864 5.73823 6.61963L0.113227 0.994633C0.0387065 0.91466 -0.00186265 0.808884 6.57266e-05 0.699589C0.0019941 0.590295 0.0462695 0.486016 0.123564 0.408721C0.200859 0.331426 0.305138 0.28715 0.414433 0.285222C0.523727 0.283294 0.629503 0.323863 0.709477 0.398383L6.03635 5.72455L11.3632 0.398383C11.4432 0.323863 11.549 0.283294 11.6583 0.285222C11.7676 0.28715 11.8718 0.331426 11.9491 0.408721C12.0264 0.486016 12.0707 0.590295 12.0726 0.699589C12.0746 0.808884 12.034 0.91466 11.9595 0.994633Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};
