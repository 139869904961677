export const TABLES = [
  {
    heading: 'How is my overall account performance?',
    subheading: 'Key overall account performance metrics from the last 6 weeks',
    columns: [],
    rows: [
      {
        name: 'CPA',
        value: 'averageCPA',
        format: 'currency',
        data: [],
      },
      {
        name: 'Spend',
        value: 'spend',
        format: 'currency',
        data: [],
      },
      {
        name: 'Results',
        value: 'results',
        format: 'integer',
        data: [],
      },
    ],
  },
  {
    heading: 'What is our creative velocity?',
    subheading:
      'Are we testing enough new creative to meaningfully combat CPMs and find creative breakthroughs? ',
    columns: [],
    rows: [
      {
        name: '# New Creatives',
        value: 'newVisualsCount',
        format: 'integer',
        data: [],
      },
      {
        name: '# Winning Creatives',
        value: 'numberOfNewCreativeTestsThatWereWinners',
        format: 'integer',
        data: [],
      },
      {
        name: '# Losing Creatives',
        value: 'numberOfNewCreativeTestsThatWereLosers',
        format: 'integer',
        data: [],
      },
      {
        name: 'Spend on Creative Tests',
        value: 'newVisualsSpend',
        format: 'currency',
        data: [],
      },
    ],
  },
  {
    heading: 'What is the impact of my active creative on performance?',
    subheading:
      "What impact does all of the creative I'm currently running have on overall account performance?",
    columns: [],
    rows: [
      {
        name: 'Spend on Below Avg Creative',
        value: 'spendOnWorseThanAverageCPAVisuals',
        format: 'currency',
        data: [],
      },
      {
        name: '% Spend on Below Avg Creative',
        value: 'percentSpendOnWorseThanAverageVisuals',
        format: 'percentage',
        data: [],
      },
      {
        name: '% Spend on Above Avg Creative',
        value: 'percentSpendOnBetterThanAverageVisuals',
        format: 'percentage',
        data: [],
      },
    ],
  },
  {
    heading: 'Are we testing creative strategically and scaling new creative?',
    subheading:
      'Are we finding creative winners and scaling them to take advantage of those winners? ',
    columns: [],
    rows: [
      {
        name: 'CPA on New Creatives ',
        value: 'allNewVisualCPA',
        format: 'currency',
        data: [],
      },
      {
        name: 'Spend on New Creatives',
        value: 'allNewVisualSpend',
        format: 'currency',
        data: [],
      },
      {
        name: 'New Creative Share of Voice',
        value: 'newCreativeShareOfVoice',
        format: 'percentage',
        data: [],
      },
    ],
  },
  {
    heading: 'Are we getting better at testing creative over time?',
    subheading:
      'How does our creative testing improve as our data set evolves?',
    columns: [],
    rows: [
      {
        name: '% Spend on Successful Creative Tests',
        value: 'percentSpendOnSuccessfulTests',
        format: 'percentage',
        data: [],
      },
      {
        name: '% Spend on Failed Creative Tests',
        value: 'percentSpendOnFailedTests',
        format: 'percentage',
        data: [],
      },
      {
        name: 'Spend on Failed Creative Tests',
        value: 'allNewVisualLoserSpend',
        format: 'currency',
        data: [],
      },
    ],
  },
];
