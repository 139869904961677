export const makeVariationPayload = (visuals, baseId, metric) => {
  const performance_data = visuals.map((v) => {
    return {
      visual_id: v.id ?? v._id,
      spend: v.spend,
      results: v.results,
    };
  });

  return {
    base_visual_id: baseId,
    primary_metric: metric,
    performance_data,
  };
};
