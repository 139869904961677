import { useRef, useEffect } from 'react';
import { abortableFetch } from '../../../utils';

import {
  useApiState,
  bindFetchToRef,
} from '../../../components/Hooks/useApiState';

const HEADERS = { 'content-type': 'application/json' };

function getFrequencyData(payload) {
  return abortableFetch(
    ['api', 'creative-reports', 'performance-frequency', payload.view_id].join(
      '/'
    ),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useGetFrequencyData() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (payload) => withRef(getFrequencyData(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: true,
      count: 0,
    }
  );

  const handlePost = (payload) => {
    getDispatch(payload);
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...getState,
      request: handlePost,
    },
  };
}

function getNewAds(payload) {
  return abortableFetch(
    [
      'api',
      'creative-reports',
      'performance-frequency',
      'new-ads',
      payload.view_id,
      payload.start_date,
      payload.end_date,
      payload.insights_by_asset,
    ].join('/'),
    {
      method: 'GET',
      headers: HEADERS,
      credentials: 'include',
    }
  );
}

export function useGetNewAds() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (payload) => withRef(getNewAds(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: true,
      count: 0,
    }
  );

  const handleGet = (payload) => {
    getDispatch(payload);
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    get: {
      ...getState,
      request: handleGet,
    },
  };
}

function getCommonElements(payload) {
  return abortableFetch(
    ['api', 'creative-reports', 'performance-frequency', 'get-common-elements'].join(
      '/'
    ),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useGetCommonElements() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (payload: string[]) => withRef(getCommonElements(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    },
  );

  const handlePost = (payload) => {
    getDispatch(payload);
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...getState,
      request: handlePost,
    },
  };
}

function getReportCardRecs(payload) {
  return abortableFetch(
    ['api', 'creative-reports', 'performance-frequency', 'get-report-card-recs'].join(
      '/'
    ),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useGetReportCardRecs() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (payload) => withRef(getReportCardRecs(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    },
  );

  const handlePost = (payload) => {
    getDispatch(payload);
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...getState,
      request: handlePost,
    },
  };
}

function getPhraseThemeData(payload) {
  return abortableFetch(
    ['api', 'creative-reports', 'performance-frequency', 'get-phrase-theme-data'].join(
      '/'
    ),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useGetPhraseThemeData() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (payload) => withRef(getPhraseThemeData(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    },
  );

  const handlePost = (payload) => {
    getDispatch(payload);
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...getState,
      request: handlePost,
    },
  };
}

function organizePhraseThemes(payload) {
  return abortableFetch(
    ['api', 'creative-reports', 'performance-frequency', 'organize-phrase-themes'].join(
      '/'
    ),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useOrganizePhraseThemes() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(organizePhraseThemes(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    },
  );

  const handlePost = (payload) => {
    postDispatch(payload);
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: handlePost,
    },
  };
}