import React from 'react';
import { EditVisualObject } from '../../types';
import TooltipWrapper from './TooltipWrapper';

type MutuallyExclusiveProps = {
  label: string;
  isSelected: boolean;
  onSelect: (label: string, selection: string) => void;
  current: any;
  visual_hash: string;
  annotatedVisuals: any;
};

const MutuallyExclusive = ({
  label,
  onSelect,
  current,
  annotatedVisuals,
  visual_hash,
  selectedFrame,
  predictedVisual,
}: MutuallyExclusiveProps) => {
  const prediction_match =
    predictedVisual?.[selectedFrame.index]?.labels || null;
  const confidence = predictedVisual?.[selectedFrame.index]?.confidence || null;
  const predictedLabel = (confidence && prediction_match) || null;
  let isPredicted = predictedLabel === label;
  let isSelected = null;

  if (confidence && predictedLabel === label) {
    isSelected = true;
  }

  const annotated = annotatedVisuals[visual_hash];

  if (annotated) {
    if (annotated.asset?.[selectedFrame?.index]) {
      isSelected = annotated.asset[selectedFrame.index].labels === label;
    } else if (annotated.labels) {
      isSelected = annotated.labels === label;
    }
  }

  return (
    <TooltipWrapper {...{ isSelected, isPredicted, confidence }}>
      <li
        className={`visuals-list__single-label ${
          isSelected ? 'visuals-list__single-label--selected' : ''
        }
          ${
            isSelected && isPredicted
              ? 'visuals-list__single-label--predicted'
              : ''
          }
        `}
        onClick={() => onSelect(label)}
      >
        <div className="visuals-list__circle"></div>
        <span title={label}>{label}</span>
      </li>
    </TooltipWrapper>
  );
};

export default MutuallyExclusive;
