import { AD_NAME } from './CreativeReports/constants';
import { START_30, END, darwinDateToDate } from '../utils/darwin-dates';
import { isRootRoute } from '../root-routes';
const setVisType = (params) => {
  const type = params.get('type');

  return type === 'video' || type === 'image' ? type : '';
};

/**
 * @description Assumes first item in the path is
 * the view id.
 */
export const getViewId = () => {
  const [, _id] = window.location.pathname.split('/');

  if (!_id) {
    return '';
  }
  return isRootRoute(_id) ? '' : _id;
};

/**
 * @description Retrieves the current state of URLSearchParams with
 * the appropriate defaults set.
 */
export const getAppSearchParams = (isDirectMail, selectedPeriod) => {
  const params = new URLSearchParams(window.location.search);
  const defaultStart = isDirectMail ? new Date('January 1, 2010') : START_30;
  // check if period 1 / period 2 exists
  let start_date = params.has('start')
    ? darwinDateToDate(params.get('start'))
    : defaultStart;

  let end_date = params.has('end') ? darwinDateToDate(params.get('end')) : END;

  console.log(params)

  if (selectedPeriod) {
    if (selectedPeriod === 'two') {
      start_date = darwinDateToDate(params.get('period2').split(',')[0]);
      end_date = darwinDateToDate(params.get('period2').split(',')[1]);
    } else {
      start_date = params.has('period1') ? darwinDateToDate(params.get('period1').split(',')[0]) : defaultStart;
      end_date = params.has('period1') ? darwinDateToDate(params.get('period1').split(',')[1]) : END;
    }
  }

  console.log(start_date, end_date)

  return {
    report_type: params.get('report') || params.get('display') || AD_NAME,
    start_date,
    end_date,
    selectedAudience: params.get('audience') || '',
    spendThreshold: params.has('spendThreshold')
      ? parseInt(params.get('spendThreshold'), 10)
      : 1,
    creativeCount: params.has('creativeCount')
      ? parseInt(params.get('creativeCount'), 10)
      : 1,
    // Converted to an Object for quick lookup elsewhere in the app.
    exclude: params.has('exclude')
      ? params
          .get('exclude')
          .split(',')
          .reduce((acc, name) => {
            acc[name.toUpperCase()] = 1;
            return acc;
          }, {})
      : null,
    visualType: setVisType(params),
    view_type: params.get('ad_label_type') || 'all_labels',
    insights_view: params.get('insights_view') || 'performance_frequency',
    show_categories: params.get('show_categories') || 'true',
  };
};

export const fetchFromApi = async ({ uri, body, method = 'POST' }) => {
  const res = await window.fetch(uri, {
    mode: 'cors',
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const json = await res.json();

  return json;
};

// courtesy of the MDN
function storageAvailable(type) {
  let storage;
  try {
    storage = window[type];

    const x = '__storage_test__';

    storage.setItem(x, x);
    storage.removeItem(x);

    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

export const LocalStorage = (() => {
  if (!storageAvailable('localStorage')) {
    return null;
  }
  const store = window.localStorage;
  const baseKey = 'darwinsoftware';

  const self = {
    /**
     * @description Updates localstorage at the given
     * key:value pair. If value is "undefined", it will
     * remove the given key from localStorage.
     *
     * @param {String} key
     * @param {Object?} value
     */
    setItem(key, value) {
      const k = `${baseKey}:${key}`;

      if (value === undefined) {
        store.removeItem(k);
      } else {
        store.setItem(k, value);
      }

      return self;
    },
    getItem(key) {
      return store.getItem(`${baseKey}:${key}`);
    },
    remove(key) {
      store.removeItem(`${baseKey}:${key}`);

      return this;
    },
    removeAll() {
      store.clear();

      return self;
    },
  };

  return self;
})();
