import { getColumnDropdownOptions } from './Columns';
import { CUSTOM_SCORING_METRICS } from './constants';

export const getHiddenColumns = (
  columns,
  isROAS,
  isDirectMail,
  clientMetrics,
  gaEnabled,
  customEvents,
  additional_metrics,
  customIntegratedMetrics,
  isOrganicView,
  account_id,
  isEmailView,
  customScoringData
) =>
  getColumnDropdownOptions({
    isROAS,
    isDirectMail,
    clientMetrics,
    gaEnabled,
    customEvents,
    additional_metrics,
    customIntegratedMetrics,
    isOrganicView,
    account_id,
    isEmailView,
    customScoringData
  }).reduce((acc, { value }) => {
    if (!columns.some((col) => col.toLowerCase() === value.toLowerCase())) {
      acc.push(value);
    }

    return acc;
  }, []);

export const getMetricsWithData = (filteredData, options) => {
  return options.filter(({ value }) =>
    filteredData.some(
      (data) =>
        data[value] > 0 ||
        ['visuals', 'planning', 'percent_budget'].includes(value) ||
        CUSTOM_SCORING_METRICS.includes(value)
    )
  );
};
