import { useState, useEffect } from 'react';
import { useStandardLabelsApi } from '../api';
import { EXECUTION_STYLES, INSPIRATION, TRENDING, STANDARD_CATEGORIES_LOOKUP } from '../categories';

export default function useStandardLabels(account_id) {
  const standardLabelsApi = useStandardLabelsApi();
  const [standardLabels, setStandardLabels] = useState([]);
  const [trending, setTrending] = useState([]);
  const [executionStyles, setExecutionStyles] = useState([]);
  const [inspiration, setInspiration] = useState([]);
  const [categoryLabelOptions, setCategoryLabelOptions] = useState({});

  useEffect(() => {
    standardLabelsApi.get.request(account_id);
  }, []);

  useEffect(() => {
    if (standardLabelsApi.get.data) {
      const apiData = standardLabelsApi.get.data;
      const trendingLabels = [];
      const executionStylesLabels = [];
      const inspirationLabels = [];

      setStandardLabels(apiData);

      apiData.forEach((label) => {
        switch (label.category) {
          case TRENDING:
            trendingLabels.push(label);
            break;
          case EXECUTION_STYLES:
            executionStylesLabels.push(label);
            break;
          case INSPIRATION:
            inspirationLabels.push(label);
            break;
          default:
            break;
        }
      });

      setTrending(trendingLabels);
      setExecutionStyles(executionStylesLabels);
      setInspiration(inspirationLabels);
    }
  }, [standardLabelsApi.get.data]);

  useEffect(() => {
    if (standardLabels) {
      const result = {};

      standardLabels.forEach((label) => {
        const category = STANDARD_CATEGORIES_LOOKUP[label.category];
        if (result[category]) {
          result[category] = [...result[category], label];
        } else {
          result[category] = [label];
        }
      });

      setCategoryLabelOptions(result);
    }
  }, [standardLabels])

  return [standardLabels, trending, executionStyles, inspiration, categoryLabelOptions];
}
