import { SET_LOADER } from './actions';

const STATE = {};

const reducer = (state = STATE, action) => {
  switch (action.type) {
    case SET_LOADER:
      const {
        isLoading,
        meta: { entity },
      } = action;

      return { ...state, [entity]: isLoading };

    default:
      return state;
  }
};

export default reducer;
