/* eslint-disable no-nested-ternary */

import React, { useEffect, useState, useCallback, useRef } from 'react';
import '../PerformanceFrequency.scss';
import { useCreativeAnalyticsGet } from '../../contexts';
import useCategories from '../../hooks/useCategories';
import { Table } from './Table';
import { getCategoryOverview } from '../../Explorer/utils/getCategoryOverview';
import { ReportHeader } from './ReportHeader';
import { ErrorBoundary } from '../../../../components/Helpers/Error';
import { STANDARD_CATEGORIES_LOOKUP } from '../../categories';
import LazyLoader from '../../../../components/LazyLoader';
import { useSearchParams } from '../../../../components/Hooks/search-params';
import { useInView } from 'react-intersection-observer';

const LazyLoadWrapper = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing after it's visible
        }
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, []);

  return <div ref={ref}>{isVisible ? children : null}</div>;
};

export default LazyLoadWrapper;

export const Report = ({
  category_name,
  categoryData,
  categoryLabels,
  columns,
  isCompare = false,
  period2,
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const desc = getCategoryOverview({ category_name });
  const { ccc_desc } = categoryData;

  if (categoryLabels?.length < 1) return null;

  return (
    <div
      ref={ref}
      className="report"
      style={{ overflowX: `${period2 ? 'scroll' : 'auto'}` }}
    >
      {inView ? (
        <ErrorBoundary>
          <ReportHeader
            category_name={category_name}
            categoryData={categoryData}
            desc={desc || ccc_desc}
          />
          <Table
            category_name={category_name}
            categoryData={categoryData}
            categoryLabels={categoryLabels}
            isCompare={isCompare}
            columns={columns}
          />
        </ErrorBoundary>
      ) : null}
    </div>
  );
};

export const TablesWrapper = ({ columns, isCompare = false }) => {
  const {
    categoriesLookup,
    category: selectedCategory,
    period2,
  } = useCreativeAnalyticsGet();
  const { getParams } = useSearchParams();
  const { group } = getParams('group');
  const { tableData } = useCategories(null, isCompare);
  const selectedCategoryData = !!selectedCategory
    ? tableData.find(
        (c) =>
          (STANDARD_CATEGORIES_LOOKUP[c.category_name] || c.category_name) ===
          selectedCategory
      )
    : null;
  const dataToDisplay =
    !!selectedCategoryData && selectedCategory !== 'All Visuals'
      ? [selectedCategoryData]
      : tableData.filter((category) => {
          if (category.category_name === 'Phrase Theme') return false;
          if (
            group === 'yes' &&
            category.category_name === 'Primary Dimensions'
          ) {
            return false;
          }

          return true;
        });

  if (!categoriesLookup) return null;

  if (!tableData.some(({ labels }) => labels.length > 0)) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center mx-auto w-100 mt-5">
        <div className="no-data-face">
          <span></span>
          <span></span>
        </div>
        <h5 className="my-0 ml-3 mt-3">
          We found no matching visuals with the filters provided.
        </h5>
      </div>
    );
  }

  return dataToDisplay.map(({ category_name, category_data, labels }) => {
    return (
      <LazyLoader placeholder={<div style={{ height: '40px' }} />}>
        <Report
          category_name={category_name}
          categoryData={category_data}
          categoryLabels={labels}
          {...{ columns, isCompare, period2 }}
        />
      </LazyLoader>
    );
  });
};
