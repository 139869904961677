import { useRef, useEffect } from 'react';
import { abortableFetch } from '../../utils';

import {
  useApiState,
  bindFetchToRef,
} from '../../components/Hooks/useApiState';

const fetchAudienceBreakdown = (payload) => {
  return abortableFetch(
    [
      'api',
      'creative-analytics',
      'audience-breakdown',
      payload.account_id,
    ].join('/'),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(payload),
      credentials: 'include',
    }
  );
};

export const useAudienceBreakdownApi = () => {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) =>
      withRef(fetchAudienceBreakdown(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: postDispatch,
    },
  };
};

const fetchConfig = (payload) => {
  return abortableFetch(
    ['api', 'creative-analytics', 'dashboard', payload.account_id, 'get'].join(
      '/'
    ),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(payload),
      credentials: 'include',
    }
  );
};

const saveConfig = (payload) => {
  return abortableFetch(
    ['api', 'creative-analytics', 'dashboard', 'save'].join(
      '/'
    ),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(payload),
      credentials: 'include',
    }
  );
};

export const useConfigApi = () => {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (payload) => withRef(fetchConfig(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );
  const [saveState, saveDispatch] = useApiState(
    (payload) => withRef(saveConfig(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    get: {
      ...getState,
      request: getDispatch,
    },
    save: {
      ...saveState,
      request: saveDispatch,
    },
  };
};
