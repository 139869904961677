import React, { useMemo } from 'react';
import { CreativePerformance } from './Results/DetailData/CreativePerformance';
import { useTable, useSortBy } from 'react-table';

import '../styles.scss';
import { toCurrency } from '../../../utils/numbers';
import { SummaryTable } from './Results/SummaryTable/SummaryTable';

const deriveData = (data = {}) => {
  return { ...data, tableData: restoreData(data.tableData) };
};
export const StaticLabelPerformance = ({ data }) => {
  const { reportSection } = deriveData(data);
  switch (reportSection) {
    case 'detail_data':
      return (
        <Layout>
          <CreativePerformance
            data={data.data}
            columns={data.columns}
            columnOptions={data.columnOptions}
            sort={data.sort}
          />
        </Layout>
      );
    case 'summary_data':
      return (
        <Layout>
          <StaticSummaryWrapper
            isROAS={data.isROAS}
            tableData={data.tableData}
          />
        </Layout>
      );

    default:
      return <p>There was an error.</p>;
  }
};

const Layout = ({ children }) => {
  return <div className="p-4">{children}</div>;
};

const baseSummaryCols = [
  { Header: 'Ad Name Group', accessor: 'ad_name_group' },
  { Header: 'Label Name', accessor: 'label_name' },
  {
    Header: 'Total Spend',
    accessor: 'total_spend',
    Cell: ({ value }) => toCurrency(value),
  },
];

const roasSummaryCols = [
  {
    Header: 'ROAS',
    accessor: 'total_roas',
    Cell: ({ value }) => value?.toFixed(2) || value || '-',
  },
  {
    Header: 'Total Revenue',
    accessor: 'total_revenue',
    Cell: ({ value }) => toCurrency(value),
  },
];

const cpaSummaryCols = [
  { Header: 'Total Link Clicks', accessor: 'total_link_clicks' },
  { Header: 'Total Results', accessor: 'total_results' },

  {
    Header: 'Total CPA',
    accessor: 'total_cpa',
    Cell: ({ value }) => toCurrency(value),
  },
  {
    Header: 'Total CPC',
    accessor: 'total_cpc',
    Cell: ({ value }) => toCurrency(value),
  },
  {
    Header: 'CVR',
    accessor: 'CVR',
  },
];

const StaticSummaryWrapper = ({ isROAS, tableData }) => {
  const { data } = tableData;

  const defaultColumn = useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 200,
    }),
    []
  );

  const columns = useMemo(() => {
    if (isROAS) {
      return [...baseSummaryCols, ...roasSummaryCols];
    }

    return [...baseSummaryCols, ...cpaSummaryCols];
  }, []);

  const tableInstance = useTable(
    { columns, data: data ?? tableData, defaultColumn },
    useSortBy
  );

  return <SummaryTable tableInstance={tableInstance} />;
};
