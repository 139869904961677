
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import Modal from '../BootstrapModal';

import { useApiPotentialOptions } from './Api';
import {
  FormText,
  Description,
  StartDate,
  FormGroup,
} from './Wizard/FormElements';
import { nextDay } from './date-utils';
import { ACTIVE_BAR_COUNT } from './constants';

const DEFAULT_STATE = {
  study_name: '',
  description: '',
  start_date: '',
  campaigns: [],
};

export function EventModal({
  handleSubmit, hide, visible, study,
}) {
  const options = useApiPotentialOptions('manual_campaigns');
  const [state, setState] = useState(
    study.study_name === '' ? DEFAULT_STATE : study,
  );

  const handleChange = (key, value) => setState((prev) => ({
    ...prev,
    [key]: value,
  }));

  const submit = () => {
    hide();
    handleSubmit({
      ...state,
      campaigns: state.campaigns.map(({ value }) => value),
      end_date: nextDay(state.start_date),
      study_type: 'significant_event',
      y_axis: ACTIVE_BAR_COUNT - 1,
    });
  };

  useEffect(() => {
    if (!visible) {
      setState({ ...DEFAULT_STATE, campaigns: options });
    } else if (visible && study.study_name !== '') {
      const { study_name, description, start_date } = study;

      setState({
        study_name, description, start_date, campaigns: options,
      });
    }
  }, [visible, study]);

  return (
    <Modal
      id="create-event-flow-modal"
      Header={(
        <div className="modal-header">
          <div className="modal-title">
            <h4 className="modal-title-heading">Add Significant Event</h4>
          </div>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={hide}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
      Body={
        visible && (
          <div>
            <FormText
              label="Event Name"
              name="study_name"
              value={state.study_name}
              onChange={handleChange}
              required
            />

            <Description
              value={state.description}
              name="description"
              onChange={handleChange}
            />

            <StartDate
              name="start_date"
              value={state.start_date}
              onChange={handleChange}
            />

            <FormGroup
              label="Campaigns"
              Input={(
                <Select
                  options={options}
                  value={state.campaigns}
                  onChange={(selected, _) => handleChange('campaigns', selected)}
                  isMulti
                />
              )}
            />

            <button
              type="button"
              className="btn btn-success my-2"
              style={{ width: '100%', fontWeight: 700 }}
              onClick={submit}
            >
              Save
            </button>
          </div>
        )
      }
      show={visible}
      handleHide={hide}
      modalClass="modal"
    />
  );
}

EventModal.defaultProps = {
  visible: false,
};

EventModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  hide: PropTypes.func.isRequired,
  study: PropTypes.shape({ study_name: PropTypes.string.isRequired })
    .isRequired,
};
