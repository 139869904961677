import { useState, useEffect, useMemo } from 'react';
import { useSearchParams } from '../../../components/Hooks/search-params';
import { useAccountId, useViewId } from '../selectors';
import { LOCKED_DATES } from '../constants';

export const setLockedDates = (viewId, accountId, param) => {
  try {
    for (const { viewIds, accountIds, dateRange } of LOCKED_DATES) {
      const [start, end] = dateRange;
      if (viewIds.includes(viewId) || accountIds.includes(accountId)) {
        return `${start},${end}`;
      }
    }
  } catch (e) {
    console.error(e);
    return param;
  }

  return param;
};

function setDefaultParams(
  { period1, period2, rc, depth, fixed, show_recs },
  accountId,
  viewId
) {
  const base = {
    period1: setLockedDates(viewId, accountId, period1) || '',
    period2: period2 || '',
    rc: rc || '',
    depth: depth || '1',
    fixed: fixed || [],
    show_recs: show_recs || '',
    refresh: false,
  };

  return base;
}

export default function useAnalyticsParams() {
  const accountId = useAccountId();
  const viewId = useViewId();
  const { getParams, setParams } = useSearchParams();
  const params =
    getParams('period1', 'period2', 'rc', 'show_recs', 'refresh') || {};
  const defaults = setDefaultParams(params, accountId, viewId);

  const [state, setState] = useState(defaults);

  const makeUpdate = (prev, update) => {
    const merged = { ...prev, ...update };
    return merged;
  };

  const setStateMerge = (update) =>
    setState((prev) => makeUpdate(prev, update));

  useEffect(() => {
    setParams(state);
  }, [
    state.period1,
    state.period2,
    state.rc,
    state.depth,
    state.fixed,
    state.show_recs,
    state.refresh,
  ]);

  return [state, setStateMerge];
}
