import { useSelector } from 'react-redux';
import {
  getCategories,
  useSimpleImpactApi,
} from '../Recommendations/SimpleImpact';
import { useCreativeAnalyticsGet } from '../contexts';
import { useEffect, useMemo, useState } from 'react';
import { useCreativeAnalyticsApis } from '../api';
import useCategories from './useCategories';
import { useDNAColumnOptions } from '../../DNA/useDNAColumnOptions';
import { getRows } from '../../DNA/utils';
import { useViewId } from '../selectors';
import {
  makeDnaTableData,
  makeImpactReportData,
  makeSummaryData,
} from '../CreativeCopilot/utils';
import { useCreativePerformanceState } from '../../DNA/context';

export const useCopilotPayload = ({
  indexData,
  isRevision,
  userInstructions,
  previousOutput,
  userFeedback,
  requestType,
  visualExample,
  includeDna,
}) => {
  const { trendData } = useCreativePerformanceState() ?? { trendData: null };
  const { dnaReportData } = useSelector(
    ({ CreativeAnalytics: { dnaReportData } }) => {
      return {
        dnaReportData,
      };
    }
  );
  const {
    filteredAllVisualsLabel,
    period1,
    period2,
    primary_metric,
    isROAS,
    customEvents,
    clientMetrics,
    start_date,
    end_date,
  } = useCreativeAnalyticsGet();
  const view_id = useViewId();

  // impact report data
  const simpleImpactApi = useSimpleImpactApi();
  const {
    request: simpleImpactRequest,
    data: simpleImpactData,
    loading: simpleImpactLoading,
    error: simpleImpactError,
  } = simpleImpactApi;

  const all_visuals_array = useMemo(() => {
    return filteredAllVisualsLabel?.visuals ?? [];
  }, [filteredAllVisualsLabel]);

  const categorical_columns = useMemo(
    () => getCategories(dnaReportData),
    [dnaReportData]
  );

  const [activeCategories, setActiveCategories] = useState(categorical_columns);

  useEffect(() => {
    setActiveCategories(categorical_columns);
  }, [view_id, period1, period2, dnaReportData]);

  const fetchImpactReportData = (data_list) => {
    simpleImpactRequest({
      data_list,
      primary_metric,
      categorical_columns: activeCategories,
    });
  };

  // summary data
  const navCategories = useCategories();

  const dnaColumnOptions = useDNAColumnOptions({
    isROAS,
    customEvents,
    clientMetrics,
  });

  const dnaRows = useMemo(() => {
    return getRows(
      all_visuals_array,
      dnaReportData,
      primary_metric,
      trendData,
      start_date,
      end_date
    );
  }, [all_visuals_array, dnaReportData, primary_metric]);

  useEffect(() => {
    if (dnaRows.length && categorical_columns.length) {
      fetchImpactReportData(dnaRows);
    }
  }, [dnaRows]);

  // dna table data
  const dnaTableData = makeDnaTableData(
    dnaRows,
    dnaColumnOptions.map(({ value }) => value)
  );

  // copilot settings
  useEffect(() => {
    getSettingsRequest(view_id);
  }, []);
  const { copilotApi } = useCreativeAnalyticsApis();
  // const {
  //   data: copilotData,
  //   error: copilotError,
  //   loading: copilotLoading,
  //   request: copilotRequest,
  // } = copilotApi.post;
  const {
    data: getSettingsData,
    error: getSettingsError,
    loading: getSettingsLoading,
    request: getSettingsRequest,
  } = copilotApi.get;

  const [clientPreferences, setClientPreferences] = useState('');
  const [advertiserDetails, setAdvertiserDetails] = useState('');

  useEffect(() => {
    if (getSettingsData) {
      setClientPreferences(getSettingsData[0].client_preferences);
      setAdvertiserDetails(getSettingsData[0].advertiser_details);
    }
  }, [getSettingsData]);

  let payload = {
    PRIMARY_METRIC: primary_metric,
    ADVERTISER_DETAILS: advertiserDetails || 'None',
    CLIENT_PREFERENCES: clientPreferences || 'None',
    DNA_TABLE: includeDna ? dnaTableData : [],
    IMPACT_REPORT: simpleImpactData.length
      ? makeImpactReportData(simpleImpactData)
      : {},
    SUMMARY_DATA: makeSummaryData(navCategories.navCategories, isROAS),
    INDEX_DATA: indexData,
    IS_REVISION: isRevision,
    USER_INSTRUCTIONS: userInstructions || '',
    PREVIOUS_OUTPUT: previousOutput || 'None',
    USER_FEEDBACK: userFeedback || 'None',
    REQUEST_TYPE: requestType,
    visual_example_included:
      visualExample && visualExample.asset_url ? true : false,
    visual_example_labels: visualExample
      ? visualExample.labels
          .map((label) => label.name)
          .filter(
            (name) =>
              ![
                'All Visuals, All New Visuals, Top 10 Spending Visuals',
              ].includes(name)
          )
      : [],
    visual_example_id: visualExample?.id || 'None',
  };

  if (visualExample && visualExample.visual_type === 'image') {
    payload = {
      ...payload,
      visual_example_url: visualExample.asset_url,
    };
  }

  return payload;
};
