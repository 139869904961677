import React, { useState } from 'react';
import Button from '../../../components/Button';

export const RefreshReport = ({ handleRefreshReport }) => {
  return (
    <div className="p-4 d-flex justify-content-between align-items-center">
      <p style={{ fontSize: '1.1rem', margin: 0 }}>Click to refresh report data.</p>
      <Button onClick={handleRefreshReport}>Refresh</Button>
    </div>
  );
};
