import { unwind } from '../../utils/wranglers';

export const ADNAME_LABELS = 'adNames_Labels';

/**
 * @param {{ value: string, ad_names: {name: string}[] }[]} data
 *
 * @return {{ string: string[] }}
 */
export function adNamesLabelsLookup(data) {
  const unwound = unwind(data, 'ad_names');

  const results = Object.create(null);

  const pushTo = (key, item) => results[key].push(item);

  unwound.forEach(({ value, ad_names: { name } }) => {
    if (!results[name]) {
      results[name] = [];
    }

    pushTo(name, value);
  });

  return results;
}
