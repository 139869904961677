import React, { useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useCreativeAnalyticsGet } from '../../contexts';
import { chart } from 'highcharts';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { toPercent_2 } from '../../../../utils/numbers';
import { useRightDrawerContext } from '../../RightDrawer';
import { useSimpleImpactApi } from '.';
import { useRecommendationsContext } from '../context';

const prepareData = (data = []) => {
  if (!data || !Array.isArray(data)) return [];

  return data.sort(
    (a, b) => b['Aggregated Importance'] - a['Aggregated Importance']
  );
};
const truncate = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
};

export const Chart = ({ data = [], handleCategoryClick }) => {
  const { category } = useCreativeAnalyticsGet();
  const { drawerWidth } = useRightDrawerContext();
  const chartData = prepareData(data);
  const windowSize = useWindowSize();
  const { simpleImpactApi } = useRecommendationsContext();
  const { loading: apiLoading, error } = simpleImpactApi;
  const [loading, setLoading] = useState(true);

  const height = data.length * 40;
  let width = windowSize.width * 0.5;
  if (width > 500) width = 500;

  useEffect(() => {
    if (apiLoading) {
      setLoading(true);
    } else if (!apiLoading && (data.length || (error && error !== 'Error: undefined'))) {
      setLoading(false);
    }
  }, [apiLoading])

  if (loading) {
    return(
      <div className="d-flex justify-content-center">
        <i className="fas fa-spinner fa-spin" style={{ fontSize: '1.2rem' }} />
      </div>
    );
  }

  if (!chartData.length && !loading) {
    return <p>No data to display.</p>;
  }
 
  return (
    <BarChart
      width={drawerWidth}
      height={height}
      data={chartData}
      layout="vertical"
      margin={{ top: 10, right: 30, left: -120, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis type="number" />
      <YAxis
        dataKey="Category Name"
        type="category"
        tickFormatter={(tickItem) => truncate(tickItem, 20)}
        // tick={{fontSize: 10, color: Colors.gray2}}
        tickLine={false}
        tickMargin={20}
        width={300}
        // tick={renderCustomAxisTick}
      />
      <Tooltip formatter={(value) => toPercent_2(value)} />
      <Bar dataKey="Aggregated Importance" onClick={handleCategoryClick}>
        {chartData.map((entry, index) => {
          const color =
            entry['Category Name'] === category ? '#FB70A2' : '#FFABDD';
          return <Cell fill={color} />;
        })}
      </Bar>
    </BarChart>
  );
};
