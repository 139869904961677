import React from "react";
import Tooltip from "../../../../components/Tooltip";
import { useCreativeAnalyticsGet } from "../../contexts";
import { LabelObject } from "../../types";
import {
  ApproxEqualIcon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  StarIcon,
} from "../icons";
import {
  getColumnData,
  getDarwinIndexCells,
  getDarwinIndexHeaders,
} from "./columns";
import { getCPAQuantile } from "../../filter-utils";

const headerDisplay = {
  cpa: "CPA",
  roas: "ROAS",
  percent_spend: "% of Spend",
};

export const DarwinIndexTable = ({
  category,
  fromCreativeStudio,
  current,
  isROASClient = false,
  isCPATable = false,
  tableMetric,
}) => {
  const { cpasList } = useCreativeAnalyticsGet();
  const Heading = (
    <h4 className="index-table__heading">
      Compare with Darwin Index:{" "}
      <span className="index-table__heading__metric">
        {headerDisplay[tableMetric]}
      </span>
    </h4>
  );
  const indexHeaders = getDarwinIndexHeaders(isCPATable);

  return (
    <div className="index-table">
      {Heading}
      <table className="table table-borderless category-table">
        <tr className="category-table__header">
          <th className="category-table__cell header">Label</th>
          {indexHeaders.map((colHeader) => (
            <th className="category-table__cell header">{colHeader}</th>
          ))}
        </tr>
        {category.labels.map((label: LabelObject, i: number) => {
          const activeLabels: LabelObject[] = category.labels.filter(
            (categoryLabel) =>
              !fromCreativeStudio
                ? categoryLabel.visuals.some(({ id }) => id === current.data.id)
                : current.data.labels.includes(categoryLabel.value)
          );
          const active = activeLabels.find((x) => x.value === label.value);
          let ranking = ("00" + (i + 1)).slice(-2);
          const isTestingOpportunity =
            label.spend === 0 && label.total_results === 0;

          const formatCellData = (cell) => {
            return cell;
          };

          const cells = getDarwinIndexCells({
            isCPATable,
            label,
            cpasList,
            isROASClient,
          });

          if (!cells || !Array.isArray(cells)) return null;

          const cpaQuintile = getCPAQuantile(label, cpasList, isROASClient);
          const getDiff = (currentQuintile, indexQuintile) => {
            if (!currentQuintile || !indexQuintile) return null;

            return ((currentQuintile - indexQuintile) / indexQuintile) * 100;
          };
          const percentDiff = getDiff(cpaQuintile, label.average_cpa_quintile);

          let markerColor = "#039BE5";
          let Marker = <ApproxEqualIcon fill={markerColor} />;

          if (percentDiff >= 50) {
            markerColor = "#25A778";
            Marker = <ChevronDoubleUpIcon fill={markerColor} />;
          } else if (percentDiff >= 10 && percentDiff < 50) {
            markerColor = "#49A953";
            Marker = <ChevronUpIcon fill={markerColor} />;
          } else if (percentDiff >= -10 && percentDiff < 10) {
            markerColor = "#039BE5";
          } else if (percentDiff >= -50 && percentDiff < -10) {
            markerColor = "#B77686";
            Marker = <ChevronDownIcon fill={markerColor} />;
          } else if (percentDiff < -50) {
            markerColor = "#B95D7E";
            Marker = <ChevronDoubleDownIcon fill={markerColor} />;
          }

          return (
            <tr
              className={`category-table__row ${
                active ? "category-table__row--active" : ""
              } ${
                isTestingOpportunity
                  ? "category-table__row--testing-opportunity"
                  : ""
              }`}
            >
              <td className="category-table__cell label">
                <div className="item-number">
                  {!!percentDiff && isCPATable && (
                    <Tooltip
                      content={
                        <div>
                          <div>
                            <span className="subtle">
                              Your {isROASClient ? "ROAS" : "CPA"} quintile:
                            </span>
                            <span>{` ${cpaQuintile}`}</span>
                          </div>
                          <div>
                            <span className="subtle">
                              Index {isROASClient ? "ROAS" : "CPA"} quintile:
                            </span>
                            <span>{` ${label.average_cpa_quintile}`}</span>
                          </div>
                        </div>
                      }
                      className="phrase-builder-tooltip"
                      delay={[500, 0]}
                    >
                      <span className="marker">{Marker}</span>
                    </Tooltip>
                  )}
                  {isTestingOpportunity && (
                    <Tooltip
                      className="phrase-builder-tooltip"
                      content={
                        <div>
                          <span>Testing Opportunity</span>
                          <br />
                          <span>
                            <span className="subtle">
                              You aren't testing any
                            </span>
                            {` ${label.name} `}
                            <span className="subtle">ads.</span>
                          </span>
                        </div>
                      }
                      delay={[500, 0]}
                    >
                      <span className="opportunity-icon">
                        <StarIcon />
                      </span>
                    </Tooltip>
                  )}
                  <span>{isTestingOpportunity ? "00" : ranking}</span>
                </div>
                <div className="label-name">
                  <span>{`${label.name} `}</span>
                </div>
              </td>
              {cells.map((cell) => {
                return (
                  <td
                    style={{ ...(isCPATable && { textAlign: "left " }) }}
                    className="category-table__cell"
                  >
                    {formatCellData(cell)}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </table>
    </div>
  );
};
