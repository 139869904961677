import { useState, useEffect, useRef } from 'react';

/**
 * @returns {[{ current: any }, boolean, () => void]}
 */
export function useLazyLoader() {
  const [visible, setVisibility] = useState(false);
  const [status, setStatus] = useState('active');
  const node = useRef(null);

  const handlePause = () => setStatus('pause');

  const handleObservation = (entries) => {
    const { intersectionRect } = entries[0];

    const {
      top, left, bottom, right,
    } = intersectionRect;

    if ([top, left, right, bottom].some(Boolean)) {
      return setVisibility(true);
    }
    return setVisibility(false);
  };

  const observer = useRef(new IntersectionObserver(handleObservation));

  useEffect(() => {
    if (node.current && observer.current && status === 'active') {
      observer.current.observe(node.current);
    }

    return () => {
      if (node.current) {
        observer.current.unobserve(node.current);
      }
    };
  }, [node.current, status]);

  useEffect(() => {
    if (observer.current && status === 'pause') {
      observer.current.unobserve(node.current);
    }
  }, [status, observer.current]);

  return [node, visible, handlePause];
}
