import { useEffect, useMemo, useRef } from 'react';
import { useViewName } from '../../selectors';
import { getStats } from './utils';
import { useCreativeAnalyticsGet } from '../../contexts';
import { createPortal } from 'react-dom';

export const usePerformanceMetrics = ({
  isDirectMail,
  filteredVisuals: visuals,
  isROAS,
  isVeterans,
  gaEnabled,
  viewName,
}) => {
  const { spendThreshold, clientMetrics, totalBudget, filteredData, customEvents } =
    useCreativeAnalyticsGet();
  const stats = useMemo(() => {
    return getStats({
      visuals,
      isROAS,
      spendThreshold,
      clientMetrics,
      isDirectMail,
      totalBudget,
      viewName,
      isVeterans,
      filteredData,
      gaEnabled,
      customEvents,
    });
  }, [
    visuals,
    isROAS,
    spendThreshold,
    clientMetrics,
    isDirectMail,
    totalBudget,
    viewName,
    isVeterans,
    filteredData,
    gaEnabled,
    customEvents,
  ]);

  return stats;
};

export const useDraggableInPortal = () => {
  const self = useRef({}).current;

  useEffect(() => {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.pointerEvents = 'none';
    div.style.top = '0';
    div.style.width = '100%';
    div.style.height = '100%';
    self.elt = div;
    document.body.appendChild(div);
    return () => {
      document.body.removeChild(div);
    };
  }, [self]);

  return (render) =>
    (provided, ...args) => {
      const element = render(provided, ...args);
      if (provided.draggableProps.style.position === 'fixed') {
        return createPortal(element, self.elt);
      }
      return element;
    };
};
