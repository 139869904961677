import React from 'react';
import './HideLabels.scss';
import { useDispatch } from 'react-redux';
import { useCreativeAnalyticsStore } from '../../../selectors';
import { setHiddenLabels } from '../../../store/CreativeAnalytics/creativeAnalyticsSlice';
import '../ReportCard/CategoryEditor/CategoryItem.scss';
import { Callout } from '../../../components/Callout';
import { useCategoriesLookup } from '../selectors';

export const HideLabels = () => {
  const { hiddenLabels } = useCreativeAnalyticsStore();
  const categoriesLookup = useCategoriesLookup();
  const dispatch = useDispatch();

  const handleToggleLabel = (isSelected, label) => {
    if (isSelected) {
      dispatch(
        setHiddenLabels(
          hiddenLabels.filter((lbl) => {
            return lbl.label !== label.name || lbl.category !== label.category;
          })
        )
      );
    } else {
      dispatch(
        setHiddenLabels([
          ...hiddenLabels,
          { label: label.name, category: label.category },
        ])
      );
    }
  };

  return (
    <div className="hide-labels-manager">
      <Callout style={{ margin: '1rem' }}>
        Selected labels will be temporarily hidden until the page is refreshed.
      </Callout>

      {Object.entries(categoriesLookup).map(([category, obj]) => {
        return (
          <div>
            <p className="hide-labels-manager__category">{category}</p>
            <ul>
              {obj.labels.map((label) => {
                const isSelected = hiddenLabels.some((hiddenLabel) => {
                  return (
                    hiddenLabel.label === label.name &&
                    hiddenLabel.category === label.category
                  );
                });

                return (
                  <li>
                    <button
                      className={`hide-labels-manager__toggle-btn mr-2 ${
                        isSelected ? 'is-enabled' : ''
                      }`}
                      onClick={() => handleToggleLabel(isSelected, label)}
                    >
                      <i
                        className={`fa-${
                          isSelected ? 'solid' : 'regular'
                        } fa-eye-slash`}
                        style={{ color: isSelected ? '#039BE5' : '#8c94af' }}
                      ></i>
                    </button>
                    <p>{label.name}</p>

                    {/* <button
                      onClick={() => handleToggleLabel(isSelected, label)}
                    >
                      <i
                        className={`fa-${
                          isSelected ? 'solid' : 'regular'
                        } fa-eye-slash`}
                      ></i>
                    </button> */}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};
