export const LOADING = 'General Loading';
export const CREATIVE_ANALYTICS = 'Creative Analytics';
export const CREATIVE_LIBRARY = 'Creative Library';
export const PERFORMANCE_DASHBOARD = 'Performance Dashboard';
export const CREATIVE_STUDIO = 'Creative Studio';
export const CREATIVE_TESTING = 'Creative Testing';
export const BLOCK_REPORT = 'Block Report';
export const AD_NAME_REPORT = 'Ad Name Report';
export const CUSTOM_REPORTS = 'Custom Reports';
export const SYNONYMS = 'Synonyms';
export const VIEW_SETTINGS = 'View Settings';
export const BULK_AUDIENCE = 'Bulk Audience';
export const REFRESH_DATA = 'Refresh Data ';
export const AD_LABEL_REPORT = 'Ad Label Report';
export const ADSET_REPORT = 'Adset Report';

export const TOGGLE_VISUAL_MODE = 'Toggled to Visual Mode';
export const TOGGLE_TABLE_MODE = 'Toggled to Table Mode';
export const TOGGLE_TRENDING = 'Toggled to Trending';
export const TOGGLE_ADSET_REPORT = 'Toggled to Adset Report';
export const TOGGLE_AD_REPORT = 'Toggled to Ad Report';
export const TOGGLE_DASHBOARD = 'Toggled to Dashboard';
export const TOGGLE_CLUSTERS = 'Toggled to Darwin Clusters';
export const TOGGLE_VARIATION = 'Toggled to Variation Report';
export const TOGGLE_HURDLE = 'Toggled to Hurdle Report';
export const TOGGLE_COPILOT = 'Toggled to Creative Copilot';
export const TOGGLE_PHRASE_THEME = 'Toggled to Phrase Theme';
export const TOGGLE_BLOCK_REPORT = 'Toggled to Block Report';
export const TOGGLE_LIBRARY = 'Toggled to Library';
export const TOGGLE_IMPACT_REPORT = 'Toggled to Impact Report';
export const TOGGLE_COPY = 'Toggled to Copy Report';
export const OPEN_CREATIVE_REQUEST_DRAWER = 'Opened creative request Drawer';
export const ADD_TO_REQUEST = 'Added element to creative request';
export const SUBMIT_REQUEST = 'Submitted creative request';
export const TRENDING_LAZY_LOADS = 'Scrolled through Trending';
export const CREATE_CATEGORY_BUTTON_PRESSED = 'Pressed Create Category button';
export const TRAIN_NEW_CCC = 'Trained New CCC';
export const EDIT_CCC = 'Edited CCC';
export const ADD_VISUALS_COLUMN = 'Added "Visuals" column';
export const CHANGE_SPEND_THRESHOLD = 'Changed Spend Threshold';
export const CHANGE_AUDIENCE = 'Changed Audience';
export const CHANGE_DATES = 'Changed dates';
export const SET_COMPARISON_PERIOD = 'Set comparison period';
export const LOAD_REPORT_CARD = 'Loaded report card';
export const HARD_REFRESH = 'Hard Refresh';

export const COMMENT_SUBMITTED = 'Submitted comment';
export const CLIENT_APPROVED = 'Client approved a creative';
export const FB_PREVIEW_BUTTON = 'Pressed FB Preview Button';
export const CUSTOM_THUMBNAILS_BUTTON = 'Pressed Custom Thumbnails Button';
export const PREDICTIONS_BUTTON = 'Pressed Predictions Button';
export const COMPLIANCE_BUTTON = 'Pressed Compliance Button';
export const REPORT_CARD_BUTTON = 'Pressed Report Card Button';
export const FB_PREVIEW_FORMAT_TOGGLE = 'Changed FB preview format';
export const SEARCHED_TERM_IN_LIBRARY = 'Searched term in library';

export const LoadingEvents = {
  CREATIVE_ANALYTICS,
  CREATIVE_LIBRARY,
  PERFORMANCE_DASHBOARD,
  CREATIVE_STUDIO,
  CREATIVE_TESTING,
  BLOCK_REPORT,
  AD_NAME_REPORT,
  CUSTOM_REPORTS,
  SYNONYMS,
  VIEW_SETTINGS,
  BULK_AUDIENCE,
  REFRESH_DATA,
};

export const CreativeAnalyticsEvents = {
  TOGGLE_VISUAL_MODE,
  TOGGLE_TABLE_MODE,
  OPEN_CREATIVE_REQUEST_DRAWER,
  ADD_TO_REQUEST,
  SUBMIT_REQUEST,
  TRENDING_LAZY_LOADS,
  CREATE_CATEGORY_BUTTON_PRESSED,
  TRAIN_NEW_CCC,
  EDIT_CCC,
  ADD_VISUALS_COLUMN,
  CHANGE_SPEND_THRESHOLD,
  CHANGE_AUDIENCE,
  CHANGE_DATES,
  SET_COMPARISON_PERIOD,
  LOAD_REPORT_CARD,
};

export const CreativeStudioEvents = {
  COMMENT_SUBMITTED,
  CLIENT_APPROVED,
  FB_PREVIEW_BUTTON,
  CUSTOM_THUMBNAILS_BUTTON,
  PREDICTIONS_BUTTON,
  COMPLIANCE_BUTTON,
  REPORT_CARD_BUTTON,
  FB_PREVIEW_FORMAT_TOGGLE,
};
