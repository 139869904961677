import React from 'react';
import { MenuItem } from '../../../../../components/LeftMenu/MenuItem';
import {
  useCreativeAnalyticsGet,
  useCreativeAnalyticsSet,
} from '../../../contexts';
import { useDispatch } from 'react-redux';
import { trackEvent } from '../../../../../store/UserActivity/actions';
import {
  CREATIVE_ANALYTICS,
  TOGGLE_CLUSTERS,
  TOGGLE_HURDLE,
  TOGGLE_COPILOT,
  TOGGLE_COPY,
  TOGGLE_IMPACT_REPORT,
  TOGGLE_PHRASE_THEME,
  TOGGLE_VARIATION,
} from '../../../../../store/UserActivity/constants';
import {
  CLUSTERS,
  HURDLE,
  COPILOT,
  COPY,
  PHRASE_THEME,
  VARIATION,
} from '../../../../../store/CreativeReports/constants';
import { PhraseThemeIcon } from '../../icons/phraseTheme';
import { BetaIcon } from '../../icons/beta';
import { ImpactReportIcon } from '../../icons/impact';
import { usePermissions } from '../../../../../components/Permissions/usePermissions';
import {
  PHRASE_THEME_REPORT,
  IMPACT_REPORT,
} from '../../../../../components/Permissions/constants';
import { ClustersIcon } from '../../icons/clusters';
import { VariationIcon } from '../../icons/variation';
import { HurdleIcon } from '../../icons/hurdle';
import { useSearchParams } from '../../../../../components/Hooks/search-params';
import { CopyIcon } from '../../icons/copy';
import { CopilotIcon } from '../../icons/copilot';

export const BetaReports = ({ loading, exclude, itemClass, collapsed }) => {
  const { display } = useCreativeAnalyticsGet();
  const { setParams } = useSearchParams();
  const dispatch = useDispatch();
  const hasPermission = usePermissions();

  return (
    <MenuItem
      title="BETA Reports"
      icon={<BetaIcon />}
      expandByDefault={false}
      exclude={exclude}
      className={itemClass}
      subMenu={
        loading ? (
          <>
            <div>Loading...</div>
          </>
        ) : (
          <>
            <MenuItem
              title="Creative Copilot"
              icon={<CopilotIcon />}
              onMenuItemClick={() => {
                dispatch(trackEvent(CREATIVE_ANALYTICS, TOGGLE_COPILOT));
                setParams({ display: COPILOT, category: '' });
              }}
              isSelected={display === COPILOT}
              exclude={!hasPermission(COPILOT)}
              className={itemClass}
              {...{ collapsed }}
            />
            <MenuItem
              title="Hurdle Report"
              icon={<HurdleIcon />}
              onMenuItemClick={() => {
                dispatch(trackEvent(CREATIVE_ANALYTICS, TOGGLE_HURDLE));
                setParams({ display: HURDLE, category: '' });
              }}
              isSelected={display === HURDLE}
              className={itemClass}
              {...{ collapsed }}
            />
            <MenuItem
              title="Variation Report"
              icon={<VariationIcon />}
              onMenuItemClick={() => {
                dispatch(trackEvent(CREATIVE_ANALYTICS, TOGGLE_VARIATION));
                setParams({ display: VARIATION, category: '' });
              }}
              isSelected={display === VARIATION}
              className={itemClass}
              {...{ collapsed }}
            />
            {/* <MenuItem
              title="Darwin Clusters"
              icon={<ClustersIcon />}
              onMenuItemClick={() => {
                dispatch(trackEvent(CREATIVE_ANALYTICS, TOGGLE_CLUSTERS));
                setParams({ display: CLUSTERS, category: 'clusters' });
              }}
              isSelected={display === CLUSTERS}
            /> */}
            {/* <MenuItem
              title="Phrase Theme"
              icon={<PhraseThemeIcon />}
              onMenuItemClick={() => {
                dispatch(trackEvent(CREATIVE_ANALYTICS, TOGGLE_PHRASE_THEME));
                setParams({ display: PHRASE_THEME, category: 'phrase_theme' });
              }}
              isSelected={display === PHRASE_THEME}
              exclude={!hasPermission(PHRASE_THEME_REPORT)}
            /> */}
            {/* <MenuItem
              title="Impact Report"
              icon={<ImpactReportIcon />}
              onMenuItemClick={() => {
                dispatch(trackEvent(CREATIVE_ANALYTICS, TOGGLE_IMPACT_REPORT));
                setParams({ display: 'impact_report', category: '' });
              }}
              isSelected={display === 'impact_report'}
              exclude={!hasPermission(IMPACT_REPORT)}
            /> */}
          </>
        )
      }
    />
  );
};
