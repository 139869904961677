import React, { useState, useEffect, useMemo } from 'react';

const formatExisting = (existing) => {
  return {
    name: existing.udc_name || '',
    description: existing.description || '',
    visual_mode: existing.visual_mode || true,
  }
}

export const useInputState = (markFieldAsUpdated, existing) => {
  const [input, setInput] = useState(existing ? formatExisting(existing) : {
    name: '',
    description: '',
    visual_mode: true,
  });

  const handleInput = (field, value) => {
    markFieldAsUpdated(field);
    setInput((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return [input, handleInput];
};
