import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const GetCtx = createContext(null);
const SetCtx = createContext(null);

export function VisualReportProvider({
  metrics, period1, period2, campaigns, audience_breakdown, level, setParams, children,
}) {
  return (
    <GetCtx.Provider value={{ metrics, period1, period2, campaigns, audience_breakdown, level }}>
      <SetCtx.Provider value={setParams}>
        {children}
      </SetCtx.Provider>
    </GetCtx.Provider>
  );
}

VisualReportProvider.defaultProps = {
  period2: '',
};

VisualReportProvider.propTypes = {
  period1: PropTypes.string.isRequired,
  period2: PropTypes.string,
  setParams: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export function useVisualReportGet() {
  return useContext(GetCtx);
}

export function useVisualReportSet() {
  return useContext(SetCtx);
}