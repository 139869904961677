import React from "react";
import PropTypes from "prop-types";
/**
 * @description Footer for left hand display in
 * creative-reports. Parent element must have
 * position: "relative" to work properly.
 */

import './style.css';

const Footer = ({ height, children }) => {
  return (
    <div
      className="report-footer"
      style={{
        position: "absolute",
        backgroundColor: "#fff",
        bottom: 0,
        left: 0,
        width: "100%",
        height: `${height * 0.075}px`,
      }}
    >
      {children}
    </div>
  );
};

Footer.propTypes = {
  height: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default Footer;
