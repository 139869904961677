import { batch } from 'react-redux';
import { API_SUCCESS, apiRequest, API_FAILURE } from '../Api/actions';
import { addNotification } from '../Notifications/actions';
import { setCreativeTestingAd } from '../CreativeTesting/actions';

export const AD_APPROVALS = 'AdApprovals';

export const AD_APPROVALS_FETCH = `[${AD_APPROVALS}] FETCH`;

export const fetchAdForApproval = ({ ad_id, status, comments }) => ({
  type: AD_APPROVALS_FETCH,
  data: {
    ad_id,
    status,
    comments,
  },
});

export const adApprovalMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case AD_APPROVALS_FETCH: {
        const {
          ViewSettings: { viewId, campaigns, account_id },
        } = getState();
        const {
          data: { ad_id, status, comments },
        } = action;

        batch(() => {
          dispatch(setCreativeTestingAd({ ad_id, approvalStatus: status }));

          dispatch(
            apiRequest({
              body: {
                ad_id,
                status,
                comments,
                campaigns,
                account_id,
              },
              url: ['api', viewId, 'approve-ad'].join('/'),
              entity: AD_APPROVALS,
              method: 'POST',
            })
          );

          dispatch(
            addNotification(
              {
                id: new Date().getMilliseconds(),
                status: 'info',
                message: `Attempting to ${status.slice(0, -2)} ad`,
                title: 'Ad Approvals',
              },
              AD_APPROVALS
            )
          );
        });

        break;
      }

      case `[${AD_APPROVALS}] ${API_SUCCESS}`: {
        const { response } = action;

        if (response.status === 'SUCCESS') {
          dispatch(
            addNotification(
              {
                id: new Date().getMilliseconds(),
                status: 'success',
                title: 'Ad Approvals',
                message: 'Ad status was successfully updated',
              },
              AD_APPROVALS
            )
          );
        }

        break;
      }

      case `[${AD_APPROVALS}] ${API_FAILURE}`: {
        const { error } = action;

        dispatch(
          addNotification(
            {
              id: new Date().getMilliseconds(),
              status: 'danger',
              title: 'Ad Approvals',
              message: error.body || error,
            },
            AD_APPROVALS
          )
        );

        break;
      }

      default:
        return result;
    }

    return result;
  };
