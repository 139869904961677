import React, { useCallback, useEffect } from 'react';
import { useWindowSize } from '../../../hooks/useWindowSize';
import ReactFlow, {
  ReactFlowProvider,
  Panel,
  Background,
  Controls,
  useNodesState,
  useEdgesState,
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  MiniMap,
} from 'reactflow';

import 'reactflow/dist/style.css';
import { createNodesAndEdges } from './utils/createNodesAndEdges';

const Flow = ({ category_name, derived_labels, onBack }) => {
  const size = useWindowSize();
  const width = size.width - 460;
  const { nodes: initialNodes, edges: initialEdges } = createNodesAndEdges({
    category_name,
    derived_labels,
    width,
  });
  const [nodes, setNodes] = useNodesState(initialNodes);
  const [edges, setEdges] = useEdgesState(initialEdges);

  useEffect(() => {
    setNodes(initialNodes);
    setEdges(initialEdges);
  }, [category_name, derived_labels]);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );
  
  const onLoad = (reactFlowInstance) =>
    reactFlowInstance.fitView({ padding: 0.5 });

  return (
    <div style={{ width: `${width}px`, height: '90vh' }}>
      <ReactFlow
        onLoad={onLoad}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodes={nodes}
        edges={edges}
        panOnScroll
      >
        {!!onBack && (
          <Panel position="top-left">
            <button className="sidebar__back" onClick={onBack}><i className="fas fa-arrow-left"></i>Editor</button>
          </Panel>
        )}
        <Panel position="top-center">{category_name}</Panel>
        <Controls />
        <MiniMap pannable />
      </ReactFlow>
    </div>
  );
};

function FlowWithProvider(props) {
  return (
    <ReactFlowProvider>
      <Flow {...props} />
    </ReactFlowProvider>
  );
}

export default FlowWithProvider;
