import React from 'react';
import { ADD_NEW_RULE, SELECT_RULE } from './reducer';

export const RulesList = ({ derivedLabelsState, dispatchDerivedLabels }) => {
  const addNewLabel = () => {
    dispatchDerivedLabels({
      type: ADD_NEW_RULE,
    });
  };

  const handleSelect = (rule_id) => {
    dispatchDerivedLabels({
      type: SELECT_RULE,
      rule_id,
    });
    // send label name and rule number
  };

  let RenderList = <p>No derived labels added.</p>;

  if (derivedLabelsState.rules.length > 0) {
    RenderList = (
      <ul>
        {derivedLabelsState.rules.map(({ label_name, rule_num, rule_id }, i) => {
          const isSelected = rule_id === derivedLabelsState.selected_rule;

          return (
            <div
              onClick={() => handleSelect(rule_id)}
              className={`rules-list__item ${
                isSelected ? 'is-selected' : ''
              } d-flex justify-content-between`}
            >
              <h3 className="rules-list__rule-name">
                {derivedLabelsState.category_name} - {label_name}
              </h3>
              <p className="rules-list__rule-num">Rule {rule_num}</p>
            </div>
          );
        })}
      </ul>
    );
  }

  return (
    <div className="rules-list">
      <div className="rules-list__header d-flex justify-content-between">
        <h3 className="derived-labels__heading rules-list__heading">
          Create derived labels
        </h3>
        <button className="rules-list__add-btn" onClick={addNewLabel}>
          + New
        </button>
      </div>
      {RenderList}
    </div>
  );
};
