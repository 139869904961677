import React, { useEffect, useMemo, useState } from 'react';
import { Creative } from './Creative';
import './VisualsWrapper.scss';
import { formatPreviousData, getVisualsPerPage } from './utils';
import { EmptyState } from './EmptyState';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { Pagination } from '@mui/material';
import LazyLoader from '../../../components/LazyLoader';
import { usePreviousDateRangeData } from '../PeriodComparison/api';
import { generateMockPreviousPeriodData } from '../PeriodComparison/utils';
import { useFetchPFRData } from '../../CreativeAnalytics/hooks/useFetchPFRData';
import { Virtuoso, VirtuosoGrid } from 'react-virtuoso';

export const VisualsWrapper = ({
  visuals,
  columns,
  metadataFields,
  metadata,
  groupParams,
  formatMetric,
  columnOptions,
  handleCreateManualTag,
  stacked,
  iterationsData,
  isOrganicView,
}) => {
  const { width } = useWindowSize();
  const visualsPerPage = getVisualsPerPage(width);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const numOfPages = Math.ceil(visuals.length / visualsPerPage);

  const startIndex = (currentPage - 1) * visualsPerPage;
  const visibleData = stacked
    ? visuals
    : visuals.slice(startIndex, startIndex + visualsPerPage);

  useEffect(() => {
    if (numOfPages > 0) {
      if (currentPage > numOfPages) {
        setCurrentPage(numOfPages);
      }

      if (currentPage < 1) {
        setCurrentPage(1);
      }
    } else {
      setCurrentPage(0);
    }
  }, [visuals, visualsPerPage, currentPage]);

  if (visuals.length < 1) return <EmptyState />;

  return (
    <div>
      <div className="dna-grid-wrapper" style={{ height: '50vh' }}>
        <VirtuosoGrid
          style={{ height: '100%', paddingBottom: '10px' }}
          data={visibleData}
          itemContent={(index, visual) => {
            const hasIterations = iterationsData?.some(
              (x) => x.source_asset_id === visual.id
            );
            return (
              <div className="dna-grid-item" style={{ position: 'relative' }}>
                <Creative
                  visual={visual}
                  columns={columns}
                  metadata={metadata}
                  metadataFields={metadataFields}
                  rank={visual?.rank}
                  showRankings={true}
                  formatMetric={formatMetric}
                  columnOptions={columnOptions}
                  handleCreateManualTag={handleCreateManualTag}
                  hasIterations={hasIterations}
                    isOrganicView={isOrganicView}
                />
              </div>
            );
          }}
          components={{
            Item: 'div', // Ensures each item is a block-level element
            List: 'div', // Makes the list container a div to allow CSS grid
          }}
          listClassName="dna-grid" // Apply grid styling here
        />
      </div>
      {!stacked && (
        <Pagination
          onChange={(e, value) => handlePageChange(value)}
          count={numOfPages}
          variant="outlined"
          color="primary"
        />
      )}
    </div>
  );
};
