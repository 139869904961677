import { toCurrency } from '../../../utils/numbers';

export const format$ = (n) => {
  if (typeof n !== 'string') {
    return '-';
  }
  const forParsing = n.replace('$', '');
  const parsed = parseFloat(forParsing);

  return toCurrency(parsed);
};
