import React from "react";

export const RetryButton = ({ fn }) => {
  return (
    <span
      className="btn-retry"
      onClick={fn}
    >
      Retry
    </span>
  );
};