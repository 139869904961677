import React, { useEffect, useState } from "react";
import {
  GripIcon,
  RecommendationsIcon,
  ReportCardIcon,
} from "../../../../components/Icons";
import Tooltip from "../../../../components/Tooltip";
import "./EditorItem.scss";
import Tippy from "@tippyjs/react";

const EditorItem = ({
  metric,
  enabled,
  isDragging,
  isDraggingOver,
  isBestPerformingView,
  handleToggle,
}) => {
  const DisabledIcon = (
    <Tooltip
      content="Metric unavailable in report card. Changes will only affect library metrics."
      delay={[500, 0]}
    >
      <i className="fa-solid fa-ban metric-editor__alert ml-1"></i>
    </Tooltip>
  );

  const Toggles = (
    <div className="metric-editor__item__toggles">
      <Tooltip
        content="Show this metric in library/report card"
        delay={[500, 0]}
      >
        <button
          className={`metric-editor__toggle-btn ${enabled ? "is-enabled" : ""}`}
          onClick={() => handleToggle(metric)}
        >
          <i className="metric-editor__icon fa-solid fa-photo-film"></i>
        </button>
      </Tooltip>
    </div>
  );
  return (
    <div
      className={`metric-editor__item ${isDragging ? "is-dragging" : ""} ${
        isDraggingOver ? "is-dragging-over" : ""
      }`}
    >
      <div className="d-flex align-items-center">
        {Toggles}
        <p className="metric-editor__item__title">{metric.metric}</p>
        {(metric.value === null || metric.value === undefined) &&
          !isBestPerformingView &&
          DisabledIcon}
      </div>

      <GripIcon className="grip-icon ml-4" />
    </div>
  );
};

export default EditorItem;
