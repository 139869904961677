import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useCreativeRequest } from './hooks';

const GetCtx = createContext(null);
const SetCtx = createContext(null);

export function CreativeRequestProvider({ children }) {
  const {
    requests,
    setRequests,
    currentIndex,
    setCurrentIndex,
    currentRequest,
    setInclude,
    setAvoid,
    removeLabel,
    addToOverview,
    addLinkToOverview,
    descriptions,
    setDescriptions,
    handleAddDataPoint,
    handleRemoveDataPoint,
    overviews,
    setOverviews,
  } = useCreativeRequest();

  return (
    <GetCtx.Provider
      value={{
        requests,
        currentIndex,
        currentRequest,
        descriptions,
        overviews,
      }}
    >
      <SetCtx.Provider
        value={{
          setRequests,
          setCurrentIndex,
          setInclude,
          setAvoid,
          removeLabel,
          addToOverview,
          addLinkToOverview,
          setDescriptions,
          handleAddDataPoint,
          handleRemoveDataPoint,
          setOverviews,
        }}
      >
        {children}
      </SetCtx.Provider>
    </GetCtx.Provider>
  );
}

export function useCreativeRequestGet() {
  return useContext(GetCtx);
}

export function useCreativeRequestSet() {
  return useContext(SetCtx);
}
