import { useState, useEffect, useCallback } from 'react';
import { useReportCardSettings } from '../ReportCard/hooks';

export const useReportCardPrefs = (view_id, isROAS, reportCardPrefsApi) => {
  const [reportCardDataType, setReportCardDataType] = useState('aggregate');
  const [reportCardMetric, setReportCardMetric] = useState('cpa');

  const [reportCardSettings, dispatchReportCardSettings] =
    useReportCardSettings(view_id, isROAS, reportCardPrefsApi);

  const fetchCustomSettings = useCallback(() => {
    reportCardPrefsApi.get.request(view_id);
  }, [view_id]);

  useEffect(() => {
    fetchCustomSettings();
  }, []);

  useEffect(() => {
    if (reportCardPrefsApi.get.data) {
      const { data } = reportCardPrefsApi.get;
      const type = data.type === 'average' ? 'aggregate' : data.type;
      setReportCardDataType(type);
      setReportCardMetric(data.selected_down_funnel_metric);
    }
  }, [reportCardPrefsApi.get.data]);

  return {
    reportCardDataType,
    setReportCardDataType,
    reportCardMetric,
    setReportCardMetric,
    reportCardSettings,
    fetchCustomSettings,
    dispatchReportCardSettings,
    loadingRcSettings: reportCardPrefsApi.get.loading
  };
};
