import React from "react";
import Button from "../../../components/Button";
import { useClustersContext } from "./contexts/ClustersContext";
import { Table } from "../PerformanceFrequency/Table/Table";
import { useCategories } from "../hooks";
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from "../contexts";
import { usePfrCategories } from "../selectors";

const Clusters = ({ columns }) => {
  const {
    clustersLoading,
    clustersError,
    clustersData,
    generalLoading,
    handleFetchClusters,
    preprocessData,
    changeClustersData,
    shouldRefresh,
    setShouldRefresh,
    clustersSuccess,
  } = useClustersContext();
  const { tableData } = usePfrCategories();
  const clusterTableData = tableData?.find(
    (data) => data.category_name === "Darwin Clusters"
  );
  const { category_name, category_data, labels } = clusterTableData || {};

  const disabled = clustersLoading;

  const handleRefresh = () => {
    setShouldRefresh(false);
    handleFetchClusters();
  };

  const TextDisplay = ({ text }) => {
    return (
      <p>
        <span>{text}</span>
      </p>
    );
  };

  let Display = (
    <TextDisplay
      text={
        "Fetch the last saved cluster data and display the results. Use this to display the dataset."
      }
    />
  );

  if (clustersLoading) {
    Display = <TextDisplay text={"Loading..."} />;
  }

  if (clustersError) {
    Display = <TextDisplay text={clustersError.error} />;
  }

  if (clustersData) {
    if (clustersSuccess) {
      if (clusterTableData) {
        Display = (
          <div className="report pl-0">
            <Table
              category_name={category_name}
              categoryData={category_data}
              categoryLabels={labels}
              isCompare={false}
              columns={[...columns, 'visuals']}
            />
          </div>
        );
      } else {
        Display = <TextDisplay text={"No data to display."} />;
      }
    } else {
      if (clustersData.body.includes("There is no cluster")) {
        Display = <TextDisplay text={"The visuals must be preprocessed."} />;
      } else if (clustersData.body.includes("number of clusters")) {
        Display = (
          <TextDisplay text={"The number of clusters has not been saved."} />
        );
      } else if (clustersData.body.includes("not enough visuals")) {
        Display = <TextDisplay text={"There are not enough visuals to display cluster data."} />;
      } else {
        console.log(clustersData);
        Display = <TextDisplay text={"There was an error."} />;

      }
    }
  }

  return (
    <div className="clusters__section clusters-data">
      <div className="d-flex align-items-center">
        <h2>Display Clusters</h2>
        {shouldRefresh && (
          <Button
            appearance="subtle"
            onClick={handleRefresh}
            style={{ margin: "0 0 0.5rem 0.5rem" }}
          >
            <i className="fa-solid fa-arrow-rotate-right" />
          </Button>
        )}
      </div>
      <div className="d-flex align-items-center">
        {!clusterTableData && (
          <Button
            onClick={handleFetchClusters}
            loading={clustersLoading}
            disabled={disabled}
          >
            Go
          </Button>
        )}
        {Display}
      </div>
    </div>
  );
};

export default Clusters;
