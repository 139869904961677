import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import CustomDropdown from '../../../components/CustomDropdown';
import Button from '../../../components/Button';

import { METRIC_OPTIONS, GA_METRIC_OPTIONS } from './constants';
import { useVisualReportGet, useVisualReportSet } from './contexts';

import DateSpan from '../../../components/DateSpanDropdown';
import { darwinDateToDate, toDarwinDate } from '../../../utils/darwin-dates';
import { DATES } from './dates';

export function useCustomMetrics() {
  return useSelector(
    ({ ViewSettings: { customIntegratedMetrics } }) => customIntegratedMetrics
  );
}

export function extendCustomMetrics(metrics) {
  return metrics.reduce((acc, { value, label, remove_cost_per }) => {
    acc.push({ value, label, isCustom: true });

    if (!remove_cost_per) {
      acc.push({
        value: `cp_${value}`,
        label: `Cost per ${label}`,
        isCustom: true,
      });
    }

    return acc;
  }, []);
}

function useColumnOptions() {
  const custom = useCustomMetrics();
  const merged = [...METRIC_OPTIONS, ...extendCustomMetrics(custom)];

  return merged;
}

export function ColumnPicker({ metrics }) {
  const selected = metrics.length ? metrics.split(' ') : metrics;
  const setParams = useVisualReportSet();

  const options = useColumnOptions();

  const [show, setShow] = useState(false);

  const handleVisibility = (isOpen, meta) => {
    if (isOpen) {
      setShow(true);
    } else if (meta.source !== 'select') {
      setShow(false);
    }
  };

  const handleColumns = ({ metric, checked }) => {
    if (checked) {
      return [...selected, metric];
    }
    return selected.filter((value) => value !== metric);
  };

  const handleSelect = (index) => {
    const metric = options[index];

    const update = handleColumns({
      metric: metric.value,
      checked: !selected.includes(metric.value),
    });
    const metricParams = update.join(' ');

    return setParams({ metrics: metricParams });
  };

  return (
    <CustomDropdown
      id="block-metric-select"
      label="Columns"
      onToggle={handleVisibility}
      onSelect={handleSelect}
      menuStyle={{ overflow: 'scroll', height: '650px' }}
      show={show}
    >
      <CustomDropdown.Header>Facebook Data</CustomDropdown.Header>
      {options.map(({ value, label, isCustom }, i) => (
        <CustomDropdown.Item key={value} eventKey={i}>
          <div className="form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="form-check-input"
                name={value}
                defaultChecked={selected.includes(value)}
              />
              {label}
            </label>
          </div>
        </CustomDropdown.Item>
      ))}
    </CustomDropdown>
  );
}

const getDefaultCampaigns = (selectedCampaigns, campaign_names) => {
  if (selectedCampaigns === 'all') {
    return campaign_names.map((campaign) => {
      return campaign._id;
    });
  }

  return selectedCampaigns ? selectedCampaigns.split(' ') : selectedCampaigns;
};

export const CampaignPicker = ({ selectedCampaigns }) => {
  const setParams = useVisualReportSet();
  const { campaign_names } = useSelector(
    ({ ViewSettings: { campaign_names } }) => ({
      campaign_names,
    })
  );
  const [selected, setSelected] = useState(
    getDefaultCampaigns(selectedCampaigns, campaign_names)
  );

  const [show, setShow] = useState(false);

  useEffect(() => {
    setSelected(getDefaultCampaigns(selectedCampaigns, campaign_names));
  }, [selectedCampaigns]);

  const handleVisibility = (isOpen, meta) => {
    if (isOpen) {
      setShow(true);
    } else if (meta.source !== 'select') {
      setShow(false);
    }
  };

  const handleCampaigns = ({ campaign, checked }) => {
    if (checked) {
      return [...selected, campaign];
    }
    return selected.filter((id) => id !== campaign);
  };

  const handleSelect = (index) => {
    const campaign = campaign_names[index]._id;
    const update = handleCampaigns({
      campaign,
      checked: !selected.includes(campaign),
    });
    setSelected(update);
  };

  const handleSubmit = () => {
    const campaignParams = selected.join(' ');
    return setParams({ campaigns: campaignParams });
  };

  return (
    <CustomDropdown
      id="campaign-select"
      label="Campaigns"
      onToggle={handleVisibility}
      onSelect={handleSelect}
      menuStyle={{ overflow: 'scroll', height: '650px' }}
      show={show}
    >
      {campaign_names.map((campaign, i) => (
        <CustomDropdown.Item key={campaign._id} eventKey={i}>
          <div className="form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="form-check-input"
                name={campaign._id}
                defaultChecked={selected.includes(campaign._id)}
              />
              {campaign.name}
            </label>
          </div>
        </CustomDropdown.Item>
      ))}
      <Button onClick={handleSubmit}>Submit</Button>
    </CustomDropdown>
  );
};

function setDateRanges(disableToday) {
  if (disableToday) {
    return [{ ...DATES[0], disabled: true }, ...DATES.slice(1)];
  }
  return DATES;
}

export function PeriodPicker({ name }) {
  const params = useVisualReportGet();
  const period = params[name];
  const setParams = useVisualReportSet();
  const [startDate, endDate] = period.split(',').map(darwinDateToDate);
  const disableToday = true;
  const [show, setShow] = useState(false);

  const handleChange = (dates) => {
    if (dates === '') {
      return setParams({ [name]: '' });
    }
    const str = dates.map(toDarwinDate).join(',');

    return setParams({ [name]: str });
  };

  if (!period || !period.length) {
    return (
      <DateSpan
        startDate={new Date()}
        endDate={new Date()}
        handleChange={handleChange}
        dateRanges={setDateRanges(disableToday)}
        allowNoSelection={name === 'period2'}
        noneSelected
        {...{ show, setShow }}
      />
    );
  }

  return (
    <DateSpan
      startDate={startDate}
      endDate={endDate}
      handleChange={handleChange}
      dateRanges={setDateRanges(disableToday)}
      allowNoSelection={name === 'period2'}
      {...{ show, setShow }}
    />
  );
}
