import { batch } from 'react-redux';
import {
  CREATE_AD,
  CREATE_AD_FETCH_AD,
  CREATE_AD_FETCH_CAROUSEL,
  setCreateAd_ad,
  setCreateAd_carousel,
  updateElement,
} from './actions';
import { API_SUCCESS, API_FAILURE, apiRequest } from '../Api/actions';
import { setLoader } from '../Loaders/actions';
import { setError } from '../Errors/actions';
import { getAppSearchParams, getViewId } from '../utils';

const AD = `${CREATE_AD}.Ad`;

const adMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case CREATE_AD_FETCH_AD: {
        const {
          ViewSettings: { campaigns, account_id },
        } = getState();
        const viewId = getViewId();
        const { start_date, end_date, selectedAudience } = getAppSearchParams();

        batch(() => {
          dispatch(setError(AD, false));
          dispatch(setLoader(AD, true));
          dispatch(
            apiRequest({
              body: {
                campaigns,
                account_id,
                start_date,
                end_date,
                audience: [selectedAudience].filter((a) => a !== ''),
                view_type: 'testing',
              },
              url: ['api', viewId, 'what-to-test'].join('/'),
              entity: AD,
              method: 'POST',
            })
          );
        });

        break;
      }

      case `[${AD}] ${API_SUCCESS}`: {
        const { response } = action;

        batch(() => {
          dispatch(setCreateAd_ad(response.data));
          dispatch(setLoader(AD, false));
        });

        break;
      }

      case `[${AD}] ${API_FAILURE}`: {
        const { error } = action;

        batch(() => {
          dispatch(setError(AD, error));
          dispatch(setLoader(AD, false));
        });

        break;
      }

      default:
        return result;
    }

    return result;
  };

function bestBatch(dispatch, carousels) {
  const keys = ['atf', 'btf', 'cta', 'visual'];

  keys.forEach((key) => {
    dispatch(
      updateElement({
        type: key,
        value: carousels[key][0] || { value: '', index: 0 },
      })
    );
  });
}

const carouselMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case CREATE_AD_FETCH_CAROUSEL: {
        const {
          ViewSettings: { account_id, campaigns },
        } = getState();
        const viewId = getViewId();
        const { start_date, end_date, selectedAudience } = getAppSearchParams();

        batch(() => {
          dispatch(setError(`${CREATE_AD}.Carousel`, false));
          dispatch(setLoader(`${CREATE_AD}.Carousel`, true));
          dispatch(
            apiRequest({
              body: {
                viewId,
                start_date,
                end_date,
                campaigns,
                audience: [selectedAudience].filter((a) => a !== ''),
                view_type: 'testing',
                account_id,
              },
              url: ['api', viewId, 'what-to-test-carousel'].join('/'),
              entity: `${CREATE_AD}.Carousel`,
              method: 'POST',
            })
          );
        });

        break;
      }

      case `[${CREATE_AD}.Carousel] ${API_SUCCESS}`: {
        const { response } = action;
        const carousels = response.data;

        batch(() => {
          dispatch(setCreateAd_carousel(carousels));
          bestBatch(dispatch, carousels);
          dispatch(setLoader(`${CREATE_AD}.Carousel`, false));
        });

        break;
      }

      case `[${CREATE_AD}.Carousel] ${API_FAILURE}`: {
        const { error } = action;

        batch(() => {
          dispatch(setError(`${CREATE_AD}.Carousel`, error));
          dispatch(setLoader(`${CREATE_AD}.Carousel`, false));
        });

        break;
      }

      default:
        return result;
    }

    return result;
  };

const createAdMiddlewares = [adMiddleware, carouselMiddleware];

export default createAdMiddlewares;
