import React from 'react';

export const Insights = ({ insights }) => {
  return (
    <div className="insights">
      <ul>
        {insights.map(({ insight_bold_point, insight_description }) => (
          <li className="insight">
            <p className="insight__bold-point mb-2">{insight_bold_point}</p>
            <p className="insight__description">{insight_description}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};
