import React, { useMemo } from 'react';
import _ from 'lodash';

import { CARD_TEMPLATES } from './constants';
import { getCards } from './utils';

import { CardObject } from './types';
import { useDashboardConfig } from './hooks';
import { Wrapper } from './Wrapper';
import { LoadingSkeleton } from './Skeleton';

import './Card.scss';

const InsightsDashboard = ({
  account_id,
  filteredData,
  isROAS,
  libraryAnalyticsState,
  dashboardEditMode: editMode,
  setDashboardEditMode: setEditMode,
}) => {
  const { config, setConfig, loading, error, messages } =
    useDashboardConfig(account_id);

  const cards: CardObject[] = useMemo(() => {
    return getCards(filteredData, config, CARD_TEMPLATES);
  }, [filteredData, config]);

  if (loading) return <LoadingSkeleton />;
  if (error) return <p>there was an error</p>;

  return (
    <Wrapper
      cards={cards}
      {...{
        isROAS,
        libraryAnalyticsState,
        editMode,
        config,
        setConfig,
        setEditMode,
        messages
      }}
    />
  );
};

export default InsightsDashboard;
