import React, { useEffect, useState } from 'react';
import ClustersContextProvider from './contexts/ClustersContext';
import { ErrorBoundary } from '../../../components/Helpers/Error';
import Preprocess from './Preprocess';
import ChangeClusters from './ChangeClusters';
import Clusters from './Clusters';
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from '../contexts';
import { useViewId } from '../selectors';
import { makePreprocessPayload } from './utils';
import usePreprocess from './hooks/usePreprocess';

const Wrapper = ({ columns }) => {
  const { allVisuals, clustersApi } = useCreativeAnalyticsGet();
  const { setClustersData } = useCreativeAnalyticsSet();
  const view_id = useViewId();
  const [numberOfClusters, setNumberOfClusters] = useState('4');
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const {
    save: {
      // loading: preprocessLoading,
      // error: preprocessError,
      // data: preprocessData,
      request: preprocessRequest,
    },
  } = clustersApi;

  const {
    get: {
      loading: clustersLoading,
      error: clustersError,
      data: clustersData,
      request: clustersRequest,
    },
  } = clustersApi;

  const {
    post: {
      loading: changeClustersLoading,
      error: changeClustersError,
      data: changeClustersData,
      request: changeClustersRequest,
    },
  } = clustersApi;

  const changeClustersSuccess =
    changeClustersData && changeClustersData?.status === 200;
  const clustersSuccess = clustersData && clustersData?.status_code === 200;

  useEffect(() => {
    if (clustersData) {
      const { status_code } = clustersData;
      if (status_code === 200) {
        setClustersData(clustersData.body);
      } else {
        console.log(clustersData);
      }
    }
  }, [clustersData]);

  const [
    preprocessLoading,
    preprocessError,
    preprocessData,
    preprocessSuccess,
  ] = usePreprocess();

  const handlePreprocess = () => {
    const payload = makePreprocessPayload(view_id, allVisuals);
    preprocessRequest({ all_visuals: payload });
  };

  const handleChangeClusters = () => {
    changeClustersRequest({ view_id, n_clusters: parseInt(numberOfClusters) });
  };

  const handleFetchClusters = () => {
    clustersRequest({ view_id });
  };

  return (
    <ErrorBoundary>
      <ClustersContextProvider
        generalLoading={
          preprocessLoading || clustersLoading || changeClustersLoading
        }
        {...{
          handlePreprocess,
          handleFetchClusters,
          handleChangeClusters,
          preprocessLoading,
          preprocessError,
          preprocessData,
          clustersLoading,
          clustersError,
          clustersData,
          changeClustersLoading,
          changeClustersError,
          changeClustersData,
          numberOfClusters,
          setNumberOfClusters,
          shouldRefresh,
          setShouldRefresh,
          preprocessSuccess,
          changeClustersSuccess,
          clustersSuccess,
        }}
      >
        <div className="clusters">
          <Preprocess
            isVariationReport={false}
            loading={preprocessLoading}
            error={preprocessError}
            data={preprocessData}
            success={preprocessSuccess}
            handlePreprocess={handlePreprocess}
          />
          <ChangeClusters />
          <Clusters columns={columns} />
        </div>
      </ClustersContextProvider>
    </ErrorBoundary>
  );
};

export default Wrapper;
