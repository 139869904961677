import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';

import { getDefaultViewFeature } from '../../root-routes';

import connectTo from './Container';
import Login from './Login';
import Boundary from './LoginBoundary';

function useRedirect(viewId) {
  const location = useLocation();
  const defaultRoute = getDefaultViewFeature(viewId);

  const {
    from: { pathname, search },
  } = location.state || {
    from: { pathname: defaultRoute, search: '' },
  };

  if (pathname.split('/').length === 2) {
    return defaultRoute;
  }

  if (pathname.includes('creative-reports') && !search) {
    return `/${viewId}/creative-reports?report=block`;
  }

  return pathname + search;
}

function LoginRedirect({
  fetchLogin,
  removeError,
  isLoading,
  hasError,
  viewId,
  loggedIn,
}) {
  // !!! At this point in the login process, the URL has not been
  // updated with view-id. The view-id is pulled from redux.
  // This is not ideal, the active view-id should only come from
  // the URL.
  const redirectTo = useRedirect(viewId);

  return (
    <Boundary isLoading={isLoading} hasError={hasError}>
      {loggedIn ? (
        <Redirect exact to={redirectTo} />
      ) : (
        <Login fetchLogin={fetchLogin} removeError={removeError} />
      )}
    </Boundary>
  );
}

LoginRedirect.defaultProps = {
  loggedIn: false,
  isLoading: false,
  hasError: false,
  viewId: '',
};

LoginRedirect.propTypes = {
  fetchLogin: PropTypes.func.isRequired,
  removeError: PropTypes.func.isRequired,
  viewId: PropTypes.string,
  isLoading: PropTypes.bool,
  hasError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  loggedIn: PropTypes.bool,
};

export default connectTo(LoginRedirect);
