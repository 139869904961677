import React from 'react';
import './CopilotIcon.scss';
import { DARWIN_BLUE, DARWIN_NAVY } from '../../../../constants';

export const CopilotIcon = ({ loading, button }) => {
  return (
    <div
      className={`copilot-icon copilot-icon--placeholder ${
        loading && 'spin-loading'
      } ${!loading && !button ? 'idle' : ''} ${button ? 'button' : ''}`}
    >
      <svg
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.9513 19.8821C16.1188 19.8821 16.2336 19.7764 16.2513 19.6175C16.5687 17.2618 16.6481 17.2618 19.0832 16.7943C19.242 16.768 19.3569 16.6618 19.3569 16.4943C19.3569 16.3354 19.242 16.221 19.0832 16.1943C16.6481 15.8593 16.56 15.7798 16.2513 13.3801C16.2336 13.2213 16.1188 13.1064 15.9513 13.1064C15.7925 13.1064 15.6777 13.2213 15.66 13.3888C15.369 15.7531 15.2455 15.7444 12.8281 16.1943C12.6693 16.2297 12.5544 16.3354 12.5544 16.4943C12.5544 16.6708 12.6693 16.768 12.8631 16.7943C15.2632 17.1824 15.369 17.2441 15.66 19.5998C15.6777 19.7764 15.7925 19.8821 15.9513 19.8821ZM21.9327 29.6489C22.1624 29.6489 22.3299 29.481 22.3739 29.2427C23.0003 24.4083 23.6797 23.6758 28.4615 23.1465C28.7084 23.1202 28.8763 22.935 28.8763 22.7053C28.8763 22.4757 28.7084 22.2995 28.4615 22.2642C23.6797 21.7349 23.0003 21.0028 22.3739 16.1679C22.3299 15.9296 22.1624 15.7708 21.9327 15.7708C21.7035 15.7708 21.536 15.9296 21.5006 16.1679C20.8742 21.0028 20.1861 21.7349 15.413 22.2642C15.1571 22.2995 14.9896 22.4761 14.9896 22.7053C14.9896 22.935 15.1571 23.1202 15.413 23.1465C20.1771 23.7729 20.8389 24.417 21.5006 29.2427C21.536 29.481 21.7035 29.6489 21.9327 29.6489Z"
          fill={DARWIN_NAVY}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.3149 6.32214C18.7506 4.24565 11.3976 6.92228 7.81304 13.131C3.67091 20.3054 6.12904 29.4792 13.3034 33.6214C20.4778 37.7635 29.6517 35.3054 33.7938 28.131C37.3785 21.9222 36.0199 14.216 30.9394 9.56938L30.4263 10.458C35.0341 14.8093 36.2349 21.903 32.9278 27.631C29.0618 34.3271 20.4995 36.6213 13.8034 32.7553C7.10733 28.8894 4.81308 20.3271 8.67907 13.631C11.9861 7.90303 18.7296 5.39607 24.8018 7.21077L25.3149 6.32214Z"
          fill={DARWIN_NAVY}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M31.9678 15.0616C29.3469 9.92357 23.4111 7.13349 17.6158 8.68632C10.9544 10.4712 7.00123 17.3184 8.78616 23.9798C10.5711 30.6412 17.4182 34.5944 24.0796 32.8094C29.8139 31.2729 33.5413 25.9853 33.3293 20.294L32.336 20.5602C32.424 25.7076 29.0186 30.4508 23.8208 31.8435C17.6928 33.4855 11.3941 29.8489 9.75208 23.721C8.1101 17.593 11.7467 11.2942 17.8747 9.65225C23.1339 8.24305 28.5189 10.7219 30.978 15.3268L31.9678 15.0616Z"
          fill={DARWIN_BLUE}
        />
      </svg>
    </div>
  );
};
