export const DEFAULT = "default";
export const FAVORITES = "favorites";
export const GENERAL = "general";
export const TRY_THESE = "try_these";
export const TOP_PERFORMANCE = "top_performance";
export const LOADING_MESSAGES = [
  "Pulling in your data",
  "Analyzing creative performance",
  "Assembling recommendations",
  "Reticulating splines",
  "Boiling water",
  "Still working on it",
];
