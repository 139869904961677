import React from "react";

import Wrapper from "./Wrapper";
import { ErrorBoundary } from "../../components/Helpers/Error";

export default () => (
  <ErrorBoundary message="An error occurred">
    <Wrapper />
  </ErrorBoundary>
);
