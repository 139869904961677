import React, { useState, useEffect } from 'react';
import './CollapsibleItem.scss';

type CollapsibleItemProps = {
  AnchorChild: React.ReactElement;
  ExpandableChild: React.ReactElement;
  className: string;
  expandOnLoad: boolean;
  onToggle: (boolean) => void;
};

const CollapsibleItem = ({
  AnchorChild,
  ExpandableChild,
  className,
  id,
  expandOnLoad = false,
  onToggle,
}: CollapsibleItemProps) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => {
    setCollapsed((prev) => !prev);
  };

  useEffect(() => {
    if (onToggle) {
      onToggle(collapsed);
    }
  }, [collapsed]);

  useEffect(() => {
    if (expandOnLoad && collapsed) toggleCollapse();
  }, [expandOnLoad]);

  return (
    <div id={id} className={`collapsible-container ${className ? className : ''}`}>
      <div
        className={`anchor-child ${collapsed ? 'collapsed' : 'expanded'} ${
          !!ExpandableChild ? '' : 'no-expand'
        }`}
        onClick={toggleCollapse}
      >
        {AnchorChild}
      </div>
      {!collapsed && <div className={`expandable-child`}>{ExpandableChild}</div>}
    </div>
  );
};

export default CollapsibleItem;
