function shouldNormalize(action) {
  if (!action.type) {
    return false;
  }
  const { type, meta } = action;

  return type.includes('SET') && meta && meta.normalizeId;
}

function normalizeData(entity) {
  return {
    type: `[${entity}] NORMALIZE`,
    meta: { entity },
  };
}

function normalize(list, key) {
  const results = {};
  const L = list.length;

  for (let i = 0; i < L; i += 1) {
    const k = list[i][key];

    results[k] = list[i];
  }

  return results;
}

/**
 * @description Converts an array of objects
 * to an object based on the "normalizeId".
 *
 * ex: [{id: 1}, {id: 2}] -> { 1: {id: 1}, 2, {id: 2} }
 */
const middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (!shouldNormalize(action)) {
      next(action);
    } else {
      dispatch(normalizeData(action.meta.entity));

      const id = action.meta.normalizeId;
      const data = normalize(action.data, id);

      next({
        ...action,
        data,
        byIds: action.data.map((obj) => obj[id]),
        meta: { ...action.meta, normalizeId: null },
      });
    }
  };

export default middleware;
