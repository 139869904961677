import React, { useEffect, useState, useMemo } from 'react';
import './index.scss';
import {
  useTable,
  useSortBy,
  useFilters,
  useFlexLayout,
  useColumnOrder,
  useRowSelect,
} from 'react-table';
import useCategoryImportancesColumns from './columns';
import {
  getExportFileBlob,
  useExportData,
} from '../../../BulkActions/EventHistory/export';
import { ImportancesTable } from './ImportancesTable';

const CategoryImportances = ({ intelData, intelLoading, intelError }) => {
  const [importancesData, setImportancesData] = useState([]);

  useEffect(() => {
    if (intelData) {
      setImportancesData(intelData.importance);
    }
  }, [intelData]);

  const memoed = useMemo(
    () => ({ cols: useCategoryImportancesColumns(), rows: importancesData }),
    [importancesData]
  );

  if (intelLoading)
    return (
      <div className="m-5 d-flex justify-content-center w-100 text-center">
        <i className="fas fa-spinner fa-spin" style={{ fontSize: '1.2rem' }} />
      </div>
    );

  if (intelError) return <div>There was an error.</div>;

  return (
    <div className="category-importances mt-2">
      <ImportancesTable memoed={memoed} />
    </div>
  );
};

export default CategoryImportances;
