import { DARWIN_NAVY_2, DARWIN_NAVY_3 } from "../../../../constants";

export const NOW = new Date();
export const MINIMUM = new Date(NOW.setFullYear(NOW.getFullYear() - 15));

export const EMPTY_REPORT = {
  // id: "",
  name: "",
  status: "off",
  start_date: null,
  end_date: null,
  audience: null,
  excluded_labels: [],
  combined_category_names: [],
};

export const IR_SELECT_STYLES = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "#fff",
    border: "1px solid #DCE1ED",
    borderColor: "#DCE1ED",
    boxShadow: "none",
    ":hover": {
      boxShadow: "0px 0px 0px 1px #DCE1ED",
    },
    ":focus": {
      borderColor: "#00B3DB",
      boxShadow: "none",
    },
    cursor: "pointer",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    backgroundColor: "transparent",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: DARWIN_NAVY_3,
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    cursor: "pointer",
    fontSize: "1rem",
    color: DARWIN_NAVY_2,
    backgroundColor: isFocused ? "#F2F8FF" : "white",
    ":hover": {
      backgroundColor: "#F2F8FF",
    },
    borderRadius: "2px",
  }),
};

export const IR_MAIN_SELECT_STYLES = {
  ...IR_SELECT_STYLES,
  control: (styles) => ({
    ...styles,
    backgroundColor: "#fff",
    border: "1px solid transparent",
    borderColor: "transparent",
    boxShadow: "none",
    ":hover": {
      boxShadow: "0px 0px 0px 1px #DCE1ED",
    },
    ":focus": {
      borderColor: "#00B3DB",
      boxShadow: "none",
    },
    cursor: "pointer",
  }),
};

export const toggleStyle = {
  "& .MuiSwitch-switchBase": {
    "&+.MuiSwitch-track": {
      backgroundColor: "#808BA6",
    },
    "& .MuiSwitch-thumb": {
      color: "#fff",
    },
    "&.Mui-checked": {
      "&+.MuiSwitch-track": {
        backgroundColor: "#00B3DB60",
      },
      "& .MuiSwitch-thumb": {
        color: "#00B3DB",
      }
    },
    "&.Mui-disabled": {
      "&+.MuiSwitch-track": {
        backgroundColor: "#0000010",
      },
      "& .MuiSwitch-thumb": {
        color: "#f5f5f5",
      },
      "&.Mui-checked": {
        "& .MuiSwitch-thumb": {
          color: "#00B3DB20"
        }
      }
      
    }
  }
};

export const defaultToggleStyle = {
  ...toggleStyle,
  "& .MuiSwitch-switchBase.Mui-disabled": {
    "&+.MuiSwitch-track": {
      backgroundColor: "#00B3DB25",
      opacity: 1,
    },
    "& .MuiSwitch-thumb": {
      color: "#93dcec",
    }
  }
}
