import { batch } from 'react-redux';

import {
  Marketplace,
  MARKETPLACE,
  setMarketplaceProjects,
  marketplaceSetBulkOptions,
} from './actions';
import { setMarketplaceOptions, OPTIONS } from './Options/actions';

import { API_SUCCESS, apiRequest, API_FAILURE } from '../Api/actions';
import { setLoader } from '../Loaders/actions';
import { setError } from '../Errors/actions';

const PROJECTS = `${MARKETPLACE}.projects`;
const BULK_OPTIONS = `${MARKETPLACE}.bulkOptions`;

export function marketplaceMiddleware(store) {
  return (next) => (action) => {
    const result = next(action);

    switch (action.type) {
      case Marketplace.FETCH:
        return handleFetch(store, action);

      case Marketplace.FETCH_BULK_OPTIONS:
        return handleFetchBulkOptions(store, action);

      case `[${MARKETPLACE}.projects] ${API_FAILURE}`:
        return failedFetchProjects(store, action);

      case `[${MARKETPLACE}.projects] ${API_SUCCESS}`:
        return successfulFetchProjects(store, action);

      case `[${OPTIONS}] ${API_FAILURE}`:
        return failedFetchOptions(store, action);

      case `[${OPTIONS}] ${API_SUCCESS}`:
        return successfulFetchOptions(store, action);

      case `[${BULK_OPTIONS}] ${API_FAILURE}`:
        return failureBulkOptions(store, action);

      case `[${BULK_OPTIONS}] ${API_SUCCESS}`:
        return successBulkOptions(store, action);

      default:
        return result;
    }
  };
}

function handleFetch({ dispatch, getState }, _) {
  const state = getState();

  if (state.Loaders[MARKETPLACE]) {
    return console.log('pending marketplace request');
  }
  return batch(() => {
    dispatch(setLoader(PROJECTS, true));
    dispatch(setError(PROJECTS, null));
    dispatch(setLoader(OPTIONS, true));
    dispatch(setError(OPTIONS, null));
    dispatch(
      apiRequest({
        entity: PROJECTS,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Cache-control': 'no-cache',
        },
        url: ['api', 'marketplace'].join('/'),
      })
    );
    dispatch(
      apiRequest({
        entity: OPTIONS,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        url: ['api', 'marketplace', 'options'].join('/'),
      })
    );
  });
}

function failedFetchProjects({ dispatch }, { error }) {
  return batch(() => {
    dispatch(setError(PROJECTS, error));
    dispatch(setLoader(PROJECTS, false));
  });
}

function successfulFetchProjects({ dispatch }, { response }) {
  // Project listing response is streamed, which means it's not wrapped
  // with { entity: '', data: ... }
  return batch(() => {
    dispatch(setMarketplaceProjects(response));
    dispatch(setLoader(PROJECTS, false));
  });
}

function failedFetchOptions({ dispatch }, { error }) {
  return batch(() => {
    dispatch(setError(OPTIONS, error));
    dispatch(setLoader(OPTIONS, false));
  });
}

function successfulFetchOptions({ dispatch }, { response: { data } }) {
  return batch(() => {
    dispatch(setMarketplaceOptions({ data }));
    dispatch(setLoader(OPTIONS, false));
  });
}

/// / bulkOptions

function handleFetchBulkOptions(
  { dispatch, getState },
  { data: { project_id } }
) {
  const state = getState();

  if (state.Marketplace.bulkOptions) {
    return null;
  }

  const client_id = getClientId(state, project_id);

  if (!client_id) {
    return dispatch(
      setError(BULK_OPTIONS, new Error('No "client_id" provided.'))
    );
  }
  return batch(() => {
    dispatch(setError(BULK_OPTIONS, null));
    dispatch(setLoader(BULK_OPTIONS, true));
    dispatch(
      apiRequest({
        entity: BULK_OPTIONS,
        url: ['api', 'marketplace', project_id, 'bulk-options', client_id].join(
          '/'
        ),
        method: 'GET',
      })
    );
  });
}

function getClientId(
  {
    ViewSettings: { views, viewId },
    Marketplace: {
      projects: { lookup },
    },
  },
  project_id
) {
  const maybe = lookup[project_id];

  if (maybe) {
    return maybe.client_id;
  }
  const found = views.find(({ view_id }) => view_id === viewId);

  return found.client_id || '';
}

function successBulkOptions({ dispatch }, { response: { data } }) {
  return batch(() => {
    dispatch(marketplaceSetBulkOptions(data));
    dispatch(setLoader(BULK_OPTIONS, false));
  });
}

function failureBulkOptions({ dispatch }, { error }) {
  return batch(() => {
    dispatch(setError(BULK_OPTIONS, error));
    dispatch(setLoader(BULK_OPTIONS, false));
  });
}
