import { REPORTS_SET, REPORTS_RESET, REPORTS_RESET_P2 } from './actions';

export const reducer = (state = { reports: {}, report_type: '' }, action) => {
  switch (action.type) {
    case REPORTS_RESET:
      return {
        ...state,
        reports: {},
      };

    case REPORTS_RESET_P2:
      return {
        ...state,
        reports_p2: {},
      };

    case REPORTS_SET:
      if (action.data.reports) {
        return {
          ...state,
          reports: { ...state.reports, ...action.data.reports },
        };
      }
      return { ...state, ...action.data };

    default:
      return state;
  }
};

export default reducer;