export const REPORTS = 'CreativeReports';
export const REPORTS_FETCH = `[${REPORTS}] FETCH`;
export const REPORTS_SET = `[${REPORTS}] SET`;
export const REPORTS_RESET = `[${REPORTS}] RESET`;
export const REPORTS_P2 = 'CreativeReports_P2';
export const REPORTS_FETCH_P2 = `[${REPORTS_P2}] FETCH`;
export const REPORTS_SET_P2 = `[${REPORTS_P2}] SET`;
export const REPORTS_RESET_P2 = `[${REPORTS_P2}] RESET`;

export const fetchReports = (reportType = undefined, ignoreCheck = false, period = "one") => ({
  type: REPORTS_FETCH,
  data: { reportType, ignoreCheck, period },
  meta: { entity: REPORTS },
});

export const setReports = (data) => ({
  type: REPORTS_SET,
  data,
  meta: { entity: REPORTS },
});

export const resetReports = () => ({
  type: REPORTS_RESET,
  meta: { entity: REPORTS },
});

export const resetPeriod2 = () => ({
  type: REPORTS_RESET_P2,
  meta: { entity: REPORTS },
});
