import React, { useState, useEffect, useMemo } from 'react';

import {
  MULTIPLE_LABELS,
  MUTUALLY_EXCLUSIVE,
  INVALID_CHARACTERS,
} from '../../constants';

export const useInputState = () => {
  const [input, setInput] = useState({
    name: '',
    description: '',
    label_type: '',
    type: '',
    labels: [],
  });
  const [initialLabels, setInitialLabels] = useState([]);

  const labelsHaveChanged = useMemo(
    () => !_.isEqual(initialLabels, input.labels),
    [initialLabels, input.labels]
  );

  const handleInput = (field, value) => {
    if (field === 'initial_labels') {
      setInitialLabels((prev) => {
        return [...prev, value];
      });
    } else if (field === 'labels') {
      if (!value) return;
      setInput((prev) => {
        let existing = prev.labels || [];

        if (existing.some((label) => label === value)) {
          return prev;
        }

        return {
          ...prev,
          labels: [...existing, value],
        };
      });
    } else {
      setInput((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  const removeLabel = (label) => {
    setInput((prev) => {
      return {
        ...prev,
        labels: prev.labels.filter((curr) => curr !== label),
      };
    });
  };

  return [input, handleInput, removeLabel, labelsHaveChanged];
};

export const useSetupValidation = (
  input,
  existing,
  existingNames,
  labelInput
) => {
  let errors = [];
  let categoryInputErrors = [];
  let labelInputErrors = [];

  // validate label names
  if (labelInput.length) {
    if (existingNames.labels.includes(labelInput.toLowerCase())) {
      labelInputErrors.push(`${labelInput} is already in use, please choose a unique label
      name.`);
    }

    if (INVALID_CHARACTERS.some((c) => labelInput.includes(c))) {
      labelInputErrors.push(
        `Label names only allow the following special characters: ! ' - . * ( )`
      );
    }
  }

  // check if # of labels is valid
  if (input.name.length) {
    if (input.type === MUTUALLY_EXCLUSIVE) {
      if (input.labels.length < 2) {
        errors.push('Add two or more labels to continue.');
      }
    } else if (input.type === MULTIPLE_LABELS) {
      if (input.labels.length < 1) {
        errors.push('Add at least one label to continue.');
      } else if (input.labels.length > 4) {
        errors.push(
          'A maximum of four labels may be used for multiple labels categories.'
        );
      }
    }

    // check if category name is unique
    if (!existing || existing.category !== input.name) {
      if (existingNames.categories.includes(input.name.toLowerCase())) {
        errors.push(`${input.name} is already in use, please choose a unique category
        name.`);
        categoryInputErrors.push(`${input.name} is already in use, please choose a unique category
        name.`);
      }
    }

    // validate category name
    if (INVALID_CHARACTERS.some((c) => input.name.includes(c))) {
      errors.push(
        `Category names only allow the following special characters: ! ' - . * ( )`
      );
      categoryInputErrors.push(`Category names only allow the following special characters: ! ' - . * ( )`);
    }
    
  } else {
    errors.push('Please enter a name for the category.');
  }

  if (input.type.length < 1) {
    errors.push('Please choose a category type.');
  }

  return {
    errors,
    labelInputErrors,
    categoryInputErrors,
  };
};
