export const API_REQUEST = 'API_REQUEST';
export const API_SUCCESS = 'API_SUCCESS';
export const API_FAILURE = 'API_FAILURE';

/**
 * @param {{ body?: Object, headers?: Object, method: String, url: String, entity: String }} conf
 */
export const apiRequest = ({
  body,
  method,
  headers = { 'Content-Type': 'application/json' },
  url,
  entity,
  ...rest
}) => ({
  type: `[${entity}] ${API_REQUEST}`,
  body,
  meta: {
    method,
    headers,
    url,
    entity,
    ...rest,
  },
});

export const apiSuccess = (response, entity) => ({
  type: `[${entity}] ${API_SUCCESS}`,
  response,
  meta: { entity },
});

export const apiFailure = (error, entity) => ({
  type: `[${entity}] ${API_FAILURE}`,
  error,
  meta: { entity },
});
