import React, { useMemo, useState } from 'react';
import _isEqual from 'lodash/isEqual';
import Select from 'react-select';
import Tooltip from '../../../../../components/Tooltip';
import { DetailDataItem, SummaryDataItem } from '../../../types/types';
import { toCurrency } from '../../../../../utils/numbers';
import { Section } from '../../shared/Helpers';

import { useTable, useSortBy } from 'react-table';
import { CreativePerformance, SortToggle } from './CreativePerformance';
import IconButton from '../../../../../components/IconButton';
import { exportToCSV } from '../../../utils/export';
import { ShareReport } from '../../../../DNA/ShareReport';
import format from 'date-fns/format';
import { SELECT_STYLES } from '../../../../../constants';
import { DataMode, DATASET_MAPPING, getDataModeColumns } from './DataMode';
import { useAnalyticsUrlProps } from '../../../../CreativeAnalytics/PivotReport/hooks';
import { useSearchParams } from '../../../../../components/Hooks/search-params';
import {
  CREATIVE_DATA_CSV_EXPORT,
  REPORT_BUILDER,
} from '../../../../../store/UserActivity/constants';
import { useTrackEvent } from '../../../../CreativeAnalytics/hooks/useTrackEvent';
import { ColumnPicker } from '../../shared/ColumnPicker';
import DisplaySelect from './DisplaySelect';
import { useShareStaticDetailData } from '../../../hooks/useShareStaticDetailData';
import { VisualsByLabel } from './VisualsByLabel';
import { useSaveOverridesApi } from '../../../../CreativeAnalytics/Explorer/api';
import { useSelector } from 'react-redux';
import { OverridesSelect } from './Overrides';

export const SECTION_STYLE = {
  flex: 1,
  padding: '1rem',
  background: 'white',
  margin: '1rem',
  borderRadius: '5px',
  boxShadow: '3px 3px 2px #e4ebf4',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
};

const baseDetailCols = [
  {
    Header: 'Ad Name',
    accessor: 'ad_name',
    maxWidth: 200,
    Cell: ({ row }) => (
      <a
        href={row.original.s3_url}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 underline"
      >
        {row.original.ad_name}
      </a>
    ),
  },
  { Header: 'Ad Name Group', accessor: 'ad_name_group' },
  {
    Header: 'Total Spend',
    accessor: 'total_spend',
    Cell: ({ value }) => toCurrency(value),
  },

  {
    Header: 'Has Chosen Label',
    accessor: 'has_chosen_label',
    Cell: ({ value }) => (value ? 'Yes' : 'No'),
  },
  {
    Header: 'Has Comparison Label',
    accessor: 'has_comparison_label',
    Cell: ({ value }) => (value ? 'Yes' : 'No'),
  },
  {
    Header: 'Total CPC',
    accessor: 'total_cpc',
    Cell: ({ value }) => toCurrency(value),
  },
  {
    Header: 'CVR',
    accessor: 'CVR',
  },
  { Header: 'Total Link Clicks', accessor: 'total_link_clicks' },
];

const roasDetailCols = [
  {
    Header: 'ROAS',
    accessor: 'total_roas',
    Cell: ({ value }) => value?.toFixed(2) || value || '-',
  },
  {
    Header: 'Total Revenue',
    accessor: 'total_revenue',
    Cell: ({ value }) => toCurrency(value),
  },
];

const cpaClientDetailCols = [
  {
    Header: 'Total CPA',
    accessor: 'total_cpa',
    Cell: ({ value }) => toCurrency(value),
  },
  { Header: 'Total Results', accessor: 'total_results' },
];

const useDetailColumns = (isROAS: boolean = false) => {
  return useMemo(() => {
    if (isROAS) {
      return [...baseDetailCols, ...roasDetailCols];
    }

    return [...baseDetailCols, ...cpaClientDetailCols];
  }, []);
};

type DetailDataProps = {
  selectedRow: SummaryDataItem;
  detail_data: DetailDataItem[];
  columns: [];
  exportFileName: string;
};

const MODE_OPTS = [
  { label: 'Creative View', value: 'creative' },
  { label: 'Data View', value: 'data' },
];

const filterVisualsBySelectedRow =
  (selectedRow: SummaryDataItem) => (ad: DetailDataItem) => {
    try {
      if (!ad || !selectedRow) return false;

      const labelNameIsNone =
        !selectedRow.label_name || selectedRow.label_name === 'None';

      if (!labelNameIsNone) {
        return (
          ad.ad_name_group === selectedRow.ad_name_group &&
          ad.all_labels.includes(selectedRow.label_name)
        );
      } else {
        return ad.ad_name_group === selectedRow.ad_name_group;
      }
    } catch (e) {
      console.error('Error filtering visuals.');
      return false;
    }
  };

export const DetailData = ({
  selectedRow,
  detail_data,
  exportFileName,
  dateRange,
  isROAS,
  renderAnalyticsToggle,
  darwinIqApi,
  darwinIqData,
  mode,
  allowDataMode,
  visual_id_data,
}: DetailDataProps) => {
  const saveOverridesApi = useSaveOverridesApi();
  const account_id = useSelector((state) => state.ViewSettings.account_id);
  const [selectedLabel, setSelectedLabel] = useState<string | null>(null);
  const {
    dataset,
    columns: selectedColumnsParam,
    categories: selectedCategories,
  } = useAnalyticsUrlProps();
  const trackEvent = useTrackEvent();
  const { setParams } = useSearchParams();
  const setMode = (update) => setParams({ mode: update });
  const setDataset = (update: 'spend_based' | 'rank_based') =>
    setParams({ dataset: update });
  const selectedColumns = getDataModeColumns(
    dataset,
    selectedColumnsParam,
    isROAS
  );
  const {
    shouldShowShareModal,
    openShareModal,
    closeShareModal,
    submitShareReport,
    sharedReportId,
    loadingShareReport,
  } = useShareStaticDetailData(isROAS);

  const columns = useDetailColumns(isROAS);

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 200,
    }),
    []
  );
  const [sort, setSort] = useState({
    sortBy: 'total_spend',
    desc: true,
  });

  const { rows } = useTable(
    { columns, data: detail_data, defaultColumn },
    useSortBy
  );

  const colOpts = useMemo(() => {
    if (isROAS) {
      return [
        {
          label: 'Spend',
          value: 'total_spend',
        },
        {
          label: 'ROAS',
          value: 'total_roas',
        },
        {
          label: 'Total Revenue',
          value: 'total_revenue',
        },
        {
          label: 'CPC',
          value: 'total_cpc',
        },
      ];
    } else {
      return [
        {
          label: 'Spend',
          value: 'total_spend',
        },
        {
          label: 'CPA',
          value: 'total_cpa',
        },
        {
          label: 'Results',
          value: 'total_results',
        },
        {
          label: 'CPC',
          value: 'total_cpc',
        },
      ];
    }
  }, [isROAS]);
  const datasetConfig = DATASET_MAPPING[dataset] || {};

  const dataModeColumns = useMemo(() => datasetConfig.columns, [dataset]);

  const updateMetric = (metric) => {
    if (metric === 'roas') return 'total_roas';
    if (metric === 'cpa') return 'total_cpa';
    if (metric === 'cpc') return 'total_cpc';
    if (metric === 'spend') return 'total_spend';
    return metric || null;
  };

  const handleColumnChange = (selectedOptions) => {
    setParams({ columns: selectedOptions.join(',') });
  };

  const visibleColumns = useMemo(
    () =>
      !selectedColumns.length
        ? dataModeColumns
        : dataModeColumns.filter((col) =>
            selectedColumns.includes(col.accessor)
          ),
    [dataModeColumns, selectedColumns]
  );

  const visuals: DetailDataItem[] = useMemo(() => {
    return detail_data
      .filter(filterVisualsBySelectedRow(selectedRow))
      .sort((a, b) => {
        const metric = updateMetric(sort?.sortBy) ?? 'total_spend';
        const preferHigher = metric === 'total_roas';

        const aValue = a[metric] ?? null;
        const bValue = b[metric] ?? null;

        const aInvalid = isNaN(aValue) || aValue === 0;
        const bInvalid = isNaN(bValue) || bValue === 0;

        if (aInvalid && !bInvalid) return 1;
        if (bInvalid && !aInvalid) return -1;

        if (preferHigher) {
          return sort?.desc ? aValue - bValue : bValue - aValue;
        }

        return sort?.desc ? bValue - aValue : aValue - bValue;
      })
      .map((ad, index) => ({
        ...ad,
        rank: index + 1,
        isTopSpending: index === 0,
      }));
  }, [selectedRow, detail_data, sort]);

  const [selectedCategory, selectedLabelName] = selectedLabel?.split('->') ?? [
    null,
    null,
  ];
  const category_id = darwinIqData?.category_performance_data?.find(
    (c) => c.category_name === selectedCategory
  )?.['category_id'];
  const selectedCategoryData = darwinIqData?.category_details?.find(
    (x) => x.category_id === category_id
  );

  const data = darwinIqData?.[datasetConfig.field] || [];
  const defaultSort = datasetConfig.defaultSort || null;

  const dataByCategory = useMemo(() => {
    return new Map(
      selectedCategories.map((category_name) => [
        category_name,
        data.filter((label) => label.category_name === category_name),
      ])
    );
  }, [selectedCategories, data]);

  const handleOverride = ({ label, visual }) => {
    console.log({ label, visual });
    try {
      const category = selectedLabel?.split('->')[0];
      const category_id = darwinIqData.category_performance_data.find(
        (c) => c.category_name === category
      )?.['category_id'];
      const category_details_match = darwinIqData.category_details.find(
        (x) => x.category_id === category_id
      );
      const prevLabel = convertFormat1ToFormat2(selectedLabel);

      if (!category_id || !label || !visual) return;
      const payload = {
        hash_id: visual,
        category_id,
        account_id,
        assigned_labels: { [label]: prevLabel },
      };

      saveOverridesApi.request(payload);
      console.log({ payload });
    } catch (e) {
      console.error('Could not perform override');
      console.error(e);
    }
  };

  const visualsData = useMemo(() => {
    if (!darwinIqData || !selectedLabel) return [];

    const [selectedCategory = null, selectedLabelName = null] =
      selectedLabel?.split('->');
    const categoryData = dataByCategory.get(selectedCategory);

    if (!categoryData) return [];

    const labelData = categoryData.find(
      (label) => label.label_name === selectedLabelName
    );

    if (!labelData) return [];

    const ad_name_array = labelData.ad_name_array || [];
    return ad_name_array
      .map(
        (ad_name) =>
          darwinIqData?.ad_name_performance_data?.find(
            (y) => ad_name === y.ad_name
          )
      )
      .filter(Boolean);
  }, [darwinIqData, selectedLabel, dataByCategory]);

  const visualIdData = useMemo(() => {
    return visual_id_data?.map((obj) => {
      return {
        ...obj,
        array_of_ad_names: obj.array_of_ad_names || obj['Ad Name'],
      };
    });
  }, [visual_id_data]);

  const renderView = () => {
    if (mode === 'creative') {
      return (
        <CreativePerformance
          data={visuals}
          columns={[`total_${isROAS ? 'roas' : 'cpa'}`, 'total_spend']}
          exportCsv={() => exportToCSV(rows, exportFileName, [], isROAS)}
          sort={sort}
          setSort={setSort}
          columnOptions={colOpts}
          visual_id_data={visualIdData}
        />
      );
    } else if (mode === 'data') {
      return (
        <DataMode
          allowDataMode={allowDataMode}
          darwinIqData={darwinIqData}
          loading={darwinIqApi.loading}
          selectedColumns={selectedColumns}
          visibleColumns={visibleColumns}
          sort={sort}
          selectedRow={selectedRow}
          selectedLabel={selectedLabel}
          setSelectedLabel={setSelectedLabel}
          dataByCategory={dataByCategory}
        />
      );
    }
  };

  return (
    <div
      className="d-flex flex-column flex-lg-row"
      style={{ height: '100%', gap: '1rem', margin: '1rem' }}
    >
      <div
        style={{
          flex: 1,
          minWidth: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Section
          style={{
            ...SECTION_STYLE,
            margin: '1rem 0',
          }}
          title={
            <div
              style={{ flexWrap: 'wrap', gap: '6px' }}
              className="d-flex align-items-center justify-content-between mb-3"
            >
              <div className="d-flex align-items-center" style={{ flex: 1 }}>
                <h3 className="mr-3 mb-0" style={{ whiteSpace: 'nowrap' }}>
                  Detail Data
                </h3>
                <div style={{ marginRight: '1rem' }}>
                  {' '}
                  <Select
                    styles={{
                      ...SELECT_STYLES,
                      container: (provided) => ({
                        ...provided,
                        width: '100%',
                        minWidth: '200px',
                      }),
                    }}
                    options={MODE_OPTS}
                    value={MODE_OPTS.find((opt) => opt.value === mode) || null}
                    onChange={(selected) => {
                      setMode(selected.value);
                    }}
                  />
                </div>

                {mode === 'data' && (
                  <>
                    {' '}
                    <DisplaySelect display={dataset} setDisplay={setDataset} />
                    <div className="mx-2">
                      <ColumnPicker
                        columns={selectedColumns}
                        options={dataModeColumns
                          .filter((col) => col.accessor !== 'label_name')
                          .map((col) => ({
                            value: col.accessor,
                            label: col.Header,
                          }))}
                        onChange={({ column, checked }) => {
                          let update = [...selectedColumns];
                          if (checked) {
                            update = [...selectedColumns, column];
                          } else {
                            update = selectedColumns.filter(
                              (col) => col !== column
                            );
                          }
                          handleColumnChange(update);
                        }}
                      />
                    </div>
                  </>
                )}

                {mode === 'creative' && (
                  <Tooltip content="Export to CSV">
                    <div>
                      <IconButton
                        onClick={() => {
                          trackEvent(REPORT_BUILDER, CREATIVE_DATA_CSV_EXPORT);
                          exportToCSV(rows, exportFileName, [], isROAS);
                        }}
                      >
                        <i className="fa-solid fa-table"></i>
                      </IconButton>
                    </div>
                  </Tooltip>
                )}

                {mode === 'creative' && (
                  <Tooltip content="Export static">
                    <div className="ml-1">
                      <IconButton onClick={() => openShareModal()}>
                        <i className="fa-solid fa-share"></i>
                      </IconButton>
                    </div>
                  </Tooltip>
                )}
              </div>

              <div className="d-flex">
                <span className="mr-2">{renderAnalyticsToggle()}</span>
                <SortToggle {...{ sort, setSort }} />
              </div>
            </div>
          }
        >
          <div style={{ flex: 1, minHeight: 0, overflow: 'auto' }}>
            {renderView()}
          </div>
        </Section>
      </div>

      {!!selectedLabel && mode === 'data' && dataset === 'spend_based' && (
        <div
          style={{
            flex: 1,
            minWidth: 0,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Section style={{ ...SECTION_STYLE, margin: '1rem 0' }}>
            <div style={{ flex: 1, minHeight: 0 }}>
              <VisualsByLabel
                selectedLabel={selectedLabel}
                visualsData={visualsData}
                sort={sort}
                handleOverride={handleOverride}
                overridesSelect={(visual) => {
                  return (
                    <div>
                      <label>Override label to:</label>
                      <OverridesSelect
                        visual_id={visual.darwin_visual_id}
                        current={{
                          label: convertFormat1ToFormat2(selectedLabel),
                          value: convertFormat1ToFormat2(selectedLabel),
                        }}
                        options={selectedCategoryData?.category_labels?.map(
                          (label) => ({
                            label,
                            value: label,
                          })
                        )}
                        handleSaveOverride={handleOverride}
                        loading={saveOverridesApi.loading}
                      />
                    </div>
                  );
                }}
              />
            </div>
          </Section>
        </div>
      )}

      {!!shouldShowShareModal && (
        <ShareReport
          close={closeShareModal}
          submit={({ report_name, report_description, elementsToInclude }) =>
            submitShareReport({
              data: visuals,
              columns: [`total_${isROAS ? 'roas' : 'cpa'}`, 'total_spend'],
              sort,
              report_name,
              report_description,
              elementsToInclude,
              start_date: format(new Date(dateRange.start), 'yyyy-MM-dd'),
              end_date: format(new Date(dateRange.end), 'yyyy-MM-dd'),
              columnOptions: colOpts,
            })
          }
          sharedReportId={sharedReportId}
          loading={loadingShareReport}
        />
      )}
    </div>
  );
};

/**
 * Converts from Format 1 (category->label) to Format 2 (MM_category__label)
 * @param {string} format1 - String in Format 1 (e.g. "has offer->yes")
 * @returns {string} - String in Format 2 (e.g. "MM_Has Offer__yes")
 */
const convertFormat1ToFormat2 = (format1) => {
  if (!format1 || !format1.includes('->')) {
    throw new Error('Invalid Format 1 input. Expected "category->label"');
  }

  const [category, label] = format1.split('->');

  // capitalize first letter of each word
  const formattedCategory = category
    .trim()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return `MM_${formattedCategory}__${label.trim()}`;
};

/**
 * Converts from Format 2 (MM_category__label) to Format 1 (category->label)
 * @param {string} format2 - String in Format 2 (e.g. "MM_Has Offer__yes")
 * @returns {string} - String in Format 1 (e.g. "has offer->yes")
 */
const convertFormat2ToFormat1 = (format2) => {
  if (!format2 || !format2.startsWith('MM_') || !format2.includes('__')) {
    throw new Error('Invalid Format 2 input. Expected "MM_Category__label"');
  }

  const match = format2.match(/^MM_(.+)__(.+)$/);

  if (!match) {
    throw new Error('Invalid Format 2 pattern');
  }

  const category = match[1];
  const label = match[2];

  const formattedCategory = category.toLowerCase();

  return `${formattedCategory}->${label}`;
};
