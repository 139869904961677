import React from "react";

import Modal from "../BootstrapModal";
import { CircleCheck, CircleTimes } from "../SVG-Icons";

export function SuccessModal({ show, handleHide }) {
  return (
    <Modal
      id="create-study-success-modal"
      Title="Chart saved successfully"
      Body={
        <div className="d-flex justify-content-center align-items-center">
          <CircleCheck style={{ fontSize: "3rem" }} className="text-primary" />
        </div>
      }
      show={show}
      handleHide={handleHide}
      modalClass=""
    />
  );
}

export function FailureModal({ show, handleHide }) {
  return (
    <Modal
      id="create-study-failure-modal"
      Title="Failed to save chart"
      Body={
        <div className="d-flex justify-content-center align-items-center">
          <CircleTimes style={{ fontSize: "3rem" }} className="text-danger" />
        </div>
      }
      show={show}
      handleHide={handleHide}
      modalClass=""
    />
  );
}
