
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import { Loading } from '../Helpers/Loading';

// import { STANDARD_AD_LABELS } from './constants';
import useStandardLabelsOnMount, { StandardLabelType, StandardLabelAssetType } from './useStandardLabelsApi';
import { setLabels, partition } from './utils';

import { LABEL_CATEGORIES } from './constants';

import './LabelMatrix.style.scss';
import { useSearchParams } from '../Hooks/search-params';
import { containsHex } from '../../utils';

function useColumns(creativeRequest) {
  let colAmount = 5;
  let twoCol = false;

  if (!creativeRequest) {
    if (useMediaQuery({ query: '(max-width: 1400px)' })) {
      colAmount = 4;
    }
    if (useMediaQuery({ query: '(max-width: 1200px)' })) {
      colAmount = 3;
    }
    if (useMediaQuery({ query: '(max-width: 992px)' })) {
      colAmount = 2;
    }
  } else {
    colAmount = 2;
    twoCol = true;
  }

  return { colAmount, twoCol };
}

const LabelMatrix = ({
  data,
  standardLabels,
  handleSeriesClick,
  selectedId,
  showExisting,
  creativeRequest,
  addToRequest,
  getLabelData,
}) => {
  const { getParams } = useSearchParams();
  const { colAmount, twoCol } = useColumns(creativeRequest);

  const { ad_label_type } = getParams('ad_label_type') || { ad_label_type: '' };

  const labelData = setLabels(data, ad_label_type, standardLabels);

  useEffect(() => {
    if (creativeRequest) {
      getLabelData(labelData);
    }
  }, labelData);

  const rows = partition(labelData, colAmount);

  const handleSelect = (category) => {
    if (handleSeriesClick) {
      handleSeriesClick({ category });
    } else if (creativeRequest) {
      addToRequest(category);
    }
  };

  return (
    <div className={creativeRequest ? 'label-matrix-wrapper request-matrix' : 'label-matrix-wrapper'}>
      <div className={twoCol === true ? 'label-matrix two-col' : 'label-matrix'}>
        {rows.map((row, i) => (
          <div
            role="row"
            // eslint-disable-next-line react/no-array-index-key
            key={`label-matrix-row-${i}`}
            className="d-flex flex-row justify-content-start label-matrix-row"
          >
            {row.map(({
              value, disabled, label, description, example, rating,
            }) => (
              <Cell
                {...{
                  value, label, description, example, rating,
                }}
                key={value}
                selected={selectedId === value}
                handleSelect={handleSelect}
                disabled={!showExisting && !!disabled}
                creativeRequest={creativeRequest}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

LabelMatrix.defaultProps = {
  showExisting: true,
  style: { height: '100%', overflow: 'auto' },
  handleSeriesClick: null,
};

LabelMatrix.propTypes = {
  standardLabels: PropTypes.arrayOf(StandardLabelType).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSeriesClick: PropTypes.func,
  selectedId: PropTypes.string.isRequired,
  style: PropTypes.shape({ height: PropTypes.string, overflow: PropTypes.string }),
  showExisting: PropTypes.bool,
};

const LabelMatrixCategories = ({
  data,
  standardLabels,
  handleSeriesClick,
  selectedId,
  showExisting,
}) => {
  const { getParams } = useSearchParams();
  const { colAmount } = useColumns();

  const { ad_label_type } = getParams('ad_label_type') || { ad_label_type: '' };

  const labelData = setLabels(data, ad_label_type, standardLabels);

  const labelCategoryData = LABEL_CATEGORIES.map((category) => {
    return {
      name: category,
      rows: partition(labelData.filter((label) => label.category === category), colAmount),
    }
  })

  const handleSelect = (category) => {
    if (handleSeriesClick) {
      handleSeriesClick({ category });
    }
  };

  return (
    <div className="label-matrix-wrapper category-matrix">
      <div className="label-matrix">
        {labelCategoryData.map((category) => (
          <div className="label-matrix-category">
            <div className="label-matrix-category-header">{category.name}</div>
            <div className="label-matrix-category-labels">
              {category.rows.map((row, i) => (
                <div
                  role="row"
                  // eslint-disable-next-line react/no-array-index-key
                  key={`label-matrix-row-${i}`}
                  className="d-flex flex-row justify-content-start label-matrix-row"
                >
                  {row.map(({
                    value, disabled, label, description, example, rating,
                  }) => (
                    <Cell
                      {...{
                        value, label, description, example, rating,
                      }}
                      key={value}
                      selected={selectedId === value}
                      handleSelect={handleSelect}
                      disabled={!showExisting && !!disabled}
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

LabelMatrixCategories.defaultProps = {
  showExisting: true,
  style: { height: '100%', overflow: 'auto' },
  handleSeriesClick: null,
};

LabelMatrixCategories.propTypes = {
  standardLabels: PropTypes.arrayOf(StandardLabelType).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSeriesClick: PropTypes.func,
  selectedId: PropTypes.string.isRequired,
  style: PropTypes.shape({ height: PropTypes.string, overflow: PropTypes.string }),
  showExisting: PropTypes.bool,
};

function LabelMatrixContainer({
  data,
  handleSeriesClick,
  selectedId,
  style,
  showExisting,
  creativeRequest,
  addToRequest,
  getLabelData,
}) {
  const { getParams } = useSearchParams();
  const { status, standardLabels } = useStandardLabelsOnMount();
  const show_categories = getParams('show_categories').show_categories === 'true' || false;

  let Render = (
    <LabelMatrix {...{
      data,
      handleSeriesClick,
      selectedId,
      style,
      showExisting,
      creativeRequest,
      addToRequest,
      getLabelData,
      standardLabels,
    }}
    />
  );

  if (status === 'error') {
    return (
      <div className={creativeRequest ? 'label-matrix-wrapper request-matrix' : 'label-matrix-wrapper'}>
        An error occurred.
      </div>
    );
  } if (status === 'loading') {
    return (
      <div className={creativeRequest ? 'label-matrix-wrapper request-matrix' : 'label-matrix-wrapper'}>
        <Loading />
      </div>
    );
  } if (show_categories) {
    Render = (
      <LabelMatrixCategories {...{
        data,
        handleSeriesClick,
        selectedId,
        style,
        showExisting,
        addToRequest,
        getLabelData,
        standardLabels,
      }}
      />
    )
  }

  return Render;
}

LabelMatrixContainer.defaultProps = {
  showExisting: true,
  style: { height: '100%', overflow: 'auto' },
  handleSeriesClick: null,
};

LabelMatrixContainer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSeriesClick: PropTypes.func,
  selectedId: PropTypes.string.isRequired,
  style: PropTypes.shape({ height: PropTypes.string, overflow: PropTypes.string }),
  showExisting: PropTypes.bool,
};

export default LabelMatrixContainer;

function setLineheight(n) {
  if (n < 3) {
    return '';
  } if (n < 4) {
    return '1.5rem';
  }
  return '1rem';
}

function useLabelFormat(label) {
  if (!label.includes(' ')) {
    return ['', <>{label}</>];
  }
  const values = label.split(' ');

  const comp = values.slice(1).reduce((acc, v) => {
    acc.push(
      <React.Fragment key={v}>
        <br />
        {v}
      </React.Fragment>,
    );

    return acc;
  }, [values[0]]);

  return [setLineheight(values.length), comp];
}

function Cell({
  label,
  value,
  description,
  example,
  handleSelect,
  rating,
  selected,
  disabled,
}) {
  const [lineHeight, labelVal] = useLabelFormat(label);
  const [showLabel, setShowLabel] = useState(false);
  const [delayHandler, setDelayHandler] = useState(null);

  function handleShowLabel(setShowLabel) {
    setDelayHandler(setTimeout(() => {
      setShowLabel(true);
    }, 250));
  }

  function handleCloseLabel(setShowLabel) {
    clearTimeout(delayHandler);
    setShowLabel(false);
  }

  return (
    <>
      <div
        tabIndex={-1}
        role="button"
        onClick={() => handleSelect(value)}
        onMouseEnter={() => handleShowLabel(setShowLabel)}
        onMouseLeave={() => handleCloseLabel(setShowLabel)}
        className={`label-matrix-cell ${rating} ${
          selected ? `${rating}--selected` : ''
        }`}
        style={{ lineHeight }}
        onKeyPress={() => null}
      >
        {disabled && <div className="cell-disabled" />}
        <>
          {labelVal}
        </>
      </div>
      <div className={showLabel ? 'label-details-wrapper label-details-wrapper--open' : 'label-details-wrapper'}>
        <LabelDetails label={label} description={description} example={example} showLabel={showLabel} />
      </div>
    </>
  );
}

Cell.defaultProps = {
  description: '',
  example: {},
  selected: false,
  disabled: false,
};

Cell.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  description: PropTypes.string,
  example: StandardLabelAssetType,
  handleSelect: PropTypes.func.isRequired,
  rating: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
};

export function LabelDetails({
  label,
  description,
  example,
  showLabel,
  align,
  trending,
}) {
  const hex = containsHex(label);

  return (
    showLabel === true ? (
      <div className={`label-details ${align ? align : ''}`}>
        <div className="label-details-example">
          { example.type === 'image' && <img className="label-details-example__img" src={example.asset} alt="" /> }
          { example.type === 'video' && (
          <video className="label-details-example__img" autoPlay loop muted plays-inline="true">
            <source src={example.asset} type="video/mp4" />
          </video>
          )}
        </div>
        <div className="label-details-summary">
          <h3 className="label-details-summary__name">
            {label}
            {hex.match && <HexPreview color={hex.hexCode} />}
            {trending === true && <span className="label-details-summary__trending">TRENDING</span>}
          </h3>
          {description
            ? <p className="label-details-summary__desc">{description}</p>
            : <p className="label-details-summary__desc">No description available.</p>
          }
          {trending === true && (
            <p className="label-details-summary__trending-desc">
              This ad label is performing well across the Darwin Index.
            </p>
          )}
        </div>
      </div>
    ) : (
      <></>
    )
  );
}

LabelDetails.defaultProps = {
  description: '',
  example: {},
  showLabel: false,
  align: '',
};

LabelDetails.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  example: PropTypes.shape({
    type: PropTypes.string,
    asset: PropTypes.string,
  }),
  showLabel: PropTypes.bool,
  align: PropTypes.string,
};

const HexPreview = ({ color }) => {
  return (
    <>
      &nbsp;&nbsp;<div className="label-details-summary__color" style={{ background: color }}/>
    </>
  )
}