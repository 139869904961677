import React from 'react';
import { useCreativeAnalyticsGet } from '../../contexts';

export const usePreprocess = () => {
  const { clustersApi } = useCreativeAnalyticsGet();
  const {
    save: {
      loading: preprocessLoading,
      error: preprocessError,
      data: preprocessData,
    },
  } = clustersApi;
  const preprocessSuccess = preprocessData && preprocessData?.status === 200;

  return [
    preprocessLoading,
    preprocessError,
    preprocessData,
    preprocessSuccess,
  ];
};

export default usePreprocess;
