import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DateSpan from '../DateSpanDropdown';
import {
  DATE_PAIRS,
  toDarwinDate,
  darwinDateToDate,
  START_30,
  END,
} from '../../utils/darwin-dates';
import { useSearchParams } from '../Hooks/search-params';
import { setViewSettings } from '../../store/ViewSettings/actions';

function Picker({ setDates, isDirectMail, toggleStyle, toggleClass }) {
  const { setParams, getParams } = useSearchParams();

  const DEFAULT_START = isDirectMail ? new Date('January 1, 2010') : START_30;

  const { start, end } = getParams('start', 'end');
  const start_date = start ? darwinDateToDate(start) : DEFAULT_START;
  const end_date = end ? darwinDateToDate(end) : END;

  const handleDateChange = ([start_date, end_date]) => {
    setParams(
      {
        start: toDarwinDate(start_date),
        end: toDarwinDate(end_date),
      },
      () => setDates({ start_date, end_date }),
    );
  };

  return (
    <DateSpan
      startDate={start_date}
      endDate={end_date}
      handleChange={handleDateChange}
      dateRanges={DATE_PAIRS}
      toggleStyle={toggleStyle}
      toggleClass={toggleClass}
    />
  );
}

Picker.propTypes = {
  setDates: PropTypes.func.isRequired,
};

const stateToProps = ({ ViewSettings: { isDirectMail }}) => ({
  isDirectMail,
});

const dispatchToProps = (dispatch, props) => ({
  setDates: ({ start_date, end_date }) => dispatch(setViewSettings({ start_date, end_date, resetStore: true })),
});

export const ViewDatePicker = connect(stateToProps, dispatchToProps)(Picker);
