import { useEffect, useReducer, useMemo } from 'react';

export const ADDITIONAL_TOGGLE = 'TOGGLE_SHOW_ADDITIONAL_CATEGORIES';
export const COMPLIANCE_TOGGLE = 'TOGGLE_SHOW_COMPLIANCE_CATEGORIES';
export const ONLY_COMPLIANCE_TOGGLE = 'TOGGLE_SHOW_ONLY_COMPLIANCE_CATEGORIES';
export const TESTING_OPPS_TOGGLE = 'TOGGLE_SHOW_TESTING_OPPORTUNITIES';
export const INCLUDE_UDC = 'INCLUDE_UDC';
export const HIDE_UDC = 'HIDE_UDC';

const getInitial = (mode, fromCreativeStudio) => {
  return {
    showAdditionalCategories: mode === 'library' || fromCreativeStudio || false,
    showComplianceCategories: mode === 'library' || fromCreativeStudio || false,
    showOnlyComplianceCategories: false,
    showTestingOpportunities: mode === 'library' || fromCreativeStudio || false,
    includeUdc: true,
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case ADDITIONAL_TOGGLE:
      return {
        ...state,
        showAdditionalCategories: !state.showAdditionalCategories,
      };
    case COMPLIANCE_TOGGLE:
      return {
        ...state,
        showComplianceCategories: !state.showComplianceCategories,
      };
    case ONLY_COMPLIANCE_TOGGLE:
      return {
        ...state,
        showOnlyComplianceCategories: !state.showOnlyComplianceCategories,
      };
    case TESTING_OPPS_TOGGLE:
      return {
        ...state,
        showTestingOpportunities: !state.showTestingOpportunities,
      };
    case INCLUDE_UDC:
      return {
        ...state,
        includeUdc: true,
      };
    case HIDE_UDC:
      return {
        ...state,
        includeUdc: false,
      };
    default:
      return state;
  }
};

const useCategoryFilters = (mode, fromCreativeStudio) => {
  const [
    {
      showAdditionalCategories,
      showComplianceCategories,
      showOnlyComplianceCategories,
      showTestingOpportunities,
      includeUdc,
    },
    dispatchCategoryFilter,
  ] = useReducer(reducer, getInitial(mode, fromCreativeStudio));

  const categoryFilters = useMemo(
    () => ({
      showAdditionalCategories,
      showComplianceCategories,
      showOnlyComplianceCategories,
      showTestingOpportunities,
      includeUdc,
    }),
    [
      showAdditionalCategories,
      showComplianceCategories,
      showOnlyComplianceCategories,
      showTestingOpportunities,
      includeUdc,
    ]
  );

  return {
    categoryFilters,
    dispatchCategoryFilter,
  };
};

export default useCategoryFilters;
