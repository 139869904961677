import { useEffect, useMemo, useState } from 'react';
const _ = require('lodash');
import { LabelObject } from '../types';
import { MULTIPLE_LABELS, MUTUALLY_EXCLUSIVE } from './constants';

export const useCustomCategories = (data: LabelObject[], category) => {
  const isNew = category.category === 'custom';
  const [stage, setStage] = useState(isNew ? 0 : 1);
  const [ccc_type, setCCCType] = useState(category.ccc_type);
  const [dropdownOptions, setDropdownOptions] = useState([]);

  useEffect(() => {
    if (data) {
      setDropdownOptions(() => {
        return data.map(({ name, value }) => {
          return {
            label: name,
            value,
          };
        });
      });
    }
  }, [data]);

  useEffect(() => {
    setStage(isNew ? 0 : 1);
    setCCCType(category.ccc_type);
  }, [category.category]);

  const next = () =>
    setStage((prev) => {
      if (prev < 2) return prev + 1;
    });
  const prev = () =>
    setStage((prev) => {
      if (prev > 0) return prev - 1;
    });

  return [stage, next, prev, dropdownOptions, ccc_type, setCCCType, isNew];
};

export const useCustomCategoriesEditor = (
  input,
  annotatedVisuals,
  predictedVisuals,
  visualsData,
  labelOptions
) => {
  let visuals = {};

  if (visualsData) {
    visuals = Object.assign({}, visualsData);
  }

  try {
    Object.entries(visuals).forEach(([visual_hash, val]) => {
      let confidence = null;
      let labels = null;

      if (predictedVisuals && predictedVisuals[visual_hash]) {
        if (input.type === MUTUALLY_EXCLUSIVE) {
          const currentP = predictedVisuals[visual_hash];
          const currentV = visuals[visual_hash];

          currentP.asset.forEach((p) => {
            confidence = p.confidence;
            labels = p.labelName;

            currentV.asset.forEach((v, i) => {
              if (currentV.type?.toLowerCase() === 'video') {
                if (
                  v.frame_num === p.frame_num ||
                  v.frame_num.toString() === p.frame_num.toString()
                ) {
                  Object.assign(
                    { labels, confidence },
                    visuals[visual_hash].asset[i]
                  );
                }
              } else if (currentV.type?.toLowerCase() === 'image') {
                Object.assign(
                  { labels, confidence },
                  visuals[visual_hash].asset[i]
                );
              }
            });
          });
          Object.assign({ spend: val.spend }, visuals[visual_hash]);
        } else if (input.type === MULTIPLE_LABELS) {
          const currentPredicted = predictedVisuals[visual_hash];
          const currentVisual = visuals[visual_hash];

          currentPredicted.asset.forEach((p) => {
            currentVisual.asset.forEach((v, i) => {
              if (currentVisual.type?.toLowerCase() === 'video') {
                if (
                  v.url === p.url ||
                  v.frame_num === p.frame_num ||
                  (v.frame_num && v.frame_num.toString() === p.frame_num)
                ) {
                  labels = p.labels;
                  Object.assign({ labels }, visuals[visual_hash].asset[i]);
                }
              } else if (currentVisual.type?.toLowerCase() === 'image') {
                labels = p.labels;
                Object.assign({ labels }, visuals[visual_hash].asset[i]);
              }
            });
          });
          Object.assign({ spend: val.spend }, visuals[visual_hash]);
        }
      }
    });
  } catch (e) {
    console.error(e);
  }

  let labelCounter = {};
  let isValid = true;

  if (labelOptions) {
    if (input.type === MUTUALLY_EXCLUSIVE) {
      labelOptions.forEach((label) => {
        const count = getLabelCount(label, annotatedVisuals, input.type);

        labelCounter[label] = {
          label,
          count,
        };
      });

      const numAnnotated = Object.entries(labelCounter).reduce(
        (total, [key, val]) => (val.count >= 2 ? (total += 1) : total),
        0
      );
      if (numAnnotated < 2) isValid = false;
    } else if (input.type === MULTIPLE_LABELS) {
      let numAnnotated = 0;

      labelOptions.forEach((label) => {
        const yesLabel = `${label} - Yes`;
        const noLabel = `${label} - No`;

        let yesCount = 0;
        let noCount = 0;

        Object.entries(annotatedVisuals).forEach(([key, val]) => {
          val.asset.forEach((frame) => {
            if (!frame.labels) return;
            Object.entries(frame.labels).forEach(([labelName, opt]) => {
              let annotation = opt?.status || opt || null;
              const hasPrediction = !!opt?.confidence;

              if (hasPrediction) annotation = null;

              if (labelName === label && annotation === 'yes') {
                yesCount += 1;
              }
              if (labelName === label && annotation === 'no') {
                noCount += 1;
              }
            });
          });
        });

        labelCounter[yesLabel] = {
          label: yesLabel,
          count: yesCount,
        };
        labelCounter[noLabel] = {
          label: noLabel,
          count: noCount,
        };
      });

      let labelArr = [];

      for (const key in labelCounter) {
        labelArr.push(
          Object.assign(labelCounter[key], { name: key.split(' - ')[0] })
        );
      }

      const grouped = _.mapValues(_.groupBy(labelArr, 'name'));
      Object.keys(grouped).forEach((key) => {
        if (grouped[key][0]['count'] >= 2 && grouped[key][1]['count'] >= 2) {
          numAnnotated += 1;
        }
      });

      if (numAnnotated < 1) isValid = false;
    }
  }

  return [visuals, labelCounter, isValid];
};

const getLabelCount = (label, annotatedVisuals, type) => {
  let count = 0;

  if (type === MUTUALLY_EXCLUSIVE) {
    Object.entries(annotatedVisuals).forEach(([key, val]) => {
      if (!Array.isArray(val.asset)) return;
      val.asset.forEach((frame) => {
        if (frame.labels === label) count += 1;
      });
    });
  } else if (type === MULTIPLE_LABELS) {
    Object.entries(annotatedVisuals).forEach(([key, val]) => {
      Object.entries(val.labels).forEach(([labelName, opt]) => {
        if (labelName === label && opt === 'yes') {
          count += 1;
        }
      });
    });
  }

  return count;
};
