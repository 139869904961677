import React from "react";

export const Rank = ({ rank, outOf }) => {
  const value =
    nonValue(rank) || nonValue(outOf)
      ? "Rank Unknown"
      : `Rank ${rank} of ${outOf}`;

  return <div className="zone-content__rank">{value}</div>;
};

const nonValue = n => n === 0 || n === null || n === undefined;

export const ChevronUp = ({ style, className = "creative-tooltip" }) => (
  <i className={`icon mdi mdi-chevron-up ${className}`} style={style}></i>
);

export const ChevronDown = ({ style, className = "" }) => (
  <i
    className={`fa fa-chevron-down creative-tooltip-2 ${className}`}
    style={style}
  ></i>
);
export const ChevronLeft = ({ style }) => (
  <i className="icon mdi mdi-chevron-left" style={style}></i>
);

export const ChevronRight = ({ style }) => (
  <i className="icon mdi mdi-chevron-right" style={style}></i>
);

export const HelpIcon = ({
  style,
  className = "creative-tooltip",
  ...props
}) => (
  <i className={`fa fa-info-circle ${className}`} style={style} {...props} />
);

const CHEVRON_STYLE = { fontSize: "1.2rem" };
export const SizedChevronDown = () => (
  <ChevronDown className="padding-2-6-2-2" style={CHEVRON_STYLE} />
);
