import { useState, useLayoutEffect, useCallback } from 'react';

/**
 * @description https://github.com/Swizec/useDimensions/blob/master/src/index.ts
 * @param {{ liveMeasure: Boolean}=} conf
 *
 * @return {[Object, { height: Number, width: Number}, Object]}
 */
export const useDimensions = (conf = { liveMeasure: true }) => {
  const [state, setState] = useState({ height: 0, width: 0 });
  const [node, setNode] = useState(null);

  const ref = useCallback((aNode) => setNode(aNode), []);

  useLayoutEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() => {
          const data = node.getBoundingClientRect();

          // Prevent setting state if DOM node is not rendered.
          if (
            data.x === 0 &&
            data.y === 0 &&
            data.height === 0 &&
            data.width === 0
          ) {
            return null;
          }
          return setState(data);
        });

      measure();

      if (conf.liveMeasure) {
        window.addEventListener('resize', measure);
        window.addEventListener('scroll', measure);

        return () => {
          window.removeEventListener('resize', measure);
          window.removeEventListener('scroll', measure);
        };
      }
    }
  }, [node]);

  return [ref, state, node];
};
