import { toFixed_2 } from '../../../utils/numbers';
import {
  LABEL_CATEGORIES,
  NAV_CATEGORIES,
  CATEGORY_ORDERS,
  ALL_VISUALS,
  ALL_ADS,
  PHRASES,
  STANDARD_CATEGORIES_LOOKUP,
} from './../categories';
import { useCreativeAnalyticsGet } from '../contexts';
import { useCreativeAnalyticsStore } from '../../../selectors';
import { useSelector } from 'react-redux';
import { useCategoriesLookup } from '../selectors';

const getCategoryLabel = (category) => {
  const match = NAV_CATEGORIES.find(({ value }) => value === category);
  if (match) return match.label;
  return category;
};

const sortCategoryLabels = (category, data = []) => {
  const order = CATEGORY_ORDERS[category];

  if (!order) return data;

  return [...data].sort((a, b) => {
    if (order.indexOf(a.value) < 0) return 1;
    if (order.indexOf(b.value) < 0) return -1;
    return order.indexOf(a.value) - order.indexOf(b.value);
  });
};

const labelIsHidden = (label, hiddenLabels = []) => {
  return hiddenLabels.some(
    (x) => label.name === x.label && label.category === x.category
  );
};

const useCategories = (specifiedCategory = null, isCompare) => {
  const { categoryFilters, reportCardSettings, filteredData, period2 } =
    useCreativeAnalyticsGet();
  const categoriesLookup = useCategoriesLookup();
  const { hiddenLabels } = useCreativeAnalyticsStore();
  const labelsLookup = useSelector(
    ({
      CreativeAnalytics: {
        labels: { lookup },
      },
    }) => lookup
  );
  let navCategories = [];
  let tableData = [];
  let rows = [];
  let categoryLabels = [];

  if (filteredData.length < 1) {
    return {
      data: [],
      categories: [],
    };
  }

  const maybe = reportCardSettings?.categorySettings || [];
  let update = [...maybe.filter((x) => x.category_name !== 'All Visuals')];

  const categorySettings = [
    ...[
      {
        category_name: 'All Visuals',
        enabled: { report_card: true, recommendations: true },
      },
    ],
    ...update,
  ];

  categorySettings.forEach((curr) => {
    const category = curr.category_name;
    const match = categoriesLookup?.[category];

    const shouldInclude =
      curr.enabled.report_card || categoryFilters.showAdditionalCategories;

    if (shouldInclude) {
      if (match) {
        const name = getCategoryLabel(category);
        let labels = sortCategoryLabels(
          category,
          match.labels.map((label) => ({
            ...label,
            ...labelsLookup.get(label.id),
          }))
        ).filter((label) =>
          !categoryFilters.showTestingOpportunities && !period2
            ? !label.testing_opportunity
            : label
        );

        const labelsNames = labels.map(({ name }) => name);
        const compliance = ['No - Not Compliant', 'Yes - Compliant'];
        const isCompliance = _.isEqual(labelsNames.sort(), compliance);
        const isPhrases = name.toLowerCase() === 'phrases';
        const editable = (match.ccc || isCompliance) && !isPhrases;

        if (specifiedCategory && category === specifiedCategory) {
          categoryLabels = labels;
        }

        labels = labels.filter((label) => !labelIsHidden(label, hiddenLabels));

        tableData.push({
          category_name: category,
          category_data: match,
          labels,
        });

      if (labels.length > 0) {
        rows = [
          ...rows,
          {
            name,
            category_id: match.category_id || '',
            categoryHeader: true,
            editable,
            synonym: match.synonym,
            udc: match.udc,
            ccc: match.ccc,
            derived: match.derived,
            ad_name_label: match.ad_name_label,
          },
          ...labels,
        ];

          navCategories.push({
            label: STANDARD_CATEGORIES_LOOKUP[category] || category,
            value: STANDARD_CATEGORIES_LOOKUP[category] || category,
            labels,
          });
        }
      } else {
      }
    }
  }, []);

  return {
    data: rows,
    navCategories,
    categoryLabels,
    tableData,
    categoryFilters,
    reportCardSettings,
    filteredData,
    categoriesLookup,
    period2,
    specifiedCategory,
  };
};

export default useCategories;
