import { batch } from 'react-redux';
import { API_SUCCESS, apiRequest, API_FAILURE } from '../Api/actions';
import { addNotification } from '../Notifications/actions';

export const BOOST = 'Ad-Boost';

export const BOOST_FETCH = `[${BOOST}] FETCH`;

export const fetchAdBoost = ({
  ad_name,
  adset_name,
  budget,
  adset_id,
  campaign_id,
  viewId,
}) => ({
  type: BOOST_FETCH,
  data: {
    viewId,
    ad_name,
    adset_name,
    budget,
    adset_id,
    campaign_id,
  },
});

export const adBoostMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case BOOST_FETCH: {
        const {
          ViewSettings: { campaigns, account_id, viewId },
        } = getState();
        const {
          data: { ad_name, adset_name, budget, adset_id, campaign_id },
        } = action;

        batch(() => {
          dispatch(
            apiRequest({
              body: {
                ad_name,
                adset_name,
                budget,
                adset_id,
                campaign_id,
                campaigns,
                account_id,
              },
              url: ['api', viewId, 'boost-ad'].join('/'),
              entity: BOOST,
              method: 'POST',
            })
          );

          dispatch(
            addNotification(
              {
                id: new Date().getMilliseconds(),
                status: 'info',
                message: 'Boosting Ad',
              },
              BOOST
            )
          );
        });

        break;
      }

      case `[${BOOST}] ${API_SUCCESS}`: {
        const { response } = action;

        dispatch(
          addNotification(
            {
              id: new Date().getMilliseconds(),
              status: 'success',
              message: response.body,
            },
            BOOST
          )
        );

        break;
      }

      case `[${BOOST}] ${API_FAILURE}`: {
        const { error } = action;

        dispatch(
          addNotification(
            {
              id: new Date().getMilliseconds(),
              status: 'danger',
              message: error.body,
            },
            BOOST
          )
        );

        break;
      }

      default:
        return result;
    }

    return result;
  };
