import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  // SHOW_NOTIFICATION
} from './actions';

const reducer = (state = [], action) => {
  if (action.meta && action.meta.entity) {
    const { entity } = action.meta;

    switch (action.type) {
      case `[${entity}] ${ADD_NOTIFICATION}`: {
        const { message, delay, status, title, id } = action;

        return [
          ...state,
          {
            message,
            delay,
            status,
            entity,
            title,
            id,
          },
        ];
      }

      case `[${entity}] ${REMOVE_NOTIFICATION}`: {
        return state.filter(({ id }) => id !== action.id);
      }

      default:
        return state;
    }
  } else {
    return state;
  }
};

export default reducer;
