//@ts-check
import React, { useEffect, useState } from "react";

import CustomDropdown from "../CustomDropdown";
import { useSearchParams } from "../Hooks/search-params";

const OPTIONS = [
  { label: "All", value: "" },
  { label: "Audience", value: "audience" },
  { label: "Creative", value: "creative" },
];

export function StudyTypePicker() {
  let { initialIndex, setType } = useParams();
  let [index, setIndex] = useState(initialIndex);

  useEffect(() => {
    setType(OPTIONS[index].value);
  }, [index]);

  return (
    <CustomDropdown
      label={`Study Type ${OPTIONS[index].label}`}
      onSelect={(i) => i !== index && setIndex(i)}
    >
      {OPTIONS.map(({ label, value }, i) => (
        <CustomDropdown.Item key={value} eventKey={i.toString()}>
          {label}
        </CustomDropdown.Item>
      ))}
    </CustomDropdown>
  );
}

function useParams() {
  let { setParams, getParams } = useSearchParams();
  let { study_type } = getParams("study_type") || { study_type: "" };
  const found = OPTIONS.findIndex(({ value }) => value === study_type);

  return {
    initialIndex: found > -1 ? found : 0,
    setType: (type) => setParams({ study_type: type }),
  };
}
