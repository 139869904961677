import React, { useRef, useState } from 'react';
import { useInputState } from './AIDriven/hooks';

import Editor from './AIDriven/Editor';

import { LabelObject } from '../../types';
import { useCustomCategories } from '../hooks';
import {
  useCreativeAnalyticsGet,
  useCreativeAnalyticsSet,
} from '../../contexts';
import { MULTIPLE_LABELS, MUTUALLY_EXCLUSIVE } from '../constants';

import {
  useDarwinClientId,
  useDates,
  usePlatform,
  useViewId,
} from '../selectors';
import { useCustomCategoriesApi } from '../api';
import { makeFetchPayload } from '../payloads';
import { useEffect } from 'react';
import { useAnalyticsParams, useDatePeriods } from '../../hooks';
import { fillMissingFrames, makeVisualsObject } from './AIDriven/utils';

type CustomCategoriesProps = {
  data: LabelObject[];
  category: string;
  visual: string;
  fetchPerformanceData: any;
};

const CustomSingle = ({
  data,
  category,
  category_id,
  visual,
  fetchPerformanceData,
  singleToFull,
}: CustomCategoriesProps) => {
  const { allVisualsList } = useCreativeAnalyticsGet();
  const { setEditCategoryVisual } = useCreativeAnalyticsSet();
  const [stage, next, prev, dropdownOptions, ccc_type, setCCCType, isNew] =
    useCustomCategories(data, category);
  const [allVisuals, setAllVisuals] = useState({});
  const [input, handleInput] = useInputState();
  const [existing, setExisting] = useState(null);
  const [annotatedVisuals, setAnnotatedVisuals] = useState(
    existing ? existing.annotated_visuals : {}
  );
  const [predictedVisuals, setPredictedVisuals] = useState(
    existing ? existing.predicted_visuals : {}
  );
  const ref = useRef();
  const api = useCustomCategoriesApi();
  const darwin_client_id = useDarwinClientId();
  const view_id = useViewId();
  const platform = usePlatform();
  const [{ period1, period2 }] = useAnalyticsParams();
  const [period1Start, period1End, period2Start, period2End] = useDatePeriods(
    period1,
    period2
  );

  const visualsData = makeVisualsObject(
    allVisualsList?.filter((v) => v.id === visual) ?? []
  );

  const fetch = (category, category_id) => {
    api.get.request(
      makeFetchPayload({
        category_name: category,
        category_id,
        platform,
        darwin_client_id,
        view_id,
        period1Start,
        period1End,
      })
    );
  };

  useEffect(() => {
    fetch(category, category_id);
  }, [category]);

  useEffect(() => {
    if (existing) {
      const { annotated_visuals, predicted_visuals } = fillMissingFrames(
        existing,
        visualsData
      );
      setAnnotatedVisuals(annotated_visuals);
      setPredictedVisuals(predicted_visuals);
    }
  }, [existing]);

  useEffect(() => {
    if (api.get.data) {
      const { data } = api.get;
      const combined = {
        ...data.annotated_visuals,
        ...data.predicted_visuals,
      };

      setExisting(data);
      setAllVisuals(data.visuals);
    }
  }, [api.get.data]);

  useEffect(() => {
    if (existing) {
      handleInput('name', existing.category);
      handleInput(
        'type',
        existing.mutually_exclusive ? MUTUALLY_EXCLUSIVE : MULTIPLE_LABELS
      );

      if (existing.output_label_info) {
        Object.keys(existing.output_label_info).map((label) => {
          handleInput('labels', label);
        });
      }
    }
  }, [existing]);

  if (api.get.loading) {
    return (
      <div className="category-manager category-manager--loading">
        <p className="category-manager--loading__msg loading-text">
          Fetching category data
        </p>
      </div>
    );
  }

  if (!visualsData) {
    return (
      <div className="category-manager category-manager--loading">
        <p className="category-manager--loading__msg">
          There was an error fetching the data.
        </p>
      </div>
    );
  }

  return (
    <div className={`category-manager custom-categories stage2`} ref={ref}>
      <Editor
        {...{
          prev,
          existing,
          fetch,
          fetchPerformanceData,
          visualsData,
          visual,
          category,
          allVisuals,
          annotatedVisuals,
          setAnnotatedVisuals,
          predictedVisuals,
          setPredictedVisuals,
          category_id,
          singleToFull,
        }}
        input={input}
        isSingle
      />
    </div>
  );
};

export default CustomSingle;
