import React from 'react';

import DarwinRobotIcon from '../../components/Icons/DarwinRobotIcon';

const robotStyles = {
  position: 'absolute',
  top: -20,
  right: -40,
};

export const steps = [
  {
    target: '#page-content-wrapper',
    content: (
      <div>
        <h5>Woah, Darwin got a facelift!</h5>
        <div>Follow along to see where everything is.</div>
        <div style={robotStyles}>
          <DarwinRobotIcon width={88} height={66} />
        </div>
      </div>
    ),
    disableBeacon: true,
    placement: 'center',
    styles: {
      tooltip: {
        arrow: 'none',
      },
    },
  },
  {
    target: '#left-menu',
    content: (
      <div>
        <div>
          Toggle between your Analytics, Roadmap, and Studio modules here.
        </div>
        <div style={robotStyles}>
          <DarwinRobotIcon width={88} height={66} />
        </div>
      </div>
    ),
    placement: 'right-start',
    styles: {
      tooltip: {
        top: 20,
      },
    },
  },
  {
    target: '.tab-list-wrapper',
    content: (
      <div>
        <div>
          These tabs allow you to toggle between your global view, your
          platforms and your campaigns.
        </div>
        <div style={robotStyles}>
          <DarwinRobotIcon width={88} height={66} />
        </div>
      </div>
    ),
    placement: 'bottom-start',
    styles: {
      tooltip: {
        left: 80,
      },
    },
  },
  {
    target: '.segments',
    content: (
      <div>
        <div>Ready to jump in?</div>
        <div>Select a segment to get started.</div>
        <div style={robotStyles}>
          <DarwinRobotIcon width={88} height={66} />
        </div>
      </div>
    ),
    placement: 'right',
  },
];
