import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const Root = document.getElementById('modal-root');

const Portal = ({ children }) => {
  const el = document.createElement('div');

  useEffect(() => {
    Root.appendChild(el);

    return () => Root.removeChild(el);
  }, []);


  return ReactDOM.createPortal(children, el);
}

Portal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Portal;
