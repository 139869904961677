import INITIAL_STATE from '../initial-state';
import {
  CREATE_AD_SET_AD,
  CREATE_AD_SET_CAROUSEL,
  UPDATE_ELEMENT,
  APPEND_ELEMENT,
} from './actions';

const DEFAULT_VAL = { value: '' };

const elementReducer = (
  state = {
    atf: DEFAULT_VAL,
    btf: DEFAULT_VAL,
    cta: DEFAULT_VAL,
    visual: DEFAULT_VAL,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_ELEMENT:
      const { type, value } = action.data;

      return { ...state, [type]: value };

    default:
      return state;
  }
};

const reducer = (state = INITIAL_STATE.CreateAd, action) => {
  switch (action.type) {
    case CREATE_AD_SET_AD:
      return {
        payload: state.payload,
        carousel: state.carousel,
        ads: action.data,
        byIds: action.byIds,
      };

    case CREATE_AD_SET_CAROUSEL:
      return {
        ...state,
        carousel: action.data,
        payload: setInitialPayload(action.data),
      };

    case UPDATE_ELEMENT:
      return { ...state, payload: elementReducer(state.payload, action) };

    case APPEND_ELEMENT:
      const { type, value } = action.data;

      return {
        ...state,
        carousel: {
          ...state.carousel,
          [type]: [...state.carousel[type], value],
        },
      };

    default:
      return state;
  }
};

export default reducer;

function setInitialPayload({ atf, btf, cta, visual }) {
  return {
    atf: getValue(atf),
    btf: getValue(btf),
    cta: getValue(cta),
    visual: getValue(visual),
  };
}

function getValue(obj, defaultTo = DEFAULT_VAL) {
  if (!obj[0]) {
    return defaultTo;
  }
  return necessary(obj[0]);
}

function necessary({
  value,
  rank,
  ranks,
  cpr_difference,
  spend,
  image_url,
  type,
}) {
  return {
    value,
    rank,
    ranks,
    cpr_difference,
    spend,
    image_url,
    type,
    index: 0,
  };
}
