import React from "react";

export const DescriptionIcon = () => {
  return (
    <svg
      className="description-icon"
      width="14"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.730469 1.60181H11.2305M0.730469 4.60181H8.23047M0.730469 7.60181H11.2305M0.730469 10.6018H5.23047"
        stroke="#9E9E9E"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
