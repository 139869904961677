import React from "react";
import Button from "../../../components/Button";
import { ClickAwayListener } from "@mui/material";

const Modal = ({
  handleClickAway,
  show,
  handleSetShow,
  handleConfirm,
  message,
  className,
  children,
}) => {
  return (
    <>
      <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
        <div className="clickaway-div">
          {show ? (
            <div className={`pb-modal${show ? " show" : ""} ${className}`}>
              <span className="mb-2">{message}</span>
              <div className="mt-2 d-flex">
                <Button onClick={handleSetShow}>
                  Cancel
                </Button>
                <Button
                  className="darwin-button--active"
                  onClick={handleConfirm}
                >
                  Confirm
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </ClickAwayListener>
      {children}
    </>
  );
};

export default Modal;
