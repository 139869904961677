import React, { useEffect, useState, useMemo } from "react";
const _ = require("lodash");
import "./ImpactReport.scss";
import { SwiperSlide } from "swiper/react";
import VisualCard from "../../CreativeAnalytics/TopPerformers/VisualCard";
import Select from "react-select";
import ReportEditor from "./ReportEditor/ReportEditor";
import { CombinedIcon, ReportEditorIcon } from "../ImpactReport/Helpers";
import RoleAccess from "../../../components/RoleAccess";
import { ImpactReportProvider } from "./contexts";

import {
  CHANGE_REPORT,
  DUMMY_DATA,
  DUMMY_REPORT,
  DUMMY_VISUAL_OBJ,
  GENERAL_LOADING,
  IMPACT_REPORT,
  VIEW_LABEL_DATA,
} from "./constants";
import { useActionName } from "../../CreativeAnalytics/selectors";
import {
  ImpactReportTable,
  CombinedImpactTable,
  CategoryDetailsTable,
} from "./Tables";
import ReportDescription from "./ReportDescription";
import { useImpactReport } from "./hooks";
import {
  SET_SELECTED_CATEGORY,
  SET_SELECTED_LABEL,
  SET_SELECTED_REPORT,
} from "./reducer";
import { IR_MAIN_SELECT_STYLES } from "./ReportEditor/constants";
import { useDispatch } from "react-redux";
import { trackEvent } from "../../../store/UserActivity/actions";
import { useGetImpactReportsApi } from "./api";
import { sortReports } from "./utils";
import LoadingAnvil from "../../../components/Helpers/LoadingAnvil";
import { toPercentRounded } from "../../../utils/numbers";
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from "../contexts";

const ImpactReport = ({ view_id, getParams }) => {
  const { setParams, setImpactCategories } = useCreativeAnalyticsSet();
  const isROAS = useActionName().includes("value_");
  const impactReport = useImpactReport();
  const {
    selectedReport,
    selectedCategory,
    selectedLabel,
    dispatchImpactReport,
  } = impactReport;
  const [reports, setReports] = useState<any[]>([]);
  const [audienceOptions, setAudienceOptions] = useState<string[]>([]);
  const [labelOptions, setLabelOptions] = useState<string[]>([]);
  const [selectorOptions, setSelectorOptions] = useState<any[]>([]);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [showReportEditor, setShowReportEditor] = useState<boolean>(false);
  const [stage, setStage] = useState<string>("1");
  const [loadingReports, setLoadingReports] = useState<boolean>(false);
  const [pageHasLoaded, setPageHasLoaded] = useState<boolean>(false);
  const dispatch = useDispatch();
  const params = getParams("report");
  const { report } = params;
  const getImpactReportsApi = useGetImpactReportsApi();

  useEffect(() => {
    if (getImpactReportsApi.get.loading) {
      setLoadingReports(true);
    }
  }, [getImpactReportsApi.get.loading]);

  useEffect(() => {
    if (report) {
      if (report === DUMMY_REPORT) {
        setReports(DUMMY_DATA.reports);
        dispatchImpactReport({
          type: SET_SELECTED_REPORT,
          payload: DUMMY_DATA.reports[0],
        });
      } else {
        if (reports.length) {
          dispatchImpactReport({
            type: SET_SELECTED_REPORT,
            payload: reports.find((r) => r.id === report),
          });

          dispatch(trackEvent(IMPACT_REPORT, CHANGE_REPORT));
        }
      }
    }
  }, [report, reports]);

  useEffect(() => {
    dispatch(trackEvent(IMPACT_REPORT, GENERAL_LOADING));
  }, []);

  useEffect(() => {
    if (selectedCategory && selectedLabel) {
      setStage("3");
    } else if (selectedCategory) {
      setStage("2");
    } else {
      setStage("1");
    }
  }, [selectedCategory, selectedLabel]);

  useEffect(() => {
    getImpactReportsApi.get.request(view_id);
  }, []);

  useEffect(() => {
    if (getImpactReportsApi.get.data) {
      const { data } = getImpactReportsApi.get;
      setReports(data.reports.sort(sortReports));
      setAudienceOptions(data.audience_options);
      setLabelOptions(data.label_options);
      setLoadingReports(false);
      setPageHasLoaded(true);

      if (
        data.reports.length &&
        data.reports.some((r) => r.default || r.status === "on")
      ) {
        const defaultReport = data.reports.find((r) => r.default);
        dispatchImpactReport({
          type: SET_SELECTED_REPORT,
          payload: defaultReport
            ? defaultReport
            : data.reports.find((r) => r.status === "on"),
        });
      }
    }
  }, [getImpactReportsApi.get.data]);

  useEffect(() => {
    if (reports) {
      setSelectorOptions(
        reports
          .filter((r) => r.status === "on")
          .map((r) => {
            return {
              label: r.name === "default" ? "Last Month" : r.name,
              value: r.id,
            };
          })
      );
    }
  }, [reports]);

  const refreshData = () => {
    getImpactReportsApi.get.request(view_id);
  };

  const handleSelectReport = (selected) => {
    if (selectedReport.id !== selected.value) {
      setParams({ report: selected.value });
      setShowAll(false);
    }
  };

  const handleSelectCategory = (category) => {
    dispatchImpactReport({
      type: SET_SELECTED_CATEGORY,
      payload: selectedCategory === category ? "" : category,
    });

    if (selectedCategory !== category) {
      dispatch(trackEvent(IMPACT_REPORT, VIEW_LABEL_DATA));
    }
  };

  const handleSelectLabel = (label) => {
    dispatchImpactReport({
      type: SET_SELECTED_LABEL,
      payload: selectedLabel === label ? "" : label,
    });
  };

  const ReportSelector = () => {
    return (
      <>
        <div className="report-selector">
          <Select
            value={{
              label:
                selectedReport.name === "default"
                  ? "Last Month"
                  : selectedReport.name,
              value: selectedReport.id,
            }}
            options={selectorOptions}
            onChange={handleSelectReport}
            styles={IR_MAIN_SELECT_STYLES}
          />
        </div>
      </>
    );
  };

  const sortedCategories = useMemo(
    () =>
      selectedReport?.categories
        ? Object.entries(selectedReport.categories)
            .map(([key, val]) => {
              return {
                name: key,
                score: val.score,
                label_breakdown: val.label_breakdown,
                included_in_combined:
                  selectedReport.combined_category_names?.includes(key),
              };
            })
            .sort((a, b) =>
              a.score > b.score ? -1 : a.score < b.score ? 1 : 0
            )
        : [],
    [selectedReport]
  );

  useEffect(() => {
    if (sortedCategories.length) {
      setImpactCategories(sortedCategories);
    }
  }, [sortedCategories])

  const defaultCategories = sortedCategories.filter(
    (category) => category.included_in_combined
  );

  const visualObj = useMemo(() => {
    if (selectedCategory && selectedLabel) {
      if (report === DUMMY_REPORT) {
        return DUMMY_VISUAL_OBJ;
      } else {
        const labelObj =
          selectedReport.categories[selectedCategory].label_breakdown[
            selectedLabel
          ];

        return {
          label: selectedLabel,
          cpa: labelObj.cpa,
          visuals: labelObj.visuals
            .sort((a, b) =>
              a.spend > b.spend ? -1 : a.spend < b.spend ? 1 : 0
            )
            .map((visual) => {
              return {
                id: visual.id,
                spend: visual.spend,
                cpa: visual.cpa,
                roas: visual.roas,
                visual_type: visual.visual_type,
                asset_url: visual.asset_url,
                thumbnail_url: visual.thumbnail_url,
              };
            }),
        };
      }
    } else {
      return [];
    }
  }, [selectedCategory, selectedLabel, report]);

  const combinedPercentage = selectedReport
    ? selectedReport.combined_score > 0 && selectedReport.combined_score < 0.01
      ? "<1%"
      : toPercentRounded(selectedReport.combined_score)
    : "";

  return (
    <ImpactReportProvider
      {...{
        isROAS,
        reports,
        setReports,
        audienceOptions,
        labelOptions,
        showReportEditor,
        setShowReportEditor,
        view_id,
        refreshData,
      }}
    >
      {selectedReport && (
        <div className="m-0" style={{ height: "calc(100vh - 170px" }}>
          <div
            className="creative-reports__header row align-items-center ml-5 mr-5 my-4 align-item-center"
            style={{ zIndex: "4", position: "relative" }}
          >
            <h2 className="mr-2">Impact Report</h2>
            {!loadingReports && (
              <div className={`impact-report__header ${showReportEditor ? "hide" : ""}`}>
                <div className="options">
                  <ReportSelector />
                </div>
                <RoleAccess roles={["internal"]}>
                  <ReportEditorIcon
                    className={"report-editor__icon"}
                    onClick={() => {
                      if (loadingReports) return;
                      setShowReportEditor(true);
                    }}
                  />
                </RoleAccess>
              </div>
            )}
          </div>
          <div id="impact-report__wrapper" className="impact-report__wrapper">
            <div
              className={`impact-report ${
                showReportEditor ? "slide-left" : ""
              } stage${stage}`}
            >
              <div className="d-flex justify-content-between">
                <div>
                  {!loadingReports && selectedReport.id ? (
                    <ReportDescription
                      {...{
                        defaultCategories,
                        selectedReport,
                        combinedPercentage,
                      }}
                    />
                  ) : null}
                </div>
              </div>
              <>
                {!loadingReports && reports.length ? (
                  <div className="blur-overlay"></div>
                ) : null}
                <div className="impact-report__container report">
                  {loadingReports && !pageHasLoaded ? (
                    <LoadingAnvil
                      helperText="Loading..."
                      className="impact-report__loading"
                    />
                  ) : reports.some((r) => r.status === "on") ? (
                    <>
                      <div className="impact-report__content">
                        {selectedReport.error ? (
                          <div className="error">
                            <i
                              className="fa-solid fa-triangle-exclamation mr-3"
                              style={{ color: "#f0ad4e" }}
                            ></i>
                            {selectedReport.error}
                          </div>
                        ) : (
                          <>
                            <div
                              className={`impact-report__panels horizontal-accordion stage${stage}`}
                            >
                              <div
                                className={`panel panel--categories expand stage${stage}`}
                              >
                                <div className="impact-report__table">
                                  {Object.keys(selectedReport).length && (
                                    <>
                                      <ImpactReportTable
                                        {...{
                                          sortedCategories,
                                          handleSelectCategory,
                                          selectedCategory,
                                          showAll,
                                        }}
                                      />
                                      <div className="legend legend--main">
                                        <div>
                                          <CombinedIcon /> Included in combined
                                          impact %
                                        </div>
                                        <div className="d-flex">
                                          <div className="asterisk">*</div>
                                          <div>
                                            The % of performance that can be
                                            explained by this single category
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                                {/* {defaultCategories.length <
                                  sortedCategories.length && (
                                  <p
                                    className="show-all"
                                    onClick={() => setShowAll((prev) => !prev)}
                                  >
                                    Show {showAll ? "Fewer" : "All"} Categories
                                  </p>
                                )} */}
                              </div>
                              <div
                                className={`panel panel--combined-score ${
                                  stage === "1" ? "expand" : "small"
                                }`}
                              >
                                <div className="impact-report__table">
                                  {Object.keys(selectedReport).length && (
                                    <>
                                      <CombinedImpactTable
                                        combinedScore={
                                          selectedReport.combined_score
                                        }
                                        {...{ combinedPercentage }}
                                      />
                                      <div className="legend">
                                        <div>
                                          The % of performance that can be
                                          explained by the included{" "}
                                          <CombinedIcon /> categories together
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div
                                className={`panel panel--category-details ${
                                  stage !== "1" ? "expand" : "small"
                                } ${stage === "3" ? "stage3" : ""}`}
                              >
                                <div className="impact-report__table">
                                  {Object.keys(selectedReport).length && (
                                    <>
                                      <div
                                        className="table-caret"
                                        style={{}}
                                      ></div>
                                      <CategoryDetailsTable
                                        {...{
                                          selectedReport,
                                          selectedCategory,
                                          selectedLabel,
                                          handleSelectLabel,
                                        }}
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                              {selectedLabel && (
                                <div
                                  className={`panel panel--carousel ${
                                    stage === "3" ? "expand" : "small"
                                  }`}
                                >
                                  <div className="impact-report__carousel">
                                    {visualObj.visuals.length ? (
                                      <SwiperSlide>
                                        <VisualCard
                                          visualObj={visualObj}
                                          isHeader={false}
                                          isImpactReport={true}
                                          isROAS={isROAS}
                                        ></VisualCard>
                                      </SwiperSlide>
                                    ) : (
                                      <div className="no-visuals">
                                        No visuals found for this label.
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <div>No reports found for this view.</div>
                  )}
                </div>
              </>
            </div>
            <ReportEditor />
            <div className="wrapper-border wrapper-border--left"></div>
            <div className="wrapper-border wrapper-border--right"></div>
          </div>
        </div>
      )}
    </ImpactReportProvider>
  );
};

export default ImpactReport;
