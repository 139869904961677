import { useRef, useEffect, useMemo, useCallback } from 'react';
import { abortableFetch } from '../../../utils';

import {
  useApiState,
  bindFetchToRef,
} from '../../../components/Hooks/useApiState';
import { useDispatch, useSelector } from 'react-redux';
import { setPresetsData } from '../../../store/CreativeAnalytics/creativeAnalyticsSlice';
import { setLoader } from '../../../store/Loaders/actions';
import { setError } from '../../../store/Errors/actions';

const HEADERS = { 'content-type': 'application/json' };

const PRESETS = 'PRESETS';

function getPreset(payload) {
  return abortableFetch(['api', 'creative-analytics', 'get-preset'].join('/'), {
    method: 'POST',
    headers: HEADERS,
    credentials: 'include',
    body: JSON.stringify(payload),
  });
}

export function useFetchPresetsApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const dispatch = useDispatch();
  const { view_id, darwin_client_id } = useSelector(
    ({ ViewSettings: { viewId, darwin_client_id } }) => ({
      view_id: viewId,
      darwin_client_id,
    })
  );

  const [{ data, loading, error }, getDispatch] = useApiState(
    (payload) => withRef(getPreset(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  const fetchPresetsData = () => {
    getDispatch({
      data: {
        client_id: darwin_client_id,
        view_id,
      },
      method: 'GET',
    });
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  useEffect(() => {
    fetchPresetsData();
  }, [view_id, darwin_client_id]);

  useEffect(() => {
    if (data) {
      if (typeof data === 'string') {
        dispatch(setPresetsData([]));
      } else {
        if (data.body && Array.isArray(data.body)) {
          dispatch(setPresetsData(data.body));
        } else if (Array.isArray(data)) {
          dispatch(setPresetsData(data));
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (loading) {
      dispatch(setLoader(PRESETS, true));
    } else {
      dispatch(setLoader(PRESETS, false));
    }
  }, [loading]);

  useEffect(() => {
    if (error) {
      dispatch(setError(PRESETS, true));
    } else {
      dispatch(setError(PRESETS, false));
    }
  }, [error]);

  return () => fetchPresetsData();
}
