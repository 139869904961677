import { format } from 'date-fns';
import React, { useRef, useState, useMemo, useEffect } from 'react';
import { CloseIcon, InfoIcon, SettingsIcon } from './icons';
import Tooltip from '../../../../components/Tooltip';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { toCurrency } from '../../../../utils/numbers';
import { getPhraseBuilderOptions, getPreferencesOptions } from './constants';
import { Dropdown } from './Dropdown';

import './Header.scss';
import { useViewName, useIsDirectMail } from '../../selectors';
import { PHRASE_BUILDER, REPORT_CARD } from '../constants';
import { useReportCardContext } from '../contexts';
import Tippy from '@tippyjs/react';
import { useViewSettings } from '../../../../selectors';
import { useCreativeAnalyticsGet } from '../../contexts';

const Header = ({
  spendThreshold,
  start_date,
  end_date,
  selectedAudience,
  isPhraseBuilder,
  setReportCardMode,
  handleClose,
  handleExit,
  fromCreativeStudio,
  bestPerforming,
  setShowKeywordSettingsModal,
}) => {
  const {
    changesMade,
    categorySettings,
    dispatchReportCardSettings,
    reportCardSettings,
    primary_metric,
    data_type,
  } = useReportCardContext();
  const { isCompetitorView, isOrganicView, isROAS } = useViewSettings();

  const [openedMenu, setOpenedMenu] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [modeDropdown, setModeDropdown] = useState(false);
  const prefsOpen = openedMenu === 'preferences';
  const inactiveMode = !isPhraseBuilder ? PHRASE_BUILDER : REPORT_CARD;
  const isDuff = isCompetitorView || isOrganicView;
  const { isGoHealth } = useCreativeAnalyticsGet();

  const ref = useRef();
  useClickOutside(ref, () => {
    if (openedMenu && ref.current) {
      setOpenedMenu(null);
    }
  });

  const preferencesOptions = useMemo(() => {
    if (isPhraseBuilder) {
      return getPhraseBuilderOptions(setShowKeywordSettingsModal);
    } else {
      return getPreferencesOptions({
        categorySettings,
        dispatchReportCardSettings,
        reportCardSettings,
        isDuff,
        primary_metric,
        isROAS,
        data_type,
        isGoHealth,
      });
    }
  }, [categorySettings, reportCardSettings, isPhraseBuilder]);

  const start = format(start_date, 'MM/dd/yyyy');
  const end = format(end_date, 'MM/dd/yyyy');
  const audienceText = selectedAudience.length
    ? `the audience ${selectedAudience}`
    : 'all audiences';

  const handleToggle = (option) => {
    if (!fromCreativeStudio && !bestPerforming) {
      if (isPhraseBuilder && changesMade) {
        handleExit(false);
      } else {
        setReportCardMode(option);
      }
    } else {
      handleClose(true);
    }
  };

  const handleHover = () => {
    setOpenedMenu((prev) => (prev === 'preferences' ? null : 'preferences'));
  };

  return (
    <div className="rc-header">
      <div className="container">
        <div className="row justify-content-between">
          <div className="d-flex">
            {!bestPerforming && (
              <ul className="nav-group">
                {!fromCreativeStudio && (
                  <>
                    <li
                      className={`nav-item dropdown-item ${'selected'} ${
                        modeDropdown ? 'expanded' : ''
                      }`}
                      onMouseOver={() => setModeDropdown(true)}
                      onMouseOut={() => setModeDropdown(false)}
                    >
                      <a className="selection">
                        {isPhraseBuilder ? 'Phrase Builder' : 'Report Card'}
                      </a>
                      <i className="fa fa-chevron-down"></i>
                    </li>
                    <ul
                      className={`dropdown-options ${
                        modeDropdown ? 'expanded' : ''
                      }`}
                      onMouseOver={() => setModeDropdown(true)}
                      onMouseOut={() => setModeDropdown(false)}
                    >
                      <li onClick={() => handleToggle(inactiveMode)}>
                        {inactiveMode}
                      </li>
                    </ul>
                  </>
                )}

                <ul className="rc-header__options">
                  <Tippy
                    className="phrase-builder-tooltip"
                    content="Preferences"
                    delay={[500, 0]}
                    disabled={prefsOpen}
                  >
                    <li
                      className={`nav-item dropdown-item ${
                        prefsOpen ? 'selected expanded' : ''
                      }`}
                      onMouseOver={() => {
                        handleHover();
                      }}
                      onMouseOut={() => {
                        handleHover();
                      }}
                    >
                      <button>
                        <SettingsIcon />
                      </button>
                    </li>
                  </Tippy>
                  <>
                    <Dropdown
                      {...{
                        activeSubMenu,
                        setActiveSubMenu,
                        openedMenu,
                        handleHover,
                      }}
                      options={preferencesOptions}
                    />
                  </>
                  {!isPhraseBuilder && (
                    <Tooltip
                      content={
                        <span>
                          <span className="subtle">
                            This data is between the dates of
                          </span>
                          {` ${start}`}&nbsp;and&nbsp;{`${end} `}
                          <span className="subtle">for</span>
                          {` ${audienceText} `}
                          <span className="subtle">
                            with a spend threshold of
                          </span>
                          {` ${toCurrency(spendThreshold)}.`}
                        </span>
                      }
                      delay={[500, 0]}
                    >
                      <li className="nav-item">
                        <button>
                          <InfoIcon />
                        </button>
                      </li>
                    </Tooltip>
                  )}
                </ul>
              </ul>
            )}
          </div>
          <ul className="rc-header__options nav-group">
            <li
              className="nav-item"
              onClick={() => {
                if (isPhraseBuilder && changesMade) {
                  handleExit(true);
                } else {
                  handleClose(true);
                }
              }}
            >
              <button>
                <CloseIcon />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
