import React, { useContext } from "react";

import { WizardContext } from "./Context";
import { MIN_DATE, nextDay } from "../date-utils";
import {
  AutomationStudyType,
  TestType,
  StartDate,
  EndDate,
  SubType,
  FormText,
  StudyStatus,
} from "./FormElements";

export function HighLevel() {
  const {
    handleChange,
    study_name,
    automation_study_type,
    testing_study_type,
    study_type,
    end_date,
    start_date,
    status,
    inProgress,
  } = useContext(WizardContext);

  return (
    <>
      <FormText
        label="Study Name"
        value={study_name}
        name="study_name"
        onChange={handleChange}
        required
        disabled={inProgress}
      />

      <AutomationStudyType
        label="Study Type"
        value={automation_study_type}
        name="automation_study_type"
        onChange={handleChange}
        required
        disabled={inProgress}
      />

      <TestType
        value={testing_study_type}
        name="testing_study_type"
        onChange={handleChange}
        disabled={inProgress}
      />

      <SubType
        value={study_type}
        name="study_type"
        onChange={handleChange}
        disabled={inProgress}
      />

      <StartDate
        value={start_date}
        name="start_date"
        onChange={handleChange}
        disabled={inProgress}
      />

      <EndDate
        value={end_date}
        name="end_date"
        onChange={handleChange}
        min={start_date === "" ? MIN_DATE : nextDay(start_date)}
      />

      <StudyStatus
        value={status}
        name="status"
        onChange={handleChange}
        disabled={inProgress}
      />
    </>
  );
}
