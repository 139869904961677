//@ts-check
import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import MemberDropdown from "./MemberDropdown";
import FilterInput from "./FilterInput";

import { dimensionToSelection, memberToSelection } from "./select-derivers";

const SELECT_STYLES = {
  container: (base, state) => ({ ...base, width: "200px" }),
  menu: (base, state) => ({ ...base }),
};

const FiltersForSelect = ({ members, availableMembers, children }) =>
  children({
    members: members.map(dimensionToSelection),
    availableMembers: availableMembers.map(memberToSelection),
  });

const OperatorsForSelect = ({ operators, children }) => {
  return children(
    operators.map((op) => ({ ...op, label: op.title, value: op.name }))
  );
};

const FilterGroup = ({
  members,
  availableMembers,
  addMemberName,
  updateMethods,
  disabled,
}) => (
  <>
    {members.map((m, i) => (
      <div key={`${m.name}-${i}`} className="d-flex flex-row mb-2">
        <MemberDropdown
          disabled={disabled}
          selected={m}
          options={availableMembers}
          onChange={(maybeMember) => {
            if (maybeMember) {
              updateMethods.update(m, { ...m, dimension: maybeMember });
            } else {
              updateMethods.remove(m);
            }
          }}
          size="200px"
        />
        &emsp;
        <OperatorsForSelect operators={m.operators}>
          {(ops) => (
            <Select
              isDisabled={disabled}
              placeholder="Operator"
              styles={SELECT_STYLES}
              value={
                m && m.operator
                  ? ops.find(({ name }) => name === m.operator)
                  : null
              }
              options={ops}
              onChange={(selected) =>
                selected && selected.value
                  ? updateMethods.update(m, {
                      ...m,
                      operator: selected.value,
                    })
                  : selected
              }
            />
          )}
        </OperatorsForSelect>
        &emsp;
        <FilterInput
          disabled={disabled}
          member={m}
          key="filterInput"
          updateMethods={updateMethods}
        />
      </div>
    ))}

    {!disabled && (
      <MemberDropdown
        placeholder={addMemberName}
        selected={null}
        options={availableMembers}
        onChange={(m) =>
          updateMethods.add({
            dimension: m,
          })
        }
        size="200px"
      />
    )}
  </>
);

FilterGroup.propTypes = {
  members: PropTypes.array.isRequired,
  availableMembers: PropTypes.array.isRequired,
  addMemberName: PropTypes.string.isRequired,
  updateMethods: PropTypes.object.isRequired,
};

export default (props) => (
  <FiltersForSelect
    members={props.members}
    availableMembers={props.availableMembers}
  >
    {(derived) => <FilterGroup {...props} {...derived} />}
  </FiltersForSelect>
);
