import React, { useState, useEffect } from 'react';
import {
  PRIMARY_PHRASE,
  TRENDING,
} from '../../../components/LabelMatrix/constants';
import {
  CopyIcon,
  CopyPopup,
  StarIcon,
  EyeIcon,
  EyeIconHide,
  ResetIcon,
  StarIconHalf,
} from './assets';
import { useRatingsApi } from './api';
import { useSearchParams } from '../../../components/Hooks/search-params';
import { copyTextToClipboard } from './utils';

import Carousel from './Carousel';
import CardOptions from './CardOptions';
import HeartIcon from './Helpers';
import Tooltip from '../../../components/Tooltip';
import { FavoriteIcon } from '../../../components/Icons';

const Card = ({
  label,
  tab,
  spendThreshold,
  account_id,
  handleSaveConcept,
  isSaved,
  justSaved,
  justUnsaved,
}) => {
  const ratingsApi = useRatingsApi();

  const [carouselIndex, setCarouselIndex] = useState(0);
  const [copied, setCopied] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [rating, setRating] = useState(label.rating);
  const [isHidden, setIsHidden] = useState(false);

  const { getParams } = useSearchParams();
  const { ratings } = getParams('ratings');

  const { name, value, description, type } = label;
  const isTryThese = tab === 'try_these';
  const isTopPerformers = tab === 'top_performers';
  const isKeyword = value.charAt(0) === '"' && !label.category;

  const showOverlay = label.label === justSaved || label.label === justUnsaved;

  let previews = [];
  let title = '';

  useEffect(() => {
    setCarouselIndex(0);
  }, [spendThreshold]);

  const getTryTheseData = (examples) => {
    return examples.map(({ asset, type }) => {
      return {
        visual_type: type,
        asset_url: asset,
      };
    });
  };

  try {
    previews = getTryTheseData([...label.example].reverse());
  } catch (e) {
    if (typeof label.example === 'object') {
      previews = [label.example];
    }
  }
  
  title = label.label;

  const prev = () => {
    if (carouselIndex > 0) {
      setCarouselIndex(carouselIndex - 1);
    } else {
      setCarouselIndex(previews.length - 1);
    }
  };

  const next = () => {
    if (carouselIndex < previews.length - 1) {
      setCarouselIndex(carouselIndex + 1);
    } else {
      setCarouselIndex(0);
    }
  };

  const descriptionText =
    description && description.length
      ? description
      : isKeyword
      ? 'This keyword was found in the visual asset.'
      : null;

  let Description = (
    <p className="recommendation-card__desc">{descriptionText}</p>
  );

  const copy = () => {
    copyTextToClipboard(previews[carouselIndex].asset_url);
    setCopied(true);
  };

  let Display = (
    <Carousel
      {...{
        previews,
        carouselIndex,
        prev,
        next,
        showOptions,
        setShowOptions,
        name,
        value,
        isTopPerformers,
        isTryThese,
        label,
        copy,
        justSaved,
        showOverlay,
      }}
    />
  );

  let Heading = (
    <>
      <div className="recommendation-card__top-bar d-flex">
        <CarouselNav {...{ carouselIndex, setCarouselIndex, previews }} />
        <p className="recommendation-card__category">{label.category}</p>
      </div>
    </>
  );

  useEffect(() => {
    rating > 0 ? setIsHidden(false) : setIsHidden(true);
  }, [rating]);

  const toggleHidden = (prev) => {
    setIsHidden((prev) => !prev);
    isHidden ? handleSaveRating(1.5) : handleSaveRating(0);
  };

  const handleSaveRating = (update) => {
    const label_type = isTryThese ? 'common_ad_labels' : type;
    let newRating = update.toString();

    if (update === parseFloat(rating) && rating !== 1.5) {
      setRating(0);
      newRating = '0';
    } else {
      setRating(update);
    }

    ratingsApi.post.request({
      account_id,
      label: value,
      type: label_type,
      rating: newRating,
    });
  };

  return (
    <div className={`recommendation-card ${tab}`}>
      {Display}
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="recommendation-card__title" title={title}>
            {title}
          </h2>
          <Tooltip
            content={
              isSaved ? 'Remove from saved concepts' : 'Add to saved concepts'
            }
          >
            <FavoriteIcon
              isSaved={isSaved}
              onClick={() => {
                if (!showOverlay) handleSaveConcept(label);
              }}
              animateSave={label.label === justSaved}
              animateUnsave={label.label === justUnsaved}
            />
          </Tooltip>
        </div>
        {Description}
        <p className="recommendation-card__category">{label.category}</p>
        {!isTopPerformers && ratings === 'true' && (
          <div className="d-flex flex-col">
            <div className={`star-rating rating-${rating}`}>
              <StarIcon
                onClick={() => handleSaveRating(1)}
                className={`star-icon ${rating >= 1 ? 'active' : ''}`}
              />
              {rating === 1.5 || rating === '1.5' ? (
                <div
                  onClick={() => handleSaveRating(2)}
                  className="star-wrapper"
                >
                  <div className="star-container">
                    <StarIconHalf />
                  </div>
                  <div className="star-container right">
                    <StarIconHalf />
                  </div>
                </div>
              ) : (
                <StarIcon
                  onClick={() => handleSaveRating(2)}
                  className={`star-icon ${rating >= 2 ? 'active' : ''}`}
                />
              )}
              <StarIcon
                onClick={() => handleSaveRating(3)}
                className={`star-icon ${rating >= 3 ? 'active' : ''}`}
              />
              <StarIcon
                onClick={() => handleSaveRating(4)}
                className={`star-icon ${rating >= 4 ? 'active' : ''}`}
              />
              <StarIcon
                onClick={() => handleSaveRating(5)}
                className={`star-icon ${rating >= 5 ? 'active' : ''}`}
              />
              {ratingsApi.post.loading && (
                <p className="stars-saving">Saving...</p>
              )}
              {ratingsApi.post.error && (
                <p className="stars-saving">There was an error.</p>
              )}
            </div>
            <div className="my-2">
              <div
                onClick={() => toggleHidden()}
                className={`eye-icon ${!isHidden ? 'show' : ''}`}
              >
                {!isHidden ? <EyeIcon /> : <EyeIconHide />}
              </div>
              <div onClick={() => handleSaveRating(1.5)} className="reset-icon">
                <ResetIcon />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;

const CarouselNav = ({ carouselIndex, setCarouselIndex, previews }) => {
  return (
    <div className="carousel-nav">
      {previews.map((asset, i) => (
        <div
          onClick={() => setCarouselIndex(i)}
          className={`carousel-nav__dot${carouselIndex === i ? ' active' : ''}`}
        ></div>
      ))}
    </div>
  );
};
