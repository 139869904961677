import React, { useCallback, useMemo } from 'react';
import { DrawerSection } from '../Drawer/DrawerSection';
import { LabelsList } from '../Drawer/LabelsList';
import { SET_VIEW } from '../../hooks/useExplorerState';
import { CATEGORY_TYPES_INDEX } from '../../constants';
import { useCopyToClipboard } from '../../../../../hooks/useCopyToClipboard';
import Tooltip from '../../../../../components/Tooltip';
import { Toolbar } from '../Drawer/Toolbar';
import { ReportCardIcon } from '../../../../../components/Icons';
import { useFilteredCategories } from '../../hooks/useFilteredCategories';
import { DrawerAction } from '../../types';
import { VisualsLookupItem } from '../../../ReportCard/types';

type VisualViewerProps = {
  selectedVisualId: string;
  currentVisual: VisualsLookupItem;
  handleClose: () => void;
  dispatch: React.Dispatch<DrawerAction>;
  previewIsVisible: boolean;
  openReportCard: () => void;
  isCopied: boolean;
  handleCopyId: () => void;
};

export const VisualViewer = ({
  selectedVisualId,
  currentVisual,
  handleClose,
  dispatch,
  previewIsVisible,
  openReportCard,
  isCopied,
  handleCopyId,
}: VisualViewerProps) => {
  const { data, categories: visualCategories } = currentVisual;
  const filteredCategories = useFilteredCategories(visualCategories);
  const showCloseButtonInToolbar = !previewIsVisible;

  const getRelevantLabels = useMemo(() => {
    return (labels) =>
      labels?.filter((label) =>
        label.visuals?.some((x) => x.id === selectedVisualId)
      );
  }, [selectedVisualId]);

  const ReportCardButton = () => {
    return (
      <Tooltip content="Open report card">
        <button onClick={openReportCard}>
          <ReportCardIcon />
        </button>
      </Tooltip>
    );
  };

  return (
    <div className="visual-viewer">
      <Toolbar
        left={<ReportCardButton />}
        right={
          showCloseButtonInToolbar ? (
            <button>
              <i
                style={{ fontSize: '1.3rem', color: '#2B437E' }}
                className="drawer__close fa fa-xmark"
                onClick={handleClose}
              ></i>
            </button>
          ) : null
        }
      />
      <DrawerSection
        title={`${isCopied ? 'Copied! ✓' : 'Visual ID'}`}
        onClick={handleCopyId}
        content={
          <p
            style={{
              fontFamily: 'Fira Code',
              marginBottom: 0,
              fontSize: '1.1rem',
              fontWeight: 500,
            }}
          >
            {selectedVisualId}
          </p>
        }
      />

      {filteredCategories.map((category) => {
        const { category: category_name, ccc_type, labels } = category;
        const relevantLabels = getRelevantLabels(labels);

        const { label: categoryTypeLabel = null, icon: Icon = null } =
          CATEGORY_TYPES_INDEX[ccc_type] || {};

        const handleClick = () =>
          dispatch({
            type: SET_VIEW,
            currentView: { type: 'category_viewer', id: category_name },
          });

        let Content = <p className="fira mb-0">Not labelled.</p>;
        if (relevantLabels.length > 0)
          Content = <LabelsList labels={relevantLabels} />;

        return (
          <DrawerSection
            className={`drawer-section--${ccc_type}`}
            title={category_name}
            icon={
              Icon && (
                <Icon
                  className={`drawer-section__icon drawer-section__icon--${ccc_type}`}
                />
              )
            }
            iconTooltip={categoryTypeLabel}
            content={Content}
            onClick={handleClick}
          />
        );
      })}
    </div>
  );
};
