import { useSelector } from 'react-redux';

export function useAccountId() {
  return useSelector(({ ViewSettings: { account_id } }) => account_id || '');
}

export function usePlatforms() {
  return useSelector(({ ViewSettings: { platforms } }) => platforms || '');
}

export function useCustomMetrics() {
  return useSelector(
    ({ ViewSettings: { customIntegratedMetrics } }) => customIntegratedMetrics
  );
}

export function useAccountPlatforms() {
  const account_platforms = useSelector(
    ({ ViewSettings: { account_platforms } }) => account_platforms || []
  );
  return account_platforms.map((account_platform) => {
    return account_platform.value;
  });
}

export function useAccountPlatformObject() {
  return useSelector(
    ({ ViewSettings: { account_platforms } }) => account_platforms || []
  );
}

export function useDarwinClientId() {
  return useSelector(
    ({ ViewSettings: { darwin_client_id } }) => darwin_client_id
  );
}
