import { useRef, useEffect } from 'react';
import { abortableFetch } from '../../../utils';
import {
  useApiState,
  bindFetchToRef,
} from '../../../components/Hooks/useApiState';

const HEADERS = { 'content-type': 'application/json' };

function createCategoryStatus({
  view_id,
  darwin_client_id,
  category,
  platform,
  request_id,
  category_id,
  start_date,
  end_date,
}) {
  return abortableFetch(
    [
      'api',
      'creative-analytics',
      'custom-categories',
      'create-category',
      'check-status',
      darwin_client_id,
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify({
        view_id,
        darwin_client_id,
        category,
        platform,
        request_id,
        category_id,
        start_date,
        end_date,
      }),
    }
  );
}

export function useCreateCategoryStatus() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (payload) =>
      withRef(createCategoryStatus(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    ...getState,
    request: getDispatch,
  };
}
