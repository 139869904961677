import React from "react";

import { Rank } from "./Helpers";
import { formatMetricTypeAbbrv, fontColor } from "./utils";
import { CPR_HEIGHT } from "./constants";

export const ZoneLabel = ({ children }) => {
  return (
    <div className="d-flex flex-row align-items-center height_100pct">
      {children}
    </div>
  );
};

export const ZoneContent = ({ value, type, rank, total}) => (
    <div className="zone-content d-flex flex-row justify-content-between">
      <span className="d-flex justify-content-end align-items-center">
        <span className="zone-content__label">
          &ensp;{formatMetricTypeAbbrv(type)}
        </span>
        <span>
          {value === null ? (
            <span className="zone-content__value null-value">N/A</span>
          ) : (
            <span className="zone-content__value">{value}</span>
          )
          }
        </span>
        
      </span>
      <Rank rank={rank} outOf={total} />
    </div>
  );
