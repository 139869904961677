import Tippy from "@tippyjs/react";
import React from "react";
import Button from "../components/Button";
import ConfirmationModal from "./ConfirmationModal";
import { EMPTY_REPORT } from "./constants";

const Header = ({
  reports,
  current,
  reportsToDelete,
  activeReports,
  handleCreateNew,
  reportDetails,
  setReportDetails,
  handleDelete,
  showModal,
  setShowModal,
  handleDeleteClickAway,
  changesMade,
  handleChangesModal,
  setEditing,
  setToEmpty,
  saveInProgress,
}) => {
  const existing = reports.find((r) => r.id === current.id);

  return (
    <div className="row report-editor__header">
      <div className="">
        <h2>Reports</h2>
      </div>
      <div className="header-options">
        <div
          className="report-details"
          onClick={() => setReportDetails((prev) => !prev)}
        >
          <label htmlFor="report-details">Report Details</label>
          <div className="checkbox-padding checkbox-padding--header">
            <div className="input-container">
              <input type="checkbox" checked={reportDetails} />
            </div>
          </div>
        </div>
        <div>
          <span className="active-reports">{activeReports}/4 Active </span>
        </div>
        <ConfirmationModal
          handleClickAway={(e) => handleDeleteClickAway(e)}
          show={showModal}
          handleCancel={() => setShowModal(false)}
          handleConfirm={handleDelete}
          message={`Delete ${reportsToDelete.length} report${
            reportsToDelete.length > 1 ? "s" : ""
          }?`}
          className={"bulk-delete"}
        >
          <Button
            id="bulk-delete"
            type="subtle"
            onClick={() => {
              if (reportsToDelete.length) setShowModal((prev) => !prev);
            }}
            disabled={!reportsToDelete.length}
          >
            Delete
            {`${reportsToDelete.length ? ` (${reportsToDelete.length})` : ""}`}
          </Button>
        </ConfirmationModal>
        <Button
          id="create-new"
          type="primary"
          onClick={() => {
            if (changesMade) {
              if (!existing) {
                setEditing(true);
                return;
              } else {
                handleChangesModal("new");
              }
            } else {
              handleCreateNew();
            }
          }}
          disabled={null}
        >
          {!existing && !_.isEqual(current, EMPTY_REPORT)
            ? "Continue Editing >"
            : "+ Create New Report"}
        </Button>
      </div>
    </div>
  );
};

export default Header;
