/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from './constants';
import './styles.scss';

function InputTag({
  appearance,
  className,
  id,
  active,
  children,
  onClick,
  onKeyDown,
  onChange,
  disabled,
  placeholder,
  value,
  ...restProps
}) {
  const baseClass = classNames.base;
  const activeClass = classNames.active;
  const disabledClass = classNames.disabled;
  const inputClass = classNames.input;
  const handleClick = onClick;

  return (
    <div>
      <div
        className={[
          baseClass,
          inputClass,
          className ? className : '',
        ].filter(Boolean).join(' ')}
      >
        <input
          {...restProps}
          autoFocus
          onClick={handleClick}
          onKeyDown={onKeyDown}
          onChange={onChange}
          id={id}
          placeholder={placeholder}
          value={value}
        />
      </div>
      <span style={{ color: "red" }}>{(value.includes(",") ? "Tag cannot include commas." : null)}</span>
    </div>
  );
}

export default InputTag;

InputTag.defaultProps = {
  appearance: 'primary',
  className: null,
  id: null,
  children: null,
  onClick: null,
  active: false,
  disabled: false,
  placeholder: 'add',
};

InputTag.propTypes = {
  appearance: PropTypes.node,
  className: PropTypes.node,
  id: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  onKeyDown: PropTypes.node.isRequired,
  onChange: PropTypes.node.isRequired,
  placeholder: PropTypes.node,
  value: PropTypes.node.isRequired,
};
