import React from 'react';
import Tooltip from '../../../components/Tooltip';

const AnalyticsButton = ({ onClick }) => {
  return (
    <Tooltip content="Open analytics for this category">
      <button onClick={onClick} className="analytics-btn">
        <i className="fa fa-arrow-right"></i>
      </button>
    </Tooltip>
  );
};

export default AnalyticsButton;
