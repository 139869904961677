import React from 'react';

export const VisualPreview = ({ previewRef, data, OverlayToolbar }) => {
  let preview = null;
  const { visual_type, asset_url, thumbnail_url } = data;

  if (visual_type.includes('image')) {
    preview = <img src={asset_url} alt="A visual." />;
  } else if (visual_type.includes('video')) {
    preview = (
      <video key={asset_url} muted plays-inline="true" controls>
        <source src={asset_url} type="video/mp4" />
      </video>
    );
  } else {
    preview = (
      <span>
        &quot;
        {visual_type}
        &quot; is not a supported format.
      </span>
    );
  }

  return (
    <div ref={previewRef} className="visual-viewer__preview">
      {OverlayToolbar && OverlayToolbar}
      {preview}
    </div>
  );
};
