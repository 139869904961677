import React from "react";

const RequiredField = ({ error, label, value, children }) => {
  return (
    <div className="row form-group">
      <label
        className={`col-sm-12 col-md-3 ${
          error.includes(value) ? "error" : ""
        }`}
      >
        {label}
      </label>
      {children}
    </div>
  );
};

export default RequiredField;
