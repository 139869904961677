/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns-tz';
import { toCurrency } from '../../../utils/numbers';

import './Suggestions.scss';

const EVALUATION_LABELS = [
  {
    name: 'IMAGE+',
    comparison: 'VIDEO+'
  },
  {
    name: 'VIDEO+',
    comparison: 'IMAGE+'
  },
  {
    name: 'PEOPLE+',
    comparison: 'NOPEOPLE+'
  },
  {
    name: 'NOPEOPLE+',
    comparison: 'PEOPLE+'
  },
  {
    name: '1:1+',
    comparison: '4:5+'
  },
  {
    name: '1:1+',
    comparison: '9:16+'
  },
  {
    name: '4:5+',
    comparison: '1:1+'
  },
  {
    name: '4:5+',
    comparison: '9:16+'
  },
  {
    name: '9:16+',
    comparison: '1:1+'
  },
  {
    name: '9:16+',
    comparison: '4:5+'
  },
]

const SuggestionNavLeft = () => (
  <svg width="12" height="12" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.95395 9.80655L11.6206 16.3615C11.7206 16.4371 11.8377 16.4814 11.9591 16.4895C12.0805 16.4977 12.2016 16.4694 12.3093 16.4077C12.4171 16.3461 12.5073 16.2534 12.5703 16.1397C12.6333 16.026 12.6666 15.8957 12.6666 15.7628V2.65289C12.6667 2.51995 12.6335 2.38952 12.5705 2.27574C12.5076 2.16196 12.4174 2.06917 12.3096 2.00742C12.2019 1.94567 12.0807 1.91731 11.9592 1.92543C11.8378 1.93355 11.7207 1.97783 11.6206 2.05347L2.95395 8.60844C2.86566 8.67576 2.79355 8.76538 2.74376 8.86968C2.69397 8.97398 2.66797 9.08987 2.66797 9.2075C2.66797 9.32513 2.69397 9.44102 2.74376 9.54531C2.79355 9.64961 2.86566 9.73923 2.95395 9.80655V9.80655Z" fill="#667394" />
  </svg>
);

const SuggestionNavRight = () => (
  <svg width="12" height="12" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.69059 16.4145C3.79843 16.4776 3.91951 16.5065 4.04084 16.498C4.16217 16.4896 4.27916 16.4441 4.37925 16.3665L13.0459 9.61649C13.1345 9.54747 13.2068 9.45533 13.2568 9.34796C13.3068 9.24059 13.3329 9.12119 13.3329 8.99999C13.3329 8.87879 13.3068 8.75939 13.2568 8.65202C13.2068 8.54466 13.1345 8.45251 13.0459 8.38349L4.37925 1.63349C4.27923 1.55564 4.16219 1.51004 4.04079 1.50166C3.9194 1.49327 3.79827 1.5224 3.69054 1.5859C3.5828 1.64939 3.49256 1.74484 3.42958 1.8619C3.36661 1.97895 3.33329 2.11316 3.33325 2.24999V15.75C3.33323 15.8869 3.36652 16.0212 3.42951 16.1383C3.4925 16.2555 3.58279 16.351 3.69059 16.4145Z" fill="#667394" />
  </svg>
);

const getTotalBelowSpend = (data, only_new_ads) => {
  let total = 0;
  const mode = only_new_ads ? 'new_ads' : 'all_ads';

  data.forEach((label) => {
    if (label.value === mode) {
      label.losers.forEach((ad) => {
        total += ad.spend;
      })
    }
  })

  return total;
}

const getSuggestions = (data, only_new_ads, ad_labels, spendThreshold) => {
  let results = [];
  let all_percent = 0;

  data.forEach((label) => {
    if (only_new_ads) {
      if (label.value === 'new_ads') {
        all_percent = label.performance_percent;
      }
    } else if (label.value === 'all_ads') {
      all_percent = label.performance_percent;
    }
  })

  EVALUATION_LABELS.forEach((evaluationObj) => {
    let criteriaMet = 0;

    let evaluationLabel = {};
    let comparisonLabel = {};

    let evaluation_percent = 0;
    let comparison_percent = 0;

    let evaluation_cpa = 0;
    let comparison_cpa = 0;
    let others_cpa = 0;

    // first get data for evaluation and comparison labels
    data.forEach((label) => {
      if (label.value === evaluationObj.name) {
        evaluationLabel = label;
        evaluation_percent = label.performance_percent;
      } else if (label.value === evaluationObj.comparison) {
        comparisonLabel = label;
        comparison_percent = label.performance_percent;
      }
    })

    // go through ad_label_report data to get CPA data
    ad_labels.forEach((label) => {
      if (label.value === evaluationObj.name) {
        evaluation_cpa = label.cpr;
        others_cpa = label.other_cpr;
      } else if (label.value === evaluationObj.comparison) {
        comparison_cpa = label.cpr;
      }
    })

    // CRITERIA 1: This number must be greater % than “All Ads” % or “New Ads” depending on “New Ads” or “All Ads” mode
    if (evaluation_percent > all_percent) {
      criteriaMet += 1;
    }

    // CRITERIA 2: This number must be at least 10% greater than the comparison label
    if ((evaluation_percent - comparison_percent) >= 10) {
      criteriaMet += 1;
    }

    // CRITERIA 3: The CPA of the label must be better than “Others”
    if (evaluation_cpa < others_cpa) {
      criteriaMet += 1;
    }

    // CRITERIA 4: The CPA of the label must be better than the comparison label
    if (evaluation_cpa < comparison_cpa) {
      criteriaMet += 1;
    }

    const valid =
      Object.keys(evaluationLabel).length
      && Object.keys(comparisonLabel).length
      && criteriaMet >= 4;

    const getAboveRatio = (evaluationLabel, spendThreshold) => {
      let wins = evaluationLabel.numerator;
      let all = evaluationLabel.denominator;
      let adjusted = 0;

      evaluationLabel.winners.forEach((ad) => {
        if (ad.spend < spendThreshold) {
          wins -= 1;
          all -= 1;
        }
      });
  
      evaluationLabel.losers.forEach((ad) => {
        if (ad.spend < spendThreshold) {
          all -= 1;
        }
      });

      adjusted = Math.round((wins / all) * 100) || 0;

      return adjusted;
    }

    const getBelowRatio = (comparisonLabel, spendThreshold) => {
      let wins = comparisonLabel.numerator;
      let all = comparisonLabel.denominator;
      let adjusted = 0;

      comparisonLabel.winners.forEach((ad) => {
        if (ad.spend < spendThreshold) {
          wins -= 1;
          all -= 1;
        }
      });
  
      comparisonLabel.losers.forEach((ad) => {
        if (ad.spend < spendThreshold) {
          all -= 1;
        }
      });

      adjusted = (100 - Math.round((wins / all) * 100)) || 0;
      return adjusted;
    }

    if (valid) {
      results.push({
        evaluation: {
          label: evaluationLabel.name,
          value: evaluationLabel.value,
        },
        comparison: {
          label: comparisonLabel.name,
          value: comparisonLabel.value,
        },
        above_ratio: getAboveRatio(evaluationLabel, spendThreshold),
        below_ratio: getBelowRatio(comparisonLabel, spendThreshold)
      })
    }
  })

  return results;
}

const Suggestions = ({ data, ad_labels, only_new_ads, start_date, end_date, spendThreshold, }) => {
  const [selection, setSelection] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  const [index, setIndex] = useState(0);
  const [totalBelow, setTotalBelow] = useState(0);
  const start = format(start_date, 'MM/dd/yyyy');
  const end = format(end_date, 'MM/dd/yyyy');

  useEffect(() => {
    setSelection({});
    setSuggestions([]);
    const suggestionData = getSuggestions(data, only_new_ads, ad_labels, spendThreshold);
    setSuggestions(suggestionData);
  }, [data, ad_labels, only_new_ads, spendThreshold]);

  useEffect(() => {
    const total = getTotalBelowSpend(data, only_new_ads);
    setTotalBelow(total)
  }, [data, only_new_ads]);

  useEffect(() => {
    if (suggestions.length) {
      setSelection(suggestions[index]);
    }
  }, [suggestions, index]);

  const next = () => {
    if (index < suggestions.length - 1) {
      setIndex(index + 1);
    } 
  }

  const previous = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  }

  const Label = (
    <span className="page-nav__text">
      {index + 1} of {suggestions.length}
    </span>
  ) 

  return (
    <div className="suggestions">

      <h2 className="suggestions__heading">
        You have spent&nbsp;
        <span className="semibold">{toCurrency(totalBelow)}</span>
        &nbsp;on underperforming creatives between&nbsp;
        <span className="semibold">{start}</span>
        &nbsp;and&nbsp;
        <span className="semibold">{end}</span>
        .
      </h2>

      <div className="suggestions__nav">
        <h4 className="suggestions__list-heading">Improvement Suggestions</h4>
        <div className="page-nav">
          <button className="page-nav__arrow mb-5" type="button" onClick={previous}>
            <SuggestionNavLeft />
          </button>
          {Object.keys(selection).length ? Label : <span className="page-nav__text">0 of 0</span>}
          <button className="page-nav__arrow" type="button" onClick={next}>
            <SuggestionNavRight />
          </button>
        </div>
      </div>

      {Object.keys(selection).length ? (
        <div className="suggestions__list">

          <div className="suggestions__section">
            <h3 className="suggestions__section-heading">Test More Of This</h3>
            <div className="suggestions__block suggestions__block--green">
              <p><span className="semibold">{selection.evaluation.label}</span> ads peform above average in <span className="semibold">{selection.above_ratio}%</span> of ads you are currently running.</p>
            </div>
          </div>

          <div className="suggestions__section">
            <h3 className="suggestions__section-heading">Test Less Of This</h3>
            <div className="suggestions__block suggestions__block--red">
              <p><span className="semibold">{selection.comparison.label}</span> ads peform below average in <span className="semibold">{selection.below_ratio}%</span> of ads you are currently running.</p>
            </div>
          </div>

          {/* PLACEHOLDER */}
          {/* <div className="suggestions__section">
            <h3 className="suggestions__section-heading">
              Performance Impact
            </h3>
            <div className="suggestions__block suggestions__block--blue">
              <p>
                Shifting 50% of spend from <span className="semibold">{selection.comparison.label}</span> ads 
                to <span className="semibold">{selection.evaluation.label}</span> ads would save an estimated $30,000 and reduce your CPA by
                12%.
              </p>
            </div>
          </div> */}

        </div>
      ) : (
        <p>No suggestions to display.</p>
      )}
    </div>
  )
}

export default Suggestions;