import React from 'react';
import { useCopilotContext } from '../contexts/copilotContext';
import { TOGGLE_INCLUDE_DATA } from '../hooks/useCopilotState';

const Checkbox = ({ label, dataType, disabled }) => {
  const { copilotState, copilotDispatch } = useCopilotContext();

  return (
    <div
      className="section-checkbox d-flex align-items-center"
      onClick={() => {
        copilotDispatch({
          type: TOGGLE_INCLUDE_DATA,
          dataType,
        });
      }}
      style={{ cursor: 'default' }}
    >
      <input
        type="checkbox"
        checked={copilotState.dataToInclude[dataType]}
        disabled={disabled}
      />
      <span className="ml-2">{label}</span>
    </div>
  );
};

export default Checkbox;
