import React, { useRef, useState, useEffect } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/bundle';

import './Carousel.scss';
import VisualCard from './VisualCard';
import { useScrollIntoView } from '../../../hooks/useScrollIntoView';
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from '../contexts';
import { useWindowSize } from '../PerformanceFrequency/hooks';

const Carousel = ({ visualsData, section, Header, recsTab, isHeader, isROAS }) => {
  const ref = useRef();
  useScrollIntoView(ref, recsTab === section, {
    behavior: 'smooth',
    block: 'center',
  });
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const { setParams } = useCreativeAnalyticsSet();
  const { requestOpen, rightDrawerDisplay } = useCreativeAnalyticsGet();

  return (
    <div ref={ref} className="visuals-carousel">
      <div className="d-flex justify-content-between align-items-center mb-4">
        {Header}
        <div className="visuals-carousel__nav">
          <div
            ref={prevRef}
            className="visuals-carousel__arrow visuals-carousel__prev"
          >
            <i className="fa fa-caret-left"></i>
          </div>
          <div
            ref={nextRef}
            className="visuals-carousel__arrow visuals-carousel__next"
          >
            <i className="fa fa-caret-right"></i>
          </div>
        </div>
      </div>
      <Swiper
        modules={[Navigation]}
        spaceBetween={50}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
        }}
        slidesPerView={requestOpen || !!rightDrawerDisplay ? 3 : 4}
        slidesPerGroup={requestOpen || !!rightDrawerDisplay ? 3 : 4}
        observeParents={true}
        observer={true}
        allowTouchMove={false}
        noSwiping
        // breakpoints={{
        //   // when window width is >= 640px
        //   640: {
        //     width: 640,
        //     slidesPerView: 1,
        //   },
        //   // when window width is >= 768px

        //   992: {
        //     width: 992,
        //     slidesPerView: 2,
        //   },
        //   1500: {
        //     width: 1500,
        //     slidesPerView: 3,
        //   },
        // }}
      >
        {Object.entries(visualsData).map(([id, visualObj], index) => {
          if (visualObj.visuals.length) {
            return (
              <SwiperSlide>
                <VisualCard {...{ visualObj, isROAS, setParams }} index={index + 1} section={section} isHeader={isHeader} />
              </SwiperSlide>
            );
          }
        })}
      </Swiper>
    </div>
  );
};

export default Carousel;
