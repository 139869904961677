import React, { useMemo } from 'react';
import { DrawerSection } from '../Drawer/DrawerSection';
import { VisualPreview } from '../VisualViewer/Preview';
import { VisualInfo } from '../VisualViewer/VisualInfo';
import { LabelTag, LabelsList } from '../Drawer/LabelsList';
import { SET_VIEW } from '../../hooks/useExplorerState';
import { CATEGORY_TYPES_INDEX, CATEGORY_TYPE_LABELS } from '../../constants';
import { DrawerButtonsWrapper } from '../Button/DrawerButtonsWrapper';
import { DrawerButton } from '../Button/DrawerButton';
import { Toolbar } from '../Drawer/Toolbar';
import { DERIVED_LABELS } from '../../constants';
import { getCategoryOverview } from '../../utils/getCategoryOverview';
import { useDependencies } from '../../hooks/useDependencies';
import { useCreativeAnalyticsGet } from '../../../contexts';
import {
  CategoriesLookup,
  CategoriesLookupItem,
  VisualsLookupItem,
} from '../../../ReportCard/types';
import { DrawerAction } from '../../types';

const getCategoriesList = (categoriesLookup) => {
  return Object.entries(categoriesLookup).map(([key, val]) => {
    return {
      ...val,
      category: key,
    };
  });
};

type CategoryViewerProps = {
  category_name: string;
  selectedVisualId?: string;
  currentVisual?: VisualsLookupItem;
  dispatch: React.Dispatch<DrawerAction>;
  subDrawerDispatch: string;
  handleBack: () => void;
  handleClose: () => void;
  previewIsVisible: string;
  openReportCard: () => void;
  isCopied: boolean;
  handleCopyId: () => void;
};

export const CategoryViewer = ({
  category_name,
  selectedVisualId,
  currentVisual,
  dispatch,
  subDrawerDispatch,
  handleBack,
  handleClose,
  previewIsVisible,
  openReportCard,
  isCopied,
  handleCopyId,
}: CategoryViewerProps) => {
  const { categoriesLookup }: CategoriesLookup = useCreativeAnalyticsGet();
  const categories = currentVisual
    ? currentVisual.categories
    : getCategoriesList(categoriesLookup);
  const categoryObj = categories.find((x) => x.category === category_name) ?? {
    category_id: null,
    ccc_type: null,
    labels: null,
    ccc_desc: null,
  };
  if (!categoryObj) return <p>There was an error.</p>;
  const {
    category_id,
    ccc_type,
    labels: categoryLabels,
    ccc_desc,
  } = categoryObj;
  const { label: categoryTypeLabel = null, icon: Icon = null } =
    CATEGORY_TYPES_INDEX[ccc_type] || {};
  const customOverview = getCategoryOverview({ category_name });
  const { dependencies, filteredDependencies, dependents } = useDependencies({
    category_name,
    ccc_type,
  });

  const allowEdit = ccc_type !== 'standard' && !customOverview;
  const showCloseButtonInToolbar =
    !selectedVisualId || !currentVisual || !previewIsVisible;

  const onClickBack = () => {
    if (selectedVisualId && currentVisual) {
      dispatch({
        type: SET_VIEW,
        currentView: {
          type: 'visual_viewer',
          id: selectedVisualId,
        },
      });
    } else {
      handleBack();
    }
  };

  const handleEditCategory = (
    specifiedCategory?: string,
    showDerivativeFlow = false
  ) => {
    const specifiedCategoryData: CategoriesLookupItem = specifiedCategory
      ? categoriesLookup[specifiedCategory]
      : null;

    const categoryToEdit = specifiedCategoryData
      ? {
          category_name: specifiedCategory,
          ccc_type: specifiedCategoryData.ccc_type,
          category_id: specifiedCategoryData.category_id,
          visual_hash: selectedVisualId,
        }
      : { category_name, ccc_type, category_id, visual_hash: selectedVisualId };

    subDrawerDispatch({
      type: SET_VIEW,
      currentView: {
        type: 'ccc_editor',
        category: categoryToEdit,
        opts: {
          showDerivativeFlow,
        },
      },
    });
  };

  const { inThisVisual, notInThisVisual } = useMemo(() => {
    let yes = [];
    let no = [];

    categoryLabels?.forEach((label) => {
      if (label.visuals?.length) {
        if (label.visuals?.some((x) => x.id === selectedVisualId)) {
          yes.push(label);
        } else {
          no.push(label);
        }
      }
    });

    return { inThisVisual: yes, notInThisVisual: no };
  }, [selectedVisualId, categoryLabels]);

  const dependencyOptions =
    filteredDependencies?.size > 0
      ? Array.from(filteredDependencies, ([category, arr]) => {
          const dep_ccc_type = categoriesLookup[category]?.ccc_type;
          const { icon: DepIcon } = CATEGORY_TYPES_INDEX[dep_ccc_type] ?? {
            DepIcon: null,
          };

          return (
            <DrawerButton onClick={() => handleEditCategory(category)}>
              <div className="d-flex justify-content-between p-1">
                <span>{category}</span>
                {DepIcon && (
                  <DepIcon
                    className={`drawer-section__icon drawer-section__icon--nested`}
                  />
                )}
              </div>
            </DrawerButton>
          );
        })
      : null;

  let openReportCardBtn = !!selectedVisualId ? (
    <DrawerButton onClick={openReportCard}>Open report card</DrawerButton>
  ) : null;

  let overviewContent = (
    <div>
      <p className="text-block">
        This is a {ccc_type} category and cannot be edited.
      </p>
    </div>
  );

  if (allowEdit) {
    if (ccc_type === DERIVED_LABELS) {
      overviewContent = (
        <div>
          <DrawerButtonsWrapper show={allowEdit}>
            <DrawerButton onClick={handleEditCategory}>
              Open category editor
            </DrawerButton>
          </DrawerButtonsWrapper>

          <DrawerButtonsWrapper show={!!dependencyOptions}>
            <div className="mt-4">
              <p className="text-block">
                Labels in this category are derived from other labels in the
                following categories:
              </p>
              {dependencyOptions}
            </div>
          </DrawerButtonsWrapper>
        </div>
      );
    } else {
      overviewContent = (
        <div>
          {ccc_desc && <p>{ccc_desc}</p>}
          <DrawerButtonsWrapper show={allowEdit}>
            <DrawerButton onClick={handleEditCategory}>
              Open category editor
            </DrawerButton>
          </DrawerButtonsWrapper>
        </div>
      );
    }
  }

  let rightToolbarIcon = null;

  if (showCloseButtonInToolbar) {
    rightToolbarIcon = (
      <button>
        <i
          style={{ fontSize: '1.3rem' }}
          className="drawer__close fa fa-xmark"
          onClick={handleClose}
        ></i>
      </button>
    );
  } else if (allowEdit) {
    rightToolbarIcon = (
      <button>
        <i
          style={{ fontSize: '1rem' }}
          className="drawer-toolbar__icon fa fa-pencil"
          onClick={handleEditCategory}
        ></i>
      </button>
    );
  }

  if (customOverview) {
    overviewContent = (
      <div>
        <p className="text-block">{customOverview}</p>
      </div>
    );
  }

  return (
    <div className="category-viewer">
      <Toolbar
        left={
          <button className="drawer-toolbar__back" onClick={onClickBack}>
            <i className="fas fa-arrow-left"></i>
          </button>
        }
        center={
          <p title={category_name} className="drawer-toolbar__title">
            {category_name}
          </p>
        }
        right={rightToolbarIcon}
      />

      <DrawerSection
        className={`drawer-section--${ccc_type}`}
        title={`${CATEGORY_TYPE_LABELS[ccc_type] || ccc_type} Category`}
        icon={
          Icon && (
            <Icon
              onClick={() => handleEditCategory(category_name, true)}
              className={`drawer-section__icon drawer-section__icon--${ccc_type}`}
            />
          )
        }
        iconTooltip={categoryTypeLabel}
        content={overviewContent}
      />

      {!!selectedVisualId && (
        <DrawerSection
          title={`${isCopied ? 'Copied! ✓' : 'Visual ID'}`}
          onClick={handleCopyId}
          content={
            <div>
              <p
                style={{
                  fontFamily: 'Fira Code',
                  marginBottom: '1rem',
                  fontSize: '1.1rem',
                  fontWeight: 500,
                }}
              >
                {selectedVisualId}
              </p>
              {openReportCardBtn}
            </div>
          }
        />
      )}

      <DrawerSection
        show={inThisVisual.length > 0}
        title="In this visual"
        content={<LabelsList labels={inThisVisual} />}
      />

      <DrawerSection
        show={notInThisVisual.length > 0}
        title={selectedVisualId ? 'Not in this visual' : 'Labels'}
        content={<LabelsList labels={notInThisVisual} />}
      />

      {dependents.length > 0 && (
        <DrawerSection
          title="Dependents"
          titleTooltip="Other labels that are derived from labels in this category."
          content={
            <DrawerButtonsWrapper>
              {dependents.map((dependent) => {
                return (
                  <DrawerButton
                    onClick={() =>
                      handleEditCategory(dependent.derived_category)
                    }
                    className="p-0"
                  >
                    <DrawerButton.Section>
                      <LabelTag
                        style={{ margin: 0 }}
                        name={dependent.derived_label}
                      />
                    </DrawerButton.Section>
                    <DrawerButton.Section className="flex-column align-items-start">
                      <div style={{ flex: 1, marginBottom: '0.6rem' }}>
                        derived from
                      </div>
                      <LabelTag
                        style={{ margin: 0, flex: 1 }}
                        name={dependent.derived_from}
                      />
                    </DrawerButton.Section>
                  </DrawerButton>
                );
              })}
            </DrawerButtonsWrapper>
          }
        />
      )}
    </div>
  );
};
