import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setReports } from '../../../store/CreativeReports/actions';
import { resetAnalytics } from '../../../store/CreativeAnalytics/creativeAnalyticsSlice';

export const useInit = ({
  view_id,
  insights_by_asset,
  period1Start,
  period1End,
  period1,
  newAdsApi,
}) => {
  const dispatch = useDispatch();

  const resetReportsStore = () => {
    dispatch(
      setReports({
        only_new_ads: false,
        selected_type: 'suggestions',
        performance_frequency: [],
      })
    );
  };

  useEffect(() => {
    resetReportsStore();
    dispatch(resetAnalytics());
    newAdsApi.get.request({
      view_id,
      start_date: period1Start,
      end_date: period1End,
      insights_by_asset,
    });
  }, [period1]);
};
