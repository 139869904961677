import React from 'react';
import PropTypes from 'prop-types';

import './_YellowBars.css';

export const YellowHR = ({ style }) => <hr style={style} className="yellow-hr" />;

YellowHR.defaultProps = {
  style: {},
};

YellowHR.propTypes = {
  style: PropTypes.shape({ color: PropTypes.string }),
};

const YellowBars = ({ style = null, children }) => (
  <>
    <YellowHR style={style} />
    {children}
  </>
);

YellowBars.defaultProps = {
  style: {},
};

YellowBars.propTypes = {
  style: PropTypes.shape({ color: PropTypes.string }),
  children: PropTypes.node.isRequired,
};

export default YellowBars;
