import React, { useEffect, useCallback, useReducer, useMemo } from 'react';
import { Drawer } from './components/Drawer/Drawer';
import { useExplorerContext } from './contexts/explorerContext';
import { ExplorerState } from './types';
import Overlay from '../../../components/Overlay';
import { CLOSE, GO_BACK } from './hooks/useExplorerState';
import { ErrorBoundary } from '../../../components/Helpers/Error';
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from '../contexts';
import { useSearchParams } from '../../../components/Hooks/search-params';

const Wrapper = (props) => {
  const {
    drawerState,
    drawerDispatch,
    subDrawerState,
    subDrawerDispatch,
  }: ExplorerState = useExplorerContext();
  const { setParams } = useSearchParams();
  const { display } = useCreativeAnalyticsGet();
  const keepState = ['variation', 'copilot', 'draft-creative'].includes(
    display
  );

  useEffect(() => {
    drawerDispatch({
      type: CLOSE,
      keepState: false,
    });
  }, [display]);

  useEffect(() => {
    if (!drawerState.currentView) return () => {};
    if (drawerState.currentView.type === 'category_viewer') {
      setParams({ category: drawerState.currentView.id });
    } else {
      setParams({ category: '' });
    }
  }, [drawerState.currentView]);

  const closeBoth = () => {
    subDrawerDispatch({ type: CLOSE, keepState });
    drawerDispatch({ type: CLOSE, keepState });
  };

  const drawerProps = {
    ...drawerState,
    ...props,
    dispatch: drawerDispatch,
    subDrawerDispatch,
    handleClose: closeBoth,
    handleBack: () => drawerDispatch({ type: GO_BACK }),
    keepState,
  };

  const subDrawerProps = {
    ...subDrawerState,
    ...props,
    dispatch: subDrawerDispatch,
    subDrawerDispatch,
    drawerState,
    handleClose: () => subDrawerDispatch({ type: CLOSE, keepState }),
    handleBack: () => subDrawerDispatch({ type: GO_BACK }),
  };

  if (!drawerState.isOpen) return null;

  const Backdrop = <div className="explorer-backdrop" />;

  return (
    <div className="explorer">
      <ErrorBoundary
        fallback={
          <div className="drawer">
            <p onClick={closeBoth}>There was an error. Click to exit.</p>
          </div>
        }
      >
        <Drawer {...drawerProps} />
        {subDrawerState.isOpen && <Drawer {...subDrawerProps} />}
        {subDrawerState.isOpen && Backdrop}
      </ErrorBoundary>
    </div>
  );
};

export default Wrapper;
