import { batch } from 'react-redux';

import { TRACK_EVENT, FETCH_TRACKING_EVENTS, USER_ACTIVITY } from './actions';

import { API_SUCCESS, apiRequest, API_FAILURE } from '../Api/actions';
import { setLoader } from '../Loaders/actions';
import { setError } from '../Errors/actions';
import { toDarwinDate } from '../../utils/darwin-dates';

const middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case TRACK_EVENT: {
        const {
          ViewSettings: {
            user_id,
            darwin_client_id,
            user_first_name,
            viewId: view_id,
            viewName,
          },
        } = getState();
        const url = window.location.href;
        const { event_category, event_type, rest } = action.data;

        dispatch(setError(USER_ACTIVITY, false));

        const body = {
          user_id,
          darwin_client_id,
          user_first_name,
          view_id,
          view_name: viewName,
          url,
          event_category,
          event_type,
          ...rest,
        };

        batch(() => {
          dispatch(setLoader(USER_ACTIVITY, true));
          dispatch(
            apiRequest({
              entity: USER_ACTIVITY,
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Cache-control': 'no-cache',
              },
              url: ['api', 'user-events', 'save'].join('/'),
              body,
            })
          );
        });

        break;
      }

      case `[${USER_ACTIVITY}] ${API_SUCCESS}`: {
        batch(() => {
          dispatch(setLoader(USER_ACTIVITY, false));
        });
        break;
      }

      case `[${USER_ACTIVITY}] ${API_FAILURE}`: {
        const { error } = action;
        console.log('track event error');
        console.log(error);

        batch(() => {
          dispatch(setError(USER_ACTIVITY, error));
          dispatch(setLoader(USER_ACTIVITY, false));
        });

        break;
      }

      default:
        return result;
    }

    return result;
  };

export default middleware;
