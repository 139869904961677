import { useEffect, useState } from 'react';

import { toDarwinDate, daysFrom } from '../../../utils/darwin-dates';
import { useSearchParams } from '../../../components/Hooks/search-params';

function isEmpty(str) {
  return !str || str === 'null' || str === 'undefined';
}

function setPeriod2(now) {
  return [daysFrom(now, -7), daysFrom(now, -1)].map(toDarwinDate).join(',');
}

/**
 * @param {{ breakdown?: string, period1?: string, period2?: string, include_ga_data?: boolean } }} params
 */
function setDefaultParams({ breakdown, period1, period2, include_ga_data }) {
  const now = new Date();
  const base = {
    breakdown: breakdown || 'trending',
    period1: period1 || [now, now].map(toDarwinDate).join(','),
    include_ga_data: include_ga_data || false,
  };

  if (base.breakdown === 'trending') {
    base.period2 = isEmpty(period2) ? '' : period2;
  }

  return base;
}

/**
 * @returns {[{ breakdown: string, period1: string, period2?: string, include_ga_data?: boolean }, Function]}
 */
export function useBlockParams() {
  const { getParams, setParams } = useSearchParams();
  const params = getParams('breakdown', 'period1', 'period2') || {};
  const defaults = setDefaultParams(params);
  const [state, setState] = useState(defaults);

  const makeUpdate = (prev, update) => {
    const merged = { ...prev, ...update };

    if (
      (merged.breakdown === 'trending' && isEmpty(merged.period2)) ||
      merged.breakdown !== 'trending'
    ) {
      return { ...merged, period2: '' };
    }
    return merged;
  };

  const setStateMerge = (update) =>
    setState((prev) => makeUpdate(prev, update));

  useEffect(() => {
    setParams(state);
  }, [state.breakdown, state.period1, state.period2, state.include_ga_data]);

  return [state, setStateMerge];
}
