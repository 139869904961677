import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';

const STYLES = {
  control: (provided) => ({
    ...provided,
    minHeight: '31px',
    height: '31px',
    alignItems: 'center',
  }),

  valueContainer: (provided) => ({
    ...provided,
    position: 'relative',
    bottom: '4px',
  }),

  indicatorsContainer: (provided) => ({
    ...provided,
    position: 'relative',
    bottom: '4px',
  }),
};

function toOption(category) {
  return { label: category, value: category };
}

export default function CategorySelect({
  categories, onChange, value, disabled, maxInputLength,
}) {
  const [options, setOptions] = useState(categories.map(toOption));

  const handleChange = (selected) => onChange(selected ? selected.value : null);
  const handleCreate = (val) => {
    const adjusted = val.length <= maxInputLength
      ? val
      : val.slice(0, maxInputLength);

    setOptions((prev) => [...prev, { value: adjusted, label: adjusted }]);
    onChange(adjusted);
  };

  return (
    <CreatableSelect
      isClearable
      isSearchable
      options={options}
      value={value ? { value, label: value } : null}
      isDisabled={disabled}
      onChange={handleChange}
      onCreateOption={handleCreate}
      styles={STYLES}
      placeholder="Select or create a category..."
    />
  );
}

CategorySelect.defaultProps = {
  categories: [],
  value: null,
  maxInputLength: 50,
};

CategorySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  maxInputLength: PropTypes.number,
};
