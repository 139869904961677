import React, { useCallback, useRef, useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import ReactQuill from 'react-quill';
import Select from 'react-select';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import CreatableSelect from 'react-select/creatable';
import { IconPicker } from 'react-fa-icon-picker';

import './styles.scss';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import Button from '../../../../components/Button';
import { Palette } from './Palette';
import Tooltip from '../../../../components/Tooltip';

export const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
  ],
};

export const modulesNoEdit = {
  toolbar: false,
};

export const minimalFormats = ['bold', 'link'];

export const minimalModules = {
  toolbar: [['bold', 'link']],
};

export const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

export const InputField = ({
  label,
  value,
  onChange,
  type = 'text',
  onBlur,
  onFocus,
  ...rest
}) => {
  return (
    <div className="input-field">
      {label && <label>{label}</label>}
      <input
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </div>
  );
};

export const TextAreaField = ({
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  ...rest
}) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    textareaRef.current.style.height = '0px';
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = scrollHeight + 8 + 'px';
  }, [value]);

  return (
    <div className="input-field">
      {label && <label>{label}</label>}
      <textarea
        ref={textareaRef}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </div>
  );
};

export const SelectField = ({ label, value, options, onChange }) => {
  return (
    <div className="input-field">
      <label>{label}</label>
      <Select
        value={value || options.find((opt) => opt.value === value)}
        options={options}
        onChange={(selected) => onChange(selected.value)}
      />
    </div>
  );
};

export const DateField = ({ label, date, onChange, isClearable, onClick }) => {
  return (
    <div className="input-field" onClick={onClick}>
      {label && <label htmlFor="">{label}</label>}
      <DatePicker
        selected={date}
        className="text-center"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        onChange={onChange}
        popperClassName="input-field__datepicker"
        popperProps={{
          positionFixed: true,
        }}
        popperPlacement="left"
        placeholderText="Choose a date"
        isClearable={isClearable}
      />
    </div>
  );
};

export const CreatableSelectField = ({ label, value, options, onChange }) => {
  return (
    <div className="input-field">
      <label htmlFor="">{label}</label>
      <CreatableSelect
        isSearchable
        options={options}
        value={value}
        onChange={(selected) => onChange(selected.value)}
        placeholder="test group"
        noOptionsMessage={() => null}
        formatCreateLabel={(userInput) => `Create test group "${userInput}"`}
        createOptionPosition="first"
      />
    </div>
  );
};

export const ColorField = ({
  label,
  color = '#3D4868',
  onChange,
  handleSave,
  isKL,
  loading,
  existingPalette = [],
}) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);
  const hasPalette = existingPalette.length > 0;

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  return (
    <div className="input-field color-picker">
      <div className="d-flex align-items-center justify-content-between">
        {label && <label htmlFor="">{label}</label>}
        <div
          className="swatch"
          style={{ backgroundColor: color }}
          onClick={() => toggle(true)}
        />
      </div>

      {isOpen && (
        <div className="color-popover" ref={popover}>
          <HexColorPicker color={color} onChange={onChange} />
          <HexColorInput color={color} onChange={onChange} />
          {isKL && (
            <Button
            className="p-1"
              appearance="secondary"
              onClick={() => {
                handleSave();
                close();
              }}
              loading={loading}
              disabled={loading}
            >
              Save
            </Button>
          )}
          {hasPalette && <Palette colors={existingPalette} onChange={onChange} />}
        </div>
      )}
    </div>
  );
};

export const IconField = ({
  label,
  icon,
  color,
  onChange,
  containerStyles,
  onClick,
}) => {
  const BUTTON_STYLES = {
    border: 'none',
    height: '32px',
    width: '32px',
    minHeight: '32px',
    padding: '0',
  };

  const BUTTON_ICON_STYLES = {
    color: color,
    height: '32px',
    width: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
  };

  const CONTAINER_STYLES = {
    width: '280px',
    top: '0',
    right: '40px',
    border: '1px solid #e2e6f0',
    maxHeight: '280px',
    boxShadow: '0 8px 16px 0 #00000020',
    ...containerStyles,
  };

  const SEARCH_INPUT_STYLES = {
    marginBottom: '8px',
  };

  return (
    <div className="input-field icon-picker" onClick={onClick}>
      {label && <label htmlFor="">{label}</label>}
      <IconPicker
        value={icon}
        onChange={onChange}
        containerStyles={CONTAINER_STYLES}
        buttonStyles={BUTTON_STYLES}
        pickerIconStyles={BUTTON_ICON_STYLES}
        buttonIconStyles={BUTTON_ICON_STYLES}
        searchInputStyles={SEARCH_INPUT_STYLES}
      />
    </div>
  );
};

export const TextEditorField = ({ label, value, onChange, hideToolbar }) => {
  return (
    <div className="text-editor-field">
      {label && <label>{label}</label>}
      <ReactQuill
        key="1"
        className="1"
        theme="snow"
        value={value}
        onChange={onChange}
        modules={hideToolbar ? modulesNoEdit : modules}
        formats={formats}
        readOnly={false}
      />
    </div>
  );
};

export const MinimalTextEditorField = ({
  label,
  value,
  onChange,
  hideToolbar,
}) => {
  return (
    <div className="text-editor-field">
      {label && <label>{label}</label>}
      <ReactQuill
        key="1"
        className="1"
        theme="snow"
        value={value}
        onChange={onChange}
        modules={hideToolbar ? modulesNoEdit : minimalModules}
        formats={minimalFormats}
        readOnly={hideToolbar ?? false}
      />
    </div>
  );
};

export const CheckboxField = ({
  label,
  checked,
  onClick,
  tooltip,
  CustomCheckbox,
}) => {
  return (
    <div className="text-editor-field d-flex align-items-center" onClick={onClick}>
      {CustomCheckbox ? <div>{CustomCheckbox}</div> : <input
        type="checkbox"
        checked={checked}
      ></input>}
      <div className="d-inline-flex">
        {label && <label className="mb-0 ml-2">{label}</label>}
        {tooltip && (
          <div>
            <Tooltip
              content={tooltip}
            >
              <div className="info-circle-question" />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  )
}
