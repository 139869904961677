import React, { useEffect, useReducer, useRef } from 'react';
import _ from 'lodash';
import {
  ADD_CONDITION,
  REMOVE_CONDITION,
  UPDATE_CONDITION,
  successConditionsReducer,
} from './reducer';
import { useViewSettings } from '../../../selectors';
import { useMetricOptions } from '../hooks/useMetricOptions';
import { Condition } from './types';
import './styles.scss';
import clsx from 'clsx';
import Button from '../../../components/Button';
import { useUpdateSucesssConditions, useViewSettingsPayload } from './hooks';
import { useViewSettingsApi } from '../../BulkActions/ViewSettings/api';
import { isEqual } from 'lodash';
import {
  notifyError,
  useNotifications,
} from '../../../components/Notification/useNotifications';
import { validate } from './validate';
import Tooltip from '../../../components/Tooltip';

const METRIC_OPTS = [
  {
    label: 'CPA',
    value: 'cpa',
  },
  {
    label: 'Spend',
    value: 'spend',
  },
  {
    label: 'CPM',
    value: 'cpm',
  },
];

const COMPARISON_OPTS = [
  {
    label: 'is greater than',
    value: 'greater',
  },
  {
    label: 'is greater than or equal to',
    value: 'greater_or_equal',
  },
  {
    label: 'is less than',
    value: 'less',
  },
  {
    label: 'is less than or equal to',
    value: 'less_or_equal',
  },
  {
    label: 'is equal to',
    value: 'equal',
  },
];

const useExistingSuccessConditions = () => {
  const { visual_success_conditions } = useViewSettings();

  return visual_success_conditions ?? [];
};

export const SuccessConditions = () => {
  const existing: Condition[] = useExistingSuccessConditions();
  const [conditions, dispatch] = useReducer(successConditionsReducer, existing);
  const metricOptions: { label: string; value: string }[] = useMetricOptions();
  const notifications = useNotifications();
  const viewSettingsApi = useViewSettingsApi();
  const updateViewSettings = useUpdateSucesssConditions();
  const { payload, isLoading, error } = useViewSettingsPayload();
  const prev = useRef(existing);
  const cache = useRef(prev.current);
  const isSaved = _.isEqual(prev.current, conditions);
  const errors = validate(conditions);

  useEffect(() => {
    if (viewSettingsApi.put.data) {
      notifications.add({
        title: 'Success!',
        message: 'Success conditions saved.',
      });
      prev.current = [...cache.current];
    }
  }, [viewSettingsApi.put.data]);

  useEffect(() => {
    if (viewSettingsApi.put.error) {
      updateViewSettings(prev.current);
      cache.current = [...prev.current];
      notifyError(
        'There was an error saving success conditions.',
        'Please try again.'
      );
    }
  }, [viewSettingsApi.put.error]);

  const handleAddNew = () => {
    dispatch({ type: ADD_CONDITION });
  };

  const handleRemove = (_id) => {
    dispatch({ type: REMOVE_CONDITION, _id });
  };

  const handleUpdate = (_id, field, value) => {
    dispatch({ type: UPDATE_CONDITION, _id, update: { field, value } });
  };

  const handleSave = () => {
    updateViewSettings(conditions);
    cache.current = [...conditions];
    viewSettingsApi.put.request({
      ...payload,
      visual_success_conditions: conditions,
    });
  };

  if (isLoading) {
    return <p>Loading view settings...</p>;
  }

  return (
    <div className="success-conditions">
      <div>
        <h5 className="success-conditions__title">
          A visual is considered successful if:{' '}
        </h5>
        <ul className="success-conditions-list">
          {conditions.map((cond) => {
            return (
              <Condition
                cond={cond}
                handleRemove={handleRemove}
                handleUpdate={handleUpdate}
                metricOptions={metricOptions}
              />
            );
          })}
        </ul>
        <button
          className="success-conditions__add-btn"
          onClick={(e) => {
            e.preventDefault();
            handleAddNew();
          }}
        >
          + Add condition
        </button>
      </div>

      <Tooltip shouldShow={!!errors.length} content={(
        <ul style={{ listStyle: 'none', padding: 0, margin: 0}}>
          {errors.map((err) => <li className="m-0">{err}</li>)}
        </ul>
      )}>
        <div className="success-conditions-footer d-flex w-100 align-items-center justify-content-between">
          <Button
            onClick={handleSave}
            disabled={viewSettingsApi.put.loading || isSaved || !!errors.length}
          >
            Save changes
          </Button>
        </div>
      </Tooltip>
    </div>
  );
};

const Condition = ({ cond, handleRemove, handleUpdate, metricOptions }) => {
  const { _id, metric, comparison, value } = cond;
  const isAverage = value === 'avg';

  return (
    <li key={_id}>
      <select
        name="metric"
        value={metric}
        onChange={(e) => handleUpdate(_id, 'metric', e.target.value)}
        className="mr-3"
      >
        <option>-</option>
        {metricOptions.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </select>
      <select
        name="comparison"
        value={comparison}
        onChange={(e) => handleUpdate(_id, 'comparison', e.target.value)}
        className="mr-3"
      >
        <option>-</option>
        {COMPARISON_OPTS.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </select>
      <div style={{ position: 'relative' }}>
        <input
          name="value"
          value={isAverage ? 'Average' : value}
          placeholder={isAverage ? 'Average' : value}
          onChange={(e) => handleUpdate(_id, 'value', e.target.value)}
          className={clsx('mr-3 d-block', {
            'is-avg': isAverage,
          })}
          disabled={isAverage}
          type="number"
        ></input>
        <span
          style={{
            position: 'absolute',
            bottom: -21,
            left: 0,
            fontSize: '10px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <input
            type="checkbox"
            className="mr-1"
            checked={isAverage}
            onChange={(e) =>
              handleUpdate(_id, 'value', e.target.checked ? 'avg' : 0)
            }
          />
          Average
        </span>
      </div>

      <button
        onClick={() => handleRemove(_id)}
        className="success-conditions__delete-btn"
      >
        <i className="fa fa-trash" aria-hidden="true"></i>
      </button>
    </li>
  );
};
