import React from 'react';
import { DARWIN_NAVY } from '../../../../constants';

export const CaretRightIcon = () => {
  return (
    <svg
      width="8"
      height="13"
      viewBox="0 0 8 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.10434 12.3057C1.02534 12.2266 0.980967 12.1193 0.980967 12.0075C0.980967 11.8957 1.02534 11.7885 1.10434 11.7094L6.43052 6.38254L1.10435 1.05567C1.02983 0.975694 0.989257 0.869918 0.991185 0.760624C0.993114 0.651329 1.03739 0.54705 1.11468 0.469755C1.19198 0.392461 1.29626 0.348185 1.40555 0.346257C1.51485 0.344329 1.62062 0.384898 1.7006 0.459418L7.3256 6.08442C7.4046 6.16352 7.44897 6.27075 7.44897 6.38254C7.44897 6.49434 7.4046 6.60157 7.3256 6.68067L1.7006 12.3057C1.62149 12.3847 1.51427 12.429 1.40247 12.429C1.29067 12.429 1.18345 12.3847 1.10434 12.3057Z"
        fill={DARWIN_NAVY}
      />
    </svg>
  );
};
