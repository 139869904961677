import { LabelObject, VisualObject } from '../types';

export const normalizeVisuals = (metricsData: VisualObject[]) => {
  const ids = metricsData.map((item) => item.id || item._id);

  const lookup = metricsData.reduce<Map<string, VisualObject>>((acc, item) => {
    acc.set(item.id, item);
    return acc;
  }, new Map<string, VisualObject>());

  return { ids, lookup };
};

export const normalizeLabels = (data: LabelObject[]) => {
  const ids = data.map(
    (label) => label.id ?? `${label.category}->${label.name}`
  );

  const lookup = data.reduce<Map<string, LabelObject>>((acc, label) => {
    acc.set(label.id ?? `${label.category}->${label.name}`, label);
    return acc;
  }, new Map<string, LabelObject>());

  return { ids, lookup };
};
