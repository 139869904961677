/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { format } from 'date-fns';
import RoleAccess from '../../../../../components/RoleAccess';
import { useFetchReports, usePostCreativeRequests } from '../api';
import LabelMatrix from '../../../../../components/LabelMatrix/Matrix';
import { ASSET_DROPDOWN_STYLES } from '../../Preview/constants';
import { Loading } from '../../../../../components/Helpers/Loading';
import { strToNum } from '../../../../../utils/numbers';
import './CreativeRequest.scss';

import {
  ExpandIcon, CloseIcon, CloseWindowIcon, TalentIcon, BrandIcon, ResultsIcon, YellowBar, ThumbsUpIcon, HangTightIcon, HelpIcon, CreativeIcon, InfoIcon,
} from '../Helpers';

const CreativeRequest = ({ setShowRequestForm }) => {
  const [stage, setStage] = useState(0);
  return (
    <div className="creative-request-wrapper">
      <div className={stage === 0 ? 'creative-request no-scroll' : 'creative-request'}>
        <YellowBar />
        {
          stage === 0 ? (
            <InfoScreen setShowRequestForm={setShowRequestForm} setStage={setStage} />
          ) : (
            <CreativeRequestForm setShowRequestForm={setShowRequestForm} />
          )
        }
      </div>
    </div>
  );
};

const PLATFORM_OPTIONS = [
  { label: 'Facebook/Instagram', value: 'facebook_ig' },
  { label: 'Snapchat', value: 'snapchat' },
  { label: 'Pinterest', value: 'pinterest' },
  { label: 'Twitter', value: 'twitter' },
  { label: 'Linkedin', value: 'linkedin' },
  { label: 'Gmail', value: 'gmail' },
  { label: 'GDN', value: 'gdn' },
  { label: 'Tik Tok', value: 'tik_tok' },
  { label: 'Other', value: 'other' },
];

const newRequest = {
  request_type: 'static',
  creative_freedom: false,
  platform: 'facebook_ig',
  labels: [],
  description: '',
};

const date = new Date();
const formattedDate = format(date, 'M/d/yyyy');

const CreativeRequestForm = ({ setShowRequestForm }) => {
  const reportsApi = useFetchReports();
  const creativeRequestsApi = usePostCreativeRequests();
  const [reportsData, setReportsData] = useState({});
  const [projectName, setProjectName] = useState(`Client Project ${formattedDate}`);
  const [requestData, setRequestData] = useState([]);
  const [currentRequest, setCurrentRequest] = useState(newRequest);
  const [temp, setTemp] = useState({});
  const [isPrevious, setIsPrevious] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [submit, setSubmit] = useState(false);
  const [formError, setFormError] = useState('');

  const [expanded, setExpanded] = useState({
    left: false,
    right: false,
  });

  const {
    viewId, audience, campaigns, account_id, start_date, end_date,
  } = useSelector(
    ({
      ViewSettings: {
        viewId,
        audience,
        campaigns,
        account_id,
        start_date,
        end_date,
      },
    }) => ({
      viewId, campaigns, audience, account_id, start_date, end_date,
    }),
  );

  const payload = {
    report_type: 'ad_labels',
    audience,
    campaigns,
    account_id,
    view_type: 'all_labels',
    start_date,
    end_date,
  };

  useEffect(() => {
    reportsApi.post.request(viewId, payload);
  }, []);

  useEffect(() => {
    if (reportsApi.post.data) {
      const { data } = reportsApi.post;
      setReportsData(data);
    }
  }, [reportsApi.post.data]);

  useEffect(() => {
    if (submit === true) {
      creativeRequestsApi.post.request(viewId, {
        account_id,
        view_id: viewId,
        project_name: projectName,
        requests: requestData,
      });
      setSubmit(false);
    }
  }, [requestData]);

  useEffect(() => {
    if (Object.keys(temp).length > 0) {
      setIsPrevious(true);
    } else {
      setIsPrevious(false);
    }
  }, [currentIndex]);

  // MEDIA QUERIES

  const desktop = useMediaQuery({
    query: '(min-width:  768px)',
  });

  const mobile = useMediaQuery({
    query: '(max-width:  767px)',
  });

  const checkDevice = () => {
    if (desktop) {
      setExpanded({
        left: true,
        right: true,
      });
    }
  };

  useEffect(() => {
    checkDevice();
  }, [desktop, mobile]);

  const toggleExpand = (key) => {
    setExpanded((prev) => ({
      ...prev,
      [key]: !expanded[key],
    }));
  };

  const handleInput = (key, value) => {
    if (key === 'project_name') {
      setProjectName(value);
    } else {
      setCurrentRequest((prev) => ({
        ...prev,
        [key]: value,
      }));
      
      if (!!isPrevious) {
        let updatedRequestData = requestData;
        for (let i = 0; i < updatedRequestData.length; i++) {
          if (i === currentIndex) {
            updatedRequestData[i][key] = value;
          }
        }
        setRequestData(updatedRequestData);
      }
    }
  };

  const handleSelectPlatform = (selected) => {
    setCurrentRequest((prev) => ({
      ...prev,
      platform: selected.value,
    }));

    if (!!isPrevious) {
      let updatedRequestData = requestData;
      for (let i = 0; i < updatedRequestData.length; i++) {
        if (i === currentIndex) {
          updatedRequestData[i].platform = selected.value;
        }
      }
      setRequestData(updatedRequestData);
    }
  };

  const handleAddLabel = (value) => {
    const lookup = toDict(reportsData, 'value');

    // check if label already added to request
    if (!currentRequest.labels.some((el) => el.value === value) && lookup[value]) {
      setCurrentRequest((prev) => ({
        ...prev,
        labels: [
          ...prev.labels,
          {
            value: lookup[value].value,
            percent_difference: parsePercent(lookup[value].percent_difference),
          },
        ],
      }));

      if (!!isPrevious) {
        let updatedRequestData = requestData;
        for (let i = 0; i < updatedRequestData.length; i++) {
          if (i === currentIndex) {
            updatedRequestData[i].labels.push({
              value: lookup[value].value,
              percent_difference: parsePercent(lookup[value].percent_difference),
            })
          }
        }
        setRequestData(updatedRequestData);
      }
    }
    // if label doesn't exist in view data
    else if (!currentRequest.labels.some((el) => el.value === value)) {
      setCurrentRequest((prev) => ({
        ...prev,
        labels: [
          ...prev.labels,
          {
            value: value,
            percent_difference: null,
          },
        ],
      }));
      if (!!isPrevious) {
        let updatedRequestData = requestData;
        for (let i = 0; i < updatedRequestData.length; i++) {
          if (i === currentIndex) {
            updatedRequestData[i].labels.push({
              value: value,
              percent_difference: null,
            })
          }
        }
        setRequestData(updatedRequestData);
      }
    }
  };

  const handleRemoveLabel = (index) => {
    const newData = currentRequest.labels;

    if (index > -1) {
      newData.splice(index, 1);
    }

    setCurrentRequest({
      ...currentRequest,
      labels: newData,
    });
  };

  const LABEL_OPTIONS = [];

  const getLabelData = (labelData) => {
    labelData.forEach((item) => {
      LABEL_OPTIONS.push({
        label: item.label,
        value: item.value,
      });
    });
  };

  function toDict(arr, key) {
    const results = {};
    arr.forEach((item) => {
      results[item[key]] = item;
    });
    return results;
  }

  function parsePercent(str) {
    return !str ? null : strToNum(str) * 100;
  }

  const validate = () => {
    let requestToValidate;
    if (temp && Object.keys(temp).length > 0) {
      requestToValidate = temp;
    } else {
      requestToValidate = currentRequest;
    }
    const { labels, description, platform } = requestToValidate;

    if (projectName.length < 1) {
      setFormError('Project name is required.');
      return;
    }
    if (platform.length < 1) {
      setFormError('Platform is required.');
      return;
    }
    if (description.length < 1 && labels.length < 1) {
      setFormError('At least one of the following is required: labels or description.');
      return;
    }
    setFormError('');
    return true;
  };

  const handleSubmit = () => {
    const isValid = validate();
    if (isValid) {
      if (temp && Object.keys(temp).length > 0) {
        setRequestData([...requestData, temp]);
      } else {
        setRequestData([...requestData, currentRequest]);
      }
      setSubmit(true);
    }
  };

  const handleAddRequest = () => {
    const isValid = validate();
    if (isValid) {
      setRequestData([...requestData, currentRequest]);
      setCurrentRequest(newRequest);
      setCurrentIndex(requestData.length + 1);
    }
  };

  const onCancel = () => setShowRequestForm(false);

  const validateSelect = () => {
    const { labels, description, platform } = currentRequest;

    if (projectName.length < 1) {
      setFormError('Project name is required.');
      return;
    }
    if (platform.length < 1) {
      setFormError('Platform is required.');
      return;
    }
    if (description.length < 1 && labels.length < 1) {
      setFormError('At least one of the following is required: labels or description.');
      return;
    }
    setFormError('');
    return true;
  };

  const selectRequest = (i) => {
    if (Object.keys(temp).length > 0) {
      const isValid = validateSelect();
      if (!isValid) {
        return;
      }
    }
    
    if (Object.keys(temp).length === 0) setTemp(currentRequest);
    setCurrentIndex(i);
    setCurrentRequest(requestData[i]);
  };

  const selectCurrent = () => {
    if (Object.keys(temp).length > 0) {
      const isValid = validateSelect();
      if (!isValid) {
        return;
      }
    }

    if (temp && Object.keys(temp).length > 0) {
      setCurrentRequest(temp);
      setTemp({});
      setCurrentIndex(requestData.length);
    }
  };

  const InfoMessage = ({ field }) => {
    const [show, setShow] = useState(false);
    let infoMsg = '';

    switch (field) {
      case 'project_name':
        infoMsg = 'Give the project a friendly name you will remember.';
        break;
      case 'request_type':
        infoMsg = 'Specify whether you want static image creative or motion graphics.';
        break;
      case 'creative_freedom':
          infoMsg = 'Specify whether the designer should strictly work with your assets or if the designer can look at other sources (stock motion, etc.).';
          break;
      case 'labels':
        infoMsg = 'Specify what data driven labels should be included in the creative request.';
        break;
      case 'description':
        infoMsg = 'Tell us what you want to see. Be descriptive. Provide links to any examples you want us to include.';
        break;
      default:
        infoMsg = ''
        break;
    }

    const handleShowInfo = () => setShow(true);
    const handleCloseInfo = () => setShow(false);

    return (
      <InfoIcon
        onShowInfo={handleShowInfo}
        onCloseInfo={handleCloseInfo}
        className="help-icon"
      >
        {!!show && <div className="info-popup" onClick={(e) => e.stopPropagation()}><p>{infoMsg}</p></div>}
      </InfoIcon>
    )
  };

  const RequestSelector = () => {
    if (requestData.length > 0) {
      return (
        <div className="request-selector d-flex noselect-all">
          {(requestData && requestData.length > 0) && requestData.map((request, i) => (
            <div className={currentIndex === i ? 'request-selector__item request-selector__item--selected' : 'request-selector__item'} onClick={() => selectRequest(i)}>{i + 1}</div>
          ))}
          <div className={currentIndex === requestData.length || requestData.length === 0 ? 'request-selector__item request-selector__item--selected' : 'request-selector__item'} onClick={selectCurrent}>{requestData.length + 1}</div>
        </div>
      );
    }
    return <></>;
  };

  if (creativeRequestsApi.post.loading) {
    return <Loading />;
  }
  if (creativeRequestsApi.post.data) {
    const { data } = creativeRequestsApi.post;
    const url = `http://dashboard.darwinsoftware.io/marketplace/${data.project_id}`;
    return <SuccessScreen url={url} onCancel={onCancel} />;
  }
  if (creativeRequestsApi.post.error) {
    return <ErrorScreen onCancel={onCancel} />;
  }

  let header_text = 'Create New Request';
  if ((Object.keys(temp).length > 0)) {
    header_text = `Request ${currentIndex + 1} of ${requestData.length + 1}`;
  }

  return (
    <>
      {Object.keys(reportsData).length > 0 ? (
        <div className="request-form">
          <div className="request-form-header d-flex flex-col flex-md-row justify-content-between">
            <h1 className="request-form__heading">{header_text}</h1>
            <div className="d-flex flex-col flex-md-row align-items-center">
              <RequestSelector />
              {desktop && <CloseWindowIcon onCancel={onCancel} />}
            </div>
          </div>
          <div className="request-form-content d-flex flex-col flex-md-row-reverse">
            <div className="request-form-section right-brain">
              <div onClick={() => mobile && toggleExpand('right')} className="request-form-section-header noselect">
                <div>
                  <h3 className="request-form-section__subtitle">Right Brain</h3>
                  <h2 className="request-form-section__title">Your Ideas</h2>
                </div>
                <ExpandIcon />
              </div>
              <form className={!!expanded.right ? 'request-form-fields' : 'd-none'}>
                <p className="request-form-section__description">Give us direction so we can make creative that you are proud of.</p>
                <div className="request-form-group">
                  <div className="form-group-header">
                    <label className="request-form-label" htmlFor="name">Project Name</label>
                    <InfoMessage field="project_name" />
                  </div>
                  <input onChange={(e) => handleInput('project_name', e.target.value)} className="request-form-input" type="text" id="name" defaultValue={`Client Project ${formattedDate}`} />
                </div>
                <div className="request-form-group">
                  <div className="form-group-header">
                    <label className="request-form-label" htmlFor="request-type">Request Type</label>
                    <InfoMessage field="request_type" />
                  </div>
                  <div className={!isPrevious ? 'request-form-select request-form-select--red' : 'request-form-select disabled'}>
                    <div
                      className={
                        currentRequest.request_type === 'static'
                          ? 'request-form-select-box selected-option'
                          : 'request-form-select-box'
                      }
                      onClick={() => handleInput('request_type', 'static')}
                    >
                      Static Image
                    </div>
                    <div
                      className={
                        currentRequest.request_type === 'animation'
                          ? 'request-form-select-box selected-option'
                          : 'request-form-select-box'
                      }
                      onClick={() => handleInput('request_type', 'animation')}
                    >
                      Animation
                    </div>
                  </div>
                </div>
                <div className="request-form-group">
                  <div className="form-group-header">
                    <label className="request-form-label" htmlFor="creative-freedom">Creative Freedom</label>
                    <InfoMessage field="creative_freedom" />
                  </div>
                  <div className={!isPrevious ? 'request-form-select' : 'request-form-select disabled'}>
                    <div
                      className={
                        currentRequest.creative_freedom === false
                          ? 'request-form-select-box selected-option'
                          : 'request-form-select-box'
                      }
                      onClick={() => handleInput('creative_freedom', false)}
                    >
                      Stick to only my assets
                    </div>
                    <div
                      className={
                        currentRequest.creative_freedom === true
                          ? 'request-form-select-box selected-option'
                          : 'request-form-select-box'
                      }
                      onClick={() => handleInput('creative_freedom', true)}
                    >
                      Allow designer to explore other assets
                    </div>
                  </div>
                </div>
                <div className="request-form-group">
                  <div className="form-group-header">
                    <label className="request-form-label" htmlFor="labels">Labels</label>
                    <InfoMessage field="labels" />
                  </div>
                  <div className="mb-3">
                    <Select
                      value=""
                      options={LABEL_OPTIONS}
                      onChange={(selected) => handleAddLabel(selected.value)}
                      styles={ASSET_DROPDOWN_STYLES}
                    />
                  </div>
                  <div className="request-form-input">
                    <ul className="creative-request-labels">
                      {currentRequest.labels.length > 0 ? (
                        currentRequest.labels.map((label, index) => (
                          <li className="creative-request-label" key={`creative-request-${label.value}`}>
                            <p className="creative-request-label__text">{label.value}</p>
                            <CloseIcon onRemoveLabel={handleRemoveLabel} index={index} />
                          </li>
                        ))
                      ) : (
                        <p className="noselect-all">Click on the matrix or search in the dropdown to add a label.</p>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="request-form-group">
                  <label className="request-form-label" htmlFor="platform">Platform</label>
                  <Select
                    options={PLATFORM_OPTIONS}
                    value={PLATFORM_OPTIONS.filter((option) => option.value === currentRequest.platform)}
                    onChange={handleSelectPlatform}
                    styles={ASSET_DROPDOWN_STYLES}
                  />
                </div>
                <div className="request-form-group">
                  <div className="form-group-header">
                    <label className="request-form-label" htmlFor="description">Project Description</label>
                    <InfoMessage field="description" />
                  </div>  
                  <textarea onChange={(e) => handleInput('description', e.target.value)} value={currentRequest.description} className="request-form-input" type="text" id="description" placeholder="Tell us what you want to see. Be descriptive. Provide links to any examples you want us to include" />
                </div>
              </form>
            </div>
            <div className="request-form-section left-brain">
              <div onClick={() => mobile && toggleExpand('left')} className="request-form-section-header noselect">
                <div>
                  <h3 className="request-form-section__subtitle">Left Brain</h3>
                  <h2 className="request-form-section__title">Your Data</h2>
                </div>
                <ExpandIcon />
              </div>
              <div className={!!expanded.left ? 'request-form-matrix' : 'd-none'}>
                <p className="request-form-section__description">Select elements you want to include in the creative request. Data is last 30 days.</p>
                <LabelMatrix
                  data={reportsData}
                  creativeRequest
                  addToRequest={handleAddLabel}
                  getLabelData={getLabelData}
                />
              </div>
            </div>
          </div>
          <div className="request-form-footer d-flex flex-col flex-md-row justify-content-between">
            <RoleAccess roles={['internal']}>
              <div>
                {!(Object.keys(temp).length > 0) && <button className="request-form-btn request-form-btn--add" onClick={handleAddRequest}>Add Additional Request</button> }
              </div>
            </RoleAccess>
            <p className="request-form__error">{formError}</p>
            <div className="d-flex flex-row flex-md-col align-items-center">
              <button className="request-form-btn request-form-btn--cancel" onClick={onCancel}>Cancel</button>
              <button className="request-form-btn request-form-btn--submit ml-3" onClick={handleSubmit}>Submit</button>
            </div>
          </div>
        </div>
      ) : (<Loading />)}
    </>
  );
};

export const SuccessScreen = ({ onCancel, url }) => (
  <div className="info-screen">
    <div className="info-screen-header">
      <h4 className="info-screen-header__subtitle">Success</h4>
      <h1>Request Submitted</h1>
      <h2>Your new creative is in the works. A few things...</h2>
    </div>

    <div className="info-screen-list">
      <div className="info-screen-item">
        <div className="info-screen-item__img">
          <HangTightIcon />
        </div>
        <div className="info-screen-item-content">
          <h3>Hang Tight</h3>
          <h4>You can expect your first drafts in 2-3 business days. We will notify you when they are ready.</h4>
        </div>
      </div>
      <div className="info-screen-item">
        <div className="info-screen-item__img">
          <CreativeIcon />
        </div>
        <div className="info-screen-item-content">
          <h3>Good Creative is Hard</h3>
          <h4>You should expect that you will have to request revisions, so plan ahead.</h4>
        </div>
      </div>
      <div className="info-screen-item">
        <div className="info-screen-item__img">
          <HelpIcon />
        </div>
        <div className="info-screen-item-content">
          <h3>We Are Here To Help</h3>
          <h4>We will pre screen the results to make sure they are up to your quality standards. Send any questions to support@darwinsoftware.io</h4>
        </div>
      </div>
    </div>

    <div className="info-screen-footer">
      <RoleAccess roles={['internal']}>
        <a target="_blank" href={url}>Link to creative request</a>
      </RoleAccess>
      <button className="info-screen-footer__confirm" onClick={onCancel}>
        <i className='fa fa-thumbs-up mr-3'></i>
        Got It!
      </button>
    </div>
  </div>
);

const ErrorScreen = ({ onCancel }) => (
  <div>
    <p>An error occurred. Please try again.</p>
    <button className="request-form-btn" onClick={onCancel}>Got it!</button>
  </div>
);

const PreviousRequest = ({ currentRequest }) => (
  <div>
    <ul>
      <li>
        request type:
        {currentRequest.request_type}
      </li>
      <li>
        {' '}
        creative freedom:
        {`${currentRequest.creative_freedom}`}
      </li>
      <li>
        platform:
        {currentRequest.platform}
      </li>
      <li>
        labels:
        <ul className="creative-request-labels">
          {currentRequest.labels.length > 0 ? (
            currentRequest.labels.map((label, index) => (
              <li className="creative-request-label" key={`creative-request-${label.value}`}>
                  <p className="creative-request-label__text">{label.value}</p>
                </li>
            ))
          ) : (
            <p>No labels.</p>
          )}
        </ul>
      </li>
      {currentRequest.description.length > 0 && (
        <li>
          description:
          {currentRequest.description}
        </li>
      )}
    </ul>
  </div>
);

const InfoScreen = ({ setStage, setShowRequestForm }) => {
  const onCancel = () => setShowRequestForm(false);

  return (
    <div className="info-screen">
      <div className="info-screen-header">
        <h4 className="info-screen-header__subtitle">On Demand</h4>
        <h1>Data Driven Creative</h1>
        <h2>Connect to our Marketplace of Creative Talent. Things to remember...</h2>
      </div>

      <div className="info-screen-list">
        <div className="info-screen-item">
          <div className="info-screen-item__img">
            <TalentIcon />
          </div>
          <div className="info-screen-item-content">
            <h3>We Have Talent</h3>
            <h4>We are connecting you to our marketplace of over 200 designers, animators, illustrators, and copy writers to make your creative ideas come to life.</h4>
          </div>
        </div>
        <div className="info-screen-item">
          <div className="info-screen-item__img">
            <BrandIcon />
          </div>
          <div className="info-screen-item-content">
            <h3>We Have Your Brand Guidelines</h3>
            <h4>Rest easy. We have your latest brand guidelines and assets at our disposal. If you have updated guidelines or new assets, please email them to support@darwinsoftware.io</h4>
          </div>
        </div>
        <div className="info-screen-item">
          <div className="info-screen-item__img">
            <ResultsIcon />
          </div>
          <div className="info-screen-item-content">
            <h3>We Want You To Be Delighted with the Results</h3>
            <h4>Most requests come back within 48 hours, and you can request as many revisions as you want. We pre screen the assets to make sure they are up to your standards.</h4>
          </div>
        </div>
      </div>

      <div className="info-screen-disclaimer">
        <p>
          By clicking "Got It" you agree to our
          &nbsp;<a target="_blank" href="https://darwinsoftware.io/privacy-policy/">Standard terms</a>
        </p>
      </div>

      <div className="info-screen-footer">
        <button className="info-screen-footer__cancel" onClick={onCancel}>Cancel</button>
        <button className="info-screen-footer__confirm" onClick={() => setStage(1)}>
          <i className='fa fa-thumbs-up mr-3'></i>
          Got It!
        </button>
      </div>
    </div>
  );
};

export default CreativeRequest;
