import { DARWIN_NAVY_2, DARWIN_NAVY_3 } from "../../../constants"

export const PB_SELECT_STYLES = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "#E5EBF3",
    border: "none",
    boxShadow: '0px 3px 3px -3px #C1CDDE',
    ':hover': {
      transform: 'translateY(-1px)',
      transition: '0.1s ease-in-out',
      boxShadow: '0px 5px 3px -3px #d7dee7',
    },
    cursor: 'pointer',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    backgroundColor: "transparent",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: DARWIN_NAVY_3,
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    cursor: 'pointer',
    fontSize: '1.3rem',
    color: DARWIN_NAVY_2,
    backgroundColor: isFocused ? '#F2F8FF' : 'white',
    ':hover': {
      backgroundColor: '#F2F8FF',
    },
    borderRadius: '2px',
  }),
}

export const EMPTY_RULE = {
  include: [],
  exclude: [],
}

export const EMPTY_PHRASE = {
  name: "",
  rules: [{...EMPTY_RULE}],
}

export const PHRASES = "Phrases";
export const KEYWORD = "Keyword";