import { useViewId } from '../selectors';
import { useHistory } from 'react-router-dom';

export const useSelectFeature = () => {
  const viewId = useViewId();
  const history = useHistory();

  const prefixView = (rest) => `/${viewId}/${rest}`;

  return (key, subkey = null) => {
    const withParams = !!subkey ? `${key}/${subkey}` : key;

    history.push(prefixView(withParams));
  };
};
