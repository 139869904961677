import React, { useState } from 'react';
import Toggle from '../../../../components/BeagleToggle';
import Button from '../../../../components/Button';

export const CategoriesToggle = ({
  activeCategories,
  categorical_columns = [],
  toggleCategory,
}) => {
  const [show, setShow] = useState(false);

  const Toggles = (
    <div className="mx-4">
      <ul>
        {categorical_columns.map((category: string) => {
          const isActive = activeCategories.includes(category);

          return (
            <li className="d-flex justify-content-between">
              <p>{category}</p>
              <Toggle
                id={category}
                checked={isActive}
                onChange={(e) => {
                  toggleCategory(category);
                }}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );

  return (
    <div>
      <Button
        className="my-4 text-center"
        fullWidth
        appearance="subtleBordered"
        active={show}
        onClick={() => setShow((prev) => !prev)}
      >
        Toggle categories on and off
      </Button>
      {show && Toggles}
    </div>
  );
};
