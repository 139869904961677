import React from 'react';
import { DARWIN_NAVY_4 } from '../../constants';

const EfficiencyIcon = ({ className = "" }) => (
  <svg className={className} width="19" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.3125 14.5H2.75V1.4375C2.75 1.05766 2.44234 0.75 2.0625 0.75H0.6875C0.307656 0.75 0 1.05766 0 1.4375V15.875C0 16.6343 0.615742 17.25 1.375 17.25H21.3125C21.6923 17.25 22 16.9423 22 16.5625V15.1875C22 14.8077 21.6923 14.5 21.3125 14.5ZM19.9375 2.125H14.8646C13.9459 2.125 13.4857 3.23574 14.1354 3.88543L15.5276 5.27762L12.375 8.43066L9.22238 5.27805C8.68527 4.74094 7.81473 4.74094 7.27805 5.27805L4.32652 8.22957C4.05797 8.49813 4.05797 8.9334 4.32652 9.20195L5.29848 10.1739C5.56703 10.4425 6.0023 10.4425 6.27086 10.1739L8.25 8.19434L11.4026 11.347C11.9397 11.8841 12.8103 11.8841 13.347 11.347L17.472 7.22195L18.8641 8.61414C19.5138 9.26383 20.6246 8.80363 20.6246 7.88496V2.8125C20.625 2.43266 20.3173 2.125 19.9375 2.125Z" fill={DARWIN_NAVY_4}/>
  </svg>
)

export default EfficiencyIcon;
