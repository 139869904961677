import { useRef, useEffect } from 'react';
import joi from 'joi';
import { abortableFetch } from '../../../utils';

import {
  useApiState,
  bindFetchToRef,
} from '../../../components/Hooks/useApiState';

const HEADERS = { 'content-type': 'application/json' };

const SAVE_LABEL_SETTINGS_SCHEMA = joi.object({
  account_id: joi.string().required(),
  custom_trained_label_settings: joi.array().required(),
  common_ad_labels: joi.array().required(),
});

function getLabelSettings(account_id) {
  return abortableFetch(
    ['api', 'creative-reports', 'matrix', account_id, 'label-settings'].join(
      '/'
    ),
    {
      method: 'GET',
      headers: HEADERS,
      credentials: 'include',
    }
  );
}

export function useLabelSettings() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (account_id) =>
      withRef(getLabelSettings(account_id)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: true,
      count: 0,
    }
  );

  const handleGet = (account_id) => {
    getDispatch(account_id);
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    get: {
      ...getState,
      request: handleGet,
    },
  };
}

export const saveLabelSettings = (payload) => {
  abortableFetch(
    [
      'api',
      'creative-reports',
      'matrix',
      payload.account_id,
      'label-settings',
      'save',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      body: JSON.stringify(payload),
      credentials: 'include',
    }
  );
};

export function useSaveLabelSettings() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);

  const [postState, postDispatch] = useApiState((payload) =>
    withRef(saveLabelSettings(payload)).then(extract)
  );

  const handlePost = (payload) => {
    const { error } = SAVE_LABEL_SETTINGS_SCHEMA.validate(payload);

    if (error) {
      throw new Error(error.message);
    } else {
      postDispatch(payload);
    }
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: handlePost,
    },
  };
}
