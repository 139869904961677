import React, { useMemo } from 'react';
import { useCreativeAnalyticsStore, useViewSettings } from '../../selectors';
import { useCreativeAnalyticsGet } from '../CreativeAnalytics/contexts';
import { getCustomEventsOpts } from './utils/getCustomEventsCols';
import { nonOrganicCols } from '../CreativeAnalytics/PerformanceFrequency/Columns';
import { capitalizeFirst } from '../CreativeAnalytics/ReportCard/utils';

export const useDNAColumnOptions = ({
  isROAS,
  customEvents = [],
  clientMetrics = [],
}) => {
  const { dnaReportData } = useCreativeAnalyticsStore();
  const { additional_metrics, isOrganicView } = useViewSettings();
  const { account_id } = useViewSettings();
  const isGoHealth = account_id === 'act_637485863314808';

  const customEventsOpts = useMemo(
    () => getCustomEventsOpts(customEvents),
    [customEvents]
  );

  const metadataOpts = useMemo(() => {
    if (dnaReportData) {
      try {
        return dnaReportData.categories.map(({ category_name }) => ({
          label: category_name,
          value: category_name,
          isMetadata: true,
        }));
      } catch (e) {
        console.error(e);
      }
    } else {
      return [];
    }
  }, [dnaReportData]);

  const additionalMetricsOpts = useMemo(() => {
    let result = [];

    if (additional_metrics && !isGoHealth) {
      try {
        additional_metrics.forEach((metric) => {
          result.push({
            label: `${metric.name}`,
            value: `${metric.value}`,
          });
          if (!isOrganicView) {
            additionalMetricsOpts.push({
              label: `Cost per ${metric.name}`,
              value: `cp_${metric.value}`,
            });
          }
        });
      } catch (e) {
        console.error(e);
      }
    }

    return result;
  }, [additional_metrics]);
  
  const clientMetricOpts =
    useMemo(() => {
      return clientMetrics?.reduce((opts, metric) => {
        try {
          if (isGoHealth && metric.includes('OppAttempted')) {
            opts.push({
              label: `Cost per Opp Attempted`,
              value: `cp_${metric}`,
            });
          } else {
            opts.push({
              label: capitalizeFirst(metric.replace('_', ' ')),
              value: metric,
            });

            if (!isOrganicView) {
              opts.push({
                label: `Cost per ${capitalizeFirst(metric.replace('_', ' '))}`,
                value: `cp_${metric}`,
              });
            }
          }
        } catch (e) {
          console.error(e);
        }

        return opts;
      }, []);
    }, []) ?? [];

  return [
    {
      label: 'Visual',
      value: 'id',
      isMetadata: true,
    },
    {
      label: isROAS ? 'ROAS' : 'CPA',
      value: isROAS ? 'roas' : 'cpa',
    },
    {
      label: 'CPM',
      value: 'cpm',
    },
    {
      label: 'CPC',
      value: 'cpc',
    },
    {
      label: 'CTR',
      value: 'ctr',
    },
    ...(!isOrganicView
      ? [
          {
            label: 'Spend',
            value: 'spend',
          },
          {
            label: 'Impressions',
            value: 'impressions',
          },
          {
            label: 'Spend Quintile',
            value: 'spend_quintile',
          },
          {
            label: 'Fitness Score',
            value: 'fitness_score',
          },
        ]
      : []),

    {
      label: 'Results',
      value: 'results',
    },
    {
      label: 'clicks',
      value: 'clicks',
    },
    {
      label: 'Custom Performance Score',
      value: 'custom_performance_score',
    },
    {
      label: 'Manual Tags',
      value: 'manual_tags',
      isMetadata: true,
    },

    {
      label: 'Performance Quintile',
      value: 'primary_metric_quintile',
    },

    ...clientMetricOpts,
    ...additionalMetricsOpts,
    ...customEventsOpts,
    ...metadataOpts,
  ].filter(({ value, shouldShow }) => {
    if (!isOrganicView) return true;
    return !nonOrganicCols.some((v) => value.indexOf(v) >= 0);
  });
};
