
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { LoadingOverlay } from '../Helpers/Loading';
import { useCustomLabels } from './Container';
import Matrix from './Matrix';

function StandaloneLabelMatrix({ adName, PrimaryColor }) {
  const { data, isAvailable } = useCustomLabels(adName);
  const [hidden, setVisibility] = useState(true);

  const checkId = `label-matrix:${adName}`;

  return (
    <>
      <div className="mt-4 standalone-matrix" style={{ position: 'relative' }}>
        {!isAvailable && <LoadingOverlay useSpinner />}
        <h4>
          <strong>Creative Element Performance</strong>
        </h4>
        <hr />
        <div>
          <label htmlFor={checkId} className="d-flex flex-row align-items-baseline">
            <input
              id={checkId}
              className="mr-2"
              type="checkbox"
              checked={hidden}
              onChange={(e) => setVisibility(e.target.checked)}
            />
            Remove existing elements
          </label>

        </div>
        <Matrix
          data={data}
          selectedId=""
          style={{
            height: '400px',
            overflow: 'auto',
            padding: '1rem',
            border: '1px solid #dddddd',
          }}
          showExisting={!hidden}
        />
      </div>
      { PrimaryColor }
    </>
  );
}

StandaloneLabelMatrix.propTypes = {
  adName: PropTypes.string.isRequired,
};

export default StandaloneLabelMatrix;
