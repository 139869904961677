import { useRef, useEffect } from 'react';

import { abortableFetch } from '../../../utils';
import {
  useApiState,
  bindFetchToRef,
} from '../../../components/Hooks/useApiState';

function getVisualReport(payload) {
  return abortableFetch(
    ['api', 'creative-reports', 'visual-report', payload.account_id].join('/'),
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }
  );
}

export function useVisualReportApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) =>
      withRef(getVisualReport(payload)).then(({ data }) => {
        return data;
      }),
    {
      loading: false,
      error: null,
      data: null,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return {
    post: {
      ...postState,
      request: postDispatch,
    },
  };
}

function getCustomLabels(payload) {
  return abortableFetch(
    ['api', 'creative-reports', 'visual-report', 'custom-labels'].join('/'),
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }
  );
}

function saveCustomLabels(payload) {
  return abortableFetch(
    [
      'api',
      'creative-reports',
      'visual-report',
      'custom-labels',
      payload.visual_hash,
      'save',
    ].join('/'),
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }
  );
}

export function useCustomLabels() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (payload) => withRef(getCustomLabels(payload)).then(({ data }) => data),
    {
      loading: true,
      error: null,
      data: null,
      count: 0,
    }
  );
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(saveCustomLabels(payload)).then(({ data }) => data),
    {
      loading: false,
      error: null,
      data: null,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return {
    get: {
      ...getState,
      request: getDispatch,
    },
    post: {
      ...postState,
      request: postDispatch,
    },
  };
}
