import React from 'react';
import { CHANGE_REQUEST_TYPE } from '../hooks/useCopilotState';

const RadioButton = ({ type, requestType, copilotDispatch, loading }) => {
  return (
    <div
      className="d-flex align-items-center"
      onClick={() => {
        if (!loading)
          copilotDispatch({ type: CHANGE_REQUEST_TYPE, requestType: type });
      }}
      style={{ cursor: 'default' }}
    >
      <input type="radio" checked={requestType === type} disabled={loading} />
      <div className="section-label ml-2 mb-0">
        {type === 'answer-questions'
          ? 'Answer questions about my data'
          : type === 'rewrite-brief'
          ? 'Enhance existing creative brief'
          : 'Give me creative guidance'}
        {type === 'answer-questions' && <span className="beta">BETA</span>}
      </div>
    </div>
  );
};

export default RadioButton;
