import React, { useEffect, useMemo, useState } from 'react';
import { BasicLoading } from '../../components/Helpers/Loading';
import { formatDate } from '.';
import { VisualsWrapper } from '../DNA/CreativePerformance/VisualsWrapper';
import clsx from 'clsx';
import LazyLoader from '../../components/LazyLoader';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useCreativeAnalyticsGet } from '../CreativeAnalytics/contexts';

import {
  useTable,
  useSortBy,
  useFilters,
  useFlexLayout,
  useColumnOrder,
  useRowSelect,
} from 'react-table';
import { IndeterminateCheckbox, useDnaReportColumns } from '../DNA/columns';
import Button from '../../components/Button';
import { Tag, TagsList } from '../../components/Tags';

const deriveGroupParams = (params) => {
  if (!params) {
    return {
      title: params?.title || null,
      selectBy: params?.selectBy || null,
      count: params?.count || null,
      metric: params?.metric || null,
      labels: params?.labels || [],
      sortBy: params?.sortBy || 'spend',
      sortOrder: params?.sortOrder || null,
      selectedVisuals: params?.selectedVisuals || [],
      selectedLabel: params?.selectedLabel || null,
      custom: params?.custom || false,
      mode: params?.mode || 'visuals',
      columnFilters: params?.columnFilters || [],
    };
  }

  return params;
};

export const StaticDnaReport = ({
  report_name,
  report_description,
  start_date,
  end_date,
  data,
  formatMetric,
  visualsForSelectedLabel,
  loading,
  metadata,
}) => {
  const {
    params,
    visuals: rows,
    columns = [],
    metadataFields,
    elementsToInclude = {},
  } = data;
  const { primary_metric, additional_metrics, customScoringData } =
    useCreativeAnalyticsGet();

  const groupParams = deriveGroupParams(data.params);

  const isSorted = data?.params?.sortBy;

  const SortText = (
    <p
      style={{
        fontWeight: 500,
        marginBottom: 0,
        marginLeft: '1rem',
      }}
      className="sort-text"
    >
      {isSorted && (
        <i
          className={`fa-solid fa-caret${
            data?.params?.sortOrder === 'descending' ? '-down' : '-up'
          } mr-2`}
        />
      )}
      {!isSorted && (
        <i className="fa-solid fa-minus mr-2" style={{ color: '#616161' }} />
      )}
      {isSorted ? (
        <span>Sorted by {formatMetric(data?.params?.sortBy)} </span>
      ) : (
        <span style={{ color: '#616161' }}>No sort applied</span>
      )}
      {isSorted && <span>{formatMetric(data?.params?.sortOrder)}</span>}
    </p>
  );

  if (loading) return <BasicLoading />;

  let visuals = [];
  if (visualsForSelectedLabel?.length) {
    visuals = visualsForSelectedLabel;
  } else if (data?.visuals) {
    visuals = data.visuals;
  }

  return (
    <>
      <div className="p-5">
        <div className="mt-4">{SortText}</div>
        <VisualsWrapper
          visuals={visuals}
          columns={data?.columns ?? []}
          metadataFields={metadataFields ?? []}
          metadata={metadata || {}}
          formatMetric={formatMetric}
          columnOptions={data?.dnaColumnOptions || data?.columnOptions}
        />

        {elementsToInclude?.table && (
          <div className="mt-5">
            <TableWrapper
              {...{
                primary_metric,
                metadata,
                additional_metrics,
                columns,
                rows,
                groupParams,
                customScoringData,
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

const TableWrapper = ({
  primary_metric,
  metadata,
  additional_metrics,
  columns,
  rows,
  groupParams,
}) => {
  const cols = useDnaReportColumns({
    primary_metric,
    metadata,
    additional_metrics,
    clientMetrics: [],
  });

  const tableColumns = useMemo(() => {
    return cols.filter((column) =>
      ['id', ...columns, 'period_dates', 'period_type', 'new_visual'].includes(
        column.accessor
      )
    );
  }, [cols, columns]);

  console.log({ tableColumns, rows });
  const tableInstance = useTable(
    {
      columns: tableColumns,
      data: rows,
      initialState: {
        sortBy: [
          {
            id: groupParams?.sortBy ?? 'fitness_score',
            desc: groupParams?.sortOrder === 'descending',
          },
        ],
        // selectedRowIds: groupParams.selectedVisuals.reduce((acc, id) => {
        //   acc[id] = true;
        //   return acc;
        // }, {}),
        filters: groupParams?.columnFilters ?? [],
        hiddenColumns: ['period_type', 'period_dates', 'new_visual'],
      },
      autoResetHiddenColumns: false,
      autoResetSortBy: false,
      autoResetFilters: false,
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    useColumnOrder,
    useRowSelect
  );

  return <Table tableInstance={tableInstance} groupParams={groupParams} />;
};

const getItemStyle = ({ isDragging, isDropAnimating }, draggableStyle) => ({
  ...draggableStyle,
  userSelect: 'none',

  background: isDragging ? 'lightgreen' : 'grey',
  color: 'green',

  ...(!isDragging && { transform: 'translate(0,0)' }),
  ...(isDropAnimating && { transitionDuration: '0.001s' }),
});

export const Table = ({ tableInstance, groupParams }) => {
  const [enableFilters, setEnableFilters] = useState(true);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows: r,
    prepareRow,
    exportData,
    setColumnOrder,
    setFilter,
    visibleColumns,
    state: { filters, selectedRowIds },
  } = tableInstance;

  const rows = useMemo(() => {
    return r.filter((row) => {
      return row.original.period_type !== 'previous';
    });
  }, [r]);

  const currentColOrder = React.useRef();

  const handleFilterChange = (columnId, value) => {
    setFilter(columnId, value.length > 0 ? value : null, { exactMatch: true });
  };

  const columnOptions = {};

  headerGroups.forEach((headerGroup) => {
    headerGroup.headers.forEach((column) => {
      if (column.canFilter) {
        let id = column.id;

        const columnValues = column.preFilteredRows.reduce((acc, row) => {
          if (column.Header === 'Visual') {
            acc.push(
              row.original._id
              // row.original.ad_names?.length > 1
              //   ? row.original._id
              //   : row.original.ad_names?.[0]
            );
            return acc;
          }

          acc.push(row.values[id]);
          return acc;
        }, []);
        columnOptions[column.id] = [...new Set(columnValues)].filter(Boolean);
      }
    });
  });

  const validFilters = filters?.filter(({ value }) => !!value) ?? [];
  const hasFilters = validFilters.length > 0;

  return (
    <div>
      <div className="d-flex align-items-center mb-3 justify-content-between">
        <div className="d-flex align-items-center">
          <Button
            onClick={() => setEnableFilters((prev) => !prev)}
            appearance="raised"
            active={enableFilters}
          >
            <i
              style={{ fontSize: '1rem' }}
              className="fa-solid fa-filter mr-2"
            />
            Filters
            {hasFilters ? <span>&nbsp;({validFilters.length})</span> : null}
          </Button>
          <p className="mb-0 ml-3">
            {rows.length} visual{rows.length === 1 ? '' : 's'}
          </p>
          {/* {!!SortText && SortText} */}
        </div>
        {hasFilters && enableFilters && (
          <div
            className="d-flex align-items-start"
            style={{ maxWidth: '50%', overflow: 'auto' }}
          >
            <span className="" style={{ transform: 'translateY(3px)' }}>
              Filters:&nbsp;
            </span>
            <TagsList>
              {validFilters.map(({ id, value }) => {
                if (!value?.length) return null;
                return (
                  <Tag
                    onClick={() => handleFilterChange(id, '')}
                    className="mb-2 mr-2"
                    tooltip={value}
                  >
                    {value}
                  </Tag>
                );
              })}
            </TagsList>
          </div>
        )}
      </div>

      <table
        {...getTableProps()}
        className="dna-table table table-responsive mt-4"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <DragDropContext
              onDragStart={() => {
                currentColOrder.current = visibleColumns.map((o) => o.id);
              }}
              onDragUpdate={(dragUpdateObj, b) => {
                const colOrder = [...currentColOrder.current];
                const sIndex = dragUpdateObj.source.index;
                const dIndex =
                  dragUpdateObj.destination && dragUpdateObj.destination.index;

                if (dIndex === 0) return;

                if (typeof sIndex === 'number' && typeof dIndex === 'number') {
                  colOrder.splice(sIndex, 1);
                  colOrder.splice(dIndex, 0, dragUpdateObj.draggableId);
                  setColumnOrder(colOrder);
                }
              }}
            >
              <Droppable droppableId="droppable" direction="horizontal">
                {(droppableProvided, snapshot) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    ref={droppableProvided.innerRef}
                  >
                    {headerGroup.headers.map((column, index) => {
                      return (
                        <Draggable
                          key={column.id}
                          draggableId={column.id}
                          index={index}
                          isDragDisabled={
                            !column.accessor ||
                            column.disableColumOrder ||
                            index === 0
                          }
                        >
                          {(provided, snapshot) => {
                            return (
                              <th
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                // {...extraProps}
                                ref={provided.innerRef}
                                className="dna-table__headercell "
                                title={column.Header}
                                style={{
                                  ...getItemStyle(
                                    snapshot,
                                    provided.draggableProps.style
                                  ),
                                  // ...style
                                }}
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    position: 'relative',
                                    wordBreak: 'break-all',
                                    overflowWrap: 'break-word',
                                    textAlign: 'center',
                                  }}
                                >
                                  <span>
                                    {column.render('Header')}
                                    {column.isSorted
                                      ? column.isSortedDesc
                                        ? ' 🔽'
                                        : ' 🔼'
                                      : ''}
                                  </span>
                                  {column.canFilter && enableFilters && (
                                    <select
                                      className="mt-2"
                                      onClick={(e) => e.stopPropagation()}
                                      value={
                                        filters.find(
                                          (filter) => filter.id === column.id
                                        )?.value || ''
                                      }
                                      onChange={(e) =>
                                        handleFilterChange(
                                          column.id,
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value={''}>All</option>
                                      {columnOptions[column.id].map(
                                        (option, index) => (
                                          <option key={index} value={option}>
                                            {option}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  )}
                                </div>
                              </th>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                  </tr>
                )}
              </Droppable>
            </DragDropContext>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return <Row {...{ row }} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

const Row = ({ row }) => {
  return (
    <LazyLoader>
      <tr
        className={clsx('dna-table__row', {
          selected: true,
        })}
        {...row.getRowProps()}
      >
        {row.cells.map((cell) => {
          return (
            <td className={`dna-table__cell`} {...cell.getCellProps()}>
              {cell.render('Cell')}
            </td>
          );
        })}
      </tr>
    </LazyLoader>
  );
};
