/**
 * @param {Object} src
 * @param {...Function} procs
 *
 * @return {Object}
 */
export function pipe(src, ...procs) {
  return procs.reduce((acc, proc) => proc(acc), src);
}

/**
 * @param {Function} proc
 * @param {Number=} delay
 *
 * @return {Function}
 */
export function asAsync(proc, delay = 0) {
  let id = null;

  return (...args) => {
    clearTimeout(id);

    id = setTimeout(() => proc(...args), delay);
  };
}

export function sleep(proc, delay = 0) {
  let id = null;

  return (...args) => {
    clearTimeout(id);

    return new Promise((success) => {
      id = setTimeout(() => success(proc(...args), delay));
    });
  };
}

/**
 * @param {(...args) => any} proc
 * @param {number=} delay
 *
 * @return {(...args) => Promise<any>} result
 */
export const timeout = (proc, delay = 0) => {
  let id = null;

  return (...args) => {
    clearTimeout(id);

    return new Promise((success) => {
      id = setTimeout(() => success(proc(...args)), delay);
    });
  };
};

/**
 * @description Determines if both arrays are equal.
 */
export const arraysEqual = (predicate, arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  let i = 0;

  while (arr1[i]) {
    if (!predicate(arr1[i], arr2[i])) {
      return false;
    }

    i += 1;
  }

  return true;
};

/**
 * @param {URL} URL
 *
 * @return {Object}
 */
export const useSearchParams = ({ search }) => {
  const params = new window.URLSearchParams(search);
  const result = {};

  params.forEach((value, key) => {
    result[key] = value;
  });

  return result;
};

/**
 * @description Converts a string to a number through hashing its
 * characters.
 * @param {String} str
 *
 * @return {Number}
 */
export const simpleHash = (str) => {
  let hash = 0;
  const L = str.length;

  if (L === 0) {
    return hash;
  }
  for (let i = 0; i < L; i += 1) {
    const char = str.charCodeAt(i);

    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + char;
    // eslint-disable-next-line no-bitwise
    hash &= hash;
  }

  return hash;
};

/**
 * @description Limits the number of calls to
 * the given functions to once per waiting period.
 *
 * @param {Function} func
 * @param {Number} wait
 * @param {Boolean=} immediate
 *
 * @return {Function}
 */
export function debounce(func, wait, immediate = false) {
  let id = null;

  return function debounced() {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    const later = function latered() {
      id = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };

    const callNow = immediate && !id;

    clearTimeout(id);

    id = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
}

/**
 * @description Take from:
 * https://developers.google.com/web/updates/2017/09/abortable-fetch
 * @param {RequestInfo} request
 * @param {Object=} opts
 *
 * @return {{ abort: () => void, ready: Promise<Response>}}
 */
export function abortableFetch(request, opts) {
  const controller = new AbortController();
  const { signal } = controller;
  return {
    abort: () => controller.abort(),
    ready: fetch(request, { ...opts, signal }),
  };
}

/**
 * @description Checks if a string contains a hex code.
 *
 * @param {String} str
 *
 * @return {Object}
 */
export const containsHex = (str) => {
  const regex = /#([0-9A-F]{3}){1,2}/i;
  const match = regex.test(str);
  let hexCode = '';
  if (match) {
    hexCode = str.match(regex)[0];
  }

  return {
    match,
    hexCode,
  };
};

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isUrl = (input) => {
  try {
    new URL(input);
    return true;
  } catch (e) {
    return false;
  }
};
