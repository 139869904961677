import PropTypes from 'prop-types';

export const PreviewListItem = PropTypes.shape({
  type: PropTypes.string,
  preview: PropTypes.string,
});

export const PreviewItemAdName = PropTypes.shape({
  ad_name: PropTypes.string,
  preview_list: PropTypes.arrayOf(PreviewListItem),
});

export const PreviewItemAdId = PropTypes.shape({
  ad_id: PropTypes.string,
  preview_list: PropTypes.arrayOf(PreviewListItem),
});
