import React, { useState, useMemo } from 'react';
import { useCreativeAnalyticsGet } from '../contexts';
import Overlay from '../../../components/Overlay';
import Button from '../../../components/Button';
import { formatCategory } from '../../InsightsDashboard/Card';

export const CategoriesSelectModal = ({
  show,
  setShow,
  onConfirm,
  existing = [],
  categorySettings,
}) => {
  const [selectedCategories, setSelectedCategories] = useState(existing);

  const opts = useMemo(() => {
    return categorySettings.reduce((acc, category) => {
      if (category.enabled.report_card) {
        acc.push(category.category_name);
      }

      return acc;
    }, []);
  }, [categorySettings]);

  const isAlreadySelected = (prev, category) => {
    return prev.includes(category);
  };

  const toggleCategorySelect = (category) => {
    setSelectedCategories((prev: Set<string>) => {
      if (isAlreadySelected(prev, category)) {
        return [...prev].filter((y) => y !== category);
      } else {
        return [...prev, category];
      }
    });
  };

  const handleSave = () => {
    onConfirm(selectedCategories);
    setShow(false);
  };

  let Render = (
    <div className="add-categories">
      <h1>Select categories</h1>
      {opts.length < 1 && (
        <p>There are no categories that currently have data.</p>
      )}
      <ul>
        {opts.map((opt) => {
          const checked = isAlreadySelected(selectedCategories, opt);
          // const optLabel = getOptLabel(opt);

          return (
            <li
              style={{ background: checked ? '#D7E7FF' : '#F3F7FD' }}
              onClick={() => toggleCategorySelect(opt)}
            >
              <input
                type="checkbox"
                checked={checked}
                onChange={() => toggleCategorySelect(opt)}
                name=""
                id=""
              />
              <span>{opt} </span>
            </li>
          );
        })}
      </ul>
      <div className="add-categories__footer">
        <Button appearance="subtle" onClick={() => setShow(false)}>
          Cancel
        </Button>
        {opts.length >= 1 && (
          <Button className="ml-3" onClick={handleSave}>
            Confirm
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <Overlay show={show} close={() => setShow(false)}>
      {Render}
    </Overlay>
  );
};
