//@ts-check
import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

import { useApiPotentialOptions } from "../Api";
import { MIN_DATE, MAX_DATE } from "../date-utils";
import {
  TEST_TYPE_OPTIONS,
  SUB_TYPE_OPTIONS,
  AUTOMATION_STUDY_TYPE_OPTIONS,
} from "../constants";

export function FormGroup({ label, Input }) {
  return (
    <div className="d-flex flex-row justify-content-center align-items-center mb-3">
      <label className="mr-5 text-right" style={{ width: "100px" }}>
        {label}
      </label>
      <div style={{ width: "400px" }}>{Input}</div>
    </div>
  );
}

FormGroup.propTypes = {
  label: PropTypes.string.isRequired,
  Input: PropTypes.node.isRequired,
};

export function StudyStatus({ value, name, onChange, disabled = false }) {
  const active = {
    label: "Active",
    value: "active",
  };
  const draft = {
    label: "Draft",
    value: "draft",
  };

  return (
    <FormGroup
      label="Status"
      Input={
        <Select
          isDisabled={disabled}
          value={value === "draft" ? draft : active}
          onChange={(option) => onChange(name, !!option ? option.value : "")}
          options={[active, draft]}
        />
      }
    />
  );
}

export function Description({ value, name, onChange, disabled = false }) {
  return (
    <FormGroup
      label="Description"
      Input={
        <textarea
          disabled={disabled}
          name="description"
          className="form-control"
          value={value}
          onChange={(e) => onChange(name, e.target.value)}
          required
        ></textarea>
      }
    />
  );
}

const CURRENCY_MASK = createNumberMask({
  prefix: "$",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2,
  integerLimit: 5,
  allowNegative: false,
  allowLeadingZeroes: false,
});

export function SpendGoal({ value, name, onChange, disabled = false }) {
  return (
    <FormGroup
      label="Spend Goal"
      Input={
        <MaskedInput
          disabled={disabled}
          mask={CURRENCY_MASK}
          className="form-control basic-input"
          type="text"
          value={value}
          onChange={(e) => {
            const val = e.target.value;

            onChange(name, val);
          }}
        />
      }
    />
  );
}

export function TestType({
  options = TEST_TYPE_OPTIONS,
  name,
  onChange,
  value,
  disabled = false,
}) {
  const lookup = optionLookup(options);

  return (
    <FormGroup
      label="Test Type"
      Input={
        <Select
          isDisabled={disabled}
          value={lookup[value] || null}
          onChange={(option) => onChange(name, !!option ? option.value : "")}
          options={options}
        />
      }
    />
  );
}

export function SubType({
  options = SUB_TYPE_OPTIONS,
  onChange,
  value,
  name,
  disabled,
}) {
  const lookup = optionLookup(options);

  return (
    <FormGroup
      label="Sub Type"
      Input={
        <Select
          isDisabled={disabled}
          value={lookup[value] || null}
          onChange={(option) => onChange(name, !!option ? option.value : "")}
          options={options}
        />
      }
    />
  );
}

export function StartDate({
  onChange,
  name,
  value,
  min = MIN_DATE,
  max = MAX_DATE,
  disabled = false,
}) {
  return (
    <FormGroup
      label="Start Date"
      Input={
        <input
          disabled={disabled}
          className="form-control basic-input"
          type="date"
          min={min}
          max={max}
          name={name}
          value={value}
          onChange={(e) => onChange(name, e.target.value)}
          required
        />
      }
    />
  );
}

export function EndDate({ value, name, onChange, min }) {
  return (
    <FormGroup
      label="End Date"
      Input={
        <input
          type="date"
          name={name}
          min={min}
          className="form-control basic-input"
          value={value}
          onChange={(e) => onChange(name, e.target.value)}
          required
        />
      }
    />
  );
}

export function TargetCampaign({
  name,
  value,
  onChange,
  disabled = false,
  automation_study_type,
}) {
  const options = campaignOptions(automation_study_type);
  const lookup = optionLookup(options);

  return (
    <FormGroup
      label="Target Campaign"
      Input={
        <Select
          isDisabled={disabled}
          value={lookup[value] || null}
          onChange={(option) => onChange(name, !!option ? option.value : "")}
          options={options}
          isClearable
        />
      }
    />
  );
}

export function ComparisonCampaigns({ value, onChange, disabled = false }) {
  const options_ = useApiPotentialOptions("manual_campaigns");

  let uniq = new Map();

  for (const { value, label } of options_) {
    if (uniq.has(value)) {
      uniq.set(value, `${uniq.get(value)} & ${label}`);
    } else {
      uniq.set(value, label);
    }
  }

  const options = [...uniq].map(([value, label]) => ({ value, label }));

  return (
    <FormGroup
      label="Comparison Campaigns"
      Input={
        <Select
          isDisabled={disabled}
          options={options}
          value={valueSelection(value, options)}
          onChange={(selected, _) => onChange("comparison_campaigns", selected)}
          isMulti
        />
      }
    />
  );
}

export function Audience({
  name,
  label,
  value,
  opposingValue = "",
  onChange,
  campaigns = [],
  disabled = false,
}) {
  const options = useApiPotentialOptions("audience");
  const filtered = applyFilters(options, campaigns, opposingValue);
  const lookup = optionLookup(filtered);

  return (
    <FormGroup
      label={label}
      Input={
        <Select
          isDisabled={disabled}
          value={lookup[value] || null}
          onChange={(option) => onChange(name, !!option ? option.value : "")}
          options={filtered}
          isClearable
        />
      }
    />
  );
}

export function Creative({
  name,
  label,
  value,
  opposingValue = "",
  onChange,
  campaigns = [],
  disabled = false,
}) {
  const options = useApiPotentialOptions("creative");
  const filtered = applyFilters(options, campaigns, opposingValue);
  const lookup = optionLookup(filtered);

  return (
    <FormGroup
      label={label}
      Input={
        <Select
          isDisabled={disabled}
          value={lookup[value] || null}
          onChange={(option) => onChange(name, !!option ? option.value : "")}
          options={filtered}
          isClearable
        />
      }
    />
  );
}

function optionLookup(options) {
  return options.reduce((acc, item) => {
    acc[item.value] = item;

    return acc;
  }, {});
}

function campaignOptions(automation_study_type) {
  return automation_study_type === "automatic"
    ? useApiPotentialOptions("campaign")
    : useApiPotentialOptions("manual_campaigns");
}

function valueSelection(value, options) {
  return !!value && value.length > 0 ? value : options;
}

export function FormText({
  onChange,
  name,
  value,
  label,
  required = false,
  disabled = false,
}) {
  return (
    <FormGroup
      label={label}
      Input={
        <input
          type="text"
          name={name}
          className="form-control basic-input"
          value={value}
          onChange={(e) => onChange(name, e.target.value)}
          required={required}
          disabled={disabled}
        />
      }
    />
  );
}

FormText.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export function AutomationStudyType({
  options = AUTOMATION_STUDY_TYPE_OPTIONS,
  name,
  onChange,
  value,
  disabled = false,
}) {
  const lookup = optionLookup(options);

  return (
    <FormGroup
      label="Study Type"
      Input={
        <Select
          isDisabled={disabled}
          value={lookup[value] || options[0]}
          onChange={(option) => onChange(name, !!option ? option.value : "")}
          options={options}
        />
      }
    />
  );
}

function applyFilters(options, campaigns = [], opposingValue = "") {
  const L = campaigns.length;

  if (L === 0 && !opposingValue) {
    return options;
  } else if (L > 0) {
    return options.filter(
      ({ campaign_id }) => !!campaigns.find(({ id }) => campaign_id === id)
    );
  } else if (opposingValue) {
    return options.filter(({ value }) => value !== opposingValue);
  } else {
    return options.filter(({ value, campaign_id }) => {
      return (
        !!campaigns.find(({ id }) => campaign_id === id) &&
        value !== opposingValue
      );
    });
  }
}
