import React from 'react';
import Button from '../../components/Button';
import { TabsState } from './types';

export const Toggles = ({
  tabs,
  setTabs,
}: {
  tabs: TabsState;
  setTabs: React.SetStateAction<TabsState>;
}) => {
  return (
    <div className="bev-toggles">
      <Button
        appearance={!!tabs['experiments'] ? 'primary' : 'grayed'}
        onClick={() =>
          setTabs((prev) => {
            return {
              ...prev,
              experiments: !prev.experiments,
            };
          })
        }
        className="mr-3"
      >
        Experiments
      </Button>
      <Button
        appearance={!!tabs['findings'] ? 'primary' : 'grayed'}
        onClick={() =>
          setTabs((prev) => {
            return {
              ...prev,
              findings: !prev.findings,
            };
          })
        }
        className="mr-3"
      >
        Findings
      </Button>
      <Button
        appearance={!!tabs['chart'] ? 'primary' : 'grayed'}
        onClick={() =>
          setTabs((prev) => {
            return {
              ...prev,
              chart: !prev.chart,
            };
          })
        }
        className=""
      >
        Chart
      </Button>
    </div>
  );
};
