import React from "react";
import { useCreativeRequestGet, useCreativeRequestSet } from "./contexts";

const RequestNav = ({ setSaveStatus }) => {
  const { requests, currentIndex } = useCreativeRequestGet();
  const { setCurrentIndex } = useCreativeRequestSet();

  return (
    <div className="request-nav d-flex noselect-all">
      {requests.map((req, i) => (
        <div
          className={
            currentIndex === i
              ? "request-nav__item request-nav__item--selected"
              : "request-nav__item"
          }
          onClick={() => {
            setCurrentIndex(i);
            setSaveStatus({
              error: false,
              message: "",
            });
          }}
        >
          {i + 1}
        </div>
      ))}
    </div>
  );
};

export default RequestNav;
