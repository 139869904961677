import React, { useState } from 'react';
import LazyLoader from '../../../components/LazyLoader';
import Tooltip from '../../../components/Tooltip';
import { toCurrency } from '../../../utils/numbers';
import { formatMetricValue } from '../../DNA/utils';

const HurdleVisual = ({
  visual,
  primary_metric,
  primaryMetricLabel,
  isROAS,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { visual_type, asset_url, thumbnail_url } = visual;

  let videoDisplay = (
    <div className={`video-display ${isHovered ? 'hovered' : ''}`}>
      <div className="video-icon">
        <i className="fa fa-play" />
      </div>
      <img src={thumbnail_url} />
      {isHovered && asset_url && (
        <video className="" key={asset_url} autoPlay loop muted plays-inline>
          <source src={asset_url} type="video/mp4" />
        </video>
      )}
      {!asset_url && !thumbnail_url && (
        <p className="missing-asset-url">
          <i className="fa-solid fa-triangle-exclamation"></i>
          Missing asset URL
        </p>
      )}
    </div>
  );

  return (
    <LazyLoader
      className="visual-preview"
      placeholder={<div style={{ height: '200px' }} />}
    >
      <Tooltip
        content={
          <div>
            <p className="mb-0">{`${primaryMetricLabel}: ${formatMetricValue(
              primary_metric,
              visual[primary_metric],
              isROAS
            )}`}</p>
            <p className="mb-0">{`Spend: ${toCurrency(visual.spend)}`}</p>
          </div>
        }
        follow={false}
      >
        <div
          className="overlay"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
          }}
          onMouseOver={() => setIsHovered(true)}
          onMouseOut={() => setIsHovered(false)}
        ></div>
      </Tooltip>
      {visual_type === 'image' && (
        <div className="image-display">
          <img src={asset_url} alt="" />
        </div>
      )}
      {visual_type === 'video' && videoDisplay}
    </LazyLoader>
  );
};

export default HurdleVisual;
