import INITIAL_STATE from '../initial-state';
import { VIEW_SETTINGS_SET } from './actions';

const ViewSettings = (state = INITIAL_STATE.ViewSettings, action) => {
  switch (action.type) {
    case VIEW_SETTINGS_SET:
      return {
        ...state,
        ...action.settings,
      };

    default:
      return state;
  }
};

export default ViewSettings;
