export const getLabels = (audiences, metric) => {
  let top = null;
  let worst = null;

  const labels = audiences[0].labels.map(({ name, value }) => ({
    name,
    value,
  }));

  return [
    labels.reduce((acc, label) => {
      let audiences_for_label = [];

      audiences.forEach((audience) => {
        let match = null;

        audience.labels.forEach((lab) => {
          if (metric.includes('cpa')) {
            if (lab.cpa && (lab.cpa < top || top === null)) top = lab.cpa;
            if (lab.cpa && (lab.cpa > worst || worst === null)) worst = lab.cpa;
          } else if (metric.includes('roas')) {
            if (lab.roas && (lab.roas > top || top === null)) top = lab.roas;
            if (lab.roas && (lab.roas < worst || worst === null))
              worst = lab.roas;
          }

          if (lab.value === label.value) match = lab;
          if (lab.cpa && match) match.roas = 1 / lab.cpa;
        });

        if (match)
          audiences_for_label.push({ ...match, audience: audience.audience });
      });

      acc.push({
        ...label,
        audiences: audiences_for_label,
      });

      return acc;
    }, []),
    top,
    worst,
  ];
};