/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "../../../components/Hooks/search-params";

import { usePredictionsApi } from "./api";

import "./Predictions.scss";

const Predictions = ({
  Results,
  Justifications,
  Suggestions,
  Accuracy,
  suggestions,
  accuracy,
}) => (
  <div className="predictions">
    {Results}
    <div className="predictions-body">
      {suggestions && Suggestions}
      {Justifications}
      {accuracy && Accuracy}
    </div>
  </div>
);

const QuintileBar = ({ active }) => {
  return (
    <div
      className={`quintile-block__bar ${
        active ? "quintile-block__bar--active" : ""
      }`}
    />
  );
};

export const Results = ({ quintile }) => {
  let quintileScore = null;
  let quintileText = null;

  switch (quintile) {
    case 1:
      quintileScore = "Very Poor";
      quintileText = (
        <>
          in the <span>bottom 20%</span>
        </>
      );
      break;
    case 2:
      quintileScore = "Poor";
      quintileText = (
        <>
          in the <span>bottom 40%</span>
        </>
      );
      break;
    case 3:
      quintileScore = "Average";
      quintileText = <span>average</span>;
      break;
    case 4:
      quintileScore = "Good";
      quintileText = (
        <>
          in the <span>top 40%</span>
        </>
      );
      break;
    case 5:
      quintileScore = "Very Good";
      quintileText = (
        <>
          in the <span>top 20%</span>
        </>
      );
      break;
    default:
      break;
  }

  return (
    <div className="predictions-results">
      <h2 className="predictions-results__score">{quintileScore}</h2>
      <h3 className="predictions-results__subtitle">
        Expected CPAs will be {quintileText}.
      </h3>
      <div className="performance-quintiles">
        <div className="quintile-block quintile-block--very-poor">
          <QuintileBar active={quintile === 1} />
        </div>
        <div className="quintile-block quintile-block--poor">
          <QuintileBar active={quintile === 2} />
        </div>
        <div className="quintile-block quintile-block--average">
          <QuintileBar active={quintile === 3} />
        </div>
        <div className="quintile-block quintile-block--good">
          <QuintileBar active={quintile === 4} />
        </div>
        <div className="quintile-block quintile-block--very-good">
          <QuintileBar active={quintile === 5} />
        </div>
      </div>
    </div>
  );
};

const Justifications = ({ negativeAttributes, positiveAttributes }) => (
  <div className="justifications">
    {positiveAttributes && (
      <>
        {positiveAttributes.length ? (
          <h3 className="justifications__heading">Positive Attributes</h3>
        ) : null}
        <ul className="justifications__list">
          {positiveAttributes.map((justification) => {
            const headerSentence = justification.headerSentence.replace(
              "attribute:",
              "attribute:\n"
            );
            return (
              <li className="justifications__item">
                <p className="justifications__label">{headerSentence}</p>
                <p className="justifications__why">
                  {justification.detailsSentence}.
                </p>
              </li>
            );
          })}
        </ul>
      </>
    )}
    {negativeAttributes && (
      <>
        {negativeAttributes.length ? (
          <h3 className="justifications__heading">Negative Attributes</h3>
        ) : null}
        <ul className="justifications__list">
          {negativeAttributes.map((justification) => {
            const headerSentence = justification.headerSentence.replace(
              "attribute:",
              "attribute:\n"
            );
            return (
              <li className="justifications__item">
                <p className="justifications__label">{headerSentence}</p>
                <p className="justifications__why">
                  {justification.detailsSentence}.
                </p>
              </li>
            );
          })}
        </ul>
      </>
    )}
  </div>
);

const Suggestions = ({ suggestions }) => {
  if (!suggestions.length) {
    return null;
  }

  return (
    <div className="suggestions">
      <h3 className="justifications__heading">Suggestions To Improve</h3>
      <ul className="suggestions__list">
        {suggestions.map((suggestion) => {
          return <li className="suggestions__item">{suggestion}</li>;
        })}
      </ul>
    </div>
  );
};

const Accuracy = ({ accuracy }) => {
  return (
    <p className="justifications__accuracy">
      The accuracy is estimated at <span>{accuracy}%.</span>
    </p>
  );
};

const PredictionsLoading = () => (
  <div className="predictions">
    <div className="predictions-results">
      <h2 className="predictions-results__score">Please wait</h2>
      <h3 className="predictions-results__subtitle loading-text">
        Attempting to make a prediction
      </h3>
      <div className="performance-quintiles">
        <div className="quintile-block quintile-block--gray" />
        <div className="quintile-block quintile-block--gray" />
        <div className="quintile-block quintile-block--gray" />
        <div className="quintile-block quintile-block--gray" />
        <div className="quintile-block quintile-block--gray" />
      </div>
    </div>
  </div>
);

const PredictionsError = ({ reason }) => (
  <div className="predictions">
    <div className="predictions-error">
      <h2 className="predictions-error__heading">{reason}.</h2>
      {/* {reason && <h3 className="predictions-error__subheading">Reason: {reason}.</h3>} */}
    </div>
    <h3 className="justifications__heading">Potential Troubleshooting</h3>
    <ul className="predictions-error__list">
      <li>
        Make sure that you are running enough ads in the campaigns linked to the
        view.
      </li>
      <li>
        Make sure the campaigns in the view have a good amount of data over the
        last 30 days.
      </li>
      <li>
        Make sure that the Performance Frequency report is set to “asset level”
        reporting.
      </li>
      <li>
        Make sure the down funnel event is correct in the client settings.
      </li>
    </ul>
  </div>
);

const PredictionsWrapper = ({ show, filename, version, project_id }) => {
  const [predictions, setPredictions] = useState({});
  const predictionsApi = usePredictionsApi();
  const { view_id } = useSelector(({ ViewSettings: { viewId } }) => {
    return {
      view_id: viewId,
    };
  });
  const {
    negativeAttributes,
    positiveAttributes,
    accuracy,
    confidenceScore,
    predictionQuintile,
    suggestions,
  } = predictions;

  useEffect(() => {
    if (show) {
      predictionsApi.post.request({
        filename,
        version,
        project_id,
        view_id,
      });
    }
    
  }, [show, filename, version]);

  useEffect(() => {
    if (predictionsApi.post.data) {
      const { data } = predictionsApi.post;
      setPredictions(data.data);
    }
  }, [predictionsApi.post.data]);

  if (predictionsApi.post.loading) {
    return <PredictionsLoading />;
  }

  if (predictionsApi.post.error) {
    return <PredictionsError reason="Unable to make a prediction" />;
  }

  if (predictionsApi.post.data && predictions.error === true) {
    return <PredictionsError reason={predictions.errorReason} />;
  }

  return (
    <div className={`creative-display-wrapper ${show ? "show" : ""}`}>
      <Predictions
        Results={<Results quintile={predictionQuintile} />}
        Justifications={
          <Justifications {...{ negativeAttributes, positiveAttributes }} />
        }
        Suggestions={<Suggestions {...{ suggestions }} />}
        Accuracy={<Accuracy {...{ accuracy }} />}
        suggestions={suggestions}
        accuracy={accuracy}
      />
    </div>
  );
};

export default PredictionsWrapper;
