import React from 'react';
import Preview from './Preview';
import { formatMetricValue, getVisualsStats } from '../utils';
import { toPercent } from '../../../utils/numbers';
import { METRIC_VALUES_LOOKUP } from './constants';

const RightDisplay = ({
  hoveredVisual,
  height,
  isROAS,
  primary_metric,
  primaryMetricLabel,
  selectedVisuals,
  filteredVisuals,
  filteredAllVisualsLabel,
  columnDropdownOptions,
}) => {
  const selectedVisualsLabels = filteredVisuals.filter((label) =>
    selectedVisuals.includes(label.id)
  );
  const noneSelected = !selectedVisuals.length || !selectedVisuals[0];
  const selectedLosers = filteredAllVisualsLabel.losers.filter((label) =>
    selectedVisualsLabels.some((l) => l.id === label.id)
  );
  const selectedStats = getVisualsStats(selectedVisualsLabels, primary_metric);
  const loserStats = getVisualsStats(
    filteredAllVisualsLabel.losers,
    primary_metric
  );
  const selectedLoserStats = getVisualsStats(selectedLosers, primary_metric);
  const primaryMetric = columnDropdownOptions.find(
    (opt) => opt.label === primaryMetricLabel
  );
  const defaultMetric = isROAS ? 'aggregate_roas' : 'aggregate_cpa';
  const primaryMetricValue =
    METRIC_VALUES_LOOKUP[primary_metric] ||
    primaryMetric?.value ||
    defaultMetric;

  const avgPrimary = noneSelected
    ? filteredAllVisualsLabel[primaryMetricValue]
    : selectedStats.metric_sum / selectedStats.count;

  const percentSpendOnLosers = noneSelected
    ? loserStats.spend / filteredAllVisualsLabel.spend
    : selectedLoserStats.spend / selectedStats.spend;

  const MetricSection = ({ title, value, show, children }) => {
    return (
      <div className={`metric-section ${show ? 'show' : ''}`}>
        {title && <div className="metric-section__title">{title}</div>}
        {value && <div className="metric-section__value">{value}</div>}
        {children}
      </div>
    );
  };

  const rows = document.getElementsByClassName('right-display__row');
  const rowsHeight =
    rows[0]?.getBoundingClientRect().height +
    rows[1]?.getBoundingClientRect().height;

  return (
    <div
      className="right-display"
      style={{ height: !!height ? `${height}px` : 'auto' }}
    >
      <div className="right-display__row">
        <MetricSection
          title={`${primaryMetricLabel} (${
            noneSelected ? 'All' : 'Selected'
          }\u00A0Creatives)`}
          value={formatMetricValue(primary_metric, avgPrimary, isROAS)}
          show={true}
        />
        <MetricSection
          title={`${selectedVisuals.length ? '' : 'Total '}# of ${
            selectedVisuals.length ? 'Selected ' : ''
          }Creatives`}
          value={`${
            selectedVisuals.length
              ? selectedVisuals.length
              : [
                  ...filteredAllVisualsLabel.losers,
                  ...filteredAllVisualsLabel.winners,
                ].length
          }`}
          show={true}
        />
      </div>
      <div className="right-display__row">
        <MetricSection
          title={`% Spend on Below Average Performers (${
            selectedVisuals.length ? 'Selected' : 'All'
          }\u00A0Creatives)`}
          value={`${
            !noneSelected && selectedVisuals.length === 1
              ? 'N/A'
              : toPercent(percentSpendOnLosers)
          }`}
          show={true}
        />
      </div>
      <div
        className="right-display__row"
        style={{ height: `${height - rowsHeight + 4}px` }}
      >
        <MetricSection title={null} value={null} show={!!hoveredVisual}>
          <Preview
            visual={hoveredVisual}
            isROAS={isROAS}
            primaryMetricLabel={primaryMetricLabel}
          />
        </MetricSection>
      </div>
    </div>
  );
};

export default RightDisplay;
