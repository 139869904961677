import {
  ALL_VISUALS,
  BRAND,
  FORMAT,
  KEYWORD,
  PHRASES,
  PROMO,
  PROMOTION_TYPE,
} from '../CreativeAnalytics/categories';
import {
  DEFAULT_TEMPLATE,
  INSIGHTS_CATEGORIES,
  SINGLE_VALUE,
} from './constants';
import { cleanup } from './hooks';

const findCategoryLabels = (filteredData, cards) => {
  let result = {};
  let categories = new Set();

  cards.forEach((card) => {
    categories.add(card.category);
  });

  Array.from(categories).forEach((category) => {
    filteredData.forEach((label) => {
      const hasData = !label.testing_opportunity;

      if (label.category === category && hasData) {
        if (result[category]) {
          result[category] = [...result[category], label];
        } else {
          result[category] = [label];
        }
      }
    });
  });

  return result;
};

export const DEFAULT_CONFIG = [
  {
    category: BRAND,
    metric_options: ['spend'],
  },
  {
    category: BRAND,
    metric_options: ['aggregate_cpa'],
  },
  {
    category: FORMAT,
    metric_options: ['aggregate_cpa', 'spend'],
  },
  {
    category: KEYWORD,
    metric_options: ['aggregate_cpa', 'percent_budget'],
  },
  {
    category: PHRASES,
    metric_options: ['aggregate_cpa', 'percent_budget'],
  },
  {
    category: ALL_VISUALS,
    metric_options: ['number_unique_creatives'],
  },
  {
    category: ALL_VISUALS,
    metric_options: ['spend', 'aggregate_cpa'],
  },
  {
    category: PROMO,
    metric_options: ['aggregate_cpa', 'spend'],
  },
  {
    category: PROMOTION_TYPE,
    metric_options: ['aggregate_cpa', 'percent_budget'],
  },
];

export function arrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

export const generateId = (category) => {
  const { category: category_name, metric_options } = category;
  if (!metric_options) return category_name;
  return `${category_name}+${metric_options.join('+')}`;
};

const getCardTemplates = (config, TEMPLATES) => {
  return config.reduce((acc, category, i) => {
    const maybe = TEMPLATES.find((template) => {
      return (
        template.category === category.category &&
        arrayEquals(template.metric_options, category.metric_options)
      );
    });

    const template = maybe || DEFAULT_TEMPLATE;

    acc.push({
      ...template,
      id: category.id || generateId(category),
      category: category.category,
    });

    return acc;
  }, []);
};

export const getCards = (filteredData, config, TEMPLATES) => {
  const cards = getCardTemplates(withIds(config), TEMPLATES);

  const labelsByCategory = findCategoryLabels(filteredData, cards);

  return cards.reduce((acc, current) => {
    const { category } = current;

    if (labelsByCategory[category]) {
      acc.push({
        ...current,
        data: labelsByCategory[category],
      });
    }

    return acc;
  }, []);
};

export const makeDefaultConfig = (cards) => {
  return cards;
};

export const makeSavePayload = (config, account_id) => {
  return {
    account_id,
    categories: cleanup(config).map(({ category, id, metric_options }) => ({
      category: category || '',
      id: id || '',
      metric_options: metric_options || [],
    })),
  };
};

export const withIds = (config) =>
  config.map((category) => {
    let id = category.id || generateId(category);

    if (category.id === category.category) {
      id = generateId(category);
    }

    return {
      ...category,
      id,
    };
  });


// for Keywords and Phrases, align to right side when expanded, w/ Keyword as priority
export const getIndexFromRight = (category, expandedCategories) => {
  const preferRight = getPreferRight(expandedCategories)
  return preferRight.findIndex((c) => c === category);
}

const getPreferRight = (expandedCategories) =>
  ['Keyword+aggregate_cpa+percent_budget', 'Phrases+aggregate_cpa+percent_budget'].filter((x) => expandedCategories?.includes(x));
