import { batch } from 'react-redux';
import {
  DASHBOARD_FETCH_NAV,
  DASHBOARD_FETCH_ADS,
  DASHBOARD_FETCH_SUPPLEMENTAL,
  NAV,
  ADS,
  SUPPLEMENTAL,
  setCreativeTestingNav,
  setCreativeTestingAds,
  setCreativeTestingSupplemental,
  setCreativeTesting,
  fetchCreativeTestingSupplmental,
} from './actions';
import { API_SUCCESS, apiRequest, API_FAILURE } from '../Api/actions';
import { setLoader } from '../Loaders/actions';
import { setError } from '../Errors/actions';

const navMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case DASHBOARD_FETCH_NAV:
        const {
          ViewSettings: { viewId, campaigns, account_id },
        } = getState();

        batch(() => {
          dispatch(setError(NAV, false));
          dispatch(setLoader(NAV, true));
          dispatch(
            apiRequest({
              body: { campaigns, account_id },
              url: ['api', viewId, 'ad-carousel'].join('/'),
              entity: NAV,
              method: 'POST',
            })
          );
        });

        break;

      case `[${NAV}] ${API_SUCCESS}`:
        const { response } = action;

        batch(() => {
          dispatch(
            setCreativeTestingNav({
              nav: response || [],
            })
          );

          dispatch(setLoader(NAV, false));

          dispatch(
            setCreativeTesting({
              currentId: response[0] ? response[0].ad_id : '',
            })
          );
        });

        break;

      case `[${NAV}] ${API_FAILURE}`:
        const { error } = action;

        batch(() => {
          dispatch(setError(NAV, error));
          dispatch(setLoader(NAV, false));
        });

        break;

      default:
        return result;
    }
  };

const firstMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case DASHBOARD_FETCH_ADS:
        const {
          ViewSettings: {
            viewId,
            start_date,
            end_date,
            selectedAudience,
            campaigns,
            account_id,
          },
          CreativeTesting: { ads },
        } = getState();
        const { ad_state, ad_id } = action.data;

        dispatch(
          setCreativeTesting({ currentId: ad_id, currentStateType: ad_state })
        );

        if (!ads[ad_id]) {
          batch(() => {
            dispatch(setError(ADS, false));
            dispatch(setLoader(ADS, true));

            dispatch(
              apiRequest({
                body: {
                  campaigns,
                  account_id,
                  viewId,
                  ad_state,
                  ad_id,
                  start_date,
                  end_date,
                  audience: [selectedAudience].filter((a) => a !== ''),
                },
                url: ['api', viewId, 'creative-testing'].join('/'),
                entity: ADS,
                method: 'POST',
              })
            );
          });
        }

        break;

      case `[${ADS}] ${API_SUCCESS}`:
        const { response } = action;

        batch(() => {
          dispatch(
            setCreativeTestingAds({
              ad: response,
            })
          );
          dispatch(setLoader(ADS, false));

          dispatch(fetchCreativeTestingSupplmental({ ad_id: response.ad_id }));
        });

        break;

      case `[${ADS}] ${API_FAILURE}`:
        const { error } = action;

        batch(() => {
          dispatch(setError(ADS, error));
          dispatch(setLoader(ADS, false));
        });

        break;

      default:
        return result;
    }
  };

const secondMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case DASHBOARD_FETCH_SUPPLEMENTAL:
        const {
          ViewSettings: {
            viewId,
            start_date,
            end_date,
            selectedAudience,
            campaigns,
            account_id,
          },
          CreativeTesting: { ads, currentStateType },
        } = getState();
        const { ad_id } = action.data;

        if (ads[ad_id]) {
          batch(() => {
            dispatch(setError(SUPPLEMENTAL, false));
            dispatch(setLoader(SUPPLEMENTAL, true));

            dispatch(
              apiRequest({
                body: {
                  campaigns,
                  account_id,
                  viewId,
                  ad_state: currentStateType,
                  ad_id,
                  start_date,
                  end_date,
                  audience: [selectedAudience].filter((a) => a !== ''),
                },
                url: ['api', viewId, 'creative-testing-supplemental'].join('/'),
                entity: SUPPLEMENTAL,
                method: 'POST',
              })
            );
          });
        }

        break;

      case `[${SUPPLEMENTAL}] ${API_SUCCESS}`:
        const state = getState();
        const { response } = action;

        batch(() => {
          dispatch(
            setCreativeTestingSupplemental({
              stateType: state.CreativeTesting.currentStateType,
              ads: response.find(
                ({ id }) => id === state.CreativeTesting.currentId
              ),
            })
          );
          dispatch(setLoader(SUPPLEMENTAL, false));
        });

        break;

      case `[${SUPPLEMENTAL}] ${API_FAILURE}`:
        const { error } = action;

        batch(() => {
          dispatch(setError(SUPPLEMENTAL, error));
          dispatch(setLoader(SUPPLEMENTAL, false));
        });

        break;

      default:
        return result;
    }
  };

const middlewares = [
  navMiddleware,
  firstMiddleware,
  // secondMiddleware
];

export default middlewares;
