import React, { useState, useReducer, useEffect, useMemo } from 'react';

import { useInputState } from './hooks';

import EditorContainer from './Editor';
import CategorySetup from './Setup';

import { reducer } from './reducer';
import { useCreateApi, useUpdateApi, useDeleteApi } from './api';
import { useAccountId } from '../../../selectors';

import './UDC.scss';
import {
  formatExistingLabels,
  notifyCreateSuccess,
  notifySaving,
} from './utils';
import { useCreativeAnalyticsSet } from '../../../contexts';

/* example

name: 'Example',
description: 'User defined category',
visual_mode: true,
labels: [
  {
    name: '',
    filters: [
      {
        name: 'campaigns',
        include: '',
        exclude: '',
      },
      {
        name: 'adset',
        include: '',
        exclude: '',
      },
      {
        name: 'ad',
        include: '',
        exclude: '',
      },
    ]
  },
]

*/
export const INITIAL_UPDATED = {
  name: false,
  description: false,
  visual_mode: false,
  labels: false,
};

const UDC = ({
  stage,
  prev,
  next,
  dropdownOptions,
  udcData,
  category,
  fetchPerformanceData,
  isNew,
  udcApi,
}) => {
  const { setEditCategory } = useCreativeAnalyticsSet();
  const [loadingMsg, setLoadingMsg] = useState('');
  const existing =
    udcData && !isNew
      ? udcData.find((c) => c.udc_name === category.category)
      : null;
  const [updatedFields, setUpdatedFields] = useState(INITIAL_UPDATED);
  const markFieldAsUpdated = (field) =>
    setUpdatedFields((prev) => ({
      ...prev,
      [field]: true,
    }));
  const [input, handleInput] = useInputState(markFieldAsUpdated, existing);
  const [{ labels, index }, dispatch] = useReducer(reducer, {
    index: existing ? 0 : -1,
    labels: existing ? formatExistingLabels(existing.user_defined_labels) : [],
  });
  const account_id = useAccountId();

  useEffect(() => {
    if (existing) {
      next();
    }
  }, [existing]);

  const handleCreate = (payload) => {
    udcApi.saveUdc({
      action: 'create',
      category_name: input.name,
      payload,
    });
  };

  const handleUpdate = (payload) => {
    udcApi.saveUdc({
      action: 'update',
      category_name: input.name,
      payload,
    });
  };

  const handleDelete = (payload) => {
    udcApi.saveUdc({
      action: 'delete',
      category_name: input.name,
      payload,
    });
  };

  return (
    <div className="udc">
      {stage === 1 && (
        <CategorySetup
          {...{
            dropdownOptions,
            next,
            prev,
            input,
            handleInput,
            existing,
          }}
        />
      )}
      {stage === 2 && (
        <EditorContainer
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          {...{
            prev,
            existing,
            markFieldAsUpdated,
            account_id,
            updatedFields,
            labels,
            index,
            dispatch,
            loadingMsg,
          }}
          input={input}
        />
      )}
    </div>
  );
};

export default UDC;
