import { useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { withIds } from './utils';
import { useEffect, useRef, useState, useMemo } from 'react';
import { useDebounce } from '../../hooks/useDebounce';
import { usePrevious } from '../../hooks/usePrevious';
import { ConfigObject, DashboardConfig } from './types';
import { DEFAULT_CONFIG, makeSavePayload } from './utils';
import { useConfigApi } from './api';

const DEFAULT = ['Prospecting', 'Retargeting'];

export const useAudience = () => {
  const { audiences, selectedAudience } = useSelector(
    ({ ViewSettings: { audience, selectedAudience } }) => {
      return {
        audiences: audience?.length ? audience : DEFAULT,
        selectedAudience: selectedAudience?.length ? selectedAudience : DEFAULT,
      };
    }
  );

  return { audiences, selectedAudience };
};

export const cleanup = (data) => {
  return data?.filter(
    (category, index, self) =>
      category?.category?.length &&
      self.findIndex((category2) => category2?.id === category?.id) === index &&
      category.id !== 'All Visuals+spend+aggregate_cpa'
  );
};

export const useDashboardConfig = (account_id) => {
  const api = useConfigApi();
  const [config, setConfig] = useState<DashboardConfig>(
    withIds(DEFAULT_CONFIG)
  );
  const debouncedConfig: DashboardConfig = useDebounce(config, 3000);
  const prevConfig: DashboardConfig = usePrevious(debouncedConfig);
  const justFetched = useRef(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [saveError, setSaveError] = useState(false);

  const { loading, error } = api.get;

  const waiting = useMemo(() => {
    return configHasChanged(config, debouncedConfig);
  }, [config, debouncedConfig]);

  useEffect(() => {
    api.get.request({ account_id });
  }, []);

  useEffect(() => {
    if (api.get.data) {
      const { data } = api.get;

      try {
        setConfig(withIds(cleanup(data)));
        justFetched.current = true;
      } catch (e) {
        console.error(e);
      }
    }
  }, [api.get.data]);

  useEffect(() => {
    if (api.save.data) {
      setSaveSuccess(true);
    }
  }, [api.save.data]);

  useEffect(() => {
    if (saveSuccess) {
      setTimeout(() => setSaveSuccess(false), 5000);
    }
  }, [saveSuccess]);

  useEffect(() => {
    if (saveError) {
      setTimeout(() => setSaveError(false), 5000);
    }
  }, [saveError]);

  const saveConfig = () => {
    console.log({
      prevConfig,
      haschanged: configHasChanged(config, prevConfig),
    });

    if (prevConfig) {
      if (configHasChanged(config, prevConfig)) {
        const payload = makeSavePayload(config, account_id);
        api.save.request(payload);
      }
    }
  };

  useEffect(() => {
    // console.log({ config, prevConfig });
    // console.log({ justFetched });
    // console.log('debounced config changed');

    // watch for config change -> save,
    // unless it was changed from fetch
    if (justFetched.current) {
      justFetched.current = false;
    } else {
      console.log('will save');
      saveConfig();
    }
  }, [debouncedConfig]);

  let messages = [];

  if (saveSuccess) {
    messages.push({ msg: 'Changes saved successfully.', ellipses: false });
  }

  if (saveError) {
    messages.push({
      msg: 'There was an error saving the configuration.',
      ellipses: false,
    });
  }

  if (waiting && justFetched.current === false) {
    messages.push({ msg: 'Editing', ellipses: true });
  }

  if (api.save.loading) {
    messages.push({ msg: 'Saving changes', ellipses: true });
  }

  return { config, setConfig, loading, error, messages };
};

const configHasChanged = (config, prevConfig) => {
  return (
    config?.length !== prevConfig?.length ||
    !config.every(({ id }, i) => prevConfig[i]?.id === id)
  );
};
