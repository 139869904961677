export const AD_LABELS = 'ad_labels';
// Not plural but var name plural for consistency.
// Ideally, the string "adset_label" will never
// be written anywhere else but here.
export const ADSET_LABELS = 'adset_label';
export const COPY = 'copy';
export const TRIGGER = 'trigger';
export const KEYWORD = 'keyword';
export const AD_NAME = 'ad_name';
export const ADSET_NAME = 'adset_name';
export const TESTING = 'testing';
export const CREATIVE_MATRIX = 'creative_matrix';
export const AD_STUDY = 'ad_study';
export const THEME = 'theme';
export const COLOR = 'color';
export const LABEL_MATRIX = 'label_matrix';
export const LABEL_SETTINGS = 'label_settings';
export const CREATIVE_INSIGHTS = 'creative_insights';
export const PERFORMANCE_FREQUENCY = 'performance_frequency';
export const VISUAL_HASH = 'visual_hash';
export const TOP_PERFORMERS = 'top_performers';
export const RECOMMENDATIONS = 'recommendations';
export const EFFICIENCY = 'efficiency';
export const LIBRARY = 'library';
export const INSIGHTS = 'insights';
export const TRENDING = 'trending';
export const DASHBOARD = 'dashboard';
export const PHRASE_THEME = 'phrase_theme';
export const CLUSTERS = 'clusters';
export const VARIATION = 'variation';
export const REPORT_BUILDER = 'report_builder';
export const HURDLE = 'hurdle';
export const COPILOT = 'copilot';

export const REPORT_TYPES = [
  AD_LABELS,
  KEYWORD,
  COPY,
  TRIGGER,
  AD_NAME,
  ADSET_NAME,
  TESTING,
  CREATIVE_MATRIX,
  AD_STUDY,
  THEME,
  COLOR,
  EFFICIENCY,
];
