/* eslint-disable no-unsafe-finally */

import { batch } from 'react-redux';

import { setLoader } from '../../Loaders/actions';
import { MARKETPLACE } from '../actions';

export const VERSIONS = `${MARKETPLACE}.versions`;
export const INTERNAL = `${VERSIONS}.internalApprovals`;
export const CLIENT = `${VERSIONS}.clientInternals`;

export const Versions = {
  TOGGLE_INTERNAL_APPROVAL: `[${VERSIONS}] TOGGLE INTERNAL APPROVAL`,
  TOGGLE_CLIENT_APPROVAL: `[${VERSIONS}] TOGGLE CLIENT APPROVAL`,
  UPDATE_ONE: `[${VERSIONS}] UPDATE ONE`,
  ADD_ONE: `[${VERSIONS}] ADD ONE`,
};

export function versionsAddOne(version, data) {
  return {
    type: Versions.ADD_ONE,
    data: { version, data },
  };
}

/**
 * @param {string} version
 * @param {Object<string, any>} data
 */
export function versionsUpdateOne(version, data) {
  return {
    type: Versions.UPDATE_ONE,
    data: { version, data },
  };
}

export function versionsToggleInternalApproval({ versionId, approved }) {
  return {
    type: Versions.TOGGLE_INTERNAL_APPROVAL,
    data: { versionId, approved },
  };
}

export function versionsToggleClientApproval({ versionId, client_approved }) {
  return {
    type: Versions.TOGGLE_CLIENT_APPROVAL,
    data: { versionId, client_approved },
  };
}

export function fetchInternalApproval({
  s3_filename,
  filename,
  version,
  approved,
  project_id,
  user_id,
}) {
  return async (dispatch) => {
    batch(() => {
      dispatch(setLoader(INTERNAL, true));
      dispatch(
        versionsToggleInternalApproval({
          versionId: version,
          approved,
        })
      );
    });

    try {
      const res = await fetch(
        ['api', 'marketplace', 'assets', project_id, 'approvals'].join('/'),
        {
          method: 'PUT',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({ s3_filename, filename, approved, version, user_id }),
        }
      );

      if (res.status !== 200) {
        dispatch(
          versionsToggleInternalApproval({
            versionId: version,
            approved: !approved,
          })
        );
      }
    } catch (e) {
      dispatch(
        versionsToggleInternalApproval({
          versionId: version,
          approved: !approved,
        })
      );
    } finally {
      return dispatch(setLoader(INTERNAL, false));
    }
  };
}

export function fetchClientApproval({
  s3_filename,
  filename,
  version,
  client_approved,
  project_id,
}) {
  return async (dispatch) => {
    batch(() => {
      dispatch(setLoader(CLIENT, true));

      dispatch(
        versionsToggleClientApproval({
          versionId: version,
          client_approved,
        })
      );
    });

    try {
      const res = await fetch(
        ['api', 'marketplace', 'assets', project_id, 'client-approvals'].join(
          '/'
        ),
        {
          method: 'PUT',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({
            s3_filename,
            filename,
            client_approved,
            version,
          }),
        }
      );

      if (res.status !== 200) {
        dispatch(
          versionsToggleClientApproval({
            versionId: version,
            client_approved: !client_approved,
          })
        );
      }
    } catch (e) {
      dispatch(
        versionsToggleClientApproval({
          versionId: version,
          client_approved: !client_approved,
        })
      );
    } finally {
      return dispatch(setLoader(CLIENT, false));
    }
  };
}
