import { useNotifications } from "../../../../components/Notification/useNotifications";

const formatRecommendations = (suggestions) => {
  const recommendations = Array.isArray(suggestions)
    ? suggestions
    : [suggestions];
  return recommendations.map(({ category, current, change }) => {
    return { category, current, change };
  });
};

export const makeRevisionPayload = (
  view_id,
  revision_type,
  emailInput,
  additional_message,
  suggestions,
  options
) => {
  let recommendations = [];
  let suggestionText = suggestions.phrase || suggestions.Recommendation;

  if (suggestions.reference_name)
    suggestionText = `Try a ${suggestions.reference_name} style.`;

  const designers_choice_msg =
    "Allow the designer to choose which data points to incorporate into a revision.";
    
  const ai_suggestion_msg = `${
    suggestions.recommendation_type || ""
  } Recommendation: ${suggestions.recommendation}, Reason: ${
    suggestions.why || suggestions.Why
  }${suggestions.visual_url ? `, Visual URL: ${suggestions.visual_url}` : ""}`;

  if (!options.designers_choice && !options.ai_suggestion) {
    recommendations = formatRecommendations(suggestions);
  }

  return {
    view_id,
    report_card_url: window.location.href,
    revision_type,
    custom_emails:
      revision_type === "custom_email" ? emailInput.split(",") : [],
    additional_message: options.designers_choice
      ? `${designers_choice_msg} ${additional_message}`
      : options.ai_suggestion
      ? `${ai_suggestion_msg} ${additional_message}`
      : additional_message,
    recommendations,
  };
};

export const notifySaving = () => {
  const notifications = useNotifications();

  notifications.add({
    id: `revision-request-loading`,
    title: "Sending revision request",
    message: "Please wait",
    showEllipses: true,
  });
};

export const notifyError = (error) => {
  const notifications = useNotifications();

  notifications.add({
    title: "There was an error.",
    message: error,
    toastProps: {
      className: "error-toast",
    },
  });
};

export const notifySuccess = () => {
  const notifications = useNotifications();

  notifications.add({
    title: "Success!",
    message: "Revision request sent successfully.",
  });
};
