
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, FormControl } from 'react-bootstrap';

import CustomDropdown, { UnboundCustomToggle } from '../CustomDropdown';

export const METRICS = [
  {
    label: 'Cost Per Result',
    value: 'cpr',
  },
  {
    label: 'Cost Per Click',
    value: 'cpc',
  },
  {
    label: 'Cost Per Impressions',
    value: 'cpm',
  },
];

export const LOOKBACKS = [
  {
    label: '7 Day Lookback',
    value: 'last_7d',
  },
  {
    label: 'Yesterday Lookback',
    value: 'yesterday',
  },
];

export const Picker = ({
  handleSelect,
  options,
  selected,
  menuStyle,
}) => (
  <CustomDropdown
    menuStyle={menuStyle}
    label={selected.label}
    onSelect={(i) => {
      const next = options[i];

      if (next.value !== selected.value) {
        handleSelect(next);
      }
    }}
  >
    {options.map(({ label, value }, i) => (
      <CustomDropdown.Item key={value} eventKey={i.toString()}>
        {label}
      </CustomDropdown.Item>
    ))}
  </CustomDropdown>
);

export const Value = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
});

Picker.defaultProps = {
  menuStyle: undefined,
};

Picker.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  selected: Value.isRequired,
  options: PropTypes.arrayOf(Value).isRequired,
  menuStyle: PropTypes.string,
};

export const MetricPicker = ({ handleSelect, menuStyle, selected }) => (
  <Picker {...{ handleSelect, menuStyle, selected }} options={METRICS} />
);

MetricPicker.defaultProps = {
  menuStyle: undefined,
};

MetricPicker.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  selected: Value.isRequired,
  menuStyle: PropTypes.string,
};

export const LookbackPicker = ({ handleSelect, menuStyle, selected }) => (
  <Picker {...{ handleSelect, menuStyle, selected }} options={LOOKBACKS} />
);

LookbackPicker.defaultProps = {
  menuStyle: undefined,
};

LookbackPicker.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  selected: Value.isRequired,
  menuStyle: PropTypes.string,
};

export const EntityPicker = ({
  handleSelect,
  selected,
  options,
  menuStyle,
}) => {
  const FilterableMenu = UnboundFilterableMenu(menuStyle);

  const trunc = selected.label.length > 80
    ? `${selected.label.slice(0, 77)}...`
    : selected.label;

  return (
    <Dropdown onSelect={handleSelect}>
      <Dropdown.Toggle id="history-entity-toggle" as={UnboundCustomToggle()}>
        <span title={selected.label}>{trunc}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu as={FilterableMenu} renderOnMount>
        {options.map(({ label, value }, i) => (
          <CustomDropdown.Item key={`entity-${value}`} eventKey={i.toString()}>
            {label}
          </CustomDropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

EntityPicker.defaultProps = {
  menuStyle: undefined,
};

EntityPicker.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  selected: Value.isRequired,
  options: PropTypes.arrayOf(Value).isRequired,
  menuStyle: PropTypes.shape({ color: PropTypes.string }),
};

const UnboundFilterableMenu = (_style = {}, _className = '') => React.forwardRef(
  ({
    children, style, className, 'aria-labelledby': labeledBy,
  }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={{ ...style, ..._style }}
        className={`${className} ${_className}`}
        aria-labelledby={labeledBy}
      >
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value.toLowerCase())}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) => !value || child.props.children.toLowerCase().includes(value),
          )}
        </ul>
      </div>
    );
  },
);
