import { Marketplace } from '../actions';
import { Previews } from './actions';

function setPreview(
  { ids, lookup, publish_access_lookup },
  { data, meta, publish_access }
) {
  const previewData = data || {};
  const id = meta.version_id;

  return {
    ids: [...ids.filter((anId) => anId !== id), id],
    lookup: {
      ...lookup,
      [id]: Object.keys(previewData).map((k) => ({
        value: previewData[k],
        label: k,
      })),
    },
    publish_access_lookup: {
      ...publish_access_lookup,
      [id]: publish_access,
    },
  };
}

export function previews(
  state = { ids: [], lookup: {}, publish_access_lookup: {} },
  action
) {
  switch (action.type) {
    case Previews.SET:
      return setPreview(state, action);

    case Marketplace.RESET_ASSETS:
      return {
        lookup: {},
        ids: [],
        publish_access_lookup: {},
      };

    default:
      return state;
  }
}
