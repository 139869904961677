import React, { useEffect, useState } from 'react';
import './SettingsModal.scss';
import Button from '../../../../../../components/Button';

const SettingsModal = ({
  showCopilotSettings,
  setShowCopilotSettings,
  updatedPreferences,
  setUpdatedPreferences,
  updatedDetails,
  setUpdatedDetails,
  handleSaveSettings,
  saveSettingsData,
  saveSettingsError,
  saveSettingsLoading,
  clientPreferences,
  advertiserDetails,
  expandDown,
}) => {
  const saved =
    saveSettingsData &&
    clientPreferences === updatedPreferences &&
    advertiserDetails === updatedDetails;

  return (
    <div
      className={`settings-modal ${showCopilotSettings ? 'show' : ''} ${
        expandDown ? 'expand-down' : ''
      }`}
    >
      <div className="mb-2">
        <div className="settings-modal__label">Client preferences</div>
        <textarea
          value={updatedPreferences}
          onChange={(e) => setUpdatedPreferences(e.target.value)}
        />
      </div>
      <div className="mb-2">
        <div className="settings-modal__label">Advertiser details</div>
        <textarea
          value={updatedDetails}
          onChange={(e) => setUpdatedDetails(e.target.value)}
        />
      </div>
      <div className="d-flex mr-3">
        <Button
          onClick={() => handleSaveSettings(false)}
          disabled={saveSettingsLoading}
          loading={saveSettingsLoading}
        >
          Save
        </Button>
        <Button
          onClick={() => handleSaveSettings(true)}
          disabled={saveSettingsLoading}
          loading={saveSettingsLoading}
        >
          Save & refresh suggestions
        </Button>
        <Button
          onClick={() => {
            setUpdatedPreferences(clientPreferences);
            setUpdatedDetails(advertiserDetails);
            setShowCopilotSettings(false);
          }}
          disabled={saveSettingsLoading}
          loading={saveSettingsLoading}
          appearance="subtle"
        >
          Cancel
        </Button>
      </div>
      <div></div>
      {!saveSettingsLoading && (
        <div style={{ fontSize: '1rem' }}>
          {saveSettingsError ? 'There was an error.' : saved ? 'Saved.' : ''}{' '}
        </div>
      )}
    </div>
  );
};

export default SettingsModal;
