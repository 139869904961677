import React from "react";
import { DARWIN_RED } from "../../constants";

const WarningIcon = ({ className = "", style = {}, fill }) => (
  <svg
    className={className}
    style={style}
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.98113 1.2739C8.88215 1.10148 8.73943 0.958234 8.56738 0.858617C8.39533 0.759 8.20004 0.706543 8.00123 0.706543C7.80242 0.706543 7.60713 0.759 7.43508 0.858617C7.26303 0.958234 7.12031 1.10148 7.02133 1.2739L0.165021 12.9397C-0.291933 13.7176 0.256012 14.7065 1.14492 14.7065H14.8565C15.7454 14.7065 16.2944 13.7166 15.8364 12.9397L8.98113 1.2739ZM7.99923 4.70755C8.53417 4.70755 8.95313 5.16951 8.89914 5.70245L8.54917 9.2091C8.53741 9.34686 8.47438 9.47518 8.37255 9.5687C8.27071 9.66221 8.13749 9.7141 7.99923 9.7141C7.86097 9.7141 7.72775 9.66221 7.62591 9.5687C7.52407 9.47518 7.46104 9.34686 7.44928 9.2091L7.09932 5.70245C7.08675 5.5767 7.10066 5.44971 7.14016 5.32966C7.17966 5.20961 7.24386 5.09916 7.32864 5.00544C7.41342 4.91171 7.51689 4.83678 7.63239 4.78547C7.74788 4.73417 7.87285 4.70763 7.99923 4.70755ZM8.00123 10.7069C8.26642 10.7069 8.52074 10.8123 8.70826 10.9998C8.89578 11.1873 9.00113 11.4417 9.00113 11.7068C9.00113 11.972 8.89578 12.2264 8.70826 12.4139C8.52074 12.6014 8.26642 12.7067 8.00123 12.7067C7.73604 12.7067 7.48171 12.6014 7.29419 12.4139C7.10668 12.2264 7.00133 11.972 7.00133 11.7068C7.00133 11.4417 7.10668 11.1873 7.29419 10.9998C7.48171 10.8123 7.73604 10.7069 8.00123 10.7069Z"
      fill={fill ?? "#f0ad4e"}
    />
  </svg>
);

export default WarningIcon;
