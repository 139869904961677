const YELP = 'act_2210554242517430';

type account_id = string;
type MetricType = string;
type ComparisonType =
  | 'greater'
  | 'greater_or_equal'
  | 'less'
  | 'less_or_equal'
  | 'equal';

type Condition = {
  metric: MetricType;
  comparison: ComparisonType;
  value: number | 'avg';
};

type Score = {
  score: number;
  conditions: Condition[];
};

type CustomScoringType = {
  [key: account_id]: {
    scores: Score[];
    preferLowerScore?: boolean;
  };
};

export const CUSTOM_SCORES: CustomScoringType = {
  [YELP]: {
    scores: [
      {
        score: 1,
        conditions: [
          { metric: 'cpa', comparison: 'less', value: 112 },
          { metric: 'spend', comparison: 'greater_or_equal', value: 10000 },
        ],
      },
      {
        score: 2,
        conditions: [
          { metric: 'cpa', comparison: 'less', value: 112 },
          { metric: 'spend', comparison: 'greater_or_equal', value: 5000 },
          { metric: 'spend', comparison: 'less', value: 10000 },
        ],
      },
      {
        score: 3,
        conditions: [
          { metric: 'cpa', comparison: 'less', value: 112 },
          { metric: 'spend', comparison: 'greater_or_equal', value: 1000 },
          { metric: 'spend', comparison: 'less', value: 5000 },
        ],
      },
      {
        score: 4,
        conditions: [
          { metric: 'cpa', comparison: 'less', value: 112 },
          { metric: 'spend', comparison: 'less', value: 1000 },
        ],
      },
      {
        score: 5,
        conditions: [
          { metric: 'cpa', comparison: 'greater_or_equal', value: 112 },
        ],
      },
    ],
    preferLowerScore: true,
  },
};
