import React, { useEffect } from 'react';
import Button from '../../../../components/Button';
import GridIcon from '../../../../components/Icons/GridIcon';
import { ColumnPicker } from '../../PerformanceFrequency/Pickers';
import { Table } from '../../PerformanceFrequency/Table/Table';
import { useCreativeAnalyticsGet } from '../../contexts';

const PfrTable = ({
  columns,
  handleColumns,
  setSelectedCategory,
  category_name,
  category_data,
  labels,
  selectedCategory,
}) => {
  const { clientMetrics } = useCreativeAnalyticsGet();

  useEffect(() => {
    if (selectedCategory) {
      document.querySelector('#pfr-table').scrollIntoView();
    }
  }, [selectedCategory]);

  return (
    <div className="pt-5" id="pfr-table">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex">
          <Button
            className="mr-2"
            style={{ height: '30px', padding: '0 6px' }}
            appearance="raised"
            active={columns.includes('visuals')}
            onClick={() => {
              handleColumns({
                column: 'visuals',
                checked: !columns.includes('visuals'),
              });
            }}
          >
            <GridIcon className="mr-2" /> Show visuals
          </Button>
          <ColumnPicker
            columns={columns}
            clientMetrics={clientMetrics}
            onChange={handleColumns}
          />
        </div>
        <div>
          <i
            className="fa fa-close"
            onClick={() => setSelectedCategory(null)}
            style={{
              fontSize: '1.2rem',
              color: '#616161',
              marginRight: '2rem',
              cursor: 'pointer',
            }}
          />
        </div>
      </div>
      <div className="report pl-0">
        <div className="report__header mb-4">
          <h3 className="report__title">
            Performance by <span>{category_name}</span>
          </h3>
        </div>
        <Table
          category_name={category_name}
          categoryData={category_data}
          categoryLabels={labels}
          isCompare={false}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default PfrTable;
