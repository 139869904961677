import { nanoid } from 'nanoid';
import { Condition } from './types';
import { customNanoId } from '../../../utils/nanoid';

export const ADD_CONDITION = 'ADD_CONDITION';
export const REMOVE_CONDITION = 'REMOVE_CONDITION';
export const UPDATE_CONDITION = 'UPDATE_CONDITION';

const EMPTY_CONDITION: Condition = {
  _id: nanoid(),
  metric: null,
  comparison: null,
  value: null,
};

const createNewCondition = () => {
  const nanoid = customNanoId();

  return {
    _id: nanoid(),
    metric: null,
    comparison: null,
    value: null,
  };
};

export const successConditionsReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_CONDITION:
      return [...state, createNewCondition()];

    case REMOVE_CONDITION:
      return state.filter((cond) => cond._id !== action._id);

    case UPDATE_CONDITION:
      return state.map((cond) => {
        console.log({ state, action, cond })
        if (cond._id === action._id) {
          const { update } = action;

          return {
            ...cond,
            [update.field]: update.value,
          };
        }

        return cond;
      });
  }
};
