import { useSelector } from 'react-redux';
import { useFetch } from '../../../components/Hooks/fetch';
import { usePostRequest } from '../../../components/Hooks/usePostRequest';
import { useViewSettings } from '../../../selectors';

export const useTagsApi = () => {
  const { request, error, loading, data } = usePostRequest(
    ['api', 'creative-analytics', 'save-manual-tags'].join('/')
  );

  return {
    request,
    error,
    loading,
    data,
  };
};
