export const ADSETS = 'Adsets';
export const ADSETS_FETCH = `[${ADSETS}] FETCH`;
export const ADSETS_SET = `[${ADSETS}] SET`;
export const ADSETS_RESET = `[${ADSETS}] RESET`;

export const fetchAdsets = () => ({
  type: ADSETS_FETCH,
});

export const setAdsets = ({ adsets }) => ({
  type: ADSETS_SET,
  data: adsets,
  meta: { normalizeId: 'adset_name', entity: ADSETS },
});

export const resetAdsets = () => ({
  type: ADSETS_RESET,
  meta: { entity: ADSETS },
});
