import { createStore, applyMiddleware } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { listenerMiddleware } from './listenerMiddleware.ts';

import normalizer from './middlewares/normalizer';
import resetter from './middlewares/resetter';
import api from './Api/middleware';
import views from './ViewSettings/middleware';
import adsets from './Adsets/middleware';
import runningAdsByName from './RunningAdsByName/middleware';
import creativeReports from './CreativeReports/middleware';
import creativeTestingMiddlewares from './CreativeTesting/middleware';
import createAdMiddlewares from './CreateAd/middleware';
import notifications from './Notifications/middleware';
import { adBoostMiddleware } from './background/api.ad-boost';
import { adCreationMiddleware } from './background/api.ad-create';
import { registerUserMiddleware } from './background/api.register';
import { loginMiddleware } from './background/api.login';
import { sendResetMiddleware } from './background/api.send-reset-password';
import { resetMiddleware } from './background/api.reset-password';
import { localStorageMiddleware } from './middlewares/local-storage';
import { adApprovalMiddleware } from './background/api.ad-approvals';
import logoutMiddleware from './background/api.logout';
import { dashboardConfigMiddleware } from './background/api.dashboard';
import { backgroundMiddleware } from './background/api.notify';
import { marketplaceMiddleware } from './Marketplace/middleware';
import userActivityMiddleware from './UserActivity/middleware';

import rootReducer from './rootReducer';
import INITIAL_STATE from './initial-state';

import { hasRoles } from '../cookies';

import { rtkApi } from '../services/api';
import { analyticsApi } from './CreativeAnalytics/api';

const MIDDLEWARES = [
  listenerMiddleware.middleware,
  thunk,
  normalizer,
  resetter,
  api,
  notifications,
  backgroundMiddleware,
  localStorageMiddleware,
  ...views,
  adsets,
  creativeReports,
  runningAdsByName,
  ...creativeTestingMiddlewares,
  ...createAdMiddlewares,
  adBoostMiddleware,
  adCreationMiddleware,
  registerUserMiddleware,
  loginMiddleware,
  adApprovalMiddleware,
  logoutMiddleware,
  sendResetMiddleware,
  resetMiddleware,
  dashboardConfigMiddleware,
  marketplaceMiddleware,
  userActivityMiddleware,
  analyticsApi.middleware,
];


const enhancer = (() => {
  const appliedMiddleware = applyMiddleware(...MIDDLEWARES);

  if (
    window &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    hasRoles(['internal'])
  ) {
    return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(appliedMiddleware);
  }
  return appliedMiddleware;
})();

const store = createStore(rootReducer, INITIAL_STATE, enhancer);
// export const store = configureStore({
//   reducer: rootReducer,
//   middleware: MIDDLEWARES,
//   preloadedState: INITIAL_STATE,
//   devTools: true,
// });

// eslint-disable-next-line no-unused-expressions
process.env.NODE_ENV === 'development' && (window.STORE = store);

export type RootState = ReturnType<typeof store.getState>

export default store;
