import React from 'react';

const Visual = ({
  asset_url,
  visual_type,
  loading,
}) => {
  if (asset_url && !loading) {
    let videoDisplay = (
      <div className="video-display">
        <video
          className="element-details__img"
          key={asset_url}
          autoPlay
          loop
          muted
          plays-inline
        >
          <source src={asset_url} type="video/mp4" />
        </video>
      </div>
    );

    return (
      <a className="element-details__example" href={asset_url} target="_blank">
        {(visual_type === 'image' || !visual_type) && (
          <img className="element-details__img" src={asset_url} alt="" />
        )}
        {visual_type === 'video' && videoDisplay}
      </a>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <i
        className="fa-solid fa-image"
        style={{ color: '#ccc', fontSize: '3rem' }}
      />
    </div>
  );
};

export default Visual;
