import React, { useState } from "react";
import Button from "../../../../components/Button";

import "./Display.scss";
import { VisualsLookupItem } from "../types";
import { PHRASE_BUILDER } from "../constants";

const Display = ({
  current,
  fromCreativeStudio,
  currentFormat,
}: {
  current: VisualsLookupItem;
  fromCreativeStudio: boolean;
  currentFormat: string;
}) => {
  let visual_type = "";
  let asset_url = "";
  if (!fromCreativeStudio) {
    visual_type = current.visual_type;
    asset_url = current.asset_url;
  } else {
    asset_url = current.asset_url;
    visual_type = current.visual_type.includes("image")
      ? "image"
      : "video";
  }

  const [showThumbnail, setShowThumbnail] = useState(asset_url ? false : true);
  const thumbnail = current.thumbnail_url || null;

  const thumbnailBtn = (
    <Button
      className={!showThumbnail ? "darwin-button--inactive" : ""}
      onClick={() => setShowThumbnail(true)}
    >
      Thumbnail
    </Button>
  );

  const videoBtn = (
    <Button
      className={showThumbnail ? "darwin-button--inactive" : ""}
      onClick={() => setShowThumbnail(false)}
    >
      Full Video
    </Button>
  );

  let preview = <p className="text-white">There was an error.</p>;

  if (visual_type === "image") {
    preview = <img className="report-card__img" src={asset_url} alt="" />;
  } else if (visual_type === "video") {
    if (showThumbnail && !fromCreativeStudio && thumbnail) {
      preview = <img className="report-card__img" src={thumbnail} alt="" />;
    } else if (asset_url) {
      preview = (
        <>
          <video
            key={asset_url}
            autoPlay
            loop
            muted
            plays-inline="true"
            className="report-card__img"
            controls
          >
            <source src={asset_url} type="video/mp4" />
          </video>
        </>
      );
    } else {
      preview = (
        <p className="missing-asset-url">
          <i className="fa-solid fa-triangle-exclamation"></i>
          Missing Facebook page access for this asset
        </p>
      )
    }
  }

  return (
    <>
      <div className={`report-card__display card`}>
        <div className="img-background">{preview}</div>
      </div>
      {currentFormat === "Carousel" && (
        <div className="caption">This visual is part of a carousel ad.</div>
      )}
      {thumbnail && (
        <div className="display-buttons">
          {thumbnailBtn}
          {videoBtn}
        </div>
      )}
    </>
  );
};

export default Display;
