import React from 'react';

export const EmptyState = () => {
  return (
    <div className="">
      <div className="creative-performance__empty-display">
        <i className="fa fa-photo-film"></i>
        <p>Select one or more visuals below to view stats.</p>
      </div>
    </div>
  );
};
