import { useState, useEffect } from 'react';

export function useCopyToClipboard() {
  const [isCopied, setIsCopied] = useState(false);
  const [copiedText, setCopiedText] = useState('');

  function copyToClipboard(text) {
    // navigator.clipboard only exists on localhost or https (does not work in dev environment)
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(
        function () {
          console.log('Async: Copying to clipboard was successful!');
          setIsCopied(true);
        },
        function (err) {
          console.error('Async: Could not copy text: ', err);
        }
      );
    } else {
      setCopiedText(text);
      setIsCopied(true);
    }
  }

  useEffect(() => {
    let timerId;
    if (isCopied) {
      timerId = setTimeout(() => setIsCopied(false), 2000);
    }
    return () => clearTimeout(timerId);
  }, [isCopied]);

  return { isCopied, copyToClipboard, copiedText };
}
