
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
// @ts-ignore
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ExploreQueryBuilder from '../components/QueryBuilder/ExploreQueryBuilder';
import TitleModal from '../components/TitleModal';

import { VizStateType, LayoutType } from '../../cube-prop-types';

// imported from rest of app
import { setViewSettings } from '../../../../store/ViewSettings/actions';

import { setDashboardConfigs, UPSERT } from '../graphql/dash-config';

const ExplorePage = ({ history, dashboardConfig, updateDashConfig }) => {
  const { config, itemId } = useConfig(dashboardConfig);
  const [vizState, setVizState] = useState(config.vizState);
  const [title, setTitle] = useState(config.name);
  const compareDateRange = useRef(null);

  const [addingToDashboard, setAddingToDashboard] = useState(false);
  const [titleModalVisible, setTitleModalVisible] = useState(false);

  const wrappedSetVizState = (vizStateUpdate) => {
    setVizState(vizStateUpdate);

    if (vizStateUpdate.chartType === 'custom_periodComparison') {
      if (vizStateUpdate.query.timeDimensions[0].compareDateRange) {
        compareDateRange.current = vizStateUpdate.query.timeDimensions[0].compareDateRange;
      }
    } else {
      compareDateRange.current = null;
    }
  };

  const handleSave = (titleText) => {
    let redirect = true;
    setTitleModalVisible(false);

    try {
      updateDashConfig(dashboardConfig, {
        id: itemId,
        vizState:
          vizState.chartType === 'custom_periodComparison'
            ? mergeWithComparisonRanges(vizState, compareDateRange.current)
            : vizState,
        name: titleText,
      });

      setAddingToDashboard(false);
    } catch (err) {
      console.error(err.toString());
      redirect = false;
    } finally {
      setAddingToDashboard(true);
    }

    setTitle(titleText);
    // eslint-disable-next-line no-unused-expressions
    redirect
      && history.push(
        window.location.pathname.replace('create-new-report', 'dashboard'),
      );
  };

  return (
    <>
      <TitleModal
        handleSave={handleSave}
        hideTitleModal={(e) => setTitleModalVisible(false)}
        titleModalVisible={titleModalVisible}
        finalTitle={title || 'New Chart'}
      />
      <ExploreQueryBuilder
        vizState={vizState || {}}
        setVizState={wrappedSetVizState}
        AddToDashboard={(
          <button
            type="button"
            style={{ height: '38px' }}
            className="btn btn-success"
            disabled={addingToDashboard}
            onClick={() => setTitleModalVisible(true)}
          >
            {itemId ? 'Update' : 'Add to Dashboard'}
          </button>
        )}
      />
    </>
  );
};

ExplorePage.propTypes = {
  history: PropTypes.shape({ length: PropTypes.number, push: PropTypes.func }).isRequired,
  updateDashConfig: PropTypes.func.isRequired,
  dashboardConfig: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      layout: LayoutType,
      vizState: VizStateType,
    }),
  ).isRequired,
};

const stateToProps = ({ ViewSettings: { dashboardConfig } }) => ({
  dashboardConfig,
});

const dispatchToProps = (dispatch) => ({
  updateDashConfig: (dashboardConfig, { id, vizState, name }) => {
    const update = setDashboardConfigs({
      action: UPSERT,
      update: { id, vizState, name },
      existingConfigs: dashboardConfig,
    });

    dispatch(
      setViewSettings({ dashboardConfig: update, updateDashboardConfig: true }),
    );
  },
});

export default withRouter(connect(stateToProps, dispatchToProps)(ExplorePage));

function useConfig(config) {
  const params = new URLSearchParams(window.location.search);
  const itemId = params.get('itemId');

  if (!itemId) {
    return {
      itemId: null,
      config: {},
    };
  }
  const maybe = config.find(({ id }) => id === itemId);

  if (!maybe) {
    return {
      itemId: null,
      config: {},
    };
  }
  return {
    itemId,
    config: tweakConfig(maybe),
  };
}

function tweakConfig(config) {
  if (
    config.vizState
    && config.vizState.chartType === 'custom_periodComparison'
  ) {
    return {
      ...config,
      vizState: {
        ...config.vizState,
        query: {
          ...config.vizState.query,
          timeDimensions: [
            {
              ...config.vizState.query.timeDimensions[0],
              dateRange: ['This Week'],
            },
          ],
        },
      },
    };
  }
  return config;
}

function mergeWithComparisonRanges(state, compareDateRange) {
  const clone = { ...state };
  clone.query.timeDimensions[0].compareDateRange = compareDateRange;
  clone.query.timeDimensions[0].granularity = 'day';

  return clone;
}
