import { RuleLabelObject } from '../../CategoryBuilder/Custom/DerivedLabels/types';
import { nodeStyle } from '../constants';

export function createRuleNodesAndEdges(
  nodes: [],
  edges: [],
  parent: string,
  ruleType: 'include' | 'exclude',
  rules: RuleLabelObject[]
) {
  const ruleNodes: [] = [];
  const ruleEdges: [] = [];

  rules.forEach((rule, index) => {
    const ruleNodeId = `${parent}_${ruleType}_rule_${index}`;
    ruleNodes.push({
      id: ruleNodeId,
      data: { label: rule.name },
      position: { x: 0, y: 0 },
      type: 'input',
      style: {
        ...nodeStyle,
        backgroundColor: ruleType === 'include' ? '#2CA3BD' : '#A845D0',
      },
    });
    ruleEdges.push({
      id: `edge_${ruleNodeId}_to_${parent}`,
      type: 'smoothstep',
      source: ruleNodeId,
      target: parent,
      animated: true,
      markerEnd: {
        type: 'arrow',
        width: 15,
        height: 15,
      },
      label: ruleType === 'include' ? 'Yes' : 'No',
    });
  });

  const parentNode = nodes.find((node) => node.id === parent);

  if (parentNode) {
    const parentNodeX = parentNode.position.x;
    const parentNodeY = parentNode.position.y;
    const nodeSpacing = 250;

    let yOffset = 0;

    ruleNodes.forEach((ruleNode, i) => {
      const ruleNodeX =
        parentNodeX + (ruleType === 'include' ? -nodeSpacing : nodeSpacing);
      const ruleNodeY = parentNodeY + yOffset;
      ruleNode.position = { x: ruleNodeX, y: i * 80 + ruleNodeY - 100 };
      nodes.push(ruleNode);

      const edge = ruleEdges.find((e) => e.source === ruleNode.id);
      if (edge) {
        edge.target = parentNode.id;
        edges.push(edge);
      }

      yOffset += 50;
    });
  }
}
