import React, { useState, useEffect, useMemo, useRef } from 'react';

import {
  TESTING,
  AD_STUDY,
  ADSET_NAME,
  PERFORMANCE_FREQUENCY,
  LABEL_SETTINGS,
  AD_NAME,
  RECOMMENDATIONS,
  AD_LABELS,
} from '../../store/CreativeReports/constants';
import { setReports } from '../../store/CreativeReports/actions';
import WhatToTest from '../../components/WhatToTest';
import History from '../../components/AnimatedHistory';
import StudyFlow from '../../components/StudyFlow';

import { HISTORY, STUDY_FLOW } from './constants';
import Layout from './Layout';
import { ChartPicker } from './Charts';
import { useFilteredData, useFetchByAdNames } from './Container';
import { useChartSettings, useCreativeInsights } from './report-hooks';
import {
  useReportsApi,
  useResetReportsApi,
  useAdsetsApi,
  useSetReports,
} from './withApi';
import { fetchReports } from '../../store/CreativeReports/actions';
import ReportStatus from './ReportLoading';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from '../../components/Hooks/search-params';
import { END, toDarwinDate } from '../../utils/darwin-dates';
import { setViewSettings } from '../../store/ViewSettings/actions';
import { trackEvent } from '../../store/UserActivity/actions';
import {
  AD_LABEL_REPORT,
  ADSET_REPORT,
  LOADING,
  AD_NAME_REPORT,
} from '../../store/UserActivity/constants';
import { useCreativeAnalyticsGet } from '../CreativeAnalytics/contexts';

const DEFAULT_SELECTION = {
  display: [],
  value: '',
  percent_difference: '0%',
  ad_names: [],
  ad_ids: [],
};

function ApiContainer({ reportType, isPF }) {
  const reports = useReportsApi(reportType);
  const resetReports = useResetReportsApi();
  const adsets = useAdsetsApi();

  return <ReportsSwitcher {...{ reports, resetReports, adsets, isPF }} />;
}

export default ApiContainer;

function ReportsSwitcher({ reports, resetReports, adsets, isPF }) {
  const dispatch = useDispatch();
  const { getParams, setParams } = useSearchParams();
  const {
    start,
    end,
    display: pfrDisplay,
  } = getParams('start', 'end', 'display');
  const {
    showPreviews,
    insights_view,
    isDirectMail,
    start_date,
    end_date,
    selectedAudience,
  } = useSelector(
    ({
      CreativeReports: { showPreviews, insights_view },
      ViewSettings: { isDirectMail, start_date, end_date, selectedAudience },
    }) => {
      return {
        showPreviews: showPreviews || false,
        insights_view,
        isDirectMail,
        start_date,
        end_date,
        selectedAudience,
      };
    }
  );
  const { data, report_type, spendThreshold, visualType, study_type } =
    useFilteredData(pfrDisplay);

  let reportType = pfrDisplay;

  const { options, chart, setChart } = useChartSettings({
    report: report_type,
  });

  useEffect(() => {
    if (reportType !== AD_LABELS) {
      dispatch(setReports({ show_request: false }));
    }
    if (reportType === TESTING) {
      return;
    }
    if (chart.value === HISTORY) {
      return;
    }
    if (reportType === AD_STUDY && chart.value === STUDY_FLOW) {
      return;
    }
    if (reportType === ADSET_NAME) {
      dispatch(trackEvent(LOADING, ADSET_REPORT));
    }
    if (reportType === AD_NAME) {
      dispatch(trackEvent(LOADING, AD_NAME_REPORT));
    }

    reports.dispatch();

    adsets.dispatch();
  }, [reportType, chart.value, start_date, end_date, selectedAudience]);

  useEffect(() => {
    if (chart.value) {
      dispatch(setReports({ chart_type: chart.value }));
    }
  }, [chart.value]);

  useEffect(() => {
    dispatch(setReports({ reportType }));

    if (isDirectMail && !start && !end) {
      const new_start = new Date('January 1, 2010');
      const new_end = END;

      setParams({
        start: toDarwinDate(new_start),
        end: toDarwinDate(new_end),
      });

      dispatch(
        setViewSettings({
          start_date: new_start,
          end_date: new_end,
        })
      );
    }
  }, [reportType]);

  const [selection, handleSelection] = useReportSelection({
    data,
    report_type,
    spendThreshold,
    visualType,
    study_type,
    chartType: chart.value,
    showPreviews,
  });

  const selectChart = (i) => {
    if (chart.value === LABEL_SETTINGS) resetReports.dispatch();
    setChart(i);
  };

  const Picker = (
    <ChartPicker
      options={options}
      handleSelect={(index) => selectChart(options[index])}
      label={chart.label}
    />
  );

  const { allVisuals } = useCreativeAnalyticsGet() || {};

  const { value, display, percent_difference, ad_ids } = selection || {};

  let asset = '';

  if (selection && report_type === AD_NAME) {
    asset = allVisuals.find((visual) =>
      visual.ad_names.includes(selection.ad_name)
    );
  }

  if (selection)
    switch (report_type) {
      case TESTING:
        return <WhatToTest />;

      default:
        switch (chart.value) {
          case HISTORY:
            return <History reportType={report_type} ChartPicker={Picker} />;

          case STUDY_FLOW:
            return <StudyFlow ChartPicker={Picker} />;

          default:
            const adjustedDisplay =
              report_type === ADSET_NAME
                ? [...display, { performance: 'demographic' }]
                : report_type === AD_STUDY
                ? [...display, { performance: 'placement' }]
                : display;

            const { status, message } = ReportStatus.get({
              report_type,
              reportsApi: reports,
              adsetsApi: adsets,
            });

            if (status === 'ERROR') {
              return <ReportStatus.Error error={message} />;
            }
            if (status === 'LOADING') {
              return <ReportStatus.Loading />;
            }
            return (
              <Layout
                handleSelect={handleSelection}
                selectedId={value}
                selected={adjustedDisplay}
                percent_difference={percent_difference}
                data={data}
                ad_ids={ad_ids}
                ChartPicker={Picker}
                chartType={chart.value}
                showPreviews={showPreviews}
                isDirectMail={isDirectMail}
                isPF={isPF}
                asset={asset}
              />
            );
        }
    }
}

/**
 * @return {[Object, ({ category: String}) => void]}
 */
function useReportSelection({
  data,
  spendThreshold,
  visualType,
  report_type,
  study_type,
  chartType,
  showPreviews,
}) {
  const dispatch = useDispatch();
  const fetchByAdNames = useFetchByAdNames();
  const index = useMemo(
    () => new Map(data.map((item) => [item.value, item])),
    [data, report_type, spendThreshold, visualType]
  );

  const [_id, setId] = useState(data[0] ? data[0].value : '');
  const mounted = useRef(false);

  /**
   * @param {{ category: String}=} seriesObject
   */
  const handleSeriesClick = (seriesObject) => {
    if (!seriesObject) {
      setId('');
    } else {
      const currId = seriesObject.category;

      setId(currId);
    }
  };

  useEffect(() => {
    if (mounted.current) {
      setId(data[0] ? data[0].value : '');
    } else {
      mounted.current = true;
    }
  }, [report_type, data.length]);

  useEffect(() => {
    if (mounted.current) {
      if (chartType === HISTORY || chartType === STUDY_FLOW) {
        setId('');
      } else {
        setId((prevId) => (index.has(prevId) ? prevId : ''));
      }
    }
  }, [spendThreshold, visualType, study_type, chartType]);

  const selection = useMemo(() => {
    return index.get(_id) || DEFAULT_SELECTION;
  }, [_id, index]);

  useEffect(() => {
    dispatch(
      setReports({
        showPreviews: false,
      })
    );
  }, [_id]);

  useEffect(() => {
    if (showPreviews === true) {
      let active = true;

      const _fetch = () => {
        active && fetchByAdNames(selection.ad_names);
      };

      // _fetch();

      return () => (active = false);
    }
  }, [showPreviews]);

  return [selection, handleSeriesClick];
}
