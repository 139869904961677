import React, { useEffect, useState } from 'react';
import Overlay from '../../../components/Overlay';
import { useDispatch } from 'react-redux';
import { fetchReports } from '../../../store/CreativeReports/actions';

import { usePrimaryPhraseApi } from './api';
import Button from '../../../components/Button';

import './PrimaryPhraseOptions.scss';
import { AD_LABELS } from '../../../store/CreativeReports/constants';
import { useCreativeAnalyticsGet } from '../contexts';

const getHashes = (label) => {
  let hashes = [];

  label.losers.forEach((visual) => {
    hashes.push(visual.id);
  });

  label.winners.forEach((visual) => {
    hashes.push(visual.id);
  });

  return hashes;
};

const PrimaryPhraseOptions = ({
  show,
  hide,
  label,
}) => {
  const { account_id } = useCreativeAnalyticsGet();

  const showHidePhrase = show === 'hide';
  const showRenamePhrase = show === 'rename';
  const showMergePhrase = show === 'merge';

  const primaryPhraseApi = usePrimaryPhraseApi();
  const dispatch = useDispatch();

  useEffect(() => {
    if (primaryPhraseApi.rename.data) {
      hide();
      dispatch(fetchReports(AD_LABELS, true));
    }
  }, [primaryPhraseApi.rename.data]);

  useEffect(() => {
    if (primaryPhraseApi.merge.data) {
      hide();
      dispatch(fetchReports(AD_LABELS, true));
    }
  }, [primaryPhraseApi.merge.data]);

  useEffect(() => {
    if (primaryPhraseApi.hide.data) {
      hide();
      dispatch(fetchReports(AD_LABELS, true));
    }
  }, [primaryPhraseApi.hide.data]);

  const saveHide = () => {
    primaryPhraseApi.hide.request({
      value: label.value,
      account_id,
    });
  };

  const saveRename = (newPhrase, applyAll) => {
    if (newPhrase && newPhrase.length) {
      primaryPhraseApi.rename.request({
        old_phrase_value: label.value,
        new_phrase_name: newPhrase,
        account_id,
        apply_all: applyAll,
        visual_hashes: getHashes(label),
      });
    }
  };

  const saveMerge = (newPhrase) => {
    if (newPhrase && newPhrase.length) {
      primaryPhraseApi.merge.request({
        selected_phrase_value: label.value,
        merge_with_phrase_value: newPhrase,
        account_id,
      });
    }
  };

  const handleCancel = () => {
    hide();
  };

  return (
    <>
      {showHidePhrase && (
        <HidePhrase
          name={label.name}
          saveHide={saveHide}
          handleCancel={handleCancel}
          loading={primaryPhraseApi.hide.loading}
          error={primaryPhraseApi.hide.error}
        />
      )}
      {showRenamePhrase && (
        <RenamePhrase
          name={label.name}
          saveRename={saveRename}
          handleCancel={handleCancel}
          loading={primaryPhraseApi.rename.loading}
          error={primaryPhraseApi.rename.error}
        />
      )}
      {showMergePhrase && (
        <MergePhrase
          name={label.name}
          handleCancel={handleCancel}
          saveMerge={saveMerge}
          loading={primaryPhraseApi.merge.loading}
          error={primaryPhraseApi.merge.error}
        />
      )}
    </>
  );
};

export default PrimaryPhraseOptions;

const HidePhrase = ({ name, saveHide, handleCancel, loading, error }) => {
  return (
    <Overlay show>
      <div className="phrase-modal">
        <div className="dw-modal__section">
          <h3 className="dw-modal__heading">Hide this phrase?</h3>
          <h4 className="dw-modal__subheading mt-3">{name}</h4>
        </div>
        <div className="dw-modal__options">
          <Button
            disabled={loading}
            appearance="navy"
            className="mr-3"
            onClick={saveHide}
          >
            Submit
          </Button>
          <Button appearance="subtle" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
        <div className="dw-modal__notification">
          {loading && <p className="dw-modal__message">Saving...</p>}
          {error && (
            <p className="dw-modal__message">
              There was an error. {error.error}
            </p>
          )}
        </div>
      </div>
    </Overlay>
  );
};

const RenamePhrase = ({ name, saveRename, handleCancel, loading, error }) => {
  const [newPhrase, setNewPhrase] = useState(null);
  const [applyAll, setApplyAll] = useState(true);

  const handleSubmit = () => {
    saveRename(newPhrase, applyAll);
  };

  return (
    <Overlay show>
      <div className="phrase-modal">
        <div className="dw-modal__section">
          <div className="dw-modal__block">
            <h3 className="dw-modal__heading">Original Phrase:</h3>
            <p className="dw-modal__subheading">{name}</p>
          </div>
          <div className="dw-modal__block">
            <label className="dw-modal__heading" htmlFor="">
              New Phrase:
            </label>
            <textarea
              rows="3"
              className="dw-modal__input"
              type="text"
              onChange={(e) => setNewPhrase(e.target.value)}
              value={newPhrase}
            />
          </div>
        </div>
        <div className="dw-modal__options">
          <Button
            disabled={!newPhrase || loading}
            appearance="navy"
            className="mr-3"
            onClick={handleSubmit}
          >
            Submit
          </Button>
          <Button appearance="subtle" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
        <div className="dw-modal__notification">
          {loading && <p className="dw-modal__message">Saving...</p>}
          {error && (
            <p className="dw-modal__message">
              There was an error. {error.error}
            </p>
          )}
        </div>
      </div>
    </Overlay>
  );
};

const MergePhrase = ({
  name,
  handleCancel,
  saveMerge,
  loading,
  error,
}) => {
  const [newPhrase, setNewPhrase] = useState(null);
  const { primaryPhraseLabels } = useCreativeAnalyticsGet();
  const options = primaryPhraseLabels.filter((label) => {
    return label.name !== name;
  });

  const handleSelect = (selected) => {
    setNewPhrase(selected);
  };

  const handleSubmit = () => {
    saveMerge(newPhrase);
  };

  return (
    <Overlay show>
      <div className="phrase-modal">
        <div className="dw-modal__section">
          <div className="dw-modal__block">
            <h3 className="dw-modal__heading">Original Phrase</h3>
            <p className="dw-modal__subheading">{name}</p>
          </div>
          <label className="dw-modal__heading" htmlFor="">
            Phrase to merge with:
          </label>
          <select
            className="dw-modal__select"
            name=""
            id=""
            onChange={(e) => handleSelect(e.target.value)}
          >
            {options.map((label) => (
              <option className="dw-modal__select-option" value={label.value}>
                {label.name.slice(0, 100)}
              </option>
            ))}
          </select>
          {newPhrase && (
            <p className="dw-modal__subheading mt-3">{newPhrase}</p>
          )}
        </div>
        <div className="dw-modal__options">
          <Button
            disabled={!newPhrase || loading}
            appearance="navy"
            className="mr-3"
            onClick={handleSubmit}
          >
            Submit
          </Button>
          <Button appearance="subtle" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
        <div className="dw-modal__notification">
          {loading && <p className="dw-modal__message">Saving...</p>}
          {error && (
            <p className="dw-modal__message">
              There was an error. {error.error}
            </p>
          )}
        </div>
      </div>
    </Overlay>
  );
};
