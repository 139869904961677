import { batch } from 'react-redux';

import { API_SUCCESS, apiRequest, API_FAILURE } from '../Api/actions';
import { setLoader } from '../Loaders/actions';
import { setViewSettings, fetchAudiences } from '../ViewSettings/actions';
import { addNotification } from '../Notifications/actions';

export const LOGIN = 'Login';

export const LOGIN_FETCH = `[${LOGIN}] FETCH`;

function shouldFetchFromStorage({ meta }) {
  return !!(meta && meta.fromLocalStorage && meta.entity === 'Login');
}

function shouldFetchFromApi({ data }) {
  return !!(data && data.email && data.password);
}

function setGa(user_id = null) {
  if (user_id) {
    window.gtag('set', { user_id });
    window.USER_ID = user_id;
  }
}

const ADMIN_ROLES = new Set(['internal', 'admin_user']);

function isAdmin(roles) {
  return roles.some((role) => ADMIN_ROLES.has(role));
}

function getViewNameAndIdAndLever(viewId, views) {
  const theDefault = {
    view_name: '',
    view_id: '',
    runs_lever: false,
    snapchat_account_id: '',
  };

  if (viewId) {
    const maybe = views.find(({ view_id }) => view_id === viewId);

    return maybe || theDefault;
  }

  return views.length > 0
    ? views.find(({ is_primary }) => is_primary)
    : theDefault;
}

export const fetchLogin = ({ email, password }) => {
  return {
    type: LOGIN_FETCH,
    data: {
      email,
      password,
    },
    meta: {},
  };
};

function dispatchFetchAction(dispatch, action) {
  const tokenInStorage = shouldFetchFromStorage(action);
  const hasEmailAndPass = shouldFetchFromApi(action);

  if (!(tokenInStorage || hasEmailAndPass)) {
    dispatch({
      type: `[${LOGIN}] INVALID USER - REDIRECTING TO /login`,
    });
  } else {
    const payload = tokenInStorage
      ? { token: action.meta.fromLocalStorage, auth_field: 'token' }
      : { ...action.data, auth_field: 'email' };
    const { viewId } = action.meta;

    batch(() => {
      dispatch(setLoader(LOGIN, true));

      if (viewId) {
        dispatch(setViewSettings({ viewId }));
      }

      dispatch(
        apiRequest({
          body: payload,
          url: ['api', 'login'].join('/'),
          entity: LOGIN,
          method: 'POST',
        })
      );
    });
  }
}

function dispatchApiSuccess(
  dispatch,
  {
    response: {
      views,
      token,
      user_id,
      roles,
      user_first_name,
      darwin_client_id,
      account_platforms,
      darwin_client_id_list,
      account_id_list,
      pull_live_metrics,
      label_queue,
    },
  },
  viewId = ''
) {
  const { view_name, view_id, runs_lever, snapchat_account_id } =
    getViewNameAndIdAndLever(viewId, views);

  setGa(user_id);

  console.log({
    roles,
    viewId: view_id,
    viewName: view_name,
    views,
    token,
    loggedIn: true,
    allowedAdmin: isAdmin(roles),
    isLeverView: runs_lever,
    snapchatAccountId:
      snapchat_account_id === undefined ? '' : snapchat_account_id,
    user_first_name,
    user_id,
    darwin_client_id,
    account_platforms,
    darwin_client_id_list,
    account_id_list,
    pull_live_metrics,
    label_queue,
  });
  batch(() => {
    dispatch(
      setViewSettings({
        roles,
        viewId: view_id,
        viewName: view_name,
        views,
        token,
        loggedIn: true,
        allowedAdmin: isAdmin(roles),
        isLeverView: runs_lever,
        snapchatAccountId:
          snapchat_account_id === undefined ? '' : snapchat_account_id,
        user_first_name,
        user_id,
        darwin_client_id,
        account_platforms,
        darwin_client_id_list,
        account_id_list,
        pull_live_metrics,
        label_queue,
      })
    );

    dispatch(fetchAudiences({ viewId: view_id }));

    dispatch({
      type: '[Token] SET',
      meta: {
        setLocalStorage: { key: 'token', value: token },
        entity: 'Token',
      },
    });

    dispatch(setLoader(LOGIN, false));
  });
}

export const loginMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case LOGIN_FETCH:
        dispatchFetchAction(dispatch, action);

        break;

      case `[${LOGIN}] ${API_SUCCESS}`: {
        const {
          ViewSettings: { viewId },
        } = getState();

        dispatchApiSuccess(dispatch, action, viewId);

        break;
      }

      case `[${LOGIN}] ${API_FAILURE}`: {
        const { error } = action;

        if (error.error === 'Password did not match.') {
          batch(() => {
            dispatch(
              addNotification(
                {
                  id: new Date().getMilliseconds(),
                  status: 'danger',
                  message: 'Password did not match. Please try again.',
                  title: 'Log in',
                },
                LOGIN
              )
            );
            dispatch(setLoader(LOGIN, false));
          });
        } else {
          // Clear the invalid token.
          batch(() => {
            dispatch({
              type: '[Token] SET',
              meta: {
                setLocalStorage: { key: 'token' },
                entity: 'Token',
              },
            });
            dispatch(setLoader(LOGIN, false));
          });
        }

        break;
      }

      default:
        return result;
    }

    return result;
  };
