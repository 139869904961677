const sortCategories = (a, b) => {
  const categoryA = a.category.toLowerCase();
  const categoryB = b.category.toLowerCase();
  return categoryA.localeCompare(categoryB);
};

export const findVisualCategories = (visual_hash, categoriesLookup) => {
  let result = [];

  Object.entries(categoriesLookup).forEach(([key, category]) => {
    if (category.visuals.includes(visual_hash)) {
      result.push({ ...category, category: key });
    }
  });

  return result.sort(sortCategories);
};

export const updateVisualsLookup = (visualsData, categoriesLookup, labels) => {
  const { ids, lookup } = visualsData;
  const updatedVisuals = new Map(lookup);

  lookup.forEach((visual, visualId) => {
    const labelIds = labels
      .filter((label) => label.visual_ids.includes(visualId))
      .map((label) => label.id);

    const categoryIds = Object.keys(categoriesLookup);

    const updatedVisual = {
      ...visual,
      label_ids: labelIds,
      category_ids: categoryIds,
    };

    updatedVisuals.set(visualId, updatedVisual);
  });

  return {
    ids,
    lookup: updatedVisuals,
  };
};
