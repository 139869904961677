export const deriveUdcLabels = (udcLabelsParam = '') => {
  if (!!udcLabelsParam.length) {
    const labels = udcLabelsParam.split(',');

    return labels.map((item) => {
      const category = item.split('->')[0];
      const label = item.split('->')[1];

      return {
        category,
        label,
      };
    });
  }

  return [];
};
