export const VIEW_SETTINGS = 'ViewSettings';
export const VIEW_SETTINGS_SET = `[${VIEW_SETTINGS}] SET`;
export const VIEW_SETTINGS_FETCH = `[${VIEW_SETTINGS}] FETCH`;
export const VIEW_AUDIENCE = 'ViewSettings.audience';
export const VIEW_CLIENTS = 'ViewSettings.clients';
export const VIEW_CAMPAIGNS = 'ViewSettings.campaigns';
export const VIEW_SETTINGS_FETCH_AUDIENCES = `[${VIEW_AUDIENCE}] FETCH`;
export const VIEW_SETTINGS_FETCH_CLIENTS = `[${VIEW_CLIENTS}] FETCH`;
export const VIEW_SETTINGS_FETCH_CAMPAIGNS = `[${VIEW_CAMPAIGNS}] FETCH`;

export const setViewSettings = ({
  resetStore = false,
  updateDashboardConfig = false,
  ...settings
}) => ({
  type: VIEW_SETTINGS_SET,
  settings,
  meta: { entity: VIEW_SETTINGS, resetStore, updateDashboardConfig },
});

export const fetchView = ({ viewId }) => ({
  type: VIEW_SETTINGS_FETCH,
  meta: { entity: VIEW_SETTINGS },
  data: {
    viewId,
  },
});

export const fetchAudiences = ({ viewId }) => ({
  type: VIEW_SETTINGS_FETCH_AUDIENCES,
  data: { viewId },
  meta: { entity: VIEW_SETTINGS },
});

export const fetchClients = () => ({
  type: VIEW_SETTINGS_FETCH_CLIENTS,
  meta: { entity: VIEW_SETTINGS },
});

export const fetchCampaignsByPlatform = (darwin_client_id, platform) => ({
  type: VIEW_SETTINGS_FETCH_CAMPAIGNS,
  data: { darwin_client_id, platform },
  meta: { entity: VIEW_SETTINGS },
});
