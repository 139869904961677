import { useLocation } from 'react-router-dom';

import { hasRoles } from './cookies';

export const ROUTE_ROUTES = new Set([
  'logout',
  'login',
  'signup',
  'request-reset',
  'reset-password',
  'marketplace',
  'contractors',
  'clients',
  'previews',
  'creative-requests',
  'tracker',
  'event-history',
  'variation-report',
  'hurdle-report',
  'copy-report',
  'static-report',
  'report-group',
]);

export function isRootRoute(endpoint) {
  return ROUTE_ROUTES.has(endpoint);
}

function isMobile() {
  return window.screen.width <= 768;
}

/**
 * @description Default route table
 * Role       | Desktop Route         | Mobile Route
 * -----------|-----------------------|-------------
 * internal   | /<view-id>/dashboard  | /<view-id>/creative-reports?report=ad_labels
 * client     | /<view-id>/dashboard  | /<view-id>/creative-reports?report=ad_labels
 * contractor | /marketplace          | /marketplace
 */
export function getDefaultViewFeature(viewId) {
  if (!viewId || hasRoles(['contractor'], { useAdmin: false })) {
    return '/marketplace';
  }

  return `/${viewId}/home`;
}

export function getViewFeatureUrl(id) {
  const loc = window.location;
  const [, , maybeFeature] = loc.pathname.split('/');

  switch (maybeFeature) {
    case 'creative-reports': {
      const params = new URLSearchParams(loc.search);

      if (params.has('report')) {
        return `/${id}/creative-reports${loc.search}`;
      }
      return `/${id}/creative-reports?report=ad_labels`;
    }

    default:
      if (maybeFeature) {
        return `/${id}/${maybeFeature}`;
      }
      return getDefaultViewFeature(id);
  }
}
