import { connect, useSelector } from 'react-redux';
import { previewsFetchFlow } from '../../store/AdPreviews/actions';

export const Status = {
  error: 'ERROR',
  init: 'INIT',
  loading: 'LOADING',
  ok: 'OK',
};

function setStatus(isLoading, hasError) {
  if (hasError) {
    return Status.error;
  } if (isLoading) {
    return Status.loading;
  }
  return Status.ok;
}

const deriveSources = (previews, pIndex, key) => {
  const maybeIndex = pIndex[key];

  if (!maybeIndex) {
    return { preview_list: [] };
  }
  const index = maybeIndex.previews;

  return previews[index];
};

export function useAdPreview(key) {
  return useSelector(({
    AdPreviews: {
      isLoading, previews, previewsIndex,
    },
  }) => {
    const maybe = previews[key];

    return {
      status: setStatus(isLoading && !maybe, false),
      preview: deriveSources(previews, previewsIndex, key),
    };
  });
}

const stateToProps = (
  {
    AdPreviews: {
      isLoading, hasError, previews, previewsIndex,
    },
  },
  { adName },
) => ({
  isLoading: isLoading || !previewsIndex[adName],
  hasError,
  preview: deriveSources(previews, previewsIndex, adName),
});

const dispatchToProps = (dispatch, { adName }) => ({
  fetchAdPreview: () => dispatch(previewsFetchFlow({ attribute: 'ad_name', values: [adName] })),
});

export const connectSingle = connect(stateToProps, dispatchToProps);

/// / Multi-Fetch
const stateToProps_bulk = (
  {
    AdPreviews: {
      isLoading, hasError, previews, previewsIndex,
    },
  },
  { adNames },
) => ({
  isLoading,
  hasError,
  previews: adNames.map((adName) => deriveSources(previews, previewsIndex, adName)),
});

const dispatchToProps_bulk = (dispatch, { adNames }) => ({
  fetchAdPreviews: () => dispatch(previewsFetchFlow({ attribute: 'ad_name', values: adNames })),
});

export const connectMulti = connect(stateToProps_bulk, dispatchToProps_bulk);

const genericMultiStateToProps = (
  {
    AdPreviews: {
      isLoading, hasError, previews, previewsIndex,
    },
  },
  { values },
) => ({
  status: setStatus(isLoading, hasError),
  previews: values.map((value) => deriveSources(previews, previewsIndex, value)),
});

const genericMultiDispatchToProps = (dispatch, { values, attribute }) => ({
  fetchAdPreviews: () => dispatch(previewsFetchFlow({ attribute, values })),
});

export const connectGenericMulti = connect(genericMultiStateToProps, genericMultiDispatchToProps);
