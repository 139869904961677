import React, { useMemo, useState, useEffect } from 'react';
import Button from '../../../components/Button';
import Overlay from '../../../components/Overlay';
import { useCreativeAnalyticsGet } from '../../CreativeAnalytics/contexts';

import './AddCategories.scss';
import { arrayEquals, generateId } from '../utils';
import { ALL_VISUALS, BRAND } from '../../CreativeAnalytics/categories';
import { CARD_VARIANTS_BY_CATEGORY } from '../constants';
import { formatCategory } from '../Card';
import { useCategoriesLookup } from '../../CreativeAnalytics/selectors';

export const AddCategories = ({
  show = true,
  cards,
  showAddCategoriesModal,
  setShowAddCategoriesModal,
  setConfig,
}) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const categoriesLookup = useCategoriesLookup();

  const opts = useMemo(() => {
    return Object.keys(categoriesLookup).reduce((acc, category_name) => {
      let variants =
        CARD_VARIANTS_BY_CATEGORY[category_name] ||
        CARD_VARIANTS_BY_CATEGORY['default'];

      const card_variants = variants.map((metricOpts: []) => {
        const alreadyEnabled = cards.some(
          (card) =>
            category_name === card.category &&
            arrayEquals(metricOpts, card.metric_options)
        );

        return {
          metric_options: metricOpts,
          alreadyEnabled,
        };
      });

      const categoryHasData = categoriesLookup[category_name]?.hasData;

      card_variants.forEach(({ metric_options, alreadyEnabled }) => {
        if (!alreadyEnabled && categoryHasData) {
          acc.push({ category_name, metric_options, alreadyEnabled });
        }
      });

      return acc;
    }, []);
  }, [cards, categoriesLookup]);

  const isAlreadySelected = (category) => {
    if (category.category_name === 'Audiences') {
      console.log({ category, selectedCategories });
    }
    return selectedCategories.some((sc) => {
      return (
        sc.category_name === category.category_name &&
        arrayEquals(sc.metric_options, category.metric_options)
      );
    });
  };

  const toggleCategorySelect = (category) => {
    setSelectedCategories((prev: Set<string>) => {
      console.log({ prev, category });

      if (isAlreadySelected(category)) {
        return [...prev].filter(
          (y) =>
            y.category_name !== category.category_name &&
            arrayEquals(y.metric_options, category.metric_options)
        );
      } else {
        return [...prev, category];
      }
    });
  };

  const handleSave = () => {
    const new_categories = selectedCategories.map((selectedCategory) => {
      return {
        category: selectedCategory.category_name,
        metric_options: selectedCategory.metric_options,
        id: generateId({
          category: selectedCategory.category_name,
          metric_options: selectedCategory.metric_options,
        }),
      };
    });
    setConfig((prev) => {
      return [...prev, ...new_categories];
    });

    setShowAddCategoriesModal(false);
  };

  let Render = (
    <div className="add-categories">
      <h1>Add categories</h1>
      {opts.length < 1 && (
        <p>There are no categories that currently have data.</p>
      )}
      <ul>
        {opts.map((opt) => {
          const checked = isAlreadySelected(opt);
          const optLabel = getOptLabel(opt);

          return (
            <li
              style={{ background: checked ? '#D7E7FF' : '#F3F7FD' }}
              onClick={() => toggleCategorySelect(opt)}
            >
              <input
                type="checkbox"
                checked={checked}
                onChange={toggleCategorySelect}
                name=""
                id=""
              />
              <span>
                {formatCategory(opt.category_name)}{' '}
                {optLabel && <span className="opt-label">({optLabel})</span>}
              </span>
            </li>
          );
        })}
      </ul>
      <div className="add-categories__footer">
        <Button
          appearance="subtle"
          onClick={() => setShowAddCategoriesModal(false)}
        >
          Cancel
        </Button>
        {opts.length >= 1 && (
          <Button className="ml-3" onClick={handleSave}>
            Save
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <Overlay
      show={showAddCategoriesModal}
      close={() => setShowAddCategoriesModal(false)}
    >
      {Render}
    </Overlay>
  );
};

const getOptLabel = (opt) => {
  const { category_name, metric_options } = opt;
  let result = null;

  if (category_name === BRAND) {
    if (metric_options[0] === 'spend') {
      result = '% Spend View';
    } else if (metric_options[0] === 'aggregate_cpa') {
      result = 'CPA View';
    }
  }

  if (category_name === ALL_VISUALS) {
    if (metric_options[0] === 'number_unique_creatives') {
      result = 'Amount';
    } else if (metric_options[0] === 'aggregate_cpa') {
      result = 'CPA View';
    }
  }

  return result;
};
