import React from 'react';

const CloseIcon = ({
  className = '',
  onClick,
  style,
  closeToast,
  fill = '#1E2B4E',
  height = '40',
  width = '40',
}) => {
  const handleClick = () => {
    if (closeToast) closeToast();
    if (onClick) onClick();
  };
  return (
    <svg
      className={className}
      style={style}
      onClick={handleClick}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 11.75L28.25 10L20 18.25L11.75 10L10 11.75L18.25 20L10 28.25L11.75 30L20 21.75L28.25 30L30 28.25L21.75 20L30 11.75Z"
        fill={fill}
      />
    </svg>
  );
};

export default CloseIcon;
