import React, { useState, useEffect } from 'react';
import { LabelSetupIcon } from '../assets';
import IconButton from '../../../components/IconButton';
import Tooltip from '../../../components/Tooltip';

export const useLabelSetup = ({
  account_id,
  filteredData,
  fullData,
  setFullData,
  searchTermsApi,
}) => {
  const [searchTermsData, setSearchTermsData] = useState({});
  const [editBasicLabels, setEditBasicLabels] = useState<boolean>(false);

  useEffect(() => {
    if (searchTermsApi.get.data) {
      const { data } = searchTermsApi.get;
      setSearchTermsData(data);
    }
  }, [searchTermsApi.get.data]);

  useEffect(() => {
    if (filteredData.length && !fullData) {
      setFullData([...filteredData]);

      if (
        !filteredData.some(
          (label) =>
            label.type === 'client_custom_category' ||
            label.type === 'user_defined_labels' ||
            label.type === 'custom_trained_label_settings' ||
            label.value.includes('CCC') ||
            label.value.includes('DW')
        )
      ) {
        checkSavedLabels();
      }
    }
  }, [filteredData.length]);

  const checkSavedLabels = () => {
    const {
      brand_search_terms: brands,
      promotion_search_terms: promos,
      keywords,
    } = searchTermsData;
    if (brands?.length === 0 && promos?.length === 0 && keywords?.length === 0) {
      // open widget if no CCC & no saved labels
      setEditBasicLabels(true);
    }
  };

  useEffect(() => {
    if (searchTermsApi.get.error) {
      console.log(searchTermsApi.get.error);
    }
    if (searchTermsApi.post.error) {
      console.log(searchTermsApi.post.error);
    }
  }, [searchTermsApi.get.error, searchTermsApi.post.error]);

  const searchTermsLoading = searchTermsApi.get.loading;
  const searchTermsError = searchTermsApi.get.error;

  return {
    searchTermsData,
    searchTermsLoading,
    searchTermsError,
    LabelSetupIcon: (
      <Tooltip
        content={'Label Setup Widget'}
        follow={false}
        placement={'right'}
      >
        <IconButton
          disabled={!filteredData.length}
          onClick={() => {
            if (!filteredData.length) return;
            setEditBasicLabels(true);
            searchTermsApi.get.request(account_id);
          }}
        >
          <i className='fa fa-wrench'></i>
        </IconButton>
      </Tooltip>
    ),
    setSearchTermsData,
    editBasicLabels,
    setEditBasicLabels,
  };
};
