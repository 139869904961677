import { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReports } from '../../../store/CreativeReports/actions';
import {
  AD_LABELS,
  VISUAL_HASH,
} from '../../../store/CreativeReports/constants';
import {
  toCurrency,
  toFixed_2,
  toPercentRounded,
  toPercent_2,
} from '../../../utils/numbers';
import {
  getPercentBudget,
  getSpend,
  useGetAggregate,
  useGetMedian,
  useGetPercentResults,
  useGetPercentResultsDiff,
  useGetTotalLength,
  useGetTotalResults,
} from '../utils';
export const useReportsData = () => {
  const data = useSelector(({ CreativeReports: { reports } }) => {
    // combine ad_name and ad_labels report data for perf freq report
    let data = [];

    if (Object.entries(reports).length) {
      Object.entries(reports).forEach(([key, value]) => {
        data.push(...value);
      });
    }

    return data;
  });
  return data;
};

export const usePeriod2Data = () => {
  const data = useSelector(({ CreativeReports: { reports_p2 } }) => {
    // combine ad_name and ad_labels report data for perf freq report
    let data = [];

    if (reports_p2) {
      if (Object.entries(reports_p2).length) {
        Object.entries(reports_p2).forEach(([key, value]) => {
          data.push(...value);
        });
      }
    }

    return data;
  });
  return data;
};

export default function useElementSelect(
  period1,
  period2,
  filteredData,
  allVisuals,
  isROAS,
  totalBudget,
  spendThreshold,
  isOrganicView,
  clientMetrics,
  primary_metric
) {
  const [_id, setId] = useState('');
  const [selectedElement, setSelectedElement] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(false);

  const p1VisualsData = useMemo(
    () =>
      allVisuals.reduce(
        (acc, { spend, cpa, results, impressions, clicks }) => {
          acc.spend += spend;
          acc.cpa = acc.cpa += cpa;
          acc.results = acc.results += results;
          acc.impressions = acc.impressions += impressions;
          acc.clicks = acc.clicks += clicks;

          return acc;
        },
        { spend: 0, cpa: 0, results: 0, impressions: 0, clicks: 0 }
      ),
    [filteredData]
  );

  const selected = useMemo(() => {
    const selectedVisual = allVisuals.find((obj) => obj.id === _id);
    if (selectedVisual) {
      const otherVisuals = allVisuals.filter((v) => v.id !== selectedVisual.id);
      const others_spend = p1VisualsData.spend - selectedVisual.spend;
      const others_results = p1VisualsData.results - selectedVisual.results;
      const others_cpr = others_spend / others_results;
      const difference = others_cpr - selectedVisual.cpa;

      const othersTotalResults = useGetTotalResults(
        otherVisuals,
        'results',
        isROAS
      );

      const othersTotalClicks = useGetTotalResults(
        otherVisuals,
        'clicks',
        false,
        spendThreshold
      );

      const othersTotalNumberVisuals = useGetTotalLength(
        otherVisuals,
        spendThreshold
      );

      let others = {
        cpa: toCurrency(useGetAggregate(otherVisuals, 'cpa')),
        median_cpa: toCurrency(useGetMedian(otherVisuals, 'cpa')),
        roas: toFixed_2(useGetAggregate(otherVisuals, 'roas')),
        median_roas: toFixed_2(useGetMedian(otherVisuals, 'roas')),
        cpm: toCurrency(useGetAggregate(otherVisuals, 'cpm')),
        median_cpm: toCurrency(useGetMedian(otherVisuals, 'cpm')),
        cpc: toCurrency(useGetAggregate(otherVisuals, 'cpc')),
        median_cpc: toCurrency(useGetMedian(otherVisuals, 'cpc')),
        ctr: toPercent_2(useGetAggregate(otherVisuals, 'ctr')),
        median_ctr: toPercent_2(useGetMedian(otherVisuals, 'ctr')),
        total_results: othersTotalResults,
        percent_of_results: toPercent_2(
          useGetPercentResults(
            otherVisuals,
            'results',
            othersTotalResults,
            isROAS
          )
        ),
        percent_of_results_diff: toPercent_2(
          useGetPercentResultsDiff(
            otherVisuals,
            'results',
            othersTotalResults,
            totalBudget,
            isROAS
          )
        ),
        total_link_clicks: useGetTotalResults(otherVisuals, 'clicks'),
        percent_of_link_clicks: toPercent_2(
          useGetPercentResults(otherVisuals, 'clicks', othersTotalClicks)
        ),
        number_unique_creatives: otherVisuals.length,
        percent_of_creative_count: toPercentRounded(
          otherVisuals.length / othersTotalNumberVisuals
        ),
        percent_budget: toPercent_2(
          getPercentBudget(otherVisuals, totalBudget)
        ),
        spend: toCurrency(getSpend(otherVisuals)),
        percent_of_link_clicks_diff: toPercent_2(
          useGetPercentResultsDiff(
            otherVisuals,
            'clicks',
            othersTotalClicks,
            totalBudget
          )
        ),
      };

      if (isOrganicView && clientMetrics.length) {
        const othersClientMetrics = clientMetrics.reduce((acc, curr) => {
          const metricTotal = otherVisuals
            .map((i) => i[curr])
            .reduce((a, b) => a + b);
          const average =
            otherVisuals.length > 0 ? metricTotal / otherVisuals.length : null;

          return (acc[curr] = toFixed_2(average)), acc;
        }, {});

        others = { ...others, ...othersClientMetrics };
      }

      const percentDifference =
        (selectedVisual[primary_metric] - parseFloat(others[primary_metric])) /
        ((selectedVisual[primary_metric] + parseFloat(others[primary_metric])) /
          2);

      return {
        ...selectedVisual,
        display: [
          {
            performance: 'cpa',
            data: [
              {
                type: selectedVisual.id,
                spend: toCurrency(selectedVisual.spend),
                cpa: toCurrency(selectedVisual.cpa),
              },
              {
                type: 'Others',
                ...others,
              },
            ],
          },
        ],
        percent_difference: isOrganicView
          ? toPercent_2(percentDifference)
          : toPercent_2(difference / others_cpr),
        url: selectedVisual.asset_url,
      };
    }
  }, [_id, selectedPeriod]);

  const dispatch = useDispatch();

  const fetchData = (type, period) => {
    dispatch(fetchReports(type, false, period));
  };

  const handleSelectElement = useCallback(
    (id, type, period) => {
      const periodType = period ? 'two' : 'one';
      const dateRange = period ? period2 : period1;

      if (!dateRange.includes(',')) {
        console.error('Invalid dateRange format:', dateRange);
        return;
      }

      const [start_date, end_date] = dateRange.split(',');

      setId(id);
      if (id !== _id) setSelectedElement(null);
      setSelectedType(type);
      setSelectedPeriod(period);

      switch (type) {
        case 'ad_label':
          fetchData(AD_LABELS, periodType, start_date, end_date);
          break;
        case 'visual_hash':
          fetchData(VISUAL_HASH, periodType, start_date, end_date);
          break;
        default:
          console.warn('Unhandled type:', type);
          break;
      }
    },
    [fetchData, _id, period1, period2]
  );

  useEffect(() => {
    if (_id.length) {
      setSelectedElement(selected);
    }
  }, [_id, selectedPeriod]);

  return [
    { element: selectedElement, type: selectedType, period: selectedPeriod },
    handleSelectElement,
  ];
}
