import React from 'react';
import { useCreativeAnalyticsStore } from '../../../selectors';
import { Layout } from './Layout';

export const Wrapper = () => {
  const { missingData } = useCreativeAnalyticsStore();

  return (
    <Layout missingData={missingData} />
  )
}