import React, { createContext, useContext } from 'react';

const Context = createContext(null);

export function CreativePerformanceProvider({
  trendData,
  children,
}) {
  return (
    <Context.Provider
      value={{
        trendData,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useCreativePerformanceState() {
  return useContext(Context);
}