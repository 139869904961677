import { useSelector, useDispatch } from 'react-redux';

import { fetchReports, resetReports, REPORTS } from '../../store/CreativeReports/actions';
import { fetchAdsets, ADSETS } from '../../store/Adsets/actions';
import { fetchRunningAdsByName } from '../../store/RunningAdsByName/actions';

export function useReportsApi(reportType) {
  const dispatch = useDispatch();
  const { loading, error } = useSelector(({ Errors, Loaders }) => ({
    loading: Loaders[REPORTS],
    error: Errors[REPORTS],
  }));

  return { loading, error, dispatch: () => dispatch(fetchReports(reportType)) };
}

export function useResetReportsApi() {
  const dispatch = useDispatch();
  const { loading, error } = useSelector(({ Errors, Loaders }) => ({
    loading: Loaders[REPORTS],
    error: Errors[REPORTS],
  }));

  return { loading, error, dispatch: () => dispatch(resetReports()) };
}

export function useAdsetsApi() {
  const dispatch = useDispatch();
  const { loading, error } = useSelector(({ Errors, Loaders }) => ({
    loading: Loaders[ADSETS],
    error: Errors[ADSETS],
  }));

  return {
    loading,
    error,
    dispatch: () => dispatch(fetchAdsets()),
  };
}

export function useAdPreviewsApi() {
  const dispatch = useDispatch();

  return {
    dispatch: (adNames) => dispatch(fetchRunningAdsByName(adNames.map(({ name }) => name))),
  };
}
