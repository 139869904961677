import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { Tag, TagsList } from '../../../../components/Tags';
import Tooltip from '../../../../components/Tooltip';
import { useHover } from '../../../../hooks/useHover';
import {
  ADD_LABEL,
  SELECT_LABEL,
  DESELECT_LABEL,
  REMOVE_LABEL,
  SELECT_AD_NAME,
  DESELECT_AD_NAME,
  REMOVE_AD_NAME,
} from '../reducer';
import { labelsAreMatching } from '../utils';

import './LibraryLabels.scss';

const CloseIcon = ({ onClick, setCloseIsHovered }) => {
  return (
    <div
      onClick={onClick}
      onMouseEnter={() => setCloseIsHovered(true)}
      onMouseLeave={() => setCloseIsHovered(false)}
      style={{
        background: '#EBBCA8',
        borderRadius: '50px',
        position: 'absolute',
        top: '-7px',
        right: '-7px',
        width: '15px',
        height: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <i
        className="fa fa-xmark"
        style={{
          color: '#B45A46',
          fontSize: '1rem',
          transform: 'translateY(1px)',
        }}
      ></i>
    </div>
  );
};

const LabelTag = ({ label, library, dispatchLibrary, selectedSearchTerms }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [closeIsHovered, setCloseIsHovered] = useState(false);
  const active = selectedSearchTerms.some((sl) =>
    labelsAreMatching(label, sl)
  );

  const handleSelectLabel = (e) => {
    // e.preventDefault();
    if (active) {
      if (!!label.category) {
        dispatchLibrary({ type: DESELECT_LABEL, label })
      } else {
        dispatchLibrary({ type: DESELECT_AD_NAME, label })
      }
    } else {
      if (!!label.category) {
        dispatchLibrary({ type: SELECT_LABEL, label })
      } else {
        dispatchLibrary({ type: SELECT_AD_NAME, label })
      }
    }
  };

  const handleDelete = (e) => {
    console.log('delete');
    e.stopPropagation();

    if (!!label.category) {
      dispatchLibrary({ type: REMOVE_LABEL, label });
    }
    dispatchLibrary({ type: REMOVE_AD_NAME, label });
  };

  return (
    <Tooltip disabled={closeIsHovered} content={label.category ? label.category : "Ad name"}>
      <li className="mt-1">
        <Tag
          className={`library-labels__tag ${
            active ? 'library-labels__tag--active' : ''
          }`}
          active={active}
          onClick={handleSelectLabel}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {isHovered && <CloseIcon onClick={handleDelete} setCloseIsHovered={setCloseIsHovered} />}
          {label.name}
        </Tag>
      </li>
    </Tooltip>
  );
};

const LibraryLabels = ({ library, dispatchLibrary }) => {
  const allSearchTerms = [...library.labels, ...library.adNames];
  const selectedSearchTerms = [...library.selectedLabels, ...library.selectedAdNames];

  return (
    <div className="library-labels">
      <TagsList>
        {allSearchTerms.map((label) => (
          <LabelTag {...{ label, library, dispatchLibrary, selectedSearchTerms }} />
        ))}
      </TagsList>
    </div>
  );
};

export default LibraryLabels;
