import React from 'react';
import Tooltip from '../../../../../components/Tooltip';

export const SummaryTable = ({
  tableInstance,
  onRowSelect = () => {},
  selectedRowId = null,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <div className="lp-table">
      <table {...getTableProps()} className="border-collapse">
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="border text-left font-semibold"
                >
                  {column.render('Header')}
                  {column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const isSelected =
              selectedRowId && row.original.id
                ? selectedRowId === row.original.id
                : false;
            return (
              <Tooltip
                shouldShow={!!row.original.ad_name}
                content={row.original.ad_name}
                interactive
              >
                <tr
                  className={`lp-table__row ${
                    isSelected ? 'lp-table__row--selected' : ''
                  }`}
                  onClick={() =>
                    row.original.id ? onRowSelect(row.original.id) : () => {}
                  }
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        maxWidth: '200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                      className="border"
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              </Tooltip>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
