import React from 'react';

export const ReportCardIcon = ({ className = '' }) => (
  <svg
    width="19"
    height="15"
    viewBox="0 0 19 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M9.80306 10.3925H7.99641V8.58589H9.80306V10.3925ZM15.223 10.3925H11.6097V8.58589H15.223V10.3925ZM6.18975 6.77923H4.38309V4.97258H6.18975V6.77923ZM15.223 6.77923H7.99641V4.97258H15.223V6.77923ZM17.0297 14.9092H2.57643C2.09728 14.9092 1.63775 14.7188 1.29893 14.38C0.960119 14.0412 0.769775 13.5817 0.769775 13.1025V2.26259C0.769775 1.78343 0.960119 1.3239 1.29893 0.98509C1.63775 0.646276 2.09728 0.455933 2.57643 0.455933H17.0297C17.5089 0.455933 17.9684 0.646276 18.3072 0.98509C18.646 1.3239 18.8364 1.78343 18.8364 2.26259V13.1025C18.8364 13.5817 18.646 14.0412 18.3072 14.38C17.9684 14.7188 17.5089 14.9092 17.0297 14.9092ZM2.57643 2.26259V13.1025H17.0297V2.26259H2.57643Z"
      fill="#2B437E"
    />
  </svg>
);

export default ReportCardIcon;
