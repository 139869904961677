import React, { useState } from 'react';

import {
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
} from 'recharts';
import { toFixed_2, toPercentRounded } from '../../utils/numbers';
import {
  DARWIN_BLUE,
  DARWIN_BLUE_2,
  DARWIN_NAVY,
  DARWIN_NAVY_5,
} from '../../constants';
import { DataItem } from './types';
import { isNumber } from '../../utils';

const POINT_COLORS = {
  weak: DARWIN_NAVY_5,
  mid: DARWIN_BLUE_2,
  strong: DARWIN_BLUE,
};

const CustomTooltip = ({ active, payload, allData, location }) => {
  if (active && payload && payload.length) {
    const hoveredData = payload[0].payload;
    const { win_percentage, experiments_with_comparison } = hoveredData;

    const percentThreshold = 2;
    const experimentsThreshold = 1;

    const overlappingPoints = allData.filter(
      (point) =>
        Math.abs(point.win_percentage - win_percentage) <= percentThreshold &&
        Math.abs(
          point.experiments_with_comparison - experiments_with_comparison
        ) <= experimentsThreshold
    );

    return (
      <div
        style={{
          fontFamily: 'Fira Code',
          background: 'rgba(255, 255, 255)',
          backdropFilter: 'blur(3px)',
          padding: '1rem',
          border: '1px solid #CACDD2',
          borderRadius: '5px',
          zIndex: 500
        }}
      >
        {overlappingPoints.map((data, index) => (
          <div key={index} style={{ marginBottom: '1rem' }}>
            <p className="m-0 mb-2">
              <strong>{data.label_name}</strong>
            </p>
            <p className="m-0 mb-2">
              Win percentage: {toPercentRounded(data.win_percentage * 0.01)}
            </p>
            <p className="m-0 mb-2">
              Experiments with comparison: {data.experiments_with_comparison}
            </p>
            {location === 'data_view' && (
              <p className="m-0 mb-2">Category name: {data.category_name}</p>
            )}
            <p className="m-0 mb-2">Full label name: {data.full_label_name}</p>
            {renderPValue(data.combined_p_value)}
          </div>
        ))}
      </div>
    );
  }
};

const renderPValue = (pValue) => {
  const isNumber = (value) => typeof value === 'number' && !isNaN(value);

  const getPValueText = (p) => {
    if (!isNumber(p) || p === 0) {
      return '-';
    }

    // Round up to 0.01 if p is between 0.00 and 0.01
    const adjustedP = p > 0 && p < 0.01 ? 0.01 : p;
    const formattedP = adjustedP.toFixed(2);

    if (adjustedP <= 0.05) {
      return `${formattedP} (Statistical Significance)`;
    } else if (adjustedP > 0.05 && adjustedP <= 0.1) {
      return `${formattedP} (Marginal Significance)`;
    }

    return formattedP;
  };

  return <p className="m-0">P-value: {getPValueText(pValue)}</p>;
};

type FilterType = {
  experiment_type: 'Versus' | 'Combined' | null;
  significance: 'marginal' | 'statistical' | null;
};

const filterData = (data: DataItem[], filters: FilterType) => {
  return data.filter((item) => {
    if (
      filters.experiment_type === 'Combined' &&
      item.experiment_type !== 'Combined'
    ) {
      return false;
    } else if (
      filters.experiment_type === 'Versus' &&
      item.experiment_type !== 'Versus'
    ) {
      return false;
    }

    if (
      filters.significance === 'marginal' &&
      (isNaN(item.combined_p_value) || item.combined_p_value > 0.10)
    ) {
      return false;
    }

    return true;
  });
};

export const Chart = ({
  data,
  location = 'global_view',
}: {
  data: DataItem[];
  location: 'global_view' | 'data_view';
}) => {
  const [filters, setFilters] = useState<FilterType>({
    experiment_type: null,
    significance: null,
  });

  const filteredChartData = filterData(data, filters);

  return (
    <div style={{ maxWidth: '80vw' }}>
      <ResponsiveContainer width="100%" height={400}>
        <ScatterChart margin={{ top: 10, right: 10, bottom: 40, left: 10 }}>
          <CartesianGrid />
          <XAxis
            type="number"
            dataKey="win_percentage"
            name="Higher Win Percentage"
            label={{
              value: 'Higher Win Percentage',
              position: 'insideBottom',
              offset: -20,
            }}
            domain={[0, 100]}
            reversed={true}
          />
          <YAxis
            type="number"
            dataKey="experiments_with_comparison"
            name="Experiments with Comparison"
          >
            <Label
              value="Experiments with Comparison"
              angle={-90}
              position="insideLeft"
              style={{ textAnchor: 'middle' }}
            />
          </YAxis>
          <Tooltip
            isAnimationActive={false}
            active={false}
            cursor={{ strokeDasharray: '3 3' }}
            content={(props) => (
              <CustomTooltip
                {...props}
                allData={filteredChartData}
                location={location}
              />
            )}
          />
          <Scatter
            isAnimationActive={false}
            data={filteredChartData}
            onMouseEnter={() => {}}
            onMouseLeave={() => {}}
            dataKey="id"
            shape={<SquareShape />}
          />
        </ScatterChart>
      </ResponsiveContainer>

      <Filters {...{ filters, setFilters, location }} />
    </div>
  );
};

const Filters = ({ filters, setFilters, location }) => {
  return (
    <div className="text-center">
      {location === 'global_view' && (
        <FilterItem
          onChange={(e) =>
            setFilters((prev) => ({
              ...prev,
              experiment_type: e.target.checked ? 'Versus' : null,
            }))
          }
          checked={filters.experiment_type === 'Versus'}
          label={`Only show "Versus" experiments`}
        />
      )}

      {location === 'global_view' && (
        <FilterItem
          onChange={(e) =>
            setFilters((prev) => ({
              ...prev,
              experiment_type: e.target.checked ? 'Combined' : null,
            }))
          }
          checked={filters.experiment_type === 'Combined'}
          label={`Only show "Combined" experiments`}
        />
      )}

      <FilterItem
        onChange={(e) =>
          setFilters((prev) => ({
            ...prev,
            significance: e.target.checked ? 'marginal' : null,
          }))
        }
        checked={filters.significance === 'marginal'}
        label={`Marginal and Statistical Significance only`}
      />
    </div>
  );
};

const FilterItem = ({ onChange, checked, label }) => {
  return (
    <span className="m-2 d-inline-flex align-items-center">
      <input onChange={onChange} checked={checked} type="checkbox" />
      <label className="mb-0 ml-1" style={{ fontSize: '12px' }}>
        {label}
      </label>
    </span>
  );
};

const calculatePointColor = (combined_p_value) => {
  if (!combined_p_value || !isNumber(combined_p_value))
    return POINT_COLORS['weak'];
  if (combined_p_value > 0.1) return POINT_COLORS['weak'];
  if (combined_p_value > 0.05) return POINT_COLORS['mid'];
  else return POINT_COLORS['strong'];
};

const SquareShape = (props) => {
  const { cx, cy, fill, combined_p_value } = props;
  const size = 20;
  return (
    <rect
      x={cx - size / 2}
      y={cy - size / 2}
      width={size}
      height={size}
      fill={calculatePointColor(combined_p_value)}
      stroke={DARWIN_NAVY}
      strokeWidth={1}
    />
  );
};
