import React from 'react';
import { toast } from 'react-toastify';
import Overlay from '../../components/Overlay';
import Button from '../../components/Button';

export const CloseButton = ({ closeToast }) => (
  <Button className="material-icons close-toast" onClick={closeToast}>
    Got it!
  </Button>
);

export const ToastContent = (missingSpend) => {
  const { permissions, dpa, total, date_string } = missingSpend;

  return (
    <>
      <Overlay show></Overlay>
      <h3>
        <i className="fa-solid fa-triangle-exclamation"></i>Just for your
        information
      </h3>
      <p>
        Due to limitations with Facebook, Darwin does not support the following
        types of ads: (1) Product catalog ads, or (2) Ads where we were not able
        to retrieve at least the thumbnail asset from Facebook directly. This
        can happen with missing permissions (influencer pages) or ads that are
        past their scheduled end date.
      </p>
      <p>
        The following spend is not represented in the Performance Frequency
        Report <br></br>
        {date_string}:
      </p>
      <div className="d-flex justify-content-center">
        <ul>
          <li>
            <span>Missing Permissions Spend: </span>
          </li>
          <li>
            <span>Unsupported Dynamic Product Catalog Spend: </span>
          </li>
          <li>
            <span>Total Missing Spend: </span>
          </li>
        </ul>
        <ul>
          <li>
            <span>
              {!!permissions
                ? permissions.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })
                : '$0.00'}
            </span>
          </li>
          <li>
            <span>
              {!!dpa
                ? dpa.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })
                : '$0.00'}
            </span>
          </li>
          <li>
            <span>
              {!!total
                ? total.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })
                : '$0.00'}
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};

export const missingSpendModal = (missingSpend) =>
  toast(ToastContent(missingSpend), {
    toastId: 'missing-spend',
    icon: false,
    position: 'top-center',
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    progress: 0,
    delay: 2000,
    className: 'spend-toast',
    closeButton: CloseButton,
  });
