import React, { ReactChild, ReactChildren } from 'react';
import Select from 'react-select';
import { DROPDOWN_STYLES } from './constants';

export type BreadcrumbItemProps = {
  text: string;
  variant: 'normal' | 'select' | 'custom';
  options?: [];
  selectedValue?: string;
  handleClick?: () => void;
  handleSelect?: (e, i) => void;
  onClick: () => void;
  children: ReactChildren | ReactChild;
  disable?: boolean;
};

export const BreadcrumbItem = ({
  text = '',
  variant = 'normal',
  options = [],
  selectedValue,
  handleSelect,
  onClick,
  onMenuOpen,
  children,
  disable=false,
}: BreadcrumbItemProps) => {
  if (disable) return null;
  let Render = null;

  switch (variant) {
    case 'custom':
      Render = children;
      break;
    case 'select':
      Render = <Dropdown {...{ text, options, selectedValue, handleSelect, onMenuOpen }} />;
      break;
    default:
      Render = <Default {...{ text, onClick }} />;
  }

  return (
    <span className="breadcrumbs-item">
      <span className="breadcrumbs-item-content">{Render}</span>
      <span className="breadcrumbs__slash">/</span>
    </span>
  );
};

type DefaultItemProps = {
  text: string;
  onClick: () => void;
};

const Default = ({ text, onClick }: DefaultItemProps) => {
  return <span onClick={onClick}>{text}</span>;
};

type DropdownItemProps = {
  options: { label: string; value: string; disable?: boolean }[];
  handleSelect: (e, i) => void;
  selectedValue?: string;
  onMenuOpen?: () => void;
};

const Dropdown = ({
  options,
  selectedValue,
  handleSelect,
  onMenuOpen = () => {},
}: DropdownItemProps) => {
  return (
    <div style={{ width: '100%' }}>
      <Select
        defaultValue={selectedValue}
        onChange={(e, i) => handleSelect(e, i)}
        options={options.filter((opt) => !opt.disable)}
        styles={DROPDOWN_STYLES}
        // menuPortalTarget={document.body}
        // menuPosition={'fixed'}
        value={selectedValue}
        onMenuOpen={onMenuOpen}
        // options={options.map((opt) => {
        //   return (
        //     <option value={opt.value}>{opt.label || opt.name || null}</option>
        //   );
        // })}
      />
    </div>
  );
};
