import React, { useState, useEffect } from 'react';

export const AddButton = ({ onAddLabel }) => {
  const handleAddLabel = () => {
    onAddLabel();
  };

  return (
    <svg
      className="custom-trained-add__btn"
      onClick={handleAddLabel}
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.1061 14.2582C9.10666 14.1751 9.12359 14.093 9.15594 14.0165C9.18829 13.94 9.23542 13.8707 9.29462 13.8124C9.35381 13.7541 9.42392 13.7081 9.50093 13.677C9.57794 13.6459 9.66033 13.6303 9.74338 13.631L14.4699 13.6697L14.5068 8.94315C14.5075 8.86013 14.5245 8.77804 14.5569 8.70159C14.5893 8.62514 14.6364 8.55582 14.6956 8.49758C14.7548 8.43934 14.8248 8.39333 14.9018 8.36217C14.9788 8.33101 15.0611 8.31531 15.1441 8.31598C15.2271 8.31664 15.3092 8.33365 15.3857 8.36604C15.4621 8.39842 15.5315 8.44555 15.5897 8.50473C15.6479 8.5639 15.6939 8.63397 15.7251 8.71093C15.7563 8.78789 15.772 8.87024 15.7713 8.95326L15.7326 13.6798L20.4592 13.7167C20.5422 13.7174 20.6243 13.7344 20.7007 13.7668C20.7772 13.7992 20.8465 13.8463 20.9047 13.9055C20.963 13.9646 21.009 14.0347 21.0402 14.1117C21.0713 14.1886 21.087 14.271 21.0863 14.354C21.0857 14.437 21.0687 14.5191 21.0363 14.5956C21.0039 14.672 20.9568 14.7413 20.8976 14.7996C20.8384 14.8578 20.7683 14.9038 20.6914 14.935C20.6144 14.9661 20.5321 14.9818 20.4491 14.9812L15.7225 14.9425L15.6856 19.669C15.6849 19.7521 15.6679 19.8342 15.6355 19.9106C15.6032 19.9871 15.556 20.0564 15.4969 20.1146C15.4377 20.1729 15.3676 20.2189 15.2907 20.25C15.2137 20.2812 15.1313 20.2969 15.0483 20.2962C14.9653 20.2956 14.8832 20.2786 14.8068 20.2462C14.7303 20.2138 14.661 20.1667 14.6027 20.1075C14.5445 20.0483 14.4985 19.9782 14.4673 19.9013C14.4362 19.8243 14.4205 19.742 14.4211 19.6589L14.4598 14.9324L9.73327 14.8955C9.65022 14.8949 9.56809 14.878 9.49159 14.8456C9.41509 14.8133 9.34572 14.7662 9.28746 14.707C9.2292 14.6478 9.18319 14.5777 9.15207 14.5007C9.12095 14.4236 9.10533 14.3413 9.1061 14.2582Z"
        fill="#667394"
      />
    </svg>
  );
};

export const SuccessMessage = ({ setShowMessage }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      setShowMessage(false);
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (!visible) return null;

  return <p className="success-msg">Saved!</p>;
};

export const CloseIcon = ({ onRemoveLabel, index }) => {
  const handleClick = (e) => {
    onRemoveLabel(index);
  };

  return (
    <svg
      onClick={handleClick}
      className="custom-trained-label__close"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.537519 0.427655C0.583964 0.381092 0.63914 0.344149 0.699885 0.318943C0.76063 0.293736 0.825751 0.280762 0.891519 0.280762C0.957286 0.280762 1.02241 0.293736 1.08315 0.318943C1.1439 0.344149 1.19907 0.381092 1.24552 0.427655L3.89152 3.07466L6.53752 0.427655C6.58401 0.381167 6.6392 0.344291 6.69993 0.319132C6.76067 0.293973 6.82577 0.281024 6.89152 0.281024C6.95726 0.281024 7.02236 0.293973 7.0831 0.319132C7.14384 0.344291 7.19903 0.381167 7.24552 0.427655C7.29201 0.474143 7.32888 0.529332 7.35404 0.590072C7.3792 0.650811 7.39215 0.715911 7.39215 0.781655C7.39215 0.847399 7.3792 0.9125 7.35404 0.973239C7.32888 1.03398 7.29201 1.08917 7.24552 1.13566L4.59852 3.78166L7.24552 6.42766C7.29201 6.47414 7.32888 6.52933 7.35404 6.59007C7.3792 6.65081 7.39215 6.71591 7.39215 6.78166C7.39215 6.8474 7.3792 6.9125 7.35404 6.97324C7.32888 7.03398 7.29201 7.08917 7.24552 7.13566C7.19903 7.18214 7.14384 7.21902 7.0831 7.24418C7.02236 7.26934 6.95726 7.28229 6.89152 7.28229C6.82577 7.28229 6.76067 7.26934 6.69993 7.24418C6.6392 7.21902 6.58401 7.18214 6.53752 7.13566L3.89152 4.48866L1.24552 7.13566C1.19903 7.18214 1.14384 7.21902 1.0831 7.24418C1.02236 7.26934 0.957262 7.28229 0.891519 7.28229C0.825775 7.28229 0.760674 7.26934 0.699935 7.24418C0.639196 7.21902 0.584006 7.18214 0.537519 7.13566C0.491031 7.08917 0.454154 7.03398 0.428995 6.97324C0.403836 6.9125 0.390887 6.8474 0.390887 6.78166C0.390887 6.71591 0.403836 6.65081 0.428995 6.59007C0.454154 6.52933 0.491031 6.47414 0.537519 6.42766L3.18452 3.78166L0.537519 1.13566C0.490955 1.08921 0.454012 1.03403 0.428806 0.973289C0.403599 0.912544 0.390625 0.847423 0.390625 0.781655C0.390625 0.715888 0.403599 0.650767 0.428806 0.590022C0.454012 0.529276 0.490955 0.474101 0.537519 0.427655Z"
        fill="#667394"
      />
    </svg>
  );
};