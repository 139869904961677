export const makeDnaTableData = (dnaRows, dnaCols) => {
  const columns = ['visual', ...dnaCols];

  const rows = dnaRows.map((row) => {
    return columns.map((col) => {
      if (col === 'visual') {
        const adNameWithLength = row.ad_names?.find((visual) => visual?.length);
        return adNameWithLength ?? row._id;
      }

      if (
        row[col] &&
        !isNaN(row[col]) &&
        row[col].toString().indexOf('.') != -1
      ) {
        try {
          return +row[col].toFixed(2);
        } catch (e) {
          // console.error(e)
          return row[col];
        }
      }

      return row[col];
    });
  });

  return [columns, ...rows];
};

export const makeImpactReportData = (data) => {
  return data.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item['Category Name']]: +item['Aggregated Importance'].toFixed(4),
      }),
    {}
  );
};

export const makeSummaryData = (data, isROAS) => {
  return data
    .filter(
      (category) =>
        !['All Visuals', 'All New Visuals', 'Top 10 Spending Visuals'].includes(
          category.label
        )
    )
    .reduce(
      (obj, category) =>
        Object.assign(obj, {
          [category.label]: category.labels.map((label) => {
            let obj = {
              l: label.name,
              cpa: +label.aggregate_cpa.toFixed(2),
            };

            if (isROAS)
              return {
                ...obj,
                roas: +label.aggregate_roas.toFixed(2),
              };

            return obj;
          }),
        }),
      {}
    );
};
