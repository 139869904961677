import PropTypes from "prop-types";

import { hasRoles } from "../../cookies";

export default function RoleAccess({
  roles = [],
  children = null,
  useAdmin = true,
}) {
  if (hasRoles(roles, { useAdmin })) {
    return children;
  } else {
    return null;
  }
}

RoleAccess.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
};
