import React, { useEffect, useMemo } from 'react';
import { toCurrency, toPercent_2, toInt, toFixed_2 } from '../../utils/numbers';
import Tooltip from '../../components/Tooltip';
import { AssetDetails } from '../CreativeAnalytics/PerformanceFrequency/SquaresList';
import { useDispatch } from 'react-redux';
import { toggleSelectVisual } from '../../store/CreativeAnalytics/creativeAnalyticsSlice';
import { QUINTILE_COLORS } from './constants';
import { useCreativeAnalyticsGet } from '../CreativeAnalytics/contexts';
import { getCustomEventsCols } from './utils/getCustomEventsCols';
import { useHover } from '../../hooks/useHover';
import { format } from 'date-fns';
import { getCustomScoringHeader } from './utils';
import { isAdminRole } from '../../cookies';
import { SKY_ZONE_CUSTOM_METRICS } from '../CreativeAnalytics/PerformanceFrequency/constants';

export const sm = 150;
export const md = 250;
export const lg = 300;

export const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

export const removeDuplicates = (arr: Object[], prop: string) => {
  const seen = new Set();
  return arr.filter((obj) => {
    const value = obj[prop];
    if (!seen.has(value)) {
      seen.add(value);
      return true;
    }
    return false;
  });
};

export const customSortFn = (accessor) => (rowA, rowB, columnId, desc) => {
  const valueA = rowA.original[accessor];
  const valueB = rowB.original[accessor];

  if (desc) return valueA < valueB ? -1 : 1;

  if (!valueA && !valueB) {
    return 0;
  } else if (!valueA) {
    return 1;
  } else if (!valueB) {
    return -1;
  }

  return valueA > valueB ? 1 : -1;
};

export const useDnaReportColumns = ({
  primary_metric,
  metadata = {},
  additional_metrics = [],
  handleCreateManualTag,
  clientMetrics,
  customScoringData = [],
}) => {
  const { customEvents, isNoom } = useCreativeAnalyticsGet();
  const { visuals = [], categories = [] } = metadata ?? {};

  const seenAccessors = new Set();

  const metadataCols = useMemo(() => {
    return categories.map(({ category_name, category_values }) => ({
      Header: category_name,
      accessor: category_name,
      width: lg,
      maxWidth: lg,
      filter: 'equals',
      isMetadata: true,
      Cell: ({
        row: {
          original: { id, _id },
        },
      }) => {
        const maybe = visuals.find(
          (v) => v.visual_id === id || v.visual_id === _id
        );
        if (!maybe) return <div>-</div>;

        const maybeCategory = maybe?.visual_categories?.find(
          (category) => Object.keys(category)[0] === category_name ?? {}
        );

        const labels = (
          !!maybeCategory ? Object.values(maybeCategory)[0] : []
        ).map((label) => <li>{label}</li>);

        if (labels.length < 1) return <p>-</p>;

        const LabelsTooltip = (
          <div className="labels-tooltip">
            <ul className="dna-table__labels m-0">{labels}</ul>
          </div>
        );

        return (
          <Tooltip content={LabelsTooltip}>
            <ul className="dna-table__labels">{labels}</ul>
          </Tooltip>
        );
      },
    }));
  }, [metadata]);

  const additionalMetricsCols = useMemo(() => {
    let result = [];

    additional_metrics.forEach((metric) => {
      if (seenAccessors.has(metric.value)) {
        // dont add
      } else {
        result.push({
          Header: metric.name,
          accessor: metric.value,
          width: lg,
          maxWidth: lg,
          disableFilters: true,
          Cell: ({ row: { original } }) => {
            return (
              <Tooltip content={'test'}>
                <ul className="dna-table__labels">
                  {original[metric.value] ?? '-'}
                </ul>
              </Tooltip>
            );
          },
        });
        result.push({
          Header: `Cost per ${metric.name}`,
          accessor: `cp_${metric.value}`,
          width: lg,
          maxWidth: lg,
          disableFilters: true,
          sortType: customSortFn(`cp_${metric.value}`),
          Cell: ({ row: { original } }) => {
            let value = '-';

            try {
              if (metric.value === 'video_view') {
                value = `$${original[`cp_video_view`].toFixed(4)}`;
              } else {
                value = toCurrency(original[`cp_${metric.value}`]);
              }
            } catch (e) {
              console.error(e);
            }

            return (
              <Tooltip content={'test'}>
                <ul className="dna-table__labels">{value ?? '-'}</ul>
              </Tooltip>
            );
          },
        });
        seenAccessors.add(metric.value);
      }
    });

    return result;
  }, [additional_metrics]);

  const customEventsCols = useMemo(() => {
    return getCustomEventsCols(customEvents, (metric, addMetric) => {
      if (seenAccessors.has(metric)) {
        // dont add
      } else {
        addMetric();
        seenAccessors.add(metric);
      }
    });
  }, [customEvents]);

  const clientMetricsCols = useMemo(() => {
    let result = [];

    if (!isNoom) {
      clientMetrics?.forEach((metric) => {
        if (seenAccessors.has(metric)) {
          // dont add
        } else {
          result.push({
            Header: metric,
            accessor: metric,
            width: lg,
            maxWidth: lg,
            disableFilters: true,
            Cell: ({ row: { original } }) => {
              return (
                <Tooltip content={'test'}>
                  <ul className="dna-table__labels">
                    {original[metric] ?? '-'}
                  </ul>
                </Tooltip>
              );
            },
          });
          result.push({
            Header: `Cost per ${metric}`,
            accessor: `cp_${metric}`,
            width: lg,
            maxWidth: lg,
            disableFilters: true,
            Cell: ({ row: { original } }) => {
              return (
                <Tooltip content={'test'}>
                  <ul className="dna-table__labels">
                    {toCurrency(original[`cp_${metric}`]) ?? '-'}
                  </ul>
                </Tooltip>
              );
            },
          });
          seenAccessors.add(metric);
        }
      });
    } else {
      result = [
        {
          Header: `Signups`,
          accessor: 'Signups',
          disableFilters: true,
          Cell: ({ row: { original } }) => {
            const value = original['Signups'];
            if (!value) return <p>N/A</p>;
            return <p>{toInt(value)}</p>;
          },
        },
        {
          Header: `LTV`,
          accessor: 'LTV',
          disableFilters: true,
          Cell: ({ row: { original } }) => {
            const value = original['LTV'];
            if (!value) return <p>N/A</p>;
            return <p>{toCurrency(value)}</p>;
          },
        },
        {
          Header: `Cost per Signup`,
          accessor: 'Cost per Signup',
          disableFilters: true,
          Cell: ({ row: { original } }) => {
            const value = original['Cost per Signup'];
            if (!value || !isFinite(value)) return <p>N/A</p>;
            return <p>{toCurrency(value)}</p>;
          },
        },
        {
          Header: `Average LTV`,
          accessor: 'Average LTV',
          disableFilters: true,
          Cell: ({ row: { original } }) => {
            const value = original['Average LTV'];
            if (!value || !isFinite(value)) return <p>N/A</p>;
            return <p>{toCurrency(value)}</p>;
          },
        },
        {
          Header: `LTV to CAC Ratio`,
          accessor: 'LTV to CAC Ratio',
          disableFilters: true,
          Cell: ({ row: { original } }) => {
            const value = original['LTV to CAC Ratio'];
            if (!value || !isFinite(value)) return <p>N/A</p>;
            return <p>{toFixed_2(value)}</p>;
          },
        },
      ];
    }

    return result;
  }, [clientMetrics, isNoom]);

  const customCols = removeDuplicates(
    [...additionalMetricsCols, ...clientMetricsCols],
    'accessor'
  );

  const customScoringCols = useMemo(() => {
    if (!customScoringData) return [];
    const obj = customScoringData[0];
    if (!obj) return [];

    return Object.keys(obj).reduce((acc, col) => {
      let invalidCols = [
        'Asset URL',
        'Visual',
        'Fitness Score',
        'Spend Quintile',
        'Performance Quintile',
        'CPA',
        'Spend',
        'Period Type',
        'Period Dates',
        'Ad Names',
      ];

      if (!isAdminRole()) {
        invalidCols = [
          ...invalidCols,
          'line_item_cpa',
          'line_item_cpc',
          'line_item_ctr',
        ];
      }

      const isValid = !invalidCols.includes(col);

      if (isValid) {
        acc.push({
          Header: getCustomScoringHeader(col),
          accessor: col,
          width: lg,
          maxWidth: lg,
          disableHidden: true,
          disableFilters: true,
          Cell: ({ row: { original } }) => {
            return (
              <p>
                {original[col] && typeof original[col] === 'number'
                  ? col.includes('Score')
                    ? toPercent_2(original[col])
                    : original[col].toFixed(2)
                  : '-'}
              </p>
            );
          },
        });
      }

      return acc;
    }, []);
  }, [customScoringData?.length]);

  return [
    {
      Header: 'Visual',
      accessor: 'id',
      maxWidth: md,
      disableColumnOrder: true,
      width: md,
      Cell: ({ row: { original: visual } }) => {
        const dispatch = useDispatch();
        const adNameWithLength = visual.ad_names?.find(
          (visual) => visual?.length
        );

        const [ref, isHovered] = useHover();

        const display = adNameWithLength ?? visual._id;

        const handleClick = () => {
          dispatch(toggleSelectVisual(visual._id));
        };

        const uniqueAdNames = Array.from(new Set(visual.ad_names));

        return (
          <Tooltip
            shouldShow={isHovered}
            content={
              <AssetDetails getVisualData={() => visual} primary_metric={primary_metric} />
            }
            placement="right"
            follow={false}
            distance={0}
            delay={[100, 100]}
            maxWidth="none"
            noPadding
          >
            <p ref={ref} className="visual-cell" onClick={handleClick}>
              {`${display}`}
              {uniqueAdNames?.length > 1 ? (
                <sup>{uniqueAdNames.length}</sup>
              ) : null}
            </p>
          </Tooltip>
        );
      },
      filter: (rows, id, filterValue) => {
        return rows.filter(({ original: visual }) => {
          if (!filterValue) return true;
          try {
            const { ad_names } = visual;
            const display = ad_names?.length > 1 ? visual._id : ad_names?.[0];
            return display.toLowerCase().includes(filterValue.toLowerCase());
          } catch (e) {
            console.error(e);
            return false;
          }
        });
      },
    },
    {
      Header: 'Fitness Score',
      accessor: 'fitness_score',
      disableFilters: true,
      Cell: ({
        row: {
          original: { fitness_score },
        },
      }) => {
        const bgColor = QUINTILE_COLORS.bg[fitness_score - 1];
        return (
          <div
            style={{
              width: '75%',
              background: bgColor,
              padding: '3px 16px',
              borderRadius: '5px',
              opacity: '95%',
            }}
          >
            {fitness_score}/10
          </div>
        );
      },
    },
    {
      Header: 'Spend Quintile',
      accessor: 'spend_quintile',
      disableFilters: true,
      Cell: ({
        row: {
          original: { spend_quintile },
        },
      }) => {
        const bgColor = QUINTILE_COLORS.bg[(spend_quintile - 1) * 2];
        return (
          <div
            style={{
              width: '75%',
              background: bgColor,
              padding: '3px 16px',
              borderRadius: '5px',
              opacity: '95%',
            }}
          >
            {spend_quintile}/5
          </div>
        );
      },
    },
    {
      Header: 'Performance Quintile',
      accessor: 'primary_metric_quintile',
      disableFilters: true,
      Cell: ({
        row: {
          original: { primary_metric_quintile },
        },
      }) => {
        const bgColor = QUINTILE_COLORS.bg[(primary_metric_quintile - 1) * 2];
        return (
          <div
            style={{
              width: '75%',
              background: bgColor,
              padding: '3px 16px',
              borderRadius: '5px',
              opacity: '95%',
            }}
          >
            {primary_metric_quintile}/5
          </div>
        );
      },
    },

    {
      Header: 'CPA',
      accessor: 'cpa',
      disableFilters: true,
      filter: 'equals',
      sortType: customSortFn(`cpa`),
      Cell: ({
        row: {
          original: { cpa },
        },
      }) => {
        if (!cpa) return <p>-</p>;
        return <p>{toCurrency(cpa)}</p>;
      },
    },
    {
      Header: 'ROAS',
      accessor: 'roas',
      disableFilters: true,
      filter: 'equals',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original['roas'];
        const valueB = rowB.original['roas'];

        return desc ? (valueA < valueB ? -1 : 1) : valueA > valueB ? 1 : -1;
      },
      Cell: ({
        row: {
          original: { roas },
        },
      }) => {
        if (!roas) return <p>-</p>;
        return <p>{roas.toFixed(2)}</p>;
      },
    },
    {
      Header: 'CPM',
      accessor: 'cpm',
      disableFilters: true,
      filter: 'equals',
      sortType: customSortFn(`cpm`),
      Cell: ({
        row: {
          original: { cpm },
        },
      }) => {
        if (!cpm) return <p>-</p>;
        return <p>{toCurrency(cpm)}</p>;
      },
    },
    {
      Header: 'CPC',
      accessor: 'cpc',
      disableFilters: true,
      filter: 'equals',
      sortType: customSortFn(`cpc`),
      Cell: ({
        row: {
          original: { cpc },
        },
      }) => {
        if (!cpc) return <p>-</p>;
        return <p>{toCurrency(cpc)}</p>;
      },
    },
    {
      Header: 'CTR',
      accessor: 'ctr',
      disableFilters: true,
      filter: 'equals',
      sortType: (rowA, rowB, columnId, desc) => {
        const valueA = rowA.original[columnId];
        const valueB = rowB.original[columnId];

        return desc ? (valueA < valueB ? -1 : 1) : valueA > valueB ? 1 : -1;
      },
      Cell: ({
        row: {
          original: { ctr },
        },
      }) => {
        if (!ctr) return <p>-</p>;
        return <p>{toPercent_2(ctr)}</p>;
      },
    },
    {
      Header: 'Spend',
      accessor: 'spend',
      disableFilters: true,
      filter: 'equals',
      Cell: ({
        row: {
          original: { spend },
        },
      }) => {
        return <p>{toCurrency(spend)}</p>;
      },
    },
    {
      Header: 'Impressions',
      accessor: 'impressions',
      disableFilters: true,
      Cell: ({ value }) => {
        return <p>{toInt(value)}</p>;
      },
    },
    {
      Header: 'Results',
      accessor: 'results',
      disableFilters: true,
      Cell: ({
        row: {
          original: { results },
        },
      }) => {
        return <p>{results?.toFixed(2) ?? '-'}</p>;
      },
    },
    {
      Header: 'Period Type',
      accessor: 'period_type',
      disableFilters: true,
      show: false,
      isVisible: false,
      canExport: true,
      Cell: ({
        row: {
          original: { period_type },
        },
      }) => {
        const PERIOD_TYPE = {
          selected: 'Selected',
          previous: 'Previous',
        };
        return <p>{PERIOD_TYPE[period_type]} Period</p>;
      },
    },
    {
      Header: 'Period Dates',
      accessor: 'period_dates',
      disableFilters: true,
      width: md,
      show: false,
      Cell: ({
        row: {
          original: { period_dates },
        },
      }) => {
        return <p>{period_dates}</p>;
      },
    },
    {
      Header: 'New Visual',
      accessor: 'new_visual',
      disableFilters: true,
      // width: md,
      Cell: ({
        row: {
          original: { new_visual },
        },
      }) => {
        return <p>{new_visual}</p>;
      },
    },
    {
      Header: 'Custom Performance Score',
      accessor: 'custom_performance_score',
      disableFilters: true,
      Cell: ({ value }) => {
        return <p>{Math.round(value) ?? '-'}</p>;
      },
    },
    {
      Header: 'Manual Tags',
      accessor: 'manual_tags',
      maxWidth: md,
      width: md,
      cellProps: {
        style: {
          position: 'relative',
        },
      },
      Cell: ({
        row: {
          original: { manual_tags = [], id },
        },
      }) => {
        return (
          <Tooltip
            shouldShow={manual_tags.length > 0}
            content={
              <ul className="px-3 py-0 mb-0">
                {manual_tags?.map((tag) => <li className="mb-0">{tag}</li>)}
              </ul>
            }
          >
            <span>
              {manual_tags.length > 0 ? manual_tags.join(', ') : 'None'}{' '}
              <i
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
                onClick={() => handleCreateManualTag(id)}
                className="fa-regular fa-square-plus ml-3"
              ></i>
            </span>
          </Tooltip>
        );
      },
    },
    ...metadataCols,
    ...customCols,
    ...customEventsCols,
    ...customScoringCols,
  ];
};
