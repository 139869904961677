import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

function Toggle({ checked, onChange, disabled, id, name, dark, }) {
  return (
    <div className={
      `${disabled ? 'toggle-disabled': ''}
        ${dark ? 'dark-bg': ''}
       switch-button switch-button-success`}>
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        name={name}
        id={id}
        onChange={onChange}
      />
      <span>
        <label htmlFor={id} />
      </span>
    </div>
  );
}

Toggle.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string,
};

export default Toggle;
