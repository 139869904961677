import React from 'react'
import Tooltip from '../../../../components/Tooltip';
import clsx from 'clsx';

const RecItem = ({
  rec,
  selectedRecs,
  setSelectedRecs,
  showCopilot,
  setShowCopilot,
}) => {
  const { category, recommendation, why, creative_score } = rec;
  const isTestMore = category?.toLowerCase() === 'test more';
  const isSelected = selectedRecs.includes(rec.recommendation);

  const handleToggleRec = () => {
    let update;

    if (!isSelected) {
      update = [...selectedRecs, rec.recommendation];
    } else {
      update = selectedRecs.filter((r) => r !== rec.recommendation);
    }

    setSelectedRecs(update);

    if (!showCopilot) {
      setShowCopilot(true);
    }
  };

  const Top = (
    <div className="recs-list-item__top d-flex justify-content-between">
      <div className="d-flex align-items-center">
        <div className="recs-list-item__icon">
          <i
            className={`fa-regular fa-thumbs-${isTestMore ? 'up' : 'down'}`}
          ></i>
        </div>
        <h3 className="recs-list-item__heading m-0">
          {category} <span>{recommendation}</span>
        </h3>
      </div>
      <Tooltip
        content={
          !isSelected
            ? 'Add to creative request'
            : 'Remove from creative request'
        }
      >
        <button
          className={`recs-list-item__toggle ${isSelected ? 'selected' : ''}`}
          onClick={handleToggleRec}
        >
          {isSelected ? (
            <i className="fa fa-check" />
          ) : (
            <i className="fa fa-plus" />
          )}
        </button>
      </Tooltip>
    </div>
  );

  return (
    <li
      className={clsx('recs-list-item m-0', {
        test_more: category.toLowerCase() === 'test more',
        test_less: category.toLowerCase() === 'test less',
      })}
    >
      {Top}
      <p className="recs-list-item__why">{why}</p>
    </li>
  );
};

export default RecItem