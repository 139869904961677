import React, { useMemo } from 'react';
import Tooltip from '../../../../components/Tooltip';
import { StarIcon } from '../icons';
import { getColumnData } from './columns';
import { useLabelsLookup } from '../../selectors';

export const getSortedLabels = (
  labels,
  isOrganicView,
  isCompetitorView,
  selectedMetric,
  labelsLookup
) => {
  if (isOrganicView) {
    return [...labels].sort((a, b) => {
      if (a[`${selectedMetric} per post`] > b[`${selectedMetric} per post`])
        return -1;
      if (a[`${selectedMetric} per post`] < b[`${selectedMetric} per post`])
        return 1;
    });
  }

  if (isCompetitorView) {
    return [...labels].sort((a, b) => {
      if (a.number_unique_creatives > b.number_unique_creatives) return -1;
      if (a.number_unique_creatives < b.number_unique_creatives) return 1;
    });
  }

  return labels;
};

export const DetailsTable = ({
  category,
  columnHeaders,
  fromCreativeStudio,
  current,
  isCompetitorView,
  isOrganicView,
  typeMetric,
  selectedMetric,
  isDuff,
}) => {
  const labelsLookup = useLabelsLookup();
  const labels = useMemo(() => {
    try {
      return category.labels.map((label) => {
        return {
          ...label,
          ...labelsLookup.get(label.id),
        };
      });
    } catch (e) {
      console.log(e);
      return category?.labels || [];
    }
  }, [category, labelsLookup]);

  const sortedLabels = getSortedLabels(
    labels,
    isOrganicView,
    isCompetitorView,
    selectedMetric,
    labelsLookup
  );

  console.log({ sortedLabels });

  if (!labels.length) {
    return (
      <table className="table table-borderless category-table">
        <p className="mt-2">No labels exist for this category.</p>
      </table>
    );
  }

  return (
    <table className="table table-borderless category-table">
      <tr className="category-table__header">
        <th className="category-table__cell header">Label</th>
        {columnHeaders.map((colHeader) => (
          <th className="category-table__cell header">{colHeader}</th>
        ))}
      </tr>
      {sortedLabels.map((label: LabelObject, i: number) => {
        const activeLabels: LabelObject[] = sortedLabels.filter(
          (categoryLabel) =>
            !fromCreativeStudio
              ? categoryLabel.visuals.some(({ id }) => id === current.id)
              : current.labels.includes(categoryLabel.value)
        );
        const active = activeLabels.find((x) => x.value === label.value);
        let ranking = ('00' + (i + 1)).slice(-2);
        const isTestingOpportunity =
          label.spend === 0 && label.total_results === 0;

        const formatCellData = (cell) => {
          if (isTestingOpportunity) return '-';
          return cell;
        };

        const cells = getColumnData({
          label,
          typeMetric,
          selectedMetric,
          isDuff,
          isOrganicView,
          isCompetitorView,
        });

        return (
          <tr
            className={`category-table__row ${
              active ? 'category-table__row--active' : ''
            } ${
              isTestingOpportunity
                ? 'category-table__row--testing-opportunity'
                : ''
            }`}
          >
            <td className="category-table__cell label">
              <div className="item-number">
                <span>{isTestingOpportunity ? '00' : ranking}</span>
                {isTestingOpportunity && (
                  <Tooltip
                    content={
                      <div>
                        <span>Testing Opportunity</span>
                        <br />
                        <span>
                          <span className="subtle">You aren't testing any</span>
                          {` ${label.name} `}
                          <span className="subtle">ads.</span>
                        </span>
                      </div>
                    }
                    delay={[500, 0]}
                  >
                    <span className="opportunity-icon">
                      <StarIcon />
                    </span>
                  </Tooltip>
                )}
              </div>
              <div className="label-name">
                <span>{`${label.name} `}</span>
              </div>
            </td>
            {cells.map((cell) => {
              return (
                <td className="category-table__cell">{formatCellData(cell)}</td>
              );
            })}
          </tr>
        );
      })}
    </table>
  );
};
