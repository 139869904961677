import { simpleHash } from '../../../../utils';

export const REMOVE = 'REMOVE';
export const UPSERT = 'UPSERT';

const removeItem = (dashboardConfigs, item) =>
  dashboardConfigs.filter(({ id }) => id !== item.id);

function findWithIndex(arr, predicate) {
  const L = arr.length;

  for (let i = 0; i < L; i += 1) {
    const item = arr[i];

    if (predicate(item)) {
      return [i, item];
    }
  }

  return [null, null];
}

function setArray(arr, index, item) {
  arr[index] = item;

  return arr;
}

/**
 * @param {Array} dashboardConfigs
 * @param {{ id?: String, name: String, layout?: Object, vizState?: Object }} conf
 */
const upsertItem = (
  dashboardConfigs,
  { id = null, name, layout, vizState }
) => {
  const doesNotExist = !id;
  const update = {
    id: id || simpleHash(`${name}-${Date.now()}`).toString(),
    name,
    layout: layout || undefined,
    vizState: vizState || undefined,
  };

  if (doesNotExist) {
    return [...dashboardConfigs, update];
  }
  const [index, item] = findWithIndex(
    dashboardConfigs,
    (item) => item.id === update.id
  );

  if (index >= 0) {
    return setArray(dashboardConfigs, index, { ...item, ...update });
  }
  return [...dashboardConfigs, update];
};

const METHODS = {
  [REMOVE]: removeItem,
  [UPSERT]: upsertItem,
};

/**
 * @typedef {{ id?: String, name: String, layout?: Object, vizState?: Object}} DashItem
 * @param {Object} config
 * @param {String} config.action "UPSERT" or "REMOVE"
 * @param {DashItem} config.update
 * @param {[DashItem]} config.existingConfigs
 *
 * @return {[DashItem]}
 */
export function setDashboardConfigs({ action, update, existingConfigs }) {
  const method = METHODS[action];

  if (!method) {
    throw new Error('Invalid method for updating the dashboard config.');
  } else {
    return method(existingConfigs, update);
  }
}
