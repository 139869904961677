import { toDarwinDate } from '../../utils/darwin-dates';
import { ELEMENT_KEYS } from './constants';

export const makeHash = (...strs) => simpleHash(strs.join(','));

export const simpleHash = (str) => {
  let hash = 0;
  const L = str.length;

  if (L === 0) {
    return hash;
  }
  for (let i = 0; i < L; i++) {
    const char = str.charCodeAt(i);

    hash = (hash << 5) - hash + char;
    hash &= hash;
  }

  return hash;
};

export const makeAdName = (visualType) =>
  `${
    visualType === 'image' ? 'IMG' : 'VID'
  } | TH: <THEME> | <AD-NAME> | PENDING_CLIENT_APPROVAL | ${toDarwinDate(
    new Date()
  )}`;

/**
 * @param {{ atf: Object, btf: Object, cta: Object, visual: Object, name: String, id: String, preview: String}} ad
 * @param {{ atf: Array, btf: Array, cta: Array, visual: Array}} carousel
 *
 * @return {{ atf: Array, btf: Array, cta: Array, visual: Array, id: String, name: String, preview: String}}
 */
export const mergeCarouselData = (ad, carousel) => {
  if (!ad) {
    return {};
  }
  const results = {};

  for (const elementType of ELEMENT_KEYS) {
    const elementCarousel = carousel[elementType];
    const firstItem = getFeatures(ad[elementType], elementCarousel);
    firstItem.isBest = true;

    results[elementType] = firstItem;
  }

  return {
    ...ad,
    ...results,
  };
};

/**
 * @param {Object} first
 * @param {[Object]} rest
 */
const getFeatures = (first, rest) => {
  if (!rest) {
    return first;
  }
  const found = rest.find(({ value }) => value === first.value);

  if (!found) {
    return first;
  }
  return { ...first, features: found.features };
};
