import React from 'react';
import BlockReport from '../CreativeReports/BlockReport';

const TestResults = ({ test, handleEditTest, handleShowRail, showRail, isIdealImage }) => {
  if (!test) {
    return <p className="select-test">Select a test.</p>;
  }
  return (
    <div className="test-results">
      <BlockReport
        creativeTest={test}
        handleEditTest={handleEditTest}
        handleShowRail={handleShowRail}
        showRail={showRail}
        isIdealImage={isIdealImage}
      />
    </div>
  )
}

export default TestResults;
