import { toDarwinDate, daysFrom } from '../../../utils/darwin-dates';

const now = new Date();
const yesterday = new Date(now);
yesterday.setDate(yesterday.getDate() - 1);

export const PARAM_DEFAULTS = {
  metrics: 'spend results cpa',
  campaigns: 'all',
  level: 'asset',
  period1: [daysFrom(now, -30), daysFrom(now, -1)].map(toDarwinDate).join(','),
  period2: '',
  audience_breakdown: false,
  sort: 'spend desc',
  labels: '',
};

export const METRIC_OPTIONS = [
  { value: 'spend', label: 'Spend' },
  { value: 'results', label: 'Results' },
  { value: 'impressions', label: 'Impressions' },
  { value: 'post', label: 'Shares' },
  { value: 'link_clicks', label: 'Link Clicks' },
  { value: 'cpa', label: 'CPA' },
  { value: 'cpm', label: 'CPM' },
  { value: 'ctr', label: 'CTR' },
  { value: 'cpc', label: 'CPC' },
  { value: 'roas', label: 'ROAS' },
  { value: 'a_s', label: 'A/S' },
  { value: 'cpl', label: 'Cost per Lead' },
  { value: 'cp_post', label: 'Cost per Share' },
  { value: 'cp_add_to_cart', label: 'Cost per Add To Cart' },
  { value: 'cp_initiate_checkout', label: 'Cost per Initiate Checkout' },
  { value: 'cp_purchase', label: 'Cost per Purchase' },
  { value: 'cp_page_engagement', label: 'Cost per Page Engagement' },
  { value: 'cp_post_engagement', label: 'Cost per Post Engagement' },
  { value: 'cp_view_content', label: 'Cost per View Content' },
];

export const GA_METRIC_OPTIONS = [
  { value: 'ga_fb_lp', label: 'GA FB Landing Page View' },
  { value: 'ga_fb_add_to_cart', label: 'GA FB Add to Cart' },
  { value: 'ga_fb_initiate_checkout', label: 'GA FB Initiate Checkouts' },
  { value: 'ga_fb_conversions', label: 'GA FB Conversions' },
  { value: 'ga_fb_conversion_value', label: 'GA FB Conversion Value' },
  { value: 'cp_ga_fb_lp', label: 'Cost Per GA FB Landing Page View' },
  { value: 'cp_ga_fb_add_to_cart', label: 'Cost Per GA FB Add to Cart' },
  {
    value: 'cp_ga_fb_initiate_checkout',
    label: 'Cost Per GA FB Initiate Checkout',
  },
  { value: 'cp_ga_fb_conversions', label: 'GA FB CPA' },
  { value: 'cp_ga_fb_conversion_value', label: 'GA FB ROAS' },
];

export const currencyMetrics = [
  'spend',
  'cpa',
  'cpm',
  'cpc',
  'cpl',
  'cp_post',
  'cp_add_to_cart',
  'cp_initiate_checkout',
  'cp_purchase',
  'cp_page_engagement',
  'cp_post_engagement',
  'cp_view_content',
  'value_offsite_conversion.fb_pixel_purchase',
];

export const percentMetrics = ['a_s'];

export const percent_2Metrics = ['ctr'];

export const intMetrics = [
  'results',
  'post',
  'link_clicks',
  'impressions',
  'reach',
];

export const decimalMetrics = ['roas'];

export const FILTER_TAG_CLASSES = [
  'filter-tags__header',
  'filter-tags__body',
  'noselect-all',
  'filter-tags__heading',
  'filter-tags',
  'filter-tags__list',
  'filter-tags__item',
  'close-icon',
  'filter-tags__tag-name',
  'filter-tags__select',
  'tags-icon',
  'tags-icon-path',
  'filters-toggle',
];
