import joi from 'joi';

export const SavePreviewSchema = joi.object({
  filename: joi.string().required(),
  version: joi.string().required(),
  stories_filename: joi.string().required(),
  stories_version: joi.string().required(),
  atf: joi.string().required(),
  btf: joi.string().required(),
  cta: joi.string().required(),
  landing_page: joi.string().allow(''),
  utm_parameters: joi.string().allow(''),
  default_preview_type: joi.string().allow(''),
  ad_type: joi.string().required(),
  carousel_choices: joi.array().required(),
});

export const VIEW_OPTIONS = [
  { label: 'Asset Preview', value: 'raw-asset' },
  { label: 'Facebook Preview', value: 'facebook-ad' },
];

export const ASSET_DROPDOWN_STYLES = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    border: '1px solid #dedede',
    boxShadow: 'none',
    fontSize: '1.2rem',
    color: '#566577',
    cursor: 'pointer',
  }),
  valueContainer: (styles) => ({
    ...styles,
    overflow: 'visible',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: '#dedede',
    borderLeft: 0,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    color: '#dedede',
  }),
  container: (provided) => ({
    ...provided,
    borderRadius: '2px',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '5px',
    fontSize: '1.1rem',
    color: '#667394',
    width: 'fit-content',
    padding: '0.5rem',
  }),
  option: (provided) => ({
    ...provided,
    cursor: 'pointer',
    fontSize: '1rem',
    color: '#667394',
    backgroundColor: 'white',
    ':hover': {
      backgroundColor: '#F2F8FF',
    },
    borderRadius: '2px',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#667394',
    fontSize: '1.1rem',
    overflow: 'visible',
  }),
};
