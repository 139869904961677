import React from 'react';
import Tooltip from '../../../components/Tooltip';
import AnalyticsButton from '../assets/AnalyticsButton';
import DeleteButton from '../assets/DeleteButton';

import './SingleValue.scss';

const SingleValue = ({ cardData, metric, editMode, remove, openCategoryInNewTab }) => {
  const { title, data, tooltip, labelToUse } = cardData;
  const label = data.find((x) => x.value === labelToUse);

  return (
    <div
      className="insights-card"
      style={{ justifyContent: 'center', alignItems: 'center' }}
    >
      <AnalyticsButton onClick={openCategoryInNewTab} />
      {editMode && <DeleteButton onClick={remove} />}
      <Tooltip content={tooltip[metric]}>
        <div className="single-value">
          <h2 className="single-value__value">{label?.[metric] || '0'}</h2>
          <h3 className="single-value__title">{title}</h3>
        </div>
      </Tooltip>
    </div>
  );
};
export default SingleValue;
