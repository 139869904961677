import { useMemo, useState } from 'react';
import flatten from 'lodash/flatten';

import {
  dropCPAColumns,
  dropCPAColumnsP1,
  dropCPAColumnsP2,
  dropNonEmailColumns,
  dropNonOrganicColumns,
  dropROASColumns,
  dropROASColumnsP1,
  dropROASColumnsP2,
  useColumns,
  useCustomColumnHeaders,
  useCustomEventsHeaders,
} from '../CreativeAnalytics/PerformanceFrequency/Columns';
import { useCreativeAnalyticsGet } from '../CreativeAnalytics/contexts';
import { useAdditionalMetricsColumns } from '../CreativeAnalytics/PerformanceFrequency/hooks/useAdditionalMetricsColumns';
import { useCustomIntegratedMetricsColumns } from '../CreativeAnalytics/PerformanceFrequency/hooks/useCustomIntegratedMetricsColumns';
import { toCurrency, toCurrency_4, toFixed_2 } from '../../utils/numbers';
import { useCustomScoringColumns } from '../CreativeAnalytics/PerformanceFrequency/hooks/useCustomScoringColumns';
const _ = require('lodash');

const getColumns = ({
  isROAS,
  period2,
  isCompare,
  columnDropdownOptions,
  customColumnHeaders,
  customEventsHeaders,
  additionalMetricsColumns,
  customIntegratedMetricsColumns,
  customEvents,
  columnData,
  gaEnabled,
  isOrganicView,
  isEmailView,
  selectedColumns,
  customScoringData,
}) => {
  let result = [];

  const filterCols = (fn) => {
    const gaCols = ['ga_aggregate_ctr', 'ga_average_ctr', 'ga_median_ctr'];

    let customEventsCols = customEvents
      ? customEvents.map((event) => {
          return [
            'aggregate_' + event.calculation,
            'average_' + event.calculation,
            'median_' + event.calculation,
          ];
        })
      : [];
    customEventsCols = _.flatten(customEventsCols);

    if (!gaEnabled) {
      return columnData
        .filter(fn)
        .filter(({ accessor }) => !gaCols.includes(accessor));
    } else {
      return columnData.filter(fn);
    }
  };

  if (isROAS) {
    if (period2) {
      if (isCompare) {
        result = filterCols(dropCPAColumnsP2);
      } else if (!isCompare) {
        result = filterCols(dropCPAColumnsP1);
      }
    } else {
      result = filterCols(dropCPAColumns);
    }
  } else {
    if (period2) {
      if (isCompare) {
        result = filterCols(dropROASColumnsP2);
      } else if (!isCompare) {
        result = filterCols(dropROASColumnsP1);
      }
    } else {
      result = filterCols(dropROASColumns);
    }
  }

  let columnOrder = columnDropdownOptions
    .map((x) => x.value)
    .filter((x) => x !== 'visuals' && x !== 'planning');

  columnOrder = ['name', ...columnOrder, 'visuals', 'planning', 'options'];

  const customScoringColumns =
    customScoringData && customScoringData.length
      ? useCustomScoringColumns()
      : [];
      
  const combined = [
    ...result,
    ...customScoringColumns,
    ...customColumnHeaders,
    ...customEventsHeaders,
    ...additionalMetricsColumns,
    ...customIntegratedMetricsColumns,
  ].filter(
    (v, i, a) =>
      // remove duplicates
      a.findIndex(
        (v2) => v2.accessor.toLowerCase() === v.accessor.toLowerCase()
      ) === i
  );

  if (isOrganicView) {
    result = combined.filter(dropNonOrganicColumns);
  } else if (isEmailView) {
    result = combined.filter(dropNonEmailColumns);
  } else {
    result = combined;
  }

  const sorted = result.sort(
    (a, b) => columnOrder.indexOf(a.accessor) - columnOrder.indexOf(b.accessor)
  );

  return sorted.filter(
    (col) => col.accessor === 'name' || selectedColumns.includes(col.accessor)
  );
};

const getAdditionalMetricColumns = (additional_metrics) => {
  if (!additional_metrics) return [];

  const additionalMetricsColumns = additional_metrics.map((metric) => {
    return [
      {
        Header: `Total ${metric.name}`,
        accessor: metric.value,
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          const value = original[metric.value];
          if (!value) return <p>N/A</p>;
          return <p>{value.toFixed(2)}</p>;
        },
      },
      {
        Header: `Cost Per ${metric.name}`,
        accessor: `cp_${metric.value}`,
        disableFilters: true,
        minWidth: 200,
        Cell: ({ row: { original } }) => {
          const value = original[`cp_${metric.value}`];
          if (!value) return <p>N/A</p>;
          return <p>{toCurrency_4(value)}</p>;
        },
      },
      {
        Header: `Median ${metric.name}`,
        accessor: `median_${metric.value}`,
        disableFilters: true,
        minWidth: 200,
        Cell: ({ row: { original } }) => {
          const value = original[`median_${metric.value}`];
          if (!value) return <p>N/A</p>;
          return <p>{toFixed_2(value)}</p>;
        },
      },
      {
        Header: `Median Cost Per ${metric.name}`,
        accessor: `median_cp_${metric.value}`,
        disableFilters: true,
        minWidth: 200,
        Cell: ({ row: { original } }) => {
          const value = original[`median_cp_${metric.value}`];
          if (!value) return <p>N/A</p>;
          return <p>{toCurrency_4(value)}</p>;
        },
      },
    ];
  });

  return flatten(additionalMetricsColumns);
};

export const getCustomIntegratedMetricsColumns = (customIntegratedMetrics) => {
  if (!customIntegratedMetrics) return [];

  const customIntegratedMetricsColumns = customIntegratedMetrics.map(
    (metric) => {
      return [
        {
          Header: `Total ${metric.label}`,
          accessor: metric.value,
          disableFilters: true,
          Cell: ({ row: { original } }) => {
            const value = original[metric.value];
            if (!value) return <p>N/A</p>;
            return <p>{value.toFixed(2)}</p>;
          },
        },
        {
          Header: `Cost Per ${metric.label}`,
          accessor: `cp_${metric.value}`,
          disableFilters: true,
          minWidth: 200,
          Cell: ({ row: { original } }) => {
            const value = original[`cp_${metric.value}`];
            if (!value) return <p>N/A</p>;
            return <p>{toCurrency(value)}</p>;
          },
        },
        {
          Header: `Median Cost Per ${metric.label}`,
          accessor: `median_cp_${metric.value}`,
          disableFilters: true,
          minWidth: 200,
          Cell: ({ row: { original } }) => {
            const value = original[`median_cp_${metric.value}`];
            if (!value) return <p>N/A</p>;
            return <p>{toCurrency(value)}</p>;
          },
        },
      ];
    }
  );

  return flatten(customIntegratedMetricsColumns);
};

export const useFilteredColumns = (isCompare = false, selectedColumns) => {
  const {
    columnDropdownOptions,
    isDirectMail,
    period2,
    isROAS,
    isOrganicView,
    isEmailView,
    ga_enabled: gaEnabled,
    customEvents,
    additional_metrics,
    customIntegratedMetrics,
    columnOptions,
    customScoringData,
  } = useCreativeAnalyticsGet();
  const columnData = useColumns();
  const customColumnHeaders = useCustomColumnHeaders(isDirectMail);
  const customEventsHeaders = useCustomEventsHeaders(customEvents);
  const additionalMetricsColumns =
    getAdditionalMetricColumns(additional_metrics);

  const customIntegratedMetricsColumns = getCustomIntegratedMetricsColumns(
    customIntegratedMetrics
  );

  const columns = useMemo(() => {
    return getColumns({
      isROAS,
      period2,
      isCompare,
      columnDropdownOptions: columnOptions,
      customColumnHeaders,
      customEventsHeaders,
      additionalMetricsColumns,
      customIntegratedMetricsColumns,
      customEvents,
      columnData,
      gaEnabled,
      isOrganicView,
      isEmailView,
      selectedColumns,
      customScoringData,
    });
  }, [
    isROAS,
    period2,
    isCompare,
    columnDropdownOptions,
    customColumnHeaders,
    customEventsHeaders,
    additionalMetricsColumns,
    customIntegratedMetricsColumns,
    customEvents,
    columnData,
    gaEnabled,
    isOrganicView,
    isEmailView,
    selectedColumns,
    customScoringData,
  ]);

  return columns;
};
