import React, { useMemo } from 'react';
import { useCreativeAnalyticsStore, useViewSettings } from '../../selectors';
import { useCreativeAnalyticsGet } from '../CreativeAnalytics/contexts';
import { getCustomEventsOpts } from './utils/getCustomEventsCols';
import { nonOrganicCols } from '../CreativeAnalytics/PerformanceFrequency/Columns';
import { capitalizeFirst } from '../CreativeAnalytics/ReportCard/utils';
import {
  NOOM_CUSTOM_METRICS,
  SKY_ZONE_CUSTOM_METRICS,
} from '../CreativeAnalytics/PerformanceFrequency/constants';
import { useSelector } from 'react-redux';

export const useDNAColumnOptions = ({
  isROAS,
  customEvents = [],
  clientMetrics = [],
  selectedTab,
}) => {
  const { dnaReportData } = useCreativeAnalyticsStore();
  const { additional_metrics, isOrganicView, account_id, isWisdomPanel } =
    useViewSettings();
  const isGoHealth = account_id === 'act_637485863314808';
  const { isSkyZone, isNoom } = useSelector(
    ({ ViewSettings: { isSkyZone, isNoom } }) => ({
      isSkyZone,
      isNoom,
    })
  );

  const isWpPro =
    isWisdomPanel && selectedTab?.tab_name.includes('Prospecting');
  const isWpRtg =
    isWisdomPanel && selectedTab?.tab_name.includes('Retargeting');

  const wisdomPanelOpts =
    isWpPro || isWpRtg
      ? [
          {
            label: `WP ${isWpPro ? 'PRO' : 'RTG'} ROAS`,
            value: `WP ${isWpPro ? 'PRO' : 'RTG'} ROAS`,
            alwaysShow: true,
          },
        ]
      : [];

  const skyZoneOpts = SKY_ZONE_CUSTOM_METRICS.map((metric) => ({
    label: metric,
    value: metric,
    alwaysShow: true,
  }));

  const customEventsOpts = useMemo(
    () => getCustomEventsOpts(customEvents),
    [customEvents]
  );

  const metadataOpts = useMemo(() => {
    if (!dnaReportData) return [];

    try {
      return dnaReportData.categories.map(({ category_name }) => ({
        label: category_name,
        value: category_name,
        isMetadata: true,
      }));
    } catch (e) {
      console.error('Error processing metadata options:', e);
      return [];
    }
  }, [dnaReportData]);

  const additionalMetricsOpts = useMemo(() => {
    if (!additional_metrics || isGoHealth)
      return isSkyZone ? [...skyZoneOpts] : [];

    let result = additional_metrics.map((metric) => ({
      label: metric.name,
      value: metric.value,
    }));

    if (!isOrganicView) {
      result = [
        ...result,
        ...additional_metrics.map((metric) => ({
          label: `Cost per ${metric.name}`,
          value: `cp_${metric.value}`,
        })),
      ];
    }

    return isSkyZone
      ? [...result, ...skyZoneOpts]
      : isWisdomPanel
      ? [...result, ...wisdomPanelOpts]
      : result;
  }, [additional_metrics, isSkyZone, isOrganicView]);

  const clientMetricOpts =
    useMemo(() => {
      if (isNoom) {
        return NOOM_CUSTOM_METRICS.map((metric) => ({
          label: metric,
          value: metric,
          alwaysShow: true,
        }));
      }

      return clientMetrics?.reduce((opts, metric) => {
        try {
          if (isGoHealth && metric.includes('OppAttempted')) {
            opts.push({
              label: `Cost per Opp Attempted`,
              value: `cp_${metric}`,
            });
          } else {
            opts.push({
              label: capitalizeFirst(metric.replace('_', ' ')),
              value: metric,
            });

            if (!isOrganicView) {
              opts.push({
                label: `Cost per ${capitalizeFirst(metric.replace('_', ' '))}`,
                value: `cp_${metric}`,
              });
            }
          }
        } catch (e) {
          console.error(e);
        }

        return opts;
      }, []);
    }, []) ?? [];

  const cols = [
    {
      label: 'Visual',
      value: 'id',
      isMetadata: true,
    },
    {
      label: isROAS ? 'ROAS' : 'CPA',
      value: isROAS ? 'roas' : 'cpa',
    },
    {
      label: 'CPM',
      value: 'cpm',
    },
    {
      label: 'CPC',
      value: 'cpc',
    },
    {
      label: 'CTR',
      value: 'ctr',
    },
    ...(!isOrganicView
      ? [
          {
            label: 'Spend',
            value: 'spend',
          },
          {
            label: 'Impressions',
            value: 'impressions',
          },
          {
            label: 'Spend Quintile',
            value: 'spend_quintile',
          },
          {
            label: 'Fitness Score',
            value: 'fitness_score',
          },
        ]
      : []),

    {
      label: 'Results',
      value: 'results',
    },
    {
      label: 'clicks',
      value: 'clicks',
    },
    {
      label: 'Custom Performance Score',
      value: 'custom_performance_score',
    },
    {
      label: 'Manual Tags',
      value: 'manual_tags',
      isMetadata: true,
    },

    {
      label: 'Performance Quintile',
      value: 'primary_metric_quintile',
    },

    ...clientMetricOpts,
    ...additionalMetricsOpts,
    ...customEventsOpts,
    ...metadataOpts,
  ];

  const result = useMemo(() => {
    const seen = new Set();

    const uniqueCols = cols
      .filter(({ value }) => {
        if (seen.has(value)) return false;
        seen.add(value);
        return true;
      })
      .map(({ label, value }) => {
        if (label === 'cp_Signups') return { label: 'Cost per Signup', value };
        return {
          label: label.replace('cp_', 'Cost per '),
          value,
        };
      });

    return uniqueCols.filter(
      ({ value, shouldShow }) =>
        !isOrganicView || !nonOrganicCols.some((v) => value.includes(v))
    );
  }, [cols, isOrganicView]);

  return result;
};
