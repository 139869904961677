import { toCurrency } from '../../../utils/numbers';

export const format$ = (n) => {
  if (typeof n !== 'string') {
    return '-';
  }
  const forParsing = n.replace('$', '');
  const parsed = parseFloat(forParsing);

  return toCurrency(parsed);
};

export const handleTruncate = ( str, maxLength ) => {
  const ellipsis = "..."

  if (str.length > maxLength) {
    return `${str.slice(0, maxLength)}${ellipsis}`;
  }
  return str;
}
