/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from './constants';
import './styles.scss';
import { Loader } from './loader';
import Spinner from 'react-bootstrap/Spinner';
import { StatusIcon } from './StatusIcon';

export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  appearance?: string;
  size?: string;
  fullWidth?: boolean;
  loading: boolean;
  status?: 'success' | 'loading' | 'error';
}

function Button({
  appearance,
  className,
  active,
  style,
  id,
  size,
  fullWidth,
  children,
  onClick,
  disabled,
  loading = false,
  status = null,
  ...restProps
}: ButtonProps) {
  const baseClass = classNames.base;
  const appearanceClass = classNames.appearanceClasses[appearance] || 'primary';
  const sizeClass = classNames.sizeClasses[size] || '';
  const disabledClass = classNames.disabled;
  const loadingClass = classNames.loading;

  const handleClick = onClick;

  return (
    <button
      {...restProps}
      type="button"
      onClick={handleClick}
      disabled={disabled || loading}
      id={id}
      style={style}
      className={[
        baseClass,
        className && className,
        appearance && appearanceClass,
        size && sizeClass,
        disabled && disabledClass,
        loading && loadingClass,
        fullWidth && 'w-100',
        active && 'active',
      ].join(' ')}
    >
      {!!status && <StatusIcon status={status} />}
      {loading ? (
        <>
          <Spinner
            style={{ position: 'absolute' }}
            size="sm"
            animation="border"
          />
          <span>{children}</span>
        </>
      ) : (
        children
      )}
    </button>
  );
}

export default Button;

Button.defaultProps = {
  appearance: 'primary',
  className: null,
  id: null,
  size: null,
  fullWidth: null,
  children: null,
  onClick: null,
  disabled: false,
};

Button.propTypes = {
  appearance: PropTypes.node,
  className: PropTypes.node,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
