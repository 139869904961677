/**
 * @param {number} len Array.length
 * @param {number} index Current array index
 * @param {number} offset Offset from current index
 *
 * @return {number} New array index
 */
export function handleIndex(len, index, offset) {
  const newIndex = index + offset;

  if (newIndex < 0) {
    return len + offset;
  }
  if (newIndex >= len) {
    return 0;
  }
  return newIndex;
}
