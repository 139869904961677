import { DEFAULT_PROMO_TERMS } from "./constants";
import { labelsAreMatching } from "./utils";

export const SET_LABEL_DATA = "set-label-data";

export const ADD_BRAND = "add-brand";
export const REMOVE_BRAND = "remove-brand";

export const ADD_PROMO = "add-promo";
export const REMOVE_PROMO = "remove-promo";

export const ADD_KEYWORD = "add-keyword";
export const REMOVE_KEYWORD = "remove-keyword";

export function labelSetupReducer(state, action) {
  switch (action.type) {
    case SET_LABEL_DATA:
      return {
        ...state,
        activeBrands: action.activeBrands,
        inactiveBrands: action.inactiveBrands,
        activePromos: action.activePromos,
        inactivePromos: action.inactivePromos,
        existingKeywords: action.existingKeywords,
        activeKeywords: action.activeKeywords,
        inactiveKeywords: action.inactiveKeywords,
        suggestedKeywords: action.suggestedKeywords,
      };

    case ADD_BRAND:
      return {
        ...state,
        activeBrands: [...state.activeBrands, action.label],
        inactiveBrands: state.inactiveBrands.includes(action.label)
          ? state.inactiveBrands.filter(
              (label) => !labelsAreMatching(label, action.label)
            )
          : state.inactiveBrands,
      };

    case REMOVE_BRAND:
      return {
        ...state,
        activeBrands: state.activeBrands.filter(
          (label) => !labelsAreMatching(label, action.label)
        ),
        inactiveBrands: [...state.inactiveBrands, action.label]
      };

    case ADD_PROMO:
      return {
        ...state,
        activePromos: [...state.activePromos, action.label],
        inactivePromos: state.inactivePromos.includes(action.label)
          ? state.inactivePromos.filter(
              (label) => !labelsAreMatching(label, action.label)
            )
          : state.inactivePromos,
      };

    case REMOVE_PROMO:
      return {
        ...state,
        activePromos: state.activePromos.filter(
          (label) => !labelsAreMatching(label, action.label)
        ),
        inactivePromos: [...state.inactivePromos, action.label]
      };

    case ADD_KEYWORD:
      return {
        ...state,
        activeKeywords: [...state.activeKeywords, action.label],
        inactiveKeywords:state.inactiveKeywords.filter(
              (label) => !labelsAreMatching(label, action.label)
            )
      };

    case REMOVE_KEYWORD:
      return {
        ...state,
        activeKeywords: state.activeKeywords.filter(
          (label) => !labelsAreMatching(label, action.label)
        ),
        inactiveKeywords: [...state.inactiveKeywords, action.label],
      };
  }
}
