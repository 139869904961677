import { nodeStyle } from '../constants';
import { createRuleNodesAndEdges } from './createRuleNodesAndEdges';

export const createNodesAndEdges = ({
  category_name,
  derived_labels,
  width,
}) => {
  const nodes: [] = [];
  const edges: [] = [];

  derived_labels.forEach((derivedLabel, index) => {
    const derivedLabelId = `derived_label_${index}`;
    const prev = index > 0 ? derived_labels[index - 1] : null;
    const previousAmountOfRuleLabels = getHighestRuleLabelsLength(prev?.rules);

    const baseYPos = 200;
    const scaleByDerivedLabelIndex = index * 350;
    const adjustForRuleLabels = previousAmountOfRuleLabels * 80;

    nodes.push({
      id: derivedLabelId,
      data: { label: derivedLabel.label_name },
      position: {
        x: 380,
        y: scaleByDerivedLabelIndex + adjustForRuleLabels + baseYPos,
      },
      type: 'output',
      style: {
        ...nodeStyle,
      },
    });

    createRuleNodesAndEdges(
      nodes,
      edges,
      derivedLabelId,
      'include',
      derivedLabel.rules.flatMap((r) => r.labels_to_include)
    );
    createRuleNodesAndEdges(
      nodes,
      edges,
      derivedLabelId,
      'exclude',
      derivedLabel.rules.flatMap((r) => r.labels_to_exclude)
    );
  });
  return { nodes, edges };
};

const getHighestRuleLabelsLength = (rules) => {
  if (!rules) return 0;
  let include = 0;
  let exclude = 0;

  rules.forEach((rule) => {
    include += rule.labels_to_include.length;
    exclude += rule.labels_to_exclude.length;
  });

  return Math.max(include, exclude);
};
