import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { handleTestName, NameTooltip } from '../CreativeReports/BlockReport';

const StatSummary = ({ show, test, test_block, compare_block, stats, conclusion, reason, loading }) => {

  const [testAvgCpa, setTestAvgCpa] = useState([]);
  const [compareAvgCpa, setCompareAvgCpa] = useState([]);
  const [testConfidence, setTestConfidence] = useState([]);
  const [testOutcomes, setTestOutcomes] = useState([]);
  const [compareConfidence, setCompareConfidence] = useState([]);
  const [compareOutcomes, setCompareOutcomes] = useState([]);
  const [outcomesTick, setOutcomesTick] = useState();
  const [confidenceTick, setConfidenceTick] = useState();

  const [bigArray, setBigArray] = useState([]);

  const error_reason = stats.conclusion_error_reason;

  const winning_data = stats.winning_block_data;
  const winning_name = stats.winning_block_data.block_name;
  const winning_est_cpa = stats.winning_block_data.estimated_cpa;
  const winning_high_cpa = stats.winning_block_data.high_cpa;
  const winning_low_cpa = stats.winning_block_data.low_cpa;

  const losing_name = stats.losing_block_data.block_name;
  const losing_est_cpa = stats.losing_block_data.estimated_cpa;
  const losing_high_cpa = stats.losing_block_data.high_cpa;
  const losing_low_cpa = stats.losing_block_data.low_cpa;

  const test_name = stats.test_block.block_name;
  const test_confidence = stats.test_block.confidence_interval_array;
  const test_outcomes = stats.test_block.outcomes_over_time_array;
  const test_cpa = stats.test_block.account_avg_cpa;

  const compare_name = stats.compare_block.block_name;
  const compare_confidence = stats.compare_block.confidence_interval_array;
  const compare_outcomes = stats.compare_block.outcomes_over_time_array;
  const compare_cpa = stats.compare_block.account_avg_cpa;

  useEffect(() => {
      if (!!test_outcomes) {
        let test_avg_cpa = [];
        let test_confidence_filtered = [];
        let test_outcomes_filtered = [];

        while (test_avg_cpa.length < test_outcomes.length) {
          test_avg_cpa.push(test_cpa);
        }
        setTestAvgCpa(test_avg_cpa);

        // remove zeros from array
        // so they do not display in chart
        test_confidence_filtered = test_confidence.filter(cv => cv != 0);
        setTestConfidence(test_confidence_filtered);
        console.log(testConfidence);
        console.log('test block: ' + test_name + ' / Confidence length: ' + testConfidence.length);

        test_outcomes_filtered = test_outcomes.filter(cv => cv != 0);
        setTestOutcomes(test_outcomes_filtered);
        console.log(testOutcomes);
        console.log('test block: ' + test_name + ' / Outcomes length: ' + testOutcomes.length);
      } else {
        setTestConfidence([]);
        setTestOutcomes([]);
        console.log('test block has been reset');
      }

      if (!!compare_outcomes) {
        let compare_avg_cpa = [];
        let compare_confidence_filtered = [];
        let compare_outcomes_filtered = [];

        // while (compare_avg_cpa.length < compare_outcomes.length) {
        //   compare_avg_cpa.push(compare_cpa);
        // }
        // setCompareAvgCpa(compare_avg_cpa);

        compare_confidence_filtered = compare_confidence.filter(cv => cv != 0);
        setCompareConfidence(compare_confidence_filtered);
        console.log(compareConfidence);
        console.log('compare block: ' + compare_name + ' / Confidence length: ' + compareConfidence.length);

        compare_outcomes_filtered = compare_outcomes.filter(cv => cv != 0);
        setCompareOutcomes(compare_outcomes_filtered);
        console.log(compareOutcomes);
        console.log('compare block: ' + compare_name + ' / Outcomes length: ' + compareOutcomes.length);
      } else {
        setCompareConfidence([]);
        setCompareOutcomes([]);
        console.log('compare block has been reset');
      }
    }, [stats]);

  // for testing avg cpa chart line
  // const test_array = [11, 11, 11, 11, 11, 11];
  // const test_array_2 = [12, 12, 12, 12, 12, 12];

  // for testing big datasets
  // let big_array = Array.from({
  //   length: 400
  // }, () => (30));
  // console.log(big_array);

  useEffect(() => {
    (testConfidence.length >= 400 || compareConfidence.length >= 400) ?
    setConfidenceTick(25) :
    (testConfidence.length >= 200 || compareConfidence.length >= 200) ?
    setConfidenceTick(20) :
    (testConfidence.length >= 100 || compareConfidence.length >= 100) ?
    setConfidenceTick(10) :
    setConfidenceTick(6)
  }, [testConfidence.length, compareConfidence.length]);

  useEffect(() => {
    (testOutcomes.length >= 400 || compareOutcomes.length >= 400) ?
    setOutcomesTick(25) :
    (testOutcomes.length >= 200 || compareOutcomes.length >= 200) ?
    setOutcomesTick(20) :
    (testOutcomes.length >= 100 || compareOutcomes.length >= 100) ?
    setOutcomesTick(10) :
    setOutcomesTick(1)
  }, [testOutcomes.length, compareOutcomes.length]);

  // confidence intervals chart
  const options_confidence = {
    chart: {
      height: 280,
      type: 'spline',
      style: {
        fontFamily: 'Poppins'
      }
    },

    title: {
      text: ''
    },

    legend: {
      enabled: false,
    },

    exporting: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },

    tooltip: {
      enabled: false,
    },

    animation: {
      duration: 1000,
    },

    xAxis: {
      visible: true,
      title: {
        text: '# Outcomes',
        margin: 15,
        style: {
          color: '#808BA6', // darwin-navy-4
          fontSize: '1.1rem',
          fontWeight: 500
        }
      },
      labels: {
        enabled: true,
        style: {
          color: '#808BA6',
        },
      },
      maxPadding: 0.05,
      min: 6,
      startOnTick: false,
      tickInterval: confidenceTick,
    },

    yAxis: [{ // test block axis
      visible: true,
      title: {
        text: 'CI',
        style: {
          color:
          (error_reason === null) ?
          (test_name === winning_name) ?
          '#3DD394' :
          '#FFA4A4' :
          '#888',
          fontSize: '1.1rem',
          fontWeight: 500
        }
      },
      labels: {
        enabled: true,
        style: {
          color: '#808BA6',
        },
      },
      lineWidth: 2
    }, { // compare block axis
      className:
        (error_reason === null) ?
        (compare_name === losing_name) ?
        'loser' :
        'winner' :
        'inconclusive--orange',
      title: {
        text: 'CI',
        style: {
          color:
          (error_reason === null) ?
          (compare_name === losing_name) ?
          '#FFA4A4' :
          '#3DD394' :
          '#f0ad4e',
          fontSize: '1.1rem',
          fontWeight: 500
        }
      },
      labels: {
        enabled: true,
        style: {
          color: '#808BA6',
        },
      },
      lineWidth: 2,
      opposite: true
    }],

    plotOptions: {
      series: {
        enableMouseTracking: false,
        lineWidth: 3.5
      },
      spline: {
        marker: {
          enabled: false,
        }
      }
    },

    series: [{
        name: (testConfidence.length > 6) ? test_name : '',
        data: testConfidence,
        // data: big_array,
        color:
        // keep winning/losing block colors
        // consistent in the charts
        (error_reason === null) ?
        (test_name === winning_name) ?
        '#3DD394' :
        '#FFA4A4' :
        '#888',
        yAxis: 1
      }, {
        name: (compareConfidence.length > 6) ? compare_name : '',
        data: compareConfidence,
        color:
        (error_reason === null) ?
        (compare_name === losing_name) ?
        '#FFA4A4' :
        '#3DD394' :
        '#f0ad4e',
      }],

      legend: {
        enabled: true,
        align: 'center',
        verticalAlign: 'bottom',
        x: 20,
        y: 10,
        symbolHeight: 18,
        symbolWidth: 6,
        symbolPadding: 8,
        itemStyle: {
          color: '#808BA6',
          fontWeight: '300',
          fontSize: '12px',
        }
      }
  };

  // outcomes over time chart
  const options_outcomes = {
    chart: {
      height: 280,
      type: 'spline',
      style: {
        fontFamily: 'Poppins'
      }
    },

    title: {
      text: ''
    },

    legend: {
      enabled: false,
    },

    exporting: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },

    tooltip: {
      enabled: false,
    },

    animation: {
      duration: 1000,
    },

    xAxis: {
      visible: true,
      title: {
        text: '# Outcomes',
        margin: 15,
        style: {
          color: '#808BA6',
          fontSize: '1.1rem',
          fontWeight: 500
        }
      },
      labels: {
        enabled: true,
        style: {
          color: '#808BA6',
        },
      },
      maxPadding: 0.05,
      min: 1,
      startOnTick: false,
      tickInterval: outcomesTick,
    },

    yAxis: {
      visible: true,
      title: {
        text: 'CPA',
        style: {
          color: '#808BA6',
          fontSize: '1.1rem',
          fontWeight: 500
        }
      },
      labels: {
        enabled: true,
        style: {
          color: '#808BA6',
        },
      },
      lineWidth: 2
    },

    plotOptions: {
      series: {
        enableMouseTracking: false,
        lineWidth: 3.5
      },
      spline: {
        marker: {
          enabled: false,
        }
      }
    },

    series: [{
        name: (testOutcomes.length > 1) ? test_name : '',
        data: testOutcomes,
        // data: big_array,
        color:
        // keep winning/losing block colors
        // consistent in the charts
        (error_reason === null) ?
        (test_name === winning_name) ?
        '#3DD394' :
        '#FFA4A4' :
        '#888',
      }, {
        name: (compareOutcomes.length > 1) ? compare_name : '',
        data: compareOutcomes,
        color:
        (error_reason === null) ?
        (compare_name === losing_name) ?
        '#FFA4A4' :
        '#3DD394' :
        '#f0ad4e',
      }, {
        name: test_name + ' Account Average CPA',
        // data: testAvgCpa,
        // data: test_array,
        color: '#ccc',
        type: 'line',
        dashStyle: 'dash',
        marker: {
          enabled: false,
        },
        showInLegend: false,
        states: {
          hover: {
            enabled: false
          }
        },
        enableMouseTracking: false
      }, {
        name: compare_name + ' Account Avg CPA',
        // data: compareAvgCpa,
        //// commenting out until this part of api
        //// is ready for testing
        // data: test_array_2,
        color: '#ccc',
        type: 'line',
        dashStyle: 'dash',
        marker: {
          enabled: false,
        },
        showInLegend: false,
        states: {
          hover: {
            enabled: false
          }
        },
        enableMouseTracking: false
      }],

      legend: {
        enabled: true,
        align: 'center',
        verticalAlign: 'bottom',
        x: 20,
        y: 10,
        symbolHeight: 18,
        symbolWidth: 6,
        symbolPadding: 8,
        itemStyle: {
          color: '#808BA6',
          fontWeight: '300',
          fontSize: '12px',
        },
      }
  };

  const maxLength = 34;

  return show ? (
  (!!stats && !!winning_data && !loading) ?
    ( <div className="stats-summary">
        <div className="conclusion-wrapper">
        {
          (error_reason === null)
          ? <div className="conclusion">
              <span className="block-name">
                {winning_name.length > 28
                  ? <NameTooltip text={winning_name} />
                  : null
                }
                <h2 className="winner">
                  {handleTestName(winning_name, maxLength)}
                </h2>
              </span>
              <br />
              <h2>is better than</h2>
              <br />
              <div className="block-name">
                {losing_name.length > 28
                  ? <NameTooltip text={losing_name} />
                  : null
                }
                <h2 className="loser">
                  {handleTestName(losing_name, maxLength)}
                </h2>
              </div>
            </div>
          : (error_reason === "not_enough_data")
          ? <div>
              <h2 className="conclusion error">Expected CPA: Not Enough Data</h2>
              <p>There is not enough data to make any conclusions about the test at this time. There needs to be a minimum of 40 outcomes between test and control over at least 2 days to be able to return actionable data.</p>
            </div>
          : (error_reason === "variance_too_large")
          ? <div>
              <h2 className="conclusion error">Expected CPA: Inconclusive</h2>
              <p>There is too much variation in the data set to make any sort of confident prediction on how future performance might hold.</p>
            </div>
          : (error_reason === "cpa_too_close")
          ? <div>
              <h2 className="conclusion error">Conclusion: Too Close to Call</h2>
              <p>The CPA values are too close together to give 80% confidence that one is better than the other.</p>
            </div>
          : (error_reason === "inconsistent_data")
          ? <div>
              <h2 className="conclusion error">Expected CPA: Inconsistent Data</h2>
              <p>The confidence intervals are not decreasing over time. This signals that there is no consistency in the dataset, therefore we cannot make any predictions about future performance.</p>
            </div>
          : <div></div>
        }
        </div>
        { // hide this section if there is a conclusion error
        (error_reason === null)
        ? <div>
            <h3>Statistical Summary</h3>
            <p>Provided all variables stay the same, there is a <u><b>greater than 80% chance</b></u> that&nbsp;
            <span className="block-name sm">
              {winning_name.length > maxLength
                ? <NameTooltip text={winning_name} />
                : null
              }
              <span className="winner">
                {handleTestName(winning_name, maxLength)}
              </span>
            </span>
            &nbsp;will continue to outperform&nbsp;
            <span className="block-name sm">
              {losing_name.length > maxLength
                ? <NameTooltip text={losing_name} />
                : null
              }
              <span className="loser">
                {handleTestName(losing_name, maxLength)}
              </span>
            </span>
            .</p>
            <table className="cpa-table">
              <thead>
                <tr>
                  <th></th>
                  <th>Estimated CPA</th>
                  <th>High CPA</th>
                  <th>Low CPA</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span className="block-name sm">
                      {winning_name.length > maxLength
                        ? <NameTooltip text={winning_name} />
                        : null
                      }
                      <span className="winner">
                        {handleTestName(winning_name, maxLength)}
                      </span>
                    </span>
                  </td>
                  <td><span className="winning-cpa">${winning_est_cpa.toFixed(2)}</span></td>
                  <td>${winning_high_cpa.toFixed(2)}</td>
                  <td>${winning_low_cpa.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>
                    <span className="block-name sm">
                      {losing_name.length > maxLength
                        ? <NameTooltip text={losing_name} />
                        : null
                      }
                      <span className="loser">
                        {handleTestName(losing_name, maxLength)}
                      </span>
                    </span>
                  </td>
                  <td><span className="losing-cpa">${losing_est_cpa.toFixed(2)}</span></td>
                  <td>${losing_high_cpa.toFixed(2)}</td>
                  <td>${losing_low_cpa.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          : <div></div>
          }
          {/* hide charts if no data is returned */}
          {(testOutcomes.length > 1 || compareOutcomes.length > 1)
            ? <div className="outcomes pt-3">
              <h3>Outcomes Over Time</h3>
              <p>As you get more outcomes, you want the data to centralize on a consistent value.</p>
              <div className="highcharts-figure">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={options_outcomes}
                />
              </div>
            </div>
            : <div></div>
          }
          {(testConfidence.length > 6 || compareConfidence.length > 6)
            ? <div className="confidence-intervals pt-4">
                <h3>Confidence Intervals</h3>
                <p>If the Confidence Interval keeps getting smaller, it signals that the data is consistent.</p>
                <div className="highcharts-figure">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={options_confidence}
                  />
                </div>
              </div>
            : <div></div>
          }
        </div> )
    : (<div></div>)
  ) : null;
}

export default StatSummary;
