import React from 'react';
import Tooltip from '../../../components/Tooltip';

const WizardToggle = ({ setShowWizard }) => {
  return (
    <Tooltip content="Wizard">
      <div
        className="wizard-toggle"
        onClick={() => setShowWizard((prev) => !prev)}
      >
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.6731 21.5586H1.1314C0.716252 21.5586 0.380005 21.8948 0.380005 22.31V23.8128C0.380005 24.2279 0.716252 24.5641 1.1314 24.5641H23.6731C24.0883 24.5641 24.4245 24.2279 24.4245 23.8128V22.31C24.4245 21.8948 24.0883 21.5586 23.6731 21.5586ZM9.39669 18.553L6.39113 17.0502L9.39669 15.5475L10.8995 12.5419L12.4023 15.5475L15.4078 17.0502L12.4023 18.553L11.6509 20.0558H21.4189L17.361 10.5869C17.0708 9.91032 17.0392 9.15077 17.2722 8.45246L19.9162 0.519653L11.107 5.5535C9.99577 6.18851 9.11622 7.16168 8.59646 8.3313L3.38557 20.0558H10.1481L9.39669 18.553ZM12.4023 8.03356L13.1536 6.53078L13.905 8.03356L15.4078 8.78495L13.905 9.53634L13.1536 11.0391L12.4023 9.53634L10.8995 8.78495L12.4023 8.03356Z"
            fill="#1E2B4E"
          />
        </svg>
      </div>
    </Tooltip>
  );
};

export default WizardToggle;
