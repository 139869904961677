import React from 'react';

import connectTo from './Container';
import ResetPassword from './ResetPassword';
import Boundary from './ResetPasswordBoundary';

const Comp = ({ fetchReset, isLoading, hasError }) => (
  <Boundary isLoading={isLoading} hasError={hasError}>
    <ResetPassword fetchReset={fetchReset} />
  </Boundary>
);

export default connectTo(Comp);
