import { useSelector } from 'react-redux';

import { ADNAME_LABELS } from '../../store/Lookups/adNames_Labels';

function setLabels(reportData, labels) {
  if (labels.length === 0) {
    return reportData;
  }
  const uniq = new Set(labels);

  return reportData.map(({ value, ...rest }) => ({
    ...rest,
    value,
    disabled: uniq.has(value),
  }));
}

function maybeLabels(lookup, adName) {
  if (!lookup) {
    return [];
  }
  return lookup[adName] || [];
}

export function useCustomLabels(adName) {
  return useSelector(
    ({
      Lookups,
      CreativeReports: {
        reports: { ad_labels },
      },
    }) => {
      if (!ad_labels) {
        return {
          data: [],
          isAvailable: false,
        };
      }
      const maybe = maybeLabels(Lookups[ADNAME_LABELS], adName);
      const withLabels = setLabels(ad_labels, maybe);

      return {
        data: withLabels,
        isAvailable: !!withLabels,
      };
    },
  );
}
