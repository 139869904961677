import React, { useEffect, useMemo, useState } from 'react';
import CustomDropdown from '../../../components/CustomDropdown';
import Button from '../../../components/Button';
import { getColumnDropdownOptions } from './Columns';
import { useAccountId, useActionName, useViewId } from '../selectors';
import Tooltip from '../../../components/Tooltip';
import { DATES } from './dates';
import subDays from 'date-fns/subDays';
import {
  darwinDateToDate,
  toDarwinDate,
  END,
} from '../../../utils/darwin-dates';
import DateSpan from '../../../components/DateSpanDropdown';
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from '../contexts';
import { useSelector, useDispatch, batch } from 'react-redux';
import {
  resetReports,
  resetPeriod2,
} from '../../../store/CreativeReports/actions';
import {
  TIP_TEXT,
  useClientMetricsTipText,
  useCustomEventsTipText,
  useCustomScoringTipText,
} from './constants';
import { useIsDirectMail, useGaEnabled, useCustomEvents } from '../selectors';
import { trackEvent } from '../../../store/UserActivity/actions';
import {
  CHANGE_DATES,
  CREATIVE_ANALYTICS,
  SET_COMPARISON_PERIOD,
} from '../../../store/UserActivity/constants';
import { useViewSettings } from '../../../selectors';
import { getMetricsWithData } from './utils';
import { useDarwinClientId, useUserId } from '../../CreativeStudio/selectors';
import { LOCKED_DATES } from '../constants';
import { customScoringMetrics } from '../hooks/useFilteredData/utils/getCustomScoringValues';
const _ = require('lodash');

export function ColumnPicker({ onChange, columns, clientMetrics }) {
  const {
    filteredData,
    isOrganicView,
    account_id,
    isEmailView,
    customScoringData,
  } = useCreativeAnalyticsGet();
  const [show, setShow] = useState(false);
  const actionName = useActionName();
  const isROAS = actionName.includes('value_');
  const text = TIP_TEXT;
  const clientMetricsTipText = useClientMetricsTipText(clientMetrics);
  const isDirectMail = useIsDirectMail();
  const gaEnabled = useGaEnabled();
  const customEvents = useCustomEvents();
  const { additional_metrics, customIntegratedMetrics } = useViewSettings();

  const options = useMemo(
    () =>
      getColumnDropdownOptions({
        isROAS,
        isDirectMail,
        clientMetrics,
        gaEnabled,
        customEvents,
        additional_metrics,
        customIntegratedMetrics,
        isOrganicView,
        account_id,
        isEmailView,
        customScoringData,
      }),
    [
      isROAS,
      isDirectMail,
      clientMetrics,
      gaEnabled,
      customEvents,
      additional_metrics,
      customIntegratedMetrics,
      isOrganicView,
      account_id,
      isEmailView,
      customScoringData,
    ]
  );

  const metricsWithData = useMemo(() => {
    return getMetricsWithData(filteredData, options);
  }, [filteredData, options]);

  const handleVisibility = (isOpen, meta) => {
    if (isOpen) {
      setShow(true);
    } else if (meta.source !== 'select') {
      setShow(false);
    }
  };

  const handleSelect = (i) => {
    const column = options[i].value;
    return onChange({ column, checked: !columns.includes(column) });
  };

  let clientMetricsLabels =
    isDirectMail && clientMetrics && clientMetrics.length > 0
      ? clientMetrics.map((metric) => {
          return [
            'Aggregate ' + metric,
            'Average ' + metric,
            'Median ' + metric,
          ];
        })
      : [];
  clientMetricsLabels = _.flatten(clientMetricsLabels);

  let customEventsLabels = customEvents
    ? customEvents.map((event) => {
        return [
          'Aggregate ' + event.name,
          'Average ' + event.name,
          'Median ' + event.name,
        ];
      })
    : [];
  customEventsLabels = _.flatten(customEventsLabels);

  let customScoringLabels = customScoringMetrics.map(({ label }) => label);

  const handleContent = (label) => {
    const customEventsTipText = useCustomEventsTipText(
      customEvents,
      label.split(' ')[1]
    );

    const customScoringTipText = useCustomScoringTipText(
      customScoringLabels,
      label
    );

    switch (label) {
      case 'Aggregate CPA':
        return text.aggregate_cpa;
      case 'Aggregate ROAS':
        return text.aggregate_roas;
      case 'Average CPA':
        return text.average_cpa;
      case 'Average ROAS':
        return text.average_roas;
      case 'Median CPA':
        return text.median_cpa;
      case 'Median ROAS':
        return text.median_roas;
      case 'Aggregate CPM':
        return text.aggregate_cpm;
      case 'Average CPM':
        return text.average_cpm;
      case 'Median CPM':
        return text.median_cpm;
      case 'Aggregate CPC':
        return text.aggregate_cpc;
      case 'Average CPC':
        return text.average_cpc;
      case 'Median CPC':
        return text.median_cpc;
      case 'Aggregate CTR':
        return text.aggregate_ctr;
      case 'Average CTR':
        return text.average_ctr;
      case 'Median CTR':
        return text.median_ctr;
      case 'GA Aggregate CTR':
        return text.ga_aggregate_ctr;
      case 'GA Average CTR':
        return text.ga_average_ctr;
      case 'GA Median CTR':
        return text.ga_median_ctr;
      case '% Spend':
        return text.percent_budget;
      case 'Spend':
        return text.spend;
      case 'P-value':
        return text.p_value;
      case 'Darwin index':
        return text.darwin_index;
      case 'Win rate':
        return text.performance_percent;
      case 'Wins/All':
        return text.wins_over_all;
      case 'Visuals':
        return text.visuals;
      case 'Total Results':
        return text.total_results;
      case '% of Results':
        return text.percent_of_results;
      case '% of Results Difference':
        return text.percent_of_results_diff;
      case 'Total Link Clicks':
        return text.total_link_clicks;
      case '% of Link Clicks':
        return text.percent_of_link_clicks;
      case '% of Link Click Difference':
        return text.percent_of_link_clicks_diff;
      case '# Unique Creatives':
        return text.number_unique_creatives;
      case '% of Creative Count':
        return text.percent_of_creative_count;
      default:
        if (clientMetricsLabels.includes(label)) {
          return clientMetricsTipText[label] ? clientMetricsTipText[label] : '';
        }
        if (customEventsLabels.includes(label)) {
          return customEventsTipText[label];
        }
        if (customScoringLabels.includes(label)) {
          return customScoringTipText[label];
        }
    }
  };

  return (
    <CustomDropdown
      id="block-metric-select"
      toggleClass="dw-dropdown-toggle dw-dropdown-toggle--sm"
      label={
        <Button
          appearance="raised"
          style={{ height: '30px', padding: '0 6px' }}
        >
          <i
            style={{ color: '#07063E', fontSize: '1rem' }}
            className="fa-solid fa-columns mr-3"
          />
          Edit columns
        </Button>
      }
      onToggle={handleVisibility}
      onSelect={handleSelect}
      menuStyle={{ transform: 'translateX(-80px) !important' }}
      menuClass="analytics-columns-dropdown"
      toggleStyle={{ cursor: 'pointer', borderRadius: '5px' }}
      show={show}
    >
      {options.map(({ value, label, alwaysShow }, i) => {
        const hasValue = metricsWithData?.some((m) => m.value === value);

        return alwaysShow || hasValue ? (
          <>
            <CustomDropdown.Item key={value} eventKey={i} className="py-1">
              <div className="form-check d-flex align-items-center justify-content-left">
                <label
                  className="form-check-label"
                  style={{ cursor: 'pointer' }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name={value}
                    defaultChecked={columns.includes(value)}
                    style={{ cursor: 'pointer' }}
                  />
                  <span className="ml-1">{label}</span>
                </label>
                {handleContent(label) && (
                  <Tooltip content={handleContent(label)}>
                    <i class="fa-solid fa-circle-info ml-3"></i>
                  </Tooltip>
                )}
              </div>
            </CustomDropdown.Item>
          </>
        ) : null;
      })}
    </CustomDropdown>
  );
}

function setDateRanges(disableToday) {
  if (disableToday) {
    return [{ ...DATES[0], disabled: true }, ...DATES.slice(1)];
  }

  return DATES;
}

export function PeriodPicker({
  name,
  toggleClass,
  toggleStyle = {},
  disableToday,
}) {
  const params = useCreativeAnalyticsGet();
  const { setParams } = useCreativeAnalyticsSet();
  const viewId = useViewId();
  const accountId = useAccountId();
  const period = params[name];
  const defaultDates = params.defaultDates;
  const [startDate, endDate] = period.split(',').map(darwinDateToDate);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const newUrl = new URL(window.location.href);
  const args = new URLSearchParams(newUrl.search);

  useEffect(() => {
    if (period === '' && name === 'period1') {
      setParams({ [name]: defaultDates.map(toDarwinDate).join(',') });
    }
  }, []);

  const handleChange = (dates) => {
    setShow(false);

    if (name == 'period1') {
      dispatch(resetReports());
    } else {
      batch(() => {
        dispatch(trackEvent(CREATIVE_ANALYTICS, SET_COMPARISON_PERIOD));
        dispatch(resetPeriod2());
      });
    }

    if (!dates || dates === '') {
      return setParams({ [name]: '' });
    }
    const str = dates.map(toDarwinDate).join(',');

    dispatch(trackEvent(CREATIVE_ANALYTICS, CHANGE_DATES));
    return setParams({ [name]: str });
  };

  if (!period || period.length < 1) {
    return (
      <DateSpan
        startDate={new Date()}
        endDate={new Date()}
        handleChange={(dates) => handleChange(dates)}
        dateRanges={setDateRanges(disableToday)}
        allowNoSelection={name === 'period2'}
        toggleClass={toggleClass}
        toggleStyle={toggleStyle}
        noneSelected
        disable={shouldDisable(accountId, viewId)}
        {...{ show, setShow }}
      />
    );
  }
  return (
    <DateSpan
      startDate={startDate}
      endDate={endDate}
      handleChange={(dates) => handleChange(dates)}
      dateRanges={setDateRanges(disableToday)}
      allowNoSelection={name === 'period2'}
      toggleClass={toggleClass}
      toggleStyle={toggleStyle}
      disable={shouldDisable(accountId, viewId)}
      {...{ show, setShow }}
    />
  );
}

const shouldDisable = (accountId, viewId) => {
  return LOCKED_DATES.some(({ viewIds, accountIds }) => {
    return viewIds.includes(viewId) || accountIds.includes(accountId);
  });
};
