import React from 'react';
import { DARWIN_NAVY_4 } from '../../constants';

const ExportIcon = ({ className = "", onClick }) => (
  <svg className={className} onClick={onClick} width="28" height="28" viewBox="-2 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 12.5V13C5.691 10.422 7.6 9.047 10 9V12C10 12.551 10.511 13 11.143 13C11.507 13 11.818 12.842 12.026 12.609C13.959 10.58 18 6.5 18 6.5C18 6.5 13.959 2.418 12.025 0.363C11.7898 0.131283 11.4732 0.000966261 11.143 0C10.511 0 10 0.447 10 1V4C5.34 4 4 8.871 4 12.5ZM1 17H15C15.2652 17 15.5196 16.8946 15.7071 16.7071C15.8946 16.5196 16 16.2652 16 16V9.954C15.336 10.63 14.636 11.347 14 12.001V15H2V3H9V1H1C0.734784 1 0.48043 1.10536 0.292893 1.29289C0.105357 1.48043 0 1.73478 0 2V16C0 16.2652 0.105357 16.5196 0.292893 16.7071C0.48043 16.8946 0.734784 17 1 17Z" fill={DARWIN_NAVY_4}/>
  </svg>
)

export default ExportIcon;
