import React, { useRef } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/bundle';
import IterationCard from './Card';

const IterationsCarousel = ({ assetIterations }) => {
  const ref = useRef();
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div ref={ref} className="iterations-carousel">
      {assetIterations.length > 1 ? (
        <div className="d-flex justify-content-end align-items-center">
          <div className="iterations-carousel__nav">
            <div
              ref={prevRef}
              className="iterations-carousel__arrow iterations-carousel__prev"
            >
              <i className="fa fa-caret-left"></i>
            </div>
            <div
              ref={nextRef}
              className="iterations-carousel__arrow iterations-carousel__next"
            >
              <i className="fa fa-caret-right"></i>
            </div>
          </div>
        </div>
      ) : null}
      <Swiper
        modules={[Navigation]}
        spaceBetween={50}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
        }}
        slidesPerView={1}
        slidesPerGroup={1}
        observeParents={true}
        observer={true}
        allowTouchMove={false}
        noSwiping
      >
        {assetIterations.map((data) => {
          return (
            <SwiperSlide>
              <IterationCard {...{ data }} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default IterationsCarousel;
