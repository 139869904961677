import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';
import {
  darwinDateToDate,
  toDarwinDate,
  daysFrom,
} from '../../utils/darwin-dates';

export { startOfMonth, endOfMonth, startOfYear, endOfYear };

const today_ms = () => {
  const today = new Date();

  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);

  return today.getTime();
};

/**
 * @param {string} darD YYYY-MM-DD
 *
 * @return {string} YYYY-MM-DD
 */
export const nextDay = (darD) =>
  toDarwinDate(daysFrom(darwinDateToDate(darD), 1));
export const prevDay = (darD) =>
  toDarwinDate(daysFrom(darwinDateToDate(darD), -1));

const NOW = new Date();
export const MIN_DATE = toDarwinDate(daysFrom(NOW, 1));
export const MAX_DATE = toDarwinDate(daysFrom(NOW, 100));
export const TODAY = today_ms();
export const DAY = 1000 * 60 * 60 * 24;

export function msToDarwinDate(ms) {
  return toDarwinDate(new Date(ms));
}

export function darwinDateToMs(d) {
  const date = darwinDateToDate(d);

  return date.getTime();
}

export function monthFrom(date) {
  return date.toLocaleString('default', { month: 'long' });
}
