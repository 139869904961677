import React from 'react';

import { ErrorBoundary } from '../../components/Helpers/Error';
import { ReportAnalytics } from '../../components/Analytics';

import Wrapper from './Wrapper';
import Header from './Header';
import { useReportParam, useUrlProps } from './report-hooks';
import { useSearchParams } from '../../components/Hooks/search-params';

import BlockReport from './BlockReport';
import VisualReport from './VisualReport';

import './styles.scss';
import EfficiencyReport from './EfficiencyReport';
import { useCreativeAnalyticsGet } from '../CreativeAnalytics/contexts';

function Reports({ reportType, isPF }) {
  return (
    <div>
      <Header {...{ reportType, isPF }} />
      <Wrapper {...{ reportType, isPF }} />
    </div>
  );
}

function switchReport(reportType, showAllMetrics, isPF) {
  switch (reportType) {
    case 'block':
      return <BlockReport />;

    case 'visuals':
      return <VisualReport />;

    case 'efficiency':
      return (
        <>
          <Header reportType={reportType} showAllMetrics={showAllMetrics} />
          <EfficiencyReport showAllMetrics={showAllMetrics} />
        </>
      )

    default:
      return <Reports {...{ reportType, isPF }} />;
  }
}

export default function WrappedWithParams() {
  let reportType = useReportParam();
  const { getParams } = useSearchParams();
  const { showAllMetrics, display } = getParams('showAllMetrics', 'display');
  const isPF = !!display;

  if (isPF) {
    reportType = display;
  }

  return (
    <ErrorBoundary message="Oops, something went wrong">
      <>
        <ReportAnalytics />

        {switchReport(reportType, showAllMetrics, isPF)}
      </>
    </ErrorBoundary>
  );
}
