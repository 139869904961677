import { findMedian, useGetMedian } from '../utils';

export const getCustomIntegratedMetricsValues = (
  visuals,
  customIntegratedMetrics = [],
  spend
) => {
  let result = {};

  customIntegratedMetrics.forEach((metric) => {
    const total = visuals.reduce(
      (total, visual) => (total += visual[metric.value] ?? 0),
      0
    );

    let costPer = total > 0 ? spend / total : 0;
    const values = visuals.map((visual) => visual[`${metric.value}`] || 0);
    const cp_arr = visuals.map((visual) => visual[`cp_${metric.value}`] || 0);
    const median = findMedian(values);
    const medianCostPer = findMedian(cp_arr);

    result[metric.value] = total || 0;
    result[`median_${metric.value}`] = median || 0;
    result[`cp_${metric.value}`] = costPer || 0;
    result[`median_cp_${metric.value}`] = medianCostPer || 0;
  });
  return result;
};
