/* eslint-disable no-param-reassign */
/* eslint-disable linebreak-style */
/* eslint-disable prefer-const */

import {
  toPercentRounded,
  strToNum,
  toPercent,
  toInt,
  toPercent_2,
} from '../../utils/numbers';
import {
  BRAND,
  FORMAT,
  KEYWORD,
  PHRASES,
  PROMO,
  PROMOTION_TYPE,
} from './categories';
import { sortReportCardLabels } from './ReportCard/utils';
import { customNanoId } from '../../utils/nanoid';

export const getSpend = (ads) => {
  let sum = 0;

  ads.forEach((ad) => {
    sum += ad.spend;
  });

  return sum;
};

export const getAvgSpend = (ads) => {
  let sum = 0;
  let count = 0;

  ads.forEach((ad) => {
    sum += ad.spend;
    count += 1;
  });

  return `$${(sum / count).toFixed(2)}`;
};

export const getTotal = (visuals, metric) => {
  let total = 0;

  visuals.forEach((visual) => {
    total += visual[metric];
  });

  return total;
};

export const useGetAggregate = (
  ads,
  metric,
  clientMetrics = [],
  isDirectMail = false
) => {
  let total_spend = 0;
  let total_results = 0;
  let total_impressions = 0;
  let total_clicks = 0;
  let total_sessions = 0;
  let total_leads = 0;
  let total_recallers = 0;
  let cpa = 0;
  let roas = 0;
  let cpm = 0;
  let cpc = 0;
  let ctr = 0;
  let ga_ctr = 0;
  let cpik = 0;
  let cp_recallers = 0;
  let custom_metrics = {};
  const shouldAddClientMetric =
    clientMetrics && clientMetrics.length && isDirectMail;

  if (shouldAddClientMetric) {
    clientMetrics.forEach((metric) => {
      custom_metrics[`total_${metric}`] = 0;
    });
  }

  ads.forEach((ad) => {
    total_spend += ad.spend;
    total_results += ad.results;
    total_impressions += ad.impressions;
    total_clicks += ad.clicks;
    total_sessions += ad.ga_landing_page_views;
    total_leads += ad.leads;
    total_recallers += ad.estimated_ad_recallers;
    if (shouldAddClientMetric) {
      clientMetrics.forEach((metric) => {
        custom_metrics[`total_${metric}`] += ad[metric];
      });
    }
  });

  if (total_spend > 0) {
    cp_recallers = total_spend / total_recallers;
  }

  if (total_results > 0) {
    cpa = total_spend / total_results;
    roas = 1 / (total_spend / total_results);
  }

  if (total_impressions > 0) {
    cpm = total_spend / (total_impressions / 1000);
    ctr = total_clicks / total_impressions;
    ga_ctr = total_sessions / total_impressions;
    cpik = (total_leads / total_impressions) * 1000000;
  }

  if (total_clicks > 0) cpc = total_spend / total_clicks;

  switch (metric) {
    case 'cpa':
      return Number.isFinite(total_spend / total_results) ? cpa : 0;
    case 'roas':
      return Number.isFinite(total_spend / total_results) ? roas : 0;
    case 'cpm':
      return Number.isFinite(total_spend / total_impressions) ? cpm : 0;
    case 'cpc':
      return Number.isFinite(total_spend / total_clicks) ? cpc : 0;
    case 'ctr':
      return Number.isFinite(total_clicks / total_impressions) ? ctr : 0;
    case 'ga_ctr':
      return Number.isFinite(total_sessions / total_impressions) ? ga_ctr : 0;
    case 'cpik':
      return Number.isFinite(total_leads / total_impressions) ? cpik : 0;
    case 'cp_recallers':
      return Number.isFinite(total_recallers / total_spend) ? cp_recallers : 0;
    case 'conversion_rate':
      return Number.isFinite(total_results / total_clicks)
        ? toPercent_2(total_results / total_clicks)
        : '0%';
    default:
      if (
        clientMetrics &&
        clientMetrics.length &&
        clientMetrics.includes(metric)
      ) {
        return Number.isFinite(total_spend / custom_metrics[`total_${metric}`])
          ? total_spend / custom_metrics[`total_${metric}`]
          : 0;
      }
      return 0;
  }
};

export const useGetAverage = (
  ads,
  metric,
  clientMetrics = [],
  isDirectMail = false,
  isOrganicView
) => {
  let sum = 0;
  let count = 0;
  let sumCpm = 0;
  let sumCpc = 0;
  let sumCtr = 0;
  let sumGaCtr = 0;
  let sumCpik = 0;
  let cpa = 0;
  let roas = 0;
  let cpm = 0;
  let cpc = 0;
  let ctr = 0;
  let ga_ctr = 0;
  let cpik = 0;
  let custom_metrics = {};
  const shouldAddClientMetric =
    clientMetrics && clientMetrics.length && isDirectMail;

  if (shouldAddClientMetric) {
    clientMetrics.forEach((metric) => {
      custom_metrics[`sum_${metric}`] = 0;
    });
  }

  ads.forEach((ad) => {
    let impressions = ad.impressions / 1000;
    sum += ad.cpa;
    count += 1;
    if (impressions > 0) sumCpm += ad.spend / impressions;
    if (ad.clicks > 0) sumCpc += ad.spend / ad.clicks;
    if (ad.impressions > 0) {
      sumCtr += (ad.clicks / ad.impressions) * 100;
      sumGaCtr += (ad.ga_landing_page_views / ad.impressions) * 100;
      sumCpik += calculateCPIK(ad.leads, ad.impressions);
    }
    if (shouldAddClientMetric) {
      clientMetrics.forEach((metric) => {
        if (ad[metric] > 0) {
          if (isOrganicView) {
            custom_metrics[`sum_${metric}`] += ad[metric];
          } else {
            custom_metrics[`sum_${metric}`] += ad.spend / ad[metric];
          }
        }
      });
    }
  });

  if (count > 0) {
    cpa = sum / count;
    roas = sum / count > 0 ? 1 / (sum / count) : 0;
    cpm = sumCpm / count;
    cpc = sumCpc / count;
    ctr = sumCtr / count;
    ga_ctr = sumGaCtr / count;
    cpik = sumCpik / count;
  }

  switch (metric) {
    case 'cpa':
      return Number.isFinite(sum / count) ? cpa : 0;
    case 'roas':
      return Number.isFinite(sum / count) ? roas : 0;
    case 'cpm':
      return Number.isFinite(sumCpm / count) ? cpm : 0;
    case 'cpc':
      return Number.isFinite(sumCpc / count) ? cpc : 0;
    case 'ctr':
      return Number.isFinite(sumCtr / count) ? ctr : 0;
    case 'ga_ctr':
      return Number.isFinite(sumGaCtr / count) ? ga_ctr : 0;
    case 'cpik':
      return Number.isFinite(sumCpik / count) ? cpik : 0;
    default:
      if (
        clientMetrics &&
        clientMetrics.length &&
        clientMetrics.includes(metric)
      ) {
        if (Number.isFinite(custom_metrics[`sum_${metric}`] / count)) {
          return custom_metrics[`sum_${metric}`] / count;
        } else {
          return 0;
        }
      }
      return 0;
  }
};

/**
 * @param {Array<Number>} arr
 * @return {Number}
 */
export function findMedian(arr, metric) {
  arr = [...arr].sort((a, b) => a - b);
  const square1 = arr[(arr.length - 1) >> 1];
  const square2 = arr[arr.length >> 1];
  const result = (square1 + square2) / 2;

  if (
    arr.length % 2 === 0 &&
    (square1 === 0 || square2 === 0) &&
    metric === 'cpa'
  ) {
    return 1 / 0; // return Infinity
  }
  return result;
}

export const useGetMedian = (
  ads,
  metric,
  clientMetrics = [],
  isDirectMail = false
) => {
  let cpas = ads.map(({ cpa }) => cpa);
  let roas = ads.map(({ cpa }) => (cpa > 0 ? 1 / cpa : 0));
  let cpm = 0;
  let cpms = [];
  let cpc = 0;
  let cpcs = [];
  let ctr = 0;
  let ctrs = [];
  let conversion_rates = [];
  let ga_ctr = 0;
  let ga_ctrs = [];
  let cpiks = ads.map(({ leads, impressions }) =>
    impressions > 0 ? calculateCPIK(leads, impressions) : 0
  );
  let cp_recallers = ads.map(({ estimated_ad_recallers, spend }) =>
    spend > 0 ? calculateRecallers(estimated_ad_recallers, spend) : 0
  );
  let median = 0;
  let custom_metrics = {};
  const shouldAddClientMetric =
    clientMetrics && clientMetrics.length && isDirectMail;

  if (shouldAddClientMetric) {
    clientMetrics.forEach((metric) => {
      custom_metrics[`${metric}`] = [];
    });
  }

  ads.forEach((ad) => {
    let impressions = ad.impressions / 1000;
    if (impressions > 0) cpm = ad.spend / impressions;
    cpms.push(cpm);
    if (ad.clicks > 0) cpc = ad.spend / ad.clicks;
    cpcs.push(cpc);
    if (ad.impressions > 0) {
      ctr = (ad.clicks / ad.impressions) * 100;
      ga_ctr = (ad.ga_landing_page_views / ad.impressions) * 100;
    }
    ctrs.push(ctr);
    ga_ctrs.push(ga_ctr);
    if (shouldAddClientMetric) {
      clientMetrics.forEach((metric) => {
        if (ad[metric] > 0)
          custom_metrics[`${metric}`] = [
            ad.spend / ad[metric],
            ...custom_metrics[`${metric}`],
          ];
        else custom_metrics[`${metric}`] = [0, ...custom_metrics[`${metric}`]];
      });
    }
    conversion_rates.push(ad.results / ad.clicks);
  });

  switch (metric) {
    case 'cpa':
      median = findMedian(cpas, metric);
      return Number.isFinite(median) && median > 0 ? median : null;
    case 'roas':
      median = findMedian(roas, metric);
      return Number.isFinite(median) ? median : 0;
    case 'cpm':
      median = findMedian(cpms, metric);
      return Number.isFinite(median) ? median : 0;
    case 'cpc':
      median = findMedian(cpcs, metric);
      return Number.isFinite(median) ? median : 0;
    case 'ctr':
      median = findMedian(ctrs, metric);
      return Number.isFinite(median) ? median : 0;
    case 'ga_ctr':
      median = findMedian(ga_ctrs, metric);
      return Number.isFinite(median) ? median : 0;
    case 'cpik':
      median = findMedian(cpiks, metric);
      return Number.isFinite(median) ? median : 0;
    case 'cp_recallers':
      median = findMedian(cp_recallers, metric);
      return Number.isFinite(median) ? median : 0;
    case 'conversion_rate':
      median = findMedian(conversion_rates, metric);
      return Number.isFinite(median) ? toPercent_2(median) : '0%';
    default:
      if (
        clientMetrics &&
        clientMetrics.length &&
        clientMetrics.includes(metric) &&
        custom_metrics[`${metric}`]
      ) {
        median = findMedian(custom_metrics[`${metric}`]);
        return Number.isFinite(median) ? median : 0;
      }
      return 0;
  }
};

export const calculateRecallers = (recallers, spend) => {
  return spend / recallers;
};

export const calculateCPIK = (leads, impressions) => {
  return (leads / impressions) * 100000;
};

export const setOutcomesData = (rowData) => {
  let outcomes_breakdown = [];
  let outcomes_avg = [];
  // calculate CPA and spend for winners and losers

  try {
    if (rowData.winners.length) {
      outcomes_breakdown.push({
        type: 'Better than Avg',
        spend: getSpend(rowData.winners),
        cpa: useGetAggregate(rowData.winners),
      });
      outcomes_avg.push({
        type: 'Better than Avg',
        spend: getAvgSpend(rowData.winners),
        cpa: useGetAverage(rowData.winners),
      });
    }

    if (rowData.losers.length) {
      outcomes_breakdown.push({
        type: 'Worse than Avg',
        spend: getSpend(rowData.losers),
        cpa: useGetAggregate(rowData.losers, 'cpa'),
      });
      outcomes_avg.push({
        type: 'Worse than Avg',
        spend: getAvgSpend(rowData.losers),
        cpa: useGetAverage(rowData.losers, 'cpa'),
      });
    }
  } catch (e) {
    console.error(e);
  }

  return {
    outcomes_breakdown,
    outcomes_avg,
  };
};

const getNewAdsOthersSpend = (totalSpend, elementSpend) =>
  `$${(totalSpend - elementSpend).toFixed(2)}`;

const getNewAdsOthersCPA = (
  totalSpend,
  totalResults,
  elementSpend,
  elementResults
) => {
  /* get total spend and results from all new ads,
    subtract selected element's spend and results,
    then calculate CPA
  */
  let spendDiff = totalSpend - elementSpend;
  let resultsDiff = totalResults - elementResults;

  const cpa = `$${(spendDiff / resultsDiff).toFixed(2)}`;
  return Number.isFinite(spendDiff / resultsDiff) ? cpa : 'N/A';
};

export const setNewAdsData = (label, data, newAds) => {
  let new_ads_cpa = [];
  // selected element's ads
  let ads = [...label.winners, ...label.losers];
  // list of ads for selected label only containing new ads
  let elementNewAds = ads.filter((ad) => newAds.includes(ad.ad_name));

  let distinctNewAds = [];
  let distinctNewAdNames = new Set();

  // loop through full data, get distinct set of new ads
  data.forEach((labelObj) => {
    labelObj.winners.forEach((ad) => {
      if (!distinctNewAdNames.has(ad.ad_name)) {
        distinctNewAds.push(ad);
      }
      distinctNewAdNames.add(ad.ad_name);
    });

    labelObj.losers.forEach((ad) => {
      if (!distinctNewAdNames.has(ad.ad_name)) {
        distinctNewAds.push(ad);
      }
      distinctNewAdNames.add(ad.ad_name);
    });
  });

  let totalSpend = 0;
  let elementSpend = 0;
  let totalResults = 0;
  let elementResults = 0;

  distinctNewAds.forEach((newAd) => {
    totalSpend += newAd.spend;
    totalResults += newAd.results;
  });

  elementNewAds.forEach((ad) => {
    elementSpend += ad.spend;
    elementResults += ad.results;
  });

  new_ads_cpa.push({
    type: label.value,
    spend: getSpend(elementNewAds),
    cpa: useGetAggregate(elementNewAds),
  });

  new_ads_cpa.push({
    type: 'Others',
    spend: getNewAdsOthersSpend(totalSpend, elementSpend),
    cpa: getNewAdsOthersCPA(
      totalSpend,
      totalResults,
      elementSpend,
      elementResults
    ),
  });

  return {
    new_ads_cpa,
  };
};

export const getPercentBudget = (visuals, totalBudget) => {
  if (totalBudget === 0) return toPercentRounded(0);
  const spend = visuals.reduce((acc, curr) => {
    acc += curr.spend;
    return acc;
  }, 0);

  if (spend / totalBudget > 0 && spend / totalBudget < 0.01) return '<1%';

  return toPercentRounded(spend / totalBudget);
};

export const getCCCType = (label) => {
  if (label.type === 'derived_label') return 'derived_labels';
  if (label.udc) return 'user_defined';
  if (label.ad_name_label) return 'ad_name_labels';
  if (label.ccc) return 'ai_driven';
  return 'standard';
};

const formatLookupLabel = (label: LabelObject) => {
  const { id, name, value, category, spend, visual_ids } = label;
  return {
    id,
    name,
    value,
    category,
    spend,
    visual_ids,
  };
};

export const getCategoriesLookup = ({
  filteredData,
  isROAS = false,
  isDirectMail = false,
  reportCardDataType,
  viewName = '',
  reportCardMetric,
  spendThreshold = 1,
}) => {
  if (!filteredData) return null;
  let result = {};
  const nanoid = customNanoId();

  filteredData.forEach((label) => {
    const category = label.category || null;
    const category_id = label.category_id || nanoid();

    if (category && category_id) {
      if (result[category]) {
        result[category] = {
          ...result[category],
          labels: [...result[category].labels, formatLookupLabel(label)],
        };
      } else {
        result[category] = {
          category_id,
          labels: [formatLookupLabel(label)],
          isUdc: label.udc,
          synonym: label.value.slice(0, 4) === 'CCC_',
          derived: label.type === 'derived_label',
          ccc: label.ccc,
          ad_name_label: label.ad_name_label,
          ccc_type: getCCCType(label),
          ccc_desc: label.ccc ? label.description : null,
        };
      }
    }
  });

  [FORMAT, BRAND, PROMO, PROMOTION_TYPE, KEYWORD, PHRASES].forEach(
    (category) => {
      if (!result[category]) {
        result[category] = {
          category,
          labels: [],
        };
      }
    }
  );

  const getCategoryVisuals = (category) => {
    let distinctVisuals = new Set();
    category.labels.forEach(({ visual_ids }) => {
      if (!visual_ids) console.log({ visual_ids, category });
      return visual_ids?.forEach((id) => distinctVisuals.add(id));
    });
    return Array.from(distinctVisuals);
  };

  Object.entries(result).forEach(([key, category]) => {
    const isSynonym = (label) => {
      if (label.value.slice(0, 4) === 'CCC_' || label.type === 'synonyms') {
        return true;
      }
    };

    result[key] = {
      ...result[key],
      ccc: category.labels.some((x) => x.ccc),
      synonym: category.labels.some((x) => isSynonym(x)),
      udc: category.labels.some((x) => x.type === 'user_defined_labels'),
      derived: category.labels.some((x) => x.type === 'derived_label'),
      ad_name_label: category.labels.some((x) => x.ad_name_label),
      labels: category.labels.sort(
        sortReportCardLabels(
          isROAS,
          isDirectMail,
          reportCardDataType,
          viewName,
          reportCardMetric,
          key === 'Format'
        )
      ),
      hasData: category.labels.some(
        (label) => label.spend >= parseInt(spendThreshold)
      ),
      visuals: getCategoryVisuals(category),
      multiple_labels: category.labels.some(
        (label) => label.ccc_type === 'multiple labels'
      ),
    };
  });

  return result;
};

export const getAllKeywords = (keywordsData) => {
  if (!Object.keys(keywordsData).length) return [];
  const uniq = new Set([...Object.values(keywordsData)].flat());
  return Array.from(uniq);
};

export const getAdNames = (allVisuals) => {
  if (!allVisuals.length) return [];
  return Array.from(new Set(allVisuals.map(({ ad_names }) => ad_names).flat()));
};

export const useGetTotalLength = (ads, spendThreshold = 1) => {
  let result = 0;
  ads.map((x) => {
    if (x.spend >= spendThreshold) {
      result += 1;
    }
  });

  return result;
};

export const useGetTotalResults = (
  ads,
  metric,
  isROAS = false,
  spendThreshold = 1
) => {
  let result = 0;
  ads.map((x) => {
    if (x.spend >= spendThreshold) {
      result += x[metric];
    }
  });

  return Math.round(result);
};

export const useGetPercentResults = (
  ads,
  metric,
  totalResultsAllVisuals,
  isROAS = false
) => {
  let result = 0;
  if (!isROAS) {
    let ads_result = 0;
    ads.map((x) => {
      ads_result += x[metric];
    });
    result = toPercentRounded(ads_result / strToNum(totalResultsAllVisuals));
  }

  return result;
};

export const useGetPercentResultsDiff = (
  ads,
  metric,
  totalResultsAllVisuals,
  totalBudget,
  isROAS = false
) => {
  let result = 0;
  if (!isROAS) {
    const percent_results = useGetPercentResults(
      ads,
      metric,
      totalResultsAllVisuals,
      isROAS
    );
    const percent_budget = getPercentBudget(ads, totalBudget);
    result =
      strToNum(percent_budget.slice(0, -1)) -
      strToNum(percent_results.slice(0, -1));
  }

  return toPercent(result / 100);
};

export const getTotalLikes = (visuals) => {
  let count = 0;

  visuals.forEach((visual) => {
    if (visual?.['Like Count']) {
      count += visual['Like Count'];
    }
  });

  return count;
};

export const makeSearchLookup = (filteredData, categorySettings) => {
  return filteredData.reduce((arr, label) => {
    const maybeCategory = categorySettings?.find(
      (c) => c.category_name === label.category
    );
    const isVisible =
      maybeCategory?.enabled.report_card && label.visual_ids?.length > 0;

    if (isVisible) {
      arr.push({
        name: label.name,
        label: `${label.category} -> ${label.name}`,
        value: label.value,
        labelObj: label,
        category: label.category,
        visuals: [...label.visual_ids],
      });
    }

    return arr;
  }, []);
};
