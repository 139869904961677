import { fetchFromApi, getViewId } from '../utils';
import { REQUEST, SUCCESS, FAILURE } from '../constants';

export const AD_PREVIEWS_FETCH = 'AD_PREVIEWS_FETCH';

/**
 * @param {String} status
 * @param {{error: Object, response: Object}} data
 */
export const previewsFetch = (
  status,
  data = { error: null, response: null }
) => ({
  type: AD_PREVIEWS_FETCH,
  status,
  ...data,
});

const previewsToRetrieve = (previewsIndex, keys) =>
  keys.filter((key) => !previewsIndex[key]);

/**
 * @param {{ values: string[], attribute: "ad_name" | "ad_id", viewId?: string }} adNames
 */
export const previewsFetchFlow =
  ({ values, attribute, viewId = '' }) =>
  async (dispatch, getState) => {
    const {
      AdPreviews: { previewsIndex },
    } = getState();
    const viewIdToPass = viewId || getViewId();
    const neededValues = previewsToRetrieve(previewsIndex, values);

    if (neededValues.length > 0) {
      dispatch(previewsFetch(REQUEST));

      try {
        const response = await fetch(
          ['api', viewIdToPass, 'ad-previews'].join('/'),
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              attribute,
              values: neededValues,
            }),
          }
        );

        if (response.status === 200) {
          const res = await response.json();
          dispatch(previewsFetch(SUCCESS, { response: res, error: null }));
        } else {
          dispatch(
            previewsFetch(FAILURE, {
              error: new Error(`Status code ${response.status}`),
              response: null,
            })
          );
        }
      } catch (err) {
        dispatch(
          previewsFetch(FAILURE, { error: err.toString(), response: null })
        );
      }
    }
  };

const adjustResponse = (response, hash) => ({
  preview: response[0],
  _id: hash,
});

/**
 * @param {Object} conf
 * @param {String} conf._id
 * @param {String} conf.atf
 * @param {String} conf.btf
 * @param {String} conf.cta
 * @param {String} conf.visual
 * @param {"image" | "video"} conf.type
 * @param {String} conf.image_url
 */
export const fetchCreateAdPreviewFlow =
  ({ _id, atf, btf, cta, visual, type, image_url }) =>
  async (dispatch, getState) => {
    const {
      AdPreviews: { previewsIndex },
      ViewSettings: { account_id },
    } = getState();
    const viewId = getViewId();

    if (!previewsIndex[_id]) {
      dispatch(previewsFetch(REQUEST));

      try {
        const response = await fetchFromApi({
          uri: ['api', viewId, 'create-ad-previews'].join('/'),
          body: {
            account_id,
            previews: [
              {
                atf,
                btf,
                cta,
                visual,
                type,
                image_url,
              },
            ],
          },
        });

        if (response.error) {
          dispatch(
            previewsFetch(FAILURE, {
              error: response.error.toString(),
              response: null,
            })
          );
        } else {
          dispatch(
            previewsFetch(SUCCESS, {
              response: adjustResponse(response.data, _id),
              error: null,
            })
          );
        }
      } catch (err) {
        dispatch(
          previewsFetch(FAILURE, { error: err.toString(), response: null })
        );
      }
    }
  };
