import { useViewSettings } from '../../../selectors';
import { useCreativeAnalyticsGet } from '../contexts';
import { CUSTOM_SCORING_METRICS } from '../PerformanceFrequency/constants';

const DEFAULT_METRICS = [
  {
    label: 'Spend',
    value: 'spend',
  },
  {
    label: 'Impressions',
    value: 'impressions',
  },
  {
    label: 'Results',
    value: 'results',
  },
  {
    label: 'Clicks',
    value: 'clicks',
  },
];

export const useMetricOptions = () => {
  const { isROAS, additional_metrics, isOrganicView, isEmailView, account_id } =
    useViewSettings();
  const { clientMetrics, isGoHealth, filteredData, customScoringData } =
    useCreativeAnalyticsGet();
  const cpaMetric = isROAS ? 'roas' : 'cpa';
  const isUBeauty = account_id === 'act_478482719415309';

  const additionalMetricsOpts = additional_metrics.reduce((result, opt) => {
    result.push({
      label: `Cost per ${opt.name}`,
      value: `cp_${opt.value}`,
    });
    result.push({
      label: `Median cost per ${opt.name}`,
      value: `median_cp_${opt.value}`,
    });
    return result;
  }, []);

  const clientMetricsOpts =
    [
      ...clientMetrics
        ?.map((metric) => {
          return {
            label: metric,
            value: metric,
          };
        })
        .filter((metric) =>
          !customScoringData?.length
            ? !CUSTOM_SCORING_METRICS.includes(metric.label)
            : metric
        ),
    ] ?? [];

  let options = [
    { label: cpaMetric.toUpperCase(), value: cpaMetric },
    {
      label: `Median ${cpaMetric.toUpperCase()}`,
      value: `median_${cpaMetric}`,
    },
    { label: 'CPM', value: 'cpm' },
    { label: 'Median CPM', value: 'median_cpm' },
    { label: 'CPM-Reach', value: 'cpm_reach' },
    { label: 'CPC', value: 'cpc' },
    { label: 'Median CTR', value: 'median_ctr' },
    ...clientMetricsOpts,
    ...additionalMetricsOpts,
    ...DEFAULT_METRICS,
  ];

  if (isUBeauty) {
    const filtered = options.filter((opt) => {
      return opt.value === 'cp_Rockerbox_Conversions';
    });
    options = [
      ...filtered,
      { label: 'Rockerbox Roas', value: 'Rockerbox_Roas' },
    ];
  }

  if (isGoHealth) {
    const filtered = options.filter((opt) => {
      return ![...clientMetricsOpts, ...additionalMetricsOpts].some(
        (o) => o.value === opt.value
      );
    });

    const customOpts = additional_metrics.reduce((result, opt) => {
      result.push({
        label: `Cost per Opp Attempted`,
        value: `cp_${opt.value}`,
      });
      result.push({
        label: `Median cost per Opp Attempted`,
        value: `median_cp_${opt.value}`,
      });
      return result;
    }, []);

    options = [...filtered, ...customOpts];
  }

  if (isOrganicView || isEmailView) {
    options = [...clientMetricsOpts, ...DEFAULT_METRICS];
  }

  if (isEmailView) {
    options.unshift({ label: cpaMetric.toUpperCase(), value: cpaMetric });
  }

  return options;
};
