import { toPercentRounded } from '../../../../../utils/numbers';
import { useCustomScoringApi } from '../../../../DNA/api';
import { DARWIN_INDEX_LOOKUP } from '../../../constants';
import { shouldIncludeLabel } from '../../../filter-utils';
import {
  getPercentBudget,
  getTotalLikes,
  useGetAggregate,
  useGetAverage,
  useGetMedian,
  useGetPercentResults,
  useGetPercentResultsDiff,
  useGetTotalResults,
} from '../../../utils';
import { getAdditionalMetrics } from '../../../utils/getAdditionalMetrics';
import { getCustomIntegratedMetricsValues } from '../../../utils/getCustomIntegratedMetricsValues';
import { getSumsAndAverages } from '../../../utils/getSumsAndAverages';
import { getTotalImpressions } from '../../../utils/getTotalImpressions';
import { getAggregate } from './getAggregate';
import { getAverage } from './getAverage';
import { getClientMetricValues } from './getClientMetricValues';
import { getCustomEventsValues } from './getCustomEventsValues';
import { getCustomScoringValues } from './getCustomScoringValues';
import { getMedian } from './getMedian';
import { getReach } from './getReach';
import { modifyLabel } from './modifyLabel';

const findDiscrepancies = (combined, byId, label) => {
  return combined.some((visual) => {
    const maybe = byId[visual.id];

    if (!maybe) return false;
    if (maybe?.spend !== visual?.spend) {
      return true;
    }
  });
};

const getVisuals = ({
  label,
  byId,
  isDiscrepancy,
  filteredWinnerIds,
  filteredLoserIds,
  handleFilterVisuals,
  sumsAndAverages,
}) => {
  const combined = [...label.winners, ...label.losers];

  // for udc, visuals need to be calculated//filtered separately
  try {
    if (isDiscrepancy) {
      const { winners, losers } = handleFilterVisuals(
        combined,
        sumsAndAverages
      );
      return { winners, losers };
    } else {
      return combined.reduce(
        (acc, visual) => {
          if (filteredWinnerIds.includes(visual.id)) {
            acc.winners.push(byId[visual.id]);
          } else if (filteredLoserIds.includes(visual.id)) {
            acc.losers.push(byId[visual.id]);
          }

          return acc;
        },
        { winners: [], losers: [] }
      );
    }
  } catch (e) {
    console.error(e);
    return {
      winners: [],
      losers: [],
    };
  }
};

export const filterLabels = ({
  data,
  addSynonymLabel,
  filteredAllVisuals,
  handleFilterVisuals,
  byId,
  filteredWinnerIds,
  filteredLoserIds,
  isOrganicView,
  sumsAndAverages,
  isROAS,
  categoryFilters,
  customIntegratedMetrics,
  additional_metrics,
  clientMetrics,
  isDirectMail,
  isCompetitorView,
  creativeCount,
  customEvents,
  totalImpressions,
  customScoringData,
}) => {
  return data.reduce((acc, curr, i) => {
    const label = modifyLabel(curr);
    const { category } = label;

    addSynonymLabel(label, curr, (synonymOthersLabel) =>
      acc.push(synonymOthersLabel)
    );

    const isDiscrepancy = findDiscrepancies(
      [...label.winners, ...label.losers],
      byId,
      label
    );

    const { winners, losers } = getVisuals({
      label,
      byId,
      isDiscrepancy,
      filteredWinnerIds,
      filteredLoserIds,
      handleFilterVisuals,
      sumsAndAverages,
    });

    const visuals = [...winners, ...losers].filter((visual) => !!visual);

    const labelSumsAndAverages = getSumsAndAverages(visuals, isOrganicView);

    const totalBudget = sumsAndAverages.sumSpend;
    const spend = labelSumsAndAverages.sumSpend;

    const customIntegratedMetricsValues = getCustomIntegratedMetricsValues(
      visuals,
      customIntegratedMetrics,
      spend
    );

    const additionalMetricsValues = getAdditionalMetrics(
      visuals,
      additional_metrics,
      spend
    );

    const temporarilyRemoved = [];

    const shouldInclude =
      shouldIncludeLabel(label, categoryFilters) &&
      !temporarilyRemoved.includes(category);

    const clientMetricsValues: {} = getClientMetricValues({
      clientMetrics,
      isDirectMail,
      isCompetitorView,
      isOrganicView,
      visuals,
    });

    const customEventsValues: {} = getCustomEventsValues({
      customEvents,
      visuals,
      numOfAllVisuals: filteredAllVisuals.length,
      totalImpressions,
    });

    const total_likes = getTotalLikes(visuals);
    const average_cpa = getAverage(visuals, 'cpa');
    const average_roas = getAverage(visuals, 'roas');
    // console.log(customScoringData)
    const customScoringValues = getCustomScoringValues(
      visuals,
      customScoringData,
    )

    if (
      shouldInclude &&
      (visuals.length >= parseInt(creativeCount) || label.testing_opportunity)
    ) {
      acc.push({
        ...label,
        category,
        numerator: winners.length,
        denominator: visuals.length,
        performance_percent: Math.round(
          (winners.length / visuals.length) * 100
        ),
        ...clientMetricsValues,
        ...customScoringValues,
        aggregate_cpa: getAggregate(visuals, 'cpa'),
        average_cpa,
        median_cpa: getMedian(visuals, 'cpa'),
        aggregate_roas: getAggregate(visuals, 'roas'),
        average_roas,
        median_roas: getMedian(visuals, 'roas'),
        aggregate_cpm: getAggregate(visuals, 'cpm'),
        average_cpm: getAverage(visuals, 'cpm'),
        median_cpm: getMedian(visuals, 'cpm'),
        cpm_reach: getReach(visuals),
        aggregate_cpc: getAggregate(visuals, 'cpc'),
        average_cpc: getAverage(visuals, 'cpc'),
        median_cpc: getMedian(visuals, 'cpc'),
        aggregate_ctr: getAggregate(visuals, 'ctr'),
        average_ctr: getAverage(visuals, 'ctr'),
        median_ctr: getMedian(visuals, 'ctr'),
        aggregate_cpik: getAggregate(visuals, 'cpik'),
        average_cpik: getAverage(visuals, 'cpik'),
        median_cpik: getMedian(visuals, 'cpik'),
        aggregate_cost_per_estimated_ad_recallers: getAggregate(
          visuals,
          'cp_recallers'
        ),
        median_cost_per_estimated_ad_recallers: getMedian(
          visuals,
          'cp_recallers'
        ),
        aggregate_conversion_rate: getAggregate(visuals, 'conversion_rate'),
        median_conversion_rate: getMedian(visuals, 'conversion_rate'),
        ga_aggregate_ctr: getAggregate(visuals, 'ga_ctr'),
        ga_average_ctr: getAverage(visuals, 'ga_ctr'),
        ga_median_ctr: getMedian(visuals, 'ga_ctr'),
        total_results: useGetTotalResults(visuals, 'results', isROAS),
        percent_of_results: useGetPercentResults(
          visuals,
          'results',
          sumsAndAverages.sumResults,
          isROAS
        ),
        percent_of_results_diff: useGetPercentResultsDiff(
          visuals,
          'results',
          sumsAndAverages.sumResults,
          totalBudget,
          isROAS
        ),
        total_link_clicks: useGetTotalResults(visuals, 'clicks'),
        percent_of_link_clicks: useGetPercentResults(
          visuals,
          'clicks',
          sumsAndAverages.sumClicks
        ),
        percent_of_link_clicks_diff: useGetPercentResultsDiff(
          visuals,
          'clicks',
          sumsAndAverages.sumClicks,
          totalBudget
        ),
        number_unique_creatives: visuals.length,
        percent_of_creative_count: toPercentRounded(
          visuals.length / filteredAllVisuals.length
        ),
        winners: winners,
        losers: losers,
        percent_budget: getPercentBudget(visuals, totalBudget),
        spend,
        darwin_index: DARWIN_INDEX_LOOKUP[label.name] || '-',
        visuals,
        wins_over_all: `${winners.length}/${visuals.length}`,
        client_down_funnel_events: clientMetrics,
        originalLabelData: curr,
        total_likes,
        likes_per_post:
          visuals.length > 0 ? (total_likes / visuals.length).toFixed(2) : 0,
        average_roas_quintile: label.average_cpa_quintile,
        percent_of_visuals: toPercentRounded(
          visuals.length / filteredAllVisuals.length
        ),
        ad_name_label:
          label.type === 'ad_name_label' || label.category === 'Ad Name Label',
        total_impressions: getTotalImpressions(visuals),
        ...(additionalMetricsValues ?? {}),
        ...(customIntegratedMetricsValues ?? {}),
        ...(clientMetricsValues ?? {}),
        ...(customEventsValues ?? {}),
      });
    }

    return acc;
  }, []);
};
