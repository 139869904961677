/* eslint-disable prefer-const */
//@ts-check
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setViewSettings } from "../../store/ViewSettings/actions";

import CustomDropdown from "../CustomDropdown";
import { useSearchParams } from "../Hooks/search-params";

let options = [];
const amount = 10;

for (let i = 1; i <= amount; i++) {
  options.push({
    label: i.toString(), value: i,
  });
}

export function Picker({ setView, creativeCount, chartType, toggleStyle, toggleClass }) {
  let { initialIndex } = useParams();
  let [index, setIndex] = useState(initialIndex);
  let { setParams } = useSearchParams();

  useEffect(() => {
    let update = { creativeCount: options[index].value };
    setParams(update, () => setView(update));
  }, [index]);

  useEffect(() => {
    if (chartType === 'label_matrix') {
      setIndex(4)
    }
  }, [chartType]);

  return (
    <CustomDropdown
      label={`Creative Count ${options[index].label}`}
      onSelect={(i) => i !== index && setIndex(i)}
      toggleStyle={toggleStyle}
      toggleClass={toggleClass}
    >
      {options.map(({ label, value }, i) => (
        <CustomDropdown.Item key={value} eventKey={i.toString()} className="py-2">
          {label}
        </CustomDropdown.Item>
      ))}
    </CustomDropdown>
  );
}

function useParams() {
  let { getParams } = useSearchParams();
  let { creativeCount } = getParams('creativeCount') || {
    creativeCount: "",
  };
  const found = options.findIndex(({ value }) => {
    return value.toString() === creativeCount;
  });

  return {
    initialIndex: found > -1 ? found : 0,
    creativeCount,
  };
}

const stateToProps = ({ ViewSettings: { creativeCount } }, props) => ({
  creativeCount,
});

const dispatchToProps = (dispatch, props) => ({
  setView: (settings) => dispatch(setViewSettings(settings)),
});

export const CreativeCountPicker = connect(
  stateToProps,
  dispatchToProps,
)(Picker);
