import { useEffect, useState, useRef } from 'react';

/**
 * @param {Function} proc
 * @param {Array} dependencies
 */
export function useAsyncEffect(proc, dependencies = []) {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setErr] = useState(false);

  useEffect(() => {
    let active = true;
    const doer = async () => {
      setLoading(true);

      try {
        const res = await proc();

        if (active) {
          setResult(res);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        if (active) {
          setErr(e);
        }
      } finally {
        if (active) {
          setLoading(false);
        }
      }
    };

    doer();

    return () => {
      active = false;
    };
  }, dependencies);

  return { error, loading, result };
}

export function useAsyncDidUpdate(proc, dependencies = []) {
  const hasMounted = useRef(false);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setErr] = useState(false);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
    } else {
      const _do = async () => {
        setLoading(true);

        try {
          const res = await proc();

          setResult(res);
        } catch (e) {
          console.error(e);
          setErr(true);
        } finally {
          setLoading(false);
        }
      };

      _do();
    }
  }, dependencies);

  return { error, loading, result };
}
