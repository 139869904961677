import React, { useState, useEffect } from 'react';
import { useSelector, dispatch } from 'react-redux';
import { setViewSettings } from '../../../store/ViewSettings/actions';
import { TABLES } from './constants';
import Tables from './Tables';
import { useEfficiencyReportApi } from './api';
import { toDarwinDate } from '../../../utils/darwin-dates';
import { getRowData } from './utils';
import { subDays } from 'date-fns';
import { useSearchParams } from '../../../components/Hooks/search-params';


import './EfficiencyReport.scss';

const EfficiencyReport = () => {
  const efficiencyReportApi = useEfficiencyReportApi();
  const { account_id, view_id, } = useSelector(({ ViewSettings: { account_id, viewId }}) => {
    return { account_id, view_id: viewId }
  });
  const [tables, setTables] = useState(TABLES);
  const defaultStartDate = subDays(new Date(), 42);
  const defaultEndDate = subDays(new Date(), 1);
  const { getParams } = useSearchParams();
  const { showAllMetrics } = getParams('showAllMetrics');
  const start_date = getParams('start_date').start_date || defaultStartDate;
  const end_date = getParams('end_date').end_date || defaultEndDate;

  const transplantTables = (data) => {
    const { periods } = data;

    const newTables = tables.map((table, i) => {
      let rows = table.rows.map((row) => {
        return {
          ...row,
          data: getRowData(data, row.value, row.format),
        }
      });

      return {
        ...table,
        columns: periods,
        rows,
      }
    });

    return newTables;
  }

  useEffect(() => {
    efficiencyReportApi.post.request({ start_date: toDarwinDate(start_date), end_date: toDarwinDate(end_date), account_id, view_id });
  }, []);

  useEffect(() => {
    if (efficiencyReportApi.post.data) {
      const { data } = efficiencyReportApi.post;
      setTables(transplantTables(data));
    }
  }, [efficiencyReportApi.post.data]);

  if (efficiencyReportApi.post.loading) {
    return <p>Loading...</p>
  }

  return (
    <div className="efficiency-report">
      <Tables {...{ tables, showAllMetrics }} />
    </div>
  )
}

export default EfficiencyReport;