import React, { useState, useEffect, useMemo, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useCreativeAnalyticsGet } from '../../contexts';
import { getFilteredMetrics } from './filtered-metrics';
import { useDraggableInPortal, usePerformanceMetrics } from './hooks';

import 'swiper/css';
import 'swiper/css/bundle';

import './LibraryStats.scss';
import { SET_METRICS, TOGGLE_METRIC } from '../../ReportCard/reducer';
import EditorItem from './EditorItem';
import usePortal from 'react-cool-portal';
import Dropdown from '../../../../components/Dropdowns/Dropdown';
import { useViewName } from '../../selectors';
const grid = 8;

export function useRcParam() {
  const params = new window.URLSearchParams(window.location.search);

  return params.get('rc') || null;
}

const getItemStyle = (isDragging, draggableStyle) => ({
  borderRadius: '7px',
  display: 'inline-block',
  userSelect: 'none',
  // padding: '0.5rem',
  padding: '2px',
  margin: `0 ${grid}px 0 0`,
  background: isDragging ? 'rgba(40, 47, 63, 0.95)' : '',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver, itemsLength) => {
  return {
    background: isDraggingOver
      ? 'rgba(71, 77, 98, 0.95)'
      : 'rgba(25, 29, 45, 0.95)',
    display: 'flex',
    width: `calc(100vw - 360px - ${itemsLength * 150 + 100})`,
    maxWidth: 'calc(100vw - 450px)',
    padding: '1.2rem 0.6rem',
    overflowX: 'overlay',
    whiteSpace: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 'fit-content',
  };
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);

  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const LibraryStats = ({
  filteredVisuals,
  isDirectMail,
  dispatchReportCardSettings,
  gaEnabled,
}) => {
  const { isROAS, reportCardMetric, metricOptions, reportCardSettings } =
    useCreativeAnalyticsGet();
  const viewName = useViewName();
  const isVeterans = metricOptions.some((option) => option.value === 'cpik');
  const renderDraggable = useDraggableInPortal();
  const rc = useRcParam();
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const { Portal, toggle, hide, isShow } = usePortal({
    containerId: 'dropdown-root',
    defaultShow: false,
  });

  const { metric_order } =
    reportCardSettings;

  const metricsData = usePerformanceMetrics({
    isDirectMail,
    filteredVisuals,
    isROAS,
    isVeterans,
    gaEnabled,
    viewName,
  });

  const filteredMetrics = getFilteredMetrics(
    metricsData,
    reportCardSettings,
    isDirectMail,
    viewName
  );

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const ordered = reorder(
      metric_order,
      result.source.index,
      result.destination.index
    );

    dispatchReportCardSettings({
      type: SET_METRICS,
      metric_order: ordered,
    });
  };

  const handleToggle = (metric) => {
    dispatchReportCardSettings({
      type: TOGGLE_METRIC,
      metric,
      filteredMetrics,
    });
    hide();
  };

  const handleMenu = (e) => {
    setX(e.clientX);
    setY(e.clientY);
    toggle();
  };

  const options = useMemo(() => {
    return metricsData.filter(
      (x) => !filteredMetrics.find((y) => x.metric === y.metric)
    );
  }, [metricsData, filteredMetrics]);

  return (
    <div>
      <Portal>
        <Dropdown
          title="metrics"
          style={{
            top: 'auto',
            right: `calc(95vw - ${x}px + -90px)`,
            bottom: '128px',
            maxHeight: 'calc(100vh - 150px)',
          }}
        >
          <Dropdown.Title title="ADD METRIC" />
          {options.map((metric) => (
            <Dropdown.Item
              option={metric.metric || metric.label}
              onClick={() => handleToggle(metric)}
            />
          ))}
        </Dropdown>
      </Portal>
      <div
        className="library-stats__wrapper"
        style={rc === '0' ? { transform: 'none' } : {}}
      >
        <div className="library-stats">
          <div
            className="library-stats__add"
            onClick={(e) => {
              handleMenu(e);
            }}
          >
            <i class="fa-solid fa-plus"></i>
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable3" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(
                    snapshot.isDraggingOver,
                    filteredMetrics.length
                  )}
                  className={`dnd-container ${
                    snapshot.isDraggingOver ? 'is-dragging-over' : ''
                  }`}
                >
                  {filteredMetrics.map((custom_metric) => {
                    return (
                      <Draggable
                        key={custom_metric.metric}
                        draggableId={custom_metric.metric}
                        index={custom_metric.metricIndex}
                      >
                        {renderDraggable((provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                            className="dnd-item"
                          >
                            <EditorItem
                              metric={custom_metric}
                              enabled={custom_metric.enabled}
                              isDragging={snapshot.isDragging}
                              isDraggingOver={snapshot.isDraggingOver}
                              handleToggle={handleToggle}
                            />
                          </div>
                        ))}
                      </Draggable>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </div>
  );
};

export default LibraryStats;
