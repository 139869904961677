import React, { useEffect, useRef, useState } from 'react';
import './RecsCopilot.scss';
import '../../CreativeCopilot/Output/Output.scss';
import { CopilotIcon } from '../../CreativeCopilot/components/CopilotIcon';
import LoadingMessages from '../../../../components/Helpers/LoadingMessages';
import { LOADING_MESSAGES } from '../../CreativeCopilot/constants';
import CopilotButton from '../../CreativeCopilot/components/CopilotButton';
import { useCopyToClipboard } from '../../../../hooks/useCopyToClipboard';
import parse from 'html-react-parser';
import { useCategories } from '../../hooks';
import { ColumnPicker } from '../../PerformanceFrequency/Pickers';
import GridIcon from '../../../../components/Icons/GridIcon';
import Button from '../../../../components/Button';
import { useCreativeAnalyticsGet } from '../../contexts';
import { Table } from '../../PerformanceFrequency/Table/Table';
import Tooltip from '../../../../components/Tooltip';
import { useRightDrawerContext } from '../../RightDrawer';
import { useCreativeAnalyticsApis } from '../../api';
import { useCopilotPayload } from '../../hooks/useCopilotPayload';

const RecsCopilot = ({ showCopilot, setShowCopilot, recs, selectedRecs }) => {
  const { clientMetrics, columns, handleColumns } = useCreativeAnalyticsGet();
  const { isCopied, copyToClipboard, copiedText } = useCopyToClipboard();
  const ref = useRef();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [chatStyle, setChatStyle] = useState(false);
  const { tableData } = useCategories();
  const categoryTableData = tableData?.find(
    (data) => data.category_name === selectedCategory
  );
  const { setDrawerWidth } = useRightDrawerContext();
  const [isRevision, setIsRevision] = useState(false);
  const [previousOutput, setPreviousOutput] = useState('');
  const [userFeedback, setUserFeedback] = useState('');
  const [copilotOutput, setCopilotOutput] = useState({
    summary: '',
    reasons: [],
  });
  const { copilotApi } = useCreativeAnalyticsApis();
  const {
    data: copilotData,
    loading: copilotLoading,
    error: copilotError,
    request: copilotRequest,
  } = copilotApi.post;

  const copilotPayload = useCopilotPayload({
    indexData: false,
    isRevision: previousOutput.length ? true : false,
    userInstructions: `Here are some instructions on what the client definitely wants included or excluded in the creative request. ${recs
      .filter((rec) =>
        selectedRecs.some((r) => r.recommendation === rec.recommendation)
      )
      .map(
        (rec, i) =>
          `${i + 1}. Category: ${rec.category}, Recommendation: ${
            rec.recommendation
          }, Why: ${rec.why}`
      )}`,
    previousOutput: previousOutput || 'None',
    userFeedback: userFeedback || 'None',
    requestType: 'creative request',
    visualExample: null,
    includeDna: true,
  });

  const requestSuccess = copilotData && copilotData.recommendation_summary;
  const requestError =
    copilotError || (copilotData && !copilotData.recommendation_summary);

  const fetchCopilot = () => {
    if (selectedRecs.length) {
      copilotRequest(copilotPayload);
    }
  };

  useEffect(() => {
    if (requestSuccess) {
      setCopilotOutput({
        summary: copilotData.recommendation_summary,
        reasons: copilotData.recommendation_reasons_array,
      });
      setPreviousOutput(copilotData.recommendation_summary);
    }
  }, [requestSuccess]);

  useEffect(() => {
    if (showCopilot) {
      setDrawerWidth(900);
    } else {
      setDrawerWidth(500);
    }
  }, [showCopilot]);

  const { category_name, category_data, labels } = categoryTableData || {};
  const textareaRef = useRef(null);
  const handleClick = (e) => {
    e.stopPropagation();
    textareaRef.current.focus();
  };

  const handleFocus = (e) => {
    e.target.select();
    // execCommand is deprecated; only used for the workaround
    document.execCommand('copy');
  };

  useEffect(() => {
    ref.current?.focus();
  }, [copiedText]);

  const SelectedRecs = (
    <div className="d-flex flex-column justify-content-center">
      <div className="selected-recs">
        <div className="copilot-section">
          <span>Include:</span>
          <ul>
            {selectedRecs.map((rec) => {
              if (rec.category === 'Test More') {
                return <li className="rec">{rec.recommendation}</li>;
              }
            })}
          </ul>
        </div>
        <div className="copilot-section">
          <span>Exclude:</span>
          <ul>
            {selectedRecs.map((rec) => {
              if (rec.category === 'Test Less') {
                return <li className="rec">{rec.recommendation}</li>;
              }
            })}
          </ul>
        </div>
      </div>
    </div>
  );

  const handleReset = () => {
    setIsRevision(false);
    setUserFeedback(false);
    setPreviousOutput('');
    setSelectedCategory(null);
    setCopilotOutput({
      summary: '',
      reasons: [],
    });
  };

  const regex = /(<([^>]+)>)/gi;

  let Display = (
    <div
      className="d-flex align-items-center justify-content-center flex-column"
      style={{ height: '40%' }}
    >
      <CopilotIcon loading={copilotLoading} button={false} />
      <LoadingMessages messages={LOADING_MESSAGES} delay={5000} />
    </div>
  );

  if (requestError) {
    Display = <div>There was an error.</div>;
  }

  if (!copilotLoading && copilotOutput.summary) {
    Display = (
      <div className="output">
        <div className="output__summary">
          <div
            className="clipboard-workaround"
            style={{ position: 'absolute', left: '-5000px' }}
          >
            <input
              ref={ref}
              type="text"
              value={copiedText}
              onFocus={handleFocus}
            />
          </div>
          <button
            className="copy-button"
            onClick={() =>
              copyToClipboard(copilotOutput.summary.replace(regex, ''))
            }
          >
            <i className="fa-regular fa-copy" />
          </button>
          {isCopied && <div className="copied">Copied!</div>}
          <div className="summary-text">{parse(copilotOutput.summary)}</div>
          <div
            className="d-flex justify-content-end"
            style={{
              position: 'absolute',
              bottom: '1rem',
              right: '1rem',
              zIndex: 1,
            }}
          >
            <CopilotButton
              handleClick={() => {
                if (!openFeedback) {
                  setOpenFeedback((prev) => !prev);
                } else if (userFeedback.trim().length) {
                  fetchCopilot();
                }
              }}
              disableButton={false}
              loading={copilotLoading}
              text={openFeedback ? 'Submit' : 'Give feedback'}
              className={`${openFeedback ? 'no-shadow' : ''}`}
            />
          </div>
          <div
            className={`feedback ${openFeedback ? 'open' : ''} ${
              chatStyle ? 'chat' : ''
            }`}
          >
            <div
              className="minimize-button"
              onClick={() => setOpenFeedback(false)}
            >
              <i className="fa-solid fa-minus" />
            </div>
            <div className="text-container">
              <textarea
                onClick={handleClick}
                ref={textareaRef}
                className="text-input"
                onChange={(e) => setUserFeedback(e.target.value)}
                onBlur={() => {
                  if (userFeedback.trim().length) {
                    setChatStyle(true);
                  }
                }}
                onFocus={() => setChatStyle(false)}
              />
            </div>
          </div>
        </div>
        <div className={`output__reasons`}>
          <div className="reasons">
            {copilotOutput.reasons.map(
              ({ category_name, label_name, bold_reason, description }) => {
                return (
                  <div className="reason">
                    <div className="title">{bold_reason}</div>
                    <div className="description">{description}</div>
                    <div
                      className="breakdown"
                      onClick={() => setSelectedCategory(category_name)}
                    >
                      See full breakdown: <span>{category_name}</span>
                      {` >`}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
        {categoryTableData && (
          <div className="pt-5 mt-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex">
                <Button
                  className="mr-2"
                  style={{ height: '30px', padding: '0 6px' }}
                  appearance="raised"
                  active={columns.includes('visuals')}
                  onClick={() => {
                    handleColumns({
                      column: 'visuals',
                      checked: !columns.includes('visuals'),
                    });
                  }}
                >
                  <GridIcon className="mr-2" /> Show visuals
                </Button>
                <ColumnPicker
                  columns={columns}
                  clientMetrics={clientMetrics}
                  onChange={handleColumns}
                />
              </div>
              <div>
                <i
                  className="fa fa-close"
                  onClick={() => setSelectedCategory(null)}
                  style={{
                    fontSize: '1.2rem',
                    color: '#616161',
                    marginRight: '2rem',
                    cursor: 'pointer',
                  }}
                />
              </div>
            </div>
            <div className="report pl-0">
              <div className="report__header mb-4">
                <h3 className="report__title">
                  Performance by <span>{category_name}</span>
                </h3>
              </div>
              <Table
                category_name={category_name}
                categoryData={category_data}
                categoryLabels={labels}
                isCompare={false}
                columns={columns}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={`recs-copilot ${showCopilot ? 'show' : ''}`}>
      <div className="d-flex justify-content-end">
        <Tooltip content="Reset">
          <div className="reset-button" onClick={handleReset}>
            <i className="fa fa-rotate-right" />
          </div>
        </Tooltip>
        <div className="close-button" onClick={() => setShowCopilot(false)}>
          <i className="fa fa-close" />
        </div>
      </div>
      {SelectedRecs}
      <div className="d-flex w-100 justify-content-center">
        {
          <CopilotButton
            handleClick={() => fetchCopilot()}
            disableButton={
              copilotLoading ||
              !selectedRecs.length ||
              (requestSuccess && copilotOutput.summary.length)
            }
            loading={copilotLoading}
            text={`Generate creative request`}
            className="generate-request"
          />
        }
      </div>
      {Display}
    </div>
  );
};

export default RecsCopilot;
