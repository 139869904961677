import { getTotal } from './getTotal';
import { getAggregate } from './getAggregate';
import { getAverage } from './getAverage';
import { getMedian } from './getMedian';
import { useCreativeAnalyticsGet } from '../../../contexts';
import { NOOM_CUSTOM_METRICS } from '../../../PerformanceFrequency/constants';

export const getClientMetricValues = ({
  clientMetrics,
  isDirectMail,
  isCompetitorView,
  isOrganicView,
  visuals,
  isNoom,
}): {} => {
  let clientMetricsValues;

  if (clientMetrics?.length) {
    if (isCompetitorView) {
      clientMetricsValues = Object.assign(
        {},
        ...clientMetrics.map((metric) => ({
          [`${metric}`]: getTotal(visuals, metric),
          [`${metric} per post`]: getTotal(visuals, metric) / visuals.length,
        }))
      );
    } else if (isOrganicView) {
      clientMetricsValues = Object.assign(
        {},
        ...clientMetrics.map((metric) => ({
          [`Average ${metric}`]: getAverage(
            visuals,
            metric,
            clientMetrics,
            isDirectMail,
            isOrganicView
          ).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          [`Total ${metric}`]: getTotal(visuals, metric).toLocaleString(
            'en-US',
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          ),
        }))
      );
    } else if (isNoom) {
      clientMetricsValues = NOOM_CUSTOM_METRICS.reduce((acc, metric) => {
        const totalSpend = getTotal(visuals, 'spend');
        const totalSignups = getTotal(visuals, 'Signups');
        const totalLTV = getTotal(visuals, 'LTV');

        if (['Signups', 'LTV'].includes(metric)) {
          acc[metric] = getTotal(visuals, metric);
        } else if (totalSignups > 0) {
          if (metric === 'cp_Signups') {
            acc[metric] = totalSpend / totalSignups;
          }

          if (metric === 'Average LTV') {
            acc[metric] = totalLTV / totalSignups;
          }

          if (metric === 'LTV to CAC Ratio') {
            acc[metric] = totalLTV / totalSignups / (totalSpend / totalSignups);
          }
        } else {
          acc[metric] = 0;
        }

        return acc;
      }, {});
    } else {
      clientMetricsValues = Object.assign(
        {},
        ...clientMetrics.map((metric) => ({
          [`Aggregate ${metric}`]: getAggregate(
            visuals,
            metric,
            clientMetrics,
            isDirectMail
          ),
          [`Average ${metric}`]: getAverage(
            visuals,
            metric,
            clientMetrics,
            isDirectMail
          ),
          [`Median ${metric}`]: getMedian(
            visuals,
            metric,
            clientMetrics,
            isDirectMail
          ),
        }))
      );
    }
  }

  return clientMetricsValues;
};
