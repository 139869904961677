import { batch } from 'react-redux';

import { setError } from '../../Errors/actions';
import { setLoader } from '../../Loaders/actions';
import { MARKETPLACE } from '../actions';
import { hasRoles } from '../../../cookies';

export const PREVIEWS = `${MARKETPLACE}.previews`;

export const Previews = {
  SET: `[${PREVIEWS}] SET`,
};

export function setMarketplacePreviews(data, version_id, publish_access) {
  return {
    type: Previews.SET,
    data,
    publish_access,
    meta: { version_id },
  };
}

export function fetchMarketplacePreviews({
  filename,
  project_id,
  version,
  settings = {},
}) {
  return async (dispatch) => {
    batch(() => {
      dispatch(setLoader(PREVIEWS, true));
      dispatch(setError(PREVIEWS, null));
    });

    try {
      const res = await fetch(
        ['api', 'marketplace', 'assets', project_id, 'previews'].join('/'),
        {
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ filename, version, ...settings }),
          method: 'POST',
        }
      );
      const json = await res.json();
      console.log(json);

      if (res.status === 422) {
        return batch(() => {
          dispatch(setMarketplacePreviews(json.data, version, false));
          dispatch(setLoader(PREVIEWS, false));
        });
      }
      if (res.status !== 200) {
        if (hasRoles(['client'])) {
          return batch(() => {
            dispatch(setMarketplacePreviews(json.data, version, true));
            dispatch(setLoader(PREVIEWS, false));
          });
        } else {
          return batch(() => {
            dispatch(setError(PREVIEWS, json.error));
            dispatch(setLoader(PREVIEWS, false));
          });
        }
      }
      return batch(() => {
        dispatch(setMarketplacePreviews(json.data, version, true));
        dispatch(setLoader(PREVIEWS, false));
      });
    } catch (e) {
      return batch(() => {
        dispatch(setError(PREVIEWS, e));
        dispatch(setLoader(PREVIEWS, false));
      });
    }
  };
}
