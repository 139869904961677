import React, { useState, useMemo, useEffect } from 'react';
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from '../contexts';
import Grid from './Grid';
import './VisualsLibrary.scss';
import { useActionName, useViewName } from '../selectors';
import { getVisualsData, labelsAreMatching, getAllVisuals } from './utils';
import LibraryStats from './LibraryStats';
import Tooltip from '../../../components/Tooltip';
import BestPerformingIcon from '../../../components/Icons/BestPerformingIcon';
import { useLibrary } from './hooks';
import LibraryLabels from './LibraryLabels';
import LibrarySearch from './LibrarySearch';

const Header = ({
  library,
  LibrarySearch,
  LibraryExport,
  LibraryBestPerforming,
  LibraryLabels,
  filteredSearchLookup,
}) => {
  const { isNonPaidMediaType, isUltraLight } = useCreativeAnalyticsGet();

  return (
    <>
      {/* <div className="visuals-library-header">
        {LibrarySearch}
        {!isNonPaidMediaType && !isUltraLight && (
          <div className="visuals-library-header__options">
            {LibraryExport}
            {LibraryBestPerforming}
          </div>
        )}
      </div> */}
      {LibraryLabels}
    </>
  );
};

const Wrapper = ({ Header, Grid, Stats }) => {
  return (
    <div className="visuals-library">
      {Header}
      {Grid}
      {Stats}
    </div>
  );
};

const LibraryBestPerforming = ({ filteredVisuals = [] }) => {
  const { setParams } = useCreativeAnalyticsSet();

  const disabled = !filteredVisuals.length;

  const handleShowBestPerforming = () => {
    if (disabled) return;
    setParams({ rc: '0' });
  };

  return (
    <>
      <Tooltip content="Best Performing">
        <div>
          <BestPerformingIcon
            onClick={handleShowBestPerforming}
            className={`best-performing-icon ${disabled ? 'disabled' : ''}`}
          />
        </div>
      </Tooltip>
    </>
  );
};

// data format for each visual card?

const VisualsLibrary = ({
  isROAS,
  sort,
  show_request,
  isDirectMail,
  filteredVisuals,
  gaEnabled,
  LibraryExport,
  LabelSetupIcon,
  filteredSearchLookup,
  dispatchReportCardSettings,
}) => {
  const { library, dispatchLibrary } = useLibrary();
  const { filteredData, allVisuals } = useCreativeAnalyticsGet();
  const viewName = useViewName();

  const visualsData = useMemo(() => {
    return getVisualsData(sort, filteredVisuals, isROAS);
  }, [sort, filteredVisuals]);

  if (!visualsData.length) {
    return (
      <div className="visuals-library">
        <p>No visuals found.</p>
      </div>
    );
  }

  return (
    <Wrapper
      Header={
        <Header
          LibrarySearch={
            <LibrarySearch
              library={library}
              dispatchLibrary={dispatchLibrary}
              searchLookup={filteredSearchLookup}
            />
          }
          LabelSetupIcon={LabelSetupIcon}
          LibraryLabels={
            <LibraryLabels
              library={library}
              dispatchLibrary={dispatchLibrary}
            />
          }
          LibraryBestPerforming={
            <LibraryBestPerforming {...{ filteredVisuals }} />
          }
          {...{ LibraryExport, filteredSearchLookup, library }}
        />
      }
      Grid={<Grid {...{ visualsData, show_request, viewName }} />}
      Stats={
        <LibraryStats
          {...{ filteredVisuals, dispatchReportCardSettings, isDirectMail, allVisuals, gaEnabled }}
        />
      }
    />
  );
};

export default VisualsLibrary;
