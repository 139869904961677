import React from 'react';

const Tables = ({ tables, showAllMetrics }) => {
  const filtered = tables.filter((table, i) => {
    if (!showAllMetrics && [0, 2, 4].includes(i)) {
      return false;
    }
    return true;
  })
  
  return (
    filtered.map((table) => (
      <div className="efficiency__group">
        <h2 className="efficiency__heading">{table.heading}</h2>
        <h3 className="efficiency__subheading">{table.subheading}</h3>
        <Table rows={table.rows} columns={table.columns} />
      </div>
    ))
  )
}
export default Tables;

const Table = ({ rows, columns }) => {
  return (
    <table class="table table-bordered">
       <thead className="thead-dark">
         <tr>
           <th scope="col"></th>
           {columns.slice(1).map((column) => (
             <th scope="col">{column}</th>
           ))}
         </tr>
       </thead>
       <tbody>
         {rows.map((row) => (
           <tr className="thead-light">
            <th scope="row">{row.name}</th>
            {row.data.slice(1).map((item) => (
              <td>{item ? item : '-'}</td>
            ))}
          </tr>
         ))}
       </tbody>
      </table>
  )
}