import React, { useState } from 'react';
import { MoreSettings } from './MoreSettings';
import { CloseIcon } from '../../../components/Icons';
import { useCreativeAnalyticsGet } from '../contexts';

const MenuItem = ({
  show = true,
  id,
  icon,
  text,
  selectedMenuId,
  handleMenuClick,
}) => {
  if (!show) return null;

  return (
    <li
      key={id}
      className={`menu-item ${
        selectedMenuId === id ? 'menu-item--selected' : ''
      }`}
      onClick={() => handleMenuClick(id)}
    >
      <div className="menu-item__icon">{icon}</div>
      <div className="menu-item__text">{text}</div>
    </li>
  );
};

export const Settings = ({
  CategoryEditor,
  LabelSetup,
  MoreSettings,
  GlobalKeywordSettings,
  RefreshReport,
  HideLabels,
  SuccessConditions,
  onClose,
}) => {
  const { isDirectMail } = useCreativeAnalyticsGet();
  const [selectedMenuId, setSelectedMenuId] = useState('manage-categories');
  let Render = <p>There was an error.</p>;

  const handleMenuClick = (menuId: string) => {
    setSelectedMenuId(menuId);
  };

  switch (selectedMenuId) {
    case 'manage-categories':
      Render = CategoryEditor;
      break;
    case 'label-setup':
      Render = LabelSetup;
      break;
    case 'more-settings':
      Render = MoreSettings;
      break;
    case 'refresh-report':
      Render = RefreshReport;
      break;
    case 'keyword-settings':
      Render = GlobalKeywordSettings;
      break;
    case 'hide-labels':
      Render = HideLabels;
      break;
    case 'success-conditions':
      Render = SuccessConditions;
      break;
    default:
      break;
  }

  if (!Render) Render = <p>There was an error.</p>;

  return (
    <div className="settings">
      <div className="settings__header">
        <h5 className="m-0">Settings</h5>
        <CloseIcon onClick={onClose} height={30} width={30} />
      </div>
      <div className="settings-content">
        <div className="settings-menu">
          <ul>
            <MenuItem
              id="manage-categories"
              selectedMenuId={selectedMenuId}
              handleMenuClick={handleMenuClick}
              icon={<i className="fa fa-layer-group"></i>}
              text="Manage categories"
            />
            <MenuItem
              id="label-setup"
              selectedMenuId={selectedMenuId}
              handleMenuClick={handleMenuClick}
              icon={<i className="fa fa-wrench"></i>}
              text="Label setup"
            />
            <MenuItem
              id="keyword-settings"
              selectedMenuId={selectedMenuId}
              handleMenuClick={handleMenuClick}
              icon={<i className="fa fa-spell-check"></i>}
              text="Keyword settings"
            />
            <MenuItem
              id="hide-labels"
              selectedMenuId={selectedMenuId}
              handleMenuClick={handleMenuClick}
              icon={<i className="fa-solid fa-eye-slash"></i>}
              text="Hide Labels"
            />
            <MenuItem
              show={isDirectMail}
              id="refresh-report"
              selectedMenuId={selectedMenuId}
              handleMenuClick={handleMenuClick}
              icon={<i className="fa fa-rotate"></i>}
              text="Refresh report data"
            />
            <MenuItem
              id="success-conditions"
              selectedMenuId={selectedMenuId}
              handleMenuClick={handleMenuClick}
              icon={<i className="fa fa-trophy"></i>}
              text="Success conditions"
            />
            <MenuItem
              id="more-settings"
              selectedMenuId={selectedMenuId}
              handleMenuClick={handleMenuClick}
              icon={<i className="fa fa-gear"></i>}
              text="More settings"
            />
          </ul>
        </div>

        <div className="settings-display">{Render}</div>
      </div>
    </div>
  );
};
