import React from 'react';
import './styles.scss';
import Button from '../Button';
import { CloseIcon } from '../Icons';

const Modal = ({ children, styles, className, close, bottom }) => {
  return (
    <div
      className={`dw-modal ${className ? className : ''}`}
      style={styles}
    >
      {close && <CloseIcon onClick={close} className="dw-modal__close" />}
      {children}
      {bottom && (
        <div className="dw-modal__bottom">
          {bottom}
        </div>
      )}
    </div>
  )
}

export default Modal;