export const TOGGLE_CATEGORY = 'toggle-category';
export const SET_CATEGORIES = 'set-categories';
export const SET_DATA_TYPE = 'set-data-type';
export const SET_DATA = 'set-data';
export const SET_SELECTED_METRIC = 'set-selected-metric';

const handleToggle = (categorySettings, category_name, toggleType) => {
  return categorySettings.map((c) => {
    if (c.category_name === category_name) {
      return {
        ...c,
        enabled: {
          ...c.enabled,
          [toggleType]: !c.enabled[toggleType],
        },
      };
    }
    return c;
  });
};

export function categoryEditorReducer(state, action) {
  switch (action.type) {
    case TOGGLE_CATEGORY:
      return {
        categorySettings: handleToggle(
          state.categorySettings,
          action.category_name,
          action.toggleType
        ),
        settings_changed: true,
        data_type: state.data_type,
        metric_options: state.metric_options,
        selected_metric: state.selected_metric,
      };

    case SET_CATEGORIES:
      return {
        categorySettings: action.categorySettings,
        settings_changed: true,
        data_type: state.data_type,
        metric_options: state.metric_options,
        selected_metric: state.selected_metric,
      };

    case SET_DATA_TYPE:
      return {
        categorySettings: state.categorySettings,
        settings_changed: true,
        data_type: action.data_type,
        metric_options: state.metric_options,
        selected_metric: state.selected_metric,
      };
    
    case SET_DATA:
      return {
        categorySettings: action.categorySettings,
        settings_changed: false,
        data_type: action.data_type,
        metric_options: action.metric_options,
        selected_metric: action.selected_metric,
      }

    case SET_SELECTED_METRIC:
      return {
        categorySettings: state.categorySettings,
        settings_changed: true,
        data_type: state.data_type,
        metric_options: state.metric_options,
        selected_metric: action.selected_metric,
      }


    default:
      return state;
  }
}
