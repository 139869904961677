import React, { useMemo, useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useViewSettings } from '../../../selectors';
import { isNumber, toInt } from '../../../utils/numbers';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { getMetricValue } from '../../CreativeAnalytics/PerformanceFrequency/SquaresList';
import { useCreativeAnalyticsGet } from '../../CreativeAnalytics/contexts';
import { POINT_COLORS } from './constants';
import { formatMetricValue } from '../utils';
import RightDisplay from './RightDisplay';
import { useMetricOptions } from '../../CreativeAnalytics/hooks/useMetricOptions';
import { useSearchParams } from '../../../components/Hooks/search-params';

const Scatter = ({
  rows,
  handleSeriesClick,
  selectedVisuals,
  filteredVisuals,
  selectAllVisuals,
  allAreSelected,
  deselectAllVisuals,
  setGroupParams,
}) => {
  const { primary_metric, filteredAllVisualsLabel, columnDropdownOptions } =
    useCreativeAnalyticsGet();
  const { setParams } = useSearchParams();
  const [selectedPoints, setSelectedPoints] = useState(selectedVisuals);
  const [shouldAccumulate, setShouldAccumulate] = useState(false);
  const [onlySelected, setOnlySelected] = useState(false);
  const [hoveredId, setHoveredId] = useState(null);
  const data = useMemo(() => {
    return rows.map((row) => {
      const {
        original: { fitness_score: score, id, _id },
      } = row;
      return {
        selected: selectedVisuals.includes(id) || selectedVisuals.includes(_id),
        className: `fitness-score--${score} ${
          selectedVisuals.includes(id) ? 'transform-selected' : ''
        }`,

        ...row.original,
      };
    });
  }, [selectedVisuals, primary_metric, rows]);
  const { isROAS } = useViewSettings();
  const size = useWindowSize();
  const options = useMetricOptions();
  const primaryMetricLabel =
    options.find((x) => x.value === primary_metric)?.label || primary_metric;
  const state = {
    data: data.filter((ad) => {
      if (onlySelected) {
        return (
          (selectedVisuals.includes(ad.id) ||
            selectedVisuals.includes(ad._id)) &&
          isNumber(ad[primary_metric])
        );
      }
      return isNumber(ad[primary_metric]);
    }),
    onSeriesClick: handleSeriesClick,
    showLabels: false,
    height: size.width > 1600 ? size.height * 0.5 : size.height * 0.55,
    width: size.width * 0.55,
    selectedPoints,
    setSelectedPoints,
    shouldAccumulate,
    primary_metric,
    isROAS,
    setHoveredId,
    primaryMetricLabel,
  };

  useEffect(() => {
    setGroupParams({ selectedVisuals: selectedPoints });
  }, [selectedPoints]);

  function downHandler({ key }) {
    if (key === 'Shift') {
      setShouldAccumulate(true);
    }
  }

  function upHandler({ key }) {
    if (key === 'Shift') {
      setShouldAccumulate(false);
    }
  }

  const handleToggleAllRows = () => {
    if (allAreSelected) return deselectAllVisuals();
    else return selectAllVisuals();
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  const RadialGradient = ({ id }) => {
    return (
      <radialGradient id={`gradient-${id}`} cx="0.35" cy="0.35" r="0.75">
        <stop offset="5%" stop-color="white" />
        <stop offset="0.34" stop-color={POINT_COLORS[id]['inner']} />
        <stop offset="1" stop-color={POINT_COLORS[id]['outer']} />
      </radialGradient>
    );
  };

  const scatterContainerElement = document
    .getElementsByClassName('highcharts-plot-background')[0]
    ?.getBoundingClientRect();

  const rightDisplayHeight = scatterContainerElement
    ? scatterContainerElement?.height
    : null;

  return (
    <div id="dna__scatter" className="dna__scatter">
      <svg style={{ height: '0' }}>
        <defs>
          <linearGradient id="gradient-6" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0" />
            <stop offset="1" />
          </linearGradient>
          <RadialGradient id={'1'} />
          <RadialGradient id={'2'} />
          <RadialGradient id={'3'} />
          <RadialGradient id={'4'} />
          <RadialGradient id={'5'} />
        </defs>
      </svg>
      <div className="d-flex justify-content-between">
        <div className="mr-1" onMouseLeave={() => setHoveredId(null)}>
          <HighchartsReact highcharts={Highcharts} options={makeState(state)} />
        </div>
        {filteredVisuals && filteredAllVisualsLabel && (
          <RightDisplay
            hoveredVisual={data.find((ad) =>
              [ad.id, ad._id].includes(hoveredId)
            )}
            height={rightDisplayHeight}
            {...{
              isROAS,
              primary_metric,
              primaryMetricLabel,
              selectedVisuals,
              filteredVisuals,
              filteredAllVisualsLabel,
              columnDropdownOptions,
            }}
          />
        )}
      </div>
      <div className="d-flex mt-2 chart-options">
        <div className="chart-tip">
          <span>Shift + click to select multiple points</span>
        </div>
        <div
          className="d-flex align-items-center selected-toggle ml-4"
          onClick={() => setOnlySelected((prev) => !prev)}
          style={{ cursor: 'pointer' }}
        >
          <input type="checkbox" checked={onlySelected} className="mr-2" />
          <label className="m-0">Only show selected visuals</label>
        </div>
        <div
          className="d-flex align-items-center selected-toggle ml-4"
          onClick={() => handleToggleAllRows()}
          style={{ cursor: 'pointer' }}
        >
          <input type="checkbox" checked={allAreSelected} className="mr-2" />
          <label className="m-0">Select/deselect all visuals</label>
        </div>
      </div>
    </div>
  );
};

export default Scatter;

const makeState = ({
  data,
  showLabels,
  height,
  width,
  selectedPoints,
  setSelectedPoints,
  primary_metric,
  isROAS,
  setHoveredId,
  primaryMetricLabel,
}) => {
  let click = false;

  return {
    series: [
      {
        data: data?.map((ad) => {
          return {
            x: ad.spend,
            y: ad[primary_metric],
            label: ad.id || ad._id,
            id: ad.id || ad._id,
            selected: ad.selected,
            className: ad.className,
            metricValue: getMetricValue(ad, primary_metric),
          };
        }),
        animation: {
          duration: 150,
        },
        point: {
          events: {
            mouseOver: function () {
              setHoveredId(this.id);
            },
            click: function (e) {
              let update = [];
              if (this.selected) {
                if (selectedPoints.includes(this.id)) {
                  update = selectedPoints.filter((id) => id !== this.id);
                } else {
                  update = [...selectedPoints, this.id];
                }
              } else {
                update = [this.id];
              }
              setSelectedPoints(update);
              click = true;
            },
            select: function (e) {
              let update = [];
              if (!click) {
                if (e.accumulate) {
                  if (selectedPoints.includes(this.id)) {
                    update = selectedPoints.filter((id) => id !== this.id);
                  } else {
                    update = [...selectedPoints, this.id];
                  }
                } else {
                  update = [this.id];
                }
              }
              setSelectedPoints(update);
              click = false;
            },
          },
        },
        events: {
          mouseOut: function () {
            setHoveredId(null);
          },
        },
      },
    ],
    title: {
      text: '',
    },
    xAxis: {
      title: {
        enabled: true,
        text: 'Spend',
        margin: 15,
      },
      labels: {
        useHTML: true,
        formatter: function () {
          return `$${toInt(this.value)}`;
        },
      },
    },
    yAxis: {
      title: {
        enabled: true,
        text: primaryMetricLabel,
        margin: 20,
      },
      labels: {
        useHTML: true,
        formatter: function () {
          return formatMetricValue(primary_metric, this.value, isROAS);
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    chart: {
      type: 'scatter',
      height,
      width,
      events: {
        load: function () {
          const chart = this,
            data = chart.series[0].data;
          data.map((element) => {
            element.update({
              marker: {
                radius: 6,
                states: {
                  select: {
                    opacity: 1,
                    radius: 10,
                  },
                  normal: {
                    opacity: 0.8,
                    radius: 6,
                  },
                  hover: {
                    opacity: 1,
                    radius: 6,
                  },
                },
              },
            });
          });
        },
      },
    },
    legend: {
      enabled: false,
    },

    plotOptions: {
      scatter: {},
      series: {
        marker: {
          radius: 6,
          states: {
            select: {
              opacity: 1,
              radius: 10,
            },
            normal: {
              opacity: 0.8,
              radius: 6,
            },
            hover: {
              opacity: 1,
              radius: 6,
            },
          },
        },
        dataLabels: {
          enabled: showLabels,
          formatter() {
            return this.point.label.slice(0, 20);
          },
          x: 15,
          y: -10,
        },
        scatter: {},
        className: 'ui-notify-point',
        animation: false,
      },
    },
  };
};
