import React, { useState } from 'react';
import { useReportCardContext } from '../contexts';
import './Dropdown.scss';
import { SET_SELECTED_METRIC } from '../reducer';

const DropdownItem = ({
  title,
  Icon,
  submenu,
  activeSubMenu,
  setActiveSubMenu,
  action,
  selected,
  clickFn,
  primary_metric,
}) => {
  const { dispatchReportCardSettings } = useReportCardContext();
  const [showSubmenu, setShowSubmenu] = useState(false);

  const handleSubmenuHover = () => {
    if (submenu) {
      setActiveSubMenu(title);
    }
  };

  const handleClick = () => {
    if (action) {
      dispatchReportCardSettings(action);
      if (primary_metric) {
        dispatchReportCardSettings({
          type: SET_SELECTED_METRIC,
          selected_metric: primary_metric,
        });
      }
    } else if (clickFn) {
      clickFn();
    }
  };

  return (
    <>
      <li
        className="menu-item"
        onMouseOver={() => setShowSubmenu(true)}
        onMouseOut={() => setShowSubmenu(false)}
        onClick={handleClick}
      >
        <div className="d-flex align-items-center flex-grow-1">
          <div className="d-flex align-items-center flex-grow-1">
            {!!Icon && Icon}
            <p className="menu-item__title">{title}</p>
          </div>
          {submenu && <i className="fa-solid fa-chevron-right"></i>}
          {selected && <i className="fa-solid fa-check"></i>}
        </div>
        <div className={`submenu ${showSubmenu ? 'expanded' : ''}`}>
          {submenu}
        </div>
      </li>
    </>
  );
};

export const Dropdown = ({
  options,
  activeSubMenu,
  setActiveSubMenu,
  openedMenu,
  handleHover,
}) => {
  return (
    <div
      className={`dropdown-options dropdown-options--preferences ${
        openedMenu === 'preferences' ? 'expanded' : ''
      }`}
      onMouseOver={handleHover}
      onMouseOut={handleHover}
    >
      <ul>
        {options.map((option, i) => {
          return (
            <DropdownItem
              {...{ ...option, activeSubMenu, setActiveSubMenu, openedMenu }}
            />
          );
        })}
      </ul>
    </div>
  );
};
