import React from 'react';
import CategoryBuilder from '../../../CategoryBuilder';

export const CCCEditor = ({ currentView, singleToFull }) => {
  const { category, showDerivativeFlow } = currentView;
  const { category_name, category_id, ccc_type, visual_hash } = category;
  let Render = null;

  if (ccc_type === 'ai_driven' && visual_hash) {
    Render = (
      <CategoryBuilder
        singleToFull={singleToFull}
        editCategoryVisual={{
          category: category_name,
          category_id,
          visual_hash,
          ccc_type,
          type: ccc_type === 'standard' ? 'standard' : 'custom',
          show: true,
          shouldShowBoxPlot: false,
        }}
      />
    );
  } else {
    Render = (
      <CategoryBuilder
        category={{
          category: category_name,
          category_id,
          ccc_type,
          shouldShowBoxPlot: false,
        }}
        showDerivativeFlow={showDerivativeFlow || false}
      />
    );
  }
  return <div className="ccc-drawer-wrapper">{Render}</div>;
};
