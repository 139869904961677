import React from 'react';
import PropTypes from 'prop-types';

import { WhenLoading } from '../Helpers/Loading';
import { WhenError } from '../Helpers/Error';

const LoginBoundary = ({ hasError, isLoading, children }) => (
  <WhenError hasError={hasError}>
    <WhenLoading isLoading={isLoading}>{children}</WhenLoading>
  </WhenError>
);

LoginBoundary.defaultProps = {
  hasError: false,
  isLoading: false,
};

LoginBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  hasError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default LoginBoundary;
