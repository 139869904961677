import React, { useMemo } from 'react';
import {
  useCreativeRequestGet,
  useCreativeRequestSet,
} from '../../CreativeRequest/contexts';
import { DATA_POINTS } from '../../CreativeRequest/constants';
import Tooltip from '../../../../components/Tooltip';
import { useCreativeAnalyticsGet } from '../../contexts';
import { getDistPValue } from '../../utils/distribution-p-value';
import { Trophy } from '../Columns';
import { toFixed_2 } from '../../../../utils/numbers';
import { useVisuals, useVisualsLookup } from '../../selectors';

const DISABLED = ['All Visuals', 'All New Visuals', 'Top 10 Spending Visuals'];

export const DistributionPValue = ({ row: { original: label } }) => {
  const { primary_metric } = useCreativeAnalyticsGet();
  const visualsLookup = useVisualsLookup();
  const allVisuals = useVisuals();

  const pValue = useMemo(() => {
    let result = null;

    const visuals = label.visual_ids.map((id) => visualsLookup.get(id));

    try {
      result = getDistPValue(
        visuals ?? [],
        allVisuals ?? [],
        primary_metric,
        1000
      );
    } catch (e) {
      console.log('Error calculating distribution p value:');
      console.log(e);
    }

    return result;
  }, [visualsLookup, label, allVisuals, primary_metric]);

  if (!pValue) return '-';

  const significant = pValue < 0.05 && label.denominator > 1;
  const potential = pValue > 0.05 && pValue < 0.2 && label.denominator > 1;

  return (
    <div className="d-flex justify-content-center">
      {significant && (
        <span style={{ marginRight: '0.5rem' }}>
          <Trophy
            content="Statistically Significant: A p-value less than 0.05 (typically ≤ 0.05) is statistically significant. It indicates strong evidence against the null hypothesis, as there is less than a 5% probability the null is correct (and the results are random)."
            color="#FFB673"
          />
        </span>
      )}
      {label.name !== 'All Visuals' ? (
        <Tooltip
          content={`What this means: There is a ${parseFloat(
            pValue
          ).toLocaleString(undefined, {
            style: 'percent',
            minimumFractionDigits: 0,
          })} chance that the “Win Rate” for the ${
            label.visual_ids.length
          } visuals with the "${label.name}" label is due to random chance.`}
          className="frequency-table__tooltip"
          follow
        >
          <p>{`${toFixed_2(pValue)}`}</p>
        </Tooltip>
      ) : (
        <p>-</p>
      )}
    </div>
  );
};
