import { customNanoId } from '../../../utils/nanoid';
import {
  FORMAT,
  BRAND,
  PROMO,
  PROMOTION_TYPE,
  KEYWORD,
  PHRASES,
} from '../categories';
import { sortReportCardLabels } from '../ReportCard/utils';
import { getCCCType } from '../utils';

const formatLookupLabel = (label: LabelObject) => {
  const {
    id,
    name,
    value,
    category,
    spend,
    visual_ids,
    ccc,
    udc,
    type,
    ad_name_label,
  } = label;
  return {
    id,
    name,
    value,
    category,
    spend,
    visual_ids,
    ccc,
    udc,
    type,
    ad_name_label,
  };
};

export const makeCategoriesLookup = ({
  labels,
  isROAS = false,
  isDirectMail = false,
  reportCardDataType,
  viewName = '',
  reportCardMetric,
  spendThreshold = 1,
}) => {
  if (!labels) return null;
  let result = {};
  const nanoid = customNanoId();

  labels.forEach((label) => {
    const category = label.category || null;
    const category_id = label.category_id || nanoid();

    if (category && category_id) {
      if (result[category]) {
        result[category] = {
          ...result[category],
          labels: [...result[category].labels, formatLookupLabel(label)],
        };
      } else {
        result[category] = {
          category_id,
          category,
          labels: [formatLookupLabel(label)],
          isUdc: label.udc,
          synonym: label.value.slice(0, 4) === 'CCC_',
          derived: label.type === 'derived_label',
          ccc: label.ccc,
          ad_name_label: label.ad_name_label,
          ccc_type: getCCCType(label),
          ccc_desc: label.ccc ? label.description : null,
        };
      }
    }
  });

  [FORMAT, BRAND, PROMO, PROMOTION_TYPE, KEYWORD, PHRASES].forEach(
    (category) => {
      if (!result[category]) {
        result[category] = {
          category,
          labels: [],
        };
      }
    }
  );

  const getCategoryVisuals = (category) => {
    let distinctVisuals = new Set();
    category.labels.forEach(({ visual_ids }) => {
      if (!visual_ids) console.log({ visual_ids, category });
      return visual_ids?.forEach((id) => distinctVisuals.add(id));
    });
    return Array.from(distinctVisuals);
  };

  Object.entries(result).forEach(([key, category]) => {
    const isSynonym = (label) => {
      if (label.value.slice(0, 4) === 'CCC_' || label.type === 'synonyms') {
        return true;
      }
    };

    result[key] = {
      ...result[key],
      ccc: category.labels.some((x) => x.ccc),
      synonym: category.labels.some((x) => isSynonym(x)),
      udc: category.labels.some((x) => x.type === 'user_defined_labels'),
      derived: category.labels.some((x) => x.type === 'derived_label'),
      ad_name_label: category.labels.some((x) => x.ad_name_label),
      labels: category.labels.sort(
        sortReportCardLabels(
          isROAS,
          isDirectMail,
          reportCardDataType,
          viewName,
          reportCardMetric,
          key === 'Format'
        )
      ),
      hasData: category.labels.some(
        (label) => label.spend >= parseInt(spendThreshold)
      ),
      visuals: getCategoryVisuals(category),
      multiple_labels: category.labels.some(
        (label) => label.ccc_type === 'multiple labels'
      ),
    };
  });

  return result;
};
