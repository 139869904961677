import { EXECUTION_STYLES, INSPIRATION, TRENDING } from '../categories';
import parse from 'html-react-parser';
import { DEFAULT_REQUEST } from './constants';

const TRY_THESE_CATEGORIES = [EXECUTION_STYLES, INSPIRATION, TRENDING];

export const withTryThese = (requests) => {
  return requests.map((request) => {
    const try_these = getTryTheseLabels(request);

    return {
      ...request,
      ...(try_these.length && { try_these }),
    };
  });
};

export const getTryTheseLabels = (request) => {
  let try_these = [];

  request.include_these.forEach((label) => {
    if (label.category && TRY_THESE_CATEGORIES.includes(label.category)) {
      try_these.push(label.value);
    }
  });

  const overview = parse(request.request_overview);

  if (request.request_overview.length) {
    if (!Array.isArray(overview)) {
      if (
        overview.props['data-category'] &&
        overview.props['data-label-value'] &&
        TRY_THESE_CATEGORIES.includes(overview.props['data-category'])
      ) {
        try_these.push(overview.props['data-label-value']);
      }
    } else {
      overview.forEach((el) => {
        if (
          el.props &&
          el.props['data-category'] &&
          el.props['data-label-value'] &&
          TRY_THESE_CATEGORIES.includes(el.props['data-category'])
        ) {
          try_these.push(el.props['data-label-value']);
        }
      });
    }
  }

  return try_these;
};
