import { EditorOptions } from './actions';
import { Marketplace } from '../actions';

function stringToOption(s) {
  return { value: s, label: s };
}

function rankedToOption({ rank, value }) {
  return { label: value, value, rank };
}

function prepOptions({
  landing_page,
  utm_parameters,
  creative_options,
  stories_creative_options,
  carousel_options,
  carousel_choices,
  atf,
  btf,
  call_to_action,
  default_preview_type,
  ad_type,
}) {
  return {
    landing_page: landing_page.map(stringToOption),
    utm_parameters: utm_parameters.map(stringToOption),
    ad_type: ad_type,
    creative_options: creative_options.map(({ version, filename }) => ({
      value: version,
      label: filename,
    })),
    stories_creative_options: stories_creative_options.map(
      ({ version, filename }) => ({
        value: version,
        label: filename,
      })
    ),
    carousel_options: carousel_options.map(({ version, filename }) => ({
      value: version,
      label: filename,
    })),
    carousel_choices: carousel_choices.map(({ version, filename }) => ({
      value: version,
      label: filename,
    })),
    atf: atf.map(rankedToOption),
    btf: btf.map(rankedToOption),
    call_to_action: call_to_action.map(rankedToOption),
    default_preview_type: default_preview_type.map((s) => ({
      label: s,
      value: s,
    })),
  };
}

export function editorOptions(state = null, action) {
  switch (action.type) {
    case EditorOptions.SET:
      return prepOptions(action.data);

    case Marketplace.RESET_ASSETS:
      return null;

    default:
      return state;
  }
}
