export const POINT_COLORS = {
  '1': {
    inner: '#FCA1CD',
    outer: '#E1619F',
  },
  '2': {
    inner: '#FFB39A',
    outer: '#DB7452',
  },
  '3': {
    inner: '#FFD490',
    outer: '#E9AD4F',
  },
  '4': {
    inner: '#f0e554',
    outer: '#ddca22',
  },
  '5': {
    inner: '#88f0a7',
    outer: '#34c05e',
  },
};

export const METRIC_VALUES_LOOKUP = {
  cpa: 'aggregate_cpa',
  roas: 'aggregate_roas',
  cpm: 'aggregate_cpm',
  cpc: 'aggregate_cpc',
  ctr: 'aggregate_ctr',
  impressions: 'total_impressions',
  results: 'total_results',
  clicks: 'total_link_clicks',
};
