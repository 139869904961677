import React, { useEffect } from 'react';
import { usePostRequest } from '../../../components/Hooks/usePostRequest';
import { useNotifications } from '../../../components/Notification/useNotifications';
import { toast } from 'react-toastify';
import { CloseIcon } from '../../../components/Icons';
import { DARWIN_NAVY } from '../../../constants';

export const useSaveOverridesApi = ({ successTitle = 'Success!', successMsg }) => {
  const notifications = useNotifications();
  const saveApi = usePostRequest(
    ['api', 'creative-analytics', 'custom-categories', 'annotate-easy-ai'].join(
      '/'
    )
  );

  useEffect(() => {
    if (saveApi.loading) {
      notifications.add({
        id: 'save-override',
        title: 'Saving changes',
        message: 'Please wait',
        showEllipses: true,
      });
    } else {
      notifications.remove('save-override');
    }
  }, [saveApi.loading]);

  useEffect(() => {
    if (saveApi.data || saveApi.data === undefined) {
      if (successMsg) {
        toast(successMsg, {
          position: 'top-right',
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          draggable: true,
          progress: undefined,
          closeButton: <CloseIcon className="toast__close" />,
          style: {
            background: DARWIN_NAVY,
            padding: 0,
          },
          toastId: 'save-override-success',
        });
      } else {
        notifications.add({
          id: 'save-override-success',
          title: 'Success!',
          message: 'Overrides saved. Run a hard refresh to see results.',
        });
      }
    }
  }, [saveApi.data]);

  return saveApi;
};
