import React, { useEffect, useState, useCallback } from 'react';
import Button from '../../../components/Button';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { RATIO_OPTIONS } from './constants';

import './CreativeRequest.scss';

import { useCreativeRequestApi } from './api';
import { ArrowIcon } from './Helpers';

import {
  RATIO,
  FORMAT,
  REQUEST_OVERVIEW,
  PRIMARY_TEXT,
  SECONDARY_TEXT,
  INCLUDE_THESE,
  AVOID_THESE,
  PRIMARY_POSITION,
  SECONDARY_POSITION,
  DEFAULT_STANDALONE_REQUEST,
  PROJECT_NAME,
  REQUEST_DESCRIPTION,
  ASSET_NAME,
  DATA_POINTS,
  CREATIVE_CODE,
} from './constants';
import { useSearchParams } from '../../../components/Hooks/search-params';
import { TagsList, Tag } from '../../../components/Tags';
import { DataPoints } from './DataPoints/DataPoints';

export default function StandaloneCreativeRequest() {
  const [request, setRequest] = useState(DEFAULT_STANDALONE_REQUEST);
  const { getParams } = useSearchParams();
  const creativeRequestApi = useCreativeRequestApi();
  const [, , request_id] = window.location.pathname.split('/');
  const { isCopied, copyToClipboard } = useCopyToClipboard();

  const handleCopyCreativeCode = useCallback(() => {
    copyToClipboard(request[CREATIVE_CODE]);
  }, [copyToClipboard]);

  useEffect(() => {
    if (request_id) {
      creativeRequestApi.get.request(request_id);
    }
  }, [request_id]);

  useEffect(() => {
    if (creativeRequestApi.get.data) {
      const { data } = creativeRequestApi.get;

      setRequest(data);
    }
  }, [creativeRequestApi.get.data]);

  if (creativeRequestApi.get.loading) {
    return <p>Loading...</p>;
  }

  if (creativeRequestApi.get.error || !request_id) {
    return <p>There was an error.</p>;
  }

  const quillModules = {
    toolbar: false,
  };

  return (
    <div className="standalone-request">
      <h1 className="request__title m-4">
        {request[PROJECT_NAME]}
        {request[ASSET_NAME] ? ` - ${request[ASSET_NAME]}` : null}
      </h1>
      <div className="request__form standalone">
        <div className="request__form-group">
          <label className="request__label" htmlFor={RATIO}>
            Ratio
          </label>
          <div className="request__input">
            {request[RATIO] ? (
              <TagsList className="flex-column">
                {RATIO_OPTIONS.map((ratio) => (
                  <div
                    className="d-flex align-items-center mb-1 mr-3"
                    style={{ height: '26px' }}
                  >
                    <div className="tag-container">
                      <Tag
                        active={request[RATIO][ratio] > 0}
                        style={{ borderRadius: '0' }}
                      >
                        {ratio}
                      </Tag>
                    </div>
                    <input
                      type="number"
                      disabled
                      // defaultValue={getDefaultValue(ratio)}
                      value={request[RATIO][ratio]}
                      min={0}
                    />
                  </div>
                ))}
              </TagsList>
            ) : (
              <p>None specified.</p>
            )}
          </div>
        </div>

        <div className="request__form-group">
          <label className="request__label" htmlFor="format">
            Format
          </label>
          <div className="request__input">
            <div className="request__value">{request[FORMAT]}</div>
          </div>
        </div>

        {request[DATA_POINTS]?.length ? (
          <DataPoints data_points={request[DATA_POINTS]} readOnly />
        ) : null}

        <div className="request__form-group">
          <label className="request__label" htmlFor={REQUEST_OVERVIEW}>
            Request Overview
          </label>
          <div className="request__input">
            <ReactQuill
              readOnly
              modules={quillModules}
              className="request-overview"
              theme="snow"
              value={request[REQUEST_OVERVIEW]}
            />
          </div>
        </div>

        <div className="request__form-group">
          <label className="request__label" htmlFor={REQUEST_DESCRIPTION}>
            Request Description
          </label>
          <div className="request__input">
            <ReactQuill
              readOnly
              modules={quillModules}
              className="request-overview"
              theme="snow"
              value={request[REQUEST_DESCRIPTION]}
            />
          </div>
        </div>

        {request[CREATIVE_CODE] && (
          <div className="request__form-group">
            <label className="request__label" htmlFor="creative_code">
              Creative Code
            </label>
            <div className="request__input align-items-center">
              <input
                id={CREATIVE_CODE}
                name={CREATIVE_CODE}
                disabled
                value={isCopied ? 'Copied!' : request[CREATIVE_CODE]}
                style={{
                  display: 'inline-block',
                  width: 'auto',
                  marginRight: '1rem',
                }}
              />
              <Button onClick={handleCopyCreativeCode}>Copy</Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
