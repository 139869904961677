import { useAbortableFetch } from "../../../hooks/useAbortableFetch";
import { useAccountId } from "../../CreativeAnalytics/selectors";

const FETCH_LABELS_ENDPOINT = [
  'api',
  'creative-analytics',
  'report-builder',
  'get-unique-mm-labels',
].join('/');

export const useFetchLabelsApi = () => {
  const { data, loading, error, request } = useAbortableFetch();
  const account_id = useAccountId();

  const fetchLabels = () => {
    request(FETCH_LABELS_ENDPOINT, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        fb_account_id: account_id,
      }),
    });
  };

  return { labels: getLabels(data), loading, error, fetchLabels };
};

const getLabels = (data) => {
  try {
    return Array.isArray(data?.data) ? data.data : [];
  } catch (e) {
    console.error('There was an error getting the labels data.');
    return [];
  }
};
