
import React from 'react';
import PropTypes from 'prop-types';
import { QueryBuilder } from '@cubejs-client/react';
import ChartRenderer from '../ChartRenderer';
import MemberGroup from './MemberGroup';
import FilterGroup from './FilterGroup';
import TimeGroup from './TimeGroup';
import SelectChartType from './SelectChartType';

const ExploreQueryBuilder = ({
  vizState,
  cubejsApi,
  setVizState,
  AddToDashboard,
}) => (
  <QueryBuilder
    vizState={vizState}
    setVizState={setVizState}
    cubejsApi={cubejsApi}
    wrapWithQueryRenderer={false}
    render={(renderProps) => {
      const {
        validatedQuery,
        isQueryPresent,
        chartType,
        updateChartType,
        measures,
        availableMeasures,
        updateMeasures,
        dimensions,
        availableDimensions,
        updateDimensions,
        segments,
        availableSegments,
        updateSegments,
        filters,
        updateFilters,
        timeDimensions,
        availableTimeDimensions,
        updateTimeDimensions,
      } = renderProps;

      const builderDisabled = chartType === 'custom_periodComparison';

      return (
        <>
          <div className="row mb-2">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 d-flex flex-row">
                      <MemberGroup
                        disabled={builderDisabled}
                        members={measures}
                        availableMembers={availableMeasures}
                        addMemberName="Measure"
                        updateMethods={updateMeasures}
                        size="300px"
                      />
                      &emsp;
                      <MemberGroup
                        disabled={builderDisabled}
                        members={dimensions}
                        availableMembers={availableDimensions}
                        addMemberName="Dimension"
                        updateMethods={updateDimensions}
                        size="200px"
                      />
                      &emsp;
                      <MemberGroup
                        disabled={builderDisabled}
                        members={segments}
                        availableMembers={availableSegments}
                        addMemberName="Segment"
                        updateMethods={updateSegments}
                        size="200px"
                      />
                      &emsp;
                      <TimeGroup
                        disabled={builderDisabled}
                        members={timeDimensions}
                        availableMembers={availableTimeDimensions}
                        addMemberName="Time"
                        updateMethods={updateTimeDimensions}
                      />
                      &emsp;
                      {AddToDashboard}
                    </div>
                  </div>
                  {measures.length > 0 && (
                    <div className="row mt-2">
                      <div className="col-md-12">
                        <FilterGroup
                          disabled={builderDisabled}
                          members={filters}
                          availableMembers={availableDimensions.concat(
                            availableMeasures,
                          )}
                          addMemberName="Filter"
                          updateMethods={updateFilters}
                        />
                      </div>
                    </div>
                  )}
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <SelectChartType
                        chartType={chartType}
                        updateChartType={updateChartType}
                        hasTime={timeDimensions.length > 0}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              {isQueryPresent || builderDisabled ? (
                <div className="card">
                  <div className="card-body">
                    <ChartRenderer
                      setVizState={setVizState}
                      useCustomRenderer={builderDisabled}
                      vizState={{
                        query: validatedQuery,
                        chartType,
                      }}
                      cubejsApi={cubejsApi}
                    />
                  </div>
                </div>
              ) : (
                <h2 className="text-center">
                  Choose a measure or dimension to get started
                </h2>
              )}
            </div>
          </div>
        </>
      );
    }}
  />
);

ExploreQueryBuilder.defaultProps = {
  vizState: {},
  setVizState: null,
  cubejsApi: null,
};

ExploreQueryBuilder.propTypes = {
  vizState: PropTypes.shape({ chartType: PropTypes.string, query: PropTypes.object }),
  setVizState: PropTypes.func,
  cubejsApi: PropTypes.objectOf(PropTypes.func),
  AddToDashboard: PropTypes.node.isRequired,
};

export default ExploreQueryBuilder;
