import React, { useEffect, useState } from 'react';
import '../../CreativeCopilot/index.scss';
import CopilotButton from '../../CreativeCopilot/components/CopilotButton';
import RecsCopilot from '../Copilot/RecsCopilot';
import { useCreativeAnalyticsApis } from '../../api';
import { useCopilotPayload } from '../../hooks/useCopilotPayload';
import { useRightDrawerContext } from '../../RightDrawer';
import Tooltip from '../../../../components/Tooltip';
import RecItem from './RecItem';
import { RetryButton } from '../components/RetryButton';
import { WarningIcon } from '../../../../components/Icons';
import Button from '../../../../components/Button';

const RecsList = ({
  recs,
  fetchRecs,
  loading,
  selectedVisualsChanged,
  selectedVisuals,
}) => {
  const [showCopilot, setShowCopilot] = useState(false);
  const [selectedRecs, setSelectedRecs] = useState([]);

  return (
    <div className="d-flex flex-column position-relative">
      <div className="recs__retry">
        {(!recs || !recs.length) && !loading ? (
          <div className="d-flex align-items-center">
            <WarningIcon className="mr-2" />
            <span>Couldn't fetch recommendations.</span>
            <RetryButton fn={() => fetchRecs(true)} />
          </div>
        ) : (
          <div
            className={`d-flex justify-content-${
              selectedVisualsChanged ? 'start' : 'end'
            }`}
          >
            <div>
              {selectedVisualsChanged ? (
                <div className="mb-3 pl-2" style={{ fontWeight: '500' }}>
                  Your selections have changed.{' '}
                  <Tooltip
                    content="Select more visuals to enable refresh"
                    disabled={selectedVisuals.length > 1}
                  >
                    <div style={{ display: 'inline' }}>
                      <Button
                        appearance={`${
                          selectedVisuals.length < 2 ? 'subtle' : 'secondary'
                        }`}
                        onClick={() => fetchRecs(true)}
                        className="ml-2"
                        disabled={selectedVisuals.length < 2}
                      >
                        Refresh
                      </Button>
                    </div>
                  </Tooltip>
                </div>
              ) : (
                <Tooltip
                  content="Refresh recommendations"
                  follow={false}
                  delay={[500, 0]}
                >
                  <button
                    className="recs-header__icon btn-reset"
                    onClick={() => fetchRecs(true)}
                  >
                    <i
                      style={{ fontSize: '14px' }}
                      className="fa fa-rotate-right"
                    ></i>
                  </button>
                </Tooltip>
              )}
            </div>
          </div>
        )}
      </div>
      {recs && recs.length ? (
        <div>
          <ul
            className="recs-list"
            style={{ maxWidth: showCopilot ? '40%' : 'none' }}
          >
            {recs.map((rec) => (
              <RecItem
                {...{
                  rec,
                  selectedRecs,
                  setSelectedRecs,
                  showCopilot,
                  setShowCopilot,
                }}
              />
            ))}
          </ul>
          <div
            className="copilot-toggle"
            style={{ maxWidth: showCopilot ? '40%' : 'none' }}
          >
            <Tooltip
              content={`${!showCopilot ? 'Open' : 'Close'} Creative Copilot`}
            >
              <div>
                <CopilotButton
                  handleClick={() => setShowCopilot((prev) => !prev)}
                  disableButton={false}
                  loading={false}
                  text={null}
                  className="icon-only"
                />
              </div>
            </Tooltip>
          </div>
        </div>
      ) : (
        <ul />
      )}
      {showCopilot && (
        <RecsCopilot
          selectedRecs={recs.filter((rec) =>
            selectedRecs.includes(rec.recommendation)
          )}
          {...{
            recs,
            showCopilot,
            setShowCopilot,
          }}
        />
      )}
    </div>
  );
};

export default RecsList;
