import React, { useState, useEffect } from 'react';
import { useSearchParams } from '../../../components/Hooks/search-params';
import { PARAM_DEFAULTS } from './constants';

/**
 * @param {{ breakdown?: string, period1?: string, period2?: string, include_ga_data?: boolean } }} params
 */
function setDefaultParams({
  metrics,
  campaigns,
  period1,
  period2,
  audience_breakdown,
  level,
  sort,
  labels,
}) {
  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);

  const base = {
    metrics: metrics || PARAM_DEFAULTS.metrics,
    campaigns: campaigns || PARAM_DEFAULTS.campaigns,
    level: level || PARAM_DEFAULTS.level,
    period1: period1 || PARAM_DEFAULTS.period1,
    period2: period2 || PARAM_DEFAULTS.period2,
    audience_breakdown: audience_breakdown || PARAM_DEFAULTS.audience_breakdown,
    sort: sort || PARAM_DEFAULTS.sort,
    labels: labels || PARAM_DEFAULTS.labels,
  };

  return base;
}

export function useVisualReportParams() {
  const { getParams, setParams } = useSearchParams();
  const params =
    getParams(
      'metrics',
      'campaigns',
      'period1',
      'period2',
      'audience_breakdown',
      'level',
      'sort',
      'labels'
    ) || {};
  const defaults = setDefaultParams(params);
  const [state, setState] = useState(defaults);

  const makeUpdate = (prev, update) => {
    const merged = { ...prev, ...update };

    return merged;
  };
  const setStateMerge = (update) => {
    setState((prev) => makeUpdate(prev, update));
  };

  useEffect(() => {
    setParams(state);
  }, [
    state.metrics,
    state.campaigns,
    state.period1,
    state.period2,
    state.audience_breakdown,
    state.level,
    state.sort,
    state.labels,
  ]);

  return [state, setStateMerge];
}
