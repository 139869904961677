//@ts-check
import React from 'react';
import PropTypes from 'prop-types';
import MemberDropdown from './MemberDropdown';

import { memberToSelection, dimensionToSelection } from './select-derivers';

// imported from rest of app
import Select from 'react-select';

const TimesForSelect = ({ members, availableMembers, children }) => {
  return children({
    members: members.map(dimensionToSelection),
    availableMembers: availableMembers.map(memberToSelection),
  });
};

const DATE_RANGES = [
  {
    title: 'All time',
    label: 'All time',
  },
  {
    value: 'Today',
    label: 'Today',
  },
  {
    value: 'Yesterday',
    label: 'Yesterday',
  },
  {
    value: 'This week',
    label: 'This week',
  },
  {
    value: 'This month',
    label: 'This month',
  },
  {
    value: 'This quarter',
    label: 'This quarter',
  },
  {
    value: 'This year',
    label: 'This year',
  },
  {
    value: 'Last 7 days',
    label: 'Last 7 days',
  },
  {
    value: 'Last 14 days',
    label: 'Last 14 days',
  },
  {
    value: 'Last 30 days',
    label: 'Last 30 days',
  },
  {
    title: 'Previous 7 days',
    value: 'Previous 7 days',
    label: 'Previous 7 days',
  },
  {
    title: 'Previous 14 days',
    value: 'Previous 14 days',
    label: 'Previous 14 days',
  },
  {
    value: 'Last week',
    label: 'Last week',
  },
  {
    value: 'Last month',
    label: 'Last month',
  },
  {
    value: 'Month before last',
    label: 'Month before last',
  },
  {
    value: 'Last quarter',
    label: 'Last quarter',
  },
  {
    value: 'Last year',
    label: 'Last year',
  },
];

const GRANULARITIES = [{ label: 'No members found', value: null }];

const TimeGroup = ({
  members,
  availableMembers,
  addMemberName,
  updateMethods,
  disabled,
}) => {
  const getGranularities = (member) =>
    member.granularities.length > 0
      ? [
          ...GRANULARITIES,
          ...member.granularities.map((m) => ({
            ...m,
            label: m.title,
            value: m.name,
          })),
        ]
      : GRANULARITIES;
  const getGranularity = (m) => {
    const maybe = m.dimension.granularities.find(
      (g) => g.name === m.granularity
    );

    return (
      { ...maybe, label: maybe.title, value: maybe.name } || GRANULARITIES[0]
    );
  };

  return (
    <span>
      {members.map((m, i) => (
        <React.Fragment key={`${m.name}-${i}`}>
          <div className="mb-2">
            <MemberDropdown
              disabled={disabled}
              selected={m}
              options={availableMembers}
              onChange={(maybeMember) => {
                if (maybeMember) {
                  updateMethods.update(m, { ...m, dimension: maybeMember });
                } else {
                  updateMethods.remove(m);
                }
              }}
              size="200px"
            />
          </div>

          <div className="d-flex flex-row align-items-center justify-content-between">
            <b>FOR</b>

            <DateRangePicker
              disabled={disabled}
              value={
                m.dateRange
                  ? { value: m.dateRange, label: m.dateRange }
                  : m.compareDateRange
                  ? {
                      value: 'Last 7 vs Previous 7 days',
                      label: 'Last 7 vs Previous 7 days',
                    }
                  : DATE_RANGES[0]
              }
              onChange={(selected) => {
                const updated = handleDateSelection(m, selected);

                return updated && updateMethods.update(m, updated);
              }}
            />

            <b>BY</b>

            <GranularityPicker
              disabled={disabled}
              value={getGranularity(m)}
              options={getGranularities(m.dimension)}
              onChange={(selected) =>
                selected && selected.value
                  ? updateMethods.update(m, {
                      ...m,
                      granularity: selected.value,
                    })
                  : selected
              }
            />
          </div>
        </React.Fragment>
      ))}
      {!members.length && (
        <MemberDropdown
          disabled={disabled}
          placeholder={addMemberName}
          selected={null}
          options={availableMembers}
          onChange={(member) =>
            updateMethods.add({
              dimension: member,
              granularity: 'day',
            })
          }
          size="200px"
        />
      )}
    </span>
  );
};

TimeGroup.propTypes = {
  members: PropTypes.array.isRequired,
  availableMembers: PropTypes.array.isRequired,
  addMemberName: PropTypes.string.isRequired,
  updateMethods: PropTypes.object.isRequired,
};

export default (props) => (
  <TimesForSelect
    members={props.members}
    availableMembers={props.availableMembers}
  >
    {(derived) => <TimeGroup {...props} {...derived} />}
  </TimesForSelect>
);

const PICKER_STYLES = {
  control: (base, { selectProps: { height, width } }) => ({
    ...base,
    width,
    height,
    minHeight: height,
  }),
  menu: (base, { selectProps: { dropdownWidth } }) => ({
    ...base,
    width: dropdownWidth,
  }),
  singleValue: (base, { selectProps: { paddingBottom } }) => ({
    ...base,
    paddingBottom,
  }),
};

function DateRangePicker({ onChange, value, disabled }) {
  return (
    <Picker
      onChange={onChange}
      value={value}
      width="80px"
      options={DATE_RANGES}
      disabled={disabled}
    />
  );
}

function GranularityPicker({ value, options, onChange, disabled }) {
  return (
    <Picker
      value={value}
      options={options}
      onChange={onChange}
      width="60px"
      disabled={disabled}
    />
  );
}

function Picker({
  onChange,
  value,
  options,
  width,
  height = '32px',
  dropdownWidth = '120px',
  paddingBottom = '0.25rem',
  disabled = false,
}) {
  return (
    <Select
      isDisabled={disabled}
      width={width}
      height={height}
      dropdownWidth={dropdownWidth}
      paddingBottom={paddingBottom}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      options={options}
      value={value}
      onChange={onChange}
      styles={PICKER_STYLES}
    />
  );
}

function handleDateSelection(measure, selected) {
  if (!selected) {
    return null;
  } else {
    const { value } = selected;
    const { dateRange, compareDateRange, ...rest } = measure;

    return { ...rest, dateRange: value };
  }
}
