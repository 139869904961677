import { CUSTOM_SCORING_METRICS } from '../PerformanceFrequency/constants';

export const getSumsAndAverages = (
  filteredVisuals = [],
  isOrganicView,
  clientMetrics,
  customScoringData,
  isNoom,
  isSkyZone
) => {
  const sumSpend = filteredVisuals.reduce(
    (total, visual) => total + visual?.spend ?? 0,
    0
  );
  const sumImpressions = filteredVisuals.reduce(
    (total, visual) => total + visual?.impressions ?? 0,
    0
  );
  const sumClicks = filteredVisuals.reduce(
    (total, visual) => total + visual?.clicks ?? 0,
    0
  );
  const sumResults = filteredVisuals.reduce(
    (total, visual) => total + visual?.results ?? 0,
    0
  );
  const sumReach = filteredVisuals.reduce(
    (total, visual) => total + visual?.reach ?? 0,
    0
  );
  const cpMetricsSums = {};
  const customMetricsSums = {};
  let customScoringSums = {};

  if (customScoringData && customScoringData.length) {
    CUSTOM_SCORING_METRICS.forEach((metric) => {
      const sum = filteredVisuals.reduce(
        (total, visual) => total + visual[metric] ?? 0,
        0
      );

      if (!customScoringSums[metric]) {
        customScoringSums = { ...customScoringSums, [metric]: sum };
      }
    });
  }

  try {
    filteredVisuals.forEach((visual) => {
      for (const metric in visual) {
        if (metric.startsWith('cp_') || isOrganicView || isNoom || isSkyZone) {
          const sumMetric = `sum_${metric}`;
          const baseMetric = metric.replace('cp_', '');

          if (!cpMetricsSums[sumMetric]) {
            cpMetricsSums[sumMetric] = visual[metric];
          } else {
            cpMetricsSums[sumMetric] += visual[metric];
          }

          if (!customMetricsSums[baseMetric]) {
            customMetricsSums[baseMetric] = filteredVisuals
              .map((i) => i[baseMetric])
              .reduce((a, b) => a + b);
          }
        }
      }
    });
  } catch (e) {
    console.error(e);
  }

  const cpmAverage =
    sumImpressions === 0 ? 0 : (sumSpend / sumImpressions) * 1000;
  const cpcAverage = sumClicks === 0 ? 0 : sumSpend / sumClicks;
  const ctrAverage = sumImpressions === 0 ? 0 : sumClicks / sumImpressions;
  const roasAverage = sumResults === 0 ? 0 : 1 / (sumSpend / sumResults);
  const cpaAverage = sumResults === 0 ? 0 : sumSpend / sumResults;
  const cpmReachAverage = sumReach === 0 ? 0 : (sumSpend / sumReach) * 1000;

  const customMetricsAverages = {};
  const cpMetricsAverages = {};
  let customScoringAverages = {};

  for (const metric in customScoringSums) {
    customScoringAverages[`average_${metric}`] =
      customScoringSums[metric] / filteredVisuals.length;
  }

  for (const metric in customMetricsSums) {
    if (isOrganicView || isNoom || isSkyZone) {
      customMetricsAverages[`average_${metric}`] =
        customMetricsSums[metric] / filteredVisuals.length;
      cpMetricsAverages[`average_cp_${metric}`] =
        cpMetricsSums[`sum_cp_${metric}`] / filteredVisuals.length;
    } else if (metric === 'value_Rockerbox_ROAS') {
      customMetricsAverages[`average_${metric}`] =
        // value_Rockerbox_ROAS (i.e. revenue) / spend
        customMetricsSums[metric] / sumSpend;
    } else {
      customMetricsAverages[`average_${metric}`] =
        sumSpend / customMetricsSums[metric];
      cpMetricsAverages[`average_cp_${metric}`] =
        cpMetricsSums[`sum_cp_${metric}`] / filteredVisuals.length;
    }
  }

  return {
    sumSpend,
    sumImpressions,
    sumClicks,
    sumResults,
    sumReach,
    cpmAverage,
    cpcAverage,
    ctrAverage,
    cpaAverage,
    roasAverage,
    cpmReachAverage,
    ...customMetricsSums,
    ...customMetricsAverages,
    ...cpMetricsAverages,
    ...customMetricsAverages,
    ...customScoringSums,
    ...customScoringAverages,
  };
};
