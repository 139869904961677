import React, { useState, useReducer, useEffect, useMemo } from 'react';
import Button from '../../../../../components/Button';

import './DerivedLabels.scss';
import { useDerivedLabelsState } from './hooks';
import { RulesList } from './RulesList';
import { SET_CATEGORY_NAME } from './reducer';
import { RuleEditor } from './RuleEditor';
import { useDerivedLabelsApi } from './api';
import { useCreativeAnalyticsGet } from '../../../contexts';
import { deriveDerivedLabels } from './utils';

const Confirm = ({ derived_labels, category_name, setShowConfirmScreen, save }) => {
  return (
    <div className="derived-labels__confirm">
      <h1>Confirm: {category_name}</h1>
      <h3>The following labels will be added:</h3>
      <div>
        <Button onClick={() => setShowConfirmScreen(false)} appearance="subtle" className="mr-3">
          Back
        </Button>
        <Button
          onClick={save}
          style={{ color: '#fff' }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default Confirm;

const getSummary = () => {
  
}