import React, { useState } from "react";
import "./TopSpending.scss";
import { ChevronDoubleRightIcon } from "../../CreativeRequest/icons";
import {
  toCurrency,
  toFixed_2,
  toPercentRounded,
} from "../../../../utils/numbers";
import { SuggestionIcon } from "../../BasicLabelsSetup/Helpers";
import Tooltip from "../../../../components/Tooltip";
import { RetryButton } from "../components/RetryButton";
import { ErrorIcon, WarningIcon } from "../../../../components/Icons";
import Button from "../../../../components/Button";

const TopSpending = ({ topSpendingProps, isROAS, fetchSuggestions }) => {
  const {
    topSpendingWinner,
    topSpendingLoser,
    projections: { winner: winnerProjections, loser: loserProjections },
  } = topSpendingProps;
  const ABOVE = "Above";
  const BELOW = "Below";
  const [activeType, setActiveType] = useState(BELOW);

  const winnerData = {
    type: ABOVE,
    suggestions: topSpendingProps.winnerSuggestions,
    loading: topSpendingProps.winnerSuggestionsLoading || (topSpendingProps.loserSuggestionsLoading && topSpendingProps.winnerSuggestionsCount < 1),
    error: topSpendingProps.winnerSuggestionsError,
    asset: topSpendingWinner,
  };

  const loserData = {
    type: BELOW,
    suggestions: topSpendingProps.loserSuggestions,
    loading: topSpendingProps.loserSuggestionsLoading || (topSpendingProps.winnerSuggestionsLoading && topSpendingProps.loserSuggestionsCount < 1),
    error: topSpendingProps.loserSuggestionsError,
    asset: topSpendingLoser,
  };

  const handleToggle = (type) => {
    if (type === activeType) return;

    setActiveType(type);
  };

  return (
    <div className="top-spending">
      <div className="top-spending__toggle">
        <div
          className={`toggle-icon ${activeType === BELOW ? "enabled" : ""} `}
          onClick={() => handleToggle(ABOVE)}
        >
          <ChevronDoubleRightIcon />
        </div>
        Top Spending&nbsp;
        <span className={activeType}>{`${activeType} Average`}</span>
        <div
          className={`toggle-icon ${activeType === ABOVE ? "enabled" : ""} `}
          onClick={() => handleToggle(BELOW)}
        >
          <ChevronDoubleRightIcon />
        </div>
      </div>
      <div
        className={`top-spending__section-container ${
          activeType === BELOW ? "slide-left" : ""
        }`}
      >
        <TopSpendingSection
          {...{
            ...winnerData,
            activeType,
            isROAS,
            ...winnerProjections,
            fetchSuggestions,
          }}
        />
        <TopSpendingSection
          {...{
            ...loserData,
            activeType,
            isROAS,
            ...loserProjections,
            fetchSuggestions,
          }}
        />
      </div>
    </div>
  );
};

export default TopSpending;

const TopSpendingSection = ({
  type,
  suggestions,
  loading,
  error,
  asset,
  activeType,
  isROAS,
  percent_improvement,
  savings,
  fetchSuggestions,
}) => {
  const isFirstFetch = !suggestions.length && !loading && !error;

  const handleRefetch = () => {
    let requestType = "loser";
    if (type === "Above") requestType = "winner";
    fetchSuggestions(requestType);
  };

  let Render = suggestions.length && !loading ? (
    suggestions.map((sugg) => {
      const [showWhy, setShowWhy] = useState(false);

      return (
        <div className="top-spending__suggestion-item">
          <div className="suggestion__rec">
            {sugg.Recommendation}{sugg.Recommendation.charAt(sugg.Recommendation.length - 1) === "." ? "" : "."}
            <i
              className="fa fa-info-circle"
              onClick={() => setShowWhy((prev) => !prev)}
            ></i>
          </div>
          <div className={`suggestion__why ${showWhy ? "show" : ""}`}>
            {sugg.Why}{sugg.Why.charAt(sugg.Why.length - 1) === "." ? "" : "."}
          </div>
        </div>
      );
    })
  ) : (
    <div>
      <WarningIcon className="mr-2" /> Couldn't get suggestions for this asset. <RetryButton fn={handleRefetch} />
    </div>
  );

  if (error)
    Render = (
      <div className="d-flex align-items-center">
        <ErrorIcon className="mr-2" /> There was an error. <RetryButton fn={handleRefetch} />
      </div>
    );

  if (loading) Render = <div>Loading...</div>;

  if (isFirstFetch) Render = null;
  
  let OverviewEnd = (
    <span>
      a{" "}
      <span className="emphasis">
        {isROAS ? toFixed_2(asset.roas) : toCurrency(asset.cpa)}&nbsp;
        {isROAS ? "ROAS" : "CPA"}
      </span>
    </span>
  );

  if (asset.cpa === 0) {
    OverviewEnd = (
      <span>no downfunnel results</span>
    )
  }

  return (
    <div
      className={`top-spending__section top-spending__section--${type} ${
        type === activeType ? "show" : ""
      }`}
    >
      <div className="top-spending__overview">
        This is your top spending {activeType.toLowerCase()} average asset. It
        has{" "}
        <span className="emphasis">spent&nbsp;{toCurrency(asset.spend)}</span>{" "}
        over this period with {OverviewEnd}.
      </div>
      <div className="top-spending__preview">
        {asset.visual_type === "image" ? (
          <img
            src={asset.asset_url}
            alt=""
            style={{ maxHeight: "200px", objectFit: "contain" }}
          />
        ) : (
          <video
            key={asset.asset_url}
            autoPlay
            loop
            muted
            plays-inline="true"
            className="report-card__img"
            controls
            style={{ maxHeight: "200px", objectFit: "contain" }}
          >
            <source src={asset.asset_url} type="video/mp4" />
          </video>
        )}
      </div>
      {Number.isFinite(percent_improvement) && Number.isFinite(savings) ? (
        <div className="top-spending__projections">
          If this asset performed 20% better, it would{" "}
          {isROAS ? "raise" : "lower"} the {isROAS ? "ROAS" : "CPA"} of this dataset by{" "}
          <span className="emphasis">
            {toPercentRounded(percent_improvement)}
          </span>
          . This would provide you with{" "}
          <span className="emphasis">{toCurrency(savings)}</span> in marketing
          savings.
        </div>
      ) : (
        <div>Improving the performance of this asset will have a material impact on your overall performance.</div>
      )}
      <div className="top-spending__suggestions">
        <div className="top-spending__suggestions-top">
          <div className="top-spending__suggestions-title">
            <div className="top-spending__suggestions-icon">
              <SuggestionIcon />
            </div>
            Suggestions to Improve
          </div>
          {!suggestions.length && !loading && !error ? (
            <Button
              appearance=""
              onClick={handleRefetch}
            >
              Fetch
            </Button>
          ) : (
            <>
              <Tooltip
                content="Refresh suggestions"
                follow={false}
                delay={[500, 0]}
              >
                <button
                  className="btn-reset"
                  onClick={handleRefetch}
                >
                  <i
                    style={{ fontSize: "14px" }}
                    className="fa fa-rotate-right"
                  ></i>
                </button>
              </Tooltip>
            </>
          )}
        </div>
        {Render}
      </div>
    </div>
  );
};
