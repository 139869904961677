export const SPEND = 'spend';
export const CPA = 'cpa';

export const THEME = 'theme';
export const ATTRIBUTES = 'attributes';
export const KEYWORDS = 'keywords';

export const SECTION_HEADERS = {
  messageTheme: 'Messaging Theme',
  visualTheme: 'Visual Theme',
  attribute: 'Attribute',
  keyword: 'Keyword',
};

