//@ts-check
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomDropdown from "../CustomDropdown";
import { useSearchParams } from "../Hooks/search-params";
import { setViewSettings } from "../../store/ViewSettings/actions";

const OPTIONS = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Image",
    value: "image",
  },
  {
    label: "Video",
    value: "video",
  },
];

export function VisualTypePicker() {
  const _id = "visual-picker-button";

  const [visualType, handleSelect] = usePickerState();

  return (
    <CustomDropdown
      id={_id}
      label={`Visual Type ${visualType === "" ? "All" : visualType}`}
      onSelect={(type, _) => handleSelect({ type })}
    >
      {OPTIONS.map(({ label, value }) => (
        <CustomDropdown.Item key={label} eventKey={value}>
          {label}
        </CustomDropdown.Item>
      ))}
    </CustomDropdown>
  );
}

function usePickerState() {
  let { setParams } = useSearchParams();
  let { visualType } = useSelector(({ ViewSettings: { visualType } }) => ({
    visualType,
  }));

  const dispatch = useDispatch();

  const handleSelect = ({ type }) => {
    if (type === visualType) {
      return null;
    } else {
      setParams({ type }, () =>
        dispatch(setViewSettings({ visualType: type }))
      );
    }
  };

  return [visualType, handleSelect];
}
