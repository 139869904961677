//@ts-check
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MultiStep } from "react-multistep-contextualized";

import { ErrorBoundary } from "../../Helpers/Error";

import { WizardProvider, WizardContext } from "./Context";
import { HighLevel } from "./Step.HighLevel";
import { Optional } from "./Step.Optional";
import { Details } from "./Step.Details";

import "./FormElements.style.scss";

export const Wizard = {
  Provider: WizardProvider,
  Body: StepWizard,
};

function StepWizard({ handleSubmit, visible }) {
  const { validators, status, inProgress, reset } = useContext(WizardContext);
  const [activeStep, setStep] = useState(0);
  const isDraft = status === "draft";

  const fieldsValid = (arr) => arr.every((field) => validators[field].valid);

  const _handleSubmit = (data) => {
    handleSubmit(data);
    reset();
    setStep(0);
  };

  const steps = [
    {
      name: "Main",
      component: (
        <ErrorBoundary>
          <>
            <HighLevel />
            {isDraft && <Footer handleSubmit={_handleSubmit} />}
          </>
        </ErrorBoundary>
      ),
      valid:
        inProgress ||
        fieldsValid([
          "study_name",
          "automation_study_type",
          "testing_study_type",
          "study_type",
          "start_date",
          "end_date",
        ]),
    },
    {
      name: "Optional",
      component: (
        <ErrorBoundary>
          <Optional />
        </ErrorBoundary>
      ),
    },
    {
      valid:
        inProgress ||
        fieldsValid([
          "campaign_id",
          "group_a_label",
          /*"group_a_ad_id",
        "group_a_ad_string",
        "group_a_adset_id",
        "group_a_adset_string",
        "group_b_label",
        "group_b_ad_id",
        "group_a_ad_name",
        "group_b_adset_id",
        "group_b_adset_name",*/
        ]),
      name: "Details",
      component: (
        <ErrorBoundary>
          <Details />
        </ErrorBoundary>
      ),
    },
    {
      name: "Confirmation",
      component: (
        <ErrorBoundary>
          <>
            <Confirmation />
            <Footer handleSubmit={_handleSubmit} />
          </>
        </ErrorBoundary>
      ),
    },
  ];

  // MultiStep does not allow for button styling?
  // Also ignores the "name" field.
  useEffect(() => domOps(), []);

  useEffect(() => {
    if (!visible) {
      setStep(0);
    }
  }, [visible]);

  const _steps = isDraft ? steps.slice(0, 1) : steps;

  return (
    <div className="multistep-container" style={{ width: "70%" }}>
      <MultiStep.Provider
        showNavigation={true}
        steps={_steps}
        activeStep={activeStep}
        handleStep={setStep}
      >
        <MultiStep.Status />
        <MultiStep.Step />
        {!isDraft && (
          <MultiStep.NavButtons
            className="d-flex flex-row justify-content-end"
            prevClassName="btn btn-default"
            nextClassName="btn btn-primary ml-3"
            prevStyle={{ fontWeight: 700 }}
            nextStyle={{ fontWeight: 700 }}
          />
        )}
      </MultiStep.Provider>
    </div>
  );
}

StepWizard.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

function Confirmation() {
  const { handleChange, validators, ...rest } = useContext(WizardContext);

  const keys = Object.keys(rest);

  return (
    <>
      <div className="d-flex flex-col align-items-center">
        {keys
          .filter((k) => !!validators[k])
          .map((k, i) => {
            const displayAs = validators[k].display;
            const keyAs = k.includes("id") ? k.replace("id", "name") : k;
            const value = displayAs ? displayAs(rest[k]) : rest[k];
            if (value != "") {
              return (
                <ConfirmedItem
                  key={`${k}-${i}`}
                  name={snakeToDisplay(keyAs)}
                  value={value}
                />
              );
            }
          })}
      </div>
    </>
  );
}

function ConfirmedItem({ name, value }) {
  return (
    <div
      style={{ width: "100%", borderTop: "1px solid #dddddd" }}
      className="d-flex justify-content-center mb-2"
    >
      <span className="text-right" style={{ width: "25%" }}>
        {name}
      </span>{" "}
      <strong className="ml-4" style={{ width: "75%" }}>
        {value}
      </strong>
    </div>
  );
}

function Footer({ handleSubmit }) {
  const { handleChange, validators, ...rest } = useContext(WizardContext);

  return (
    <>
      <button
        type="button"
        className="btn btn-success my-2"
        style={{ width: "100%", fontWeight: 700 }}
        onClick={(e) => handleSubmit(rest)}
      >
        Save
      </button>{" "}
    </>
  );
}

function domOps() {
  const $ol = document.querySelector(".multistep-container ol");

  $ol.className = `${$ol.className} d-flex justify-content-center px-0`;

  const $body = document.querySelector("#create-study-flow-modal .modal-body");

  $body.className += " d-flex flex-row justify-content-center";
}

function snakeToDisplay(text) {
  return text
    .split("_")
    .map((w) => `${w[0].toUpperCase()}${w.slice(1)}`)
    .join(" ");
}
