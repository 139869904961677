import React from 'react';
import { DARWIN_NAVY_4 } from '../../constants';

const BestPerformingIcon = ({ className = "", onClick }) => (
  <svg className={className} onClick={onClick} width="28" height="28" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 18.999H7V10.599C7 10.4399 7.06321 10.2873 7.17574 10.1748C7.28826 10.0623 7.44087 9.99904 7.6 9.99904H12.4C12.5591 9.99904 12.7117 10.0623 12.8243 10.1748C12.9368 10.2873 13 10.4399 13 10.599V18.999V18.999ZM18.4 18.999H13V16.099C13 15.9399 13.0632 15.7873 13.1757 15.6748C13.2883 15.5623 13.4409 15.499 13.6 15.499H18.4C18.5591 15.499 18.7117 15.5623 18.8243 15.6748C18.9368 15.7873 19 15.9399 19 16.099V18.399C19 18.5582 18.9368 18.7108 18.8243 18.8233C18.7117 18.9358 18.5591 18.999 18.4 18.999V18.999ZM7 18.999V14.099C7 13.9399 6.93679 13.7873 6.82426 13.6748C6.71174 13.5623 6.55913 13.499 6.4 13.499H1.6C1.44087 13.499 1.28826 13.5623 1.17574 13.6748C1.06321 13.7873 1 13.9399 1 14.099V18.399C1 18.5582 1.06321 18.7108 1.17574 18.8233C1.28826 18.9358 1.44087 18.999 1.6 18.999H7V18.999ZM8.806 3.11204L9.715 1.18504C9.73954 1.12995 9.77952 1.08316 9.8301 1.05032C9.88068 1.01748 9.93969 1 10 1C10.0603 1 10.1193 1.01748 10.1699 1.05032C10.2205 1.08316 10.2605 1.12995 10.285 1.18504L11.195 3.11204L13.227 3.42304C13.488 3.46304 13.592 3.79904 13.403 3.99104L11.933 5.49104L12.28 7.60904C12.324 7.88104 12.052 8.08904 11.818 7.96004L10 6.96004L8.182 7.96004C7.949 8.08804 7.676 7.88104 7.72 7.60904L8.067 5.49104L6.597 3.99104C6.407 3.79904 6.512 3.46304 6.772 3.42304L8.806 3.11304V3.11204Z" stroke="white" stroke-width="0.25" stroke-linecap="round" stroke-linejoin="round" fill={DARWIN_NAVY_4}/>
  </svg>
)

export default BestPerformingIcon;
