//@ts-check
import { useEffect } from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import {
  CREATE_AD,
  fetchCreateAd_carousel,
  updateElement,
} from "../../store/CreateAd/actions";

import { fetchCreateAdPreviewFlow } from "../../store/AdPreviews/actions";
import { fetchAdToCreate } from "../../store/background/api.ad-create";
import { makeAdName, makeHash } from "./utils";

const AD = `${CREATE_AD}.Ad`;
const CAROUSEL = `${CREATE_AD}.Carousel`;

/**
 * @return {{adError: Boolean, carouselError: Boolean, loadingPreview: Boolean, loadingCreateAd: Boolean, previewFetchError: Boolean }}
 */
export const useFetchStatus = () => {
  const state = useSelector(
    ({
      Loaders,
      Errors,
      AdPreviews: { isLoading, hasError },
      CreateAd: { payload },
    }) => ({
      loadingPreview: isLoading,
      loadingCreateAd: !!Loaders[`${CREATE_AD}.Ad`] || !payload.atf.value,
      previewFetchError: hasError,
      adError: Errors[AD],
      carouselError: Errors[CAROUSEL],
    })
  );

  return state;
};

export const useFetchOnMount = (callback) => {
  const dispatch = useDispatch();

  useEffect(() => {
    let active = true;

    const _fetch = async () => {
      if (active) {
        dispatch(fetchCreateAd_carousel());

        callback && callback();
      }
    };

    _fetch();

    return () => (active = false);
  }, []);

  return null;
};

export const useAd = () => {
  return useSelector(
    ({ CreateAd: { payload }, AdPreviews: { previews, previewsIndex } }) => {
      return {
        adPreviews: previews,
        adPreviewsIndex: previewsIndex,
        ad: payload,
        validAd: isValidAd(payload),
      };
    }
  );
};

function isValidAd(payload) {
  return ["atf", "btf", "cta", "visual"].every((k) => {
    const elem = payload[k];

    if (!elem) {
      return false;
    } else {
      return (
        typeof elem.value === "string" &&
        elem.value !== "" &&
        elem.value.length > 0
      );
    }
  });
}

const CREATION_TYPES = [
  ...["atf", "btf", "cta"].map((type) => ({
    type,
    validate(payload) {
      return validString(payload[type].value);
    },
    value(payload) {
      return { [type]: payload[type].value };
    },
  })),
  {
    type: "visual",
    validate(payload) {
      return validString(payload.visual.value);
    },
    value(payload) {
      const { type, image_url, value } = payload.visual;

      return {
        type,
        image_url,
        visual: value,
        ad_name: makeAdName(type),
      };
    },
  },
];

/**
 * @return {{ atf: string, btf: string, cta: string, visual: string, type: "image" | "video", image_url: string, ad_name: string }}
 */
function setPayload(payload) {
  //@ts-ignore
  return CREATION_TYPES.reduce(
    (acc, { value }) => ({ ...acc, ...value(payload) }),
    {}
  );
}

export function usePayload() {
  const dispatch = useDispatch();
  const payload = useSelector(({ CreateAd: { payload } }) => {
    return payload;
  });

  const fetchCreation = () => {
    const createAs = setPayload(payload);

    dispatch(fetchAdToCreate(createAs));
  };

  const fetchPreview = () => {
    const { atf, btf, cta, visual, type, image_url } = setPayload(payload);
    const _id = makeHash(atf, btf, cta, visual, type).toString();

    batch(() => {
      dispatch(updateElement({ type: "_id", value: _id }));
      dispatch(
        fetchCreateAdPreviewFlow({
          _id,
          atf,
          btf,
          cta,
          visual,
          type,
          image_url,
        })
      );
    });
  };

  return {
    fetchCreation,
    fetchPreview,
    payload,
  };
}

/**
 * @param {any} s
 *
 * @return {boolean}
 */
function validString(s) {
  return typeof s === "string" && s.length > 0;
}
