import { useCallback, useEffect, useState } from 'react';
import { usePostRequest } from '../../../components/Hooks/usePostRequest';
import { useSelector } from 'react-redux';
import { darwinDateToDate } from '../../../utils/darwin-dates';
import { useDNAColumnOptions } from '../../DNA/useDNAColumnOptions';

const replaceInvalidCharacters = (str) => {
  if (!str) return str;
  return str.replaceAll('.', '___');
};

const isHidden = (hiddenLabels, label) => {
  return hiddenLabels.some((hl) => {
    return hl.label === label.name && hl.category === label.category;
  });
};

const filterLabels = (
  filteredData,
  selectedCategories = [],
  hiddenLabels = []
) => {
  return filteredData.filter((label) => {
    const isInSelectedCategory = selectedCategories.length
      ? selectedCategories.includes(label.category)
      : true;
    return isInSelectedCategory && !isHidden(hiddenLabels, label);
  });
};

export const useShareStaticReport = ({
  columnOptions,
  filteredData,
  filteredVisuals,
  reportCardSettings,
  columns,
  selectedLabels,
  period1Start,
  period1End,
  primary_metric,
  clientMetrics = [],
  category,
  customScoringData,
}) => {
  const {
    customIntegratedMetrics,
    custom_events,
    additional_metrics,
    isOrganicView,
    isDirectMail,
    isEmailView,
    ga_enabled,
    isROAS,
    metadataFields,
    hiddenLabels,
    visualsLookup,
  } = useSelector(
    ({
      ViewSettings: {
        customIntegratedMetrics,
        custom_events,
        additional_metrics,
        isOrganicView,
        isDirectMail,
        isEmailView,
        ga_enabled,
        isROAS,
      },
      CreativeAnalytics: { dnaReportData, hiddenLabels, visuals },
    }) => ({
      customIntegratedMetrics,
      custom_events,
      additional_metrics,
      isOrganicView,
      isDirectMail,
      isEmailView,
      ga_enabled,
      isROAS,
      metadataFields: dnaReportData?.categories || [],
      hiddenLabels,
      visualsLookup: visuals.lookup,
    })
  );
  const {
    data: apiData,
    loading,
    error,
    request,
  } = usePostRequest(
    ['api', 'creative-analytics', 'create-static-report'].join('/')
  );

  let status = 'idle';

  if (apiData) status = 'success';
  else if (error) status = 'error';
  else if (loading) status = 'loading';

  const errString = error?.error?.toString() || error?.toString();

  const [shouldShowShareModal, setShouldShowShareModal] = useState(false);
  const [sharedReportId, setSharedReportId] = useState(null);

  const dnaColumnOptions = useDNAColumnOptions({
    isROAS,
    clientMetrics,
    customEvents: custom_events,
  });

  const shareStaticPfr = ({
    report_name,
    report_description,
    selectedCategories,
  }) => {
    const payload = {
      report_name,
      report_description,
      start_date: darwinDateToDate(period1Start),
      end_date: darwinDateToDate(period1End),
      data: {
        report_type: 'pfr',
        labels: filterLabels(
          filteredData,
          selectedCategories,
          hiddenLabels
        ).map((label) => {
          let update = {
            ...label,
            winners: label.winner_ids,
            losers: label.loser_ids,
            visuals: label.visual_ids,
            relevant_visuals: [],
            client_down_funnel_events:
              label.client_down_funnel_events?.map((ev) =>
                replaceInvalidCharacters(ev)
              ) || [],
          };

          Object.keys(label).forEach((key) => {
            if (key.includes('.')) {
              update[replaceInvalidCharacters(key)] = label[key];
              delete update[key];
            }
          });

          return update;
        }),
        visuals: filteredVisuals.map((visual) => {
          let update = {};

          Object.keys(visual).forEach((key) => {
            update[replaceInvalidCharacters(key)] = visual[key];
          });

          return update;
        }),
        columns: columns.map((col) => {
          return replaceInvalidCharacters(col);
        }),
        reportCardSettings,
        selectedLabels: selectedLabels.map((label) => {
          return {
            ...label,
            columns: columns.map((col) => {
              return replaceInvalidCharacters(col);
            }),
          };
        }),
        metadataFields: metadataFields,
        primary_metric: replaceInvalidCharacters(primary_metric),
        start_date: period1Start,
        end_date: period1End,
        hiddenLabels: hiddenLabels || [],
        viewData: {
          customIntegratedMetrics,
          custom_events,
          additional_metrics: additional_metrics.map((metric) => {
            return {
              name: replaceInvalidCharacters(metric.name),
              value: replaceInvalidCharacters(metric.value),
            };
          }),
          isOrganicView,
          isDirectMail,
          isEmailView,
          ga_enabled,
          isROAS,
        },
        columnOptions: columnOptions.map((col) => {
          return {
            label: replaceInvalidCharacters(col.label),
            value: replaceInvalidCharacters(col.value),
          };
        }),
        dnaColumnOptions: dnaColumnOptions.map((col) => {
          return {
            label: replaceInvalidCharacters(col.label),
            value: replaceInvalidCharacters(col.value),
          };
        }),
        customScoringData,
      },
    };
    request(payload);
  };

  useEffect(() => {
    const report_id = extractReportId(apiData);

    if (report_id) {
      setSharedReportId(report_id);
    }
  }, [apiData]);

  const handleSharePfr = useCallback(() => {
    setShouldShowShareModal(true);
  }, []);

  return {
    sharedReportId,
    handleSharePfr,
    shouldShowShareModal,
    closeShareModal: () => {
      setSharedReportId(null);
      setShouldShowShareModal(false);
    },
    submitShareStaticPfr: ({
      report_name,
      report_description,
      selectedCategories,
    }) =>
      shareStaticPfr({
        report_name,
        report_description,
        selectedCategories,
      }),
    loadingSharePfr: loading,
    error: errString,
    status,
  };
};

const extractReportId = (apiData) => {
  if (apiData?.message) {
    const reportIdMatch = apiData.message.match(/report id (\w+)/);

    const reportId = reportIdMatch ? reportIdMatch[1] : null;

    return reportId;
  } else {
    return null;
  }
};
