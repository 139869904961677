import React, { useEffect, useRef, useState } from 'react';
import './Output.scss';
import { useCopilotContext } from '../contexts/copilotContext';
import { useCreativeAnalyticsGet } from '../../contexts';
import { CopilotIcon } from '../components/CopilotIcon';
import LoadingMessages from '../../../../components/Helpers/LoadingMessages';
import { LOADING_MESSAGES } from '../constants';
import CopilotButton from '../components/CopilotButton';
import { CHANGE_REQUEST_TYPE, SET_FEEDBACK } from '../hooks/useCopilotState';
import Tooltip from '../../../../components/Tooltip';
import { Table } from '../../PerformanceFrequency/Table/Table';
import { useCategories } from '../../hooks';
import { ColumnPicker } from '../../PerformanceFrequency/Pickers';
import Button from '../../../../components/Button';
import GridIcon from '../../../../components/Icons/GridIcon';
import { useCopyToClipboard } from '../../../../hooks/useCopyToClipboard';
import parse from 'html-react-parser';
import PfrTable from './PfrTable';
import { usePfrCategories } from '../../selectors';

const Output = ({
  loading,
  requestError,
  setChangesMade,
  showHint,
  columns,
  handleColumns,
  handleSendRequest,
  prevState,
  data,
  isAnswerQuestions,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [chatStyle, setChatStyle] = useState(false);
  const { clientMetrics } = useCreativeAnalyticsGet();
  const { copilotState, copilotDispatch } = useCopilotContext();
  const { currentOutput, requestType, userFeedback } = copilotState;
  const { tableData } = usePfrCategories();
  const categoryTableData = tableData?.find(
    (data) => data.category_name === selectedCategory
  );
  const { category_name, category_data, labels } = categoryTableData || {};
  const { isCopied, copyToClipboard, copiedText } = useCopyToClipboard();
  const ref = useRef();

  useEffect(() => {
    if (!currentOutput.summary.length) {
      setChatStyle(false);
      setOpenFeedback(false);
      setSelectedCategory(null);
    }
  }, [currentOutput.summary]);

  const handleFocus = (e) => {
    e.target.select();
    // execCommand is deprecated; only used for the workaround
    document.execCommand('copy');
  };

  useEffect(() => {
    ref.current?.focus();
  }, [copiedText]);

  const regex = /(<([^>]+)>)/gi;

  let Render = (
    <div className="d-flex align-items-center justify-content-center flex-column">
      <CopilotIcon loading={loading} button={false} />
      <LoadingMessages messages={LOADING_MESSAGES} delay={5000} />
    </div>
  );

  if (requestError) {
    Render = <div>There was an error.</div>;
  }

  if (
    !loading &&
    currentOutput.summary &&
    ((!isAnswerQuestions && data.recommendation_summary) ||
      (isAnswerQuestions && data.answer_summary))
  ) {
    Render = (
      <>
        <div
          className={`output__summary col-${!isAnswerQuestions ? '7' : '12'}`}
        >
          <div
            className="clipboard-workaround"
            style={{ position: 'absolute', left: '-5000px' }}
          >
            <input
              ref={ref}
              type="text"
              value={copiedText}
              onFocus={handleFocus}
            />
          </div>
          <button
            className="copy-button"
            onClick={() => copyToClipboard(currentOutput.summary.replace(regex, ''))}
          >
            <i className="fa-regular fa-copy" />
          </button>
          {isCopied && <div className="copied">Copied!</div>}
          <div className="summary-text">{parse(currentOutput.summary)}</div>
          <div
            className="d-flex justify-content-end"
            style={{
              position: 'absolute',
              bottom: '1rem',
              right: '1rem',
              zIndex: 1,
            }}
          >
            <CopilotButton
              handleClick={() => {
                if (!openFeedback) {
                  setOpenFeedback((prev) => !prev);
                } else if (userFeedback.trim().length) {
                  handleSendRequest();
                }
              }}
              disableButton={false}
              loading={loading}
              text={openFeedback ? 'Submit' : 'Give feedback'}
              className={`${openFeedback ? 'no-shadow' : ''} ${
                showHint ? 'with-border' : ''
              }`}
            />
          </div>
          <div
            className={`feedback ${openFeedback ? 'open' : ''} ${
              chatStyle ? 'chat' : ''
            }`}
          >
            <div
              className="minimize-button"
              onClick={() => setOpenFeedback(false)}
            >
              <i className="fa-solid fa-minus" />
            </div>
            <div className="text-container">
              <textarea
                className="text-input"
                placeholder={
                  requestType === 'answer-questions'
                    ? ''
                    : 'e.g., Great, but it needs to be a video ad.'
                }
                onChange={(e) => {
                  copilotDispatch({
                    type: SET_FEEDBACK,
                    text: e.target.value,
                  });
                }}
                onBlur={() => {
                  if (userFeedback.trim().length) {
                    setChatStyle(true);
                    setChangesMade(true);
                  }
                }}
                onFocus={() => setChatStyle(false)}
              />
            </div>
          </div>
        </div>
        {!isAnswerQuestions && (
          <div className={`output__reasons col-5`}>
            <div className="reasons">
              {currentOutput.reasons.map(
                ({ category_name, label_name, bold_reason, description }) => {
                  return (
                    <div className="reason">
                      <div className="title">{bold_reason}</div>
                      <div className="description">{description}</div>
                      <div
                        className="breakdown"
                        onClick={() => setSelectedCategory(category_name)}
                      >
                        See full breakdown: <span>{category_name}</span>
                        {` >`}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <div className="copilot__section output col-9">
      <div className="d-flex align-items-center justify-content-between">
        <div className="section-title">Creative Data</div>
        <Tooltip content="Reset">
          <div
            className="reset-button"
            onClick={() => {
              copilotDispatch({
                type: 'RESET',
              });
            }}
          >
            <i className="fa fa-rotate-right" />
          </div>
        </Tooltip>
      </div>
      <div className="output__container">{Render}</div>
      {categoryTableData && (
        <PfrTable
          {...{
            columns,
            handleColumns,
            setSelectedCategory,
            category_name,
            category_data,
            labels,
            selectedCategory,
          }}
        />
      )}
    </div>
  );
};

export default Output;
