
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useState, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useCubeQuery } from '@cubejs-client/react';
import Select from 'react-select';

import { BasicLoading } from '../../../../../components/Helpers/Loading';

import BootstrapTable from './BootstrapTable';
import {
  translateMeasures,
  comparisonFormatter,
  prepSeries,
  makeDateRanges,
  getLookbackRange,
} from './period-utils';

import {
  QueryType, ResultSetType, MeasureType, FilterType, PeriodOptionType,
} from '../../../cube-prop-types';

/**
 * @typedef {import('../../../cube-prop-types').PeriodOption} PeriodOption
 */

/**
 * @type {PeriodOption[]}
 */
const DATE_RANGES = [
  {
    label: 'Previous 7 days',
    value: [-14, -8],
  },
  {
    label: 'Last 7 days',
    value: [-7, -1],
  },
  {
    label: 'Previous 14 days',
    value: [-28, -15],
  },
  {
    label: 'Last 14 days',
    value: [-14, -1],
  },
];

/**
 * {...<table.column>: [<table.column>] }
 * @typedef {Object<string, { calc?: Function, fields: string[] }>} PeriodMappings
 */
const PeriodMappingsType = PropTypes.shape({
  calc: PropTypes.func,
  fields: PropTypes.arrayOf(PropTypes.string),
});

const PICKER_STYLES = {
  control: (base, { selectProps: { height, width } }) => ({
    ...base,
    width,
    height,
    minHeight: height,
  }),
  menu: (base, { selectProps: { dropdownWidth } }) => ({
    ...base,
    width: dropdownWidth,
  }),
  singleValue: (base, { selectProps: { paddingBottom } }) => ({
    ...base,
    paddingBottom,
  }),
};

/**
 * cube rawData:
 * [...{ AdsMetrics.metricData: string, .... }]
 *
 * period table:
 * [...{
 *    date: "<date>" or formal period name,
 *    ...<metric>: number
 * }, {
 *    date: "Change",
 *    ...<metric>: number (change over previous rows)
 * }]
 */

/**
 *
 * @param {Object} x
 * @param {[PeriodOption, PeriodOption]} x.compareDateRange
 * @param {Object[]} x.measures
 * @param {Object[]} x.filters
 * @param {Object[]} x.dimensions
 *
 */
function makeQuery({
  compareDateRange, measures, dimensions, filters,
}) {
  return {
    measures,
    dimensions,
    filters,
    timeDimensions: [
      {
        granularity: 'day',
        dimension: 'AdsMetrics.metricDate',
        compareDateRange,
      },
    ],
  };
}

function QueryLoader({ query, children }) {
  // There can be a delay between passing a new query & "isLoading"
  // being set to true.
  const { isLoading, resultSet, error } = useCubeQuery(query);

  if (error) {
    return <p>{error.toString()}</p>;
  } if (isLoading) {
    return <BasicLoading />;
  } if (!resultSet) {
    return <p>No results</p>;
  }
  return children(resultSet);
}

function transformQuery(query) {
  const {
    measures, dimensions = [], filters, timeDimensions,
  } = query;
  const [{ granularity, dimension, compareDateRange = [] }] = timeDimensions;

  const translations = translateMeasures(measures);

  return {
    periods: compareDateRange,
    derivedMappings: translations.mappings,
    query: {
      measures: translations.measures,
      dimensions,
      filters,
      timeDimensions: [
        {
          granularity,
          dimension,
          compareDateRange: makeDateRanges(
            compareDateRange.map(({ value }) => value),
          ),
        },
      ],
    },
  };
}

function TransformedQueryLoader({ query, children }) {
  const transformed = transformQuery(query);

  return (
    <QueryLoader query={transformed.query}>
      {(resultSet) => children(resultSet, transformed)}
    </QueryLoader>
  );
}

TransformedQueryLoader.propTypes = {
  query: QueryType.isRequired,
  children: PropTypes.func.isRequired,
};

function Lookback({ period, filters, dimensions }) {
  const cpa = 'AdSnapshotMetrics.costPerResult';
  // Takes the most recent number from the value pair.
  const dateRange = getLookbackRange(period.value[1]);
  const query = {
    measures: [cpa],
    dimensions,
    filters,
    timeDimensions: [
      {
        dateRange,
        granularity: 'week',
        dimension: 'AdSnapshotMetrics.metricDate',
      },
    ],
  };

  return (
    <QueryLoader query={query}>
      {(resultSet) => {
        const snapshotCpa = resultSet.totalRow()[cpa];
        const [, days] = period.label.split('Previous ');

        return (
          <p>
            *Note: Snapshot
            {' '}
            {days}
            {' '}
            ago was
            {' '}
            <strong>
              $
              {snapshotCpa}
            </strong>
          </p>
        );
      }}
    </QueryLoader>
  );
}

Lookback.propTypes = {
  period: PeriodOptionType.isRequired,
  filters: FilterType.isRequired,
  dimensions: FilterType.isRequired,
};

function TableDisplay({
  results, query, period1, period2, mappings,
}) {
  const series = results
    .decompose()
    .map((currResultSet) => currResultSet.rawData())
    .flat();

  const { rows, headers } = useMemo(
    () => prepSeries(series, period1, period2, mappings),
    [JSON.stringify(query)],
  );

  if (rows.length === 0) {
    return <p>No data to display</p>;
  }
  const hasValidOptions = 'costPerResult' in mappings && period1.label.includes('Previous');
  const adjustedRows = hasValidOptions
    ? rows.map(({ date, ...rest }) => ({
      date: date.replace(/previous [0-9]+ days/i, `${period1.label}*`),
      ...rest,
    }))
    : rows;

  return (
    <>
      <BootstrapTable
        showRowNumber={false}
        headers={headers}
        rows={adjustedRows}
        formatter={comparisonFormatter}
      />
      {hasValidOptions && (
        <Lookback
          period={period1}
          dimensions={query.dimensions}
          filters={query.filters}
        />
      )}
    </>
  );
}

TableDisplay.propTypes = {
  results: ResultSetType.isRequired,
  query: QueryType.isRequired,
  period1: PeriodOptionType.isRequired,
  period2: PeriodOptionType.isRequired,
  mappings: PeriodMappingsType.isRequired,
};

export default function PeriodComparison({ setVizState, query }) {
  if (setVizState) {
    const {
      filters, dimensions, measures,
    } = query;

    return (
      <ExplorePeriodComparison
        {...{
          filters, dimensions, measures,
        }}
        setVizState={setVizState}
      />
    );
  }
  return (
    <TransformedQueryLoader query={query}>
      {(results, { derivedMappings, periods: [period1, period2] }) => (
        <TableDisplay
          results={results}
          query={query}
          period1={period1}
          period2={period2}
          mappings={derivedMappings}
        />
      )}
    </TransformedQueryLoader>
  );
}

PeriodComparison.propTypes = {
  setVizState: PropTypes.func.isRequired,
  query: QueryType.isRequired,
};

function ExplorePeriodComparison({
  setVizState,
  filters,
  measures,
  dimensions,
}) {
  const [period1, setPeriod1] = useState(DATE_RANGES[0]);
  const [period2, setPeriod2] = useState(DATE_RANGES[1]);

  const query = makeQuery({
    compareDateRange: [period1, period2],
    dimensions,
    measures,
    filters,
  });

  const M = measures.length;

  useEffect(() => {
    if (M > 0) {
      setVizState({ chartType: 'custom_periodComparison', query });
    }
  }, [period1.value, period2.value, M]);

  return (
    <>
      <div className="row">
        <div className="col-12 d-flex flex-row">
          <div className="ml-2 d-flex flex-row align-items-baseline">
            <label className="mr-2">Compare</label>
            <Picker
              // @ts-ignore
              options={DATE_RANGES.filter(({ label }) => label !== period2.label)}
              // @ts-ignore
              value={period1}
              onChange={(selected) => setPeriod1(selected)}
            />

            <label className="mx-2">To</label>
            <Picker
              // @ts-ignore
              options={DATE_RANGES.filter(({ label }) => label !== period1.label)}
              // @ts-ignore
              value={period2}
              onChange={(selected) => setPeriod2(selected)}
            />
          </div>
        </div>
      </div>

      {M > 0 && (
        <TransformedQueryLoader query={query}>
          {(results, { derivedMappings }) => (
            <TableDisplay
              results={results}
              query={query}
              period1={period1}
              period2={period2}
              mappings={derivedMappings}
            />
          )}
        </TransformedQueryLoader>
      )}

      <hr />
      <p>To update any measures or filters: </p>
      <ol>
        <li>Select a different chart type</li>
        <li>Select desired measures/filters</li>
        <li>Reselect &quot;Period Comparison&quot;</li>
      </ol>
    </>
  );
}

ExplorePeriodComparison.defaultProps = {
  filters: [],
  measures: [],
  dimensions: [],
};

ExplorePeriodComparison.propTypes = {
  setVizState: PropTypes.func.isRequired,
  filters: FilterType,
  measures: MeasureType,
  dimensions: FilterType,
};

function Picker({
  onChange,
  value,
  options,
  width = '120px',
  height = '32px',
  dropdownWidth = '120px',
  paddingBottom = '0.25rem',
}) {
  return (
    <Select
      width={width}
      height={height}
      dropdownWidth={dropdownWidth}
      paddingBottom={paddingBottom}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      options={options}
      value={value}
      onChange={onChange}
      // @ts-ignore
      styles={PICKER_STYLES}
    />
  );
}

Picker.defaultProps = {
  value: null,
  width: undefined,
  height: undefined,
  dropdownWidth: undefined,
  paddingBottom: undefined,

};

const OptionType = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.number)]),
  label: PropTypes.string,
});

Picker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: OptionType,
  options: PropTypes.arrayOf(OptionType).isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  dropdownWidth: PropTypes.string,
  paddingBottom: PropTypes.string,
};
