
import React from 'react';

import { BasicError } from '../Helpers/Error';
import { BasicLoading } from '../Helpers/Loading';

import { TestingAdContainer } from './TestingAd';
import { useFetchStatus, useFetchOnMount } from './Container';
import { LayoutContainer } from '../../features/CreativeReports/Helpers';

const Wrapper = () => {
  useFetchOnMount();

  const { adError, carouselError, loadingCreateAd } = useFetchStatus();

  if (!!adError || !!carouselError) {
    return (
      <BasicError
        fallback={(
          <LayoutContainer>
            <div>
              <h3>An error occurred</h3>
              <p>{JSON.stringify(adError || carouselError)}</p>
            </div>
          </LayoutContainer>
        )}
      />
    );
  } if (loadingCreateAd) {
    return (
      <LayoutContainer>
        <BasicLoading />
      </LayoutContainer>
    );
  }
  return <TestingAdContainer />;
};

export default Wrapper;
