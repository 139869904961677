import React from 'react';
import './RightRail.scss';
import TopPerformersWrapper from '../TopPerformers';

const RightRail = ({
  recsTab,
  TryThese,
  isROAS,
  activeTTSection,
  setActiveTTSection,
  showRightRail,
  setShowRightRail,
  handleBlockValue,
  handleBlockVisualType,
  railRef,
}) => {
  return (
    <div
      ref={railRef}
      className={`draft-creative__right-rail ${showRightRail ? 'show' : ''}`}
    >
      <TopPerformersWrapper
        fromDraftCreative={true}
        {...{
          recsTab,
          TryThese,
          isROAS,
          activeTTSection,
          setActiveTTSection,
          setShowRightRail,
          handleBlockValue,
          handleBlockVisualType,
        }}
      />
    </div>
  );
};

export default RightRail;
