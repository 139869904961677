import React from "react";
import PropTypes from "prop-types";

import "./Loading.style.scss";

export const Loading = () => (
  <div className="Loading">
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#4285F4"
    >
      <g fill="none" fillRule="evenodd" strokeWidth="2">
        <circle cx="22" cy="22" r="1">
          <animate
            attributeName="r"
            begin="0s"
            dur="1.8s"
            values="1; 20"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.165, 0.84, 0.44, 1"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-opacity"
            begin="0s"
            dur="1.8s"
            values="1; 0"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.3, 0.61, 0.355, 1"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="22" cy="22" r="1">
          <animate
            attributeName="r"
            begin="-0.9s"
            dur="1.8s"
            values="1; 20"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.165, 0.84, 0.44, 1"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-opacity"
            begin="-0.9s"
            dur="1.8s"
            values="1; 0"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.3, 0.61, 0.355, 1"
            repeatCount="indefinite"
          />
        </circle>
      </g>
    </svg>
  </div>
);

export const LoadingOverlay = ({ children = null, useSpinner = null }) => (
  <div className="loading-overlay">
    <div className="loading-overlay-content d-flex justify-content-center">
      {children}
      {!!useSpinner && <Loading />}
    </div>
  </div>
);

LoadingOverlay.propTypes = {
  children: PropTypes.node,
};

/**
 * @param {{ isLoading: Boolean, children?: React.Component }} props
 */
export const WhenLoading = ({ isLoading, children, placeholder }) => {
  if (isLoading) {
    return (
      <div className="row">
        <div className="col-md-12">
          {placeholder ? placeholder : <Loading />}
        </div>
      </div>
    );
  } else {
    return children;
  }
};

export const BasicLoading = ({ fallback = null }) => (
  <div className="row">
    <div className="col-md-12">{fallback ? fallback : <Loading />}</div>
  </div>
);
