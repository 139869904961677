import { useState, useEffect } from 'react';
import { useCreativeAnalyticsGet } from '../contexts';
import { setOutcomesData } from '../utils';
import { useActionName } from '../selectors';

export const useOutcomes = () => {
  const [outcomes, setOutcomes] = useState(null);
  const { selected, filteredData } = useCreativeAnalyticsGet();
  const selectedId = selected.element ? selected.element.value : '';

  useEffect(() => {
    if (
      !selected.period &&
      filteredData &&
      selectedId &&
      selected.type === 'ad_label'
    ) {
      calculateOutcomes(filteredData);
    } else {
      setOutcomes(null);
    }
  }, [filteredData, selectedId]);

  const calculateOutcomes = (data) => {
    data.forEach((label) => {
      if (label.value === selectedId) {
        const result = setOutcomesData(label);
        setOutcomes(result);
      }
    });
  };

  return { outcomes };
};
