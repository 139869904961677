import { DataState } from './types';

export const initialDataState: DataState = {
  dataToInclude: {
    dna: true,
    impact: true,
    index: false,
    inspiration: false,
    summary: true,
  },
  requestType: 'creative-request',
  instructions: '',
  currentOutput: {
    summary: '',
    reasons: [],
  },
  previousSummary: '',
  userFeedback: '',
  clientPreferences: '',
  advertiserDetails: '',
  baseVisual: {
    id: '',
    asset_url: '',
    visual_type: '',
    labels: [],
  },
};

export const LOADING_MESSAGES = [
  'Pushing pixels',
  'Up, Up, Down, Down, Left, Right, Left, Right, B, A',
  'Reversing the shield polarity',
  'Reticulating splines',
  'Waking up the minions',
  'Mining some Bitcoin',
  'Looking for exact change',
  'Ordering 1s and 0s',
  'Writing witty dialogue',
  'Swapping time and space',
  'Following the white rabbit',
  `Dotting all the i's`,
];
