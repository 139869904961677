import { darwinDateToMs, msToDarwinDate, DAY, nextDay } from './date-utils';
import { BAR_COUNT, ACTIVE_BAR_COUNT } from './constants';

/**
 * @param {string} a YYYY-MM-DD
 * @param {string} b YYYY-MM-DD
 *
 * @return {string} YYYY-MM-DD
 */
export const alphabeticalMax = (a, b) => {
  const cmp = a.localeCompare(b);

  return cmp === -1 ? b : a;
};

export const alphabeticalMin = (a, b) => {
  const cmp = a.localeCompare(b);

  return cmp === 1 ? b : a;
};

export function formToNewForm({
  start_date,
  end_date,
  description,
  study_type,
  testing_study_type,
  automation_study_type,
  value: study_name,
  in_progress,
  difference,
  backlog,
  y,
  test_details: {
    campaign_id,
    comparison_campaigns,
    group_a_adset_string,
    group_a_adset_id,
    group_a_ad_string,
    group_a_ad_id,
    group_a_label,
    group_b_adset_string,
    group_b_adset_id,
    group_b_ad_string,
    group_b_ad_id,
    group_b_label,
    spend_goal,
    status,
  },
}) {
  return {
    y,
    in_progress,
    backlog,
    difference,
    description,
    testing_study_type,
    automation_study_type,
    study_type,
    study_name,
    end_date,
    start_date,
    campaign_id,
    comparison_campaigns,
    group_a_adset_id,
    group_a_ad_id,
    group_a_ad_string,
    group_a_adset_string,
    group_a_label,
    group_b_adset_id,
    group_b_ad_id,
    group_b_ad_string,
    group_b_adset_string,
    group_b_label,
    spend_goal,
    status,
  };
}

export function newFormToForm({
  description,
  testing_study_type,
  automation_study_type,
  study_type,
  study_name,
  end_date,
  start_date,
  campaign_id,
  comparison_campaigns,
  group_a_adset_id,
  group_a_adset_string,
  group_a_ad_id,
  group_a_ad_string,
  group_a_label,
  group_b_adset_id,
  group_b_adset_string,
  group_b_ad_id,
  group_b_ad_string,
  group_b_label,
  spend_goal,
  status,
}) {
  return {
    start_date,
    end_date,
    description,
    study_type,
    testing_study_type,
    automation_study_type,
    value: study_name,
    in_progress: false,
    difference: 0,
    backlog: false,
    test_details: {
      campaign_id,
      comparison_campaigns,
      group_a_adset_string:
        group_a_adset_string == '' ? group_a_adset_id : group_a_adset_string,
      group_a_adset_id,
      group_a_ad_string:
        group_a_ad_string == '' ? group_a_ad_id : group_a_ad_string,
      group_a_ad_id,
      group_a_label,
      group_b_adset_string:
        group_b_adset_string == '' ? group_b_adset_id : group_b_adset_string,
      group_b_adset_id,
      group_b_ad_string:
        group_b_ad_string == '' ? group_b_ad_id : group_b_ad_string,
      group_b_ad_id,
      group_b_label,
      spend_goal,
      status,
    },
    y: 0,
  };
}

export function eventToForm({
  description,
  study_type,
  study_name,
  start_date,
  end_date,
  campaigns,
  y_axis,
}) {
  return {
    start_date,
    end_date,
    description,
    study_type,
    value: study_name,
    in_progress: false,
    difference: 0,
    backlog: false,
    test_details: {
      campaigns,
    },
    y: y_axis,
  };
}

/**
 * @description Converts api response & form data into
 * shape for Highcharts series.
 */
export function formToBar({
  value,
  in_progress,
  difference,
  backlog,
  description,
  study_type,
  testing_study_type,
  automation_study_type,
  start_date,
  end_date,
  test_details,
  y = 0,
}) {
  const start = darwinDateToMs(start_date);
  const end = darwinDateToMs(end_date);
  const { color, dragDrop } = condValues(in_progress, study_type);

  return {
    details: {
      description,
      start_date,
      end_date,
      study_type,
      testing_study_type,
      automation_study_type,
      ...test_details,
    },
    schema: 'bar',
    id: value,
    name: value,
    y: backlog && y < 3 ? 3 : y,
    start,
    end,
    duration: parseInt((end - start) / DAY),
    difference,
    in_progress,
    color,
    dragDrop,
    // highchart extras
    milestone: false,
    dependency: false,
  };
}

export function apiToBar({
  value,
  y_axis,
  difference,
  in_progress,
  description,
  backlog,
  study_type,
  testing_study_type,
  automation_study_type,
  start_date,
  end_date,
  test_details,
  campaigns,
  status,
}) {
  const _end_date = start_date === end_date ? nextDay(end_date) : end_date;

  const start = darwinDateToMs(start_date);
  const end = darwinDateToMs(_end_date);
  const { color, dragDrop } = condValues(in_progress, study_type);

  return {
    details: {
      campaign: campaigns ? campaigns[0] : '',
      description,
      start_date,
      end_date: _end_date,
      study_type,
      testing_study_type,
      automation_study_type,
      status,
      ...test_details,
    },
    schema: 'bar',
    id: value,
    name: value,
    y: y_axis,
    start,
    end,
    duration: parseInt((end - start) / DAY),
    in_progress,
    color,
    dragDrop,
    difference,
    // highchart extras
    milestone: false,
    dependency: false,
  };
}

function condValues(inProgress, study_type) {
  const color =
    study_type === 'significant_event'
      ? '#666666'
      : inProgress
      ? '#01728b'
      : '#ffcb36';

  if (inProgress) {
    return {
      color,
      dragDrop: {
        draggableX: false,
        draggableStart: false,
        draggableEnd: false,
        // dragMinX: start + DAY,
        draggableY: true,
      },
    };
  }
  return {
    color,
    dragDrop: {
      draggableX: true,
      draggableY: true,
      draggableStart: false,
      draggableEnd: false,
    },
  };
}

/**
 * @description Converts Highcharts bar to form/api data
 */
export function barToForm({
  id,
  start,
  end,
  y,
  in_progress,
  difference,
  details: {
    study_type,
    testing_study_type,
    automation_study_type,
    campaign_id,
    campaign_name,
    campaigns,
    comparison_campaigns,
    group_a_label,
    group_a_ad_string,
    group_a_ad_id,
    group_a_adset_string,
    group_a_adset_id,
    group_b_label,
    group_b_adset_string,
    group_b_adset_id,
    group_b_ad_string,
    group_b_ad_id,
    description,
    spend_goal,
    status,
  },
}) {
  return {
    schema: 'form',
    value: id,
    study_type,
    testing_study_type,
    automation_study_type,
    description,
    difference,
    backlog: y > 7,
    y,
    start_date: msToDarwinDate(start),
    end_date: msToDarwinDate(end),
    in_progress,
    test_details: {
      campaign_id,
      campaign_name,
      campaigns,
      comparison_campaigns,
      group_a_label,
      group_a_adset_string,
      group_a_adset_id,
      group_a_ad_string,
      group_a_ad_id,
      group_b_label,
      group_b_adset_string,
      group_b_adset_id,
      group_b_ad_string,
      group_b_ad_id,
      spend_goal,
      status,
    },
  };
}

/**
 * @param {Object} Change
 * @param {string} Change.value
 * @param {number=} Change.y
 * @param {Object} Change.test_details
 * @param {string=} Change.test_details.campaign
 *
 * @param {string} view_id
 */
export function formToApi({ value, ...rest }, view_id) {
  const {
    y,
    test_details: { campaign, ...details },
    ...remaining
  } = rest;

  return {
    view_id,
    study_name: value,
    campaign_name: campaign,
    y_axis: y,
    ...details,
    ...remaining,
  };
}

/**
 * @param {{ start: number, end: number, y: number, in_progress: boolean, backlog: boolean }[]} data
 * @param {number=} maxBuckets
 *
 * @return {{ start: number, end: number, y: number, in_progress: boolean }[]}
 */
export function assignVerticalIndex(
  data,
  maxBuckets = BAR_COUNT,
  backlogStartIndex = ACTIVE_BAR_COUNT
) {
  const byStart = [...data].sort(byStatusThenDate);

  // [...{ start, end }]
  const buckets = [];
  const results = [];

  for (const { start, end, y, in_progress, ...rest } of byStart) {
    let i = !in_progress && y > backlogStartIndex - 1 ? backlogStartIndex : 0;

    while (i < maxBuckets) {
      const bucket = buckets[i];

      if (!bucket) {
        buckets.push({ start, end });
        break;
      } else if (bucket.end <= start) {
        bucket.end = end;
        break;
      } else {
        i++;
      }
    }

    results.push({
      ...rest,
      start,
      end,
      in_progress,
      y: i,
    });
  }

  return results;
}

function byStatusThenDate(a, b) {
  if (a.in_progress && b.in_progress) {
    return a.start - b.start;
  }
  if (a.in_progress) {
    return -1;
  }
  if (b.in_progress) {
    return 1;
  }
  return a.start - b.start;
}

/**
 * @param {string} name
 * @param {number} days
 *
 * @return {string}
 */
export function adjustDisplayName(name, days) {
  if (days < 7) {
    return maybeTrunc(name, 5);
  }
  if (days >= 7 && days < 14) {
    return maybeTrunc(name, 12);
  }
  if (days >= 14 && days < 21) {
    return maybeTrunc(name, 20);
  }
  if (days >= 21 && days < 28) {
    return maybeTrunc(name, 28);
  }
  return name;
}

function maybeTrunc(string, toIndex) {
  const L = string.length;

  return L <= toIndex ? string : `${string.slice(0, toIndex)}...`;
}

export function isEvent({ study_type }) {
  return study_type === 'significant_event';
}
