import React from 'react';

const GridIcon = ({ className = '', onClick }) => (
  <svg
    className={className}
    onClick={onClick}
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.75439 2.35263H3.25439C3.05548 2.35263 2.86472 2.42726 2.72406 2.5601C2.58341 2.69293 2.50439 2.8731 2.50439 3.06096V7.31096C2.50439 7.49883 2.58341 7.67899 2.72406 7.81183C2.86472 7.94467 3.05548 8.0193 3.25439 8.0193H7.75439C7.95331 8.0193 8.14407 7.94467 8.28472 7.81183C8.42538 7.67899 8.50439 7.49883 8.50439 7.31096V3.06096C8.50439 2.8731 8.42538 2.69293 8.28472 2.5601C8.14407 2.42726 7.95331 2.35263 7.75439 2.35263ZM7.00439 6.60263H4.00439V3.7693H7.00439V6.60263ZM10.7544 8.0193H15.2544C15.4533 8.0193 15.6441 7.94467 15.7847 7.81183C15.9254 7.67899 16.0044 7.49883 16.0044 7.31096V3.06096C16.0044 2.8731 15.9254 2.69293 15.7847 2.5601C15.6441 2.42726 15.4533 2.35263 15.2544 2.35263H10.7544C10.5555 2.35263 10.3647 2.42726 10.2241 2.5601C10.0834 2.69293 10.0044 2.8731 10.0044 3.06096V7.31096C10.0044 7.49883 10.0834 7.67899 10.2241 7.81183C10.3647 7.94467 10.5555 8.0193 10.7544 8.0193ZM11.5044 3.7693H14.5044V6.60263H11.5044V3.7693ZM2.50439 14.3943C2.50439 14.5822 2.58341 14.7623 2.72406 14.8952C2.86472 15.028 3.05548 15.1026 3.25439 15.1026H7.75439C7.95331 15.1026 8.14407 15.028 8.28472 14.8952C8.42538 14.7623 8.50439 14.5822 8.50439 14.3943V10.1443C8.50439 9.95644 8.42538 9.77627 8.28472 9.64343C8.14407 9.51059 7.95331 9.43596 7.75439 9.43596H3.25439C3.05548 9.43596 2.86472 9.51059 2.72406 9.64343C2.58341 9.77627 2.50439 9.95644 2.50439 10.1443V14.3943ZM4.00439 10.8526H7.00439V13.686H4.00439V10.8526ZM10.0044 14.3943C10.0044 14.5822 10.0834 14.7623 10.2241 14.8952C10.3647 15.028 10.5555 15.1026 10.7544 15.1026H15.2544C15.4533 15.1026 15.6441 15.028 15.7847 14.8952C15.9254 14.7623 16.0044 14.5822 16.0044 14.3943V10.1443C16.0044 9.95644 15.9254 9.77627 15.7847 9.64343C15.6441 9.51059 15.4533 9.43596 15.2544 9.43596H10.7544C10.5555 9.43596 10.3647 9.51059 10.2241 9.64343C10.0834 9.77627 10.0044 9.95644 10.0044 10.1443V14.3943ZM11.5044 10.8526H14.5044V13.686H11.5044V10.8526Z"
      fill="#5D678B"
    />
  </svg>
);

export default GridIcon;
