import { DARWIN_BLUE } from '../../../constants';

export const PREVIEW_DROPDOWN_STYLE = {
  control: (styles) => ({
    ...styles,
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
  }),
  valueContainer: (styles) => ({
    ...styles,
    overflow: 'visible',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  container: (provided) => ({
    ...provided,
    borderRadius: '2px',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '10px',
    fontSize: '1.1rem',
    color: '#667394',
    width: 'fit-content',
    padding: '0.5rem',
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    fontSize: '1rem',
    backgroundColor: state.isSelected ? DARWIN_BLUE : 'white',
    ':hover': {
      backgroundColor: '#F2F8FF',
    },
    borderRadius: '5px',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#667394',
    fontSize: '1.1rem',
    overflow: 'visible',
  }),
};
