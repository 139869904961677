import { DARWIN_NAVY_2, DARWIN_NAVY_4 } from '../../constants';
import { LabelPerformanceReportState } from './types/types';

export const CHOSEN_LABEL = 'chosen_label';
export const COMPARISON_LABEL = 'comparison_label';
export const ARRAY_OF_AD_NAME_STRINGS = 'array_of_ad_name_strings';
export const HARD_REFRESH = 'hard_refresh';
export const INCLUDE_MISSING_PERMISSIONS = 'include_missing_permissions';

export const INITIAL: LabelPerformanceReportState = {
  [CHOSEN_LABEL]: null,
  [COMPARISON_LABEL]: null,
  [HARD_REFRESH]: false,
  [ARRAY_OF_AD_NAME_STRINGS]: [],
  [INCLUDE_MISSING_PERMISSIONS]: false,
};

export const SELECT_STYLES = {
  control: (styles) => ({
    ...styles,
    backgroundColor: '#F9FBFF',
    border: '1px solid #EFF4FA',
    // boxShadow: '0px 3px 3px -3px #C1CDDE',
    // ':hover': {
    //   transform: 'translateY(-1px)',
    //   transition: '0.1s ease-in-out',
    //   boxShadow: '0px 3px 3px -3px rgb(226, 231, 240)',
    // },
    fontSize: '1.1rem',
    color: '#566577',
    cursor: 'pointer',
    width: 'max-content',
    minWidth: '210px',
  }),
  valueContainer: (styles) => ({
    ...styles,
    overflow: 'visible',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: '#dedede',
    borderLeft: 0,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    color: '#dedede',
  }),
  container: (provided) => ({
    ...provided,
    borderRadius: '2px',
    width: '100%',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '2px',
    border: 'none',
    fontSize: '1.1rem',
    color: '#667394',
    width: 'max-content',
    minWidth: '100%',
    padding: '0.5rem',
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    cursor: 'pointer',
    fontSize: '1rem',
    color: DARWIN_NAVY_2,
    backgroundColor: isFocused ? '#F2F8FF' : 'white',
    ':hover': {
      backgroundColor: '#F2F8FF',
    },
    borderRadius: '2px',
  }),
  singleValue: (provided, { isDisabled }) => ({
    ...provided,
    color: isDisabled ? DARWIN_NAVY_4 : DARWIN_NAVY_2,
    fontSize: '1.1rem',
    fontWeight: '500',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: DARWIN_NAVY_2,
  }),
};

