import { useRef, useEffect } from 'react';
import { abortableFetch } from '../../utils';
import { useSelector } from 'react-redux';

import {
  useApiState,
  bindFetchToRef,
} from '../../components/Hooks/useApiState';

function listTests(account_id, darwin_client_id) {
  return abortableFetch(
    [
      'api',
      'creative-testing',
      `${account_id}?darwin_client_id=${darwin_client_id}`,
    ].join('/'),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      credentials: 'include',
    }
  );
}

function saveTest(payload) {
  return abortableFetch(
    ['api', 'creative-testing', payload.account_id, 'save'].join('/'),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(payload),
      credentials: 'include',
    }
  );
}

function deleteTest(payload) {
  return abortableFetch(
    ['api', 'creative-testing', payload.account_id, 'delete'].join('/'),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(payload),
      credentials: 'include',
    }
  );
}

export function useCreativeTestingApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const darwin_client_id = useSelector(
    ({ ViewSettings: { darwin_client_id } }) => darwin_client_id
  );
  const [getState, getDispatch] = useApiState(
    (account_id) =>
      withRef(listTests(account_id, darwin_client_id)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(saveTest(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );
  const [deleteState, deleteDispatch] = useApiState(
    (payload) => withRef(deleteTest(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    get: {
      ...getState,
      request: getDispatch,
    },
    post: {
      ...postState,
      request: postDispatch,
    },
    delete: {
      ...deleteState,
      request: deleteDispatch,
    },
  };
}

function listBlocks(account_id, darwin_client_id) {
  return abortableFetch(
    [
      'api',
      'creative-testing',
      account_id,
      `blocks?darwin_client_id=${darwin_client_id}`,
    ].join('/'),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      credentials: 'include',
    }
  );
}

export function useBlocksApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const darwin_client_id = useSelector(
    ({ ViewSettings: { darwin_client_id } }) => darwin_client_id
  );
  const [getState, getDispatch] = useApiState(
    (account_id) =>
      withRef(listBlocks(account_id, darwin_client_id)).then(
        ({ data }) => data
      ),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    get: {
      ...getState,
      request: getDispatch,
    },
  };
}

function getBlockStatistics(payload) {
  return abortableFetch(
    ['api', 'creative-testing', payload.account_id, 'stats'].join('/'),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(payload),
    }
  );
}

export function useBlockStatisticsApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(getBlockStatistics(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: postDispatch,
    },
  };
}
