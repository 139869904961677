import React from 'react';
import { DARWIN_NAVY } from '../../../../constants';

export const HurdleIcon = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.24483 3.14386V1.3451H3.99951V3.14386H2.24483ZM1.74483 3.74385H2.24483H3.99951H5.99951H8.00049H10.0005H11.7555H12.2555V10.9241C12.2555 11.2003 12.4793 11.4241 12.7555 11.4241C13.0316 11.4241 13.2555 11.2003 13.2555 10.9241V3.74385H13.2809C13.557 3.74385 13.7809 3.52 13.7809 3.24385V1.2451C13.7809 0.968953 13.557 0.745095 13.2809 0.745095H13.2555H12.7555H11.7555H10.0005H8.00049H5.99951H3.99951H2.24483H1.24483H0.744827H0.719131C0.442989 0.745095 0.219131 0.968953 0.219131 1.2451V3.24385C0.219131 3.52 0.44299 3.74385 0.719132 3.74385H0.744827V10.9241C0.744827 11.2003 0.968685 11.4241 1.24483 11.4241C1.52097 11.4241 1.74483 11.2003 1.74483 10.9241V3.74385ZM11.7555 1.3451H10.0005V3.14386H11.7555V1.3451ZM8.00049 1.3451H5.99951V3.14386H8.00049V1.3451Z"
        fill={DARWIN_NAVY}
      />
    </svg>
  );
};
