import React, { useMemo } from "react";
import "../Tables.scss";
import { useTable, useSortBy, useFlexLayout } from "react-table";

import { useCategoryDetailsColumns } from "../Columns";

import { HeaderRow, Row } from "../ImpactReportTable/ImpactReportTable";
import { useImpactReportContext } from "../../contexts";

const CategoryDetailsTable = ({
  selectedReport,
  selectedCategory,
  selectedLabel,
  handleSelectLabel,
}) => {
  const { isROAS } = useImpactReportContext();
  const { categories } = selectedReport;

  const labelBreakdown = selectedCategory ? Object.entries(
    categories[selectedCategory].label_breakdown
  ).map(([key, val]) => {
    return {
      name: key,
      budget_percentage: val.budget_percentage,
      cpa: val.cpa,
      roas: val.roas,
    };
  }).sort((a, b) => {
    const aVal = isROAS ? a.roas : a.cpa;
    const bVal = isROAS ? b.roas : b.cpa;

    if (isROAS) {
      return (aVal === null) - (bVal === null) || +(aVal < bVal) || -(aVal > bVal)
    }
    return (aVal === null) - (bVal === null) || +(aVal > bVal) || -(aVal < bVal)
  }) : [];

  const memoed = useMemo(
    () => ({
      cols: useCategoryDetailsColumns(selectedCategory, isROAS),
      rows: labelBreakdown,
    }),
    [selectedCategory]
  );

  const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } =
    useTable(
      {
        columns: memoed.cols,
        data: memoed.rows,
      },
      useSortBy,
      useFlexLayout
    );

  return (
    <table {...getTableProps()} className="impact-report__table">
      <thead>
        {headerGroups.map((headerGroup) => (
          <>
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return <HeaderRow {...{ column }} />;
              })}
            </tr>
          </>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);

          return (
            <Row
              handleClick={handleSelectLabel}
              active={selectedLabel === row.original.name}
              {...{ row, i, selectedCategory }}
            />
          );
        })}
      </tbody>
    </table>
  );
};

export default CategoryDetailsTable;