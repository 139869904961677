import { toCurrency, toFixed_2, toPercent_2 } from '../../../../utils/numbers';

export const getFilteredMetrics = (metricsData, reportCardSettings, isDirectMail, viewName) => {
  const { metric_order } = reportCardSettings;

  if (
    isDirectMail &&
    viewName.toLowerCase().includes('organic') &&
    viewName.toLowerCase().includes('competitor')
  ) {
    return metricsData;
  }

  return metric_order.reduce((acc, metric, i) => {
    const match = metricsData.find(
      (metricOption) => metricOption.metric === metric.metric
    );

    if (
      match &&
      metric.enabled &&
      match.value !== null &&
      match.value !== undefined
    ) {
      let updatedValue;

      switch (match.format) {
        case 'currency':
          updatedValue = toCurrency(match.value);
          break;
        case 'percent':
          updatedValue = toPercent_2(match.value);
          break;
        case 'decimal':
            updatedValue = toFixed_2(match.value);
            break;
        default:
          updatedValue = match.value;
      }

      acc.push({ ...match, metricIndex: i, value: updatedValue });
    }

    return acc;
  }, []);
};
