// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./stripe.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".bar-chart__item{margin-bottom:1.5rem;position:relative}.bar-chart__tooltip{font-family:'Fira Code';border:1px solid #dde6f3;border-radius:4px;background-color:#fff !important;font-weight:600 !important;color:#1e2b4e}.bar-chart__label{flex:1;text-align:right;margin-right:1.5rem;font-weight:500;font-family:'Fira Code';width:70px;position:relative;overflow:hidden;display:block;align-items:center;justify-content:flex-end;white-space:nowrap;text-overflow:ellipsis}.bar-chart__label span{position:absolute;right:1rem}.bar-chart__label.expand{transition:0.2s ease-in-out}.bar-chart__label.expand span{top:0}.bar{background:#283a69;position:relative;padding:0.75rem;display:flex;align-items:center;flex:1;box-shadow:4px 4px 0px rgba(96,110,145,0.25);z-index:1;border-radius:5px}.bar__value{color:#fff;font-weight:500;position:relative;letter-spacing:0.05em;z-index:50;font-size:1.2rem;margin-bottom:0}.bar__fill{background:#4b48e9;position:absolute;display:block;height:100%;bottom:0;left:0;border-radius:5px}.bar__fill::before{content:'';position:absolute;left:0;right:0;top:0;bottom:0;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") transparent repeat fixed;mix-blend-mode:multiply;opacity:0.35}.bar__fill.best-performing{background:#0E9EBF}\n", ""]);
// Exports
module.exports = exports;
