import React from 'react';
import Button from '../../../../../components/Button';
import Tooltip from '../../../../../components/Tooltip';
import { useCreativeAnalyticsGet } from '../../../contexts';

type SidebarProps = {
  prev: () => void;
  input: any;
  labelCounter;
  handleSave: () => void;
  handleTrain: () => void;
  handleDelete: () => void;
  loadingMsg: string;
  isLoading: boolean;
  tempMsg: string;
  isValid: boolean;
  existing: any;
  changed: boolean;
  isSingle: boolean;
};

const Sidebar = ({
  prev,
  input,
  labelCounter,
  handleSave,
  handleTrain,
  handleTrainOne,
  handleSaveOne,
  handleDelete,
  loadingMsg,
  isLoading,
  tempMsg,
  isValid,
  existing,
  changed,
  isSingle,
  visualModeChanged,
  selectedLabel,
  setSelectedLabel,
  singleToFull = null,
  lockInAll,
  predictedVisuals,
  allowLockIn,
}: SidebarProps) => {
  const { asyncApi } = useCreativeAnalyticsGet();
  const isExistingProcess = asyncApi?.processes?.some(
    (process) => process.category === input.name
  );
  const onSave = isSingle ? handleSaveOne : handleSave;
  const onTrain = isSingle ? handleTrainOne : handleTrain;

  let TooltipWrapper = ({ children }) => {
    if (isExistingProcess) {
      return (
        <Tooltip
          content={
            isExistingProcess
              ? 'There are already changes currently being saved to this category. Please wait.'
              : null
          }
        >
          <div style={{ flex: 1 }}>{children}</div>
        </Tooltip>
      );
    }

    return children;
  };

  const handleSelectLabel = (label) => {
    if (selectedLabel === label) {
      setSelectedLabel('');
    } else {
      setSelectedLabel(label);
    }
  };

  return (
    <div className="sidebar">
      <div>
        <div className="d-flex justify-content-between">
          {!isSingle && (
            <button className="sidebar__back" onClick={prev}>
              <i className="fas fa-arrow-left"></i>
              Back
            </button>
          )}
        </div>

        <h2
          className="sidebar__title"
          style={{ marginTop: isSingle ? '0' : '2rem' }}
        >
          {input.name}
        </h2>
        <ul className="sidebar__list">
          {Object.entries(labelCounter).map(([key, val]) => (
            <li
              className={`sidebar__label ${
                val.label === selectedLabel ? 'sidebar__label--selected' : ''
              }`}
              title={val.label}
              key={key}
              onClick={() => handleSelectLabel(val.label)}
            >
              <p className="sidebar__label-name">{val.label}</p>
              <p className="sidebar__label-count">{val.count}/2</p>
            </li>
          ))}
        </ul>
        <p>Select two of each in order to train the model.</p>
      </div>
      <div className="w-100">
        {tempMsg && <p className="sidebar__msg">{tempMsg}</p>}
        {loadingMsg && <p className="sidebar__msg">{loadingMsg}</p>}
        <div className="d-flex flex-column">
          {/* {existing && !isSingle && ( */}
          {allowLockIn ? (
            <Tooltip content="This will convert your predicted labels to annotations to strengthen the accuracy of the model.
            Note: You should only proceed if you are confident that all of the predictions for this AI model are correct. If there are incorrect predictions, you will actually be training the AI on incorrect data, leading to more inaccuracies in the future.">
              <div>
                <Button
                  appearance="subtleBordered"
                  onClick={lockInAll}
                  fullWidth
                  className="mb-2"
                  style={{ flex: 1 }}
                >
                  <i class="fa fa-lock mr-2" style={{ fontSize: '10px' }}></i>
                  Lock in all predictions
                </Button>
              </div>
            </Tooltip>
          ) : null}

          <Button
            fullWidth
            appearance="red"
            onClick={handleDelete}
            className="mb-2"
            style={{ flex: 1 }}
          >
            Delete
          </Button>
          {/* )} */}
          {singleToFull && (
            <Button
              appearance="secondary"
              className="mb-2"
              fullWidth
              style={{ flex: 1 }}
              onClick={singleToFull}
            >
              Edit full category
            </Button>
          )}

          <TooltipWrapper>
            <Button
              appearance="secondary"
              className="mb-2"
              disabled={isLoading || isExistingProcess}
              fullWidth
              onClick={onSave}
              style={{ flex: 1 }}
            >
              Save
            </Button>
          </TooltipWrapper>
        </div>
        <TooltipWrapper>
          <Button
            disabled={!changed || isLoading || !isValid || isExistingProcess}
            fullWidth
            onClick={onTrain}
          >
            Train
          </Button>
        </TooltipWrapper>
      </div>
    </div>
  );
};

export default Sidebar;
