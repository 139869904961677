import { RUNNING } from './CreativeTesting/constants';
import { PARAM_DEFAULTS } from './constants';
import { MARKETPLACE_STATE } from './Marketplace/index';
import { initialState as CreativeAnalyticsInitialState } from './CreativeAnalytics/creativeAnalyticsSlice';

const {
  report_type,
  start_date,
  end_date,
  selectedAudience,
  spendThreshold,
  creativeCount,
  exclude,
  visualType,
} = PARAM_DEFAULTS;

const INITIAL_STATE = {
  AdPreviews: {
    hasError: false,
    isLoading: false,
    previews: [],
    // {...<ad-name>: { previews: Number} }
    previewsIndex: {},
  },
  ViewSettings: {
    viewId: '',
    account_id: '',
    campaigns: [],
    viewName: '',
    views: [],
    start_date,
    end_date,
    audience: [],
    selectedAudience,
    spendThreshold,
    creativeCount,
    token: null,
    loggedIn: false,
    cubeToken: '',
    dashboardConfig: [],
    action_name: { name: '', value: '' },
    excludeAds: exclude,
    visualType,
    roles: [],
    initialView: true,
    custom_events: null,
    concepts: [],
  },

  // new design
  Adsets: {
    byNames: [],
    adsets: {},
  },
  CreativeReports: {
    report_type,
    reports: {},
    reports_p2: {},
  },
  RunningAdsByName: {
    // only need a reference to selected names
    // not all names
    selectedNames: [],
    // {...<ad-name>: Number }
    ads: {},
  },
  CreativeTesting: {
    currentId: '',
    currentStateType: RUNNING,

    navIds: [],
    // {...<ad_id>: Object }
    navItems: {},
    ads: {},
  },
  CreateAd: {
    payload: {
      atf: {},
      btf: {},
      cta: {},
      visual: {},
      _id: '',
    },
    ads: {},
    byIds: [],
    carousel: [],
  },

  Marketplace: MARKETPLACE_STATE,

  // For looking up relationships.
  Lookups: {
    // adNameTolabels: {...<ad-name>: [...<ad-label>] }
  },
  Errors: {},
  Loaders: {},
  Notifications: [],
  CreativeAnalytics: CreativeAnalyticsInitialState,
};

export default INITIAL_STATE;
