import React, { useReducer, useState, useEffect } from 'react';
import { derivedLabelsReducer, makeEmptyRule, SET_DATA } from './reducer';
import { deriveListOfRules } from './utils';

export const useDerivedLabelsState = () => {
  const [derivedLabelsState, dispatchDerivedLabels] = useReducer(
    derivedLabelsReducer,
    {
      category_name: '',
      original_category_name: '',
      selected_rule: null,
      data: [],
      rules: [],
      isExisting: false,
      description: '',
    }
  );

  useEffect(() => {
    if (derivedLabelsState.data) {
      if (derivedLabelsState.data.length < 1) {
        const emptyRule = makeEmptyRule();
        dispatchDerivedLabels({
          type: SET_DATA,
          data: {
            rules: [emptyRule],
            selected_rule: emptyRule.rule_id,
          },
        });
      } else {
        const rules = deriveListOfRules(derivedLabelsState.data);
        dispatchDerivedLabels({
          type: SET_DATA,
          data: {
            rules: rules,
            selected_rule: rules[0].rule_id,
          },
        });
      }
    }
  }, [derivedLabelsState.data]);

  return [derivedLabelsState, dispatchDerivedLabels];
};
