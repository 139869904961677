import { useHistory, useLocation } from 'react-router-dom';

function paramsToObject(iter) {
  const results = {};

  [...iter].forEach(([key, val]) => {
    results[key] = val;
  });

  return results;
}

export function useSearchParams() {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const setParams = (update, callback) => {
    const asObj = paramsToObject(params);
    const search = new URLSearchParams({ ...asObj, ...update }).toString();

    history.push({ search });

    if (callback) {
      callback();
    }
  };

  const getParams = (...keys) => {
    const results = {};

    keys.forEach((k) => {
      results[k] = params.get(k);
    });

    return results;
  };

  const clearParams = (callback = null) => {
    history.push({ search: null });

    if (callback) {
      callback();
    }
  };

  const replaceParams = (update) => {
    const search = new URLSearchParams(update).toString();

    history.push({ search });
  };

  const removeParams = (update) => {
    const temp = params;
    temp.delete(update);
    const search = temp.toString();

    history.push({ search });
  };

  return {
    history,
    setParams,
    getParams,
    clearParams,
    replaceParams,
    removeParams,
  };
}
