import { useRef, useEffect } from 'react';

import { abortableFetch } from '../../../utils';
import {
  useApiState,
  bindFetchToRef,
} from '../../../components/Hooks/useApiState';

function postAdPreviews(view_id, payload) {
  return abortableFetch(['api', view_id, 'ad-previews'].join('/'), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });
}

export function useGetAdPreviews() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (view_id, payload) =>
      withRef(postAdPreviews(view_id, payload)).then((data) => data),
    {
      data: null,
      error: null,
      loading: true,
      count: 0,
    }
  );

  const handlePost = (view_id, payload) => {
    getDispatch(view_id, payload);
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...getState,
      request: handlePost,
    },
  };
}
