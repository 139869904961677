import React from 'react';
import PropTypes from 'prop-types';

import { arraysEqual } from '../../utils';
import { ErrorBoundary, BasicError } from '../Helpers/Error';
import { Placeholder, deriveOptions } from './Helpers';

import { PreviewItemAdName } from './ad-preview-prop-types';

const withApi = (Comp) => {
  class AdPreviewsApiGeneric extends React.Component {
    componentDidMount() {
      const { fetchAdPreviews } = this.props;

      fetchAdPreviews();
    }

    componentDidUpdate(prevProps) {
      const { values, attribute, fetchAdPreviews } = this.props;

      if (
        !arraysEqual(
          (a, b) => a === b,
          prevProps.values,
          values,
        )
      ) {
        fetchAdPreviews({ attribute });
      }
    }

    renderPreviews() {
      const {
        status,
        previews,
        fetchAdPreviews,
        attribute,
        ...rest
      } = this.props;

      if (status === 'ERROR') {
        return <BasicError />;
      } if (status === 'INIT' || status === 'LOADING') {
        return <Placeholder />;
      }
      const attrKey = `ad_${attribute}`;

      return (
        <>
          {previews
            .filter((item) => item[attrKey])
            .map(({ preview_list, ...other }, i) => {
              const previewKey = other[attrKey];

              return (
                <Comp
                      // eslint-disable-next-line react/jsx-props-no-spreading
                  {...rest}
                      // eslint-disable-next-line react/no-array-index-key
                  key={`${i}-${previewKey}`}
                  options={deriveOptions(preview_list)}
                  previewKey={previewKey}
                  previewKeyAttribute={attribute}
                />
              );
            })}
        </>
      );
    }

    render() {
      return <ErrorBoundary>{this.renderPreviews()}</ErrorBoundary>;
    }
  }

  AdPreviewsApiGeneric.defaultProps = {
    status: 'INIT',
    previews: [],
  };

  AdPreviewsApiGeneric.propTypes = {
    attribute: PropTypes.oneOf(['id', 'name']).isRequired,
    values: PropTypes.arrayOf(PropTypes.string).isRequired,
    fetchAdPreviews: PropTypes.func.isRequired,
    status: PropTypes.oneOf(['INIT', 'OK', 'ERROR', 'LOADING']),
    previews: PropTypes.arrayOf(PreviewItemAdName),
  };

  return AdPreviewsApiGeneric;
};

export default withApi;
