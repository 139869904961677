import React from "react";
import "./LoadingAnvil.scss";

const LoadingAnvil = ({ helperText, className }) => {
  return (
    <div className="loading-anvil">
      {helperText && (
        <div className={`helper-text ${className}`}>{helperText}</div>
      )}
      <div className="loading-anvil__container">
        <Hammer className="hammer" />
        <Anvil className="anvil" />
        <span className="spark spark1"></span>
        <span className="spark spark2"></span>
        <span className="spark spark3"></span>
        <span className="spark spark4"></span>
        <div className="progress-bar"></div>
        <div className="progress-bar">
          <div className="gradient"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingAnvil;

export const Anvil = ({ className }) => {
  return (
    <svg
      className={className}
      width="60"
      height="45"
      viewBox="0 0 60 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.8554 0.986877V16.5789C32.7919 24.2814 19.1967 32.0775 11.9697 35.2894V44.6447H56.3983V35.2894C37.4125 26.7761 45.9427 13.4605 59.3602 10.3421V0.986877H20.8554ZM0.12207 4.10529C2.52121 10.7475 7.28988 15.0197 14.9316 16.5789V4.10529H0.12207Z"
        fill="#039BE5"
      />
    </svg>
  );
};

export const Hammer = ({ className }) => {
  return (
    <svg
      className={className}
      width="53"
      height="51"
      viewBox="0 0 53 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8866 4.16593L42.9455 27.2249L52.8279 17.3425L38.0702 2.58474C38.0702 2.58474 28.2866 5.9777 23.1807 0.871796L19.8866 4.16593ZM29.1101 16.6507L1.01119 44.7167C0.443879 45.3253 0.128418 46.1264 0.128418 46.9584C0.128418 47.7904 0.443879 48.5914 1.01119 49.2C1.60067 49.7991 2.39723 50.1497 3.23719 50.1797C4.07715 50.2098 4.8967 49.9169 5.52745 49.3614L33.7549 21.2987L29.1101 16.6507Z"
        fill="#1E2B4E"
      />
    </svg>
  );
};