import React from 'react';
import IterationsCarousel from './Carousel';
import { ITERATIONS } from '../constants';

const Wrapper = ({ assetIterations, activePage }) => {
  return (
    <div
      className={`iterations card ${activePage === ITERATIONS ? 'show' : ''}`}
    >
      <div>
        <IterationsCarousel {...{ assetIterations }} />
      </div>
    </div>
  );
};

export default Wrapper;
