import { useRef, useEffect } from 'react';

import { abortableFetch } from '../../../utils';
import {
  useApiState,
  bindFetchToRef,
} from '../../../components/Hooks/useApiState';

import { useSelector } from 'react-redux';

function getCreativeRequest(request_id) {
  return abortableFetch(['api', 'creative-request', request_id].join('/'), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
  });
}

function postCreativeRequest(payload) {
  return abortableFetch(
    [
      'api',
      'creative-reports',
      'creative-request',
      payload.account_id,
      'submit',
    ].join('/'),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(payload),
    }
  );
}

export function useCreativeRequestApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (request_id) =>
      withRef(getCreativeRequest(request_id)).then((data) => data),
    {
      loading: true,
      error: null,
      data: null,
      count: 0,
    }
  );
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(postCreativeRequest(payload)).then(({ data }) => data),
    {
      loading: false,
      error: null,
      data: null,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: postDispatch,
    },

    get: {
      ...getState,
      request: getDispatch,
    },
  };
}

function postEmail(payload) {
  return abortableFetch(
    [
      'api',
      'creative-reports',
      'creative-request',
      payload.view_id,
      'send-email',
    ].join('/'),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(payload),
    }
  );
}

export function useEmailApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(postEmail(payload)).then(({ data }) => data),
    {
      loading: false,
      error: null,
      data: null,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: postDispatch,
    },
  };
}

function postSaveCreativeRequest(view_id, payload) {
  return abortableFetch(
    ['api', 'creative-reports', 'creative-request', view_id, 'save'].join('/'),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(payload),
    }
  );
}

export function useSaveCreativeRequestApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const view_id = useViewId();
  const [postState, postDispatch] = useApiState(
    (payload) =>
      withRef(postSaveCreativeRequest(view_id, payload)).then(
        ({ data }) => data
      ),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: postDispatch,
    },
  };
}

function useViewId() {
  return useSelector(({ ViewSettings: { viewId } }) => viewId);
}

function getSavedRequest(view_id) {
  return abortableFetch(
    [
      'api',
      'creative-reports',
      'creative-request',
      view_id,
      `get-saved-requests`,
    ].join('/'),
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
  );
}

export function useGetSavedRequestApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const view_id = useViewId();
  const [getState, getDispatch] = useApiState(
    () => withRef(getSavedRequest(view_id)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: true,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return {
    get: {
      ...getState,
      request: getDispatch,
    },
  };
}
