export const SET_CURRENT = "set-current";
export const SET_ID = "set-id";
export const SET_NAME = "set-name";
export const SET_STATUS = "set-status";
export const SET_START_DATE = "set-start-date";
export const SET_END_DATE = "set-end-date";
export const SET_AUDIENCE = "set-audience";
export const ADD_EXCLUDED_LABEL = "add-label";
export const REMOVE_EXCLUDED_LABEL = "remove-label";
export const SET_COMBINED_CATEGORIES = "set-combined-categories";
export const SET_INCLUDED_CATEGORIES = "set-categories";
export const SET_ERRORS = "set-errors";


export function reportEditorReducer(state, action) {
  let updates = {};

  switch (action.type) {
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };

    case SET_ID:
      return {
        ...state,
        current: { ...state.current, id: action.payload },
      };

    case SET_NAME:
      return {
        ...state,
        current: { ...state.current, name: action.payload },
      };

    case SET_STATUS:
      return {
        ...state,
        current: { ...state.current, status: action.payload },
      };

    case SET_START_DATE:
      updates = {
        start_date: action.payload,
      };

      return {
        ...state,
        current: { ...state.current, ...updates },
      };

    case SET_END_DATE:
      updates = {
        end_date: action.payload,
      };

      return {
        ...state,
        current: { ...state.current, ...updates },
      };

    case SET_AUDIENCE:
      return {
        ...state,
        current: { ...state.current, audience: action.payload },
      };

    case ADD_EXCLUDED_LABEL:
      return {
        ...state,
        current: {
          ...state.current,
          excluded_labels: [...state.current.excluded_labels, action.payload],
        },
      };

    case REMOVE_EXCLUDED_LABEL:
      return {
        ...state,
        current: {
          ...state.current,
          excluded_labels: state.current.excluded_labels.filter((label) => label !== action.payload),
        },
      };

    case SET_INCLUDED_CATEGORIES:
      return {
        ...state,
        current: { ...state.current, combined_category_names: action.payload },
      };

    case SET_ERRORS:
      console.log(action.payload)
      return {
        ...state,
        errors: action.payload,
      };
  }
}
