import React from 'react';
import './CreativeRequest.scss';
import { CreativeRequestIcon } from './Helpers';

export const CreativeRequestToggle = ({ handleToggleForm }) => {
  return (
    <div className="">
      <CreativeRequestIcon onClick={handleToggleForm} />
    </div> 
  )
}