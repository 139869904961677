import React from 'react';
import { connect } from 'react-redux';
import { setMin } from '../../store/CreativeAnalytics/creativeAnalyticsSlice';

import CustomDropdown from '../CustomDropdown';
import { useSearchParams } from '../Hooks/search-params';

const OPTIONS = [
  { label: 'None', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
];

export const Picker = ({ setMinResults, toggleStyle, toggleClass }) => {
  const _id = 'min-results-button';
  const { getParams, setParams } = useSearchParams();
  const params = getParams('min');
  const min = params.min || 0;

  const handleSelect = (eventKey, _) => {
    const parsed = parseInt(eventKey);

    if (parsed === params.min) return null;

    const update = { min: parsed };
    setParams(update, () => setMinResults(update));
  };

  return (
    <CustomDropdown
      id={_id}
      onSelect={handleSelect}
      label={`${parseInt(min) > 0 ? min : "None"}`}
      toggleStyle={toggleStyle}
      toggleClass={toggleClass}
    >
      {OPTIONS.map(({ value, label }) => (
        <CustomDropdown.Item key={label} eventKey={value} className="py-2">
          {label}
        </CustomDropdown.Item>
      ))}
    </CustomDropdown>
  );
};

const stateToProps = ({ CreativeAnalytics: { min } }, props) => ({
  min,
});

const dispatchToProps = (dispatch, props) => ({
  setMinResults: (min) => dispatch(setMin(min)),
});

export const MinResultsPicker = connect(stateToProps, dispatchToProps)(Picker);
