import React, { useEffect, useRef } from 'react';
import VariantCarousel from '../Carousel/Carousel';
import Card from '../Card/Card';
import { ExplorerState } from '../../../Explorer/types';
import { useExplorerContext } from '../../../Explorer/contexts/explorerContext';
import { SET_VIEW } from '../../../Explorer/hooks/useExplorerState';
import { VisualsLookup } from '../../../ReportCard/types';
import { useCreativeAnalyticsGet } from '../../../contexts';
import { useVariationContext } from '../../contexts/variationContext';
import {
  REMOVE_ROW,
  SET_BASE_VISUAL,
  SET_ERROR,
  SET_LOADING,
  SET_PRIMARY_METRIC,
  SET_VARIANT_DATA,
} from '../../hooks/useVariationState';
import { useCreativeAnalyticsApis } from '../../../api';
import CarouselNav from '../Carousel/Nav';
import './Row.scss';
import { makeVariationPayload } from '../../utils/makePayload';
import { formatData } from '../../utils/formatData';
import { EMPTY_VARIANT_DATA } from '../../constants';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import { useVisuals, useVisualsLookup } from '../../../selectors';

const VariationRow = ({
  isROAS,
  variantData,
  activeRow,
  setActiveRow,
  rowIndex,
  isSharedReport,
  filterFavorites,
  primary_metric,
  rowMetric,
  primaryMetricLabel,
  sharedRows,
}) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const { variationState, variationDispatch } = useVariationContext();
  const currentRow = sharedRows
    ? sharedRows[rowIndex]
    : variationState.rows[rowIndex];
  const loading = currentRow.loading;
  const error = currentRow.error;
  const { width } = useWindowSize();
  const baseSize = width < 576 ? '46%' : width < 992 ? '30%' : '23%';
  const disableDrawer = variationState.rows.some((row) => row.loading);

  const handleOpenDrawer = (i) => {
    if (disableDrawer) return;

    drawerDispatch({
      type: 'OPEN',
      currentView: { type: 'category_viewer', id: '' },
      selectedVisualId: '',
    });
    drawerDispatch({
      type: SET_VIEW,
      currentView: {
        type: 'browser',
        browserType: 'visuals',
      },
    });
    setActiveRow(i);
  };

  const handleRemoveRow = () => {
    if (disableDrawer) return;

    variationDispatch({
      type: REMOVE_ROW,
      rowIndex,
    });
  };

  const Render = (
    <div className="row-container">
      <div className="variation__row">
        <div
          className="base-visual"
          style={{ width: baseSize, minWidth: baseSize, maxWidth: baseSize }}
        >
          <Card
            type="base"
            data={currentRow.baseVisual}
            title="Base Visual"
            subtitle={null}
            variantIndex={null}
            {...{
              handleOpenDrawer,
              loading,
              isROAS,
              setActiveRow,
              rowIndex,
              isSharedReport,
              rowMetric,
              primaryMetricLabel,
              disableDrawer,
            }}
          />
        </div>
        <div className="plus">
          <i className="fa-solid fa-plus" />
        </div>
        <div className="variants">
          <VariantCarousel
            visualsData={
              filterFavorites
                ? variantData.filter((v) => v.data && v.data.favorite)
                : variantData
            }
            {...{
              loading,
              isROAS,
              rowIndex,
              prevRef,
              nextRef,
              isSharedReport,
              filterFavorites,
              rowMetric,
              primaryMetricLabel,
              width,
            }}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div style={{ opacity: error && !loading ? 1 : 0, userSelect: 'none' }}>
          There was an error. Please try again.
        </div>
        <div className="d-flex">
          {variationState.rows.length > 1 && (
            <button
              className={`delete-row ${disableDrawer ? 'disabled' : ''}`}
              onClick={handleRemoveRow}
            >
              Delete row
            </button>
          )}
          <CarouselNav prevRef={prevRef} nextRef={nextRef} />
        </div>
      </div>
    </div>
  );

  if (isSharedReport) return Render;

  const { drawerState, drawerDispatch }: ExplorerState = useExplorerContext();

  const visualsLookup = useVisualsLookup();
  const filteredVisuals = useVisuals();

  const { variationApi } = useCreativeAnalyticsApis();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (drawerState.selectedVisualId) {
        const dataObj = {
          asset_url: '',
          visual_type: '',
          performance: 0,
        };

        const found = visualsLookup.get(drawerState.selectedVisualId);

        if (found) {
          dataObj.asset_url = found.asset_url;
          dataObj.visual_type = found.visual_type;
          dataObj.performance = found[rowMetric];
        }

        variationDispatch({
          type: SET_BASE_VISUAL,
          rowIndex: activeRow,
          visual: dataObj,
        });

        variationDispatch({
          type: SET_VARIANT_DATA,
          rowIndex: activeRow,
          data: EMPTY_VARIANT_DATA,
        });
      } else {
        variationDispatch({
          type: SET_BASE_VISUAL,
          rowIndex: activeRow,
          visual: null,
        });
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [drawerState.selectedVisualId]);

  useEffect(() => {
    const payload = makeVariationPayload(
      filteredVisuals,
      drawerState.selectedVisualId,
      primary_metric
    );

    if (!!currentRow.baseVisual) {
      variationApi.post.request(payload);

      variationDispatch({
        type: SET_PRIMARY_METRIC,
        rowIndex: activeRow,
        metric: payload.primary_metric,
      });
    }
  }, [currentRow.baseVisual]);

  useEffect(() => {
    if (variationApi.post.error) {
      console.error(variationApi.post.error);
      variationDispatch({
        type: SET_ERROR,
        rowIndex: activeRow,
        error: true,
      });
      variationDispatch({
        type: SET_LOADING,
        rowIndex: activeRow,
        loading: false,
      });
    }
  }, [variationApi.post.error]);

  useEffect(() => {
    if (
      variationApi.post.loading &&
      !!currentRow.baseVisual &&
      activeRow === rowIndex
    ) {
      variationDispatch({
        type: SET_LOADING,
        rowIndex: activeRow,
        loading: true,
      });
    }
  }, [variationApi.post.loading]);

  useEffect(() => {
    if (variationApi.post.data) {
      const {
        data: {
          message: { combination_visuals: data },
          status,
        },
      } = variationApi.post;
      if (status === 200) {
        variationDispatch({
          type: SET_VARIANT_DATA,
          rowIndex: activeRow,
          data: formatData(data, primary_metric),
        });
        variationDispatch({
          type: SET_ERROR,
          rowIndex: activeRow,
          error: false,
        });
      } else {
        variationDispatch({
          type: SET_ERROR,
          rowIndex: activeRow,
          error: true,
        });
      }
      variationDispatch({
        type: SET_LOADING,
        rowIndex: activeRow,
        loading: false,
      });
    }
  }, [variationApi.post.data]);

  return Render;
};

export default VariationRow;
