import { LabelObject, VisualObject } from '../../types';
import { checkIfVisualIsSelected } from '../../hooks/useFilteredData/utils/filterVisuals';
import { debug } from './debug';
import _ from 'lodash';

const defaultMetricsToSum = ['spend', 'results', 'impressions', 'clicks'];

const getAdNamesMap = (visuals) => {
  const adNamesMap = new Map<string, Set<string>>();

  visuals.forEach((visual) => {
    const visualId = visual.id;
    const adNames = [...(visual.ad_names ?? [])].sort();
    const adNamesKey = adNames.join('|||');

    if (adNamesMap.has(adNamesKey)) {
      adNamesMap.get(adNamesKey).add(visualId);
    } else {
      adNamesMap.set(adNamesKey, new Set([visualId]));
    }
  });

  return adNamesMap;
};

const getMetricsToSum = (
  defaultMetricsToSum,
  { customIntegratedMetrics, clientMetrics, additional_metrics }
) => {
  return [
    ...defaultMetricsToSum,
    ...customIntegratedMetrics.map((metric) => metric.value),
    ...clientMetrics,
    ...additional_metrics.map((metric) => metric.value),
  ];
};

const calculateTotalMetrics = (visuals, metricsToSum) => {
  const totals = metricsToSum.reduce((acc, metric) => {
    acc[metric] = 0;
    return acc;
  }, {});

  visuals.forEach((visual) => {
    metricsToSum.forEach((metric) => {
      totals[metric] += visual[metric] || 0;
    });
  });

  return totals;
};

const verifyTotalMetrics = (beforeTotals, afterTotals) => {
  for (const metric in beforeTotals) {
    if (beforeTotals[metric] !== afterTotals[metric]) {
      console.log(
        `Total ${metric} mismatch! Before: ${beforeTotals[metric]}, After: ${afterTotals[metric]}`
      );
      return false;
    }
  }
  return true;
};

const getAllAdded = (updatedVisuals) => {
  let result = new Set();

  updatedVisuals.forEach((v) => {
    v.added.forEach((x) => {
      result.add(x.id);
    });
  });

  return Array.from(result);
};

const mergeV = ({ visuals, visualMap, adNamesMap, metricsToSum }) => {
  const removeTheseVisuals = new Set<string>();
  const updatedVisuals = new Map();

  visuals.forEach((visual) => {
    if (removeTheseVisuals.has(visual.id)) return;

    const adNames = [...(visual.ad_names ?? [])].sort();
    const adNamesMatch = findSubsetMatches(
      adNames,
      adNamesMap,
      visual.id === '115166065956712'
    );

    if (!adNamesMatch || !adNames.length) return;

    const visualsToMerge = findMatches(visual, visuals, adNamesMatch);

    try {
      let topSpend = visual.spend;
      let topSpendingVisualId = visual.id;

      visualsToMerge.forEach((v) => {
        if (v.spend > topSpend) {
          topSpend = v.spend;
          topSpendingVisualId = v.id;
        }
      });

      if (topSpendingVisualId === visual.id) {
        updatedVisuals.set(
          visual.id,
          mergeMetrics(visual, visualsToMerge, metricsToSum)
        );
        visualsToMerge.forEach((v) => {
          removeTheseVisuals.add(v.id);
        });
      } else {
        const top = visualsToMerge.find((x) => x.id === topSpendingVisualId);
        updatedVisuals.set(
          topSpendingVisualId,
          mergeMetrics(top, [visual], metricsToSum)
        );
        removeTheseVisuals.add(visual.id);
        adNamesMap.set();
      }
    } catch (e) {
      console.error('Error updating visual' + visual.id);
      console.error(e);
      return;
    }
  });

  return { updatedVisuals, removeTheseVisuals: Array.from(removeTheseVisuals) };
};

const mergeMetrics = (
  visual: VisualObject,
  visualsToMerge: VisualObject[],
  metricsToSum: string[]
) => {
  const updatedVisual: VisualObject = { ...visual };

  visualsToMerge.forEach((v) => {
    metricsToSum.forEach((metric) => {
      updatedVisual[metric] = (updatedVisual[metric] ?? 0) + (v[metric] ?? 0);
    });
  });

  return {
    updatedVisual,
    original: visual,
    added: visualsToMerge,
  };
};

const isSubset = (subset: string[], superset: string[]): boolean => {
  return subset.every((value) => superset.includes(value));
};

const findSubsetMatches = (adNames, adNamesMap, test = false) => {
  const matchedKeys = [];
  for (const [key, visualIds] of adNamesMap.entries()) {
    if (!key) {
      continue;
    }
    const existingAdNames = key.split('|||');

    if (test) {
      console.log({ key, visualIds, existingAdNames, adNames, adNamesMap });
    }

    if (
      isSubset(adNames, existingAdNames) ||
      isSubset(existingAdNames, adNames) ||
      _.isEqual(adNames, existingAdNames)
    ) {
      matchedKeys.push(visualIds);
    }
  }
  return matchedKeys;
};

const findMatches = (visual, visuals, matchedKeys): VisualObject[] => {
  const matchedWith: VisualObject[] = [];

  matchedKeys.forEach((visualIds) => {
    visualIds.forEach((matchingId) => {
      const matchingVisual = visuals.find((v) => v.id === matchingId);
      if (matchingVisual && matchingVisual.id !== visual.id) {
        matchedWith.push(matchingVisual);
      }
    });
  });

  return matchedWith;
};

const getVisuals = (label, lookup) => {
  try {
    if (label.relevant_visuals) {
      return label.relevant_visuals.map((id) => lookup[id]);
    } else {
      return [...label.winners, ...label.losers];
    }
  } catch (e) {
    console.log(e);
    return [];
  }
};

export function groupByAdName(
  labels,
  groupByAdName,
  { customIntegratedMetrics, clientMetrics, additional_metrics },
  { spendThreshold, visualIdsFromSelectedLabels, metricsData = [] }
) {
  if (!groupByAdName) {
    return {
      updatedLabels: labels,
      updatedMetricsData: metricsData,
    };
  }

  const visuals = [...metricsData].filter((visual) => {
    if (
      !(
        visual.spend >= parseInt(spendThreshold) &&
        (visualIdsFromSelectedLabels.size === 0 ||
          checkIfVisualIsSelected(visual, visualIdsFromSelectedLabels))
      )
    ) {
    }
    return (
      visual.spend >= parseInt(spendThreshold) &&
      (visualIdsFromSelectedLabels.size === 0 ||
        checkIfVisualIsSelected(visual, visualIdsFromSelectedLabels))
    );
  });

  const visualMap = new Map();

  const adNamesMap = getAdNamesMap(visuals);

  const metricsToSum = getMetricsToSum(defaultMetricsToSum, {
    customIntegratedMetrics,
    clientMetrics,
    additional_metrics,
  });

  visuals.forEach((visual) => {
    visualMap.set(visual.id, visual);
  });

  const beforeTotals = calculateTotalMetrics(visuals, metricsToSum);

  const { updatedVisuals } = mergeV({
    visuals,
    visualMap,
    adNamesMap,
    metricsToSum,
  });

  debug(updatedVisuals);

  const removeTheseVisuals = getAllAdded(updatedVisuals);

  const updatedLabels = updateLabelData(
    labels,
    updatedVisuals,
    removeTheseVisuals,
    (label) => {
      return mergeOneLabel(label, adNamesMap, metricsToSum);
    }
  );

  const afterVisuals = Array.from(updatedVisuals.values()).map(
    ({ updatedVisual }) => updatedVisual
  );
  const afterTotals = calculateTotalMetrics(afterVisuals, metricsToSum);

  if (!verifyTotalMetrics(beforeTotals, afterTotals)) {
    console.error('Total metrics mismatch after grouping!');
  }

  return {
    updatedLabels,
    updatedMetricsData: visuals
      .filter((visual) => !removeTheseVisuals.includes(visual.id))
      .map((visual) => {
        if (updatedVisuals.has(visual.id)) {
          return { ...updatedVisuals.get(visual.id).updatedVisual };
        } else {
          return visual;
        }
      }),
  };
}

const mergeOneLabel = (label, adNamesMap, metricsToSum) => {
  const visualMap = new Map();
  const visuals = [
    ...(label?.relevant_visuals ?? [...label.winners, ...label.losers]),
  ];
  visuals.forEach((visual) => {
    visualMap.set(visual.id, visual);
  });
  const { updatedVisuals } = mergeV({
    visuals,
    visualMap,
    adNamesMap,
    metricsToSum,
  });
  const removeTheseVisuals = getAllAdded(updatedVisuals);

  const newVisuals = updateVisuals(
    [...visuals],
    updatedVisuals,
    removeTheseVisuals
  );

  debug(updatedVisuals);

  return {
    ...label,
    relevant_visuals: newVisuals,
  };
};

export const updateLabelData = (
  labels: LabelObject[],
  updatedVisuals: Map<string, VisualObject>,
  removeTheseVisuals: string[],
  mergeForSpecificLabel: (label: LabelObject) => void
) =>
  [...labels].map((label) => {
    if (
      label.udc ||
      label.type === 'ad_name_label' ||
      label.category === 'Ad Name Label'
    )
      return mergeForSpecificLabel(label);

    const visual_ids = label.relevant_visuals ?? [
      ...label.winners,
      ...label.losers,
    ];

    return {
      ...label,
      relevant_visuals: visual_ids.filter(
        (id) => !removeTheseVisuals.includes(id)
      ),
    };
  });

const updateVisuals = (
  visuals: VisualObject[],
  updatedVisuals,
  removeTheseVisuals: string[]
) => {
  return visuals
    .filter((visual) => !removeTheseVisuals.includes(visual.id))
    .map((visual) =>
      updatedVisuals.has(visual.id)
        ? { ...updatedVisuals.get(visual.id).updatedVisual }
        : visual
    );
};

/* 
if label.udc is true:

*/
