import React from 'react';
import CollapsibleItem from '../../../components/CollapsibleItem/CollapsibleItem';
import { Header, Section } from '../Recommendations/Layout';
import '../Recommendations/index.scss';
import { BasicLoading } from '../../../components/Helpers/Loading';
import Tooltip from '../../../components/Tooltip';

type InsightsDataItem = {
  category_name: string;
  combined_p_value: number;
  statement_description: string;
  statement_header: string;
  statement_outcomes: string;
};

type InsightsSectionItem = {
  section_description: string;
  section_name: string;
  section_order: number;
  section_recommendations: InsightsDataItem[];
};

export const EmptyDisplay = ({ message }) => {
  return (
    <div className="empty-display">
      <i class="fas fa-times-circle"></i>
      <p>{message}</p>
    </div>
  );
};

export const Insights = ({
  insightsData,
  loading,
  close,
  refetchIqData,
  allowDataMode,
}: {
  insightsData: InsightsSectionItem[];
}) => {
  if (!allowDataMode) {
    return (
      <EmptyDisplay message="No Grouping is currently not supported as a segment in Analytics Intelligence." />
    );
  }

  if (loading) return <BasicLoading />;

  const render = (recs) => {
    if (!Array.isArray(recs) || !recs?.length)
      return (
        <EmptyDisplay message="There is not enough data in this grouping for Darwin recommendations." />
      );
    return (
      <ul style={{ listStyle: 'none' }}>
        {recs?.map((insightsSection: InsightsDataItem, index) => {
          const {
            statement_header,
            statement_description,
            statement_outcomes,
            category_name,
          } = insightsSection;

          return (
            <li key={index} className="p-3">
              <SectionWrapper
                {...{
                  statement_header,
                  statement_description,
                  statement_outcomes,
                  category_name,
                }}
              />
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div className={`recs full-height`}>
      <div className="recs-wrapper">
        <Header close={close} />
        {insightsData?.length ? (
          insightsData.map((section: InsightsSectionItem) => {
            const {
              section_recommendations,
              section_name,
              section_description,
            } = section;

            let recs = section_recommendations;

            return (
              <CollapsibleItem
                AnchorChild={<Section title={section_description} />}
                ExpandableChild={
                  <div className="w-100" style={{ position: 'relative' }}>
                    <Tooltip
                      content="Refresh analytics data"
                      follow={false}
                      delay={[500, 0]}
                    >
                      <button
                        style={{
                          position: 'absolute',
                          top: '0.5rem',
                          right: '0.5rem',
                        }}
                        className="recs-header__icon btn-reset"
                        onClick={() =>
                          refetchIqData({ refresh_insights: true })
                        }
                      >
                        <i
                          style={{ fontSize: '14px' }}
                          className="fa fa-rotate-right"
                        ></i>
                      </button>
                    </Tooltip>

                    {render(recs)}
                  </div>
                }
                className={null}
                expandOnLoad
              />
            );
          })
        ) : (
          <div>
            <Tooltip
              content="Refresh analytics data"
              follow={false}
              delay={[500, 0]}
            >
              <button
                className="recs-header__icon btn-reset"
                onClick={() => refetchIqData({ refresh_insights: true })}
              >
                <i
                  style={{ fontSize: '14px' }}
                  className="fa fa-rotate-right"
                ></i>
              </button>
            </Tooltip>
            <EmptyDisplay message="No data found." />
          </div>
        )}
      </div>
    </div>
  );
};

const SectionWrapper = ({
  statement_header,
  statement_description,
  statement_outcomes,
}) => {
  return (
    <div className="takeaway-section mb-0">
      <div className="takeaway-section__block">
        <h4 className="takeaway-section__heading">{statement_header}</h4>
        <p className="takeaway-section__desc">
          {statement_description} <span>{statement_outcomes}</span>
        </p>
      </div>
    </div>
  );
};
