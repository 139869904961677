export const customPartition = (
  visual,
  visual_success_conditions,
  sumsAndAverages
) => {
  const predicates = visual_success_conditions.reduce(
    (acc, { metric, comparison, value }) => {
      if (!metric || !comparison) return acc;

      try {
        let valueToCompare = findValueToCompare(metric, value, sumsAndAverages);

        if (valueToCompare) {
          acc.push((visual) => {
            const visualMetricValue = visual[metric];
            switch (comparison) {
              case 'greater':
                return visualMetricValue > valueToCompare;
              case 'greater_or_equal':
                return visualMetricValue >= valueToCompare;
              case 'less':
                return visualMetricValue < valueToCompare;
              case 'less_or_equal':
                return visualMetricValue <= valueToCompare;
              case 'equal':
                return visualMetricValue === valueToCompare;
              default:
                return false;
            }
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        return acc;
      }
    },
    []
  );

  if (predicates.every((filter) => filter(visual))) {
    return true;
  } else {
    return false;
  }
};

const findValueToCompare = (metric, value, sumsAndAverages) => {
  if (value !== 'avg') return value;
  let avgValue = null;

  if (['cpa', 'cpc', 'cpm', 'ctr', 'roas'].includes(metric)) {
    avgValue = sumsAndAverages[`${metric}Average`];
  } else {
    avgValue = sumsAndAverages[`average_${metric}`];
  }

  if (!avgValue) return null;

  return avgValue;
};
