import { useSelector, useDispatch } from 'react-redux';
import { useViewId } from '../../components/Hooks/view-id';
import {
  fetchMarketplaceAssetsByView,
  ASSETS,
} from '../../store/Marketplace/Assets/actions';
import {
  fetchAssetChatsByView,
  fetchAssetChatsByAsset,
} from '../../store/Marketplace/Chats/actions';
import { EMPTY_PREVIEW } from './constants';
import { hasRoles, isAdminRole } from '../../cookies';

export const useFetchAssetChatsByAsset = (asset_id, project_id) => {
  const dispatch = useDispatch();

  return () => {
    dispatch(fetchAssetChatsByAsset(asset_id, project_id));
  };
};

export function useFetchAssets() {
  const view_id = useViewId();
  const dispatch = useDispatch();

  return () => {
    dispatch(fetchMarketplaceAssetsByView({ view_id }));
  };
}

export function useAssetApiState() {
  return useSelector(({ Loaders, Errors }) => ({
    loading: Loaders[ASSETS],
    error: Errors[ASSETS],
  }));
}

export function useFilenames(pid) {
  return useSelector(
    ({
      Marketplace: {
        assets: { ids, lookup },
      },
    }) =>
      ids
        .filter((id) => !!lookup[id])
        .map((id) => {
          const {
            filename,
            s3_filename,
            project_id,
            versionIds,
            placeholder,
            contractor,
            asset_name,
            request_id,
            due_date,
            contractor_name,
            project_name,
            ratio,
            asset_tags,
            carousel,
            card_number,
            isStudioUpload = false,
          } = lookup[id];

          if (placeholder) {
            return {
              filename,
              s3_filename,
              project_id: project_id || pid,
              versionIds,
              placeholder,
              contractor,
              asset_name,
              request_id,
              due_date,
              contractor_name,
              project_name,
              ratio,
              asset_tags,
              carousel,
              card_number,
            };
          }

          // if (isStudioUpload) {
          //   console.log({
          //     filename,
          //     s3_filename,
          //     project_id: project_id || pid,
          //     request_id: request_id || '',
          //     versionIds,
          //     contractor: contractor || '',
          //     isStudioUpload,
          //   })
          // }

          return {
            filename,
            s3_filename,
            project_id: project_id || pid,
            request_id: request_id || '',
            versionIds,
            contractor: contractor || '',
            isStudioUpload,
          };
        })
        .sort((a, b) => {
          if (a.isStudioUpload) return -1;
          if (a.request_id < b.request_id) return -1;
          if (a.request_id < b.request_id) return -1;
        })
  );
}

export const useAsset = (filename) =>
  useSelector(({ Marketplace: { assets } }) => {
    const asset = assets.lookup[filename];
    return asset;
  });

export const useVersions = () =>
  useSelector(({ Marketplace: { versions } }) => versions);

export function useAssetVersions(filename) {
  const versions = useSelector(({ Marketplace: { assets, versions } }) => {
    const asset = assets.lookup[filename];

    if (!asset) {
      return [EMPTY_PREVIEW];
    }

    const vers = asset.versionIds.map((id) => versions.lookup[id]);

    return vers;
  });

  return {
    length: versions.length,
    versions,
  };
}

/**
 * @param {string} filename
 *
 * @return {string}
 */
export function useContractor(filename) {
  return useSelector(
    ({
      Marketplace: {
        assets: { lookup },
      },
    }) => {
      const asset = lookup[filename];

      if (asset) {
        return asset.contractor;
      }
      return '';
    }
  );
}

export function useDarwinClientId() {
  return useSelector(
    ({ ViewSettings: { darwin_client_id } }) => darwin_client_id
  );
}

export const usePlatforms = (selectedProject) => {
  if (selectedProject) return [selectedProject.platform];
  return useSelector(({ ViewSettings: { platforms } }) => platforms || []);
};

export function useViewName() {
  return useSelector(({ ViewSettings: { viewName } }) => viewName);
}

export function useAccountId() {
  return useSelector(({ ViewSettings: { account_id } }) => account_id || '');
}

export function useUserId() {
  return useSelector(({ ViewSettings: { user_id } }) => user_id || '');
}
