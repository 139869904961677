import React from 'react';
import { toCurrency, toPercent_2 } from '../../utils/numbers';

export const SPEND_BASED_COLUMNS = [
  { Header: 'Label', accessor: 'label_name' },
  {
    Header: 'CPA',
    accessor: 'cpa',
    Cell: ({ value }) => toCurrency(value),
  },
  {
    Header: 'ROAS',
    accessor: 'roas',
    Cell: ({ value }) => value?.toFixed(2) ?? '-',
  },
  {
    Header: 'Spend',
    accessor: 'total_spend',
    Cell: ({ value }) => toCurrency(value),
  },
  {
    Header: 'Total Link Clicks',
    accessor: 'total_link_clicks',
    Cell: ({ value }) => value?.toFixed() ?? '-',
  },
  {
    Header: 'Total Impressions',
    accessor: 'total_impressions',
    Cell: ({ value }) => value?.toFixed() ?? '-',
  },
  {
    Header: 'Total Results',
    accessor: 'total_results',
    Cell: ({ value }) => value?.toFixed() ?? '-',
  },
  {
    Header: 'Total Revenue',
    accessor: 'total_revenue',
    Cell: ({ value }) => (!!value ? toCurrency(value) : '-'),
  },
  {
    Header: 'CPC',
    accessor: 'cpc',
    Cell: ({ value }) => toCurrency(value),
  },
  {
    Header: 'CPM',
    accessor: 'cpm',
    Cell: ({ value }) => toCurrency(value),
  },
  {
    Header: 'CTR',
    accessor: 'ctr',
    Cell: ({ value }) => toPercent_2(value),
  },
];

export const RANK_BASED_COLUMNS = [
  { Header: 'Label', accessor: 'label_name' },
  {
    Header: '# of Valid Experiments',
    accessor: 'number_of_valid_eperiments',
    Cell: ({ value }) => value.toFixed(),
  },
  {
    Header: '# of Positive Outcomes',
    accessor: 'number_of_positive_outcomes',
    Cell: ({ value }) => value.toFixed(),
  },
  {
    Header: 'Success Rate',
    accessor: 'success_rate',
    Cell: ({ value }) => toPercent_2(value),
  },
  {
    Header: 'p-value',
    accessor: 'p_value',
    Cell: ({ value }) => value.toFixed(2),
  },
];
