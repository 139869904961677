import React from 'react';
import Button from '../../../components/Button';

const Preprocess = ({
  isVariationReport,
  loading,
  error,
  data,
  success,
  handlePreprocess,
}) => {
  let Display = !isVariationReport ? (
    <span>
      Preprocess any new or missing visuals in order to be able to display or
      alter the number of clusters.
    </span>
  ) : (
    <span>First, preprocess any new or missing visuals.</span>
  );

  if (loading) {
    Display = (
      <span>
        We have sent the visuals to get preprocessed. Check back in 10 minutes.
      </span>
    );
  }

  if (error) {
    Display = <span>There was an error.</span>;
  }

  if (data) {
    if (success) {
      Display = (
        <span>
          <i className="fa-solid fa-check mr-2"></i>Preprocessing complete.
        </span>
      );
    } else {
      Display = <span>There was an error.</span>;
    }
  }

  return (
    <div className="clusters__section preprocess">
      {!isVariationReport && <h2>Preprocess Missing Visuals</h2>}
      <div className="d-flex align-items-center">
        <Button onClick={handlePreprocess} loading={loading} disabled={loading}>
          {isVariationReport ? 'Preprocess visuals' : 'Go'}
        </Button>
        <p>{Display}</p>
      </div>
    </div>
  );
};

export default Preprocess;
