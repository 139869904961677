import React from 'react';

const MenuIcon = ({ className = '', onClick }) => (
  <div onClick={onClick}>
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.61182 12.6028C6.33669 12.6028 6.92432 12.0152 6.92432 11.2903C6.92432 10.5654 6.33669 9.97778 5.61182 9.97778C4.88694 9.97778 4.29932 10.5654 4.29932 11.2903C4.29932 12.0152 4.88694 12.6028 5.61182 12.6028Z"
        fill="white"
      />
      <path
        d="M10.8618 12.6028C11.5867 12.6028 12.1743 12.0152 12.1743 11.2903C12.1743 10.5654 11.5867 9.97778 10.8618 9.97778C10.1369 9.97778 9.54932 10.5654 9.54932 11.2903C9.54932 12.0152 10.1369 12.6028 10.8618 12.6028Z"
        fill="white"
      />
      <path
        d="M16.1118 12.6028C16.8367 12.6028 17.4243 12.0152 17.4243 11.2903C17.4243 10.5654 16.8367 9.97778 16.1118 9.97778C15.3869 9.97778 14.7993 10.5654 14.7993 11.2903C14.7993 12.0152 15.3869 12.6028 16.1118 12.6028Z"
        fill="white"
      />
    </svg>
  </div>
);

export default MenuIcon;
