import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

export function useAccountId() {
  return useSelector(({ ViewSettings: { account_id } }) => account_id || '');
}

export function useIsDirectMail() {
  return useSelector(({ ViewSettings: { isDirectMail } }) => isDirectMail);
}

export function useActionName() {
  return useSelector(({ ViewSettings: { action_name } }) =>
    typeof action_name === 'string'
      ? action_name
      : typeof action_name === 'object'
      ? action_name.value || ''
      : ''
  );
}

export function useViewId() {
  return useSelector(({ ViewSettings: { viewId } }) => viewId);
}

export function useGaEnabled() {
  return useSelector(({ ViewSettings: { ga_enabled } }) => ga_enabled);
}

export function useViewName() {
  return useSelector(({ ViewSettings: { viewName } }) => viewName);
}

export function useCustomEvents() {
  return useSelector(({ ViewSettings: { custom_events } }) => custom_events);
}

export const useCustomMetrics = () => {
  return useSelector(
    ({
      ViewSettings: {
        custom_events,
        additional_metrics,
        customIntegratedMetrics,
      },
    }) => ({
      custom_events,
      additional_metrics,
      customIntegratedMetrics,
    })
  );
};

const selectVisuals = (state) => state.CreativeAnalytics.visuals;
export const selectVisualsArray = createSelector([selectVisuals], (visuals) =>
  Array.from(visuals.lookup.values())
);
const selectLabels = (state) => state.CreativeAnalytics.labels;
export const selectVisualsLookup = createSelector(
  [selectVisuals],
  (visuals) => visuals.lookup
);
export const selectVisualLabels = createSelector(
  [selectVisualsLookup, selectLabels],
  (visualsLookup, labels) => (visualId) => {
    const visual = visualsLookup.get(visualId);
    if (!visual?.label_ids) return [];

    return visual.label_ids.map((labelId) => labels.lookup.get(labelId));
  }
);
export const selectLabelsArray = createSelector([selectLabels], (labels) =>
  Array.from(labels.lookup.values())
);
export const selectLabelsLookup = createSelector(
  [selectLabels],
  (labels) => labels.lookup
);

export const useVisuals = () => useSelector(selectVisualsArray);

export const useVisualsLookup = () => useSelector(selectVisualsLookup);

export const useVisualById = () => {
  const lookup = useSelector(selectVisualsLookup);

  return (id) => lookup.get(id);
};

export const useVisualLabels = () => useSelector(selectVisualLabels);

export const useLabels = () => useSelector(selectLabelsArray);
export const useLabelsLookup = () => useSelector(selectLabelsLookup);

export const useCategoriesLookup = () =>
  useSelector(
    ({ CreativeAnalytics: { categoriesLookup } }) => categoriesLookup || {}
  );

export const useCategoriesList = () => {
  return useSelector(
    ({ CreativeAnalytics: { categoriesLookup } }) =>
      Object.values(categoriesLookup) || []
  );
};

export const useAllVisualsLabel = () =>
  useSelector(({ CreativeAnalytics: { labels } }) =>
    labels.lookup.get('All Visuals->All Visuals')
  );

export const useLabelsById = () => {
  const lookup = useSelector(selectLabelsLookup);
  return (ids = []) => ids?.map((id) => lookup.get(id) || null);
};

export type VisualObject = {
  ad_names: string[];
  asset_url: string[];
  cpa: number;
  roas?: number;
  id: string;
  percent_diff: string;
  results: number;
  spend: number;
  visual_type: string;
  impressions: number;
  clicks: number;
  thumbnail_url?: string;
  isNew: boolean;
};

export const useSortVisualIdsBySpend = () => {
  const lookup = useSelector(selectVisualsLookup);

  const sortVisualIdsBySpend = useCallback(
    (ids: string[]): string[] => {
      const spends = ids.map((id) => ({
        id,
        spend: lookup.get(id)?.spend || 0,
      }));

      return spends
        .filter(({ id }) => lookup.has(id))
        .sort((a, b) => b.spend - a.spend)
        .map(({ id }) => id);
    },
    [lookup]
  );

  return sortVisualIdsBySpend;
};

export const usePfrCategories = () => {
  return useSelector(({ CreativeAnalytics: { tableData, navCategories } }) => ({
    tableData,
    navCategories,
  }));
};
