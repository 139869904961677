import React from "react";

export const DotsIcon = () => {
  return (
    <svg
      className="dots-icon"
      width="5"
      height="17"
      viewBox="0 0 5 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.88208 14.8172C0.88208 14.3929 1.05065 13.9859 1.35071 13.6859C1.65077 13.3858 2.05773 13.2172 2.48208 13.2172C2.90643 13.2172 3.31339 13.3858 3.61345 13.6859C3.91351 13.9859 4.08208 14.3929 4.08208 14.8172C4.08208 15.2416 3.91351 15.6485 3.61345 15.9486C3.31339 16.2487 2.90643 16.4172 2.48208 16.4172C2.05773 16.4172 1.65077 16.2487 1.35071 15.9486C1.05065 15.6485 0.88208 15.2416 0.88208 14.8172ZM0.88208 8.41724C0.88208 7.99289 1.05065 7.58592 1.35071 7.28587C1.65077 6.98581 2.05773 6.81724 2.48208 6.81724C2.90643 6.81724 3.31339 6.98581 3.61345 7.28587C3.91351 7.58592 4.08208 7.99289 4.08208 8.41724C4.08208 8.84158 3.91351 9.24855 3.61345 9.54861C3.31339 9.84867 2.90643 10.0172 2.48208 10.0172C2.05773 10.0172 1.65077 9.84867 1.35071 9.54861C1.05065 9.24855 0.88208 8.84158 0.88208 8.41724ZM0.88208 2.01724C0.88208 1.59289 1.05065 1.18592 1.35071 0.885865C1.65077 0.585807 2.05773 0.417236 2.48208 0.417236C2.90643 0.417236 3.31339 0.585807 3.61345 0.885865C3.91351 1.18592 4.08208 1.59289 4.08208 2.01724C4.08208 2.44158 3.91351 2.84855 3.61345 3.14861C3.31339 3.44867 2.90643 3.61724 2.48208 3.61724C2.05773 3.61724 1.65077 3.44867 1.35071 3.14861C1.05065 2.84855 0.88208 2.44158 0.88208 2.01724Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};
