import React, { useRef, useState } from 'react';
import { useClickOutside } from '../../../hooks/useClickOutside';
import Button from '../../../components/Button';

const DropdownItem = ({ label, onClick }) => {
  return (
    <div className="sort-dropdown__item" onClick={onClick}>
      Sort by {label} {}
    </div>
  );
};

export const SortDropdown = ({
  show,
  setShow,
  setSort,
  sortDesc,
  primary_metric,
  options=[],
}) => {
  if (!show) return null;

  const ref = useRef();
  useClickOutside(ref, () => setShow(false));

  if (options.length) {
    return (
      <div className="sort-dropdown" ref={ref}>
        {options.map((opt) => {
          return (
            <DropdownItem
              label={opt.label}
              onClick={() => {
                setSort(opt.metric, opt.sortDesc);
                setShow(false);
              }}
            />
          )
        })}
        </div>
    )
  }

  return (
    <div className="sort-dropdown" ref={ref}>
      <>
        <DropdownItem
          label="Spend descending"
          onClick={() => {
            setSort('spend', true);
            setShow(false);
          }}
        />
        <DropdownItem
          label="Spend ascending"
          onClick={() => {
            setSort('spend', false);
            setShow(false);
          }}
        />
        <DropdownItem
          label="Performance (best first)"
          onClick={() => {
            setSort(primary_metric, sortDesc);
            setShow(false);
          }}
        />
        <DropdownItem
          label="Performance (worst first)"
          onClick={() => {
            setSort(primary_metric, !sortDesc);
            setShow(false);
          }}
        />
      </>
    </div>
  );
};

export const SortOptions = ({
  SortText,
  setSort,
  primary_metric,
  isOrganicView,
  isFacebookView,
  isInstagramView,
  isLinkedInView,
}) => {
  const [show, setShow] = useState(false);
  const sortDesc = ['roas', 'ctr', 'impressions', 'results', 'clicks'].includes(
    primary_metric
  )
    ? true
    : false;

  let options = [];

  if (isOrganicView) {
    if (isFacebookView || isInstagramView) {
      options = [
        {
          label: 'Total Likes descending',
          metric: 'Total Likes',
          sortDesc: true,
        },
        {
          label: 'Total Likes ascending',
          metric: 'Total Likes',
          sortDesc: false,
        },
        {
          label: 'Total Engagements descending',
          metric: 'Engagements',
          sortDesc: true,
        },
        {
          label: 'Total Engagements ascending',
          metric: 'Total Engagements',
          sortDesc: false,
        },
      ];
    } else if (isLinkedInView) {
      options = [
        {
          label: 'Post Reactions descending',
          metric: 'Post Reactions',
          sortDesc: true,
        },
        {
          label: 'Post Reactions ascending',
          metric: 'Post Reactions',
          sortDesc: false,
        },
        {
          label: 'Post Comments descending',
          metric: 'Post Comments',
          sortDesc: true,
        },
        {
          label: 'Post Comments ascending',
          metric: 'Post Comments',
          sortDesc: false,
        },
      ];
    }
  }

  return (
    <div className="sort-options">
      <Button appearance="raised" onClick={() => setShow((prev) => !prev)}>
        {SortText}
      </Button>
      <SortDropdown
        {...{ show, setShow, setSort, sortDesc, primary_metric, options }}
      />
    </div>
  );
};
