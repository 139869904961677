import React, { useEffect, useReducer } from 'react';
import { useBoxPlotData } from '../AIDriven/useBoxPlotData';

import { LabelForm, LabelFormPlaceholder } from './LabelForm';

import {
  reducer,
  SELECT_LABEL,
  ADD_LABEL,
  EDIT_LABEL,
  DELETE_LABEL,
} from './reducer';
import Sidebar from './Sidebar';
import {
  makeEmptyLabel,
  makeCreatePayload,
  makeUpdatePayload,
  makeDeletePayload,
} from './utils';

function LabelItem({ name, onClick, selected, status }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`udc__label ${selected ? 'udc__label--active' : ''}`}
    >
      <div className="d-flex flex-column" style={{ width: '100%' }}>
        <div className="d-flex flex-row align-items-baseline justify-content-between">
          <p className="udc__label__name">{name}</p>
        </div>
      </div>
    </button>
  );
}

function LabelsList({ children }) {
  return <div className="udc__labels">{children}</div>;
}

function Editor({
  input,
  data,
  onCreate,
  onUpdate,
  onDelete,
  prev,
  existing,
  updatedFields,
  markFieldAsUpdated,
  account_id,
  labels,
  index,
  dispatch,
  loadingMsg,
  isSingle,
}) {
  const isValid = labels.length;
  const updateLabels = () => markFieldAsUpdated('labels');

  const handleSubmit = () => {
    if (existing) {
      onUpdate(
        makeUpdatePayload(input, labels, updatedFields, account_id, existing)
      );
    } else {
      onCreate(makeCreatePayload(input, labels, account_id));
    }
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      onDelete(makeDeletePayload(account_id, input));
    }
  };

  const handleSelect = (i) => dispatch({ type: SELECT_LABEL, index: i });
  const handleSelectNew = () => dispatch({ type: SELECT_LABEL, index: -2 });

  const addLabel = (label) => {
    updateLabels();
    dispatch({
      type: ADD_LABEL,
      label,
    });
  };

  const editLabel = (label) => {
    updateLabels();
    dispatch({
      type: EDIT_LABEL,
      index,
      label,
    });
  };

  const handleDeleteLabel = (name) => {
    updateLabels();
    dispatch({ type: DELETE_LABEL, name });
  };

  let Details = null;

  if (index === -1) {
    Details = <LabelFormPlaceholder onNewClick={handleSelectNew} />;
  } else {
    // const account_platforms = useAccountPlatforms();
    const { filters, name, onSaveLabel } =
      index === -2
        ? { ...makeEmptyLabel(), onSaveLabel: addLabel }
        : { ...labels[index], onSaveLabel: editLabel };

    Details = (
      <LabelForm
        onSaveLabel={onSaveLabel}
        onDelete={() => handleDeleteLabel(name)}
        {...{
          filters,
          name,
        }}
      />
    );
  }

  return (
    <div className="d-flex h-100">
      <Sidebar
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        {...{ input, prev, handleSelectNew, existing, loadingMsg, isValid }}
        renderLabelsList={() => (
          <LabelsList>
            {labels.map(({ id, name }, i) => (
              <LabelItem
                key={id}
                name={name}
                selected={i === index}
                onClick={() => handleSelect(i)}
              />
            ))}
          </LabelsList>
        )}
      />
      <div className="label-form w-50">{Details}</div>
    </div>
  );
}

export default Editor;
