import { useState } from 'react';
import { useSelector } from 'react-redux';

export const useReportBuilderGroupBy = () => {
  const report_builder_group_by = useSelector(
    (state) => state.ViewSettings.report_builder_group_by
  );
  const [reportBuilderGroupBy, setReportBuilderGroupBy] = useState<
    'ad_name' | 'visual_id'
  >(report_builder_group_by);

  return [reportBuilderGroupBy, setReportBuilderGroupBy];
};
