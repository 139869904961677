import React from 'react';
import { RecIcon, BarGraphIcon } from '../../components/Icons';
import { LabelObject } from './types';

export const DARWIN_INDEX_LOOKUP = {
  Image: '45%',
  Video: '41%',
  Carousel: '43%',
  '9:16 Ratio': '39%',
  '1:1 Ratio': '43%',
  '4:5 Ratio': '36%',
  'Primary Text Top ': '38%',
  'Primary Text Middle': '39%',
  'Primary Text Bottom': '36%',
  'Primary Text Size XL': '44%',
  'Primary Text Size LG': '46%',
  'Primary Text Size MD': '38%',
  'Primary Text Size SM': '38%',
  'Secondary Text Position Top': '45%',
  'Secondary Text Position Middle': '49%',
  'Secondary Text Position Bottom': '25%',
  '0-10%': '34%',
  '10-20%': '46%',
  '20-30%': '47%',
  '30-40%': '67%',
  'All Visuals': '40%',
  'Focal Point: Weak': '40%',
  'Focal Point: Average': '43%',
  'Focal Point: Strong': '35%',
  'Focal Point: Very Strong': '34%',
  'Focal Point: Extremely Strong': '35%',
  'No Text': '45%',
  '10 Words or Less': '36%',
  '11-20 Words': '38%',
  '20+ Words': '49%',
  'One Main Color': '37%',
  'Two Main Colors': '39%',
};

export const REC_TABS = {
  test_more: 'Test More of This',
  test_less: 'Test Less of This',
  top_performers: 'Top Performers',
  try_these: 'Try These',
};

export const PMAX_MOCK: LabelObject[] = [
  {
    name: 'new tempo serve your best look at the gym',
    value: 'new tempo serve your best look at the gym',
    label_id: '',
    description: '',
    rating: 1.5,
    type: 'google_vision',
    example: [{}],
    winners: [
      {
        id: '494688214894151',
        performance_score: 3,
        ad_names: [],
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/402600435338211',
      },
      {
        id: '899952523135053',
        performance_score: 3,
        ad_names: [],
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/429723221414432',
      },
      {
        id: '170773074914650',
        performance_score: 2,
        ad_names: [],
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/500829958897151',
      },
      {
        id: '848716639027390',
        performance_score: 3,
        ad_names: [],
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/767023569590830',
      },
    ],
    losers: [
      {
        id: '388882992279536',
        ad_names: [],
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/274678216251139',
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/865477057416476',
        performance_score: 1,
      },
      {
        id: '10270949064703',
        ad_names: [],
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/37843728562176',
        performance_score: 0,
      },
      {
        id: '947715507637174',
        ad_names: [],
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/82594787059394',
        performance_score: 0,
      },
    ],
    numerator: 1,
    denominator: 3,
    performance_percent: 33.33,
    view_avg_cpa: 0.22258646607269011,
    category: 'Phrase Text',
    visual_mode: false,
    compliance: false,
    derived_label: true,
    udc: false,
    ccc_type: '',
    synonym: false,
    client_down_funnel_events: [],
  },
  {
    name: '"Shop"',
    value: '"shop"',
    label_id: '',
    description: '',
    rating: 1.5,
    type: 'visual_attributes',
    example: '',
    numerator: 4,
    denominator: 7,
    performance_percent: null,
    view_avg_cpa: null,
    winners: [
      {
        id: '494688214894151',
        performance_score: 3,
        ad_names: [],
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/402600435338211',
      },
      {
        id: '899952523135053',
        performance_score: 3,
        ad_names: [],
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/429723221414432',
      },
      {
        id: '170773074914650',
        performance_score: 2,
        ad_names: [],
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/500829958897151',
      },
      {
        id: '848716639027390',
        performance_score: 3,
        ad_names: [],
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/767023569590830',
      },
    ],
    losers: [
      {
        id: '388882992279536',
        ad_names: [],
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/274678216251139',
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/865477057416476',
        performance_score: 1,
      },
      {
        id: '10270949064703',
        ad_names: [],
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/37843728562176',
        performance_score: 0,
      },
      {
        id: '947715507637174',
        ad_names: [],
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/82594787059394',
        performance_score: 0,
      },
    ],
    category: 'Keyword',
    category_id: '',
    visual_mode: false,
    compliance: false,
    derived_label: false,
    udc: false,
    ccc_type: '',
    synonym: false,
    client_down_funnel_events: [],
    average_ctr_quintile: null,
    average_cpa_quintile: null,
    percent_diff_average_cpa: null,
    average_percent_spend: null,
  },
  {
    losers: [
      {
        id: '388882992279536',
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/274678216251139',
        performance_score: 1,
        ad_names: [],
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/865477057416476',
      },
      {
        id: '10270949064703',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/37843728562176',
        performance_score: 0,
        ad_names: [],
      },
      {
        id: '947715507637174',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/82594787059394',
        performance_score: 0,
        ad_names: [],
      },
      {
        id: '327978173002555',
        performance_score: 1,
        visual_type: 'image',
        ad_names: [],
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/736630062434445',
      },
      {
        id: '579785058651364',
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/729873713424525',
        performance_score: 0,
        ad_names: [],
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/146312545673875',
      },
      {
        id: '100003464316926',
        ad_names: [],
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/715841818608043',
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/335446268968957',
        performance_score: 1,
      },
      {
        id: '938695991608758',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/273685286598142',
        performance_score: 1,
        ad_names: [],
      },
      {
        id: '8494027758608',
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/720731845033619',
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/373582249140772',
        ad_names: [],
        performance_score: 1,
      },
      {
        id: '844709995003034',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/716607327130704',
        ad_names: [],
        performance_score: 1,
      },
      {
        id: '374139471376811',
        performance_score: 1,
        ad_names: [],
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/238524057556155',
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/424110534444526',
      },
      {
        id: '71651792204001',
        performance_score: 0,
        ad_names: [],
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/380977410090962',
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/111602586045682',
      },

      {
        id: '270663455011961',
        ad_names: [],
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/505570437835448',
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/72271169085856',
        performance_score: 1,
      },
      {
        id: '358956994046601',
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/163579882326935',
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/347326884263753',
        ad_names: [],
        performance_score: 3,
      },
    ],
    winners: [
      {
        id: '494688214894151',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/402600435338211',
        ad_names: [],
        performance_score: 3,
      },
      {
        id: '899952523135053',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/429723221414432',
        ad_names: [],
        performance_score: 3,
      },
      {
        id: '170773074914650',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/500829958897151',
        ad_names: [],
        performance_score: 3,
      },
      {
        id: '848716639027390',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/767023569590830',
        ad_names: [],
        performance_score: 2,
      },
      {
        id: '620970094169253',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/744798706310121',
        ad_names: [],
        performance_score: 2,
      },
      {
        id: '358921705412341',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/405533315546561',
        ad_names: [],
        performance_score: 3,
      },
      {
        id: '696760245870',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/841833644493022',
        ad_names: [],
        performance_score: 2,
      },
      {
        id: '496797037518823',
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/601614697498095',
        ad_names: [],
        performance_score: 2,
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/798698063424263',
      },
      {
        id: '338364050748920',
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/752770995833305',
        ad_names: [],
        performance_score: 3,
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/309796676602597',
      },
      {
        id: '388942259712372',
        ad_names: [],
        performance_score: 3,
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/443196437750444',
      },
      {
        id: '267379883590810',
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/819854637253387',
        ad_names: [],
        performance_score: 2,
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/481596865084945',
      },
      {
        id: '722731974443475',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/47549627339803',
        ad_names: [],
        performance_score: 2,
      },
      {
        id: '808753445700999',
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/396956048993424',
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/192655693060412',
        ad_names: [],
        performance_score: 2,
      },
      {
        id: '891439321973678',
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/585024578807531',
        ad_names: [],
        performance_score: 2,
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/819591069731426',
      },
      {
        id: '755262827261533',
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/234299210228854',
        ad_names: [],
        performance_score: 2,
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/744913751327841',
      },
      {
        id: '854313862492295',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/499790938109206',
        ad_names: [],
        performance_score: 2,
      },
      {
        id: '531840581973048',
        ad_names: [],
        performance_score: 2,
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/942004871914290',
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/373834294001631',
      },
      {
        id: '92018118952547',
        ad_names: [],
        performance_score: 3,
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/681927460705292',
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/243754863098868',
      },
    ],
    value: 'all_visuals',
    name: 'All Visuals',
    description: 'Every visual shown in the report.',
    example: {},
    category: null,
    numerator: 48,
    denominator: 136,
    performance_percent: 35.294117647058826,
    label_id: '-',
    view_avg_cpa: 0.22258646607269011,
  },
  {
    losers: [
      {
        id: '388882992279536',
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/274678216251139',
        performance_score: 1,
        ad_names: [],
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/865477057416476',
      },
      {
        id: '10270949064703',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/37843728562176',
        performance_score: 0,
        ad_names: [],
      },
      {
        id: '947715507637174',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/82594787059394',
        performance_score: 0,
        ad_names: [],
      },
      {
        id: '327978173002555',
        performance_score: 1,
        visual_type: 'image',
        ad_names: [],
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/736630062434445',
      },
      {
        id: '579785058651364',
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/729873713424525',
        performance_score: 0,
        ad_names: [],
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/146312545673875',
      },
      {
        id: '100003464316926',
        ad_names: [],
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/715841818608043',
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/335446268968957',
        performance_score: 1,
      },
      {
        id: '938695991608758',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/273685286598142',
        performance_score: 1,
        ad_names: [],
      },
      {
        id: '8494027758608',
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/720731845033619',
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/373582249140772',
        ad_names: [],
        performance_score: 1,
      },
      {
        id: '844709995003034',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/716607327130704',
        ad_names: [],
        performance_score: 1,
      },
      {
        id: '374139471376811',
        performance_score: 1,
        ad_names: [],
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/238524057556155',
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/424110534444526',
      },
      {
        id: '71651792204001',
        performance_score: 0,
        ad_names: [],
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/380977410090962',
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/111602586045682',
      },

      {
        id: '270663455011961',
        ad_names: [],
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/505570437835448',
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/72271169085856',
        performance_score: 1,
      },
      {
        id: '358956994046601',
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/163579882326935',
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/347326884263753',
        ad_names: [],
        performance_score: 3,
      },
    ],
    winners: [
      {
        id: '494688214894151',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/402600435338211',
        ad_names: [],
        performance_score: 3,
      },
      {
        id: '899952523135053',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/429723221414432',
        ad_names: [],
        performance_score: 3,
      },
      {
        id: '170773074914650',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/500829958897151',
        ad_names: [],
        performance_score: 3,
      },
      {
        id: '848716639027390',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/767023569590830',
        ad_names: [],
        performance_score: 2,
      },
      {
        id: '620970094169253',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/744798706310121',
        ad_names: [],
        performance_score: 2,
      },
      {
        id: '358921705412341',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/405533315546561',
        ad_names: [],
        performance_score: 3,
      },
      {
        id: '696760245870',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/841833644493022',
        ad_names: [],
        performance_score: 2,
      },
      {
        id: '496797037518823',
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/601614697498095',
        ad_names: [],
        performance_score: 2,
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/798698063424263',
      },
      {
        id: '338364050748920',
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/752770995833305',
        ad_names: [],
        performance_score: 3,
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/309796676602597',
      },
      {
        id: '388942259712372',
        ad_names: [],
        performance_score: 3,
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/443196437750444',
      },
      {
        id: '267379883590810',
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/819854637253387',
        ad_names: [],
        performance_score: 2,
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/481596865084945',
      },
      {
        id: '722731974443475',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/47549627339803',
        ad_names: [],
        performance_score: 2,
      },
      {
        id: '808753445700999',
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/396956048993424',
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/192655693060412',
        ad_names: [],
        performance_score: 2,
      },
      {
        id: '891439321973678',
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/585024578807531',
        ad_names: [],
        performance_score: 2,
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/819591069731426',
      },
      {
        id: '755262827261533',
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/234299210228854',
        ad_names: [],
        performance_score: 2,
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/744913751327841',
      },
      {
        id: '854313862492295',
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/499790938109206',
        ad_names: [],
        performance_score: 2,
      },
      {
        id: '531840581973048',
        ad_names: [],
        performance_score: 2,
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/942004871914290',
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/373834294001631',
      },
      {
        id: '92018118952547',
        ad_names: [],
        performance_score: 3,
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/681927460705292',
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/243754863098868',
      },
    ],
    value: 'new_visuals',
    name: 'All New Visuals',
    description: 'Every new visual shown in the report.',
    example: {},
    category: null,
    numerator: 48,
    denominator: 136,
    performance_percent: 35.294117647058826,
    label_id: '-',
    view_avg_cpa: 0.22258646607269011,
  },
  {
    name: '"20% Off"',
    value: '"20% off"',
    label_id: '',
    description: '',
    rating: 1.5,
    type: 'visual_attributes',
    example: '',
    numerator: 4,
    denominator: 7,
    performance_percent: null,
    view_avg_cpa: null,
    winners: [
      {
        id: '494688214894151',
        performance_score: 3,
        ad_names: [],
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/402600435338211',
      },
      {
        id: '899952523135053',
        performance_score: 3,
        ad_names: [],
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/429723221414432',
      },
      {
        id: '170773074914650',
        performance_score: 2,
        ad_names: [],
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/500829958897151',
      },
      {
        id: '848716639027390',
        performance_score: 3,
        ad_names: [],
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/767023569590830',
      },
    ],
    losers: [
      {
        id: '388882992279536',
        ad_names: [],
        visual_type: 'video',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/274678216251139',
        thumbnail_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/865477057416476',
        performance_score: 1,
      },
      {
        id: '10270949064703',
        ad_names: [],
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/37843728562176',
        performance_score: 0,
      },
      {
        id: '947715507637174',
        ad_names: [],
        visual_type: 'image',
        asset_url:
          'https://fb-assets.s3.us-east-2.amazonaws.com/82594787059394',
        performance_score: 0,
      },
    ],
    category: 'Keyword',
    category_id: '',
    visual_mode: false,
    compliance: false,
    derived_label: false,
    udc: false,
    ccc_type: '',
    synonym: false,
    client_down_funnel_events: [],
    average_ctr_quintile: null,
    average_cpa_quintile: null,
    percent_diff_average_cpa: null,
    average_percent_spend: null,
  },
];

export const PMAX_SCORE_LOOKUP = {
  0: 'Unknown/Not enough data',
  1: 'Bad',
  2: 'Good',
  3: 'Best',
};

export const DEFAULT_DATE_RANGES_BY_VIEW = {
  '5DYcU0mCDBImclxMyq': ['2024-09-01', '2024-09-30'],
  cZ2LM4VSgTg4FxXDJu: ['2024-09-01', '2024-09-30'],
  IqXtUOulCpti9YrusB: ['2024-09-01', '2024-09-30'],
  gjgC71U86Gkrs2le2L: ['2024-09-01', '2024-09-30'],
  QECSt7ES6RX1Dbir10: ['2024-09-01', '2024-09-30'],
  Y9byFRTkyWWwEWXF1E: ['2024-12-01', '2025-01-01'],
};

export const LOCKED_DATES = [
  {
    viewIds: ['Ki3K0dH16iJcKOWb1Z', 'Krn2bJakmHMvDH7cLa', 'yX6F2p5T5WKMmLbEJ6'],
    accountIds: ['act_462484415951159'],
    dateRange: ['2024-01-01', '2024-04-30'],
  },
  {
    viewIds: ['5N2uJW42fEe61kwAcI'],
    accountIds: [],
    dateRange: ['2023-10-01', '2023-12-31'],
  },
  {
    viewIds: ['VRW3O16LootEP7NlWx', 't3u7ASGz7QXOf2V6Rm'],
    accountIds: [],
    dateRange: ['2024-12-01', '2024-12-31'],
  },
];

export const DISABLE_REFRESH_VIEWS = [];
