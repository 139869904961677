import INITIAL_STATE from '../initial-state';
import { ADSETS_SET } from './actions';

const reducer = (state = INITIAL_STATE.Adsets, action) => {
  switch (action.type) {
    case ADSETS_SET:
      return {
        byNames: action.byIds,
        adsets: action.data,
      };

    default:
      return state;
  }
};

export default reducer;
