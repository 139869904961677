import React, { useMemo } from 'react';
import { LeftMenu } from '../../../../../components/LeftMenu/Menu';
import TableModeMenu from './Menu.TableMode';

import './Menu.scss';

interface MenuItemData {
  name: string;
  hasSubMenu: boolean;
  icon: JSX.Element;
}

interface MenuProps {
  mode: 'table' | 'visuals';
  onMenuItemClick: (menuItem: string) => void;
  setIsSettingsOpen: () => void;
  loading: boolean;
  isDirectMail: boolean;
  isNonPaidMediaType: boolean;
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
  mainView: any;
}

const Menu: React.FC<MenuProps> = ({
  mode,
  onMenuItemClick,
  setIsSettingsOpen,
  loading,
  isDirectMail,
  isNonPaidMediaType,
  collapsed,
  setCollapsed,
  mainView,
}) => {
  let Render = null;

  switch (mode) {
    case 'table':
      Render = (
        <div className="pt-3">
          <TableModeMenu
            setIsSettingsOpen={setIsSettingsOpen}
            {...{
              loading,
              isDirectMail,
              isNonPaidMediaType,
              collapsed,
              setCollapsed,
            }}
          />
        </div>
      );
      break;
    case 'visuals':
      break;
    default:
      break;
  }

  return (
    <LeftMenu {...{ collapsed, setCollapsed, mainView }}>{Render}</LeftMenu>
  );
};

export default Menu;
