import React from 'react';
import flatten from 'lodash/flatten';
import { useViewSettings } from '../../../../selectors';
import { toCurrency } from '../../../../utils/numbers';

export const useCustomIntegratedMetricsColumns = () => {
  const { customIntegratedMetrics } = useViewSettings();

  if (!customIntegratedMetrics) return [];

  const customIntegratedMetricsColumns = customIntegratedMetrics.map((metric) => {
    return [
      {
        Header: `Total ${metric.label}`,
        accessor: metric.value,
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          const value = original[metric.value];
          if (!value) return <p>N/A</p>;
          return <p>{value.toFixed(2)}</p>;
        },
      },
      {
        Header: `Cost Per ${metric.label}`,
        accessor: `cp_${metric.value}`,
        disableFilters: true,
        minWidth: 200,
        Cell: ({ row: { original } }) => {
          const value = original[`cp_${metric.value}`];
          if (!value) return <p>N/A</p>;
          return <p>{toCurrency(value)}</p>;
        },
      },
      {
        Header: `Median Cost Per ${metric.label}`,
        accessor: `median_cp_${metric.value}`,
        disableFilters: true,
        minWidth: 200,
        Cell: ({ row: { original } }) => {
          const value = original[`median_cp_${metric.value}`];
          if (!value) return <p>N/A</p>;
          return <p>{toCurrency(value)}</p>;
        },
      },
    ];
  });

  return flatten(customIntegratedMetricsColumns);
};
