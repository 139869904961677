import React, { useContext } from "react";

import { WizardContext } from "./Context";
import { FormText, Audience, Creative } from "./FormElements";

const DETAIL_SWITCH = {
  "automatic:ab_test:audience": {
    state: {
      group_a_label: "",
      group_a_adset_id: "",
      group_a_adset_name: "",
      group_b_adset_id: "",
      group_b_label: "",
      group_b_adset_name: "",
    },
    Component: () => (
      <AB_Layout A_Selection={<AAudience />} B_Selection={<BAudience />} />
    ),
  },

  "automatic:abz_test:audience": {
    state: {
      group_a_label: "",
      group_a_adset_id: "",
      group_a_adset_name: "",
    },
    Component: () => <AZ_Layout A_Selection={<AAudience />} />,
  },

  "automatic:ab_test:creative": {
    state: {
      group_a_label: "",
      group_a_ad_id: "",
      //group_a_ad_string: "",
      group_a_ad_name: "",
      group_b_ad_id: "",
      group_b_label: "",
      group_b_ad_name: "",
      group_b_ad_string: "",
    },
    Component: () => (
      <AB_Layout A_Selection={<ACreative />} B_Selection={<BCreative />} />
    ),
  },

  "automatic:abz_test:creative": {
    state: {
      group_a_label: "",
      group_a_ad_id: "",
      group_a_ad_name: "",
    },
    Component: () => <AZ_Layout A_Selection={<ACreative />} />,
  },

  "manual:abz_test:creative": {
    state: {
      group_a_label: "",
      group_a_ad_name: "",
      group_a_adset_name: "",
    },
    Component: () => (
      <AZ_Manual_Layout
        A_Ad_Selection={<AManualCreative />}
        A_Adset_Selection={<AManualAudience />}
        stringType="creative"
      />
    ),
  },

  "manual:ab_test:creative": {
    state: {
      group_a_label: "",
      group_a_ad_name: "",
      group_a_adset_name: "",
      group_b_label: "",
      group_b_ad_name: "",
      group_b_adset_name: "",
    },
    Component: () => (
      <AB_Manual_Layout
        A_Ad_Selection={<AManualCreative />}
        A_Adset_Selection={<AManualAudience />}
        B_Ad_Selection={<BManualCreative />}
        B_Adset_Selection={<BManualAudience />}
        stringType="audience"
      />
    ),
  },

  "manual:abz_test:audience": {
    state: {
      group_a_label: "",
      group_a_ad_name: "",
      group_a_adset_name: "",
    },
    Component: () => (
      <AZ_Manual_Layout
        A_Ad_Selection={<AManualCreative />}
        A_Adset_Selection={<AManualAudience />}
      />
    ),
  },

  "manual:ab_test:audience": {
    state: {
      group_a_label: "",
      group_a_ad_name: "",
      group_a_adset_name: "",
      group_b_label: "",
      group_b_ad_name: "",
      group_b_adset_name: "",
    },
    Component: () => (
      <AB_Manual_Layout
        A_Ad_Selection={<AManualCreative />}
        A_Adset_Selection={<AManualAudience />}
        B_Ad_Selection={<BManualCreative />}
        B_Adset_Selection={<BManualAudience />}
      />
    ),
  },
};

/**
 * @param {"automatic" | "manual"} automation_study_type
 * @param {"ab_test" | "abz_test"} testing_study_type
 * @param {"audience" | "creative"}
 *
 * @return {{ state: Object, Component: Function }}
 */
export function useDetails(
  automation_study_type,
  testing_study_type,
  study_type
) {
  const branch = `${automation_study_type}:${testing_study_type}:${study_type}`;
  const maybe = DETAIL_SWITCH[branch];

  if (!maybe) {
    return {
      state: {},
      Component: () => (
        <p>
          One of these is invalid: {automation_study_type}, {testing_study_type}
          , {study_type}
        </p>
      ),
    };
  } else {
    return maybe;
  }
}

function AZ_Manual_Layout({ A_Ad_Selection, A_Adset_Selection, stringType }) {
  return (
    <>
      {A_Ad_Selection}
      {A_Adset_Selection}
      {<ALabel />}
    </>
  );
}

function AB_Manual_Layout({
  A_Ad_Selection,
  A_Adset_Selection,
  B_Ad_Selection,
  B_Adset_Selection,
  stringType,
}) {
  return (
    <>
      {A_Ad_Selection}
      {A_Adset_Selection}
      {<ALabel />}
      {B_Ad_Selection}
      {B_Adset_Selection}

      {<BLabel />}
    </>
  );
}

function AZ_Layout({ A_Selection, stringType = "" }) {
  return (
    <>
      {A_Selection}
      {<ALabel />}
      {stringType === "" ? null : stringType === "audience" ? (
        <A_Adset_String />
      ) : (
        <A_Ad_String />
      )}
    </>
  );
}

function AB_Layout({ A_Selection, B_Selection, stringType = "" }) {
  return (
    <>
      {A_Selection}
      {stringType === "" ? null : stringType === "audience" ? (
        <A_Adset_String />
      ) : (
        <A_Ad_String />
      )}
      {<ALabel />}
      {B_Selection}
      {stringType === "" ? null : stringType === "audience" ? (
        <B_Adset_String />
      ) : (
        <B_Ad_String />
      )}
      {<BLabel />}
    </>
  );
}

function Label({ name, label }) {
  const ctx = useContext(WizardContext);

  return (
    <FormText
      name={name}
      label={label}
      onChange={ctx.handleChange}
      value={ctx[name]}
      required
      disabled={ctx.inProgress}
    />
  );
}

function AAudience() {
  const name = "group_a_adset_id";
  const ctx = useContext(WizardContext);

  return (
    <Audience
      name={name}
      value={ctx[name]}
      campaigns={[]}
      onChange={ctx.handleChange}
      label="Group A Audience"
      disabled={ctx.inProgress}
    />
  );
}

function BAudience() {
  const name = "group_b_adset_id";
  const ctx = useContext(WizardContext);

  return (
    <Audience
      name={name}
      value={ctx[name]}
      campaigns={[]}
      onChange={ctx.handleChange}
      label="Group B Audience"
      opposingValue={ctx.group_a_adset_id}
      disabled={ctx.inProgress}
    />
  );
}

function AManualCreative() {
  return <Label name="group_a_ad_string" label="Group A Ad String" />;
}

function AManualAudience() {
  return <Label name="group_a_adset_string" label="Group A Adset String" />;
}

function BManualCreative() {
  return <Label name="group_b_ad_string" label="Group B Ad String" />;
}

function BManualAudience() {
  return <Label name="group_b_adset_string" label="Group B Adset String" />;
}

function ACreative() {
  const name = "group_a_ad_id";
  const ctx = useContext(WizardContext);

  return (
    <Creative
      name={name}
      value={ctx[name]}
      campaigns={[]}
      onChange={ctx.handleChange}
      label="Group A Creative"
      disabled={ctx.inProgress}
    />
  );
}

function BCreative() {
  const name = "group_b_ad_id";
  const ctx = useContext(WizardContext);

  return (
    <Creative
      name={name}
      value={ctx[name]}
      campaigns={[]}
      onChange={ctx.handleChange}
      label="Group B Creative"
      opposingValue={ctx.group_a_ad_id}
      disabled={ctx.inProgress}
    />
  );
}

function ALabel() {
  return <Label name="group_a_label" label="Group A Friendly Name" />;
}

function BLabel() {
  return <Label name="group_b_label" label="Group B Friendly Name" />;
}

function A_Ad_String() {
  return <Label name="group_a_ad_string" label="Group A Ad String" />;
}

function A_Adset_String() {
  return <Label name="group_a_adset_string" label="Group A Adset String" />;
}

function B_Ad_String() {
  return <Label name="group_b_ad_string" label="Group B Ad String" />;
}

function B_Adset_String() {
  return <Label name="group_b_adset_string" label="Group B Adset String" />;
}
