import React from 'react';
import Overlay from '../../../../components/Overlay';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';

const CreateModal = ({
  disableCreate,
  setShowCreateModal,
  newProjectName,
  setNewProjectName,
  saveDraftProjectLoading,
  saveDraftProjectError,
  handleCreateProject,
}) => {
  return (
    <Overlay close={() => setShowCreateModal(false)} show>
      <Modal className="create-draft-project-modal">
        <h3>Create Draft Project</h3>
        <div className="p-1 w-100">
          <div className="form-group">
            <label>Project Name</label>
            <input
              className="w-100"
              onChange={(e) => setNewProjectName(e.target.value)}
            ></input>
          </div>
        </div>
        {disableCreate && (
          <div>
            <i
              className="fa-solid fa-triangle-exclamation mr-2"
              style={{ color: '#f0ad4e', fontSize: '1.2em' }}
            />
            A project with this name already exists.
          </div>
        )}
        <div className="align-self-end w-100">
          <Button
            fullWidth
            className="mt-3 mr-3"
            onClick={() => setShowCreateModal(false)}
            appearance="subtle"
          >
            Cancel
          </Button>
          <Button
            fullWidth
            className="mt-3"
            loading={saveDraftProjectLoading}
            onClick={() => {
              if (!newProjectName.length) return;
              handleCreateProject(newProjectName);
            }}
            disabled={disableCreate}
          >
            Create project
          </Button>
          {saveDraftProjectError && <div>There was an error.</div>}
        </div>
      </Modal>
    </Overlay>
  );
};

export default CreateModal;
