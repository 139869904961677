import usePortal from 'react-cool-portal';

export function usePortals() {
  return {
    ActionButtonsPortal: usePortal({
      containerId: 'action-buttons-root',
      clickOutsideToHide: false,
      defaultShow: true,
    }),
    CreativeRequestPortal: usePortal({
      containerId: 'creative-request-root',
      clickOutsideToHide: false,
      defaultShow: true,
    }),
    ReportCardPortal: usePortal({
      containerId: 'report-card-root',
      clickOutsideToHide: false,
    }),
    LibraryAnalyticsPortal: usePortal({
      containerId: 'library-analytics-root',
      clickOutsideToHide: false,
    }),
    PhraseThemeSettingsPortal: usePortal({
      containerId: 'phrase-theme-settings-root',
      clickOutsideToHide: false,
      defaultShow: true,
    })
  };
}
