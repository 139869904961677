import { useEffect } from 'react';
import { useDerivedLabelsApi } from '../CategoryBuilder/Custom/DerivedLabels/api';

export const useDerivedLabelsDelete = (fetchPerformanceData, reportCardSettings) => {
  const derivedLabelsApi = useDerivedLabelsApi(fetchPerformanceData);

  useEffect(() => {
    if (derivedLabelsApi.delete.data) {
      reportCardSettings.deleteCategoryFromSettings(
        derivedLabelsApi.delete.data
      );
    }
  }, [derivedLabelsApi.delete.data]);
}