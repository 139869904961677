import React, { useEffect, useState } from 'react';
import './Settings.scss';
import { useCopilotContext } from '../contexts/copilotContext';
import Select from 'react-select';
import Checkbox from '../components/Checkbox';
import RadioButton from '../components/RadioButton';
import {
  SET_BASE_VISUAL,
  SET_DETAILS,
  SET_INSTRUCTIONS,
  SET_PREFERENCES,
} from '../hooks/useCopilotState';
import CopilotButton from '../components/CopilotButton';
import CollapsibleItem from '../../../../components/CollapsibleItem/CollapsibleItem';
import { BrowserIcon } from '../../Explorer/icons/ToolbarIcons';
import { ExplorerState } from '../../Explorer/types';
import { useExplorerContext } from '../../Explorer/contexts/explorerContext';
import { CLOSE, SET_VIEW } from '../../Explorer/hooks/useExplorerState';
import { VisualsLookup } from '../../ReportCard/types';
import { useCreativeAnalyticsGet } from '../../contexts';
import Visual from '../../Variation/components/Card/Visual';
import Button from '../../../../components/Button';

const Settings = ({
  // contextOptions,
  // context,
  // handleRoute,
  handleSendRequest,
  loading,
  changesMade,
  setShowHint,
  simpleImpactLoading,
  simpleImpactError,
  isAnswerQuestions,
  prevState,
  handleSaveSettings,
  saveSettingsLoading,
  saveSettingsError,
  saveSuccess,
}) => {
  const { copilotState, copilotDispatch } = useCopilotContext();
  const {
    dataToInclude,
    requestType,
    instructions,
    currentOutput,
    clientPreferences,
    advertiserDetails,
    baseVisual,
  } = copilotState;
  const { drawerState, drawerDispatch }: ExplorerState = useExplorerContext();
  const {
    visualsLookup,
    filteredAllVisualsLabel,
  }: { visualsLookup: VisualsLookup; filteredAllVisualsLabel } =
    useCreativeAnalyticsGet();
  const [saveMessage, setSaveMessage] = useState('');

  const disableButton =
    (requestType === 'answer-questions' && !instructions.length) ||
    loading ||
    (currentOutput.summary.length && !changesMade) ||
    simpleImpactLoading ||
    !Object.values(dataToInclude).some((type) => type);

  const disableInstructions =
    currentOutput.summary.length > 0 && prevState.requestType === requestType;

  const handleOpenDrawer = () => {
    drawerDispatch({
      type: 'OPEN',
      currentView: { type: 'category_viewer', id: '' },
      selectedVisualId: '',
    });
    drawerDispatch({
      type: SET_VIEW,
      currentView: {
        type: 'browser',
        browserType: 'visuals',
      },
    });
  };

  const handleClearSelection = () => {
    drawerDispatch({
      type: CLOSE,
      keepState: false,
    });
  };

  useEffect(() => {
    if (drawerState.selectedVisualId) {
      const dataObj = {
        id: '',
        asset_url: '',
        visual_type: '',
        labels: [],
      };

      const found = Object.values(visualsLookup).find(
        (visual) => visual.data.id === drawerState.selectedVisualId
      );

      if (found) {
        const { data } = found;
        const labels = found.labels
          .map((label) => label.name)
          .filter(
            (name) =>
              ![
                'All Visuals, All New Visuals, Top 10 Spending Visuals',
              ].includes(name)
          );

        dataObj.id = data.id;
        dataObj.asset_url = data.asset_url;
        dataObj.visual_type = data.visual_type;
        dataObj.labels = labels;
      }

      copilotDispatch({
        type: SET_BASE_VISUAL,
        visual: dataObj,
      });
    } else {
      copilotDispatch({
        type: SET_BASE_VISUAL,
        visual: {
          id: '',
          asset_url: '',
          visual_type: '',
          labels: [],
        },
      });
    }
  }, [drawerState.selectedVisualId]);

  useEffect(() => {
    if (saveSuccess) {
      setSaveMessage('Saved.');
    }

    if (saveSettingsError) {
      setSaveMessage('Error saving.');
    }

    setTimeout(() => {
      setSaveMessage('');
    }, 5000);
  }, [saveSettingsError, saveSuccess]);

  return (
    <div className="copilot__section request-settings col-3">
      {/* <div className="contexts">
        <div className="section-label">Context</div>
        <Select
          options={contextOptions}
          onChange={(e) => handleRoute(e.value)}
          value={context}
        />
      </div> */}
      <div className="data-to-include">
        <div className="section-label">Data to include:</div>
        <Checkbox label={'Ad Level Data'} dataType={'dna'} disabled={false} />
        <div className="d-flex align-items-center">
          <Checkbox
            label={'Impact Report Data'}
            dataType={'impact'}
            disabled={false}
          />
          {simpleImpactLoading && (
            <i className="fa-regular fa-hourglass-half ml-1" />
          )}
          {simpleImpactError && simpleImpactError !== 'Error: undefined' && (
            <span>
              <i className="fa fa-exclmation-circle ml-1" /> Error fetching the
              data.
            </span>
          )}
        </div>
        {!isAnswerQuestions && (
          <Checkbox
            label={'Darwin Index Data'}
            dataType={'index'}
            disabled={isAnswerQuestions}
          />
        )}
        <Checkbox label={'Summary Data'} dataType={'summary'} disabled={false} />
      </div>
      <div className="request-type">
        <RadioButton
          type="creative-request"
          {...{ requestType, copilotDispatch, loading }}
        />
        <RadioButton
          type="answer-questions"
          {...{ requestType, copilotDispatch, loading }}
        />
        <RadioButton
          type="rewrite-brief"
          {...{ requestType, copilotDispatch, loading }}
        />
      </div>
      <div className="instructions">
        <div className="section-label">Additional instructions:</div>
        <textarea
          className="text-input text-input--instructions"
          placeholder={
            requestType === 'creative-request'
              ? 'e.g., I want it to be an image ad.'
              : requestType === 'answer-questions'
              ? 'e.g., What background colors are performing best?'
              : ''
          }
          value={instructions}
          onChange={(e) =>
            copilotDispatch({
              type: SET_INSTRUCTIONS,
              text: e.target.value,
            })
          }
          disabled={disableInstructions}
          onMouseOver={() => {
            if (disableInstructions) setShowHint(true);
          }}
          onMouseOut={() => {
            if (disableInstructions) setShowHint(false);
          }}
        />
      </div>
      {requestType === 'creative-request' && (
        <div className="visual-select">
          <div className="d-flex align-items-center">
            <div className="section-label mb-0">Select a base visual: </div>
            <button onClick={handleOpenDrawer}>
              <BrowserIcon height={17} width={17} />
            </button>
          </div>
          {baseVisual.id && (
            <div
              className="clear-selection d-flex"
              onClick={handleClearSelection}
            >
              <i className="fa fa-close" />
            </div>
          )}
        </div>
      )}
      {requestType === 'creative-request' && baseVisual.id && (
        <div className="visual-preview">
          <Visual
            data={baseVisual}
            loading={false}
            isBaseVisual={true}
            handleOpenDrawer={null}
            disableDrawer={false}
          />
        </div>
      )}
      <div>
        <CopilotButton
          handleClick={handleSendRequest}
          disableButton={disableButton}
          loading={loading}
          text={
            requestType === 'creative-request'
              ? 'Generate creative request'
              : requestType === 'answer-questions'
              ? 'Analyze creative data'
              : 'Enhance brief'
          }
        />
      </div>
      <div className="additional-settings">
        <CollapsibleItem
          AnchorChild={
            <div className="section-label">
              <i className="fa-solid fa-chevron-right" /> Client preferences
            </div>
          }
          ExpandableChild={
            <textarea
              className="text-input text-input--preferences"
              onChange={(e) =>
                copilotDispatch({
                  type: SET_PREFERENCES,
                  text: e.target.value,
                })
              }
              value={clientPreferences}
            />
          }
        />
        <CollapsibleItem
          AnchorChild={
            <div className="section-label">
              <i className="fa-solid fa-chevron-right" /> Advertiser details
            </div>
          }
          ExpandableChild={
            <textarea
              className="text-input text-input--details"
              onChange={(e) =>
                copilotDispatch({
                  type: SET_DETAILS,
                  text: e.target.value,
                })
              }
              value={advertiserDetails}
            />
          }
        />
        <div className="d-flex justify-content-end align-items-center">
          <span className="mr-2">{saveMessage}</span>
          <Button
            onClick={() => handleSaveSettings()}
            loading={saveSettingsLoading}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Settings;
