import { connect } from "react-redux";

const stateToProps = (
  { ViewSettings: { loggedIn, isLeverView, allowedAdmin, viewId } },
  props
) => ({
  loggedIn,
  isLeverView,
  allowedAdmin,
  viewId,
});

export default connect(stateToProps, null);
