import { batch } from 'react-redux';
import { apiRequest } from '../Api/actions';

export const RESET_APP = '[*] RESET';
export const RESETTING_APP = '[*] RESETTING';

function shouldResetApp({ type }) {
  return type === RESET_APP;
}

const middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    if (shouldResetApp(action)) {
      const {
        ViewSettings: { token },
      } = getState();

      batch(() => {
        // 1. call server with log out request
        dispatch(
          apiRequest({
            body: { token },
            url: ['api', 'logout'].join('/'),
            method: 'POST',
            entity: 'Token',
          })
        );
        // 2. clear local storage
        dispatch({
          type: '[Token] SET',
          meta: { setLocalStorage: { key: 'token' }, entity: 'Token' },
        });
        // 3. reset redux store
        dispatch({ type: RESETTING_APP });
      });
    }
  };

export default middleware;
