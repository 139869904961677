import React, { useEffect, useState } from 'react';
import Tooltip from '../../../components/Tooltip';
import { toCurrency, toFixed_2 } from '../../../utils/numbers';
import { useCreativeAnalyticsGet } from '../../CreativeAnalytics/contexts';
import { useDatePeriods } from '../../CreativeAnalytics/hooks';
import { useAudienceBreakdownApi } from '../api';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import '../Card.scss';
import { useAudience } from '../hooks';
import AnalyticsButton from '../assets/AnalyticsButton';

import './AudienceBreakdown.scss';
import Card from '../Card';
import { getLabels } from './utils';

function convertRange(value, r1, r2) {
  return ((value - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0]) + r2[0];
}

export const Table = ({ audiences, metric, category, isROAS }) => {
  const [labels, top, worst] = getLabels(audiences, metric);
  const preferLowerValue = metric.includes('cpa');
  
  const formatValue = (val) => {
    if (isROAS) return toFixed_2(val);
    return toCurrency(val);
  }

  return (
    <div className="audience-breakdown">
      <table className="table table-borderless">
        <thead className="">
          <tr>
            <th className="table-header">{category}</th>

            {audiences.map(({ audience }) => {
              return <th className="col-header">{audience}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {labels.map((label) => {
            return (
              <tr>
                <th className="row-header">{label.name}</th>
                {label.audiences.map((audience) => {
                  const value = audience[metric];
                  const shouldShowScore = value && metric.includes('cpa') || metric.includes('roas');

                  const score = shouldShowScore
                    ? convertRange(value, [worst, top], [0, 1])
                    : 0;

                  return (
                    <td
                      className="audience-breakdown__value"
                      style={{
                        background: `rgba(134, 231, 204, ${score || 0})`,
                        opacity: value ? 1 : 0.6,
                      }}
                    >
                      {value ? formatValue(value) : '-'}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};