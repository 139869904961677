import React from 'react';
import Overlay from '../../../../components/Overlay';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';

const DeleteModal = ({ setShowDeleteModal, handleDeleteProject }) => {
  return (
    <Overlay close={() => setShowDeleteModal(false)}>
      <Modal className="draft-projects-modal draft-projects-modal--delete">
        <div className="close-btn" onClick={() => setShowDeleteModal(false)}>
          <i className="fa fa-close" />
        </div>
        <div className="w-100">
          <div
            style={{ fontSize: '1.1rem', fontWeight: 500, marginTop: '1rem' }}
          >
            Delete this project?
          </div>
          <div className="mt-4">
            <Button
              onClick={() => setShowDeleteModal(false)}
              appearance="subtle"
              className="mr-3"
            >
              Cancel
            </Button>
            <Button onClick={handleDeleteProject}>Delete</Button>
          </div>
        </div>
      </Modal>
    </Overlay>
  );
};

export default DeleteModal;
