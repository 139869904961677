//@ts-check
import React from "react";
import PropTypes from "prop-types";

import { useDimensions } from "../../../components/Hooks/dom-utils";

import "../../CubeDashboard/cube-source/components/style.css";

const STYLE = {
  margin: "10px 0 10px 0",
};

const ReportTable = ({
  id,
  data,
  handleSeriesClick,
  selectedId,
  dimensions,
}) => {
  let [header, { height }] = useDimensions();

  const style = { maxHeight: `${dimensions.height - height - 16}px` };
  const sorted = [...data].sort((a, b) => b.spend - a.spend);

  const Header = (
    <thead className="scroll-thead" ref={header}>
      <tr>
        <th scope="col" className="value-col">
          Value
        </th>
        <th scope="col">Spend</th>
        <th scope="col">CPA</th>
        <th scope="col">CPA Difference</th>
        <th scope="col">Ads</th>
      </tr>
    </thead>
  );

  return (
    <table id={id} className="table" style={STYLE}>
      {Header}
      {
        <Rows
          style={style}
          data={sorted}
          currentValue={selectedId}
          onClick={handleSeriesClick}
        />
      }
    </table>
  );
};

ReportTable.propTypes = {
  id: PropTypes.string.isRequired,
  selectedId: PropTypes.string,
  handleSeriesClick: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  dimensions: PropTypes.shape({
    height: PropTypes.number,
  }),
};

export default ReportTable;

function Rows({ data, onClick, currentValue, style }) {
  return (
    <tbody className="scroll-tbody" style={style}>
      {data.map((props, i) => {
        return (
          <Row
            {...props}
            key={`${i}:${props.value}`}
            onClick={(e) => onClick({ category: props.value })}
            selected={currentValue === props.value}
          />
        );
      })}
    </tbody>
  );
}

Rows.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired,
  currentValue: PropTypes.string.isRequired,
  style: PropTypes.object,
};

function Row({
  value,
  cpr,
  percent_difference,
  spend,
  ads,
  onClick,
  selected,
}) {
  return (
    <tr
      onClick={onClick}
      className={`scroll-tr ${
        selected ? "darwin-btn-selected" : "darwin-action"
      }`}
      title={value}
    >
      <td className="value-col">
        {value.length > 30 ? `${value.slice(0, 27)}...` : value}
      </td>
      <td>{`$${spend.toFixed(2)}`}</td>
      <td>{cpr && cpr.toFixed(2)}</td>
      <td>{percent_difference}</td>
      <td>{ads}</td>
    </tr>
  );
}

Row.propTypes = {
  value: PropTypes.string.isRequired,
  cpr: PropTypes.number,
  spend: PropTypes.number.isRequired,
  ads: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
